import { useState, useEffect } from "react"
import { DesktopWrapper } from "./DesktopWrapper/DesktopWrapper"
import MobileWrapper from "./MobileWrapper/MobileWrapper"
import useIsMobile from '../hooks/useIsMobile';
import { LoadingFullPage } from "../elements/LoadingFullPage/LoadingFullPage";
import configHelper from '../utils/configHelper';

interface LayoutWrapperProps {
    children: React.ReactNode,
    allowMobile?: boolean
}

// Simple wrapper component to get the right layout for a given website, i.e. Mobile or Desktop
// Mainly just to avoid repeating code in app/index.tsx...
const LayoutWrapper = ({children, allowMobile}: LayoutWrapperProps) => {
    const isMobileSite = configHelper.useMobile;
    const [useMobileWrapper, setUseMobileWrapper] = useState(false);
    const [layoutLoaded, setLayoutLoaded] = useState(false)
    const isMobile = useIsMobile();

    useEffect(() => {
        if (allowMobile) {
            setUseMobileWrapper(isMobile);
        } else if (isMobileSite) {
            setUseMobileWrapper(isMobileSite)
        }
        setLayoutLoaded(true)
    }, [allowMobile, isMobile, isMobileSite]);

    // make sure we have checked if loaded the layout before rendering any wrapper, avoids potential memory leak warnings...
    return layoutLoaded ? useMobileWrapper ? <MobileWrapper>{children}</MobileWrapper> : <DesktopWrapper>{children}</DesktopWrapper> : <LoadingFullPage entityName="" />
}

export default LayoutWrapper
