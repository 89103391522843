import { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
// API
import { SupportContacts } from '../../api/inni/SupportContacts';
import { CompanyConsultations } from '../../api/inni/CompanyConsultations';
import { SupportContact } from '../../api/inni/data-contracts';
// Hooks
import { SimpleTerm, useSimpleTerms } from '../../hooks/terms/useSimpleTerms'
import { useInniAPI } from '../../hooks/useInniAPI';
import { useAssertPermission } from '../../hooks/useAssertPermission';
// Components
import { ContactCard, ContactCardPlaceholder } from './Components/ContactCard';

import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { Action, Entity } from '../../utils/EntityAction';
import CompanyContext from '../../context/CompanyContext';
import styles from './Help.module.scss';
import useIsMobile from '../../hooks/useIsMobile';

export const Help = () => {
  useAssertPermission(Entity.Help, Action.Read);
  const companyContext = useContext(CompanyContext);
  const supportContactsAPI = useInniAPI(SupportContacts);
  const consultationsSummaryAPI = useInniAPI(CompanyConsultations);
  const [loaded, setLoaded] = useState(false);

  const [accountManager, setAccountManager] = useState<SupportContact>();
  const [accountant, setAccountant] = useState<SupportContact>();
  const [consultationsFree, setConsultationsFree] = useState<number | undefined>();
  const [showAccountManager, setShowAccountManager] = useState(false);
  const [showAccountant, setShowAccountant] = useState(false);

  const isMobile = useIsMobile();
  const simpleTerm = useSimpleTerms();

  // Get consultations count
  useEffect(() => {
    if (consultationsSummaryAPI) {
      consultationsSummaryAPI.summary(companyContext.cid)
      .then((response) => {
        setConsultationsFree(response.data.numFree);
      })
      .catch((error) => {
        console.error(error);
      })
    }
  }, [consultationsSummaryAPI])

  // Get contact details
  useEffect(() => {
    if (supportContactsAPI) {
      supportContactsAPI.index(companyContext.cid)
      .then((response) => {
        const accountManager = response.data.accountManager;
        const accountant = response.data.accountant;
        const willShowAccountManager = accountManager && (accountManager.name !== null || accountManager.canGetFromUpgrade);
        const willShowAccountant = accountant && (accountant.name !== null || accountant.canGetFromUpgrade);

        setAccountManager(accountManager);
        setAccountant(accountant);
        if (willShowAccountManager) setShowAccountManager(true);
        if (willShowAccountant) setShowAccountant(true);

        setLoaded(true);
      })
      .catch((error) => {
        console.error(error);
      })
    }
  }, [supportContactsAPI, companyContext.cid]);

  return (
    <DefaultLayout
      entity={Entity.Help}
      title="Get help"
      greyBackground
      useFullWidth={isMobile}
    >
      <div id={styles.help} className={styles.contactCardContainer}>
        <p className={`lead my-4 text-center ${styles.title}`}>If you need support or advice, { isMobile && <br /> } we are here to help</p>

        <div className="d-flex justify-content-center flex-wrap w-100">
          { loaded
            ? (
              <>
                {/* RE: names, inniAccounts Accountant looks bad, we just use 'Accountant' for inni - DD */}
                { (accountManager && showAccountManager) && (
                  <ContactCard
                    title="Account Manager"
                    name={accountManager.name || ((simpleTerm(SimpleTerm.AppName) === 'Provestor') ? 'Provestor Account Manager' : 'Account Manager')}
                    photoUrl={accountManager.photoUrl || (showAccountant ? './images/placeholders/male_profile.png' : './images/placeholders/female_profile.png')} // If placeholders ensure female is shown - DD
                    canUpgrade={accountManager.name == null && accountManager.canGetFromUpgrade}
                    isMobile={isMobile}
                  />
                )}
                { (accountant && showAccountant) && (
                  <ContactCard
                    title="Accountant"
                    name={accountant.name || ((simpleTerm(SimpleTerm.AppName) === 'Provestor') ? 'Provestor Accountant' : 'Accountant')}
                    photoUrl={accountant.photoUrl || './images/placeholders/female_profile.png'}
                    canUpgrade={accountant.name == null && accountant.canGetFromUpgrade}
                    consultations={consultationsFree}
                    consultationLink={accountant.consultationLink}
                    isMobile={isMobile}
                  />
                )}
              </>
            )
            : (
              <>
                <ContactCardPlaceholder />
                <ContactCardPlaceholder />
              </>
            )
          }
        </div>

        <br />

        <Card className={styles.contactDetails}>
          <Card.Header>
            <p className={styles.title}>Support</p>
          </Card.Header>
          <Card.Body className="centerFlexContent">
            <div className="text-left">
              {/* Phone */}
              <div>
                <p className={`${styles.showSmall} m-0`}><b>Phone:</b></p>
                <FontAwesomeIcon icon={faPhoneAlt} className={`${styles.icon} ${styles.hideSmall}`} />
                {isMobile ? 
                <a href={`tel:${simpleTerm(SimpleTerm.AppContactPhone).replace(/\s/g, '')}`}>
                  <span className="lead">{simpleTerm(SimpleTerm.AppContactPhone)}</span>
                </a> : 
                <span className="lead">{simpleTerm(SimpleTerm.AppContactPhone)}</span>}
              </div>
              {/* Email */}
              <div className="mt-3">
                <p className={`${styles.showSmall} m-0`}><b>Email:</b></p>
                <FontAwesomeIcon icon={faEnvelope} className={`${styles.icon} ${styles.hideSmall}`} />
                <span className="lead">
                  <a href={`mailto:${simpleTerm(SimpleTerm.AppContactEmail)}`}>{simpleTerm(SimpleTerm.AppContactEmail)}</a>
                </span>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

    </DefaultLayout>
  )
}
