import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import { Test } from "../../features/Test/Test";

export const testdata:Array<EntityRoute> = [
    {
        path: '/company/:cid/testdata/',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.TestData,
        component: Test
    }
]