/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LiveCashUpdate, LiveCashV6 } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class LiveCash<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags LiveCash
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/livecash
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<LiveCashV6, any>({
      path: `/api/v2/companies/${companyId}/livecash`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LiveCash
   * @name Post
   * @request POST:/api/v2/companies/{companyId}/livecash
   * @secure
   */
  post = (companyId: number, liveCashUpdate: LiveCashUpdate, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/livecash`,
      method: "POST",
      body: liveCashUpdate,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
