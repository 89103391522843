import styles from "../OnBoarding.module.css";
import { Link } from "react-router-dom";

interface StageDetailsProps {
  title: string;
  info: string;
  linkText?: string; 
  link?: string;
  status?: boolean;
}

const StageDetails = ({ title, info, link, linkText, status }: StageDetailsProps) => {
  return (
    <div className={styles.stageDetailsOuter}>
      <div className={styles.stageDetails}>
        <p data-cy="title" className={status ? `${styles.StagesTitle} ${styles.completed}` : styles.StagesTitle}>
          {title}
        </p>
        <p data-cy="info" className={status ? `${styles.stagesInfo} ${styles.completed}` : styles.stagesInfo}>
          {info}
        </p>
      </div>
      {link && linkText?.trim() &&(
        <Link to={link} className={styles.stagesLinkText}>
              {linkText} 
        </Link>
      )}
    </div>
  );
};

export default StageDetails;
