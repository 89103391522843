import React, { useContext } from 'react';
import BrandContext from '../../context/BrandContext';
import styles from './LoadingFullPage.module.css'

interface LoadingFullPageProps {
    entityName: string,
    errorMessage?: string,
    fullScreen?: boolean
}

export const LoadingFullPage = ({entityName, errorMessage, fullScreen=true}: LoadingFullPageProps) => {
    const brand = useContext(BrandContext).brand;
    
    return (
        // fullscreen=false doesn't overlay the screen and so can be used within e.g. a container
        <div id={fullScreen ? styles.loadingFullPage : undefined}>
            <div id={styles.inner}>
                <img className={styles.logo} src={brand.logoDarkUrl} alt={brand.name} />
                {!errorMessage &&
                    <>
                        <div className={styles.loader} />
                        Loading {entityName}
                    </>
                }
                {errorMessage &&
                    <div className={styles.errorMessage}>
                        {errorMessage}
                    </div>
                }
            </div>
        </div>
    )
}