import { InvoiceTimeLine } from '../../../../api/inni/data-contracts';
import { Button } from '../../../../elements/Button/Button';
import styles from './TimeLine.module.scss';
type Props = {
	timeLine?: InvoiceTimeLine[];
    onClickInvoiceTimeLineButton:(id:number)=>void;
};

const TimeLine = ({ timeLine, onClickInvoiceTimeLineButton }: Props) => {
	return (
		<div data-cy="timeline" className="timeline ml-3">
			<div className="row">
				<div className="col">
					<ul className={`${styles.timeline} `}>
						{timeLine?.map((item:InvoiceTimeLine,index:number) => (
							<li key={index} className={item.elementType ? `${styles[item.elementType]}` : ''}>
								<p className={styles.title}>{item.title}</p>
								<p>{item.description}</p>
								{item.date !== '01/01/0001' ? <p> {item.date}</p> : ''}
                         {item.actionName && item.actionName ==="DeleteBalancingPayments"  && <Button buttonType="delete" onClick={()=>onClickInvoiceTimeLineButton(item.actionId)} label="Delete"></Button>}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default TimeLine;
