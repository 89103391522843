import { Entity, Action } from "../utils/EntityAction";
import { useGetUrl } from "./useGetUrl";
import { useHistoryWrapper } from "./useHistoryWrapper";


export const useNavigateToEntity = () : (entity: Entity, action?: Action, extraParams?: { [key: string]: string|number; }, newTab?: boolean)=> void => {
    const getUrl = useGetUrl();
    const history = useHistoryWrapper();

    return (entity: Entity, action?: Action, extraParams?: { [key: string]: string|number; }, newTab?: boolean) => {
        const url = getUrl(entity, action, extraParams);
        if (url) {
            if (newTab) {
                const newTabUrl = window.location.href.split(url.url.slice(1).split('/')[0])[0] + url.url.slice(1);
                window.open(newTabUrl, '_blank', 'noopener,noreferrer');
            }
            else if (url.external)
                window.location.href = url.url
            else
                history.push(url.url)
        }
    }
}