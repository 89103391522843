import { Form, InputGroup } from 'react-bootstrap'

const SearchWithAction = ({search,setSearch,placeholder,action} : {search : string, setSearch : (i : string) => void, placeholder? : string, action : () => void}) => {

    const checkEnterKey = (key: string) => {
        if (key === 'Enter')
            action()
    }

    return (
        <InputGroup>
            <Form.Control
                type="text"
                placeholder={placeholder ? placeholder : "Search..."}
                className="d-inline"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyUp={(e: { key: string; }) => {checkEnterKey(e.key)}}
                
            />
            <InputGroup.Append>
                <InputGroup.Text style={{cursor:"pointer"}} onClick={action}>Go</InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    )
}

export default SearchWithAction