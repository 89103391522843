/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PensionStatus, PensionStatusPostModel, SelectlistModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PensionStatuses<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags PensionStatuses
   * @name Get
   * @request GET:/api/v2/companies/{companyId}/pensionstatus/{employmentId}
   * @secure
   */
  get = (companyId: number, employmentId: number, params: RequestParams = {}) =>
    this.request<PensionStatus[], any>({
      path: `/api/v2/companies/${companyId}/pensionstatus/${employmentId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PensionStatuses
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/pensionstatus/{employmentId}
   * @secure
   */
  create = (companyId: number, employmentId: number, model: PensionStatusPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/pensionstatus/${employmentId}`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PensionStatuses
   * @name GetStatusSelectList
   * @request GET:/api/v2/companies/{companyId}/pensionstatus/GetStatusSelectList
   * @secure
   */
  getStatusSelectList = (companyId: number, params: RequestParams = {}) =>
    this.request<SelectlistModel[], any>({
      path: `/api/v2/companies/${companyId}/pensionstatus/GetStatusSelectList`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PensionStatuses
   * @name GetProvidersSelectList
   * @request GET:/api/v2/companies/{companyId}/pensionstatus/GetProvidersSelectList
   * @secure
   */
  getProvidersSelectList = (companyId: number, params: RequestParams = {}) =>
    this.request<SelectlistModel[], any>({
      path: `/api/v2/companies/${companyId}/pensionstatus/GetProvidersSelectList`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PensionStatuses
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/pensionstatus/ValidateCreate
   * @secure
   */
  validateCreate = (companyId: number, model: PensionStatusPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/pensionstatus/ValidateCreate`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags PensionStatuses
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/pensionstatus/ValidateUpdate/{statusId}
   * @secure
   */
  validateUpdate = (companyId: number, statusId: number, model: PensionStatusPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/pensionstatus/ValidateUpdate/${statusId}`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags PensionStatuses
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/pensionstatus/{employmentId}/{statusId}
   * @secure
   */
  update = (
    companyId: number,
    employmentId: number,
    statusId: number,
    model: PensionStatusPostModel,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/pensionstatus/${employmentId}/${statusId}`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PensionStatuses
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/pensionstatus/{employmentId}/{statusId}
   * @secure
   */
  delete = (companyId: number, employmentId: number, statusId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/pensionstatus/${employmentId}/${statusId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
