import { SetupWizardForm } from '../../../api/inni/data-contracts';
import { Button } from '../../../elements/Button/Button';
import Creditors from './Creditors';
import Debtors from './Debtors';
import styles from '../SetupWizard.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { WarningText } from './WarningText';
import { formatDate } from '../../../utils/formatDate';

const OutstandingPayments = ({setNextPage, formState, setFormState, setPrevPage} : {setNextPage : () => void, formState : SetupWizardForm, setFormState : (i : SetupWizardForm) => void, setPrevPage : () => void}) => {

    return (
        
        <div>
            <p style={{cursor: 'pointer'}} onClick={setPrevPage}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
            <h1>Outstanding payments</h1>
            <p>In order to provide an accurate view of your company's finances, you'll need to enter the details of any outstanding transactions as at {formatDate(formState.setupMigrationDate)}. This includes both money owed to your company, perhaps from clients, and money your company owed to others.</p>
            <hr />
            {formState.setupUnpaid ? 
            <>
                <p>The outstanding payments for this company have already been confirmed, please reset the wizard on the confirmation page to make changes.</p>
            </> : 
            <>                
                <h5><u>Money owed to my company</u></h5>
                <p>Enter the details of any money owed to your company on {formatDate(formState.setupMigrationDate)}. For example, you may have invoiced a client before {formatDate(formState.setupMigrationDate)} and the payment had not been received by {formatDate(formState.setupMigrationDate)}.</p>
                <Debtors setFormState={setFormState} formState={formState} />
                <hr style={{marginTop: '30px'}} />
                <h5><u>Money my company owed</u></h5>
                <p>Enter the details of any money your company owed on {formatDate(formState.setupMigrationDate)}. For example, any unpaid bills and expenses, unpaid accountancy fees, unpaid payslips or unpaid dividends. Do not enter corporation tax returns, VAT returns or PAYE payments, as these have already been captured.</p>
                <Creditors setFormState={setFormState} formState={formState} />
            </>}
            
            <div className={styles.formButtons}>
                <Button onClick={setNextPage} variant="change">Continue</Button>
            </div>
            <WarningText />
        </div>)
}

export default OutstandingPayments