import { useContext, useEffect, useState, lazy, Suspense } from 'react'
import { useParams } from 'react-router-dom';
import { Account, CertificateType, Property, PropertyCertificate } from '../../api/inni/data-contracts';
import { Properties } from '../../api/inni/Properties';
import { useAssertPermission } from '../../hooks/useAssertPermission';
import { Action, Entity } from '../../utils/EntityAction';
import * as Tabs from '../../elements/VerticalTabs/VerticalTabs';
import { EmptyLayout } from '../../layouts/Desktop/EmptyLayout';
import { PropertyHeader } from './Components/PropertyHeader';
import { useFetchSingleEntity } from '../../hooks/entities/useFetchSingleEntity';
import RAGIndicator, { RAGIndicatorColours } from '../../elements/RAGIndicator/RAGIndicator';
import { useFetchEntityList } from '../../hooks/entities/useFetchEntityList';
import { Accounts } from '../../api/inni/Accounts';
import { CertificateTypes } from '../../api/inni/CertificateTypes';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { useInniAPI } from '../../hooks/useInniAPI';
import { PropertyCertificates as PropertyCertificatesAPI } from "../../api/inni/PropertyCertificates";
import { useHasPermission } from '../../hooks/useHasPermission';
import { asYearMonthDay } from '../../utils/formatDate';
import PillTabs from '../../components/PillTabs/PillTabs';
import { Tab } from "react-bootstrap";
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import DismissibleBanner, { DismissibleBannerProps } from '../../elements/DismissibleBanner/DismissibleBanner';
import { useHistoryWrapper } from '../../hooks/useHistoryWrapper';


const PropertyDashboard = lazy(() => import('./Components/PropertyDashboard'));
const PropertyUnits     = lazy(() => import('./Components/PropertyUnits'));
const PropertyDetails   = lazy(() => import('./Components/PropertyDetails'));
const PropertyDetailsEdit = lazy(() => import('./Components/PropertyDetailsEdit'));
const PropertyOwnership = lazy(() => import('./Components/PropertyOwnership'));
const PropertyTransactions = lazy(() => import('./Components/PropertyTransactions'));
const PropertyCertificates = lazy(() => import('./PropertyCertificates/PropertyCertificates'));
const PropertyValuations = lazy(() => import('./Components/PropertyValuations'));
const MortgageAccountViewer = lazy(() => import('./Components/PropertyMortgageAccounts'));
const PropertyPurchaseAndSale = lazy(() => import('./Components/PropertyPurchaseAndSale'));


interface RouteParams {
    id: string,
    defaultTab?: string
}

interface TabItem {
    eventKey: string;
    title: string;
    adminOnly: boolean;
}

export enum PropertiesTabsEnum{
    DASHBOARD='dashboard',
    UNITS = 'tenantsandunits',
    TRANSACTIONS = 'transactions',
    DETAILS = 'propertydetails',
    /* Load and calc the correct indicator colour how? Assume we want it to show up before the component is first clicked/opened */
    CERTIFICATES = 'propertycertificates',
    VALUATIONS = 'propertyvaluations',
    OWNERSHIP = 'ownership',
    MORTAGAGE_LOANS = 'mortgagesandloans',
    PURCHASE_SALE = 'purchaseandsale',
}

export const PropertyReadPage = () => {
    useAssertPermission(Entity.Property, Action.Read);
    const propertyId = parseInt(useParams<RouteParams>().id);
    const defaultTab = useParams<RouteParams>().defaultTab
    const [property, propertyLoaded, reloadProperty] = useFetchSingleEntity<Property, Properties>(Properties, propertyId);
    const [mortgageChanged, setMortgageChanged] = useState<boolean|undefined>(false);
    const [selectedKey, setSelectedKey] = useState<string|undefined>();
    const [isEditing, setIsEditing] = useState<boolean|undefined>(false);
    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts);

    // In order to show the RAG for the Certificates tab we have to load the data here
    const PropertyCertificateAPI = useInniAPI(PropertyCertificatesAPI);
    const CertificateTypesAPI = useInniAPI(CertificateTypes);
    const companyContext = useContext(CompanyContext)
    const [isLoaded, setIsLoaded] = useState(false);
    const [certData, setCertData] = useState<PropertyCertificate[] | undefined>(undefined);
    const [typesData, setTypesData] = useState<CertificateType[] | undefined>(undefined);
    const [certRagColor, setCertRagColor] = useState<RAGIndicatorColours>('green')
    const propertiesAPI = useInniAPI(Properties)
    
    const navigateToEntity = useNavigateToEntity()
    const isSaas = companyContext.product === CompanyProduct.isCap;
    const v8Styling = companyContext.company?.useV8UI || false;
    const [bannerProps, setBannerProps] = useState<DismissibleBannerProps>()
    const history = useHistoryWrapper();

    const [featureFocus, setFeatureFocus] = useState(false); // hide all header components, e.g. tabs and titles
    
    // set default tab
    useEffect(() => {
        if (v8Styling)
            setSelectedKey(PropertiesTabsEnum.DETAILS);
        else
            setSelectedKey(PropertiesTabsEnum.UNITS)
    }, [v8Styling])

    useEffect(() => {
        if (PropertyCertificateAPI && CertificateTypesAPI && !isLoaded) {
            CertificateTypesAPI.index(companyContext.cid)
                .then(res => {
                    setTypesData(res.data)
                });
                
            PropertyCertificateAPI.index(companyContext.cid, propertyId)
                .then(res => {
                    setCertData(res.data);                 
                });
        }
    }, [PropertyCertificateAPI, CertificateTypesAPI, isLoaded, companyContext.cid, propertyId])

    useEffect(() => {
        if(!propertyLoaded && defaultTab) {
            setSelectedKey(getTabName(defaultTab as PropertiesTabsEnum))
        }
    }, [defaultTab, propertyLoaded])

    // We calculate all expiry dates as soon as we have data - so we can use it to sort
    // Whenever certData changes we want to recalculate the expiryDates
    useEffect(() => {
        let tempData = certData;

        if (tempData && typesData && !isLoaded) {
            tempData?.map((i,n) => {
                const typeDetails = typesData?.find(j => j.id === i.certificateTypeId);
                if (!i.expiryDate && typeDetails && tempData && !i.archived) {
                    if (typeDetails.defaultValidityPeriod) {
                        const iDate = new Date(String(i.issueDate))
                        const year = iDate.getFullYear();
                        const month = iDate.getMonth();
                        const day = iDate.getDate();
                        const newDate = new Date(year + Number(typeDetails.defaultValidityPeriod.replaceAll('Y', '')), month, day);
                        tempData[n].expiryDate = asYearMonthDay(newDate);
                    }
                }
            })
            setCertData(certData)
            setIsLoaded(true);  
        }
        
    }, [certData, isLoaded, typesData])

    useEffect(() => {
        if (certData && typesData) {
            let worst = 1;
            certData.map(i => {          
                if (!i.archived && worst < i.ragStatus) {
                    worst = i.ragStatus
                }
            })
            setCertRagColor(worst === 1 ? 'green' : worst === 2 ? 'amber' : 'red')
        }
    }, [certData, certRagColor, typesData])

    const reloadDataCB = () => {
        setCertData(undefined)
        setIsLoaded(false)
    }
    const hasPermission = useHasPermission();


    const isMultiUnit = () => {
        //CAP 'Tenants' label only, but they do still have read permission for units and edit for property so check product instead
        // if(companyContext.product === CompanyProduct.isCap)
        //     return false;

        // Admin's can create new/multiple units
        if (hasPermission(Entity.Property, Action.Edit)[0])
            return true;

        if (property && property.lettableUnits && property.lettableUnits.length > 1)
            return true;

        return false;
    }

    const onEditClick = () => {
        setIsEditing(true);
    }

    const onDeleteClick = ( propertyId: number) => {
        if(propertiesAPI){
            propertiesAPI.deleteProperty(companyContext.cid, propertyId)
            .then(() =>{
                navigateToEntity(Entity.Property, Action.List)
            })
            .catch((ex) =>{
                console.error(ex)
            })
        }
    }

    const onEditClose = () => {
        setIsEditing(false);
        window.scrollTo(0, 0);
    }

    const checkMortgage = () => {
        if(mortgageChanged) {
            reloadProperty()
            setMortgageChanged(false)
        }
    } 

    function GetMenu() {
        let tabs: Array<TabItem>= [];
        if(companyContext && companyContext.navMenu && companyContext.navMenu.navItems){
            companyContext.navMenu.navItems.find(x => x.name === "Properties")?.v8TopNavTabs?.forEach((item) => {
                tabs.push({ eventKey: getTabName(item.name?.replaceAll(" ", "").toLowerCase() || ""), 
                            title: item.name === "Tenants and units" 
                                    ? isMultiUnit() ? "Tenants and units" : "Tenants"
                                    :  item.name || "", 
                            adminOnly: item.isAdminOnly 
                        });
            }); 
        }
        return tabs;
    }

    const onArchiveClick = (propertyId: number) => {
        if(propertiesAPI){
            propertiesAPI.archiveProperty(companyContext.cid, propertyId)
            .then(() =>{
                reloadProperty()
                setBannerProps({ type: 'success', body: `Property moved to archive`, showBanner: true })
                
            })
            .catch((ex) =>{
                console.error(ex)
            })
        }
    }

    const onUnarchiveClick = (propertyId: number) => {
        if(propertiesAPI){
            propertiesAPI.unarchiveProperty(companyContext.cid, propertyId)
            .then(() =>{
                reloadProperty()
                setBannerProps({ type: 'success', body: `Property removed from archive`, showBanner: true })
            })
            .catch((ex) =>{
                console.error(ex)
            })
        }
    }

    const handleTabSelect = (value: string | null) => {
        let activeTab = getTabName(value as PropertiesTabsEnum)
        setSelectedKey(activeTab)
        history.push(`/company/${companyContext.cid}/property/${propertyId}/${activeTab}`);
    };

    function getTabName(value: string): PropertiesTabsEnum {
        if (Object.values(PropertiesTabsEnum).includes(value as PropertiesTabsEnum))
            return (value as PropertiesTabsEnum)
        else 
            return v8Styling? PropertiesTabsEnum.DETAILS : PropertiesTabsEnum.UNITS
    }

    const PropertyContent=()=>{
        return(
            <>
                {false && selectedKey === PropertiesTabsEnum.DASHBOARD &&
                    <Suspense fallback={null}>
                        <PropertyDashboard propertyId={propertyId} />
                    </Suspense>   
                }
                {selectedKey === PropertiesTabsEnum.UNITS && property &&
                    <Suspense fallback={null}>
                        <PropertyUnits property={property} reload={reloadProperty} v8Styling={v8Styling} />
                    </Suspense>    
                }
                {selectedKey === PropertiesTabsEnum.DETAILS && property &&
                    <Suspense fallback={null}>
                        {checkMortgage()}
                        {(!isEditing
                            ?  (
                                accountsLoaded &&
                                    <PropertyDetails
                                        property={property}
                                        onEditClick={onEditClick}
                                        isSaas={isSaas}
                                        reloadProperty={reloadProperty}
                                        onDeleteClick={onDeleteClick}
                                        onArchiveClick={onArchiveClick}
                                        onUnarchiveClick={onUnarchiveClick}
                                    />
                            )
                            : accountsLoaded &&
                                <PropertyDetailsEdit
                                    id={propertyId}
                                    close={onEditClose}
                                    property={property}
                                    reloadProperty={reloadProperty}
                                    isExisting={true}
                                    accounts={accounts}
                                    isSaas={isSaas}
                                />
                        )}
                    </Suspense> 
                }
                {selectedKey === PropertiesTabsEnum.OWNERSHIP && property &&
                    <Suspense fallback={null}>
                        <PropertyOwnership propertyId={propertyId}/>
                    </Suspense>
                }
                {selectedKey === PropertiesTabsEnum.TRANSACTIONS && property &&
                    <Suspense fallback={null}>
                        <PropertyTransactions propertyId={propertyId} />
                    </Suspense>
                }
                {selectedKey === PropertiesTabsEnum.CERTIFICATES && property &&
                    <Suspense fallback={null}>
                        <PropertyCertificates
                            changeCertData={setCertData}
                            reloadCB={reloadDataCB}
                            isLoaded={isLoaded}
                            typesData={typesData}
                            certData={certData}
                            propertyId={propertyId}
                            setFeatureFocus={setFeatureFocus}
                        />
                    </Suspense>
                }
                {selectedKey === PropertiesTabsEnum.VALUATIONS && property &&
                    <Suspense fallback={null}>
                        <PropertyValuations propertyId={propertyId} v8Styling={v8Styling} reload={reloadProperty}/>
                    </Suspense>
                }
                {selectedKey === PropertiesTabsEnum.MORTAGAGE_LOANS &&
                    <Suspense fallback={null}>
                        <MortgageAccountViewer propertyId={propertyId} setMortgageChanged={() => setMortgageChanged(true)} v8Styling={v8Styling} />
                    </Suspense>
                }
                {selectedKey === PropertiesTabsEnum.PURCHASE_SALE && accountsLoaded && property &&
                    <Suspense fallback={null}>
                        <PropertyPurchaseAndSale
                            propertyId={property.id}
                            reloadProperty={reloadProperty}
                            initialValues={{saleCompletionDate: property.saleCompletionDate, purchaseCompletionDate: property.purchaseCompletionDate,
                                tradingStatus: property.tradingStatus}}
                            hasPurchaseStatement={property.completionStatementDocumentId !== undefined && property.completionStatementDocumentId !== null} 
                            hasSaleStatement={property.saleCompletionStatementDocumentId !== undefined && property.saleCompletionStatementDocumentId !== null}
                            saleStatementId={property.saleCompletionStatementId}
                            purchaseStatementId={property.purchaseCompletionStatementId}
                            canReplacePurchase={property.canReplacePurchaseStatement}
                            canReplaceSale={property.canReplaceSaleStatement}
                            purchaseStatementUrl={property.completionStatementDocumentUrl}
                            saleStatementUrl={property.saleCompletionStatementDocumentUrl}
                            allowAnyStatus={property.editability?.allowAnyStatus || false}
                        />
                    </Suspense>
                }
            
            </>

        )
           
    }

    return (
        <EmptyLayout entity={Entity.Property} title={property?.name || ' '} loading={!propertyLoaded}>
            { propertyLoaded && property && !featureFocus &&
                <PropertyHeader 
                    property={property} 
                    reloadProperty={reloadProperty} 
                    v8Styling={v8Styling} 
                    onDeleteClick={onDeleteClick} 
                    onArchiveClick={onArchiveClick} 
                    onUnarchiveClick={onUnarchiveClick} 
                />
            }

            { isSaas
                ? (
                    <div>
                        { !featureFocus && (
                            <PillTabs defaultActiveKey={selectedKey||""} onSelect={handleTabSelect}>
                                { GetMenu().map((tab) =>
                                    <Tab key={tab.eventKey} eventKey={tab.eventKey} title={tab.title}/>
                                    
                                )}
                            </PillTabs>
                        )}

                        { PropertyContent() }
                    </div>
                ) :
                (
                    <Tabs.Wrapper>
                        <Tabs.Tabs selectedKey={selectedKey} onChangeTab={setSelectedKey}>
                            <Tabs.Tab keyName={PropertiesTabsEnum.UNITS}>{isMultiUnit() ? 'Units & tenants' : 'Tenants'}</Tabs.Tab>
                            <Tabs.Tab hidden={isSaas} keyName="transactions">Transactions</Tabs.Tab>
                            <Tabs.Tab keyName={PropertiesTabsEnum.DETAILS}>Property details</Tabs.Tab>
                            {/* Load and calc the correct indicator colour how? Assume we want it to show up before the component is first clicked/opened */}
                            <Tabs.Tab hidden={isSaas} keyName={PropertiesTabsEnum.CERTIFICATES}><div style={{display: 'flex', justifyContent: 'space-between'}}>Safety and certificates <RAGIndicator size={15} color={certRagColor} /></div></Tabs.Tab>
                            <Tabs.Tab keyName={PropertiesTabsEnum.VALUATIONS}>Property valuations</Tabs.Tab>
                            <Tabs.Tab keyName={PropertiesTabsEnum.OWNERSHIP} hidden={!hasPermission(Entity.Ownership, Action.List)[0]}>Ownership</Tabs.Tab>
                            <Tabs.Tab keyName={PropertiesTabsEnum.MORTAGAGE_LOANS} hidden={!hasPermission(Entity.MortgageAccount, Action.List)[0]}>Mortgages and loans</Tabs.Tab>
                            <Tabs.Tab keyName={PropertiesTabsEnum.PURCHASE_SALE} hidden={!hasPermission(Entity.CompletionStatement, Action.Edit)[0]}>Purchase and sale</Tabs.Tab>
                        </Tabs.Tabs>

                        <Tabs.Content>
                            {PropertyContent()}
                        </Tabs.Content>
                    </Tabs.Wrapper>
                )
            }    

            <DismissibleBanner bannerProps={bannerProps} />

        </EmptyLayout>
    )
}