import { useContext, useEffect, useState } from 'react';
import { Employment, ManagePeopleDetails } from '../../api/inni/data-contracts';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { useInniAPI } from '../../hooks/useInniAPI';
import { Entity, Action } from '../../utils/EntityAction'
import { Shareholdings } from '../../api/inni/Shareholdings';
import { People as PeopleAPI } from '../../api/inni/People';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Toolbar from '../../layouts/Desktop/Toolbar';
import { Button } from '../../elements/Button/Button';
import { PersonAsDetailed } from '../../api/inni/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faEnvelope, faPencil, faUsers, faUserTie } from '@fortawesome/pro-regular-svg-icons';
import { useModalDialog } from '../../hooks/useModalDialog';
import DropdownButton from '../../elements/DropdownButton/DropdownButton';
import SetupP45Modal from './Components/Modals/SetupP45Modal';
import SetupP46Modal from './Components/Modals/SetupP46Modal';
import PayeViaMigrationSetupModal from './Components/Modals/PayeViaMigrationSetupModal';
import AddP45Modal from './Components/Modals/AddP45Modal';
import * as Tabs from '../../elements/VerticalTabs/VerticalTabs';
import styles from './People.module.scss'
import { PeopleLoading, PeopleLoadingCAPCompany } from './Components/PeopleLoading';
import PersonTabs from './Components/PersonTabs';
import PersonTabContent from './Components/PersonTabContent';
import { NoContentSlate } from '../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../hooks/terms/useDatagridTerms';
import { Container } from 'react-bootstrap';
import { SimpleTerm, useSimpleTerms } from "../../hooks/terms/useSimpleTerms";
// import { useOnboardingMessages } from '../../hooks/onboarding/useOnboardingMessages';
import PersonCard from './Components/PersonCard/PersonCard';
import BlankStateCard from '../../components/BlankStateCard/BlankStateCard';
import { SbBlokData, getStoryblokApi } from '@storyblok/react';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import { useHasPermission } from '../../hooks/useHasPermission';

export type TabTypes = 'employment' | 'overview' | 'shares' | 'benefits' | 'itr' | 'taxcode' | 'payslips' | 'pension' | 'statutorypayments'
type PayeModalTypes = 'p45' | 'p46' | 'viaMig' | 'viaMigNoPay' | 'updateP45' | undefined

export interface TabEditProps {
    editing : boolean,
    setEditing : (i : boolean) => void
}

export const People = () => {
    const companyContext = useContext(CompanyContext);
    const shareholdingsApi = useInniAPI(Shareholdings, [400]);
    const peopleApi = useInniAPI(PeopleAPI, [400]);
    const simpleTerms = useSimpleTerms();

    const [loaded, setLoaded] = useState(false);

    const [activePersonId, setActivePersonId] = useState<string>();
    const [people, setPeople] = useState<ManagePeopleDetails | undefined>();

    const [personDetails, setPersonDetails] = useState<PersonAsDetailed | undefined>(undefined)
    const [personDetailsLoaded, setPersonDetailsLoaded] = useState(false)

    const [curEmployment, setCurEmployment] = useState<Employment | undefined>(undefined)

    const [activeTab, setActiveTab] = useState<TabTypes>('overview')

    const [showModalDialog, modalDialog] = useModalDialog();
	const [disabled, setDisabled] = useState(false);
    // PAYE Setup modals hide/show
    const [payeModal, setPayeModal] = useState<PayeModalTypes>(undefined)

    const [editing, setEditing] = useState(false)

	// const [unreadPageMessages, setMessageRead, getOnboardingComponent] = useOnboardingMessages('manageemployees')

    const v8Styling = companyContext.company?.useV8UI || false

    const [blankSlateInfo, setBlankSlateInfo] = useState<SbBlokData>();
    const navigateToEntity = useNavigateToEntity();
    const hasPermission = useHasPermission();
    const hasDIYPerson = hasPermission(Entity.PersonV7, Action.Create)

    useEffect(()=>{
        const storyblokApi = getStoryblokApi();
        storyblokApi.get(hasDIYPerson[0] ?  'cdn/stories/blank-slate/person' : 'cdn/stories/blank-slate/person-advisory', { version: 'published'})
            .then(res => {
                setBlankSlateInfo(res.data.story.content)
            }).catch(err => {
                console.error(err)
            });
    },[])

    useEffect(() => {
        setEditing(false)
    }, [activeTab, activePersonId])

    useEffect(() => {
        if (!loaded && shareholdingsApi && peopleApi) {
            peopleApi.getForManagePeople(companyContext.cid).then(results => {
                if (results.data) {
                    if (activePersonId === undefined)
                        results.data.people && results.data.people[0] && setActivePersonId(results.data.people[0].id.toString())
                    setPeople(results.data)
                    setLoaded(true)
                }
            }).catch(error => console.error(error))
        }
    }, [shareholdingsApi, peopleApi, people, loaded, companyContext.cid, activePersonId])

    useEffect(() => {
        setPersonDetailsLoaded(false)
        if (personDetails && activePersonId && personDetails.id === parseInt(activePersonId)) {
            setPersonDetailsLoaded(true)
        } else if (peopleApi && activePersonId) {
            setPersonDetails(undefined)
            peopleApi.getAsDetailed(companyContext.cid, parseInt(activePersonId))
                .then(res => {
                    setPersonDetails(res.data)
                    setPersonDetailsLoaded(true)
                    // Switch to overview if the person doesn't have the current tab
                    // We don't switch automatically as it may be useful to compare data on a given tab w/ other people
                    if (((!res.data.employments || res.data.employments.length === 0) && activeTab === 'employment') ||
                        (activeTab === 'employment') || // since employment id's are unique to a person we don't want to show employment on person switch
                        (activeTab === 'pension' && !res.data.showPension) ||
                        (activeTab === 'statutorypayments' && !res.data.showStatPaymnets) ||
                        ((activeTab === 'taxcode' || activeTab === 'payslips') && !res.data.isActiveEmployee)
                    )
                        setActiveTab('overview')
                })
        }
    }, [activePersonId, activeTab, companyContext.cid, peopleApi, personDetails])

    const reloadPeople = () => {
        setLoaded(false)
        if (peopleApi && activePersonId) {
            peopleApi.getAsDetailed(companyContext.cid, parseInt(activePersonId))
                .then(res => {
                    setPersonDetails(res.data)
                    if (activeTab === 'employment' && curEmployment)
                        setCurEmployment(res.data.employments?.find(x => curEmployment.id === x.id))
                })
        }
    }

    const requestSAAtuh = () => {
        if (peopleApi) {
            peopleApi.requestAuthSa(companyContext.cid, parseInt(activePersonId || ''))
                .then(res => {
                    if (res.status === 200 && personDetails) {
                        setPersonDetails({ ...personDetails, showAuthRequest: false })
                    }
                })
        } else {
            console.error("Error requesting auth")
        }
    }

    const deletePerson = (id: number) => {
        if (peopleApi) {
            peopleApi.delete(companyContext.cid, {personId: id})
                .then(res => {
                    setActivePersonId(undefined)
                    setLoaded(false)
                })
                .catch((err) =>{
                    console.error(err)
                })
        } else {
            console.error("Error deleting person")
        }
    }

    const showSAAuthDialog = () => {
        showModalDialog(
            'Request SA auth?',
            `Are you sure you want to request SA auth?`,
            [
                <Button key="yesBtn" variant="danger" label="Yes" onClick={requestSAAtuh} />,
                <Button key="noBtn" variant="primary" outline label="No" onClick={() => { }} />,
            ],
            false
        );
    }

    const deleteEmployment = () => {
        if (peopleApi && activePersonId) {
            peopleApi.deleteEmployment(companyContext.cid, parseInt(activePersonId))
                .then(res => {
                    if (res.status === 200) {
                        reloadPeople()
                        setActiveTab('overview')
                    } else {
                        console.error('Error when deleting the employment (either person doesn\'t exist or employment doesn\'t)')
                    }
                })
        } else {
            console.error('activePersonId not set') 
        }
    }

    const showDeleteEmploymentDialog = () => {
        showModalDialog(
            'Delete employment?',
            `Are you sure you want to delete the current employment for ${personDetails?.name || 'unknown'}?`,
            [
                <Button key="yesBtn" variant="danger" label="Yes" onClick={deleteEmployment} />,
                <Button key="noBtn" variant="primary" outline label="No" onClick={() => { }} />,
            ],
            false
        );
    }

    const issueP45 = () => {
        if (peopleApi && activePersonId) {
            peopleApi.issueP45(companyContext.cid, parseInt(activePersonId))
                .then(res => {
                    if (res.status === 200) {
                        reloadPeople()
                    } else {
                        console.error("Error when issuing the P45")
                    }
                })
        } else {
            console.error("activePersonId not set")
        }
    }

    const showIssueP45Dialog = () => {
        showModalDialog(
            'Issue P45?',
            `Are you sure you want to issue a P45 for the latest employment of ${personDetails?.name || 'unknown'}?`,
            [
                <Button key="yesBtn" variant="danger" label="Yes" onClick={issueP45} />,
                <Button key="noBtn" variant="primary" outline label="No" onClick={() => { }} />,
            ],
            false
        );
    }

    const showDeleteDialog = (id?: number) => {
        showModalDialog(
            'Delete person?',
            `Are you sure you want to delete this person?`,
            [
                <Button key="yesBtn" variant="danger" label="Yes" onClick={() => deletePerson(id ? id : parseInt(activePersonId || '0'))} />,
                <Button key="noBtn" variant="primary" outline label="No" onClick={() => { }} />,
            ],
            false
        );
    }

    const handleAddButton = () =>{
        if(people && people.people && people.maxPeople && (people.maxPeople <= people.people?.length) && people.personEditabilityMetaData?.canAddNew){
            showOverrideAddMessage();
        }
        else{
            navigateToEntity(Entity.PersonV7, Action.Create);
        }
    }

    const showOverrideAddMessage = () => {
        showModalDialog(
            'Add person?',
            `This company's plan does not permit adding any more people. However, as an admin, you can override this restriction and add a new person anyway. Would you like to do this now?`,
            [
                <Button key="yesBtn" variant="primary" label="Yes" onClick={() => navigateToEntity(Entity.PersonV7, Action.Create)} />,
                <Button key="noBtn" variant="primary" outline label="No" onClick={() => { }} />,
            ],
            false
        );
    }

    const PersonInfo=(activePersonID:string, peopleDetails:ManagePeopleDetails)=>{

        return (
            <>
                <div className="mt-3 mb-4">
                    {/* Not really a good way of loading these / making these look good when moving between tabs */}
                    {/* We could show all of them all the time but disabling them may lead to some confusion - also would match old verion */}

                    {personDetails?.name && !v8Styling &&
                        <Button 
                            buttonType="delete" 
                            entity={Entity.PersonV7} 
                            action={Action.Delete} 
                            onClick={showDeleteDialog}
                            disabled={!personDetails?.editability?.canDelete}
                            disabledMessage={personDetails?.editability?.cantDeleteReason}
                            label={`Delete ${personDetails?.name}`}
                        />
                    }

                    {personDetails?.showAuthRequest && 
                        <Button
                            variant="dark"
                            entity={Entity.PersonV7}
                            action={Action.Edit}
                            onClick={showSAAuthDialog}
                            outline
                        >
                            <FontAwesomeIcon icon={faEnvelope} />Request SA auth
                        </Button>
                    }

                    {personDetails && personDetails.enablePayeVisible &&
                        <DropdownButton
                            disabled={personDetails.enablePayeDisabledReason !== null}
                            disabledMessage={personDetails.enablePayeDisabledReason}
                            entity={Entity.PersonV7}
                            action={Action.Edit}
                            variant="primary"
                            title={<><FontAwesomeIcon icon={faPencil} /> Enable PAYE</>}
                        >
                            <Dropdown.Item onClick={() => setPayeModal('p45')}>This employee has a P45 from previous employment</Dropdown.Item>
                            <Dropdown.Item onClick={() => setPayeModal('p46')}>This employee does not have a P45</Dropdown.Item>
                            {personDetails?.showMigration && <>
                                <Dropdown.Item onClick={() => setPayeModal('viaMig')}>Employee started in a previous Tax Year</Dropdown.Item>
                                <Dropdown.Item onClick={() => setPayeModal('viaMigNoPay')}>No pay or mileage between mig. and following 5th april</Dropdown.Item>
                            </>}
                        </DropdownButton>}
                    {personDetails && personDetails.showDeleteEmployment &&
                        <Button onClick={showDeleteEmploymentDialog} entity={Entity.PersonV7} action={Action.Edit} variant="danger">Delete employment</Button>}
                    {personDetails && personDetails.addP45Visible &&
                        <Button onClick={() => setPayeModal('updateP45')} variant="primary" entity={Entity.PersonV7} action={Action.Edit}>Add/update P45</Button>}
                    {personDetails && personDetails.displayIssueP45Button &&
                        <Button onClick={showIssueP45Dialog} variant="primary">Issue P45</Button>}
                    {/* {(activeTab === 'overview' || (activeTab === 'employment' && 
                        curEmployment?.id === personDetails?.employmentId) || activeTab === 'pension' || activeTab === 'itr' || activeTab === 'benefits') && 
                        <Button buttonType="edit" entity={Entity.PersonV7} action={Action.Edit} onClick={() => setEditing(true)} disabled={editing} />} */}

                    {/* <hr /> */}
                </div>

                <PersonTabs
                    disabled={disabled}
                    personDetails={personDetails}
                    editing={editing}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setCurEmployment={setCurEmployment}
                    curEmployment={curEmployment}
                />
                <PersonTabContent
                    setDisabled={setDisabled}
                    activePersonId={activePersonID}
                    activeTab={activeTab}
                    people={peopleDetails}
                    personDetails={personDetails}
                    personDetailsLoaded={personDetailsLoaded}
                    editing={editing}
                    setEditing={setEditing}
                    reloadPeople={reloadPeople}
                    setPersonDetails={setPersonDetails}
                    curEmployment={curEmployment}
                />
            </>
        );
    }

    return (
        <DefaultLayout
            title={
                v8Styling
                    ? "People"
                    : `${simpleTerms(SimpleTerm.MenuLabelPeople)} ${activePersonId ? '- ' +
                        people?.people?.find(x => x.id === parseInt(activePersonId || ''))?.name || '' : ''}`
            }
            entity={Entity.PersonV7}
            noPadding={true}
            useFullWidth
            subLayout={v8Styling}
        >
            { !v8Styling && (
                <Toolbar>
                    <Button
                        buttonType="new"
                        entity={Entity.PersonV7}
                        action={Action.Create}
                        disabled={people && people?.people?.length !== 0 && !people?.personEditabilityMetaData?.canAddNew }
                        disabledMessage={people?.personEditabilityMetaData?.canAddNewDisabledReason || ''} 
                    />
                </Toolbar>
            )}
            
            {/* { unreadPageMessages?.includes('info-boxes/onboarding/people_welcome') && (
                <div>
                    { getOnboardingComponent('people_welcome')}
                </div>
            )} */}

            {people && people.people && people.people.length === 0 && (
                v8Styling
                ? (
                    <BlankStateCard
                        content={blankSlateInfo}
                        loading={!blankSlateInfo}
                        buttonEntity={Entity.PersonV7}
                        buttonAction={Action.Create}
                    />
                )
                : (
                    <Container className="ml-0">
                        <NoContentSlate type={DatagridType.People} termsKey="emptyTerms" whiteBg />
                    </Container>
                )
            )}

            {!loaded && 
                (v8Styling ?  <PeopleLoadingCAPCompany/> : <PeopleLoading />
            )}

            {loaded&& activePersonId && people && people.people && people.people.length > 0  &&
                <>
                    {v8Styling &&
                        <>
                            <div className={styles.PeopleCards}>
                                {people && people?.people?.map(x =>
                                    <PersonCard 
                                        id={x.id}
                                        key={x.id}
                                        selected={x.id.toString()===activePersonId} 
                                        onClick={()=>{setActivePersonId(x.id.toString())}} 
                                        personName={x.name||""}
                                        avatarColor={x.id.toString()===activePersonId?'primary':'secondary'}
                                        v8Styling = {v8Styling}
                                        onDelete={() => showDeleteDialog(x.id)}
                                    />
                                )}
                     
                                <PersonCard 
                                    selected={false} 
                                    onClick={ handleAddButton}
                                    addNew
                                    disable={!people?.personEditabilityMetaData?.canAddNew}
                                    disabledReason={people?.personEditabilityMetaData?.canAddNewDisabledReason}
                                />
                            </div>
                    
                            {PersonInfo(activePersonId, people)}
                        </>   
                    }

                    {!v8Styling && 
                        <Tabs.Wrapper>
                            <Tabs.Tabs
                                selectedKey={activePersonId}
                                onChangeTab={setActivePersonId}
                                children={people?.people?.map(x =>
                                    <Tabs.Tab key={x.id} keyName={x.id.toString()}>
                                        {x.name}
                                        {(x.isDirector || x.isShareholder || x.isActiveEmployee) && 
                                        <div className={styles.tabIconContainer}>
                                            {/* Hide or disable??? */}
                                            {x.isDirector && 
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id={`directorTT_${x.id}`}>Director</Tooltip>}>
                                                <FontAwesomeIcon data-active={x.isDirector} icon={faUserTie} />
                                            </OverlayTrigger>}
                                            {x.isShareholder && 
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id={`directorTT_${x.id}`}>Shareholder</Tooltip>}>
                                                <FontAwesomeIcon data-active={x.isShareholder} icon={faCoins} />
                                            </OverlayTrigger>}
                                            {x.isActiveEmployee && 
                                            <OverlayTrigger placement="bottom" overlay={<Tooltip id={`directorTT_${x.id}`}>Employee</Tooltip>}>
                                                <FontAwesomeIcon data-active={x.isActiveEmployee} icon={faUsers} />
                                            </OverlayTrigger>}
                                        </div>}
                                    </Tabs.Tab>)} 
                            />

                            <Tabs.Content>
                                
                                {PersonInfo(activePersonId,people)}
                            </Tabs.Content>
                        </Tabs.Wrapper>
                    }
                </>
            }  

            {/* MODALS */}
            <SetupP45Modal onClose={() => setPayeModal(undefined)} personId={parseInt(activePersonId || '')} show={payeModal === 'p45'} person={personDetails} reloadPeople={reloadPeople} />
            <SetupP46Modal onClose={() => setPayeModal(undefined)} personId={parseInt(activePersonId || '')} show={payeModal === 'p46'} person={personDetails} reloadPeople={reloadPeople} />
            <PayeViaMigrationSetupModal
                migrationDate={people?.migrationDate}
                taxYearEndDate={people?.taxYearEndDate}
                onClose={() => setPayeModal(undefined)}
                personId={parseInt(activePersonId || '')}
                show={payeModal === 'viaMig' || payeModal === 'viaMigNoPay'}
                reloadPeople={reloadPeople}
                noPay={payeModal === 'viaMigNoPay'}
                person={personDetails}
            />
            <AddP45Modal person={personDetails} show={payeModal === 'updateP45'} personId={parseInt(activePersonId || '')} reloadPeople={reloadPeople} onClose={() => setPayeModal(undefined)} />
            {modalDialog}
        </DefaultLayout>
    )
}

export default People