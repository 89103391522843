export const GetHelpKeyForUrl = (url:string) => {
    //Take a url and remove any IDs using a regex to detect "/123/"
    //So /company/9386/property/4 becomes /company/property/
    const regex = /\/[0-9]+(\/|$)/g
    const numRemoved = url.replace(regex, "/")
    const normalised = numRemoved.endsWith("/") ? numRemoved.substring(0, numRemoved.length - 1).toLowerCase() : numRemoved.toLowerCase()

    //Special case, DLA, current and savings accounts use same page
    if(normalised.includes("issavings")) {
        return "businesssavingsaccounts";
    } 
    else if(normalised.includes("isloanaccount")) {
        return "directorsloan";
    } else {
        switch(normalised) {
            case "/company/dashboard":
                return "dashboard";
            case "/company/quickentry":
                return "time&expenses";
            case "/company/bookkeeping":
                return "time&expenses";
            case "/company/reporting":
                return "reports";
            default:
                return undefined;
        }
    }
}
