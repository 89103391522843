import { useContext, useEffect, useState } from 'react';
import CompanyContext from '../../context/CompanyContext';
import { Form, Formik, FormikHelpers, FieldArray } from "formik";
// API
import { useInniAPI } from '../../hooks/useInniAPI';
import { PvSatr } from '../../api/inni/PvSatr';
import { PvSatrProperty, PvSatrPropertyPostModel } from '../../api/inni/data-contracts';
// Components
import { BasicFormLayout } from '../../layouts/Desktop/BasicFormLayout';
import FormikField from "../../elements/FormikField/FormikField";
import { Button } from '../../elements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useStandaloneSATRStatus } from '../../hooks/useStandaloneSATRStatus';
import { PageAlert } from '../../components/Alerts/PageAlert';
import Icon from '../../elements/Icon/Icon';
import { lettingTypesToListOptions } from '../../utils/formatters/formatProperty';
import styles from './SATR.module.scss'
import classNames from 'classnames';

const PropertyDetails = () => {
    const companyContext = useContext(CompanyContext);
    const pvSatrApi = useInniAPI(PvSatr);

    const [properties, setProperties] = useState<PvSatrProperty[]>();
    const [propertiesLoaded, setPropertiesLoaded] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [showSavedMessage, setShowSavedMessage] = useState(false);

    const { pvSatrDetails, completeStep, nextStep } = useStandaloneSATRStatus();

    const SATRCompleted = pvSatrDetails?.progress?.setupSubmitted;
    const lettingOptions = lettingTypesToListOptions();

    useEffect(() => {
        if (pvSatrApi && !propertiesLoaded) {
            pvSatrApi?.listProperties(companyContext.cid).then(res => {
                setProperties(res.data)
                setPropertiesLoaded(true);
                setIsCreating(false);
                setIsDeleting(false);
            }).catch((e) => {
                console.error(e.error);
            })
        }
    }, [companyContext.cid, propertiesLoaded, pvSatrApi])

    const addProperty = (values: PvSatrPropertyPostModel, actions: FormikHelpers<PvSatrPropertyPostModel>) => {
        setIsCreating(true);
        setIsEditing(false);

        return new Promise((resolve, reject) => {
            if (pvSatrApi) {
                pvSatrApi.createProperty(companyContext.cid, values).then((res) => {
                    setIsCreating(true);
                    setPropertiesLoaded(false);
                    if (pvSatrDetails?.progress?.setupProperty)
                        setShowSavedMessage(true);

                    resolve(0);
                }).catch((e) => {
                    setIsCreating(false);
                    console.error(e.error)
                    reject(e.error);
                })
            } else {
                reject()
            }
        })
    }

    const removeProperty = (propertId: number) => {
        if (!isDeleting) {
            setIsDeleting(true);

            pvSatrApi?.deleteProperty(companyContext.cid, propertId).then(() => {
                setPropertiesLoaded(false);
                if (pvSatrDetails?.progress?.setupProperty)
                    setShowSavedMessage(true);
            }).catch((e) => {
                console.error(e.error);
            })
        }
    }

    const confirmProperties = () => {
        completeStep('SetupProperty');
    }

    const nextPage = () => {
        nextStep('SetupProperty');
    }

    return (
        <BasicFormLayout
            title="Properties"
            storyblokDescriptionId="properties-page"
        >
            {/* Existing properties */}
            <div className={properties ? styles.tableScroll : undefined}>
                { properties && (
                    <Formik
                        initialValues={properties}
                        enableReinitialize={true}
                        onSubmit={() => {}}
                    >
                        {({ values }) => (
                            <Form>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className={styles.largeColumn}>Property name</th>
                                            <th className={styles.mediumColumn}>Type</th>
                                            <th className={styles.smallColumn}>Ownership</th>
                                            <th className={styles.smallColumn}>Bought/Sold in the tax year</th>
                                            <th className={styles.verySmallColumn}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <FieldArray name="">
                                            {() => (
                                                <>
                                                    {values.map((property, index) => {
                                                        const canDelete = property.editability?.canDelete;
                                                        return (
                                                        <tr key={`property-${index}`}>
                                                            <td className={styles.largeColumn}>
                                                                <FormikField<PvSatrProperty>
                                                                    name={`[${index}].propertyName` as 'propertyName'}
                                                                    type="text"
                                                                    noPadding
                                                                    positionHack
                                                                    readonly
                                                                />
                                                            </td>
                                                            <td className={classNames(styles.mediumColumn, styles.selectContainer) }>
                                                                <FormikField<PvSatrProperty>
                                                                    name={`[${index}].lettingType` as 'lettingType'}
                                                                    type="select"
                                                                    option={lettingOptions.map((x) => {
                                                                        return {value: x.value||'', label: x.label}
                                                                    })}
                                                                    isTable
                                                                    readonly
                                                                />
                                                            </td>
                                                            <td className={styles.smallColumn}>
                                                                <FormikField<PvSatrProperty>
                                                                    name={`[${index}].ownershipPercentage` as 'ownershipPercentage'}
                                                                    type="number"
                                                                    prefix="%"
                                                                    noPadding
                                                                    readonly
                                                                />
                                                            </td>
                                                            <td className={styles.smallColumn}>
                                                                <FormikField<PvSatrProperty>
                                                                    name={`[${index}].boughtOrSoldInTaxyear` as 'boughtOrSoldInTaxyear'}
                                                                    type="checkbox"
                                                                    noPadding
                                                                    positionHack
                                                                    readonly
                                                                />
                                                            </td>
                                                            <td style={isDeleting ? {opacity: '0.25'} : {}}>
                                                                { !SATRCompleted && (
                                                                    canDelete
                                                                    ? (
                                                                        <FontAwesomeIcon
                                                                            icon={faTrashCan}
                                                                            className="deleteIcon ml-2"
                                                                            size='lg'
                                                                            onClick={() => removeProperty(property.id)}
                                                                        />
                                                                    )                                                                
                                                                    : (
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id={property.id.toString()}>
                                                                                    { property.editability?.cantDeleteReason }
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon={faTrashCan}
                                                                                className="deleteIcon ml-2"
                                                                                style={{opacity: '0.25'}}
                                                                                size='lg'
                                                                            />
                                                                        </OverlayTrigger>
                                                                    )
                                                                )}
                                                            </td>
                                                        </tr>
                                                    )})}
                                                </>
                                            )}
                                        </FieldArray>
                                    </tbody>
                                </table>
                            </Form>
                        )}
                    </Formik>
                )}

                {/* Add new property placeholder */}
                { (isCreating || !properties) && (
                    <table className="mt-2 mb-3">
                        <tbody>
                            <tr>
                                <td className={styles.largeColumn}><LoadingPlaceholder dark width="285px" height="35px" /></td>
                                <td className={styles.mediumColumn}><LoadingPlaceholder dark width="185px" height="35px" /></td>
                                <td className={styles.smallColumn}><LoadingPlaceholder dark width="135px" height="35px" /></td>
                                <td className={styles.smallColumn}><LoadingPlaceholder dark width="135px" height="35px" /></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                )}

                {/* Editing */}
                {((isEditing || properties?.length === 0) && !isCreating) && (
                    <Formik
                        initialValues={{propertyName: '', ownershipPercentage: 100, lettingType : 'ResidentialSingleUnit', boughtOrSoldInTaxyear: false} as PvSatrPropertyPostModel}
                        enableReinitialize
                        onSubmit={addProperty}
                        validate={() => {}}
                    >
                        {({ values, submitForm }) => (
                            <Form>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className={styles.largeColumn}>
                                                <FormikField<PvSatrPropertyPostModel>
                                                    name="propertyName"
                                                    type="text"
                                                    noPadding
                                                    positionHack
                                                    placeholder="New property name"
                                                />
                                            </td>
                                            <td className={classNames(styles.mediumColumn, styles.selectContainer)}>
                                                <FormikField<PvSatrPropertyPostModel>
                                                    name="lettingType"
                                                    type="select"
                                                    option={lettingOptions.map((x) =>{
                                                        return {value: x.value||'', label: x.label}
                                                    })}
                                                    isTable
                                                />
                                            </td>
                                            <td className={styles.smallColumn}>
                                                <FormikField<PvSatrPropertyPostModel>
                                                    name="ownershipPercentage"
                                                    type="number"
                                                    prefix="%"
                                                    noPadding
                                                    min="0"
                                                    max="100"
                                                />
                                            </td>
                                            <td className={styles.smallColumn}>
                                                <FormikField<PvSatrPropertyPostModel>
                                                    name="boughtOrSoldInTaxyear"
                                                    type="checkbox"
                                                    noPadding
                                                />
                                            </td>
                                            <td className={classNames(styles.mediumColumn, styles.showAddButtonColumn) }>
                                                <Button
                                                    variant="primary"
                                                    label="Add"
                                                    onClick={submitForm}
                                                    disabled={values.propertyName === '' ||
                                                        values.ownershipPercentage <= 0 ||
                                                        values.ownershipPercentage > 100
                                                    }
                                                />

                                                { properties?.length !== 0 && (
                                                    <Button
                                                        variant="secondary"
                                                        label="Cancel"
                                                        onClick={() => setIsEditing(false)}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                        <tr className={styles.showAddButtonRow}>
                                            <td className={styles.mediumColumn}>
                                                <Button
                                                    variant="primary"
                                                    label="Add"
                                                    onClick={submitForm}
                                                    disabled={values.propertyName === '' ||
                                                        values.ownershipPercentage <= 0 ||
                                                        values.ownershipPercentage > 100
                                                    }
                                                />

                                                { properties?.length !== 0 && (
                                                    <Button
                                                        variant="secondary"
                                                        label="Cancel"
                                                        onClick={() => setIsEditing(false)}
                                                    />
                                                )}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
            {/* Enable editing */}
            { (!SATRCompleted && !isEditing && properties && properties?.length > 0) && (
                <div className="addNewDashedBox" style={{ maxWidth: '800px', marginTop: '10px' }} onClick={() => {setIsEditing(true)}}>
                    <FontAwesomeIcon icon={faCirclePlus} className='mr-2' color='var(--feature-green)' size='lg' />Add a property
                </div>
            )}

            { properties?.length === 0 && (
                <p className="m-0 text-muted"><small>Please add a property to continue</small></p>
            )}

            {/* Buttons */}
            { pvSatrDetails?.progress?.setupSubmitted 
                // SATR submitted
                ? (
                    <>
                        <Alert className="mt-5" variant="info">
                            <b>You have completed your tax return. Please contact us if you need to make changes.</b>
                        </Alert>

                        <Button 
                            variant="primary" 
                            onClick={nextPage}
                            marginTop 
                            thin
                        >
                            Continue <Icon name='arrowNext' />
                        </Button>
                    </>
                )
                // SATR in progress
                : (
                    <>
                        { pvSatrDetails?.progress?.setupProperty
                            ? (
                                <>
                                    <Alert className="mt-5" variant="info">
                                        <b>You have already confirmed your properties, but you can still make changes.</b>
                                    </Alert>
                                    <Button 
                                        variant="primary" 
                                        onClick={nextPage}
                                        marginTop 
                                        thin
                                    >
                                        Continue <Icon name='arrowNext' />
                                    </Button>
                                </>
                            )
                            : (
                                <div className="mt-3">
                                    <Button
                                        variant="primary"
                                        label="Confirm properties"
                                        onClick={confirmProperties}
                                        marginTop
                                        disabled={isCreating || isDeleting || properties?.length === 0}
                                    />
                                </div>
                            )
                        }
                    </>
                )
            }

            <PageAlert
                ignoreSidebar={true}
                variant="success"
                showAlert={showSavedMessage}
                message="Property details updated."
                hideAlert={() => { setShowSavedMessage(false) }}
            />
        </BasicFormLayout>
    )
}

export default PropertyDetails;