import { useState, cloneElement, ReactElement, ReactFragment, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '../elements/Button/Button';
import useIsMobile from './useIsMobile';
import { BrandWrapper } from '../components/BrandWrapper/BrandWrapper';

/**
 * Customisable modal dialog. Pass showModalDialog() title, body and array of buttons.
 * Optional 'close' argument to display default close button.
 * @returns [showModalDialog() - to init/show instance AND JSX element - put in the render of your component]
 */
export const useModalDialog = (): [
  (
    title: string,
    body: React.ReactFragment,
    buttons: ReactElement[],
    close?: boolean,
    size?: 'sm' | 'lg' | 'xl',
    onHide?: () => void,
  ) => void,
  JSX.Element,
  boolean
] => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState<React.ReactFragment>('');
  const [showClose, setShowClose] = useState<boolean>(false);
  const [buttons, setButtons] = useState<ReactElement[]>();
  const isMobile = useIsMobile();
  const [size, setSize] = useState<undefined | 'sm' | 'lg' | 'xl'>()
  const onHideCallbackRef = useRef<(() => void) | undefined>(undefined);

  // Tried assigning this an interface but didn't seem to work on nested function - DD
  const showModalDialog = (
    title: string,
    body: React.ReactFragment,
    buttons: ReactElement[],
    close: boolean = false,
    size?: 'sm' | 'lg' | 'xl',
    onHide?: () => void,
  ) => {
    setShow(true);
    setTitle(title);
    setBody(body);
    setButtons(buttons)
    setShowClose(close);
    setSize(size)
    onHideCallbackRef.current = onHide;
  };

  const handleOnHide = () => {
    if (onHideCallbackRef.current)
    {
      onHideCallbackRef.current();
    }   
    setShow(false);
    
  };

  const toRender = (
    <Modal show={show} onHide={handleOnHide} centered data-cy="useModalHook" size={size}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>{body}</Modal.Body>

      <Modal.Footer>
        <div className="d-flex w-100">
          <BrandWrapper>
            {/* Render buttons with added show=false onClick */}
            {
              buttons?.map((bttn, i) => {
                return cloneElement(
                  bttn,
                  {
                    key: `button-${i}`,
                    onClick: () => { bttn.props.onClick(); setShow(false); },
                    mobile: isMobile
                  },
                  null)
              })
            }
            {showClose && (
              <Button variant="light" onClick={() => setShow(false)} mobile={isMobile}>
                Close
              </Button>
            )}
          </BrandWrapper>
        </div>
      </Modal.Footer>
    </Modal>
  );

  return [showModalDialog, toRender, show];
};
