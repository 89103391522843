/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { QuickEntriesPaged } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class QuickEntries<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags QuickEntries
   * @name Get
   * @request GET:/api/v2/companies/{companyId}/quickentries
   * @secure
   */
  get = (
    companyId: number,
    query?: {
      limit?: number;
      startI?: number;
      showMileages?: boolean;
      showExpenses?: boolean;
      showTimes?: boolean;
      employeeId?: number;
      start?: string;
      end?: string;
      description?: string;
      billable?: number;
      contractId?: number;
      year?: number;
      month?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<QuickEntriesPaged, any>({
      path: `/api/v2/companies/${companyId}/quickentries`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
