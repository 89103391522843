/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LettingStatement, LettingStatementPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class LettingStatements<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags LettingStatements
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/lettingStatements
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<LettingStatement[], any>({
      path: `/api/v2/companies/${companyId}/lettingStatements`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LettingStatements
   * @name GetOne
   * @request GET:/api/v2/companies/{companyId}/lettingStatements/{id}
   * @secure
   */
  getOne = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<LettingStatement, any>({
      path: `/api/v2/companies/${companyId}/lettingStatements/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LettingStatements
   * @name UploadLettingStatement
   * @request POST:/api/v2/companies/{companyId}/lettingStatements/{lettingStatementId}/uploadLettingStatement
   * @secure
   */
  uploadLettingStatement = (
    companyId: number,
    lettingStatementId: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/lettingStatements/${lettingStatementId}/uploadLettingStatement`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LettingStatements
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/lettingStatements/ValidateUpdate
   * @secure
   */
  validateUpdate = (
    companyId: number,
    query: { lettingStatementId: number },
    pm: LettingStatementPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/lettingStatements/ValidateUpdate`,
      method: "PUT",
      query: query,
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags LettingStatements
   * @name UpdateLettingStatement
   * @request PUT:/api/v2/companies/{companyId}/lettingStatements/{lettingStatementId}
   * @secure
   */
  updateLettingStatement = (
    companyId: number,
    lettingStatementId: number,
    pm: LettingStatementPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/lettingStatements/${lettingStatementId}`,
      method: "PUT",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags LettingStatements
   * @name MarkAsDetailsCompleted
   * @request PUT:/api/v2/companies/{companyId}/lettingStatements/markcompleted/{lettingStatementId}
   * @secure
   */
  markAsDetailsCompleted = (companyId: number, lettingStatementId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/lettingStatements/markcompleted/${lettingStatementId}`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags LettingStatements
   * @name ForceSave
   * @summary Mark the Letting statement as completed even the lines are not equal to the net payment to landlord
   * @request PUT:/api/v2/companies/{companyId}/lettingStatements/ForceSave/{lettingStatementId}
   * @secure
   */
  forceSave = (companyId: number, lettingStatementId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/lettingStatements/ForceSave/${lettingStatementId}`,
      method: "PUT",
      secure: true,
      ...params,
    });
}
