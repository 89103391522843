import React from 'react';
import { CompanySummary } from '../api/inni/data-contracts';
 
export interface User {
    firstName: string,
    lastName: string,
    fullName: string,
    email: string,
    oid: string
    contactId: number,
}

export interface UserContextInterface {
    user?: User,
    getAccessToken: () => Promise<[string, Date | null] | null>,
    logout?: () => Promise<void>,
    displayHttpResponseError : (error: Response) => void,
    errorMessage?: string,
    customErrorMessage?: string,
    clearErrorMessage?: () => void
}

const UserContext = React.createContext<UserContextInterface>({
    getAccessToken: () => (new Promise(r => r(null))),
    displayHttpResponseError: (error: Response) => {}
});

export default UserContext;


