/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { YearEnd } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class YearEnds<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags YearEnds
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/admin/YearEnds
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<YearEnd[], any>({
      path: `/api/v2/companies/${companyId}/admin/YearEnds`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags YearEnds
   * @name Close
   * @summary Closes the earliest year end
   * @request POST:/api/v2/companies/{companyId}/admin/YearEnds/Close
   * @secure
   */
  close = (companyId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/admin/YearEnds/Close`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags YearEnds
   * @name Open
   * @summary Reopens the last closed YE
   * @request POST:/api/v2/companies/{companyId}/admin/YearEnds/Open
   * @secure
   */
  open = (companyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/admin/YearEnds/Open`,
      method: "POST",
      secure: true,
      ...params,
    });
}
