import _ from 'lodash';
import { useState, useEffect, RefObject } from 'react';
import { Pagination } from 'react-bootstrap';

export function usePagination<T>(data : any[], iPerPage : number, ref?: RefObject<HTMLDivElement>, sortKey?: keyof T | undefined, p1OnChange: boolean = true, showAllButton: boolean = false) {

    const [currentPage, setCurrentPage] = useState(1);
    const [showAll, setShowAll] = useState(false)
    const maxPage = Math.ceil(data.length / iPerPage);
    const controls: JSX.Element[] = [];

    function getCurrentData() {
        const start = (currentPage - 1) * iPerPage;
        const end = start + iPerPage;
        if (sortKey) {
            return _.sortBy(data, sortKey).slice(start,showAll ? undefined : end);
        } else {
            return data.slice(start,end);
        }
        
    }

    function setPage(num : number) {
        if (ref && ref.current) {
            ref.current.scrollIntoView({ behavior: 'auto' });
        }
        else{
            window.scrollTo(0, 0);
        }
        
        setCurrentPage(num)
    }

    function next() {
        if (currentPage + 1 <= maxPage) {
            if (ref && ref.current) {
                ref.current.scrollIntoView({ behavior: 'auto' });
            }
            else{
                window.scrollTo(0, 0);
            }
            setCurrentPage(currentPage + 1)
        }        
    }

    function prev() {
        if (currentPage !== 1) {
            if (ref && ref.current) {
                ref.current.scrollIntoView({ behavior: 'auto' });
            }
            else{
                window.scrollTo(0, 0);
            }
            setCurrentPage(currentPage - 1)
        }
    }

    function getControls() {
        // Generate page controls if the data is long enough, if it isn't then empty array is returned
        if (controls.length === 0 && data.length > iPerPage) {
            controls.push(<Pagination.Prev key={0} onClick={prev} disabled={currentPage === 1} />)
            if (!showAll ) {
                let initial = currentPage - 5 > 0 ? currentPage - 5 : 1;
                let end = initial + 9 < maxPage ? initial + 9 : maxPage;

                if (initial - end < 10) {initial = (end-9 < 1 ? 1 : end-9)}

                for (let number = initial; number <= end; number++) {
                    controls.push(
                        <Pagination.Item onClick={() => setPage(number)} key={number} active={number === currentPage}>
                            {number}
                        </Pagination.Item>
                    );
                }
            } else {
                controls.push(
                    <Pagination.Item onClick={() => setPage(1)} active>
                        1
                    </Pagination.Item>
                );
            }
            controls.push(<Pagination.Next key={maxPage+1} onClick={next} disabled={currentPage === maxPage} />)
            if (showAllButton) controls.push(<Pagination.Item key="showAllBtn" active={showAll} onClick={() => {setShowAll(!showAll); setCurrentPage(1)}}>Show all</Pagination.Item>)
            // Show current position/count within the data
            controls.push(<p key="count" className="text-muted small" style={{margin: 'auto 10px'}}>
                {iPerPage*(currentPage-1)+1}-{showAll ? data.length : iPerPage*currentPage > data.length ? data.length : iPerPage*currentPage} of {data.length}
            </p>)

            
        }
        
        return controls;
    }

    // Set current page to 1 when the data prop is changed
    useEffect(() => {
        if (p1OnChange) setCurrentPage(1);
    }, [data, p1OnChange])

    return {next, prev, setPage, getCurrentData, getControls, maxPage, currentPage}

}