import { useParams } from 'react-router-dom';
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout"
import * as DataGrid from '../../elements/DataGrid/DataGrid';
import { Entity } from "../../utils/EntityAction";
import { FactFinding } from "./FactFindingForm";
import { Alert, Fade } from "react-bootstrap";
import Toolbar from "../../layouts/Desktop/Toolbar";
import { Button } from "../../elements/Button/Button";
import { FormikHelpers } from "formik";
import { useInniAPI } from "../../hooks/useInniAPI";
import { useEffect, useState } from "react";
import { FactFindingForm, FactFindingFormPostModel, FactFindingSummary } from "../../api/inni/data-contracts";
import { ContactConsultations } from "../../api/inni/ContactConsultations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder/LoadingPlaceholder";
import { DatagridType } from "../../hooks/terms/useDatagridTerms";
import { NoContentSlate } from "../../elements/Slates/NoContentSlate";


interface RouteParams {
  contactId: string,
}

export const UserConsultations = () => {
    const consultationsApi = useInniAPI(ContactConsultations, [400]);
    const contactId = parseInt(useParams<RouteParams>().contactId);
    const [form, setForm] = useState<FactFindingForm>();
    const [consultationUrl, setConsultationUrl] = useState<string>();
    const [previousConsultations, setPreviousConsultations] = useState<FactFindingSummary[]>();
    const [loaded, setLoaded] = useState(false);
    const [showSaveMessage, setShowSaveMessage] = useState(false);
    const [saveMessage, setSaveMessage] = useState('Saved');

    const onSubmit =  (values: FactFindingFormPostModel, actions: FormikHelpers<FactFindingForm>) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if(!values.isDraft) {
                actions.validateForm().then((res) => {
                    if(Object.keys(res).length === 0) {
                        resolve(submitForm(values, actions))
                    } else {
                        resolve(false)
                    }
                })
            } else {
                resolve(submitForm(values, actions))
            }
        })
    }

    const submitForm = (values: FactFindingFormPostModel, actions: FormikHelpers<FactFindingForm>) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (consultationsApi && form) {
                consultationsApi.updateForm(contactId, form.id, values)
                .then(res => {
                    if(res.status !== 200)
                        console.warn("Unexpected response code " + res.status)

                    if(res.status === 200) {
                        setForm({...form, ...values, ragStatus: !values.isDraft ? "A" : "R"})
                        if (values.isDraft) {
                            setSaveMessage('Saved');
                        } else {
                            setSaveMessage('Submitted, thank you');
                        }
                        setShowSaveMessage(true)
                        setTimeout(() => {
                            setShowSaveMessage(false)
                        }, 2500);
                    }
                        
                    resolve(res.status === 200);
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } 
            else {
                reject();
            }
        })
    }

    const bookConsultation = () => {
        if (consultationUrl) {
            window.open(consultationUrl, '_blank', 'noopener');
          } else {
            console.warn('Failed to get consultation link');
          }
    }

    useEffect(() => {
        if (consultationsApi && !loaded ) {
            consultationsApi.getFactFindingForm(contactId).then(results => {
                if (results.data) {
                    setForm(results.data)
                }
                consultationsApi.factFindingSummaries(contactId).then(results => {
                    if (results.data) {
                        setPreviousConsultations(results.data.sort((val, val2) => {
                            if(val.dateAndTime && val2.dateAndTime) {
                                //Value comes in as "15/10/2021 at 15:30" - We need to get just the date and reverse it to yyyy/mm/dd
                                return Date.parse(val.dateAndTime.substr(0, 10).split("/").reverse().join()) < Date.parse(val2.dateAndTime.substr(0, 10).split("/").reverse().join()) ? 1 : -1
                            } else {
                                return 0
                            }
                        }))
                    }
                    setLoaded(true)
                }).catch(error => console.error(error))
            }).catch(error => console.error(error))

            consultationsApi.getConsultationUrl(contactId).then(results => {
                if (results.data) {
                    setConsultationUrl(results.data)
                }
            }).catch(error => console.error(error))
        }
    }, [consultationsApi, loaded, contactId])
    return (
        <DefaultLayout title="Consultations" entity={Entity.PortalConsultations}>
            {!form && consultationUrl &&
                <Toolbar>
                    <Button label="Book a consultation" variant="primary" onClick={bookConsultation}/>
                </Toolbar>
            }
            <div style={{margin: '0 auto'}}>
                { (form && form.nextConsultationTitle)
                    ? ( 
                        <>
                            <div style={{margin: '1rem 0 3rem 0', textAlign: 'center', width: '100%'}}>
                                {form.staffImg && <img style={{display:'inline-block', height: '80px', width: '80px', borderRadius: '50%', backgroundColor: '#bbb'}} src={form.staffImg}/>}
                                <h2 style={{borderBottom: 'none', marginTop: '0.75rem', padding: '0', fontSize: '24px'}}>{form && form.nextConsultationTitle}</h2>
                            </div>
                            {<Alert variant={form.ragStatus === "G" ? "success" : form.ragStatus === 'A' ? "info" : "warning"} style={{marginTop: "1em"}}>
                                {form.ragStatus === "G" ? 
                                    "Your fact finding form has been reviewed and completed" : 
                                form.ragStatus === "A" ? 
                                    "Thanks for submitting" : 
                                    "Please ensure this form is complete before your consultation"}
                            </Alert>}
                            <h2>Details for this consultation</h2>
                            <FactFinding onSubmit={onSubmit} formData={form}/>
                            <Fade in={showSaveMessage}>
                                <p style={{marginTop: '1rem', marginBottom: '0', position: 'absolute', color: 'var(--success)'}}>
                                    <FontAwesomeIcon icon={faCheckCircle} style={{display: 'inline-block', marginRight: '0.25rem'}}/>
                                    { saveMessage }
                                </p>
                            </Fade>
                        </>
                    )
                    : (
                        <div style={{textAlign: 'center'}}>
                            <FontAwesomeIcon icon={faCalendarAlt} className="text-muted" style={{display: 'inline-block', marginRight: '10px', fontSize: '18px'}}/>
                            <h5 className="py-5 text-muted d-inline-block">You have no upcoming consultation</h5>
                        </div>
                    )
                }

                {<div style={{marginTop: `${form && form.nextConsultationTitle ? "5rem" : "1rem"}`}}>
                    <h2>Previous consultations</h2>
                    <DataGrid.Table>
                        <thead>
                            <tr>
                                <th style={{width:'20%'}}>Date</th>
                                <th style={{width:'70%'}}>Description</th>
                                <th style={{width:'10%'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (previousConsultations && previousConsultations.length > 0) && previousConsultations.map((consultation) => 
                                    <DataGrid.EntityNavigateRow key={consultation.id} entity={Entity.PortalConsultations} extraParams={{rid: consultation.id}}>
                                        <td style={{textAlign: 'left'}}>{consultation.dateAndTime?.substring(0, 10)}</td>
                                        <td style={{textAlign: 'left'}}>{consultation.title}</td>
                                        <td style={{textAlign: 'right', color: 'var(--link-blue-color)'}}>View</td>
                                    </DataGrid.EntityNavigateRow>
                                )
                            }
                            {(!previousConsultations || previousConsultations.length === 0) && loaded &&
                                <tr><td colSpan={3} style={{padding: 0}}>
                                    <NoContentSlate type={DatagridType.Consultations} termsKey='emptyTerms' />     
                                </td></tr>
                            }
                            {!loaded &&
                                <tr><td colSpan={3}><LoadingPlaceholder/></td></tr>
                            }
                        </tbody>
                    </DataGrid.Table>
                </div>}
            </div>
        </DefaultLayout>
    )
}