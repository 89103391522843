import { Form, Formik, FormikErrors, FormikHelpers } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { ConfirmPagePostModel, Person, SetupWizardForm, VatSetting } from '../../../api/inni/data-contracts'
import { SetupWizard } from '../../../api/inni/SetupWizard'
import CompanyContext from '../../../context/CompanyContext'
import { Button } from '../../../elements/Button/Button'
import { Switch } from '../../../elements/EditRow/EditRow'
import { useInniAPI } from '../../../hooks/useInniAPI'
import styles from '../SetupWizard.module.css'
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { VatSettings } from '../../../api/inni/VatSettings'
import { asYearMonthDay, formatDate } from '../../../utils/formatDate'
import { formatCurrency, formatPercentWithoutSymbol } from '../../../utils/formatNumbers'
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList'
import { People } from '../../../api/inni/People'
import { useModalDialog } from '../../../hooks/useModalDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity'
import { Entity } from '../../../utils/EntityAction'

interface BSFormat {
    msg: string,
    disabled: boolean
}

const ConfirmChanges = ({formState, setPrevPage, setFormState, reloadData} : {formState : SetupWizardForm, setPrevPage : () => void, setFormState : (i : SetupWizardForm) => void, reloadData : () => void}) => {

    const swAPI = useInniAPI(SetupWizard)
    const vatSettingsAPI = useInniAPI(VatSettings)
    const companyContext = useContext(CompanyContext)

    const [employees, employeesLoaded] = useFetchEntityList<Person, People>(People)

    const [vatSetting, setVatSetting] = useState<VatSetting | undefined>(undefined)
    const [vatSettingLoaded, setVatSettingLoaded] = useState(false)

    const [showModalDialog, modalDialog] = useModalDialog();

    const navToEntity = useNavigateToEntity()

    useEffect(() => {
        if (vatSettingsAPI && !vatSettingLoaded) {
            vatSettingsAPI.getAtDate(companyContext.cid, { date : asYearMonthDay(new Date()) })
            .then(res => {
                setVatSetting(res.data)
                setVatSettingLoaded(true)
            })
        }
    }, [companyContext.cid, vatSettingLoaded, vatSettingsAPI])

    const editCall = (values: ConfirmPagePostModel, actions: FormikHelpers<ConfirmPagePostModel>) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (swAPI) {
                swAPI.updateConfirmPage(companyContext.cid, values)
                .then(res => {
                    if (res.status === 200) {
                        setFormState({...formState, ...values})
                    }
                })
            }
        })
    }

    const validateEditCall = (values : ConfirmPagePostModel) : Promise<FormikErrors<ConfirmPagePostModel>> => {
        return new Promise<FormikErrors<ConfirmPagePostModel>>((resolve, reject) => {
            if (swAPI) {
                swAPI?.validateUpdateConfirmPage(companyContext.cid, values).then(x => {
                    resolve({})
                }).catch(err => resolve(err.error))
            } else {
                reject()
            }            
        })
    }

    const showResetDialog = () => {
        showModalDialog(
            'Reset setup wizard?',
            'Are you sure you want to reset the setup wizard? This will undo whatever seems safe to do - eg payroll will be reset if no pay runs have happened. For more extensive undoing, contact support',
            [
                <Button variant="danger" label="Yes" onClick={resetCall} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const resetCall = () => {
        if (swAPI) {
            swAPI.resetWizard(companyContext.cid)
            .then(res => {
                if (res.status === 200) {
                    reloadData()
                }
            })
        }
    }

    const vatReturnsText = () => {
        let vatStatusText = ''
        if (formState.setupStartVAT === undefined) {
            vatStatusText = 'You cannot confirm your VAT returns as you have not provided the date of your VAT periods'
        } else {
            if (formState.setupStartVAT === formState.setupMigrationDate) {
                vatStatusText = 'I have entered my VAT return details'
            } else {
                vatStatusText = ''
                if (formState.isUnpaidVatReturn)
                    vatStatusText += `There is an unpaid VAT return for 
                        ${(formState.unpaidVATReturnAmount || 0) === 0 ? 'an unknown amount' : formatCurrency(formState.unpaidVATReturnAmount)}
                        which does not appear on my bank statement at the migration date`
                if (formState.isUnpaidVatReturn && formState.vatNotOnAReturn > 0)
                    vatStatusText += ' and '
                if (formState.vatNotOnAReturn > 0)
                    vatStatusText += `I have a VAT balance of ${formatCurrency(formState.vatNotOnAReturn)} due to HMRC which I have not yet included on a return.`
            }
        }
        return vatStatusText
    }

    const payePaymentsText = () => {
        let payePaymentsStatusText = ''
        payePaymentsStatusText += `I have paid ${
            formState.unpaidPayePayment ? 
                formState.unpaidPayePayment !== 0 ? formatCurrency(formState.unpaidPayePayment) : 'NOTHING' 
                : 'SOMETHING'} to HMRC which does not appear on my bank statement at the migration date and `
        if (formState.payeNotSent === 0)
            payePaymentsStatusText += 'I have NO payment due to HMRC which I have not yet paid'
        else
            payePaymentsStatusText += `I have a payment of ${formatCurrency(formState.payeNotSent)} due to HMRC which I had not paid at the migration date.`
        
        return payePaymentsStatusText
         
    } 

    const calcTotals = () => {
        return Math.round((formState.setupOpeningBalances?.reduce((a,b) => a + (b.amount || 0), 0) || 0) * 100) / 100
    }

    const balanceSheetFormat = (values : ConfirmPagePostModel) : BSFormat => {
        if (!values.setupCoInfoComplete)
            return {
                msg: 'You cannot confirm the balance sheet as you have not confirmed your company details',
                disabled: true
            }
        else if (!values.setupPAYEPayments)
            return {
                msg: 'You cannot confirm the balance sheet until you have confirmed that your PAYE details',
                disabled: true
            }
        else if (!values.setupAssets)
            return {
                msg: 'You cannot confirm the balance sheet until you have confirmed your company\'s assets',
                disabled: true
            }
        else if (!values.setupUnpaid)
            return {
                msg: 'You cannot confirm the balance sheet until you have confirmed that your outstanding payments are complete',
                disabled: true
            }
        else 
            if (calcTotals() === 0)
                return {
                    msg: 'I have correctly entered my balance sheet',
                    disabled: false
                }
            else
                return {
                    msg: 'You cannot confirm the balance sheet as it has not been entered correctly',
                    disabled: true
                }
    }

    return (
        <div>
            {(formState.setupCoInfoComplete && formState.setupVATSettings 
            && formState.setupPayroll && formState.setupEmployees && formState.setupVATAdjPP 
            && formState.setupPAYEPayments && formState.setupUnpaid 
            && formState.setupAssets && formState.setupBS) ?
                <>
                    <h1>Confirm changes</h1>
                    <hr />
                    <p>All setup wizard sections have been completed. To make any changes either reset below, or make them elsewhere in the software. Use the buttons below to reset or exit the wizard</p>
                    <div className={styles.formButtons}>
                        <Button onClick={showResetDialog} variant="primary">Reset</Button>
                        <Button variant="primary" onClick={() => navToEntity(Entity.CompanyDashboard)}>Exit wizard</Button>
                    </div>
                </>
            :
            <Formik
                onSubmit={editCall}
                initialValues={formState as ConfirmPagePostModel}
                validate={validateEditCall}
                enableReinitialize
            >
                {({ values }) => (
                    <Form>
                        <p style={{cursor: 'pointer'}} onClick={setPrevPage}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
                        <h1>Confirm changes</h1>
                        <hr />
                        <p>Please review the following details. If you are satisfied that the information is accurate, confirm the changes. Once you have confirmed the changes, the information cannot be changed.</p>
                        <p>RESET: Resetting the wizard will undo whatever seems safe to do - eg payroll will be reset if no pay runs have happened. For more extensive undoing, contact support</p>
                        {!formState.setupCoInfoComplete &&
                        <>
                            <Switch<ConfirmPagePostModel> name="setupCoInfoComplete" label="Confirm company details" />
                            {formState.setupWizardType !== 0 && <>I have selected {formatDate(formState.setupMigrationDate)} as my migration date.</>}
                            <br />My financial year is {formatDate(formState.fyStartDate)} - {formatDate(formState.fyEndDate)}
                            <hr />
                        </>}
                        

                        {!formState.setupVATSettings &&
                        <>
                            <Switch<ConfirmPagePostModel> name="setupVATSettings" label="Confirm VAT settings" />
                            {vatSetting && vatSettingLoaded && 
                            <DataGrid.Table noHover>
                                <thead>
                                    <tr>
                                        <th>Effective date</th>
                                        <th>Method</th>
                                        <th>Flat rate (%)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{formatDate(vatSetting?.effectiveDate)}</td>
                                        <td>{vatSetting.method}</td>
                                        <td>{formatPercentWithoutSymbol(vatSetting.flatRate)}</td>
                                    </tr>
                                </tbody>
                            </DataGrid.Table>}
                            <hr />
                        </>}
                        
                        {!formState.setupPayroll && <>
                            <Switch<ConfirmPagePostModel> name="setupPayroll" label="Confirm payroll details" />
                            I have provided the information from my yellow PAYE payment booklet (if required).
                            <hr />
                        </>}
                        
                        {!formState.setupEmployees && employeesLoaded &&
                        <>
                            <Switch<ConfirmPagePostModel> name="setupEmployees" label="Confirm employee setup & shareholder details" />
                            I have provided the information from my yellow PAYE payment booklet.
                            I have entered the details of the following employees and shareholders:&nbsp;
                            {employees.map((x, i) => <b>
                                {(i !== 0 && i+1 !== employees.length) ? ', ' : 
                                i+1 === employees.length && employees.length !== 1 
                                ? ' and ' : ''}
                                    {x.name}
                            </b>)}
                            <hr />
                        </>}

                        {!formState.setupVATAdjPP && <>
                            <Switch<ConfirmPagePostModel> name="setupVATAdjPP" label="Confirm VAT returns" />
                            {vatReturnsText()}
                            <hr />
                        </>}

                        {!formState.setupPAYEPayments && 
                        <>
                            <Switch<ConfirmPagePostModel> name="setupPAYEPayments" label="Confirm PAYEPayments details " />
                            {payePaymentsText()}
                            <hr />
                        </>}

                        {!formState.setupUnpaid && 
                        <>
                            <Switch<ConfirmPagePostModel> name="setupUnpaid" label="Confirm outstanding payments" />
                            I have entered the details all of my company's outstanding payments
                            <hr />
                        </>}

                        {!formState.setupAssets &&
                        <>
                            <Switch<ConfirmPagePostModel> name="setupAssets" label="Confirm company assets" />
                            I have entered the details all of my company's assets
                            <hr />
                        </>}
                        
                        {!formState.setupBS &&
                        <>
                            <Switch<ConfirmPagePostModel> disabled={balanceSheetFormat(values).disabled} name="setupBS" label="Confirm balance sheet" />
                            {balanceSheetFormat(values).msg}
                        </>}
                        

                        <div className={styles.formButtons}>
                            <Button onClick={showResetDialog} variant="primary">Reset</Button>
                            <Button submitButton buttonType="save" />
                            <Button variant="primary" onClick={() => navToEntity(Entity.CompanyDashboard)}>Exit wizard</Button>
                        </div>
                    </Form>

                )}                      
            </Formik>}
            { modalDialog }
        </div>
    )
}

export default ConfirmChanges