import React, { FC } from 'react';
import cn from 'classnames';
import styles from './BlankStateCard.module.scss';
import { SbBlokData } from '@storyblok/react';
import { Card, Image } from 'react-bootstrap';
import BlankStateCardPlaceholder from './BlankStateCardPlaceholder';
import { Button } from '../../elements/Button/Button';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import * as Icons from '../../layouts/Components/icons';
import { Action, Entity } from '../../utils/EntityAction';
import useIsMobile from '../../hooks/useIsMobile';

type Props = {
	content: SbBlokData|undefined;
	loading?: boolean;
	onButtonClick?: ()=>void;
	warningMessage?: string;
	showContactLink?: boolean;
	buttonEntity?: Entity;
	buttonAction?: Action;
	buttonDisable?: boolean;
	buttonDisableMessage?: string;
}

const BlankStateCard: FC<Props> = ({ content, loading, onButtonClick, warningMessage, showContactLink, buttonEntity, buttonAction, buttonDisable, buttonDisableMessage }) => {
	const isMobile = useIsMobile()

	if(loading || !content)
	{
		return(
			<Card className={cn('w-100', styles.blankStateCard)}>
				<Card.Body className={cn(styles.cardBody, 'd-flex flex-column flex-md-row-reverse align-items-start')}>
					<BlankStateCardPlaceholder />
				</Card.Body>
			</Card>
		)
	}

	const { Info, Title, image, ButtonText, Note } = content;
	return (
		<Card className={cn('w-100', styles.blankStateCard)}>
			<Card.Body className={cn(styles.cardBody, 'd-flex flex-column flex-md-row-reverse align-items-start')}>
				<div className={cn(styles.cardImage, {[styles.cardImageMobile]: isMobile})} >
					<Image width="100%" height="100%" src={image as string}/>
				</div>

				<div className={cn(styles.cardBodyContent, {[styles.cardBodyContentMobile]: isMobile})}>
					<div>
						<h2>{Title as string}</h2>
						<Card.Text className="m-0">{Info as string}</Card.Text>
					</div>
					<div>
						{ buttonEntity &&
							<Button 
								className={styles.button} 
								variant="primary" 
								onClick={onButtonClick} 
								label={ButtonText as string}
								entity={buttonEntity} 
								action={buttonAction}
								disabled={buttonDisable}
								disabledMessage={buttonDisableMessage}
							/>
						}
						
						{Note ? <small>{Note as string}</small> : null}
					</div>

					
					{/* Warning message */}
					{ warningMessage && (
						<div className={styles.warningMessage}>
							<p>
								<Icons.WarningTriangleV8 className="mr-1 mb-1" />
								{ warningMessage }
							</p>

							{ showContactLink && (
								<a
									href="https://www.provestor.co.uk/about/contact"
									className="linkText"
									target="_blank"
									rel="noopener noreferrer"
								>
									https://www.provestor.co.uk/about/contact
								</a>
							)}
						</div>
					)}
				</div>					
			</Card.Body>
		</Card>
	);
};

export default BlankStateCard;
