/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { EmploymentPostModel, PensionProviderPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Employments<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Employments
   * @name ValidateUpdateEmployment
   * @request PUT:/api/v2/companies/{companyId}/people/employments/{employmentId}/ValidateUpdate
   * @secure
   */
  validateUpdateEmployment = (
    companyId: number,
    employmentId: number,
    model: EmploymentPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/employments/${employmentId}/ValidateUpdate`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Employments
   * @name UpdateEmployment
   * @request PUT:/api/v2/companies/{companyId}/people/employments/{employmentId}
   * @secure
   */
  updateEmployment = (
    companyId: number,
    employmentId: number,
    model: EmploymentPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/employments/${employmentId}`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Employments
   * @name ValidateUpdatePensionProvider
   * @request PUT:/api/v2/companies/{companyId}/people/employments/{employmentId}/ValidateUpdatePensionProvider
   * @secure
   */
  validateUpdatePensionProvider = (
    companyId: number,
    employmentId: number,
    model: PensionProviderPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/employments/${employmentId}/ValidateUpdatePensionProvider`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Employments
   * @name UpdatePensionProvider
   * @request PUT:/api/v2/companies/{companyId}/people/employments/{employmentId}/UpdatePensionProvider
   * @secure
   */
  updatePensionProvider = (
    companyId: number,
    employmentId: number,
    model: PensionProviderPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/employments/${employmentId}/UpdatePensionProvider`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
