import authentication from './authentication';
import dashboard from './dashboard';
import { EntityRoute } from './routeTypes';
import external from './external';
import properties from './properties';
import tenancies from './tenancies';
import reporting from './reporting';
import portfolio from './portfolio';
import consultations from './consultations';
import help from './help';
import { nocompany, hub } from './nocompany';
import portalConsultations from './portalConsultations';
import portalIncorporation from './portalIncorporation';
import portalProfile from './portalProfile';
import userProfile from './userProfile';
import receipts from './receipts'
import quickEntry from './quickEntry';
import { manageemployees, newemployee } from './people';
import recurringPayments from './recurringPayments';
import atedDeclaration from './declarations';
import bookkeeping from './bookkeeping';
import adjustments from './adjustments';
import setupWizard from './setupWizard';
import accountEdit from './accountEdit';
import bookkeepingV8 from './bookkeeping2022';
import invoices from './invoice';
import onboarding from './onboarding';
import lettingstatements from './lettingstatements';
import queries from './queries';
import assets from './asset';
import error from './errorPage';
import documents from './documents'; 
import expenseImport from './expenseImport';
import enableLogrocket from './enableLogrocket';
import dividends from './dividends';
import companyInformation from './companyInformation'
import completionStatement from './completionStatement';
import billing from './billing';
import { testdata } from './test';
import onboardingSetup from './onboardingsetup';
import bankSelection from './BankSelection';
import connectToBank from './ConnectToBank';
import consentRedirect from './consentRedirect';
import corporationTax from './corporationTax';
import shareholdings from './shareholdings';
import SATR from './SATR';
import mobile from './mobile';

let routes:Array<EntityRoute> = []

routes = [
  ...routes,
  ...dashboard,
  ...authentication,
  ...properties,
  ...tenancies,
  ...portfolio,
  ...reporting,
  ...consultations,
  ...help,
  ...hub,
  ...nocompany,
  ...receipts,
  ...quickEntry,
  ...manageemployees,
  ...newemployee,
  ...portalIncorporation,
  ...portalConsultations,
  ...portalProfile,
  ...userProfile,
  ...recurringPayments,
  ...atedDeclaration,
  ...bookkeeping,
  ...adjustments,
  ...setupWizard,
  ...accountEdit,
  ...external,
  ...bookkeepingV8,
  ...invoices,
  ...onboarding,
  ...lettingstatements,
  ...queries, 
  ...assets,
  ...documents,
  ...expenseImport,
  ...enableLogrocket,
  ...dividends,
  ...companyInformation,
  ...completionStatement,
  ...billing,
  ...testdata,
  ...onboardingSetup,
  ...bankSelection,
  ...connectToBank,
  ...consentRedirect,
  ...corporationTax,
  ...shareholdings,
  ...SATR,
  ...mobile,


  // error page must be last
  ...error
];

export default routes;
