/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Country } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Countries<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Countries
   * @name GetCountries
   * @request GET:/api/v2/countries/GetCountries
   * @secure
   */
  getCountries = (params: RequestParams = {}) =>
    this.request<Country[], any>({
      path: `/api/v2/countries/GetCountries`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
