import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { Account } from '../../api/inni/data-contracts';
import { GroupedListOption, GroupedSelectList, Text as EditText } from '../../elements/EditRow/EditRow';
import { Button } from '../../elements/Button/Button';
import { ReviewItemsPostModel } from './Queries';
import { BrandWrapper } from '../../components/BrandWrapper/BrandWrapper';

interface ModalProps {
	hide: () => void;
	create: (
		values: ReviewItemsPostModel,
		actions: FormikHelpers<ReviewItemsPostModel>
	) => void;
	accounts: Account[];
	initialValue?: ReviewItemsPostModel;
	title?: string;
}


const CreateQueryModal = ({
	hide,
	create,
	accounts,
	initialValue = {
		query: '',
	},
	title = 'Create query',
}: ModalProps) => {
	const [accountOptions, setAccountOptions] = useState<GroupedListOption[]>([]);
	const validate = (values: ReviewItemsPostModel) => {
		let errors: any = {};
		if (!Object.values(values).some(Boolean)) errors.form = 'Required';
		return errors;
	};

	useEffect(() => {
		let tempOptions: Array<GroupedListOption> = [];

		accounts
			.filter((v) => v.name)
			.forEach((i) => {
				const exists = tempOptions.findIndex((x) => x.label === i.accountSubGroupName);
				if (exists === -1) {
					tempOptions.push({
						label: i.accountSubGroupName || '',
						options: [{ value: i.id.toString(), label: i.name || '' }],
					});
				} else {
					tempOptions[exists].options?.push({ value: i.id.toString(), label: i.name || '' });
				}
			});
		tempOptions.length > 0 && setAccountOptions(tempOptions);
	}, [accounts]);

	return (
		<Modal size="lg" show={true} onHide={hide}>
			<BrandWrapper>
				<Formik
					initialValues={initialValue}
					enableReinitialize
					validateOnChange
					onSubmit={create}
					isInitialValid={false}
					validate={validate}
				>
					{({ isSubmitting, isValid }) => (
						<FormikForm>
							{/* Header */}
							<Modal.Header closeButton>
								<Modal.Title id="inni-modal-title">{title}</Modal.Title>
							</Modal.Header>

							{/* Body */}
							<Modal.Body>
								<EditText<ReviewItemsPostModel>
									labelClassName="w-100"
									name="query"
									label="Query"
									type="textarea"
									labelTop
								/>
								<GroupedSelectList<ReviewItemsPostModel>
									name="newAccountId"
									label="Suggested new account (optional)"
									labelTop
									options={accountOptions}
								/>
							</Modal.Body>

							{/* Footer */}
							<Modal.Footer>
								<div style={{ textAlign: 'left', width: '100%' }}>
									<Button variant="change" submitButton disabled={isSubmitting || !isValid}>
										Submit
									</Button>
									<Button variant="secondary" onClick={hide}>
										Cancel
									</Button>
								</div>
							</Modal.Footer>
						</FormikForm>
					)}
				</Formik>
			</BrandWrapper>
		</Modal>
	);
};

export default CreateQueryModal;
