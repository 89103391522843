import React from 'react';
import { Invoice } from '../../../api/inni/data-contracts';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { formatCurrency } from '../../../utils/formatNumbers';
import { Action, Entity } from '../../../utils/EntityAction';
import { formatDate } from '../../../utils/formatDate';
import _ from 'lodash';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { InvoiceStatusBadge } from './InvoiceListPageWrapper';
import { useHasPermission } from '../../../hooks/useHasPermission';
export interface InvoiceTableProps {
	loaded: boolean;
	invoices: Array<Invoice>;
}
// https://innidev.visualstudio.com/inni/_git/inni?path=%2FWebsite%2FAreas%2FCompany%2FViews%2FInvoicing%2F_InvoiceList.vbhtml
export const InvoiceTable = ({ loaded, invoices }: InvoiceTableProps) => {
	const hasPermission=useHasPermission() 
	return (
		<DataGrid.Table>
			<thead>
				<tr>
					<th>No</th>
					<th>Description</th>
					<th style={{ textAlign: 'right' }}>Amount</th>
					<th style={{ textAlign: 'right' }}>Balance</th>
					<th style={{ textAlign: 'right' }}>Due date</th>
					<th style={{ textAlign: 'right' }}>Status</th>
				</tr>
			</thead>
			<tbody>
				{!loaded && (
					<>
						<DataGrid.LoadingRow cols={6} />
						<DataGrid.LoadingRow cols={6} />
						<DataGrid.LoadingRow cols={6} />
						<DataGrid.LoadingRow cols={6} />
					</>
				)}
				{loaded && (
					<>
						{invoices && invoices.length > 0 ? (
							_.sortBy(invoices, 'date').map((invoice) => (
								//TODO: Once the users have invoice V7 permission, remove the terinary operator in entity
								<DataGrid.EntityNavigateRow key={invoice.id} entity={hasPermission(Entity.InvoiceV7, Action.Read)[0]?Entity.InvoiceV7:Entity.Invoice} action={Action.Read} extraParams={{ id: invoice.id }}>
										<td>{invoice.id}</td>
										<td>{invoice.description}</td>
										<td style={{ textAlign: 'right' }}>{formatCurrency(invoice.amount)}</td>
										<td style={{ textAlign: 'right' }}>{formatCurrency(invoice.balance)}</td>
										<td style={{ textAlign: 'right' }}>{formatDate(invoice.dateDue)}</td>
										<td style={{ textAlign: 'right' }}>
											<InvoiceStatusBadge status={invoice.status} />
										</td>
								</DataGrid.EntityNavigateRow>))
							) : (
							<tr>
								<td colSpan={6} style={{ padding: 0 }}>
									<NoContentSlate type={DatagridType.Invoices} termsKey='emptyTerms' />
								</td>
							</tr>
						)}
					</>
				)}
			</tbody>
		</DataGrid.Table>
	);
};
