import { Form, Formik } from 'formik';
import { FormikErrors, FormikHelpers } from 'formik/dist/types'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Accounts } from '../../../api/inni/Accounts';
import { Contracts } from '../../../api/inni/Contracts';
import { BankTransactionPostModel, Contract, Property } from '../../../api/inni/data-contracts'
import { Expenses } from '../../../api/inni/Expenses';
import CompanyContext, { CompanyProduct } from '../../../context/CompanyContext';
import { Text as EditText, Currency as EditCurrency, DateSelector, SelectList, ListOption, GroupedListOption, GroupedSelectList, Switch }  from '../../../elements/EditRow/EditRow'
import useIsMobile from '../../../hooks/useIsMobile';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { Button } from '../../../elements/Button/Button';
import BrandContext from '../../../context/BrandContext';
import provestorBrand from '../../../components/BrandWrapper/pvBrand';
import { Properties } from '../../../api/inni/Properties';
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList';

export interface ReceiptFormProps {
    initialValues: BankTransactionPostModel,
    formSubmit: (values: BankTransactionPostModel & {isAsset: boolean}, actions: FormikHelpers<BankTransactionPostModel & {isAsset: boolean}>) => Promise<void>,
    formValidate: (values: BankTransactionPostModel & {isAsset: boolean}) => Promise<FormikErrors<BankTransactionPostModel & {isAsset: boolean}>>,
    onCancelClick?: () => void,
    accountId: number
}

const ReceiptPage4 = ({initialValues, formSubmit, formValidate, onCancelClick, accountId} : ReceiptFormProps) => {

    const expensesAPI = useInniAPI(Expenses);
    const accountsAPI = useInniAPI(Accounts);
    const contractsAPI = useInniAPI(Contracts);
    const companyContext = useContext(CompanyContext);
    const brandContext = useContext(BrandContext)

    const [VATRateOptions, setVATRateOptions] = useState<Array<ListOption>>([]);
    const [categoryOptions, setCategoryOptions] = useState<Array<GroupedListOption>>([])
    const [contractOptions, setContractOptions] = useState<Array<ListOption>>([]);

    const [properties] = useFetchEntityList<Property, Properties>(Properties, [403])

    const [contracts, setContracts] = useState<Contract[] | undefined>(undefined);

    const [accountsLoaded, setAccountsLoaded] = useState(false);
    const [contractsLoaded, setContractsLoaded] = useState(false);
    const [VatRateLoaded, setVatRateLoaded] = useState(false);

    const isMobile = useIsMobile()

    useEffect(() => {
        if (expensesAPI && !VatRateLoaded) {
            expensesAPI.vatRates(companyContext.cid)
            .then(res => {
                let formattedOptions: Array<ListOption> = []
                res.data.map(i => {
                    formattedOptions.push({
                        value: i.id, label: i.name || ''
                    })
                })
                setVATRateOptions(formattedOptions)
                setVatRateLoaded(true)
            })
        }
    }, [expensesAPI, VATRateOptions, VatRateLoaded, companyContext.cid])

    useEffect(() => {
        if (accountsAPI && !accountsLoaded) {
            accountsAPI.index(companyContext.cid)
            .then(res => {
                let tempOptions: Array<GroupedListOption> = []
                let tempAccounts = res.data
                if(companyContext.product === CompanyProduct.isCap) {
                    tempAccounts = res.data.filter(x => x.hasCategory)
                }

                tempAccounts.filter(i => i.accountGroup === 'Expenses').map(i => {
                    const exists = tempOptions.findIndex(x => x.label === i.accountSubGroup)
                    if (exists === -1) {
                        tempOptions.push({
                            label: i.accountSubGroup || '',
                            options: [{value: i.id.toString(), label: i.name || ''}]
                        })
                    } else {
                        tempOptions[exists].options?.push({value: i.id.toString(), label: i.name || ''})
                    }
                })
                setCategoryOptions(tempOptions)
                setAccountsLoaded(true)
            })
        }
    }, [accountsAPI, accountsLoaded, categoryOptions, companyContext.cid, companyContext.product])

    useEffect(() => {
        if (contractsAPI && !contractsLoaded) {
            contractsAPI.index(companyContext.cid)
            .then(res => {
                setContracts(res.data)
                
                setContractsLoaded(true)
            })
            
        }
    }, [companyContext.cid, contractsAPI, contractsLoaded])

    useEffect(() => {
        if (contracts) {
            let formattedOptions: Array<ListOption> = []
            contracts.forEach(i => {
                formattedOptions.push({
                    value: i.id.toString(), 
                    label: (i.contractSubType === 'Tenancy' ? properties.find(x => x.id === i.propertyId)?.name + ' / ' : '') + i.name || ''
                })
            })
            setContractOptions(formattedOptions)
        }
    }, [contracts, properties])

    return (
        <Formik
            initialValues={{...initialValues, isAsset: false}}
            enableReinitialize
            onSubmit={formSubmit}
            validateOnChange={false}       
            validate={formValidate}
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form>
                    { values.bankAccountId === 0 && setFieldValue('bankAccountId', accountId) }
                    <DateSelector<BankTransactionPostModel> labelTop={isMobile}  name='date' />
                    <EditText<BankTransactionPostModel> labelTop={isMobile} label='Paid to' name='payee' />
                    <EditText<BankTransactionPostModel> labelTop={isMobile} name='description' />
                    <EditText<BankTransactionPostModel> labelTop={isMobile} label='Reference number' name='refNo' />
                    <EditCurrency<BankTransactionPostModel> min={0} step="0.01" labelTop={isMobile} name='amount' />
                    
                    <SelectList<BankTransactionPostModel> labelTop={isMobile} label='VAT code' name='vatCode' options={VATRateOptions}/>
                    <GroupedSelectList<BankTransactionPostModel> labelTop={isMobile} label='Category' name='accountId' options={categoryOptions}/>
                    <SelectList<BankTransactionPostModel> 
                        labelTop={isMobile} 
                        label={brandContext.brand === provestorBrand ? 'Property/Tenancy' : 'Contract'} 
                        name='contractId' 
                        options={contractOptions}
                    />
                    { contracts?.find(x => x.id.toString() === String(values.contractId))?.contractSubType === 'Tenancy' &&
                        <Switch<BankTransactionPostModel> name='isBillable' label='Billable (Yes/No)' />
                    }
                    <Switch<BankTransactionPostModel & {isAsset: boolean}> name='isAsset' label='Is it an Asset' />
                    {/* <Submit showCancel={false} disabled={isSubmitting} onCancelClick={onCancelClick} /> */}
                    <Button buttonType="save" submitButton mobile={isMobile} />
                </Form>       
            )}
            </Formik>
    )
}
export default ReceiptPage4

