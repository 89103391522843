import { Modal as ReactModal } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { LettingAgentPostModel } from '../../../api/inni/data-contracts';
import { Text as EditText } from '../../../elements/EditRow/EditRow';
import { Button } from '../../../elements/Button/Button';
import { BrandWrapper } from '../../../components/BrandWrapper/BrandWrapper';


interface NewAgentOptionModalProps {
	hide: () => void;
	create: (values: LettingAgentPostModel, actions: FormikHelpers<LettingAgentPostModel>) => void;
}

const AddNewAgentOptionModal = ({ hide, create }: NewAgentOptionModalProps) => {
	const validate = (values: LettingAgentPostModel) => {
		let errors: any = {};
		if (!values.name) errors.name = 'Required';

		return errors;
	};

	return (
		<ReactModal size="lg" show onHide={hide}>
			<BrandWrapper>
				<Formik
					initialValues={{}}
					enableReinitialize
					validateOnChange={false}
					validate={validate}
					onSubmit={create}
				>
					{({ isSubmitting }) => (
						<FormikForm>
							{/* Header */}
							<ReactModal.Header closeButton>
								<ReactModal.Title id="inni-modal-title">Add new agent</ReactModal.Title>
							</ReactModal.Header>

							{/* Body */}
							<ReactModal.Body>
								<EditText<LettingAgentPostModel>
									labelClassName="w-100"
									name="name"
									label="Agent name"
									labelTop
									autoFocus
								/>
							</ReactModal.Body>

							{/* Footer */}
							<ReactModal.Footer>
								<div style={{ textAlign: 'left', width: '100%' }}>
									<Button variant="change" submitButton disabled={isSubmitting}>
										Submit
									</Button>
									<Button variant="secondary" onClick={hide}>
										Cancel
									</Button>
								</div>
							</ReactModal.Footer>
						</FormikForm>
					)}
				</Formik>
			</BrandWrapper>
		</ReactModal>
	);
};
export default AddNewAgentOptionModal;
