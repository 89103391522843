import React from "react";
import styles from "./EnableLogRocket.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "../../elements/Button/Button";
import { Container } from "react-bootstrap";
import { BrandWrapper } from "../../components/BrandWrapper/BrandWrapper";
import LogRocket from "logrocket";
type Props = {};

function EnableLogRocket({}: Props) {
    const setDebugging=()=>{
        window.sessionStorage.setItem(`temp_debugging`, "True");
		LogRocket.init('ke6fuy/react-v7-app');
		window.location.href= window.location.origin;
    }

	const unsetDebugging=()=>{
        window.sessionStorage.setItem(`temp_debugging`, "False");
       window.location.href= window.location.origin;
    }
	return (
	<BrandWrapper>	
		<Container>
			<div className="d-flex d-flex justify-content-center ">
				<div className="m-4">
					<div className={styles.ContentWrapper}>
						<div className="d-flex align-items-center ml-1">
							<FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="mr-1" />	
                            <h3 className="mx-3 mb-1">Enable Debugging</h3>						
						</div>
						<hr className="mt0"></hr>
						<p>
							Enabling debugging will record actions and information within the app to help us identify any potential issues. 
                                Once you close the browser, the debugging feature will be automatically turned off.
						</p>
                        <p className="d-flex align-items-center ml-1">Continue enabling debugging?</p>
						<div>
							<Button variant="primary" className="m-2" onClick={setDebugging}>
								Yes
							</Button>
							<Button variant="primary" className="m-2" onClick={unsetDebugging}>
								No
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Container>
	</BrandWrapper>
	);
}

export default EnableLogRocket;
