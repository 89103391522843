import { faBuilding, faDollarSign, faDoorOpen, faEye, faHandHoldingUsd, faLaptopHouse, faMoneyBillAlt, faMoneyCheck, faSack, faSackDollar, faUsers } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { Step, Stepper } from '../../elements/Stepper/Stepper'
import CompanyDetails from './Components/CompanyDetails'
import EmployeesAndShareholders from './Components/EmployeesAndShareholders'
import PayePayments from './Components/PayePayments'
import Payroll from './Components/Payroll'
import VATReturns from './Components/VATReturns'
import Welcome from './Components/Welcome'
import OutstandingPayments from './Components/OutstandingPayments'
import BalanceSheet from './Components/BalanceSheet'
import ConfirmChanges from './Components/ConfirmChanges'
import { useParams } from 'react-router-dom'
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity'
import { Action, Entity } from '../../utils/EntityAction'
import { SetupWizardForm } from '../../api/inni/data-contracts'
import { useInniAPI } from '../../hooks/useInniAPI';
import CompanyContext from '../../context/CompanyContext'
import { SetupWizard as SetupWizardAPI } from '../../api/inni/SetupWizard'
import useIsMobile from '../../hooks/useIsMobile'
import Assets from './Components/Assets'
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout'
import { LoadingFullPage } from '../../elements/LoadingFullPage/LoadingFullPage'
import InfoBanner from '../../elements/InfoBanner/InfoBanner'

const sections = [
    'welcome',
    'companydetails',
    'payroll',
    'employees',
    'vatreturns',
    'payepayments',
    'outstandingpayments',
    'assets',
    'balancesheet',
    'overview'
]

interface RouteParams {
    page: string
}

const SetupWizard = () => {

    const SWAPI = useInniAPI(SetupWizardAPI)
    const companyContext = useContext(CompanyContext)

    const [setupData, setSetupData] = useState<SetupWizardForm | undefined>(undefined)
    const [setupLoaded, setSetupLoaded] = useState(false)

    const page = useParams<RouteParams>().page
    const navToEntity = useNavigateToEntity()

    const [activePage, setActivePage] = useState<number>(sections.findIndex(x => x === page) + 1)
    const [pages, setPages] = useState<number[]>([1,2,3,4,5,6,7,8,9,10])

    const isMobile = useIsMobile()

    useEffect(() => {
        let activePages = [1,2,3,4,5,6,7,8,9,10]
        // Disable pages based on the conditions below
        if (setupData) {
            if (setupData.setupCoInfoComplete) activePages = activePages.filter(x => x !== 2)
            if (setupData.setupPAYEPayments) activePages = activePages.filter(x => x !== 6)
            if (setupData.setupEmployees) activePages = activePages.filter(x => x !== 4)
            if (setupData.setupUnpaid) activePages = activePages.filter(x => x !== 7)
            if (setupData.setupAssets) activePages = activePages.filter(x => x !== 8)
            if (setupData.setupBS) activePages = activePages.filter(x => x !== 9)
            if (setupData.setupWizardType === 0 || !setupData.showVatReturns || setupData.setupVATAdjPP)
                activePages = activePages.filter(x => x !== 5)
            
            if (setupData.setupWizardType > 0 && !setupData.setupMigrationDate) {
                activePages = activePages.filter(x => x !== 5)
                activePages = activePages.filter(x => x !== 4)
                activePages = activePages.filter(x => x !== 6)
                activePages = activePages.filter(x => x !== 7)
                activePages = activePages.filter(x => x !== 8)
                activePages = activePages.filter(x => x !== 9)
                activePages = activePages.filter(x => x !== 10)
            }
            setPages(activePages)
        }
    }, [setupData])

    useEffect(() => {
        let index = sections.findIndex(x => x === page)
        
        if (index === -1) {
            navToEntity(Entity.SetupWizard, Action.Read, { 'page': 'welcome' }) // default to the welcome page
        } else {
            setActivePage(index + 1)
        }
    }, [navToEntity, page])

    useEffect(() => {
        if (SWAPI && !setupLoaded) {
            SWAPI.index(companyContext.cid)
            .then(res => {
                setSetupData(res.data)
                setSetupLoaded(true)
            })
        }
    }, [SWAPI, companyContext.cid, setupLoaded])

    

    const navToNextPage = (newSetupData? : SetupWizardForm) => {
        let index = sections.findIndex(x => x === page)
        let i2 = pages.findIndex(x => x === index+1)
        navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[pages[i2+1]-1] })

        if (newSetupData !== undefined) {
            setSetupData(newSetupData)
        }

    }

    const navToPrevPage = (newSetupData? : SetupWizardForm) => {
        let index = sections.findIndex(x => x === page)
        let i2 = pages.findIndex(x => x === index+1)
        navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[pages[i2-1]-1] })
    }

    return (
        <DefaultLayout noPadding useFullWidth title="Setup Wizard">
            {/* Banner */}
            <p style={{
                    color:'white',
                    background:'rgb(0, 101, 129)',
                    padding: '8px',
                    textAlign: 'center',
                    margin: '0px',
                }}>
                    👋 <b>Welcome</b> to the new setup wizard. If you run into any issues, you can visit the old one&nbsp; 
                    <a href="" onClick={(e) => {e.preventDefault(); navToEntity(Entity.SetupWizardOLD)}} style={{color:'white'}}>
                        <u>here</u>
                    </a>
                    
            </p>
            {!setupLoaded ? <LoadingFullPage entityName='Setup wizard' fullScreen={false} /> : <>
                {!isMobile && 
                <div style={{background: '#006581', padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {setupData &&
                    <div style={{width: '75%'}}>
                        <Stepper maxItems={5} inactiveColor="#FFF" activeColor="#e8b811">
                            {pages.find(x => x === 1) && 
                                <Step 
                                    onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[0] })} 
                                    active={activePage === 1} 
                                    complete={activePage > 1} 
                                    label="Welcome">
                                        <FontAwesomeIcon style={{ color: activePage === 1 ? 'orange' : 'white' }} icon={faDoorOpen} />
                                    </Step>
                            }
                            {pages.find(x => x === 2) && 
                                <Step 
                                    onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[1] })} 
                                    disabled={setupData?.setupCoInfoComplete} 
                                    active={activePage === 2} 
                                    complete={activePage > 2} 
                                    label="Company details">
                                        <FontAwesomeIcon style={{ color: activePage === 2 ? 'orange' : 'white' }} icon={faBuilding} />
                                    </Step>
                            }
                            {pages.find(x => x === 3) && 
                                <Step 
                                    onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[2] })} 
                                    disabled={setupData?.setupPayroll} 
                                    active={activePage === 3} 
                                    complete={activePage > 3} 
                                    label="Payroll">
                                        <FontAwesomeIcon style={{ color: activePage === 3 ? 'orange' : 'white' }} icon={faMoneyCheck} />
                                    </Step>
                            }
                            {pages.find(x => x === 4) && 
                                <Step 
                                    onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[3] })} 
                                    disabled={setupData?.setupEmployees} 
                                    active={activePage === 4} 
                                    complete={activePage > 4} 
                                    label="Employees and shareholders">
                                        <FontAwesomeIcon style={{ color: activePage === 4 ? 'orange' : 'white' }} icon={faUsers} />
                                    </Step>
                            }
                            { pages.find(x => x === 5) &&
                                <Step 
                                    onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[4] })} 
                                    disabled={setupData?.setupVATSettings} 
                                    active={activePage === 5} 
                                    complete={activePage > 5} 
                                    label="VAT returns">
                                        <FontAwesomeIcon style={{ color: activePage === 5 ? 'orange' : 'white' }} icon={faSackDollar} />
                                    </Step>
                            }
                            { pages.find(x => x === 6) &&
                                <Step 
                                    onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[5] })} 
                                    disabled={setupData?.setupPAYEPayments} 
                                    active={activePage === 6} 
                                    complete={activePage > 6} 
                                    label="PAYE payments">
                                        <FontAwesomeIcon style={{ color: activePage === 6 ? 'orange' : 'white' }} icon={faHandHoldingUsd} />
                                    </Step>
                            }
                            {pages.find(x => x === 7) &&
                                <Step 
                                    onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[6] })} 
                                    disabled={setupData?.setupUnpaid} 
                                    active={activePage === 7} 
                                    complete={activePage > 7} 
                                    label="Outstanding payments">
                                        <FontAwesomeIcon style={{ color: activePage === 7 ? 'orange' : 'white' }} icon={faMoneyBillAlt} />
                                    </Step>
                            }
                            { pages.find(x => x === 8) &&
                                <Step 
                                    onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[7] })} 
                                    disabled={setupData?.setupAssets} 
                                    active={activePage === 8} 
                                    complete={activePage > 8} 
                                    label="Assets">
                                        <FontAwesomeIcon style={{ color: activePage === 8 ? 'orange' : 'white' }} icon={faLaptopHouse} />
                                    </Step>
                            }
                            {pages.find(x => x === 9) && 
                                <Step 
                                    onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[8] })} 
                                    disabled={setupData?.setupBS} 
                                    active={activePage === 9} 
                                    complete={activePage > 9} 
                                    label="Balance sheet">
                                        <FontAwesomeIcon style={{ color: activePage === 9 ? 'orange' : 'white' }} icon={faDollarSign} />
                                    </Step>}
                            {pages.find(x => x === 10) && 
                                <Step 
                                    onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': sections[9] })} 
                                    active={activePage === 10} 
                                    complete={activePage > 10} 
                                    label="Overview">
                                        <FontAwesomeIcon style={{ color: activePage === 10 ? 'orange' : 'white' }} icon={faEye} />
                                    </Step>
                            }
                        </Stepper>
                    </div>}
                </div>}                
                {setupData &&
                <Container style={{marginTop: '20px'}}>
                    {/* Shows when we are on a scraped company */}
                    {setupData.setupDataSource?.toUpperCase() === "NWSJD" &&
                    <InfoBanner 
                        type="info" 
                        title="Scraper data" 
                        body="This company uses data scraped from other sources, some fields may be be disabled to protect the data. This data should be right if something is wrong please let the dev team know and we will look into fixing it." 
                    />}
                    {activePage === 1 && <Welcome setNextPage={navToNextPage} />}
                    {activePage === 2 && <CompanyDetails initial={setupData} setNextPage={navToNextPage} setPrevPage={navToPrevPage} />}
                    {activePage === 3 && <Payroll formState={setupData} setNextPage={navToNextPage} setPrevPage={navToPrevPage} />}                
                    {activePage === 4 && <EmployeesAndShareholders formState={setupData} setNextPage={navToNextPage} setPrevPage={navToPrevPage} />}
                    {activePage === 5 && <VATReturns formState={setupData} setNextPage={navToNextPage} setPrevPage={navToPrevPage} />}
                    {activePage === 6 && <PayePayments formState={setupData} setNextPage={navToNextPage} setPrevPage={navToPrevPage} />}
                    {activePage === 7 && <OutstandingPayments setFormState={setSetupData} formState={setupData} setNextPage={navToNextPage} setPrevPage={navToPrevPage}  />}
                    {activePage === 8 && <Assets formState={setupData} setNextPage={navToNextPage} setPrevPage={navToPrevPage} />}
                    {activePage === 9 && <BalanceSheet setFormState={setSetupData} formState={setupData} setNextPage={navToNextPage} setPrevPage={navToPrevPage} />}
                    {activePage === 10 && <ConfirmChanges reloadData={() => setSetupLoaded(false)} formState={setupData} setPrevPage={navToPrevPage} setFormState={setSetupData} />}
                </Container>}
            </>}
        </DefaultLayout>)
}

export default SetupWizard
