import { Form as FormikForm, Formik, FormikErrors, FormikHelpers } from 'formik';
import { ConsultationPostModel, ConsultationsSummary } from '../../../api/inni/data-contracts';
import { Text as EditText, DateSelector, Switch, Currency }  from '../../../elements/EditRow/EditRow'
import { BrandWrapper } from '../../../components/BrandWrapper/BrandWrapper';
import { Form, Modal } from 'react-bootstrap';
import { Button } from '../../../elements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

export interface ConsultationsEditFormProps {
    initialValues: ConsultationPostModel,
    formSubmit: (values: ConsultationPostModel, actions: FormikHelpers<ConsultationPostModel>) => Promise<void>,
    formValidate: (values: ConsultationPostModel) => Promise<FormikErrors<ConsultationPostModel>>,
    onCancelClick?: () => void,
    title: string
    summary: ConsultationsSummary
}

export const ConsultationsEditForm = ({
    initialValues,
    formSubmit,
    formValidate,
    onCancelClick,
    title,
    summary
} : ConsultationsEditFormProps) => {

    return (
        <BrandWrapper>
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={formSubmit}
            validateOnChange={false}       
            validate={formValidate}
        >
            {({ isSubmitting, values }) => (
                <FormikForm>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            { title }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DateSelector<ConsultationPostModel> labelTop name="consultationDate" />

                        <EditText<ConsultationPostModel> labelTop name="notes" />

                        <Switch<ConsultationPostModel> label="Was the consultation part of a bundle?" labelTop name="isBundled" />
                        { values.isBundled && new Date(values.consultationDate) < new Date(summary.consultationCycleEnd)
                            && new Date(values.consultationDate) > new Date(new Date(summary.consultationCycleEnd).getFullYear() + -1, new Date(summary.consultationCycleEnd).getMonth(), new Date(summary.consultationCycleEnd).getDay())
                            && summary.numFree <= 0 &&
                            <Form.Control.Feedback style={{display: 'block'}} type="invalid"><FontAwesomeIcon icon={faExclamationCircle} style={{marginRight: '5px'}} />You are creating a consultation outside the companies payment plan</Form.Control.Feedback>
                        }
                        { !values.isBundled && <Currency<ConsultationPostModel> min={0} labelTop name="pricePaid" /> }                 
                    </Modal.Body>    
                    <Modal.Footer>
                        <div style={{textAlign: 'left', width: '100%'}}>
                            <Button variant="change" submitButton disabled={isSubmitting}>Save</Button>
                            <Button buttonType="close" onClick={onCancelClick}>Cancel</Button>
                        </div>
                    </Modal.Footer>
                    
                </FormikForm>       
            )}
        </Formik>
        </BrandWrapper>
    )
}