/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { OwnershipPostModel, OwnershipRecord, PersonSelectList } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Ownership<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Ownership
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/properties/{propertyId}/ownership
   * @secure
   */
  index = (companyId: number, propertyId: number, params: RequestParams = {}) =>
    this.request<OwnershipRecord[], any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/ownership`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ownership
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyId}/ownership
   * @secure
   */
  create = (companyId: number, propertyId: number, postModel: OwnershipPostModel[], params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/ownership`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ownership
   * @name ListPeople
   * @request GET:/api/v2/companies/{companyId}/properties/{propertyId}/ownership/People
   * @secure
   */
  listPeople = (companyId: number, propertyId: number, params: RequestParams = {}) =>
    this.request<PersonSelectList[], any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/ownership/People`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ownership
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyId}/ownership/Validate
   * @secure
   */
  validateCreate = (
    companyId: number,
    propertyId: number,
    postModel: OwnershipPostModel[],
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/ownership/Validate`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
