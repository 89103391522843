import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CompanyContext from '../../../context/CompanyContext';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { NoContentSlate } from '../../Slates/NoContentSlate';
import { Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import OnboardingContext from '../../../context/OnboardingContext';
import { SbBlokData } from '@storyblok/react';
import { InitialiseDateModel } from '../../../api/inni/data-contracts';
import { asHtml5Date, formatDate, isSameOrBefore } from '../../../utils/formatDate';
import { subDays } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import styles from '../../../features/Help/Help.module.scss';
import { Button } from '../../Button/Button';
import { IPropertyPostModel } from '../../../hooks/useOnboardingProperties';
import { formatCurrency } from '../../../utils/formatNumbers';
import { useModalDialog } from '../../../hooks/useModalDialog';
import { Onboarding } from '../../../api/inni/Onboarding';

type Props = { blok: SbBlokData; initialData: InitialiseDateModel; isCompletedPage: boolean };

type Errors = { [key: string]: string };
const PropertiesTable = ({ blok, initialData }: Props) => {
	const onboardingApi = useInniAPI(Onboarding);
	const { cid } = useContext(CompanyContext);
	const { soldPropertiesFormik, ownedPropertiesFormik } = useContext(OnboardingContext);
	const [loader, setLoader] = useState(false);
	const [showModalDialog, modalDialog] = useModalDialog();
	const { values, setValues, handleChange, errors, setErrors } = useMemo(
		() => (blok.component === 'properties_owned_table' ? ownedPropertiesFormik : soldPropertiesFormik),
		[blok.component, ownedPropertiesFormik, soldPropertiesFormik]
	);
	const previousYearEnd = useMemo(
		() => subDays(new Date(initialData?.financialYearStartDate || ''), 1).toDateString(),
		[initialData?.financialYearStartDate]
	);

	const changeValuation = useCallback(
		(date: string, value: number): number | undefined => {
			if (initialData.preAppointmentYearEnd) {
				if (blok.component === 'properties_sold_table') {
					return isSameOrBefore(new Date(previousYearEnd), new Date(date)) ? value : undefined;
				} else {
					return !isSameOrBefore(new Date(previousYearEnd), new Date(date)) ? value : undefined;
				}
			}
			return undefined;
		},
		[blok.component, initialData.preAppointmentYearEnd, previousYearEnd]
	);

	useEffect(() => {
		setLoader(true);
		if (onboardingApi && cid) {
			onboardingApi
				.getSubmittedPropertyFinancingDetails(cid)
				.then(({ data }) => {
					setValues(
						blok.component === 'properties_sold_table'
							? data
									.filter((v) => v.tenureLifecycleStatus === 'Sold')
									.map((v) => ({
										id: v.id,
										name: v.name,
										purchaseOrSaleDate: v.saleDate || '',
										purchaseOrSalePrice: v.salePrice,
										valuation: changeValuation(v.saleDate || '', v.valuation ||  0),
										status: v.tenureLifecycleStatus || 'Sold',
										preAppointmentYearEnd: initialData.preAppointmentYearEnd,
										propertyId: v.id,
									}))
							: data
									.filter((v) => v.tenureLifecycleStatus !== 'Sold')
									.map((v) => ({
										id: v.id,
										name: v.name,
										purchaseOrSaleDate: v.purchaseDate || '',
										purchaseOrSalePrice: v.purchasePrice || 0,
										valuation: changeValuation(v.purchaseDate || '', v.valuation || 0),
										status: v.tenureLifecycleStatus || 'Active',
										preAppointmentYearEnd: initialData.preAppointmentYearEnd,
										propertyId: v.id,
									}))
					);
				})
				.finally(() => setLoader(false));
		}
	}, [
		onboardingApi,
		cid,
		setValues,
		blok.component,
		initialData.preAppointmentYearEnd,
		previousYearEnd,
		changeValuation,
	]);

	useEffect(() => {
		return () => setErrors([]);
	}, []);
	const renderThs = useMemo(() => {
		const tables = {
			properties_owned_table: [
				'Name',
				'Purchase date',
				'Purchase price',
				initialData.preAppointmentYearEnd ? `Valuation at ${formatDate(previousYearEnd)}` : undefined,
			],
			properties_sold_table: [
				'Name',
				'Sale date',
				'Sale price',
				initialData.preAppointmentYearEnd ? `Valuation at ${formatDate(previousYearEnd)}` : undefined,
			],
		};
		return tables[blok.component as keyof typeof tables].filter(Boolean);
	}, [blok.component, initialData.preAppointmentYearEnd, previousYearEnd]);

	const renderTooltip = (props: any) => (
		<Tooltip id="button-tooltip" {...props}>
			We need your property valuations at the previous year end's date to prepare your accounts
		</Tooltip>
	);

	const addNewEmptyRow = () => {
		setValues([
			...values,
			{
				id: new Date().getTime(),
				name: '',
				purchaseOrSaleDate: '',
				purchaseOrSalePrice: 0,
				valuation: initialData.preAppointmentYearEnd ? 0 : undefined,
				preAppointmentYearEnd: initialData.preAppointmentYearEnd,
				status: blok.component === 'properties_sold_table' ? 'Sold' : 'Active',
			},
		]);
	};

	const removeRow = (id: number) => {
		setValues((prev) => prev.filter((v) => v.id !== id));
	};

	const getInvalidClassByField = (index: number, name: string) => {
		return (errors as Errors)[`[${index}].${name}`] ? 'is-invalid' : '';
	};

	const showDeleteModal = (id: number) => {
        showModalDialog(
            'Delete property?',
            `Are you sure you want to delete this property?`,
            [
                <Button key="yes" variant="danger" label="Yes" onClick={() => removeRow(id)} />,
                <Button key="no" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

	return (
		<div className="mb-4">
			<DataGrid.Table>
				<thead>
					<tr>
						{renderThs.map((th, i, arr) => (
							<th key={th} className="w-25" style={{ fontSize: '13px' }}>
								<div className="d-flex align-items-center justify-content-between">
									{th}{' '}
									{i === arr.length - 1 && initialData.preAppointmentYearEnd ? (
										<OverlayTrigger placement="top" overlay={renderTooltip}>
											<FontAwesomeIcon icon={faInfoCircle} className={styles.infoCircle} />
										</OverlayTrigger>
									) : (
										<></>
									)}
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{loader && (
						<>
							<DataGrid.LoadingRow cols={initialData.preAppointmentYearEnd ? 4 : 3} />
							<DataGrid.LoadingRow cols={initialData.preAppointmentYearEnd ? 4 : 3} />
						</>
					)}
					{!loader && (
						<>
							{values.length > 0 ? (
								(values as IPropertyPostModel[]).map(
									({ name, valuation, purchaseOrSalePrice, purchaseOrSaleDate, id, propertyId }, i) =>
										propertyId ? (
											<tr key={id}>
												<td>{name || ''}</td>
												<td>{formatDate(purchaseOrSaleDate || '')}</td>
												<td>{formatCurrency(purchaseOrSalePrice)}</td>
												{initialData.preAppointmentYearEnd && (
													<td>
														{valuation !== undefined ? (
															<div>{formatCurrency(valuation)}</div>
														) : (
															<div>N/A</div>
														)}
													</td>
												)}
											</tr>
										) : (
											<tr key={id}>
												<td>
													<Form.Control
														name={`[${i}].name`}
														onChange={handleChange}
														value={name || ''}
														style={{ padding: '0.64rem' }}
														className={getInvalidClassByField(i, 'name')}
													/>
												</td>
												<td>
													<Form.Control
														type={'date'}
														name={`[${i}].purchaseOrSaleDate`}
														onChange={handleChange}
														value={asHtml5Date(purchaseOrSaleDate || '')}
														isInvalid={false}
														className={getInvalidClassByField(i, 'purchaseOrSaleDate')}
														onBlur={() => {
															setValues((prev) =>
																prev.map((v) =>
																	v.id === id
																		? {
																				...v,
																				valuation: changeValuation(
																					purchaseOrSaleDate || '',
																					valuation || 0
																				),
																		  }
																		: v
																)
															);
														}}
													/>
												</td>
												<td>
													<div className="d-flex justify-content-between align-items-center">
														<InputGroup style={{ marginBottom: '0' }} className={!initialData.preAppointmentYearEnd ? 'mr-3' : ''}>
															<InputGroup.Prepend>
																<InputGroup.Text className="px-2 py-1">
																	£
																</InputGroup.Text>
															</InputGroup.Prepend>
															<Form.Control
																type={'number'}
																value={purchaseOrSalePrice || ''}
																autoComplete={'off'}
																name={`[${i}].purchaseOrSalePrice`}
																onChange={(e) =>
																	+e.target.value >= 0 ? handleChange(e) : () => {}
																}
																className={getInvalidClassByField(
																	i,
																	'purchaseOrSalePrice'
																)}
															/>
														</InputGroup>
														{!initialData.preAppointmentYearEnd && (
															<Button
																iconOnly
																buttonType='delete'
																onClick={() => showDeleteModal(id)}
															/>
														)}
													</div>
												</td>
												{initialData.preAppointmentYearEnd && (
													<td style={{verticalAlign: 'middle'}}>
														<div className="d-flex justify-content-between align-items-center">
															{valuation !== undefined ? (
																<InputGroup style={{ marginBottom: '0' }} className="mr-3">
																	<InputGroup.Prepend>
																		<InputGroup.Text className="px-2 py-1">
																			£
																		</InputGroup.Text>
																	</InputGroup.Prepend>
																	<Form.Control
																		type={'number'}
																		name={`[${i}].valuation`}
																		value={valuation || ''}
																		autoComplete={'off'}
																		onChange={(e) =>
																			+e.target.value >= 0
																				? handleChange(e)
																				: () => {}
																		}
																		className={getInvalidClassByField(
																			i,
																			'valuation'
																		)}
																	/>
																</InputGroup>
															) : (
																<div className="mr-3">N/A</div>
															)}
															<Button
																iconOnly
																buttonType='delete'
																onClick={() => showDeleteModal(id)}
															/>
														</div>
													</td>
												)}
											</tr>
										)
								)
							) : (
								<tr>
									<td colSpan={initialData.preAppointmentYearEnd ? 4 : 3} style={{ padding: 0 }}>
										<NoContentSlate type={DatagridType.Properties} termsKey="emptyTerms" />
									</td>
								</tr>
							)}

							<tr>
								<td colSpan={initialData.preAppointmentYearEnd ? 4 : 3}>
									<Button buttonType="new" onClick={addNewEmptyRow} />
								</td>
							</tr>
						</>
					)}
				</tbody>
			</DataGrid.Table>
			{ modalDialog }
		</div>
	);
};

export default PropertiesTable;
