import { CompanyAESettingsPostModel } from '../../../api/inni/data-contracts'
import useCompareChange from '../../../hooks/useCompareChange';
import { Form, Formik } from 'formik';
import { DateSelector, SelectList, Submit, Text } from '../../../elements/EditRow/EditRow';
import { CompanySettingsProps, EnumMapper } from '../CompanySettingsDataTypes';


function AEAssessmentTab<T extends CompanyAESettingsPostModel>({
  settings,
  handleSubmit,
  handleValidate
}: CompanySettingsProps<T>) {

  const formikRef = useCompareChange<T>();
  const autoEnrll_StatusList:EnumMapper<CompanyAESettingsPostModel["autoEnrll_ChoosenStatus"]>[] = [{
    value:'PendingAction',
    label:'Pending SD - Client ACTION needed'
  },{
    value:'NoAsOnlyOneDirector',
    label:'Exempt - 1 Director no staff'
  },{
    value:'NoAsNoDirectorsWithContracts',
    label:'No AS no directors with contracts'
  },{
    value:'NoAsOnlyOneContractOfEmployment',
    label:'Exempt - 2+ Directors,1 empl contract'
  },{
    value:'Yes',
    label:'Wants / Needs Pension'
  },
  {
    value:'PendingGood',
    label:'Pending SD - No client action needed'
  },
  {
    value:'Voluntary',
    label:'Voluntary'
  }];

  const autoEnrll_TPRNotifiedStatus:EnumMapper<CompanyAESettingsPostModel["autoEnrll_TPRNotifiedStatus"]>[] = [{
    value:'Pending',
    label:'Action Required'
  },{
    value:'NotifiedExempt',
    label:'Notified exempt'
  },{
    value:'CompleteExemptionLater',
    label:'Complete exemption later'
  },{
    value:'IAActionToNominate',
    label:'IA to nominate'
  },{
    value:'Nominated',
    label:'Nominated'
  },{
    value:'ClientNominated',
    label:'Client nominated'
  }
  ];
//autoEnrll_ChoosenStatus
//autoEnrll_TPRNotifiedStatus
  return (
    <Formik
      initialValues={settings as T}
      onSubmit={handleSubmit}
      validate={handleValidate}
      enableReinitialize
      innerRef={formikRef}
      validateOnBlur
      validateOnChange={false}
    >
      {({ values,initialValues,isSubmitting, dirty }) => (
        <Form>
          <Text<CompanyAESettingsPostModel> name="autoEnrll_LetterCode" label="Letter code" />
          <DateSelector<CompanyAESettingsPostModel> name="autoEnrll_StagingDate" label="Staging date" />
          <DateSelector<CompanyAESettingsPostModel> name="autoEnrll_RedeclarationLastMade" label="Redeclaration last made" />
          <SelectList<CompanyAESettingsPostModel> name="autoEnrll_ChoosenStatus" label="Wizard status" options={autoEnrll_StatusList} />
          <Text<CompanyAESettingsPostModel> name="autoEnrll_Primary_PhoneNumber" label="Primary contact phone" />
          <SelectList<CompanyAESettingsPostModel> name="autoEnrll_TPRNotifiedStatus" label="TPR notified" options={autoEnrll_TPRNotifiedStatus}/>
          <DateSelector<CompanyAESettingsPostModel> name="autoEnrll_ClientNotified" label="Client notified" />
          {values.autoEnrll_ComplianceDeclarationDate &&
          <Text<CompanyAESettingsPostModel> name="autoEnrll_ComplianceDeclarationDate" label="Declaration of Compliance Date" disabled />}
          {values.autoEnrll_ReassessmentDate && 
          <Text<CompanyAESettingsPostModel> name="autoEnrll_ReassessmentDate"  label="Re-assessment date" disabled/>}
          <Submit disabled={isSubmitting || !dirty} onCancelClick={() => {}} />
        </Form>
      )}
    </Formik>
  )
}

export default AEAssessmentTab