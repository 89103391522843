import { useContext, useEffect, useState } from "react";
import { Button } from "../../../elements/Button/Button";
import SearchWithClear from "../../../elements/SearchWithClear/SearchWithClear";
import styles from "./ConnectToBank.module.css"
import BankCard from "./Elements/BankCard";
import SelectedBank from "./Components/SelectedBank";
import {LoadingOpenBankCard} from "../../../elements/LoadingBankCard/LoadingBankCard";
import { BankFeeds } from "../../../api/inni/BankFeeds";
import { useInniAPI } from "../../../hooks/useInniAPI";
import CompanyContext from "../../../context/CompanyContext";
import { BankFeed, CombinedOpenBankingAccountsResponse, OBAuthorisationRequest } from "../../../api/inni/data-contracts";
import SimpleFullscreen from "../../../components/Fullscreen/SimpleFullscreen";
import { useHistoryWrapper } from "../../../hooks/useHistoryWrapper";
import {OpenBankingAccounts} from "../../../api/inni/OpenBankingAccounts";
import SelectedBankUnimportedAccounts from "./Components/SelectedBankUnimportedAccounts";
import { useHasPermission } from "../../../hooks/useHasPermission";
import { Action, Entity } from "../../../utils/EntityAction";
import { useModalDialog } from "../../../hooks/useModalDialog";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { SimpleTerm, useSimpleTerms } from '../../../hooks/terms/useSimpleTerms';

export default function ConnectToBank(){
    const [bankSelected, setBankSelected]= useState<boolean>(false);
    const [showBanks, setShowBanks]= useState<boolean>(true);
    const [showSelectUnimportedAccounts, setShowSelectUnimportedAccounts]= useState<boolean>(false);
    const [loading, setLoading]= useState<boolean>(true);
    const [bankFeed,setBankFeed]=useState<BankFeed[]|undefined>();
    const [filteredBanksData, setFilteredBanksData]= useState<BankFeed[]|undefined>();
    const [searchTerm, setSearchTerm]=useState<string|undefined>(undefined)
    const [selectedBank, setSelectedBank] = useState<BankFeed | undefined>(undefined);
    const [combinedOpenBankingAccounts, setCombinedOpenBankingAccounts] = useState<CombinedOpenBankingAccountsResponse>()
    const bankfeedAPI = useInniAPI(BankFeeds);
    const obAccountsAPI = useInniAPI(OpenBankingAccounts);
    const companyContext = useContext(CompanyContext);
    const companyId = companyContext.cid;
    const history = useHistoryWrapper();
    const [showNoBankForm, setShowNoBankForm] = useState<boolean>(false);
    const [title, setTitle] = useState<string>("");
    const hasPermission = useHasPermission()
    const hasAMSupport = hasPermission(Entity.AccountManagerSupport, Action.All)[0]
    const [showModalDialog, modalDialog] = useModalDialog();
    const simpleTerm = useSimpleTerms();

    useEffect(()=>{
        setLoading(true);
        if(bankfeedAPI){
            bankfeedAPI?.index(companyId).then(res=>{
                setBankFeed(res.data)
                setLoading(false);
            }).catch(err => {
                console.error(err)
            });
        }

    }, [bankfeedAPI, companyId]);

    useEffect(()=>{
        setBankSelected(false);
        setSelectedBank(undefined);
        if(bankFeed){
            const filteredData = bankFeed
                                    .filter(x=>x.name && x.name.toLowerCase().includes(searchTerm?.toLowerCase()||''));
            setFilteredBanksData(filteredData)
        }
    },[bankFeed, searchTerm]);

    function connectToMyBankClick() {
        setBankSelected(false);
        obAccountsAPI?.listCombinedOpenBankingAccounts(companyId,
            { dataProcessorTypeId: selectedBank?.id! })
            .then(res=>{
                if (res.status === 200 && res.data.hasUnimportedAccounts) {
                    setShowSelectUnimportedAccounts(true);
                    setShowBanks(false);
                    setCombinedOpenBankingAccounts(res.data);
                }
                else
                {
                    setShowBanks(false);
                }
            });
    }

    const updateTitle = (title: string) => {
        setTitle(title);
    }

    const handleNoBank = () => {
        if (hasAMSupport) {
            showModalDialog(
                'My bank\'s not listed',
                <>
                    <p>If your bank isn’t listed, it means we can't currently connect to it via Open Banking.</p>
                    <p>To add this account, please contact your account manager.</p>
                    <FontAwesomeIcon icon={faPhoneAlt} style={{ transform: 'scaleX(-1)' }} />
                    &nbsp;&nbsp;Phone:&nbsp;
                    { simpleTerm(SimpleTerm.AppContactPhone) }
                    <br />
                    <FontAwesomeIcon icon={faEnvelope} />
                    &nbsp;&nbsp;Email:&nbsp;
                    <a href={`mailto:${simpleTerm(SimpleTerm.AppContactEmail)}`}>
                        { simpleTerm(SimpleTerm.AppContactEmail) }
                    </a>
                </>,
                [
                    <Button
                        variant="primary"
                        label="Return to previous page"
                        onClick={() => history.goBack()}
                    />
                ],
                true
            );
        } else {
            setShowNoBankForm(true)
        }
    }

    return(
        <SimpleFullscreen
            isOpen={true}
            title={title}
            onClose={() => history.goBack()}
        >
            {!showSelectUnimportedAccounts?
            <div className={styles.outerLayout}>
                <div className={styles.innerLayout}>
                    {showBanks &&
                        <>
                            {showNoBankForm
                                ? (
                                    <>
                                        <iframe
                                            title="My bank's not listed"
                                            aria-label="My bank's not listed"
                                            style={{height: '500px', width: '99%', border: 'none'}}
                                            src={`https://forms.zohopublic.eu/james13/form/Mybanksnotlisted21/formperma/o4a3xvuBfqQ5x5_PyU4ys_epiJL1VWxV2FykQqkTguE?CompanyID=${companyId}`}
                                        ></iframe>

                                        <div>
                                            <Button buttonType="back" label="Go back" variant="secondary"
                                                    onClick={() => {
                                                        setShowNoBankForm(false)
                                                    }}/>
                                        </div>
                                    </>
                                )
                                : (
                                    <>
                                        <h1 className={styles.H2Title}>Connect your bank account</h1>
                                        <p className={styles.normal}>With Provestor, managing your finances becomes a
                                            breeze. Let's begin by connecting your bank. Just select your bank from the
                                            list below, and we’ll guide you through the rest.</p>

                                        <div className={styles.searchBar}>
                                            <SearchWithClear search={searchTerm || ''} setSearch={setSearchTerm}
                                                             placeholder="Search banks"/>
                                        </div>

                                        <div className={styles.banksGrid}>
                                            {!loading && filteredBanksData && filteredBanksData.length > 0 &&
                                                filteredBanksData.map((data) => {
                                                    return (
                                                        <BankCard
                                                            key={data.id}
                                                            name={data.name || ""}
                                                            isBeta={data.isBeta}
                                                            image={data.logoUrl || ""}
                                                            altText={data.name}
                                                            onClick={() => {
                                                                setSelectedBank(data);
                                                                setBankSelected(true)
                                                            }}
                                                            isSelected={data.name === selectedBank?.name}/>
                                                    )
                                                })
                                            }

                                            {!loading && filteredBanksData && filteredBanksData.length === 0 &&
                                                <div className={styles.noResultsGrid}>
                                                    <h3 className="text-muted">No results</h3>
                                                </div>
                                            }

                                            {loading &&
                                                <>
                                                    {Array(8)
                                                        .fill(0)
                                                        .map((_, index) => (
                                                            <div key={index}>
                                                                <LoadingOpenBankCard/>
                                                            </div>
                                                        ))}
                                                </>
                                            }

                                        </div>

                                        <div className={styles.footer}>
                                            <div>
                                                <Button
                                                    label="Connect to my bank"
                                                    variant="primary"
                                                    disabled={!bankSelected} onClick={connectToMyBankClick}
                                                />
                                                <Button
                                                    label="My bank's not listed"
                                                    variant="primary"
                                                    onClick={handleNoBank}
                                                />
                                            </div>

                                            <p className={styles.linkText} onClick={() => history.goBack()}>Cancel</p>
                                        </div>
                                    </>
                                )
                            }
                        </>
                    }
                    {!showBanks && !showSelectUnimportedAccounts &&
                        <SelectedBank
                            companyId={companyId}
                            name={selectedBank?.name!}
                            onClick={() => {
                                setShowBanks(true);
                                setBankSelected(true)
                            }}
                            requestModel={{dataProcessorTypeId: selectedBank?.id} as OBAuthorisationRequest}
                            overrideConnection={selectedBank?.overrideTenantConnection!}
                            motd={selectedBank?.messageOfTheDay}
                            bankFeedMessage={selectedBank?.interstitialMessage}
                        />
                    }
                </div>
            </div>
                :
            <div>
                {!showBanks && showSelectUnimportedAccounts &&
                    <SelectedBankUnimportedAccounts
                        companyId={companyId}
                        bankName={selectedBank?.name!}
                        onCancelClick={() => {
                        setShowBanks(true);
                        setTitle("")
                        setShowSelectUnimportedAccounts(false)
                        setBankSelected(true)
                    }}
                    requestModel={{dataProcessorTypeId: selectedBank?.id} as OBAuthorisationRequest}
                    overrideConnection={selectedBank?.overrideTenantConnection!}
                    motd={selectedBank?.messageOfTheDay}
                    combinedOpenBankingAccounts={combinedOpenBankingAccounts}
                    changeTitle={updateTitle}
                    bankFeedMessage={selectedBank?.interstitialMessage}
                    />
                }
            </div>}

            { modalDialog }

        </SimpleFullscreen>
    );
}