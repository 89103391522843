import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import EnableLogRocket from "../../layouts/Components/EnableLogrocket";


const enableLogrocket:Array<EntityRoute> = [
    {
        path: '/EnableDebugging',
        exact: true,
        context: RouteContext.Public,
        component: EnableLogRocket,
        action: Action.Read
    }, 
]

export default enableLogrocket;