import { formatCurrency } from "../../utils/formatNumbers";
import * as DataGrid from '../../elements/DataGrid/DataGrid';
import { Account, Adjustment } from "../../api/inni/data-contracts";
import { FormikErrors, FormikHelpers } from "formik";
import { FormikRowInput, FormikRowLogic, FormikRowSubmitBtns } from "../../elements/FormikTableEditor/FormikTableEditor";
import { useEffect, useState, useContext } from "react";
import { Button } from "../../elements/Button/Button";
import { useModalDialog } from "../../hooks/useModalDialog";
import React from "react";
import { CogOptionsDropdown } from "../../elements/CogOptionsDropdown/CogOptionsDropdown";
import { GroupedListOption } from "../../elements/EditRow/EditRow";
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';

interface AdjustmentsTableProps {
    adjustments: Adjustment[],
    adjustmentsLoaded: boolean,
    accounts: Account[],
    isReadOnly: boolean
    onUpdate: (values: Adjustment, actions: FormikHelpers<Adjustment>, id: number) => Promise<boolean>,
    onCreate: (values: Adjustment, actions: FormikHelpers<Adjustment>) => Promise<number>,
    onDelete: (id: number) => Promise<void>,
    validateCreate: (values: Adjustment) => Promise<FormikErrors<Adjustment>>,
    validateUpdate: (id: number, values: Adjustment) => Promise<FormikErrors<Adjustment>>
}

export const AdjustmentsTable = ({adjustments, adjustmentsLoaded, accounts, isReadOnly, validateCreate, validateUpdate, onCreate, onDelete, onUpdate} : AdjustmentsTableProps) => {
    const [editingRow, setEditingRow] = useState<number|undefined>(undefined);
    const [addingNewRow, setAddingnewRow] = useState<boolean>(false);
    const [rows, setRows] = useState<(Adjustment)[]>([]);
    const [showModalDialog, modalDialog] = useModalDialog();
    const [accountOptions, setAccountOptions] = useState<GroupedListOption[]>([])
    const companyContext = useContext(CompanyContext);
    const v8Styling = companyContext.company?.useV8UI || false;

    useEffect(() => {
        setRows(adjustments)
        setAddingnewRow(false)
    }, [adjustments])

    useEffect(() => {
        let tempOptions: Array<GroupedListOption> = []
        accounts.filter(x => !x.bankAccount || x.currency === "GBP").forEach(i => {
            const exists = tempOptions.findIndex(x => x.label === i.accountSubGroup)
            if (exists === -1) {
                tempOptions.push({
                    label: i.accountSubGroup || '',
                    options: [{value: i.id.toString(), label: i.name || ''}]
                })
            } else {
                tempOptions[exists].options?.push({value: i.id.toString(), label: i.name || ''})
            }
        })
        setAccountOptions(tempOptions)
    }, [accounts])

    const createNewEmptyRow = () => {
        return {} as Adjustment;
    }
    
    const formik = FormikRowLogic<Adjustment>({
        validateCreate: validateCreate, 
        validateUpdate: validateUpdate, 
        onCreate: onCreate, 
        onUpdate: onUpdate, 
        editRowValues: editingRow !== undefined && editingRow !== null ? rows[editingRow] : undefined, 
        addingNewRow: addingNewRow,
        id: editingRow !== undefined && rows[editingRow] ? rows[editingRow].id : undefined })

    const handleSubmit = () => {
        if(!formik.isSubmitting && formik.values.accountId !== undefined) {
            formik.submitForm().then((newIdOrSuccess:number|void|boolean) => {
                if(newIdOrSuccess !== undefined && newIdOrSuccess !== false) {
                    setEditingRow(undefined)
                    setAddingnewRow(false)
                }
            })
        }
        else if(formik.values.accountId === undefined)  {
            
            formik.setFieldTouched("accountId", true).then(() =>{
                formik.setErrors({accountId : "Required"})
            })
            
        }
    }

    const addNewEmptyRow = () => {
        formik.setValues(createNewEmptyRow())
        setRows([...rows, createNewEmptyRow()])
        setEditingRow(rows.length) //Length is index + 1. When adding a row, the index we need will be exactly that
        setAddingnewRow(true)
        formik.setErrors({});
        formik.setFieldTouched("accountId", false);
    }

    const handleClose = () => {
        setEditingRow(undefined); 
        if(addingNewRow) {
            setAddingnewRow(false);
            let newRows = [...rows]
            newRows.pop()
            setRows(newRows)
        }
    }

    const showDeleteDialog = (row: Adjustment) => {
        showModalDialog(
            'Delete adjustment?',
            'Are you sure you want to delete this line?',
            [
                <Button variant="danger" label="Yes" onClick={() => handleDelete(row)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const handleDelete = (row:Adjustment) => {
        row.id !== undefined && onDelete && onDelete(row.id).then(res => {
            setRows(rows.filter((newE) => newE !== row))
        })
    }

    const handleSetEditRow = (rowIndex:number) => {
        addingNewRow && handleClose()
        setEditingRow(rowIndex)
    }

    const renderCreateEditRow = () => {
        return (<>
            <td style={{verticalAlign: 'top'}}>
                <FormikRowInput<Adjustment> formik={formik} property={"accountId"} type="groupedselect" groupedOptions={accountOptions}/>
            </td>
            <td></td>
            <td style={{verticalAlign: 'top'}}>
                <FormikRowInput<Adjustment> 
                    formik={formik} 
                    property={"debit"} 
                    type="number" 
                    prefix={<>&pound;</>} 
                    inputProps={{
                                    min: 0,
                                    step: 0.01,
                                    onKeyDown:  e => {
                                        if (e.key === '-' ) {
                                            e.preventDefault();
                                        }
                                    }
                                }}
                />
            </td>
            <td style={{verticalAlign: 'top'}}>
                <FormikRowInput<Adjustment> 
                    formik={formik} 
                    property={"credit"} 
                    type="number" 
                    prefix={<>&pound;</>} 
                    inputProps={{
                                    min: 0,
                                    step: 0.01,
                                    onKeyDown:  e => {
                                        if (e.key === '-' ) {
                                            e.preventDefault();
                                        }
                                    }
                                }}
                />
            </td>
            <td></td>
            <td style={{textAlign: 'right', minWidth: '190px', verticalAlign: 'top'}}>
                <FormikRowSubmitBtns handleSubmit={handleSubmit} handleClose={handleClose} addLabel />
            </td>
        </>)
    }

    return (
        <>
            <DataGrid.Table noHover>
                <thead>
                    <tr>
                        <th style={{minWidth: "350px"}}>Account</th>
                        <th>Opening balance</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Closing balance</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {!adjustmentsLoaded && 
                        <>
                            <DataGrid.LoadingRow cols={6}  />
                            <DataGrid.LoadingRow cols={6}  />
                            <DataGrid.LoadingRow cols={6}  />
                        </>
                    }
                    {adjustmentsLoaded && rows.map((adj, index) => {
                        return (
                            <React.Fragment key={index}>
                                { editingRow === index &&
                                    <tr key={index}>
                                        { renderCreateEditRow() }
                                    </tr>
                                }
                                
                                { editingRow !== index && <tr key={index}>
                                    <td>{accounts.find(x => x.id === adj.accountId)?.name}</td>
                                    <td>{formatCurrency(adj.openingBalance)}</td>
                                    <td>{formatCurrency(adj.debit)}</td>
                                    <td>{formatCurrency(adj.credit)}</td>
                                    <td>{formatCurrency(adj.closingBalance)}</td>
                                    <td style={{textAlign: 'right', width: '10%'}}>
                                        {!isReadOnly && <CogOptionsDropdown
                                            edit={() => handleSetEditRow(index)}
                                            del={() => showDeleteDialog(adj)}
                                            hamburger={v8Styling}
                                        />}
                                    </td>
                                </tr> }
                            </React.Fragment>
                        )
                    })}

                    {<tr>
                        <td></td>
                        <td></td>
                        <td style={{fontWeight: "bold"}}>{formatCurrency(adjustments.reduce((a, b) => a + ((b.debit || 0) || 0), 0))}</td>
                        <td style={{fontWeight: "bold"}}>{formatCurrency(adjustments.reduce((a, b) => a + ((b.credit || 0) || 0), 0))}</td>
                        <td></td>
                        <td></td>
                    </tr>}

                    {!addingNewRow && !isReadOnly && <tr>
                        <td colSpan={6}>
                            <Button buttonType='new' label="Add line" onClick={addNewEmptyRow} />
                        </td>
                    </tr>}
                </tbody>
            </DataGrid.Table>

            { modalDialog }
        </>
    )
}