/*
    'Simple' terms are straightforward key / value pairs
*/

import { useContext } from 'react';
import CompanyContext from '../../context/CompanyContext';

export enum SimpleTerm {
    AppName,
    AppContactPhone,
    AppContactEmail,
    MenuLabelInvoicing,
    MenuLabelCompanyInformation,
    MenuLabelPeople,
    CompanyName,
    MenuLabelCompanyInformationAdmin,
    MenuLabelDocuments
}

export const useSimpleTerms = () : (term: SimpleTerm) => string => {

    const terms = useContext(CompanyContext).terms;

    return (term) => {
        if (terms) {
            let t = terms.simpleTerms?.find((t) => SimpleTerm[t.term as keyof typeof SimpleTerm] === term)
            if (t) return t.value || '';
            console.error(`Couldn't find term ${term.toString()}`);
            return '';    
        } else {
            console.error(`Terms not loaded`);
            return '';    
        }
    }
}
