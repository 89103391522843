import React, { useRef } from 'react';
import { CompanySummary } from '../../api/inni/data-contracts';
import { User } from '../../context/UserContext';
import { Dropdown } from 'react-bootstrap';
import styles from './TopBar.module.scss';
import { useGetUrl } from '../../hooks/useGetUrl';
import { Entity, Action } from '../../utils/EntityAction';
import * as Icons from './icons';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import configHelper, { Brand, Platform } from '../../utils/configHelper';
import classNames from 'classnames';
import { useHasPermission } from '../../hooks/useHasPermission';


interface CompanySelectorDDMenuProps {
    user?: User,
    companies?: Array<CompanySummary>,
    hasSearchCompanies?: boolean,
    logout?: () => void,
    contextLevel?: 'contact' | 'company',
    useMobileLayout: boolean,
    setSearch: (val: string) => void,
    search: string,
    retrievedCompanies: Array<CompanySummary>
    v8Styling?: boolean
}


// Company is the current company
// Companies is the list of all companies they can access
export const CompanySelectorDDMenu = ({ user, companies, hasSearchCompanies, logout, contextLevel, useMobileLayout, search, setSearch, retrievedCompanies, v8Styling }: CompanySelectorDDMenuProps) => {
    const getUrl = useGetUrl();
    const searchInput = useRef<HTMLInputElement>(null);
    const navigateToEntity = useNavigateToEntity();
    const hasPermission = useHasPermission()(Entity.OnboardingSetup, Action.Read)[0]
    
    const logoutClick = (e: React.MouseEvent<HTMLElement>) => {
        if (logout) logout();

        // Clean up any temporary (temp_) storage
        for (let i = window.sessionStorage.length; i >= 0; i -= 1) {
            const storageKey = window.sessionStorage.key(i);
            if (storageKey && /^temp_/.test(storageKey)) {
                window.sessionStorage.removeItem(storageKey);
            }
        }

        e.preventDefault();
    }

    const redirect = (companyId : number, isCAP: boolean) =>{
        if(isCAP && hasPermission){
            navigateToEntity(Entity.OnboardingSetup, Action.List, { cid: companyId})
        }
        else{
            navigateToEntity(Entity.CompanyDashboard, Action.List, { cid: companyId})
        }
    }

    const getHREFUrl =(companyId : number, isCAP: boolean): string | undefined =>{
        const url = getUrl((isCAP && hasPermission) ? Entity.OnboardingSetup :  Entity.CompanyDashboard, Action.List, { cid: companyId });
        if (url) {
            const newUrl = new URL(window.location.href).origin;
            return newUrl +'/v7/' + url.url.slice(1);
        }
        return undefined
    }

    return (
        <Dropdown.Menu 
            align="right" 
            className={classNames({[styles.mobileDropdownMenu]: useMobileLayout, 
                                    [styles.dropDownMenuV8]: v8Styling,
                                    [styles.dropDownMenuV8Search] :( v8Styling && retrievedCompanies && retrievedCompanies.length >= 1 )
                                })
                    }
        >
            {hasSearchCompanies
                // admin: search for companies
                ? (
                    <>
                        <div className={styles.searchBox}>
                            <Icons.CompanySwitch className={styles.dropDownIcon} />
                            <input
                                type="text"
                                autoFocus={true}
                                ref={searchInput}
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search companies"
                            />
                        </div>

                        {retrievedCompanies.map((c) => {
                            // If we're in PV hub we need to link to the app and leave hub
                            const hubToApp = (configHelper.brand === Brand.Provestor && configHelper.platform === Platform.Hub);
                            return (
                                <Dropdown.Item
                                    className={useMobileLayout ? styles.mobileDropdownMenuItem : ''}
                                    key={c.id}
                                    href={hubToApp ? getHREFUrl(c.id, c.isCAP): undefined}
                                    onClick={hubToApp ? undefined : () => redirect(c.id, c.isCAP) }
                                >
                                    <Icons.CompanySwitchResult className={styles.dropDownIcon} /><span className={useMobileLayout ? styles.dropDownItemMobile : styles.dropDownItem}>{c.name}</span>
                                </Dropdown.Item>
                            );
                        })}

                        <Dropdown.Divider />
                    </>
                )
                // user: show preset companies
                : (
                    companies?.map((c) => {
                        // If we're in PV hub we need to link to the app and leave hub
                        const hubToApp = (configHelper.brand === Brand.Provestor && configHelper.platform === Platform.Hub);
                        return (
                            <Dropdown.Item
                                className={useMobileLayout ? styles.mobileDropdownMenuItem : ''}
                                key={c.id}
                                href={hubToApp ? getHREFUrl(c.id, c.isCAP) : undefined}
                                onClick={hubToApp ? undefined : () => redirect(c.id, c.isCAP)}
                            >
                                <Icons.CompanySwitchResult className={styles.dropDownIcon} /><span className={useMobileLayout ? styles.dropDownItemMobile : styles.dropDownItem}>{c.name}</span>
                            </Dropdown.Item>
                        );
                    })
                )
            }

            {(companies && companies.length > 0) && <Dropdown.Divider />}

            {/* Currently only mobile app doesn't have access to user profile */}
            {user && (
                <>
                    <Dropdown.Item
                        onClick={
                            contextLevel === 'company'
                                ? () => navigateToEntity(Entity.UserProfile, Action.Edit)
                                : () => navigateToEntity(Entity.Contact, Action.Edit)
                        }
                        className={useMobileLayout ? styles.mobileDropdownMenuItem : ''}
                    >
                        <Icons.Profile className={styles.dropDownIcon} />
                        <span className={useMobileLayout ? styles.dropDownItemMobile : styles.dropDownItem}>My profile &amp; password</span>
                    </Dropdown.Item>

                    <Dropdown.Divider />
                </>
            )}

            <Dropdown.Item onClick={logoutClick} className={useMobileLayout ? styles.mobileDropdownMenuItem : ''}>
                <Icons.Logout className={styles.dropDownIcon} /><span className={useMobileLayout ? styles.dropDownItemMobile : styles.dropDownItem}>Sign out</span>
            </Dropdown.Item>
        </Dropdown.Menu>
    )
}
