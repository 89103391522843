import { Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { useContext, useState } from 'react'
import { PayeSetupP45PostModel } from '../../../../api/inni/data-contracts'
import { Modal } from '../../../../components/Modal/Modal'
import CompanyContext from '../../../../context/CompanyContext'
import { Currency, DateSelector, ListOption, SelectList, Switch, Text } from '../../../../elements/EditRow/EditRow'
import { useInniAPI } from '../../../../hooks/useInniAPI'
import { payPeriodTypes } from '../../../../utils/commonLists'
import { People } from '../../../../api/inni/People'
import { PersonAsDetailed } from '../../../../api/inni/data-contracts'
import { Button } from '../../../../elements/Button/Button'
import styles from '../../People.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { useValidateFormikPage } from '../../../../hooks/useValidateFormikPage'
import { asYearMonthDay } from '../../../../utils/formatDate'

const pageFields : Array<Array<keyof PayeSetupP45PostModel>> = [
    ['employeeStartDate', 'nino', 'niTable', 'salary', 'studentLoan', 'postGraduateLoan', 'defaultEmployee'],
    ['leavingDate', 'payeOffice', 'payeReference', 'periodType', 'lastPayPeriod', 'taxCode', 'oneWeekOneMonth', 
    'grossPayToDate', 'taxToDate', 'carMiles'],
    ['migrationPay', 'migrationTax', 'migrationEENIC', 'migrationERNIC']
]

interface SetupP45ModalProps {
    person : PersonAsDetailed | undefined, 
    personId : number, 
    show : boolean, 
    onClose : () => void, 
    reloadPeople : () => void
}

const SetupP45Modal = ({person, personId, show, onClose, reloadPeople} : SetupP45ModalProps) => {

    const companyContext = useContext(CompanyContext)
    const peopleApi = useInniAPI(People, [400])

    const [payPeriodSl] = useState<ListOption[]>(payPeriodTypes.map(x => ({ value: x.value, label: x.label } as ListOption)))

    const [page, setPage] = useState(1)    
    const validatePage = useValidateFormikPage<PayeSetupP45PostModel>()

    const validateUpdate = (values: PayeSetupP45PostModel) : Promise<FormikErrors<PayeSetupP45PostModel>> => {
        return new Promise((resolve) => {
            if (peopleApi) {
                peopleApi.validatePayeSetupP45(companyContext.cid, personId, values)
                .then(res => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const updateCall = (values: PayeSetupP45PostModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (peopleApi) {
                peopleApi.payeSetupP45(companyContext.cid, personId, values)
                .then(res => {
                    if (res.status === 200) {
                        reloadPeople()
                        onClose()
                    }
                    resolve()
                }).catch(err => reject(err.error))
            }
        })
    }

    const handleClose = () => {
        setPage(1)
        onClose()
    }

    const validateCurPage = async (
        setTouched: (touched: FormikTouched<PayeSetupP45PostModel>, shouldValidate?: boolean | undefined) => void, 
        validateForm: (values?: any) => Promise<FormikErrors<PayeSetupP45PostModel>>
    ) => {
        if (await validatePage(pageFields[page-1], setTouched, validateForm)) 
            setPage(page+1) 
    }

    return (
        <Modal size="xl" showModal={show} hideModal={handleClose} title="Setup PAYE with a P45">
            
            <Formik
                initialValues={{
                    periodType: 'W',
                    employeeStartDate: asYearMonthDay(new Date()),
                    nino: "",
                    niTable: "",
                    salary: 0,
                    studentLoan: false,
                    postGraduateLoan: false,
                    defaultEmployee: false,
                    leavingDate: "",
                    payeOffice: "",
                    payeReference: "",
                    lastPayPeriod: 0,
                    taxCode: "",
                    oneWeekOneMonth: false,
                    grossPayToDate: 0,                                                                                                      
                    taxToDate: 0,
                    carMiles: 0
                } as PayeSetupP45PostModel}
                validateOnChange={false}
                onSubmit={updateCall}
                validate={validateUpdate}
            >
                {({ setTouched, validateForm }) => (

                    <Form>
                        {page === 1 &&
                        <>
                            <DateSelector<PayeSetupP45PostModel> name="employeeStartDate" labelTop />
                            <Text<PayeSetupP45PostModel> name="nino" labelTop label="N.I. number" />
                            <Text<PayeSetupP45PostModel> name="niTable" labelTop label="N.I. table" />
                            <Currency<PayeSetupP45PostModel> flipToPositive name="salary" labelTop />
                            <Switch<PayeSetupP45PostModel> labelTop name="studentLoan" />
                            <Switch<PayeSetupP45PostModel> labelTop name="postGraduateLoan" />
                            <Switch<PayeSetupP45PostModel> labelTop name="defaultEmployee" />
                            {person?.isDirector &&
                                <Switch<PayeSetupP45PostModel> labelTop name="useAlternateNICal"  label="Use alternative NI calculation"/>
                            }
                            
                        </>}
                        {page === 2 && <>
                            <h3>From P45</h3>
                            <DateSelector<PayeSetupP45PostModel> name="leavingDate" labelTop />
                            <Text<PayeSetupP45PostModel> name="payeOffice" labelTop label="PAYE Office No" />
                            <Text<PayeSetupP45PostModel> name="payeReference" labelTop label="PAYE Ref No" />
                            <SelectList labelTop options={payPeriodSl} name="periodType" />                              
                            <Text<PayeSetupP45PostModel> min={0} name="lastPayPeriod" labelTop type="number" />
                            <Text<PayeSetupP45PostModel> name="taxCode" labelTop />
                            <Switch<PayeSetupP45PostModel> name="oneWeekOneMonth" label="Week 1 / Month 1 basis" labelTop />
                            <Currency<PayeSetupP45PostModel> labelTop name="grossPayToDate" />
                            <Currency<PayeSetupP45PostModel> labelTop name="taxToDate" />
                            <Text<PayeSetupP45PostModel> min={0} name="carMiles" labelTop type="number" label="Car miles from 6th April to leaving date" />
                        </>}                                                                                                                                                                                                                                                    
                        {person && person.showMigration && page === 3 && <>
                            <h3>Migration</h3>
                            <Currency<PayeSetupP45PostModel> flipToPositive labelTop name="migrationPay" label="Pay" />
                            <Currency<PayeSetupP45PostModel> flipToPositive labelTop name="migrationTax" label="Tax" />
                            <Currency<PayeSetupP45PostModel> flipToPositive labelTop name="migrationERNIC" label="ERNIC" />
                            <Currency<PayeSetupP45PostModel> flipToPositive labelTop name="migrationEENIC" label="EENIC" />
                        </>}

                        <div className={styles.buttonContainer}>
                            <Button buttonType="cancel" onClick={handleClose} />
                            {page !== 1 && <Button onClick={() => setPage(page-1)} buttonType="back" />}
                            {(page === 1 || (page === 2 && person?.showMigration)) && 
                                <Button onClick={() => validateCurPage(setTouched, validateForm)} 
                                variant="primary">Continue <FontAwesomeIcon icon={faChevronRight} style={{marginRight: '0px', marginLeft: '0.5em'}} /></Button>}
                            {(page === 3 || (page === 2 && !person?.showMigration)) && <Button submitButton buttonType="save" />}
                        </div>

                    </Form>
                )}
            </Formik>

        </Modal>
    )

}

export default SetupP45Modal