import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { Alert as BootstrapAlert } from 'react-bootstrap'
import styles from './Alert.module.scss'

type AlertVariant = 'success' | 'warning' | 'info'

interface AlertProps {
    alertMessage: string | undefined
    variant: AlertVariant
    setAlertMessage?: (i: string | undefined) => void
    dismissable?: boolean
    showCheck?: boolean
    inline?: boolean
}

const Alert = ({alertMessage, setAlertMessage, variant, dismissable, showCheck, inline}: AlertProps) => { 
    return (
        <BootstrapAlert 
            show={alertMessage !== undefined}
            variant="success" 
            dismissible={dismissable}
            onClose={setAlertMessage ? () => setAlertMessage(undefined) : undefined}
            className={classNames(
                styles.alert,
                styles[variant],
                {
                    [styles.inline]: inline
                }
            )
        }>
            {showCheck && <FontAwesomeIcon size="lg" icon={faCheckCircle} />} {alertMessage}
        </BootstrapAlert>
    )
    
}

export default Alert