import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Accounts } from '../../api/inni/Accounts';
import { BankTransactions } from '../../api/inni/BankTransactions';
import { Account, BankTransaction, DatePicker } from '../../api/inni/data-contracts';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { useInniAPI } from '../useInniAPI';
type TOption = { value: number; label: string };
const useTransactions = (all?: boolean, defaultYearCode?: string) => {
	const { cid } = useContext(CompanyContext);
	const transactionsApi = useInniAPI(BankTransactions);
	const accountsApi = useInniAPI(Accounts);
	const [accounts, setAccounts] = useState<Account[]>([]);
	const [accountOptions, setAccountOptions] = useState<Account[]>([])
	const [transactions, setTransactions] = useState<BankTransaction[]>([]);
	const [selectedAccount, setSelectedAccount] = useState<TOption>({ value: 0, label: '' });
	const [currentYearCode, setCurrentYearCode] = useState<string | undefined>(undefined);
	const [dateSelection, setDateSelection] = useState<[Date | undefined, Date | undefined]>([undefined, undefined]);
	const [loading, setLoading] = useState(false);
	const [accountsLoaded, setAccountsLoaded] = useState(false);
	const companyContext = useContext(CompanyContext)

	useEffect(() => {
		if (accountsApi && cid) {
			accountsApi.index(cid).then(({ data }) => {
				let tempAccounts = data
				if(companyContext.product === CompanyProduct.isCap) {
					tempAccounts = data.filter(x => x.hasCategory)
				}

				setAccounts(tempAccounts);
				setAccountOptions(tempAccounts.filter((v) => v.bankAccount && !v.isExpenseAccount));
			}).finally(() => setAccountsLoaded(true));
		}
	}, [accountsApi, cid, companyContext.product]);

	useEffect(() => {
		let isSubscribed = true;
		if (transactionsApi && cid && currentYearCode) {
			setLoading(true);
			transactionsApi
				.index(cid, { yearCode: currentYearCode, accountId: selectedAccount?.value || undefined, hasImage: false })
				.then(({ data }) => isSubscribed  && setTransactions(data))
				.catch((err) => console.log(err))
				.finally(() => setLoading(false));
		}
	
		return () => {isSubscribed = false}
	}, [transactionsApi, cid, currentYearCode, selectedAccount?.value]);


	useEffect(() => {
		!all && accountOptions && accountOptions[0] && setSelectedAccount({ value: accountOptions[0].id, label: accountOptions[0].name });
	}, [accountOptions, all]);

	const getFilter = useCallback(
		(yearCode: string | undefined): Promise<DatePicker> => {
			if (!selectedAccount) {
				return new Promise<DatePicker>((resolve, reject) => reject());
			}
			const p = new Promise<DatePicker>((resolve, reject) => {
				defaultYearCode && transactionsApi
					?.filter(cid, { accountId: selectedAccount.value, yearCode: yearCode || defaultYearCode })
					.then((results) => {
						if (results.data.datePicker) {
							resolve(results.data.datePicker);
						} else reject();
					})
					.catch((error) => reject(error));
			});
			return p;
		},
		[cid, selectedAccount, transactionsApi, defaultYearCode]
	);
	const currentYearId = useMemo(() => +(currentYearCode || '').replace(/\D/g, ''), [currentYearCode]);
	return {
		setSelectedAccount,
		selectedAccount,
		accounts,
		getFilter,
		setCurrentYearCode,
		setDateSelection,
		currentYearCode,
		loading,
		transactions,
		accountOptions,
		dateSelection,
		currentYearId,
		accountsLoaded,
	};
};

export default useTransactions;
