import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import styles from './ZoomImage.module.css'

const ZoomImage = ({src,alt, maxHeight} : {src : string, alt? : string, maxHeight? : string}) => {

    const [backgroundPosition, setBackgroundPosition] = useState<string>('0% 0%')

    const handleMouseMove = (e : any) => {
        const { left, top, width, height } = e.target.getBoundingClientRect()
        const x = (e.pageX - left) / width * 100
        const y = (e.pageY - top) / height * 100
        setBackgroundPosition(`${x}% ${y}%`)
    }
    return (
        <figure onMouseMove={handleMouseMove} className={styles.figure} style={{backgroundPosition: backgroundPosition, backgroundImage: `url("${src}")`, maxHeight: maxHeight}}>
            <div><FontAwesomeIcon icon={faSearch} /> hover</div>
            <img style={{maxHeight: maxHeight}} src={src} alt={alt} />
        </figure>
    )
}

export default ZoomImage