import * as DataGrid from '../../../../elements/DataGrid/DataGrid';
import { useInniAPI } from '../../../../hooks/useInniAPI';
import { PensionStatuses } from '../../../../api/inni/PensionStatuses'
import React, { useContext, useEffect, useState } from 'react';
import CompanyContext from '../../../../context/CompanyContext';
import { PensionStatus, PensionStatusPostModel, PersonAsDetailed } from '../../../../api/inni/data-contracts';
import { formatDate } from '../../../../utils/formatDate';
import { formatPercent } from '../../../../utils/formatNumbers';
import { Button } from '../../../../elements/Button/Button';
import { FormikErrors, FormikHelpers } from 'formik';
import { FormikRowInput, FormikRowLogic } from '../../../../elements/FormikTableEditor/FormikTableEditor';
import { ListOption } from '../../../../elements/EditRow/EditRow';
import { NoContentSlate } from '../../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../../hooks/terms/useDatagridTerms';
import { Action, Entity } from '../../../../utils/EntityAction';
import { isAfter } from 'date-fns';
import { CogOptionsDropdown } from '../../../../elements/CogOptionsDropdown/CogOptionsDropdown';
import { useModalDialog } from '../../../../hooks/useModalDialog';

const PensionStatusList = ({person} : {person : PersonAsDetailed | undefined}) => {
    const pensionsApi = useInniAPI(PensionStatuses, [400])
    const companyContext = useContext(CompanyContext)

    const [pensions, setPensions] = useState<PensionStatus[] | undefined>(undefined)
    const [pensionsLoaded, setPensionsLoaded] = useState(false)

    const [providersSl, setProvidersSl] = useState<ListOption[]>([])
    const [statusSl, setStatusSl] = useState<ListOption[]>([])

    const [adding, setAdding] = useState(false)
    const [editing, setEditing] = useState<number | undefined>(undefined)

    const [showModalDialog, modalDialog] = useModalDialog();

    useEffect(() => {
        setPensionsLoaded(false)
    }, [person])

    useEffect(() => {
        if (pensionsApi && !pensionsLoaded && person && person.employmentId) {
            pensionsApi.get(companyContext.cid, person.employmentId)
            .then(res => {
                setPensions(res.data)
                setPensionsLoaded(true)
            })
        } else if (person && !person.employmentId) {
            setPensionsLoaded(true)
        }
    }, [companyContext.cid, pensionsApi, pensionsLoaded, person])

    useEffect(() => {
        if (pensionsApi) {
            pensionsApi.getStatusSelectList(companyContext.cid)
            .then(res => {
                let tempData = res.data
                let tempLo : ListOption[] = []
                tempData.map(x => tempLo.push(({ label: x.name?.replace(/([a-z])([A-Z])/g, '$1 $2'), value: x.id.toString() } as ListOption)))
                setStatusSl(tempLo)
            })
        }
    }, [companyContext.cid, pensionsApi])

    useEffect(() => {
        if (pensionsApi) {
            pensionsApi.getProvidersSelectList(companyContext.cid)
            .then(res => {
                let tempData = res.data
                let tempLo : ListOption[] = []
                tempData.map(x => tempLo.push(({ label: x.name, value: x.id.toString() } as ListOption)))
                setProvidersSl(tempLo)
            })            
        }
    }, [companyContext.cid, pensionsApi])

    const validateCreate = (values: PensionStatusPostModel) : Promise<FormikErrors<PensionStatusPostModel>> => {
        return new Promise((resolve) => {
            if (pensionsApi) {
                pensionsApi.validateCreate(companyContext.cid, values)
                .then(() => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const createCall = (values: PensionStatusPostModel) : Promise<number> => {
        return new Promise((resolve, reject) => {
            if (pensionsApi) {
                pensionsApi.create(companyContext.cid, person?.employmentId || 0, values)
                .then(res => {
                    if (res.status === 200) {
                        setPensions([...pensions || [], 
                            { effectiveDate: values.effectiveDate, 
                            statusId: values.statusId, 
                            status: statusSl.find(x => x.value === String(values.statusId))?.label, 
                            id: parseInt(res.data), 
                            employeeContributionPct: values.employeeContributionPct, 
                            employerContributionPct: values.employerContributionPct, 
                            employeeID: person?.id || 0,
                            companyPensionProvider: providersSl.find(x => x.value === String(values.companyPensionProviderId))?.label,
                            companyPensionProviderId: values.companyPensionProviderId
                        }])
                        setAdding(false)
                    }
                    resolve(parseInt(res.data))
                }).catch(err => reject(err.error))
            }
        })
    }

    const validateUpdate = (id: number, values: PensionStatusPostModel) : Promise<FormikErrors<PensionStatusPostModel>> => {
        return new Promise((resolve) => {
            if (pensionsApi) {
                pensionsApi.validateUpdate(companyContext.cid, id, values)
                .then(() => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }

    const editCall = (values: PensionStatusPostModel, _actions: FormikHelpers<PensionStatusPostModel>, id : number) : Promise<void> => {
        
        return new Promise((resolve, reject) => {
            if (pensionsApi) {
                pensionsApi.update(companyContext.cid, person?.employmentId || 0, id, values)
                .then(res => {
                    if (res.status === 200) {
                        const tempPensions = pensions
                        if (pensions) {
                            const index = pensions?.findIndex(x => x.id === id)
                            if (tempPensions && index !== -1) {
                                tempPensions[index].effectiveDate = values.effectiveDate
                                tempPensions[index].statusId = values.statusId
                                tempPensions[index].status = statusSl.find(x => x.value === String(values.statusId))?.label
                                tempPensions[index].employeeContributionPct = values.employeeContributionPct
                                tempPensions[index].employerContributionPct = values.employerContributionPct
                                tempPensions[index].employeeID = person?.id || 0
                                tempPensions[index].companyPensionProvider = providersSl.find(x => x.value === String(values.companyPensionProviderId))?.label
                                tempPensions[index].companyPensionProviderId = values.companyPensionProviderId
                            }
                        }
                        setPensions([...tempPensions || []])
                        setEditing(undefined)
                    }
                    resolve()
                }).catch(err => reject(err.error))
            }
        })

    }

    const showDeleteDialog = (id : number) => {
        showModalDialog(
            'Delete pension?',
            `Are you sure you want to delete this pension?`,
            [
                <Button variant="danger" label="Yes" onClick={() => deletePension(id)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const deletePension = (id : number) => {
        if (pensionsApi) {
            pensionsApi.delete(companyContext.cid, person?.employmentId || 0, id)
            .then(() => {
                setPensions(pensions?.filter(x => x.id !== id))
            })
        }
    }

    //Setup formik instance to be attached to inputs
    const formik = FormikRowLogic<PensionStatusPostModel>({
        editRowValues: editing ? {
            effectiveDate: pensions?.find(x => x.id === editing)?.effectiveDate || '',
            companyPensionProviderId: pensions?.find(x => x.id === editing)?.companyPensionProviderId || 0,
            statusId: pensions?.find(x => x.id === editing)?.statusId || 0,
            employeeContributionPct: pensions?.find(x => x.id === editing)?.employeeContributionPct || 0,
            employerContributionPct: pensions?.find(x => x.id === editing)?.employerContributionPct || 0,
        } : { 
            effectiveDate: "", 
            companyPensionProviderId: providersSl && providersSl[0] ? parseInt(providersSl[0].value || '') : -1,
            statusId: statusSl && statusSl[0] ? parseInt(statusSl[0].value || '') : -1,
            employeeContributionPct: 0,
            employerContributionPct: 0
        }, 
        validateUpdate: validateUpdate,
        validateCreate: validateCreate, 
        onCreate: createCall, 
        onUpdate: editCall, 
        addingNewRow: adding,
        id: editing
    })

    return (<>
        {person && person.showPension &&
        <DataGrid.Table noHover>
            <thead>
                <tr>
                    <th style={{width: '20%'}}>Effective date</th>
                    <th style={{width: '30%'}}>Pension provider</th>
                    <th style={{width: '30%'}}>Status</th>
                    <th style={{width: '10%'}}>Employer contribution</th>
                    <th style={{width: '10%'}}>Employee contribution</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {(!pensionsLoaded || !pensions) && <>
                    <DataGrid.LoadingRow cols={6} />
                    <DataGrid.LoadingRow cols={6} />
                    <DataGrid.LoadingRow cols={6} />
                </>}
                {pensionsLoaded && pensions && pensions.length === 0 &&
                    <tr>
                        <td colSpan={5}><NoContentSlate whiteBg termsKey="emptyTerms" type={DatagridType.PensionSettings} /></td>
                    </tr>
                }
                {pensionsLoaded && pensions &&
                pensions.map(x => <React.Fragment key={x.id}>
                {editing !== x.id ?
                <tr>
                    <td>{formatDate(x.effectiveDate)}</td>
                    <td>{x.companyPensionProvider}</td>
                    <td>{x.status?.replace(/([a-z])([A-Z])/g, '$1 $2')}</td> 
                    <td>{x.employerContributionPct === 0 ? "" : formatPercent(x.employerContributionPct ? (x.employerContributionPct || 1)/100 : 0)}</td>
                    <td>{x.employeeContributionPct === 0 ? "" : formatPercent(x.employeeContributionPct ? (x.employeeContributionPct || 1)/100 : 0)}</td>
                    {/* Can edit a pension setting if the effective date is AFTER today */}
                    <td>{isAfter(new Date(x.effectiveDate || ''), new Date()) && <CogOptionsDropdown adminOnly edit={() => setEditing(x.id)} del={() => showDeleteDialog(x.id)} />}</td>
                </tr> : <tr>
                    <td><FormikRowInput<PensionStatusPostModel> formik={formik} property="effectiveDate" type="date" /></td>
                    <td><FormikRowInput<PensionStatusPostModel> formik={formik} property="companyPensionProviderId" type="select" options={providersSl} /></td>
                    <td><FormikRowInput<PensionStatusPostModel> formik={formik} property="statusId" type="select" options={statusSl} /></td>
                    <td><FormikRowInput<PensionStatusPostModel> type="number" formik={formik} property="employerContributionPct" suffix="%" /></td>
                    <td><FormikRowInput<PensionStatusPostModel> type="number" formik={formik} property="employeeContributionPct" suffix="%" /></td>
                    <td></td>
                </tr>}
                </React.Fragment>)}
                {/* Add row */}
                {adding && 
                <tr key="addRow">
                    <td><FormikRowInput<PensionStatusPostModel> formik={formik} property="effectiveDate" type="date" /></td>
                    <td><FormikRowInput<PensionStatusPostModel> formik={formik} property="companyPensionProviderId" type="select" options={providersSl} /></td>
                    <td><FormikRowInput<PensionStatusPostModel> formik={formik} property="statusId" type="select" options={statusSl} /></td>
                    <td><FormikRowInput<PensionStatusPostModel> type="number" formik={formik} property="employerContributionPct" suffix="%" /></td>
                    <td><FormikRowInput<PensionStatusPostModel> type="number" formik={formik} property="employeeContributionPct" suffix="%" /></td>
                </tr>}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={5}>
                        {!adding && !editing ? <Button onClick={() => setAdding(true)} buttonType="new" action={Action.Edit} entity={Entity.PersonV7} /> :
                        <>
                            <Button buttonType="save" onClick={formik.handleSubmit} />
                            <Button buttonType="cancel" onClick={() => {setAdding(false); setEditing(undefined)}} />                            
                        </>}
                    </td>
                </tr>
            </tfoot>
        </DataGrid.Table>}
        { modalDialog }
    </>)

}

export default PensionStatusList