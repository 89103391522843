import { Badge } from 'react-bootstrap';
import { Property } from '../../../api/inni/data-contracts';
import { HeaderImageWithUpload } from '../../../components/HeaderImageWithUpload/HeaderImageWithUpload';
import { LettableUnitsBadges } from '../Elements/LettableUnitsBadges';
import { formatTenure } from '../../../utils/formatters/formatProperty';
import styles from '../Property.module.scss';
import stylesV75 from '../Property_V75.module.scss';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { Entity, Action } from '../../../utils/EntityAction';
import { ExpandedViewBackLabel } from '../../../elements/ExpandedViewBackLabel/ExpandedViewBackLabel';
// import { Button } from '../../../elements/Button/Button';
// import { useModalDialog } from '../../../hooks/useModalDialog';
import Toolbar from '../../../layouts/Desktop/Toolbar';

export interface PropertyHeaderProps {
    property: Property,
    reloadProperty?: () => void,
    v8Styling: boolean,
    onDeleteClick : (id: number) => void,
    onArchiveClick : (id: number) => void,
    onUnarchiveClick : (id: number) => void
}

export const PropertyHeader = ({property, reloadProperty, v8Styling, onDeleteClick, onArchiveClick, onUnarchiveClick} : PropertyHeaderProps) => {
    const styling = v8Styling ? stylesV75 : styles;
    const navigateToEntity = useNavigateToEntity();
    // const [showModalDialog, modalDialog] = useModalDialog(); 
    
    // const showDeletePropertyDialog = () => {
    //     showModalDialog(
    //       'Delete property?',
    //       `Are you sure you want to delete this property?`,
    //       [
    //         <Button
    //           variant="danger"
    //           label="Yes"
    //           onClick={() => onDeleteClick(property.id)}
    //         />,
    //         <Button variant="secondary" label="No" onClick={() => {}} />,
    //       ],
    //       false
    //     );
    // };

    // const showArchiveDialog = () => {
    //     showModalDialog(
    //       'Archive property?',
    //       `Are you sure you want to archive this property?`,
    //       [
    //         <Button
    //           variant="danger"
    //           label="Yes"
    //           onClick={() => onArchiveClick(property.id)}
    //         />,
    //         <Button variant="secondary" label="No" onClick={() => {}} />,
    //       ],
    //       false
    //     );
    // };

    // const showUnarchiveDialog = () => {
    //     showModalDialog(
    //       'unarchive property?',
    //       `Are you sure you want to unarchive this property?`,
    //       [
    //         <Button
    //           variant="danger"
    //           label="Yes"
    //           onClick={() => onUnarchiveClick(property.id)}
    //         />,
    //         <Button variant="secondary" label="No" onClick={() => {}} />,
    //       ],
    //       false
    //     );
    // };

    return (
        <div id={styling.propertyHeader} data-test-id='propertyRead'>
            { v8Styling 
                ? (
                    <ExpandedViewBackLabel
                        backLabel="Properties"
                        title={property.nickname || property.name}
                        onClick={() => navigateToEntity(Entity.Property, Action.List)}
                        label={property.isInactive ? 'Archived' : undefined}
                    >
                        <Toolbar>
                            {/* This has moved to subtitle, cleanup when happy correct decision */}
                            {/* <Button 
                                buttonType='delete' 
                                onClick={showDeletePropertyDialog} 
                                disabled={!property.editability?.canDelete}  
                                disabledMessage={property.editability?.cantDeleteReason}
                                action={Action.Delete} 
                                entity={Entity.Property}
                                headerBtn
                            />
              
                            <Button 
                                variant='dark' 
                                onClick={property.isInactive ? showUnarchiveDialog : showArchiveDialog} 
                                label={property.isInactive ? 'Unarchive' : 'Archive'}
                                action={Action.Edit} 
                                entity={Entity.Property}
                                headerBtn
                                outline
                            /> */}
                        </Toolbar>
                    </ExpandedViewBackLabel>
                )
                : (
                    <>
                        <div className={styling.image}>
                            <HeaderImageWithUpload property={property} reloadProperty={reloadProperty} />
                        </div>
                        <div className={styling.content}>
                            <h1>{property.nickname || property.name}</h1>
                            <div className='address'>{property.address} {property.postcode}</div>
                            <div> 
                                <h4 className="d-inline">
                                    <Badge pill variant='secondary' className='mr-2'>{formatTenure(property.tenure)}</Badge>
                                    <LettableUnitsBadges units={property.lettableUnits} />
                                </h4>
                            </div>
                            {/* { property &&

                                <div className={styling.headerButtons}>
                                    {property.isInactive ?
                                            <Button 
                                                variant='secondary' 
                                                onClick={showUnarchiveDialog} 
                                                label='Unarchive'
                                                action={Action.Edit} 
                                                entity={Entity.Property}
                                            />
                                        :
                                            <Button 
                                                variant='secondary' 
                                                onClick={showArchiveDialog} 
                                                label='Archive'
                                                action={Action.Edit} 
                                                entity={Entity.Property}
                                            />
                                    }
                    
                                    <Button 
                                        buttonType='delete' 
                                        onClick={showDeletePropertyDialog} 
                                        disabled={!property.editability?.canDelete}  
                                        disabledMessage={property.editability?.cantDeleteReason}
                                        action={Action.Delete} 
                                        entity={Entity.Property}
                                    />
                                </div> 

                            } */}
                        </div>
                    </>
                )
            }
            {/* {modalDialog} */}
        </div>
    )
}