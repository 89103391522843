/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Market, MarketTerms } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Markets<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Markets
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/market
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Market, any>({
      path: `/api/v2/companies/${companyId}/market`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Markets
   * @name MarketTerms
   * @request GET:/api/v2/companies/{companyId}/market/Terms
   * @secure
   */
  marketTerms = (companyId: number, params: RequestParams = {}) =>
    this.request<MarketTerms, any>({
      path: `/api/v2/companies/${companyId}/market/Terms`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
