import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Account, BankTransactionUnbilledPayments, InvoiceAsDetailed, InvoiceLinePostModel, MileageBillable, TimesheetItem } from '../../../../api/inni/data-contracts';
import { asYearMonthDay, formatDate } from '../../../../utils/formatDate';
import { getUniqueValues } from '../../HelperFunctions/HelperFunctions';
import DateSelector from '../../Components/DateSelector';
import styles from './Dropdown.module.css';
import classNames from 'classnames';

type InvoiceDropdownProps = {
	onSelect: (eventKey: string | number, values: InvoiceLinePostModel) => void;
	accounts?: Account[];
	showTimeElements?: boolean;
	mileageBillable?: MileageBillable[] | undefined;
	bankpayements?: BankTransactionUnbilledPayments[] | undefined;
	timeSheets: TimesheetItem[] | undefined;
	invoiceData : InvoiceAsDetailed;
};

type interfaceDropdownChildProps = {
	onSelect: (eventKey: any) => void;
	dropdownTitle: string;
	dropdownValues: any[] | undefined;
	type: string;
	isProperty?: boolean;
	lines: InvoiceLinePostModel[];
};

export type TimeSheetSummary = {
	id: number;
	weekStart: string;
	firstDate: string;
	lastDate: string;
	hours: number;
	keys: number[];
	rate: number;
};

const DropdownButtons = ({ onSelect,showTimeElements,invoiceData ,accounts, mileageBillable, bankpayements, timeSheets }: InvoiceDropdownProps) => {
	const {invoiceCurrency,
			documentType,
			lines,
			showInvoiceVAT,
			currencyRateAtTaxPoint,
			} = invoiceData; 
	const intialValues: Partial<InvoiceLinePostModel> = {
		quantity: 1,
		unitPrice: 0,
		type: 'Freetype',
		vatCode: 'S',
	};
	const [modalShow, setModalShow] = useState(false);
	const [timeSheetSummary, setTimeSheetSummary] = useState<TimeSheetSummary[] | undefined>(undefined);
	const [selectedTime, setSelectedTime] = useState<{
		startDate: string;
		endDate: string;
	}>();
	useEffect(() => {
		if (timeSheets) {
			if (selectedTime && selectedTime.startDate !== '' && selectedTime.endDate !== '') {
				setTimeSheetSummary(getSummary(timeSheets));
			} else {
				setTimeSheetSummary(getSummary(timeSheets));
			}
		}
	}, [timeSheets, selectedTime?.startDate, selectedTime?.endDate, selectedTime]);

	const getSummary = (timeSheets: TimesheetItem[]) => {
		const getMonday = (date: string) => {
			const d = new Date(date);
			const day = d.getDay(),
				diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

			return new Date(d.setDate(diff));
		};

		const getTimeSheetSummaries = () => {
			const timeSheetSummaries: TimeSheetSummary[] = [];
			timeSheets?.forEach((timeSheet) => {
				if (timeSheet.lineId === null) {
					const timeSheetSummary: TimeSheetSummary = {
						id: timeSheet.id,
						weekStart: asYearMonthDay(getMonday(timeSheet.date)),
						firstDate: timeSheet.date,
						lastDate: timeSheet.date,
						hours: timeSheet.hours,
						keys: [timeSheet.id],
						rate: timeSheet.rate,
					};
					timeSheetSummaries.push(timeSheetSummary);
				}
			});

			return timeSheetSummaries;
		};
		const timeSheetSummaries: TimeSheetSummary[] = getTimeSheetSummaries();
		let result: TimeSheetSummary[] = [];
		//converts the timesheets to a summary of the week
		timeSheetSummaries.forEach((timeSheetSummary) => {
			const existingTimeSheet = result.find((x) => x.weekStart === timeSheetSummary.weekStart);
			if (existingTimeSheet) {
				existingTimeSheet.hours += timeSheetSummary.hours;
				existingTimeSheet.keys.push(timeSheetSummary.id);
				existingTimeSheet.firstDate = timeSheetSummary.firstDate < existingTimeSheet.firstDate ? timeSheetSummary.firstDate : existingTimeSheet.firstDate;
				existingTimeSheet.lastDate = timeSheetSummary.lastDate > existingTimeSheet.lastDate ? timeSheetSummary.lastDate : existingTimeSheet.lastDate;
			} else {
				result.push(timeSheetSummary);
			}
		}
		);

		return result;
	};

	const handleInvoiceLine = (eventKey: any, itemdata: Partial<InvoiceLinePostModel>) => {
		//if the item is a timesheet, we need to add the timesheet ids to the line
		//Timesheets
		if (itemdata.type === 'Timesheet') {
			if (eventKey === 'customtime') {
				setModalShow(!modalShow);
			}
			if (eventKey === 'alltimesheets' && timeSheetSummary) {
				if (timeSheets?.length === 0) {
					return;
				}
				// adds all the timesheets
				timeSheets?.forEach((timesheet)=>{
					onSelect(eventKey, {
						...intialValues,
						...itemdata,
						description: `For services ${formatDate(timesheet.date)} to ${formatDate(timesheet.date)}`,
						vatCode: showInvoiceVAT ? 'S' : 'E',
						timesheetIds: [timesheet.id],
						quantity: timesheet.hours,
						unitPrice: timesheet.rate,
						type: 'Timesheet',
					});

				});
			}
			if (eventKey !== 'customtime' && eventKey !== 'alltimesheets' && timeSheetSummary) {
				// it will get the ids of timesheets from the selected week
				const selectedTimesheetIds= timeSheetSummary.find((x: TimeSheetSummary) => x.id === +eventKey)?.keys
				// This filters the timesheets from the above selected ids and adds one invoice line for each timesheet record
				timeSheets?.filter(x=>selectedTimesheetIds?.includes(x.id)).forEach((timesheet)=>{
					onSelect(eventKey, {
						...intialValues,
						...itemdata,
						description:`For services ${formatDate(timesheet.date)} to ${formatDate(timesheet.date)}`,
						vatCode: showInvoiceVAT ? 'S' : 'E',
						timesheetIds: [timesheet.id],
						quantity: timesheet.hours,
						unitPrice: timesheet.rate,
						type: 'Timesheet',
					});
				});
			}
		}
		//Expense
		if (itemdata.type === 'BankPayment' && bankpayements) {
			let unitPriceInInvoiceCurr = 0;
			const getunitPriceInInvoiceCurr = (bankPayment: BankTransactionUnbilledPayments|undefined) => {
				if(bankPayment){
					if (invoiceCurrency === 'GBP' && bankPayment.bankAccountCurrency === 'GBP') {
						unitPriceInInvoiceCurr = bankPayment.amountNet || 0;
						return unitPriceInInvoiceCurr;
					} else if (invoiceCurrency === bankPayment.bankAccountCurrency) {
						unitPriceInInvoiceCurr = bankPayment.amountNetBACurr || 0 ;
						return unitPriceInInvoiceCurr;
					} else {
						unitPriceInInvoiceCurr = bankPayment.amountNet * currencyRateAtTaxPoint || 0;
						return unitPriceInInvoiceCurr;
					}
				}
				return 0;
			};
			if (bankpayements && bankpayements.length === 0) {
				return;
			}
			if(eventKey !== 'allexpenses' && bankpayements.find(x=>x.id === +eventKey)){
				unitPriceInInvoiceCurr = getunitPriceInInvoiceCurr(bankpayements.find(x=>x.id === +eventKey));
				onSelect(eventKey, {
					...intialValues,
					...itemdata,
					description: "Expenses for" + bankpayements.find(x=>x.id === +eventKey)?.description,
					quantity: 1,
					unitPrice: unitPriceInInvoiceCurr,
					unitPriceInSourceCurrency : bankpayements.find(x=>x.id === +eventKey)?.amountNetBACurr,
					vatCode: showInvoiceVAT ? 'S' : 'E',
					bankPaymentId: bankpayements.find(x=>x.id === +eventKey)?.id,
					type: 'BankPayment',
				});
			}
			if (eventKey === 'allexpenses' && bankpayements){
				bankpayements.forEach((bankpayement) => {
					onSelect(eventKey, {
						...intialValues,
						...itemdata,
						description: `Expenses for ${bankpayement.description}`,
						quantity: 1,
						unitPrice: getunitPriceInInvoiceCurr(bankpayement),
						vatCode: showInvoiceVAT ? 'S' : 'E',
						bankPaymentId: bankpayement.id,
						type: 'BankPayment',
						unitPriceInSourceCurrency: bankpayement.amountNetBACurr,
					});
				});
			}
		}
		//Journey
		if (itemdata.type === 'Mileage') {
			if (mileageBillable?.length === 0) {
				return;
			}
			if (eventKey === 'alljourneys') {
				mileageBillable?.forEach((element) => {
					onSelect(element.id, {
						...intialValues,
						...itemdata,
						description: element.description,
						vatCode: showInvoiceVAT ? 'S' : 'E',
						mileageId: element.id,
						quantity: element.distance,
						unitPrice: element.billedRate,
						type: 'Mileage',
					});
				});
			}
			if (eventKey !== 'alljourneys' && mileageBillable?.length !== 0) {
				onSelect(eventKey, {
					...intialValues,
					...itemdata,
					description: mileageBillable?.find((x: MileageBillable) => x.id === +eventKey)?.description,
					vatCode: showInvoiceVAT ? 'S' : 'E',
					mileageId: mileageBillable?.find((x: MileageBillable) => x.id === +eventKey)?.id,
					quantity: mileageBillable?.find((x: MileageBillable) => x.id === +eventKey)?.distance,
					unitPrice: mileageBillable?.find((x: MileageBillable) => x.id === +eventKey)?.billedRate,
					type: 'Mileage',
				});
			}

			return;
		}
		//Freetype
		if (itemdata.type === 'Freetype') {
			onSelect(eventKey, {
				...intialValues,
				...itemdata,
				vatCode: showInvoiceVAT ? 'S' : 'E',
			});
		}
		return;
	};

	const handleCustomDateRange = (startDate: string, endDate: string) => {
		setModalShow(false);
		setSelectedTime({ startDate, endDate });
		// This filters the timesheets from the selected date range and adds one invoice line for each timesheet record present in this date range
		timeSheets?.filter(x=>(x.date >=startDate) && (x.date <=endDate)).forEach((timesheet)=>{
			onSelect('customtime', {
				...intialValues,
				description:`For services ${formatDate(timesheet.date)} to ${formatDate(timesheet.date)}`,
				vatCode: showInvoiceVAT ? 'S' : 'E',
				timesheetIds: [timesheet.id],
				quantity: timesheet.hours,
				unitPrice: timesheet.rate,
				type: 'Timesheet',
			});
		});
	};

	return (
		<>
			{timeSheetSummary && timeSheets && timeSheets.length > 0 && (
				<DateSelector
					setShow={setModalShow}
					timeSheetSummary={timeSheets}
					showModal={modalShow}
					startDate={timeSheets.sort((a,b)=>a.date.localeCompare(b.date))[0].date}
					endDate={timeSheets.sort((a,b)=>a.date.localeCompare(b.date))[timeSheets?.length -1].date}
					SubmitValues={(startDate, endDate) => handleCustomDateRange(startDate, endDate)}></DateSelector>
			)}
			<DropdownButton
				id="dropdown-button-drop-right"
				title={
					<>
						<FontAwesomeIcon icon={faPlus} />
						&nbsp; Add line
					</>
				}
				className={styles.invoiceDropdownParentButton}
				drop="down">
				{/* TimeSheet */}
				{'Invoice' === documentType && showTimeElements && (
					<DropdownChildButton
						type={'TimeSheet'}
						lines={lines}
						onSelect={(eventKey) =>
							handleInvoiceLine(eventKey, {
								type: 'Timesheet',
							})
						}
						dropdownTitle="Billable time"
						dropdownValues={timeSheetSummary}
					/>
				)}
				{'Invoice' === documentType && mileageBillable && (
					<DropdownChildButton
						type={'Mileage'}
						lines={lines}
						onSelect={(eventKey: any) =>
							handleInvoiceLine(eventKey, {
								description: mileageBillable.find((x) => x.id === eventKey)?.description || 'Billable journey',
								mileageId: eventKey,
								type: 'Mileage',
								quantity: mileageBillable.find((x) => x.id === +eventKey)?.distance,
								unitPrice: mileageBillable.find((x) => x.id === +eventKey)?.billedRate,
							})
						}
						dropdownTitle="Billable Journey"
						dropdownValues={mileageBillable}
					/>
				)}
				{/* Expenses */}
				{'Invoice' === documentType && bankpayements && (
					<DropdownChildButton
						type={'BankPayment'}
						lines={lines}
						onSelect={(eventKey: any) =>
							handleInvoiceLine(eventKey, {
								description: bankpayements?.find((x) => x.id === eventKey)?.description || 'Billable expenses',
								bankPaymentId: eventKey,
								type: 'BankPayment',
							})
						}
						dropdownTitle="Billable expenses"
						dropdownValues={bankpayements}
					/>
				)}
				{/* FreeType */}
				{
					<DropdownChildButton
						type={'Freetype'}
						lines={lines}
						onSelect={(eventKey: any) =>
							handleInvoiceLine(eventKey, {
								description: accounts?.find((x) => x.id === +eventKey)?.name || 'Add description',
								accountId: +eventKey,
								type: 'Freetype',
							})
						}
						dropdownTitle="Manual line"
						dropdownValues={accounts}
						isProperty={!showTimeElements}
					/>
				}
			</DropdownButton>
		</>
	);
};

const DropdownChildButton = ({ onSelect, dropdownTitle, dropdownValues, isProperty, type }: interfaceDropdownChildProps) => {
	return (
		<>
			{type === 'TimeSheet' && (
				<DropdownButton
					variant="light"
					className={styles.invoiceDropdownButton}
					id="dropdown-button-drop-right"
					drop="right"
					title={dropdownTitle}
					onSelect={onSelect}>
					{dropdownValues &&
						dropdownValues.map((item) => {
							return (
								<React.Fragment key={item.id}>
									<Dropdown.Item eventKey={item.id}>{pharaPhaseTimeSheet(item)}</Dropdown.Item>
								</React.Fragment>
							);
						})}

					<Dropdown.Item eventKey="alltimesheets" disabled={dropdownValues && dropdownValues?.length > 0 ? false : true}>
						Add all bilable time
					</Dropdown.Item>
					<Dropdown.Item eventKey="customtime" disabled={dropdownValues && dropdownValues?.length > 0 ? false : true}>
						Custom date range
					</Dropdown.Item>
				</DropdownButton>
			)}

			{(type === 'BankPayment' || type === 'Mileage') && (
				<DropdownButton
					variant="light"
					className={classNames(styles.invoiceDropdownButton, dropdownValues && dropdownValues?.length === 0 ? styles.invoiceDropdownNoItem : ' ')}
					id="dropdown-button-drop-right"
					drop="right"
					title={dropdownTitle}
					onSelect={onSelect}>
					{dropdownValues &&
						dropdownValues.map((item) => {
							return (
								<React.Fragment key={item.id}>
									<Dropdown.Item eventKey={item.id}>{item.description}</Dropdown.Item>
								</React.Fragment>
							);
						})}
					{type === 'Mileage' && (
						<Dropdown.Item eventKey="alljourneys" disabled={dropdownValues && dropdownValues?.length > 0 ? false : true}>
							Add all journeys
						</Dropdown.Item>
					)}
					{type === 'BankPayment' && (
						<Dropdown.Item eventKey="allexpenses" disabled={dropdownValues && dropdownValues?.length > 0 ? false : true}>
							Add all expenses
						</Dropdown.Item>
					)}
				</DropdownButton>
			)}
			{type === 'Freetype' && (
				<DropdownButton
					variant="light"
					className={`${styles.invoiceDropdownButton}`}
					id="dropdown-button-drop-right"
					drop="right"
					title={dropdownTitle}
					onSelect={onSelect}>
					<>
						{dropdownValues && isProperty
							? getUniqueValues<Account>(dropdownValues, 'accountGroup')
									.reverse()
									.map((item: any) => {
										return (
											<React.Fragment key={item}>
												{dropdownValues?.filter((x) => x.showOnFreetypeInvoice && x.accountGroup === item).length > 0 && (
													<>
														<Dropdown.ItemText className="mt-3">
															<b>{item}</b>
														</Dropdown.ItemText>
														<Dropdown.Divider />
														{dropdownValues
															?.filter((x) => x.showOnFreetypeInvoice && x.accountGroup === item)
															.map((item) => (
																<Dropdown.Item key={item.id} eventKey={item.id}>
																	{item.name}
																</Dropdown.Item>
															))}
													</>
												)}
											</React.Fragment>
										);
									})
							: dropdownValues
									?.filter((x) => x.showOnFreetypeInvoice)
									.map((item) => {
										return (
											<Dropdown.Item key={item.id} eventKey={item.id}>
												{item.name}
											</Dropdown.Item>
										);
									})}
					</>
				</DropdownButton>
			)}
		</>
	);
};

export const pharaPhaseTimeSheet = (item: TimeSheetSummary | undefined , noTime = false) => {
	if (item) {
		return `${formatDate(item.firstDate)} to ${formatDate(item.lastDate)} ${ noTime ? '' :  '(' + item.hours + ')'}`;
	}

	return '';
};

export const checkDateExists = (date: string, start: string | undefined, end: string | undefined) => {
	let dateGiven = new Date(date);
	let startDate = start && new Date(start);
	let endDate = end && new Date(end);
	if ((startDate || endDate) === undefined) {
		return true;
	}
	if (startDate && endDate && dateGiven >= startDate && dateGiven <= endDate) {
		return true;
	}

	return false;
};
export default DropdownButtons;
