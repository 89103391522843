/**
 * @prettier
 */
import { useContext, useEffect, useState } from 'react';
import CompanyContext from '../../context/CompanyContext';
import { Button } from '../../elements/Button/Button';
import { useInniAPI } from '../../hooks/useInniAPI';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { Entity } from '../../utils/EntityAction';
import { Billing } from '../../api/inni/Billing';
import InfoBanner from '../../elements/InfoBanner/InfoBanner';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { BillingData } from '../../api/inni/data-contracts';

export const BillingPage = () => {
  const companyContext = useContext(CompanyContext);
  const billingAPI = useInniAPI(Billing);

  const [billingData, setBillingData] = useState<BillingData>();

  useEffect(() => {
    if (billingAPI && billingData === undefined) {
      billingAPI
        .getBillingData(companyContext.cid)
        .then((res) => {
          setBillingData(res.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [billingAPI, billingData, companyContext.cid]);

  const goToPortal = () => {
    if (billingData && billingData.stripePortalUrl) {
      window.location.assign(billingData.stripePortalUrl);
    }
  };

  return (
    <DefaultLayout entity={Entity.BillingV7} greyBackground subLayout title="Billing">
      {companyContext.company && companyContext.company.showBillingBlocker && (
        <InfoBanner
          type="danger"
          title={'Your billing needs updating'}
          body={<>We haven't been able to take payment using your current billing method. Please update it to resolve this issue.</>}
          noTopMargin
        />
      )}

      {billingData === undefined && <LoadingPlaceholder height="100px" />}

      {billingData && billingData.stripePortalUrl === '' && (
        <InfoBanner type="info" title={'Stripe billing'} body={<>We are currently in the process of synchronising your Stripe details. Please try again later.</>} noTopMargin />
      )}

      {billingData && billingData.stripePortalUrl && (
        <InfoBanner
          type="info"
          title={'Stripe billing'}
          noTopMargin
          body={
            <>
              Please click the button below to visit Stripe, where you can review your billing information and invoice history.
              <br />
              Once you're finished, you can click the 'return' button to be redirected back to this page.
              <br />
              <br />
              <Button small onClick={goToPortal} variant="primary">
                Go to stripe
              </Button>
            </>
          }
        />
      )}
    </DefaultLayout>
  );
};

export default BillingPage;
