/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { SupportContactsGroup } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class SupportContacts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags SupportContacts
   * @name Index
   * @summary Returns the name, photo URL and if applicable consultation link of the Account Manager and Accountant for a company (if they exist)
   * @request GET:/api/v2/companies/{companyId}/supportContacts
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<SupportContactsGroup, any>({
      path: `/api/v2/companies/${companyId}/supportContacts`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
