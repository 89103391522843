import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Property, PropertyCreateModel, PropertyPortfolioSummary } from '../../api/inni/data-contracts';
import { Properties } from '../../api/inni/Properties';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { useInniAPI } from '../../hooks/useInniAPI';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { Action, Entity } from '../../utils/EntityAction';
import { useAssertPermission } from '../../hooks/useAssertPermission';
import { PropertyListTable } from './Components/PropertyListTable';
import { ValueEquityKpi, ValueGrossKpi } from './DashboardElements/ValueKpis';
import { LtvKpi } from './DashboardElements/Ltv';
import { LettableUnitsKpi } from './DashboardElements/LettableUnits';
import { PropertyListCards } from './Components/PropertyListCards';
import { ViewSwitcher } from '../../elements/ViewSwitcher/ViewSwitcher';
import { useFetchEntityList } from '../../hooks/entities/useFetchEntityList';
import Toolbar from '../../layouts/Desktop/Toolbar';
import { Button } from '../../elements/Button/Button';
import { usePersistedState } from '../../hooks/usePersistedState';
import { NoContentSlate } from '../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../hooks/terms/useDatagridTerms';
import BlankStateCard from '../../components/BlankStateCard/BlankStateCard';
import { SbBlokData, getStoryblokApi } from '@storyblok/react';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import { useHasPermission } from '../../hooks/useHasPermission';


export const PropertyListPage = () => {
    useAssertPermission(Entity.Property, Action.List);
    const companyContext = useContext(CompanyContext);

    const isCAP = companyContext.product===CompanyProduct.isCap;
    const v8Styling = companyContext.company?.useV8UI || false;
    const [properties, propertiesLoaded] = useFetchEntityList<Property, Properties>(Properties);
    const propertiesApi = useInniAPI(Properties);

    const [kpis, setKpis] = useState<PropertyPortfolioSummary|undefined>(undefined);
    const [kpisLoaded, setKpisLoaded] = useState(false);
    const [showInactive, setShowInactive] = useState(false);

    const [currentView, setCurrentView] = usePersistedState('local', 'PropertyListPage_view', 'cards', ['cards', 'table']);

    const showProperties = properties.filter(x => x.isInactive === showInactive || (x.isInactive === null && !showInactive));

	// const [unreadPageMessages, setMessageRead, getOnboardingComponent] = useOnboardingMessages('property');

    const [blankSlateInfo, setBlankSlateInfo]= useState<SbBlokData>();
    const navigateToEntity= useNavigateToEntity()
    const hasPermission = useHasPermission()

    useEffect(()=>{
        const storyblokApi = getStoryblokApi();
        storyblokApi.get('cdn/stories/blank-slate/property', { version: 'published'})
        .then(res => {
            setBlankSlateInfo(res.data.story.content)
        }).catch(err => {
            console.error(err)
        });
    },[])

    useEffect(() => {
        if (propertiesApi && !kpisLoaded) {
            propertiesApi.portfolioSummary(companyContext.cid).then(
                response => {
                    setKpis(response.data);
                    setKpisLoaded(true);
                } 
            )
        }
    }, [propertiesApi, companyContext.cid, propertiesLoaded, kpisLoaded])

    return (
        <DefaultLayout entity={Entity.Property} title="Properties" greyBackground>

            { ((hasPermission(Entity.Property, Action.Create)[0] && !(properties.length === 0 && isCAP)) ||
                hasPermission(Entity.Property, Action.LinkToExternal)[0]) && (
                <Toolbar>
                    { !(properties.length === 0 && isCAP) && (
                        <Button entity={Entity.Property} action={Action.Create} buttonType='new' headerBtn={v8Styling} />
                    )}
                    <Button entity={Entity.Property} action={Action.LinkToExternal} buttonType='import' />
                </Toolbar>
            )}

            {/* { unreadPageMessages?.includes('info-boxes/onboarding/properties_welcome') && propertiesLoaded && properties.length === 0 && (
                <Row>
                    <Col>
                        { getOnboardingComponent('properties_welcome') }
                    </Col>
                </Row>
            )} */}

            {!(properties.length===0 && isCAP && propertiesLoaded) &&
                <>
                    <Row>
                        <Col xs={12} md={6} lg={3}>
                            <LettableUnitsKpi loading={!kpisLoaded} unitsLet={kpis?.lettableUnitsLet} unitsVacant={kpis?.lettableUnitsVacant} />
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <ValueGrossKpi loading={!kpisLoaded} value={kpis?.grossValue} />
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <ValueEquityKpi loading={!kpisLoaded} value={kpis?.equity} />
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <LtvKpi loading={!kpisLoaded} value={kpis?.portfolioLTV}/>
                        </Col>
                    </Row>
                    <Row className='mt-5 mb-0'>
                        <Col style={{textAlign: 'right'}}>
                            { properties.filter(x => x.isInactive).length > 0 && (
                                <ViewSwitcher
                                    propertyStatus
                                    currentView={showInactive ? "inactiveProperties" : "activeProperties"}
                                    viewChanged={(val) => setShowInactive(val === "inactiveProperties" ? true : false)}
                                />
                            )}
                            { !v8Styling && (
                                <>
                                    <ViewSwitcher card table currentView={currentView} viewChanged={setCurrentView} />
                                    <hr className='mt-2 mb-3' />
                                </>
                            )}
                        </Col>
                    </Row>
                    { showProperties.length > 0
                        ? (
                            <>
                                { (v8Styling || currentView === 'cards') && (
                                    <PropertyListCards properties={showProperties} loaded={propertiesLoaded} />
                                )}
                                { (!v8Styling && currentView === 'table') && (
                                    <PropertyListTable properties={showProperties} loaded={propertiesLoaded} />
                                )} 
                            </>
                        )
                        : !propertiesLoaded
                            ? <PropertyListCards properties={[]} loaded={false} />
                            : <NoContentSlate type={DatagridType.PropertiesNew} termsKey="emptyTerms" largeMargin />
                    }
                </>
            }

            { isCAP && propertiesLoaded && properties.length===0 &&
                <BlankStateCard  
                    content={blankSlateInfo} 
                    loading={!blankSlateInfo}
                    buttonEntity={Entity.Property}
                    buttonAction={Action.Create}
                />
            }

        </DefaultLayout>
    )
}