/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BankPayment,
  BankPaymentSearchView,
  BankTransaction,
  BankTransactionAsDetailed,
  BankTransactionPostModel,
  BankTransactionUnbilledPayments,
  BankTransactionWithOB,
  BankTransferModel,
  Datagrid,
  NewPaymentPostModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class BankTransactions<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
 * No description
 * 
 * @tags BankTransactions
 * @name NewForReminder
 * @summary Setup new payment will return some json to the browser to allow it to set up the new payment editor.
could also accept post to change account but keep other vals?
 * @request GET:/api/v2/companies/{companyid}/banktransactions/newForReminder
 * @secure
 */
  newForReminder = (
    companyid: number,
    query: { procType: string; relatedId: number; bankAccountid?: number },
    params: RequestParams = {},
  ) =>
    this.request<NewPaymentPostModel, any>({
      path: `/api/v2/companies/${companyid}/banktransactions/newForReminder`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    }); /**
 * No description
 * 
 * @tags BankTransactions
 * @name SavePayment
 * @summary When the use clicks save the data from the editor (home page reminder) this is posted.
The UI will be using teh same component as the transaction editor, so this API should be the same - this should be bank payment post elsewhere..
 * @request POST:/api/v2/companies/{companyid}/banktransactions/newForReminder
 * @secure
 */
  savePayment = (companyid: number, npm: NewPaymentPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyid}/banktransactions/newForReminder`,
      method: "POST",
      body: npm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name ValidateCreateNewPayment
   * @request POST:/api/v2/companies/{companyId}/banktransactions/newForReminder/Validate
   * @secure
   */
  validateCreateNewPayment = (companyId: number, postmodel: NewPaymentPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/newForReminder/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name New
   * @request GET:/api/v2/companies/{companyId}/banktransactions/new
   * @secure
   */
  new = (companyId: number, params: RequestParams = {}) =>
    this.request<BankPayment, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/new`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name GetBankPayment
   * @summary Can only return bank PAYMENTS not Transfers, MJ...
   * @request GET:/api/v2/companies/{companyId}/banktransactions/bankpayments/{id}
   * @secure
   */
  getBankPayment = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<BankPayment, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/bankpayments/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name DeleteBankPayment
   * @request DELETE:/api/v2/companies/{companyId}/banktransactions/bankpayments/{id}
   * @secure
   */
  deleteBankPayment = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/bankpayments/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name GetPaymentAsDetailed
   * @summary Get the detailed view for editing the transaction
   * @request GET:/api/v2/companies/{companyId}/banktransactions/bankpayments/{bankPaymentId}/GetAsDetailed
   * @secure
   */
  getPaymentAsDetailed = (companyId: number, bankPaymentId: number, params: RequestParams = {}) =>
    this.request<BankTransactionAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/bankpayments/${bankPaymentId}/GetAsDetailed`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name SearchBankPayments
   * @summary Search BankPayments
   * @request GET:/api/v2/companies/{companyId}/banktransactions/bankpayments/search
   * @secure
   */
  searchBankPayments = (companyId: number, query?: { nearDate?: string; q?: string }, params: RequestParams = {}) =>
    this.request<BankPaymentSearchView[], any>({
      path: `/api/v2/companies/${companyId}/banktransactions/bankpayments/search`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name CreateBankPayment
   * @request POST:/api/v2/companies/{companyId}/banktransactions/bankpayments/{quickEntry}
   * @secure
   */
  createBankPayment = (
    companyId: number,
    quickEntry: boolean,
    bankTxn: BankTransactionPostModel,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/bankpayments/${quickEntry}`,
      method: "POST",
      body: bankTxn,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/banktransactions/bankpayment/Validate
   * @secure
   */
  validateCreate = (companyId: number, postmodel: BankTransactionPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/bankpayment/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name ValidateCreateTransfer
   * @request POST:/api/v2/companies/{companyId}/banktransactions/bankpayment/ValidateTransfer
   * @secure
   */
  validateCreateTransfer = (companyId: number, postmodel: BankTransferModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/bankpayment/ValidateTransfer`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name UpdateBankPayment
   * @request PUT:/api/v2/companies/{companyId}/banktransactions/bankpayment/{id}
   * @secure
   */
  updateBankPayment = (
    companyId: number,
    id: number,
    transaction: BankTransactionPostModel,
    params: RequestParams = {},
  ) =>
    this.request<number, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/bankpayment/${id}`,
      method: "PUT",
      body: transaction,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name CreateBankTransfer
   * @summary We need to be able to create BP and BT - do they go through other controllers, or just different flavours of transaction? MJ also..
   * @request POST:/api/v2/companies/{companyId}/banktransactions/banktransfer
   * @secure
   */
  createBankTransfer = (companyId: number, bankTransfer: BankTransferModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/banktransfer`,
      method: "POST",
      body: bankTransfer,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name GetBankTransfer
   * @request GET:/api/v2/companies/{companyId}/banktransactions/banktransfer/{id}
   * @secure
   */
  getBankTransfer = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<BankTransferModel, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/banktransfer/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name UpdateBankTransfer
   * @request PUT:/api/v2/companies/{companyId}/banktransactions/banktransfer/{id}
   * @secure
   */
  updateBankTransfer = (companyId: number, id: number, transfer: BankTransferModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/banktransfer/${id}`,
      method: "PUT",
      body: transfer,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name DeleteBankTransfer
   * @request DELETE:/api/v2/companies/{companyId}/banktransactions/banktransfer/{id}
   * @secure
   */
  deleteBankTransfer = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/banktransfer/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name Filter
   * @request GET:/api/v2/companies/{companyId}/banktransactions/Filter
   * @secure
   */
  filter = (companyId: number, query: { accountId: number; yearCode?: string }, params: RequestParams = {}) =>
    this.request<Datagrid, any>({
      path: `/api/v2/companies/${companyId}/banktransactions/Filter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name GetAllWithOb
   * @request GET:/api/v2/companies/{companyId}/banktransactions/GetAllWithOB
   * @secure
   */
  getAllWithOb = (companyId: number, query: { accountId: number; yearCode: string }, params: RequestParams = {}) =>
    this.request<BankTransactionWithOB[], any>({
      path: `/api/v2/companies/${companyId}/banktransactions/GetAllWithOB`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    }); /**
 * No description
 * 
 * @tags BankTransactions
 * @name Index
 * @summary The bank statement is a union of Bank payments, transfers and adjustments - these are combined into a BankTransaction type
When you CRUD any individual item, you must do it using the relevant type /BankPayments /BankTransfers etc
 * @request GET:/api/v2/companies/{companyId}/banktransactions
 * @secure
 */
  index = (
    companyId: number,
    query: { yearCode: string; accountId?: number; hasImage?: boolean; onlyThumbnail?: boolean },
    params: RequestParams = {},
  ) =>
    this.request<BankTransaction[], any>({
      path: `/api/v2/companies/${companyId}/banktransactions`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name ConvertExpenseToAsset
   * @request POST:/api/v2/companies/{companyId}/banktransactions/ConvertExpenseToAsset
   * @secure
   */
  convertExpenseToAsset = (companyId: number, query: { bpId: number }, params: RequestParams = {}) =>
    this.request<BankTransaction[], any>({
      path: `/api/v2/companies/${companyId}/banktransactions/ConvertExpenseToAsset`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags BankTransactions
   * @name GetUnbilledBankPayments
   * @request GET:/api/v2/companies/{companyId}/banktransactions/UnbilledBankPayments
   * @secure
   */
  getUnbilledBankPayments = (companyId: number, query?: { contractId?: number }, params: RequestParams = {}) =>
    this.request<BankTransactionUnbilledPayments[], any>({
      path: `/api/v2/companies/${companyId}/banktransactions/UnbilledBankPayments`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    }); /**
 * No description
 * 
 * @tags BankTransactions
 * @name GetAllTransactions
 * @summary The bank statement is a union of Bank payments, transfers and adjustments - these are combined into a BankTransaction type
When you CRUD any individual item, you must do it using the relevant type /BankPayments /BankTransfers etc
 * @request GET:/api/v2/companies/{companyId}/banktransactions/GetAllTransactions
 * @secure
 */
  getAllTransactions = (
    companyId: number,
    query?: { accountingPeriodId?: number; accountId?: number },
    params: RequestParams = {},
  ) =>
    this.request<BankTransaction[], any>({
      path: `/api/v2/companies/${companyId}/banktransactions/GetAllTransactions`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
