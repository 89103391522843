import { faArrowLeft, faArrowRight, faBuilding, faCheck, faFileAlt, faTimes, faUsers, faUserTie } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { Step, Stepper } from '../../elements/Stepper/Stepper'
import CompanyDetails from './Components/Incorporation/CompanyDetails'
import People from './Components/Incorporation/DirectorsAndShareholders'
import FinalChecks from './Components/Incorporation/FinalChecks'
import Disclaimer from './Components/Incorporation/Disclaimer'
import { useParams } from 'react-router-dom'
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity'
import { Action, Entity } from '../../utils/EntityAction'
import { IncorporationFormPostModel, IncorporationFormViewModel, Participant } from '../../api/inni/data-contracts'
import { useInniAPI } from '../../hooks/useInniAPI'
import { IncorporationForm } from '../../api/inni/IncorporationForm'
import styles from './NoCompanies.module.css'
import ContactDetails from './Components/ContactDetails'
import BrandContext from '../../context/BrandContext'
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout'
import { Alert, Container } from 'react-bootstrap'
import { NoContentSlate } from '../../elements/Slates/NoContentSlate'
import { DatagridType } from '../../hooks/terms/useDatagridTerms'
import { LoadingFullPage } from '../../elements/LoadingFullPage/LoadingFullPage';
import useIsMobile from '../../hooks/useIsMobile'
import PSC from './Components/Incorporation/PSC'
import { asYearMonthDay } from '../../utils/formatDate'

export const authQuestions : Map<keyof Participant, string> = new Map([
    // INCO: Repurposed incorporation backend has broken frontend
    // ["townOfBirth", "Town of birth"],
    // ["telephoneNumber", "Telephone number"],
    // ["niNumber", "National insurance no."],
    // ["passportNumber", "Passport number"],
    // ["mothersMaidenName", "Mother's maiden name"],
    // ["fathersFirstName", "Father's first forename"]
])

const steps = [
    "Getting started",
    "Your Company",
    "Directors and Shareholders",
    "Other people",
    "Final checks"
]

export interface IncorpFormRouteParams {  
    page?: string
}

const defaultIncorpForm : IncorporationFormViewModel = {
    companyName: '',
    registeredOfficeAddress: '',
    sicCode: '',
    registeredPostcode: '',
    agreedDisclaimer: false,
    lastPage: 0,
    shares: [
        { name: 'Ordinary', nominalValue: 1.00, votingRights: false, equityRights: false, dividendRights: false }
    ]
}

const Incorporation = () => {
    return null;
    // const brandContext = useContext(BrandContext);
    // const activeStep = Number(useParams<IncorpFormRouteParams>().page);
    // const navigateToEntity = useNavigateToEntity();

    // const [pagesVisted, setPagesVisted] = useState<Set<number>>(new Set<number>())
    // const [successSaved, setSuccessSaved] = useState(false)

    // const [formState, setFormState] = useState<IncorporationFormViewModel>(defaultIncorpForm)
    // const [formStateLoaded, setFormStateLoaded] = useState(false)
    // const [showEmpty, setShowEmpty] = useState(false)

    // const [justSubmitted, setJustSubmitted]  = useState(false)
    // const [alertShowing, setAlertShowing] = useState(false)
    // const [errMsg, setErrMsg] = useState<string>('')

    // const incorpAPI = useInniAPI(IncorporationForm, [400,404])

    // // loaded initial data
    // useEffect(() => {
    //     if (incorpAPI && !formStateLoaded) {
    //         incorpAPI.get(0).then(res => {
    //             if (res.status === 200) {
    //                 setFormState({...res.data})
    //                 navigateToEntity(Entity.Incorporation, Action.List, { 'page' : (res.data.lastPage || 1).toString() })
    //                 setFormStateLoaded(true)
    //             }
    //         }).catch(err => {
    //             setShowEmpty(true)
    //             setFormStateLoaded(true)
    //         })
    //     }
    // }, [formStateLoaded, incorpAPI])

    // useEffect(() => {
    //     let tempVisted = pagesVisted
    //     tempVisted.add(activeStep)
    //     setPagesVisted(tempVisted)
    // }, [activeStep])

    // const isMobile = useIsMobile()

    // const setActiveStep = (i : number) => {
    //     navigateToEntity(Entity.Incorporation, Action.List, { 'page' : i.toString() })
    // }

    // const setFieldValue = (key : keyof IncorporationFormViewModel, value : any | undefined) => {
    //     setFormState({...formState, [key]: value})
    // }

    // const setValues = (values : IncorporationFormViewModel) => {
    //     setFormState({...formState, ...values})
    // }

    // const saveFormState = (values?: IncorporationFormPostModel, showSuccess?: boolean) => {
    //     if (incorpAPI) {
    //         let tempFs = values ? values : formState
    //         tempFs.lastPage = activeStep
    //         incorpAPI.updateApplication(0,tempFs as IncorporationFormPostModel).then(x => {
    //             if (x.status === 200) {
    //                 if (showSuccess === undefined || showSuccess === true) {
    //                     setSuccessSaved(true);
    //                 }
    //                 setTimeout(() => { setSuccessSaved(false) }, 4000);
    //             }            
    //         }).catch(err => {
    //             let s = err.error as {[key : string] : any}
                
    //             setErrMsg(`Please check you entered a value for ${Object.keys(s)[0].replace(/([A-Z])/g, ' $1').trim().toLowerCase()} (your company page)`) 
    //             setAlertShowing(true)
    //             setTimeout(() => setAlertShowing(false), 2000)
    //         })
    //     }
    // }

    // const submitForReview = () => {
    //     if (incorpAPI) {
    //         let tempFs = formState
    //         incorpAPI.validateMainForm(0, tempFs as IncorporationFormPostModel)
    //         .then(res => {                
    //             tempFs.submittedDate = asYearMonthDay(new Date())
                
    //             incorpAPI.updateApplication(0,tempFs as IncorporationFormPostModel).then(res => {
    //                 if (res.status === 200) {
    //                     setJustSubmitted(true)
    //                 }
    //             }).catch(err => {
    //                 setAlertShowing(true)
    //                 setTimeout(() => setAlertShowing(false), 2000)
    //             })
    //         }).catch(err => {
    //             let s = JSON.parse(JSON.stringify(err.error)) as {[key : string] : any}
                
    //             setErrMsg(`Please check you entered a value for ${Object.keys(s)[0].replace(/([A-Z])/g, ' $1').trim().toLowerCase()} (your company page)`) 
    //             setAlertShowing(true)
    //             setTimeout(() => setAlertShowing(false), 5000)
    //         })

            
    //     }
    // }

    // return (<DefaultLayout greyBackground={isMobile} noPadding useFullWidth entity={Entity.Incorporation} title="Incorporation">
    //     { !formStateLoaded && <>
    //         <Container className={styles.incorpFormContainer}>
    //             <LoadingFullPage entityName='Incorporation' fullScreen={false} />
    //         </Container>
    //     </>}
    //     {formStateLoaded && !showEmpty && !justSubmitted && formState.submittedDate === null &&
    //     <div>
    //         <div style={{background: '#006581', padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
    //             <div style={{width: '75%'}}>
    //                 {!isMobile &&
    //                 <Stepper inactiveColor="#FFF" activeColor="#e8b811">
    //                     <Step active={activeStep === 1} complete={activeStep > 1} label={steps[0]}><FontAwesomeIcon style={{ color: activeStep === 1? 'orange' : 'white' }} icon={faFileAlt} /></Step>
    //                     <Step active={activeStep === 2} complete={activeStep > 2} label={steps[1]}><FontAwesomeIcon style={{ color: activeStep === 2? 'orange' : 'white' }} icon={faBuilding} /></Step>
    //                     <Step active={activeStep === 3} complete={activeStep > 3} label={steps[2]}><FontAwesomeIcon style={{ color: activeStep === 3? 'orange' : 'white' }} icon={faUserTie} /></Step>
    //                     <Step active={activeStep === 4} complete={activeStep > 4} label={steps[3]}><FontAwesomeIcon style={{ color: activeStep === 4? 'orange' : 'white' }} icon={faUsers} /></Step>
    //                     <Step active={activeStep === 5} complete={activeStep > 5} label={steps[4]}><FontAwesomeIcon style={{ color: activeStep === 5? 'orange' : 'white' }} icon={faCheck} /></Step>
    //                     {/* Sometimes this is not shown */}
    //                     {/* TODO payment stuff - not in v1 */}
    //                     {/* <Step active={activeStep === 6} complete={activeStep > 6} label={steps[5]}><FontAwesomeIcon style={{ color: activeStep === 6? 'orange' : 'white' }} icon={faWallet} /></Step> */}
    //                 </Stepper>}
    //                 {isMobile &&
    //                     <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
    //                         <div>{activeStep !== 1 && <FontAwesomeIcon onClick={() => setActiveStep(activeStep-1)} style={{cursor: 'pointer'}} color="white" icon={faArrowLeft} />}</div>
    //                         <span style={{color: 'white'}}>{steps[activeStep-1]}</span>
    //                         <div>{activeStep !== steps.length && pagesVisted.has(activeStep+1) && <FontAwesomeIcon onClick={() => setActiveStep(activeStep+1)} style={{cursor: 'pointer'}} color="white" icon={faArrowRight} />}</div>
    //                     </div>                        
    //                 }
    //             </div>
    //         </div>
    //         <div>               
    //             {activeStep === 1 && <Disclaimer formState={formState} setDisclaimerState={(i : boolean) => setFieldValue('agreedDisclaimer', i)} disclaimerState={formState.agreedDisclaimer || false} setNextPage={() => setActiveStep(2)} />}
    //             {activeStep === 2 && <CompanyDetails save={saveFormState} initialPm={formState} setValues={setValues} setNextPage={() => setActiveStep(3)} />}
    //             {activeStep === 3 && <People setValues={setValues} save={saveFormState} formState={formState} setNextPage={() => setActiveStep(4)} />}
    //             {activeStep === 4 && <PSC setFieldValue={setFieldValue} setValues={setValues} formState={formState} save={saveFormState} setNextPage={() => setActiveStep(5)} />}
    //             {activeStep === 5 && <FinalChecks submitForReview={submitForReview} pm={formState} save={saveFormState}  /> }
    //         </div>
    //     </div>}
    //     { formStateLoaded && !showEmpty && formState.confirmedDate === null && formState.submittedDate !== null && !justSubmitted &&
    //         <Container style={{background: `${isMobile ? '' : '#fff'}`}}>
    //             <div id={styles.noCompany} style={{background: isMobile ? '' : '#fff'}}>
    //                 <div className={`${styles.onboardingProcess} ${isMobile ? 'shadow' : ''}`}>
    //                     {!isMobile && <img style={{height: '50px'}} src={brandContext.brand.logoDarkUrl} alt={"logo"} />}
    //                     <div className={styles.formTxtContainer}>
    //                         <h1>We are reviewing your application</h1>
    //                         <h5 className="mt-3">One of our team members is reviewing your application and will be in touch soon.</h5>                        
    //                     </div>
    //                     <ContactDetails />
    //                 </div>
    //             </div>
    //         </Container>
    //     }
    //     { formStateLoaded && !showEmpty && formState.confirmedDate !== null && formState.submittedDate !== null && !justSubmitted &&
    //         <Container style={{background: `${isMobile ? '' : '#fff'}`}}>
    //             <div id={styles.noCompany} style={{background: isMobile ? '' : '#fff'}}>
    //                 <div className={`${styles.onboardingProcess} ${isMobile ? 'shadow' : ''}`}>
    //                     {!isMobile && <img style={{height: '50px'}} src={brandContext.brand.logoDarkUrl} alt={"logo"} />}
    //                     <div className={styles.formTxtContainer}>
    //                         <h1>Congratulations, your application is complete!</h1>
    //                         <h5 className="mt-3">You and your shareholders should have received an email with details on ID verification, please complete this if you haven't already. Once this is done we will begin the incorporation of your company through Companies House.</h5>                        
    //                     </div>
    //                     <ContactDetails />
    //                 </div>
    //             </div>
    //         </Container>
    //     }
    //     { formStateLoaded && !showEmpty && justSubmitted &&
    //         <Container style={{background: `${isMobile ? '' : '#fff'}`}}>
    //             <div id={styles.noCompany} style={{background: isMobile ? '' : '#fff'}}>
    //                 <div className={`${styles.onboardingProcess} ${isMobile ? 'shadow' : ''}`}>
    //                     {!isMobile && <img style={{height: '50px'}} src={brandContext.brand.logoDarkUrl} alt={"logo"} />}
    //                     <div className={styles.formTxtContainer}>
    //                         <h1>Thank you! We have your form.</h1>
    //                         <h5 className="mt-3">Our team have been notified and someone will be in contact with you very soon.</h5>
    //                     </div>
    //                     <div className={styles.formTxtContainer}>
    //                         <h1>Next steps</h1>
    //                         <h5 className="mt-3">To comply with Anti Money Laundering regulations, we are required to verify the identify of key members of your company. We will send emails directly to all members we must identify 
    //                             to request they complete an online verification check. We will apply to incorporate your company once all company member identities have been verified</h5>
    //                     </div>
    //                     <ContactDetails />
    //                 </div>
    //             </div>
    //         </Container>
    //     }
    //     { showEmpty && <NoContentSlate whiteBg termsKey="emptyTerms" type={DatagridType.Incorporation} />}
    //     {successSaved &&
    //     <div style={{position: 'fixed', bottom: '0', width: '80%', left: '10%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className="alert alert-success" role="alert">
    //         <>Successfully saved form!</>
    //         <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={() => setSuccessSaved(false)} icon={faTimes} />
    //     </div>}

    //     <Alert dismissible 
    //         onClose={() => setAlertShowing(false)} 
    //         show={alertShowing} 
    //         variant="danger"
    //         className={styles.alert}
    //     >
    //         <b>Error encountered when saving: </b>{ errMsg }
    //     </Alert>
    // </DefaultLayout>)
}

export default Incorporation