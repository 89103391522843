import { Form, Formik, FormikErrors, FormikProps } from 'formik'
import { useCallback, useContext} from 'react'
import { BenefitsPostModel } from '../../../api/inni/data-contracts'
import { PersonAsDetailed } from '../../../api/inni/data-contracts'
import { People } from '../../../api/inni/People'
import CompanyContext from '../../../context/CompanyContext'
import { Text as TextDetail, YesNo, Date } from '../../../elements/DetailRow/DetailRow'
import { Submit, Switch, Text } from '../../../elements/EditRow/EditRow'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { CompareValues} from '../../../utils/compareValues'
import { TabEditProps } from '../People'
import { Button } from '../../../elements/Button/Button'
import { Entity, Action } from '../../../utils/EntityAction'

const Benefits = ({person, setPerson, editing, setEditing,setDisabled} : {person:PersonAsDetailed|undefined, setPerson: (i : PersonAsDetailed | undefined) => void,setDisabled: (disabled: boolean) => void} & TabEditProps) => {

    const companyContext = useContext(CompanyContext)
    const peopleApi = useInniAPI(People)
	const refVal = useCallback(
		(node: FormikProps<any>) => {
			if (node !== null) {
				setDisabled(!CompareValues({ ...node.values }, { ...node.initialValues }, true));
			}
		},
		[setDisabled]
	);

    const validateUpdate = (values: BenefitsPostModel) : Promise<FormikErrors<BenefitsPostModel>> => {
        return new Promise((resolve) => {
            if (peopleApi) {
                peopleApi.validateUpdateBenefits(companyContext.cid, person?.id || 0, values)
                .then(() => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const updateCall = (values: BenefitsPostModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (peopleApi) {
                peopleApi.updateBenefits(companyContext.cid, person?.id || 0, values)
                .then(res => {
                    if (res.status === 200) {
                        let tempPerson = person
                        if (tempPerson) {
                            tempPerson.p11DRequiredOngoing = values.p11DRequiredOngoing
                            tempPerson.p11DRequiredReason = values.p11DRequiredReason
                            tempPerson.companyCar = values.companyCar
                        }
                        setPerson(tempPerson)
                        setEditing(false)
                        resolve()
                    } else {
                        console.error(`Unexpected resposne code ${res.status}`)
                    }
                }).catch(err => reject(err.error))
            }
        })
    }


    return (
        <>
            { person && (
                <>
                    { !editing
                        ? (
                            <>
                                <YesNo editStyling entity={person} name="p11DRequiredOngoing" label="Ongoing P11D needed" />
                                <TextDetail editStyling entity={person} name="p11DRequiredReason" label="P11D needed (blank if not)" ensureRender />
                                <TextDetail editStyling entity={person} name="directorLoanMaxValueThisFY" label="Maximum DL this FY" valueFormatter={(i) => parseInt(i || '0').toFixed(2)} />
                                <Date editStyling entity={person} name="directorLoanMaxValueThisFYAtDate" label="Maximum DL date" />
                                <YesNo editStyling entity={person} name="companyCar" />
                                {person.showAuthRequest && <TextDetail editStyling entity={person} name="authStatus" />}

                                <Button
                                    buttonType="edit"
                                    entity={Entity.PersonV7}
                                    action={Action.Edit}
                                    onClick={() => setEditing(true)}
                                    disabled={editing}
                                    marginTop
                                />
                            </>
                        )
                        : (
                            <Formik
                                initialValues={{ p11DRequiredOngoing: person.p11DRequiredOngoing, p11DRequiredReason: person.p11DRequiredReason, companyCar: person.companyCar } as BenefitsPostModel}
                                validateOnChange={false}
                                enableReinitialize
                                onSubmit={updateCall}
                                validate={validateUpdate}
                                innerRef={refVal}
                            >
                                {({ isSubmitting,values }) => (
                                    
                                    <Form>
                                        <Switch name="p11DRequiredOngoing" label="Ongoing P11D needed" />
                                        <Text name="p11DRequiredReason" label="P11D needed (blank if not)" type="textarea" />
                                        <Switch name="companyCar" label="Company car/fuel" />

                                        <Submit disabled={isSubmitting} onCancelClick={() => setEditing(false)} />

                                    </Form>
                                )}
                            </Formik>
                        )
                    }
                </>
            )}
        </>
    )
}

export default Benefits