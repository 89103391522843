import { useState, useEffect, useContext } from 'react';
import { FormikHelpers } from 'formik';
import {
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Row,
  Col,
  Alert,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSortAmountUp, faSortAmountDown, faArchive, faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import _ from 'lodash';
// API
import { TenancyNotes } from '../../api/inni/TenancyNotes';
import { TenancyNote, TenancyNotePostModel } from '../../api/inni/data-contracts';
import { useInniAPI } from '../../hooks/useInniAPI';
// Hooks
import { useModalDialog } from '../../hooks/useModalDialog';
// Components
import { TenancyNoteEntry } from './Components/TenancyNoteEntry';
import { TenancyNoteEntryPlaceholder } from './Components/TenancyNotePlaceholder';
import { Button } from '../../elements/Button/Button';
import { TenancyNoteModal } from './Components/TenancyNoteModal';

import CompanyContext from '../../context/CompanyContext';
import styles from './TenancyNote.module.css';
import { usePersistedState } from '../../hooks/usePersistedState';
import SearchWithClear from '../../elements/SearchWithClear/SearchWithClear';
import { NoContentSlate } from '../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../hooks/terms/useDatagridTerms';


interface TenancyNotesPageProps {
  tenancyId: number,
}

export const TenancyNotesPage = ({tenancyId}: TenancyNotesPageProps) => {
  const companyContext = useContext(CompanyContext);
  const [search, setSearch] = useState('');
  const [viewMode, setViewMode] = useState<'active' | 'archived'>('active');
  const [showModal, setShowModal] = useState(false);
  const [editData, setEditData] = useState<TenancyNote | null>(null);
  const [loaded, setLoaded] = useState(false);
  const [errorLoading, setErrorLoading] = useState(false);
  const [entries, setEntries] = useState<TenancyNote[]>([]);
  const [shownEntries, setShownEntries] = useState<TenancyNote[]>([]);

  const [showModalDialog, modalDialog] = useModalDialog();

  const [viewOrder, setViewOrder] = usePersistedState('local', 'TenancyNotesPage_view', 'asc', ['asc', 'desc']);

  const tenancyNotesApi = useInniAPI(TenancyNotes);

  // Load entries
  useEffect(() => {
    if (tenancyNotesApi) {
      tenancyNotesApi
        .index(companyContext.cid, tenancyId)
        .then((response) => {
          if (response.status === 200) {
            setEntries(response.data);
            setLoaded(true);
          }
        })
        .catch((error) => {
          console.error(error);
          setErrorLoading(true);
        });
    }
  }, [tenancyNotesApi, companyContext.cid, tenancyId]);

  // Update shown entries (filters)
  // TODO: pagination - DD
  useEffect(() => {
    const shown = _.filter(entries, {archived: (viewMode === 'archived')});
    if (viewOrder === 'desc')
      shown.reverse();

    setShownEntries(shown);
  }, [entries, viewMode, viewOrder])

  // Clear search on escape key
  const checkEscapeKey = (key: string) => {
    if (key === 'Escape')
      setSearch('');
  }

  const showEditModal = (data: TenancyNote | null = null) => {
    setEditData(data)
    setShowModal(true);
  }

  const updateNote = (values: TenancyNotePostModel, actions: FormikHelpers<TenancyNotePostModel>): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (!editData)
        return reject();

      if (tenancyNotesApi) {
        tenancyNotesApi.update(companyContext.cid, tenancyId, editData.id, values)
        .then(response => {
          setShowModal(false);
          resolve();

          // Request success, update UI
          if (response.status === 200) {
            for (let i = 0; i <= entries.length; i += 1) {
              if (entries[i].id === editData.id) {
                const newEntries = _.cloneDeep(entries);
                newEntries[i].title = values.title;
                newEntries[i].text = values.text;
                setEntries(newEntries);
                break;
              }
            }
          }

        })
        .catch(error => {
          actions.setErrors(error.error);
          reject();
        })
      } else {
        reject();
      }
    })
  }

  const createNote = (values: TenancyNotePostModel, actions: FormikHelpers<TenancyNotePostModel>): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (tenancyNotesApi) {
        tenancyNotesApi.create(companyContext.cid, tenancyId, values)
        .then(response => {
          setShowModal(false);
          resolve();

          // Request success, update UI
          if (response.status === 201) {
            const newEntries = [...entries];
            newEntries.push({
                id: parseInt(response.data),
                companyId: companyContext.cid,
                tenancyId: tenancyId,
                createdAt: new Date().toString(),
                title: values.title,
                text: values.text,
                archived: false
              })

            setViewMode('active');
            setEntries(newEntries);
          }
        })
        .catch(error => {
          actions.setErrors(error.error);
          reject();
        })
      } else {
        reject();
      }
    })
  }

  const showDeleteDialog = (id: number, title: string) => {
    showModalDialog(
      'Delete note?',
      `Are you sure you want to delete note '${title}'?`,
      [
        <Button variant="danger" label="Yes" onClick={() => deleteNote(id)} />,
        <Button variant="secondary" label="No" onClick={() => {}} />,
      ],
      false
    );
  }

  const deleteNote = (id: number) => {
    tenancyNotesApi?.delete(companyContext.cid, tenancyId, id)
      .then((response) => {
        if (response.status === 200)
          setEntries(_.filter(entries, (entry) => entry.id !== id));
      })
  }

  const showArchiveDialog = (id: number, archive: boolean, title: string) => {
    const modalTitle = (archive) ? 'Archive Note?' : 'Restore Note?';
    const modalText = (archive)
      ? `Are you sure you want to archive note '${title}'? It can be restored back later.`
      : `Restore note '${title}' back to active?`

    showModalDialog(
      modalTitle,
      modalText, 
      [
        <Button variant="primary" label="Yes" onClick={() => archiveNote(id, archive)} />,
        <Button variant="secondary" label="No" onClick={() => {}} />,
      ],
      false
    );
  }

  const archiveNote = (id: number, archive: boolean) => {
    for (let i = 0; i <= entries.length; i += 1) {
      if (entries[i].id === id) {
        const newEntries = _.cloneDeep(entries);
        newEntries[i].archived = archive;

        // Submit update
        tenancyNotesApi?.update(companyContext.cid, tenancyId, id, newEntries[i])
          .then((response) => {
            // Update UI
            if (response.status === 200) {
              setEntries(newEntries);
            }
          }
        )

        break;
      }
    }
  } 

  return (
    <Container id={styles.tenancyNotes} className="ml-0">
      <Row>
        {/* New */}
        <Col sm={12} xl={2} className={styles.alignItemsBottom}>
          <Button buttonType="new" onClick={showEditModal} />
        </Col>

        {/* Toggles */}
        <Col sm={12} xl={6} className={`${styles.alignItemsBottom} my-sm-1 my-xl-0 justify-content-end`}>
          <ToggleButtonGroup type="radio" name="options" value={viewOrder} onChange={setViewOrder}>
            <ToggleButton data-cy="ascButton" value="asc" variant={viewOrder === 'asc' ? 'outline-primary' : 'outline-secondary'}>
              <FontAwesomeIcon icon={faSortAmountUp} />
            </ToggleButton>
            <ToggleButton data-cy="descButton" value="desc" variant={viewOrder === 'desc' ? 'outline-primary' : 'outline-secondary'}>
              <FontAwesomeIcon icon={faSortAmountDown} />
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup type="radio" name="options" value={viewMode} onChange={setViewMode} style={{marginLeft: '15px'}}>
            <ToggleButton value="active" variant={viewMode === 'active' ? 'outline-primary' : 'outline-secondary'}>
              <FontAwesomeIcon icon={faFileAlt} className={`${styles.hideIcon} mr-2`} />
              Active
            </ToggleButton>
            <ToggleButton value="archived" variant={viewMode === 'archived' ? 'outline-primary' : 'outline-secondary'}> 
              <FontAwesomeIcon icon={faArchive} className={`${styles.hideIcon} mr-2`} />
              Archived
            </ToggleButton>
          </ToggleButtonGroup>
        </Col>

        {/* Search */}
        <Col sm={12} xl={4} className={styles.alignItemsBottom}>
        <SearchWithClear search={search || ""} setSearch={setSearch} placeholder="Search notes..." />
        </Col>
      </Row>

      <hr style={{ margin: '10px 0 20px 0' }} />

      { loaded
        ? (
          <>
            { shownEntries.length === 0 
              ? (
                // No notes
                <NoContentSlate type={(viewMode === 'archived') ? DatagridType.TenancyNotesArchived:DatagridType.TenancyNotes} termsKey="emptyTerms" whiteBg />
              )
              : (
                // Notes
                 shownEntries.map((entry) => {
                  if (entry.title?.toLowerCase().includes(search.toLowerCase()) || entry.text?.toLowerCase().includes(search.toLowerCase()))
                    return (
                      <TenancyNoteEntry
                        key={entry.id}
                        data={entry}
                        showArchiveDialog={showArchiveDialog}
                        showDeleteDialog={showDeleteDialog}
                        showEditModal={showEditModal}
                      />
                    )
                })
              )
            }
          </>
        )
        : (
          !errorLoading && (
            <>
              <TenancyNoteEntryPlaceholder />
              <TenancyNoteEntryPlaceholder />
            </>
          )
        )
      }

      { errorLoading && (
        <div className={styles.centerFlexContent}>
          <Alert variant="danger">
            <Alert.Heading>We're sorry, something went wrong</Alert.Heading>
          </Alert>
        </div>
      )}

      {/* Create/edit note modal */}
      { showModal && (
        <TenancyNoteModal
          hide={() => setShowModal(false)}
          create={createNote}
          update={updateNote}
          data={editData}
        />
      )}

      { modalDialog }

    </Container>
  );
}
