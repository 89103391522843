import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity, Action } from "../../utils/EntityAction";
import PortalProfile from "../../features/UserPortal/Profile";

// Note that this is a duplicate path of the hub's profile page - hopefully we can strip hub at some point... -DD
const userProfile:Array<EntityRoute> = [
    {
        path: '/company/:cid/UserProfile/profile',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.UserProfile,
        action: Action.Edit,
        component: PortalProfile,
    }
]

export default userProfile;
