import { useContext, useEffect, useState } from "react";
import { LettingAgent, LettingAgentPostModel } from "../../api/inni/data-contracts";
import { LettingAgents } from "../../api/inni/LettingAgents";
import CompanyContext from "../../context/CompanyContext";
import { useInniAPI } from "../useInniAPI";

export const useLettingAgentsList = () => {
    const companyContext = useContext(CompanyContext);
    const lettingApi = useInniAPI(LettingAgents);
    const [agents, setAgents] = useState<Array<LettingAgent>>([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (companyContext && lettingApi && !loaded && !loading) {
            setLoading(true);
            lettingApi.index(companyContext.cid).then(
                response => {
                    setAgents(response.data);
                    setLoaded(true);
                }
            ).catch(error => setLoaded(true))
        }
    }, [companyContext, lettingApi, loaded, loading])


    const addNewAgentOption = (pm: LettingAgentPostModel, onSuccess?: (data: {id: number, name?: string}) => void) => {
        if(companyContext && lettingApi) {
            lettingApi.create(companyContext.cid, pm)
                .then(({data}) => {
                    setAgents(prev => [...prev, {id: +data, name: pm.name}]);
                    return {id: +data, name: pm.name};
                })
                .then(onSuccess)
                .catch(err => console.error(err))
        }
    }

    return {agents, loaded, addNewAgentOption};
}