import { useFormik } from 'formik';
import { useCallback, useContext } from 'react';
import { Onboarding } from '../../api/inni/Onboarding';
import CompanyContext from '../../context/CompanyContext';
import { useInniAPI } from '../useInniAPI';
export interface ICSV {
	fileToUpload: File | null;
	documentId: number;
	fileName?: string;
	isNew?: boolean;
	isUpdated?: boolean;
}

export const useUploadCSV = () => {
	const csvApi = useInniAPI(Onboarding);
	const { cid } = useContext(CompanyContext);
	const uploadCVSFormik = useFormik({
		initialValues: [] as ICSV[],
		onSubmit: (values) => {},
	});

	const makePromiseUpload = useCallback((fileToUpload: File | null) => {
		return new Promise((resolve, reject) => {
			if (cid && csvApi && fileToUpload) {
				csvApi.uploadCsvBookkeeping(cid, { fileToUpload }).then(resolve).catch(reject);
			}
		})
	}, [cid, csvApi])
	const makePromiseReplace = useCallback((fileToUpload: File | null, documentId: number) => {
		return new Promise((resolve, reject) => {
			if (cid && csvApi && fileToUpload) {
				csvApi.replaceUploadedCsvFile(cid, documentId, {fileToUpload}).then(resolve).catch(reject);
			}
		})
	}, [cid, csvApi])

	const onUploadCVSFiles = useCallback((onSuccess: () => void) => {
		uploadCVSFormik.setSubmitting(false)
		const newFiles = uploadCVSFormik.values.filter(v => v.isNew && !v.isUpdated);
		const replacedFiles = uploadCVSFormik.values.filter(v => v.isUpdated);
		const uploadPromises = newFiles.map(({fileToUpload}) => makePromiseUpload(fileToUpload));
		const replacedPromises = replacedFiles.map(({fileToUpload, documentId}) => makePromiseReplace(fileToUpload, documentId));
		(newFiles.length  || replacedFiles.length) && Promise.all([...uploadPromises, ...replacedPromises]).then(onSuccess).then(() => uploadCVSFormik.setSubmitting(true))
	}, [makePromiseReplace, makePromiseUpload, uploadCVSFormik]);
	return { uploadCVSFormik, onUploadCVSFiles };
};
