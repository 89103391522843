/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CompanyDetailsPostModel,
  ConfirmPagePostModel,
  CreditorsPostModel,
  DebtorsPostModel,
  OpeningBalancesPostModel,
  PayePaymentPostModel,
  PayrollSetupPostModel,
  SetupWizardForm,
  VatReturnSetupPostModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class SetupWizard<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags SetupWizard
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/setupwizard
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<SetupWizardForm, any>({
      path: `/api/v2/companies/${companyId}/setupwizard`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ValidateUpdateCompanyDetails
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/ValidateUpdateCompanyDetails
   * @secure
   */
  validateUpdateCompanyDetails = (companyId: number, model: CompanyDetailsPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ValidateUpdateCompanyDetails`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name UpdateCompanyDetails
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/UpdateCompanyDetails
   * @secure
   */
  updateCompanyDetails = (companyId: number, model: CompanyDetailsPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/UpdateCompanyDetails`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ValidateUpdatePayroll
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/ValidateUpdateCompanyPayroll
   * @secure
   */
  validateUpdatePayroll = (companyId: number, model: PayrollSetupPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ValidateUpdateCompanyPayroll`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name UpdateCompanyPayroll
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/UpdateCompanyPayroll
   * @secure
   */
  updateCompanyPayroll = (companyId: number, model: PayrollSetupPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/UpdateCompanyPayroll`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ValidateUpdateVatReturn
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/ValidateUpdateVatReturn
   * @secure
   */
  validateUpdateVatReturn = (companyId: number, model: VatReturnSetupPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ValidateUpdateVatReturn`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name UpdateVatReturn
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/UpdateVatReturn
   * @secure
   */
  updateVatReturn = (companyId: number, model: VatReturnSetupPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/UpdateVatReturn`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ValidateUpdatePayePayments
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/ValidateUpdatePayePayments
   * @secure
   */
  validateUpdatePayePayments = (companyId: number, model: PayePaymentPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ValidateUpdatePayePayments`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name UpdatePayePayments
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/UpdatePayePayments
   * @secure
   */
  updatePayePayments = (companyId: number, model: PayePaymentPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/UpdatePayePayments`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ValidateUpdateOpeningBalance
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/ValidateUpdateOpeningBalance
   * @secure
   */
  validateUpdateOpeningBalance = (
    companyId: number,
    query: { accountId: number },
    model: OpeningBalancesPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ValidateUpdateOpeningBalance`,
      method: "PUT",
      query: query,
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name UpdateOpeningBalance
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/UpdateOpeningBalance
   * @secure
   */
  updateOpeningBalance = (
    companyId: number,
    query: { accountId: number },
    model: OpeningBalancesPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/UpdateOpeningBalance`,
      method: "PUT",
      query: query,
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ValidateCreateCreditor
   * @request POST:/api/v2/companies/{companyId}/setupwizard/ValidateCreateCreditor
   * @secure
   */
  validateCreateCreditor = (companyId: number, model: CreditorsPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ValidateCreateCreditor`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name CreateCreditor
   * @request POST:/api/v2/companies/{companyId}/setupwizard/CreateCreditor
   * @secure
   */
  createCreditor = (companyId: number, model: CreditorsPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/CreateCreditor`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ValidateUpdateCreditor
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/ValidateUpdateCreditor
   * @secure
   */
  validateUpdateCreditor = (
    companyId: number,
    query: { id: number },
    model: CreditorsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ValidateUpdateCreditor`,
      method: "PUT",
      query: query,
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name UpdateCreditor
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/UpdateCreditor
   * @secure
   */
  updateCreditor = (companyId: number, query: { id: number }, model: CreditorsPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/UpdateCreditor`,
      method: "PUT",
      query: query,
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ValidateCreateDebtor
   * @request POST:/api/v2/companies/{companyId}/setupwizard/ValidateCreateDebtor
   * @secure
   */
  validateCreateDebtor = (companyId: number, model: DebtorsPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ValidateCreateDebtor`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name CreateDebtor
   * @request POST:/api/v2/companies/{companyId}/setupwizard/CreateDebtor
   * @secure
   */
  createDebtor = (companyId: number, model: DebtorsPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/CreateDebtor`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ValidateUpdateDebtor
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/ValidateUpdateDebtor
   * @secure
   */
  validateUpdateDebtor = (
    companyId: number,
    query: { id: number },
    model: DebtorsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ValidateUpdateDebtor`,
      method: "PUT",
      query: query,
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name UpdateDebtor
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/UpdateDebtor
   * @secure
   */
  updateDebtor = (companyId: number, query: { id: number }, model: DebtorsPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/UpdateDebtor`,
      method: "PUT",
      query: query,
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name DeleteCreditor
   * @request DELETE:/api/v2/companies/{companyId}/setupwizard/DeleteCreditor
   * @secure
   */
  deleteCreditor = (companyId: number, query: { id: number }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/DeleteCreditor`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name DeleteDebtor
   * @request DELETE:/api/v2/companies/{companyId}/setupwizard/DeleteDebtor
   * @secure
   */
  deleteDebtor = (companyId: number, query: { id: number }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/DeleteDebtor`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ValidateUpdateConfirmPage
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/ValidateUpdateConfirmPage
   * @secure
   */
  validateUpdateConfirmPage = (companyId: number, model: ConfirmPagePostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ValidateUpdateConfirmPage`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name UpdateConfirmPage
   * @request PUT:/api/v2/companies/{companyId}/setupwizard/UpdateConfirmPage
   * @secure
   */
  updateConfirmPage = (companyId: number, model: ConfirmPagePostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/UpdateConfirmPage`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags SetupWizard
   * @name ResetWizard
   * @request POST:/api/v2/companies/{companyId}/setupwizard/ResetWizard
   * @secure
   */
  resetWizard = (companyId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/setupwizard/ResetWizard`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
}
