import { faChevronDown, faChevronRight, faChevronUp } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { BankTransactions } from '../../../api/inni/BankTransactions'
import { BankPaymentSearchView } from '../../../api/inni/data-contracts'
import CompanyContext from '../../../context/CompanyContext'
import { Button } from '../../../elements/Button/Button'
import InfoBanner from '../../../elements/InfoBanner/InfoBanner'
import SearchWithClear from '../../../elements/SearchWithClear/SearchWithClear'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { formatDate } from '../../../utils/formatDate'
import { formatCurrency } from '../../../utils/formatNumbers'
import styles from '../Receipts.module.css'

const ReceiptPage1 = ({changePageCB, date, id, attachReceipt} : {changePageCB : (i : number) => void, date : string, id : number, attachReceipt : (id : number, bpid : number) => void}) => {

    const [search, setSearch] = useState('');

    const [searchResults, setSearchResults] = useState<BankPaymentSearchView[] | undefined>()
    const [searchResultsLoaded, setSearchResultsLoaded] = useState(false)

    const [showMore, setShowMore] = useState(5)

    const bankingAPI = useInniAPI(BankTransactions)
    const companyContext = useContext(CompanyContext)

    useEffect(() => {
        setSearchResultsLoaded(false)
    }, [search, setSearch])

    useEffect(() => {
        if (bankingAPI && !searchResultsLoaded) {
            bankingAPI.searchBankPayments(companyContext.cid, {
                q: search.length > 0 ? search : undefined,
                nearDate: date
            }).then(res => {
                setShowMore(5)
                setSearchResults(res.data)
                setSearchResultsLoaded(true)
            })
        }
    }, [bankingAPI, companyContext.cid, date, search, searchResultsLoaded])

    return(
        <>
            <h5>Is this a new transaction?</h5>
            <Button variant="light" className={styles.blockButtonMarginBottom} onClick={() => changePageCB(2)}>
                Enter as new transaction <FontAwesomeIcon icon={faChevronRight}/>
            </Button>
            <h5>Or have you previously entered it?</h5>
            <SearchWithClear search={search} setSearch={setSearch} /> 
            { searchResults && // we show AT MOST 5 search results unless we click show more, we also assume that the smallest description is closer to the search query, or should we sort by most recent
                _.sortBy(searchResults, (x) => x.description?.length).slice(0, showMore).map(x => {
                    return (
                        <div onClick={() => attachReceipt(id, x.bankPaymentId)} key={x.bankPaymentId} className={styles.searchResult}>
                            <p>{x.description}</p>
                            <p className={styles.dateTxt}>{formatCurrency(x.amount)} on {formatDate(x.date)}</p>
                        </div>
                    )
                })
            }

            {searchResults && searchResults.length > 5 &&
            <div onClick={() => showMore >= searchResults.length ? setShowMore(5) : setShowMore(showMore + 5)} className={styles.searchResult}>
                    <p>
                        <FontAwesomeIcon 
                            style={{marginRight: '10px'}} 
                            icon={showMore >= searchResults.length ? faChevronUp : faChevronDown} 
                        />
                        {showMore >= searchResults.length ? 'Hide' : 'Show more'}
                    </p>
            </div>}
            { (!searchResults || searchResults.length === 0) &&
                <InfoBanner type='info' title='No relevant transactions found' body='There were no expenses found near the date this picture was uploaded. Try searching.' />}
        </>
    )
} 
export default ReceiptPage1