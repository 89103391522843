/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  IncorporationFile,
  IncorporationFormAsSummary,
  IncorporationFormPostModel,
  IncorporationFormViewModel,
  Participant,
  ShareClass,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class IncorporationForm<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name Get
   * @request GET:/api/v2/contacts/{contactId}/incorporation/Get
   * @secure
   */
  get = (contactId: number, params: RequestParams = {}) =>
    this.request<IncorporationFormViewModel, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/Get`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name GetOrCreate
   * @request GET:/api/v2/contacts/{contactId}/incorporation/GetOrCreate
   * @secure
   */
  getOrCreate = (contactId: number, params: RequestParams = {}) =>
    this.request<IncorporationFormViewModel, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/GetOrCreate`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name GetFiles
   * @request GET:/api/v2/contacts/{contactId}/incorporation/Files
   * @secure
   */
  getFiles = (contactId: number, params: RequestParams = {}) =>
    this.request<IncorporationFile[], any>({
      path: `/api/v2/contacts/${contactId}/incorporation/Files`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name ValidateMainForm
   * @request POST:/api/v2/contacts/{contactId}/incorporation/ValidateMainForm
   * @secure
   */
  validateMainForm = (contactId: number, postmodel: IncorporationFormPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/ValidateMainForm`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name GetAsSummary
   * @request GET:/api/v2/contacts/{contactId}/incorporation/GetAsSummary
   * @secure
   */
  getAsSummary = (contactId: number, params: RequestParams = {}) =>
    this.request<IncorporationFormAsSummary, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/GetAsSummary`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name UpdateApplication
   * @request PUT:/api/v2/contacts/{contactId}/incorporation
   * @secure
   */
  updateApplication = (contactId: number, pm: IncorporationFormPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/incorporation`,
      method: "PUT",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name ValidateCreateShare
   * @request POST:/api/v2/contacts/{contactId}/incorporation/ValidateCreateShare
   * @secure
   */
  validateCreateShare = (contactId: number, postmodel: ShareClass, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/ValidateCreateShare`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name CreateShare
   * @request POST:/api/v2/contacts/{contactId}/incorporation/CreateShare
   * @secure
   */
  createShare = (contactId: number, postmodel: ShareClass, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/CreateShare`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name ValidateUpdateShare
   * @request PUT:/api/v2/contacts/{contactId}/incorporation/ValidateUpdateShare
   * @secure
   */
  validateUpdateShare = (contactId: number, postmodel: ShareClass, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/ValidateUpdateShare`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name UpdateShare
   * @request PUT:/api/v2/contacts/{contactId}/incorporation/UpdateShare
   * @secure
   */
  updateShare = (contactId: number, postmodel: ShareClass, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/UpdateShare`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name DeleteShare
   * @request DELETE:/api/v2/contacts/{contactId}/incorporation/DeleteShare
   * @secure
   */
  deleteShare = (contactId: number, query: { id: number }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/DeleteShare`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name ValidateCreatePerson
   * @request POST:/api/v2/contacts/{contactId}/incorporation/ValidateCreatePerson
   * @secure
   */
  validateCreatePerson = (contactId: number, postmodel: Participant, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/ValidateCreatePerson`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name CreatePerson
   * @request POST:/api/v2/contacts/{contactId}/incorporation/CreatePerson
   * @secure
   */
  createPerson = (contactId: number, postmodel: Participant, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/CreatePerson`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name ValidateUpdatePerson
   * @request PUT:/api/v2/contacts/{contactId}/incorporation/ValidateUpdatePerson
   * @secure
   */
  validateUpdatePerson = (contactId: number, postmodel: Participant, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/ValidateUpdatePerson`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name UpdatePerson
   * @request PUT:/api/v2/contacts/{contactId}/incorporation/UpdatePerson
   * @secure
   */
  updatePerson = (contactId: number, postmodel: Participant, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/UpdatePerson`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags IncorporationForm
   * @name DeletePerson
   * @request DELETE:/api/v2/contacts/{contactId}/incorporation/DeletePerson
   * @secure
   */
  deletePerson = (contactId: number, query: { id: number }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/incorporation/DeletePerson`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
}
