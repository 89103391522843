import { ErrorMessage, FieldMetaProps, FieldInputProps, useField, FieldHelperProps } from 'formik';
import { Col, Form, InputGroup } from 'react-bootstrap';
import Select, { GroupTypeBase, MenuProps, components } from 'react-select';
import styles from './FormikField.module.css';
import classNames from 'classnames';
import { InvoiceReactSelect } from '../../features/Invoices/CreateInvoice';
import { asYearMonthDay} from '../../utils/formatDate';
import { BrandWrapper } from '../../components/BrandWrapper/BrandWrapper';
import { Button } from '../Button/Button';

// TODO: this file has remnants of invoices, needs to be general purpose

type PrefixWidthSize = 'small'|'medium'|'large'

export interface FormikFieldProps<T> {
	value?: string | number | string[] | undefined;
	readonly?: boolean;
	name: Extract<keyof T, string>;
	type: 'text' |'email' | 'number' | 'date' | 'select' | 'textarea' | 'checkbox' | 'label' | 'textarea'| 'selctwithparent';
	rows?: number;
	option?: InvoiceReactSelect[];
	onChange?: (e: any) => void;
	defaultValue?: string;
	size?: 'sm' | 'lg' | undefined;
	className?: string;
	width?: string;
	prefix?:string;
	suffix?:string;
	isTable?: boolean;
	label?: string;
	noshadow?: boolean;
	min?:string;
	max?:string;
	showErrorLine?:boolean;
	placeholder?:string;
	cols?:number;
	extraOption?:InvoiceReactSelect[];
	dropDownOptions?: DropdownOptions[];
	toFixed2DP?:boolean;
	prefixWidth?: PrefixWidthSize; 
	selectAddNew?: () => void;
	afterFormikOnChange?: (e:any) => void;
	onlyIntegers?: boolean;
	onlyPositiveNumbers?: boolean;
	step?: string;
	boldLabel?: boolean;
	regularLabel?: boolean;
	helpMessage?: string;
	noPadding?: boolean;
	positionHack?: boolean; // Some formik fields don't align vertically... no time to fix this now, temporary position hack (probably inline vs flex)
	wrapLabel?: boolean
}

export interface ChildInputProps{
	 width?: string;
	 isTable?: boolean;
	 label?: string;
	 suffix?:string;
	 prefix?:string;
	 showErrorLine?:boolean;
	 meta:FieldMetaProps<any>;
	 field:FieldInputProps<any>;
	 placeholder?:string;
	 readonly?:boolean;
	 className?:string;
	 toFixed2DP?:boolean;
	 prefixWidth?: PrefixWidthSize;
	 min?: string|number,
	 max?: string|number,
	 onlyIntegers?: boolean,
	 onlyPositiveNumbers?: boolean,
	 step? : string;
	 boldLabel?: boolean
	 regularLabel?: boolean
	 helpers: FieldHelperProps<any>
	 wrapLabel?: boolean
}

export interface DropdownOptions {
	label: string; 
	value: string | number;
	parent?: string;
}

// TODO: (cap merge) this should be css class
const getPrefixWidth = (width: PrefixWidthSize) => {
	if (width === "small") return "30px" // probably should be micro, very small
	else if (width === "medium") return "300px"
	else if (width === "large") return "500px"

	return ""
}

function FormikField<T>({ ...props }: FormikFieldProps<T>) {
	switch (props.type) {
		case 'text':
			return <FormikTextField {...props} />;
		case 'textarea':
			return <FormikTextAreaField {...props} />;
		case 'email':
			return <FormikEmailField {...props} />;
		case 'number':
			return <FormikNumberField {...props} />;
		case 'select':
			return <FormikSelectField {...props} />;
		case 'date':
			return <FormikDateField {...props} />;
		case 'checkbox':
			return <FormikCheckboxField {...props} />;
		case 'label':
			return <FormikLabelField {...props} />;
		case 'selctwithparent':
			return <FormikSelectFieldwithParent {...props} />;
		default:
			return null;
	}
}

function FormikTextField<T>({
		noshadow,
		isTable,
		label,
		showErrorLine,
		prefixWidth,
		boldLabel,
		regularLabel,
		helpMessage,
		noPadding,
		positionHack,
		wrapLabel,
		...props
	}: FormikFieldProps<T>) {

	const [field, meta] = useField(props);
	return (
		<>
			<Form.Group
				data-cy="formTextField"
				as={Col}
				className={classNames(
					props.className,
					styles.inputStyle,
					showErrorLine && meta.error && meta.touched ? styles.dangerLine : ' ',
					noPadding ? 'pl-0 mb-0' : ''
				)}
				style={props.size && { marginBottom: '0' }}
			>
				{(!isTable || label) && (
					<Form.Label 
						className={classNames({
									'text-muted': !boldLabel && !regularLabel, 
									[styles.boldText]: boldLabel,
									[styles.wrapLabel]: wrapLabel
								})}
					>
						{label}
					</Form.Label>
				)}

				{helpMessage && <Form.Text className={classNames("text-muted", styles.helpMessage)}>{helpMessage}</Form.Text> }
				<Form.Control
					className={!noshadow ? classNames(styles.inputSelectStyle,'d-inline-block') : 'd-inline-block'} // TODO: Fix properly with classNames
					style={{
						borderRadius: '0',
						maxWidth: prefixWidth ? getPrefixWidth(prefixWidth) : undefined,
						position: 'relative',
						bottom: positionHack ? '10px' : 'inherit'
					}}
					autoComplete='off'
					{...field}
					value={field.value || ''}
					name={field.name}
					readOnly={props.readonly}
					isInvalid={((meta.touched && (meta.error !== undefined)))}
					placeholder={props.placeholder}
				/>
				{!showErrorLine && <ErrorMessage component='span' name={field.name} className="errorText" />}
			</Form.Group>
		</>
	);
}

function FormikTextAreaField<T>({ noshadow, isTable, label, boldLabel, regularLabel, wrapLabel, ...props }: FormikFieldProps<T>) {
	const [field, meta] = useField(props);
	return (
		<>
			<Form.Group data-cy="formTextAreaField" as={Col} className={classNames(props.className,styles.inputStyle)} style={props.size && { marginBottom: '0' }}>
				{(!isTable || label) && (
					<Form.Label 
						className={classNames({
									'text-muted': !boldLabel && !regularLabel, 
									[styles.boldText]: boldLabel, 
									[styles.wrapLabel]: wrapLabel
								})}
					>
						{label}
					</Form.Label>
				)}
				<Form.Control
					className={!noshadow ? classNames(styles.inputSelectStyle,'d-inline-block') : 'd-inline-block'}
					style={{ borderRadius: '0' }}
					autoComplete='off'
					{...field}
					as='textarea'
					rows={props.rows || 5}
					cols={props.cols ||30}
					value={field.value || ''}
					type='text'
					name={field.name}
					readOnly={props.readonly}
					isInvalid={meta.touched && meta.error !== undefined}
					placeholder={props.placeholder}
				/>
				<ErrorMessage component='span' name={field.name} className="errorText" />
			</Form.Group>
		</>
	);
}

function FormikEmailField<T>({ noshadow, isTable, label,showErrorLine, ...props }: FormikFieldProps<T>) {
	const [field, meta] = useField(props);
	return (
		<>
			<Form.Group data-cy="formEmailField" as={Col} className={classNames(props.className,styles.inputStyle,showErrorLine && meta.error && meta.touched ? styles.dangerLine : ' ')} style={props.size && { marginBottom: '0' }}>
				{(!isTable || label) && <Form.Label className='text-muted'>{label}</Form.Label>}
				<Form.Control
					className={!noshadow ? classNames(styles.inputSelectStyle,'d-inline-block') : 'd-inline-block'}
					style={{ borderRadius: '0' }}
					autoComplete='off'
					{...field}
					value={field.value || ''}
					name={field.name}
					readOnly={props.readonly}
					isInvalid={((meta.touched && (meta.error !== undefined)))}
					placeholder={props.placeholder}
					type='email'
				/>
				{!showErrorLine && <ErrorMessage component='span' name={field.name} className="errorText" />}
			</Form.Group>
		</>
	);
}

const NumericChildField = ({
		width,
		isTable,
		label,
		suffix,
		prefix,
		showErrorLine = false,
		meta,
		field,
		readonly,
		placeholder,
		toFixed2DP,
		prefixWidth,
		min,
		max,
		onlyIntegers,
		onlyPositiveNumbers,
		step,
		boldLabel,
		regularLabel,
		helpers,
		wrapLabel
	}: ChildInputProps) => {

	const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) =>{
		if(onlyIntegers){
			if (e.key === '.' || e.key === ',' ) {
				e.preventDefault();
			}
		}

		if(onlyPositiveNumbers){
			if (e.key === '-' ) {
				e.preventDefault();
			}
		}		
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value;
		let numericValue = parseFloat(value);
		if (!isNaN(numericValue)) {
			if (max && numericValue > parseFloat(max.toString())){
				numericValue = parseFloat(max.toString());
			}
			helpers.setValue(numericValue);
		}
		else{
			helpers.setValue(e.target.value);
		}		
	};


	return(
		<>
			{(!isTable || label) && (
				<Form.Label 
					className={classNames({
								'text-muted': !boldLabel && !regularLabel, 
								[styles.boldText]: boldLabel,
								[styles.wrapLabel]: wrapLabel
							})}
				>
					{label}
				</Form.Label>
			)}
			{prefix && <InputGroup.Prepend>
				<InputGroup.Text className={styles.suffix} 
					style={prefixWidth ? {width: getPrefixWidth(prefixWidth), justifyContent: 'center'} : {}}>
						{prefix}
				</InputGroup.Text>
			</InputGroup.Prepend>
			}
			<Form.Control
				className={classNames(styles.inputSelectStyle, 'd-inline-block', isTable ? 'text-right' : '')}
				style={{ width: width || undefined }}
				autoComplete="off"
				{...field}
				//In somefields value is passed a props if the property is not available in the model
				value={field.value === ' ' || field.value === null ? 0 : toFixed2DP ? Number(field.value).toFixed(2) : field.value}
				type="number"
				name={field.name}
				readOnly={readonly}
				isInvalid={meta.touched && meta.error !== undefined}
				placeholder={placeholder}
				min={min}
				max={max}
				onKeyDown={handleKeyDown}
				step={step}
				onChange={handleChange}
			/>
			{suffix && <InputGroup.Append>
				<InputGroup.Text className={styles.suffix}>{suffix}</InputGroup.Text>
			</InputGroup.Append>}
			{!showErrorLine && 
				<ErrorMessage 
					component="span" 
					name={field.name} 
					className={classNames("errorText",(suffix || prefix) ? styles.errorMessageNumberField : '')} 
				/>
			}
		</>

	)

}


function FormikNumberField<T>({noPadding, ...props}: FormikFieldProps<T>) {
	const [field, meta, helpers] = useField(props);
	const { suffix, prefix ,isTable,showErrorLine } = props;
	return (
		<>
			{(suffix || prefix) ? (
				<InputGroup
				    data-cy="formNumberField"
					as={Col}
					className={classNames(
						props.className, 
						styles.inputStyle,
						isTable && styles.hideArrow,
						showErrorLine && meta.touched ? styles.dangerLine : ' ',
						noPadding ? 'pl-0' : ''
					)}
					style={{ marginBottom: '0', padding: isTable ? 0 : '' }}>
					<NumericChildField field={field} meta={meta} helpers={helpers} {...props}/>
				</InputGroup>
			) : (
				<Form.Group
					data-cy="formNumberField"
					as={Col}
					className={classNames(
						props.className,
						styles.inputStyle,
						isTable && styles.hideArrow,
						showErrorLine && meta.touched ? styles.dangerLine : ' '
					)}
					style={{ marginBottom: '0', padding: isTable ? 0 : '' }}>
					<NumericChildField field={field} meta={meta} helpers={helpers} {...props}/>
				</Form.Group>
			)}
		</>
	);
}

function FormikDateField<T>({
		width,
		isTable,
		label,
		afterFormikOnChange,
		boldLabel,
		regularLabel,
		wrapLabel,
		...props
	}: FormikFieldProps<T>) {

	const [field, meta, helpers] = useField(props);

	return (
		<>
			<Form.Group data-cy="formDateField" as={Col} className={classNames(props.className,styles.inputStyle)}>
				{(!isTable || label) && (
					<Form.Label 
						className={classNames({
									'text-muted': !boldLabel && !regularLabel, 
									[styles.boldText]: boldLabel,
									[styles.wrapLabel]: wrapLabel
								})}
					>
						{label}
					</Form.Label>
				)}
				<Form.Control
					className={classNames(styles.inputSelectStyle,styles.inputDateStyle)}
					autoComplete='off'
					min={props.min ? asYearMonthDay(new Date(props.min)).split('T')[0] : undefined}
					max={props.max ? asYearMonthDay(new Date(props.max)).split('T')[0] : undefined}
					{...field}
					onBlur={props.onChange}
					value={field.value ? field.value.split('T')[0] : undefined} // date only
					type='date'
					name={field.name}
					readOnly={props.readonly}
					isInvalid={meta.touched && meta.error !== undefined}
					style={{ width: width || undefined }}
					onChange={(e:any) => {
						helpers.setValue(e.target.value)
						if(afterFormikOnChange) afterFormikOnChange(e)
					}}
				/>
				<ErrorMessage component='span' name={field.name} className="errorText" />
			</Form.Group>
		</>
	);
}

type CustomMenuProps = React.ComponentType<MenuProps<any, false, GroupTypeBase<any>>>;
function FormikSelectField<T>({ option, size, label, isTable,readonly,extraOption,width , selectAddNew, ...props }: FormikFieldProps<T>) {
	const [field, , helpers] = useField(props);

	//Copied from EditRow.tsx
	const CustomMenu: CustomMenuProps = ({children, ...props}) =>
	<components.Menu
		{...props}
	>
		<BrandWrapper>
			{children}
			<div className="p-2">
				<Button label="Add new" buttonType="new" variant="primary" className="btn-block" onClick={selectAddNew} />
			</div>
		</BrandWrapper>
	</components.Menu>

	return (
		<div>
			<Form.Group data-cy="formSelectField" as={Col} style={{ marginBottom: '0', padding: isTable ? 0 : '', width : width && width }} className={classNames(props.className,styles.inputStyle)}>
				{(!isTable || label) && <Form.Label className='text-muted'>{label}</Form.Label>}
				<Select
					className={classNames(styles.formikSelectStyle)}
					classNamePrefix={'formikSelectStyle'}
					options={extraOption && option ? [...extraOption, ...option] : option}
					name={field.name}
					value={option?.find((x) => x.value === field.value) || null}
					onChange={(value) => helpers.setValue(value?.value)}
					isDisabled={readonly} //Setting the value to the field.
					placeholder={props.placeholder}
					components={selectAddNew ? { Menu: CustomMenu } : undefined}
					menuPortalTarget={document.body}
					menuPlacement="auto"
					styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
				/>
				<ErrorMessage component='span' name={field.name} className="errorText" />
			</Form.Group>
		</div>
	);
}

function FormikCheckboxField<T>({ isTable, width, label, boldLabel, regularLabel, wrapLabel, noPadding, ...props }: FormikFieldProps<T>) {
	const [field, meta, helpers] = useField(props);
	return (
		<>
			<Form.Group data-cy="formCheckboxField" as={Col} className={classNames(props.className,styles.inputStyle, noPadding ? 'pl-0' : '')}>
				{(!isTable || label) && (
					<Form.Label 
						className={classNames({
									'text-muted': !boldLabel && !regularLabel, 
									[styles.boldText]: boldLabel,
									[styles.wrapLabel]: wrapLabel
								})}
					>
						{label}
					</Form.Label>
				)}
				<Form.Check
					className={styles.inputCheckboxStyle}
					style={{ width: width || undefined }}
					type='checkbox'
					name={field.name}
					checked={field.value}
					onChange={(e: any) => helpers.setValue(e.target.checked)}
					isInvalid={meta.touched && meta.error !== undefined}
					disabled={props.readonly}
				/>
				<ErrorMessage component='span' name={field.name} className="errorText" />
			</Form.Group>
		</>
	);
}

function FormikLabelField<T>({ width, isTable,label, ...props }: FormikFieldProps<T>) {
	return (
		<>
			<Form.Group data-cy="formLabelField" as={Col} className={classNames(props.className,styles.inputStyle)}  style={props.size && { marginBottom: '0' }}>
				{!isTable && <Form.Label className='text-muted'>{label}</Form.Label>}
				<Form.Control
				className={classNames(props.className,'d-inline-block')}
				value={props.value}
				name={props.name}
				style={{ width: width || undefined }} 
				readOnly={props.readonly} plaintext />
			</Form.Group>
		</>
	);
}

function FormikSelectFieldwithParent<T>({ dropDownOptions, size, label, isTable, readonly, extraOption, width, ...props }: FormikFieldProps<T>) {
	const [field, meta, helpers] = useField(props);
	const groupedOptions: { [key: string]: DropdownOptions[] } | undefined = dropDownOptions?.reduce((acc, option) => {
		if (option.parent) {
			if (!acc[option.parent]) {
				acc[option.parent] = [];
			}
			acc[option.parent].push(option);
		} else {
			acc.NoParent = acc.NoParent || [];
			acc.NoParent.push(option);
		}
		return acc;
	}, {} as any);
	const onChange = (selectedOption: DropdownOptions | DropdownOptions[]) => {
		if (Array.isArray(selectedOption)) {
			helpers.setValue(selectedOption.map((o: DropdownOptions) => o.value));
		} else {
			helpers.setValue(selectedOption?.value);
		}
	  };
	return (
		<div>
			<Form.Group data-cy="formDateField" as={Col} className={classNames(props.className, styles.inputStyle)}>
				{(!isTable || label) && <Form.Label className="text-muted">{label}</Form.Label>}
				<>
					{groupedOptions && 
					<Select
					className={classNames(styles.formikSelectStyle,)}
					classNamePrefix={"formikSelectStyle"}
						options={Object.keys(groupedOptions).map((parent) => {
							return {
								label: parent,
								options: groupedOptions[parent],
							};
						})}
						value={dropDownOptions ? dropDownOptions.find((o) => o.value === field.value) as any : null}
						onChange={onChange}
						isDisabled={readonly} //Setting the value to the field.
					    placeholder={props.placeholder}
						{...props}
					/>}
					<ErrorMessage component="span" name={field.name} className="text-danger" />
				</>
			</Form.Group>
		</div>
	);
}
export default FormikField;

