import { faChevronLeft, faChevronRight, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DatePicker } from '../../api/inni/data-contracts';
import styles from './Filter.module.scss';
import stylesV8 from './FilterV8.module.scss';


export interface FilterYearSelectorProps {
    datePicker: DatePicker;
    yearChanged: (yearCode: string|undefined) => void;
    onlyFinancialYears?: boolean;
    disabledSelector?: boolean;
    isDataLoading?:boolean;
    v8Styling?: boolean;
} 

export const FilterYearSelector = ({datePicker, yearChanged, onlyFinancialYears, disabledSelector,isDataLoading, v8Styling=false} : FilterYearSelectorProps) => {
    const [prevNextYears, setPrevNextYears] = useState<Array<string|undefined>>([undefined, undefined]);
    const [selectedYear,setSelectedYear]=useState<string|undefined>()
    const styling = v8Styling ? stylesV8 : styles;

    // When dates change, update prev / next year
    useEffect(() => {
        const year = datePicker?.currentYear;
        let newValue:Array<string|undefined> = [undefined, undefined];
        if (year) {
            const yearType = year.charAt(0);
            const years = _.find(datePicker?.availableYears, e => e.type === yearType)?.years;
            const currentIndex = years?.findIndex(y => y.key === year);

            if (years && currentIndex !== undefined) {
                if (currentIndex > 0) {
                    newValue[1] = years[currentIndex-1].key
                };
                if (currentIndex < years.length - 1) {
                    newValue[0] = years[currentIndex+1].key;
                }
            }
        }
        setPrevNextYears(newValue);        
    }, [datePicker])
    
    useEffect(()=>{
        setSelectedYear(yearName(datePicker.currentYear))  
    },[datePicker.availableYears]);

    const yearTypes:{[key: string]: string} = {
        'f': 'Financial years',
        't': 'Tax years',
        'c': 'Calendar years'
    }
    
    const yearName = (year?: string) => {
        if (year) {
            const yearType = year.charAt(0);
            const years = _.find(datePicker?.availableYears, e => e.type === yearType)?.years;
            return _.find(years, y => y.key === year)?.value;
        }
    }

    const prevClick = () => {
        if (prevNextYears[0]) yearChanged(prevNextYears[0]);
    }

    const nextClick = () => {
        if (prevNextYears[1]) yearChanged(prevNextYears[1]);
    }

    
    const setYearValue=(key?: string, value?:string)=>{
        setSelectedYear(value)
        yearChanged(key)
        
    }

    return (
        <>
            {isDataLoading ? 
                <div style={{fontSize: '0.95rem', fontWeight:200}}>
                    {selectedYear}{' '}<FontAwesomeIcon icon={faSpinnerThird} spin/>
                </div>:          
                <>
                    {!disabledSelector && 
                        <div onClick={prevClick} 
                            className={classNames(
                                styling.yearSelectorArrow, 
                                {[styling.empty]: prevNextYears[0] === undefined})}>
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </div>
                    }

                    <Dropdown data-cy="yearSelector" className={styling.yearDropDown}>
                        <Dropdown.Toggle disabled={disabledSelector} className={styling.yearDropDownButton}>
                            {yearName(datePicker.currentYear)}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdownMenuShort">
                            {datePicker.availableYears?.filter(yearType => onlyFinancialYears ? yearType.type === 'f' : true)
                            .map(yearType => (
                                <div data-cy={yearType.type} key={yearType.type}>
                                {!onlyFinancialYears && <Dropdown.Header>{yearTypes[yearType.type || 'c']}</Dropdown.Header>}
                                    {yearType.years?.map(year => (
                                        <Dropdown.Item 
                                            key={year.key} 
                                            className={styling.dropdownItem}
                                            onClick={e => setYearValue(year.key,year.value)}
                                            active={year.key === datePicker.currentYear}
                                            >
                                                {year.value}
                                        </Dropdown.Item>
                                    ))}
                                </div>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    {!disabledSelector &&  
                        <div  onClick={nextClick}
                            className={classNames(
                                styling.yearSelectorArrow, 
                                {[styling.empty]: prevNextYears[1] === undefined})}>
                            <FontAwesomeIcon icon={faChevronRight } />
                        </div>
                    }
                </>
            }
            
        </>
    )
}
