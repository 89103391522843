import React, { useMemo, useState } from 'react';
import { Card, FormCheck } from 'react-bootstrap';
import { BankTransaction, ReviewItemClientResponsePostModel } from '../../api/inni/data-contracts';
import { Button } from '../../elements/Button/Button';
import { formatDate } from '../../utils/formatDate';
import { formatCurrency } from '../../utils/formatNumbers';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { Text as EditText } from '../../elements/EditRow/EditRow';
import queriesStyles from './Queries.module.css';
import { CogOptionsDropdown } from '../../elements/CogOptionsDropdown/CogOptionsDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentPen, faCommentsQuestionCheck, faCommentsQuestion } from '@fortawesome/pro-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import { Action, Entity } from '../../utils/EntityAction';

type Props = {
	transaction: BankTransaction;
	query: string;
	category: string;
	response: string;
	newAccountId?: number;
	suggestionAccepted: boolean;
	getSuggestedCategory: (newAccountId: number) => string;
	onEdit: () => void;
	isPublished?: boolean;
	isAdmin: boolean;
	onResponse: (
		values: ReviewItemClientResponsePostModel,
		formikHelpers: FormikHelpers<ReviewItemClientResponsePostModel>
	) => void;
	onAccept: () => void;
	transactionLoading: boolean;
	bankAccountId: number;
};

const QueryCard = ({
	transaction: { date, journalTypeId, payee, description, refNo, amount, sourceID},
	query,
	category,
	response,
	newAccountId,
	suggestionAccepted,
	getSuggestedCategory,
	onEdit,
	isPublished,
	isAdmin,
	onResponse,
	onAccept,
	transactionLoading,
	bankAccountId,
}: Props) => {
	const [isDeny, setIsDeny] = useState(false);
	const navigateToEntity = useNavigateToEntity();
	const validate = (values: ReviewItemClientResponsePostModel) => {
		let errors: any = {};
		if (!values.response) errors.form = 'Required';
		return errors;
	};

	const ClientForm = useMemo(() => {
		return (
			<Formik
				initialValues={{}}
				enableReinitialize
				validateOnChange
				onSubmit={onResponse}
				isInitialValid={false}
				validate={validate}
			>
				{({ isSubmitting, isValid }) => (
					<FormikForm className="mt-3">
						{/* Body */}
						<EditText<ReviewItemClientResponsePostModel>
							labelClassName="w-100"
							name="response"
							label={isDeny ? 'Please tell us why not?' : 'Response'}
							type="textarea"
							labelTop
							autoFocus={isDeny}
						/>

						{/* Footer */}
						<div style={{ textAlign: 'left', width: '100%' }}>
							<Button variant="change" submitButton disabled={isSubmitting || !isValid}>
								Send
							</Button>
						</div>
					</FormikForm>
				)}
			</Formik>
		);
	}, [isDeny, onResponse]);

	const showClientForm = useMemo(
		() => (!isAdmin && !response && !newAccountId) || (!isAdmin && !response && isDeny),
		[isAdmin, isDeny, newAccountId, response]
	);
	const statusIcon = useMemo(() => {
		if (!isPublished) {
			return faCommentPen as IconProp;
		}
		return (response || suggestionAccepted ? faCommentsQuestionCheck : faCommentsQuestion) as IconProp;
	}, [isPublished, response, suggestionAccepted]);

	return (
		<Card className="mb-3 shadow-sm">
			<Card.Header className={`d-flex align-items-center justify-content-between flex-nowrap`}>
				{transactionLoading ? (
					<LoadingPlaceholder width="80%" height="24px" />
				) : (
					<>
						<div data-cy="tnx-meta-data" className=" d-flex flex-wrap align-items-center mr-3">
							<FontAwesomeIcon icon={statusIcon} className={queriesStyles.noteIcon} />
							{date && <div className="mr-3">Date: {formatDate(date)}</div>}
							<div className="mr-3">
								Journal type: {journalTypeId === 'BP' ? 'Bank payment' : 'Bank transfer'}
							</div>
							{payee && <div className="mr-3">Paid to: {payee}</div>}
							{description && <div className="mr-3">Description: {description}</div>}
							{category && <div className="mr-3">Category: {category}</div>}
							{refNo && <div className="mr-3">Ref: {refNo}</div>}
							{amount && <div className="mr-3">Amount: {formatCurrency(amount)}</div>}
						</div>
						<div className="d-flex align-items-center">
							<Button
								className={`m-0 ${isPublished ? '' : 'mr-3'}`}
								variant="primary"
								entity={Entity.BookkeepingV7}
								action={Action.List}
								extraParams={{ aid: bankAccountId, sourceId: +(sourceID || 0) }}
								tableBtnMd
								isNavigateInNewTab
								skipPermissionCheck
							>
								View in bookkeeping
							</Button>
							{!isPublished && <CogOptionsDropdown edit={onEdit} />}
						</div>
					</>
				)}
			</Card.Header>

			<Card.Body>
				{query ? (
					<>
						<Card.Subtitle style={{ fontWeight: 600 }}>Query:</Card.Subtitle>
						<Card.Text style={{ whiteSpace: 'pre-wrap' }}>{query || ''}</Card.Text>
					</>
				) : (
					<h5 className={queriesStyles.empty}>No query</h5>
				)}
				{newAccountId && (
					<div className="mt-2">
						<Card.Subtitle style={{ fontWeight: 600 }}>Suggested account:</Card.Subtitle>
						<Card.Text style={{ whiteSpace: 'pre-wrap' }}>{getSuggestedCategory(newAccountId)}</Card.Text>
					</div>
				)}
				{response && (
					<div className="mt-3">
						<Card.Subtitle style={{ fontWeight: 600 }}>Response:</Card.Subtitle>
						<Card.Text style={{ whiteSpace: 'pre-wrap' }}>{response || ''}</Card.Text>
					</div>
				)}
				{!isAdmin && newAccountId && !response && !suggestionAccepted && (
					<div className="mt-3">
						<Card.Subtitle style={{ fontWeight: 600 }}>Are you happy with this suggestion?</Card.Subtitle>
						<div className="mt-2">
							<Button
								variant="change"
								onClick={() => {
									onAccept();
									setIsDeny(false);
								}}
							>
								Yes
							</Button>
							<Button variant="secondary" onClick={() => setIsDeny(true)}>
								No
							</Button>
						</div>
					</div>
				)}
				{showClientForm && ClientForm}
			</Card.Body>
			{newAccountId && suggestionAccepted && (
				<Card.Footer>
					<div className="d-flex align-items-center">
						{newAccountId && suggestionAccepted && (
							<div className="d-flex mr-3">
								<FormCheck checked={suggestionAccepted} onChange={() => {}} />{' '}
								<span className="ml-2">Suggestion accepted</span>
							</div>
						)}
					</div>
				</Card.Footer>
			)}
		</Card>
	);
};

export default QueryCard;
