import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { add, isAfter } from 'date-fns';
import { FormikErrors, FormikHelpers } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Accounts } from '../../../api/inni/Accounts';
import { BankTransactions } from '../../../api/inni/BankTransactions';
import { Account, NewPaymentPostModel, Payment, Reminder } from '../../../api/inni/data-contracts';
import { Reminders as RemindersAPI } from '../../../api/inni/Reminders';
import { Modal } from '../../../components/Modal/Modal';
import CompanyContext, { CompanyProduct } from '../../../context/CompanyContext';
import { Button } from '../../../elements/Button/Button';
import { LoadingRow } from '../../../elements/DataGrid/DataGrid';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { asYearMonthDay, formatDate } from '../../../utils/formatDate';
import { formatCurrency } from '../../../utils/formatNumbers';
import styles from '../Dashboard.module.scss';
import stylesV75 from '../Dashboard_V75.module.scss';
import RecordPaymentModal from './RecordPaymentModal';
import { useCalanderPostAction } from './ReminderActionsHelper';
import StatusIcon from './StatusIcon';
import DOMPurify from 'dompurify';
import { Properties } from '../../../api/inni/Properties';
import { FileUploadModal } from '../../../components/FileUploadModal/FileUploadModal';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { MortgageStatementModal } from '../../Bookkeeping2022/MortgageStatementModal';
import { LettingStatements } from '../../../api/inni/LettingStatements';
import BlankStateCard from '../../../components/BlankStateCard/BlankStateCard';
import { SbBlokData, getStoryblokApi } from '@storyblok/react';
import _ from "lodash";

const defaultBP : NewPaymentPostModel = {
    relatedId: 0,
    date: asYearMonthDay(new Date()), // should be due date initially
    fullyPaid: false,
    amountLocal: 0,
    cbFullypaidVisible: false,
    amountPaidInSourceCurrencyReadOnly: false,
    amountPaidInSourceCurrencyEnabled: false,
    hideVAT: false,
    minDate: "",
    maxDate: "",
    amount: 0,
    vatRate: "",
    showCategory: false,
    showBillto: false
} 

const ReminderLoading = () => {
    return (
        <Card style={{padding: '10px'}} className={classNames(styles.reminderCard)}>
            <Card.Title><LoadingPlaceholder /></Card.Title>
            <Card.Body><LoadingPlaceholder /><LoadingPlaceholder /><LoadingPlaceholder /><LoadingPlaceholder /></Card.Body>
        </Card>
    )
}

const RemindersLoading = () => {
    return (<>
        <ReminderLoading />
        <ReminderLoading />
        <ReminderLoading />
    </>)
}

const sortOrder = ["Overdue", "DueSoonUrgent", "DueSoon", "Upcoming"]

const Reminders = ({view, setView} : {view : string, setView : (view : string) => void}) => {

    const post = useCalanderPostAction()
    const companyContext = useContext(CompanyContext);
    const ReminderDataAPI = useInniAPI(RemindersAPI);
    const BankTransactionAPI = useInniAPI(BankTransactions)
    const propertyAPI = useInniAPI(Properties)
    const lettingStatementsAPI = useInniAPI(LettingStatements)

    const [uploadingDocument, setUploadingDocument] = useState(false);
    const [uploadType, setUploadType] = useState<string|undefined|null>()
    const [relatedId, setRelatedId] = useState<number|undefined|null>()

    const [isLoaded, setisLoaded] = useState(false);
    const [reminderData, setReminderData] = useState<Reminder[] | undefined>(undefined);

    // Load accounts for edit component
    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts)

    const [curPayment, setCurPayment] = useState<Payment | undefined>(undefined)

    const [createModalShowing, setCreateModalShowing] = useState(false);

    const [initialValues, setInitialValues] = useState<NewPaymentPostModel>(defaultBP)

    const [type, setType] = useState<'incoming' | 'outgoing'>('incoming')
    const [successShowing, setSuccessShowing] = useState(false);

    const v8Styling = companyContext.company?.useV8UI || false;
    const styling = v8Styling ? stylesV75 : styles;

    const [blankSlateInfo, setBlankSlateInfo]= useState<SbBlokData>();

    useEffect(() => {
        if (ReminderDataAPI && !isLoaded) {
            ReminderDataAPI.index(companyContext.cid)
            .then((res) => {
                // Sort by urgency then if overdue put at top
                let sorted = res.data.sort((a,b) => sortOrder.findIndex(x => x === a.status) > sortOrder.findIndex(x => x === b.status) || !a.isOverdue ? 1 : -1)
                setReminderData(sorted);
                setisLoaded(true);
            })
        }
    }, [ReminderDataAPI, companyContext.cid, isLoaded])

    useEffect(() => {
        generateInitialValues()
    }, [curPayment])

    useEffect(()=>{
        const storyblokApi = getStoryblokApi();
        storyblokApi.get('cdn/stories/blank-slate/dashboard', { version: 'published'})
        .then(res => {
            setBlankSlateInfo(res.data.story.content)
        }).catch(err => {
            console.error(err)
        });
    },[])

    const generateInitialValues = (selectedBankAccountId?: number) => {        
        let initialBP : NewPaymentPostModel = defaultBP     

        if (!curPayment || !accountsLoaded || !accounts) {
            setInitialValues(initialBP);
            return;
        }

        //order by - this is so when setting the initialvalues for the formik form in recordPaymentModal it defaults to the lowest
        //order id account due to when accounts are given high orderid numbers when they are no longer used
        //we don't hide them because they could still be needed for accounting purposes
        
        const firstAct = _.sortBy(accounts, 'orderId').find(x => x.bankAccount);
        if (!firstAct) {
            setInitialValues(initialBP);
            return;
        }

        BankTransactionAPI?.newForReminder(companyContext.cid, {
            procType: curPayment.procType || '',
            bankAccountid: selectedBankAccountId ? selectedBankAccountId : firstAct.id,
            relatedId: curPayment.relatedId
        }).then(res => {
            if (res.data) {
                initialBP = res.data // copy any relevant data over
            }
            initialBP.description = curPayment.description
            initialBP.payee = curPayment.payee
            if (initialBP.vatRate == null)
                initialBP.vatRate = ""
            setInitialValues(initialBP);
        })
    }

    const uploadCompletionStatement = (acceptedFiles:File[]) => {
        if(propertyAPI && uploadType && relatedId) {
            if(uploadType === "PurchaseCompletionStatementUpload") {
                setUploadingDocument(true)
                propertyAPI.uploadCompletionStatement(companyContext.cid, relatedId, 
                    {fileToUpload: acceptedFiles[0]}).then((res) => {
                    if(res.status !== 201) {
                        console.warn("Unexpected response")
                        console.warn(res)
                    }
                    setUploadType(null)
                    setReminderData([]); 
                    setisLoaded(false);
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    setUploadingDocument(false)
                })
            }
            else if(uploadType === "SaleCompletionStatementUpload") {
                setUploadingDocument(true)
                propertyAPI.uploadSaleCompletionStatement(companyContext.cid, relatedId, 
                    {fileToUpload: acceptedFiles[0]}).then((res) => {
                    if(res.status !== 201) {
                        console.warn("Unexpected response")
                        console.warn(res)
                    }
                    setUploadType(null)
                    setReminderData([]); 
                    setisLoaded(false);
                })
                .catch((error) => {
                    console.error(error)
                })
                .finally(() => {
                    setUploadingDocument(false)
                })
            }
        }
    }

    const uploadLettingStatementDocument = (acceptedFiles:File[]) => {
        if(lettingStatementsAPI && acceptedFiles.length > 0 && uploadType && uploadType === "LettingAgentStatement" && relatedId) {
            setUploadingDocument(true)
            lettingStatementsAPI.uploadLettingStatement(companyContext.cid, relatedId, 
                {fileToUpload: acceptedFiles[0]}).then((res) => {
                if(res.status !== 201) {
                    console.warn("Unexpected response")
                    console.warn(res)
                }
                if(res.ok) {
                    setUploadType(null)
                    setReminderData([]); 
                    setisLoaded(false);
                }
            }).finally(() => {
                setUploadingDocument(false)
            })
        }
    }

    const validatePayment = (values: NewPaymentPostModel) : Promise<FormikErrors<NewPaymentPostModel>> => {
        return new Promise((resolve, reject) => {
            if (BankTransactionAPI) {
                return BankTransactionAPI.validateCreateNewPayment(companyContext.cid,values)
                .then(() => resolve({}))    
                .catch(error => resolve(error.error))            
            } else {
                reject();
            } 
        })
    }

    const paymentCreate = (values: NewPaymentPostModel, actions: FormikHelpers<NewPaymentPostModel>) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (BankTransactionAPI) {
                BankTransactionAPI.savePayment(companyContext.cid, values)
                .then(data => {
                    setCreateModalShowing(false)

                    // we reload here - some payments are recurring so some new ones may appear...
                    setisLoaded(false)
                    setSuccessShowing(true);
                    setTimeout(() => { setSuccessShowing(false) }, 2000);
                    resolve();
                    
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } else {
                reject();
            }
        })
    }

    const removeMortgageReminder = () => {
        if(reminderData) {
            setReminderData([
                ...reminderData.filter(x => 
                    !x.actions || 
                    !(x.actions[0] && x.actions[0].actionUrl === `UPLOAD-${relatedId}-MortgageReview`)
                )
            ])
        }
    }

    return (
        <>
        {/* Only want to show loading fully first time - keep content and update from there, looks better */}
        { !isLoaded && !reminderData &&
            <RemindersLoading />
        }
        { isLoaded && (!reminderData || reminderData?.length === 0) && (
            v8Styling
                ? <BlankStateCard content={blankSlateInfo} loading={!blankSlateInfo}/>
                : <NoContentSlate type={DatagridType.Reminders} termsKey="emptyTerms" whiteBg={v8Styling} />
        )}
        { reminderData &&
            // if it isn't admin or they have admin permission
            // sort by status
            reminderData.map((x,i) =>{
                return (
                    <Card
                        key={i}
                        className={
                            classNames(x.isOverdue ? styling.overdue : styling[x.status.toLowerCase()],
                                styling.reminderCard,
                                {[styling.cardAdmin]: x.adminOnly}
                            )
                        }
                    >
                        <Card.Title />
                        <Card.Body className={styling.dashboardCardBody}>
                            {/* header */}
                            <div style={{display: 'flex', justifyContent:'space-between', padding: '0rem 1rem'}}>
                                { <h5>{x.title}</h5> }
                                { <span>{formatDate(x.dueDate)}</span> }
                            </div>

                            <hr style={{margin: '5px'}} />

                            {/* description (from backend) */}
                            { x.shortDescription && (
                                <div
                                    style={{padding: '0.5rem 1rem 1rem 1rem'}}
                                    className={v8Styling ? '' : 'text-muted'}
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(x.shortDescription || '')}}
                                />
                            )}

                            { isLoaded && x.incomingPayments && x.incomingPayments?.length > 0 &&
                                <div style={{marginTop: '10px'}}>
                                    <div className={styling.dashboardTablePadding}>Payments to receive:</div>
                                    <table style={{width: '100%'}}>
                                        <thead>
                                            <tr style={{borderBottom: '0.5px solid #eee'}}>
                                                <th style={{width: '10px'}}></th>
                                                <th></th>
                                                <th style={{width: '75px'}}></th>
                                                <th style={{width: '100px'}}></th>
                                                <th style={{width: '155px'}}></th>
                                            </tr>
                                        </thead>
                                        <tbody data-cy="incomingPayments">
                                            { x.incomingPayments.map((y,g) => {
                                                return (
                                                    <tr
                                                        key={g + "outgoing"}
                                                        className={classNames(styling.reminderTableRow, styling.dashboardTableRow)}
                                                        style={{color: !isAfter(add(new Date(), { days: -1}), new Date(y.dueDate)) ? '#888' : ''}}
                                                    >
                                                        <td><StatusIcon status={y.status} dueDate={new Date(y.dueDate)} /></td>
                                                        <td style={{maxWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                                            <p>{y.description}</p>
                                                        </td>
                                                        <td style={{color: isAfter(add(new Date(), { days: -1}), new Date(y.dueDate)) ? '#d00' : '#888'}}>{formatDate(y.dueDate)}</td>
                                                        <td style={{textAlign: 'right'}}>{formatCurrency(y.amount)}</td>
                                                        <td style={{textAlign: 'right'}}>
                                                            <Button 
                                                                admin={x.adminOnly} 
                                                                onClick={() => {setCurPayment(y); setCreateModalShowing(true); setType('incoming')}} 
                                                                tableBtn 
                                                                variant={isAfter(add(new Date(), { days: -1}), new Date(y.dueDate)) ? "recordPayment" : "paymentInactive"}
                                                            >
                                                                Record payment
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {/* If we are reloading the data, we want it to show as loading */}
                            {!isLoaded && x.incomingPayments?.length !== 0 &&
                                <div style={{marginTop: '10px'}}>
                                    <div className={styling.dashboardTablePadding}>Payments to receive:</div>
                                    <table style={{width: '100%'}}>
                                        <thead>
                                            <tr style={{borderBottom: '0.5px solid #eee'}}>
                                                <th style={{width: '2%'}}></th>
                                                <th style={{ width: '100%' }}></th>
                                                <th style={{width: '100px'}}></th>
                                                <th style={{width: '100px'}}></th>
                                                <th style={{minWidth: '175px'}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { x.incomingPayments?.map((y,g) => {
                                                return (
                                                    <LoadingRow key={g} cols={5} />
                                                )
                                            })

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            { isLoaded && x.outgoingPayments && x.outgoingPayments?.length > 0 &&
                                <div style={{marginTop: '10px'}}>
                                    <div className={styling.dashboardTablePadding}>Payments to make:</div>
                                    <table style={{width: '100%'}}>
                                        <thead>
                                            <tr style={{borderBottom: '0.5px solid #eee'}}>
                                                <th style={{width: '10px'}}></th>
                                                <th></th>
                                                <th style={{width: '75px'}}></th>
                                                <th style={{width: '100px'}}></th>
                                                <th style={{width: '155px'}}></th>
                                            </tr>
                                        </thead>
                                        {/* we show the same number of rows as before the loading avoids jumping content... */}
                                        <tbody>
                                            { x.outgoingPayments.map((y,g) => {
                                                return (
                                                    <tr className={classNames(styling.reminderTableRow, styling.dashboardTableRow)} key={g + "outgoing"} style={{color: !isAfter(add(new Date(), { days: -1}), new Date(y.dueDate)) ? '#888' : ''}}>
                                                        <td><StatusIcon status={y.status} dueDate={new Date(y.dueDate)} /></td>
                                                        <td style={{maxWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                                            {y.description}
                                                        </td>
                                                        <td style={{color: isAfter(add(new Date(), { days: -1}), new Date(y.dueDate)) ? '#d00' : '#888'}}>{formatDate(y.dueDate)}</td>
                                                        <td style={{textAlign: 'right'}}>{formatCurrency(y.amount)}</td>
                                                        <td style={{textAlign: 'right'}}><Button 
                                                            admin={x.adminOnly} 
                                                            onClick={() => {setCurPayment(y); setCreateModalShowing(true); setType('outgoing')}} 
                                                            tableBtn 
                                                            variant={isAfter(add(new Date(), { days: -1}), new Date(y.dueDate)) ? "recordPayment" : "paymentInactive"}>
                                                                Record payment
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            {/* If we are reloading the data, we want it to show as loading */}
                            {!isLoaded && x.outgoingPayments?.length !== 0 &&
                                <div style={{marginTop: '10px'}}>
                                    <div className={styling.dashboardTablePadding}>Payments to make:</div>
                                    <table style={{width: '100%'}}>
                                        <thead>
                                            <tr style={{borderBottom: '0.5px solid #eee'}}>
                                                <th style={{width: '2%'}}></th>
                                                <th style={{ width: '100%' }}></th>
                                                <th style={{width: '100px'}}></th>
                                                <th style={{width: '100px'}}></th>
                                                <th style={{minWidth: '175px'}}></th>
                                            </tr>
                                        </thead>
                                        {/* we show the same number of rows as before the loading avoids jumping content... */}
                                        <tbody className={styling.reminderLoading}>
                                            { x.outgoingPayments?.map((y,g) => {
                                                return (
                                                    <LoadingRow key={g} cols={5} />
                                                )
                                            })

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                            { x.actions && x.actions.length > 0 &&
                                <>
                                <table style={{width: '100%'}}>
                                    <thead>
                                        <tr>
                                            <th style={{width: '10px'}}></th>
                                            <th></th>
                                            <th style={{width: '165px'}}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { x.actions.map((y,i) => {
                                            return (
                                                <tr className={classNames(styling.reminderTableRow, styling.dashboardTableRow)} key={i + (x.title||'')}>
                                                    <td><StatusIcon status={x.status} /></td>
                                                    <td>
                                                        {y.description}
                                                    </td>
                                                    <td style={{textAlign: 'right'}}>
                                                        {y.actionCode === "HREF" &&
                                                        <Button 
                                                            admin={x.adminOnly} 
                                                            tableBtn 
                                                            // variant={x.status === 'Overdue' || x.status === 'DueSoon' ? "recordPayment" : "paymentInactive"}>
                                                            variant="recordPayment">
                                                                <a
                                                                    style={{color: x.status === 'Overdue' || x.status === 'DueSoon' ? 'white' : 'inherit'}} 
                                                                    href={y.actionUrl?.includes('https://www') 
                                                                        ? y.actionUrl
                                                                        : (
                                                                            `${window.location.origin}${(
                                                                                ((y.actionUrl?.includes('aspx') || y.actionUrl?.includes('v7'))
                                                                                    ? '/'
                                                                                    : `/Company/${companyContext.cid}/`)
                                                                                + y.actionUrl?.replace(/~/g, '/').replace(/\/\//g, '/')
                                                                            ).replace(/\/\//g, '/')}`
                                                                        )
                                                                        
                                                                    }
                                                                    rel="noreferrer"
                                                                    target={y.actionUrl?.includes('https://www') ? '_new' : undefined}
                                                                >
                                                                    {y.label}</a>
                                                        </Button>}

                                                        {y.actionCode === "POST" &&
                                                        <Button 
                                                            admin={x.adminOnly} 
                                                            tableBtn 
                                                            // variant={x.status === 'Overdue' || x.status === 'DueSoon' ? "recordPayment" : "paymentInactive"}
                                                            variant="recordPayment"
                                                            onClick={async () => {let res = await post(y.actionUrl || ''); if (res) {setReminderData([]); setisLoaded(false);} else console.error("Error occured when completing a post action")}}
                                                        >
                                                                {y.label}
                                                        </Button>}

                                                        {y.actionCode === "UPLOAD" && y.actionUrl && 
                                                        <Button
                                                            admin={x.adminOnly}
                                                            tableBtn
                                                            variant='recordPayment'
                                                            onClick={() => {
                                                                setUploadType(y.actionUrl ? y.actionUrl.split("-")[2] : null); 
                                                                setRelatedId(y.actionUrl ? parseInt(y.actionUrl.split("-")[1]) : null)
                                                            }}
                                                        >
                                                            {y.label}
                                                        </Button>}
                                                    </td>
                                                </tr>
                                            )
                                        })

                                        }
                                    </tbody>
                                </table>
                                </>
                            }
                        </Card.Body>
                    </Card>
                )
            })
        }
        {uploadType && (uploadType === "PurchaseCompletionStatementUpload" || uploadType === "SaleCompletionStatementUpload") &&
            <FileUploadModal
                onCancelClick={() => setUploadType(null)}
                onFilesDropped={(acceptedFiles) => {uploadCompletionStatement(acceptedFiles)}}
                maxFiles={1}
                uploading={uploadingDocument}
                maxSizeMB={10}
                accept={'documents'}
            />
        }

        {uploadType && uploadType === "LettingAgentStatement" &&
            <FileUploadModal
                onCancelClick={() => {setUploadType(null); setRelatedId(null)}}
                onFilesDropped={(acceptedFiles) => {uploadLettingStatementDocument(acceptedFiles)}}
                maxFiles={1}
                uploading={uploadingDocument}
                maxSizeMB={10}
                accept={'documents'}
        />}

        {relatedId && uploadType && uploadType === "MortgageReview" && <MortgageStatementModal
            showModal={uploadType !== null && uploadType !== undefined && uploadType === "MortgageReview"}
            mortgageAccountId={relatedId}
            onClose={() => {setUploadType(null); setRelatedId(null)}}
            postUpload={removeMortgageReminder}
        />}

        <Modal showModal={createModalShowing} hideModal={() => setCreateModalShowing(false)} title="Record payment"
        titleBg="var(--sidebar-background-color)"
        titleColor="var(--sidebar-color)"
        children={
            <RecordPaymentModal 
                initialValues={initialValues}
                onCancelClick={() => setCreateModalShowing(false)}
                accounts={accounts}
                showPersonal={type === 'outgoing'}
                formSubmit={paymentCreate} 
                formValidate={validatePayment}
                getUpdatedIntialValues ={generateInitialValues} 
            />}
        />
        {successShowing &&
        <div style={{position: 'fixed', bottom: '0', width: '80%', left: '10%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}} className="alert alert-success" role="alert">
            <>Successfully saved payment!</>
            <FontAwesomeIcon style={{cursor: 'pointer'}} onClick={() => setSuccessShowing(false)} icon={faTimes} />
        </div>}
        </>
    )

}

export default Reminders