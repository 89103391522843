import { useEffect, useRef } from "react";
import { Account, BankTransaction } from "../../../api/inni/data-contracts";
import { CogOptionsDropdown } from "../../../elements/CogOptionsDropdown/CogOptionsDropdown";
import FileIcon from "../../../elements/FileIcon/FileIcon";
import { TableIcon } from "../../../elements/TableIcon/TableIcon";
import getCurrencySymbol from "../../../utils/currencySymbols";
import { formatDate } from "../../../utils/formatDate";
import { formatCurrency } from "../../../utils/formatNumbers";
import { AccountViewerFeatureSet } from "../AccountViewerNew";

interface QuickViewProps {
    txn : BankTransaction,
    currentAccount : Account | undefined,
    searching : boolean,
    setupModalData : (i : BankTransaction) => void,
    setUploadingModal : (i : BankTransaction) => void,
    setReceiptModal : (i : BankTransaction) => void,
    accounts : Account[],
    featureSet: AccountViewerFeatureSet,
    deleteReceipt: (documentId : number, paymentId : number) => void
    initialModalSourceId?: number
}

const QuickView = ({
    txn, 
    currentAccount, 
    searching, 
    setupModalData, 
    setUploadingModal, 
    setReceiptModal, 
    accounts, 
    featureSet, 
    deleteReceipt,
    initialModalSourceId,
} : QuickViewProps) => {

    const accountName = (id?: number) => {
        if (id) {
            const account = accounts.find(a => a.id === id)
            return account ? account.name : undefined
        }
        return undefined;
    }

    const tdRef = useRef<HTMLTableRowElement>(null)

    // const accountBadge = (id?: number) => {
    //     const name = accountName(id);
    //     if (name) {
    //         const meta = formatAccount(name);
    //         return <Badge pill style={{backgroundColor: '#fff', fontWeight: 'normal', color: meta.color, border: `1px solid ${meta.color}`, marginLeft: '0.5em'}}><FontAwesomeIcon icon={meta.icon} /> {name}</Badge>
    //     }
    // }

    useEffect(() => {
        initialModalSourceId && txn.sourceID === initialModalSourceId && 
           setTimeout(() => tdRef.current?.click())
        
    }, [initialModalSourceId, txn.sourceID])

    return (
        <tr 
            ref={tdRef}
            style={{
                    backgroundColor: !txn.confirmed && featureSet !== AccountViewerFeatureSet.QUICKENTRY ? 'var(--unmatched-row-color)' : '', 
                    cursor: (featureSet === AccountViewerFeatureSet.QUICKENTRY || featureSet === AccountViewerFeatureSet.BOOKKEEPING) ? 'pointer' : ''
                }} 
            onClick={ (featureSet === AccountViewerFeatureSet.QUICKENTRY || featureSet === AccountViewerFeatureSet.BOOKKEEPING) 
                        ? () => setupModalData(txn)
                        : undefined
                    }
            data-cy="quickView"
        >
            {featureSet === AccountViewerFeatureSet.RECEIPTS && (
                txn.hasImage ? <td onClick={(e) => {e.stopPropagation(); setReceiptModal(txn)}}>{txn.thumbnailUrl && 
                    (txn.thumbnailUrl.indexOf('.pdf') !== -1 ? <FileIcon iconType="pdf" /> : <img style={{height: '100px', width: '100px'}} src={txn.thumbnailUrl} alt="Receipt" />)
                }</td> :
                <td></td>)}
            <td>{!txn.confirmed && featureSet !== AccountViewerFeatureSet.QUICKENTRY ? "" : formatDate(txn.date)}</td>
            <td>
                {txn.payee}
                {featureSet === AccountViewerFeatureSet.NONE && txn.refNo && <>
                    <br /><span className="text-muted small">Ref: {txn.refNo}</span>
                </>}
            </td>
            <td>{txn.description}<br />{featureSet === AccountViewerFeatureSet.NONE && <span className="text-muted">{accountName(txn.bankAccountId)}</span>}{/*accountBadge(txn.accountId)*/}</td>
            {/* Paid in for none/bookkeeping, Repaid for Quickentry */}
            {(featureSet === AccountViewerFeatureSet.NONE || featureSet === AccountViewerFeatureSet.BOOKKEEPING || featureSet === AccountViewerFeatureSet.PROPERTY_TRANSACTIONS || (!currentAccount?.payExpensesViaPayroll && featureSet === AccountViewerFeatureSet.QUICKENTRY)) && 
                <td>{txn.paidIn && <>{getCurrencySymbol(currentAccount?.currency||"")}{formatCurrency(txn.amount, true)}</>}</td>}
            {(featureSet === AccountViewerFeatureSet.NONE || featureSet === AccountViewerFeatureSet.BOOKKEEPING || featureSet === AccountViewerFeatureSet.QUICKENTRY || featureSet === AccountViewerFeatureSet.PROPERTY_TRANSACTIONS) &&
                <td>{txn.paidOut && <>{getCurrencySymbol(currentAccount?.currency||"")}{formatCurrency(txn.amount, true)}</>}</td>}
            {(featureSet === AccountViewerFeatureSet.RECEIPTS) && <td>{formatCurrency(txn.amount)}</td>}
            {(featureSet === AccountViewerFeatureSet.BOOKKEEPING || (!currentAccount?.payExpensesViaPayroll && featureSet === AccountViewerFeatureSet.QUICKENTRY)) && 
                <td>{txn.balanceAfter !== undefined && txn.balanceAfter !== null && !searching && <>{getCurrencySymbol(currentAccount?.currency||"")}{formatCurrency(txn.balanceAfter, true)}</>}</td>}
            {(featureSet === AccountViewerFeatureSet.BOOKKEEPING) && <td>{txn.confirmed ? <TableIcon icon="check" /> : <TableIcon icon="warning" />}</td>}
            {featureSet !== AccountViewerFeatureSet.RECEIPTS ? <td>{
                txn.hasImage ? <TableIcon onClick={(e) => {e.stopPropagation(); setReceiptModal(txn)}} icon="image" /> : 
                txn.hasLink ? <a href={txn.getLink}><TableIcon onClick={(e) => e.stopPropagation()} icon="externallink" /></a> :
                txn.journalTypeId !== "BT" && txn.journalTypeId !== "MJ" && txn.journalTypeId !== "SY" && txn.journalTypeId !== "FX" && <TableIcon onClick={(e) => {e.stopPropagation(); setUploadingModal(txn)}} icon="upload" />}
            </td> : <td>
                <CogOptionsDropdown del={() => deleteReceipt(txn.documentId || 0, txn.sourceID || 0)} />
            </td>}
        </tr>
    )

}

export default QuickView