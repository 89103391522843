import { useEffect, useState, useContext } from 'react';
import { Alert, Card, Form } from 'react-bootstrap';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faPlug } from '@fortawesome/pro-regular-svg-icons';
// Hooks
import { useInniAPI } from '../../../hooks/useInniAPI';
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList';
import { useModalDialog } from '../../../hooks/useModalDialog';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { useHistoryWrapper } from '../../../hooks/useHistoryWrapper';
// Components
import { LoadingShim } from '../../../elements/LoadingShim/LoadingShim';
import ArthurConnect from './Components/ArthurConnect';
import { Button } from '../../../elements/Button/Button';
import InfoBanner from '../../../elements/InfoBanner/InfoBanner';
import LinkPropertyInfo from './Components/LinkPropertyInfo';
import stylesLoader from '../../../elements/LoadingFullPage/LoadingFullPage.module.css'
// Feature
import { ExternalProperties } from '../../../api/inni/ExternalProperties';
import { ExternalPropertyServices } from '../../../api/inni/ExternalPropertyServices';
import { Properties } from '../../../api/inni/Properties';
import { PropertyImportEntry, LinkedProperty } from './PropertyImportEntry';
import { Property, ExternalProperty } from '../../../api/inni/data-contracts';
// General
import { DefaultLayout } from '../../../layouts/Desktop/DefaultLayout';
import { Action, Entity } from '../../../utils/EntityAction';
import CompanyContext from '../../../context/CompanyContext';

import styles from './PropertyImport.module.css';


type SelectableExternalProperty = {
  data: ExternalProperty,
  selected: boolean,
  linked: LinkedProperty | null
};

export const PropertyImport = () => {
  const companyContext = useContext(CompanyContext);
  const [properties, propertiesLoaded] = useFetchEntityList<Property, Properties>(Properties);
  const propertiesApi = useInniAPI(Properties);
  const extPropertiesApi = useInniAPI(ExternalProperties);
  const extPropertyServicesApi = useInniAPI(ExternalPropertyServices);
  const [showModalDialog, modalDialog] = useModalDialog();
  const navigateToEntity = useNavigateToEntity();
  const history = useHistoryWrapper();

  // Lists
  const [arthurProperties, setArthurProperties] = useState<SelectableExternalProperty[]>([]);
  // TODO: TS type for none fixed objects with a recurring pattern? - DD
  const [linkableProperties, setLinkableProperties] = useState<any>();

  // State management
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [connected, setConnected] = useState(false);
  const [error, setError] = useState(false);
  const [reAuth, setReAuth] = useState(false);
  const [showLinkMessage, setShowLinkMessage] = useState<string | null>(null);

  const [hasEntityId, setHasEntityId] = useState<boolean|undefined|null>();
  const [newEntityId, setNewEntityId] = useState<string>("")

  // Init linkable properties state management
  useEffect(() => {
    if (propertiesLoaded && properties) {
      const linkables:any = {};

      for (let i = 0; i < properties.length; i += 1) {
        const property = properties[i];
        if (property.externalPropertyId == null)
          linkables[property.id] = {linked: null, ...(_.pick(property, ['id', 'name', 'address', 'postcode']))}; 
      }

      setLinkableProperties(linkables);
    }
  }, [propertiesLoaded, properties]);

  // Attempt to fetch external properties
  useEffect(() => {
    if (propertiesLoaded && extPropertiesApi && extPropertyServicesApi && hasEntityId) {
      setLoading(true);

      // Check if any external services connected
      extPropertyServicesApi
        .index(companyContext.cid)
        .then((response) => {
          // For now we only connect to Arthur
          if (response.data.length > 0 && response.data[0].isAuthorised == true) {
            setConnected(true);

            // Connected, get properties
            extPropertiesApi
              .index(companyContext.cid)
              .then((response) => {
                // Filter out existing imports, add 'selected' and 'linked' fields
                let buildProperties = [];
                for (let i = 0; i < response.data.length; i += 1) {
                  const entry = response.data[i];
                  if (!_.some(properties, (property) => property.externalPropertyId === entry.id)) {
                    buildProperties.push({
                      data: entry,
                      selected: false,
                      linked: null
                    });
                  }
                }
                setLoading(false);
                setArthurProperties(buildProperties);
              })
              .catch((error) => {
                console.error('Properties error:', error);
                setError(true);
                setLoading(false);
                if (error.status === 403 && error.error === 'Unable to access arthur')
                  setReAuth(true);
              });
          } else {
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error('Services error:', error);
          setError(true);
          setLoading(false);
          if (error.status === 403 && error.error === 'Unable to access arthur')
            setReAuth(true);
        });
    }
  }, [extPropertiesApi, extPropertyServicesApi, companyContext.cid, propertiesLoaded, properties, hasEntityId]);

  useEffect(() => {
    if(extPropertyServicesApi && (hasEntityId === undefined || hasEntityId === null)) {
      setLoading(true)
      extPropertyServicesApi.hasEntityId(companyContext.cid).then(res => {
        setHasEntityId(res.data)
        if(propertiesLoaded || !res.data) {
          setLoading(false)
        }
      })
    }
  }, [companyContext.cid, extPropertyServicesApi, hasEntityId, propertiesLoaded])

  // Select item
  const selectItem = (id: string) => {
    if (submitting)
      return;

    const newProperties = _.cloneDeep(arthurProperties);
    
    let link = null;
    for (let i = 0; i < newProperties.length; i += 1) {
      const property = newProperties[i];
      if (property.data.id === id) {
        property.selected = !property.selected;
        link = property.linked;
        property.linked = null;
        break;
      }
    }
    
    // Remove linked property
    if (link) {
      const newLinkableProperties = _.cloneDeep(linkableProperties);
      newLinkableProperties[link.value].linked = null;
      setLinkableProperties(newLinkableProperties);
    }

    setArthurProperties(newProperties);

    // Remove link property message attachment
    if (showLinkMessage === id) setShowLinkMessage(null);
  };

  // Select all
  const selectAll = () => {
    const newProperties = _.cloneDeep(arthurProperties);
    for (let i = 0; i < newProperties.length; i += 1) {
      newProperties[i].selected = true;
    }
    
    setArthurProperties(newProperties);

    // Show link property info message if linkable properties exist
    if (Object.keys(linkableProperties).length > 0)
      setShowLinkMessage('*SELECTALL*')
  };

  const showUnlinkArthurAccount = () => {
    showModalDialog(
      'Unlink Account?',
      'Are you sure you want to unlink your Arthur account? Previously imported properties will not be affected.',
      [
        <Button variant="danger" label="Yes" onClick={unlinkArthurAccount} />,
        <Button variant="primary" label="No" onClick={() => {}} />,
      ],
      false
    );
  };

  const unlinkArthurAccount = () => {
    extPropertyServicesApi
      ?.delete(companyContext.cid, 'Arthur')
      .then((response) => {
        if (response.status === 200) {
          // Return back to properties
          navigateToEntity(Entity.Property);
        } else {
          console.error(`Unlink response: ${response.status}`);
          setError(true);
        }
      })
      .catch((error) => {
        console.error('Unlink error:', error);
        setError(true);
      });
  }

  const checkUnlinkedProperties = () => {
    // Generate list of unlinked propeties
    const unlinkedProperties = _.filter(Object.values(linkableProperties), { linked: null })
    
    // Check import list for missed linkable properties 
    let unlinkedProperty = false;
    for (let i = 0; i < arthurProperties.length; i += 1) {
      const entry = arthurProperties[i];
      if (entry.selected && entry.linked == null) {
        // Check if postcode exists in unlinked properties
        if (_.some(unlinkedProperties, {postcode: entry.data.postcode})) {
          unlinkedProperty = true;
          break;
        }
      }
    }

    if (unlinkedProperty) {
      showModalDialog(
        'Unlinked Properties',
        `There are unlinked Provestor properties matching the post code of those being imported.
          Linking matching properties will ensure you don't have duplicates.
          If you are sure there are no remaining matching properties you can continue the import.`,
        [
          <Button variant="danger" label="Continue Import" onClick={importProperties} />,
          <Button variant="primary" label="Go Back" onClick={() => {}} />,
        ],
        false
      );
    } else {
      importProperties();
    }
  }

  const importProperties = () => {
    setSubmitting(true);

    // Generate list of ids to import or link
    const importIds: string[] = [];
    const linkIds: Record<string, string> = {};
    for (let i = 0; i < arthurProperties.length; i += 1) {
      const entry = arthurProperties[i];
      if (entry.selected) {
        if (entry.linked == null) {
          importIds.push(entry.data.id);
        } else {
          linkIds[entry.linked.value.toString()] = entry.data.id;
        }
      }
    }

    // Submit import ids
    const importPromise = new Promise((resolve, reject) => {
      if (importIds.length > 0) {
        propertiesApi?.createFromExternalProperty(companyContext.cid, importIds)
        .then((response) => {
          if (response.status === 200) {
            resolve(true);
          } else {
            reject(`Unexpected import response code (${response.status})`);
          }
        })
        .catch((error) => {
          if (error.status === 403 && error.error === 'Unable to access arthur')
            setReAuth(true);
          reject(error);
        })
      } else {
        // No imports to action
        resolve(true);
      }
    }).catch((error) => {
      console.error(error);
      setError(true);
      setSubmitting(false);
    });

    // Submit link ids
    const linkPromise = new Promise((resolve, reject) => {
      if (Object.keys(linkIds).length > 0) {
        propertiesApi?.linkExternalProperty(companyContext.cid, linkIds)
          .then((response) => {
            if (response.status === 200) {
              resolve(true);
            } else {
              reject(`Unexpected link response code (${response.status})`);
            }
          })
          .catch((error) => {
            if (error.status === 403 && error.error === 'Unable to access arthur')
              setReAuth(true);
            reject(error);
          })
      } else {
        // No links to action
        resolve(true);
      }
    }).catch((error) => {
      console.error(error);
      setError(true);
      setSubmitting(false);
    });

    // TODO: A toast message to show success would be nice here
    // Transition back to properties if both import and link succeeded
    Promise.all([importPromise, linkPromise]).then((values) => {
      if (!values.includes(undefined)) {
        navigateToEntity(Entity.Property);
      }
    })
  }

  const softLinkProperty = (externalPropertyId:string, oldLinkId:number | null, newLinkId:LinkedProperty | null) => {
    const newLinkableProperties = _.cloneDeep(linkableProperties);

    if (oldLinkId)
      newLinkableProperties[oldLinkId].linked = null;

    if (newLinkId)
      newLinkableProperties[newLinkId.value].linked = externalPropertyId;

    setLinkableProperties(newLinkableProperties);

    // Update link id on Arthur property
    const newArthurProperties = _.cloneDeep(arthurProperties);
    for (let i = 0; i < newArthurProperties.length; i += 1) {
      if (newArthurProperties[i].data.id === externalPropertyId) {
        newArthurProperties[i].linked = newLinkId;
        break;
      }
    }

    setArthurProperties(newArthurProperties);
  }

  const saveEntityId = () => {
    if(extPropertyServicesApi && newEntityId) {
      extPropertyServicesApi.setArthurEntityId(companyContext.cid, {arthurEntityId: newEntityId}).then(() => {
        //Rerun the API call which can check the validity (Or at least it could in the future)
        setHasEntityId(null)
        setError(false)
      }).catch(err => {
        console.log(err)
      })
    }
  }

  return (
    <DefaultLayout entity={Entity.Property} title="Import properties" backIcon={true} greyBackground>
      <div className={styles.propertyImport}>

        { (!loading && !error) && (
          connected && hasEntityId ? (
            <>
              { arthurProperties.length > 0 ? (
                <div className={styles.propertyImportContent}>
                  {/* Show available properties */}
                  <>
                    {/* Title  */}
                    <h3 className="mt-5 mb-4">Select properties to import from Arthur</h3>

                    {/* Select all */}
                    <div className={`${styles.propertyImportContent} align-items-end ml-3`}>
                      <Button
                        buttonType="edit"
                        label="select all"
                        outline={true}
                        onClick={selectAll}
                      />
                    </div>

                    {/* Show linking property info message if selected all */}
                    { showLinkMessage === '*SELECTALL*' && <LinkPropertyInfo /> }

                    {/* Properties */}
                    {arthurProperties.map((entry) => (
                      <PropertyImportEntry
                        key={entry.data.id}
                        entry={entry.data}
                        selected={entry.selected}
                        linked={entry.linked}
                        selectItem={selectItem}
                        linkableProperties={linkableProperties}
                        softLinkProperty={softLinkProperty}
                        showLinkMessage={showLinkMessage}
                        setShowLinkMessage={setShowLinkMessage}
                      />
                    ))}
                    <div className="mt-3" style={{ marginBottom: '150px' }}>
                      { !submitting
                        ? <Button buttonType="import" onClick={checkUnlinkedProperties} disabled={!_.some(arthurProperties, 'selected')} />
                        : <div className={stylesLoader.loader} />
                      }
                    </div>
                  </>
                </div>
              ) : (
                // No properties available to import
                <Alert variant="primary">
                  <Alert.Heading>No new properties avilable to import</Alert.Heading>
                </Alert>
              )}

              {/* Unlink from Arthur */}
              <div className={styles.propertyImportUnlink} onClick={showUnlinkArthurAccount}>
                <FontAwesomeIcon className="mr-2" icon={faPlug} />
                Unlink Arthur account
              </div>
            </>
          ) :
          hasEntityId === false ? (
            //No entity ID set
            <Card>
              <Card.Body>
                <Alert variant='warning'>
                  <p style={{marginBottom: '0.5rem'}}>Sorry, we couldn't find your Arthur Entity ID, please set it now.</p>
                  <p style={{marginBottom: '0'}}>You can find this by logging into Arthur, clicking your name and then selecting "OAuth Application".</p>
                </Alert>
                <Form.Group>
                  <Form.Label>Entity ID</Form.Label>
                  <Form.Control value={newEntityId} onChange={(e) => setNewEntityId(e.target.value)} type={'text'} placeholder='ABC123' style={{maxWidth: '400px', paddingLeft: '0.75rem'}}/>
                </Form.Group>
                <Button buttonType='save' disabled={!newEntityId} onClick={saveEntityId}/>
              </Card.Body>
            </Card>
          ) :
          (
            // Not connected - show connect to Arthur
            <>
              <InfoBanner
                title="Connect to a service"
                body="To import properties you will first need to connect to the external service you are using,
                    you will be taken to their authentication page"
                type="info"
              />
              <ArthurConnect />
            </>
          )
        )}

        {/* Loading icon */}
        { loading && <LoadingShim /> }

        {/* Error message */}
        { error && (
          <div style={{ textAlign: 'center' }}>
            <Alert variant="danger">
              <Alert.Heading>Sorry, something went wrong</Alert.Heading>
            </Alert>
            { reAuth
              ? (
                  <Button
                    buttonType="edit"
                    label="Reconnect to Arthur"
                    onClick={() => {navigateToEntity(Entity.Arthur, Action.LinkToExternal)}}
                  />
              )
              : <>
                <Card style={{textAlign: 'left', marginBottom: '1rem'}}>
                  <Card.Body>
                    <Alert variant='warning'>
                      <p style={{marginBottom: '0.5rem'}}>If you think your Entity ID is incorrect, you can change it here and try again.</p>
                      <p style={{marginBottom: '0'}}>You can find this by logging into Arthur, clicking your name and then selecting "OAuth Application".</p>
                    </Alert>
                    <Form.Group>
                      <Form.Label>Entity ID</Form.Label>
                      <Form.Control value={newEntityId} onChange={(e) => setNewEntityId(e.target.value)} type={'text'} placeholder='ABC123' style={{maxWidth: '400px', paddingLeft: '0.75rem'}}/>
                    </Form.Group>
                    <Button buttonType='save' disabled={!newEntityId} onClick={saveEntityId}/>
                  </Card.Body>
                </Card>
                <Button variant='secondary' onClick={() => { history.go(0); }}><FontAwesomeIcon icon={faArrowsRotate} style={{marginRight: '0.5rem'}}/>Reload Page</Button>
              </>
            }
          </div>
        )}

      </div>

      { modalDialog }

    </DefaultLayout>
  );
};
