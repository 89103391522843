import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useState } from 'react';
import { Accounts } from '../../../api/inni/Accounts';
import { Account } from '../../../api/inni/data-contracts';
import CompanyContext from '../../../context/CompanyContext';
import { Button } from '../../../elements/Button/Button';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { ReceiptAccountTypes } from '../Receipts';
import styles from '../Receipts.module.css';

interface ReceiptsPage3Props {
    accountType: ReceiptAccountTypes, 
    changePageCB : (i : number) => void, 
    changeAccountId : (i : number) => void, 
    accountsData : Account[]
}

const ReceiptPage3 = ({accountType, changePageCB, changeAccountId, accountsData} : ReceiptsPage3Props) => {
    return (
        <>
        <h5>Which account did you use?</h5>
        { accountsData && accountType === 'company' &&
            accountsData.filter(x => (x.accountSubGroup === 'DirectorsLoans' 
            || x.accountSubGroup === 'BankAccounts' 
            || x.accountSubGroup === 'CreditCardLoans') 
            && (x.name ? !x.isExpenseAccount : true) 
            && (x.name ? !x.name.includes('personal') : true)).map(i => {
                return(
                    <Button key={i.id} onClick={() => {changeAccountId(i.id); changePageCB(4)}} variant="light" className={styles.blockButton}>
                        {i.name} <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                )
            })
        }
        { accountsData && accountType === 'personal' &&
            accountsData.filter(x => (x.accountSubGroup === 'DirectorsLoans' 
            || x.accountSubGroup === 'BankAccounts' 
            || x.accountSubGroup === 'CreditCardLoans') 
            && ((x.name ? x.isExpenseAccount : true) 
            || (x.name ? x.name.includes('personal') : true))).map(i => {
                return(
                    <Button key={i.id} onClick={() => {changeAccountId(i.id); changePageCB(4)}} variant="light" className={styles.blockButton}>
                        {i.name} <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                )
            })
        }
        </>
    )
}
export default ReceiptPage3
