import _ from 'lodash';

export function countAllStrings(arr: (string | number | undefined)[], sortByAlpha?: boolean): { name: string , count: number }[] | undefined {
    if (!arr) return undefined;
    //if array contains undefined, remove it
    arr = arr.filter(d => d !== undefined);
    const values = _.values(_.groupBy(arr)).map(d => ({ name:( d[0] || "").toString(), count: d.length }));
    if (sortByAlpha) {
        values.sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        });
    }
    return values;
}

export function trimString(str: string) {
    if (str.length > 80) {
        return str.substring(0, 80) + '...' + str.substring(str.length - 6);
    } else {
        return str;
    }
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};