import React, { MutableRefObject, useEffect, useState } from 'react'
import { add, getDaysInMonth } from 'date-fns'
import { useInniAPI } from '../../../hooks/useInniAPI';
import { QuickEntries } from '../../../api/inni/QuickEntries';
import { Contract, ExpenseAsSuggestion, Mileage, Person, QuickEntry } from '../../../api/inni/data-contracts';
import { useContext } from 'react';
import CompanyContext from '../../../context/CompanyContext';
import _ from 'lodash';
import { asYearMonthDay, formatDate } from '../../../utils/formatDate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faChevronCircleUp, faChevronRight, faClock } from '@fortawesome/pro-regular-svg-icons';
import styles from '../QuickEntry.module.css'
import { formatAccount } from '../../../utils/formatters/formatAccount';
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList';
import { People } from '../../../api/inni/People';
import { Contracts } from '../../../api/inni/Contracts';
import TimeDayEditor from './TimeDayEditor';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import MileageEditor from './MileageEditor';
import ExpenseMobileEditor from './ExpenseMobileEditor';
import { QEFilters, QETypes } from '../QuickEntryMobile';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { Alert } from 'react-bootstrap';
import useVisibility from '../../../hooks/useVisibility';
import classNames from 'classnames';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { Action, Entity } from '../../../utils/EntityAction';

// Contants
const ENTRY_HEIGHT = 45

// Common props for all 3 editors...
export interface EditorCommonProps {
    contracts : Contract[], 
    people : Person[], 
    qe?: QuickEntry, 
    cancelCb : (qe? : QuickEntry, d? : boolean, r? : boolean) => void, 
    showAlert : (i : QEAlerts) => void
}
interface QEListMobileProps {
    creating : QETypes | undefined, 
    setCreating : (i : QETypes | undefined) => void, 
    filters : QEFilters, selected : QuickEntry | undefined, 
    setSelected : (i : QuickEntry | undefined) => void,
    suggestionData? : ExpenseAsSuggestion | Mileage,
    wrapperRef: MutableRefObject<HTMLDivElement | null>
}

const LoadingQEMobile = () => {
    return <div className={styles.qeMobileEntry}>
        <div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2px', marginTop: '5px' }}>
                <LoadingPlaceholder width="300px" height="20px" />
            </div>
            <div style={{marginLeft: '24px'}}><LoadingPlaceholder width="150px" height="20px" /></div>
        </div>
        <div>
            <FontAwesomeIcon icon={faChevronRight} />
        </div>
    </div>;
}

export type QEAlerts = 'saved' | 'error' | 'deleted' // defines which alert we are currently showing

const QEListMobile = ({creating, setCreating, filters, selected, setSelected, suggestionData, wrapperRef} : QEListMobileProps) => {

    // 47 too magic? its the height of an entry. This uses the window size to determine how many entries we fetch (so we ensure we don't have empty space at the bottom)
    const pageLoadLimit = Math.floor(window.outerHeight / ENTRY_HEIGHT)

    const quickEntryAPI = useInniAPI(QuickEntries);

    const [filteredQuickEntryData, setFilteredQuickEntryData] = useState<QuickEntry[] | undefined>(undefined)
    const [quickEntries, setQuickEntries] = useState<QuickEntry[]>([])
    const [isQELoaded, setIsQELoaded] = useState(false)
    const companyContext = useContext(CompanyContext)

    const [employees, employeesLoaded] = useFetchEntityList<Person, People>(People)
    const [contracts, contractsLoaded] = useFetchEntityList<Contract, Contracts>(Contracts)
    const [alertShowing, setAlertShowing] = useState<QEAlerts | undefined>(undefined)

    // paging stuff
    const [nextStart, setNextStart] = useState<number | undefined>(0)
    const [scrollPos, setScrollPos] = useState(window.scrollY)
    const [isVisible, currentElement] = useVisibility<HTMLDivElement>(100);

    // To ensure we don't reset 
    const [wasSelected, setWasSelected] = useState(false)

    const hasPermission = useHasPermission()
    const hasAMSupport = hasPermission(Entity.AccountManagerSupport, Action.All)[0]

    const onScroll = _.throttle(() => {
        if (selected === undefined && wrapperRef.current) 
            setScrollPos(wrapperRef.current.scrollTop || 0)
    }, 200)

    useEffect(() => {
        if (isQELoaded && selected === undefined && wasSelected) {            
            if (wrapperRef.current) {
                wrapperRef.current.scrollBy(0, Math.abs(scrollPos))
                setWasSelected(false)
            }
        }
    }, [isQELoaded, scrollPos, selected, wasSelected, wrapperRef])

    useEffect(() => {
        document.addEventListener('scroll', onScroll, true);
        return () => document.removeEventListener('scroll', onScroll, true);
    }, [onScroll])

    useEffect(() => {
        if (isVisible && isQELoaded === true && nextStart !== undefined) {
            setIsQELoaded(false)
        }
    }, [isVisible, currentElement]) // no isQELoaded here - otherwise we get stuck in an infinite loop

    useEffect(() => {
        setNextStart(0)
        setQuickEntries([])
        setFilteredQuickEntryData([])
        setIsQELoaded(false)
    }, [filters])

    useEffect(() => {
        if (nextStart !== undefined && quickEntryAPI && !isQELoaded) {
            quickEntryAPI.get(companyContext.cid, {
                showTimes: filters.typesToShow.find(x => x === 'T') !== undefined,
                showExpenses: filters.typesToShow.find(x => x === 'E') !== undefined,
                showMileages: filters.typesToShow.find(x => x === 'M') !== undefined,
                billable: filters.billable === '1' ? 0 : filters.billable === '0' ? 1 : undefined,
                start: filters.year && filters.year !== -1 ? asYearMonthDay(new Date(filters.year, filters.month 
                    && filters.month !== -1 ? filters.month-1 : 0, 1)) : asYearMonthDay(add(new Date(), {years: -4})),
                end: filters.year && filters.year !== -1 ? asYearMonthDay(new Date(filters.year, filters.month 
                    && filters.month !== -1 ? filters.month-1 : 11,
                    getDaysInMonth(new Date(filters.year, filters.month ? filters.month-1 : 12)))) : asYearMonthDay(new Date()),
                contractId: filters.relatedId && filters.relatedId !== -1 ? filters.relatedId : undefined,
                limit: pageLoadLimit,
                startI: nextStart,
                // since we are now paging data we use api to filter data
                description: filters.search || undefined
            })
            .then(res => {
                setIsQELoaded(true);
                setQuickEntries([...quickEntries, ..._.sortBy(res.data.items || [], 'date').reverse()]) //we still keep any previously loaded entries so no loading on scroll up
                setNextStart(res.data.hasNext ? res.data.nextStartIndex : undefined)
                // filter by search value using either description or date
                let filteredData = [...quickEntries, ..._.sortBy(res.data.items || [], 'date').reverse()]
                if (filters.search && filters.search?.length > 0) filteredData = filteredData.filter(x => x.description?.toLowerCase().match('.*' + filters.search?.toLowerCase() + '.*') || formatDate(x.date).match('.*' + filters.search + '.*'))
                setFilteredQuickEntryData(filteredData)
            })
        }

    }, [companyContext.cid, isQELoaded, quickEntryAPI, filters, quickEntries, nextStart])

    const cancelCb = (newQe?: QuickEntry, del? : boolean, reload? : boolean) => {
        setSelected(undefined)
        setCreating(undefined)

        if (reload) {
            setQuickEntries([])
            setFilteredQuickEntryData([])
            setNextStart(0)
            setIsQELoaded(false)
            return
        }

        let tempQe = filteredQuickEntryData
        if (!tempQe)
            tempQe = []
        // if we get a new value for the qe we update the state locally or delete it
        if (newQe && !del) {
            // sometimes its new sometimes its edited - if we don't find it we create a new entry
            let index = tempQe.findIndex(x => x.relatedId === newQe.relatedId)

            if (index !== -1) {
                tempQe[index] = newQe
            } else {
                tempQe.push(newQe)
            }

            setFilteredQuickEntryData([...tempQe])
        } else if (newQe && del){
            // del is true, so we delte the entry, if only one item left we set to empty, otherwise we splice.
            let index = tempQe.findIndex(x => x.relatedId === newQe.relatedId)
            if (index !== -1) {
                tempQe.splice(index, 1)
            } else {
                tempQe = []
            }
            setFilteredQuickEntryData([...tempQe])
        }        
    }

    const showAlert = (type : QEAlerts) => {
        setAlertShowing(type)

        setTimeout(() => { setAlertShowing(undefined) }, 2000);
    }

    const scrollToTop = (e : React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        if (wrapperRef.current)
            wrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' }) // smooth scroll to top
    }

    // used to group items together - we check this everytime we output a new item
    let lastDate = new Date()

    return (<>
        <div id="listContainer">
            {employeesLoaded && contractsLoaded && filteredQuickEntryData && filteredQuickEntryData.length > 0 && !selected && !creating && <>
                {filteredQuickEntryData.map((x, i) => {
                    let samedate = asYearMonthDay(lastDate) === asYearMonthDay(new Date(x.date))
                    lastDate = new Date(x.date)
                    let format = formatAccount(x.accountName || '')
                    return (<React.Fragment key={x.relatedId}>
                        {!samedate && <h5 style={{padding: '5px', background: 'var(--grey-no-content-text-color)', color: 'white'}}>{formatDate(x.date)}</h5>}
                        <div ref={i === filteredQuickEntryData.length-1 ? currentElement : null} onClick={() => {setWasSelected(true); setSelected(x)}} className={styles.qeMobileEntry}>
                            <div style={{width: '90%'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    {x.entryType === "T" ? <FontAwesomeIcon icon={faClock} /> : 
                                        x.entryType === "E" ? <FontAwesomeIcon icon={format.icon} color={format.color} /> : 
                                        <FontAwesomeIcon icon={faCar} />}
                                    <h5 style={{marginBottom: '0px', marginLeft: '10px'}}>{x.description}</h5>
                                </div>
                                <div style={{marginLeft: '24px'}}>{x.subtitle}</div>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </div>
                        </div>
                    </React.Fragment>)
                })}
            </>}
            {!isQELoaded && nextStart !== undefined && [...Array(2)].map((x, i) => <LoadingQEMobile key={`loading_${i}`} />)}
        </div>
        {isQELoaded && employeesLoaded && contractsLoaded && filteredQuickEntryData && filteredQuickEntryData.length === 0 && !selected && !creating &&
            <NoContentSlate whiteBg termsKey="noResultsTerms" type={DatagridType.QuickEntries} />
        }
        {((selected && selected?.entryType === 'T') || creating === 'T') && 
            <TimeDayEditor showAlert={showAlert} cancelCb={cancelCb} qe={selected} people={employees} contracts={contracts} />}
        {((selected && selected?.entryType === 'M') || creating === 'M') && 
            <MileageEditor suggestionData={suggestionData as Mileage} showAlert={showAlert} cancelCb={cancelCb} qe={selected} people={employees} contracts={contracts} />}
        {((selected && selected?.entryType === 'E') || creating === 'E') && 
            <ExpenseMobileEditor suggestionData={suggestionData as ExpenseAsSuggestion} showAlert={showAlert} cancelCb={cancelCb} qe={selected} people={employees} contracts={contracts} />}

        <Alert dismissible 
            onClose={() => setAlertShowing(undefined)} 
            show={alertShowing !== undefined} 
            variant={alertShowing === 'saved' || alertShowing === 'deleted' ? 'success' : alertShowing === 'error' ? 'danger' : ''} 
            className={styles.alert}
        >
            { alertShowing === 'saved' && 'Saved successfully!' }
            { alertShowing === 'deleted' && 'Your entry was deleted successfully!' }
            { alertShowing === 'error' && 'There was an error completing your request, please try again.' + hasAMSupport ? ' If the issue persists please contact your account manager.' : '' }
        </Alert>
        <div className={classNames(styles.jumpToTop, 'shadow-lg', { [styles.visible]: scrollPos > ENTRY_HEIGHT*10 && selected === undefined })}>
            <FontAwesomeIcon 
                onClick={(e) => scrollToTop(e)} 
                size="3x" icon={faChevronCircleUp} 
            />
        </div>
    </>)
}

export default QEListMobile
