import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity, Action } from "../../utils/EntityAction";
import Incorporation from "../../features/UserPortal/Incorporation";
import IncorporationDocuments from "../../features/UserPortal/Components/IncorporationDocuments/IncorporationDocuments";

const portalIncorporation:Array<EntityRoute> = [
    
    // This needs to be ABOVE the one below otherwise will be redirected to the welcome page of the form...
    {
        path: '/user/:contactId/incorporation/documents',
        exact: true,
        context: RouteContext.User,
        entity: Entity.IncorporationDocuments,
        component: IncorporationDocuments,
        action: Action.Read
    },
    {
        path: '/user/:contactId/incorporation/:page?',
        exact: true,
        context: RouteContext.User,
        entity: Entity.Incorporation,
        component: Incorporation,
        action: Action.List
    }
    
]

export default portalIncorporation;
