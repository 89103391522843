
import { storyblokEditable, StoryblokComponent, SbBlokData } from "@storyblok/react";
import { useContext } from "react";
import { InitialiseDateModel } from "../../../api/inni/data-contracts";
import OnboardingContext from "../../../context/OnboardingContext";
import styles from './pageonboarding.module.css';
 type Props = {
    blok: SbBlokData;
    initialData: InitialiseDateModel;
    isCompletedPage: boolean;
 }
const PageOnboarding = ({ blok, initialData, isCompletedPage }: Props) => {
    const {onClickAction} = useContext(OnboardingContext);
   
  return (
    <div {...storyblokEditable(blok)} className={styles.pageOnboarding}>
        <h3>{blok.title}</h3>
        <div>
            {blok.body
            ? (blok.body as SbBlokData[]).map((blok: SbBlokData) => (
                <StoryblokComponent onClick={onClickAction(blok)} blok={blok} key={blok._uid} isCompletedPage={isCompletedPage} initialData={initialData}/>
            ))
            : null}
        </div>
    </div>
    ) 
};
 
export default PageOnboarding;