import styles from './ArthurConnect.module.css';
import { Action, Entity } from '../../../../utils/EntityAction';
import { useNavigateToEntity } from '../../../../hooks/useNavigateToEntity';

const ArthurConnect = () => {
  const navigateToEntity = useNavigateToEntity();

  return (
    <div
      onClick={() => navigateToEntity(Entity.Arthur, Action.LinkToExternal)}
      className={styles.arthurLink}
    > 
        <img src="./images/Arthur/arthur-logo-title.png" alt="logo" />
        <span>Connect to Arthur property management</span>
    </div>
  );
}

export default ArthurConnect;
