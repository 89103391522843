import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import { People } from "../../features/People/People";
import NewEmployee from "../../features/People/Components/NewEmployee";

export const manageemployees:Array<EntityRoute> = [
    {
        path: '/company/:cid/manageemployees',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.PersonV7,
        component: People
    }
]

export const newemployee:Array<EntityRoute> = [
    {
        path: '/company/:cid/manageemployees/new',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.PersonV7,
        action: Action.Create,
        component: NewEmployee
    }
]
