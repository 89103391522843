import { Category } from "../../api/inni/data-contracts";
import { formatSubGroup } from "../../utils/formatters/formatAccount"


export const getUniqueForLevel = (categories : Category[], key : keyof Category) => {
    // simple! build a set and add every key to it
    let setOfEntries = new Set<string>()

    categories.forEach(x => {if (x[key] !== null) setOfEntries.add(x[key] as string)})

    return Array.from(setOfEntries)
}

export const getHeadingsForType = (str : string) => {
    switch (str) {
        case 'expense':
        case 'refund':
            return ['Category', 'Subcategory', 'Entry']
        case 'transfer':
            return ['', '', 'To/From']
        case 'nonbusinessexpense':
            return ['Category', 'Subcategory', 'Entry']
        case 'income':
            return ['', '', 'Entry']
        default:
            return str
    }
}

export const formatTypeString = (str : string) => {
    switch (str) {
        case 'expense':
            return 'Expense'
        case 'transfer':
            return 'Transfer'
        case 'nonbusinessexpense':
            return 'Non-business expense'
        case 'income':
            return 'Income'
        case 'refund':
            return 'Refund'
        default:
            return str
    }
}

export const formatSubType = (str : string) => {
    switch(str) {
        case 'services':
            return 'Services'
        case 'goods':
            return 'Goods'
        case 'tax':
            return 'Tax, fines or losses'
        case 'banking':
            return 'Banking, mortgages or interest'
        case 'withdrawals':
            return 'Other withdrawals'
        default:
            return str
    }
}

export const formatTypeByLevel = (level: number, str: string) => {
    switch (level) {
        case 0:
            return formatTypeString(str)
        case 1:
            return formatSubType(str)
        case 2:
            return formatSubGroup(str)
        default:
            return str
    }
}

export const typePrefixer = (str: string) => {
    switch (str) {
        case `Director's loan`:
        case 'Transfer':
        case 'Business expense':
        case 'Non-business expense':
        case 'Refund':
        case 'Property completion statement':
            return `a ${str}`
        case 'Other asset':
        case 'Expense':
            return `an ${str}`
        default:
            return str
    }
}

export const getAutocompleteSuggestion = (categories : Category[], search : string) => {
    let uq = getUniqueForLevel(categories, 'name')

    return uq.find(x => (x || '').toLowerCase().startsWith((search || '').toLowerCase()))
}