import { Employment, PersonAsDetailed } from '../../../api/inni/data-contracts';
import { Tab as HorizontalTab, Tabs as HorizontalTabs } from '../../../elements/HorizontalTabs/HorizontalTabs';
import { formatDate } from '../../../utils/formatDate';
import { TabTypes } from '../People';

interface PersonTabsProps {
    editing : boolean,
    personDetails : PersonAsDetailed | undefined, 
    activeTab : TabTypes, 
    setActiveTab : (i : TabTypes) => void, 
    setCurEmployment : (i : Employment) => void,
    curEmployment: Employment | undefined,
    disabled : boolean,
}

const saveDisabledMsg = "Please save before switching tab"

const PersonTabs = ({editing, personDetails, activeTab, disabled,setActiveTab, curEmployment, setCurEmployment} : PersonTabsProps) => {

    return (
        <HorizontalTabs>
            <HorizontalTab disabled={editing && disabled} disabledMessage={saveDisabledMsg} key="details" 
                keyName="details" title="Overview" onClick={() => setActiveTab('overview')} selected={activeTab === 'overview'} />
            <HorizontalTab disabled={editing && disabled} disabledMessage={saveDisabledMsg} key="shares" 
                keyName="shares" title="Shares" onClick={() => setActiveTab('shares')} selected={activeTab === 'shares'} />
            {/* Tab for each employment record */}
            {personDetails?.employments?.map((x, i) => (
                <HorizontalTab
                    disabledMessage={saveDisabledMsg}
                    disabled={editing && disabled}
                    key={"employmentTab_" + i}
                    small={(personDetails?.employments?.length || 0) > 1 ? `${formatDate(x.startDate)} - ${formatDate(x.leavingDate)}` : ''}
                    keyName="employment"
                    title="Employment"
                    onClick={() => { setActiveTab('employment'); setCurEmployment(x) }}
                    selected={activeTab === 'employment' && curEmployment === x}
                />))
            }
            {personDetails?.showBenefits &&
                <HorizontalTab
                disabled={editing && disabled} disabledMessage={saveDisabledMsg}
                    key="benefites" keyName="benefits" title="Benefits" onClick={() => setActiveTab('benefits')} selected={activeTab === 'benefits'} adminOnly />
            }
            {personDetails?.showITR &&
                <HorizontalTab
                disabled={editing && disabled} disabledMessage={saveDisabledMsg}
                    key="itr" keyName="itr" title="ITR" onClick={() => setActiveTab('itr')} selected={activeTab === 'itr'} adminOnly />
            }
            {personDetails?.isActiveEmployee && <>
                <HorizontalTab
                    disabled={editing && disabled} disabledMessage={saveDisabledMsg}
                    key="taxcode" keyName="taxcode" title="Tax code" onClick={() => setActiveTab('taxcode')} selected={activeTab === 'taxcode'} />
                <HorizontalTab
                    disabled={editing && disabled} disabledMessage={saveDisabledMsg}
                    key="payslips" keyName="payslips" title="Payslips" onClick={() => setActiveTab('payslips')} selected={activeTab === 'payslips'} />
            </>}
            {personDetails?.showPension &&
                <HorizontalTab
                disabled={editing && disabled} disabledMessage={saveDisabledMsg}
                    adminOnly key="pension" keyName="pension" title="Pension settings" onClick={() => setActiveTab('pension')} selected={activeTab === 'pension'} />
            }
            {personDetails?.showStatPaymnets &&
                <HorizontalTab
                disabled={editing && disabled} disabledMessage={saveDisabledMsg}
                    adminOnly key="statutorypayments" keyName="statutorypayments" title="Statutory payments"
                    onClick={() => setActiveTab('statutorypayments')} selected={activeTab === 'statutorypayments'}
                />
            }
        </HorizontalTabs>
    )
}

export default PersonTabs