import classNames from 'classnames'
import styles from '../CorporationTax.module.scss'
import { formatCurrency } from '../../../utils/formatNumbers'

const EmptySlateCT = () => {
    return (
        <div className={styles.seperator}>
            <h1 className={styles.reportTitle}>Profit</h1>
            <h3 className={classNames(styles[`level-0`], styles.flexGrow)}> 
                Assessable Profit
                <span className={styles.floatRight}>{formatCurrency(0)}</span>
            </h3>
            <h1 className={styles.reportTitle}>Corporation tax</h1>
            <h3 className={classNames(styles[`level-0`], styles.flexGrow)}> 
                Corporation tax due
                <span className={styles.floatRight}>{formatCurrency(0)}</span>
            </h3>
        </div>
    )     
}

export default EmptySlateCT;