import { ResponsivePie } from '@nivo/pie'
import { PieChartValue } from '../DashboardCards/PieChart'
import styles from './LoadingPlaceholder.module.css'
import classNames from 'classnames'


interface LoadingPlaceholderProps {
	width?: string,
	height?: string,
	float?: 'left' | 'right' | 'none' | 'inline-start' | 'inline-end',
	dark?: boolean
}

export const LoadingPlaceholder = ({width, height, float, dark}: LoadingPlaceholderProps) => {
	return (
		<span
			className={classNames(styles.loading, {[styles.loadingDark]: dark})}
			style={{
				width: width ? width : '100%',
				maxWidth: '100%',
				height: height ? height : '1em',
				float: float ? float : undefined
			}}
		></span>
	);
}

export const LoadingPlaceholderPie = () => {

	const sampleData: PieChartValue[] = [
		{
			id: 0,
			value: 100
		}
	]

	return (
		<>
			<div className={styles.pie}><ResponsivePie
				data={sampleData}
				innerRadius={0.8}
				enableRadialLabels={false}
				enableSliceLabels={false}
				colors={'#eee'}
				tooltip={() => <></>}
			/></div>
		</>
	)
}

export const LoadingPlaceholderFYSelector = () => {
	return (
		<div style={{border: '1px solid var(--slate-blue-border-color)', width: '200px', height: '40.91px', borderRadius: 'var(--input-field-radius)'}}>
			<span className={styles.loadingSelector}></span>
		</div>
	)
}

export const LoadingTextInput = () => {
	return (
		<div style={{border: '1px solid var(--slate-blue-border-color)', height: '40.91px', borderRadius: 'var(--input-field-radius)'}}>
			<span className={styles.loadingSelector}></span>
		</div>
	)
}

export const LoadingLabel = () => {
	return (
		<label style={{height: '1.5rem', width: '150px'}}>
			<span style={{marginTop: 0, marginLeft: 0}} className={styles.loadingSelector}></span>
		</label>
	)
}

export const LoadingText = () => {
	return (
		<div style={{height: '40.91px'}}>
			<span className={styles.loadingSelector}></span>
		</div>
	)
}
// 40.91px is used because it is the fixed width of a form element. It must be this value to perfectly replicate the selector

export const LoadingPlaceholderSearchSelector = () => {
	return (
		<div style={{border: '1px solid var(--slate-blue-border-color)', width: '194px', height: '40.91px', borderRadius: 'var(--input-field-radius)'}}>
			<span className={styles.loadingSelector}></span>
		</div>
	)
}

export const LoadingPlaceholderContractSelector = () => {
	return (
		<div style={{border: '1px solid var(--slate-blue-border-color)', width: '350px', height: '40.91px', borderRadius: 'var(--input-field-radius)'}}>
			<span className={styles.loadingSelector}></span>
		</div>
	)
}

export const LoadingPlaceholderAccountSelector = () => {
	return (
		<div style={{border: '1px solid var(--slate-blue-border-color)', width: '318px', height: '40.91px', borderRadius: 'var(--input-field-radius)'}}>
			<span className={styles.loadingSelector}></span>
		</div>
	)
}
