import { Formik, FormikErrors, FormikHelpers, Form as FormikForm, FormikProps} from 'formik'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { Account, NewPaymentPostModel } from '../../../api/inni/data-contracts'
import { Button } from '../../../elements/Button/Button'
import { Text as EditText, SelectList, ListOption, DateSelector, Switch, Currency }  from '../../../elements/EditRow/EditRow'
import { formatCurrency } from '../../../utils/formatNumbers'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { Expenses } from '../../../api/inni/Expenses'
import CompanyContext from '../../../context/CompanyContext'
import { accountsBank, accountsExpenditure, accountsIncome } from '../../../utils/accountsFilterHelper'
import { Contracts } from '../../../api/inni/Contracts'
import { addDays } from 'date-fns'

export interface RecordPaymentModalProps {
    initialValues: NewPaymentPostModel,
    onCancelClick: () => void,
    accounts: Account[],
    showPersonal?: boolean,
    readOnlyFields?: Record<string, string>,
    formSubmit: (values: NewPaymentPostModel, actions: FormikHelpers<NewPaymentPostModel>) => Promise<void>,
    formValidate: (values: NewPaymentPostModel) => Promise<FormikErrors<NewPaymentPostModel>>,
    getUpdatedIntialValues: (bankAccountid?: number) => void,
}

const RecordPaymentModal = ({
    initialValues, 
    readOnlyFields, 
    formSubmit,
    formValidate, 
    onCancelClick, 
    accounts, 
    showPersonal,
    getUpdatedIntialValues
} : RecordPaymentModalProps) => {
    const companyContext = useContext(CompanyContext);
    const [listAccounts, setListAccounts] = useState<ListOption[]>([]);
    const [listCategories, setListCategories] = useState<ListOption[]>([]);
    const [vatRates, setVATRates] = useState<ListOption[]>([]);
    const [listContracts, setListContracts] = useState<ListOption[]>([]);
    const [bankAccountId, setBankAccountId] = useState(initialValues.bankAccountid)
    const expensesAPI = useInniAPI(Expenses);
    const contractsAPI = useInniAPI(Contracts);
    
    useEffect(() =>{
        if(initialValues.bankAccountid !== bankAccountId){
            getUpdatedIntialValues(bankAccountId);
        }
    }, [bankAccountId])

    useEffect(() => {
        let out:Array<ListOption> = [];
        const sorted = _.sortBy(accounts, 'orderId')
        sorted.filter(x => x.bankAccount && !x.isExpenseAccount && (!x.personId || showPersonal)).forEach(account => {
            out.push({
                value: account.id.toString(), 
                label: account.name || "",
            })
        })
        setListAccounts(out)
    },[accounts, showPersonal])

    useEffect(() => {

        const sortedAccounts = _.sortBy(accounts, 'orderId');

        const filteredAccounts = initialValues.accountIdTypes === "Income"
                ? accountsIncome(sortedAccounts)
                : initialValues.accountIdTypes === "Expenditure"
                ? accountsExpenditure(sortedAccounts)
                : initialValues.accountIdTypes === "BankAccounts"
                    ?accountsBank(sortedAccounts)
                    :[];
        
        const out: ListOption[] = filteredAccounts.map(account => ({
            value: account.id.toString(),
            label: account.name || ""
        }));

        setListCategories(out);
        
    },[accounts, initialValues.accountIdTypes, initialValues.showCategory])

    useEffect(() => {
        if (expensesAPI && !initialValues.hideVAT && vatRates.length === 0) {
            expensesAPI.vatRates(companyContext.cid).then(res => {
                let options : ListOption[] = [];
                res.data.forEach(x => {
                    options.push({value: x.id, label: x.name || ""});
                });
                setVATRates(options);
            })
        };
    },[companyContext.cid, expensesAPI, initialValues.hideVAT]);

    useEffect(() => {
        if (contractsAPI && initialValues.showBillto && listContracts.length === 0) {
            contractsAPI.index(companyContext.cid).then(res => {
                const today = new Date()
                let options : ListOption[] = [];
                res.data.filter( x =>(
                        (x.startDate ? new Date(x.startDate) <= today : true)
                    &&  (x.endDate ? addDays(new Date(x.endDate), 30) >= today  : true))
                ).forEach(x => {
                    options.push({value: x.id.toString(), label: x.name || ""});
                });
                setListContracts(options);
            })
        };
    },[companyContext.cid, contractsAPI, expensesAPI, initialValues.showBillto]);

    return(
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={formSubmit}
                validateOnChange={false}       
                validate={formValidate}
            >
                {({ isSubmitting, values }) => {
                    
                    if(values.bankAccountid !== bankAccountId )
                    {
                        setBankAccountId(values.bankAccountid)
                    }

                    return (<>
                        <FormikForm>
                            <SelectList<NewPaymentPostModel> labelTop name="bankAccountid" options={listAccounts} label="Account" />
                            <DateSelector<NewPaymentPostModel> labelTop name="date" />
                            <EditText<NewPaymentPostModel> labelTop name="description" label="Description" />
                            <Currency<NewPaymentPostModel> labelTop name="amount" label={initialValues.amountLabel}  currencySymbol={initialValues.currencySymbol} />

                            {!initialValues.hideVAT && vatRates.length > 0 &&
                                <SelectList<NewPaymentPostModel> labelTop name="vatRate" options={vatRates} label="VAT rate" />
                            }
                           
                            {initialValues.cbFullypaidVisible &&
                                <Currency<NewPaymentPostModel> 
                                    labelTop 
                                    name="amountPaidInSourceCurrency" 
                                    label={initialValues.amountPaidInSourceCurrencyLabel} 
                                    currencySymbol={initialValues.sourceCurrencySymbol}
                                    readOnlyWithMessage={initialValues.amountPaidInSourceCurrencyReadOnly ? "" : undefined}
                                    help={`You are expecting this payment to be ${formatCurrency(initialValues.amountLocal)}. If the payment is for more or less than this, enter a different value.`}
                                />
                            }
                            <EditText<NewPaymentPostModel> labelTop name="payee" label="Payee" />
                            <EditText<NewPaymentPostModel> labelTop name="reference" label="Reference" />

                            {initialValues.showCategory && listCategories.length > 0 &&
                                <SelectList<NewPaymentPostModel> labelTop name="cat" options={listCategories} label="Category" />
                            }

                            {initialValues.showBillto && listContracts.length > 0 &&
                                <SelectList<NewPaymentPostModel> labelTop name="billTo" options={listContracts} label="Bill to" />
                            }

                            <div style={{textAlign: 'left', width: '100%'}}>
                                <Button variant="change" submitButton disabled={isSubmitting}>Save</Button>
                                <Button variant="secondary" onClick={onCancelClick}>Cancel</Button>
                            </div>                            
                        </FormikForm>
                        </> 
                    );
                    }}
            </Formik>
    )

}

export default RecordPaymentModal

