
import { SbBlokData, storyblokEditable } from "@storyblok/react";
import WistiaEmbed from "./WistiaEmbed";
 type Props = {
    blok: SbBlokData;
 }
const Video = ({ blok }: Props) => {
  return (
        <div {...storyblokEditable(blok)} className="mb-3">
            <WistiaEmbed hashedId={blok.wistia_id as string} />
        </div>
  );
};
 
export default Video;
 