import { useCallback, useEffect, useState } from "react"
import { ApiConfig, HttpClient } from "../api/inni/http-client"
import { InniAPIFactory } from "../api/inni/InniAPIFactory";
import { useDisplayHttpResponseError } from "./useDisplayHttpResponseError";
import { useGetAccessToken } from "./useGetAccessToken";


// Inspired by https://www.digitalocean.com/community/tutorials/creating-a-custom-usefetch-react-hook
/**
 * 
 * @param apiClass The class to generate
 * @param hideErrorCodes Don't show the global error handler (red bar) for these error codes
 * @returns 
 */
export function useInniAPI<T extends HttpClient>(apiClass: { new (p1: ApiConfig): T }, hideErrorCodes?: number[]): T | undefined {
    const getAccessToken = useGetAccessToken();
    const httpErrorHandler = useDisplayHttpResponseError();
    const [api, setApi] = useState<T|undefined>(undefined);
    const [errorCodes, setErrorCodes] = useState(hideErrorCodes);
    const [tokenExpiry, setTokenExpiry] = useState<Date | null>();
    const [tokenExpired, setTokenExpired] = useState(false);

    // Memoized filtered error handler
    const filteredErrorHandler = useCallback(
        (error: Response) => {
            if (errorCodes && errorCodes.includes(error.status)) {
                // swallow it
            } else {
                httpErrorHandler(error);
            }
        }, [errorCodes, httpErrorHandler]
    );

    const setupApi = useCallback(async () => {
        try {
            const apiInstance = await InniAPIFactory.getAPI(
                apiClass,
                getAccessToken,
                filteredErrorHandler
            );
            setApi(apiInstance.apiInstance);
            setTokenExpiry(apiInstance.expiry);
            setTokenExpired(false);
        } catch (error) {
            console.error('Error creating or reinitializing API instance:', error);
        }
    }, [apiClass, getAccessToken, filteredErrorHandler]);

    // Initialise the API instance
    useEffect(() => {
        setupApi();

        return () => {
            setApi(undefined);
        };
    }, [setupApi]);

    // Set timer for token refresh
    useEffect(() => {
        if (tokenExpiry) {
            // Tokens can be refreshed 5 minutes before expiry, set timer -3 minutes
            const scheduleTime = new Date(tokenExpiry.getTime() - 3 * 60 * 1000);
            const timeDeltaDelay = scheduleTime.getTime() - new Date().getTime();

            if (timeDeltaDelay > 0) {
                const timeoutId = setTimeout(() => {
                    setTokenExpired(true);
                }, timeDeltaDelay);

                // Cleanup timeout
                return () => {
                    clearTimeout(timeoutId);
                };
            } else {
                // If calculated time before expiry in the past swallow it. We could set token expired but might create error loop...
                console.error('New token has already expired');
                // setTokenExpired(true);
            }
        }
    }, [tokenExpiry]); 

    // handle token expired reinit    
    useEffect(() => {
        if (tokenExpired) {
            setupApi();
        }
    }, [tokenExpired, setupApi]);

    return api;
}