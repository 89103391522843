/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TimesheetItem, TimeSheetSummaries, TimeTask, TimeTaskPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Time<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Time
   * @name Get
   * @request GET:/api/v2/companies/{companyId}/time/{employeeId}
   * @secure
   */
  get = (companyId: number, employeeId: number, query: { date: string }, params: RequestParams = {}) =>
    this.request<TimeTask[], any>({
      path: `/api/v2/companies/${companyId}/time/${employeeId}`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Time
   * @name GetTimeSheets
   * @request GET:/api/v2/companies/{companyId}/time/Timesheets/{contractId}
   * @secure
   */
  getTimeSheets = (companyId: number, contractId: number, params: RequestParams = {}) =>
    this.request<TimesheetItem[], any>({
      path: `/api/v2/companies/${companyId}/time/Timesheets/${contractId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Time
   * @name GetTimeSheetSummaries
   * @request GET:/api/v2/companies/{companyId}/time/TimesheetSummaries/{contractId}
   * @secure
   */
  getTimeSheetSummaries = (companyId: number, contractId: number, params: RequestParams = {}) =>
    this.request<TimeSheetSummaries[], any>({
      path: `/api/v2/companies/${companyId}/time/TimesheetSummaries/${contractId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Time
   * @name Put
   * @request PUT:/api/v2/companies/{companyId}/time
   * @secure
   */
  put = (companyId: number, timeTaskList: TimeTaskPostModel[], params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/time`,
      method: "PUT",
      body: timeTaskList,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
