import { useContext, useEffect, useState } from "react"
import Fullscreen from "../../../../components/Fullscreen/Fullscreen"
import Icon from "../../../../elements/Icon/Icon"
import { BrandWrapper } from "../../../../components/BrandWrapper/BrandWrapper"
import { Asset, Category, LettingStatement, LettingStatementLine, LettingStatementPostModel, Property } from "../../../../api/inni/data-contracts";
import { formatCurrency } from "../../../../utils/formatNumbers";
import { asYearMonthDay, formatNaturalLanguageDate } from "../../../../utils/formatDate";
import { Field, FieldProps, Form, Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import FormikField from "../../../../elements/FormikField/FormikField";
import { Button } from "../../../../elements/Button/Button";
import classNames from "classnames";
import { useLettingAgentsList } from "../../../../hooks/lists/useLettingAgentsList";
import styles from "../AgentStatement.module.css"
import OptionSelector from "../../../../components/OptionSelector/OptionSelector";
import { useInniAPI } from "../../../../hooks/useInniAPI";
import CompanyContext from "../../../../context/CompanyContext";
import { Properties } from "../../../../api/inni/Properties";
import CategorySelectorSearch from "../../../../components/CategorySelector/CategorySelectorSearch";
import { useModalDialog } from "../../../../hooks/useModalDialog";
import Overview from "./Overview";
import Attachments from "../../../../components/Attachments/Attachments";
import { FileRejection } from "react-dropzone";
import { Submit } from "../../../../elements/EditRow/EditRow";
import { Form as ReactForm } from "react-bootstrap";
import CategorySelectorBrowser from "../../../../components/CategorySelector/CategorySelectorBrowser";
import { Alert } from 'react-bootstrap';

type AgentStatementProps = {
	initialValues: LettingStatement;
    onSubmit: (values: LettingStatementPostModel, actions: FormikHelpers<LettingStatementPostModel>) => Promise<void>;
    isIncoming: boolean;
    hide: () => void;
    formikRef: React.MutableRefObject<FormikProps<LettingStatementPostModel> | null>;
    accounts: Category[] | undefined;
    assets: Asset[]|undefined;
    filesToUpload: File[] | null | undefined;
    setFilesToUpload: (files:File[] | null | undefined) => void;
    onValidate : (values: LettingStatementPostModel ) => Promise<FormikErrors<LettingStatementPostModel>>;
    errorMessage?: string;
    isSaving: boolean;
    forceSave: () => void;
    setErrorMessage: (msg: string) => void;
};

const CreateLettingStatementForm = ({
        initialValues, onSubmit, isIncoming,
        hide, formikRef, accounts,
        assets, filesToUpload, setFilesToUpload,
        onValidate, errorMessage, isSaving,
        forceSave, setErrorMessage}: AgentStatementProps
    ) => {
    
    //stages
    const [linesStageFlow, setLinesStageFlow] = useState<string[]>()
    const [currentStageNumber, setCurrentStageNumber] = useState<number>(0);
    const [showNewAgentPage, setShowNewAgentPage] = useState(false);
    const [showNewPropertyPage, setShowNewPropertyPage] = useState(false);

    //context
    const companyContext = useContext(CompanyContext)

    //flags
    const [isEditFromOverview,setIsEditFromOverview] = useState(false)
    const [isSplitFromOverview,setIsSplitFromOverview] = useState(false)
    
    //agent
    const [agentDisable, setAgentDisable] = useState(false);
    const [newAgentName, setNewAgentName] = useState<string>()
    const {agents, addNewAgentOption} = useLettingAgentsList();
    
    //property
    const [propertiesLoaded, setPropertiesLoaded] = useState(false);
    const [propertiesDisable, setPropertiesDisable] = useState(false);
    const propertiesAPI = useInniAPI(Properties)
    const [newPropertyName, setNewPropertyName] = useState<string>()
    const [properties, setProperties] = useState<Property[]>()
    const [selectedPropertyId, setSelectedPropertyId] = useState<number>();

    //category
    const [selectedCategory, setSelectedCategory] = useState<Category>()
    const [showCategoryBrowser,setShowCategoryBrowser] = useState(false)
    const [showModalDialog, modalDialog] = useModalDialog();

    //lettingstatement line
    const [index, setIndex] = useState<number>()
    const [formikNewErrors, setFormikNewErrors] = useState<FormikErrors<LettingStatementPostModel>>();
    
    //BreadCrumb
    const [breadcrumbFlow, setBreadcrumbFlow] = useState<{text:string; ref?:string}[]>()

    useEffect(() => {
        if (companyContext && propertiesAPI && !propertiesLoaded ) {
            propertiesAPI.index(companyContext.cid).then(
                response => {
                    setProperties(response.data)
                    setPropertiesLoaded(true)
                }
            ).catch((error) => {
                setPropertiesLoaded(true)
                console.error(error)
            })
        }
    }, [companyContext, propertiesAPI, propertiesLoaded])

    const stageDetails = [ 
        // --- PHASE 1
        // 0
        {
            Key: "intro",
            Title: 'Categorise your letting statement',
            ShowBreadcrumb: false,
            ShowHeader: false
        },
        // 1
       {
            Key: "agent",
            Title: showNewAgentPage
                ? "New agent"
                : "Letting agent",
            Subtitle: showNewAgentPage
                ? "We'll set up your new agent now, what is your agents name?"
                : "Which agent is this statement from? If you haven't already, you can create this agent now",
            ShowBreadcrumb: false,
            ShowHeader: true
        },
        // 2
        {
            Key: "statementNumber",
            Title: 'Statement reference',
            Subtitle: "If your letting statement has a reference ID, please enter it below",
            ShowBreadcrumb: false,
            ShowHeader: true
        },
        // 3
        {
            Key: "property",
            Title: showNewPropertyPage
                ? "New property"
                : "Which property",
            Subtitle: showNewPropertyPage
                ? "We'll set up your new property now, what should we call it?"
                : "Which property is this statement for? If you haven't already, you can create this now",
            ShowBreadcrumb: false,
            ShowHeader: true
        },

        // --- PHASE 2
        // 4
        {
            Key: "linesIntro",
            Title: "Let's breakdown the details",
            ShowBreadcrumb: false,
            ShowHeader: false
        },
        // 5
        {
            Key: "category",
            Title: `Categorise the ${ (index && index > 0) ? "next" : "first"} item`,
            Subtitle: "Search for a category below, or explore the categories",
            BreadCrumb: "Category",
            BreadCrumbValue: accounts && index !== undefined
                ? accounts?.find((account) => account.accountId === formikRef.current?.values.lettingStatementLines?.[index].accountID)?.name
                : "Category",
            ShowBreadcrumb: true,
            ShowHeader: true
        },
        // 6
        {
            Key: "amount",
            Title: "Amount",
            Subtitle: "Specify the amount for this entry, as indicated on your letting statement",
            BreadCrumb: "Amount",
            BreadCrumbValue: index !== undefined ? `£ ${formikRef.current?.values.lettingStatementLines?.[index].amountExVAT.toString()}` : "Amount",
            ShowBreadcrumb: true,
            ShowHeader: true
        },
        // 7
        {
            Key: "lineProperty",
            Title: showNewPropertyPage
                ? "New property"
                : "Select property",
            Subtitle: showNewPropertyPage
                ? "We'll set up your new property now, what should we call it?"
                :"Select which property this entry relates to",
            BreadCrumb: "Select property",
            BreadCrumbValue:( index !== undefined 
                                ? properties?.find(x => x.id === formikRef.current?.values.lettingStatementLines?.[index].propertyId)?.name 
                                : "Select property"
                            ),
            ShowBreadcrumb: true,
            ShowHeader: true
        },
        // 8
        {
            Key: "isReplacement",
            Title: "Is this a replacement item?",
            Subtitle: "Is this entry replacing an item you've previously purchased?",
            BreadCrumb: "Replacement item",
            BreadCrumbValue: index !== undefined &&  formikRef.current?.values.lettingStatementLines?.[index||0].isReplacementItem 
                                ? "Replacement" 
                                : "Not a replacement",
            ShowBreadcrumb: true,
            ShowHeader: true
        },
        // 9
        {
            Key: "isValueIncrease",
            Title: "Does it increase the property value?",
            Subtitle: "Does it increase the property value? For example, adding an en-suite will increase the value vs a like-for-like bathroom replacement",
            BreadCrumb: "Increases value",
            BreadCrumbValue: index !== undefined &&  formikRef.current?.values.lettingStatementLines?.[index||0].increasesPropertyValue
                                ? "Increases value"
                                : "Does not increase value",
            ShowBreadcrumb: true,
            ShowHeader: true
        },
        // 10
        {
            Key: "isAsset",
            Title: "Is this item an asset?",
            Subtitle: "Assets are valuable items worth over £200 that retain their value over time, thereby enhancing the value of your business. Typically, these include tangible goods such as furniture and appliances.",
            BreadCrumb: "Create Asset",
            BreadCrumbValue: index !== undefined &&  formikRef.current?.values.lettingStatementLines?.[index||0].createAsset
                                ? "Asset"
                                : "Not an asset",
            ShowBreadcrumb: true,
            ShowHeader: true
        },
        // 11
        {
            Key: "isDateCoverage",
            Title: "Does this payment cover a date range?",
            Subtitle: "Does this entry cover a date range outside of the usual letting statement cycle, e.g. an upfront yearly subscription",
            BreadCrumb: "Date range",
            BreadCrumbValue: (currentStageNumber === 15 || (index !== undefined && formikRef.current?.values.lettingStatementLines?.[index||0].applicablePeriodStart))
                                 ? "Applicable period" 
                                 : "No applicable period",
            ShowBreadcrumb: true,
            ShowHeader: true
        },
        // 12
        {
            Key: "dateRange",
            Title: "Select the date range",
            Subtitle: (index !== undefined && formikRef.current?.values.lettingStatementLines?.[index || 0].createAsset)
                ? "What is the applicable start date or purchase date for this item? You can leave the end date empty if not applicable"
                : "If applicable, what is the date range for this item?",
            ShowBreadcrumb: true,
            ShowHeader: true
        },
        // 13
        {
            Key: "lineDescription",
            Title:( index !== undefined && formikRef.current?.values.lettingStatementLines?.[index].createAsset 
                        ?  "Enter a name for this item"
                        : "Enter a description for this entry"

                    ) ,
            Subtitle: ( index !== undefined && formikRef.current?.values.lettingStatementLines?.[index].createAsset 
                            ?  "You are creating a new asset with this entry, let us know what we should we call it"
                            : undefined
                        ) ,
            BreadCrumb: "Description",
            BreadCrumbValue: "Description",
            ShowBreadcrumb: true,
            ShowHeader: true
        },
        // 14
        {
            Key: "overview",
            Title: "Overview",
            ShowBreadcrumb: false,
            ShowHeader: true
        },
        // 15
        {
            Key: "submit",
            Title: "Upload letting statement",
            Subtitle: "Finally, to complete your records, please upload your letting statement",
            ShowBreadcrumb: false,
            ShowHeader: true
        }
    ]

    const getKeyIndex = (keyName: string) => {
        return stageDetails.findIndex(x => x.Key === keyName);
    }

    useEffect(() => {
        const maxStaticStage = getKeyIndex('amount');
        const firstNewLineStage = getKeyIndex('category');
        const lastNewLineStage = getKeyIndex('lineDescription');

        if (currentStageNumber >= firstNewLineStage && currentStageNumber <= lastNewLineStage) {
            const breadCrumbs = [];

            breadCrumbs.push({ text: `Entry ${(index || 0) + 1}` });

            //  add previous stages
            for (let index = firstNewLineStage; index < currentStageNumber; index++) {
                const stage = stageDetails[index];
                const bcText = stage.BreadCrumbValue;

                if (bcText && (index <= maxStaticStage || linesStageFlow?.includes(stage.Key)))
                    breadCrumbs.push({text: bcText});
            }

            // add current stage
            const stage = stageDetails[currentStageNumber];
            const bcText = stage.BreadCrumb;

            if (bcText && (currentStageNumber <= maxStaticStage || linesStageFlow?.includes(stage.Key)))
                breadCrumbs.push({text: bcText});


            setBreadcrumbFlow(breadCrumbs);
        } 
    }, [currentStageNumber, index])

    const nextStage = () => {
        setCurrentStageNumber(prev => prev + 1);
    }

    const goback = () => {
        if (showNewAgentPage || showNewPropertyPage) {
            setShowNewAgentPage(false);
            setShowNewPropertyPage(false);
        } else {
            const maxStaticStage = getKeyIndex('amount');

            if (currentStageNumber <= maxStaticStage) {
                // If we are on the category page and not editing we will have created an unfinished entry, scrap this before going back
                if (currentStageNumber === getKeyIndex('category') &&
                        !isEditFromOverview &&
                        formikRef.current?.values?.lettingStatementLines &&
                        formikRef.current?.values?.lettingStatementLines?.length > 0)
                {
                    let editedFormikData = {...formikRef.current?.values};
                    editedFormikData?.lettingStatementLines?.pop();
                    formikRef.current.setValues(editedFormikData);
                    removeLineStages(false);
                }

                setCurrentStageNumber(prev => prev - 1)
            } else if (index !== undefined) {
                let checkStageNumber = currentStageNumber - 1;
                while (checkStageNumber > maxStaticStage) {
                    const checkKey = stageDetails[checkStageNumber].Key;
                    if (linesStageFlow?.includes(checkKey)) {
                        setCurrentStageNumber(checkStageNumber);
                        break;
                    } else {
                        checkStageNumber--;
                    }
                }

                if (checkStageNumber === maxStaticStage)
                    setCurrentStageNumber(maxStaticStage);
            } else {
                if (currentStageNumber === getKeyIndex('overview'))
                    setCurrentStageNumber(getKeyIndex('linesIntro'));

                if (currentStageNumber === getKeyIndex('submit')) {
                    setCurrentStageNumber(getKeyIndex('overview'));
                    setErrorMessage('');
                }
            }
        }
    }

    const handleNextLinestage = () =>{
        const currentKey = stageDetails[currentStageNumber].Key;
        if (index !== undefined){
            if (linesStageFlow && linesStageFlow.includes(currentKey)){

                const stage = linesStageFlow[linesStageFlow.indexOf(currentKey)+1]
                
                if (stage === "isAsset" &&
                    selectedCategory?.name === "Other goods" &&
                    formikRef.current?.values.lettingStatementLines?.[index].amountExVAT &&
                    formikRef.current?.values.lettingStatementLines[index].amountExVAT < 200) {

                    setCurrentStageNumber(prev =>{
                        return stageDetails.findIndex(x => x.Key === linesStageFlow[linesStageFlow.indexOf(currentKey) + 1])
    
                    })
                }
                else{
                    setCurrentStageNumber(prev =>{
                        return stageDetails.findIndex(x => x.Key === stage)
    
                    })
                }
            } else {
                setCurrentStageNumber(prev => {
                    return prev + 1
                })
            }
        }
    }

    const handleAddNewAgent = () => {
        setAgentDisable(true)
        addNewAgentOption(
            { name: newAgentName },
            ({ id }) => {
                formikRef.current?.setFieldValue('lettingAgentId', id);
                setNewAgentName(undefined);
                setShowNewAgentPage(false);
                nextStage();
            }
        );   
    };

    const handlePropertySelection =(selectedValue: string) =>{
        if (selectedValue === "newProp"){
            setPropertiesDisable(false)
            setShowNewPropertyPage(true);
        }
        else {
            resetPropertySelectionForLines(selectedValue === "multipleProperties" ? undefined : parseInt(selectedValue))
            nextStage();
        }
    }

    const createProperty = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            setPropertiesDisable(true);

            if (propertiesAPI && newPropertyName) {
                propertiesAPI.createMinimalProperty(companyContext.cid, newPropertyName)
                    .then((res) => {
                        setNewPropertyName(undefined);
                        const propertyId = parseInt(res.data);

                        // Ensure setFieldValue is done before proceeding
                        const setFieldValuePromise = new Promise<void>((resolveSetField) => {
                            if (index !== undefined) {
                                formikRef.current?.setFieldValue(`lettingStatementLines[${index}].propertyId`, propertyId);
                                // Ensure it finishes using requestAnimationFrame
                                requestAnimationFrame(() => {
                                    resolveSetField(); // Mark the completion
                                });
                            } else {
                                setSelectedPropertyId(propertyId);
                                resolveSetField(); // Immediately resolve if no index
                            }
                        });

                        // Return the promise to properly chain it
                        return setFieldValuePromise;
                    })
                    .then(() => {
                        // Proceed to the next API call after setFieldValue completes
                        return propertiesAPI.index(companyContext.cid);
                    })
                    .then((response) => {
                        setProperties(response.data);
                        resolve(); // Resolve the main promise here
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error); // Ensure the error is propagated
                    });
            } else {
                reject(new Error("Missing propertiesAPI or newPropertyName"));
            }
        });
    };

    const handleNewPropCreate = () =>{
        createProperty()
        .then(() =>{
            setShowNewPropertyPage(false);
            if (index !== undefined)
                handleNextLinestage()
            else
                setCurrentStageNumber(prev => prev + 1)
        })
        .catch((error) =>{
            setShowNewPropertyPage(false);
        })
    }

    const resetPropertySelectionForLines = (propertyId? : number) =>{
        if(formikRef.current?.values?.lettingStatementLines && formikRef.current?.values?.lettingStatementLines?.length > 0 && propertyId) {

            let editedFormikData = {...formikRef.current?.values, lettingStatementLines: [...formikRef.current?.values.lettingStatementLines]}
            editedFormikData.lettingStatementLines.forEach((lettingLine) =>{
                lettingLine.propertyId = propertyId
            })
            formikRef.current.setValues(editedFormikData)
        }
        setSelectedPropertyId(propertyId)
    }

    const categorySelection = async (id: number) =>{
        const category = accounts?.find((account) => account.accountId === id)
        if (index !== undefined && formikRef.current?.values.lettingStatementLines && category) {
            setSelectedCategory(category)
            addLineStages(category)
            await formikRef.current?.setFieldValue(`lettingStatementLines[${index}].accountID`, id)
            if (!isEditFromOverview)
                await formikRef.current?.setFieldValue(`lettingStatementLines[${index}].description`, category.name)
        }      
        setShowCategoryBrowser(false);
        nextStage()
    }

    const handleAssetCreation = () => {
        // TODO: this should be coming from backend - isEligibleForAssets
        const eligibleForAssetCategories = ["Appliances & electronics" , "Other goods", "Furniture & furnishings", "Phone & computer equipment"]
        if( index !== undefined 
            && selectedCategory 
            && formikRef.current?.values.lettingStatementLines?.[index].amountExVAT 
            && eligibleForAssetCategories.includes(selectedCategory.name || "") 
            && formikRef.current?.values.lettingStatementLines[index].amountExVAT >= 200
            && !formikRef.current?.values.lettingStatementLines[index].isRefund
        ){  
            formikRef.current?.setFieldValue(`lettingStatementLines[${index}].createAsset`, selectedCategory.name !== "Other goods")

            // Don't autofill asset names
            // Don't clear this on edit
            if(!isEditFromOverview){
                formikRef.current?.setFieldValue(`lettingStatementLines[${index}].description`, '')
            }

            if(selectedCategory.name === "Other goods"){
                if (!linesStageFlow?.includes('isAsset')) {
                    const newStages = linesStageFlow ? [...linesStageFlow] : [];
                    newStages.splice(newStages.length - 1, 0, ...['isAsset', 'dateRange']);
                    setLinesStageFlow(newStages);
                }
                setCurrentStageNumber(getKeyIndex(selectedPropertyId ? "isAsset": "lineProperty"))
            } 
            else{

                // Add date range to the line stages array since date is mandatory
                // we could remove if status changes but not a big deal since it will just become optional
                if (!linesStageFlow?.includes('dateRange')) {
                    const newStages = linesStageFlow ? [...linesStageFlow] : [];
                    newStages.splice(newStages.length - 1, 0, 'dateRange');
                    setLinesStageFlow(newStages);
                }
                handleNextLinestage()
            }   
        } else {
            formikRef.current?.setFieldValue(`lettingStatementLines[${index}].createAsset`, false)

            if(selectedCategory && selectedCategory.showPaymentCoversDateRange === "No"){
                formikRef.current?.setFieldValue(`lettingStatementLines[${index}].applicablePeriodStart`, undefined)
                formikRef.current?.setFieldValue(`lettingStatementLines[${index}].applicablePeriodEnd`, undefined)
            }
            
            // if the condition doesn't met the criteria, remove the isasset stage
            if (linesStageFlow?.includes('isAsset')) {
                const newStages = linesStageFlow ? [...linesStageFlow] : [];
                const tempIndex = newStages.findIndex(x => x === "isAsset")
                newStages.splice(tempIndex , 2);
                setLinesStageFlow(newStages);
                setCurrentStageNumber(getKeyIndex(newStages[selectedPropertyId ? tempIndex : tempIndex-1]))
            }
            else{
                handleNextLinestage()
            }
        }
    }

    // If this is not our first line entry jump to overview page first
    const GotoNewOrOverview = () => {
        if (currentStageNumber === getKeyIndex('linesIntro') &&
            formikRef.current?.values.lettingStatementLines &&
            formikRef.current?.values.lettingStatementLines.length > 0)
            setCurrentStageNumber(getKeyIndex('overview'));
        else 
            AddNewItems(false, false)
    }

    const AddNewItems = (isSpliting: boolean, isEditing: boolean) =>{
        setFormikNewErrors(undefined)
        setIsSplitFromOverview(isSpliting)
        setIsEditFromOverview(isEditing)
        addLettingStatementLine()
        setCurrentStageNumber(stageDetails.findIndex(x => x.Key === "category"))
        setErrorMessage('');
    }

    const editLettingStatementLine = (idx: number) =>{
        setErrorMessage('');
        setFormikNewErrors(undefined)
        const accountID = formikRef.current?.values.lettingStatementLines?.[idx].accountID;
        
        if(accounts && accountID ){
            setIsEditFromOverview(true)
            setIsSplitFromOverview(false)
            setIndex(idx)
            
            setCurrentStageNumber(stageDetails.findIndex(x => x.Key === "category"))
        }
    }

    const cancelBtn = () => {
        if(isSplitFromOverview || isEditFromOverview) {
            return (
                <Button 
                    marginTop 
                    variant="primary" 
                    outline 
                    onClick={isSplitFromOverview 
                                ? handleCancelSplit 
                                : removeLineStages
                            }
                >
                    {isSplitFromOverview ? "Cancel entry" : "Cancel edit"}
                </Button>
            );
        }
        return null;
    }

    const handleCancelSplit = () =>{
        if (formikRef.current?.values.lettingStatementLines && formikRef.current.values.lettingStatementLines.length > 1 && index) {            
            let editedFormikData = {...formikRef.current?.values, lettingStatementLines: [...formikRef.current?.values.lettingStatementLines]}
            editedFormikData.lettingStatementLines.splice(index, 1)

            formikRef.current.setValues(editedFormikData)
            removeLineStages()
        }
    }

    const removeLineStages = (goToOverview = true) =>{
        setIndex(undefined)
        setBreadcrumbFlow(undefined)
        setSelectedCategory(undefined)

        if (goToOverview)
            setCurrentStageNumber(getKeyIndex('overview'));
    }

    const addLineStages = (catgeory: Category|undefined ) =>{
        if(catgeory){
            const stages: string[] = ["category", "amount"]
            if(!selectedPropertyId){
                stages.push("lineProperty")
            }

            if (catgeory.showIsReplacementItem !== "No"){
                stages.push( "isReplacement")
            }

            if (catgeory.showIncreasesValue !== "No"){
                stages.push( "isValueIncrease")
            }

            if (catgeory.showPaymentCoversDateRange !== "No"){
                stages.push("isDateCoverage")
            }

            stages.push("lineDescription")
            setLinesStageFlow([...stages])

        }
    }

    const addLettingStatementLine = (accountId?: number |undefined) => {
        if(formikRef.current && accounts){
            formikRef.current.values.lettingStatementLines?.push({
				description: accountId ? accounts.find((account) => account.accountId === accountId)?.name : undefined,
				amountExVAT: 0,
				vatCode: 'E',
				accountID: accountId ? accountId : 0,
				date: asYearMonthDay(new Date()),
				isReplacementItem: false,
				increasesPropertyValue: false,
				id: 0,
				lineNumber: formikRef.current.values.lettingStatementLines?.length || 0,
				propertyId: selectedPropertyId,
				createAsset: false
			})

            if(formikRef.current.values.lettingStatementLines){
                setIndex(formikRef.current.values.lettingStatementLines.length -1)
            }
            
        } 
    };

    const removeRecordAtIndex = (idx: number) => {
        if (formikRef.current?.values.lettingStatementLines && formikRef.current.values.lettingStatementLines.length > 1) {            
            let editedFormikData = {...formikRef.current?.values, lettingStatementLines: [...formikRef.current?.values.lettingStatementLines]}
            editedFormikData.lettingStatementLines.splice(idx, 1)

            formikRef.current.setValues(editedFormikData)
            setIndex(undefined)
        }
    }

    const showDeleteDialog = (index: number) => {
        showModalDialog(
            'Remove pending record?',
            `Are you sure you want to remove this record?`,
            [
                <Button variant="danger" label="Yes" onClick={() => removeRecordAtIndex(index)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const onFilesDropped = (acceptedFiles:File[], fileRejections:FileRejection[]) => {
        if(acceptedFiles.length > 0) {
            setFilesToUpload(acceptedFiles);
        }
        if(fileRejections.length > 0){
            if(fileRejections.filter(x => x.errors.filter(y => y.code === "too-many-files").length > 0).length > 0) {
                setErrorMessage(`Please select one file`);
            }
            else if(fileRejections.length === 1) {
                setErrorMessage(`This file type is not supported and has been ignored`);
            } 
            else {
                setErrorMessage(`${fileRejections.length} file types are not supported, these have been ignored`);
            }
        }
    };

    const removePendingFile = (fileName:string) => {
        let newRecords = filesToUpload ? [...filesToUpload] : null
        newRecords && newRecords.splice(newRecords.findIndex(x => x.name === fileName), 1)
        setFilesToUpload(newRecords)
    }

    const onOverviewContinue = () =>{
        if(formikRef.current?.values){
            onValidate(formikRef.current?.values)
            .then((res) => {
                if(res.lettingStatementLines){
                    setFormikNewErrors(res)
                }
                else{
                    nextStage()
                }
            })
        }
    }

    const isExpense = () => {
        if (accounts === undefined ||
            index === undefined ||
            formikRef.current?.values.lettingStatementLines === undefined ||
            formikRef.current?.values.lettingStatementLines?.length === 0
        ) {
            return undefined
        }

        const accountId = formikRef.current?.values.lettingStatementLines[index].accountID;
        const account = accounts.find(x => x.accountId === accountId)

        if (account?.type === "expense" )
            return true
        else 
            return false
    }

    const currentStage = stageDetails[currentStageNumber];
    const sequenceOverride = showNewAgentPage || showNewPropertyPage;

    return (
        <Fullscreen
            isOpen={true}
            onClose={hide}
            pillText={currentStage.ShowHeader
                        ? isIncoming ? 'Incoming' : 'Outgoing'
                        : undefined
                    }
            pillColor={isIncoming ? '#fff' : '#fff'}
            pillBackgroundColor={isIncoming ? 'var(--incomingTxnColor)' : 'var(--brand-secondary-color)'}
            pillIcon={isIncoming ? <Icon name='incoming' color='#fff' size='small' /> : <Icon name='outgoing' color='#fff' size='small' />}
            date={ currentStage.ShowHeader
                    ? formatNaturalLanguageDate(initialValues.date)
                    : undefined
                }
            headerTitle={currentStage.ShowHeader && initialValues.transactionDescription
                            ? initialValues.transactionDescription
                            : ""
                        }
            subTitle={ currentStage.ShowHeader
                        ? `Payment to ${isIncoming ? "you" : "letting agent"}: ${formatCurrency(Math.abs(initialValues.netPaymentToLandlord))}`
                        : undefined
                    }
            contentTitle={currentStage.Title}
            contentSubtitle={currentStage.Subtitle}
            onBack = {(currentStage.Key !== "intro" && !(currentStage.Key === "category" && (isEditFromOverview || isSplitFromOverview))) 
                        ? goback 
                        : undefined
                    }
            breadcrumbs={currentStage.ShowBreadcrumb ? breadcrumbFlow : undefined}
            brandIcon = {!currentStage.ShowHeader}
        >
            <BrandWrapper>
                <Formik
                    initialValues={{ ...initialValues }}
                    enableReinitialize
                    // Hitting enter on category search can prematurely submit the form...
                    onSubmit={currentStage.Key === 'submit' ? onSubmit : () => {}}
                    validateOnChange={false}
                    innerRef={formikRef}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                                { !sequenceOverride && currentStage.Key === 'intro' && (
                                    <>
                                        <div >
                                            <p>
                                                During bookkeeping, you’ve marked a transaction as income from a letting agent. Let’s categorise it in more detail.
                                            </p>

                                            <p>
                                                On the next page, you’ll see the payment details. Find the matching statement, and we’ll guide you through recording the transactions.
                                            </p>
                                        </div>	
                                        <Button 
                                            marginTop
                                            variant='primary' 
                                            thin  
                                            onClick={nextStage}
                                        >
                                            Let's go <Icon className="m-0" name='arrowNext'/>
                                        </Button>
                                    </>
								)}

                                { !sequenceOverride && currentStage.Key === 'linesIntro' && 
                                    <>
                                        <p>
                                            Great. Now that we have that information we can begin to breakdown the statement in more detail, line by line.
                                        </p>
                                        <p>
                                            We'll start by categorising the first payment on your letting statement, 
                                            whether it's a payment made to you or an expense for goods or services provided through your agent.
                                            From there, we'll move on to the other items on the statement.
                                        </p>
                                        

                                        <Button 
                                            marginTop
                                            variant='primary' 
                                            thin  
                                            onClick={() => {GotoNewOrOverview()}}
                                        >
                                            Continue <Icon className="m-0" name='arrowNext'/>
                                        </Button>
                                    </>	
								}

                                { !sequenceOverride && currentStage.Key === 'agent' && 
                                    <OptionSelector preventSaveState onChange={(opt) => {
                                            if(opt[0].value === "newAgent"){
                                                setShowNewAgentPage(true);
                                            }
                                            else
                                            {
                                                setFieldValue("lettingAgentID", parseInt(opt[0].value))
                                                nextStage();
                                            }                                        
                                        }}
                                        options={
                                            [
                                                {singleEntry: true, list: [
                                                    ...agents.map(c => {return {label:( c.name || ""), value: (c.id.toString() || "")}})
                                                ]},
                                                {singleEntry: true, list:
                                                    [
                                                        {label: 'A new agent not listed here', value: "newAgent"},
                                                    ]
                                                }
                                            ]
                                        }
                                    />
                                }

                                { showNewAgentPage && 
                                    <>
                                        <div>
                                            <label className="text-muted">
                                                Letting agent's name
                                            </label>
                                            <input 
                                                autoFocus 
                                                className={classNames("form-control", styles.newInput)} 
                                                type="text" 
                                                value={newAgentName} 
                                                onChange={e => setNewAgentName(e.target.value)}
                                                
                                            />
                                        </div>

                                        <Button
                                            marginTop
                                            disabled={!newAgentName || agentDisable}
                                            variant='primary' 
                                            thin  
                                            onClick={handleAddNewAgent}
                                        >
                                            Continue <Icon name='arrowNext'/>
                                        </Button>
                                    </>
                                }

                                { !sequenceOverride && currentStage.Key === "statementNumber" && 
                                    <>
                                        <FormikField<LettingStatementPostModel>
                                            className={styles.formikTextField}
                                            label="Reference"
                                            name="statementNumber"
                                            type="text"
                                        />

                                        <Button
                                            marginTop
                                            variant='primary' 
                                            thin  
                                            onClick={nextStage}
                                        >
                                            Continue <Icon name='arrowNext'/>
                                        </Button>
                                    </>
                                }

                                { !sequenceOverride && currentStage.Key === "property" && properties &&
                                    <>
                                        <OptionSelector 
                                        preventSaveState 
                                        onChange={(opt) => handlePropertySelection(opt[0].value)}
                                        options={
                                            [
                                                {singleEntry: true, list: [
                                                    ...properties?.map((c) => {return {label: c.name, value: c.id.toString() || ""}})
                                                ]},
                                                {singleEntry: true, list:
                                                    [
                                                        {label: 'More than one property', value: "multipleProperties"},
                                                        {label: 'A new property not listed here', value: "newProp"},
                                                    ]
                                                }
                                            ]
                                        }
                                        />
                                    </>
                                }

                                { showNewPropertyPage &&
                                    <>
                                        <div>
                                            <input
                                                autoFocus
                                                className={classNames("form-control", styles.newInput)}
                                                type="text"
                                                value={newPropertyName}
                                                onChange={e => setNewPropertyName(e.target.value)}
                                            />
                                        </div>
                                        <Button 
                                            disabled={!newPropertyName || propertiesDisable}
                                            variant='primary' 
                                            thin  
                                            onClick={handleNewPropCreate}
                                            marginTop
                                            >
                                                Continue <Icon name='arrowNext'/>
                                        </Button>
                                    </>
                                }

                                { !sequenceOverride && currentStage.Key === "category" && assets &&  
                                    <>
                                        <CategorySelectorSearch
                                            outgoing={!isIncoming}
                                            isSplitting={isSplitFromOverview}
                                            selectedBPType={values.lettingStatementLines && index !== undefined 
                                                                ? accounts?.find(x => x.accountId === (values.lettingStatementLines?.[index].accountID || 0))?.bpProcessorType
                                                                : undefined
                                                            }
                                            setSpecialCategoryFields={()=>{}}
                                            categories={accounts}
                                            bankAccountId={initialValues.bankAccountID}
                                            addToSplit={() =>{}}
                                            currentAccIsDLA={false}
                                            currentAccIsMortgage={false}
                                            assets={assets} 
                                            setSelectedAccount={categorySelection} 
                                            showBuildCategory ={true}
                                            selectedAccountId={values.lettingStatementLines && index !== undefined ? values.lettingStatementLines[index].accountID : undefined}
                                            showAllCategories={true}
                                            showDLA={false}
                                            showMortgageAccounts={false}
                                        />
                                            
                                        {cancelBtn()}

                                        {index !== undefined && formikRef.current?.values.lettingStatementLines && formikRef.current?.values.lettingStatementLines[index].accountID !== 0 &&
                                            <>
                                                <Button 
                                                    variant='primary' 
                                                    thin  
                                                    onClick={() => categorySelection(formikRef.current?.values.lettingStatementLines?.[index].accountID || 0)}
                                                    marginTop
                                                    >
                                                        Continue <Icon name='arrowNext'/>
                                                </Button>
                                            </>

                                        }

                                        <p 
                                            className={styles.CategorylinkText} 
                                            onClick={() => setShowCategoryBrowser(true)} 
                                        >
                                            <Icon name="threeColumns"/>Explore categories
                                        </p>
                                        
                                    </>
                                }

                                {formikRef.current?.values && formikRef.current?.values.lettingStatementLines &&
                                    <>
                                        { !sequenceOverride && currentStage.Key === "amount"  &&
                                            <>
                                                <FormikField<LettingStatementLine>
                                                    className={styles.formikNumberField}
                                                    label="Amount"
                                                    name= {`lettingStatementLines[${index}].amountExVAT` as "amountExVAT"}
                                                    type="number"
                                                    onlyPositiveNumbers
                                                    min="0"
                                                />

                                                { isExpense() && (
                                                    <div className="mt-4 mb-4">
                                                        <div className="v8CheckBoxContainer" style={{width: '250px'}}>
                                                            <Field name={`lettingStatementLines[${index}].isRefund` as "isRefund"}>
                                                                {({field}: FieldProps) => (
                                                                    <>
                                                                        <ReactForm.Check 
                                                                            id={`lettingStatementLines[${index}].isRefund` as "isRefund"}
                                                                            {...field}
                                                                            checked={values.lettingStatementLines?.[index || 0].isRefund || false}
                                                                            className="d-inline-block"
                                                                        />
                                                                        <label
                                                                            className="m-0 p-0 ml-2"
                                                                            htmlFor={`lettingStatementLines[${index}].isRefund` as "isRefund"}
                                                                        >
                                                                            This entry is for a refund
                                                                        </label>
                                                                    </>
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </div>
                                                )}

                                                <Button 
                                                    disabled={!(values.lettingStatementLines && index !== undefined && values.lettingStatementLines[index].amountExVAT)}
                                                    variant='primary' 
                                                    thin  
                                                    onClick={handleAssetCreation}
                                                    marginTop
                                                >
                                                        Continue <Icon name='arrowNext'/>
                                                </Button>
                                            </>
                                        }

                                        { !sequenceOverride && currentStage.Key === "lineProperty" && properties &&
                                            <>
                                                <OptionSelector preventSaveState onChange={async(opt) => {
                                                    if(opt[0].value === "newLineProperty"){
                                                        setPropertiesDisable(false)
                                                        setShowNewPropertyPage(true)
                                                    }
                                                    else 
                                                    {
                                                        await setFieldValue(`lettingStatementLines[${index}].propertyId`, parseInt(opt[0].value))
                                                        handleNextLinestage()
                                                    }                                        
                                                }}
                                                options={
                                                    [
                                                        {singleEntry: true, list: [
                                                            ...properties?.map((c) => {return {label: c.name, value: c.id.toString() || ""}})
                                                        ]},
                                                        {singleEntry: true, list:
                                                            [
                                                                {label: 'A new property not listed here', value: "newLineProperty"},
                                                            ]
                                                        }
                                                    ]
                                                }
                                                />
                                            </>
                                        }

                                        { !sequenceOverride && currentStage.Key === "isReplacement"  &&
                                                <OptionSelector onChange={async(opt) => {
                                                        await setFieldValue(`lettingStatementLines[${index}].isReplacementItem`,  opt[0].value === "true" ? true : false);
                                                        handleNextLinestage();
                                                    }}
                                                    options={{singleEntry: true, list: [
                                                        {label: `Yes`, value: "true"}, 
                                                        {label: `No`, value: "false"}
                                                    ]}}
                                                    preventSaveState
                                                />
                                        }   

                                        { !sequenceOverride && currentStage.Key === "isValueIncrease"  &&
                                                <OptionSelector onChange={async (opt) => {
                                                        await setFieldValue(`lettingStatementLines[${index}].increasesPropertyValue`,  opt[0].value === "true" ? true : false);
                                                        handleNextLinestage()
                                                    }}
                                                    options={{singleEntry: true, list: [
                                                        {label: `Yes`, value: "true"}, 
                                                        {label: `No`, value: "false"}
                                                    ]}}
                                                    preventSaveState
                                                />
                                        }
                                        { !sequenceOverride && currentStage.Key === "isAsset"  &&
                                                <OptionSelector onChange={async (opt) => {
                                                        await setFieldValue(`lettingStatementLines[${index}].createAsset`,  opt[0].value === "true" ? true : false);
                                                        handleNextLinestage()
                                                    }}
                                                    options={{singleEntry: true, list: [
                                                        {label: `Yes`, value: "true"}, 
                                                        {label: `No`, value: "false"}
                                                    ]}}
                                                    preventSaveState
                                                />
                                        }              

                                        { !sequenceOverride && currentStage.Key === "isDateCoverage"  &&
                                                <OptionSelector onChange={(opt) => {
                                                        if(opt[0].value === "true"){
                                                            nextStage() 
                                                        }
                                                        else
                                                        {
                                                            setFieldValue(`lettingStatementLines[${index}].applicablePeriodStart`, undefined)
                                                            setFieldValue(`lettingStatementLines[${index}].applicablePeriodEnd`, undefined)
                                                            handleNextLinestage()
                                                        }                                                          
                                                    }}
                                                    options={{singleEntry: true, list: [
                                                        {label: `Yes`, value: "true"}, 
                                                        {label: `No`, value: "false"}
                                                    ]}}
                                                    preventSaveState
                                                />
                                        }

                                        { !sequenceOverride && currentStage.Key === "dateRange"  &&
                                            <>
                                                <div className={styles.dateRange}>
                                                                    
                                                    <FormikField<LettingStatementLine>
                                                        className={styles.formikFieldsDate}
                                                        label="Start date"
                                                        name={`lettingStatementLines[${index}].applicablePeriodStart` as "applicablePeriodStart"}
                                                        type="date"
                                                    />

                                                    
                                                    <FormikField<LettingStatementLine>
                                                        className={classNames(styles.formikFieldsDate, "mb-0")}
                                                        label="End date"
                                                        name={`lettingStatementLines[${index}].applicablePeriodEnd` as "applicablePeriodEnd"}
                                                        type="date"
                                                        
                                                    /> 
                           
                                                </div>

                                                <Button
                                                    disabled ={
                                                        index !== undefined &&
                                                        values.lettingStatementLines?.[index].createAsset &&
                                                        !values.lettingStatementLines?.[index].applicablePeriodStart
                                                    }
                                                    marginTop
                                                    variant='primary' 
                                                    thin  
                                                    onClick={handleNextLinestage}
                                                >
                                                    Continue <Icon name='arrowNext'/>
                                                </Button>
                                            </>
                                        }

                                        { !sequenceOverride && currentStage.Key === "lineDescription"  &&
                                            <>
                                                <FormikField<LettingStatementLine>
                                                    className={styles.formikTextField}
                                                    label=  {index !== undefined && values.lettingStatementLines?.[index].createAsset 
                                                                ? "Asset name"
                                                                : "Description"
                                                            }
                                                    name= {`lettingStatementLines[${index}].description` as "description"}
                                                    type="text"
                                                />

                                                <Button
                                                    disabled = {!(index !== undefined && values.lettingStatementLines?.[index].description)}
                                                    marginTop
                                                    variant='primary' 
                                                    thin  
                                                    onClick={removeLineStages}
                                                >
                                                    Continue <Icon name='arrowNext'/>
                                                </Button>
                                            </>
                                        }
                                                
                                    </>

                                } 
                                        
                                { !sequenceOverride && currentStage.Key === "overview" && assets &&
                                    <>
                                        <Overview
                                            lines={values.lettingStatementLines || undefined}
                                            properties={properties}
                                            categories={accounts}
                                            txnAmount={values.netPaymentToLandlord}
                                            onAddNewItem={() =>AddNewItems(true, false)}
                                            onContinue={onOverviewContinue}
                                            canSplit={true}
                                            editRecord={editLettingStatementLine}
                                            deleteRecord={showDeleteDialog}
                                            formikNewErrors={formikNewErrors}
                                        />
                                    </>
                                }

                                { !sequenceOverride && currentStage.Key === "submit" &&
                                    <>
                                        <Attachments
                                            title='Add attachment'
                                            accept={['image/*','.pdf']}
                                            onDrop={onFilesDropped}
                                            message='Upload your letting statement to finish'
                                            attachedFiles={filesToUpload}
                                            onRemoveFile={removePendingFile}
                                            singleSelect={true}
                                        />

                                        { errorMessage && (
                                            <Alert variant='danger' className="mt-3">
                                                <p>
                                                    { errorMessage }
                                                </p>
                                                { errorMessage.includes("save anyway") && (
                                                    <Button 
                                                        marginTop 
                                                        variant="primary" 
                                                        onClick={forceSave}
                                                    >
                                                        Save anyway
                                                    </Button>
                                                )}
                                            </Alert>
                                        )}

                                        <span style={{marginTop: '20px', display: 'block'}}>
                                            <Submit 
                                                alignLeft 
                                                disabled={
                                                    (errorMessage && errorMessage.includes('save anyway')) || 
                                                    isSaving ||
                                                    !(filesToUpload && filesToUpload?.length > 0)
                                                } 
                                                showCancel={false}
                                            />
                                        </span>
                                    </>
                                }

                                { showCategoryBrowser && 
                                    <CategorySelectorBrowser
                                        bookkeeping={false}
                                        isSplitting={false}
                                        setSelectedAccount={categorySelection}
                                        setSpecialCategoryFields={() => {}}
                                        categories={accounts}
                                        bankAccountId={0}
                                        hideModal={() => setShowCategoryBrowser(false)} 
                                        assets={[]}
                                    />
                                }

                                {modalDialog}

                        </Form>       
                    )}
                </Formik>
            </BrandWrapper>
        </Fullscreen>
    );
}

export default CreateLettingStatementForm