import { useContext, useEffect, useRef, useState } from "react";
import { Form, Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import { AccountWithBalance, Adjustment, AdjustmentHeader } from "../../../api/inni/data-contracts";
import { asHtml5Date, asYearMonthDay } from "../../../utils/formatDate";
import { useInniAPI } from "../../../hooks/useInniAPI";
import { Adjustments } from "../../../api/inni/Adjustments";
import { Accounts } from "../../../api/inni/Accounts";
import CompanyContext, { CompanyProduct } from "../../../context/CompanyContext";
import { Button } from "../../../elements/Button/Button";
import Icon from "../../../elements/Icon/Icon";
import FormikField from "../../../elements/FormikField/FormikField";
import styles from '../Adjustments.module.scss'
import { AdjustmentsTable } from "../AdjustmentsTable";
import FullScreen from "../../../components/Fullscreen/Fullscreen";
import { BrandWrapper } from "../../../components/BrandWrapper/BrandWrapper";

interface CreateAdjustmentProps{
    isOpen: boolean;
    hide: ()=>void;
}

export default function CreateAdjustment({isOpen, hide} : CreateAdjustmentProps){

    type STAGE_NAMES = "intro"|"description"|"date"|"submit"

    const [stageFlow, setStageFlow] = useState<STAGE_NAMES[]>()
    const [currentStage, setCurrentStage] = useState<STAGE_NAMES>();

    const adjustmentsApi = useInniAPI(Adjustments, [400])
    const accountsApi = useInniAPI(Accounts)
    const [adjustments, setAdjustments] = useState<Adjustment[]>([]);
    const [accounts, setAccounts] = useState<AccountWithBalance[]>([]);
    const [adjustmentsLoaded, setAdjustmentsLoaded] = useState(false);    
    const [adjustmentsUpdated, setAdjustmentsUpdated] = useState(false);
    const [lineFeedback, setLineFeedback] = useState<string|null|undefined>();
    const companyContext = useContext(CompanyContext)
    const formikRef = useRef<FormikProps<AdjustmentHeader>>(null);
    
    useEffect(() => {
        if(stageFlow === undefined) {
            let stages:STAGE_NAMES[] = ["intro", "description", "date", "submit"];
            setStageFlow(stages)
            setCurrentStage("intro")
        }
    }, [stageFlow])

    

    useEffect(()=>{
        if(accountsApi){
            const today = asHtml5Date(asYearMonthDay(new Date())) || ""
            setAdjustments([]);
            setAdjustmentsLoaded(true);
            accountsApi.listWithBalances(companyContext.cid, {date: today}).then(response => {
                let tempAccounts = response.data
                setAccounts(tempAccounts.filter(x => !x.isOnBankFeed));
            }).catch(e => {
                console.error(e)
            })
        }
    },[accountsApi, companyContext.cid, companyContext.product])


    const Create = (values: AdjustmentHeader, actions: FormikHelpers<AdjustmentHeader>)=> {
        if(adjustmentsApi){
            values.lines= adjustments
            adjustmentsApi.create(companyContext.cid, values)
            .then(res => {
                if(res.status !== 201)
                    console.warn("Unexpected response code " + res.status)
                else if(res.status === 201)
                    hide();
            })
            .catch(error => {
                setLineFeedback(error.error.lines)
                actions.setSubmitting(false);
                console.error(error)
            })
        }
    }

    const validateCreate =  (value: Adjustment) : Promise<FormikErrors<Adjustment>> => {
        return new Promise((resolve, reject) => { resolve({}) })
    }

    const validateUpdate =  (id:number, value: Adjustment) : Promise<FormikErrors<Adjustment>> => {
        return new Promise((resolve, reject) => { resolve({}) })
    }

    const updateAdjustment = (value: Adjustment, actions: FormikHelpers<Adjustment>, id: number) : Promise<boolean>=> {
        return new Promise((resolve, reject) => {
            if(adjustments) {
                !adjustmentsUpdated && setAdjustmentsUpdated(true)
                const newAdjustments = [...adjustments.map(adj => adj.id === value.id ? 
                    {...value, accountId: parseInt(value.accountId.toString()), date: formikRef.current?.values.date, openingBalance: getOpeningBalance(value),
                        closingBalance: getClosingBalance(value), description:formikRef.current?.values.description, journalHeaderId: -1} : adj)]
                //TODO balances
                 validateAndUpdateLines(newAdjustments)
            }
            resolve(true)
        })
    }

    const addNewAdjustment = (value: Adjustment, actions: FormikHelpers<Adjustment>) : Promise<number>=> {
        return new Promise((resolve, reject) => {
            if(adjustments) {
                !adjustmentsUpdated && setAdjustmentsUpdated(true)
                const tempId = -Math.abs(adjustments.length) //We use a negative number so that we can identify and edit new rows before sending them
                const newAdjustments = [...adjustments, 
                    {...value, accountId: parseInt(value.accountId.toString()), date: formikRef.current?.values.date, openingBalance: getOpeningBalance(value),
                        closingBalance: getClosingBalance(value), description: formikRef.current?.values.description, id: tempId, journalHeaderId: -1}] //-1 new journalheader
                //TODO balances
                validateAndUpdateLines(newAdjustments)
            }
            resolve(1)
        })
    }

    const removeAdjustment = (id: number) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if(adjustments) {
                !adjustmentsUpdated && setAdjustmentsUpdated(true)
                let newRows = [...adjustments]
                newRows.splice(adjustments.findIndex(x => x.id === id), 1)
                //TODO balances
                validateAndUpdateLines(newRows)
            }
            resolve();
        })
    }

    const getOpeningBalance = (adj:Adjustment, newAcc?:AccountWithBalance) => {        
        const relatedAcc = newAcc || accounts && accounts.find(x => x.id === parseInt(adj.accountId.toString()))
        if(relatedAcc && relatedAcc.balance !== undefined) {
            return relatedAcc.balance - (getPolarity(relatedAcc.accountGroup || "") * (adj.amountOriginal || 0))
        }
        
        return 0
    }

    const getClosingBalance = (adj:Adjustment, newAcc?:AccountWithBalance) => {
        const relatedAcc = newAcc || accounts && accounts.find(x => x.id === parseInt(adj.accountId.toString()))
        if(relatedAcc && relatedAcc.balance !== undefined) {
            const amount = (adj.debit || 0) - (adj.credit || 0)
            return getOpeningBalance(adj, relatedAcc) + (getPolarity(relatedAcc.accountGroup || "") * amount)
        }

        return 0
    }

    const validateAndUpdateLines = (newAdjustments:Adjustment[]) => {
        setAdjustments(newAdjustments)

        if(adjustmentsApi) {
            const adjHeader = {date: formikRef.current?.values.date || "", description: formikRef.current?.values.description ||"", journalHeaderId: -1, lines: newAdjustments}
            adjustmentsApi.validateCreate(companyContext.cid, -1, adjHeader).then(res => {
                if(res.status === 200) {
                    lineFeedback && setLineFeedback(null)
                }
            }).catch(error => {
                setLineFeedback(error.error.lines)
            })
            
        }
    }

    const getStageDetails = () => {
        switch(currentStage) {
            case "intro":
                return  {    
                            Title: "Use with care", 
                            Subtitle: "Adjustments are used by your accountant to make changes to the way things have been recorded. E.g. to correct mistakes or change something in light of new information when they prepare your year-end accounts. It is important to handle adjustments with care."
                        }
            case "description":
                return  {    
                            Title:  "Adjustment details", 
                            Subtitle: "Provide a clear description of the adjustment to ensure accurate record-keeping."
                        }
            case "date":
                return  {    
                            Title:  "Adjustment date", 
                            Subtitle: "Specify the date when this adjustment took effect."
                        }
                
            case "submit":
                return  {    
                            Title: "Account & Account details", 
                            Subtitle: "Select the relevant account and enter the debit/credit amount for this adjustment."
                        }
                
            default:
                return {    
                            Title: "Use with care", 
                            Subtitle: "Adjustments are used by your accountant to make changes to the way things have been recorded. E.g. to correct mistakes or change something in light of new information when they prepare your year-end accounts. It is important to handle adjustments with care."
                        }
        }
    }

    const nextStage = () => {
        if(stageFlow && currentStage) { 
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])
        }
    }

    const getPolarity = (accGroup:string) => {
        if(["Liabilities", "Income", "Capital"].includes(accGroup)) {
            return -1
        } else {
            return 1
        }
    }
    
    return(
        <FullScreen
            isOpen={isOpen}
            onClose={hide}
            contentTitle={getStageDetails().Title}
            contentSubtitle={getStageDetails().Subtitle} 
            headerTitle={currentStage === 'submit' ? (formikRef.current?.values.description || "") : ""}
            pillText={currentStage === 'submit' ? 'Adjustment' : undefined}
            pillColor={currentStage === 'submit' ? '#fff' : undefined}
            pillBackgroundColor={currentStage === 'submit' ? 'var(--brand-secondary-color)' : undefined }
            date={currentStage === 'submit' ? formikRef.current?.values.date : undefined}
            onBack={
                stageFlow && currentStage && stageFlow.indexOf(currentStage) > 0 ? 
                () => setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)-1]) : 
                undefined
            }
            brandIcon={currentStage !== 'submit'}
        >
        <BrandWrapper>
            {stageFlow && <Formik
                initialValues={{} as AdjustmentHeader}
                enableReinitialize
                onSubmit={Create}
                innerRef={formikRef}
            >
                {({ isSubmitting, values }) => (
                    <Form>
                        {currentStage === "intro" && <>
                            <Button variant='primary' thin onClick={nextStage} marginTop>Let's go <Icon name='arrowNext'/></Button>
                        </>}

                        {currentStage === "description" && <>
                            <FormikField<AdjustmentHeader>
                                className={styles.formikFields}
                                label="Add description"
                                placeholder="Enter a short description"
                                name="description"
                                type="text"
                                width="60%"
                            />

                            <Button 
                                marginTop
                                disabled={!values.description}
                                variant='primary' 
                                thin  
                                onClick={nextStage}>
                                    Continue <Icon name='arrowNext'/>
                            </Button>
                        </>}
                        {currentStage === "date" && <>
                            <FormikField<AdjustmentHeader>
                                className={styles.formikFieldsAuto}
                                label="Add date"
                                name="date"
                                type="date"
                            />

                            <Button 
                                marginTop
                                disabled={!values.date}
                                variant='primary' 
                                thin  
                                onClick={() =>{
                                    nextStage();
                                }}
                            >
                                Continue <Icon  name='arrowNext'/>
                            </Button>
                        </>}

                        {currentStage === "submit" && <>
                        
                            <AdjustmentsTable 
                                adjustments={adjustments}
                                adjustmentsLoaded={adjustmentsLoaded}
                                accounts={accounts}
                                isReadOnly={false}
                                onCreate={addNewAdjustment}
                                onUpdate={updateAdjustment}
                                onDelete={removeAdjustment}
                                validateCreate={validateCreate} 
                                validateUpdate={validateUpdate}                        
                            />

                            { lineFeedback && (
                                <small style={{display: "block", marginBottom: "1rem", marginTop: "0"}} className="invalid-feedback">{lineFeedback}</small>
                            )}

                            <Button 
                                buttonType='save' 
                                submitButton
                                disabled={isSubmitting}
                                marginTop
                                label="Save adjustment"
                            />
                        </>}
                    </Form>       
                )}
            </Formik>}
        </BrandWrapper>
    </FullScreen>
    )
}