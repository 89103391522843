import { useContext, useEffect, useState } from 'react';
import BrandContext from "../../../../context/BrandContext"
import { Property } from "../../../../api/inni/data-contracts"
import { Category } from "../../../../api/inni/data-contracts"
import styles from './Overview.module.css';
import { formatCurrency } from "../../../../utils/formatNumbers";
import { Button } from '../../../../elements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import SplitSummaryEntry from '../../../../components/SplitSummaryEntry/SplitSummaryEntry';
import { FormikErrors } from 'formik';
import { Alert } from 'react-bootstrap';
import React from 'react';
import { LettingStatementLine } from '../../../../api/inni/data-contracts';
import Pill from '../../../../elements/Pill/Pill';

type TransactionInfoType = {
  idx: number,
  item: LettingStatementLine
}

type SummaryType = {
  [key: string]: {
    [key: string]: TransactionInfoType[]
  }
}

interface OverviewProps {
  lines: LettingStatementLine[] | undefined,
  properties: Property[] | undefined,
  categories: Category[] | undefined,
  txnAmount: number,
  onAddNewItem: () => void,
  onContinue?: () => void,
  canSplit: boolean
  editRecord: (idx: number, fromDelete?:boolean, editFromOverview?: boolean) => void,
  deleteRecord: (idx: number) => void,
  formikNewErrors: FormikErrors<LettingStatementLine> | undefined,
}


const Overview = ({ lines, properties, categories, txnAmount, onAddNewItem, onContinue,
  canSplit, editRecord, deleteRecord, formikNewErrors}: OverviewProps) => {
  const brandContext = useContext(BrandContext);
  const [summary, setSummary] = useState<SummaryType | undefined>();

  const buildCategoryFlow = (accountId: number, isRefund: boolean) => {
    const arr:React.ReactNode[] = []

    if(accountId) {
      const category = categories && categories.find(x => x.accountId === accountId)
      if(category) {
        if(isRefund)  arr.push(<span style= {{color: 'var(--ui-warning)', fontWeight: 'bold'}} >Refund</span>)
        if(category.type) arr.push(category.type.charAt(0).toUpperCase() + category.type.slice(1))
        if(category.name) arr.push(category.name.charAt(0).toUpperCase() + category.name.slice(1))
      }
    }

    return (<>{arr.map((part, index) => 
      <React.Fragment key={index}>{part}{index >= 0 && arr.length > 1 && index < arr.length - 1 && " > "}</React.Fragment>)
    }</>)
  }

  // Group by type
  useEffect(() => {
    if (categories !== undefined  && lines) {
      const summaryData = lines.reduce((prev: SummaryType, item: LettingStatementLine, idx: number) => {
        const txnCategoryId = item.accountID;
        const txnCategory= categories?.find(x => txnCategoryId === x.accountId);
        let txnCategoryType= ((txnCategory?.type?.charAt(0).toUpperCase() ||"") + txnCategory?.type?.slice(1)) || 'No associated category Type';
        
        //if amount is refund it is considered as opposite
        if(item.isRefund){
            txnCategoryType = txnCategoryType === "Income" ? "Expenses" : "Income"
        }

        if (txnCategoryType === "Expense")
          txnCategoryType = "Expenses"
        
        const categoryTypeGroup = prev[txnCategoryType] || {};
        const txnCategoryGroup = categories?.find(x => txnCategoryId === x.accountId)?.name || 'No category';

        if (prev.hasOwnProperty(txnCategoryType)) {
          const updatedCatGroup = categoryTypeGroup[txnCategoryGroup] || [];
          updatedCatGroup.push({ item: item, idx: idx });
          categoryTypeGroup[txnCategoryGroup] = updatedCatGroup;
        } else {
          categoryTypeGroup[txnCategoryGroup] = [{ item: item, idx: idx }];
        }

        prev[txnCategoryType] = categoryTypeGroup;
        return prev;
      }, {});

      setSummary(summaryData);
    }
  }, [lines, brandContext.brand, categories, properties]);

  const getPropertyName = (propId: number) => {
    if(properties) {
      const property = properties?.find(x => x.id?.toString() === propId.toString())
      if(property) {
          return property.name
      }
      return null
    }
    return null
  }

  const isPositiveLine = (line:LettingStatementLine) => {
    if(!categories) return true

    const account = categories.find(x => x.accountId === line.accountID)
    if(!account) return true

    if(account.type === "income" ) {
        return !line.isRefund
    } 
    else {
        return line.isRefund
    }
  }

  const getTotal = (): number | undefined => {
      return lines?.reduce((total, line) => {
          const multiplier = isPositiveLine(line) ? 1 : -1;
          return total + multiplier * (line.amountExVAT || 0);
      }, 0);
  };

  const remaining = txnAmount - (getTotal() || 0);

  return <> {summary && (
    <div className={styles.overviewContainer}>
      {(Object.keys(summary).sort().map((categoryType: string) => {
        let itemCount = 0;
        return (
          <div id={styles.splittingSummary} key={categoryType}>
            <p className={styles.heading}>
              {categoryType.replace('_No associated', 'No associated')}
            </p>
            {
              Object.keys(summary[categoryType]).map((categoryName: string, index) => {
                return summary[categoryType][categoryName].map((description: TransactionInfoType, i: number) => {
                  itemCount++
                  return (
                    <SplitSummaryEntry
                      key={i}
                      item={itemCount}
                      description={description.item.description || ""}
                      note={description.item.propertyId 
                              ? getPropertyName(description.item.propertyId) || 'No associated property' 
                              : 'No associated property'
                            }
                      price={`${categoryType === 'Expenses' ? '-' : ''}${formatCurrency(description.item.amountExVAT)}`}
                      title={categoryName}
                      categoryFlow={buildCategoryFlow(description.item.accountID || 0, !!description.item.isRefund)}
                      editRecord={() => editRecord(description.idx, false, true)}
                      deleteRecord={lines && lines.length > 1 ? () => deleteRecord(description.idx) : undefined}
                      pill={description.item.createAsset
                        ? <Pill text="New asset" backgroundColor='var(--success-green-color)' color='#ffffff' small/>
                        : undefined
                      }
                    />
                  );
                })
              })
            }
          </div>
        );
      }))}
      { canSplit && (
          <div data-cy="addNewitem" className={styles.addItem} onClick={onAddNewItem}>
            <FontAwesomeIcon icon={faCirclePlus} className='mr-2' color='#575C60' size='lg' />Add item
        </div>
      )}

      {/* error messages */}
      { formikNewErrors && Object.values(formikNewErrors).some(value => value !== undefined) && (
          <div style={{marginTop: '1rem'}}>
            <Alert variant='danger'>
                <ul style={{margin:0, paddingLeft: '1rem'}}>
                    {Object.values(formikNewErrors).filter(x => x !== undefined).map((err, index) => <li key={index}>
                      {err}
                    </li>)}
                </ul>
            </Alert>
          </div>
      )}
      <div className={styles.summaryFooter}>
        <div>
          {onContinue && <Button variant='primary' disabled={remaining < 0} onClick={onContinue}>Continue</Button>}
        </div>
        <div className={styles.amountTotals}>
          <div>
            <p>Total</p>
            <p data-cy="totalAmount">{formatCurrency(getTotal() || 0)}</p>
          </div>
          <div>
            <p>Remaining</p>
            <p data-cy="remainingAmount">{formatCurrency(remaining)}</p>
          </div>
          
        </div>
      </div>
    </div>
  )}
  </>
}

export default Overview;
