import styles from './Pill.module.css';

interface PillProps {
    text: string
    color?: string
    backgroundColor?: string
    small?: boolean
    pillIcon?: React.ReactNode
}

const Pill = ({text, backgroundColor, color, small, pillIcon} : PillProps) => {
    return (
        <span style={{backgroundColor: backgroundColor || "var(--bs-grey200)", color: color || "var(--bs-ink200)"}} className={`${styles.tag} ${small ? styles.small : ""}`}>{text}{pillIcon}</span>
    )
}

export default Pill