import { Form, Formik, FormikErrors } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { AdvisoryFuelRateBand, MileagePostModel, MileageType, QuickEntry, Mileage as MileageView } from '../../../api/inni/data-contracts'
import { Text as EditText, Currency, SelectList, ListOption, Switch, DateSelector }  from '../../../elements/EditRow/EditRow'
import { Date as DateRead, Text as TextRead, YesNo } from '../../../elements/DetailRow/DetailRow'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { Mileages } from '../../../api/inni/Mileages'
import CompanyContext from '../../../context/CompanyContext'
import { MileageTypes } from '../../../api/inni/MileageTypes'
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList'
import { AdvisoryFuelRateBands } from '../../../api/inni/AdvisoryFuelRateBands'
import { Button } from '../../../elements/Button/Button'
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder'
import { formatCurrency } from '../../../utils/formatNumbers'
import { EditorCommonProps } from './QEListMobile'
import { useModalDialog } from '../../../hooks/useModalDialog'
import styles from "../QuickEntry.module.css"
import { asYearMonthDay } from '../../../utils/formatDate'

const MileageDetailRowLoading = () => <div style={{marginBottom: '1.5rem'}}><LoadingPlaceholder height="10px" width="20%" /><LoadingPlaceholder height="15px" /></div>

const MileageEditor = ({qe, cancelCb, people, contracts, showAlert, suggestionData} : EditorCommonProps & { suggestionData?: MileageView }) => {
    const mileageAPI = useInniAPI(Mileages, [400])
    const companyContext = useContext(CompanyContext)
    const [initialValues, setInitialValues] = useState<MileagePostModel & { lastPerson: number | undefined }>({ date: asYearMonthDay(new Date()), advisoryFuelRateBand: '', ...suggestionData } as MileagePostModel & { lastPerson: number | undefined })
    const [initialValuesLoaded, setInitialValuesLoaded] = useState(false)

    const [mileageTypes, mileageTypesLoaded] = useFetchEntityList<MileageType, MileageTypes>(MileageTypes)
    const [mileageTypeSL, setMileageTypesSL] = useState<ListOption[]>([])
    const [advisoryFuelBands, advisoryFuelBandsLoaded] = useFetchEntityList<AdvisoryFuelRateBand, AdvisoryFuelRateBands>(AdvisoryFuelRateBands)
    const [advisoryFuelBandSL, setAdvisoryFuelBandSL] = useState<ListOption[]>([])

    const [creating, setCreating] = useState(false)

    const [personSL, setPersonSL] = useState<ListOption[]>([])
    const [contractSL, setContractSL] = useState<ListOption[]>([])

    const [isEditing, setIsEditing] = useState(false)

    const [showModalDialog, modalDialog] = useModalDialog()

    useEffect(() => {
        if (qe && mileageAPI) {
            mileageAPI.get(companyContext.cid, parseInt(qe.relatedId || '0')).then(res => {
                setInitialValues({...res.data, lastPerson: res.data.personId})
                setInitialValuesLoaded(true)
                setCreating(false)
            })
        } else if (!qe) {
            setCreating(true)
            setIsEditing(true)
            setInitialValuesLoaded(true)
        }
    }, [companyContext.cid, initialValuesLoaded, mileageAPI, qe])

    useEffect(() => {
        if (mileageTypesLoaded) {
            let tempMileages : ListOption[] = []
            mileageTypes.map(x => tempMileages.push({ value: String(x.id), label: x.name || '' }))
            setMileageTypesSL(tempMileages)
        }
    }, [mileageTypes, mileageTypesLoaded])

    useEffect(() => {
        if (advisoryFuelBandsLoaded) {
            let tempAFB : ListOption[] = []
            advisoryFuelBands.map(x => tempAFB.push({ value: String(x.id), label: x.name || '' }))
            setAdvisoryFuelBandSL(tempAFB)
        }
    }, [advisoryFuelBands, advisoryFuelBandsLoaded])

    useEffect(() => {
        if (contracts) {
            let tempContracts : ListOption[] = []
            contracts.map(x => tempContracts.push({ value: String(x.id), label: x.name || '' }))
            setContractSL(tempContracts)
        }
    }, [contracts])
    
    useEffect(() => {
        if (people) {
            let tempPpl : ListOption[] = []
            people.map(x => tempPpl.push({ value: String(x.id), label: x.name || '' }))
            setPersonSL(tempPpl)
        }
    }, [people])

    const saveMileage = (values : MileagePostModel & { lastPerson: number | undefined }) => {
        if (mileageAPI && qe && !creating) {
            mileageAPI.put(companyContext.cid, parseInt(qe.relatedId || '0'), values)
            .then(res => {
                if (res.status === 200) {
                    let tempQe = {
                        ...qe, 
                        date: (asYearMonthDay(new Date())) || '',
                        billable: values.isBillable || false,
                        description: values.description,
                        subtitle: `${values.distance?.toFixed(3)} miles ${values.to} - ${values.from}`,
                        entryType: 'M'
                    }
                    showAlert('saved')
                    cancelCb(tempQe)
                } else {
                    // not sure how much this will show - but the other error bar wouldn't appear (I think) so best to let the user know
                    // so that they don't think they have saved...
                    showAlert('error')
                }
            })
        } else if (mileageAPI && creating) {
            mileageAPI.create(companyContext.cid, values)
            .then(res => {
                if (res.status === 201) {
                    // success - create new qe model, show saved and add qe to list 
                    let qe : QuickEntry = {
                        amount: 0,
                        amountGBP: 0,
                        contractColour: 0,
                        contractId: values.contractId || 0,
                        contractTaskId: 0,
                        date: (asYearMonthDay(new Date())) || '',
                        billable: values.isBillable || false,
                        description: values.description,
                        subtitle: `${values.distance?.toFixed(3)} miles ${values.to} - ${values.from}`,
                        entryType: 'M'
                    }
                    showAlert('saved')
                    cancelCb(qe)
                } else {
                    // not sure how much this will show - but the other error bar wouldn't appear (I think) so best to let the user know
                    // so that they don't think they have saved...
                    showAlert('error')
                }
            })
        }
    }

    const validateMileage = (values : MileagePostModel & { lastPerson: number | undefined }) : Promise<FormikErrors<MileagePostModel & { lastPerson: number | undefined }>> => {
        return new Promise<FormikErrors<MileagePostModel>>((resolve, reject) => {
            if (mileageAPI) {
                mileageAPI?.validateCreate(companyContext.cid, values).then(x => {
                    resolve({})
                }).catch(err => resolve(err.error))
            } else {
                reject()
            }            
        })
    }


    const showDeleteDialog = () => {
        showModalDialog(
            'Delete mileage?',
            'Are you sure you want to delete this mileage?',
            [
                <Button key="del" variant="danger" label="Yes" onClick={() => handleDelete()} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const handleDelete = () => {
        if (mileageAPI) {
            mileageAPI.delete(companyContext.cid, parseInt(qe?.relatedId || ''))
            .then(res => {
                if (res.status === 200) {
                    showAlert('deleted')
                    cancelCb(qe, true)
                } else {
                    // not sure how much this will show - but the other error bar wouldn't appear (I think) so best to let the user know
                    // so that they don't think they have saved...
                    showAlert('error')
                }
            })
        }
    }


    return (
        <Container>

            {(!mileageTypesLoaded || !initialValuesLoaded || !advisoryFuelBandsLoaded) &&
                <>
                    <MileageDetailRowLoading />
                    <MileageDetailRowLoading />
                    <MileageDetailRowLoading />
                    <MileageDetailRowLoading />
                    <MileageDetailRowLoading />
                </>
            }

            { !isEditing && mileageTypesLoaded && initialValuesLoaded && advisoryFuelBandsLoaded && <>
                <div style={{display: 'flex', justifyContent: 'space-between', margin: '20px 0px', alignItems: 'flex-end', flexWrap: 'wrap', wordBreak: 'break-all'}}>
                    <h3>{initialValues.description}</h3>
                    <h5 className="text-muted">{initialValues.distance?.toFixed(2)} miles</h5>
                </div>
                <TextRead entity={initialValues} name="from" ensureRender />
                <TextRead entity={initialValues} name="to" ensureRender />
                <DateRead entity={initialValues} name="date" ensureRender />
                <TextRead entity={initialValues} name="personId" label="Employee" ensureRender valueFormatter={(i) => people.find(x => x.id === parseInt(i || '0'))?.name} />
                <TextRead entity={initialValues} name="mileageTypeId" label="Mileage type" ensureRender valueFormatter={(i) => mileageTypes.find(x => x.id === parseInt(i || '0'))?.name} />
                <TextRead entity={initialValues} name="advisoryFuelRateBand" label="Advisory fuel band" ensureRender valueFormatter={(i) => advisoryFuelBands.find(x => x.id === i || '0')?.name} />
                <TextRead entity={initialValues} name="contractId" label="Contract" ensureRender valueFormatter={(i) => contracts.find(x => x.id === parseInt(i || '0'))?.name} />
                <YesNo entity={initialValues} name="isBillable" ensureRender />
                <TextRead entity={initialValues} name="billedRate" valueFormatter={(i) => formatCurrency(parseInt(i || '0'))} />
            </>}

            
            {initialValuesLoaded && mileageTypesLoaded && advisoryFuelBandsLoaded &&
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={saveMileage}
                validateOnChange={false}       
                validate={validateMileage}
                
            >
                {({ values, setFieldValue }) => (
                    <Form>
                        {isEditing && <>
                            {/* Bit hacky, but it works, we add 'lastPerson' to the model - this allows us to check if the person has changed... then update the defaults */}
                            { values.personId !== values.lastPerson && setFieldValue('mileageTypeId', people.find(x => Number(values.personId) === x.id)?.defaultMileageTypeId) }
                            { values.personId !== values.lastPerson && setFieldValue('advisoryFuelRateBand', people.find(x => Number(values.personId) === x.id)?.defaultAdvisoryFuelrateBand) }
                            { values.personId !== values.lastPerson && setFieldValue('lastPerson', values.personId) }
                            <EditText<MileagePostModel> name="description" labelTop />
                            <EditText<MileagePostModel> name="from" labelTop />
                            <EditText<MileagePostModel> name="to" labelTop />
                            <EditText<MileagePostModel> type="number" suffix="miles" name="distance" labelTop />
                            <DateSelector<MileagePostModel> name="date" labelTop />
                            <SelectList<MileagePostModel> allowSearch={false} label="Employee" options={personSL} name="personId" labelTop />
                            <SelectList<MileagePostModel> allowSearch={false} label="Mileage type" options={mileageTypeSL} name="mileageTypeId" labelTop />
                            <SelectList<MileagePostModel> allowSearch={false} label="Advisory fuel band" options={advisoryFuelBandSL} name="advisoryFuelRateBand" labelTop />
                            <SelectList<MileagePostModel> allowSearch={false} options={contractSL} name="contractId" label="Related to" labelTop />
                            <Switch<MileagePostModel> name="isBillable" labelTop />
                            {values.isBillable && <Currency<MileagePostModel> name="billedRate" labelTop />}
                        </>}                        
                        <div className={styles.editorButtons} data-cy="mileageEditorActions">
                            <Button buttonType="cancel" onClick={() => isEditing && !creating ? setIsEditing(false) : cancelCb()} mobile />
                            {!isEditing && <Button onClick={showDeleteDialog} buttonType="delete" mobile />}
                            {!isEditing && <Button buttonType="edit" onClick={() => setIsEditing(true)} mobile />}
                            {isEditing && <Button buttonType="save" submitButton mobile />}
                        </div>
                    </Form>
                )}
            </Formik>}
            { modalDialog }
        </Container>
    )
}

export default MileageEditor