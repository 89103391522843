// Lifted from react mobile app
// https://innidev.visualstudio.com/_git/inni?path=%2F1.%20PL%2FWeb.ReactJS%2Fsrc%2FElements%2FIcon%2FaccountIcons.js
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

import { faRoad, faPhone, faMusic, faUniversity, faTools, faCar, faCoins, faDesktopAlt, faPrint, faUtensilFork, faFile, faEnvelope, faHomeAlt,
     faGraduationCap, faHotel, faBooks, faBrowser, faChild, faGift, faAnalytics, faBuilding, faPencilRuler, faChartArea, faCreditCard, faUserTie, 
     faHouse, faFileContract, faComputer, faShirt, faPhoneOffice, faScrewdriverWrench, faBoltLightning, faSoap, faCouch, faPassport, faGlobe, 
     faFileSignature, faMeter, faBalloons, faHeart, faArrowRightArrowLeft, faArrowRotateBack, faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { breakStrByCase } from './formatStrings';

export interface AccountMeta {
    colorIndex: Number,
    icon: IconDefinition
}
const accountList:{[key: string]: AccountMeta} = {
    "Employers NI": { colorIndex: 1, icon: faFile },
    "Trade debtors": { colorIndex: 102, icon: faCoins },
    "Mortgage interest": { colorIndex: 201, icon: faCoins },
    "Foreign exchange losses": { colorIndex: 2, icon: faCoins },
    "Gross wages": { colorIndex: 3, icon: faCoins },
    "Legal fees": { colorIndex: 4, icon: faCoins },
    "Mileage": { colorIndex: 5, icon: faCar },
    "Penalties and Fines": { colorIndex: 6, icon: faCoins },
    "Pension contributions": { colorIndex: 7, icon: faCoins },
    "Postage": { colorIndex: 8, icon: faEnvelope },
    "VAT": { colorIndex: 9, icon: faCoins },
    "Use of home as office": { colorIndex: 10, icon: faHomeAlt },
    "Printing": { colorIndex: 1, icon: faPrint },
    "Stationery": { colorIndex: 2, icon: faPencilRuler },
    "Subscriptions": { colorIndex: 3, icon: faFile },
    "Subsistence": { colorIndex: 4, icon: faUtensilFork },
    "Telephone": { colorIndex: 5, icon: faPhone },
    "Training": { colorIndex: 6, icon: faGraduationCap },
    "Travelling": { colorIndex: 7, icon: faRoad },
    "Hotels": { colorIndex: 8, icon: faHotel },
    "Entertainment": { colorIndex: 9, icon: faMusic },
    "Accountancy fees": { colorIndex: 10, icon: faCoins },
    "Books and Magazines": { colorIndex: 1, icon: faBooks },
    "Statutory repayments recovered via NICs": { colorIndex: 2, icon: faCoins },
    "Staff Entertainment": { colorIndex: 3, icon: faMusic },
    "Pension payments": { colorIndex: 4, icon: faCoins },
    "Other fees": { colorIndex: 5, icon: faCoins },
    "Other general expenses": { colorIndex: 6, icon: faCoins },
    "Insurance premiums": { colorIndex: 7, icon: faCoins },
    "Goods purchases for resale": { colorIndex: 8, icon: faCoins },
    "Computer expenses": { colorIndex: 9, icon: faDesktopAlt },
    "Bank interest": { colorIndex: 10, icon: faUniversity },
    "Bank charges": { colorIndex: 1, icon: faUniversity },
    "Sundry expenses": { colorIndex: 2, icon: faCoins },
    "Website costs": { colorIndex: 3, icon: faBrowser },
    "Sub contractors": { colorIndex: 4, icon: faCoins },
    "Repairs and renewals": { colorIndex: 5, icon: faTools },
    "Trivial benefits": { colorIndex: 6, icon: faCoins },
    "Bank interest / charges": { colorIndex: 7, icon: faUniversity },
    "Childcare costs": { colorIndex: 8, icon: faChild },
    "Charity Donations": { colorIndex: 9, icon: faGift },
    "Charitable donations": { colorIndex: 10, icon: faGift },
    "Advertising and marketing": { colorIndex: 1, icon: faAnalytics },
    "Agent fees": { colorIndex: 2, icon: faCoins },
    "Broker fees": { colorIndex: 3, icon: faCoins },
    "Childcare": { colorIndex: 4, icon: faChild },
    "Council Tax": { colorIndex: 5, icon: faCoins },
    "Service charges and ground rent": { colorIndex: 6, icon: faCoins },
    "Utilities": { colorIndex: 7, icon: faBuilding },
    "Rent": { colorIndex: 101, icon: faCoins },
    "Maintenance": { colorIndex: 9, icon: faTools },
    "Donations": { colorIndex: 10, icon: faGift },
    "Relocation costs": { colorIndex: 1, icon: faCoins },
    "Marketing": { colorIndex: 2, icon: faAnalytics },
    "Charity Donation": { colorIndex: 3, icon: faGift },
    "IR35 Off payroll deductions": { colorIndex: 4, icon: faCoins },
    "VAT due on purchases": { colorIndex: 4, icon: faCoins },
    "Original value of assets": { colorIndex: 5, icon: faCoins },
    "Net wages": { colorIndex: 6, icon: faCoins },
    "Property Purchase Costs": { colorIndex: 7, icon: faBuilding },
    "Bank interest / fees": { colorIndex: 8, icon: faCoins },
    "Depreciation": { colorIndex: 9, icon: faChartArea },
    "VAT due on sales": { colorIndex: 10, icon: faChartArea },
    "Bank loan interest - Mortgage": { colorIndex: 1, icon: faCoins },
    "Income from letting agent": { colorIndex: 2, icon: faFileContract },
    "Property completion statement": { colorIndex: 3, icon: faFileContract },
    "Apps & computer software": { colorIndex: 4, icon: faComputer },
    "Clothing": { colorIndex: 5, icon: faShirt },
    "Office equipment": { colorIndex: 6, icon: faPhoneOffice },
    "Stationery, printing & postage": { colorIndex: 7, icon: faPrint },
    "Tools": { colorIndex: 8, icon: faScrewdriverWrench },
    "Appliances & electronics": { colorIndex: 9, icon: faBoltLightning },
    "Cleaning materials": { colorIndex: 10, icon: faSoap },
    "Furniture & furnishings": { colorIndex: 1, icon: faCouch },
    "Maintenance & building materials": { colorIndex: 2, icon: faScrewdriverWrench },
    "Passports, visa & citizenship fees": { colorIndex: 3, icon: faPassport },
    "Phone, internet & app subscriptions": { colorIndex: 4, icon: faGlobe },
    "Website & domain names": { colorIndex: 5, icon: faGlobe },
    "Will preparation": { colorIndex: 6, icon: faFileSignature },
    "Cleaning": { colorIndex: 7, icon: faSoap },
    "Furniture lease": { colorIndex: 8, icon: faCouch },
    "Licences & certificates": { colorIndex: 9, icon: faFileSignature },
    "Maintenance & building services": { colorIndex: 10, icon: faScrewdriverWrench },
    "Planning permission": { colorIndex: 1, icon: faFileSignature },
    "Utilities, internet & TV subscriptions": { colorIndex: 2, icon: faMeter },
    "Car costs (company car)": { colorIndex: 3, icon: faCar },
    "Car costs (personal car)": { colorIndex: 4, icon: faCar },
    "Staff annual parties": { colorIndex: 5, icon: faBalloons },
    "Charity donations": { colorIndex: 6, icon: faHeart },
    "Personal investments": { colorIndex: 7, icon: faAnalytics },
    "Bank, mortgage & loan interest": { colorIndex: 8, icon: faAnalytics },
    "refund": { colorIndex: 8, icon: faArrowRotateBack },
    "transfer": { colorIndex: 8, icon: faArrowRightArrowLeft },
    "income": { colorIndex: 8, icon: faArrowDown },
}

const accountsSubGroup : {[key : string] : string} = {
    "BankAccounts": "Bank Accounts",
    "Capital": "Capital",
    "CreditCardsLoans": "Credit Cards and Bank Loans",
    "Debtors": "Debtors",
    "DirectorsLoans": "Directors Loans",
    "FixedAssets": "Fixed Assets",
    "GeneralExpenses": "General Expenses",
    "GrossWages": "Gross Wages",
    "Office": "Printing, Stationery and Office",
    "OtherExpenses": "Other Expenses",
    "OtherIncome": "Other Income",
    "ProfFees": "Professional Fees / Bank Charges",
    "ProfitAndLoss": "Profit and Loss Account",
    "Property": "Property",
    "SaleOfAssets": "Sale of Assets",
    "Sales": "Sales",
    "TaxInPeriod": "Tax - In Period Balance",
    "TaxLiability": "Tax - Liability",
    "TravelEnt": "Travel and Entertainment",
    "Withdrawals": "Withdrawals",
    "OtherWithdrawal": "Other Withdrawals",
    "BankingMortgageInterest": "Banking, Mortgage and Interest",
    "TaxFineLoss": "Taxes, fines and losses"
}

const getAccountColorNumber = (value:string) =>{
    let hash:number = 0, i, chr;
    if (value.length === 0) return hash;
    for (i = 0; i < value.length; i++) {
        chr   = value.charCodeAt(i);
        hash  = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }

    const colorArraySize = 10 + 1; //+1 so it can be 10 itself (zero index)
    const result = hash % colorArraySize
    return ((result)+colorArraySize)%colorArraySize; //Prevent negative numbers
}

export const formatAccount = (accountName:string) : {color: string, icon: IconDefinition} => {
    const accountData = accountList[accountName]
    if(accountData) {
        return {color: `var(--account-color-${accountData.colorIndex})`, icon: accountData.icon} 
    }
    return { color: `var(--account-color-${getAccountColorNumber(accountName)})`, icon: faCoins }
}

export const getIconForAccountSubGroup = (subgroup : string) : IconDefinition => {
    switch(subgroup) {
        case 'BankAccounts':
            return faUniversity
        case 'CreditCardsLoans':
            return faCreditCard
        case 'DirectorsLoans':
            return faUserTie
        case 'Mortgages':
            return faHouse
        default:
            return faCreditCard
    }
}


export const formatSubGroup = (subGroup : string) => accountsSubGroup[subGroup] || breakStrByCase(subGroup)