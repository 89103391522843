import { Modal as ReactModal } from 'react-bootstrap';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { NotifyOfUpdatePostModel } from "../../../api/inni/data-contracts";
import { Text as EditText }  from '../../../elements/EditRow/EditRow'
import { Button } from "../../Button/Button";
import { BrandWrapper } from '../../../components/BrandWrapper/BrandWrapper';


interface NoteModalProps {
	hide: () => void;
	create: (values: NotifyOfUpdatePostModel, actions: FormikHelpers<NotifyOfUpdatePostModel>) => void;
}

const NotifyUpdateModal = ({ hide, create }: NoteModalProps) => {

	const validate = (values: NotifyOfUpdatePostModel) => {
		let errors: any = {};
		if (!values.briefExplanation) errors.briefExplanation = 'Required';

		return errors;
	};

	return (
		<ReactModal size="lg" show={true} onHide={hide}>
			<BrandWrapper>
				<Formik
					initialValues={{
						briefExplanation: '',
					}}
					enableReinitialize
					validateOnChange={false}
					validate={validate}
					onSubmit={create}
				>
					{({ isSubmitting }) => (
						<FormikForm>
							{/* Header */}
							<ReactModal.Header closeButton>
								<ReactModal.Title id="inni-modal-title">
									Notify us of an update
								</ReactModal.Title>
							</ReactModal.Header>

							{/* Body */}
							<ReactModal.Body>
							
								<EditText<NotifyOfUpdatePostModel>
									labelClassName='w-100'
									name="briefExplanation"
									label="Tell us about what needs changing to your company details"
									type="textarea"
									labelTop
								/>
							</ReactModal.Body>

							{/* Footer */}
							<ReactModal.Footer>
								<div style={{ textAlign: 'left', width: '100%' }}>
									<Button
										variant="change"
										submitButton
										disabled={isSubmitting}
									>
										Submit
									</Button>
									<Button variant="secondary" onClick={hide}>
										Cancel
									</Button>
								</div>
							</ReactModal.Footer>
						</FormikForm>
					)}
				</Formik>
			</BrandWrapper>
		</ReactModal>
	);
};
export default NotifyUpdateModal;
