import { useContext, useEffect, useState } from "react";
import { Property } from "../../api/inni/data-contracts";
import { Properties } from "../../api/inni/Properties";
import CompanyContext from "../../context/CompanyContext";
import { useInniAPI } from "../useInniAPI";

export interface LettableUnitListItem {
    id: number,
    name?: string,
    lettingType?: "ResidentialSingleUnit" | "HmoRoom" | "Commercial" | "HolidayLet" | "ServicedAccommodation" | "Other",
    propertyId: number,
    propertyName: string, 
    propertyIsMultiUnit: boolean,
    fullName: string     
}

export function useLettableUnitsList(): [Array<LettableUnitListItem>, boolean] {

    const companyContext = useContext(CompanyContext);
    const propertiesApi = useInniAPI(Properties);
    const [units, setUnits] = useState<Array<LettableUnitListItem>>([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (companyContext && propertiesApi && !loaded && !loading) {
            setLoading(true);
            propertiesApi.index(companyContext.cid).then(
                response => {

                    const newUnits:Array<LettableUnitListItem> = [];

                    response.data.forEach(property => {
                        property.lettableUnits?.forEach(unit => {
                            const multiUnit = property.lettableUnits ? property.lettableUnits.length > 1 : false;
                            newUnits.push({
                                id: unit.id, 
                                name: unit.name,
                                lettingType: unit.lettingType,
                                propertyId: property.id,
                                propertyName: property.name,
                                propertyIsMultiUnit: multiUnit,
                                fullName: (multiUnit && unit.name) ? `${property.name} → ${unit.name}` : property.name
                            })
                        })
                    })


                    setUnits(newUnits);
                    setLoaded(true);
                }
            ).catch(error => setLoaded(true))
        }
    }, [companyContext, propertiesApi, loaded, loading])

    return [units,loaded];
}