import { LettingStatementStatus } from './AgentFields'

export const agentStatementStatus = (id: number | null | undefined, date: string | null | undefined) => {
    if ((id === null || id === undefined) && (date === null || date === undefined)) {
        return LettingStatementStatus.Created
    }
    if (id !== null && date === null) {
        return LettingStatementStatus.Processing
    }

    return LettingStatementStatus.Processed
}