import { Col, Row } from "react-bootstrap";
import { SimpleKpi } from "../../../elements/DashboardCards/SimpleKpi";
import { formatCurrency, formatPercent } from "../../../utils/formatNumbers";
import { formatDate } from "../../../utils/formatDate";
import { AssetAsDetailed, AssetChangetoExpense } from "../../../api/inni/data-contracts";
import * as DataGrid from "../../../elements/DataGrid/DataGrid";
import { Button } from "../../../elements/Button/Button";
import styles from '../Asset.module.css'
import { AssetPaymentChangeToExpenseModal } from "../AssetPaymentChangeToExpense";
import { useModalDialog } from "../../../hooks/useModalDialog";
import { useState } from "react";
import { ExpandedViewBackLabel } from "../../../elements/ExpandedViewBackLabel/ExpandedViewBackLabel";
import Toolbar from "../../../layouts/Desktop/Toolbar";
import { Action, Entity } from "../../../utils/EntityAction";
import { useHasPermission } from "../../../hooks/useHasPermission";


interface viewAssetDataProps{
    data: AssetAsDetailed |undefined;
    isProperty?: boolean;
    back: () => void;
    edit: () => void;
    deleteAsset: (values: number) => void;
    changeToExpense: (values: AssetChangetoExpense) => void;
    disposeAssetAtZero: (values: number) => void;
}

const ViewAsset =({data, isProperty, back, edit, deleteAsset, changeToExpense, disposeAssetAtZero}: viewAssetDataProps)=>{
    const [showAssetPaymentChange, setAssetPaymentChangeModal] = useState(false);
    const [showModalDialog, modalDialog] = useModalDialog();
    const hasPermission = useHasPermission();
    const hasAdminPermission = hasPermission(Entity.AssetAdmin, Action.All)

    const showDeleteAssetDialog = () => {
        showModalDialog(
            'Delete asset?',
            `Are you sure you want to delete this asset?`,
            [
            <Button
                variant="danger"
                label="Yes"
                onClick={() => deleteAsset(data?.id || 0)}
            />,
            <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    };

    const showDisposeAssetAtZeroDialog = () => {
        showModalDialog(
            'Dispose of the asset at zero?',
            <p>Confim that you have not received any money for the asset, including insurance.<br />
            If you did, record the bank payment as the asset disposal.<br /><br />
            Use this only if you've lost it, had it stolen, or it's broken and you've thrown it away.
            </p>,
            [
            <Button
                variant="danger"
                label="Yes"
                onClick={() => disposeAssetAtZero(data?.id || 0)}
            />,
            <Button variant="primary" outline label="No" onClick={() => {}} />,
            ],
            false
        );
    };

      

    const checkChangeAssetToExpense=(accountId:any)=>{
        if(accountId){
            const temp:AssetChangetoExpense={
              accountId:accountId
            }
            changeToExpense(temp)
            setAssetPaymentChangeModal(false)
            back();       
        } 
     };

    const showAssetPaymentChangeModal=()=>{
        setAssetPaymentChangeModal(true)      
    }
    
    return(
        <>
            {  data &&
                <>
                    <ExpandedViewBackLabel backLabel="Assets" title={data.description} onClick={back}>
                        <Toolbar>
                            <Button
                                variant="primary"
                                buttonType="edit"
                                onClick={edit}
                                label="Edit"
                                disabled={!data?.editability?.canEdit}
                                disabledMessage={data?.editability?.cantEditReason}
                                entity={Entity.Asset}
                                action={Action.Edit}
                            />
                            <Button
                                buttonType="delete"
                                onClick={showDeleteAssetDialog}
                                label="Delete"
                                disabled={!data?.editability?.canDelete}
                                disabledMessage={data?.editability?.cantDeleteReason}
                                entity={Entity.Asset}
                                action={Action.Delete}
                            />

                            { hasAdminPermission[0] && (data.editability?.canChangeToExpense || data.editability?.cantChangeToExpenseReason) &&
                                <Button
                                    variant="secondary"
                                    onClick={showAssetPaymentChangeModal}
                                    label="Change to expense"
                                    disabled={!data?.editability?.canChangeToExpense}
                                    disabledMessage={data?.editability?.cantChangeToExpenseReason}
                                    admin = {hasAdminPermission[1]}
                                />

                            }
                            
                            {hasAdminPermission[0] && !data.isDisposed && 
                                <Button
                                    variant="secondary"
                                    onClick={showDisposeAssetAtZeroDialog}
                                    label="Dispose at zero"
                                    admin = {hasAdminPermission[1]}
                                />
                            }
                        </Toolbar>
                    </ExpandedViewBackLabel>
            
                    <div className={styles.kpiGroup}>
                        <Row>
                            <Col xs={12} md={4} lg={2} className="pr-0">
                                <SimpleKpi
                                    key={"purchasedDate"}
                                    label={"Purchased Date"} 
                                    className={styles.cardHeight} 
                                    value={formatDate(data.datePurchased)} 
                                    useSmallKpiValues 
                                />
                            </Col>
                            <Col xs={12} md={4} lg={2} className="pr-0">
                                <SimpleKpi 
                                    key={"1styear"}
                                    label={"1st Year"} 
                                    className={styles.cardHeight} 
                                    value={data.isFirstYear ? "Yes" : "No"}
                                    useSmallKpiValues
                                />
                            </Col>
                            <Col xs={12} md={4} lg={2} className="pr-0">
                                <SimpleKpi 
                                    key={"category"}
                                    label={"Category"} 
                                    className={styles.cardHeight} 
                                    value={data.assetCategoryName} 
                                    useSmallKpiValues 
                                />
                            </Col>
                            <Col xs={12} md={4} lg={2} className="pr-0">
                                <SimpleKpi
                                    key={"originalValue"}
                                    label={"Original value"} 
                                    className={styles.cardHeight} 
                                    value={formatCurrency(data.originalValue)} 
                                    useSmallKpiValues
                                />
                            </Col>
                            <Col xs={12} md={4} lg={2} className="pr-0">
                                <SimpleKpi
                                    key={"disposaldate"}
                                    label={"Disposal date"} 
                                    className={styles.cardHeight} 
                                    value={data.disposalDate?formatDate(data.disposalDate) : "--"} 
                                    useSmallKpiValues 
                                />
                            </Col>
                            {isProperty &&
                                <Col xs={12} md={4} lg={2} className="pr-0">
                                    <SimpleKpi 
                                        key={"property"}
                                        label={"Property"} 
                                        className={styles.cardHeight} 
                                        value={data.propertyName ? data.propertyName : '--'} 
                                        useSmallKpiValues 
                                    />
                                </Col>
                            }
                        </Row>
                    </div>

                    <DataGrid.Table noHover>
                        <thead>
                            <tr>
                                <th>Method</th>
                                <th></th>
                                <th>Rate-Year 1</th>
                                <th>Rate-Year 2</th>
                                <th>Current value</th>
                                <th>Value at end of FY</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={"book"}>
                                <td>{"Book"}</td>
                                <td>{data.methodAccounts === "R" ? "Reducing" : "Straight Line"}</td>
                                <td>{formatPercent(data.rateFirstYearAccountsPct?(data.rateFirstYearAccountsPct)/100:data.rateFirstYearAccountsPct)}</td>
                                <td>{formatPercent(data.rateSubsequentAccountsPct?(data.rateSubsequentAccountsPct)/100:data.rateSubsequentAccountsPct)}</td>
                                <td>{formatCurrency(data.currentValue)}</td>
                                <td>{formatCurrency(data.forecastValueAccounts)}</td>
                            </tr>
                            <tr key={"ca"}>
                                <td>{"CA"}</td>
                                <td>{data.methodTax === "R" ? "Reducing" : "Straight Line"}</td>
                                <td>{formatPercent(data.rateFirstYearTaxPct?(data.rateFirstYearTaxPct)/100:data.rateFirstYearTaxPct)}</td>
                                <td>{formatPercent(data.rateSubsequentTaxPct?(data.rateSubsequentTaxPct)/100:data.rateSubsequentTaxPct)}</td>
                                <td>{formatCurrency(data.currentValueTax)}</td>
                                <td>{formatCurrency(data.forecastValueTax)}</td>
                            </tr>
                        </tbody>
                    </DataGrid.Table>
                </>
            }
            {modalDialog}
            {showAssetPaymentChange && 
            <AssetPaymentChangeToExpenseModal  
                hide ={()=> setAssetPaymentChangeModal(false)} 
                AssetToExpense= {checkChangeAssetToExpense}        
            />

            }
        </>
    )
}

export default ViewAsset;