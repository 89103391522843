import React, {useContext, useEffect} from 'react';
import { Container, Navbar } from 'react-bootstrap';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { Action, Entity } from '../../utils/EntityAction';
import {Helmet} from "react-helmet";
import styles from './DefaultLayout.module.scss';
import stylesV75 from './DefaultLayout_V75.module.scss';
import classNames from 'classnames';
import BrandContext from '../../context/BrandContext';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { LoadingShim } from '../../elements/LoadingShim/LoadingShim';
import { faLongArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Feedback from '../../components/Feedback/Feedback';
import useIsMobile from '../../hooks/useIsMobile';
import { useHistoryWrapper } from '../../hooks/useHistoryWrapper';

// Inspired by https://mparavano.medium.com/find-filter-react-children-by-type-d9799fb78292 
// for filtering children

type DefaultLayoutProps = {
    children: React.ReactNode,
    title?: string, 
    loading?: boolean,
    entity?: Entity,
    greyBackground?: boolean,
    noPadding?: boolean
    subLayout?: boolean
    backIcon?: boolean
    useFullWidth?: boolean,
    backFnc?: () => void,
    showFeedbackBtn?: boolean
}

export const DefaultLayout = ({
    children, title,
    loading, entity, greyBackground,
    noPadding, backIcon,
    useFullWidth, backFnc, showFeedbackBtn,
    subLayout} : DefaultLayoutProps) => {
   
    const brand = useContext(BrandContext).brand;
    const companyContext = useContext(CompanyContext);

    const childArray = React.Children.toArray(children);

    const history = useHistoryWrapper();

    const isMobile = useIsMobile();

    const styling = (companyContext.company?.useV8UI) ? stylesV75 : styles;

    useEffect(() => {
        if (companyContext.setActiveEntity) companyContext.setActiveEntity(entity);
    }, [entity, companyContext])

    const isToolbarItem = (child: React.ReactNode) => (
        React.isValidElement(child) && child.props.__TYPE === 'DefaultToolbar'
    )

    const toolbarChildren = (
        childArray.filter(child => (isToolbarItem(child)))
    )

    const childrenWithoutToolbar = (
        childArray.filter(child => (!(isToolbarItem(child))))
    )
    
    // Not used anywhere...
    // const logout = (e: React.MouseEvent<HTMLElement>) => {
    //     if (userContext.logout) {
    //         userContext.logout()
    //     }
    //     e.preventDefault();
    // }

    return (
        <>
            {title && 
                <Helmet>
                    <title>{title ? `${title} - ${brand.name}` : brand.name}</title>
                </Helmet>        
            }
            {/* defaultLayout css V7.5 inherits V7, when unified handle the inline styling here properly (i.e. remove) - DD 05/05/24 */}
            <div id={styling.defaultLayout} style={subLayout ? {margin: 0} : {}}>

                {/* HEADERS */}
                { (title || toolbarChildren.length !== 0) && (
                    <div id={isMobile ? styling.headerMobile : styling.header}
                        className={classNames({[styling.fullScreen]: useFullWidth})}
                    >
                        { title && !subLayout && (
                            <h1>
                                { loading
                                    ? <LoadingPlaceholder width='180px' /> 
                                    : (
                                        <>
                                            { backIcon && (
                                                <FontAwesomeIcon
                                                    id={styling.backIcon}
                                                    icon={faLongArrowLeft}
                                                    onClick={backFnc ? backFnc : () => history.goBack()}
                                                />
                                            )}
                                            { title }
                                        </>
                                    )
                                }
                            </h1>
                        )}

                        { title && subLayout && (
                            <h2>{title}</h2>
                        )}

                        { !loading && toolbarChildren?.length > 0 && (
                            <div id={styling.toolbar}>
                                {toolbarChildren}
                            </div>
                        )}               
                    </div> 
                )}
                {/* CONTENT */}
                <div id={styling.containerWrapper} className={classNames({[styling.greyBackground]: greyBackground})}>
                    { loading && <LoadingShim /> }
                    <Container
                        id={styling.container}
                        className={
                            classNames({
                                [styling.noPadding]: noPadding,
                                [styling.noSpacing]: subLayout,
                                [styling.useFullWidth]: useFullWidth,
                                [styling.useMobileFooter]: isMobile
                            })
                        }
                    >
                        {childrenWithoutToolbar}
                    </Container>
                </div>     
            </div>

            {showFeedbackBtn && <Feedback />}

        </>
    )
}

export const SimplePageHeader = ({title} : {title:string}) => {
    return <div id={styles.header}>
        <h1>{title}</h1>
    </div>
}