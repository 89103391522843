import { useContext, useEffect, useState } from "react";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout"
import { Action, Entity } from "../../utils/EntityAction"
import CompanyContext from "../../context/CompanyContext";
import { useHasPermission } from "../../hooks/useHasPermission";
import ErrorPage from "../../layouts/Components/ErrorPage";
import { Button } from "../../elements/Button/Button";
import { asYearMonthDay, asHtml5Date, formatDate } from "../../utils/formatDate";
import InfoBanner from "../../elements/InfoBanner/InfoBanner";
import { useFetchEntityList } from "../../hooks/entities/useFetchEntityList";
import { Accounts } from "../../api/inni/Accounts";
import { Account } from "../../api/inni/data-contracts";
import { ListOption } from "../../elements/EditRow/EditRow";
import Select from "react-select";
import { Alert } from "react-bootstrap";

export const Test = () => {
    const companyContext = useContext(CompanyContext);
    const usePermission = useHasPermission();
    const hasTestPermissions = usePermission(Entity.TestData, Action.Edit)[0];
    const API_URL = `${window.appConfig.apiUrl}/api/v1/companies/testdata`

    const [systemTime, setSystemTime] = useState(asYearMonthDay(new Date()))
    const [systemTimeFromAPI, setSystemTimeFromAPI] = useState(asYearMonthDay(new Date()))
    const [selectedAccount, setSelectedAccount] = useState<string|undefined>()
    const [selectedAccountOB, setSelectedAccountOB] = useState<string|undefined>()
    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts)
    const [accountOptions, setAccountOptions] = useState<ListOption[]>([])
    const [showTxnsCreated, setShowTxnsCreated] = useState(false)
    const [showOBCCreated, setShowOBCCreated] = useState(false)
    const [showNetworkErr, setShowNetworkErr] = useState(false)
    
    useEffect(() => {
        fetch(`${API_URL}/getSystemTime`)
        .then(response => response.json())
        .then(res => {
            setSystemTime(asHtml5Date(res) || "")
            setSystemTimeFromAPI(asHtml5Date(res) || "")
        }).catch(err => console.error(err))
    }, [])

    useEffect(() => {
        if (accountsLoaded && accounts)       {
            let tempOptions: Array<ListOption> = []
            accounts.filter(x => x.bankAccount).map(i => {
                tempOptions.push({
                    label: i.name,
                    value: i.id.toString()
                })
            })
            setAccountOptions(tempOptions)
        }        
    }, [accounts, accountsLoaded])

    useEffect(() => {
        let id: NodeJS.Timeout;
        if (showOBCCreated) {
            id = setTimeout(() => setShowOBCCreated(false), 5000)
        }
        return () => clearTimeout(id)
    }, [showOBCCreated])

    useEffect(() => {
        let id: NodeJS.Timeout;
        if (showTxnsCreated) {
            id = setTimeout(() => setShowTxnsCreated(false), 5000)
        }
        return () => clearTimeout(id)
    }, [showTxnsCreated])


    const updateSystemTime = () => {
        fetch(`${API_URL}/setSystemTime?yyyymmdd=${asHtml5Date(systemTime)}`, {method: "post"})
        .then(response => response.json())
        .then(res => {
            setSystemTimeFromAPI(asHtml5Date(res) || "")
        }).catch(err => console.error(err))
    }

    const createFakeTxns = () => {
        fetch(`${API_URL}/loadFakeIBTs?companyId=${companyContext.cid}&accountId=${selectedAccount}`, {method: "post"})
        .then(res => {
            if(res.ok) {
                setShowNetworkErr(false)
                setShowTxnsCreated(true)
            }
            else {
                setShowNetworkErr(true)
            }
        }).catch(err => console.error(err))
    }

    const createExpiredOBC = () => {
        fetch(`${API_URL}/createExpiredOBC?companyId=${companyContext.cid}&accountId=${selectedAccountOB}`, {method: "post"})
        .then(res => {
            if(res.ok) {
                setShowNetworkErr(false)
                setShowOBCCreated(true)
            }
            else {
                setShowNetworkErr(true)
            }
        }).catch(err => console.error(err))
    }

    if(!hasTestPermissions) {
        return <ErrorPage/>
    }

    return (<DefaultLayout
        title="Test data"
        entity={Entity.TestData}
    >

        <InfoBanner type="info" body={`The current system time is ${formatDate(systemTimeFromAPI)}`}/>

        <div>
            <h4>Set system time</h4>
            <input type="date" value={systemTime} onChange={(e) => setSystemTime(e.target.value)} style={{padding: "0.25rem", marginRight: "0.5rem"}}/>
            <Button variant="primary" label="Go" onClick={updateSystemTime}/>
        </div>

        <hr />

        <div>
            <h4>Create expired OB connection to account</h4>
            <a href={`/company/${companyContext.cid}/accounts/editaccounts`}>Create a new account</a>
            <span style={{display: 'block', width: '300px', margin: '1rem 0'}}>
                <Select
                    classNamePrefix='selectList'
                    options={accountOptions}
                    value={accountOptions.find(x => x.value === selectedAccountOB) as any}
                    onChange={(selectedOption: {value: string; label: string;} | null) => {
                        setSelectedAccountOB(selectedOption?.value as any)
                    }}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
            </span>
            <Button variant="primary" label="Go" onClick={createExpiredOBC} disabled={!selectedAccountOB}/>
        </div>

        <Alert
            style={{marginTop:'1rem'}}
            show={showOBCCreated}
            variant={"success"}
            dismissible
            onClose={() => setShowOBCCreated(false)}
        >
            <span>Expired OB connection created</span>
        </Alert>

        <hr />

        <div>
            <h4>Generate fake IBTs</h4>
            <h5>Up to system time, for:</h5>
            <span style={{display: 'block', width: '300px', margin: '1rem 0'}}>
                <Select
                    classNamePrefix='selectList'
                    options={accountOptions}
                    value={accountOptions.find(x => x.value === selectedAccount) as any}
                    onChange={(selectedOption: {value: string; label: string;} | null) => {
                        setSelectedAccount(selectedOption?.value as any)
                    }}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
            </span>
            <Button variant="primary" label="Go" onClick={createFakeTxns} disabled={!selectedAccount}/>
        </div>

        <Alert
            style={{marginTop:'1rem'}}
            show={showTxnsCreated}
            variant={"success"}
            dismissible
            onClose={() => setShowTxnsCreated(false)}
        >
            <span>Transactions created</span>
        </Alert>

        <Alert
            style={{marginTop:'1rem'}}
            show={showNetworkErr}
            variant={"danger"}
            dismissible
            onClose={() => setShowNetworkErr(false)}
        >
            <span>Sorry, something went wrong. Check the console for more</span>
        </Alert>
    </DefaultLayout>)
}