/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { LettingAgent, LettingAgentPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class LettingAgents<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags LettingAgents
   * @name Index
   * @summary Get letting agents list
   * @request GET:/api/v2/companies/{companyId}/lettingAgents
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<LettingAgent[], any>({
      path: `/api/v2/companies/${companyId}/lettingAgents`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags LettingAgents
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/lettingAgents
   * @secure
   */
  create = (companyId: number, pm: LettingAgentPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/lettingAgents`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
