import { Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { useContext, useState } from 'react'
import { Modal } from '../../../../components/Modal/Modal'
import CompanyContext from '../../../../context/CompanyContext'
import { Currency, DateSelector, ListOption, SelectList, Switch, Text } from '../../../../elements/EditRow/EditRow'
import { useInniAPI } from '../../../../hooks/useInniAPI'
import { People } from '../../../../api/inni/People'
import { PersonAsDetailed } from '../../../../api/inni/data-contracts'
import { PayeSetupP46PostModel } from '../../../../api/inni/data-contracts'
import { useValidateFormikPage } from '../../../../hooks/useValidateFormikPage'
import styles from '../../People.module.scss'
import { Button } from '../../../../elements/Button/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { asYearMonthDay } from '../../../../utils/formatDate'


const circumstancesSl : ListOption[] = [
    { value: 'A',  label: 'A - First job since 6th April' },
    { value: 'B',  label: 'B - Only job now, have had other job since 6th April' },
    { value: 'C',  label: 'C - Employee has another job' }
]

const pageFields : Array<Array<keyof PayeSetupP46PostModel>> = [
    ['employeeStartDate', 'nino', 'niTable', 'salary', 'studentLoan', 'postGraduateLoan', 'defaultEmployee', 'circumstances'],
    ['migrationPay', 'migrationTax', 'migrationEENIC', 'migrationERNIC']
]

const SetupP46Modal = ({person, personId, show, onClose, reloadPeople} : {person : PersonAsDetailed | undefined, personId : number, show: boolean, onClose : () => void, reloadPeople : () => void}) => {

    const companyContext = useContext(CompanyContext)
    const peopleApi = useInniAPI(People, [400])

    const [page, setPage] = useState(1)
    const validatePage = useValidateFormikPage<PayeSetupP46PostModel>()

    const validateUpdate = (values: PayeSetupP46PostModel) : Promise<FormikErrors<PayeSetupP46PostModel>> => {
        return new Promise((resolve) => {
            if (peopleApi) {
                peopleApi.validatePayeSetupP46(companyContext.cid, personId, values)
                .then(() => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const updateCall = (values: PayeSetupP46PostModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (peopleApi) {
                peopleApi.payeSetupP46(companyContext.cid, personId, values)
                .then(res => {
                    if (res.status === 200) {
                        reloadPeople()
                        onClose()
                        resolve()
                    }
                    reject(`Invalid status code ${res.status}`)
                }).catch(err => reject(err.error))
            }
        })
    }

    const handleClose = () => {
        setPage(1)
        onClose()
    }

    const validateCurPage = async (
        setTouched: (touched: FormikTouched<PayeSetupP46PostModel>, shouldValidate?: boolean | undefined) => void, 
        validateForm: (values?: any) => Promise<FormikErrors<PayeSetupP46PostModel>>
    ) => {
        if (await validatePage(pageFields[page-1], setTouched, validateForm)) 
            setPage(page+1)
    }

    return (
        <Modal size="xl" showModal={show} hideModal={handleClose} title="Setup PAYE without a P45">
            
            <Formik
                initialValues={{
                    circumstances: "A",
                    periodType: 'W',
                    employeeStartDate: asYearMonthDay(new Date()),
                    nino: "",
                    niTable: "",
                    salary: 0,
                    studentLoan: false,
                    postGraduateLoan: false,
                    defaultEmployee: false,
                    carMiles: 0,
                    useAlternateNICal: false
                } as PayeSetupP46PostModel}
                validateOnChange={false}
                onSubmit={updateCall}
                validate={validateUpdate}
            >
                {({ setTouched, validateForm }) => (

                    <Form>
                    
                        {page === 1 && <>
                            <DateSelector<PayeSetupP46PostModel> name="employeeStartDate" labelTop />
                            <SelectList<PayeSetupP46PostModel> name="circumstances" labelTop options={circumstancesSl} />
                            <Text<PayeSetupP46PostModel> name="nino" labelTop label="N.I. number" />
                            <Text<PayeSetupP46PostModel> name="niTable" labelTop label="N.I. table" />
                            <Currency<PayeSetupP46PostModel> flipToPositive name="salary" labelTop />
                            <Switch<PayeSetupP46PostModel> labelTop name="studentLoan" />
                            <Switch<PayeSetupP46PostModel> labelTop name="postGraduateLoan" />
                            <Switch<PayeSetupP46PostModel> labelTop name="defaultEmployee" />
                            {person?.isDirector &&
                                <Switch<PayeSetupP46PostModel> labelTop name="useAlternateNICal" label='Use alternative NI calculation'/>
                            }                            
                            <Text<PayeSetupP46PostModel> name="carMiles" labelTop type="number" label="Car miles from 6th April to leaving date" />
                        </>}

                        {person && person.showMigration && page === 2 && <>
                            <h3>Migration</h3>
                            <Currency<PayeSetupP46PostModel> flipToPositive labelTop name="migrationPay" label="Pay" />
                            <Currency<PayeSetupP46PostModel> flipToPositive labelTop name="migrationTax" label="Tax" />
                            <Currency<PayeSetupP46PostModel> flipToPositive labelTop name="migrationERNIC" label="ERNIC" />
                            <Currency<PayeSetupP46PostModel> flipToPositive labelTop name="migrationEENIC" label="EENIC" />
                        </>}

                        <div className={styles.buttonContainer}>
                            <Button buttonType="cancel" onClick={handleClose} />
                            {page !== 1 && <Button onClick={() => setPage(page-1)} buttonType="back" />}
                            {(page === 1 && person?.showMigration) && 
                                <Button onClick={() => validateCurPage(setTouched, validateForm)} 
                                variant="primary">Continue <FontAwesomeIcon icon={faChevronRight} style={{marginRight: '0px', marginLeft: '0.5em'}} /></Button>}
                            {(page === 2 || (page === 1 && !person?.showMigration)) && <Button submitButton buttonType="save" />}
                        </div>

                    </Form>
                )}
            </Formik>

        </Modal>
    )

}

export default SetupP46Modal