import React, { useState, useEffect } from 'react';
import { Button } from '../../elements/Button/Button';
import { Card, CloseButton } from 'react-bootstrap';
import styles from './MobileWrapper.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';

type PWADownloadButtonProps = {
	type:'sidebar' | 'popup'
}

function PWADownloadButton({type}: PWADownloadButtonProps) {
	const [showInstallButton, setShowInstallButton] = useState(false);
	const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
	const [showCard, setShowCard] = useState(true);
	const [showPopup, setShowPopup] = useState(false);

	//setShowPopup true after 5 seconds

	useEffect(() => { 
		if (type === 'popup') {
			const timer = setTimeout(() => {
				setShowPopup(true);
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [type]);
	

	useEffect(() => {
		window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
		return () => {
			window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
		};

	}, []);

	function handleBeforeInstallPrompt(e: any) {
		// Prevent Chrome 67 and earlier from automatically showing the prompt
		e.preventDefault();
		// Stash the event so it can be triggered later
		setDeferredPrompt(e);
		// Show the install button
		setShowInstallButton(true);
	}

	async function handleInstallClick() { 
		if (deferredPrompt) {
			// Show the installation prompt
			deferredPrompt.prompt();
			deferredPrompt.userChoice &&
				await deferredPrompt.userChoice.then((choiceResult: any) => {
					if (choiceResult.outcome === 'accepted') {
						console.log('User accepted the install prompt');
					} else {
						console.log('User dismissed the install prompt');
					}
					// Reset the deferred prompt variable
					setDeferredPrompt(null); 
					// Hide the install button
					setShowInstallButton(false);
				});
		}
	}
	const handleDismissCard = () => { 
		setShowCard(false);
	};

	return (
		<>
			{showInstallButton && showPopup && type === 'popup' && (
				<div className={classNames(styles.pwaWrapper,showInstallButton && showCard ? styles.show : '' , !showCard && styles.hide)}>
					{(
						<Card className={styles.pwaCard}>
							<div className="d-flex m-2 mt-3">
								{window.appConfig.brand === 'pv' ?
                                <img src="./images/provestor-icon-sq-192.png"
                                 alt="Inni" className={styles.pwaIcon} /> :
                                <img src="./images/inni-icon-sq-192.png" alt="Inni" className={styles.pwaIcon} />}
								{window.appConfig.brand !== 'pv' ? <div className="mx-2 my-1">
									<h5><b>Inni Accounts</b></h5>
									<small><a href="https://m.inniaccounts.co.uk/">m.inniaccounts.co.uk</a></small>
								</div> :
                                <div className="mx-2 my-1">
                                    <h5><b>Provestor</b></h5>
                                    <small><a href="https://m.provestor.co.uk/">m.provestor.co.uk</a></small>
                                    </div>
                                }
							</div>
							<CloseButton onClick={handleDismissCard} className="position-absolute" style={{top:'15px',right:'15px'}}/>
							{showCard && (
								<div className={styles.pwaButtonWrapper}>
									<Button variant="primary" onClick={handleInstallClick} mobile label="ADD TO HOME SCREEN"/>
								</div>
							)}
						</Card>
					)}
				</div>
			)
			}
			{showInstallButton && type === 'sidebar' && (
					<div id={styles.sideBarInstallButton} onClick={handleInstallClick}>
                    <FontAwesomeIcon icon={faDownload} />&nbsp;Install&nbsp;app
                </div>
				)
			}
		</>
	);
}

 
export default PWADownloadButton;
