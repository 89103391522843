import SetupWizard from '../../features/SetupWizard/SetupWizard';
import { Action, Entity } from '../../utils/EntityAction';
import { EntityRoute, RouteContext } from './routeTypes';

const setupWizard:Array<EntityRoute> = [
    {
        path: '/company/:cid/setupwizard',
        exact: true,
        context: RouteContext.Company,
        action: Action.List,
        entity: Entity.SetupWizard,
        component: SetupWizard
    },
    {
        path: '/company/:cid/setupwizard/:page',
        exact: true,
        context: RouteContext.Company,
        action: Action.Read,
        entity: Entity.SetupWizard,
        component: SetupWizard
    }
]

export default setupWizard;

