import React from 'react';
import  { Brand as BrandConfig }  from '../utils/configHelper';

/*
    Think before adding properties here:
        Can it go in the dynamic terms returned for a company?
        Should it not be in a stylesheet / CSS variable?
        Check with JNP first, please
    
    The test:
        Do you need this property even if you're doing something that isn's company related?
            Yes - put it here
            No - use terms

*/

export interface Brand {
    name: string,
    legalName: string,
    logoLightUrl: string,
    logoDarkUrl: string,
    logoWhiteUrl?: string,
    cssVariablesClassName: string,
    favIconUrl: string
}

export interface BrandContextInterface {
    brand: Brand,
    overrideBrand?: (brand:BrandConfig|undefined) => void
}

const BrandContext = React.createContext<BrandContextInterface>({brand: {name: '', legalName: '', logoDarkUrl: '', logoLightUrl: '', cssVariablesClassName: '', favIconUrl: ''}});

export default BrandContext;


