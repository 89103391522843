import { faCircle, faDotCircle, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { DatagridMonth, DatePicker } from '../../api/inni/data-contracts';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import useIsMobile from '../../hooks/useIsMobile';
import { getShortMonthFromNum } from '../../utils/formatDate';
import styles from './Filter.module.scss';
import stylesV8 from './FilterV8.module.scss';
import { FilterYearSelector } from './FilterYearSelector';
import * as Icons from '../../layouts/Components/icons';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';

// Legacy sources:
// https://innidev.visualstudio.com/inni/_git/inni?path=%2FWebsite%2FassetsV6%2Fjs%2Finni.datagrid.views.js
// Line 449+
// https://innidev.visualstudio.com/inni/_git/inni?path=%2FWebsite%2FassetsV6%2Fjs%2Finni.datagrid.models.js


/*
    TODO from backbone:
        If using text search, select all months
        


*/

export interface FilterProps {
    getFilter: (yearCode: string|undefined) => Promise<DatePicker>
    yearChanged: (yearCode: string|undefined) => void,
    monthChanged: (startDate: Date, endDate: Date) => void,
    searching?: boolean,
    year?: string | undefined,
    newMonth?: number | undefined | null,
    clearNewMonth?: () => void,
    watchId?: number;
    onlyFinancialYears?: boolean;
    defaultYear?: string;
    disabledSelector?: boolean;
    isDataLoading?:boolean;
}

export const FilterLoading = () => {
    return (
        <table className={styles.filter}>
            <tbody>
                <tr>
                    <td style={{width:'30px'}}><FontAwesomeIcon icon={faCircle}/></td>
                    {[...Array(12)].map((x,i) => ( <td key={i}><LoadingPlaceholder width="70%" /></td> ))}
                    <td style={{width:'120px'}}><LoadingPlaceholder width="80%" /></td>
                </tr>
            </tbody>
        </table>
    )
}

export const Filter = ({getFilter, yearChanged, monthChanged, searching, year, newMonth, clearNewMonth, watchId, onlyFinancialYears, defaultYear, disabledSelector,isDataLoading}:FilterProps) => {
    
    const [datePicker, setDatePicker] = useState<DatePicker|undefined>(undefined);

    // [fromMonth, toMonth] 
    const [dateSelection, setDateSelection] = useState<[DatagridMonth|undefined, DatagridMonth|undefined]>([undefined, undefined]);
    const [preSearchDateSelection, setPreSearchDateSelection] = useState<[DatagridMonth|undefined|null, DatagridMonth|undefined|null]>([undefined, undefined]);

    const companyContext = useContext(CompanyContext);
    const v8Styling = (companyContext.company?.useV8UI);
    const styling = v8Styling ? stylesV8 : styles;
 

    const onYearChange = useCallback((year?: string) => {
        if (year) {
            getFilter(year).then(dp => {
                setDatePicker(dp); 
                const startMonth = dp.months?.find(m => m.value === dp.currentMonthStart);
                const endMonth = dp.months?.find(m => m.value === dp.currentMonthEnd);
                setDateSelection([startMonth, endMonth]);            
            });
            yearChanged(year);
        }
    }, [getFilter, yearChanged])

    // On first mount, call without a year to get defaults from server
    useEffect(() => {
        if (year === undefined) { // this allows us to reload the filter by setting the year to undefined...
            getFilter(undefined).then(
                dp => {
                    setDatePicker(dp);
                    const startMonth = dp.months?.find(m => m.value === dp.currentMonthStart);
                    const endMonth = dp.months?.find(m => m.value === dp.currentMonthEnd);

                    setDateSelection([startMonth, endMonth]);
                    yearChanged(dp.currentYear);
                }
            ).then(() => defaultYear && onYearChange(defaultYear));
        } 
    }, [yearChanged, year, watchId, getFilter, defaultYear, onYearChange])
   
    //Alter date range if needed
    useEffect(() => {
        if(newMonth !== undefined && newMonth !== null && year) {
            getFilter(year).then(
                dp => {
                    setDatePicker(dp);
                    const startMonth = dp.months?.find(m => m.name === getShortMonthFromNum(newMonth));
                    const endMonth = dp.months?.find(m => m.name === getShortMonthFromNum(newMonth));
                    setDateSelection([startMonth, endMonth]);
                    yearChanged(dp.currentYear);
                    clearNewMonth && clearNewMonth()
                }
            );
        }
    }, [newMonth, year, clearNewMonth, getFilter, yearChanged])

    useEffect(() => {
        const startMonth = dateSelection[0];
        const endMonth = dateSelection[1];

        if (startMonth?.startDate && endMonth?.endDate) {
            monthChanged(new Date(startMonth?.startDate), new Date(endMonth?.endDate));
        }

    }, [dateSelection, monthChanged])

    const isMobile = useIsMobile()


    const onSelectAllClick = useCallback(() => {
        const months = datePicker?.months;
        if (months) {
            setDateSelection ([
                _.first(months),
                _.last(months)]);
        }
    }, [datePicker?.months])

    const [searchState, setSearchState] = useState(false);
    useEffect(() => {
        if(searchState !== searching) {
            if (searching) {
                setPreSearchDateSelection([dateSelection[0], dateSelection[1]]);
                onSelectAllClick();
            } else {
                const startMonth = preSearchDateSelection[0];
                const endMonth = preSearchDateSelection[1];
                if (startMonth && endMonth) {
                    setDateSelection([startMonth, endMonth]);
                }
            }
        }
        setSearchState(searching || false)
    }, [onSelectAllClick, searching, dateSelection, preSearchDateSelection, searchState])


    const onMonthClick = (month: DatagridMonth|undefined, shiftKey: boolean) => {
        setPreSearchDateSelection([null, null]);
        if (shiftKey) {
            if (month?.value && dateSelection[0]?.value && (month?.value < dateSelection[0]?.value)) {
                setDateSelection([month, dateSelection[1]])
            } else {
                setDateSelection([dateSelection[0], month])
            }
        } else {
            // Single month
            setDateSelection([month, month]);
        }
    }

    const isSelected = (month: DatagridMonth) => {
        if (month.value && dateSelection[0]?.value && dateSelection[1]?.value) {
            return (month.value <= dateSelection[1].value && month.value >= dateSelection[0].value );
        }
        return false;
    }

    const allSelected = () => {
        const months = datePicker?.months;
        if (months) {
            return (
                _.first(months)?.value === dateSelection[0]?.value && 
                _.last(months)?.value === dateSelection[1]?.value );
        }
        return false;
    }

    return (<table data-cy="filterTable" className={styling.filter}>
            <tbody>
                <tr>
                    <td onClick={onSelectAllClick} style={{maxWidth:'30px', paddingLeft: '0.25rem'}}  className={classNames({[styling.selected]: allSelected()})}><FontAwesomeIcon icon={allSelected() ? faDotCircle : faCircle}/></td>
                    {datePicker && 
                        <>
                            {datePicker.months?.map(month => (
                                <td data-cy="month" key={month.value} onClick={(e) => onMonthClick(month, e.shiftKey)} 
                                    className={classNames(styling.month, {
                                        [styling.selected]: isSelected(month),
                                        [styling.empty]: month.empty,
                                        [styling.warning]: month.warning
                                    })}>
                                    { (!v8Styling && month.warning) && <FontAwesomeIcon style={{marginRight: '10px'}} icon={faExclamationTriangle} /> }
                                    <b>{isMobile && month.name ? month.name[0] : month.name}</b>
                                    { (v8Styling && month.warning) && <Icons.WarningTriangleV8 className="ml-1" /> }
                                </td>
                            ))}
                            <td style={{minWidth:'120px', padding: 0}}  className={classNames({[styling.selected]: allSelected()})}>
                                <FilterYearSelector
                                    disabledSelector={disabledSelector}
                                    onlyFinancialYears={onlyFinancialYears}
                                    datePicker={datePicker}
                                    yearChanged={onYearChange}
                                    isDataLoading={isDataLoading}
                                    v8Styling={v8Styling}
                                />
                            </td>

                        </>                
                    }
                    {!datePicker && 
                        <td>&nbsp;</td>
                    }
                </tr>
            </tbody>
        </table>)
    
}