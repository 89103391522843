import { FormikErrors, FormikHelpers } from 'formik';
import React, { useContext, useEffect, useState } from 'react'
import { TenancyAsDetailed, TenancyPostModel } from '../../../api/inni/data-contracts'
import { Tenancies } from '../../../api/inni/Tenancies';
import CompanyContext from '../../../context/CompanyContext';
import { LettableUnitListItem } from '../../../hooks/lists/useLettableUnitsList';
import { useAssertPermission } from '../../../hooks/useAssertPermission';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { Entity, Action } from '../../../utils/EntityAction';
import { TenancyEditForm } from './TenancyEditForm';

  
export interface DetailsEditProps {
    id: number,
    tenancy: TenancyAsDetailed, 
    units: LettableUnitListItem[],
    close: () => void,
    reloadTenancy: () => void
} 

export const DetailsEdit = ({id, tenancy, units, close, reloadTenancy}:DetailsEditProps) => {
    useAssertPermission(Entity.Tenancy, Action.Edit);
    const companyContext = useContext(CompanyContext);
    const tenanciesApi = useInniAPI(Tenancies,[400]);
    
    const [initialValue, setInitialValue] = useState<TenancyPostModel>({startDate: '', tenantEmail: '', propertyLettableUnitId: 0});

    useEffect(() => {
        const t:TenancyPostModel = {
            ...tenancy
        }
        setInitialValue(t);
    }, [tenancy])

    const formSubmit = (values: TenancyPostModel, actions: FormikHelpers<TenancyPostModel>) : Promise<void>=> {
        return new Promise((resolve, reject) => {
            if (tenanciesApi) {
                tenanciesApi.update(companyContext.cid, id, values)
                .then(data => {
                    reloadTenancy();
                    close();
                    resolve();
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } else {
                reject();
            }
        })
    }

    const formValidate =  (values: TenancyPostModel) : Promise<FormikErrors<TenancyPostModel>> => {
        return new Promise((resolve, reject) => {
            if (tenanciesApi) {
                return tenanciesApi.validateUpdate(companyContext.cid, id, values)
                .then(() => resolve({}))    
                .catch(error => resolve(error.error))            
            } else {
                reject();
            }  
        })          
    }


    return (
        <>
            <TenancyEditForm initialValues={initialValue} readOnlyFields={tenancy.editability?.readOnlyFields} formSubmit={formSubmit} formValidate={formValidate} onCancelClick={close} lettableUnits={units} /> 
        </>
    )
}