import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import PortfolioCalendar from "../../features/PortfolioDashboard/PortfolioCalendar";

const portfolioCalendar:Array<EntityRoute> = [
    {
        path: '/company/:cid/portfoliocalendar',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.CompanyDashboard,
        component: PortfolioCalendar
    }
]

export default portfolioCalendar;

