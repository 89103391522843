import React, { FC } from 'react';
import { Card, Image } from 'react-bootstrap';
import styles from './BankAccountCard.module.scss';
import { Button } from '../../../../../elements/Button/Button';
import bankStyles from'../../BankSelection.module.css'
import { useHistory } from 'react-router-dom';

type Props = {
	image: string;
	title: string;
	subtitle: string;
	interest: string;
	monthlyFee: string;
	overdraft: string;
	thingsToCheckList: string[];
	thingsWeLikeList: string[];
	isRecommended?:boolean;
	link:string;
};

const BankAccountCard: FC<Props> = ({
	image,
	title,
	subtitle,
	interest,
	monthlyFee,
	overdraft,
	thingsToCheckList,
	thingsWeLikeList,
	isRecommended,
	link
}) => {
	const renderList = (array: string[]) => array.map((item) => <li className={bankStyles.normal} key={item}>{item}</li>);

    const handleButtonClick = () => {
      window.location.href=link
    };
	return (
		<div style={{width:"100%"}}>
		 	{isRecommended && 
				<p className={`${bankStyles.pBold} ${bankStyles.bankRecommended}`}>Provestor Recommends</p> 
			}
			
			<Card className={isRecommended ? `${styles.bankAccountCard} ${styles.recommended}` : styles.bankAccountCard}>
				<div className={styles.cardContent}>
					<Card.Header className={styles.cardHeader}>
						<div className="d-flex align-items-center">
							<div className={styles.imageWrapper}>
								<Image width="50" height="50" src={image} alt={title} />
							</div>
							<div>
								<p className={bankStyles.SectionTitle}>{title}</p>
								<p className={`${bankStyles.normal} ${bankStyles.bankSubtitle}`}>{subtitle}</p>
							</div>
						</div>
						<Button variant="primary" onClick={handleButtonClick} label="Go to site" />
					</Card.Header>

					<Card.Body className={styles.cardBody}>
						<div>
							<small className={bankStyles.pSmall}>Interest</small>
							<b className={bankStyles.pBold}>{interest}</b>
						</div>
						<div>
							<small className={bankStyles.pSmall}>Monthly fee</small>
							<b className={bankStyles.pBold}>{monthlyFee}</b>
						</div>
						<div>
							<small className={bankStyles.pSmall}>Overdraft</small>
							<b className={bankStyles.pBold}>{overdraft}</b>
						</div>
					</Card.Body>

					<Card.Footer className={styles.cardFooter}>
						<ul>
							<small className={bankStyles.pSmall}>What we like</small>
							{renderList(thingsWeLikeList)}
						</ul>

						<ul>
							<small className={bankStyles.pSmall}>Things to check</small>
							{renderList(thingsToCheckList)}
						</ul>
					</Card.Footer>
				</div>
			</Card>
		</div>
		
	);
};

export default BankAccountCard;
