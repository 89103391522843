import { EntityRoute, RouteContext } from './routeTypes';
import MobileBlocker from '../../layouts/MobileBlocker';

const Mobile:Array<EntityRoute> = [
    {
        path: '/company/:cid/mobilesupport',
        exact: true,
        context: RouteContext.Company,
        component: MobileBlocker
    }
]

export default Mobile;