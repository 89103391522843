/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  OBAuthExchangeRequest,
  OBAuthExchangeResponse,
  OBAuthorisationRequest,
  OBAuthorisationResponse,
  OpenBankingConsent,
  OpenBankingConsentReauthorisationResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class OpenBankingConsents<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags OpenBankingConsents
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/openbankingconsents
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<OpenBankingConsent[], any>({
      path: `/api/v2/companies/${companyId}/openbankingconsents`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OpenBankingConsents
   * @name CreateFromAuthRequest
   * @request POST:/api/v2/companies/{companyId}/openbankingconsents/createfromauthrequest
   * @secure
   */
  createFromAuthRequest = (companyId: number, request: OBAuthorisationRequest, params: RequestParams = {}) =>
    this.request<OBAuthorisationResponse, any>({
      path: `/api/v2/companies/${companyId}/openbankingconsents/createfromauthrequest`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OpenBankingConsents
   * @name ExchangeOneTimeToken
   * @request POST:/api/v2/companies/{companyId}/openbankingconsents/exchangeonetimetoken
   * @secure
   */
  exchangeOneTimeToken = (companyId: number, request: OBAuthExchangeRequest, params: RequestParams = {}) =>
    this.request<OBAuthExchangeResponse, any>({
      path: `/api/v2/companies/${companyId}/openbankingconsents/exchangeonetimetoken`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OpenBankingConsents
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/openbankingconsents/delete/{id}
   * @secure
   */
  delete = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/openbankingconsents/delete/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags OpenBankingConsents
   * @name ReauthoriseOpenBankingConsent
   * @request POST:/api/v2/companies/{companyId}/openbankingconsents/reauthoriseopenbankingconsent
   * @secure
   */
  reauthoriseOpenBankingConsent = (companyId: number, query: { id: number }, params: RequestParams = {}) =>
    this.request<OpenBankingConsentReauthorisationResponse, any>({
      path: `/api/v2/companies/${companyId}/openbankingconsents/reauthoriseopenbankingconsent`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
