import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import Assets from "../../features/Assets/AssetPage";
import AssetCreateV8Modal from "../../features/Assets/components/CreateAssetV8";

const assets:Array<EntityRoute> = [
    {
        path: '/company/:cid/asset',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.AssetV7,
        component:Assets
    },

    {
        path: '/company/:cid/asset/new',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.AssetV7,
        action: Action.Create,
        component: AssetCreateV8Modal
    }
]

export default assets;