import { faPaperPlane } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import CompanyContext from '../../../context/CompanyContext'
import UserContext from '../../../context/UserContext'
import styles from '../Dashboard.module.scss'
import stylesV8 from '../Dashboard_V75.module.scss'

const ProvReferral = () => {
    const companyContext = useContext(CompanyContext)
    const v8Styling = companyContext.company?.useV8UI;
    const styling = v8Styling ? stylesV8 : styles;

    return (
        <Card className={styling.referral}>
            { !v8Styling && <FontAwesomeIcon size="2x" className={styling.planeImage} icon={faPaperPlane} /> }
            <Card.Body className={styling.dashboardCardBody} style={{marginRight: '80px'}}>
                <h4 className={styling.referralTitleText}>Refer a friend and get £50 credit</h4>
                <p>Referring a friend or colleague is a great way to tell others about Provestor. What's more, there's no limit. Keep referring and we'll keep giving you a £50 credit!</p>
                <p>To refer someone, simply share your personal referral code. When they start paying their monthly fee, we'll credit you both with £50.</p>
                <div className={styling.referralBoxProv}>
                    Your personal referral code: CA{companyContext.cid}
                </div>
                As with all offers, there are a few simple rules so everyone plays fair. You can read them <a target="_new" href="https://www.provestor.co.uk/help/start/refer-a-friend-or-colleague-terms-conditions">here.</a>
            </Card.Body>
        </Card>
    )
}

const InniReferral = () => {

    const companyContext = useContext(CompanyContext)
    const userContext = useContext(UserContext)

    const [rCopy, setRCopy] = useState(false)

    const copyLink = () => {
        let v = `CA${companyContext.cid}`

        navigator.clipboard.writeText(v);
        setRCopy(true)
        setTimeout(() => { setRCopy(false) }, 2000);
    }

    const subject = encodeURIComponent(`${userContext.user?.fullName} is giving you 10% off inniAccounts`)
    const body = encodeURIComponent(`${userContext.user?.firstName} thinks you'll love inniAccounts! \n\n` + 
                                    `Get started with our proactive, complete accountancy service for contractors, consultants and freelancers. \n\n` +
                                    `Mention your friend's referral code CA${companyContext.cid} when joining to get 10% off your accountancy fees for your first year.` +
                                    ` Visit www.inniaccounts.co.uk or call us on 01332 460010 to get started. \n\n`)    

    return (
        <Card className={styles.referral}>
            <FontAwesomeIcon size="2x" className={styles.planeImage} icon={faPaperPlane} />
            <Card.Body className={styles.dashboardCardBody}>                
                <h4 className={styles.referralTitleText} style={{marginRight: '65px'}}>Earn a £250 voucher when you recommend a friend</h4>                
                <span style={{display: 'block', marginRight: '65px'}}>Refer a friend to inniAccounts and we'll give you a £250 voucher or charitable donation - plus, they'll get 10% off for a year. Simply share your personal referral code. <a href="https://www.inniaccounts.co.uk/about-us/refer-a-friend/" target="_blank">Learn more about referrals</a></span>

                <div className={styles.inniRef}>
                    <div className={styles.referralBoxInni}>
                        <p className={styles.referralBoxInniLink}>CA{companyContext.cid}</p>
                    </div>
                    <div className={styles.btnContainer}>
                        <Button style={{background: 'var(--topbar-background-color)', border: 'none'}} variant="primary" onClick={() => copyLink()}><b>{rCopy ? 'Code copied' : 'Copy code >'}</b></Button>
                        <Button style={{background: 'var(--rainforest-green)', border: 'none'}} target="_new" href={`mailto:?subject=${subject}&body=${body}`}><b>Email to a friend {'>'}</b></Button>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

export {ProvReferral, InniReferral}