/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ClassifyTranPostModel, ImportedTransactionModel, ImportedTransactionPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ExpensesImport<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ExpensesImport
   * @name UploadCsv
   * @summary Upload a CSV - it won't do any processing, this is JUST upload. Call ProcessUploaded to do more...
   * @request POST:/api/v2/companies/{companyId}/expensesimport/UploadCSV
   * @secure
   */
  uploadCsv = (companyId: number, data: { fileToUpload: File }, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v2/companies/${companyId}/expensesimport/UploadCSV`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExpensesImport
   * @name ProcessUploaded
   * @summary Process an uploaded CSV, returning alist of transactions therein. Nothing is saved to teh DB, the UI should pick the ones it wants to post
   * @request GET:/api/v2/companies/{companyId}/expensesimport/{expenseAccountId}/ProcessUploaded/{id}
   * @secure
   */
  processUploaded = (companyId: number, expenseAccountId: number, id: number, params: RequestParams = {}) =>
    this.request<ImportedTransactionModel[], any>({
      path: `/api/v2/companies/${companyId}/expensesimport/${expenseAccountId}/ProcessUploaded/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExpensesImport
   * @name ImportExpenses
   * @summary After picking the transactions, post the iones to save.
   * @request POST:/api/v2/companies/{companyId}/expensesimport/{expenseAccountId}/ImportExpenses
   * @secure
   */
  importExpenses = (
    companyId: number,
    expenseAccountId: number,
    expenses: ImportedTransactionPostModel[],
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/expensesimport/${expenseAccountId}/ImportExpenses`,
      method: "POST",
      body: expenses,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExpensesImport
   * @name ClassifyExpenses
   * @summary Use the AI classifier  to classify the expenses posted. Nothing saved, the results are returned
   * @request POST:/api/v2/companies/{companyId}/expensesimport/ClassifyExpenses
   * @secure
   */
  classifyExpenses = (companyId: number, ctpm: ClassifyTranPostModel[], params: RequestParams = {}) =>
    this.request<ClassifyTranPostModel[], any>({
      path: `/api/v2/companies/${companyId}/expensesimport/ClassifyExpenses`,
      method: "POST",
      body: ctpm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
