import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import ExpenseImport from "../../features/ExpenseImport/ExpenseImportPage";

const expenseImport:Array<EntityRoute> = [
    {
        path: '/company/:cid/expenseImport/:accountId',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.ExpenseImportV7,
        component: ExpenseImport
    }
]

export default expenseImport;

