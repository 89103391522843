import { useContext, useEffect, useState } from "react"
import { Asset, Category, LettingStatement, LettingStatementPostModel } from "../../../../api/inni/data-contracts";
import { useParams } from "react-router-dom";
import { useInniAPI } from "../../../../hooks/useInniAPI";
import { LettingStatements } from "../../../../api/inni/LettingStatements";
import CompanyContext from "../../../../context/CompanyContext";
import CreateLettingStatementForm from "./CreateLettingStatementForm";
import { FormikErrors, FormikHelpers, FormikProps } from "formik";
import { Action, Entity } from "../../../../utils/EntityAction";
import { useNavigateToEntity } from "../../../../hooks/useNavigateToEntity";
import React from "react";
import { Assets } from "../../../../api/inni/Assets";
import { Categories } from "../../../../api/inni/Categories";

interface RouteParams {
	id: string;
}

const CreateLettingStatement = ( ) => {
    const companyContext = useContext(CompanyContext);
	const companyId = companyContext.cid;
    const navigateToEntity = useNavigateToEntity(); 
    const formikRef = React.useRef<FormikProps<LettingStatementPostModel>>(null);
    
    const [agentStatement, setAgentStatement] = useState<LettingStatement>();
    const agentStatementId = parseInt(useParams<RouteParams>().id);
    const lettingStatementApi = useInniAPI(LettingStatements, [400]);
    const categoriesApi = useInniAPI(Categories, [400]);
    const [showCreateModal, setShowCreateModal] = useState(false)
    const [accounts, setAccounts] = useState<Category[]>();

    const assetApi = useInniAPI(Assets, [400]);
    const [assets, setAssets] = useState<Asset[]>([])

    const [filesToUpload, setFilesToUpload] = useState<File[]|undefined|null>(undefined);

    const [errorMessage, setErrorMessage] = useState<string>();
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
		if (!agentStatement && lettingStatementApi) {
			lettingStatementApi.getOne(companyId, agentStatementId)
            .then((response) => {
                let initialValues = {...response.data}

                // We ignore the previous draft data here,
                // this is because it's not a concept in V8 but also there are issues with the backend unexpectedly
                // sending negative numbers which it doesn't like us sending back!
                initialValues.lettingStatementLines = [];
                setAgentStatement(initialValues);

                // backend for this convenience call is not ready/working

                // lettingStatementApi.nextStatementNumber(companyId)
                // .then((res) =>{
                //     let initialValues = {...response.data}
                //     // backend for this convenience call is not ready/working
                //     initialValues.statementNumber = parseInt(res.data)

                //     // We ignore the previous draft data here,
                //     // this is because it's not a concept in V8 but also there are issues with the backend unexpectedly
                //     // sending negative numbers which it doesn't like us sending back!
                //     initialValues.lettingStatementLines = [];
                //     setAgentStatement(initialValues);
            })
            .catch((error) =>{
                console.error(error)
                // setAgentStatement(response.data);
            })
            setShowCreateModal(true)
		}
	}, [agentStatement, agentStatementId, lettingStatementApi, companyId]);

    useEffect(() => {
		if(categoriesApi && accounts === undefined) {
			categoriesApi.getLsCategories(companyId).then(res => {
                // name/description comes from bookkeeping which need tweaking for the purposes of letting statements
                const overrideRentalCatDesc = res.data.map(item => (
                    item.linkedID === 'RentDeposits'
                        ? { ...item, name: 'Rent & deposits', overrideDesc: 'lettingstatement'}
                        : item
                ))
                setAccounts(overrideRentalCatDesc);
			})
		}
	}, [lettingStatementApi, companyId, accounts, categoriesApi]);

    useEffect(() => {
        if (assetApi && !assets) {
            assetApi.index(companyContext.cid)
            .then(x => {
                setAssets(x.data);
            });
        };
    }, [companyContext.cid, assetApi, assets]);

    // Unhelpfully backend doesn't have a consistent error message format...
    const handleErrorMessage = (response: any): void => {
        if (typeof response === 'string') {
            setErrorMessage(response);
        } else if (typeof response === 'object' && response !== null) {
            const firstKey = Object.keys(response)[0];
            setErrorMessage(response[firstKey]);
        } else {
            setErrorMessage("Unexpected response format");
        }
    };

    const uploadFiles = () => {
        if (lettingStatementApi && filesToUpload && filesToUpload?.length > 0) {
            lettingStatementApi.uploadLettingStatement(companyId, agentStatementId, { fileToUpload: filesToUpload[0] }).then((response) => {
                if (response.status === 201) {
                    completeStatement();
                } else {
                    console.warn(`Unexpected return code '${response.status}' uploading completion statement, expected '201'`);
                    setErrorMessage('Sorry, something went wrong. Please try again.');
                    setIsSaving(false);
                }
            }).catch((error) => {
                setIsSaving(false);
                console.error('Failed to upload letting statement', error.error);
                setErrorMessage('Sorry, something went wrong. Please try again.');
            })
        } else {
            setErrorMessage('Please upload your completion statement to finish');
            setIsSaving(false);
        }
    }

    const completeStatement = () => {
        lettingStatementApi?.markAsDetailsCompleted(companyId, agentStatementId).then((response) => {
            if (response.status === 200) {
                navigateToEntity(Entity.BookkeepingV8, Action.List, {'activePage' : 'lettingstatements'});
            } else {
                console.warn(`Unexpected return code '${response.status}' completing statement, expected '200'`);
                setErrorMessage('Sorry, something went wrong. Please try again.');
                setIsSaving(false);
            }
        }).catch((error) => {
            setIsSaving(false);
            // Bad request, we probably have a user friendly message
            if (error.status === 400) {
                handleErrorMessage(error.error)
            } else {
                console.error('Failed to complete letting statement', error.error);
                setErrorMessage('Sorry, something went wrong. Please try again.');
            }
        })
    }

    const forceSave = () => {
        lettingStatementApi?.forceSave(companyId, agentStatementId).then((response) => {
            if (response.status === 200) {
                console.info('Force save success')
                navigateToEntity(Entity.BookkeepingV8, Action.List, {'activePage' : 'lettingstatements'});
            } else {
                console.warn('Unexpected return code')
                setErrorMessage('Sorry, something went wrong. Please try again.')
                setIsSaving(false);
            }
        }).catch((error) => {
            // Bad request, we probably have a user friendly message
            if (error.status === 400) {
                console.error('Failed to force save', error.error)
                if (error.error.hasOwnProperty('lettingStatementLines'))
                    setErrorMessage(error.error['lettinStatementLines']);
                else 
                    handleErrorMessage(error.error)

            } else {
                console.error('Failed to force save', error.error)
                setErrorMessage('Sorry, something went wrong. Please try again.')
            }
        });
    }

    const handleSubmit = async (values: LettingStatementPostModel, actions: FormikHelpers<LettingStatementPostModel>): Promise<void> => {
        setErrorMessage(undefined);
        setIsSaving(true);

		let newValues = { ...values };
		if(newValues.lettingStatementLines) {
			newValues.lettingStatementLines.forEach(x => {
				if(x.propertyId === 0) x.propertyId = undefined
			})
		}

        // Saving process is a bit convoluted here (it was designed for drafts feature not currently used)
        // Update statement > upload attachment > complete statement
        // If we fail completing statement because of amount mismatch we force save - we will have already prompted user about this
        lettingStatementApi?.updateLettingStatement(companyId, agentStatementId, newValues).then((response) => {
            if (response.status === 200) {
                uploadFiles();
            } else {
                console.warn(`Unexpected return code '${response.status}' uploading completion statement, expected '200'`);
                setErrorMessage('Sorry, something went wrong. Please try again.')
                setIsSaving(false);
            }
        }).catch((error) => {
            setIsSaving(false);
            // Bad request, we probably have a user friendly message
            if (error.status === 400) {
                console.error('Failed to complete letting statement', error.error);
                handleErrorMessage(error.error)
            } else {
                console.error('Failed to complete letting statement', error.error);
                setErrorMessage('Sorry, something went wrong. Please try again.');
            }
        })
    }

    const handleValidate = (values: LettingStatementPostModel) : Promise<FormikErrors<LettingStatementPostModel>> => {
        return new Promise<FormikErrors<LettingStatementPostModel>>((resolve, reject) => {
            if(lettingStatementApi){
                lettingStatementApi.validateUpdate(companyId, {lettingStatementId:  agentStatementId}, values)
                .then((response) => {
                        resolve({})
                }).catch((error) => {
                    console.error(error.error)
                    resolve(error.error);
                })
            }
            else{
                reject()
            }
        })
	}

    return (
       <>
            {showCreateModal && agentStatement &&
                <CreateLettingStatementForm 
                    initialValues={agentStatement} 
                    onSubmit={handleSubmit}
                    onValidate={handleValidate}
                    isIncoming = {agentStatement.netPaymentToLandlord > 0}
                    hide ={() => navigateToEntity(Entity.BookkeepingV8, Action.List, {'activePage' : 'lettingstatements'})}
                    formikRef={formikRef}
                    accounts={accounts}
                    assets={assets}
                    filesToUpload={filesToUpload}
                    setFilesToUpload={setFilesToUpload}
                    errorMessage={errorMessage}
                    isSaving={isSaving}
                    forceSave={forceSave}
                    setErrorMessage={setErrorMessage}
                />
            }
       </>
        
    );

}

export default CreateLettingStatement
