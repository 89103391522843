import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SetupWizardForm } from '../../../api/inni/data-contracts';
import { Button } from '../../../elements/Button/Button';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { Entity } from '../../../utils/EntityAction';
import styles from '../SetupWizard.module.css'
import { WarningText } from './WarningText';

const EmployeesAndShareholders = ({setNextPage, setPrevPage, formState} : {setNextPage : () => void, setPrevPage : () => void, formState : SetupWizardForm}) => {

    const navToEntity = useNavigateToEntity()

    return (
        <div>
            <p style={{cursor: 'pointer'}} onClick={setPrevPage}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
            <h1>Employees and shareholders</h1>
            <p>Please enter your employees' and shareholders' details below and select how often you would like to pay your employees. To enable a payroll for an employee, click on the 'enable PAYE' button and complete the required information.</p>
            <hr />
            {formState.setupEmployees ? 
            <p>The employees for this company have already been confirmed, please reset the wizard on the confirmation page to make changes.</p> 
            : <>
                <p>Company employees can be setup using the employees page, this can be found in the menu or click the button below</p>
                <Button variant="primary" onClick={() => navToEntity(Entity.PersonV7)}>Go to employees page</Button>
            </>}
            <div className={styles.formButtons}>
                <Button onClick={setNextPage} variant="change">Continue</Button>
            </div>
            <WarningText />
        </div>
    )
}

export default EmployeesAndShareholders