/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PropertyCertificate, PropertyCertificatePostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PropertyCertificates<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags PropertyCertificates
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/properties/{propertyId}/certificates
   * @secure
   */
  index = (companyId: number, propertyId: number, params: RequestParams = {}) =>
    this.request<PropertyCertificate[], any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/certificates`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PropertyCertificates
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyId}/certificates
   * @secure
   */
  create = (
    companyId: number,
    propertyId: number,
    postmodel: PropertyCertificatePostModel,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/certificates`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PropertyCertificates
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyId}/certificates/Validate
   * @secure
   */
  validateCreate = (
    companyId: number,
    propertyId: number,
    postmodel: PropertyCertificatePostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/certificates/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PropertyCertificates
   * @name UploadCertificate
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyId}/certificates/{certificateId}/uploadCertificate
   * @secure
   */
  uploadCertificate = (
    companyId: number,
    propertyId: number,
    certificateId: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/certificates/${certificateId}/uploadCertificate`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PropertyCertificates
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/properties/{propertyid}/certificates/{id}/Validate
   * @secure
   */
  validateUpdate = (
    companyId: number,
    propertyid: number,
    id: number,
    postmodel: PropertyCertificatePostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/certificates/${id}/Validate`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PropertyCertificates
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/properties/{propertyId}/certificates/{id}
   * @secure
   */
  update = (
    companyId: number,
    propertyId: number,
    id: number,
    postModel: PropertyCertificatePostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/certificates/${id}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PropertyCertificates
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/properties/{propertyId}/certificates/{id}
   * @secure
   */
  delete = (companyId: number, propertyId: number, id: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/certificates/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
