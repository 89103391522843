import { ApiConfig, HttpClient, RequestParams } from "./http-client";
import configHelper from '../../utils/configHelper';

export class InniAPIFactory {
    static getAPI<T extends HttpClient>(
        api: { new (p1: ApiConfig): T }, 
        getAccessToken: () => Promise<[string, Date | null] | null>,
        httpErrorHandler?: (error: Response) => void 
    ): Promise<{apiInstance: T, expiry: Date | null}> {
        
        return new Promise<{apiInstance: T, expiry: Date | null}>((resolve, reject) => { 
            getAccessToken().then((token) => {
                // Token is a tuple, [token, expiryDate]
                if (token && token[0]) {
                    let apiInstance = new api({
                        baseUrl: configHelper.apiUrl,
                        securityWorker:  () => {
                            let rp:RequestParams = {
                                headers: {
                                    Authorization: `Bearer ${token[0]}`
                                }
                            };
                            return rp;
                        }
                    })
                    if (httpErrorHandler) apiInstance.addErrorHandler(httpErrorHandler);

                    resolve({apiInstance: apiInstance, expiry: token[1]})
                } else {
                    console.error('No token at factory');
                    reject()
                }
            }).catch(() => {
                reject()
            })
        })
    }
}