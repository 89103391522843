import React from 'react';
import { CardWrapper } from './CardWrapper';
import styles from './DashboardCards.module.scss'

export interface SimpleKpiProps {
    label: string,
    helpText?: string,
    loading?: boolean,
    value?: string|number|React.ReactNode,
    children? : React.ReactNode,
    className?: string,
    height?: number,
    useSmallKpiValues?: boolean
} 

export const SimpleKpi = ({label, helpText, loading, value, children, className, height, useSmallKpiValues}: SimpleKpiProps) => {

    return (
        <CardWrapper label={label} helpText={helpText} loading={loading} className={className} height={height || 1}>
            <>
                {!loading && value &&
                    <div className={useSmallKpiValues ? styles.smallKpiValues : styles.simpleKpiValue}>{value}</div>
                }
                {!loading && children &&
                    <>{children}</>
                }
            </>
        </CardWrapper>
    )

}