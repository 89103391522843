import { useState } from "react";
import { Form } from "react-bootstrap";
import { add, isValid, setDate } from 'date-fns'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import styles from '../QuickEntry.module.css';
import { asYearMonthDay, dateIsValid } from "../../../utils/formatDate";

export const findPrevMonday = (d : Date): Date => {
    // if we enter an invalid date then it defaults to today
    if (!isValid(d)) {
        d = new Date()
    }
    let day = d.getDay();
    if (day !== 1) {
        let newDate = d;
        newDate.setDate(d.getDate() - (d.getDay() + 6) % 7);
        return newDate; 
    } else {
        return d;
    }
}

const WeekSelector = ({setStartDate} : {setStartDate : (d : Date) => void}) => {

    const addWeek = (d : Date): Date => {
        return add(d, {weeks: 1});
    }

    const takeWeek = (d : Date): Date => {
        return add(d, {weeks: -1});
    }
    
    const [date, setDate] = useState<string>(asYearMonthDay(findPrevMonday(new Date())))
    const [lastValidDate, setLastValidDate] = useState<Date>(findPrevMonday(new Date()))
    const [userTypingDate, setUserTypingDate] = useState(false)

    const handleDateChange = (d : Date) => {
        setStartDate(d);
        setDate(asYearMonthDay(d));
        setLastValidDate(d)
    }

    const trySetNewDate = (altDate?: string) => {
        //Alt date to avoid possible race condition
        const dateToUse = altDate || date
        if(dateIsValid(dateToUse) && asYearMonthDay(new Date(dateToUse)) !== asYearMonthDay(lastValidDate)) {
            const newDate = findPrevMonday(new Date(dateToUse))
            setStartDate(newDate);
            setLastValidDate(newDate)
            setDate(asYearMonthDay(newDate));
        }
        setUserTypingDate(false)
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.currentTarget.blur();
            setUserTypingDate(false)
        }
        else {
            setUserTypingDate(true)
        }
    }

    const handleInputChange = (val: string) => {
        setDate(val)
        if(!userTypingDate) {
            //They used the date picker, not manual entry
            trySetNewDate(val)
        }
    }

    return (
        <Form.Group className={styles.weekPickerFormGroup}>
            <div className={styles.pickerLeft} onClick={() => handleDateChange(takeWeek(lastValidDate))}><FontAwesomeIcon icon={faChevronLeft} /></div>
            <Form.Control 
                className="inputWithControls"
                onChange={(e) => handleInputChange(e.target.value)} 
                onBlur={() => trySetNewDate()}
                onKeyDown={handleKeyDown}
                value={date} 
                type="date">
            </Form.Control>
            <div className={styles.pickerRight} onClick={() => handleDateChange(addWeek(lastValidDate))}><FontAwesomeIcon icon={faChevronRight} /></div>
        </Form.Group>
    )

}

export default WeekSelector
