import { useEffect, useRef, useState } from 'react';
import ModalDialogbox from '../Elements/Modal/Modal';
import Form from 'react-bootstrap/Form';
import { Formik, FormikErrors, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { InputGroup } from 'react-bootstrap';
import styles from '../Invoice.module.css';
import InfoBanner from '../../../elements/InfoBanner/InfoBanner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import FormikField from '../../../elements/FormikField/FormikField';
import { InvoiceEmailModel, InvoiceEmailPostModel } from '../../../api/inni/data-contracts';
import classNames from 'classnames';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';

type EmailPdfTemplateProps = {
	setShow: (show: boolean) => void;
	onEmailSubmit: (values: InvoiceEmailPostModel, actions: FormikHelpers<InvoiceEmailPostModel>) => Promise<void>;
	showModal: boolean;
	emailDetails: InvoiceEmailModel | undefined | null;
	validateEmail: (values: InvoiceEmailPostModel) => Promise<FormikErrors<InvoiceEmailPostModel>>;
};

type Props = {
	onEmailSubmit: (values: InvoiceEmailPostModel, actions: FormikHelpers<InvoiceEmailPostModel>) => Promise<void>;
	emailDetails: InvoiceEmailModel | undefined | null;
	fromikRef: any;
	validateEmail: (values: InvoiceEmailPostModel) => Promise<FormikErrors<InvoiceEmailPostModel>>;
};

type EmailProps = {
	placeholder?: string;
	attachement?: string;
	label?: string;
	name: keyof InvoiceEmailModel;
	rows?: number;
	type?: 'textarea' | 'text' | 'number' | 'date' | 'select' | 'checkbox' | 'label';
};

function EmailPdfTemplate({ showModal, setShow, ...props }: EmailPdfTemplateProps) {
	const emailRef = useRef<FormikProps<FormikValues>>();

	const handleSubmit = () => {
		if (emailRef.current) {
			emailRef.current.handleSubmit();
		}
	};

	return (
		<ModalDialogbox
			setShow={setShow}
			children={<EmailTextFields fromikRef={emailRef} {...props} />}
			title="Send email"
			onClickSubmit={handleSubmit}
			show={showModal}
			buttonCancel="Cancel"
			buttonSubmit="Send"
		/>
	);
}

function EmailTextFields({ fromikRef, emailDetails, onEmailSubmit, validateEmail}: Props) {
	const [attachemnet, setAttachment] = useState<string | undefined>('Invoice.pdf');
	useEffect(() => {
		emailDetails && setAttachment(emailDetails?.attachmentDisplayName);
	}, [emailDetails]);

	return (
		<div className={classNames(styles.Emailpdf, 'mt-3')}>
			{emailDetails && emailDetails?.alertBody && emailDetails.alertTitle !== null && emailDetails.alertBody !== null && (
				<InfoBanner title={emailDetails?.alertTitle} body={emailDetails?.alertBody} type="info"></InfoBanner>
			)}
			{emailDetails ? (
				<Formik initialValues={emailDetails} onSubmit={onEmailSubmit} enableReinitialize validateOnChange={true} innerRef={fromikRef} validate={validateEmail}>
					{({ handleSubmit, errors }) => (
						<>
							<Form onSubmit={handleSubmit}> 
								<EmailInputGroup label="TO" name="to" />
								<EmailInputGroup label="FROM" name="from" />
								<EmailInputGroup name="cc" />
								<EmailInputGroup attachement={attachemnet} name="subject" placeholder="Email subject" />
								<EmailInputGroup name="body" placeholder="Enter email message" type="textarea" rows={12} />
							</Form>
						</>
					)}
				</Formik>
			) : (
				<>
					{new Array(4).fill(0).map((_, index) => (
						<LoadingPlaceholder height="40px" key={index} />
					))}
					<hr></hr>
					{new Array(12).fill(0).map((_, index) => (
						<LoadingPlaceholder height="15px" key={index} />
					))}
					<hr></hr>
				</>
			)}
		</div>
	);
}
export default EmailPdfTemplate;

const EmailInputGroup = ({ name, rows, type, label, attachement, placeholder }: EmailProps) => {
	return (
		<InputGroup className={styles.inputStyle}>
			{name !== 'body' && (
				<InputGroup.Text style={{ width: '100px', height:'40.91px', fontWeight: 'bold', textAlign: 'start', borderBottom: '1px solid var(--slate-blue-border-color)' }} >
					{label ? label : name.toUpperCase()}
				</InputGroup.Text>
			)}
			<FormikField<InvoiceEmailModel>
				noshadow
				name={name}
				showErrorLine
				className="p-0 m-0"
				type={type ? type : 'text'}
				rows={rows ? rows : undefined}
				placeholder={placeholder ? placeholder : 'Enter ' + name + ' email '}
				isTable
			/>
			{name === 'subject' && (
				<>
					<InputGroup.Text style={{height:'40.91px'}}>
						<FontAwesomeIcon icon={faPaperclip} />
						&nbsp;{attachement}
					</InputGroup.Text>
				</>
			)}
		</InputGroup>
	);
};
