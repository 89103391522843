import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { ReportSelectCardPlaceholder } from './Components/ReportSelectCard';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { LoadingShim } from '../../elements/LoadingShim/LoadingShim';
import styles from './Reporting.module.css';


export const ReportSelectPagePlaceholder = () => {
    return (
        <DefaultLayout title='Reports'>
            <>
                <h4>
                    <LoadingPlaceholder width="250px" />
                </h4>
                <div className={styles.cardContainer}>
                    <ReportSelectCardPlaceholder />
                    <ReportSelectCardPlaceholder />
                    <ReportSelectCardPlaceholder />
                </div>

                <br />

                <h4>
                    <LoadingPlaceholder width="250px" />
                </h4>
                <div className={styles.cardContainer}>
                    <ReportSelectCardPlaceholder />
                    <ReportSelectCardPlaceholder />
                    <ReportSelectCardPlaceholder />
                </div>
            </>
        </DefaultLayout>
    )
}

export const ReportPagePlaceholder = () => {
    return (
        <DefaultLayout title='Reports'>
            <div className={styles.reportContainer}>
                <LoadingShim />
            </div>
        </DefaultLayout>
    )
}
