import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import AtedDeclaration from "../../features/Declarations/Ated";

const atedDeclaration:Array<EntityRoute> = [
    {
        path: '/company/:cid/ated/:type/:id',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Property,
        component: AtedDeclaration
    },
]

export default atedDeclaration;
