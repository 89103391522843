/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { UploadedDocument, Valuation, ValuationPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Valuations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Valuations
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/properties/{propertyId}/valuations
   * @secure
   */
  index = (companyId: number, propertyId: number, params: RequestParams = {}) =>
    this.request<Valuation[], any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/valuations`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Valuations
   * @name Get
   * @summary For the edit page? I'm expecting another endpoint for Valuation stats...
   * @request GET:/api/v2/companies/{companyId}/properties/{propertyid}/valuations/{valuationid}
   * @secure
   */
  get = (companyId: number, propertyid: number, valuationid: number, params: RequestParams = {}) =>
    this.request<Valuation, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/valuations/${valuationid}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Valuations
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/properties/{propertyid}/valuations/{valuationid}
   * @secure
   */
  delete = (companyId: number, propertyid: number, valuationid: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/valuations/${valuationid}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Valuations
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/properties/{propertyid}/valuations/{Valuationid}/Validate
   * @secure
   */
  validateUpdate = (
    companyId: number,
    propertyid: number,
    valuationid: number,
    postmodel: ValuationPostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/valuations/${valuationid}/Validate`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Valuations
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/properties/{propertyid}/valuations/{Valuationid}
   * @secure
   */
  update = (
    companyId: number,
    propertyid: number,
    valuationid: number,
    postModel: ValuationPostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/valuations/${valuationid}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Valuations
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyid}/valuations/Validate
   * @secure
   */
  validateCreate = (companyId: number, propertyid: number, postmodel: ValuationPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/valuations/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Valuations
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyid}/valuations
   * @secure
   */
  create = (companyId: number, propertyid: number, postModel: ValuationPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/valuations`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Valuations
   * @name UploadDocument
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyId}/valuations/{valuationId}/uploadDocument
   * @secure
   */
  uploadDocument = (
    companyId: number,
    propertyId: number,
    valuationId: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<UploadedDocument, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/valuations/${valuationId}/uploadDocument`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Valuations
   * @name DeleteDocument
   * @request DELETE:/api/v2/companies/{companyId}/properties/{propertyId}/valuations/{valuationId}/deleteDocument
   * @secure
   */
  deleteDocument = (companyId: number, propertyId: number, valuationId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/valuations/${valuationId}/deleteDocument`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
