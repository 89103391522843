import { EntityRoute, RouteContext } from "./routeTypes";
import { LoginCallback } from "../../components/AuthenticatedUserWrapper/LoginCallback";

const authentication:Array<EntityRoute> = [
    {
        path: '/login',
        exact: true,      
        context: RouteContext.Public,
        component: LoginCallback
    }
]

export default authentication; 

