import { useFormikContext } from "formik";
import { PvSatrPostModel } from "../../../../api/inni/data-contracts";
import FormikField from "../../../../elements/FormikField/FormikField";
import styles from '../Income.module.scss'
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

export default function ChildBenefits (){

    const { values, setFieldValue } = useFormikContext<PvSatrPostModel>();

    const[showChildBenefit, setShowChildBenefit] = useState( 
                                                                !!(values.childBenefitHowManyChildren ||
                                                                    values.childBenefitHowMuch ||
                                                                    values.childBenefitThisEmployeeHighestEarner ||
                                                                    values.childBenefitStoppedReceivingOnDate
                                                                )
                                                            )
    
    useEffect(() =>{
        if(!showChildBenefit)
        {
            setFieldValue("childBenefitHowManyChildren", 0, false)
            setFieldValue("childBenefitHowMuch", 0, false)
            setFieldValue("childBenefitThisEmployeeHighestEarner", false, false)
            setFieldValue("childBenefitStoppedReceivingOnDate", undefined, false)

        }

    },[showChildBenefit])

    return(
        <>
            <Form.Group>
                <Form.Label>Did you receive child benefits in this tax year?</Form.Label>
                    <Form.Check
                        type='checkbox'
                        checked={showChildBenefit}
                        onChange={(e) => setShowChildBenefit(e.target.checked)}
                    />
            </Form.Group>
            
            {showChildBenefit &&
                <>
                    <FormikField<PvSatrPostModel> 
                        className={styles.formikNumberOnlyField}
                        name={"childBenefitHowManyChildren"}
                        label="For how many children?"
                        type="number" 
                        min="0"
                        onlyPositiveNumbers  
                        onlyIntegers                                   
                        regularLabel
                    />

                    <br />

                    <FormikField<PvSatrPostModel> 
                        className={styles.formikNumberField}
                        name={"childBenefitHowMuch"}
                        label="Total amount received"
                        type="number" 
                        prefix="£"
                        step="0.01"
                        min="0"
                        onlyPositiveNumbers
                        regularLabel
                    />

                    <br />

                    <FormikField<PvSatrPostModel> 
                        className={styles.formikFieldsAuto}
                        name={"childBenefitThisEmployeeHighestEarner"}
                        label="As the parent or guardian, were you the highest earner?"
                        type="checkbox"
                        regularLabel
                        wrapLabel
                    />

                    <FormikField<PvSatrPostModel> 
                        className={styles.formikFieldsAuto}
                        name={"childBenefitStoppedReceivingOnDate"}
                        label="If applicable, when did this benefit end?"
                        type="date"
                        regularLabel
                        wrapLabel
                    />
                </>
            }
        </>  
    )
}