import { faArrowCircleDown, faArrowCircleUp, faArrowDown, faArrowUp, faCircle, faPlus, faShare, faTv } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Account,Asset, Market } from "../../../api/inni/data-contracts";
import { ProcessorType } from "../BankingTypes";
import styles from '../AccountViewerNew.module.css'
import { Button } from "../../../elements/Button/Button";
import { accountsLettingAgentTrade } from "../../../utils/accountsFilterHelper";
import { Entity } from "../../../utils/EntityAction";

interface BookkeepingNewDDProps {
    assets: Asset[],
    onNewDropDownSelect: (typeLong: string | null, assetToSell?: Asset | undefined) => void,
    journalLineId: number | undefined,
    isQuickEntry?: boolean,
    market: Market,
    isEditing?: boolean,
    accounts:Account[],
    accountId: number | undefined
}

const NewTransactionDropdown = ({ assets, onNewDropDownSelect, journalLineId, isQuickEntry, market, isEditing, accounts, accountId }: BookkeepingNewDDProps) => {
    return (
        <tr>
            <td colSpan={8} data-cy="dropdownBtn">
                { /* There would only be one item in the dropdown, so there is no point in using the dropdown */ }
                {isQuickEntry && !market?.isCorporate
                    ? (
                        <Button onClick={() => { onNewDropDownSelect(ProcessorType.expense); }} variant="primary">
                            <FontAwesomeIcon className={styles.dropDownIcon} icon={faArrowCircleUp} /> {isQuickEntry ? 'Create new expense' : 'Other outgoing / expense'}
                        </Button>
                    )
                    : (
                        <>
                            <DropdownButton
                                style={accountId ? {marginRight: '0.5rem'} : {}}
                                className="d-inline"
                                title={<><FontAwesomeIcon icon={faPlus} /> Enter new transaction </>}
                                onSelect={(type) => { onNewDropDownSelect(type); }} disabled={journalLineId !== undefined || isEditing}
                            >
                                {!isQuickEntry && <>
                                    <Dropdown.Item eventKey={ProcessorType.otherIncome}>
                                        <FontAwesomeIcon className={styles.dropDownIcon} icon={faArrowCircleDown} /> Other income
                                    </Dropdown.Item>
                                    <Dropdown.Divider /></>}
                                <Dropdown.Item eventKey={ProcessorType.expense}>
                                    <FontAwesomeIcon className={styles.dropDownIcon} icon={faArrowCircleUp} /> {isQuickEntry ? 'Expense' : 'Other outgoing / expense'}
                                </Dropdown.Item>
                                {!isQuickEntry &&
                                    <><Dropdown.Item eventKey={ProcessorType.refund}>
                                        <FontAwesomeIcon className={styles.dropDownIcon} icon={faArrowCircleDown} /> Refund of expense
                                    </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item eventKey={ProcessorType.transfer}>
                                            <span className="fa-layers">
                                                <FontAwesomeIcon className={styles.dropDownIcon} icon={faCircle} />
                                                <FontAwesomeIcon className={styles.dropDownIcon} icon={faShare} transform="shrink-8" />
                                            </span> Transfer to another account
                                        </Dropdown.Item></>}
                                {market?.isCorporate && <>
                                    {!isQuickEntry && <Dropdown.Divider />}
                                    <Dropdown.Item eventKey={ProcessorType.assetPurchase}>
                                        <span className="fa-layers">
                                            <FontAwesomeIcon className={styles.dropDownIcon} icon={faTv} />
                                            <FontAwesomeIcon className={styles.dropDownIcon} icon={faArrowDown} transform="shrink-10 up-1.5" />
                                        </span> Asset purchase
                                    </Dropdown.Item></>}
                                {!isQuickEntry && market?.isCorporate && assets && assets.filter(x => x.currentValue > 0 && x.canDispose).length > 0 &&
                                    <DropdownButton className={styles.assetSaleButton} title={<>
                                        <span className="fa-layers">
                                            <FontAwesomeIcon className={styles.dropDownIcon} icon={faTv} />
                                            <FontAwesomeIcon className={styles.dropDownIcon} icon={faArrowUp} transform="shrink-10 up-1.5" />
                                        </span> Asset sale
                                    </>} onSelect={(id) => { onNewDropDownSelect(ProcessorType.assetSale, assets.find(x => x.id.toString() === id)) }} drop="right">
                                        {assets.filter(x => x.canDispose).map(asset => <Dropdown.Item key={asset.id} eventKey={asset.id}>{asset.description}</Dropdown.Item>)}
                                    </DropdownButton>}
                                {market?.isProperty && accountsLettingAgentTrade(accounts).length>0 &&<>
                                    {!isQuickEntry && <Dropdown.Divider />}
                                    <Dropdown.Item eventKey={ProcessorType.lettingReceipt}>
                                        <span className="fa-layers">
                                            <FontAwesomeIcon className={styles.dropDownIcon} icon={faArrowCircleDown} />
                                        </span> Money in from letting agent
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey={ProcessorType.lettingPayment}>
                                        <span className="fa-layers">
                                            <FontAwesomeIcon className={styles.dropDownIcon} icon={faArrowCircleUp} />
                                        </span> Money out to letting agent
                                    </Dropdown.Item>
                                </>}
                            </DropdownButton>
                        </>
                    )
                }
                {isQuickEntry && accountId && (
                    <Button
                        buttonType="import"
                        label="Import expenses from CSV"
                        entity={Entity.ExpenseImportV7}
                        extraParams={{accountId: accountId}}
                    />
                )}
            </td>
        </tr>
    )
}

export default NewTransactionDropdown
