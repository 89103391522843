import { apiPlugin, SbReactSDKOptions } from '@storyblok/react';
import NavBar from '../elements/Storyblok/navigation/NavBar';
import NavGroup from '../elements/Storyblok/navigation/NavGroup';
import NavItem from '../elements/Storyblok/navigation/NavItem';
// onboarding
import PageOnboarding from '../elements/Storyblok/onboarding/PageOnboarding';
import StatusLine from '../elements/Storyblok/onboarding/StatusLine';
import StatusLineValue from '../elements/Storyblok/onboarding/StatusLineValue';
import StatusTable from '../elements/Storyblok/onboarding/StatusTable';
import FinanceTable from '../elements/Storyblok/onboarding/FinanceTable';
import PropertiesTable from '../elements/Storyblok/onboarding/PropertiesTable';
import FinanceUploadTable from '../elements/Storyblok/onboarding/FinanceUpload';
import CompletionStatementUpload from '../elements/Storyblok/onboarding/CompletionStatementUpload';
import UploadCSV from '../elements/Storyblok/onboarding/UploadCSV';
// primitives
import KVRichText from '../elements/Storyblok/primitives/KVRichText';
import Heading from '../elements/Storyblok/primitives/Heading';
import InputText from '../elements/Storyblok/primitives/InputText';
import Video from '../elements/Storyblok/primitives/Video';
import VideoX2 from '../elements/Storyblok/primitives/VideoX2';
import Text from '../elements/Storyblok/primitives/Text';
import ShowIf from '../elements/Storyblok/primitives/ShowIf';
import ButtonRow from '../elements/Storyblok/primitives/ButtonRow';
import Button from '../elements/Storyblok/primitives/StoryblokButton';
import Alert from '../elements/Storyblok/primitives/StoryblokAlert';
import InfoBox from '../elements/Storyblok/primitives/InfoBox';

export default {
	accessToken: 'cTumPHXluhLlauqFbDoVQQtt',
	use: [apiPlugin],
	components: {
		nav_bar: NavBar,
		nav_group: NavGroup,
		nav_item: NavItem,
		page_onboarding: PageOnboarding,
		status_line: StatusLine,
		status_line_value: StatusLineValue,
		alert: Alert,
		button: Button,
		button_row: ButtonRow,
		heading: Heading,
		input_text: InputText,
		video: Video,
		video_x2: VideoX2,
		text: Text,
		show_if: ShowIf,
		id_status_table: StatusTable,
		finance_table: FinanceTable,
		completion_statement_upload_table: CompletionStatementUpload,
		finance_upload_table: FinanceUploadTable,
		properties_owned_table: PropertiesTable,
		properties_sold_table: PropertiesTable,
		file_upload: UploadCSV,
		kv_richtext: KVRichText,
		standard_info_box: InfoBox
	},
} as SbReactSDKOptions;
