import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import { BillingPage } from "../../features/Billing/BillingPage";

const billing:Array<EntityRoute> = [
    {
        path: '/company/:cid/billing/',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.BillingV7,
        action: Action.List,
        component: BillingPage
    }
]

export default billing;