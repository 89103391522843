import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import ImportBanks from "../../features/OpenBanking/ImportBanks/ImportBank";
import ImportFailed from "../../features/OpenBanking/ImportBanks/ImportFailure";

const consentRedirect:Array<EntityRoute> = [
    {
        path: '/callback/openbanking/authredirect',
        exact: true,
        context: RouteContext.Company,
        component: ImportBanks
    },

    {
        path: '/company/:cid/importbanks/:cnstId?/:isreauth?',
        exact: true,
        entity:Entity.OpenBankingAccounts,
        action:Action.Read,
        context: RouteContext.Company,
        component: ImportBanks
    },

    {
        path: '/company/:cid/reconnect/:cnstId?/:acctId?',
        exact: true,
        entity:Entity.OpenBankingAccounts,
        action:Action.Read,
        context: RouteContext.Company,
        component: ImportBanks
    },

    {
        path: '/company/:cid/import/failed',
        exact: true,
        context: RouteContext.Company,
        component: ImportFailed
    },
]

export default consentRedirect;