/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  OptimisedPlanShareholderSetupModel,
  OrdinaryPlanShareholderSetupModel,
  ShareholderSetupData,
  Shareholding,
  ShareholdingPostModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Shareholdings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Shareholdings
   * @name OnboardingData
   * @request GET:/api/v2/companies/{companyId}/Shareholdings/OnboardingData
   * @secure
   */
  onboardingData = (companyId: number, params: RequestParams = {}) =>
    this.request<ShareholderSetupData, any>({
      path: `/api/v2/companies/${companyId}/Shareholdings/OnboardingData`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Shareholdings
   * @name ResetShareholderSetup
   * @request DELETE:/api/v2/companies/{companyId}/Shareholdings/ResetShareholderSetup
   * @secure
   */
  resetShareholderSetup = (companyId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/Shareholdings/ResetShareholderSetup`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Shareholdings
   * @name OrdinaryPlanShareholderSetup
   * @request POST:/api/v2/companies/{companyId}/Shareholdings/OrdinaryPlanShareholderSetup
   * @secure
   */
  ordinaryPlanShareholderSetup = (
    companyId: number,
    postModel: OrdinaryPlanShareholderSetupModel,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/Shareholdings/OrdinaryPlanShareholderSetup`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Shareholdings
   * @name OptimisedPlanShareholderSetup
   * @request POST:/api/v2/companies/{companyId}/Shareholdings/OptimisedPlanShareholderSetup
   * @secure
   */
  optimisedPlanShareholderSetup = (
    companyId: number,
    postModel: OptimisedPlanShareholderSetupModel,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/Shareholdings/OptimisedPlanShareholderSetup`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Shareholdings
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/Shareholdings
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Shareholding[], any>({
      path: `/api/v2/companies/${companyId}/Shareholdings`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Shareholdings
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/Shareholdings
   * @secure
   */
  create = (companyId: number, postModel: ShareholdingPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/Shareholdings`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Shareholdings
   * @name ShareholdingsForEmployee
   * @request GET:/api/v2/companies/{companyId}/Shareholdings/{personId}
   * @secure
   */
  shareholdingsForEmployee = (companyId: number, personId: number, params: RequestParams = {}) =>
    this.request<Shareholding[], any>({
      path: `/api/v2/companies/${companyId}/Shareholdings/${personId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Shareholdings
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/Shareholdings/Validate
   * @secure
   */
  validateCreate = (companyId: number, postModel: ShareholdingPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/Shareholdings/Validate`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Shareholdings
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/Shareholdings/{id}
   * @secure
   */
  update = (companyId: number, id: number, postModel: ShareholdingPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/Shareholdings/${id}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Shareholdings
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/Shareholdings/{id}
   * @secure
   */
  delete = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/Shareholdings/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Shareholdings
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/Shareholdings/{id}/Validate
   * @secure
   */
  validateUpdate = (companyId: number, id: number, postModel: ShareholdingPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/Shareholdings/${id}/Validate`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
