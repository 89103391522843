/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CsvDocumentUploadModel,
  FileAttachmentsViewModel,
  IdVerificationModel,
  InitialiseDateModel,
  MarkPageCompletedPostModel,
  NonAppDateModel,
  NotifyOfUpdatePostModel,
  OnboardingPropertyPostModel,
  OnboardingPropertyViewModel,
  PropertyFinancingDetailsPostModel,
  PropertyFinancingDetailViewModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Onboarding<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Onboarding
   * @name GetInitialOnboardingData
   * @summary API: Get initial onboarding data
   * @request GET:/api/v2/companies/{companyId}/onboarding/initialdata
   * @secure
   */
  getInitialOnboardingData = (companyId: number, params: RequestParams = {}) =>
    this.request<InitialiseDateModel, any>({
      path: `/api/v2/companies/${companyId}/onboarding/initialdata`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name GetNonAppOnboardingData
   * @summary API: Return non app form dependent data
   * @request GET:/api/v2/companies/{companyId}/onboarding/nonappdata
   * @secure
   */
  getNonAppOnboardingData = (companyId: number, params: RequestParams = {}) =>
    this.request<NonAppDateModel, any>({
      path: `/api/v2/companies/${companyId}/onboarding/nonappdata`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name NotifyOfCompanyUpdate
   * @summary API: notify support of company update
   * @request POST:/api/v2/companies/{companyId}/onboarding/notifyofupdate
   * @secure
   */
  notifyOfCompanyUpdate = (companyId: number, pm: NotifyOfUpdatePostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/onboarding/notifyofupdate`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name MarkPageCompleted
   * @summary Endpoint to mark onboarding pages complete
   * @request POST:/api/v2/companies/{companyId}/onboarding/markcomleted
   * @secure
   */
  markPageCompleted = (companyId: number, pm: MarkPageCompletedPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/onboarding/markcomleted`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name SubmitCompanyUtr
   * @summary Submit company UTR endpoint
   * @request POST:/api/v2/companies/{companyId}/onboarding/submitUTR
   * @secure
   */
  submitCompanyUtr = (companyId: number, query: { utr: string }, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/onboarding/submitUTR`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name GetStatus
   * @summary Endpoint to retrieve ID verification status
   * @request GET:/api/v2/companies/{companyId}/onboarding/idVerificationStatus
   * @secure
   */
  getStatus = (companyId: number, params: RequestParams = {}) =>
    this.request<IdVerificationModel[], any>({
      path: `/api/v2/companies/${companyId}/onboarding/idVerificationStatus`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name ResendIdVerificationRequest
   * @summary Endpoint to resend id verification requests
   * @request POST:/api/v2/companies/{companyId}/onboarding/resendIdVerification
   * @secure
   */
  resendIdVerificationRequest = (companyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/onboarding/resendIdVerification`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name GetPropertyFinancingDetails
   * @summary Get property financing details
   * @request GET:/api/v2/companies/{companyId}/onboarding/PropertyFinancingDetails
   * @secure
   */
  getPropertyFinancingDetails = (companyId: number, params: RequestParams = {}) =>
    this.request<PropertyFinancingDetailViewModel[], any>({
      path: `/api/v2/companies/${companyId}/onboarding/PropertyFinancingDetails`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name SubmitPropertyFinancingDetails
   * @summary Submit property financing details
   * @request POST:/api/v2/companies/{companyId}/onboarding/submitPropertyFinancingDetails
   * @secure
   */
  submitPropertyFinancingDetails = (
    companyId: number,
    pm: PropertyFinancingDetailsPostModel[],
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/onboarding/submitPropertyFinancingDetails`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name CreateProperty
   * @summary Create the property
   * @request POST:/api/v2/companies/{companyId}/onboarding/createProperty
   * @secure
   */
  createProperty = (companyId: number, pm: OnboardingPropertyPostModel[], params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/onboarding/createProperty`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name UploadCompletionStatement
   * @request POST:/api/v2/companies/{companyId}/onboarding/{propertyid}/uploadCompletionStatement
   * @secure
   */
  uploadCompletionStatement = (
    companyId: number,
    propertyid: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/onboarding/${propertyid}/uploadCompletionStatement`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name UploadMortgageStatement
   * @request POST:/api/v2/companies/{companyId}/onboarding/{propertyId}/uploadMortgageStatement
   * @secure
   */
  uploadMortgageStatement = (
    companyId: number,
    propertyId: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/onboarding/${propertyId}/uploadMortgageStatement`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name DeleteMortgageLenders
   * @request DELETE:/api/v2/companies/{companyId}/onboarding/{mortgageId}
   * @secure
   */
  deleteMortgageLenders = (companyId: number, mortgageId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/onboarding/${mortgageId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name NotifyOfPropertyRecordsUpdate
   * @summary API: notify support of property records update
   * @request POST:/api/v2/companies/{companyId}/onboarding/notifyOfPropertyUpdate
   * @secure
   */
  notifyOfPropertyRecordsUpdate = (companyId: number, pm: NotifyOfUpdatePostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/onboarding/notifyOfPropertyUpdate`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name GetFileAttachments
   * @summary End point to list current status of file attachments
   * @request GET:/api/v2/companies/{companyId}/onboarding/fileattachments
   * @secure
   */
  getFileAttachments = (companyId: number, params: RequestParams = {}) =>
    this.request<FileAttachmentsViewModel, any>({
      path: `/api/v2/companies/${companyId}/onboarding/fileattachments`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name GetUploadedCsvFiles
   * @summary End point to list current status of file attachments
   * @request GET:/api/v2/companies/{companyId}/onboarding/csvUploads
   * @secure
   */
  getUploadedCsvFiles = (companyId: number, params: RequestParams = {}) =>
    this.request<CsvDocumentUploadModel[], any>({
      path: `/api/v2/companies/${companyId}/onboarding/csvUploads`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name UploadCsvBookkeeping
   * @request POST:/api/v2/companies/{companyId}/onboarding/csvUploads
   * @secure
   */
  uploadCsvBookkeeping = (companyId: number, data: { fileToUpload: File }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/onboarding/csvUploads`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name DeleteUploadedCsvFile
   * @summary End point to delete an existing CSV
   * @request DELETE:/api/v2/companies/{companyId}/onboarding/csvUploads/{documentId}
   * @secure
   */
  deleteUploadedCsvFile = (companyId: number, documentId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/onboarding/csvUploads/${documentId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name ReplaceUploadedCsvFile
   * @summary End point to replace an existing CSV
   * @request POST:/api/v2/companies/{companyId}/onboarding/{documentId}
   * @secure
   */
  replaceUploadedCsvFile = (
    companyId: number,
    documentId: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/onboarding/${documentId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name EditingUploadMortgageStatement
   * @request PUT:/api/v2/companies/{companyId}/onboarding/{mortgageStatementId}/editing
   * @secure
   */
  editingUploadMortgageStatement = (
    companyId: number,
    mortgageStatementId: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/onboarding/${mortgageStatementId}/editing`,
      method: "PUT",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Onboarding
   * @name GetSubmittedPropertyFinancingDetails
   * @request GET:/api/v2/companies/{companyId}/onboarding/properties
   * @secure
   */
  getSubmittedPropertyFinancingDetails = (companyId: number, params: RequestParams = {}) =>
    this.request<OnboardingPropertyViewModel[], any>({
      path: `/api/v2/companies/${companyId}/onboarding/properties`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
