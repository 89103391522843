import { useContext, useState, useEffect, useRef } from 'react';
import { useInniAPI } from '../../hooks/useInniAPI';
import { Companies } from '../../api/inni/Companies';
import { Help } from '../../api/inni/Help';
import { CompanySummary, HelpSection } from '../../api/inni/data-contracts';
import BrandContext from '../../context/BrandContext';
import { User } from '../../context/UserContext';
import { Dropdown } from 'react-bootstrap';
import styles from './TopBar.module.scss';
import Gravatar from 'react-gravatar'; 
import * as Icons from './icons';
import useIsMobile from '../../hooks/useIsMobile';
import classNames from 'classnames';
import { GetHelpKeyForUrl } from '../../utils/helpBarUrlMaps';
import { HelpBar } from './HelpBar';
import provestorBrand from '../../components/BrandWrapper/pvBrand';
import { CompanySelectorDDMenu } from './CompanySelectorDDMenu';


interface TopBarProps {
    user?: User,
    company?: CompanySummary,
    companies?: Array<CompanySummary>,
    hasSearchCompanies?: boolean,
    logout?: () => void,
    toggleSidebar?: (collapsed?: boolean) => void,
    contextLevel?: 'contact' | 'company',
    useMobileLayout: boolean
}


// Company is the current company
// Companies is the list of all companies they can access
export const TopBar = ({user, company, companies, hasSearchCompanies, logout, toggleSidebar, contextLevel, useMobileLayout}: TopBarProps) => {
    const brand = useContext(BrandContext).brand;
    const companiesApi = useInniAPI(Companies);
    const helpApi = useInniAPI(Help);
    const [helpData, setHelpData] = useState<HelpSection[]>();
    const [helpLoaded, setHelpLoaded] = useState(false);
    const [search, setSearch] = useState('');
    const [retrievedCompanies, setRetrievedCompanies] = useState<Array<CompanySummary>>([]);
    const searchInput = useRef<HTMLInputElement>(null);
    const isMobile = useIsMobile();
    const [showDimOverlay, setShowDimOverlay] = useState(false);
    const [showHelpBar, setShowHelpBar] = useState(false);
    const [helpSection, setHelpSection] = useState<HelpSection>();
    const [helpKey, setHelpKey] = useState<String>()
    // const [hasGravatar, setHasGravatar] = useState<undefined|boolean>(true)

    // Retrieve companies on search
    useEffect(() => {
        if (search.length >= 3) {
            companiesApi?.index({q: search}).then(response => {
                setRetrievedCompanies(response.data);
            }).catch(error => {
                console.warn(error);
            });
        } else if (search.length === 0) {
            setRetrievedCompanies([]);
        }
    }, [search, companiesApi]);

    useEffect(() => {
        if(!helpLoaded && helpApi) {
            helpApi.index().then((res) => {
                setHelpData(res.data)
                setHelpLoaded(true)
            }).catch((ex) => {
                console.error(ex)
            })
        }
    }, [helpApi, helpLoaded])

    useEffect(() => {
        if(helpData) {
            const helpKeyFromUrl = GetHelpKeyForUrl(window.location.pathname)
            setHelpKey(helpKeyFromUrl)
            helpKeyFromUrl && setHelpSection(helpData.find(x => x.key === helpKeyFromUrl))
        }
    }, [window.location.pathname, helpData])

    const userLabel = () => {
        let out = <></>;
        
        // Admins see owner, clients see their login
        if (hasSearchCompanies && company) {
            out = <a onClick={(e) => e.stopPropagation()} className={styles.topbarLink} href={`http://admin.inniaccounts.co.uk/Admin/Contacts/${company.ownerContactId}`}>{company.ownerFullName}</a> || '';
        } else {
            out = <>{user?.fullName}</> || '';
        }

        if (company) out = <>{out}, {company.name} ({company.id})</>;

        return out;
    }

    const toggleDropdown = (show:boolean) => {
        setSearch('');

        // Focus on search input, feels ugly but this is within a bootstrap component
        if (show) {
            setTimeout(() => {
                if (searchInput && searchInput.current)
                    searchInput.current.focus();
            }, 500);
        }

        if (useMobileLayout) {
            setShowDimOverlay(!showDimOverlay);
            if (toggleSidebar)
                toggleSidebar(true);
        }
    }
    
    const handleHelpbar = () => {
        if(brand === provestorBrand) {
            const newWindow = window.open("https://knowledge.provestor.co.uk/portal/en/kb/provestor", '_blank', 'noopener, noreferrer')
            if (newWindow) newWindow.opener = null
        } else if(helpKey === undefined) {
            const newWindow = window.open("https://www.inniaccounts.co.uk/knowledge-hub/", '_blank', 'noopener, noreferrer')
            if (newWindow) newWindow.opener = null
        } else {
            setShowHelpBar(!showHelpBar)
        }
    }

    // const nameBubbleLetter = (hasSearchCompanies && company && company.ownerFullName) ? company.ownerFullName[0] : user ? user.firstName[0] : ""

    return (
        <div id={useMobileLayout ? styles.topBarMobile : styles.topBar}>
            {toggleSidebar && 
                <Icons.Menu className={useMobileLayout ? styles.toggleIconMobile : styles.toggleIcon} onClick={() => toggleSidebar()}/>
            }
            <img className={styles.topBarLogo} src={useMobileLayout ? (brand.logoWhiteUrl || brand.logoLightUrl) : brand.name === "Provestor" ? brand.logoWhiteUrl : brand.logoLightUrl} alt={brand.name} />

            <Dropdown onToggle={toggleDropdown} bsPrefix={useMobileLayout ? 'w-100' : undefined}>
                <Dropdown.Toggle className={styles.topBarDropdown} style={brand !== provestorBrand ? {paddingRight: "1rem"} : {}}>
                    <Gravatar className={styles.gravatar} email={user?.email || ''} size={29} rating="pg" default="mm" /> 
                    
                    {/* {hasGravatar ? 
                        <Gravatar onError={() => setHasGravatar(false)} className={styles.gravatar} email={user?.email || ''} size={29} alt="" rating="pg" default="404" /> 
                        : 
                        <p className={styles.profileLetter}>{nameBubbleLetter}</p> 
                    } */}
                    
                    { !isMobile && <span className={styles.userMenuLabel}>{ userLabel() }</span> }
                </Dropdown.Toggle>
                <CompanySelectorDDMenu
                    user={user}
                    companies={companies}
                    hasSearchCompanies={hasSearchCompanies}
                    logout={logout}
                    contextLevel={contextLevel}
                    useMobileLayout={useMobileLayout}
                    search={search}
                    setSearch={setSearch}
                    retrievedCompanies={retrievedCompanies}
                />
            </Dropdown>
            { (brand !== provestorBrand && !isMobile) && (
                <span className={classNames(styles.helpIcon, {[styles.helpIconSelected]: showHelpBar})} onClick={handleHelpbar}>?</span>
            )}
            <div className={classNames(styles.dimOverlay, {[styles.dimOverlayVisible]: showDimOverlay})} />

            {brand !== provestorBrand && helpSection && <HelpBar helpSection={helpSection} onClose={() => setShowHelpBar(!showHelpBar)} isOpen={showHelpBar}/>}
        </div>
    )
}
