import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import CreateCompletionStatement from "../../features/CompletionStatements/CreateCompletionStatementPage";
import ViewCompletionStatement from "../../features/CompletionStatements/ViewCompletionStatementPage";

const completionStatement:Array<EntityRoute> = [
    {
        path: '/company/:cid/completionStatement/create/:propertyId/:statementType?',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.CompletionStatement,
        component: CreateCompletionStatement
    },
    {
        path: '/company/:cid/completionStatement/view/:id',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.CompletionStatement,
        action: Action.Read,
        component: ViewCompletionStatement
    },
    {
        path: '/company/:cid/completionStatement/edit/:propertyId/:completionStatementId',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.CompletionStatement,
        action: Action.Edit,
        component: CreateCompletionStatement
    }
]

export default completionStatement;

