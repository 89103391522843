import {useEffect, useState} from 'react';
import history from '../utils/history';
import {
  Router,
  Switch,
  Route,
  useLocation
} from "react-router-dom";
import { AuthenticatedUserWrapper } from '../components/AuthenticatedUserWrapper/AuthenticatedUserWrapper';
import routes from './routes';
import { CompanyWrapper } from '../components/CompanyWrapper/CompanyWrapper';
import { RouteContext } from './routes/routeTypes';
import { BrandWrapper } from '../components/BrandWrapper/BrandWrapper';
import { CompanySelect } from '../features/CompanySelect/CompanySelect';
import '../css/app.scss';
import ContactWrapper from '../components/ContactWrapper/ContactWrapper';
import LayoutWrapper from '../layouts/LayoutWrapper';
import configHelper, {Platform} from '../utils/configHelper';
import ReactAppzi from 'react-appzi';
import CallbackRedirect from '../features/OpenBanking/callback';
import SetupRedirection from '../features/OnboardingSetup/SetupRedirection';

// https://reactrouter.com/web/guides/scroll-restoration
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  // Initialise appZi, but for PV only to ration page views
  if (configHelper.brand === 'pv' && configHelper.environment !== 'dev')
    ReactAppzi.initialize("hyPRp")

  const publicRoutes = routes.filter(route => route.context === RouteContext.Public && route.path);
  const userRoutes = routes.filter(route => route.context === RouteContext.User && route.path);
  const companyRoutes = routes.filter(route => route.context === RouteContext.Company && route.path);

  const [hasAMSupport, setHasAMSupport] = useState(false)

  return (
      <BrandWrapper>
        <Router history={history}>
          <ScrollToTop />
          <Switch>

            {publicRoutes.map((route, i) => 
              <Route path={route.path} component={route.component} exact={route.exact} key={i} />
            )}
            
            <Route path='/' exact>
              <AuthenticatedUserWrapper>
                <CompanySelect />
              </AuthenticatedUserWrapper>
            </Route>

            <Route path='/callback/openbanking/authredirect' exact>
              <AuthenticatedUserWrapper>
                <CallbackRedirect/>
              </AuthenticatedUserWrapper>
            </Route>
            
            <Route path='/user'>
              <AuthenticatedUserWrapper>
                <ContactWrapper>
                  <LayoutWrapper allowMobile>
                    <Switch>
                      {userRoutes.map((route, i) => 
                        <Route path={route.path} component={route.component} exact={route.exact} key={i} />
                      )}
                    </Switch>
                  </LayoutWrapper>
                </ContactWrapper>
              </AuthenticatedUserWrapper>
            </Route>

            {/* Don't allow access to app from hub (except hard link) */}
            { configHelper.platform !== Platform.Hub && (
              <Route path='/company/:cid'>
                <AuthenticatedUserWrapper hasAMSupport={hasAMSupport}>
                  <CompanyWrapper setHasAMSupport={setHasAMSupport}>
                    {/* <OnboardingRedirection/> TODO: Re-enable when ready */}
                    <SetupRedirection/>
                    <LayoutWrapper>
                      <Switch>
                        {companyRoutes.map((route, i) => 
                              <Route path={route.path} component={route.component} exact={route.exact} key={i} />
                        )}
                      </Switch>
                    </LayoutWrapper>
                  </CompanyWrapper>
                </AuthenticatedUserWrapper>
              </Route>
            )}

          </Switch>
        </Router> 
    </BrandWrapper>
  );
}

export default App;
