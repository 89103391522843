import React from "react"
import { PieChart, PieChartValue } from "../../../elements/DashboardCards/PieChart"
import { SimpleKpi } from "../../../elements/DashboardCards/SimpleKpi"
import { formatPercent } from "../../../utils/formatNumbers"


export const LettableUnitsKpi = ({loading, unitsLet, unitsVacant} : {loading?: boolean, unitsLet?: number, unitsVacant?: number}) => {
    const lettableUnits = () => {
        return (
            <>
                <span className='text-success'>{unitsLet}</span>&nbsp;/&nbsp;
                <span className='text-danger'>{unitsVacant}</span>&nbsp;/&nbsp;
                <span>{(unitsLet || 0) + (unitsVacant || 0)}</span>
            </>
        )
    }

    return (
        <SimpleKpi loading={loading} label='Lettable units' value={lettableUnits()} helpText='Let / vacant / total' height={4} />
    )
}


export const LettableUnitsPieChart = ({loading, unitsLet, unitsVacant} : {loading?: boolean, unitsLet?: number, unitsVacant?: number}) => {

    const occupancyData = () : Array<PieChartValue> => {
        return [
            {
                id: "let",
                label: "Let",
                value: unitsLet || 0,
                colorName: 'success'
            },
            {
              id: "vacant",
              label: "Vacant",
              value: unitsVacant || 0,
              colorName: 'danger'
            }
          ]
    }

    const percentOccupied = () => {
        if (unitsLet !== undefined && unitsVacant !== undefined) {
            let totalUnits = unitsLet + unitsVacant;
            if (totalUnits === 0) return '';
            const p = unitsLet / totalUnits;
            return formatPercent(p);
        } else {
            return ''
        }
    }


    return (
        <PieChart 
            loading={loading}
            label='Occupancy' 
            totalText={percentOccupied()}
            data={occupancyData()}
            height={3}
            
            />
    )

}