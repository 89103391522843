import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import FinanceMenu from "../../features/Bookkeeping2022/FinancesMenu";

const bookkeepingV8:Array<EntityRoute> = [

    {
        path: '/company/:cid/finances/:activePage?/:id?',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.BookkeepingV8,
        component: FinanceMenu
    },
]

export default bookkeepingV8;

