import React from 'react';
import IconList, { TIcons } from './icons/IconList';
import './Icon.scss';

interface IconProps {
	name: TIcons;
	size?: 'small' | 'medium' | 'large';
	className?: string;
	color?: string;
}

const Icon: React.FC<IconProps> = ({ name, size = 'medium', className = '', color }) => {
	const IconBody = IconList[name];
	return <IconBody className={`icon icon-${size} ${className}`} color={color} />;
};

export default Icon;
