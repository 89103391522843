import classNames from 'classnames';
import styles from './SideBar.module.scss';
import { SideBarItem } from './SideBarItem';
import PWADownloadButton from '../MobileWrapper/PWADownloadButton';
import { useContext, useEffect, useRef, useState } from 'react';
import BrandContext from '../../context/BrandContext';
import { User } from '../../context/UserContext';
import { CompanySummary } from '../../api/inni/data-contracts';
import { Dropdown } from 'react-bootstrap';
import { CompanySelectorDDMenu } from './CompanySelectorDDMenu';
import { useInniAPI } from '../../hooks/useInniAPI';
import { Companies } from '../../api/inni/Companies';
import { AccountV8 } from './icons';
import { NavItem } from '../../api/inni/data-contracts'
import SATRSideBarItems from './SATRSidebarItems';

interface SideBarProps {
    collapsed: boolean,
    onClickCb?: () => void,
    useMobileLayout: boolean,
    sideBarEntries: NavItem[]
}

interface SideBarPropsV8 {
    collapsed: boolean,
    onClickCb?: () => void,
    logout?: () => void,
    useMobileLayout: boolean,
    user?: User
    company?: CompanySummary,
    hasSearchCompanies?: boolean,
    companies?: Array<CompanySummary>,
    contextLevel?: 'contact' | 'company',
    sideBarEntries: NavItem[]
}


export const SideBar = ({collapsed, onClickCb, useMobileLayout, sideBarEntries }: SideBarProps) => {
    return (
        <div
            id={useMobileLayout ? styles.sideBarMobile : styles.sideBar}
            data-testid='sideBar'
            className={
                classNames({
                    [styles.sideBarCollapsed]: collapsed,
                    [styles.mobile]: useMobileLayout
                }
                )
            }
        >
            <div className={styles.sidebarGroup}>
                { sideBarEntries.map((item, i) => (
                    <SideBarItem key={i} navItem={item} useMobileLayout={useMobileLayout} onClickCb={onClickCb} />
                ))}
            </div>

            {useMobileLayout && (
                <div id={styles.sideBarButtons}>
                    {/* <div data-az-l="dd2eeca4-3d59-42c4-85de-3131b3c60ef7" id={styles.sideBarFeedbackButton}>
                        <FontAwesomeIcon icon={faComment} />&nbsp;Feedback
                    </div> */}
                    <PWADownloadButton type='sidebar' />
                </div>
            )}
        </div>
    )
}

export const SideBarV8 = ({
        collapsed, onClickCb, logout,
        useMobileLayout, user, company,
        hasSearchCompanies, contextLevel, companies,
        sideBarEntries }: SideBarPropsV8) => {

    const brand = useContext(BrandContext).brand;
    const companiesApi = useInniAPI(Companies);

    const [search, setSearch] = useState('');
    const [retrievedCompanies, setRetrievedCompanies] = useState<Array<CompanySummary>>([]);

    const searchInput = useRef<HTMLInputElement>(null);

    const isStandaloneWizard = company?.onboardingWizardType === 'PvPhpSatr';

    // Retrieve companies on search
    // TODO: needs debounce
    useEffect(() => {
        if (search.length >= 3) {
            companiesApi?.index({ q: search }).then(response => {
                setRetrievedCompanies(response.data);
            }).catch(error => {
                console.warn(error);
            });
        } else if (search.length === 0) {
            setRetrievedCompanies([]);
        }
    }, [search, companiesApi]);

    const toggleDropdown = (show: boolean) => {
        setSearch('');

        // Focus on search input, feels ugly but this is within a bootstrap component
        if (show) {
            setTimeout(() => {
                if (searchInput && searchInput.current)
                    searchInput.current.focus();
            }, 500);
        }
    }

    const nameBubbleLetter = (hasSearchCompanies && company && company.ownerFullName)
        ? company.ownerFullName[0]
        : user
            ? user.fullName[0]
            : "P"

    const userLabel = () => {
        let out = <></>;

        // Admins see owner, clients see their login
        if (hasSearchCompanies && company) {
            out = <a
                onClick={(e) => e.stopPropagation()}
                className={styles.topbarLink}
                href={`http://admin.inniaccounts.co.uk/Admin/Contacts/${company.ownerContactId}`}
            >
                {company.ownerFullName}
            </a> || '';
        } else {
            out = <>{user?.fullName}</> || '';
        }

        return out;
    }

    return (
        <div
            id={styles.sideBarV8}
            data-testid='sideBar'
            className={
                classNames({
                    [styles.sideBarCollapsed]: collapsed,
                    [styles.mobile]: useMobileLayout,
                    [styles.standaloneWizard]: isStandaloneWizard
                })
            }
        >
            <img
                className={styles.sideBarLogo}
                src={isStandaloneWizard ? brand.logoDarkUrl : brand.logoWhiteUrl}
                alt={brand.name}
            />

            {/* Sidebar items */}
            { isStandaloneWizard
                ? (
                    company && <SATRSideBarItems cId={company.id} />
                )
                : (
                    <div className={styles.sidebarGroup}>
                        { sideBarEntries.map((item, i) => (
                            <SideBarItem key={i} navItem={item} onClickCb={onClickCb} useMobileLayout={useMobileLayout} />
                        ))}
                    </div>
                )
            }


            { nameBubbleLetter && (
                <div className={styles.profileContainer}>
                    <div className={styles.profile}>
                        <p className={styles.profileLetter}>{nameBubbleLetter}</p>
                        <p className={styles.usersName}>{userLabel()}</p>
                        { (company && !isStandaloneWizard) && <p className={styles.companyName}>{company.name}</p> }
                    </div>

                    <hr />

                    { isStandaloneWizard 
                        ? (
                            <div className={styles.profile}>
                                <span className={styles.logout} onClick={logout}>Logout</span>
                            </div>
                        )
                        : (
                            <div className={classNames(styles.accountDropdownContainer, "sideBarAccountDropdown")}>
                                <Dropdown onToggle={toggleDropdown} drop='right'>
                                    <Dropdown.Toggle className={styles.accountDropdown}>
                                        <span><AccountV8 style={{marginTop: '0.1rem'}} width={15} height={15} />&nbsp;Account</span>
                                    </Dropdown.Toggle>
                                    <CompanySelectorDDMenu
                                        user={user}
                                        companies={companies}
                                        hasSearchCompanies={hasSearchCompanies}
                                        logout={logout}
                                        contextLevel={contextLevel}
                                        useMobileLayout={useMobileLayout}
                                        search={search}
                                        setSearch={setSearch}
                                        retrievedCompanies={retrievedCompanies}
                                        v8Styling
                                    />
                                </Dropdown>
                            </div>
                        )
                    }
                </div>
            )}
        </div>
    )
}