import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity, Action } from "../../utils/EntityAction";
import { UserConsultations } from "../../features/UserPortal/UserConsultations";
import ConsultationReport from "../../features/UserPortal/ConsultationReport";

const portalConsultations:Array<EntityRoute> = [
    {
        path: '/user/:contactId/consultations',
        exact: true,
        context: RouteContext.User,
        entity: Entity.PortalConsultations,
        action: Action.Read,
        component: UserConsultations
    },
    {
        path: '/user/:contactId/consultations/:rid',
        exact: true,
        context: RouteContext.User,
        entity: Entity.PortalConsultations,
        action: Action.List,
        component: ConsultationReport
    }
]

export default portalConsultations;
