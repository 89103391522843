import { Form, Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import { BasicFormLayout } from "../../../layouts/Desktop/BasicFormLayout";
import FormikField from "../../../elements/FormikField/FormikField";
import { PvSatrPersonalDetails, PvSatrPersonalDetailsPostModel } from "../../../api/inni/data-contracts";
import styles from "./BasicDetails.module.scss"
import { Summary } from "../../../elements/Summary/Summary";
import { useContext, useEffect, useRef, useState } from "react";
import { Text, Date as DateText } from '../../../elements/DetailRow/DetailRow';
import { useInniAPI } from "../../../hooks/useInniAPI";
import { PvSatr } from "../../../api/inni/PvSatr";
import CompanyContext from "../../../context/CompanyContext";
import Divider from "../../../elements/Divider/Divider";
import { Button } from "../../../elements/Button/Button";
import Icon from "../../../elements/Icon/Icon";
import { useStandaloneSATRStatus } from "../../../hooks/useStandaloneSATRStatus";
import { PageAlert } from "../../../components/Alerts/PageAlert";
import { LoadingLabel, LoadingPlaceholder, LoadingTextInput } from "../../../elements/LoadingPlaceholder/LoadingPlaceholder";
import { Alert } from "react-bootstrap";

interface ValidationRules {
    fieldName: keyof PvSatrPersonalDetails;
    regex?: RegExp;
    errorMessage?: string;
    required: boolean;
}

export default function BasicDetails (){

    const companyContext = useContext(CompanyContext)
    const pvsatrAPI = useInniAPI(PvSatr)
    const formikRef = useRef<FormikProps<PvSatrPersonalDetailsPostModel>>(null);
    const { pvSatrDetails, completeStep, nextStep } = useStandaloneSATRStatus();
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [stageNumber, setStageNumber] = useState<number>()
    const [description, setDescription] = useState<string>()

    useEffect(() =>{
        if(pvSatrDetails && pvSatrDetails.progress)
        {
            setStageNumber(pvSatrDetails.progress.setupBasicDetails ? 1 : 0)
            setDescription(pvSatrDetails.progress.setupBasicDetails 
                ? "These are the details you have confirmed."
                : "Below is a summary of all the details you've provided. Review the information to ensure its accuracy, and make any necessary adjustments before confirming"

            )
        }
    }, [pvSatrDetails])

    const OnSubmit = (values: PvSatrPersonalDetailsPostModel, actions: FormikHelpers<PvSatrPersonalDetailsPostModel>): Promise<void> => {
        return new Promise((resolve, reject) => {
          if (pvsatrAPI && pvSatrDetails) {
            values.nino = values.nino?.toString().replace(/\s/g, '')
            pvsatrAPI
              .updatePersonalDetails(companyContext.cid, pvSatrDetails.id, values)
              .then((response) => {
                if (pvSatrDetails?.progress?.setupBasicDetails){
                    setShowSavedMessage(true);
                }
                    
                resolve();
              })
              .catch((error) => {
                actions.setErrors(error.error);
                reject();
              });
          } else {
            if(formikRef.current){
                actions.setErrors(formikRef.current.errors);
            }
            
            reject();
          }
        });
    };


    const stageDetails = [ 
        {
            Key: "intro",
            Title: 'Basic details',
        },

        {
            Key: "summary",
            Title: 'Basic details summary'
        },
    ]

    const validateForm = (): boolean => {
        if (!formikRef.current) return true;
    
        const validationRules: ValidationRules[] = [
            { fieldName: 'streetAddress', required: true },
            { fieldName: 'town', required: true },
            { fieldName: 'postcode', required: true },
            { fieldName: 'dob', required: true },
            { 
                fieldName: 'nino', 
                regex: /^[A-Z]{2}[0-9]{6}[A-Z]$/,
                errorMessage: 'Format should be: Two letters, six numbers, and one letter (e.g., AB123456C)',
                required: true 
            },
            { 
                fieldName: 'utr', 
                regex: /^[0-9]{10,13}$/, 
                errorMessage: 'Please enter 10 or 13 digits',
                required: true 
            }
        ];
    
        const validateField = (rule: ValidationRules): boolean => {
            const { fieldName, regex, errorMessage, required } = rule;
            const value = formikRef.current?.values[fieldName];
            
            formikRef.current?.setFieldTouched(fieldName, false, false);
            formikRef.current?.setFieldError(fieldName, undefined);

            if (required && !value) {
                formikRef.current?.setFieldTouched(fieldName, true, false);
                formikRef.current?.setFieldError(fieldName, 'Required');
                return false;
            }

            if (regex && value) {
                const valueStr = fieldName === 'nino'? value.toString().replace(/\s/g, '') : value.toString();
            
                if (!regex.test(valueStr)) {
                    formikRef.current?.setFieldTouched(fieldName, true, false);
                    formikRef.current?.setFieldError(fieldName, errorMessage);
                    return false;
                }
            
                if (fieldName === "nino") {
                    const NINORegex = /^(?!BG|GB|KN|NK|NT|TN|ZZ)(?![DFIQUV])[A-Z](?![DFIQOUV])[A-Z]\d{6}[ABCD]$/;
                    
                    if (!NINORegex.test(valueStr)) {
                        formikRef.current?.setFieldTouched(fieldName, true, false);
                        formikRef.current?.setFieldError(fieldName, 'Invalid number, please check your entry for mistakes');
                        return false;
                    }
                }
            }
    
            return true;
        };
    
        // Validate all fields and return overall result
        return validationRules.map(validateField).every(Boolean);
    };

    const handleOnClickSaveDraft = () =>{
        formikRef.current?.submitForm()
    }

    const handleOnContinueClick = () =>{
        if (!validateForm()) {
            return;
        }
        else{
            formikRef.current?.submitForm()
            .then(() =>{
                setStageNumber(1)
            })
        }
        
    }

    const handleOnConfirmClick = () =>{
        completeStep('SetupBasicDetails');
    }

    const handleOnNextClick = () =>{
        nextStep('SetupBasicDetails');
    }

    const validateCreate = (values: PvSatrPersonalDetailsPostModel) : Promise<FormikErrors<PvSatrPersonalDetailsPostModel>> => {
        return new Promise((resolve, reject) => {
            resolve({})
        })
    }

    return(
        <BasicFormLayout
            title={stageDetails[stageNumber||0].Title}
            description={stageNumber === 1? description : undefined}
            storyblokDescriptionId={stageNumber === 0 ?  "basic-page" : undefined}
        >
            <>

                {!pvSatrDetails &&
                    <>
                        <LoadingPlaceholder width="20%" height="30px" dark/>
                        {Array(5)
                            .fill(0)
                            .map((_, index) => (
                                <div key={index} className="w-50 ml-3 mt-4 mr-4">
                                    <LoadingLabel />
                                    <LoadingTextInput/>
                                </div>
                        
                        ))}

                        {Array(3)
                            .fill(0)
                            .map((_, index) => (
                                <div key={index}>
                                    <Divider margin={"2rem 0px 1rem 0px"}/>

                                    <div className="w-50 ml-3 mt-4 mr-4">
                                        <LoadingPlaceholder width="30%" height="20px" dark/>
                                        <LoadingTextInput/>
                                    </div>
                                </div>
                                
                        
                        ))}

                    </>
                }


                {pvSatrDetails &&
                    <Formik
                        initialValues={pvSatrDetails?.personalDetails || { } as PvSatrPersonalDetails}
                        enableReinitialize         
                        validateOnChange={false}
                        validate={validateCreate}
                        onSubmit={OnSubmit}
                        innerRef={formikRef}
                    >   
                        {({ values, isSubmitting }) => (
                            <Form>
                                {stageNumber === 0 && 
                                    <>
                                        <p className={styles.subheading}>Your address</p>
                                        <FormikField<PvSatrPersonalDetailsPostModel> 
                                            className={styles.formikField}
                                            label="Property name (if applicable)"
                                            name="buildingName"
                                            type="text"                                        
                                        />
                                        <FormikField<PvSatrPersonalDetailsPostModel> 
                                            className={styles.formikField}
                                            label="Street address"
                                            name="streetAddress"
                                            type="text"                                        
                                        />
                                        <FormikField<PvSatrPersonalDetailsPostModel> 
                                            className={styles.formikField}
                                            label="Town"
                                            name="town"
                                            type="text"                                        
                                        />
                                        <FormikField<PvSatrPersonalDetailsPostModel> 
                                            className={styles.formikField}
                                            label="County"
                                            name="county"
                                            type="text"                                        
                                        />
                                        <FormikField<PvSatrPersonalDetailsPostModel> 
                                            className={styles.formikField}
                                            name="postcode"
                                            label="Postcode"
                                            type="text"                                        
                                        />
                                        
                                        <Divider margin={"2rem 0px 1rem 0px"}/>
                                        
                                        <FormikField<PvSatrPersonalDetailsPostModel> 
                                            className={styles.formikFieldsAuto}
                                            label="Date of birth"
                                            name="dob"
                                            type="date"
                                            boldLabel                                        
                                        />
                    
                                        <Divider margin={"2rem 0px 1rem 0px"}/>
                    
                                        <FormikField<PvSatrPersonalDetailsPostModel> 
                                            className={styles.formikField}
                                            label="National insurance number"
                                            name="nino"
                                            type="text" 
                                            boldLabel                                       
                                        />
                    
                                        <Divider margin={"2rem 0px 1rem 0px"}/>
                    
                                        <FormikField<PvSatrPersonalDetailsPostModel> 
                                            className={styles.formikField}
                                            label="Unique taxpayer reference (UTR) number"
                                            name="utr"
                                            type="text"
                                            boldLabel 
                                            helpMessage="This is the 10-digit number issued by HM Revenue and Customs (HMRC) and can often be found on letters you may have received from them"                                  
                                        />

                                    </>
                                }

                                {stageNumber === 1 &&
                                    <>
                                        <Summary>
                                            <p className={styles.subheading}>Address</p>
                                            <Text fixedWidth ensureRender entity={values} name={'buildingName'} v8Summary showLabelTop/>
                                            <Text fixedWidth ensureRender entity={values} name={'streetAddress'} v8Summary showLabelTop/>
                                            <Text fixedWidth ensureRender entity={values} name={'town'} v8Summary showLabelTop/>
                                            <Text fixedWidth ensureRender entity={values} name={'county'} v8Summary showLabelTop/>
                                            <Text fixedWidth ensureRender entity={values} name={'postcode'} v8Summary showLabelTop/>
                                            <Divider margin={"1.5rem 0"}/>
                                            <DateText fixedWidth ensureRender entity={values} name={'dob'} label="Date of birth" v8Summary showLabelTop/>
                                            <Divider margin={"1.5rem 0"}/>
                                            <Text fixedWidth ensureRender entity={values} name={'nino'} label="National insurance number" v8Summary showLabelTop/>
                                            <Divider margin={"1.5rem 0"}/>
                                            <Text fixedWidth ensureRender entity={values} name={'utr'} label="UTR number" v8Summary showLabelTop/>
                                        </Summary>
                                        
                                    </>
                                }

                                {stageNumber === 0 ?
                                        <>
                                            {pvSatrDetails && pvSatrDetails.progress && pvSatrDetails?.progress?.setupBasicDetails ?
                                                    <Button 
                                                        variant="primary" 
                                                        onClick={handleOnContinueClick}
                                                        marginTop 
                                                        disabled={ isSubmitting }
                                                        label="Save & confirm"
                                                    />
                                                :
                                                    <div>
                                                        <Button 
                                                            variant="primary"
                                                            submitButton
                                                            label="Save draft"
                                                            marginTop 
                                                            disabled={isSubmitting}
                                                            onClick={handleOnClickSaveDraft}
                                                        />


                                                        <Button 
                                                            variant="primary" 
                                                            onClick={handleOnContinueClick}
                                                            marginTop 
                                                            disabled={ isSubmitting }
                                                            thin
                                                        >
                                                            Continue <Icon name='arrowNext' />
                                                        </Button>
                                                    </div>
                                            }
                                        </>
                                        
                                    :
                                        <>
                                            { pvSatrDetails?.progress?.setupSubmitted 
                                                // SATR submitted
                                                ? (
                                                    <>
                                                        <Alert variant="info">
                                                            <b>You have completed your tax return. Please contact us if you need to make changes.</b>
                                                        </Alert>

                                                        <Button 
                                                            variant="primary" 
                                                            onClick={handleOnNextClick}
                                                            marginTop 
                                                            thin
                                                        >
                                                            Continue <Icon name='arrowNext' />
                                                        </Button>
                                                    </>
                                                )
                                                // SATR in progress
                                                : (
                                                    <div>
                                                        { pvSatrDetails?.progress?.setupBasicDetails && (
                                                            <Alert variant="info">
                                                                <b>You have already confirmed your details, but you can still make changes.</b>
                                                            </Alert>
                                                        )}

                                                        <Button 
                                                            marginTop
                                                            variant="secondary"
                                                            onClick={() => setStageNumber(0)} 
                                                            label="Edit details "
                                                        />

                                                        { pvSatrDetails && pvSatrDetails.progress && (
                                                            !pvSatrDetails?.progress?.setupBasicDetails
                                                                ? (
                                                                    <Button 
                                                                        variant="primary" 
                                                                        label="Confirm details"
                                                                        onClick={handleOnConfirmClick}
                                                                        marginTop
                                                                    />
                                                                )
                                                                : (
                                                                    <Button 
                                                                        variant="primary" 
                                                                        onClick={handleOnNextClick}
                                                                        marginTop 
                                                                        thin
                                                                    >
                                                                        Continue <Icon name='arrowNext' />
                                                                    </Button>
                                                                )
                                                        )}
                                                    </div>
                                                )
                                            }
                                        </>
                                }
                            </Form>
                        )}

                    </Formik>
                }

                <PageAlert
                    ignoreSidebar={true}
                    variant="success"
                    showAlert={showSavedMessage}
                    message="Basic details updated"
                    hideAlert={() => { setShowSavedMessage(false) }}
                />
                
            </>
        </BasicFormLayout>
    )
}