import classNames from 'classnames';
import React from 'react';
import { Card } from 'react-bootstrap';
import { formatDate } from '../../../../utils/formatDate';
import styles from './DisplayCards.module.css';

type DisplayCardsProps = {
	cardDetails?: number | string;
	children: React.ReactNode;
	balanceStatus?: boolean;
	showBanner?: boolean;
	dueDate?: string;
	bannertext?: string;
	bold?: boolean;
	classname?: string;
};

const DisplayCards = ({ cardDetails, children, balanceStatus, classname, showBanner, dueDate, bannertext, bold = false }: DisplayCardsProps) => {
	return (
		<Card className={classNames(styles.displayCard, classname)} data-cy="displayCards">
			<Card.Body className="p-2 mt-2 px-2 ">
				<p className={`${styles.cardTitle}`} style={{ color: balanceStatus ? '#ef4747' : '#595478', borderBottom: bold ? '2px solid red' : '1px solid black' }}>
					{children}
				</p>
				<div className={classNames(styles.cardText, 'mt-lg-2')} >
					<span style={{ color: balanceStatus ? '#ef4747' : undefined, fontWeight: bold ? 'bold' : undefined }}>{cardDetails}</span>
				</div>
				{showBanner ? (
					<span
						className={`${styles.cardBanner}`}
						style={{ color: balanceStatus ? '#ef4747' : 'grey', lineHeight: '3px', fontWeight: bold ? 'bold' : undefined }}>
						{bannertext} {formatDate(dueDate)}
					</span>
				) : null}
			</Card.Body>
		</Card>
	);
};

export default DisplayCards;
