import { useContext, useState } from 'react'
import InfoBanner from '../../../elements/InfoBanner/InfoBanner'
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { Button } from '../../../elements/Button/Button';
import { OpeningBalancesPostModel, SetupWizardForm } from '../../../api/inni/data-contracts';
import { formatCurrency } from '../../../utils/formatNumbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPencil } from '@fortawesome/pro-regular-svg-icons';
import { ListOption } from '../../../elements/EditRow/EditRow';
import Select from 'react-select';
import styles from '../SetupWizard.module.css'
import { FormikErrors, FormikHelpers } from 'formik';
import { FormikRowInput, FormikRowLogic } from '../../../elements/FormikTableEditor/FormikTableEditor';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { SetupWizard } from '../../../api/inni/SetupWizard';
import CompanyContext from '../../../context/CompanyContext';
import { formatDate } from '../../../utils/formatDate';
import { WarningText } from './WarningText';

const accountGroupSL : ListOption[] = [
    { value: 'Assets', label: 'Assets' },
    { value: 'Capital', label: 'Capital' },
    { value: 'Expenses', label: 'Expenses' },
    { value: 'Income', label: 'Income' },
    { value: 'Liabilities', label: 'Liabilities' },
]

const BalanceSheet = ({setNextPage, formState, setFormState, setPrevPage} : {setNextPage : () => void, formState : SetupWizardForm, setFormState : (i : SetupWizardForm) => void, setPrevPage : () => void}) => {

    const swAPI = useInniAPI(SetupWizard, [400])
    const companyContext = useContext(CompanyContext)

    const [curGroup, setCurGroup] = useState<string>('Assets')

    const [editing, setEditing] = useState<number | undefined>(undefined)

    const calcTotals = () => {
        return Math.round((formState.setupOpeningBalances?.reduce((a,b) => a + (b.amount || 0), 0) || 0) * 100) / 100
    }

    const validateCreate = (values: OpeningBalancesPostModel) : Promise<FormikErrors<OpeningBalancesPostModel>>=> {
        return new Promise((resolve, reject) => {})
    }
    const createCall = (values: OpeningBalancesPostModel, actions: FormikHelpers<OpeningBalancesPostModel>) : Promise<number> => {
        return new Promise((resolve, reject) => {resolve(1)})
    }

    const validateUpdate = (id: number, values: OpeningBalancesPostModel) : Promise<FormikErrors<OpeningBalancesPostModel>> => {
        return new Promise((resolve, reject) => {
            if (swAPI) {
                swAPI.validateUpdateOpeningBalance(companyContext.cid, { accountId: id }, values)
                .then(res => resolve({}))
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const editCall = (values: OpeningBalancesPostModel, actions: FormikHelpers<OpeningBalancesPostModel>, id : number) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (swAPI) {
                var acc = formState.setupOpeningBalances?.find(x => x.bankAccountID === values.bankAccountID)
                if (acc?.group === "Capital" || acc?.group === "Income" || acc?.group === "Liabilities") // we display these values as positive but they are negative in the DB (so balance is worked out correctly)
                    values.amount = 0 - (values.amount || 0)
                swAPI.updateOpeningBalance(companyContext.cid, { accountId: id }, values)
                .then(res => {
                    if (res.status === 200) {
                        let tempFs = formState
                        let ob = tempFs.setupOpeningBalances?.findIndex(x => x.bankAccountID === id)
                        if (tempFs.setupOpeningBalances && ob !== undefined && ob !== -1) {
                            tempFs.setupOpeningBalances[ob].amount = values.amount
                        }
                        setFormState({...tempFs})
                        setEditing(undefined)
                    } else {
                        console.error('Error saving opening balances')
                    }
                })
            }
        })
    }

    const buildValues = () => {
        let vals = formState.setupOpeningBalances?.find(x => x.id === editing)
        if (vals)
            return {...vals,
                bankAccountID: vals.bankAccountID || 0, 
                amount: vals?.group === "Capital" || vals?.group === "Income" || vals?.group === "Liabilities" ? 0 - (vals.amount || 0) : vals.amount || 0}
    }

    //Setup formik instance to be attached to inputs
    const formik = FormikRowLogic<OpeningBalancesPostModel & {nonZeroBalanceSheet?: string}>({
        id: formState.setupOpeningBalances ? formState.setupOpeningBalances.find(x => x.id === editing)?.bankAccountID : undefined,
        editRowValues: formState.setupOpeningBalances ? 
            buildValues() : undefined, 
        validateUpdate: validateUpdate, 
        validateCreate: validateCreate, 
        onCreate: createCall, 
        onUpdate: editCall, 
        addingNewRow: false})

    return (
        <div>
            <p style={{cursor: 'pointer'}} onClick={setPrevPage}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
            <h1>Balance sheet</h1>
            <hr />
            {formState.setupBS ? 
            <p>The balance sheet for this company have already been confirmed, please reset the wizard on the confirmation page to make changes.</p> : <>
                
                {calcTotals() === 0 ? 
                <InfoBanner type="positive" body="Your balance sheet totals zero therefore it is valid, however please check your entries before confirming"
                    title="Your balance sheet is valid and has been applied." />
                : <InfoBanner type="warning" title="Your balance sheet does not total zero, any existing opening balances have not been affected" 
                    body={`Your current balance is ${formatCurrency(calcTotals())} - please check your entries. You cannot confirm your balance until it totals zero.`} />}

                <p>Your balance sheet categorises the money held by your company. It shows your current financial position and is also important for the production of your year end accounts. 
                    Please submit the following details using the values from your company's balance sheet (trial balance), as at {formatDate(formState.setupMigrationDate)}.
                </p>

                {formState.setupWizardType === 1 && <>
                    As this is your first year, your reserves and the P&L account will be zero
                </>}
                {formState.setupWizardType === 2 && formState.setupMigrationDate === formState.fyStartDate ? <p>
                    Please complete the details below using your company's end of year accounts.<br />
                    As you are starting from the beginning of your accounting year, the "Expenses" and "Income" sections will be zero, and you won't need to enter them. 
                </p> : <p>
                    Please submit the following details using the values from your company's balance sheet (trial balance), as at {formatDate(formState.setupMigrationDate)}.
                </p>}
                <DataGrid.Table noHover>
                    <thead>
                        <tr>
                            <th colSpan={4}>
                                <label>Account group</label>
                                <br />
                                <Select 
                                    options={accountGroupSL.filter(x => formState.setupWizardType === 1 ? x.label !== 'Income' && x.label !== 'Expenses' : true)}
                                    value={accountGroupSL.find(x => x.value === curGroup)}
                                    onChange={(e) => setCurGroup(e?.value || '')}
                                    className={styles.bsSelectList}
                                    isSearchable={false}
                                />
                            </th>
                        </tr>
                        <tr>
                            <th>Group</th>
                            <th>Category</th>
                            <th>Amount</th>
                            <th>Edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formState.setupOpeningBalances?.filter(x => x.group === curGroup).map((x,i) =>
                            editing === x.id ? 
                            <tr key={x.id}>
                                <td>{x.group}</td>
                                <td>{x.accountName}</td>
                                <td>
                                    <FormikRowInput<OpeningBalancesPostModel> prefix="£" formik={formik} property="amount" type="number" />
                                </td>
                                <td> 
                                    <Button buttonType="save" onClick={() => {formik.submitForm()}} />
                                    <Button buttonType="cancel" onClick={() => setEditing(undefined)} />
                                </td>
                            </tr> :
                            <tr key={x.id}>
                                <td>{x.group}</td>
                                <td>{x.accountName}</td>
                                <td>{formatCurrency(x?.group === "Capital" || x?.group === "Income" || x?.group === "Liabilities" ? 0 - (x.amount || 0) : x.amount || 0)}</td>
                                <td>{x.canEdit ? <FontAwesomeIcon onClick={() => setEditing(x.id)} icon={faPencil} /> : <>Derived</>}</td>
                            </tr>
                        )}
                    </tbody>
                </DataGrid.Table>
            </>}
            <div className={styles.formButtons}>
                <Button onClick={setNextPage} variant="change">Continue</Button>                   
            </div>
            <WarningText />
        </div>
    )
}

export default BalanceSheet