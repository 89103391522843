import { PvSatrSavings } from "../../../../api/inni/data-contracts";
import FormikField from "../../../../elements/FormikField/FormikField";
import { Summary } from "../../../../elements/Summary/Summary";
import styles from '../Income.module.scss'
import { Button } from "../../../../elements/Button/Button";

interface SavingsInterestProps{
    remove:() => void;
    index: number
    showDeleteDialog: (handleOnClickDelete: () => void) => void
}

export default function SavingsInterest({remove, index, showDeleteDialog}: SavingsInterestProps){

    const handleOnClickDelete =() =>{
        showDeleteDialog(remove)
    }

    return(
        <Summary 
            useFullWidth
            showClose
            onClose={handleOnClickDelete}
        >   
            <h5 className="mb-3">{`Savings interest ${index+1}`}</h5>
            
            <FormikField<PvSatrSavings> 
                className={styles.formikField}
                name={`savings.${index}.name` as "name"}
                label="Payer's name"
                type="text"                                        
            />
            <FormikField<PvSatrSavings> 
                className={styles.formikNumberField}
                name={`savings.${index}.amount` as "amount"}
                label="Total amount received"
                type="number"
                prefix="£" 
                step="0.01"
                min="0"                                        
                onlyPositiveNumbers                                       
            />
            <br />
            <FormikField<PvSatrSavings> 
                className={styles.formikNumberField}
                name={`savings.${index}.taxAlreadyDeducted` as "taxAlreadyDeducted"}
                label="Tax already deducted"
                type="number"
                prefix="£"
                step="0.01"
                min="0"                                        
                onlyPositiveNumbers
            />
            <br />
            <FormikField<PvSatrSavings> 
                className={styles.formikField}
                name={`savings.${index}.details` as "details"}
                label="Please provide any additional information you think might be useful"
                type="textarea"     
                wrapLabel                               
            />

            <Button buttonType="delete" label="Delete" onClick={handleOnClickDelete} marginTop />
        </Summary>
    );
}
