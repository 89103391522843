import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout'
import * as Tabs from '../../elements/VerticalTabs/VerticalTabs';
import { useContext, useEffect, useState } from 'react';
import { Market, Person } from '../../api/inni/data-contracts';
import { People } from '../../api/inni/People';
import EmployeeTab from './Components/EmployeeTab';
import { Button } from '../../elements/Button/Button';
import styles from './QuickEntry.module.css'
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { Entity } from '../../utils/EntityAction';
import BrandContext from '../../context/BrandContext';
import provestorBrand from '../../components/BrandWrapper/pvBrand';
import { useInniAPI } from '../../hooks/useInniAPI';
import CompanyContext from '../../context/CompanyContext';
import { StableSort } from '../../utils/sort';
import InfoBanner from '../../elements/InfoBanner/InfoBanner';
import { Markets } from '../../api/inni/Markets';

const QuickEntry = () => {

    const peopleApi = useInniAPI(People)
    const [people,setPeople] = useState<Person[] | undefined>(undefined)
    const [peopleLoaded, setPeopleLoaded] = useState(false)

    const marketApi = useInniAPI(Markets)
    const [market, setMarket] = useState<Market | undefined>(undefined)
    const [marketLoaded, setMarketLoaded] = useState(false)

    const companyContext = useContext(CompanyContext)
    const brandContext = useContext(BrandContext)

    const [selectedKey, setSelectedKey] = useState<string|undefined>(undefined);

    const [showFormerEmployee, setShowFormerEmployees] = useState(false);

    useEffect(() => {
        if (peopleApi && !peopleLoaded) {
            peopleApi.getForQuickEntry(companyContext.cid)
            .then(res => {
                if (res.status === 200) {
                    setPeople(res.data)
                    setPeopleLoaded(true)
                } else {
                    console.error("Error getting employees for quickentry")
                }
            })
        }
    }, [companyContext.cid, peopleApi, peopleLoaded])

    useEffect(() => {
        if (marketApi && !marketLoaded) {
            marketApi.index(companyContext.cid)
            .then(res => {
                setMarket(res.data)
                setMarketLoaded(true)
            })
        }
    }, [companyContext.cid, marketApi, marketLoaded])

    useEffect(() => {
        if (peopleLoaded && people && people.find(x => x.defaultEmployee)) {
            setSelectedKey(people.find(x => x.defaultEmployee)?.id.toString())
        } else if (peopleLoaded && people && people.find(x => x.isActiveEmployee || x.isDirector)) {
            setSelectedKey(people.find(x => x.isActiveEmployee || x.isDirector)?.id.toString())
        }
    }, [people, peopleLoaded])

    const getTabElements = () => {
        let elements: JSX.Element[] = [];
        if (peopleLoaded && people) {
            const peopleTemp = [...people]
            StableSort(
                peopleTemp,
                (a, b) =>
                    a.defaultEmployee && !b.defaultEmployee
                        ? -1  // `a` comes first if `a` is defaultEmployee and `b` is not
                        : !a.defaultEmployee && b.defaultEmployee
                            ? 1   // `b` comes first if `b` is defaultEmployee and `a` is not
                            : (a.forenames || '') < (b.forenames || '')
                                ? -1  // Sort by forenames if defaultEmployee is the same
                                : (a.forenames || '') > (b.forenames || '')
                                    ? 1
                                    : (a.surname || '') < (b.surname || '')
                                        ? -1  // Sort by surname if forenames are the same
                                        : (a.surname || '') > (b.surname || '')
                                            ? 1
                                            : 0,  // Keep original order if everything is the same
                (a, b) =>
                    a.isActiveEmployee && !b.isActiveEmployee ? -1 : b.isActiveEmployee && !a.isActiveEmployee ? 1 : 0
            )
            .filter(x => (market && !market.isCorporate) || showFormerEmployee || x.isDirector || x.isActiveEmployee || x.isShareholder)
            .map(person => (
                elements.push(
                    <Tabs.Tab key={person.id.toString()} keyName={person.id.toString()}>
                        <span className={!person.isActiveEmployee ? 'text-muted' : undefined}>
                            {`${person.forenames} ${person.surname}`}
                        </span>
                    </Tabs.Tab>
                )
            ))
        }
        return elements
    }

    const hasInactiveEmployees = people && people.filter(x => (market && market.isCorporate) && !x.isDirector && !x.isActiveEmployee && !x.isShareholder).length > 0
    
    return (
        <DefaultLayout 
            useFullWidth 
            entity={Entity.QuickEntryV7} 
            noPadding 
            title={brandContext.brand === provestorBrand 
                        ? 'Expenses' 
                        : companyContext.company?.useV8UI 
                            ? "Mileage" 
                            : "Quick entry"
                }
        >
            <Tabs.Wrapper>
                { !peopleLoaded &&
                    <Tabs.Tabs selectedKey={selectedKey} onChangeTab={setSelectedKey} >                    
                        <Tabs.Tab keyName="loading" disabled><LoadingPlaceholder /></Tabs.Tab>
                        <Tabs.Tab keyName="loading" disabled><LoadingPlaceholder /></Tabs.Tab>
                        <Tabs.Tab keyName="loading" disabled><LoadingPlaceholder /></Tabs.Tab>
                    </Tabs.Tabs>
                }
                { selectedKey && people && peopleLoaded &&
                <>
                    {people.length > 1 && 
                    <Tabs.Tabs selectedKey={selectedKey} onChangeTab={setSelectedKey} 
                    appendEnd={hasInactiveEmployees ? 
                        <div className={styles.formerEmployeeBtn}>
                            <Button 
                                onClick={() => setShowFormerEmployees(!showFormerEmployee)} 
                                variant="light"
                            >{`${!showFormerEmployee ? 'Show' : 'Hide'}`} former employees</Button>
                        </div>
                        :
                        <></>
                    }
                    >                    
                        { getTabElements() }
                    </Tabs.Tabs>}
                    
                    <Tabs.Content>
                        <EmployeeTab 
                            curEmployeeId={Number(selectedKey)} 
                            curEmployeeName={people.find(x => x.id === Number(selectedKey))?.name} 
                            person={people.find(x => x.id === Number(selectedKey))} 
                            peopleLoaded={peopleLoaded} 
                        />
                    </Tabs.Content>
                </>
                }
                { !selectedKey && people && peopleLoaded &&
                    <Tabs.Content>
                        <InfoBanner
                            title="No eligible employees"
                            body={
                            <>
                                <span className='mr-3'>
                                To track your expenses and/or mileage, please ensure you have an active employee, default employee or a director.
                                </span>
                            </>
                            }
                            type="info"
                        />
                    </Tabs.Content>
                }
            </Tabs.Wrapper>
        </DefaultLayout>
    )

}

export default QuickEntry