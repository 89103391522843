/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Reminder } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Reminders<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
 * No description
 * 
 * @tags Reminders
 * @name Index
 * @summary Here's an interestung problem. The MVC version of this page uses a list of BaseReminderModel, the view then does an if x is PaymentModel etc
It's doable in swagger/TS but looks to be a right PITA so instead we flatten
 * @request GET:/api/v2/companies/{companyid}/reminders
 * @secure
 */
  index = (companyid: number, params: RequestParams = {}) =>
    this.request<Reminder[], any>({
      path: `/api/v2/companies/${companyid}/reminders`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Reminders
   * @name Calendar
   * @summary These feel very similar, so I'm sticking them close. The Calendar is just about events, not actions, so it's a subset of the reminders
   * @request GET:/api/v2/companies/{companyid}/reminders/Calendar
   * @secure
   */
  calendar = (companyid: number, query: { dateFrom: string; dateTo: string }, params: RequestParams = {}) =>
    this.request<Reminder[], any>({
      path: `/api/v2/companies/${companyid}/reminders/Calendar`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
