import { useCallback, useContext, useEffect, useState } from "react";
import { Accounts } from "../../api/inni/Accounts";
import { Assets } from "../../api/inni/Assets";
import { BankTransactions } from "../../api/inni/BankTransactions";
import { Account, AccountAsDetailed, AccountWithOLBS, Asset, BankTransaction, DatePicker, PendingPaymentModel } from "../../api/inni/data-contracts";
import { PendingPayments } from "../../api/inni/PendingPayments";
import { Filter, FilterLoading } from "../AccountsFilter/Filter";
import CompanyContext, { CompanyProduct } from "../../context/CompanyContext";
import { useInniAPI } from "../../hooks/useInniAPI";
import { AccountViewerNew, AccountViewerNewLoading, AccountViewerFeatureSet } from "../../components/AccountViewerNew/AccountViewerNew"
import SearchWithClear from "../../elements/SearchWithClear/SearchWithClear";
import InfoBanner from "../../elements/InfoBanner/InfoBanner";
import { PendingPaymentsLoading } from "../../components/AccountViewerNew/Components/PendingPayments";
import { formatDate } from "../../utils/formatDate";
import { formatCurrency } from "../../utils/formatNumbers";
import BrandContext from "../../context/BrandContext";
import provestorBrand from "../BrandWrapper/pvBrand";
import { useModalDialog } from "../../hooks/useModalDialog";
import { Button } from "../../elements/Button/Button";
import { useParams } from "react-router-dom";
import { useHasPermission } from "../../hooks/useHasPermission";
import { Action, Entity } from "../../utils/EntityAction";
interface RouteParams {  
    aid: string;
    sourceId?: string;
}
interface WrapperProps {
    getFilter: (yearCode: string|undefined) => Promise<DatePicker>,
    getTransactions: (yearCode: string) => Promise<BankTransaction[]>,
    bankAccountId?: number,
    featureSet?: AccountViewerFeatureSet,
    dateFilterLoadingEnabled?:boolean
}

export const AccountViewerNewWrapper = ({getFilter, bankAccountId, featureSet = AccountViewerFeatureSet.NONE, getTransactions,dateFilterLoadingEnabled} : WrapperProps) => {
    // Networking
    const bookkeepingApi = useInniAPI(BankTransactions, [400]);
    const accountsApi = useInniAPI(Accounts, [400]);
    const pendingPaymentsApi = useInniAPI(PendingPayments, [400]);
    const assetsApi = useInniAPI(Assets, [400]);
    const companyContext = useContext(CompanyContext);
    const brandContext = useContext(BrandContext);
    const {sourceId} = useParams<RouteParams>()
    // useState
    const [accountsLoaded, setAccountsLoaded] = useState(false);
    const [pendingPaymentsLoaded, setPendingPaymentsLoaded] = useState(false);
    const [assetsLoaded, setAssetsLoaded] = useState(false);

    const [transactions, setTransactions] = useState<BankTransaction[]>([]);
    const [transactionsLoaded, setTransactionsLoaded] = useState<boolean>(false);
    const [pendingPayments, setPendingPayments] = useState<PendingPaymentModel[]>();
    const [accounts, setAccounts] = useState<Account[]>()
    const [accountOLBS, setAccountOLBS] = useState<AccountWithOLBS>()
    const [assets, setAssets] = useState<Asset[]>();

    const [accountDetailed, setAccountDetailed] = useState<AccountAsDetailed>();
    const [accountDetailedLoaded, setAccountDetailedLoaded] = useState<boolean>(false);

    const [showModalDialog, modalDialog] = useModalDialog();

    useEffect(() => {
        if(accountsApi && !accountsLoaded) {
            accountsApi.index(companyContext.cid).then(results => {
                if (results.data) {
                    if(companyContext.product === CompanyProduct.isCap) {
                        setAccounts(results.data.filter(x => x.hasCategory))
                    }
                    else {
                        setAccounts(results.data)
                    }
                }
                setAccountsLoaded(true)
            }).catch(error => console.error(error))
        }
    }, [accountsApi, accountsLoaded, companyContext.cid, companyContext.product])

    useEffect(() => {
        if(accountsApi && bankAccountId) {
            accountsApi.getWithOlbs(companyContext.cid, bankAccountId || 0).then(res => {
                if (res.data) {                    
                    setAccountOLBS(res.data)
                }
            }).catch(error => console.error(error))

            accountsApi.get(companyContext.cid, bankAccountId || 0).then(res => {
                if (res.data) {                    
                    setAccountDetailed(res.data)
                    setAccountDetailedLoaded(true)
                }
            }).catch(error => console.error(error))
        }
    }, [accountsApi, bankAccountId, companyContext.cid])

    useEffect(() => {
        if (bankAccountId === 0) {
            setPendingPayments([])
            setPendingPaymentsLoaded(true)
        } else if(pendingPaymentsApi && !pendingPaymentsLoaded && bankAccountId && bankAccountId !== -1) {
            pendingPaymentsApi.pendingPayments(companyContext.cid, {accountid: bankAccountId}).then(results => {
                if(results.data) {
                    setPendingPayments(results.data)
                }
                setPendingPaymentsLoaded(true)
            })
        }
    }, [pendingPaymentsApi, pendingPaymentsLoaded, bankAccountId, companyContext.cid])

    useEffect(() => {
        if (featureSet !== AccountViewerFeatureSet.BOOKKEEPING) {
            setAssets([])
            setAssetsLoaded(true)
        } else if(assetsApi && !assetsLoaded) {
            assetsApi.index(companyContext.cid,).then(results => {
                if(results.data) {
                    setAssets(results.data)
                }
                setAssetsLoaded(true)
            })
        }
    }, [assetsApi, assetsLoaded, companyContext.cid, featureSet])

    const fetchTransactions = useCallback((yearCode: string) => {
        setTransactionsLoaded(false)
        getTransactions(yearCode).then(results => {
            if (results) {
                //'Only show expenses in quick entry, otherwise we'll see repayments from payroll and adjustments
                const showBookkeepingFeatures = featureSet !== AccountViewerFeatureSet.QUICKENTRY || (accountDetailed && !accountDetailed.payExpensesViaPayroll)
                setTransactions(!showBookkeepingFeatures ? results.filter(x => x.journalTypeId === "BP") : results)
            }
            setTransactionsLoaded(true)
        }).catch(error => {
            setTransactionsLoaded(true)
            console.error(error)
        })
    }, [getTransactions, featureSet, accountDetailed])

    const updateFilterRange = (parsedDate:Date) => {
        if(parsedDate && dateSelection[0] && dateSelection[1]) {
            if(parsedDate.getFullYear() < dateSelection[0].getFullYear() || parsedDate.getFullYear() > dateSelection[1].getFullYear()) {
                //This could be difficult to navigate if using financial or tax years (Odd year code), so just reset
                setCurrentYearCode(undefined)
            }
            else if(parsedDate < dateSelection[0] || parsedDate > dateSelection[1]) {
                //Same year, different month
                setNewMonth(parsedDate.getMonth())
                currentYearCode && fetchTransactions(currentYearCode)
            }
            else {
                currentYearCode && fetchTransactions(currentYearCode)
            }
            //Within date range, nothing to do
        }
    }

    const afterCreateBankPayment = (pendingId?: number, type?: string, date?: string, reloadAssets?: boolean) => {
        const parsedDate = date ? new Date(date) : undefined
        parsedDate && updateFilterRange(parsedDate)
        setPendingPaymentsLoaded(false) // don't remove if transaction
        if(reloadAssets) setAssetsLoaded(false)
    }
    
    const afterUpdateBankPayment = () => {
        getTransactions(currentYearCode || '').then((results) => {
            setTransactions(results)
            setAccountsLoaded(false)
            !transactionsLoaded && setTransactionsLoaded(true)
        })
    }

    const onDeleteBankPayment = (id:number, reloadAssets?: boolean) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (bookkeepingApi) {
                bookkeepingApi.deleteBankPayment(companyContext.cid, id)
                .then(res => {                   
                    if(transactions && res.status === 200) {
                        setPendingPaymentsLoaded(false) // we have no way of knowing if a payment was pending before we add it, so we have to reload the data when we delete a payemnt just in case
                        setCurrentYearCode(undefined)
                        if(reloadAssets) setAssetsLoaded(false)
                        resolve(true)
                    } else {
                        console.error(`Unexpected response code: ${res.status}`)
                        reject()
                    }
                })
                .catch(error => {
                    console.error(error.error);
                    reject();
                })
            } 
            else {
                reject();
            }
        })
    }

    const onDeleteBankTransfer = (id:number) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (bookkeepingApi) {
                bookkeepingApi.deleteBankTransfer(companyContext.cid, id)
                .then(res => {                    
                    if(transactions && res.status === 200) {
                        setPendingPaymentsLoaded(false) // we have no way of knowing if a payment was pending before we add it, so we have to reload the data when we delete a payemnt just in case
                        setCurrentYearCode(undefined)
                        resolve(true)
                    } else {
                        console.error(`Unexpected response code: ${res.status}`)
                        reject()
                    }
                })
                .catch(error => {
                    console.error(error.error);
                    reject();
                })
            } 
            else {
                reject();
            }
        })
    }

    const importNewStatementDialog = (account: AccountWithOLBS) => {
        showModalDialog(
            'Remove unallocated transactions',
            `Are you sure you want to remove your unallocated transactions?`,
            [
                <Button key="delete" variant="primary" label="Yes" onClick={() => {window.location.href = `/company/${companyContext.cid}/banking/UploadNewStatement?accountId=${account.id}`}} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    //===Filter stuff===//
    const [currentYearCode, setCurrentYearCode] = useState<string|undefined>(undefined);
    const [dateSelection, setDateSelection] = useState<[Date|undefined, Date|undefined]>([undefined, undefined]);
    const [newMonth, setNewMonth] = useState<number|undefined|null>(undefined);
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined)

    const filterMonthChanged = useCallback((startDate: Date, endDate: Date) => {
        setDateSelection([startDate, endDate]);
    }, [])

    const [currentBankAccountId, setCurrentBankAccountId] = useState<undefined|number>(undefined)

    useEffect(() => {
        if(currentBankAccountId !== bankAccountId) {
            if (currentYearCode !== undefined) {
                setCurrentYearCode(undefined)
            }
            setCurrentBankAccountId(bankAccountId)
        }
    }, [bankAccountId, currentBankAccountId, currentYearCode])

    useEffect(() => {
        if(currentYearCode) {
            fetchTransactions(currentYearCode)
        }
    }, [currentYearCode])
    


    const filterByMonth = (txn:BankTransaction) => {
        const txnDate = new Date(txn.date)
        return dateSelection[0] && dateSelection[1] && txnDate >= dateSelection[0] && txnDate <= dateSelection[1]
    }
    //================//
    const hasPermission = useHasPermission()
    const hasAMSupport = hasPermission(Entity.AccountManagerSupport, Action.All)[0]

    return (
        <>
            {/* Search */}
            {
                ( accounts === undefined ||
                    pendingPayments === undefined ||
                    assets === undefined ||
                    bankAccountId === -1 ||
                    ( !accountDetailed && featureSet !== AccountViewerFeatureSet.NONE &&
                        featureSet !== AccountViewerFeatureSet.PROPERTY_TRANSACTIONS &&
                        featureSet !== AccountViewerFeatureSet.RECEIPTS
                    ) || 
                    ( !accountDetailedLoaded &&
                        featureSet !== AccountViewerFeatureSet.NONE &&
                        featureSet !== AccountViewerFeatureSet.PROPERTY_TRANSACTIONS &&
                        featureSet !== AccountViewerFeatureSet.RECEIPTS
                    )
                ) && (
                    <>
                        <div style={{width: "400px", marginBottom: "1rem", display: 'inline-block'}}>
                            <SearchWithClear disabled search={searchTerm || ''} setSearch={setSearchTerm} placeholder="Search transactions" />
                        </div>
                        <FilterLoading />
                        <AccountViewerNewLoading featureSet={featureSet} />
                        {featureSet === AccountViewerFeatureSet.BOOKKEEPING && <PendingPaymentsLoading />}
                    </>
                )
            }

            {
                accounts && 
                ( accountOLBS ||
                    featureSet === AccountViewerFeatureSet.NONE ||
                    featureSet === AccountViewerFeatureSet.PROPERTY_TRANSACTIONS ||
                    featureSet === AccountViewerFeatureSet.RECEIPTS
                ) &&
                ( accountDetailed ||
                    featureSet === AccountViewerFeatureSet.NONE ||
                    featureSet === AccountViewerFeatureSet.PROPERTY_TRANSACTIONS ||
                    featureSet === AccountViewerFeatureSet.RECEIPTS
                ) && 
                ( accountDetailedLoaded ||
                    featureSet === AccountViewerFeatureSet.NONE ||
                    featureSet === AccountViewerFeatureSet.PROPERTY_TRANSACTIONS ||
                    featureSet === AccountViewerFeatureSet.RECEIPTS
                ) && 
                pendingPayments !== undefined &&
                assets !== undefined &&
                bankAccountId !== -1 && (
                    <>
                        {featureSet === AccountViewerFeatureSet.BOOKKEEPING && accountOLBS && accountOLBS?.supportsOpenBanking && 
                            <InfoBanner 
                                title="Open banking" 
                                body='This account now supports Open Banking, please visit the Import transactions page and click "Switch to Open Banking" to get set up.'
                                type="warning"
                            />
                        }

                        {featureSet === AccountViewerFeatureSet.BOOKKEEPING && accountDetailed?.showOverdueConfirmationWarning && 
                            <InfoBanner 
                                title="Your transactions do not match your bank statement" 
                                body="It's important that your transactions agree with your bank statement so that we can provide accurate financial forecasts. Please revisit the highlighted transactions and confirm they match."
                                type="warning"
                            />
                        }

                        {featureSet === AccountViewerFeatureSet.BOOKKEEPING && accountDetailed?.accountSubGroup === "DirectorsLoans" && !accountDetailed?.isExpenseAccount && 
                            <InfoBanner 
                                title="This is a director's loan account" 
                                body={<p>Care must be taken when borrowing money from a company as it can have implications for corporation tax, income tax and National Insurance. 
                                    Please <a 
                                        href={brandContext.brand !== provestorBrand ? "https://www.inniaccounts.co.uk/guide/directors-loan-account-borrowing-money/" : "https://knowledge.provestor.co.uk/portal/en/kb/articles/borrowing-money-from-your-company"} 
                                        target="_new" 
                                        rel="noreferrer"
                                        style={{textDecoration: 'underline'}}>read this guide</a> for further information{hasAMSupport ? " or contact your account manager if you have any questions." : "."}</p>}
                                type="info"
                            />
                        }

                        {featureSet === AccountViewerFeatureSet.BOOKKEEPING && accountOLBS && accountOLBS.showNotAlignedFeed && 
                            <InfoBanner 
                            body={<>
                                <strong>You need to match your transactions with your bank statement feed</strong>
                                <p>We are now receiving transactions from your bank. You now need to update your transactions so they match those received from your bank.
                                    To do this you should ensure you have no matched transactions after {formatDate(accountOLBS.confirmedToTarget)}, and the balance at this date should be {formatCurrency(accountOLBS.confirmedBalanceTarget)}</p>
                                <p>Your balance is currently {formatCurrency(accountOLBS.confirmedBalance)}, which is a difference of {formatCurrency((accountOLBS.confirmedBalance || 0) - accountOLBS.confirmedBalanceTarget)}. Please correct your transactions below to resolve this difference.</p>
                                <p>You can <a href="http://www.inniaccounts.co.uk/guide/bank-statement-feeds/" target="_new">read this guide</a>{hasAMSupport ? " or contact your account manager if you have any questions." : " if you have any questions."}</p>
                            </>} 
                            type="warning"
                            />
                        }

                        {featureSet === AccountViewerFeatureSet.BOOKKEEPING && accountOLBS && accountOLBS.showPhNotAlignedUpload && 
                            <InfoBanner 
                                body={<>
                                    <strong>You need to match your transactions with your imported bank statement</strong>
                                    <p>We cannot complete your yet import as the transactions do not align with those shown below. You now have two choices:</p>

                                    <strong>Either, edit / enter any missing transactions below</strong>
                                    <p>You must ensure that you have entered all transactions up to {formatDate(accountOLBS.confirmedToTarget)}, the balance at this date should be {formatCurrency(accountOLBS.confirmedBalanceTarget)}, 
                                        and you should have no transactions after this date. Your balance is currently {formatCurrency(accountOLBS.confirmedBalance)}, which is a difference of {formatCurrency((accountOLBS.confirmedBalance || 0) - accountOLBS.confirmedBalanceTarget)}. 
                                        Please correct your transactions below to resolve this difference and we can then import your new transactions.</p>
                                    
                                    <strong>Or, import a new statement from your bank</strong>
                                    <p>If you made a mistake when uploading your bank statement, you can import a new statement. You must ensure that all transactions on {formatDate(accountOLBS.confirmedTo)} are included in the statement you upload.</p>
                                    <p><Button variant="secondary" onClick={() => importNewStatementDialog(accountOLBS)}>Import new statement</Button></p>
                                    <p>You can <a href="http://www.inniaccounts.co.uk/guide/bank-statement-feeds/" target="_new">read this guide</a>{hasAMSupport ? " or contact your account manager if you have any questions." : " if you have any questions."}</p>
                                </>} 
                                type="warning"
                            />
                        }

                        <div style={{width: "400px", marginBottom: "1rem", display: 'inline-block'}}>
                            <SearchWithClear search={searchTerm || ''} setSearch={setSearchTerm} placeholder="Search transactions" />
                        </div>

                        <Filter newMonth={newMonth} year={currentYearCode} searching={searchTerm !== undefined && searchTerm.length > 0} getFilter={getFilter} yearChanged={setCurrentYearCode} monthChanged={filterMonthChanged} clearNewMonth={() => setNewMonth(null)} watchId={bankAccountId} isDataLoading={dateFilterLoadingEnabled?!transactionsLoaded:false}/>
                        {dateSelection && dateSelection[0] && dateSelection[1] && transactionsLoaded ? 
                        <AccountViewerNew
                            initialModalSourceId={sourceId || undefined}
                            accounts={accounts} 
                            currentAccount={accountOLBS}
                            pendingPayments={pendingPayments}
                            transactionsExist={transactions.length !== 0}
                            transactions={transactions.filter(x => filterByMonth(x)).filter(x => searchTerm ? ((x.description && x.description.toLowerCase().includes(searchTerm.toLowerCase())) || (x.payee && x.payee.toLowerCase().includes(searchTerm.toLowerCase()))) : true)} 
                            bankAccountId={bankAccountId || 0} 
                            assets={assets}
                            searching={searchTerm !== undefined && searchTerm.length > 0}

                            afterCreateBankPayment={afterCreateBankPayment} 
                            afterUpdateBankPayment={afterUpdateBankPayment}
                            onDeleteBankPayment={onDeleteBankPayment} 

                            onDeleteBankTransfer={onDeleteBankTransfer} 

                            reloadTransactions={afterUpdateBankPayment}

                            featureSet={featureSet}
                        /> : <AccountViewerNewLoading featureSet={featureSet} />}
                    </>
                )
            }

            { modalDialog }
        </>
    )
}
