import _ from 'lodash'
import { useContext, useState } from 'react'
import { Card } from 'react-bootstrap'
import { Contracts } from '../../../api/inni/Contracts'
import { Contract, UnbilledContractModel } from '../../../api/inni/data-contracts'
import CompanyContext from '../../../context/CompanyContext'
import { Button } from '../../../elements/Button/Button'
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList'
import { formatCurrency } from '../../../utils/formatNumbers'
import styles from '../Dashboard.module.scss'

const UnbilledWork = ({unbilledContracts} : {unbilledContracts : UnbilledContractModel[]}) => {

    const companyContext = useContext(CompanyContext)
    const [contracts, contractsLoaded] = useFetchEntityList<Contract, Contracts>(Contracts)

    const [showInactive, setShowInactive] = useState(false)

    return (
        <Card className={styles.reminderCard}>
            <Card.Body className={styles.dashboardCardBody}>
                <div style={{padding: '1rem', fontSize: '1.1rem'}}>Unbilled Work</div>
                <table style={{width: '100%'}}>
                    <thead>
                        <tr style={{borderBottom: '0.5px solid #eee', borderTop: '0.5px solid #eee'}}>
                            <th style={{width: '60%'}} className={styles.dashboardTablePadding}>Client / contract</th>
                            <th style={{width: '10%'}} className={styles.dashboardTablePadding}>Time</th>
                            <th style={{width: '10%'}} className={styles.dashboardTablePadding}>Expenses</th>
                            <th style={{width: '10%'}} className={styles.dashboardTablePadding}>Miles</th>
                            <th style={{width: '10%', minWidth: '50px'}} className={styles.dashboardTablePadding}></th>
                        </tr>
                    </thead>
                    <tbody>
                        { _.sortBy(unbilledContracts, 'isActive').reverse().filter(x => x.isActive || showInactive).map(x => {
                            return (
                                <tr key={x.contractId} className={styles.dashboardTableRow}>
                                    <td style={{wordBreak: 'break-all'}} className={styles.dashboardTablePadding}>{x.clientname} <br/> {x.contractName}</td>
                                    <td className={styles.dashboardTablePadding}>{x.unbilledTime} {contracts?.find(y => y.id === x.contractId)?.contractRateType?.replace("D", "days").replace("H", "hours")}</td>
                                    <td className={styles.dashboardTablePadding}>{formatCurrency(x.unbilledExpense)}</td>
                                    <td className={styles.dashboardTablePadding}>{x.unbilledMileage}</td>
                                    <td style={{minWidth: '50px'}} className={styles.dashboardTablePadding}>
                                        <Button tableBtn variant="primary"><a style={{color: 'white'}} href={`/Company/${companyContext.cid}/invoicing/edit?contractId=${x.contractId}`}>Invoice</a></Button>
                                    </td>
                                </tr>
                            )
                        })}
                        {unbilledContracts.filter(x => !x.isActive).length !== 0 &&
                        <tr>
                            <td className="text-muted" style={{ textAlign: 'center' }} colSpan={5}>
                                {unbilledContracts.filter(x => !x.isActive).length} inactive clients / contracts 
                                <Button className="ml-4" onClick={() => setShowInactive(!showInactive)} variant='light' tableBtn>{showInactive ? 'Hide' : 'Show'}</Button>
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </Card.Body>
        </Card>
    )
}

export default UnbilledWork