import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import _ from 'lodash';

type storageType = 'session' | 'local';

type jsonValue =
  | undefined
  | string
  | number
  | boolean
  | null
  | jsonValue[]
  | { [key: string]: jsonValue };

type allowTypes = 
  | ''
  | 'object'
  | 'boolean'
  | 'number'
  | 'string'
  | 'undefined'

const init = (
  type: storageType,
  key: string,
  defaultValue: jsonValue,
  allowList: jsonValue[],
  typeCheck: allowTypes
): jsonValue => {
  let storedValue: string | null;
  let returnValue: jsonValue;

  switch (type) {
    case 'session':
      storedValue = window.sessionStorage.getItem(key);
      break;
    case 'local':
      storedValue = window.localStorage.getItem(key);
      break;
    default:
      return defaultValue;
  }

  // entry doesn't exist yet - return defaultValue
  if (storedValue == null) return defaultValue;

  // json parse stored
  try {
    returnValue = JSON.parse(storedValue);
  } catch {
    if (storedValue === 'undefined') {
      returnValue = undefined;
    } else {
      return defaultValue;
    }
  }

  // check allow list / type check
  if (allowList.length > 0) {
    if (!_.some(allowList, (entry) => _.isEqual(entry, returnValue)))
      return defaultValue;
  } else if (typeCheck !== '') { // no need to type check if passed allow list
    if (typeof returnValue !== typeCheck)
      return defaultValue;
  }

  return returnValue;
};

/**
 * Hook for storing data locally, e.g. persisting UI state on refresh/loads.
 * Data returned is parsed as a JSON value unless undefined
 * `allowList` and `typeCheck` are optional error handling to avoid loading unexpected data.
 * @param type - Either 'session' or 'local' storage method (not for sensitive data!).
 * @param key - Key to store value, careful of naming collisions.
 *  Consider prepending with calling component name, e.g. 'PropertyListPage_key'.
 * @param defaultValue - Value to initialise and fallback to.
 * @param allowList - (optional) Array of allowed values, loading falls back to `defaultValue` if fail.
 *  E.g. could be used to prevent loading old data no longer compatible.
 *  Empty array allows all. Bypasses `typeCheck`.
 * @param typeCheck - (optional) Checks loaded type matches the (typeof) string passed
 * @returns state and useState.
 */
export const usePersistedState = (
  type: storageType,
  key: string,
  defaultValue: jsonValue,
  allowList: jsonValue[] = [],
  typeCheck: allowTypes = ''
): [any, Dispatch<SetStateAction<jsonValue>>] => {

  const [state, setState] = useState(() =>
    init(type, key, defaultValue, allowList, typeCheck)
  );

  useEffect(() => {
    if (type === 'session') {
      window.sessionStorage.setItem(key, JSON.stringify(state));
    } else if (type === 'local') {
      window.localStorage.setItem(key, JSON.stringify(state));
    }
  }, [type, key, state]);

  return [state, setState];
};
