import React from 'react';
// import Link from 'next/link';
import './Breadcrumbs.scss';

interface BreadcrumbsProps {
	crumbs: { link?: string; text: string, ref?:string }[];
	currentPage?: string;
	className?: string;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ crumbs, currentPage, className }) => {
	return (
		<nav aria-label="breadcrumb" className={className}>
			<ol className="breadcrumb noContainer">
				{crumbs.map(({ link, text }, index) => (
					<li key={index} className="breadcrumb-item">
						{link ? <a href={link}>{text}</a> : <span>{text}</span>}
					</li>
				))}
				{currentPage && <li className="breadcrumb-item">
					<span>{currentPage}</span>
				</li>}
			</ol>
		</nav>
	);
};

export default Breadcrumbs;
