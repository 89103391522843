import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { useContext } from 'react';
import OnboardingContext from '../../../context/OnboardingContext';
import { Button, ButtonVariant,} from '../../Button/Button';
interface ButtonBlok extends SbBlokData {
  variant: ButtonVariant;
}
type Props = {
	blok: ButtonBlok;
	onClick: () => void;
};
const StoryblokButton = ({ blok, onClick }: Props) => {
	const {isSubmitted} = useContext(OnboardingContext)
	return (
		<Button
			variant={blok.variant}
			disabled={isSubmitted}
			onClick={onClick}
			{...storyblokEditable(blok)}
		>
			{blok.title}
		</Button>
	);
};

export default StoryblokButton;
