import { useEffect } from 'react'
import { Employment, ManagePeopleDetails, PersonAsDetailed } from '../../../api/inni/data-contracts'
import { TabEditProps, TabTypes } from '../People'
import Benefits from './Benefits'
import PersonDetailsTab from './Details/DetailsTab'
import EmploymentTab from './Employment/EmploymentTab'
import ITR from './ITR'
import Payslips from './Payslips'
import PensionProvider from './Pensions/PensionProvider'
import PensionStatusList from './Pensions/PensionStatusList'
import { ShareholdingRecords } from './Shareholdings/Shareholdings'
import StatPayments from './StatPayments'
import TaxCodes from './TaxCodes/TaxCodes'

interface PersonTabContentProps {
    activePersonId : string, 
    activeTab : TabTypes, 
    people : ManagePeopleDetails, 
    personDetails : PersonAsDetailed | undefined, 
    personDetailsLoaded : boolean, 
    reloadPeople : () => void, 
    setPersonDetails : (i : PersonAsDetailed | undefined) => void,
    setDisabled: (i: boolean) => void;
    curEmployment : Employment | undefined
}

const PersonTabContent = ({
    activePersonId, 
    activeTab, 
    people, 
    personDetails, 
    personDetailsLoaded, 
    editing, setEditing, 
    reloadPeople, 
    setPersonDetails,
    setDisabled,
    curEmployment
} : PersonTabContentProps & TabEditProps) => {
    useEffect(() => {
		setDisabled(false);
	}, [activeTab, editing, setDisabled]);
    return (
        <>
            {activeTab === 'overview' && activePersonId && people &&
                <PersonDetailsTab
                setDisabled={setDisabled}
                    editing={editing} 
                    setEditing={setEditing}
                    showAutoEnrollExempt={people.showAutoEntrolExempt}
                    showInvalidSharesBanner={people.hasInvalidShares}
                    isCorporate={people.isCorporate}
                    person={personDetails}
                    loaded={personDetailsLoaded}
                    defaultName={people?.people?.find(x => x.defaultEmployee)?.name || ''}
                    reloadPeople={reloadPeople}
                    showAdminManagedMessage ={people.showAdminManagedMessage}
                />
            }
            {activeTab === 'employment' && activePersonId && personDetailsLoaded && curEmployment &&
                <EmploymentTab setDisabled={setDisabled} editing={editing} setEditing={setEditing} employment={curEmployment} person={personDetails} reloadPeople={reloadPeople} />
            }
            {activeTab === 'shares' && activePersonId && people && people.people &&
                <ShareholdingRecords person={people.people.find(x => x.id === parseInt(activePersonId))} reloadPeople={reloadPeople}/>
            }
            {activeTab === 'benefits' && activePersonId && personDetailsLoaded &&
                <Benefits setDisabled={setDisabled} editing={editing} setEditing={setEditing} person={personDetails} setPerson={setPersonDetails} />
            }
            {activeTab === 'itr' && activePersonId && personDetailsLoaded &&
                <ITR setDisabled={setDisabled} editing={editing} setEditing={setEditing} person={personDetails} setPerson={setPersonDetails} />
            }
            {activeTab === 'taxcode' && activePersonId && personDetailsLoaded &&
                <TaxCodes person={personDetails} />
            }
            {activeTab === 'payslips' && activePersonId && personDetailsLoaded &&
                <Payslips person={personDetails} />
            }
            {activeTab === 'pension' && activePersonId && <>
                <PensionStatusList person={personDetails} />
                <PensionProvider setDisabled={setDisabled} editing={editing} setEditing={setEditing} person={personDetails} setPerson={setPersonDetails} />
            </>}
            {activeTab === 'statutorypayments' && personDetails && personDetails.employmentId &&
                <StatPayments employmentId={personDetails?.employmentId} />
            }
        </>
    )
}

export default PersonTabContent