import { faEyeSlash, faLock, faUniversity } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { AccountEditorAsDetailed, AccountEditorPostModel, PersonForAccountEdit } from '../../../api/inni/data-contracts'
import { Button } from '../../../elements/Button/Button';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { Modal as ReactModal } from 'react-bootstrap';
import CreateModal, { NewType } from './CreateModal';
import { People } from '../../../api/inni/People';
import React, { useContext, useEffect, useState } from 'react';
import { useInniAPI } from '../../../hooks/useInniAPI';
import CompanyContext from '../../../context/CompanyContext';
import { useModalDialog } from '../../../hooks/useModalDialog';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import styles from '../AccountEdit.module.css'
import { formatSubGroup } from '../../../utils/formatters/formatAccount';
import { StableSort } from '../../../utils/sort';
import { AccountsEditor } from '../../../api/inni/AccountsEditor';

const order = ['Assets', 'Liabilities', 'Capital', 'Income', 'Expenses']

const AccountList = ({showCreateModal, setShowCreateModal} : {showCreateModal : boolean, setShowCreateModal : (i : boolean) => void}) => {

    const accountAPI = useInniAPI(AccountsEditor)
    const peopleApi = useInniAPI(People)
    const companyContext = useContext(CompanyContext)

    const [accounts, setAccounts] = useState<AccountEditorAsDetailed[] | undefined>(undefined)
    const [accountsLoaded, setAccountsLoaded] = useState(false)

    const [showModalDialog, modalDialog] = useModalDialog();
    

    useEffect(() => {
        if (accountAPI && !accountsLoaded) {
            accountAPI.index(companyContext.cid)
            .then(res => {
                const sortedArray=StableSort<AccountEditorAsDetailed>(res.data,
                    (x,y)=> x.accountSubGroup>y.accountSubGroup?1:-1,
                    (x,y) => order.findIndex(g => g === x.accountGroup) > order.findIndex(g => g === y.accountGroup) ? 1 : -1);

                setAccounts(sortedArray)                
                setAccountsLoaded(true)
            })
        }
    }, [accountAPI, accountsLoaded, companyContext.cid])


    const [people, setPeople] = useState<PersonForAccountEdit[] | undefined>(undefined)
    const [peopleLoaded, setPeopleLoaded] = useState(false)

    const [editing, setEditing] = useState<number | undefined>(undefined)
    const [newType, setNewType] = useState<NewType>('bank')

    useEffect(() => {
        if (peopleApi && !peopleLoaded) {
            peopleApi.getForAccountEdit(companyContext.cid)
            .then(res => {
                if (res.status === 200) {
                    setPeople(res.data)
                    setPeopleLoaded(true)
                } else {
                    console.error(`Unexpected response code ${res.status}`)
                }
            })
        }
    }, [companyContext.cid, peopleApi, peopleLoaded])

    // use when rendering the list - used to check if we need new title/subtitle
    let lastGroup = ''
    let lastSubGroup = ''

    const getTypeFromDetail = (acc : AccountEditorAsDetailed) : NewType => {
        if (acc.accountGroup === 'Assets' && acc.accountSubGroup === 'BankAccounts')
            return 'bank'
        else if (acc.accountGroup === 'Liabilities' && acc.accountSubGroup === 'CreditCardsLoans')
            return 'loan'
        else if (acc.accountGroup === 'Liabilities' && acc.accountSubGroup === 'DirectorsLoans' && !acc.isExpenseAccount)
            return 'dloan'
        else if (acc.accountGroup === 'Liabilities' && acc.accountSubGroup === 'DirectorsLoans' && acc.isExpenseAccount)
            return 'exp'
        else if (acc.accountGroup === 'Liabilities' && acc.accountSubGroup === 'Mortgages')
            return 'mortgage'
        else
            return 'other'
    }

    const addUpdateAccount = (acc : AccountEditorAsDetailed) => {
        let existing = accounts?.findIndex(x => x.id === Number(acc.id))
        let tempAcc = accounts

        if (existing !== undefined && existing !== -1 && tempAcc) {
            // it exists - update!            
            tempAcc[existing] = acc
            setAccounts(tempAcc)
        } else {
            // it doesn't exist - we create a new one!
            if (tempAcc) {
                tempAcc.push(acc)
                setAccounts(tempAcc)
            } else {
                tempAcc = []
                tempAcc.push(acc)
                setAccounts(tempAcc)
            }
        }
    }

    const showDeleteModal = (id: number) => {
        showModalDialog(
            'Delete account?',
            `Are you sure you want to delete this account?`,
            [
                <Button key="yes" variant="danger" label="Yes" onClick={() => deleteAccount(id)} />,
                <Button key="no" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const deleteAccount = (id : number) => {
        if (accountAPI) {
            accountAPI.delete(companyContext.cid, { accountId: id })
            .then(res => {
                if (res.status === 200) {
                    setAccounts(accounts?.filter(x => x.id !== id))
                }
            })
        }
    }
    
    return (<>
        <DataGrid.Table noHover>
            <tbody>
                {(!accountsLoaded) && <>
                    <tr>
                        <th className={styles.tableHeader} colSpan={4}>
                            <LoadingPlaceholder dark width="25%" />
                        </th>
                    </tr>
                    <DataGrid.LoadingRow cols={4} />
                    <DataGrid.LoadingRow cols={4} />
                    <DataGrid.LoadingRow cols={4} />
                    <tr>
                        <th className={styles.tableHeader} colSpan={4}>
                            <LoadingPlaceholder dark width="25%" />
                        </th>
                    </tr>
                    <DataGrid.LoadingRow cols={4} />
                    <DataGrid.LoadingRow cols={4} />
                    <DataGrid.LoadingRow cols={4} />
                </>}
                {accounts && accountsLoaded && 
                <>{accounts.map(x => {
                    let isNewSection = x.accountGroup !== lastGroup
                    let isNewSubSection = x.accountSubGroup !== lastSubGroup
                    lastGroup = x.accountGroup || ''
                    lastSubGroup = x.accountSubGroup || ''
                    return (
                    <React.Fragment key={x.id}>
                        {isNewSection && <tr style={{background: 'var(--topbar-background-color)'}}><th className={styles.tableHeader}  colSpan={4}>{x.accountGroup}</th></tr>}
                        {isNewSubSection && <tr><td colSpan={4}><b>{formatSubGroup(x.accountSubGroup)}</b></td></tr>}
                        <tr>
                            <td style={{width: '95px', verticalAlign: 'middle'}}>
                                {!x.canRename && <FontAwesomeIcon icon={faLock} style={{marginRight: '7px'}} />}
                                {x.bankAccount && <FontAwesomeIcon icon={faUniversity} style={{marginRight: '7px'}} />}
                                {x.isHidden && <FontAwesomeIcon icon={faEyeSlash} />}
                            </td>
                            <td data-cy="accountName" style={{verticalAlign: 'middle'}}>{x.name}</td>
                            <td data-cy="personName" style={{verticalAlign: 'middle'}}>{x.personName}</td>
                            <td style={{width:'210px'}}>
                                {x.canRename && 
                                    <Button onClick={() => {setNewType(getTypeFromDetail(x)); setShowCreateModal(true); setEditing(x.id)}} buttonType="edit" tableBtn />
                                }
                                {x.canDelete && 
                                    <Button onClick={() => showDeleteModal(x.id)} buttonType="delete" tableBtn />
                                }
                            </td>
                        </tr>
                    </React.Fragment>)
                })}</>}
            </tbody>

        </DataGrid.Table>
        {accounts && people && accountsLoaded && peopleLoaded && 
            <ReactModal size="lg" show={showCreateModal} onHide={() => {setShowCreateModal(false); setEditing(undefined)}}>
                <CreateModal 
                    editingValues={{...accounts.find(x => x.id === editing) as AccountEditorPostModel, newType: newType} || undefined} 
                    editing={editing !== undefined} 
                    editingId={editing}
                    accounts={accounts} 
                    people={people}
                    cancelClick={() => { setShowCreateModal(false); setEditing(undefined) }}
                    createUpdateCb={addUpdateAccount}
                />
            </ReactModal>
        }
        { modalDialog }
    </>)
    
}

export default AccountList