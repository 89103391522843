import { Col, Dropdown, Row } from "react-bootstrap";
import { CompanyConsultations } from "../../api/inni/CompanyConsultations";
import { Consultation, ConsultationPostModel, ConsultationsSummary } from "../../api/inni/data-contracts";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout"
import * as DataGrid from '../../elements/DataGrid/DataGrid';
import { asYearMonthDay, formatDate } from "../../utils/formatDate";
import { formatCurrency } from "../../utils/formatNumbers";
import { Action, Entity } from "../../utils/EntityAction";
import { Button } from "../../elements/Button/Button";
import Toolbar from "../../layouts/Desktop/Toolbar";
import { faCog, faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal as ReactModal } from 'react-bootstrap';
import { ConsultationsEditForm } from "./Components/ConsultationsEditForm";
import { FormikHelpers, FormikErrors } from "formik";
import { useContext, useEffect, useState } from "react";
import { useInniAPI } from "../../hooks/useInniAPI";
import CompanyContext from "../../context/CompanyContext";
import { useModalDialog } from "../../hooks/useModalDialog";
import { useAssertPermission } from "../../hooks/useAssertPermission";
import { ConsultationCountKpi } from "./DashboardElements/ConsultationCount";
import { ConsultationCycleEndKpi } from "./DashboardElements/ConsultationCycleEndDate";
import { NoContentSlate } from "../../elements/Slates/NoContentSlate";
import { DatagridType } from "../../hooks/terms/useDatagridTerms";

const ConsultationsListPage = () => {

    useAssertPermission(Entity.Consultation, Action.All);

    const consultationsAPI = useInniAPI(CompanyConsultations, [400])
    const companyContext = useContext(CompanyContext)

    const [consultationsData, setConsultationsData] = useState<Consultation[] | undefined>(undefined)
    const [summary, setSummary] = useState<ConsultationsSummary | undefined>(undefined)
    const [summaryLoaded, setSummaryLoaded] = useState(false)
    const [consultationsLoaded, setConsultationsLoaded] = useState(false);
    const [curConsultationId, setCurConsultationId] = useState<number | undefined>(undefined)
    const [curModalShowing, setCurModalShowing] = useState<string | undefined>(undefined)
    const [showModalDialog, modalDialog] = useModalDialog();

    useEffect(() => {
        if (!consultationsLoaded && consultationsAPI) {
            consultationsAPI.index(companyContext.cid).then(
                response => {
                    setConsultationsData(response.data);
                    setConsultationsLoaded(true);
                }
            )
        }
    }, [companyContext.cid, consultationsAPI, consultationsLoaded])

    useEffect(() => {
        if (!summaryLoaded && consultationsAPI) {
            consultationsAPI.summary(companyContext.cid).then(
                response => {
                    setSummary(response.data);
                    setSummaryLoaded(true);
                }
            )
        }
    }, [companyContext.cid, consultationsAPI, summaryLoaded])

    const getCurrentInitialValues = () => {
        if (consultationsData && curConsultationId) {
            const consultation : (Consultation | undefined) = consultationsData.find(x => x.id === curConsultationId);
            if (consultation) {
                const t:ConsultationPostModel = {
                    notes: consultation.notes || '',
                    isBundled: consultation.isBundled,
                    pricePaid: consultation.pricePaid,
                    consultationDate: consultation.consultationDate || ''
                }
                return t;
            } else return ({consultationDate: asYearMonthDay(new Date()),notes: '',isBundled: (summary?.numFree || 0) + (summary?.numUsed || 0) !== 0, pricePaid: undefined})
        } else return ({consultationDate: asYearMonthDay(new Date()),notes: '',isBundled: (summary?.numFree || 0) + (summary?.numUsed || 0) !== 0, pricePaid: undefined})
    }

    const formValidateCreate =  (values: ConsultationPostModel) : Promise<FormikErrors<ConsultationPostModel>> => {
        return new Promise((resolve, reject) => {
            if (consultationsAPI) {
                return consultationsAPI.validateCreate(companyContext.cid, values)
                .then(() => resolve({}))    
                .catch(error => resolve(error.error))            
            } else {
                reject();
            } 
        })         
    }

    const formValidateUpdate =  (values: ConsultationPostModel) : Promise<FormikErrors<ConsultationPostModel>> => {
        return new Promise((resolve, reject) => {
            if (consultationsAPI && curConsultationId) {
                return consultationsAPI.validateUpdate(companyContext.cid, curConsultationId, values)
                .then(() => resolve({}))    
                .catch(error => resolve(error.error))            
            } else {
                reject();
            } 
        })         
    }

    const formSubmitCreate = (values: ConsultationPostModel, actions: FormikHelpers<ConsultationPostModel>) : Promise<void>=> {
        return new Promise((resolve, reject) => {
            if (consultationsAPI) {
                consultationsAPI.create(companyContext.cid, values)
                .then(data => {
                    if (consultationsData) {
                        let tempData = [...consultationsData];
                        tempData.push({id: Number(data.data), notes: values.notes, consultationDate: values.consultationDate, pricePaid: values.pricePaid, isBundled: values.isBundled})
                        setConsultationsData(tempData)
                    }
                    const endDate : Date | undefined = summary?.consultationCycleEnd ? new Date(summary?.consultationCycleEnd) : undefined;
                    if (endDate && values.isBundled
                        && new Date(values.consultationDate) < endDate 
                        && new Date(values.consultationDate) > new Date(endDate.getFullYear() + -1, endDate.getMonth(), endDate.getDay()))
                    {
                        let tempSummary = summary;
                        if (tempSummary) {
                            tempSummary.numFree--;
                            tempSummary.numUsed++;
                            setSummary(tempSummary);
                        }                        
                    }               
                    setCurModalShowing(undefined);    
                    resolve();
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } else {
                reject();
            }
        })
    }

    const formSubmitUpdate = (values: ConsultationPostModel, actions: FormikHelpers<ConsultationPostModel>) : Promise<void>=> {
        return new Promise((resolve, reject) => {
            if (consultationsAPI && curConsultationId) {
                consultationsAPI.update(companyContext.cid, curConsultationId, values)
                .then(() => {
                    if (consultationsData) {
                        let tempData = [...consultationsData];
                        let index = tempData.findIndex(x => x.id === curConsultationId)

                        const endDate : Date | undefined = summary?.consultationCycleEnd ? new Date(summary?.consultationCycleEnd) : undefined;
                        if (endDate && tempData[index].isBundled
                            && new Date(tempData[index].consultationDate) < endDate 
                            && new Date(tempData[index].consultationDate) > new Date(endDate.getFullYear() + -1, endDate.getMonth(), endDate.getDay()))
                        {    
                            let tempSummary = summary;
                            if (tempSummary) {
                                tempSummary.numFree++;
                                tempSummary.numUsed--;
                                setSummary(tempSummary);
                            }         
                        }

                        tempData[index] = {id: curConsultationId, notes: values.notes, consultationDate: values.consultationDate, pricePaid: values.pricePaid, isBundled: values.isBundled}

                        if (endDate && tempData[index].isBundled
                            && new Date(tempData[index].consultationDate) < endDate 
                            && new Date(tempData[index].consultationDate) > new Date(endDate.getFullYear() + -1, endDate.getMonth(), endDate.getDay()))
                        {    
                            let tempSummary = summary;
                            if (tempSummary) {
                                tempSummary.numFree--;
                                tempSummary.numUsed++;
                                setSummary(tempSummary);
                            }         
                        }

                        setConsultationsData(tempData)
                    }                    
                    setCurModalShowing(undefined);    
                    resolve();
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } else {
                reject();
            }
        })
    }

    const showConsultationDeleteDialog = (consultationId : number, date : string = '') => {
        showModalDialog(
            'Delete consultation?',
            `Are you sure you want to delete the consultation on '${date}'?`,
            [
                <Button key="delete" variant="danger" label="Yes" onClick={() => deleteConsultation(consultationId)} />,
                <Button key="no" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const deleteConsultation = (consultationId : number) => {
        if (consultationsAPI) {
            consultationsAPI.delete(companyContext.cid, consultationId)
                .then(_res => {
                    let tempCon = consultationsData;
                    if (tempCon) {                        
                        const endDate : Date | undefined = summary?.consultationCycleEnd ? new Date(summary?.consultationCycleEnd) : undefined;
                        const delCon = tempCon.find(x => x.id === consultationId);
                        if (endDate && delCon && delCon.isBundled
                            && new Date(delCon.consultationDate) < endDate 
                            && new Date(delCon.consultationDate) > new Date(endDate.getFullYear() + -1, endDate.getMonth(), endDate.getDay()))
                        {    
                            let tempSummary = summary;
                            if (tempSummary) {
                                tempSummary.numFree++;
                                tempSummary.numUsed--;
                                setSummary(tempSummary);
                            }         
                        }
                        setConsultationsData(tempCon.filter(x => x.id !== consultationId))                   
                    }                            
                })
        }
    }

    return (
        <DefaultLayout entity={Entity.Consultation} title="Consultations" greyBackground>
            <Toolbar>
                <Button onClick={() => {setCurConsultationId(undefined);setCurModalShowing('new')}} entity={Entity.Consultation} action={Action.Create} buttonType="new" />
            </Toolbar>
            <Row>
                <Col xs={12} md={6} lg={3}>
                    <ConsultationCountKpi numUsed={summary?.numUsed} numFree={summary?.numFree} loading={!summaryLoaded} />
                </Col>
                <Col xs={12} md={6} lg={3}>
                    { summary &&
                        <ConsultationCycleEndKpi loading={!summaryLoaded} date={summary?.numUsed + summary?.numFree === 0 ? "N/A" : summary?.consultationCycleEnd} />
                    }
                </Col>
            </Row>
            <DataGrid.Table noHover>
                <thead>
                    <tr>
                        <th style={{width: '15%'}}>Consultation Date</th>
                        <th style={{width: '60%'}}>Notes</th>
                        <th style={{width: '10%'}}>Bundled</th>
                        <th style={{width: '10%'}}>Price Paid</th>
                        <th style={{width: '5%'}}></th>
                    </tr>
                </thead>
                <tbody>
                    { !consultationsLoaded &&
                        <>
                        <DataGrid.LoadingRow cols={5} />
                        <DataGrid.LoadingRow cols={5} />
                        <DataGrid.LoadingRow cols={5} />
                        <DataGrid.LoadingRow cols={5} />
                        </>
                    }
                    { consultationsLoaded && consultationsData && consultationsData.length > 0 &&
                        consultationsData.sort((a,b) => new Date(b.consultationDate).getTime() - new Date(a.consultationDate).getTime()).map(i => {
                            return (<tr key={i.id}>
                                <td>{formatDate(i.consultationDate)}</td>
                                <td>{i.notes}</td>
                                <td>{i.isBundled ? 'Yes' : 'No'}</td>
                                <td>{!i.isBundled ? formatCurrency(i.pricePaid || 0) : ''}</td>
                                <td style={{textAlign: 'right'}}>
                                        <Dropdown style={{ marginLeft: 'auto' }}>
                                            <Dropdown.Toggle size="sm" variant="primary" className="button">
                                                <FontAwesomeIcon icon={faCog} style={{fontSize: '14px'}} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => {setCurConsultationId(i.id); setCurModalShowing('edit')}}>
                                                    <FontAwesomeIcon icon={faEdit} className="fa-fw mr-2" />
                                                    Edit
                                                </Dropdown.Item>
                                                <Dropdown.Item onClick={() => showConsultationDeleteDialog(i.id, formatDate(i.consultationDate))}>
                                                    <FontAwesomeIcon onClick={() => showConsultationDeleteDialog(i.id, formatDate(i.consultationDate))} icon={faTrash} className="fa-fw mr-2" />
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>   
                            </tr>)
                        })
                    }
                    { consultationsLoaded && consultationsData && consultationsData.length === 0 &&
                        <tr>
                            <td colSpan={5}  style={{ padding:0 }}>
                                <NoContentSlate type={DatagridType.TaxConsultations} termsKey="emptyTerms" whiteBg />
                            </td>
                        </tr>
                    }
                </tbody>
            </DataGrid.Table>
            {summary &&
            <>
                <ReactModal size="lg" show={curModalShowing === 'new'} onHide={() => setCurModalShowing(undefined)}>
                    <ConsultationsEditForm 
                        onCancelClick={() => setCurModalShowing(undefined)} 
                        initialValues={getCurrentInitialValues()} 
                        formSubmit={formSubmitCreate} 
                        formValidate={formValidateCreate}
                        title="New consultation" 
                        summary={summary}
                    />
                </ReactModal>
                <ReactModal size="lg" show={curModalShowing === 'edit'} onHide={() => {setCurConsultationId(undefined); setCurModalShowing(undefined)}}>
                    <ConsultationsEditForm 
                        onCancelClick={() => {setCurConsultationId(undefined); setCurModalShowing(undefined)}} 
                        initialValues={getCurrentInitialValues()} 
                        formSubmit={formSubmitUpdate} 
                        formValidate={formValidateUpdate} 
                        title="Edit consultation"
                        summary={summary} 
                    />
                </ReactModal>
            </>}
            { modalDialog }
        </DefaultLayout>
    )
}

export default ConsultationsListPage