import { useState } from 'react';
import { Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { InvoiceAsDetailed, InvoiceEmailModel, InvoiceEmailPostModel, InvoiceRefund } from '../../api/inni/data-contracts';
import { Invoices } from '../../api/inni/Invoices';
import { Button } from '../../elements/Button/Button';
import { useFetchSingleEntity } from '../../hooks/entities/useFetchSingleEntity';
import { useInniAPI } from '../../hooks/useInniAPI';
import { useModalDialog } from '../../hooks/useModalDialog';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import Toolbar from '../../layouts/Desktop/Toolbar';
import { Action, Entity } from '../../utils/EntityAction';
import { ReadInvoiceWrapper } from './Components/ReadInvoiceWrapper';
import { useCustomWebRequests } from '../../hooks/useCustomWebRequests';
import Loadingtemplate from './Components/Loadingtemplate';
import styles from './Invoice.module.css';
import { useHasPermission } from '../../hooks/useHasPermission';
import { FormikErrors, FormikHelpers } from 'formik';
import EmailPdfTemplate from './Components/EmailPdfTemplate';
import DismissibleBanner, { DismissibleBannerProps } from '../../elements/DismissibleBanner/DismissibleBanner';
interface RouteParams {
	id: string;
	cid: string;
	year: string;
}
type buttonContainerProps = {
	invoiceDetails: InvoiceAsDetailed;
	showPdf: () => void;
	invoiceId: string | number;
};
function ReadInvoice() {
	const hasPermission = useHasPermission();
	const year = useParams<RouteParams>().year;
	const customRequest = useCustomWebRequests();
	const invoiceId = parseInt(useParams<RouteParams>().id);
	const companyId = parseInt(useParams<RouteParams>().cid);
	const [pdfLoading, setPdfLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const navigateToEntity = useNavigateToEntity();
	const [invoice, invoiceLoaded, reloadInvoice] = useFetchSingleEntity<InvoiceAsDetailed, Invoices>(Invoices, invoiceId);
	const invoiceAPI = useInniAPI(Invoices, [400]);
	const invoiceAPIwithout400 = useInniAPI(Invoices, []);
	const [showModalDialog, modalDialog] = useModalDialog();
	const [emailContent, setEmailContent] = useState<InvoiceEmailModel | null>();
	const [bannerProps,setBannerProps] = useState<DismissibleBannerProps>({showBanner:false,body:'',type:'danger'});

	const deleteInvoice = (): Promise<void> => {
		return new Promise((resolve, reject) => {
			if (invoiceAPI) {
				invoiceAPI
					?.delete(companyId, invoiceId)
					.then((response) => {
						if(response.status === 200){
						navigateToEntity(Entity.InvoiceV7, Action.List, { code: year });
						resolve();
						}
						else{
							setBannerProps({showBanner:true, body: response.error.errorUser,type:'danger'});
						}
					})
					.catch((error) => reject());
			} else {
				reject();
			}
		});
	};

	const clearIR35 = (): Promise<void> => {
		return new Promise((resolve, reject) => {
			if (invoiceAPIwithout400) {
				invoiceAPIwithout400
					?.clearIr35(companyId, invoiceId)
					.then((response) => {
						if(response.status === 200){
							reloadInvoice();
							resolve();
						}
					})
					.catch((error) =>{
						reject();
					});
			} else {
				reject();
			}
		});
	};

	const zeroRemainingBalance = (): Promise<void> => {
		return new Promise((resolve, reject) => {
			if (invoiceAPI) {
				invoiceAPI
					?.zeroRemainingBalance(companyId, invoiceId)
					.then((response) => {
						if(response.status === 200){
							reloadInvoice();
							resolve();
						}
					})
					.catch((error) => {
						setBannerProps({showBanner:true,body:'Error while zeroing the remaining balance',type:'danger'});
						reject();
					});
			} else {
				reject();
			}
		});
	};

	const showDeleteInvoiceDialog = () => {
		showModalDialog(
			'Delete Invoice ?',
			`Are you sure you want to delete this Invoice?`,
			[<Button variant="danger" label="Yes" onClick={() => deleteInvoice()} />, <Button variant="secondary" label="No" onClick={() => {}} />],
			false
		);
	};

	const showZeroInvoiceDialog = () => {
		showModalDialog(
			'Zero Invoice ?',
			`Are you sure you want to zero the remaining balance, if so an adjustment or balancing payment may also be required`,
			[<Button variant="danger" label="Yes" onClick={zeroRemainingBalance} />, <Button variant="secondary" label="No" onClick={() => {}} />],
			false
		);
	};

	const viewInvoicePDF = async (): Promise<void> => {
		setPdfLoading(true);
		customRequest(`invoices/PDF/${invoiceId}`)
			.then((data) => {
				if(data.status === 200){
					return data.blob();
				}
				else{
					setBannerProps(data => ({...data, showBanner: true, body:'Error while fetching the pdf.', type: 'danger'}));
					setPdfLoading(false);
					throw new Error('Error while fetching the pdf');
				}
			})
			.then((response) => {
				const url = response ? window.URL.createObjectURL(response) : '#';
				let a = document.createElement('a');
				a.setAttribute('type', 'hidden');
				a.href = url;
				a.target = '_blank';
				//setting the file name in front end
				a.download = invoice ? `${invoice?.documentType}${invoice?.invoiceNumber}.pdf` : 'invoice.pdf';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				a.remove();
				setBannerProps(data => ({...data, showBanner: true, body: `${invoice ? `${invoice?.documentType}${invoice?.invoiceNumber}.pdf` : 'invoice.pdf'} downloaded successfully`, type: 'success'}));
				setPdfLoading(false);
			})
			.catch((error) => error);
	};

	const viewMigrationPDF = async (): Promise<void> => {
		setPdfLoading(true);
		customRequest(`invoices/MigrationPDF/${invoice?.migrationInvoiceFilestoreDocumentId}`)
			.then((data) => {
				if(data.status === 200){
					return data.blob(); 
				}
				else{
					//TODO:How does the user know the error if the request failed ?
				}
			})
			.then((response) => {
				const url = response ? window.URL.createObjectURL(response) : '#';
				let a = document.createElement('a'); 
				a.setAttribute('type', 'hidden');
				a.href = url ? url : '';
				a.target = '_blank';
				//setting the file name in front end
				a.download = invoice ? `${invoice?.documentType}${invoice?.invoiceNumber}.pdf` : 'migrationinvoice.pdf';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				a.remove();
				setPdfLoading(false);
			})
			.catch((error) => error);
	};

	const getEmailContent = async (): Promise<void> => {
		invoiceAPI &&
			invoiceAPI
				?.getEmailModel(companyId, invoiceId)
				.then((response) => {
					setEmailContent(response.data);
				})
				.catch((error) => error);
	};

	const onEmailSubmit = (values: InvoiceEmailPostModel, actions: FormikHelpers<InvoiceEmailPostModel>): Promise<void> => {
		return new Promise((resolve, reject) => {
			setShowModal(false);
			if (values) {
				invoiceAPI
					?.emailInvoice(companyId, invoiceId, values)
					.then((response) => {
						if (response.status === 200) {
							setBannerProps(data => ({...data, showBanner: true, body: 'Your email has been sent successfully', type: 'success'}));
							resolve();
						}
					})
					.catch((error) => {
						setShowModal(false);
						setBannerProps(data => ({...data, showBanner: true, body:'Unable to send the email', type: 'danger'}));
						reject();
					});
			} else {
				reject();
				setShowModal(false);
			}
		});
	};

	const validateEmail = (values: InvoiceEmailPostModel): Promise<FormikErrors<InvoiceEmailPostModel>> => {
		return new Promise((resolve, reject) => {
			if (invoiceAPI && companyId) {
				return invoiceAPI
					.validateEmail(companyId, invoiceId, values)
					.then(() => {
						resolve({});
					})
					.catch((error) => {
						resolve(error.error);
					});
			} else {
				reject();
			}
		});
	};

	const creditRefundAmount = async (invoiceRefund: InvoiceRefund | undefined): Promise<void> => {
		invoiceAPIwithout400 && 
			invoiceRefund &&
			invoice?.selectedPaymentProviderId &&
			(await invoiceAPIwithout400
				.refund(companyId, invoiceId, { paymentProviderId: invoice?.selectedPaymentProviderId }, invoiceRefund)
				.then((response) => {
					if (response.status === 200) {
						reloadInvoice();
						setBannerProps(data => ({...data, showBanner: true, body: 'Your payment has been received', type: 'success'}));
					}
				})
				.catch((error) => {
					setBannerProps(data => ({...data, showBanner: true, body: 'Payment unsuccessful', type: 'danger'}));
				}));
	};

	const confirmTaxDeduction = async (): Promise<void> => {
		invoiceAPIwithout400 &&
			invoice &&
			invoice?.amountTotal &&
			(await invoiceAPIwithout400
				.confirmTaxDeducted(companyId, invoiceId, { taxDeduction: invoice?.amountTotal - invoice?.amountPaid })
				.then((response) => {
					if (response.status === 200) {
						reloadInvoice();
					}
				})
				.catch((error) => error));
	};

	const onClickInvoiceTimeLineButton = async (id:number) : Promise<void> => {
		invoiceAPIwithout400?.deleteBalancingPayments(companyId, id)
		.then((response) => {
			if (response.status === 200) {
				reloadInvoice();
			}
		})
		.catch((error) => {
			console.error(error);
		});
	}

	function InvoiceButtonsContainer({ invoiceDetails, invoiceId, showPdf }: buttonContainerProps) {
		const { editability, showCanNotClearIR35, showClearIR35 } = invoiceDetails;

		const disabledMessage = (text: string): string => {
			return `This invoice cannot be ${text}
              because at least part of it has been paid
              or it has been included in a VAT return.`;
		};

		return (
			<div>
				<Row>
					<Button
						buttonType="done"
						className="m-1"
						entity={Entity.InvoiceV7}
						disabled={false}
						disabledMessage=""
						onClick={() => navigateToEntity(Entity.InvoiceV7, Action.List, { year: year })}></Button>
					<div className="mx-3 mx-lg-2 mx-md-0"></div>
					<Button
						buttonType="edit"
						className="m-1"
						entity={Entity.InvoiceV7}
						action={Action.Edit}
						extraParams={{ id: invoiceId, year: year }}
						disabled={!editability?.canEdit}
						disabledMessage={editability?.cantEditReason}></Button>
					{invoice && invoice.documentType !== 'InvoiceTemplate' && (
						<Button
							buttonType="copy"
							className="m-1"
							label="Copy"
							entity={Entity.InvoiceV7}
							disabled={invoiceDetails.isMigrationInvoice}
							action={Action.Create}
							extraParams={{ rid: invoiceId, doctype: invoice.documentType, action: 'copyinvoice', year: year }}
							disabledMessage={invoiceDetails.isMigrationInvoice ? disabledMessage('copied') : undefined}></Button>
					)}
					<Button
						buttonType="delete"
						className="m-1"
						entity={Entity.InvoiceV7}
						action={Action.Delete}
						disabled={!editability?.canDelete}
						disabledMessage={editability?.cantDeleteReason}
						onClick={showDeleteInvoiceDialog}></Button>
					{hasPermission(Entity.InvoiceAdmin, Action.All)[0] && invoice && invoice?.documentType !== 'InvoiceTemplate' && (
						<Button
							buttonType="edit"
							className="m-1"
							entity={Entity.InvoiceV7}
							action={Action.All}
							admin={true}
							label="Zero balance"
							onClick={showZeroInvoiceDialog}
							disabled={invoice?.amountPaid - invoice?.amountTotal === 0}
							disabledMessage={'No balance to write off'}
						/>
					)}
					{showClearIR35 && hasPermission(Entity.InvoiceAdmin, Action.All)[0] && (
						<>
							<div className="mx-3 mx-lg-2 mx-md-0"></div>
							<Button buttonType="cancel" entity={Entity.InvoiceV7} action={Action.All} admin={true} onClick={clearIR35} label="Clear IR35" />
						</>
					)}
					{showCanNotClearIR35 && (
						<Button
							buttonType="cancel"
							className="m-1"
							entity={Entity.InvoiceV7}
							action={Action.All}
							admin={true}
							disabledMessage="Non taxable pay element has been included on a payslip"
							disabled={true}
							label="Clear IR35"
						/>
					)}
					<div className="mx-3 mx-lg-2 mx-md-0"></div>
					{invoice && invoice.documentType !== 'InvoiceTemplate' && (
						<Button buttonType="file" className="m-1" onClick={showPdf} label="View PDF" disabled={pdfLoading}></Button>
					)}
					{invoice && invoice.documentType !== 'InvoiceTemplate' && (
						<Button
							buttonType="email"
							onClick={() => {
								setShowModal(!showModal);
								getEmailContent();
							}}
							className="m-1"
							label="Email PDF"
							disabledMessage={invoiceDetails.isMigrationInvoice ? disabledMessage('emailed') : undefined}
							disabled={invoiceDetails.isMigrationInvoice}></Button>
					)}
					<div className="mx-3 mx-lg-2 mx-md-0"></div>
					{invoiceDetails.documentType === 'Invoice' && (
						<Button
							buttonType="fileInvoice"
							className="m-1"
							label="Issue credit note"
							entity={Entity.InvoiceV7}
							action={Action.Create}
							disabled={invoiceDetails.isMigrationInvoice}
							disabledMessage={invoiceDetails.isMigrationInvoice ? disabledMessage('copied') : undefined}
							extraParams={{ rid: invoiceId, doctype: 'CreditNote', action: 'issuecreditnote' }}></Button>
					)}
					<div className="mx-3 mx-lg-2 mx-md-0"></div>
					{invoiceDetails.documentType === 'Quote' && (
						<Button
							buttonType="fileInvoice"
							className="m-1"
							label="Create invoice"
							entity={Entity.InvoiceV7}
							action={Action.Create}
							extraParams={{ rid: invoiceId, doctype: 'Invoice', action: 'createinvoice' }}></Button>
					)}
				</Row>
			</div>
		);
	}

	return (
		<div className={styles.font}>
			{!invoiceLoaded && <Loadingtemplate></Loadingtemplate>}
			{invoiceLoaded && (
				<DefaultLayout
					noPadding
					useFullWidth
					title={
						invoice && invoice?.documentType !== 'InvoiceTemplate'
							? invoice?.invoiceNumber.toString().padStart(4, '0') + ':' + invoice?.description
							: invoice?.description
					}>
					<Toolbar>
						{modalDialog}
						<div className="pl-3">
							{invoice && (
								<InvoiceButtonsContainer
									invoiceDetails={invoice}
									invoiceId={invoiceId}
									showPdf={invoice.isMigrationInvoice ? viewMigrationPDF : viewInvoicePDF}
								/>
							)}
						</div>
					</Toolbar>
					
					<EmailPdfTemplate
						setShow={setShowModal}
						onEmailSubmit={onEmailSubmit}
						validateEmail={validateEmail}
						emailDetails={emailContent}
						showModal={showModal}></EmailPdfTemplate>

					<div className={`${styles.invoiceReadWrapper}`}>
					<DismissibleBanner bannerProps={bannerProps}/>
						{invoice && (
							<ReadInvoiceWrapper
								confirmTaxdeducton={confirmTaxDeduction}
								onClickInvoiceTimeLineButton={onClickInvoiceTimeLineButton}
								refundAmount={creditRefundAmount}
								companyId={companyId}
								invoiceDetails={invoice}></ReadInvoiceWrapper>
						)}
					</div>
				</DefaultLayout>
			)}
		</div>
	);
}
export default ReadInvoice;
