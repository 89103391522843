import { Card } from 'react-bootstrap';
import { Button } from '../../../../elements/Button/Button';
import { useContext } from 'react';
import { CreatedBookkeepingAccountResponse, NotReconnectedAccount, ReconnectedAccount } from '../../../../api/inni/data-contracts';
import BrandContext from '../../../../context/BrandContext';
import { useNavigateToEntity } from '../../../../hooks/useNavigateToEntity';
import { Action, Entity } from '../../../../utils/EntityAction';
import { addDays } from 'date-fns';
import styles from '../importbank.module.scss'
import { LoadingPlaceholder } from '../../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import classNames from 'classnames';
import { useHasPermission } from '../../../../hooks/useHasPermission';


interface importDataProps{
    bookkeepingAccounts: CreatedBookkeepingAccountResponse [] | undefined;
    bankName: string |undefined ;
    reauthorisedAccounts?: string[];
    reauthorisedAccountsWithAuthorisation?: string[];
    reauthorisedAccountsNoAuthorisation?: string[];
    NotReauthorisedAccounts?: string[];
    isReauth?: boolean;
    consentExpiresAt?: string | undefined; //date consent expires at, used for displaying reauth date afterimporting account for existing consent
}

interface reconnectionImportDataProps{
    reconnectedAccounts: ReconnectedAccount[] |undefined;
    notConnectedAccounts: NotReconnectedAccount[] |undefined;
    bankName: string |undefined ;
}

export function LoadingImportSuccess (){
    
    return(
        <Card>
            <Card.Header>
                <LoadingPlaceholder/>
            </Card.Header>
            <Card.Body>
                <div>
                    <div>
                        <LoadingPlaceholder/>
                        <LoadingPlaceholder/>
                        <LoadingPlaceholder/>
                    </div>
                </div>
            </Card.Body>
            <Card.Footer>
                <div style={{float: "right"}}>
                    {/* <div className={classNames(styles.loadingButton, styles.loading)}/> */}
                    <div className={classNames(styles.loadingButton, styles.loading)}/>
                </div>
                
            </Card.Footer>

        </Card>
    )

}

export function ReconnectionCompleted ({reconnectedAccounts, notConnectedAccounts, bankName}: reconnectionImportDataProps){

    const brandContext= useContext(BrandContext)
    const navigateToEntity = useNavigateToEntity();
    const hasPermission = useHasPermission()
    const useV8 = hasPermission(Entity.BookkeepingV8, Action.Create)[0]

    return(
        <Card>
            {reconnectedAccounts === null ?
                    <Card.Header className={styles.failedHeader}>
                        <p className='mb-0'> Relink failed</p>
                    </Card.Header>
                :
                    <Card.Header className={styles.successHeader}>
                        <p className='mb-0'> Your accounts have been reconnected.</p>
                    </Card.Header>
            }
            <Card.Body>
            {reconnectedAccounts === null ?
                <p>The accounts you selected on your Bank's page did not match the reconnection request, no accounts have been reconnected.</p>
            :
                <div>
                    <div>
                        <p>The following accounts have been reconnected to {bankName} through Open Banking.</p>
                        <ul>
                            {reconnectedAccounts && reconnectedAccounts.map((account) =>{
                                    return(
                                        <li key={account.bookkeepingAccountId}>
                                            {account.bookkeepingAccountDetail?.name}
                                        </li>
                                    )    
                                })
                            }
                        </ul>
                        
                        {notConnectedAccounts && notConnectedAccounts.length > 0 &&
                            <>
                                <br/>
                                <br/>
                                <p>The following accounts have no matching bookkeeping accounts.</p>
                                <ul>
                                    {notConnectedAccounts && notConnectedAccounts.map((account) =>{
                                            return(
                                                <li key={account.bookkeepingAccountId}>
                                                    {account.bookkeepingAccountDetail?.name}
                                                </li>
                                            )    
                                        })
                                    }
                                </ul>
                            </>
                        }

                                <ul>
                                    {notConnectedAccounts && notConnectedAccounts.map((account) =>{
                                            return(
                                                account.potentialMatches?.map((x) => {
                                                    return(
                                                    <li key={x.accountNumber}>
                                                        {x.accountNumber}
                                                    </li>)
                                                })
                                                
                                            )    
                                        })
                                    }
                                </ul>
                        

                        <p>
                            In order to keep your transaction data flowing through to {brandContext.brand.name}, you are required to reauthorise every 90 days due to SCA requirements. You will be able to do this
                            from the bookkeeping section. Your next reauthorisation date is:  {addDays(new  Date(), 90).toDateString()}.
                        </p>
                    </div>
                </div>
            }
            </Card.Body>
            <Card.Footer>
                <div style={{float: "right"}}>
                    {/* <Button variant='primary' label='Go back to dashboard'/>  */}
                    {useV8 ?
                    <Button variant='primary' onClick={() => navigateToEntity(Entity.BookkeepingV8)} label='Go to accounts' marginTop />
                    :
                    <Button variant='primary' onClick={() => navigateToEntity(Entity.CompanyDashboard)} label='Go to dashboard' marginTop />
                    }
                </div>
                
            </Card.Footer>

        </Card>
    )

}

export function ImportCompleted({ bookkeepingAccounts, bankName, isReauth, reauthorisedAccounts, reauthorisedAccountsWithAuthorisation, reauthorisedAccountsNoAuthorisation, NotReauthorisedAccounts, consentExpiresAt }: importDataProps) {

    const brandContext = useContext(BrandContext)
    const navigateToEntity = useNavigateToEntity();
    const hasPermission = useHasPermission()
    const useV8 = hasPermission(Entity.BookkeepingV8, Action.Create)[0]

    return (
        <>
            {!(bankName &&( isReauth ? (reauthorisedAccounts && reauthorisedAccounts?.length >0) : true) ) ?
                <LoadingImportSuccess />
                :
                <>
                    <Card>
                        {/* TODO:Remove this condition when reconnect changes */}
                        {isReauth && reauthorisedAccountsWithAuthorisation && reauthorisedAccountsWithAuthorisation.length === 0 ?
                            <Card.Header className={styles.failedHeader}>
                                <p className='mb-0'> Re-authorisation failed</p>
                            </Card.Header>
                         :
                            <Card.Header className={styles.successHeader}>
                                <p className='mb-0'> Your accounts have been {isReauth ? "reauthorised" : "created"}.</p>
                            </Card.Header>
                        }
                        
                        <Card.Body>
                            <div>
                                <div>
                                    {isReauth &&
                                        <>

                                            {reauthorisedAccountsWithAuthorisation && reauthorisedAccountsWithAuthorisation.length === 0 ?
                                                <p>The accounts you selected on your Bank's page did not match the reauthorisation request, no accounts have been authorised.</p>
                                                
                                                :

                                            <>

                                                {reauthorisedAccountsWithAuthorisation && reauthorisedAccountsWithAuthorisation.length > 0 &&
                                                    <>
                                                        <p>The following accounts have been reauthorised for {bankName} through Open Banking:</p>
                                                        <ul>
                                                            {reauthorisedAccountsWithAuthorisation && reauthorisedAccountsWithAuthorisation.map((account) => {
                                                                return (
                                                                    <li key={account}>
                                                                        {bankName}-{"****"}{account.length > 4 ? account.substring(account.length - 4) : account}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </>
                                                }
                                                
                                                {reauthorisedAccountsNoAuthorisation && reauthorisedAccountsNoAuthorisation.length > 0 &&
                                                    <>
                                                        <br/>
                                                        <p>Please note that following accounts are no longer authorised because you did not re-select them on your bank's website. If you would like to reauthorise these also, please restart the process selecting all the accounts required.</p>
                                                        <ul>
                                                            {reauthorisedAccountsNoAuthorisation.map((account) => {
                                                                return (
                                                                    <li key={account}>
                                                                        {bankName}-{"****"}{account.length > 4 ? account.substring(account.length - 4) : account}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </>
                                                }

                                                {NotReauthorisedAccounts && NotReauthorisedAccounts.length > 0 &&
                                                    <>
                                                        <br/>
                                                        <p>The following accounts could not be reauthorised because they are held on a separate consent, please reauthorise these separately through the bookkeeping page:</p>
                                                        <ul>
                                                            {NotReauthorisedAccounts.map((account) => {
                                                                return (
                                                                    <li key={account}>
                                                                        {bankName}-{"****"}{account.length > 4 ? account.substring(account.length - 4) : account}
                                                                    </li>
                                                                )
                                                            })}
                                                        </ul>
                                                    </>
                                                }
                                                <p>If you would like to add a new account, you can do this separately through the bookkeeping page.</p>

                                            </>

                                            }
                                        </>
                                    }

                                    {!isReauth &&
                                        <>
                                            <p>The following accounts have been created and linked to {bankName} through Open Banking.</p>

                                            <ul>
                                                {bookkeepingAccounts && bookkeepingAccounts.map((account) => {
                                                    return (
                                                        <li key={account.bookkeepingAccountId}>
                                                            {account.name}
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </>
                                    }
                                    {(( reauthorisedAccountsWithAuthorisation && reauthorisedAccountsWithAuthorisation.length !== 0) || (!isReauth)) &&
                                        <p>
                                            In order to keep your transaction data flowing through to {brandContext.brand.name}, you are required to reauthorise every 90 days due to SCA requirements. You will be able to do this
                                            from the bookkeeping section. Your next reauthorisation date is: {consentExpiresAt ? consentExpiresAt : addDays(new Date(), 90).toDateString()}.
                                        </p>
                                    }
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            <div style={{ float: "right" }}>
                            {useV8 ?
                            <Button variant='primary' onClick={() => navigateToEntity(Entity.BookkeepingV8)} label='Go to accounts' marginTop />
                            :
                            <Button variant='primary' onClick={() => navigateToEntity(Entity.CompanyDashboard)} label='Go to dashboard' marginTop />
                            }
                            </div>
                        </Card.Footer>
                    </Card>
                </>
            }
        </>
    );
}