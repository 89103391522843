import React, { FC } from 'react';
import styles from './SplitSummaryEntry.module.scss';
import cn from 'classnames';
import Icon from '../../elements/Icon/Icon';

type Props = {
	item: number;
	title: string;
	categoryFlow: React.ReactNode;
	price: string;
	note: string;
	description: string;
	className?: string;
	editRecord?: () => void;
  	deleteRecord?: () => void;
	pill?: React.ReactElement;
};

const SplitSummaryEntry: FC<Props> = ({ item, title, categoryFlow, price, note, description, className, editRecord, deleteRecord, pill }) => {
	return (
		<div className={cn(styles.summaryEntry, className)} data-cy="summaryEntry">
			<div className={styles.summaryEntryInner}>
				<div className={styles.item}>
					<p className="mb-0">ITEM</p>
					<b>{item}</b>
				</div>

				<div className="d-flex justify-content-between align-items-center w-100">
					<div className={styles.summaryData}>
						<b>{title}</b>
						<p className={cn(styles.categories, 'mb-0')}>{categoryFlow}</p>
						{ description !== title && (
							<small className="d-block">{description ? description : "No description"}</small>
						)}
						<small className="d-block">{note}</small>
						{pill && <span style={{marginTop: '0.25rem', display: 'block'}}>{pill}</span> }
					</div>

					<div>
						<b>{price}</b>
					</div>
				</div>
			</div>

			<div className={styles.actionButtons}>
				{editRecord && <span data-cy="editEntry" onClick={editRecord}><Icon name='edit'/></span>}
				{deleteRecord && <span data-cy="deleteEntry" onClick={deleteRecord}><Icon name='trash'/></span>}
			</div>
		</div>
	);
};

export default SplitSummaryEntry;
