/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AccountingPeriodReviewItem,
  AccountingPeriodReviewItemPostModel,
  ReviewItemClientResponsePostModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class AccountingPeriodsReviewItem<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags AccountingPeriodsReviewItem
   * @name GetReviewItems
   * @request GET:/api/v2/companies/{companyId}/accountingperiods/{apid}/reviewitems
   * @secure
   */
  getReviewItems = (companyId: number, apid: number, params: RequestParams = {}) =>
    this.request<AccountingPeriodReviewItem[], any>({
      path: `/api/v2/companies/${companyId}/accountingperiods/${apid}/reviewitems`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AccountingPeriodsReviewItem
   * @name UpdateReviewItem
   * @request PUT:/api/v2/companies/{companyId}/accountingperiods/{apid}/reviewitems
   * @secure
   */
  updateReviewItem = (
    companyId: number,
    apid: number,
    query: { riid: number },
    pm: AccountingPeriodReviewItemPostModel,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/accountingperiods/${apid}/reviewitems`,
      method: "PUT",
      query: query,
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AccountingPeriodsReviewItem
   * @name SaveReviewItems
   * @request POST:/api/v2/companies/{companyId}/accountingperiods/{apid}/reviewitems
   * @secure
   */
  saveReviewItems = (
    companyId: number,
    apid: number,
    pm: AccountingPeriodReviewItemPostModel,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/accountingperiods/${apid}/reviewitems`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AccountingPeriodsReviewItem
   * @name GetReviewItemsByClient
   * @request GET:/api/v2/companies/{companyId}/accountingperiods/{apid}/reviewitems/ReviewItemsByClient
   * @secure
   */
  getReviewItemsByClient = (companyId: number, apid: number, params: RequestParams = {}) =>
    this.request<AccountingPeriodReviewItem[], any>({
      path: `/api/v2/companies/${companyId}/accountingperiods/${apid}/reviewitems/ReviewItemsByClient`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags AccountingPeriodsReviewItem
   * @name PublishItemsToClient
   * @request PUT:/api/v2/companies/{companyId}/accountingperiods/{apid}/reviewitems/PublishReviewItemsToClient
   * @secure
   */
  publishItemsToClient = (companyId: number, apid: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/accountingperiods/${apid}/reviewitems/PublishReviewItemsToClient`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags AccountingPeriodsReviewItem
   * @name ClientResponse
   * @request PUT:/api/v2/companies/{companyId}/accountingperiods/{apid}/reviewitems/{riid}/Response
   * @secure
   */
  clientResponse = (
    companyId: number,
    apid: number,
    riid: number,
    pm: ReviewItemClientResponsePostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/accountingperiods/${apid}/reviewitems/${riid}/Response`,
      method: "PUT",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags AccountingPeriodsReviewItem
   * @name AcceptSuggestion
   * @request PUT:/api/v2/companies/{companyId}/accountingperiods/{apid}/reviewitems/{riid}/AcceptSuggestion
   * @secure
   */
  acceptSuggestion = (companyId: number, apid: number, riid: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/accountingperiods/${apid}/reviewitems/${riid}/AcceptSuggestion`,
      method: "PUT",
      secure: true,
      ...params,
    });
}
