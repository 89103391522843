import { Button } from "../../../../elements/Button/Button";
import styles from "../BankSelection.module.css"
import { Link, useHistory } from "react-router-dom";

interface welcomeProps{
    onclick: () => void
}

const BankSelectionWelcome=({onclick}:welcomeProps)=>{
    return(            
            <div className={styles.welcomeOuterLayout}>
                <div className={styles.welcomeInnerLayout}>
                <div className={styles.welcomeLayout}>
                    <p className={styles.H2Title}>Welcome to Provestor's Banking Section!</p>
                    <p className={`${styles.normal} ${styles.welcomeText}`}> For a smooth property investment journey, a dedicated business bank account is essential. It not only helps in keeping your personal and investment finances separate but also ensures accurate financial tracking. With a business bank account, you can easily manage transactions, monitor expenses, and maintain clear financial records for your limited company. Let's guide you through the process.</p>
                </div>
                <div className={styles.welcomeButtonLayout}>
                        <Button className={styles.startButton} variant="primary" label="Let's start" onClick={onclick}/>
                        <Link to={`connecttobank`} className={styles.linkText}>
                            <p className={styles.linkText}>I already have a bank account</p>
                        </Link>
                </div>
                </div>
                    
            </div>        
    );
    
}

export default BankSelectionWelcome;