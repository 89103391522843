import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Field, FieldProps, Form, Formik, FormikErrors, FormikHelpers, FormikProps, FormikTouched, FormikValues, useFormik, useFormikContext } from "formik";
import CompanyContext from "../../../context/CompanyContext";
import { Button } from "../../../elements/Button/Button";
import Icon from "../../../elements/Icon/Icon";
import FormikField from "../../../elements/FormikField/FormikField";
import FullScreen from "../../../components/Fullscreen/Fullscreen";
import { BrandWrapper } from "../../../components/BrandWrapper/BrandWrapper";
import { LettableUnitListItem } from "../../../hooks/lists/useLettableUnitsList";
import { TenancyPostModel } from "../../../api/inni/data-contracts";
import OptionSelector from "../../../components/OptionSelector/OptionSelector";
import styles from '../Tenancy.module.scss'
import { Text as EditText, Currency as EditCurrency, Submit, ListOption, DateSelector } from "../../../elements/EditRow/EditRow";
import { useInniAPI } from "../../../hooks/useInniAPI";
import { Properties } from "../../../api/inni/Properties";
import { Summary } from "../../../elements/Summary/Summary";
import { Currency, Date, Text, YesNo } from '../../../elements/DetailRow/DetailRow';
import classNames from "classnames";
import { useLettingAgentsList } from "../../../hooks/lists/useLettingAgentsList";
import Select from 'react-select';
import { InputGroup, Form as ReactForm } from "react-bootstrap";

interface CreateTenancyProps{
    isOpen: boolean;
    hide: ()=>void;
    onCancel: () => void;
    intialValues: TenancyPostModel;
    formSubmit: (values: TenancyPostModel, actions: FormikHelpers<TenancyPostModel>) => Promise<void>;
    formValidate: (values: TenancyPostModel) => Promise<FormikErrors<TenancyPostModel>>;
    lettableUnitId: number|undefined
}

export default function CreateTenancy({isOpen, hide, onCancel, intialValues, formSubmit, formValidate, lettableUnitId} : CreateTenancyProps){

    //stages
    type STAGE_NAMES = "intro"|"property"|"newProp"|"tenancyPeriod"|"moveInandOutPeriod"|"rentDetails"|"managedByAgent"|"agentDetails"|"newAgent"|"tenantDetails"|"submit"
    const [stageFlow, setStageFlow] = useState<STAGE_NAMES[]>()
    const [currentStage, setCurrentStage] = useState<STAGE_NAMES>();

    //context
    const companyContext = useContext(CompanyContext)

    const {agents, addNewAgentOption} = useLettingAgentsList();
    const propertiesAPI = useInniAPI(Properties)
    const [newPropertyName, setNewPropertyName] = useState<string>()
    const [newAgentName, setNewAgentName] = useState<string>()
    const [propertiesLoaded, setPropertiesLoaded] = useState(false);
    const [propertiesDisable, setPropertiesDisable] = useState(false);
    const [agentDisable, setAgentDisable] = useState(false);
    const [lettableUnits, setLettableUnits] = useState<LettableUnitListItem[]>([])

    const formikRef = useRef<FormikProps<TenancyPostModel>>(null);

    const agentsList = useMemo(() => {
        let out:Array<ListOption> = [];
        agents.forEach(theme => {
            out.push({
                value: theme.id.toString(), 
                label: theme.name||''
            })
        } )
        return out;
    }, [agents])
    
    useEffect(() =>{
        if(lettableUnitId && formikRef.current && !formikRef.current.values.propertyLettableUnitId){
            formikRef.current?.setFieldValue("propertyLettableUnitId", lettableUnitId);
        }
    }, [lettableUnitId, formikRef.current])
    
    useEffect(() => {
        if(stageFlow === undefined) {
        type STAGE_NAMES = "intro"|"property"|"newProp"|"tenancyPeriod"|"moveInandOutPeriod"|"rentDetails"|"managedByAgent"|"agentDetails"|"newAgent"|"tenantDetails"|"submit"
            let stages:STAGE_NAMES[] = ["intro"];

            if(lettableUnitId){
                stages.push("tenancyPeriod", "moveInandOutPeriod", "rentDetails", "managedByAgent", "agentDetails", "newAgent", "tenantDetails", "submit")
            }
            else{
                stages.push("property", "newProp", "tenancyPeriod", "moveInandOutPeriod", "rentDetails", "managedByAgent", "agentDetails", "newAgent", "tenantDetails", "submit")
            }
            setStageFlow(stages)
            setCurrentStage("intro")
        }
    }, [stageFlow])

    useEffect(() => {
        if (companyContext && propertiesAPI && !propertiesLoaded ) {
            propertiesAPI.index(companyContext.cid).then(
                response => {
                    const tempLettableUnits:LettableUnitListItem[] = []
                    response.data.forEach(property => {
                        property.lettableUnits?.forEach(unit => {
                            const multiUnit = property.lettableUnits ? property.lettableUnits.length > 1 : false;
                            tempLettableUnits.push({
                                id: unit.id, 
                                name: unit.name,
                                lettingType: unit.lettingType,
                                propertyId: property.id,
                                propertyName: property.name,
                                propertyIsMultiUnit: multiUnit,
                                fullName: (multiUnit && unit.name) ? `${property.name} → ${unit.name}` : property.name
                            })
                        })
                    })
                    setLettableUnits(tempLettableUnits)
                    setPropertiesLoaded(true)
                }
            ).catch((error) => {
                setPropertiesLoaded(true)
                console.error(error)
            })
        }
    }, [companyContext, propertiesAPI, propertiesLoaded])

    const getStageDetails = () => {
        switch(currentStage) {
            case "intro":
                return  {    
                            Title: "Starting a new tenancy? Let's begin!", 
                        }
            case "property":
                return  {    
                            Title: "Which property is your tenant moving into?", 
                            Subtitle: "Select the property from your list. If it's not listed, add the property first"
                        }
            case "newProp":
                return  {    
                            Title: "Create Property", 
                            Subtitle: "This may be a property you already own, or just one you are thinking about purchasing"
                        }
            case "tenancyPeriod":
                return  {    
                            Title:  "When does the tenancy begin and end?", 
                            Subtitle: "Input the start and end dates to define the tenancy duration"
                        }
            case "moveInandOutPeriod":
                return  {    
                            Title:  "What are the move-in and move-out dates?", 
                            Subtitle: "Specify the dates your tenant will move in and when they'll move out"
                        }
                
            case "rentDetails":
                return  {    
                            Title: "What's the rent amount?", 
                            Subtitle: "Specify the rent amount and it's frequency (e.g., monthly, weekly). You can also optionally set a date and frequency for rent reviews"
                        }
            case "managedByAgent":
                return  {    
                            Title:  "Is an agent managing the property?", 
                            Subtitle: "Please indicate if you're using an agent to manage this property. If yes, provide the agent's details"
                        }
            case "agentDetails":
                return  {    
                            Title:  "Great! Let's get the agent's details.", 
                            Subtitle: "Choose an existing agent from your list or add a new one. Next, you will specify the agent costs and payment frequency"
                        }
            case "newAgent":
                return  {    
                            Title:  "Add new agent", 
                            Subtitle: "What is your agent's name?"
                        }
            case "tenantDetails":
                return  {    
                            Title: "Who's the primary tenant?", 
                            Subtitle: "Please provide the lead tenant's full name, contact details, and any other essential information"
                        }
            case "submit":
                return  {    
                            Title: "Tenancy overview", 
                            Subtitle: "Below is a summary of all the details you've provided. Review the information to ensure its accuracy, and make any necessary adjustments before finalising"
                        }
            default:
                return {    
                            Title: "Starting a new tenancy? Let's begin!",
                        }
        }
    }

    const SkipNewCreateStage = (stageName: string)=>{
        if(stageFlow && currentStage){
            const skipStage = stageName as STAGE_NAMES
            if(stageFlow.includes(skipStage)){
                stageFlow.splice(stageFlow.indexOf(skipStage),1)
            }
                
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])
        }
    }

    const AddNewCreateStage = (stageName: string)=>{
        if(stageFlow && currentStage){
            const addStage = stageName as STAGE_NAMES
            if(!stageFlow.includes(addStage)){
                stageFlow.splice(stageFlow.indexOf(currentStage)+1,0,addStage)
            }   
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])
        }
    }

    const nextStage = () => {
        if(stageFlow && currentStage) {
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])  
        }
    }
 
    const createNewProperty = (): Promise<string|undefined> => {
        return new Promise((resolve, reject) => {
            if (propertiesAPI && stageFlow && newPropertyName) {
                propertiesAPI.createMinimalProperty(companyContext.cid, newPropertyName)
                    .then((createPropRes) => {
                        //sets property to get the latest data, which will have the added property details
                        setPropertiesLoaded(false)
                        setNewPropertyName(undefined)
                        resolve(createPropRes.data.toString());
                    })
                    .catch((error) => {
                        console.error(error);
                        reject(error);
                    });
            } else {
                reject();
            }
        });
    };
    
    const handleNewPropertyButtonDisable=()=>{
        setPropertiesDisable(true)
    }

    const handleNewAgentButtonDisable=()=>{
        setAgentDisable(true)
    }

    const handleAgentDetails = (isManaged: boolean, values: TenancyPostModel) => {
        if(isManaged){
            formikRef.current?.setFieldValue("tenancyIsManaged", true);
            formikRef.current?.setTouched(Object.keys(values).reduce((acc, t) => ({ ...acc, [t]: false }), {}));   
            AddNewCreateStage("agentDetails");
        }
        else{
            formikRef.current?.setFieldValue("tenancyIsManaged", false);
            formikRef.current?.setFieldValue("lettingAgentId", undefined);
            formikRef.current?.setFieldValue("lettingAgentName", '');
            formikRef.current?.setFieldValue("agentFees", 0);
            formikRef.current?.setFieldValue("agentFeesInterval", 'M');
            formikRef.current?.setTouched(Object.keys(values).reduce((acc, t) => ({ ...acc, [t]: false }), {}));
            SkipNewCreateStage("agentDetails");
            SkipNewCreateStage("newAgent");
        }          
    };

    const handleAgentSelection=(id: number, name: string)=>{
        formikRef.current?.setFieldValue('lettingAgentId', id)
        formikRef.current?.setFieldValue("lettingAgentName", name)
        formikRef.current?.setFieldTouched("lettingAgentId", false)
    }

    const handleButtonClickNewAgent =() =>{
        setAgentDisable(false)
        AddNewCreateStage("newAgent")
    }

    const handleAddNewAgent = () => {
        handleNewAgentButtonDisable();
        if(stageFlow){
            addNewAgentOption(
                { name: newAgentName },
                ({ id }) => {
                    formikRef.current?.setFieldValue('lettingAgentId', id);
                    formikRef.current?.setFieldValue("lettingAgentName", newAgentName);
                    setNewAgentName(undefined);
                    formikRef.current?.setFieldTouched("lettingAgentId", false);
                    stageFlow?.splice(stageFlow.indexOf("newAgent"), 1);
                    setCurrentStage(stageFlow[stageFlow.indexOf("agentDetails")]);
                }
            );
        }        
    };

    const handleNewPropCreate=() =>{
        handleNewPropertyButtonDisable();
        if(stageFlow){
            createNewProperty().then((res) =>{
                if(propertiesAPI && res){
                    propertiesAPI.get(companyContext.cid, parseInt(res))
                    .then((res) => {
                        if(res.data && res.data.lettableUnits){
                            formikRef.current?.setFieldValue("propertyLettableUnitId", res.data.lettableUnits[0].id);
                            stageFlow?.splice(stageFlow.indexOf("newProp"),1)
                            setCurrentStage(stageFlow[stageFlow.indexOf("property")+1])
                        }
                        else{
                            setCurrentStage("property");
                        }
                    })
                    .catch((err) =>{
                        console.error(err);
                        setCurrentStage("property")
                    })
                }
            })
            .catch((err) =>{
                console.error(err);
                setCurrentStage("property")
            })
        }
    }

    const checkErrorInTenantDetailsStage = (errorFields: string[]| undefined) =>{
        if(errorFields){
            const currentStageFields: string[] = ["tenantFirstName", "tenantLastName", "tenantEmail", "tenantPhoneMobile", "tenantPhoneHome", "tenantPhoneWork"]

            for (const errorField of errorFields) {
                if (currentStageFields.includes(errorField)) {
                    return true;
                }
            }
            return false;
    
        }
        else{
            return false;
        }
    }

    const validatedTenancyPeriod=() =>{
        if(formikRef.current){
            formValidate(formikRef.current.values).then((res)=>{
                if(res.endDate){
                   formikRef.current?.setFieldTouched("endDate", true)
                }
                else{
                    formikRef.current?.setFieldError("endDate",undefined)
                    nextStage()
                }
            })
        }
        
    }

    const validatedTenanantMoveOut=() =>{
        if(formikRef.current){
            formValidate(formikRef.current.values).then((res)=>{
                if(res.moveOutDate){
                    formikRef.current?.setFieldTouched("moveOutDate", true)
                }
                else{
                    formikRef.current?.setFieldError("moveOutDate",undefined)
                    nextStage()
                }
            })
        }        
    }
    
    const validatedAgentDetailsStage=() =>{
        if(formikRef.current){
            formValidate(formikRef.current.values).then((res)=>{
                if(res.lettingAgentId){
                    formikRef.current?.setFieldTouched("lettingAgentId",true)
                }
                else if(!res.agentFees && !res.lettingAgentId){
                    SkipNewCreateStage("newAgent")
                }
            })
        }
    }

    const validatedTenantDetailsStage=() =>{
        if(formikRef.current){
            formValidate(formikRef.current.values).then((res)=>{
                if(checkErrorInTenantDetailsStage(Object.keys(res))){
                    formikRef.current?.setTouched(Object.keys(res).reduce((acc, t) => ({ ...acc, [t]: true }), {})); 
                }
                else
                    nextStage();
            })   
        }
    }

    const handleKeyDown =(event: React.KeyboardEvent<HTMLElement>) =>{
        if (event.key === '.' || event.key === ',' || event.key === '-' ) {
            event.preventDefault();
        }        
    }

    return(
        <FullScreen
            isOpen={isOpen}
            onClose={hide}
            contentTitle={getStageDetails().Title}
            contentSubtitle={getStageDetails().Subtitle}
            onBack={stageFlow && currentStage && stageFlow.indexOf(currentStage) > 0 ?
                () => setCurrentStage(stageFlow[stageFlow.indexOf(currentStage) - 1]) :
                undefined}
            brandIcon
            headerTitle={""}        
        >
            <BrandWrapper>
                {stageFlow && <Formik
                    initialValues={intialValues}
                    enableReinitialize
                    onSubmit={formSubmit}
                    validate={formValidate}
                    validateOnChange={false}
                    innerRef={formikRef}
                >
                    {({ isSubmitting, values, setFieldValue, setFieldError, setFieldTouched, setTouched, errors}) => (
                        <Form>
                            {currentStage === "intro" &&
                                <>
                                    <p> Kick off your landlord journey by adding your first tenancy details here.</p>
                                    <p>Input tenant information, rental terms, and more. Provestor streamlines the process, making it easy to stay on top of your property investments.</p>
                                    <Button thin variant='primary' onClick={nextStage} marginTop>Let's go <Icon className={styles.nextArrow} name='arrowNext'/></Button>
                                </>
                            }

                            {currentStage === "property" &&
                                <>
                                    <OptionSelector preventSaveState onChange={(opt) => {
                                        if(opt[0].value === "newProp"){
                                            setPropertiesDisable(false)
                                            AddNewCreateStage("newProp");
                                        }
                                        else
                                        {
                                            setFieldValue("propertyLettableUnitId", parseInt(opt[0].value))
                                            SkipNewCreateStage("newProp")
                                        }                                        
                                    }}
                                    options={
                                        [
                                            {singleEntry: true, list: [
                                                ...lettableUnits.map(c => {return {label: c.fullName, value: c.id.toString() || ""}})
                                            ]},
                                            {singleEntry: true, list:
                                                [
                                                    {label: 'New property', value: "newProp"},
                                                ]
                                            }
                                        ]
                                    }
                                    />
                                </>
                            }

                            {currentStage === "newProp" &&
                                <>
                                    <div>
                                        <input autoFocus className={classNames("form-control", styles.newPropInput)} type="text" value={newPropertyName} onChange={e => setNewPropertyName(e.target.value)}/>
                                    </div>
                                    <Button 
                                        disabled={!newPropertyName || propertiesDisable}
                                        variant='primary' 
                                        thin  
                                        onClick={ handleNewPropCreate}
                                        marginTop
                                        >
                                            Continue <Icon name='arrowNext'/>
                                    </Button>
                                </>
                            }

                            {currentStage === "tenancyPeriod" && <>
                                <div className={styles.tenancyperiod}>
                                   
                                    <FormikField<TenancyPostModel>
                                        className={styles.formikFieldsDate}
                                        label="Tenancy start"
                                        name="startDate"
                                        type="date"
                                    />

                                    <div>
                                        <FormikField<TenancyPostModel>
                                            className={classNames(styles.formikFieldsDate, "mb-0")}
                                            label="Tenancy end"
                                            name="endDate"
                                            type="date"
                                            onChange={() => setFieldTouched("endDate", false)}
                                        /> 
                                        <small className='text-muted'>Can be left blank for ongoing / periodic contracts</small>
                                    </div>                             
                                </div>

                                <Button
                                    marginTop
                                    disabled={!values.startDate}
                                    variant='primary' 
                                    thin  
                                    onClick={validatedTenancyPeriod}
                                >
                                    Continue <Icon name='arrowNext'/>
                                </Button>
                            </>}
                            {currentStage === "moveInandOutPeriod" && <>

                                <div className={styles.tenancyperiod}>
                                    <FormikField<TenancyPostModel>
                                        className={styles.formikFieldsDate}
                                        label="Move in"
                                        name="moveInDate"
                                        type="date"
                                    />

                                    <FormikField<TenancyPostModel>
                                        className={styles.formikFieldsDate}
                                        label="Move out"
                                        name="moveOutDate"
                                        type="date"
                                    />
                                </div>
                                <Button
                                    marginTop
                                    variant='primary' 
                                    thin  
                                    onClick={validatedTenanantMoveOut}
                                >
                                    Continue <Icon  name='arrowNext'/>
                                </Button>

                                <span onClick={nextStage} className={styles.linkText}>Skip this section</span>
                            </>}

                            {currentStage === "rentDetails" && <>
                            
                                    <EditCurrency<TenancyPostModel> 
                                        name='rentAmount' 
                                        intervalLabel='per' 
                                        intervals={['W','M','Q','A']} 
                                        intervalFieldName='rentInterval' 
                                        min={0} 
                                        labelTop 
                                        labelTextMuted 
                                        inputSingleRow
                                        onlyPositiveNumbers
                                    />
                                    
                                    <div className={styles.rentReview}>
                                        <div className="mt-3 mb-3">
                                            <label  className="text-muted">Rent review every</label>
                                            <div style={{width: '200px'}} >
                                                <Field name="rentReviewInterval">
                                                    {({field,meta}: FieldProps) => (
                                                        <>
                                                            <InputGroup>  
                                                                <ReactForm.Control 
                                                                    type={'number'} 
                                                                    {...field}
                                                                    value= {field.value}
                                                                    min={0}
                                                                    isInvalid={meta.touched && meta.error !== undefined}
                                                                    className="text-right"
                                                                    onKeyDown={handleKeyDown}
                                                                />
                                                                <InputGroup.Append>
                                                                    <InputGroup.Text>Months</InputGroup.Text>
                                                                </InputGroup.Append>
                                                            </InputGroup>
                                                            <ReactForm.Text className='text-muted'>How often rent should be reviewed, can be left blank</ReactForm.Text>
                                                            {meta.touched && meta.error && <ReactForm.Control.Feedback className={styles.errorFeedback} type="invalid">{meta.error}</ReactForm.Control.Feedback>}
                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>

                                        <div className="mt-3 mb-3">
                                            <label className="text-muted">Next rent review</label>
                                            <div style={{width: '200px'}}>
                                                <Field name="rentReviewDate">
                                                    {({field}: FieldProps) => (
                                                        <>
                                                            <ReactForm.Control 
                                                                type={'date'} 
                                                                {...field}
                                                            />
                                                            <ReactForm.Text className='text-muted'>(Optional)</ReactForm.Text>
                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>

                                    </div>
                                    
                                        

                                <Button
                                    marginTop 
                                    variant='primary' 
                                    thin  
                                    onClick={() =>{
                                        if(!errors.rentAmount && !errors.rentReviewInterval)
                                            nextStage()
                                    }}
                                >
                                    Continue <Icon  name='arrowNext'/>
                                </Button>
                            </>}
                            
                            {currentStage === "managedByAgent" && <>
                                <OptionSelector preventSaveState onChange={(opt) => {
                                        handleAgentDetails(opt[0].value === "managed", values)                                         
                                    }}
                                    options={{singleEntry: true, list: [
                                        {label: `Yes - it will be managed by agent`, value: "managed"}, 
                                        {label: `No`, value: "notManaged"}
                                    ]}}
                                />
                            </>}

                            {currentStage === "agentDetails" && 
                                <>
                                    <div className={styles.agentDetails}>
                                        <label  className="text-muted">Select agent</label>
                                        <div className={styles.rowAgentDetails}>
                                            <Field name="lettingAgentId">
                                                    {({field,meta}: FieldProps) => (
                                                        <div className={styles.selectList}>
                                                            <Select 
                                                                classNamePrefix='selectList'
                                                                options={agentsList}
                                                                {...field}
                                                                onChange={(e) => handleAgentSelection(parseInt(e.value.toString()), e.label)}
                                                                value={(agentsList ? agentsList.find(agentsList => agentsList.value === values.lettingAgentId?.toString()) : '') as any}
                                                                isInvalid={meta.touched && meta.error !== undefined}                                                            
                                                                menuPlacement="auto"
                                                                onBlur={()=>setFieldTouched("lettingAgentId", true)}
                                                            />
                                                            {meta.touched && meta.error && <ReactForm.Control.Feedback className={styles.errorFeedback} type="invalid">{meta.error}</ReactForm.Control.Feedback>}
                                                        </div>                                                        
                                                    )}
                                                </Field>
                                                <Button
                                                    variant='secondary'   
                                                    onClick={handleButtonClickNewAgent}
                                                >
                                                    Add new
                                                </Button>
                                        </div>
                                    </div>
                                

                                    <EditCurrency<TenancyPostModel> 
                                        name='agentFees'  
                                        intervalLabel='per' 
                                        intervals={['W','M','Q','A']} 
                                        intervalFieldName='agentFeesInterval' 
                                        min={0} 
                                        labelTop 
                                        labelTextMuted 
                                        inputSingleRow
                                        onlyPositiveNumbers
                                    />

                                    <Button
                                        marginTop
                                        variant='primary' 
                                        thin  
                                        onClick={validatedAgentDetailsStage}
                                    >
                                        Continue <Icon name='arrowNext'/>
                                    </Button>
                                </>
                            }

                            {currentStage === "newAgent" &&
                                <>
                                    <div>
                                        <input autoFocus className={classNames("form-control", styles.newPropInput)} type="text" value={newAgentName} onChange={e => setNewAgentName(e.target.value)}/>
                                    </div>
                                    <Button
                                        marginTop
                                        disabled={!newAgentName || agentDisable}
                                        variant='primary' 
                                        thin  
                                        onClick={handleAddNewAgent}
                                    >
                                        Continue <Icon name='arrowNext'/>
                                    </Button>
                                </>
                            }

                            {currentStage === "tenantDetails" && <>
                                <FormikField<TenancyPostModel>
                                    className={styles.formikFieldsAuto}
                                    label="Title"
                                    name="tenantTitle"
                                    type="select"
                                    option={[{label: "Mr", value: "Mr" }, {label: "Mrs", value: "Mrs" }, {label: "Ms", value: "Ms" }]}
                                    width="20%"
                                />
                                <FormikField<TenancyPostModel>
                                    className={styles.formikField}
                                    label="First name"
                                    name="tenantFirstName"
                                    type="text"
                                    placeholder={!values.tenancyIsManaged? "Required" : undefined}
                                />
                                <FormikField<TenancyPostModel>
                                    className={styles.formikField}
                                    label="Last name"
                                    name="tenantLastName"
                                    type="text"
                                    placeholder={!values.tenancyIsManaged? "Required" : undefined}                                    
                                />
                                <FormikField<TenancyPostModel>
                                    className={styles.formikField}
                                    label="Email address"
                                    name="tenantEmail"
                                    type="email"
                                    placeholder={!values.tenancyIsManaged? "Required" : undefined}
                                />

                                <FormikField<TenancyPostModel>
                                    className={styles.formikField}
                                    label="Phone-Mobile"
                                    name="tenantPhoneMobile"
                                    type="text"
                                />
                                <FormikField<TenancyPostModel>
                                    className={styles.formikField}
                                    label="Phone-Home"
                                    name="tenantPhoneHome"
                                    type="text"
                                />
                                <FormikField<TenancyPostModel>
                                    className={styles.formikField}
                                    label="Phone-Work"
                                    name="tenantPhoneWork"
                                    type="text"
                                />

                                <Button
                                    marginTop
                                    variant='primary' 
                                    thin  
                                    onClick={validatedTenantDetailsStage}
                                >
                                    Continue <Icon name='arrowNext'/>
                                </Button>
                                {values.tenancyIsManaged &&
                                    <span onClick={nextStage} className={styles.linkText}>Skip this section</span>
                                }
                                
                            </>}
                            {currentStage === "submit" && <>
                            <Summary>
                                <h2 className={styles.subheading}>Rental information</h2>
                                { lettableUnits.find(u => u.id === values.propertyLettableUnitId) &&
                                    <Text fixedWidth ensureRender entity={lettableUnits.find(u => u.id === values.propertyLettableUnitId)!} name={'fullName'} v8Summary />
                                }
                                
                                <Date fixedWidth ensureRender entity={values} name={'startDate'} v8Summary />
                                <Date fixedWidth ensureRender entity={values} name={'endDate'} v8Summary />
                                <Date fixedWidth ensureRender entity={values} name={'moveInDate'} v8Summary />
                                <Date fixedWidth ensureRender entity={values} name={'moveOutDate'} v8Summary />
                                <Currency fixedWidth ensureRender entity={values}name='rentAmount' intervalLabel='per' intervalFieldName='rentInterval' v8Summary  />
                                <Text fixedWidth ensureRender entity={values} name={'rentReviewInterval'} suffix=' months' v8Summary />
                                <Date fixedWidth ensureRender entity={values} name={'rentReviewDate'} v8Summary />
                                {/* <Text fixedWidth ensureRender entity={values} name={'notes'} v8Summary /> */}
                                <YesNo fixedWidth ensureRender entity={values} name='tenancyIsManaged' label="Agency managed" v8Summary  />
                                {values.tenancyIsManaged &&
                                    <>
                                        <Text fixedWidth ensureRender entity={values} name={'lettingAgentName'} label='Agent name' v8Summary />
                                        <Currency fixedWidth ensureRender entity={values} name='agentFees' intervalLabel='per' intervalFieldName='agentFeesInterval' v8Summary  />
                                    </>
                                }
                                <h2 className={classNames(styles.subheading, styles.seperation)}>Lead tenant</h2>
                                <Text fixedWidth ensureRender entity={values} name={'tenantTitle'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'tenantFirstName'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'tenantLastName'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'tenantEmail'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'tenantPhoneMobile'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'tenantPhoneHome'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'tenantPhoneWork'} v8Summary />
                            </Summary>

                            {Object.keys(errors).length > 0 &&
                                <ReactForm.Control.Feedback className={classNames(styles.errorFeedback, 'mb-2')} type="invalid">Please clear the errors in previous stages before submitting</ReactForm.Control.Feedback>
                            }

                            <Submit submitDisabled={Object.keys(errors).length > 0} alignLeft disabled={isSubmitting} onCancelClick={onCancel}  />
                        </>}
                        </Form>       
                    )}
                </Formik>}
            </BrandWrapper>
        </FullScreen>
    )
}