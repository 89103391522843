
import { LoadingPlaceholder } from "../../../elements/LoadingPlaceholder/LoadingPlaceholder";
import styles from '../CorporationTax.module.scss'

const LoadingCT = () => {
    return (
        <>
            <h1 className={styles.reportTitle} >
                <LoadingPlaceholder dark width={'20%'}/>
            </h1>
            
                <h3 style={{display: 'flex', justifyContent: 'space-between'}} className={styles["level-0"]} >
                    <LoadingPlaceholder dark width={'20%'}/>
                    <LoadingPlaceholder dark width={'20%'}/>
                </h3>
                <h2 style={{display: 'flex', justifyContent: 'space-between'}} className={styles["level-1"] }>
                    <LoadingPlaceholder width={'20%'} />
                    <LoadingPlaceholder width={'20%'} />
                </h2>
                <h2 style={{display: 'flex', justifyContent: 'space-between'}} className={styles["level-1"]}>
                    <LoadingPlaceholder width={'20%'} />
                    <LoadingPlaceholder width={'20%'} />
                </h2>
                <h2 style={{display: 'flex', justifyContent: 'space-between'}} className={styles["level-1"] }>
                    <LoadingPlaceholder width={'20%'} />
                    <LoadingPlaceholder width={'20%'} />
                </h2>
                <h2 style={{display: 'flex', justifyContent: 'space-between'}} className={styles["level-1"] }>
                    <LoadingPlaceholder width={'20%'} />
                    <LoadingPlaceholder width={'20%'} />
                </h2>
        </>
    )
    
}

export default LoadingCT;