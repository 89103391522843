import { useCallback, useContext, useEffect, useState } from 'react'
import { ContactOnboardings } from '../../../../api/inni/ContactOnboardings';
import { ContactOnboarding } from '../../../../api/inni/data-contracts';
import BrandContext from '../../../../context/BrandContext'
import { useInniAPI } from '../../../../hooks/useInniAPI';
import { Brand } from '../../../../utils/configHelper';
import styles from '../../NoCompanies.module.css'
import {Step, Stepper} from '../../../../elements/Stepper/Stepper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt, faEnvelope } from '@fortawesome/pro-regular-svg-icons';

// SVG Icons
import { ReactComponent as BankIcon } from './images/bank.svg';
import { ReactComponent as Introduction } from './images/introduction.svg';
import { ReactComponent as Incorporation } from './images/incorporation.svg';
import { ReactComponent as Information } from './images/information.svg';
import { ReactComponent as Account } from './images/account_setup.svg';
import { ReactComponent as Training } from './images/training.svg';
import { ReactComponent as Complete } from './images/complete.svg';
import ContactDetails from '../ContactDetails';
import provestorBrand from '../../../../components/BrandWrapper/pvBrand';
import useIsMobile from '../../../../hooks/useIsMobile';

// Text for a given step (last one repeated so we show something when completed is completed), rather than nothing
const pvTextForSteps = [
    'Your account manager will be introducing themselves soon.',
    'We’re currently setting up your limited company.',
    'If you haven\'t already, now\'s the time to get a business bank account set up and connected in your software.',
    'Please send across the information requested by your account manager',
    'We’ve received your information and are setting up your properties in our software.',
    'Your account manager will schedule a one-to-one software training session to show you how to use our software.',
    'Congratulations! Your onboarding is complete and your software is set up and ready to go.',
]

const iaTextForSteps = [
    'Your account manager will be introducing themselves soon.',
    'We’re currently setting up your limited company.',
    'If you haven\'t already, now\'s the time to get a business bank account set up and connected in your software.',
    'Please send across the information requested by your account manager',
    'We’ve received your information and are setting up your software.',
    'Your account manager will schedule a one-to-one software training session to show you how to use our software.',
    'Congratulations! Your onboarding is complete and your software is set up and ready to go.',
]


const OnboardingProcess = ({data, isLoaded} : {data : ContactOnboarding[], isLoaded : boolean}) => {
    
    const brandContext = useContext(BrandContext);

    const [curStep, setCurStep] = useState(1)
    const isMobile = useIsMobile()

    const getCurStep = useCallback(() => {
        if (!data || !data[0])
            return;
            
        let curData : any = data[0]; // bit of a hack (: any), but allows us to index by string...

        let curStep = 7; // 0 - intro, 1 incorp, ...

        // we check each process, if it has a completed date then is done
        // I guess we still want to show that we have started the next task even if we haven't...
        for (let i = 0; i < 8; i++) {
            if (!(curData['step' + (i+1) + 'StartedAt'] !== null && curData['step' + (i+1) + 'CompletedAt'] !== null)) {
                curStep = i;
                break;
            }
        }

        setCurStep(curStep)
    }, [data])

    useEffect(() => {
        getCurStep()
    }, [data, getCurStep])

    const stepColor = brandContext.brand === provestorBrand ? 'var(--account-color-5)' : 'var(--topbar-background-color)'

    return (<>
        {isLoaded && 
        <div className={`${styles.onboardingProcess} shadow`}>
            <img style={{height: '50px'}} src={brandContext.brand.logoDarkUrl} alt={"logo"} />
            <div>
                <h5>Welcome and thank you for joining {brandContext.brand.name}.</h5>
                <h5 style={{textAlign:'left'}}>We're working hard to get you set up and ready to use your {brandContext.brand === provestorBrand ? 'property' : ''} accounting software. You can keep track of your onboarding progress on this page.</h5>
            </div>
            <div className={styles.activeStepText}>
                <h5 style={{textAlign: 'center'}}><b>{brandContext.brand === provestorBrand ? pvTextForSteps[curStep] : iaTextForSteps[curStep]}</b></h5>
            </div>
            <div style={{width: '100%', marginTop: '5%'}}>
                <Stepper activeColor="var(--account-color-5)" inactiveColor="var(--secondary)" vertical={isMobile}>
                    <Step label="Introduction"  complete={curStep > 0} active={curStep === 0}>
                        <Introduction width="20px" height="20px" fill={curStep === 0 ? stepColor : curStep > 0 ? '#ffffff' : 'var(--secondary)'} />
                    </Step>
                    <Step label="Incorporation" complete={curStep > 1} active={curStep === 1}>
                        <Incorporation width="20px" height="20px" fill={curStep === 1 ? stepColor : curStep > 1 ? '#ffffff' : 'var(--secondary)'} />
                    </Step>
                    <Step label="Bank account setup" complete={curStep > 2} active={curStep === 2}>
                        <BankIcon width="20px" height="20px" fill={curStep === 2 ? stepColor : curStep > 2 ? '#ffffff' : 'var(--secondary)'} />
                    </Step>
                    <Step label="Account setup" complete={curStep > 4} active={curStep === 3 || curStep === 4}>
                        <Account width="20px" height="20px" fill={curStep === 4 ? stepColor : curStep > 4 ? '#ffffff' : 'var(--secondary)'}  />
                    </Step>
                    <Step label="Software Training" complete={curStep > 5} active={curStep === 5}>
                        <Training width="20px" height="20px" stroke={curStep === 5 ? stepColor : curStep > 5 ? '#ffffff' : 'var(--secondary)'}  />
                    </Step>
                </Stepper>
            </div>
            <ContactDetails />
        </div>}
    </>)

}

export default OnboardingProcess