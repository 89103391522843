import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { Asset, PropertyCreateModel } from '../../../api/inni/data-contracts';
import { Submit, ListOption }  from '../../../elements/EditRow/EditRow'
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Assets } from '../../../api/inni/Assets';
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { Action, Entity } from '../../../utils/EntityAction';
import SimpleFullscreen from '../../../components/Fullscreen/SimpleFullscreen';
import BrandContext from '../../../context/BrandContext';
import { Button } from '../../../elements/Button/Button';
import OptionSelector from '../../../components/OptionSelector/OptionSelector';
import { Text } from '../../../elements/DetailRow/DetailRow';
import Icon from '../../../elements/Icon/Icon';
import styles from './PropertyCreateFormV8.module.scss'
import { FileRejection } from 'react-dropzone';
import Attachments from '../../../components/Attachments/Attachments';
import { PageAlert } from '../../../components/Alerts/PageAlert';
import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import { Summary } from '../../../elements/Summary/Summary';
import FormikField from '../../../elements/FormikField/FormikField';

export interface PropertyCreateFormV8Props {
    initialValues: PropertyCreateModel,
    formSubmit: (values: PropertyCreateModel, actions: FormikHelpers<PropertyCreateModel>) => Promise<void>,
    formValidate: (values: PropertyCreateModel) => Promise<FormikErrors<PropertyCreateModel>>,
    onCancelClick?: () => void,
    setFilesToUpload: (files:File[] | null | undefined) => void,
    filesToUpload: File[] | null | undefined,
    onCancel: () => void
}

export const PropertyCreateFormV8 = ({
        initialValues,
        formSubmit,
        formValidate,
        onCancelClick,
        setFilesToUpload,
        filesToUpload,
        onCancel
    } : PropertyCreateFormV8Props) => {

    type STAGE_NAMES = "intro"|"name"|"nickname"|"country"|"address"|"asset"|"tenure"|"tenureLifecycle"|"receipt"|"submit"
    const adminStages: STAGE_NAMES[] =  ["asset", "tenure", "tenureLifecycle"];

    const [assets, assetsLoaded] = useFetchEntityList<Asset, Assets>(Assets)
    const [assetsList, setAssetsList] = useState<ListOption[]>([]);
    const [stageFlow, setStageFlow] = useState<STAGE_NAMES[]>()
    const [currentStage, setCurrentStage] = useState<STAGE_NAMES>();
    const [fileInvalidMessage, setFileInvalidMessage] = useState<string | undefined>(undefined);
    const [showFileInvalidAlert, setShowFileInvalidAlert] = useState(false)

    const brandContext= useContext(BrandContext);
    const hasPermission = useHasPermission()

    // Load assets
    useEffect(() => {
        if (assetsLoaded && assets) {
            let out:Array<ListOption> = [];
            // out.push({value: '', label: 'None'})
            assets.forEach((asset : Asset) => {
                out.push({
                    value: String(asset.id),
                    label: asset.description || String(asset.id)
                })
            })
            setAssetsList(out)
        }
    }, [assets, assetsLoaded])

    useEffect(() => {
        if(fileInvalidMessage) {
            setShowFileInvalidAlert(true)
        }
    }, [fileInvalidMessage])

    useEffect(() => {
        if(stageFlow === undefined && assetsLoaded) {
            const hasPropAsset = hasPermission(Entity.PropertyAsset, Action.All)[0]
            const hasPropTenure = hasPermission(Entity.PropertyTenure, Action.All)[0]

            let stages:STAGE_NAMES[] = ["intro", "name", "nickname", "country", "address"];

            if(hasPropAsset && assets.length > 0) stages.push("asset");
            if(hasPropTenure) {
                stages.push("tenure");
                stages.push("tenureLifecycle");
            }
    
            stages.push("receipt");
            stages.push("submit");

            setStageFlow(stages)
            setCurrentStage("intro")
        }
    }, [stageFlow, hasPermission, assetsLoaded, assets.length])

    //These values come from swagger, can't convert a type to values at runtime. TODO is to get swagger to generate proper enums
    const tenureDropDown:Array<ListOption> = [
        {value: "RentToRent", label: "Rent-to-rent"},
        {value: "Owned", label: "Owned"},
        {value: "Freehold", label: "Freehold"},
        {value: "Leasehold", label: "Leasehold"}
    ]

    const tenureLifecycleStatusDropDown:Array<ListOption> = [
        {value: "UnderOffer", label: "Under offer"},
        {value: "Active", label: "Active"},
        {value: "Sold", label: "Sold"}
    ]

    const getHeaderTitle = () => {
        switch(currentStage) {
            case "intro":
                return "Add a property, real or imagined!"
            case "name":
                return "Property name"
            case "nickname":
                return "Enter a nickname"
            case "country":
            case "address":
                return "Property address"
            case "asset":
                return "If applicable, select a related asset"
            case "tenure":
                return "If applicable, select a tenure"
            case "tenureLifecycle":
                return "If applicable, select a tenure status"
            case "receipt":
                return "Upload a photo?"
            case "submit":
                return "We're ready to create your property"
            default:
                return "Add a property, real or imagined!"
        }
    }

    const getSubtitle = () => {
        switch(currentStage) {
            case "name":
                return "Enter a formal name for your property, this could for example be the first line of the address"
            case "nickname":
                return "Optionally, give your property a shorter nickname, we'll refer to it as this throughout the app instead"
            case "country":
                return "Where your property is located will have different tax implications, like Stamp Duty"
            default:
                return undefined
        }
    }

    const onFilesDropped = (acceptedFiles:File[], fileRejections:FileRejection[]) => {
        if(acceptedFiles.length > 0) {
            setFilesToUpload(acceptedFiles);
        }
        if(fileRejections.length > 0){
            if(fileRejections.filter(x => x.errors.filter(y => y.code === "too-many-files").length > 0).length > 0) {
                setFileInvalidMessage(`Please select one file`);
            }
            else if(fileRejections.length === 1) {
                setFileInvalidMessage(`This file type is not supported and has been ignored`);
            } 
            else {
                setFileInvalidMessage(`${fileRejections.length} file types are not supported, these have been ignored`);
            }
        }
    };

    const removePendingFile = (fileName:string) => {
        let newRecords = filesToUpload ? [...filesToUpload] : null
        newRecords && newRecords.splice(newRecords.findIndex(x => x.name === fileName), 1)
        setFilesToUpload(newRecords)
    }

    const nextStage = () => {
        if(stageFlow && currentStage) {
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])
        }
    }

    return (
        <SimpleFullscreen 
            onBack={
                stageFlow && currentStage && stageFlow.indexOf(currentStage) > 0 ? 
                () => setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)-1]) : 
                undefined
            } 
            isOpen={true} 
            title={getHeaderTitle()} 
            adminOnly={currentStage ? adminStages.includes(currentStage) : false}
            onClose={onCancel} 
            subTitle={getSubtitle()}
        >
            {stageFlow && <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={formSubmit}
                validateOnChange={false}       
                validate={formValidate}
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form>
                        {currentStage === "intro" && <>
                            <p>Got a property in mind? Let's add it!</p>
                            <p>By inputting details like the property's name and address, you'll have a clearer picture of your investments</p>
                            <Button thin variant='primary' onClick={nextStage} marginTop>Let's go <Icon className={styles.nextArrow} name='arrowNext'/></Button>
                        </>}
                        {currentStage === "name" && <>
                            <FormikField<PropertyCreateModel>
                                className={styles.formikField}
                                label="Name"
                                name="name"
                                type="text"
                            />
                            <Button disabled={!values.name} thin variant='primary' onClick={nextStage} marginTop>Continue <Icon className={styles.nextArrow} name='arrowNext'/></Button>
                        </>}
                        {currentStage === "nickname" && <>
                            <FormikField<PropertyCreateModel>
                                className={styles.formikField}
                                label="Nickname"
                                name="nickname"
                                type="text"
                            />
                            <Button thin variant='primary' onClick={nextStage} marginTop>Continue <Icon className={styles.nextArrow} name='arrowNext'/></Button>
                        </>}
                        {currentStage === "country" && <>
                            <OptionSelector
                                onChange={(opt) => {
                                    setFieldValue("country", opt[0].value)
                                    nextStage()
                                }}
                                preventSaveState
                                options={{singleEntry: true, list: [
                                    {label: `England`, value: "England"}, 
                                    {label: `Scotland`, value: "Scotland"},
                                    {label: `Wales`, value: "Wales"},
                                    {label: `Northern Ireland`, value: "Northern Ireland"},
                                ]}}
                            />
                            <Button disabled={!values.country} thin variant='primary' onClick={nextStage} marginTop>Continue <Icon className={styles.nextArrow} name='arrowNext'/></Button>
                        </>}
                        {currentStage === "address" && <>
                            <FormikField<PropertyCreateModel>
                                className={styles.formikField}
                                label="Name"
                                name="name"
                                type="text"
                                readonly
                            />
                            <FormikField<PropertyCreateModel>
                                className={styles.formikField}
                                label="Address line 1"
                                name="addressLine1"
                                type="text"
                                placeholder='Required'
                            />
                            <FormikField<PropertyCreateModel>
                                className={styles.formikField}
                                label="Address line 2"
                                name="addressLine2"
                                type="text"
                            />
                            <FormikField<PropertyCreateModel>
                                className={styles.formikField}
                                label="Post town"
                                name="postTown"
                                type="text"
                                placeholder='Required'
                            />
                            <FormikField<PropertyCreateModel>
                                className={styles.formikField}
                                label="County"
                                name="county"
                                type="text"
                            />
                            <FormikField<PropertyCreateModel>
                                className={styles.formikField}
                                label="Postcode"
                                name="postcode"
                                type="text"
                                placeholder='Required'
                            />
                            <FormikField<PropertyCreateModel>
                                className={styles.formikField}
                                label="Country"
                                name="country"
                                type="text"
                                readonly
                            />
                            <Button thin variant='primary' disabled={!values.addressLine1 || !values.postTown || !values.postcode} onClick={nextStage} marginTop>Continue <Icon className={styles.nextArrow} name='arrowNext'/></Button>
                        </>}
                        {currentStage === "asset" && <>
                            <OptionSelector
                                onChange={(opt) => {
                                    if(opt[0].value !== "SKIP") {
                                        setFieldValue("assetId", opt[0].value)
                                        nextStage()
                                    }
                                    else {
                                        nextStage()
                                    }
                                }}
                                preventSaveState
                                options={[
                                    {singleEntry: true, list: assetsList.map(lo => {return {label: lo.label, value: lo.value || ""}})},
                                    {singleEntry: true, list: [{label: 'Skip', value: "SKIP"},]}
                                ]}
                            />                        
                        </>}
                        {currentStage === "tenure" && <>
                            <OptionSelector
                                onChange={(opt) => {
                                    if(opt[0].value !== "SKIP") {
                                        setFieldValue("tenure", opt[0].value)
                                        nextStage()
                                    }
                                    else {
                                        nextStage()
                                    }
                                }}
                                preventSaveState
                                options={[
                                    {singleEntry: true, list: tenureDropDown.map(lo => {return {label: lo.label, value: lo.value || ""}})},
                                    {singleEntry: true, list: [{label: 'Skip', value: "SKIP"},]}
                                ]}
                            />                        
                        </>}
                        {currentStage === "tenureLifecycle" && <>
                            <OptionSelector
                                onChange={(opt) => {
                                    if(opt[0].value !== "SKIP") {
                                        setFieldValue("tenureLifecycleStatus", opt[0].value)
                                        nextStage()
                                    }
                                    else {
                                        nextStage()
                                    }
                                }}
                                preventSaveState
                                options={[
                                    {singleEntry: true, list: tenureLifecycleStatusDropDown.map(lo => {return {label: lo.label, value: lo.value || ""}})},
                                    {singleEntry: true, list: [{label: 'Skip', value: "SKIP"},]}
                                ]}
                            />                        
                        </>}
                        {currentStage === "receipt" && <>
                            <Attachments
                                optional
                                title='Photo'
                                onDrop={onFilesDropped}
                                message='Optionally upload a photo of your property to help identity it throughout the app'
                                attachedFiles={filesToUpload}
                                onRemoveFile={removePendingFile}
                                singleSelect={true}
                            />
                            <span style={{marginTop: '20px', display: 'block'}}>
                                <Button thin variant='primary' onClick={nextStage} marginTop>Continue <Icon className={styles.nextArrow} name='arrowNext'/></Button>
                            </span>
                        </>}
                        {currentStage === "submit" && <>
                            <p>Below is a summary of all the details you've provided. Review the information to ensure its accuracy, and make any necessary adjustments before finalising</p>
                            <Summary>
                                <Text fixedWidth ensureRender entity={values} name={'name'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'nickname'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'addressLine1'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'addressLine2'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'postTown'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'county'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'postcode'} v8Summary />
                                <Text fixedWidth ensureRender entity={values} name={'country'} v8Summary />
                                {
                                    hasPermission(Entity.PropertyAsset, Action.All)[0] && <Text fixedWidth entity={values} name={'assetId'} v8Summary  />
                                }
                                {
                                    hasPermission(Entity.PropertyTenure, Action.All)[0] && (
                                        <>
                                            <Text fixedWidth entity={values} name={'tenure'} v8Summary />
                                            <Text fixedWidth entity={values} name={'tenureLifecycleStatus'} v8Summary />
                                        </>
                                )}
                            </Summary>
                            <Submit alignLeft disabled={isSubmitting} onCancelClick={onCancelClick}  />
                        </>}


                    </Form>       
                )}
            </Formik>}
            <PageAlert ignoreSidebar={true} variant="danger" showAlert={showFileInvalidAlert} message={fileInvalidMessage}
                hideAlert={() => {setShowFileInvalidAlert(false); setTimeout(() => setFileInvalidMessage(undefined), 250)}}/>

        </SimpleFullscreen>
    )
}