import { useContext, useState } from "react";
import { Button } from "../../../../elements/Button/Button";
import styles from "../ConnectToBank.module.css"
import { useInniAPI } from "../../../../hooks/useInniAPI";
import { OpenBankingConsents } from "../../../../api/inni/OpenBankingConsents";
import { navigate } from "@reach/router";
import {BankFeedInterstitial, OBAuthorisationRequest} from "../../../../api/inni/data-contracts";
import BrandContext from "../../../../context/BrandContext";
import CompanyContext from "../../../../context/CompanyContext";

import DOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { Alert, Form as ReactForm } from "react-bootstrap"

interface BankProps{
    onClick: ()=>void;
    name:string;
    companyId:number;
    requestModel:OBAuthorisationRequest;
    overrideConnection:boolean;
    motd:string|undefined;
    bankFeedMessage:BankFeedInterstitial|undefined;
}


export default function SelectedBank({onClick, name, requestModel, companyId, overrideConnection, motd, bankFeedMessage}:BankProps){
    const consentAPI=useInniAPI(OpenBankingConsents);
    const brandContext= useContext(BrandContext)
    const companyContext = useContext(CompanyContext)
    const [isAgreed, setIsAgreed] = useState(false);
    const [isBankFeedMessageAccepted, setIsBankFeedMessageAccepted] = useState(false);
    const [isBankFeedMessageRead, setIsBankFeedMessageRead] = useState(false);

    const handleButtonClick=()=>{
        setIsAgreed(true); 
        if(consentAPI){
            const selectedBank:OBAuthorisationRequest=requestModel
            consentAPI.createFromAuthRequest(companyId,selectedBank).then((res)=>{
                if(res.data.redirectUrl)
                    navigate(res.data.redirectUrl);
            }).catch((error)=>{
                console.error(error)
                setIsAgreed(false)
            });
        }            
    }

    const handleBankFeedMessageAcceptClick = () => {
        setIsBankFeedMessageAccepted(true);
    }

    return(
        <>
            {(isBankFeedMessageAccepted || !bankFeedMessage) ? (
            <div data-cy="OpenBankingConsent_Message" className={styles.consentMessage}>
                <h3>Please read this consent before proceeding.</h3>
                {overrideConnection &&
                    <>
                        <p>By clicking Next, and connecting to {name}, you'll be authorising Inni Ltd to connect to your {name} account(s) and download your individual transactions and bank account balance(s).</p>
                        <p><i>As part of the authorisation, you'll see inniAccounts mentioned, for technical reasons within {name}, all inni group companies must access {name} using one connection and we're using our inniAccounts connection. There's no difference in the way the data is processed or held.</i></p>
                    </>
                }

                {!overrideConnection &&
                    <p>By clicking Next, and connecting to {name}, you'll be authorising Inni Ltd (on behalf of {brandContext.brand.name}) to connect to your {name} account(s) and download your individual transactions and bank account balance(s).</p>
                }

                <p>These transactions will be imported into this accounting app and you will be able to view the individual transactions in your bookkeeping area. This data, depending on your service, may be used by {brandContext.brand.name} to complete and file your statutory returns - such as VAT returns, corporation tax returns, year end accounts and self-assessment tax returns.</p>
                <p>This feature is provided to you by Inni Ltd. You can read the terms of use and complaints process <a rel="noreferrer" href={companyContext.company?.tenantId === 1 ?
                    "https://www.provestor.co.uk/terms-inni-fca" :
                    "https://www.inniaccounts.co.uk/knowledge-hub/article/ais-terms/"} target="_blank">here</a>.</p>

                <p>
                    <i>N.B. If you are using a mobile device please proceed through the next steps inside a desktop web browser, at the moment we do not support authentication through mobile banking apps.</i>
                </p>
                <p>
                    <i>Please note that some banks are very slow to respond and can take several seconds to redirect after clicking Confirm.</i>
                </p>

                { motd && motd !== "" && (
                    <Alert variant="info" className="mt-5">
                        <FontAwesomeIcon icon={faExclamationCircle} size="lg"/> {<span style={{marginLeft: '5px'}} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(motd) }}></span>}
                    </Alert>
                )}

                <div className={styles.Bankfooter}>
                    <Button label="Go back" variant="secondary"  onClick={onClick} disabled={isAgreed}/>
                    <Button label="I agree" variant="primary" onClick={handleButtonClick} disabled={isAgreed} />
                </div>
            </div>
            ) : (
                <div data-cy="OpenBankingConsent_BankFeedMessage" className={styles.consentMessage}>
                    {bankFeedMessage.lead && (<h3 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bankFeedMessage.lead!)}}/>)}
                    {bankFeedMessage.title && (<p dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(bankFeedMessage.title!)}}/>)}
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(bankFeedMessage.message!) }}/>
                    <div data-cy="OpenBanking_BankFeedMessage_CheckboxContainer" className="v8CheckBoxContainer" style={{width: '340px', marginTop: '40px'}} >
                                <>
                                    <ReactForm.Check
                                        id="confirmReadBankFeedMessage"
                                        className="d-inline-block"
                                        onChange={(e) => {
                                            setIsBankFeedMessageRead(e.target.checked);
                                        }}
                                    />
                                    <label className="m-0 p-0 ml-2" htmlFor="confirmReadBankFeedMessage">
                                        I confirm I have read the above information
                                    </label>
                                </>
                    </div>
                    <div className={styles.Bankfooter}>
                        <Button label="Go back" variant="secondary"  onClick={onClick}/>
                        <Button label="Accept" variant="primary" onClick={handleBankFeedMessageAcceptClick} disabled={!isBankFeedMessageRead}/>
                    </div>
                </div>
            )}
        </>
    );

}