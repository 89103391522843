import { Image } from 'react-bootstrap';
import styles from '../OnBoarding.module.css';
import tick from '../../../layouts/Components/icons/v8/tick.svg'


interface stageNumberProps{
    stageNumber:number
    active?:boolean
    completed?:boolean
}

const StageNumber=({stageNumber,active,completed}:stageNumberProps)=>{

    return(completed ?
        (
            <div className={`${styles.stagesNumber} ${styles.stagesNumberCompleted}`}>
                <Image alt={""} src={tick} width={13.512} height={9.4}/>
            </div>
        ):
        (
         
            <div className={active? `${styles.stagesNumber} ${styles.stagesnumberActive}`:styles.stagesNumber}>
                 <p className={active? `${styles.stagesNumberText} ${styles.stagesNumberTextActive}`:styles.stagesNumberText}>{stageNumber}</p>
            </div>
        )   
    );

}

export default StageNumber;