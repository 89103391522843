import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Accounts } from '../../../api/inni/Accounts'
import { Account } from '../../../api/inni/data-contracts'
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList'

const ExpenseAccountSelector = ({employeeId, setAccount} : {employeeId? : number, setAccount : (i : number) => void}) => {
    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts);
    const [selectedAccount, setSelectedAccount] = useState<number>(-1)

    const handleChange = (i : number) => {
        setAccount(i);
        setSelectedAccount(i)
    }

    return (
        <Form.Control style={{marginRight: '7px', minWidth: '275px'}} onChange={(e) => handleChange(Number(e.target.value))} value={selectedAccount} as="select">
            { accountsLoaded &&
                accounts.filter(x => x.personId === employeeId && x.isExpenseAccount).map(a => {
                    return (<option value={a.id} key={a.id}>{a.name}</option>)
                })
            }
        </Form.Control>
    )
}

export default ExpenseAccountSelector