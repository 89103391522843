import React, { useContext, useEffect, useState } from 'react'
import { Card, ListGroup } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { Companies } from '../../api/inni/Companies'
import { CompanySummary } from '../../api/inni/data-contracts';
import BrandContext from '../../context/BrandContext';
import UserContext from '../../context/UserContext';
import { useGetUrl } from '../../hooks/useGetUrl';
import { useInniAPI } from '../../hooks/useInniAPI'
import { Entity } from '../../utils/EntityAction';
import styles from './CompanySelect.module.css';
import configHelper, {Platform} from '../../utils/configHelper';
import useIsMobile from '../../hooks/useIsMobile';

const CompanyRow = ({company, selectCompany} : {company: CompanySummary, selectCompany: (id: number) => void}) => {

    const onClick = () => {
        if (company.id) selectCompany(company.id)
    }

    return (
        <ListGroup.Item action onClick={onClick}>
            {company.name}
        </ListGroup.Item>

    )
}


export const CompanySelect = () => {
    const companiesApi = useInniAPI(Companies);
    const [companies, setCompanies] = useState<Array<CompanySummary>>([]);
    const [loaded, setLoaded] = useState(false);
    const brand = useContext(BrandContext).brand;
    const userContext = useContext(UserContext);
    const getUrl = useGetUrl();
    const [redirectUrl, setRedirectUrl] = useState<string|undefined>(undefined);
    const [error, setError] = useState(false);
    const redirectEntity = useIsMobile() ? Entity.QuickEntry : Entity.CompanyDashboard;

    useEffect(() => {
        if (configHelper.platform === Platform.Hub) {
            setRedirectUrl(getUrl(Entity.NoCompany, undefined).url)
        } else if (companiesApi && !loaded) {
            companiesApi.index().then(response => {
                setCompanies(response.data);
                if (response.data && response.data.length === 1 && response.data[0].id ) {
                    setRedirectUrl(getUrl(redirectEntity, undefined, {cid: response.data[0].id.toString()}).url)
                } else if (response.data && response.data.length === 0) {
                    setRedirectUrl(getUrl(Entity.NoCompany, undefined).url)
                } else {
                    setLoaded(true);
                }
            }).catch(error => {
                setError(true);
            })
        }
    }, [companiesApi, getUrl, loaded, redirectEntity])

    const selectCompany = (id:number) => {
        setRedirectUrl(getUrl(redirectEntity, undefined, {cid: id.toString()}).url)
    }

    if (redirectUrl) {
        return <Redirect to={redirectUrl} />
    } else {
        return (
            <div id={styles.companySelect}>
                <div id={styles.inner}>
                    <img className={styles.logo} src={brand.logoDarkUrl} alt={brand.name} />
                    {!error && loaded && 
                        <Card id={styles.companyList} className='shadow'>
                            <Card.Header>Please select to continue:</Card.Header>
                            <ListGroup variant='flush'>
                                {/* {!loaded &&
                                    <>
                                        <ListGroup.Item className={styles.loading}><span className={styles.line}></span></ListGroup.Item>
                                        <ListGroup.Item className={styles.loading}><span className={styles.line}></span></ListGroup.Item>
                                        <ListGroup.Item className={styles.loading}><span className={styles.line}></span></ListGroup.Item>
                                    </>
                                } */}
                                {/* {loaded &&  */}
                                    <>
                                        {companies.map(company => 
                                            <CompanyRow company={company} key={company.id} selectCompany={selectCompany} />
                                        )}
                                    </>
                                {/* } */}
                            </ListGroup>
                        </Card>
                    }
                    {error &&
                        <div className={styles.errorMessage}>
                            There was an error fetching your data. Please try again later, or contact us.
                        </div>
                    }
                    <a href='#' className={styles.logout} onClick={userContext.logout}>Logout</a>
                </div>
            </div>
        )
    }
    
}