import DocumentsPage  from '../../features/Documents/DocumentsPage';
import { Action, Entity } from '../../utils/EntityAction';
import { EntityRoute, RouteContext } from './routeTypes';

const documents:Array<EntityRoute> = [
    {
        path: '/company/:cid/files/new',
        exact: true,
        entity: Entity.DocumentV7,
        context: RouteContext.Company,
        component: DocumentsPage,
    },
]

export default documents; 