/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AnswerPostModel,
  AssessmentUpdateModel,
  IR35Assessment,
  Ir35AssessmentAnswer,
  RenameCommandModel,
  UpdateScoresCommandModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Ir35Assessment<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Ir35Assessment
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/ir35assessments
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<IR35Assessment[], any>({
      path: `/api/v2/companies/${companyId}/ir35assessments`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ir35Assessment
   * @name GetItem
   * @request GET:/api/v2/companies/{companyId}/ir35assessments/{assessmentId}
   * @secure
   */
  getItem = (companyId: number, assessmentId: string, params: RequestParams = {}) =>
    this.request<IR35Assessment, any>({
      path: `/api/v2/companies/${companyId}/ir35assessments/${assessmentId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ir35Assessment
   * @name Put
   * @request PUT:/api/v2/companies/{companyId}/ir35assessments/{assessmentId}
   * @secure
   */
  put = (companyId: number, assessmentId: string, value: AssessmentUpdateModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/ir35assessments/${assessmentId}`,
      method: "PUT",
      body: value,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Ir35Assessment
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/ir35assessments/{assessmentId}
   * @secure
   */
  delete = (companyId: number, assessmentId: string, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/ir35assessments/${assessmentId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Ir35Assessment
   * @name PostRename
   * @request POST:/api/v2/companies/{companyId}/ir35assessments/{assessmentId}/rename
   * @secure
   */
  postRename = (companyId: number, assessmentId: string, m: RenameCommandModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/ir35assessments/${assessmentId}/rename`,
      method: "POST",
      body: m,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Ir35Assessment
   * @name PostUpdateScores
   * @request POST:/api/v2/companies/{companyId}/ir35assessments/{assessmentId}/updatescores
   * @secure
   */
  postUpdateScores = (
    companyId: number,
    assessmentId: string,
    m: UpdateScoresCommandModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/ir35assessments/${assessmentId}/updatescores`,
      method: "POST",
      body: m,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Ir35Assessment
   * @name DismissNotification
   * @request POST:/api/v2/companies/{companyId}/ir35assessments/dismissnotification/{contractId}
   * @secure
   */
  dismissNotification = (companyId: number, contractId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/ir35assessments/dismissnotification/${contractId}`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Ir35Assessment
   * @name GetAnswers
   * @request GET:/api/v2/companies/{companyId}/ir35assessments/{assessmentId}/answers
   * @secure
   */
  getAnswers = (companyId: number, assessmentId: string, params: RequestParams = {}) =>
    this.request<Ir35AssessmentAnswer[], any>({
      path: `/api/v2/companies/${companyId}/ir35assessments/${assessmentId}/answers`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ir35Assessment
   * @name PutAnswer
   * @request PUT:/api/v2/companies/{companyId}/ir35assessments/{assessmentId}/answers/{key}
   * @secure
   */
  putAnswer = (
    companyId: number,
    assessmentId: string,
    key: string,
    value: AnswerPostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/ir35assessments/${assessmentId}/answers/${key}`,
      method: "PUT",
      body: value,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ir35Assessment
   * @name DeleteAnswer
   * @request DELETE:/api/v2/companies/{companyId}/ir35assessments/{assessmentId}/answers/{key}
   * @secure
   */
  deleteAnswer = (companyId: number, assessmentId: string, key: string, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/ir35assessments/${assessmentId}/answers/${key}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
