import { useContext, useEffect, useState } from "react";
import { ApiConfig, HttpClient, HttpResponse } from "../../api/inni/http-client";
import CompanyContext from "../../context/CompanyContext";
import { useInniAPI } from "../useInniAPI";

interface HasGet<R>  extends HttpClient  {
    get: (cid: number, id: number) => Promise<HttpResponse<R, any>>
}
 
export function useFetchSingleEntity<R, T extends HasGet<R>>(apiClass: { new (p1: ApiConfig): T  }, id: number, hideErrorCodes?: number[]): [R|undefined, boolean, () => void] {

    const companyContext = useContext(CompanyContext);
    const api = useInniAPI(apiClass, hideErrorCodes);
    const [entity, setEntity] = useState<R | undefined>(undefined);
    const [loaded, setLoaded] = useState(false);

    const reload = () => {
        setLoaded(false)
    }

    useEffect(() => {
        if (api && !loaded) {
            api.get(companyContext.cid, id).then(
                response => {
                    setEntity(response.data);
                    setLoaded(true);
                }
            )
        }        

    }, [api, companyContext.cid, id, loaded])

    return [entity, loaded, reload]
}
