/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Document,
  DocumentList,
  DocumentPostModel,
  DocumentSourceViewModel,
  DocumentType,
  FinancialYearViewModel,
  TaxYearViewModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Files<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Files
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/files
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Document[], any>({
      path: `/api/v2/companies/${companyId}/files`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name ListDocuments
   * @request GET:/api/v2/companies/{companyId}/files/ListDocuments
   * @secure
   */
  listDocuments = (companyId: number, params: RequestParams = {}) =>
    this.request<DocumentList, any>({
      path: `/api/v2/companies/${companyId}/files/ListDocuments`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name Tags
   * @request GET:/api/v2/companies/{companyId}/files/Tags
   * @secure
   */
  tags = (companyId: number, params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/v2/companies/${companyId}/files/Tags`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name DocumentNameTypesList
   * @request GET:/api/v2/companies/{companyId}/files/DocumentNameTypesList
   * @secure
   */
  documentNameTypesList = (companyId: number, params: RequestParams = {}) =>
    this.request<DocumentType[], any>({
      path: `/api/v2/companies/${companyId}/files/DocumentNameTypesList`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name GetTaxYears
   * @request GET:/api/v2/companies/{companyId}/files/TaxYears
   * @secure
   */
  getTaxYears = (companyId: number, params: RequestParams = {}) =>
    this.request<TaxYearViewModel[], any>({
      path: `/api/v2/companies/${companyId}/files/TaxYears`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name GetFinancialYears
   * @request GET:/api/v2/companies/{companyId}/files/FinanacialYears
   * @secure
   */
  getFinancialYears = (companyId: number, params: RequestParams = {}) =>
    this.request<FinancialYearViewModel[], any>({
      path: `/api/v2/companies/${companyId}/files/FinanacialYears`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name GetDocumentSources
   * @request GET:/api/v2/companies/{companyId}/files/GetDocumentSources
   * @secure
   */
  getDocumentSources = (companyId: number, params: RequestParams = {}) =>
    this.request<DocumentSourceViewModel[], any>({
      path: `/api/v2/companies/${companyId}/files/GetDocumentSources`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name DownloadFile
   * @request GET:/api/v2/companies/{companyId}/files/Dowload
   * @secure
   */
  downloadFile = (
    companyId: number,
    query: { docId: number; thumbnailSize?: number; getOriginal?: boolean },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/files/Dowload`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name GetTemporaryUrl
   * @request GET:/api/v2/companies/{companyId}/files/GetTemporaryURL
   * @secure
   */
  getTemporaryUrl = (
    companyId: number,
    query: { docId: number; thumbnailSize?: number; getOriginal?: boolean },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/files/GetTemporaryURL`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    }); /**
 * @description ''' Editing of a document is handled entirely by this action - including moving from inbox, deleting, etc. '''
 * 
 * @tags Files
 * @name Put
 * @summary ''' Updates a given document
'''
 * @request PUT:/api/v2/companies/{companyId}/files/{id}
 * @secure
 */
  put = (companyId: number, id: number, postModel: DocumentPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/files/${id}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/files/{id}
   * @secure
   */
  delete = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/files/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/files/update/{id}
   * @secure
   */
  update = (companyId: number, id: number, postModel: DocumentPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/files/update/${id}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name Upload
   * @summary This is probably teh one place where the file metadata and teh content itself could be confused. Mostly elsewhere we have an endpoint to upload a specific file type..
   * @request POST:/api/v2/companies/{companyId}/files/upload
   * @secure
   */
  upload = (companyId: number, data: { fileToUpload: File }, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/files/upload`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Files
   * @name UploadDocumentForBankPayment
   * @request POST:/api/v2/companies/{companyId}/files/uploadDocumentForBp/{bankPaymentId}
   * @secure
   */
  uploadDocumentForBankPayment = (
    companyId: number,
    bankPaymentId: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/files/uploadDocumentForBp/${bankPaymentId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
}
