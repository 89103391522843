import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChartPie, faCheckCircle, faCreditCardBlank, faList, faTable, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';

interface ViewSwitcherButtonProps {
    icon?: IconDefinition,
    text?: string,
    keyName: string,
    active: boolean,
    onClick: (view: string) => void
}

export const ViewSwitcherButton = ({icon, text, keyName, active, onClick}: ViewSwitcherButtonProps) => {

    const click = () => {
        onClick(keyName);
    }

    return (
        <Button 
            variant={active ? 'primary' : 'outline-secondary'} 
            value={keyName} 
            onClick={click}
            >
                {icon && 
                    <FontAwesomeIcon icon={icon} className='mr-2' />
                }
                {text}
        </Button>
    )
}

export interface ViewSwitcherProps {
    small?: boolean,
    card?: boolean,
    table?: boolean,
    pie?: boolean,
    list?: boolean,
    calander?: boolean,
    propertyStatus?: boolean,
    currentView?: string,
    viewChanged: (view: string) => void,
    marginRight?: boolean,
    listText?: string,
    activeArchived?: boolean
}


export const ViewSwitcher = ({
        small,
        card,
        table,
        pie,
        calander,
        list,
        listText = 'List',
        propertyStatus,
        currentView,
        viewChanged,
        marginRight,
        activeArchived
    }: ViewSwitcherProps) => {
    return (
        <ButtonGroup size={small ? 'sm' : undefined} style={marginRight ? {marginRight: '1rem'} : {}}>
            { list &&
                <ViewSwitcherButton text={listText} keyName='list' active={currentView === 'list'} onClick={viewChanged} icon={faList}  />
            }
            { pie &&
                <ViewSwitcherButton text='Chart' keyName='pie' active={currentView === 'pie'} onClick={viewChanged} icon={faChartPie}  />
            }
            {card &&
                <ViewSwitcherButton text='Cards' keyName='cards' active={currentView === 'cards'} onClick={viewChanged} icon={faCreditCardBlank}  />
            }
            {table &&
                <ViewSwitcherButton text='Table' keyName='table' active={currentView === 'table'} onClick={viewChanged} icon={faTable} />
            }
            {calander &&
                <ViewSwitcherButton text='Calander' keyName='cal' active={currentView === 'cal'} onClick={viewChanged} icon={faCalendar}  />
            }
            {propertyStatus &&
                <><ViewSwitcherButton text='Active' keyName='activeProperties' active={currentView === 'activeProperties'} onClick={viewChanged} icon={faCheckCircle}/>
                <ViewSwitcherButton text='Archived' keyName='inactiveProperties' active={currentView === 'inactiveProperties'} onClick={viewChanged} icon={faTimesCircle}/></>
            }
            { activeArchived &&
                <>
                    <ViewSwitcherButton
                        text='Active'
                        keyName='active'
                        active={currentView === 'active'}
                        onClick={viewChanged}
                        icon={faCheckCircle}
                    />
                    <ViewSwitcherButton
                        text='Archived'
                        keyName='archived'
                        active={currentView === 'archived'}
                        onClick={viewChanged}
                        icon={faTimesCircle}
                    />
                </>
            }
        </ButtonGroup>
    )
}