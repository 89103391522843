import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import OnboardingSetupPage from "../../features/OnboardingSetup/onBoardingSetupPage";

const onboardingSetup:Array<EntityRoute> = [
    {
        path: '/company/:cid/setup',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.OnboardingSetup,
        action: Action.List,
        component:OnboardingSetupPage
    }
]

export default onboardingSetup;