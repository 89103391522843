import { SbBlokData, storyblokEditable } from '@storyblok/react';
type Props = {
	blok: SbBlokData;
};
const StatusLineValue = ({ blok }: Props) => {
	return (
		<div {...storyblokEditable(blok)}>
			<strong>Status:</strong>{' '}
			<span className={`text-${blok.variant}`}>{blok.title}</span>{' '}
		</div>
	);
};

export default StatusLineValue;
