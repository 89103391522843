import { useEffect, useState, useContext } from 'react';
import { Alert, Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import CompanyContext from '../../context/CompanyContext';
// API
import { Property } from '../../api/inni/data-contracts';
import { Valuation } from '../../api/inni/data-contracts';
import { useInniAPI } from '../../hooks/useInniAPI';
import { Properties } from '../../api/inni/Properties';
import { Valuations } from '../../api/inni/Valuations';
import { Ated } from '../../api/inni/Ated';
// Components
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { Text, Date, Currency } from '../../elements/DetailRow/DetailRow'
import ContactDetails from '../../components/ContactDetails/ContactDetails';
import { Button } from '../../elements/Button/Button';

interface RouteParams {
  type: string,
  id: string
}

type Declaration = 'No' | 'Yes' | 'NotRequired' | 'NotSet';

const AtedDeclaration = () => {
  const companyContext = useContext(CompanyContext);
  const type = useParams<RouteParams>().type;
  const pathId = parseInt(useParams<RouteParams>().id);

  const propertiesApi = useInniAPI(Properties);
  const valuationsApi = useInniAPI(Valuations);
  const atedApi = useInniAPI(Ated);

  const [property, setProperty] = useState<Property>();
  const [valuation, setValuation] = useState<Valuation>();
  const [declarationMade, setDeclarationMade] = useState<Declaration>('NotSet');
  const [propertyId, setPropertyId] = useState<number | undefined>();
  const [check, setCheck] = useState(false);

  // Get declaration status
  useEffect(() => {
    if (atedApi) {
      if (type === 'forproperty') {
        atedApi.forProperty(companyContext.cid, {propertyId: pathId})
          .then(
            response => {
              setDeclarationMade(response.data.declarationMade|| 'NotSet');
            }
          )
          .catch((error) => {
            console.error(error);
          });
      } else if (type === 'forvaluation') {
        atedApi.forValuation(companyContext.cid, {valuationId: pathId})
          .then(
            response => {
              setDeclarationMade(response.data.declarationMade || 'NotSet');
              setPropertyId(response.data.propertyId);
            }
          )
          .catch((error) => {
            console.error(error);
          });
      }
    }
  }, [type, atedApi, companyContext.cid, pathId])

  // Get property (type == forproperty)
  useEffect(() => {
    if (type === 'forproperty' && propertiesApi) {
      propertiesApi.get(companyContext.cid, pathId)
        .then(
          response => {
            setProperty(response.data);
          }
        )
        .catch((error) => {
          console.error(error);
        });
      }
  }, [propertiesApi, type, companyContext.cid, pathId]);

  // Get valuation (type == forvaluation)
  useEffect(() => {
      if (type === 'forvaluation' && valuationsApi && propertyId) {
        valuationsApi.get(companyContext.cid, propertyId, pathId)
          .then(
            response => {
              setValuation(response.data);
            }
          )
          .catch((error) => {
            console.error(error);
          });
      }
  }, [valuationsApi, type, propertyId, companyContext.cid, pathId])

  // Get property details if type valuation
  useEffect(() => {
    if (propertiesApi && propertyId) {
      propertiesApi?.get(companyContext.cid, propertyId)
        .then(
          response => {
            setProperty(response.data);
          }
        )
        .catch((error) => {
          console.error(error);
        });
    }
  }, [propertiesApi, propertyId, companyContext.cid])

  const save = () => {
    if (type === 'forproperty') {
      atedApi?.declarationMadeForProperty(companyContext.cid, {propertyId: pathId})
        .then(
          response => {
            if (response.status === 200) {
              setDeclarationMade('Yes');
            } else {
              console.warn(`Unexpected response code ${response.status}`);
            }
          }
        )
        .catch((error) => {
          console.error(error);
        });
    } else if (type === 'forvaluation') {
      atedApi?.declarationMadeForValuation(companyContext.cid, {valuationId: pathId})
        .then(
          response => {
            if (response.status === 200) {
              setDeclarationMade('Yes');
            } else {
              console.warn(`Unexpected response code ${response.status}`);
            }
          }
        )
        .catch((error) => {
          console.error(error);
        });
    }
  }

  return (
    <DefaultLayout title="ATED declaration" backIcon={true}>
      <Container className="ml-0">
        <Card className="shadow-sm">
          <Card.Header>
            { property?.name ? <h5>{property.name}</h5> : <LoadingPlaceholder dark width="40%" />}
          </Card.Header>
          <Card.Body>
            <div>
              <p>When a property is valued at £500,000 or more and is owned by a company, partnership or collective investment scheme
                you must make a declaration relating to the "Annual Tax on Enveloped Dwellings" (ATED), and pay any tax due.</p>
              <p>You can read more about this on the governments website, <a href="https://www.gov.uk/guidance/annual-tax-on-enveloped-dwellings-the-basics" target="_blank" rel="noopener noreferrer">here</a>.</p>
              <p>If you are unsure what you need to do you can get in touch with your account manager who will be able to help.</p>
            </div>

            <br />

            <p className="mb-4"><u>Valuation details:</u></p>
            { property && (
              <>
                <Text entity={property} name="address" />
                <Text entity={property} name="postcode" />
                { type !== 'forvaluation' && <Date entity={property} name="purchaseDate" /> }
                { type !== 'forvaluation' && <Currency entity={property} name="currentValue" label="Value" /> }
              </>
            )}

            { valuation && (
              <>
                <Date entity={valuation} name="valuationDate" />
                <Currency entity={valuation} name="value" />
              </>
            )}

            { (!property && !valuation) && (
              <>
                <LoadingPlaceholder width="400px" /><br /><br />
                <LoadingPlaceholder width="400px" /><br /><br />
                <LoadingPlaceholder width="400px" /><br /><br />
                <LoadingPlaceholder width="400px" /><br /><br />
              </>
            )}

            <hr />

            {
              {
                'No': (
                  <>
                    <label style={{wordWrap: 'break-word', cursor: 'pointer'}}>
                      <input
                        className="mr-2"
                        checked={check}
                        onChange={(e) => setCheck(e.target.checked)}
                        type="checkbox"
                      />I have completed the ATED declaration and submitted it to HMRC
                    </label>

                    <br />

                    <Button variant="change" onClick={save} disabled={!check}>Submit</Button>
                  </>
                ),
                'Yes': <Alert variant="success">Declaration completed for this property.</Alert>,
                'NotRequired': <Alert variant="success">Declaration not needed for this property.</Alert>,
                'NotSet': <LoadingPlaceholder width="100%" height="50px" />
              }[declarationMade]
            }

            <hr />

            <ContactDetails />

          </Card.Body>
        </Card>
      </Container>
    </DefaultLayout>
  );
}

export default AtedDeclaration;
