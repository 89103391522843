import { PvSatrPostModel } from "../../../../api/inni/data-contracts";
import FormikField from "../../../../elements/FormikField/FormikField";
import styles from '../Income.module.scss'
import Attachments from "../../../../components/Attachments/Attachments";
import { FileRejection } from "react-dropzone";
import { Form } from "react-bootstrap";
import { ChangeEvent, useEffect } from "react";
import { useFormikContext } from "formik";

interface PensionProps{

    onFilesDropped: (acceptedFiles: File[], fileRejections: FileRejection[], section: string) => void;
    filesToUpload: File[]|undefined|null;
    removePendingFile: (fileName: string, section: string) => void;
    removeAllFiles: (section: string) => void;
    showError?: boolean
    showPension: boolean
    setShowPension:(showPension: boolean) => void
}

export default function Pension ({onFilesDropped, filesToUpload, removePendingFile, removeAllFiles, showError, showPension, setShowPension}:PensionProps){

    const { values ,setFieldValue } = useFormikContext<PvSatrPostModel>();
    useEffect(() =>{
        setShowPension(
                            !!values.pensionIncomeDetails  || !!values.attachments?.some(x => x.section === "Pension")
                        )
    },[])

    const handleFileDrop=(acceptedFiles: File[], fileRejections: FileRejection[])=>{
        onFilesDropped(acceptedFiles, fileRejections, "Pension" )
    }

    const handleRemoveFile =(fileName:string)=>{
        removePendingFile(fileName,  "Pension")
    }

    const handleShowPensionChange=(e: ChangeEvent<HTMLInputElement>)=>{
        const isChecked = e.target.checked
        setShowPension(isChecked)
        if(!isChecked){
            setFieldValue("pensionIncomeDetails", undefined, false)
            removeAllFiles("Pension");
        }
    }

    
    return(
        <>
            <Form.Group>
                <Form.Label>Have you received a pension in this tax year?</Form.Label>
                    <Form.Check
                        type='checkbox'
                        checked={showPension}
                        data-cy="showPension"
                        onChange={handleShowPensionChange}
                    />
            </Form.Group>
            
            {showPension &&
                <>
                    <FormikField<PvSatrPostModel> 
                        className={styles.formikNumberField}
                        name={"pensionIncomeDetails"}
                        label="Please provide details on how much you received and from which providers"
                        type="textarea"                                  
                        regularLabel
                        wrapLabel
                    />

                    <br />

                    <div className={styles.attachment}>
                        <Attachments
                            title='Attach files'
                            accept={['image/*','.pdf','.doc','.docx']}
                            onDrop={handleFileDrop}
                            message='Please upload your P60 or pension statements from your pension provider'
                            attachedFiles={filesToUpload}
                            onRemoveFile={handleRemoveFile}
                            hidefiles
                            fullWidth
                            darkenedMode
                            regularLabel
                            showError={showError}
                        />

                        {showError &&
                            <Form.Control.Feedback 
                                className={styles.errorFeedback} 
                                type="invalid"
                            >
                                    Please upload the relevant document(s)
                            </Form.Control.Feedback>
                        }
                    </div>
                </>
                
            }
            
        </>  
    )
}