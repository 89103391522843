import { useCallback, useContext} from 'react'
import { PensionProviderPostModel, PersonAsDetailed } from '../../../../api/inni/data-contracts'
import { Form, Formik, FormikErrors, FormikProps } from 'formik'
import { Submit, Text } from '../../../../elements/EditRow/EditRow'
import { Text as DetailText } from '../../../../elements/DetailRow/DetailRow'
import CompanyContext from '../../../../context/CompanyContext'
import { useInniAPI } from '../../../../hooks/useInniAPI'
import { Employments } from '../../../../api/inni/Employments'
import { TabEditProps } from '../../People'
import { CompareValues } from '../../../../utils/compareValues'
import { Button } from '../../../../elements/Button/Button'
import { Entity, Action } from '../../../../utils/EntityAction'


const PensionProvider = ({person, setPerson, editing, setEditing, setDisabled}:
    {person : PersonAsDetailed | undefined, setPerson : (i : PersonAsDetailed | undefined) => void,setDisabled :(disabled:boolean)=>void} & TabEditProps) => {

    const companyContext = useContext(CompanyContext)
    const employmentsApi = useInniAPI(Employments)
	const refVal = useCallback(
		(node: FormikProps<any>) => {
			if (node !== null) {
				setDisabled(!CompareValues({ ...node.values }, { ...node.initialValues }, true));
			}
		},
		[setDisabled]
	);
    const validateUpdate = (values: PensionProviderPostModel) : Promise<FormikErrors<PensionProviderPostModel>> => {
        return new Promise((resolve) => {
            if (employmentsApi) {
                employmentsApi.validateUpdatePensionProvider(companyContext.cid, person?.employmentId || 0, values)
                .then(() => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const updateCall = (values: PensionProviderPostModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (employmentsApi) {
                employmentsApi.updatePensionProvider(companyContext.cid, person?.employmentId || 0, values)
                .then(res => {
                    if (res.status === 200) {
                        let tempPerson = person
                        if (tempPerson) {
                            tempPerson.pensionProviderApiKey = values.pensionProviderApiKey
                            tempPerson.payslipEmail = values.payslipEmail
                        }
                        setPerson(tempPerson)
                        setEditing(false)
                    }
                    resolve()
                }).catch(err => reject(err.error))
            }
        })
    }

    return (
        <>
            { person && (
                <>
                    <h2 style={{marginTop: '20px'}}>Pension Provider</h2>
                    { !editing
                        ? (
                            <>
                                <DetailText editStyling entity={person} name="pensionProvider" />
                                <DetailText editStyling entity={person} name="pensionProviderApiKey" ensureRender />
                                <DetailText editStyling entity={person} name="payslipEmail" ensureRender />

                                <Button
                                    buttonType="edit"
                                    entity={Entity.PersonV7}
                                    action={Action.Edit}
                                    onClick={() => setEditing(true)}
                                    disabled={editing}
                                    marginTop
                                />
                            </>
                        )
                        : (
                            <>
                                <Formik
                                    initialValues={{ pensionProviderApiKey: person.pensionProviderApiKey, payslipEmail: person.payslipEmail } as PensionProviderPostModel}
                                    validateOnChange={false}
                                    enableReinitialize
                                    onSubmit={updateCall}
                                    validate={validateUpdate}
                                    innerRef={refVal}
                                >
                                    {({ isSubmitting }) => (
                                        <Form>
                                            <DetailText editStyling entity={person} name="pensionProvider" />
                                            <Text name="pensionProviderApiKey" />
                                            <Text name="payslipEmail" help="Putting an email address here will email the employee their payslips" />

                                            <Submit disabled={isSubmitting} onCancelClick={() => setEditing(false)} />
                                        </Form>
                                    )}
                                </Formik>
                            </>
                        )
                    }
                </>
            )}
        </>
    )
}

export default PensionProvider