import { useContext, useEffect, useState } from 'react'
import { Card, Tab, Tabs } from 'react-bootstrap'
import { Dashboard } from '../../../api/inni/Dashboard'
import { DashboardView } from '../../../api/inni/data-contracts'
import BrandContext from '../../../context/BrandContext'
import CompanyContext from '../../../context/CompanyContext'
import { useInniAPI } from '../../../hooks/useInniAPI'
import CashBalance from './CashBalance'
import IncomeExpenses from './IncomeExpenses'
import LiveCash from './LiveCash'
import { InniReferral, ProvReferral } from './Referral'
import UnbilledWork from './UnbilledWork'
import styles from '../Dashboard.module.scss'
import configHelper from '../../../utils/configHelper'
import { CompanySetupNotComplete, NoData } from './NoDataMessage'

const RightHandCard = () => {
    const dashboardAPI = useInniAPI(Dashboard)
    const companyContext = useContext(CompanyContext)
    const brandContext = useContext(BrandContext)

    const [activeKey, setActiveKey] = useState<string>('liveCash')

    const [dashboardData, setDashboardData] = useState<DashboardView | undefined>(undefined)
    const [dashboardDataLoaded, setDashboardDataLoaded] = useState(false)

    const [lcLoaded, setLcLoaded] = useState(false)

    const isMobile = configHelper.useMobile;
    const v8Styling = companyContext.company?.useV8UI || false;

    useEffect(() => {
        if (dashboardAPI && !dashboardDataLoaded && lcLoaded) {
            dashboardAPI.index(companyContext.cid)
            .then(res => {
                setDashboardData(res.data)
                setActiveKey(res.data.hideLiveCashTab ? 'cashBalance' : 'liveCash')
                setDashboardDataLoaded(true)
            })
        }
    }, [dashboardAPI, companyContext.cid, dashboardDataLoaded, lcLoaded])

    return (
        <>
        {!isMobile && 
        <Card className={styles.reminderCard}>
            <Tabs
                variant="tabs"
                activeKey={activeKey}
                onSelect={(e) => {if (e) { setActiveKey(e) }}}
                style={{width: '100%', margin: '0', marginBottom: '20px', borderBottom: '1px solid var(--light-border-color)'}}
            >
                { !dashboardData?.hideLiveCashTab && (
                    <Tab
                        tabClassName={`${styles.dashboardTab} ${activeKey === 'liveCash' ? styles.selected : ''}`}
                        eventKey="liveCash"
                        title="LiveCash"
                    >
                        <LiveCash setLoaded={setLcLoaded} />
                    </Tab>
                )}

                <Tab
                    tabClassName={`${styles.dashboardTab} ${activeKey === 'cashBalance' ? styles.selected : ''}`}
                    eventKey="cashBalance"
                    title="Cash balance"
                >
                    { dashboardData?.showInSetup
                        ? CompanySetupNotComplete(v8Styling)
                        : (
                            <>
                                { dashboardDataLoaded && dashboardData && dashboardData.chartData
                                    ? <CashBalance chartData={dashboardData.chartData} />
                                    : NoData(v8Styling)
                                }
                            </>
                        )
                    }
                </Tab>

                <Tab
                    tabClassName={`${styles.dashboardTab} ${activeKey === 'incomeExpenses' ? styles.selected : ''}`}
                    eventKey="incomeExpenses"
                    title="Income and expenses"
                >
                    { dashboardDataLoaded && dashboardData && dashboardData.chartData
                        ? <IncomeExpenses chartData={dashboardData.chartData} />
                        : NoData(v8Styling)
                    }
                </Tab>
            </Tabs>
        </Card>}
        { isMobile &&
            <LiveCash setLoaded={setLcLoaded} />
        }
        { !isMobile && dashboardDataLoaded && dashboardData && !dashboardData?.hideUnbilled && dashboardData.unbilledContracts && 
            <UnbilledWork unbilledContracts={dashboardData?.unbilledContracts} />
        }
        { !isMobile &&  brandContext.brand.name === "Provestor" &&
            <ProvReferral />
        }
        { !isMobile &&  brandContext.brand.name === "inniAccounts" && <>
            <InniReferral />
        </>}
        </>
    )
}

export default RightHandCard