export enum InvoiceDocumentNames {
    Invoice = 'Invoice',
    CreditNote = 'Credit note',
    Quote = 'Quote',
    InvoiceTemplate = 'Repeating invoice',
}
export enum InvoicePageButtons {
    Invoice = 'invoice',
    CreditNote = 'credit note',
    Quote = 'quote',
    InvoiceTemplate = 'repeating invoice',
}
export enum InvoiceListTableHeadersIsProperty {
    Date = 'Date',
    Type = 'Type',
    Number = 'No',
    Reference = 'Ref',
    Property = 'Property',
    Tenancy = 'Tenancy',
    Description = 'Description',
    Amount = 'Amount',
    Balance = 'Balance',
    Duedate = 'Due date',
    Status = 'Status',
}

export enum InvoiceListTableHeadersIsCompany {
    Date = 'Date',
    Type = 'Type',
    Number = 'No',
    Ref = 'Ref',
    Description = 'Description',
    Amount = 'Amount',
    Balance = 'Balance',
    Duedate = 'Due date',
    Status = 'Status',
}

export enum InvoiceLabelTypeRead {
    invoiceReference = 'Invoice reference',
    invoiceNumber = 'Invoice number',
    description = 'Description',
    contractName = 'Contract',
    invoiceAddress = 'Invoice to',
    date = 'Invoice date',
    dateDue = 'Payment due by',
    invoiceCurrency = 'Currency',
    currencyRateAtTaxPoint = 'Rate on invoice date',
    supplyCountry = 'Customer Location',
}
export enum InvoiceLabelTypeEdit {
    invoiceNumber = 'Invoice number',
    date = 'Invoice date',
    description = 'Description',
    invoiceAddress = 'Invoice to',
    dateDue = 'Payment due by',
    brandingThemeId = 'Branding',
    contractName = 'Contract',
    invoiceCurrency = 'Currency',
    supplyCountry = 'Customer location',
    startDate = 'From',
    endDate = 'To',
    billableDays = 'Days',
    selectedPaymentProviderId = 'Payment provider',
}


export enum CreditNoteLabelTypeRead {
    invoiceReference = 'Invoice reference',
    invoiceNumber = 'Credit note number',
    contractName = 'Contract',
    invoiceCurrency = 'Currency',
    date = 'Credit note date',
    description = 'Description',
    invoiceAddress = 'Credit note for',
    currencyRateAtTaxPoint = 'Rate on invoice date',
    supplyCountry = 'Customer location',
}
export enum CreditNoteLabelTypeEdit {
    invoiceNumber = 'Credit note number',
    date = 'Credit note date',
    description = 'Description',
    invoiceAddress = 'Credit note to',
    brandingThemeId = 'Branding',
    contractName = 'Contract',
    invoiceCurrency = 'Currency',
    supplyCountry = 'Customer location',
}

export enum QuoteLabelTypeRead {
    invoiceNumber = 'Quote number',
    invoiceReference = 'Invoice reference',
    contractName = 'Contract',
    description = 'Description',
    invoiceAddress = 'Quote for'
}

export enum QuoteLabelTypeEdit {
    invoiceNumber = 'Quote number',
    date = 'Quote date',
    description = 'Description',
    invoiceReference = 'Quote reference',
    invoiceAddress = 'Quote for',
    brandingThemeId = 'Branding',
    contractName = 'Contract',
    dateDue = 'Expiry date',
    invoiceCurrency = 'Currency',
    supplyCountry = 'Customer location',
}

export enum RepeatingInvoiceLabelTypeRead {
    description = 'Description',
    contractName = 'Contract',
    date = 'Next invoice date',
    currencyRateAtTaxPoint = 'Rate On Invoice Date',
    supplyCountry = 'Customer Location',
    invoiceAddress = 'Invoice to',
    autoEmailRepeatingInvoice = 'Auto email ?',
    defaultDueDays = 'Payment due within days',
    invoiceCurrency = 'Currency',
}
export enum RepeatingInvoiceLabelTypeEdit {
    description = 'Description',
    contractName = 'Tenancy',
    invoiceAddress = 'Invoice to',
    defaultDueDays = 'Payment due within days',
    autoEmailRepeatingInvoice = 'Auto email',
    intervalType = 'Every',
    intervalDayOfMonth = 'on',
    intervalDayOfWeek = 'on',
    brandingThemeId = 'Branding',
}
export const InvoiceDocumentLabelTypes = {
    InvoiceRead: InvoiceLabelTypeRead,
    CreditNoteRead: CreditNoteLabelTypeRead,
    QuoteRead: QuoteLabelTypeRead,
    InvoiceTemplateRead: RepeatingInvoiceLabelTypeRead,
    InvoiceEdit: InvoiceLabelTypeEdit,
    CreditNoteEdit: CreditNoteLabelTypeEdit,
    QuoteEdit: QuoteLabelTypeEdit,
    InvoiceTemplateEdit: RepeatingInvoiceLabelTypeEdit
}

export enum InvoiceLayoutTableHeadersVATDisabled {
    Description = 'DESCRIPTION',
    Quantity = 'QUANTITY',
    Unitprice = 'UNIT PRICE',
    Totalcost = 'TOTAL',
}

export enum InvoiceLayoutTableHeadersVATEnabled {
    Description = 'DESCRIPTION',
    Quantity = 'QUANTITY',
    Unitprice = 'UNIT PRICE',
    VATCode = 'VAT CODE',
    Totalcost = 'TOTAL(EX VAT)',
}
export enum ActionType {
    Create,
    Edit,
    Read
}

export enum WeekDays {
    MO = 'Monday',
    TU = 'Tuesday',
    WE = 'Wednesday',
    TH = 'Thursday',
    FR = 'Friday',
    SA = 'Saturday',
    SU = 'Sunday',
}

export enum IntervalTypes {
    W = 'Week',
    M = 'Month',
    Q = 'Quarter',
}


export function ShowLabel<T>(documentType: string, field: keyof T, actionType: ActionType) {
    switch (actionType) {
        case ActionType.Edit:
            switch (documentType) {
                case 'Invoice':
                    return InvoiceDocumentLabelTypes.InvoiceEdit[field as keyof typeof InvoiceLabelTypeEdit];
                case 'CreditNote':
                    return InvoiceDocumentLabelTypes.CreditNoteEdit[field as keyof typeof CreditNoteLabelTypeEdit];
                case 'Quote':
                    return InvoiceDocumentLabelTypes.QuoteEdit[field as keyof typeof QuoteLabelTypeEdit];
                case 'InvoiceTemplate':
                    return InvoiceDocumentLabelTypes.InvoiceTemplateEdit[field as keyof typeof RepeatingInvoiceLabelTypeEdit];
                default:
                    return;
            }
        case ActionType.Read:
            switch (documentType) {
                case 'Invoice':
                    return InvoiceDocumentLabelTypes.InvoiceRead[field as keyof typeof InvoiceLabelTypeRead];
                case 'CreditNote':
                    return InvoiceDocumentLabelTypes.CreditNoteRead[field as keyof typeof CreditNoteLabelTypeRead];
                case 'Quote':
                    return InvoiceDocumentLabelTypes.QuoteRead[field as keyof typeof QuoteLabelTypeRead];
                case 'InvoiceTemplate':
                    return InvoiceDocumentLabelTypes.InvoiceTemplateRead[field as keyof typeof RepeatingInvoiceLabelTypeRead];
                default:
                    return;
            }
    }
}

export function getFields(documentType: string) {
    switch (documentType) {
        case 'Invoice':
            return Object.entries(InvoiceDocumentLabelTypes.InvoiceRead);
        case 'CreditNote':
            return Object.entries(InvoiceDocumentLabelTypes.CreditNoteRead);
        case 'Quote':
            return Object.entries(InvoiceDocumentLabelTypes.QuoteRead);
        case 'InvoiceTemplate':
            return Object.entries(InvoiceDocumentLabelTypes.InvoiceTemplateRead);
        default:
            return;
    }
}

//Displays the label for the field in the Edit invoice layout based on the enums defined on the top
export const ShowField = (documentType: string, field: string, actionType: ActionType) => {
    switch (actionType) {
        case ActionType.Edit:
            switch (documentType) {
                case 'Invoice':
                    if (Object.keys(InvoiceDocumentLabelTypes.InvoiceEdit).includes(field as InvoiceLabelTypeEdit)) return true;

                    return false;
                case 'CreditNote':
                    if (Object.keys(InvoiceDocumentLabelTypes.CreditNoteEdit).includes(field as CreditNoteLabelTypeEdit)) return true;

                    return false;
                case 'Quote':
                    if (Object.keys(InvoiceDocumentLabelTypes.QuoteEdit).includes(field as QuoteLabelTypeEdit)) return true;

                    return false;
                case 'InvoiceTemplate':
                    if (Object.keys(InvoiceDocumentLabelTypes.InvoiceTemplateEdit).includes(field as RepeatingInvoiceLabelTypeEdit)) return true;

                    return false;
                default:
                    return;
            }
        case ActionType.Read:
            switch (documentType) {
                case 'Invoice':
                    if (Object.keys(InvoiceDocumentLabelTypes.InvoiceRead).includes(field as InvoiceLabelTypeRead)) return true;

                    return false;
                case 'CreditNote':
                    if (Object.keys(InvoiceDocumentLabelTypes.CreditNoteRead).includes(field as CreditNoteLabelTypeRead)) return true;

                    return false;
                case 'Quote':
                    if (Object.keys(InvoiceDocumentLabelTypes.QuoteRead).includes(field as QuoteLabelTypeRead)) return true;

                    return false;
                case 'InvoiceTemplate':
                    if (Object.keys(InvoiceDocumentLabelTypes.InvoiceTemplateRead).includes(field as RepeatingInvoiceLabelTypeRead)) return true;

                    return false;
                default:
                    return;
            }

    }
}

//This is for showing the color of the payement status in InvoiceListpage overdue, due, paid....
export const getInvoicePaymentStatus = (status: string) => {
    switch (status) {
        case 'Blank': return '';
        case 'Overdue': return 'danger';
        case 'Unpaid': return 'warning';
        case 'Paid': return 'success';
        case 'Collected': return 'info';
        default: return;
    }
}

//Returns the documentname based on the documenttype enum received in the API
export const getDocumentName = (documentType: string): string | undefined => {
    switch (documentType) {
        case 'Invoice': return 'Invoice';
        case 'CreditNote': return 'Credit note';
        case 'Quote': return 'Quote';
        case 'InvoiceTemplate': return 'Repeating invoice';
        default: return;
    }
}

// returns  market name for the Contract in invoice i,e Contract,Tenancy,Project....
export const getMarketTerm = (marketId: number): string | undefined => {
    switch (marketId) {
        case 1:
            return 'Contract';
        case 2:
            return 'Contract';
        case 3:
            return 'Tenancy';
        case 4:
            return 'Project';
        case 5:
            return 'Tenancy';
    }
}

export const getMarketFilterTerm = (marketId: number): string | undefined => {
    switch (marketId) {
        case 1:
            return 'contracts';
        case 2:
            return 'contract';
        case 3:
            return 'tenancies';
        case 4:
            return 'projects';
        case 5:
            return 'tenancies';
    }
}
