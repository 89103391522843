import { useState, useCallback, memo, useMemo } from 'react';

import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Nav, NavLink } from 'react-bootstrap';

import { LoadingPlaceholder } from '../../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import SearchWithClear from '../../../../elements/SearchWithClear/SearchWithClear';
import styles from './Document.module.css';

type DocumentsSidebarProps<T> = {
	showSearch?: boolean;
	showCount: boolean;
	sideBarItems: SideBarItem[] | undefined;
	handleSearch: (value: string) => void;
	setChildItems: (item: T) => void;
	setParentItems: (item: T) => void;
};

export type SideBarItem = {
	name: string;
	count?: number;
	isChildrenAvailable?: boolean;
	children?: SideBarItem[];
	type?: 'inbox' | 'label' | 'taxYear' | 'financialYear' | 'source' | 'recycleBin';
	activeLabel?: string | undefined;
	icon?: IconDefinition;
	display?: boolean;
	isAdminOnly?: boolean;
};

type NavBarItemProps<T> = {
	childNavLinkDetails: T;
	showCount?: boolean;
	onNavLinkClick: () => void;
	isDisabled?: boolean;
	isActive?: boolean;
	isItalic?: boolean;
};

function DocumentsSidebar({
	showSearch,
	sideBarItems,
	showCount,
	setChildItems,
	setParentItems,
	handleSearch,
}: DocumentsSidebarProps<SideBarItem>) {
	const [search, setSearch] = useState<string>('');

	const navLinkcallBack = useCallback(
		(item: SideBarItem, data: SideBarItem) => {
			setParentItems(item);
			setChildItems(data);
		},
		[setChildItems, setParentItems]
	);

	const memoizedSetSearch = useCallback(
		(value) => {
			setSearch(value);
			handleSearch(value);
		},
		[handleSearch] 
	);

	const NavBarItemMemo = useMemo(() => memo(NavBarItem), []);

	return (
		<div className={styles.sidebarWrapper}>
			<div className={styles.documentSidebar}>
				<Nav variant="tabs" fill className="flex-column">
					{showSearch && <SearchWithClear search={search} setSearch={memoizedSetSearch}></SearchWithClear>}
					{sideBarItems ? (
						sideBarItems.map((item) => (
							<div key={item.name}>
								{item.display ? (
									<div>
										<div
											className={classNames(
												item.isAdminOnly && styles.adminOnly,
												item.type === 'inbox' || item.type === 'recycleBin' ? styles.parentItem : styles.parentItemClickable
											)}>
											<NavBarItemMemo
												isDisabled={(item.children && item.children?.length > 0)
												|| item.type === 'inbox' || item.type === 'recycleBin' ? false : true}
												childNavLinkDetails={item}
												showCount={showCount}
												isActive={item.name === item.activeLabel ? true : false}
												onNavLinkClick={() => {
													if (item.type === 'inbox' || item.type === 'recycleBin') {
														navLinkcallBack(item, item);
													}
												}}></NavBarItemMemo>
										</div>
										<div className={classNames(styles.childItem)}>
											{(item.children && item.children?.length > 0 && item.isChildrenAvailable) || item.type === 'label' ? (
												<NavBarItemMemo
													childNavLinkDetails={{ name: 'All', display: true }}
													isActive={!item.activeLabel}
													onNavLinkClick={() => navLinkcallBack(item, { name: 'All', display: true })}></NavBarItemMemo>
											) : item.type === 'inbox' || item.type === 'recycleBin' ? null : (
												<NavBarItemMemo
													childNavLinkDetails={{ name: 'No entries', display: true }}
													onNavLinkClick={() => {}}
													isItalic
													isDisabled></NavBarItemMemo>
											)}
											{item.children?.map((child) => (
												<NavBarItemMemo
													key={child.name}
													childNavLinkDetails={child}
													showCount={showCount}
													onNavLinkClick={() => navLinkcallBack(item, child)}
													isActive={child.name === item.activeLabel ? true : false}
												/>
											))}
										</div>
									</div>
								) : null}
							</div>
						))
					) : <LoadingPlaceholderSidebar />}
				</Nav>
			</div>
		</div>
	);
}

const NavBarItem = ({
	childNavLinkDetails,
	showCount = false,
	onNavLinkClick,
	isDisabled,
	isActive = false,
	isItalic = false,
}: NavBarItemProps<SideBarItem>) => {
	const NavLinkElement = useMemo(() => {
		return (
			<NavLink
				onClick={onNavLinkClick}
				disabled={isDisabled}
				className={classNames(
					childNavLinkDetails.isChildrenAvailable === true
						? styles.parentItemNonClickable
						: childNavLinkDetails.isChildrenAvailable === false
						? styles.parentItemClickable
						: '',
					isActive ? styles.activeLink : '',
				)}>
				{childNavLinkDetails.icon ? <FontAwesomeIcon icon={childNavLinkDetails.icon} className="mx-1" /> : <span className="mx-3"></span>}{' '}
				{childNavLinkDetails.name}
				{showCount && childNavLinkDetails.count! >= 0 ? ' (' + childNavLinkDetails.count + ')' : null}
			</NavLink>
			// eslint-disable-next-line react-hooks/exhaustive-deps
		);
	}, [
		childNavLinkDetails.count,
		childNavLinkDetails.icon,
		childNavLinkDetails.isChildrenAvailable,
		childNavLinkDetails.name,
		isActive,
		isDisabled,
		showCount,
	]);

	return (
		<>
			{isDisabled ? (
				<NavLink disabled={isDisabled} className={isItalic ? 'font-italic':''}>
					{childNavLinkDetails.icon ? <FontAwesomeIcon icon={childNavLinkDetails.icon}
					className="mx-1" /> : <span className="mx-3"></span>}{' '}
					{childNavLinkDetails.name}
				</NavLink>
			) : (
				<div>{NavLinkElement}</div>
			)}
		</>
	);
};

const LoadingPlaceholderSidebar = memo(() => (
	<div className="mt-1">
		{Array(24)
			.fill(0)
			.map((_, index) => (
				<div key={index} className="">
					<LoadingPlaceholder height="34px"></LoadingPlaceholder>
				</div>
			))}
	</div>
));
export default DocumentsSidebar;
