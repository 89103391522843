import cn from 'classnames';
import { FC, useContext, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import styles from './PersonCard.module.scss';
import { LoadingPlaceholder } from '../../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { Button } from '../../../../elements/Button/Button';
import addPerson from '../../../../layouts/Components/icons/v8/addPerson.svg'
import { CogOptionsDropdown } from '../../../../elements/CogOptionsDropdown/CogOptionsDropdown';
import { useInniAPI } from '../../../../hooks/useInniAPI';
import { People as PeopleAPI } from '../../../../api/inni/People';
import CompanyContext from '../../../../context/CompanyContext';
import { PersonAsDetailed } from '../../../../api/inni/data-contracts';

type Props = {
	id?: number;
	selected: boolean;
	onClick?: () => void;
	personName?: string;
	className?: string;
	avatarColor?: 'primary' | 'secondary';
	isLoading?:boolean;
	addNew?:boolean;
	disable?: boolean;
	disabledReason?: string;
	onDelete?: () => void;
	v8Styling?:boolean;
	hideCogmenu?: boolean;
};

const avatarBgs = {
	primary: styles.activeAvatar,
	secondary: '',
};

const PersonCard: FC<Props> = ({
	id,
	selected,
	onClick,
	personName,
	className,
	avatarColor = 'primary',
	isLoading,
	addNew, 
	disable,
	disabledReason, 
	onDelete,
	v8Styling,
	hideCogmenu}) => {
	
	const [people, setPeople] = useState<PersonAsDetailed|undefined>();
	const peopleApi = useInniAPI(PeopleAPI, [400]);
	const companyContext = useContext(CompanyContext);

	useEffect(()  =>{
		if(id && peopleApi){
			peopleApi.getAsDetailed(companyContext.cid, id)
			.then((res) =>{
				setPeople(res.data);
			})
			.catch((err) =>{
				console.error(err)
			})
		}
	},[companyContext.cid, id, peopleApi])

	return (
		<div
			onClick={addNew ? undefined : onClick}
			className={cn(styles.personCard, selected ? styles.active : '', addNew ? styles.newCard : '', className)}
		>
			{ addNew && (
				<div className="w-100">
					<Image alt="Add person" src={addPerson} width="40px" height="40px"/>
					<p>Add new person</p>
					<div className={styles.addNewButton}>
						<Button 
							variant='dark'  
							outline 
							label='Add' 
							onClick={onClick} 
							className='w-100 mr-0' 
							disabled={disable}
							disabledMessage={disabledReason}
						/>
					</div>
				</div>
			)}

			{isLoading && 
				<>
					<div 
						className={cn(styles.avatar, styles.loading)}
					/>

					<div className='w-100 mt-1 ml-auto'>
						<LoadingPlaceholder height='25px'  dark/>
					</div>
				</>
			}

			{ personName && (
				<>
					<div className={cn(styles.avatar, avatarBgs[avatarColor])}>
						{personName.charAt(0)}
					</div>

					<p className="mt-2">{personName}</p>
					{ !hideCogmenu &&
						<div className='ml-auto' onClick={(e) => {e.stopPropagation()}}>
							<CogOptionsDropdown
								del={onDelete}
								disabled={people?.editability?.canDelete ? undefined : ['del']}
								rightAlign
								hamburger={v8Styling}
							/>
						</div>
					}
					
					
				</>
			)}		
		</div>
	);
};

export default PersonCard;
