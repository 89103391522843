import React, { useContext } from 'react';
import _ from 'lodash';
import { Property } from '../../../api/inni/data-contracts';
import { Badge, Card, Col, Row } from 'react-bootstrap';
import { formatCurrency0dp } from '../../../utils/formatNumbers';
import { Action, Entity } from '../../../utils/EntityAction';
import { formatTenure } from '../../../utils/formatters/formatProperty';
import { LettableUnitsBadges } from '../Elements/LettableUnitsBadges';
import styles from '../Property.module.scss';
import stylesV75 from '../Property_V75.module.scss';
import { useGetUrl } from '../../../hooks/useGetUrl';
import { Link } from 'react-router-dom';
import { placeholderImage } from '../../../utils/formatters/formatProperty';
import { placeholderImageV8 } from '../../../utils/formatters/formatProperty';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import ImageIconOverlay from '../../../elements/ImageIconOverlay/ImageIconOverlay';
import CompanyContext from '../../../context/CompanyContext';
import { PropertyCard, PropertyCardLoading } from './PropertyCard';
import classNames from 'classnames';

export interface PropertyListCardsProps {
    loaded: boolean, 
    properties: Array<Property>
}


export const PropertyListCards = ({loaded, properties}:PropertyListCardsProps) => {
    const companyContext = useContext(CompanyContext);
    const v8Styling = companyContext.company?.useV8UI || false;
    const styling = v8Styling ? stylesV75 : styles;
    const placeholderImageStyle = v8Styling ? placeholderImageV8 : placeholderImage;

    const getUrl = useGetUrl();

    const propertyName = (property: Property) => {
        const name = property.nickname ? property.nickname : property.name;
        if (property.saleDate || property.isInactive) {
            return <del>{name}</del>
        } else {
            return <>{name}</>
        }
    }

    const propertyValue = (property: Property) => {
        if (property.saleDate && property.salePrice) {
            return <>{`${property.saleDate ? 'Sold for ' : ''} ${formatCurrency0dp(property.salePrice)}`}</>
        } else if (property.currentValue) {
            return <>{formatCurrency0dp(property.currentValue)}</>
        } else {
            return <></>
        }
    }


    const tenure = (property: Property) => {
        if (property.saleDate) {
            return <Badge pill variant='warning'>Sold</Badge>
        }
        else if (property.isInactive) {
            return <Badge pill variant='warning'>Archived</Badge>
        }
        else {
            if (property.tenure)
                return <>{formatTenure(property.tenure)}</>
            
            return null;
        }
    }

    const LoadingCard = () => {
        return (
            <Card className={styling.propertyCard} >
                <Card.Img variant='top' src={placeholderImageStyle} className={styling.propertyCardImage} />
                <Card.Body>
                    <Card.Title className={styling.cardTitle}>
                        <LoadingPlaceholder width='50%' />
                    </Card.Title>
                    <Card.Text>
                        <LoadingPlaceholder width='30%' />
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

    return (
        <Row id="propertyCardRow" className={classNames({"mt-4": v8Styling})}>
            {!loaded &&
                <>
                    { v8Styling ?
                    <>
                        <Col xs={12} md={6} lg={4}><PropertyCardLoading/></Col>
                        <Col xs={12} md={6} lg={4}><PropertyCardLoading/></Col>
                        <Col xs={12} md={6} lg={4}><PropertyCardLoading/></Col>
                    </>
                    :
                    <>
                        <Col xs={12} md={6} lg={3}><LoadingCard /></Col>
                        <Col xs={12} md={6} lg={3}><LoadingCard /></Col>
                        <Col xs={12} md={6} lg={3}><LoadingCard /></Col>
                    </>
                    }
                </>
            }
            {loaded && 
                <>
                {_.sortBy(properties, 'purchaseDate' ).map(property => {
                    // External property images should be inserted into imageDocumetUrl to avoid missed use cases and less work - DD
                    let propertyImage = placeholderImageStyle;
                    if (property.imageDocumentUrl) {
                        propertyImage = property.imageDocumentUrl;
                    } else if (property.externalImageUrl) {
                        propertyImage = property.externalImageUrl;
                    }

                    return (
                        <Col key={property.id} xs={12} md={6} lg={v8Styling ? 4 : 3}>
                            { v8Styling
                                ? <PropertyCard property={property} imageUrl={propertyImage} />
                                : (
                                    // V7 property card (old)
                                    <Card style={{marginBottom: '20px'}} className={styling.propertyCard} >
                                        <Link to={getUrl(Entity.Property, Action.Read, {'id': property.id}).url}>
                                            {
                                                property.externalPropertyId != null && (
                                                    <ImageIconOverlay
                                                        size={30}
                                                        icon="Arthur/arthur-logo-small.png"
                                                        dark={propertyImage === placeholderImageStyle}
                                                    />
                                                )
                                            }
                                            <Card.Img variant='top' src={propertyImage} className={styling.propertyCardImage} />
                                        </Link>
                                        <Card.Body>
                                            <Card.Title className={styling.cardTitle}>
                                                <h5>
                                                    <Link to={getUrl(Entity.Property, Action.Read, {'id': property.id}).url}>
                                                        {propertyName(property)}
                                                    </Link>
                                                </h5>
                                            </Card.Title>
                                            <Card.Text>
                                                <h4>
                                                    <div className={styling.badges}> 
                                                        <LettableUnitsBadges units={property?.lettableUnits} />
                                                    </div>
                                                </h4>
                                                { tenure(property) !== null && (
                                                    <div>
                                                        <small>Tenure:</small>
                                                        <br />
                                                        <span style={{fontWeight: 600}}>{tenure(property)}</span>
                                                    </div>
                                                )}
                                                {(property.salePrice || property.currentValue) && (
                                                    <div>
                                                        <small>Current value:</small>
                                                        <span style={{fontWeight: 600}}>{propertyValue(property)}</span>
                                                    </div>
                                                )}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                )
                            }
                        </Col>
                    );
                })}
            </>
            }
        </Row>
    )
}