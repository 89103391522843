import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { TenancyNote } from '../../../api/inni/data-contracts';
import { formatDate } from '../../../utils/formatDate';
import { CogOptionsDropdown } from '../../../elements/CogOptionsDropdown/CogOptionsDropdown';

import styles from '../TenancyNote.module.css';


interface TenancyNoteEntryProps {
  data: TenancyNote,
  showArchiveDialog: (id: number, archive: boolean, title: string) => void,
  showDeleteDialog: (id: number, title: string) => void,
  showEditModal: (data: TenancyNote | null) => void,
}

export const TenancyNoteEntry = ({data, showArchiveDialog, showDeleteDialog, showEditModal}: TenancyNoteEntryProps) => {
  return (
    <Card className="mb-3 shadow-sm" data-cy="tenancyNotesCard">
      <Card.Header className={`${styles.alignItemsBottom} flex-row justify-content-between`}>
        <div>
          <FontAwesomeIcon icon={data.archived ? faArchive: faFileAlt} className={styles.noteIcon} />
          <h5 className={`${styles.noteTitle} p-0 m-0 mr-3`}><b>{ (data.title !== '') ? data.title : 'Note' }</b></h5>
          <span className="text-muted d-inline-block" title={new Date(data.createdAt).toString().substring(0, 21)}>
            { formatDate(data.createdAt) }
          </span>
        </div>

        <CogOptionsDropdown
          edit={() => showEditModal(data)}
          del={() => showDeleteDialog(data.id, data.title || '')}
          archive={() => showArchiveDialog(data.id, !data.archived, data.title || '')}
          archived={data.archived}
        />
      </Card.Header>

      <Card.Body>
        <Card.Text style={{whiteSpace: 'pre-wrap'}}>
          { data.text }
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
