/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Asset, AssetAsDetailed, AssetCategories, AssetChangetoExpense, AssetPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Assets<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Assets
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/assets
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Asset[], any>({
      path: `/api/v2/companies/${companyId}/assets`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/assets
   * @secure
   */
  create = (companyId: number, postModel: AssetPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/assets`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name GetAsset
   * @request GET:/api/v2/companies/{companyId}/assets/{assetId}
   * @secure
   */
  getAsset = (companyId: number, assetId: number, params: RequestParams = {}) =>
    this.request<AssetAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/assets/${assetId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/assets/{assetId}
   * @secure
   */
  update = (companyId: number, assetId: number, postModel: AssetPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/assets/${assetId}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/assets/{assetId}
   * @secure
   */
  delete = (companyId: number, assetId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/assets/${assetId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name GetAssetCategories
   * @request GET:/api/v2/companies/{companyId}/assets/assetcategories
   * @secure
   */
  getAssetCategories = (companyId: number, params: RequestParams = {}) =>
    this.request<AssetCategories[], any>({
      path: `/api/v2/companies/${companyId}/assets/assetcategories`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name New
   * @request GET:/api/v2/companies/{companyId}/assets/new
   * @secure
   */
  new = (companyId: number, params: RequestParams = {}) =>
    this.request<AssetAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/assets/new`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/assets/Validate
   * @secure
   */
  validateCreate = (companyId: number, postModel: AssetPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/assets/Validate`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/assets/{assetId}/Validate
   * @secure
   */
  validateUpdate = (companyId: number, assetId: number, postModel: AssetPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/assets/${assetId}/Validate`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name UpdateAssetToExpense
   * @request PUT:/api/v2/companies/{companyId}/assets/UpdateAssetToExpense/{assetId}
   * @secure
   */
  updateAssetToExpense = (
    companyId: number,
    assetId: number,
    postModel: AssetChangetoExpense,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/assets/UpdateAssetToExpense/${assetId}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Assets
   * @name DisposeAssetAtZero
   * @request PUT:/api/v2/companies/{companyId}/assets/DisposeAssetAtZero/{assetId}
   * @secure
   */
  disposeAssetAtZero = (companyId: number, assetId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/assets/DisposeAssetAtZero/${assetId}`,
      method: "PUT",
      secure: true,
      ...params,
    });
}
