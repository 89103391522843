import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { FileRejection } from "react-dropzone";
import { useParams } from "react-router-dom";
import CompanyContext from "../../context/CompanyContext";
import { Button } from "../../elements/Button/Button";
import { Table } from "../../elements/DataGrid/DataGrid";
import { useInniAPI } from "../../hooks/useInniAPI";
import { Action, Entity } from "../../utils/EntityAction";
import styles from './completionStatement.module.css'
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Formik, FieldArray, Form, FormikHelpers, Field, FieldProps } from 'formik';
import { Dropdown, DropdownButton, InputGroup } from "react-bootstrap";
import { Form as ReactForm } from 'react-bootstrap';
import { asHtml5Date } from "../../utils/formatDate";
import classNames from "classnames";
import { Properties } from "../../api/inni/Properties";
import { CSLineTypeOption, CompletionStatementLinePostModel, CompletionStatementPostModel, Property } from "../../api/inni/data-contracts";
import { formatCurrency } from "../../utils/formatNumbers";
import { CompletionStatements } from "../../api/inni/CompletionStatements";
import { asYearMonthDay } from "../../utils/formatDate";
import Select from 'react-select';
import Decimal from "decimal.js";
import { useModalDialog } from "../../hooks/useModalDialog";
import { useNavigateToEntity } from "../../hooks/useNavigateToEntity";
import { useHistoryWrapper } from "../../hooks/useHistoryWrapper";
import SimpleFullscreen from '../../components/Fullscreen/SimpleFullscreen';
import Icon from '../../elements/Icon/Icon';
import Attachments from '../../components/Attachments/Attachments';
import Alert from "../../elements/Alert/Alert";
import { CogOptionsDropdown } from "../../elements/CogOptionsDropdown/CogOptionsDropdown";


interface RouteParams {
    propertyId: string
    statementType?: string
    completionStatementId: string
}

interface CSFormikWrapper {
    lines: CompletionStatementLinePostModel[];
}

const CreateCompletionStatement = () => {
    const companyContext = useContext(CompanyContext)
    const propertyAPI = useInniAPI(Properties)
    const completionStatementApi = useInniAPI(CompletionStatements, [400])
    const propertyId = parseInt(useParams<RouteParams>().propertyId);
    const replaceCompletionStatementId= parseInt(useParams<RouteParams>().completionStatementId)
    const statementType = useParams<RouteParams>().statementType;

    const [currentStage, setCurrentStage] = useState(0);

    const [uploadingStatementFile, setUploadingStatementFile] = useState(false)
    const [uploadStatementFileFailed, setUploadStatementFileFailed] = useState(false)
    const [statementFileUploaded, setStatementFileUploaded] = useState(false)
    const [fileAlreadyUploaded, setFileAlreadyUploaded] = useState(false)
    const [uploadedFilename, setUploadedFilename] = useState('')

    const [uploadStatementErrors, setUploadStatementErrors] = useState<string[]>([])

    const [property, setProperty] = useState<Property>()
    const [lineTypes, setLineTypes] = useState<CSLineTypeOption[]>();

    const initialLine:CompletionStatementLinePostModel = {description: '', amount: 0, lineTypeId: 0}

    const [completionStatementDate, setCompletionStatementDate] = useState('')
    const [completionStatementType, setCompletionStatementType] = useState<"PropertyPurchase" | "PropertySale" | "Remortgage">("PropertyPurchase")
    const [completionStatementSuccessful, setCompletionStatementSuccessful] = useState(true)

    const [initialValuesSet, setInitialValuesSet] = useState(false)

    const history = useHistoryWrapper();
    const navToEntity = useNavigateToEntity()

    const uploadCompletionStatement = (
            values: {lines: CompletionStatementLinePostModel[]}, 
            formikHelpers: FormikHelpers<{lines: CompletionStatementLinePostModel[]}>
        ) => {

        let postModel:CompletionStatementPostModel = {
            propertyId: propertyId,
            completionDate: completionStatementDate ? asYearMonthDay(new Date(completionStatementDate)) : "",
            statementType: completionStatementType,
            transactionWasSuccessful: completionStatementSuccessful,
            completionStatementLines: values.lines
        }

        postModel.completionStatementLines && postModel.completionStatementLines.forEach(line => {
            if(!line.description) line.description = getLineTypeName(line.lineTypeId)
        })

        if (completionStatementApi) {
            if (replaceCompletionStatementId) {
                completionStatementApi.replace(companyContext.cid, {completionStatementId: replaceCompletionStatementId}, postModel).then((res) => {
                    if(property && (property.completionStatementDocumentId || property.saleCompletionStatementDocumentId)) {
                        navToEntity(Entity.Property, Action.Read, {id: property.id, defaultTab: 'purchaseandsale'})
                    }
                    else {
                        history.goBack()
                    }
                }).catch((err) => {
                    if(err && err.error && err.status) {
                        if(err.status === 400) {
                            const errorMessages = Object.entries(err.error).map(([key, val]) => String(val))
                            setUploadStatementErrors(errorMessages)
                        } else {
                            setUploadStatementErrors(["Sorry, something went wrong"])
                        }
                    }
                }).finally(() => {
                    setUploadingStatementFile(false)
                })
            } else {
                completionStatementApi.createCompletionStatement(companyContext.cid, postModel).then((res) => {
                    if(property && (property.completionStatementDocumentId || property.saleCompletionStatementDocumentId)) {
                        navToEntity(Entity.Property, Action.Read, {id: property.id, defaultTab: 'purchaseandsale'})
                    }
                    else {
                        history.goBack()
                    }
                }).catch((err) => {
                    if(err && err.error && err.status) {
                        if(err.status === 400) {
                            const errorMessages = Object.entries(err.error).map(([key, val]) => String(val))
                            setUploadStatementErrors(errorMessages)
                        } else {
                            setUploadStatementErrors(["Sorry, something went wrong"])
                        }
                    }
                }).finally(() => {
                    setUploadingStatementFile(false)
                })
            }
        }
    }

    useEffect(() => {
        if(propertyAPI) {
            propertyAPI?.get(companyContext.cid, propertyId).then((res) => {
                setProperty(res.data)
            })
        }
    }, [propertyAPI, companyContext.cid, propertyId])

    useEffect(() => {
        if(property && completionStatementDate === '' && !initialValuesSet) {
            let csType = completionStatementType //Avoid potential race condition
            if(statementType && ["PropertyPurchase", "PropertySale", "Remortgage"].includes(statementType)) {
                setCompletionStatementType(statementType as any)
                csType = statementType as any
            }
            switch(csType) {
                case "PropertyPurchase":
                    property.purchaseCompletionDate && setCompletionStatementDate(asYearMonthDay(new Date(property.purchaseCompletionDate)))
                    break;
                case "PropertySale":
                    property.saleCompletionDate && setCompletionStatementDate(asYearMonthDay(new Date(property.saleCompletionDate)))
                    break;
                case "Remortgage":
                    console.warn("Remortgage not yet implemented")
                    break;
            }
            setInitialValuesSet(true)
        }
    }, [completionStatementDate, completionStatementType, initialValuesSet, property, statementType])

    useEffect(() => {
        if(property && completionStatementType) {
            if(completionStatementType === "PropertyPurchase" && property.completionStatementDocumentId) {
                setFileAlreadyUploaded(true)
            }
            else if(completionStatementType === "PropertySale" && property.saleCompletionStatementDocumentId) {
                setFileAlreadyUploaded(true)
            }
            else {
                setFileAlreadyUploaded(false)
            }
        }
    }, [completionStatementType, property])

    useEffect(() => {
		if(completionStatementApi && lineTypes === undefined) {
			completionStatementApi.getLineTypeOptions(companyContext.cid).then(res => {
				setLineTypes(res.data);
			})
		}
	}, [completionStatementApi, companyContext.cid, lineTypes]);

    const completionTypeName = () => {
        switch(completionStatementType) {
            case "PropertyPurchase":
                return "Purchase"
            case "PropertySale":
                return "Sale"
            case "Remortgage":
                return "Remortgage"
            default:
                return ""
        }
    }

    const uploadCompletionStatementFile = (acceptedFiles:File[], fileRejections:FileRejection[]) => {
        if(acceptedFiles.length > 0) {
            setUploadingStatementFile(true)
            setUploadStatementFileFailed(false)
            setStatementFileUploaded(false)
            if(propertyAPI && completionStatementType) {
                if(completionStatementType === "PropertyPurchase") {
                    propertyAPI.uploadCompletionStatement(companyContext.cid, propertyId, { fileToUpload: acceptedFiles[0] }).then((res) => {
                        setStatementFileUploaded(true)
                        setUploadedFilename(acceptedFiles[0].name)
                    }).catch((err) => {
                        setUploadStatementFileFailed(true)
                    }).finally(() => {
                        setUploadingStatementFile(false)
                    })
                }
                else if(completionStatementType === "PropertySale") {
                    propertyAPI.uploadSaleCompletionStatement(companyContext.cid, propertyId, { fileToUpload: acceptedFiles[0] }).then((res) => {
                        setStatementFileUploaded(true)
                    }).catch((err) => {
                        setUploadStatementFileFailed(true)
                    }).finally(() => {
                        setUploadingStatementFile(false)
                    })
                }
                else if(completionStatementType === "Remortgage") {
                    console.error("Remortgage upload not yet implemented")
                    setUploadStatementFileFailed(true)
                }
            }
        }
        else {
            console.warn("File was of an invalid type, you tried to upload:")
            console.warn(fileRejections)
        }
    }

    const isPositiveLine = (line:CompletionStatementLinePostModel) => {
        if(lineTypes) {
            const lineType = lineTypes.find(x => x.id === line.lineTypeId)
            if(lineType) {
                return !lineType.isWithdrawal
            }
            return true
        }
        return true
    }

    const getCreditDebitAmount = (line:CompletionStatementLinePostModel) => {
        if(isNaN(line.amount) || line.amount.toString() === '') return 0
        return isPositiveLine(line) ? line.amount : -line.amount
    }

    const lineTotal = (lines:CompletionStatementLinePostModel[]) => {
        return lines.reduce((prev, current) => {
            return Decimal.add(new Decimal(prev), new Decimal(getCreditDebitAmount(current))).toNumber()
        }, 0)
    }

    const getLineTypeName = (id: number) => {
        if(lineTypes) {
            const lineType = lineTypes.find(x => x.id === id)
            if(lineType) return lineType.name || ""
            return ""
        }
        return ""
    }

    const stages = [
        {
            key: 'intro',
            title: 'Categorising your completion statement',
            subtitle: ''
        },
        {
            key: 'date',
            title: 'Statement date',
            subtitle: 'Before we begin, please confirm the date on your completion statement'
        },
        {
            key: 'successful',
            title: `Was the property ${completionTypeName().toLowerCase()} successful?`,
            subtitle: 'Did the ownership of the property successfully transfer?'
        },
        {
            key: 'categorise',
            title: 'Categorise your transactions',
            subtitle: `Enter each transaction listed on your completion statement and assign the appropriate category.

                The total of all credits and debits on a completion statement should always balance to zero.
                If it does not, please review your entries carefully.`
        },
        {
            key: 'upload',
            title: 'Upload your statement',
            subtitle: 'Finally, to complete your records, please upload your completion statement'
        }
    ]

    const nextStage = () => {
        setCurrentStage(prevState => prevState + 1)
    }

    const prevStage = () => {
        setCurrentStage(prevState => prevState - 1)
    }

    return (
        <SimpleFullscreen
            isOpen={true}
            onBack={currentStage > 0 ? prevStage : undefined}
            onClose={() => navToEntity(Entity.Property, Action.Read, {id: property?.id || 0, defaultTab: 'purchaseandsale'})}
            title={stages[currentStage].title}
            subTitle={stages[currentStage].subtitle}
        >
            <Formik<CSFormikWrapper>
                onSubmit={uploadCompletionStatement}
                initialValues={{lines: []}}
                enableReinitialize
                validateOnChange
                validate={() => {}}
            >
                {({values, handleSubmit, errors, touched}) => {
                    return (
                        <Form className={styles.reducedInputPadding}>

                            {/* INTRO */}
                            { stages[currentStage].key === 'intro' && (
                                <div>
                                    When buying or selling a property, you will receive a 'completion statement' that outlines the credits and debits associated with the sale.
                                    <br />
                                    Please have this statement ready in front of you as we categorise each entry. Correctly categorising these entries is essential for accurate bookkeeping.
                                    <br />
                                    <br />
                                    Note that the credits and debits on a completion statement are typically represented from the perspective of the conveyancer.
                                    <br />
                                    <br />
                                    If applicable, we will also use this information to set up your mortgage within the app.
                                    <br />
                                    <br />
                                    If you are ready, let's continue!
                                    <br />
                                    <Button
                                        thin
                                        marginTop
                                        variant='primary'
                                        onClick={nextStage}
                                    >
                                        Let's go <Icon className="m-0" name='arrowNext'/>
                                    </Button>
                                </div>
                            )}

                            {/* STATEMENT DATE */}
                            { stages[currentStage].key === 'date' && (
                                <div>
                                    <label className="text-muted" style={{width: '200px'}}>
                                        Completion statement date
                                    </label>
                                    <ReactForm.Control
                                        type={'date'}
                                        value={completionStatementDate}
                                        onChange={(e) => setCompletionStatementDate(e.target.value)}
                                        style={{maxWidth: '200px'}}
                                    />
                                    <Button
                                        thin
                                        marginTop
                                        variant='primary'
                                        onClick={nextStage}
                                    >
                                        Next <Icon className="m-0" name='arrowNext'/>
                                    </Button>
                                </div>
                            )}

                            {/* SUCCESSFUL */}
                            { stages[currentStage].key === 'successful' && (
                                <div>
                                    <label className="text-muted" style={{width:'200px'}}>
                                        The {completionTypeName().toLowerCase()} was
                                    </label>

                                    <div style={{width: '200px'}}>
                                        <Select 
                                            classNamePrefix='selectList'
                                            options={[
                                                {value: true, label: "Successful"},
                                                {value: false, label: "Failed"}
                                            ]}
                                            onChange={(selectedOption: {value: string; label: string;} | null) => {
                                                setCompletionStatementSuccessful(selectedOption?.value as any)
                                            }}
                                            value={completionStatementSuccessful ? {value: true, label: "Successful"} as any : {value: false, label: "Failed"}}
                                        />
                                    </div>

                                    <Button
                                        thin
                                        marginTop
                                        variant='primary'
                                        onClick={nextStage}
                                    >
                                        Next <Icon className="m-0" name='arrowNext'/>
                                    </Button>
                                </div>
                            )}

                            {/* CATEGORISE */}
                            { stages[currentStage].key === 'categorise' && (
                                <div className="mt-5">
                                    <FieldArray name="lines">
                                        {({remove, push}) => (
                                            <>
                                                <Table noHover>
                                                    <thead>
                                                        <tr>
                                                            <th style={{width: '300px'}}>Type</th>
                                                            <th>Description <span className="text-muted"><i>(optional)</i></span></th>
                                                            <th style={{width: '200px'}}>Amount</th>
                                                            <th style={{width: '75px'}}></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className={styles.csLineTableBody}>
                                                        {values.lines.map((line, index) => (
                                                            <tr
                                                                key={index}
                                                                className={classNames({[styles.noBottomCellBorder]: index + 1 === values.lines.length})}
                                                            >
                                                                <td style={{width: '220px'}}>
                                                                    {getLineTypeName(line.lineTypeId)}
                                                                </td>
                                                                <td>
                                                                    <Field name={`lines[${index}].description` as 'description'}>
                                                                        {({field}: FieldProps) => (
                                                                            <ReactForm.Control 
                                                                                type={'text'} 
                                                                                isInvalid={touched.lines && touched.lines[index] && errors.lines !== undefined && errors.lines[index] !== ""}
                                                                                {...({...field, value: asHtml5Date(field.value)})} 
                                                                                placeholder={lineTypes ? lineTypes.find(x => x.id === line.lineTypeId)?.name : ''}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </td>
                                                                <td className={styles.amountCell}>
                                                                    <Field name={`lines[${index}].amount` as 'amount'}>
                                                                        {({field}: FieldProps) => (
                                                                            <InputGroup>
                                                                                <InputGroup.Prepend>
                                                                                    <InputGroup.Text>{isPositiveLine(line) ? <>&#43;</> : <>&#8722;</>}</InputGroup.Text>
                                                                                </InputGroup.Prepend>
                                                                                <ReactForm.Control type={'number'} {...field} min={0}/>
                                                                            </InputGroup>
                                                                        )}
                                                                    </Field>
                                                                </td>
                                                                <td style={{textAlign: 'right'}}>
                                                                    <CogOptionsDropdown hamburger del={() => remove(index)} />
                                                                </td>
                                                            </tr>
                                                        ))}

                                                        {values.lines.length === 0 && (
                                                            <tr>
                                                                <td colSpan={4} style={{height: '60px'}} className="text-center">
                                                                    { (!fileAlreadyUploaded && !statementFileUploaded)
                                                                        ? "Please upload your file first"
                                                                        : "Please add a line below to begin"
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </Table>

                                                <div className="w-100">
                                                    {/* Dropdown category selector */}
                                                    <div className="w-50 d-inline-block align-top">
                                                        <DropdownButton
                                                            title={<><FontAwesomeIcon icon={faPlus} /> Add line</>}
                                                            // disabled={!fileAlreadyUploaded && !statementFileUploaded}
                                                            onSelect={(eventKey) => push({...initialLine, lineTypeId: parseInt(eventKey||'0')})}
                                                            variant="secondary"
                                                            size="sm"
                                                            drop="right"
                                                        >
                                                            <div className={styles.dropDown}>
                                                                <Dropdown.ItemText>
                                                                    <b>Transferred to solicitor</b>
                                                                </Dropdown.ItemText>
                                                                <Dropdown.Divider />
                                                                {lineTypes && lineTypes.filter(x => !x.isWithdrawal).map((item) => {
                                                                    return (
                                                                        <Dropdown.Item key={item.id} eventKey={item.id}>
                                                                            {item.name}
                                                                        </Dropdown.Item>
                                                                    );
                                                                })}
                                                                <Dropdown.ItemText className="mt-3">
                                                                    <b>Expenses</b>
                                                                </Dropdown.ItemText>
                                                                <Dropdown.Divider />
                                                                {lineTypes && lineTypes.filter(x => x.isWithdrawal && x.id !== 22 && x.id !== 23).map((item) => {
                                                                    return (
                                                                        <Dropdown.Item key={item.id} eventKey={item.id}>
                                                                            {item.name}
                                                                        </Dropdown.Item>
                                                                    );
                                                                })}
                                                                <Dropdown.ItemText className="mt-3">
                                                                    <b> Transferred to you</b>
                                                                </Dropdown.ItemText>
                                                                <Dropdown.Divider />
                                                                {lineTypes && lineTypes.filter(x => x.id === 22 || x.id === 23).map((item) => {
                                                                    return (
                                                                        <Dropdown.Item key={item.id} eventKey={item.id}>
                                                                            {item.name}
                                                                        </Dropdown.Item>
                                                                    );
                                                                })}
                                                            </div>
                                                        </DropdownButton>
                                                    </div>

                                                    {/* Sum of all lines */}
                                                    <div className="w-50 d-inline-block text-right">
                                                        <h5 style={{border: 'none', margin: 0}}>
                                                            <b>Sum of all lines: {formatCurrency(lineTotal(values.lines))}</b>
                                                        </h5>
                                                        <span className="text-muted">(This should always equal £0)</span>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </FieldArray>

                                    <Button
                                        thin
                                        marginTop
                                        variant='primary'
                                        onClick={nextStage}
                                        disabled={lineTotal(values.lines) !== 0 || values.lines.length < 2}
                                    >
                                        Next <Icon className="m-0" name='arrowNext'/>
                                    </Button>
                                </div>
                            )}

                            {/* UPLOAD */}
                            { stages[currentStage].key === 'upload' && (
                                <div>
                                    <Attachments
                                        accept={['image/*','.pdf','.doc','.docx']}
                                        onDrop={uploadCompletionStatementFile} 
                                        title='Add attachment'
                                        message={fileAlreadyUploaded
                                                    ? "Completion statement is already uploaded. You can replace it by uploading a new file here."
                                                    : "Upload your completion statement finish"
                                                }
                                        singleSelect
                                        optional = {fileAlreadyUploaded}
                                    />

                                    { uploadingStatementFile && (
                                        <Alert
                                            alertMessage={`One moment, uploading file`}
                                            variant="info"
                                            inline
                                        />
                                    )}

                                    { statementFileUploaded && (
                                        <Alert
                                            alertMessage={`File ${uploadedFilename} successfully uploaded`}
                                            variant="info"
                                            inline
                                        />
                                    )}

                                    { uploadStatementFileFailed && (
                                        <Alert
                                            alertMessage="Sorry, your file failed to upload. Please try again"
                                            variant="warning"
                                            inline
                                        />
                                    )}

                                    { uploadStatementErrors.length > 0 && (
                                        <>
                                            { uploadStatementErrors.map((error, index) => (
                                                <Alert
                                                    key={`error${index}`}
                                                    alertMessage={error}
                                                    variant="warning"
                                                    inline
                                                />
                                            ))}
                                        </>
                                    )}

                                    {/* FIN */}
                                    <Button
                                        variant="change" 
                                        onClick={handleSubmit} 
                                        disabled={!(fileAlreadyUploaded || statementFileUploaded)}
                                        marginTop
                                    >
                                        Save details
                                    </Button>
                                </div>
                            )}
                        </Form>
                    )
                }}
            </Formik>

        </SimpleFullscreen>
    )
}

export default CreateCompletionStatement
