import styles from '../OnBoarding.module.css';
import StageNumber from '../Elements/stageNumber';
import StageDetails from '../Elements/StageDetails';
import { Button } from '../../../elements/Button/Button';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { useHistoryWrapper } from '../../../hooks/useHistoryWrapper';


export interface stagesProps{
    number: number
    title: string
    info: string
    buttonText: string
    completedButtonText: string
    linkText?:string
    link?:string
    status:boolean
    active?:boolean
    buttonRedirect:string
    completedButtonRedirect:string
    buttonDisabled: boolean
    disabledMessage: string

}

const Stages=({number,title,info,buttonText,completedButtonText, link,linkText,status,active,buttonRedirect,completedButtonRedirect, buttonDisabled, disabledMessage}:stagesProps)=>{
    const history = useHistoryWrapper();

    const handleButtonClick = () => {
        history.push(status ? completedButtonRedirect : buttonRedirect);
    };

    return(

       <div className={active ? `${styles.stages} ${styles.Active}` : styles.stages}> 
            <div data-cy="stages" className={styles.stagesOuter}>
                <div className={styles.stagesInner}>
                    <StageNumber stageNumber={number} active={active} completed={status}/>
                    <StageDetails title={title} info={info} status={status} linkText={linkText} link={link}/>
                </div>
    
                { buttonText && (
                    <Button 
                        label={status ? completedButtonText : buttonText} 
                        variant= {active? 'primary' : status ? 'dark' : 'secondary'} 
                        onClick={handleButtonClick}
                        className={styles.button}
                        disabled={buttonDisabled}
                        disabledMessage={disabledMessage}
                        outline = {status}
                    />
                )}
            </div>
        </div>
    );
}

export default Stages;