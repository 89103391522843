import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import CompanyInfomation from "../../features/CompanyInfomation/CompanySettings";
import CompanyInformationMenu from "../../features/CompanyInfomation/CompanyInformationMenu"

const companyInformation:Array<EntityRoute> = [

    {
        path: '/company/:cid/companyinfo/:activePage?',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.CompanySettingsV7,
        component: CompanyInformationMenu
    },
    {
        path: '/company/:cid/settings/edit',
        exact: true,
        context: RouteContext.Company,
        action:Action.Edit,
        entity: Entity.CompanySettingsV7,
        component: CompanyInfomation
    },


  
]

export default companyInformation;
