import { useCallback, useContext, useEffect, useState } from 'react';
import CompanyContext from '../../../context/CompanyContext';
import { useInniAPI } from '../../../hooks/useInniAPI';
import ExpenseAccountSelector from './ExpenseAccountSelector'
import { BankTransaction, DatePicker, Account } from '../../../api/inni/data-contracts';
import { Accounts } from '../../../api/inni/Accounts';
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList';
import { BankTransactions } from '../../../api/inni/BankTransactions';
import { AccountViewerNewWrapper } from '../../../components/AccountViewerNew/AccountViewerNewWrapper';
import { AccountViewerFeatureSet } from '../../../components/AccountViewerNew/AccountViewerNew';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';

const Expenses = ({employeeName, employeeId} : {employeeName? : string, employeeId? : number}) => {
    
    const transactionApi = useInniAPI(BankTransactions);
    const companyContext = useContext(CompanyContext)
    
    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts);

    const [curAccount, setCurAccount] = useState<number>(-1)

    useEffect(() => {
        if (accountsLoaded && accounts) {
            setCurAccount(accounts.find(x => x.personId === employeeId && x.isExpenseAccount)?.id || -1) // we don't want to make an api call if we dont have an account id yet...
        }
    }, [accounts, accountsLoaded, employeeId])

    const getFilter = useCallback((yearCode: string|undefined) :  Promise<DatePicker> => {
        if (curAccount === -1) { return new Promise<DatePicker>((resolve, reject) => reject()) }
        const p = new Promise<DatePicker>((resolve, reject) => {
            transactionApi?.filter(companyContext.cid, { accountId: curAccount, yearCode: yearCode})
                .then(results => {
                    if (results.data.datePicker) {
                        resolve(results.data.datePicker)
                    } else reject();                    
                }).catch(error => reject(error))
        })
        return p;
    }, [companyContext.cid, curAccount, transactionApi])

    const getTransactions = useCallback((yearCode: string) :  Promise<BankTransaction[]> => {
        const p = new Promise<BankTransaction[]>((resolve, reject) => {
            transactionApi?.index(companyContext.cid, {yearCode: yearCode, accountId: curAccount})
                .then(results => {
                    if (results.data) {
                        resolve(results.data)
                    } else reject();                    
                }).catch(error => reject(error))
        })
        return p;
    }, [companyContext.cid, curAccount, transactionApi])

    return (
        <div style={{marginTop: '20px'}}>
            <h4 className="mb-3">{'Personally incurred expenses for ' + employeeName || ''}</h4>
            {
                <>
                    { accountsLoaded && curAccount !== -1
                        ? (
                            <>
                                <div style={{display: 'inline-block', marginRight: '7px'}}>
                                    <ExpenseAccountSelector setAccount={setCurAccount} employeeId={employeeId} />
                                </div>
                                <AccountViewerNewWrapper
                                    getFilter={getFilter}
                                    getTransactions={getTransactions}
                                    bankAccountId={curAccount}
                                    featureSet={AccountViewerFeatureSet.QUICKENTRY}
                                />
                            </>
                        )
                        : (
                            <>
                                <div className="mb-2">
                                    <span className='mr-2'><LoadingPlaceholder width='275px' height='30px' /></span>
                                    <span><LoadingPlaceholder width='275px' height='30px' /></span>
                                </div>
                                <DataGrid.Table noHover>
                                    <tbody>
                                        <DataGrid.LoadingRow cols={7} />
                                        <DataGrid.LoadingRow cols={7} />
                                        <DataGrid.LoadingRow cols={7} />
                                    </tbody>
                                </DataGrid.Table>
                            </>
                        )
                    }
                </>
            }
        </div>
    )   
}

export default Expenses