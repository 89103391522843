import classNames from "classnames";
import { BankTransaction } from "../../../api/inni/data-contracts";
import getCurrencySymbol from "../../../utils/currencySymbols";
import { Modal } from "../../Modal/Modal";
import styles from "./EditorModal.module.css"
import editRowStyles from '../../../elements/EditRow/EditRow.module.css'
import { Form, InputGroup } from "react-bootstrap";
import { asHtml5Date } from "../../../utils/formatDate";

interface ReadOnlyTransactionProps {
    closeEditor: () => void,
    showModal: boolean,
    bankTransaction?: BankTransaction,
    currency?: string
}

export const ReadOnlyTransactionModal = ({closeEditor, showModal, bankTransaction, currency} : ReadOnlyTransactionProps) => {
    return <Modal size="xl" restoreFocus={false} showModal={showModal} hideModal={closeEditor} title={"View transaction"} showCloseBtn>
        <div className={styles.editorRow}>
            <div data-cy="editorField" className={styles.editorField}>
                <label>Date</label>
                <InputGroup className={classNames(editRowStyles.inputGroup, editRowStyles.editRow)} style={{marginBottom: '0'}}>
                    {bankTransaction && <Form.Control 
                        value={asHtml5Date(bankTransaction.date)}
                        disabled
                        type="date"
                    />}
                </InputGroup>
            </div>

            <div data-cy="editorField" className={styles.editorField}>
                <label>Amount</label>
                <InputGroup className={classNames(editRowStyles.inputGroup, editRowStyles.editRow)} style={{marginBottom: '0'}}>
                    <InputGroup.Prepend><InputGroup.Text>{getCurrencySymbol(currency||"")}</InputGroup.Text></InputGroup.Prepend>
                    {bankTransaction && <Form.Control 
                        value={bankTransaction.amount}
                        disabled
                        type="number"
                    />}
                </InputGroup>
            </div>
        </div>

        <div className={classNames(styles.editorRow, styles.description)}>
            <div data-cy="editorField" className={styles.editorField}>
                <label>Description</label>
                <InputGroup className={classNames(editRowStyles.inputGroup, editRowStyles.editRow)} style={{marginBottom: '0'}}>
                    {bankTransaction && <Form.Control 
                        value={asHtml5Date(bankTransaction.description)}
                        disabled
                        type="text"
                    />}
                </InputGroup>
            </div>
        </div>
    </Modal>
}