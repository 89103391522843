import React, { useEffect, useState } from 'react';
import { AccountInfo, InteractionRequiredAuthError } from '@azure/msal-browser';
import { UserWrapper } from '../UserWrapper/UserWrapper';
import {msalConfig, msalInstance} from './msal'
import { useLocation, useRouteMatch } from 'react-router-dom';
import { LoadingFullPage } from '../../elements/LoadingFullPage/LoadingFullPage';
import LogRocket from 'logrocket';

type AuthenticatedUserWrapperProps = {
    children?: React.ReactNode
    hasAMSupport?: boolean
}

type InniClaimsB2C = {
    emails?: Array<string>,
    given_name?: string,
    family_name?: string,
    name?: string,
    oid?: string
}

type InniClaimsAD = {
    email?: string,
    given_name?: string,
    family_name?: string,
    name?: string,
    oid?: string
}

// ir35test.longaccess@test.com
// PAssword1!

/**
 * A wrapper around user authentication
 * 
 * tl;dr; It will only render children once we've logged in and 
 * have successfully cached an access token
 * 
 * When mounted, it will:
 *  * Try and get an access token, to check if we still have a session
 *  * Try and login sliently using a hint (oid) passed from another app
 *  * Fall back to logging in via a redirect
 * 
 * The return call from AD auth goes to the /login URL, and the LoginCallback
 * component, which handles redirection back to the original URL
 * 
 * 
 */
export const AuthenticatedUserWrapper = ({children, hasAMSupport}: AuthenticatedUserWrapperProps) => {
    let { pathname } = useLocation();
    const [accounts, setAccounts] = useState<AccountInfo[]>([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [canGetAccessToken, setCanGetAccessToken] = useState(false);

    // Login:
    useEffect(() => {
 
        const login = async () => {
            let newAccounts = msalInstance.getAllAccounts();
            setAccounts(newAccounts);

            if (newAccounts.length > 0) {
                setIsLoggedIn(true);
            } else {
                /*
                WORKS FOR B2C, but not AD:
                
                let loginRequest = {
                    scopes: msalConfig.scopes,
                    // TODO get this from session and check if it exsits
                    loginHint: 'a5d62d47-2c2d-4e14-89f4-8cb2bf7b44b5'
                };
                try {
                    await msalInstance.ssoSilent(loginRequest);
                    newAccounts = msalInstance.getAllAccounts();
                    setAccounts(newAccounts);
                    setIsLoggedIn(true);
                } catch (err) {
                    if (err instanceof InteractionRequiredAuthError) {
                        try {
                            localStorage.setItem('loginRedirectPath', pathname);
                            await msalInstance.loginRedirect({...loginRequest, loginHint: undefined});
                        } catch (err) {
                            // handle error
                        }   
                    } else {
                        // handle error
                    }
                }
                */
                try {
                    let loginRequest = {
                        scopes: msalConfig.scopes
                    };
                    localStorage.setItem('loginRedirectPath', pathname);
                    await msalInstance.loginRedirect(loginRequest);
                    } catch (err) {
                    }   
                }          
        }

        // Always login when this mounts
        // We do this as we're making the assumption that this component is mounted
        // infrequently, and has a long lifecycle

        if (!isLoggedIn) {
            login();            
        }
        

    }, [isLoggedIn, pathname]);


    // Once we're logged in, attempt to get and access token
    useEffect(() => {
        if (isLoggedIn && !canGetAccessToken) {
            getAccessToken().then(token => {
                if (token && token[0]) setCanGetAccessToken(true);
            });
        }
    }, [isLoggedIn, canGetAccessToken])


    /**
     * Called frequently by child components / APIs
     * Don't update state or do anything that will trigger a re-render
     */
    const getAccessToken = async (): Promise<[string, Date | null]> => {

        return new Promise((resolve, reject) => {
            let accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) reject(new Error("No accounts"));
            
            var request = {
                scopes: msalConfig.scopes,
                account: accounts[0] as AccountInfo
            };
            
            msalInstance.acquireTokenSilent(request).then(tokenResponse => {
                if (tokenResponse.accessToken === '') {
                    console.error('Auth token is empty')
                    // TODO do we need to save URL for redirect?
                    msalInstance.acquireTokenRedirect(request);
                    reject();
                }

                resolve([tokenResponse.accessToken, tokenResponse.expiresOn]);
            }).catch(error => {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    // will go around the whole loop again
                    msalInstance.acquireTokenRedirect(request)
                    reject();
                }
            });

        })
    }

    const logout = async() : Promise<void> => {
        return msalInstance.logout();
    }

 
    const buildUserDetailsForWapper = () => {
        const userDetails = {
            firstName: '',
            lastName: '',
            email: '',
            fullName: '',
            oid: ''
        }

        if (isLoggedIn) {
            if (msalConfig.isB2C) {
                const claims = accounts[0].idTokenClaims as InniClaimsB2C;
                userDetails.firstName = claims.given_name || '';
                userDetails.lastName = claims.family_name || '';
                userDetails.fullName = claims.name || '';
                userDetails.email = (claims.emails ? claims.emails[0] : '')
                userDetails.oid = claims.oid || '';
            } else {
                const claims = accounts[0].idTokenClaims as InniClaimsAD;
                userDetails.firstName = claims.given_name || '';
                userDetails.lastName = claims.family_name || '';
                userDetails.fullName = claims.name || '';
                userDetails.email = claims.email || '';
                userDetails.oid = claims.oid || '';
            }

            if (process.env.NODE_ENV !== 'development') {
                LogRocket.identify(userDetails.email, {
                    name: userDetails.fullName,
                    email: userDetails.email
                });
            }
        }

        return userDetails;
    }

    const renderChildren = isLoggedIn && canGetAccessToken && accounts.length > 0;

    
    return (
        <>
            {renderChildren &&
                <UserWrapper 
                    {...buildUserDetailsForWapper()}
                    getAccessToken={getAccessToken}    
                    logout={logout}             
                    hasAMSupport={hasAMSupport}
                >
                    {children}
                </UserWrapper>            
            }
            {!renderChildren &&
                <LoadingFullPage entityName='user account' />
            }
            
        </>
    )
}
