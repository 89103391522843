export const breakStrByCase = (str: string, lower: boolean = false, separator: string = ' ') => {
  let converted = str.match(/[A-Z]?[a-z]+|[0-9]+|[A-Z]+(?![a-z])/g)?.join(separator) || str;

  if (lower)
    converted = converted.toLocaleLowerCase();

  return converted;
}

export const convertSentenceCase = (str: string) => {
  let converted = str.toLocaleLowerCase();
  converted = converted.charAt(0).toUpperCase() + converted.slice(1);

  return converted;  
}

export const lowercaseFirstChar = (str: string) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
}