import { useContext } from "react";
import { generatePath } from "react-router";
import routes from "../app/routes";
import CompanyContext from "../context/CompanyContext";
import UserContext from "../context/UserContext";
import { Action, Entity } from "../utils/EntityAction";

export interface AppUrl {
    url: string,
    external?: boolean   // Outside the react app
}

/**
 * Returns a function to resolve a link based on the entity/action/params
 * 
 * That function will return a li
 */
export function useGetUrl(): (entity: Entity, action?: Action, extraParams?: { [key: string]: string|number; }) => AppUrl {

    const companyContext = useContext(CompanyContext);
    const userContext = useContext(UserContext);

    return (entity: Entity, action?: Action, extraParams?: { [key: string]: string|number; }) => {

        const lookupAction = action || Action.List; // If we don't specify an action

        // Try and find an exact match
        let routeMatch = routes.find(r => r.entity === entity && r.action === lookupAction)

        // If there's no match, and we're looking for list,
        // search for an entity with no action
        // aka routes defined with no action default to list
        if (!routeMatch && lookupAction === Action.List) {
            routeMatch = routes.find(r => r.entity === entity && r.action === undefined)
        }

        if (!routeMatch) {
            console.error(`No route match for entity ${entity.toString()} and action ${action?.toString()}`)
            return {url: ''}
        }

        // Build parameters
        let params:{ [key: string]: string|number; } = {};
        if (companyContext.cid) params['cid'] = companyContext.cid;
        if (userContext.user) params['contactId'] = userContext.user.contactId;
        params = {...params, ...extraParams}

        // Resolve react routes
        if (routeMatch.path) {
            return {url: generatePath(routeMatch.path, params)}
        }

        if (routeMatch.externalUrl) {

            let prependDomain = ""

            //this is to cope with external routes in test environment that go to external websites such as https://ir35.inniaccounts.co.uk
            //rather than just changing the domain to mvc for test on all external sites

            if (routeMatch.externalUrl.startsWith("/")) { 
                prependDomain = window.appConfig.externalDomain.replace(/\/+$/, '')
            }

            return {url: prependDomain + generatePath(routeMatch.externalUrl, params), external: true}
        }

        return {url: ''}
    }
}