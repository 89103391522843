import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Invoices } from '../../api/inni/Invoices';
import CompanyContext from '../../context/CompanyContext';
import { useInniAPI } from '../../hooks/useInniAPI';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { Action, Entity } from '../../utils/EntityAction';
import { Currency, DatePicker, Invoice, InvoiceContracts, Market, MarketTerms } from '../../api/inni/data-contracts';
import Toolbar from '../../layouts/Desktop/Toolbar';
import { Col, Row } from 'react-bootstrap';
import ButtonPopUp from './Components/ButtonPopUp';
import { Markets } from '../../api/inni/Markets';
import InvoiceListPageWrapper from './Components/InvoiceListPageWrapper';
import { useParams } from 'react-router-dom';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { Button } from '../../elements/Button/Button';
import { useHasPermission } from '../../hooks/useHasPermission';
import { useCustomWebRequests } from '../../hooks/useCustomWebRequests';
import DismissibleBanner, { DismissibleBannerProps } from '../../elements/DismissibleBanner/DismissibleBanner';
import { Currencies } from '../../api/inni/Currencies';
import { useHistoryWrapper } from '../../hooks/useHistoryWrapper';

type RouteParams = {
	cid: string;
	year: string; 
};

export const InvoicePage = () => {
	const history = useHistoryWrapper();
	const historyRef = useRef(history)
	const hasPermission = useHasPermission();
	const customRequest = useCustomWebRequests();
	const companyId = parseInt(useParams<RouteParams>().cid);
	const invoiceApi = useInniAPI(Invoices, [400]);
	const currencyAPI = useInniAPI(Currencies);
	const [invoiceData, setInvoiceData] = useState<Invoice[]>();
	const code = useParams<RouteParams>().year;
	const [year, setYear] = useState<string | undefined>(code);
	const companyContext = useContext(CompanyContext);
	const [invoiceLoaded, setInvoiceLoaded] = useState<boolean>(false);
	const [currentYearCode, setCurrentYearCode] = useState<string | undefined>();
	const [contracts, setContracts] = useState<InvoiceContracts[]>();
	const [contractsLoaded, setContractsLoaded] = useState(false);
	const marketAPI = useInniAPI(Markets);
	const [markets, setMarkets] = useState<Market>();
	const [marketTerm, setMarketTerm] = useState<MarketTerms | undefined>();
	const [marketsLoaded, setMarketsLoaded] = useState(false);
	const [exporting,setexporting] = useState(false);
	const [bannerProps,setBannerProps] = useState<DismissibleBannerProps>({showBanner:false,body:'',type:'danger'});
	const [currencies, setCurrencies] = useState<Currency[]>();

	useEffect(() => {
		historyRef.current = history;
	  }, [history]);

	useEffect(() => {
		if (!invoiceLoaded && invoiceApi) {
			invoiceApi.index(companyContext.cid).then((response) => {
				setInvoiceData(response.data);
				setInvoiceLoaded(true);
			});
		}
	}, [companyContext.cid, invoiceLoaded, invoiceApi, setInvoiceLoaded]);

	useEffect(() => {
		invoiceApi &&
			!contractsLoaded &&
			invoiceApi
				.getInvoiceableContracts(companyContext.cid)
				.then((response) => {
					setContracts(response.data);
					setContractsLoaded(true);
				})
				.catch((error) => {
					console.error(error);
				});
	}, [companyContext.cid, invoiceApi, setContractsLoaded, setContracts, contractsLoaded]);

	useEffect(() => {
		if (marketAPI && !marketsLoaded) {
			marketAPI.index(companyContext.cid).then((response) => {
				setMarkets(response.data);
				setMarketsLoaded(true);
			});
		}
	}, [companyContext.cid, marketAPI, marketsLoaded]);

	useEffect(() => {
		if (marketAPI) {
			marketAPI?.marketTerms(companyId).then((response) => {
				setMarketTerm(response.data);
			});
		}
	}, [markets, marketAPI, companyId]);

	useEffect(() => {
		if (currencyAPI ) {
			currencyAPI.index().then((response: { data: Currency[] }) => setCurrencies(response.data));
		}
	}, [companyId, currencyAPI]);

	const exportAllInvoices = () => {
		setexporting(true);
		customRequest('invoices/ZipInvoices')
			.then((data) => {
				if(data.status === 200){
					return data.blob();
				}
				else{
					throw new Error('Something went wrong');
				}
			})
			.then((response) => {
				const url = response ? window.URL.createObjectURL(response) : '#';
				let a = document.createElement('a');
				a.setAttribute('type', 'hidden');
				a.href = url;
				a.target = '_blank';
				//setting the file name in front end
				a.download ='invoice.zip';
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				a.remove();
				setBannerProps(data => ({...data, showBanner: true, body: 'Invoices downloaded successfully', type: 'success'}));
				setexporting(false);
			})
			.catch((error) => {
				setexporting(false);
				setBannerProps(data => ({...data, showBanner: true, body: 'Invoices export failed', type: 'danger'}));
			});
	};

	//Filter based on year selection.
	const getFilter = useCallback((yearCode: string | undefined): Promise<DatePicker> => {
			const p = new Promise<DatePicker>((resolve, reject) => {
				if(invoiceApi){
					invoiceApi.filter(companyContext.cid, {yearCode: yearCode ? yearCode : year,})
					.then((results) => {
						if (results.data.datePicker) {
							resolve(results.data.datePicker);
						} else reject();
					})
					.catch((error) => 
						reject(error)
					);

					if(yearCode && historyRef.current.history.location.pathname !== `/company/${companyContext.cid}/invoicing/${yearCode}` )
					{
						historyRef.current.push(`/company/${companyContext.cid}/invoicing/${yearCode}`);
					}
				}
			});
			return p;
	}, [companyContext.cid, invoiceApi, year]);

	return (
		<DefaultLayout entity={Entity.InvoiceV7} title="Invoices" greyBackground useFullWidth>
			<Toolbar>
				{markets ? (
					<Row className="mb-0">
						{
							<Col className="">
								<ButtonPopUp
									marketTerm={marketTerm}
									getInvoiceData={invoiceData}
									variants="change"
									doctype='Invoice'
									contracts={contracts}
									markets={markets}
								/>
								{markets.isProperty && (
									<ButtonPopUp
										marketTerm={marketTerm}
										getInvoiceData={invoiceData}
										doctype='InvoiceTemplate'
										contracts={contracts}
										markets={markets}
									/>
								)}
								{!markets?.isProperty && (
									<ButtonPopUp
										marketTerm={marketTerm}
										getInvoiceData={invoiceData}
										doctype='Quote'
										contracts={contracts}
										markets={markets}
									/>
								)}
								<ButtonPopUp
									marketTerm={marketTerm}
									getInvoiceData={invoiceData}
									doctype='CreditNote'
									contracts={contracts}
									markets={markets}
								/>
								{hasPermission(Entity.InvoiceAdmin, Action.All)[0] && (
									<Button admin buttonType="fileexport" label="Export all" disabled={exporting} variant="light" onClick={exportAllInvoices} />
								)}
							</Col>
						}
					</Row>
				) : (
					<Row className="ml-1">
						{Array(3)
							.fill(0)
							.map((_, index) => (
								<div className="mr-2" key={index}>
									<LoadingPlaceholder width="150px" height="40px" /> 
								</div>
							))}
					</Row>
				)}
			</Toolbar>
			<DismissibleBanner bannerProps={bannerProps}/>
			{
				<InvoiceListPageWrapper
			    	currency={currencies}
					currentYear={currentYearCode}
					setCurrentYear={setCurrentYearCode}
					market={markets}
					getFilter={getFilter}
					invoiceLoaded={invoiceLoaded}
					getInvoiceData={invoiceData}
				/>
			}
		</DefaultLayout>
	);
};
