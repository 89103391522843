import { Form, Formik, FormikHelpers } from 'formik'
import { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import { AccountEditorAsDetailed, AccountEditorPostModel } from '../../../api/inni/data-contracts'
import { ListOption, SelectList, Text, Switch, GroupedListOption, GroupedSelectList, RowWrapper } from '../../../elements/EditRow/EditRow'
import { Text as TextRead, YesNo } from '../../../elements/DetailRow/DetailRow'
import editRowStyles from '../../../elements/EditRow/EditRow.module.css'
import { Button } from '../../../elements/Button/Button'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { Accounts } from '../../../api/inni/Accounts'
import CompanyContext from '../../../context/CompanyContext'
import { PersonForAccountEdit } from '../../../api/inni/data-contracts'
import { BrandWrapper } from '../../../components/BrandWrapper/BrandWrapper'
import { AccountsEditor } from '../../../api/inni/AccountsEditor'

export type NewType = 'bank' | 'saving' | 'loan' | 'credit' | 'dloan' | 'exp' | 'mortgage' | 'other'

const newTypeSL : ListOption[] = [
    { label: 'Bank Account', value: 'bank' },
    { label: 'Savings Account', value: 'saving' },
    { label: 'Business Loan', value: 'loan' },
    { label: 'Credit Card', value: 'credit' },
    { label: 'Directors\' Loan', value: 'dloan' },
    { label: 'Expense Account', value: 'exp' },
    { label: 'Mortgage Account', value: 'mortgage' },
    { label: 'Other...', value: 'other' },
]

const initial : AccountEditorPostModel & { newType : NewType } = {
    name: '',
    bankAccount: true,
    payExpensesViaPayroll: false,
    isHidden: false,
    isExpenseAccount: false,
    supportsBankFeed: true,
    newType: 'bank',
    currency: 'GBP',
    accountGroup: 'Assets',
    accountSubGroup: 'BankAccounts',
}

export interface CreateModalProps {
    people : PersonForAccountEdit[], 
    accounts : AccountEditorAsDetailed[], 
    editingId?: number, 
    editing? : boolean, 
    editingValues? : AccountEditorPostModel & { newType : NewType }, 
    cancelClick : () => void, 
    createUpdateCb : (i : AccountEditorAsDetailed) => void
}

const CreateModal = ({people, accounts, editingId, editing, editingValues, cancelClick, createUpdateCb} : CreateModalProps) => {

    const accountsApi = useInniAPI(AccountsEditor, [400])
    const companyContext = useContext(CompanyContext)
    

    const onChange = (selectedOption: ListOption | null, setFieldValue : (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {     
        setFieldValue('newType', selectedOption ? selectedOption.value : undefined)
        if (selectedOption && (selectedOption.value === 'bank' || selectedOption?.value === 'saving')) {
            setFieldValue('supportsBankFeed', true)
        } else {
            setFieldValue('supportsBankFeed', false)
        }
        let newType = selectedOption?.value as NewType
        // we need to set the correct accountgroup, accountsubgroup, etc. for a given type
        switch (newType) {
            case 'bank':
            case 'saving':
                setFieldValue('accountGroup', "Assets")
                setFieldValue('accountSubGroup', "BankAccounts")
                setFieldValue('bankAccount', true)
                setFieldValue('isExpenseAccount', false)
                setFieldValue('payExpensesViaPayroll', false)
                break
            case 'loan':
            case 'credit':
                setFieldValue('accountGroup', "Liabilities")
                setFieldValue('accountSubGroup', "CreditCardsLoans")
                setFieldValue('bankAccount', true)
                setFieldValue('isExpenseAccount', false)
                setFieldValue('payExpensesViaPayroll', false)
                break
            case 'dloan':
                setFieldValue('accountGroup', "Liabilities")
                setFieldValue('accountSubGroup', "DirectorsLoans")
                setFieldValue('bankAccount', true)
                setFieldValue('isExpenseAccount', false)
                setFieldValue('payExpensesViaPayroll', false)
                break
            case 'exp':
                setFieldValue('accountGroup', "Liabilities")
                setFieldValue('accountSubGroup', "DirectorsLoans")
                setFieldValue('bankAccount', true)
                setFieldValue('isExpenseAccount', true)
                break
            case 'mortgage':
                setFieldValue('accountGroup', "Liabilities")
                setFieldValue('accountSubGroup', "Mortgages")
                setFieldValue('bankAccount', true)
                setFieldValue('isExpenseAccount', false)
                setFieldValue('payExpensesViaPayroll', false)
                break
            case 'other':
                setFieldValue('bankAccount', false)
                setFieldValue('isExpenseAccount', false)
                setFieldValue('payExpensesViaPayroll', false)
                break
        }
        
    }

    const buildPplSl = (i : NewType) => {
        let tempPplSl : ListOption[] = []
        people.filter(x => i === 'dloan' ? 
            (x.isDirector || x.isShareholder)
            : x.canHaveDrawingsAccount)
            .map(x => tempPplSl.push({ value: x.id.toString(), label: x.name || '' }))
        return tempPplSl
    }

    const buildAccountsSl = () => {
        let tempOptions: Array<GroupedListOption> = []
        accounts.map(i => {
            const exists = tempOptions.findIndex(x => x.label === i.accountGroup)
            if (exists === -1) {
                tempOptions.push({
                    label: i.accountGroup || '',
                    options: [{value: i.accountSubGroup || '', label: i.accountSubGroup?.replace(/([A-Z])/g, ' $1').trim() || ''}]
                })
            } else {
                let subExists = tempOptions[exists].options?.findIndex(x => x.value === i.accountSubGroup)
                if (subExists === -1)
                    tempOptions[exists].options?.push({value: i.accountSubGroup || '', label: i.accountSubGroup?.replace(/([A-Z])/g, ' $1').trim() || ''})
            }
        })
        return tempOptions
    }

    const validateCreate = (values : AccountEditorPostModel & { newType : NewType }) => {
        return new Promise((resolve, reject) => {
            if (accountsApi) {
                return accountsApi.validateCreate(companyContext.cid, values as AccountEditorPostModel)
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }

    const validateUpdate = (values : AccountEditorPostModel & { newType : NewType }) => {
        return new Promise((resolve, reject) => {
            if (accountsApi) {
                return accountsApi.validateUpdate(companyContext.cid, { accountId: editingId || 0 }, values as AccountEditorPostModel)
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }

    const createCall = (values: AccountEditorPostModel & { newType : NewType }, actions: FormikHelpers<AccountEditorPostModel & { newType : NewType }>) : Promise<number> => {
        return new Promise((resolve, reject) => {
            if (accountsApi) {
                if (values.newType === 'other') {
                    values.accountGroup = accounts.find(x => x.accountSubGroup === values.accountSubGroup)?.accountGroup
                }
                return accountsApi.create(companyContext.cid, values as AccountEditorPostModel)
                .then(res => {
                    if (res.status !== 201) {
                        reject()
                    }
                    createUpdateCb({...values, personName: people.find(x => x.id === Number(values.personId))?.name, id: parseInt(res.data), companyId: companyContext.cid, excludeFromCt: false, canDelete: true, canRename: true} as AccountEditorAsDetailed)
                    cancelClick()
                    
                    // in V7 the bank accounts, mortgage accounts, etc are visible in the side menu
                    // if not extra condition can be added to reload only for mortgage accounts
                    if(companyContext.reloadMenu ){
                        companyContext.reloadMenu();
                    }
                       
                    resolve(parseInt(res.data))
                })
                .catch(() => {
                    reject()
                })
            } else {
                reject()
            }
        })
    }

    const updateCall = (values: AccountEditorPostModel & { newType : NewType }, actions: FormikHelpers<AccountEditorPostModel & { newType : NewType }>) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (accountsApi) {
                return accountsApi.update(companyContext.cid, { accountId: editingId || 0 }, values as AccountEditorPostModel)
                .then(res => {
                    createUpdateCb({...values, id: editingId || 0, companyId: companyContext.cid, excludeFromCt: false, canDelete: true, canRename: true} as AccountEditorAsDetailed)
                    cancelClick()

                    // in V7 the bank accounts, mortgage accounts, etc are visible in the side menu
                    // if not extra condition can be added to reload only for mortgage accounts
                    if(companyContext.reloadMenu ){
                        companyContext.reloadMenu();
                    }
                    resolve()
                })
                .catch(() => {
                    reject();
                })
            } else {
                reject();
            }
        })
    }

    return (
        <BrandWrapper>
            <Formik
                initialValues={editing ? editingValues || initial : initial}
                enableReinitialize
                onSubmit={editing ? updateCall : createCall}
                validateOnChange={false}       
                validate={editing ? validateUpdate : validateCreate}
            >
                {({ isSubmitting, values, setFieldValue }) => (<>
                    <Form>
                        <Modal.Header>
                            <Modal.Title id="inni-modal-title">
                                {editing ? 'Edit account' : 'Create account'}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {editing && <TextRead entity={values} name="accountGroup" label="Group" />}
                            {editing && 
                                <TextRead 
                                    entity={values} 
                                    name="accountSubGroup" 
                                    label="Sub-Group" 
                                    valueFormatter={(i) => (i || '').replace(/([A-Z])/g, ' $1').trim()} 
                                />
                            }
                            {editing && <YesNo entity={values} name="bankAccount" />}
                            {editing && (values.newType === 'dloan' || values.newType === 'exp') && <YesNo entity={values} name="payExpensesViaPayroll" />}
                            {/* Don't use editrow as we want custom behaviour on change */}                        
                            {!editing && 
                            <RowWrapper name={"newType"} label={"New type"} type='selectList' labelTop>
                                <Select
                                    classNamePrefix='selectList' 
                                    options={newTypeSL} 
                                    className={editRowStyles.selectList + ` ${editRowStyles.LblTopAlignLeft}`}
                                    onChange={(i) => onChange(i, setFieldValue)}
                                    value={newTypeSL.find(x => x.value === values.newType)}
                                />
                            </RowWrapper>}
                            {values.newType === 'exp' && !editing && <Switch<AccountEditorPostModel> name="payExpensesViaPayroll" labelTop />}
                            {(values.newType === 'dloan' || values.newType === 'exp') && 
                                <SelectList<AccountEditorPostModel> readOnly={editing} labelTop name="personId" label="Employee" options={buildPplSl(values.newType)} />
                            }
                            {values.newType === 'other' && 
                                <GroupedSelectList<AccountEditorPostModel> readOnly={editing} label="Group" name="accountSubGroup" labelTop options={buildAccountsSl()} />
                            }
                            <Text<AccountEditorPostModel> name="name" labelTop />
                            {values.newType !== 'other' && <Text<AccountEditorPostModel> name="currency" labelTop />}
                            <Switch<AccountEditorPostModel> name="supportsBankFeed" labelTop />
                            {editing && values.bankAccount && <Switch<AccountEditorPostModel> labelTop name="isHidden" label="Hide bank account" />}
                            {editing && <Text<AccountEditorPostModel> labelTop name="orderId" type="number" />}
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="w-100 text-left">
                                <Button submitButton buttonType="save" />
                                <Button disabled={isSubmitting} onClick={cancelClick} buttonType="cancel" />
                            </div>
                        </Modal.Footer>                    
                    </Form>
                    </> 
                )}
            </Formik>
        </BrandWrapper>
    )
}

export default CreateModal