import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { Action, Entity } from '../../utils/EntityAction';
import { useAssertPermission } from '../../hooks/useAssertPermission';
import { Tenancies } from '../../api/inni/Tenancies';
import { Tenancy } from '../../api/inni/data-contracts';
import { TenancyListTable } from './Components/TenancyListTable';
import Toolbar from '../../layouts/Desktop/Toolbar';
import { Button } from '../../elements/Button/Button';
import { useFetchEntityList } from '../../hooks/entities/useFetchEntityList';
import { useLettableUnitsList } from '../../hooks/lists/useLettableUnitsList';
// import { useOnboardingMessages } from '../../hooks/onboarding/useOnboardingMessages';
import { useContext, useEffect, useState } from 'react';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import BlankStateCard from '../../components/BlankStateCard/BlankStateCard';
import { SbBlokData, getStoryblokApi } from '@storyblok/react';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';


export const TenancyListPage = () => {
    useAssertPermission(Entity.Tenancy, Action.List);
    const [tenancies, tenanciesLoaded] = useFetchEntityList<Tenancy, Tenancies>(Tenancies);
    const [units, unitsLoaded] = useLettableUnitsList();

	// const [unreadPageMessages, setMessageRead, getOnboardingComponent] = useOnboardingMessages('tenancy');
    const companyContext=useContext(CompanyContext);
    const isCAP = companyContext.product===CompanyProduct.isCap;
    const v8Styling = companyContext.company?.useV8UI; 
    const [blankSlateInfo, setBlankSlateInfo]= useState<SbBlokData>();
    const navigateToEntity = useNavigateToEntity()

    useEffect(()=>{
        const storyblokApi = getStoryblokApi();
        storyblokApi.get('cdn/stories/blank-slate/tenancies', { version: 'published'})
        .then(res => {
            setBlankSlateInfo(res.data.story.content)
        }).catch(err => {
            console.error(err)
        });
    }, [])

    return (
        <DefaultLayout entity={Entity.Tenancy} title="Tenancies" greyBackground>
            {!(isCAP && tenancies.length ===0) && tenanciesLoaded &&
                <Toolbar>
                    <Button
                        entity={Entity.Tenancy}
                        action={Action.Create}
                        buttonType='new'
                        headerBtn={v8Styling}
                    />
                </Toolbar>
            }
            {/* { getOnboardingComponent('tenancies_welcome') } */}

            { isCAP && tenanciesLoaded && tenancies.length === 0 &&
                <BlankStateCard 
                    content={blankSlateInfo} 
                    loading={!blankSlateInfo}
                    buttonEntity={Entity.Tenancy}
                    buttonAction={Action.Create}
                />
            }

            { (!isCAP || !(tenanciesLoaded && tenancies.length === 0) )&&
                <TenancyListTable tenancies={tenancies} lettableUnits={units} loaded={tenanciesLoaded && unitsLoaded} />
            }
               
        </DefaultLayout>
    )
}
