import { CompanyTabs } from './CompanySettingsTabs';
import {
	CompanyAESettingsPostModel,
	CompanyAccountSettingsPostModel,
	CompanyInformationPostModel,
	CompanyInvoicingSettingsPostModel,
	CompanyOnlineSystemSettingsPostModel,
	CompanyPAYESettingsPostModel,
	CompanySettingView,
	CompanyVATSettingsPostModel
} from '../../api/inni/data-contracts';
import AccountsSettingTab from './CompanySettingsEdit/AccountsSettingTab';
import OnlineSystemsTab from './CompanySettingsEdit/OnlineSystemsTab';
import { useInniAPI } from '../../hooks/useInniAPI';
import { CompanySettings } from '../../api/inni/CompanySettings';
import { useContext, useEffect, useState } from 'react';
import CompanyContext from '../../context/CompanyContext';
import { FormikErrors, FormikHelpers } from 'formik';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import PensionProviderTab from './CompanySettingsEdit/PensionProviderTab';
import VATSettingsTab from './CompanySettingsEdit/VATSettingsTab';
import InvoiceSettingsTab from './CompanySettingsEdit/InvoiceSettingsTab';
import PayeSettingsTab from './CompanySettingsEdit/PayeSettingsTab';
import AEAssessmentTab from './CompanySettingsEdit/AEAssessmentTab';
import { ListOption } from '../../elements/EditRow/EditRow';
import CompanyInformationTab from './CompanySettingsEdit/CompanyInformationTab';

type Props = {
	activeTab: CompanyTabs;
	companySettings: CompanySettingView;
	setReload: React.Dispatch<React.SetStateAction<boolean>>;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	isAdmin: boolean;
	updateCompanySettingsFromCH: () => void;
	v8Styling?: boolean;
};

function CompanySettingsEditLayout({
		activeTab,
		companySettings,
		setLoading,
		isAdmin,
		setReload,
		updateCompanySettingsFromCH,
		v8Styling
	}: Props) {

	const companyApi = useInniAPI(CompanySettings, [400]);
	const companyContext = useContext(CompanyContext);
	const [successMessage, setSuccessMessage] = useState<string>();
	const [vatFlatratecategories,setVatFaltrateCategories] = useState<ListOption[]>();
	const [disableUpdate, setDisableUpdate] = useState(false);

	useEffect(() => {
		if (successMessage) {
			const timer = setTimeout(() => {
				setSuccessMessage(undefined);
			}, 3000);
			return () => {
				clearTimeout(timer);
			  };
		}
	}, [successMessage]);

	useEffect(() => {
		if(activeTab === CompanyTabs.VAT_SETTINGS) {
			try{
				companyApi?.getVatFlatRateCategories(companyContext.cid).then((response) => {
					if(response.ok){
						//filter undefined values
						const filtered = response.data?.filter((item) => item !== undefined);
						setVatFaltrateCategories(filtered as ListOption[]);
					}
				});
			}
			catch(e){
				console.log(e);
			}
		}
	}, [activeTab, companyApi, companyContext.cid]);

	async function handleUpdateCompanyInfomrationFromCompanyHouse() {
		try{
			setDisableUpdate(true);
			await companyApi?.updateCompanyHouseData(companyContext.cid);
			updateCompanySettingsFromCH();
			setSuccessMessage('Company information updated successfully');
			
		}catch(e){
			console.error(e);
			setDisableUpdate(false);
		}
	}

	async function handleValidateCompanyInformation(values: CompanyInformationPostModel): Promise<FormikErrors<CompanyInformationPostModel>> {
		try {
			await companyApi?.validateUpdateCompanyInformationSettings(companyContext.cid, values)
			return {};
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleValidateAccountSettings(values: CompanyAccountSettingsPostModel) {
		try {
			await companyApi?.validateUpdateCompanyAccountSettings(companyContext.cid, values)

			return {};
		} catch (e) {
			return e.error as Error;
		}
	}

	// eslint-disable-next-line max-len
	async function handleValidateOnlineSystems(values: CompanyOnlineSystemSettingsPostModel) {
		try {
			await companyApi?.validateUpdateCompanyOnlineSystemsSettings(companyContext.cid, values);
			return {};
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleValidateVATSettings(values: CompanyVATSettingsPostModel) {
		try {
			await companyApi?.validateUpdateCompanyVatSettings(companyContext.cid, values);
			return {};
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleValidateInvoiceSettings(values: CompanyInvoicingSettingsPostModel) {
		try {
			await companyApi?.validateCompanyInvoicingSettings(companyContext.cid, values);
			return {};
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleValidatePayeSettings(values: CompanyPAYESettingsPostModel) {
		try {
			await companyApi?.validateCompanyPayeSettings(companyContext.cid, values);
			return {};
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleValidateAEAssessmentSettings(values: CompanyAESettingsPostModel) {
		try {
			await companyApi?.validateCompanyAeSettings(companyContext.cid, values);
			return {};
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleSubmitCompanyInfomation(values: CompanyInformationPostModel,actions:FormikHelpers<CompanyInformationPostModel>) {
		try {
			await companyApi?.updateCompanyInformationSettings(companyContext.cid, values)
				.then((response) => {
					if(response.ok){
						setLoading(true);
						setSuccessMessage('Company information updated successfully');
						actions.resetForm();
						setReload(prev => !prev);
					}
				});
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleSubmitAccountSettings(values: CompanyAccountSettingsPostModel,actions:FormikHelpers<CompanyAccountSettingsPostModel>) {
		try {
			await companyApi?.updateCompanyAccountSettings(companyContext.cid, values)
			.then((response) => {
				if(response.ok){
					setLoading(true);
					setSuccessMessage('Account settings updated successfully');
					actions.resetForm();
					setReload(prev => !prev);
				}
			});
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleSubmitOnlineSystems(values: CompanyOnlineSystemSettingsPostModel,
		actions:FormikHelpers<CompanyOnlineSystemSettingsPostModel>) {
		try {
			await companyApi?.updateCompanyOnlineSystemsSettings(companyContext.cid, values)
			.then((response) => {
				if(response.ok){
					setLoading(true);
					setSuccessMessage('Online systems updated successfully');
					actions.resetForm();
					setReload(prev => !prev);
				}
			});
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleSubmitVATSettings(values: CompanyVATSettingsPostModel,
		actions:FormikHelpers<CompanyVATSettingsPostModel>) {
		try {
			await companyApi?.updateCompanyVatSettings(companyContext.cid, values)
			.then((response) => {
				if(response.ok){
					setLoading(true);
					setSuccessMessage('VAT settings updated successfully');
					actions.resetForm();
					setReload(prev => !prev);
				}
			});
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleSubmitInvoiceSettings(values: CompanyInvoicingSettingsPostModel,
		actions:FormikHelpers<CompanyInvoicingSettingsPostModel>) {
		try {
			await companyApi?.updateCompanyInvoicingSettings(companyContext.cid, values)
			.then((response) => {
				if(response.ok){
					setSuccessMessage('Invoice settings updated successfully');
					actions.resetForm();
					setLoading(true);
					setReload(prev => !prev);
				}
			});
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handlePayeSettings(values: CompanyPAYESettingsPostModel,
		actions:FormikHelpers<CompanyPAYESettingsPostModel>) {
		try {
			await companyApi?.updateCompanyPayeSettings(companyContext.cid, values)
			.then((response) => {
				if(response.ok){
					setLoading(true);
					setSuccessMessage('PAYE settings updated successfully');
					actions.resetForm();
					setReload(prev => !prev);
				}
			});
		} catch (e) {
			return e.error as Error;
		}
	}

	async function handleAEAssessmentSettings(values: CompanyAESettingsPostModel,
		actions:FormikHelpers<CompanyAESettingsPostModel>) {
		try {
			await companyApi?.updateCompanyAeSettings(companyContext.cid, values)
			.then((response) => {
				if(response.ok){
					setLoading(true);
					setSuccessMessage('AE settings updated successfully');
					actions.resetForm();
					setReload(prev => !prev);
				}
			});
		} catch (e) {
			return e.error as Error;
		}
	}


	return (
		<div className={!v8Styling ? 'mx-4' : undefined}>
			{activeTab === CompanyTabs.COMPANY_INFORMATION &&(
				<CompanyInformationTab<CompanyInformationPostModel>
					handleUpdate={handleUpdateCompanyInfomrationFromCompanyHouse}
					isAdmin={isAdmin}
					settings={companySettings}
					handleSubmit={handleSubmitCompanyInfomation}
					handleValidate={handleValidateCompanyInformation}
					disableUpdate={disableUpdate}
				/>
			)}
			{activeTab === CompanyTabs.ACCOUNT_SETTINGS && (
				<AccountsSettingTab<CompanyAccountSettingsPostModel>
					isAdmin={isAdmin}
					settings={companySettings}
					handleSubmit={handleSubmitAccountSettings}
					handleValidate={handleValidateAccountSettings}
				/>
			)}
			{activeTab === CompanyTabs.VAT_SETTINGS &&(
				<>
				{vatFlatratecategories &&
				<VATSettingsTab<CompanyVATSettingsPostModel>
				isAdmin={isAdmin}
				flaterateCategories={vatFlatratecategories}
				handleSubmit={handleSubmitVATSettings}
				handleValidate={handleValidateVATSettings}
				settings={companySettings}
				/>}
				</>
			)}
			{activeTab === CompanyTabs.INVOICE_SETTINGS && (
				<InvoiceSettingsTab<CompanyInvoicingSettingsPostModel>
					isAdmin={isAdmin}
					handleSubmit={handleSubmitInvoiceSettings}
					handleValidate={handleValidateInvoiceSettings}
					settings={companySettings}
					/>
			)}
			{activeTab === CompanyTabs.ONLINE_SYSTEMS && (
				<OnlineSystemsTab<CompanyOnlineSystemSettingsPostModel>
					isAdmin={isAdmin}
				    settings={companySettings}
					handleSubmit={handleSubmitOnlineSystems}
					handleValidate={handleValidateOnlineSystems} />
			)}
			{activeTab === CompanyTabs.PAYE_SETTINGS && (
				<PayeSettingsTab<CompanyPAYESettingsPostModel>
					isAdmin={isAdmin}
					handleSubmit={handlePayeSettings}
					handleValidate={handleValidatePayeSettings}
					settings={companySettings}
				/>
			)}
			{activeTab ===CompanyTabs.AE_ASSESSMENT && (
				<AEAssessmentTab<CompanyAESettingsPostModel> settings={companySettings}
				isAdmin={isAdmin}
				handleSubmit={handleAEAssessmentSettings}
				handleValidate={handleValidateAEAssessmentSettings}
				/>
			)}
			{activeTab === CompanyTabs.PENSION_PROVIDER && (
				<PensionProviderTab/>
			)}
			<Alert
			    style={{position:'absolute',bottom: '10px', zIndex: 99, width: 'calc(100% - 275px)'}}
                show={successMessage !== undefined}
                variant="success"
                dismissible
                onClose={() => setSuccessMessage(undefined)}
			>
					<FontAwesomeIcon size="lg" icon={faCheckCircle} /> {successMessage}
			</Alert>
		</div>
	);
}
export default CompanySettingsEditLayout;
