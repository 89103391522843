import styles from './FileIcon.module.css'

// Define various IconTypes 
export type IconType = 'pdf' | 'img' | 'xls'| 'file' | 'png' | 'csv' | 'jpg' | 'docx' | 'rtf'|'zip' | 'rar'| 'txt'|'xlsx' ;

// Here we map an IconType to a tuple [string, string] which represents a link to the img 
// and the text on the image - fallbacks to file-icon.png if none is defined
const iconTypeMap : Map<IconType, [string,string]> = new Map([
    ['pdf', ['PDF', './images/file-icons/file-icon-pdf.png']],
    ['img', ['IMG','./images/file-icons/file-icon-image.png']],
    ['xls', ['XLS','./images/file-icons/file-icon-excel.png']],
    ['file', ["", "./images/file-icons/file-icon.png"]],
	['png', ["PNG", "./images/file-icons/file-icon-image.png"]],
	['csv', ["CSV", "./images/file-icons/file-icon-excel.png"]],
	['jpg', ["JPG", "./images/file-icons/file-icon-image.png"]],
    ['docx', ["DOC", "./images/file-icons/file-icon-word.png"]],
    ['rtf', ["RTF", "./images/file-icons/file-icon-word.png"]],
    ['png', ["PNG", "./images/file-icons/file-icon-image.png"]],
    ['zip', ["ZIP", "./images/file-icons/file-icon-zip.png"]],
    ['rar', ["RAR", "./images/file-icons/file-icon-zip.png"]],
    ['txt', ["TXT", "./images/file-icons/file-icon-txt.png"]],
    ['xlsx', ["XLSX", "./images/file-icons/file-icon-excel.png"]],
])

// Simple file icon image output with configurable text (based on file icons from old website)
const FileIcon = ({iconType} : {iconType: IconType}) => {
    let strs = iconTypeMap.get(iconType)
    return (
        <div className={styles.fileIcon} >
            <img alt="File Icon" src={strs ? strs[1] : './images/file-icons/file-icon.png'} /> {/* Fallback to normal file icon */}
            <span>{strs ? strs[0] : ''}</span>
        </div>
        
    )
}

export default FileIcon