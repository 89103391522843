import classNames from 'classnames';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import CompanyContext from '../../context/CompanyContext';
import UserContext from '../../context/UserContext';
import { TopBar } from '../Components/TopBar';
import styles from './MobileWrapper.module.scss'
import { SideBar } from '../Components/SideBar';
import { Alert } from 'react-bootstrap';
import { Companies } from '../../api/inni/Companies';
import { useInniAPI } from '../../hooks/useInniAPI';
import { CompanySummary } from '../../api/inni/data-contracts';
import PWADownloadButton from './PWADownloadButton';

import { Navigation } from '../../api/inni/Navigation'
import { NavigationModel } from '../../api/inni/data-contracts'

type MobileWrapperProps = {
    children?: React.ReactNode
}

const MobileWrapper = ({children}: MobileWrapperProps) => {
    const userContext = useContext(UserContext);
    const companyContext = useContext(CompanyContext);

    const companiesApi = useInniAPI(Companies);
    const [companies, setCompanies] = useState<Array<CompanySummary>|undefined>();

    const [sideBarCollapsed, setSideBarCollapsed] = useState(true);

    const navigationApi = useInniAPI(Navigation);
    const [sideBarEntries, setSideBarEntries] = useState<NavigationModel>();

    // Load sidebar entries
    useEffect(() => {
        if (navigationApi && !sideBarEntries && companyContext.cid > 0) {
            navigationApi?.index(companyContext.cid, {isMobile: true}).then((response) => {
                setSideBarEntries(response.data);
            })
        }
    }, [companyContext.cid, navigationApi, sideBarEntries])

    useEffect(() => {
        if (companiesApi && companies === undefined) {
            companiesApi.index().then(response => {
                setCompanies(response.data);
            })
        }
    }, [companiesApi, companies])

    const toggleSidebar = (collapsed?: boolean) => {
        if (collapsed !== undefined) {
            setSideBarCollapsed(collapsed)
        } else {
            setSideBarCollapsed(!sideBarCollapsed)
        }
    }

    const handleContentClick = (e : MouseEvent) => {
        if (!sideBarCollapsed) {
            e.preventDefault()
            setSideBarCollapsed(true)
        }
    }

    return (
        <div id={styles.mobileWrapper}>
            <div id={styles.topBar} className={userContext.errorMessage ? styles.hasError : undefined}>
                <TopBar
                    user={companyContext.cid === -1 ? userContext.user : undefined}
                    companies={companies}
                    contextLevel={companyContext.cid === -1 ? 'contact' : 'company'}
                    logout={userContext.logout}
                    toggleSidebar={toggleSidebar}
                    useMobileLayout={true}
                />
            </div>
            <div id={styles.contentWrapper}>
                <div id={styles.sideBarMobile}>
                    { (sideBarEntries && sideBarEntries.navItems) && (
                        <SideBar
                            onClickCb={() => setSideBarCollapsed(true)} 
                            collapsed={sideBarCollapsed} 
                            useMobileLayout={true}
                            sideBarEntries={sideBarEntries?.navItems}
                        />
                    )}
                </div>
                <div onClick={(e) => handleContentClick(e)}  className={`${styles.contentOverlay} ${sideBarCollapsed ? '' : styles.contentOverlayActive}`}></div>
                <div id={styles.content} className={classNames(styles.contentSidebarClosed, 'mobileWrapperContent', sideBarCollapsed ? '' : styles.contentSidebarOpen)}>
                        {children}
                </div>
            </div>
            <PWADownloadButton type='popup' />
            {/* should we make this disappear automatically?? */}
            <Alert dismissible className={styles.mobileWrapperAlert} onClose={() => {if (userContext.clearErrorMessage) userContext.clearErrorMessage();}} show={userContext.errorMessage !== undefined} variant="danger">
                <strong>We're sorry, something went wrong.</strong> {userContext.errorMessage}
            </Alert>
        </div>
    )
}

export default MobileWrapper