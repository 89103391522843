/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddP45PostModel,
  BenefitsPostModel,
  ItrNotesPostModel,
  ManagePeopleDetails,
  OverviewPostModel,
  PayeSetupP45PostModel,
  PayeSetupP46PostModel,
  PayeViaMigrationNoPaySetupPostModel,
  PayeViaMigrationSetupPostModel,
  Person,
  PersonAsDetailed,
  PersonCreateModel,
  PersonForAccountEdit,
  PersonSelectList,
  PersonUpdateModel,
  SelectlistModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class People<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags People
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/people
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/people
   * @secure
   */
  create = (companyId: number, pm: PersonCreateModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/people`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/people
   * @secure
   */
  delete = (companyId: number, query: { personId: number }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name GetForQuickEntry
   * @request GET:/api/v2/companies/{companyId}/people/GetForQuickEntry
   * @secure
   */
  getForQuickEntry = (companyId: number, params: RequestParams = {}) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people/GetForQuickEntry`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name GetOne
   * @request GET:/api/v2/companies/{companyId}/people/{id}
   * @secure
   */
  getOne = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<Person, any>({
      path: `/api/v2/companies/${companyId}/people/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/people/{id}
   * @secure
   */
  update = (companyId: number, id: number, pm: PersonUpdateModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/${id}`,
      method: "PUT",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name GetForManagePeople
   * @request GET:/api/v2/companies/{companyId}/people/GetForManagePeople
   * @secure
   */
  getForManagePeople = (companyId: number, params: RequestParams = {}) =>
    this.request<ManagePeopleDetails, any>({
      path: `/api/v2/companies/${companyId}/people/GetForManagePeople`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name GetAsDetailed
   * @request GET:/api/v2/companies/{companyId}/people/GetAsDetailed/{id}
   * @secure
   */
  getAsDetailed = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<PersonAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/people/GetAsDetailed/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/people/ValidateCreate
   * @secure
   */
  validateCreate = (companyId: number, pm: PersonCreateModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/ValidateCreate`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name GetPersonCreateStages
   * @request GET:/api/v2/companies/{companyId}/people/GetPersonCreateStages
   * @secure
   */
  getPersonCreateStages = (companyId: number, params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/v2/companies/${companyId}/people/GetPersonCreateStages`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name SelectList
   * @request GET:/api/v2/companies/{companyId}/people/SelectList
   * @secure
   */
  selectList = (companyId: number, params: RequestParams = {}) =>
    this.request<PersonSelectList[], any>({
      path: `/api/v2/companies/${companyId}/people/SelectList`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name GetTaxReigmeSelectList
   * @request GET:/api/v2/companies/{companyId}/people/GetTaxReigmeSelectList
   * @secure
   */
  getTaxReigmeSelectList = (companyId: number, params: RequestParams = {}) =>
    this.request<SelectlistModel[], any>({
      path: `/api/v2/companies/${companyId}/people/GetTaxReigmeSelectList`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name GetForAccountEdit
   * @request GET:/api/v2/companies/{companyId}/people/GetForAccountEdit
   * @secure
   */
  getForAccountEdit = (companyId: number, params: RequestParams = {}) =>
    this.request<PersonForAccountEdit[], any>({
      path: `/api/v2/companies/${companyId}/people/GetForAccountEdit`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name ValidateUpdateItrNotes
   * @request PUT:/api/v2/companies/{companyId}/people/ValidateUpdateItrNotes/{personId}
   * @secure
   */
  validateUpdateItrNotes = (
    companyId: number,
    personId: number,
    model: ItrNotesPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/ValidateUpdateItrNotes/${personId}`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name UpdateItrNotes
   * @request PUT:/api/v2/companies/{companyId}/people/UpdateItrNotes/{personId}
   * @secure
   */
  updateItrNotes = (companyId: number, personId: number, model: ItrNotesPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/UpdateItrNotes/${personId}`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name ValidateUpdateBenefits
   * @request PUT:/api/v2/companies/{companyId}/people/ValidateUpdateBenefits/{personId}
   * @secure
   */
  validateUpdateBenefits = (
    companyId: number,
    personId: number,
    model: BenefitsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/ValidateUpdateBenefits/${personId}`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name UpdateBenefits
   * @request PUT:/api/v2/companies/{companyId}/people/UpdateBenefits/{personId}
   * @secure
   */
  updateBenefits = (companyId: number, personId: number, model: BenefitsPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/UpdateBenefits/${personId}`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name ValidateUpdateOverview
   * @request PUT:/api/v2/companies/{companyId}/people/ValidateUpdateOverview/{personId}
   * @secure
   */
  validateUpdateOverview = (
    companyId: number,
    personId: number,
    model: OverviewPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/ValidateUpdateOverview/${personId}`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name UpdateOverview
   * @request PUT:/api/v2/companies/{companyId}/people/UpdateOverview/{personId}
   * @secure
   */
  updateOverview = (companyId: number, personId: number, model: OverviewPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/UpdateOverview/${personId}`,
      method: "PUT",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name RequestAuthSa
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/RequestAuthSA
   * @secure
   */
  requestAuthSa = (companyId: number, personId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/RequestAuthSA`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name DeleteEmployment
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/DeleteEmployment
   * @secure
   */
  deleteEmployment = (companyId: number, personId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/DeleteEmployment`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name ValidateDelete
   * @request DELETE:/api/v2/companies/{companyId}/people/ValidateDelete
   * @secure
   */
  validateDelete = (companyId: number, query: { personId: number }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/ValidateDelete`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name ValidatePayeSetupP45
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/ValidatePayeSetupP45
   * @secure
   */
  validatePayeSetupP45 = (
    companyId: number,
    personId: number,
    model: PayeSetupP45PostModel,
    params: RequestParams = {},
  ) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/ValidatePayeSetupP45`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name PayeSetupP45
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/PayeSetupP45
   * @secure
   */
  payeSetupP45 = (companyId: number, personId: number, model: PayeSetupP45PostModel, params: RequestParams = {}) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/PayeSetupP45`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name ValidatePayeSetupP46
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/ValidatePayeSetupP46
   * @secure
   */
  validatePayeSetupP46 = (
    companyId: number,
    personId: number,
    model: PayeSetupP46PostModel,
    params: RequestParams = {},
  ) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/ValidatePayeSetupP46`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name PayeSetupP46
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/PayeSetupP46
   * @secure
   */
  payeSetupP46 = (companyId: number, personId: number, model: PayeSetupP46PostModel, params: RequestParams = {}) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/PayeSetupP46`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name ValidatePayeViaMigrationSetup
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/ValidatePayeViaMigrationSetup
   * @secure
   */
  validatePayeViaMigrationSetup = (
    companyId: number,
    personId: number,
    model: PayeViaMigrationSetupPostModel,
    params: RequestParams = {},
  ) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/ValidatePayeViaMigrationSetup`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name PayeViaMigrationSetup
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/PayeViaMigrationSetup
   * @secure
   */
  payeViaMigrationSetup = (
    companyId: number,
    personId: number,
    model: PayeViaMigrationSetupPostModel,
    params: RequestParams = {},
  ) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/PayeViaMigrationSetup`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name ValidatePayeViaMigrationNoPaySetup
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/ValidatePayeViaMigrationNoPaySetup
   * @secure
   */
  validatePayeViaMigrationNoPaySetup = (
    companyId: number,
    personId: number,
    model: PayeViaMigrationNoPaySetupPostModel,
    params: RequestParams = {},
  ) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/ValidatePayeViaMigrationNoPaySetup`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name PayeViaMigrationNoPaySetup
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/PayeViaMigrationNoPaySetup
   * @secure
   */
  payeViaMigrationNoPaySetup = (
    companyId: number,
    personId: number,
    model: PayeViaMigrationNoPaySetupPostModel,
    params: RequestParams = {},
  ) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/PayeViaMigrationNoPaySetup`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name ValidateAddP45
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/ValidateAddP45
   * @secure
   */
  validateAddP45 = (companyId: number, personId: number, model: AddP45PostModel, params: RequestParams = {}) =>
    this.request<Person[], any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/ValidateAddP45`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name AddP45
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/AddP45
   * @secure
   */
  addP45 = (companyId: number, personId: number, model: AddP45PostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/AddP45`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags People
   * @name IssueP45
   * @request POST:/api/v2/companies/{companyId}/people/{personId}/IssueP45
   * @secure
   */
  issueP45 = (companyId: number, personId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/people/${personId}/IssueP45`,
      method: "POST",
      secure: true,
      ...params,
    });
}
