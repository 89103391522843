import { useContext, useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { LettingAgent, LettingStatement } from '../../../api/inni/data-contracts';
import { LettingAgents } from '../../../api/inni/LettingAgents';
import { LettingStatements } from '../../../api/inni/LettingStatements';
import { FileUploadModal } from '../../../components/FileUploadModal/FileUploadModal';
import { Modal } from '../../../components/Modal/Modal';
import CompanyContext, { CompanyProduct } from '../../../context/CompanyContext';
import { Button } from '../../../elements/Button/Button';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import DismissibleBanner, { DismissibleBannerProps } from '../../../elements/DismissibleBanner/DismissibleBanner';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { TableIcon } from '../../../elements/TableIcon/TableIcon';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { DefaultLayout } from '../../../layouts/Desktop/DefaultLayout';
import { Action, Entity } from '../../../utils/EntityAction';
import { formatDate } from '../../../utils/formatDate';
import { formatCurrency } from '../../../utils/formatNumbers';
import { agentStatementStatus } from './HelperFunction';
import { placeholderImage } from '../../../utils/formatters/formatProperty';
import styles from './AgentStatement.module.css'
import { SbBlokData, getStoryblokApi } from '@storyblok/react';
import BlankStateCard from '../../../components/BlankStateCard/BlankStateCard';
import { LettingStatementStatus } from './AgentFields';


function AgentStatements() {
	const navigateToEntity = useNavigateToEntity();
	const agentStatementsAPI = useInniAPI(LettingStatements, [400]);
	const agentDetailsAPI = useInniAPI(LettingAgents);
	const companyContext = useContext(CompanyContext);
	const companyId = companyContext.cid;
	const [agentStatements, setAgentStatements] = useState<LettingStatement[]>();
	const [agentStatementsLoaded, setAgentStatementsLoaded] = useState(false);
	const [agentDetails, setAgentDetails] = useState<LettingAgent[]>();
	const [agentDeatilsLoaded, setAgentDetailsLoaded] = useState(false);
	const [uploadingLettingStatement, setUploadingLettingStatement] = useState<LettingStatement | undefined>(undefined);
	const [uploading, setUploading] = useState(false);
	const [bannerProps, setBannerProps] = useState<DismissibleBannerProps>({ showBanner: false, body: '', type: 'danger' });
	const [statementModal, setStatementModal] = useState<LettingStatement | undefined>(undefined);

	const isCAP = companyContext.product === CompanyProduct.isCap;
	const [blankSlateInfo, setBlankSlateInfo]= useState<SbBlokData>();

	useEffect(() => {
		if (!agentStatementsLoaded && agentStatementsAPI) {
			agentStatementsAPI.index(companyId).then((response) => {
				setAgentStatements(response.data);
				setAgentStatementsLoaded(true);
			});
		}
	}, [agentStatementsLoaded, agentStatementsAPI, setAgentStatementsLoaded, companyId]);

	useEffect(() => {
		if (!agentDeatilsLoaded && agentDetailsAPI) {
			agentDetailsAPI.index(companyId).then((response) => {
				setAgentDetails(response.data);
				setAgentDetailsLoaded(true);
			});
		}
	}, [agentDeatilsLoaded, agentDetailsAPI, setAgentDetailsLoaded, companyId]);

	const uploadLettingStatement = (fileToUpload: File, docId: number) => {
		setUploadingLettingStatement(undefined);
		!uploading &&
			agentStatementsAPI
				?.uploadLettingStatement(companyId, docId, { fileToUpload: fileToUpload })
				.then((response) => {
					if (response.status === 201) {
						setBannerProps((data) => ({ ...data, showBanner: true, body: 'Agent statement uploaded successfully', type: 'success' }));
						setAgentStatementsLoaded(false);
						setUploading(false);
					}
				})
				.catch((error) => error);
	};

	useEffect(()=>{
		const storyblokApi = getStoryblokApi();
		storyblokApi.get('cdn/stories/blank-slate/letting-statements', { version: 'published'})
		.then(res => {
			setBlankSlateInfo(res.data.story.content)
		}).catch(err => {
			console.error(err)
		});
	},[])

	const diyAgentStatements = useHasPermission()(Entity.LettingStatement, Action.All)[0]

	const handleOnClick =(lettingStatementId: number, status: LettingStatementStatus) =>{
		if(diyAgentStatements && status !== 2){
			navigateToEntity(Entity.LettingStatement,Action.Edit,{ id: lettingStatementId })
		}
		else{
			navigateToEntity(Entity.LettingStatement,Action.Read,{ id: lettingStatementId })
		}
	}

	return (
		<DefaultLayout entity={Entity.LettingStatement} greyBackground subLayout title="Letting statements">

			{/* File upload modal */}
			{uploadingLettingStatement && (
				<FileUploadModal
					onCancelClick={() => setUploadingLettingStatement(undefined)}
					onFilesDropped={(acceptedFiles) => uploadLettingStatement(acceptedFiles[0], uploadingLettingStatement.id)}
					maxFiles={1}
					uploading={uploading}
					maxSizeMB={10}
					accept={'documents'}
				/>
			)}

			{isCAP && agentStatementsLoaded && agentStatements?.length===0 &&
				<BlankStateCard content={blankSlateInfo} />
			}

      		{ (!(agentStatementsLoaded && agentStatements?.length===0) || !isCAP )&&
				<DataGrid.Table >
					<thead>
						<tr>
							<th>Date</th>
							<th>Description</th>
							<th>Letting agent</th>
							<th>Statement number</th>
							<th>Statement</th>
							<th>Net payment</th>
							<th>Status</th>
						</tr>
					</thead>
					<tbody>
						{!agentStatementsLoaded && (
							<>
								<DataGrid.LoadingRow cols={7} />
								<DataGrid.LoadingRow cols={7} />
								<DataGrid.LoadingRow cols={7} />
							</>
						)}
						{agentStatementsLoaded && agentStatements && agentStatements.length === 0 && (
							<tr>
								<td colSpan={7} style={{ padding: 0, cursor: 'default' }}>
									<NoContentSlate type={DatagridType.LettingStatements} termsKey="emptyTerms" />
								</td>
							</tr>
						)}
						{agentStatements?.map((agentStatement) => (
							<tr 
								onClick={()=>handleOnClick(agentStatement.id, agentStatementStatus(agentStatement.statementDocumentID, agentStatement.detailCompletedOn))} 
								key={agentStatement.id}
							>
								<td>{formatDate(agentStatement.date)}</td>
								<td style={{maxWidth: '225px'}}>{agentStatement.transactionDescription}</td>
								<td>{agentDetails?.find((agent) => agent.id === agentStatement.lettingAgentID)?.name}</td>
								<td>{agentStatement.statementNumber}</td>
								<td>
									{!agentStatement.statementDocumentID ? (
										<TableIcon icon="upload" onClick={(e) => {e.stopPropagation(); setUploadingLettingStatement(agentStatement)}} />
									) : (
										<TableIcon icon="file" onClick={(e) => {e.stopPropagation(); setStatementModal(agentStatement);}} />
									)}
								</td>
								<td>{formatCurrency(agentStatement.netPaymentToLandlord)}</td>
								<td>
									<Badge
										style={{ borderRadius: '2px' }}
										variant={
											agentStatementStatus(agentStatement.statementDocumentID, agentStatement.detailCompletedOn) === 0
												? (diyAgentStatements ? 'warning' : 'danger')
												: agentStatementStatus(agentStatement.statementDocumentID, agentStatement.detailCompletedOn) === 1
												? 'warning'
												: 'success'
										}>
										{agentStatementStatus(agentStatement.statementDocumentID, agentStatement.detailCompletedOn) === 0
											? (diyAgentStatements ? 'In progress' : 'WAITING FOR DOCUMENT')
											: agentStatementStatus(agentStatement.statementDocumentID, agentStatement.detailCompletedOn) === 1
											? (diyAgentStatements ? 'In progress' : 'PROCESSING')
											: (diyAgentStatements ? 'Completed' : 'PROCESSED')}
									</Badge>
								</td>
							</tr>
						))}
					</tbody>
				</DataGrid.Table>
			}
			<Modal
				size="xl"
				showModal={statementModal !== undefined}
				hideModal={() => setStatementModal(undefined)}
				title="Agent statement"
				buttons={[
					<Button buttonType="close" key="closeBtn" onClick={() => setStatementModal(undefined)} />,
					<a key="downloadBtn" href={statementModal?.statementUrl} target="_blank" download rel="noopener noreferrer">
						<Button buttonType="download" />
					</a>,
					//TODO:Enable if required.
					// hasPermission(Entity.InvoiceAdmin, Action.All)[0] && (
					// 	<Button
					// 		label="Change document"
					// 		buttonType="moveToInbox"
					// 		onClick={() => {
					// 			setStatementModal(undefined);
					// 			setUploadingLettingStatement(statementModal);
					// 		}}
					// 		admin
					// 	/>
					// ),
				]}>
				{/* {statementModal && statementModal.statementUrl && <ZoomImage maxHeight="60vh" src={statementModal?.statementUrl} alt="Receipt" />} */}

				{statementModal && statementModal.statementUrl && /.pdf|.xls|.csv/.test(statementModal.statementUrl) && 
                        <div className={styles.noPreview}>
                            <img src={placeholderImage} alt="Placeholder" />
                            <>Sorry, there is currently no preview available, please download the file instead</>
                        </div>}

				{statementModal && statementModal.statementUrl && !/.pdf|.xls|.csv/.test(statementModal.statementUrl) && 
					<img style={{maxWidth: '100%'}} src={statementModal.statementUrl} alt="Statement"/>}
			</Modal>
			<DismissibleBanner bannerProps={bannerProps} />
		</DefaultLayout>
	);
}

export default AgentStatements;
