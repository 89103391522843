import { useContext, useEffect, useState } from "react"
import { Modal } from "../../Modal/Modal"
import ZoomImage from "../../../elements/ZoomImage/ZoomImage"
import { useInniAPI } from "../../../hooks/useInniAPI";
import CompanyContext from "../../../context/CompanyContext";
import { Receipt } from "../../../api/inni/data-contracts";
import { Button } from "../../../elements/Button/Button";
import { Receipts as ReceiptsAPI } from '../../../api/inni/Receipts'
import { useModalDialog } from "../../../hooks/useModalDialog";
import styles from '../AccountViewerNew.module.css'

interface ReceiptModalProps{
    documentId: number |undefined
    hideModal: () => void
    deleteReceipt: (documentId : number, paymentId : number) => void
    detachReceipt: (i : number) => void
}

const ReceiptModal=({documentId, hideModal, deleteReceipt, detachReceipt}: ReceiptModalProps)=>{

    const companyContext = useContext(CompanyContext);
    const receiptAPI = useInniAPI(ReceiptsAPI);
    const [receiptData, setReceiptData] = useState<Receipt|undefined>();
    const [showModalDialog, modalDialog] = useModalDialog();
    
    useEffect(() =>{
        if(receiptAPI && documentId){
            receiptAPI.getReceipt(companyContext.cid, documentId)
                .then((res) =>{
                    setReceiptData(res.data)
                })
        }
    },[documentId, companyContext.cid, receiptAPI])

    const showDeleteReceiptDialog = (documentId : number, paymentId : number) => {
        showModalDialog(
            'Delete receipt?',
            `Are you sure you want to delete this receipt?`,
            [
                <Button key="delete" variant="danger" label="Yes" onClick={() => deleteReceipt(documentId, paymentId)} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    return (

        <>
            {receiptData &&
                <Modal 
                    size="xl" 
                    showModal={true} 
                    hideModal={hideModal} 
                    title="Receipt image"
                    buttons={[
                        <Button buttonType="close" key="closeBtn" onClick={hideModal} />, 
                        <a key="downloadBtn" href={receiptData?.imageDownloadUrl}><Button buttonType="download" /></a>,
                        <Button key="inboxBtn" buttonType="moveToInbox" onClick={() => detachReceipt(receiptData?.documentId || 0)} />,
                        <Button key="deleteBtn" buttonType="delete" onClick={() => showDeleteReceiptDialog(receiptData?.documentId || 0, receiptData?.bankPaymentId || 0)} />
                    ]}
                >
                    {receiptData && receiptData.imageUrl && (
                        receiptData.imageUrl.indexOf('.pdf') !== -1 ?
                        <div className={styles.altText}>
                            Sorry, this PDF does not have a thumbnail yet. <br/> Alternatively, you can use the download link below
                        </div> :
                        <ZoomImage maxHeight="60vh" src={receiptData.imageUrl} alt="Receipt" />
                    )}
                </Modal>
            }
            {modalDialog}
        </>
    )

}

export default ReceiptModal;