import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity, Action } from "../../utils/EntityAction";
import PortalProfile from "../../features/UserPortal/Profile";

const portalProfile:Array<EntityRoute> = [
    {
        path: '/user/profile',
        exact: true,
        context: RouteContext.User,
        entity: Entity.Contact,
        action: Action.Read,
        component: PortalProfile,
    }
]

export default portalProfile;
