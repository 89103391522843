import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Onboarding } from '../../../api/inni/Onboarding';
import CompanyContext from '../../../context/CompanyContext';
import OnboardingContext from '../../../context/OnboardingContext';
import { useInniAPI } from '../../../hooks/useInniAPI';
import * as DataGrid from '../../DataGrid/DataGrid';
import { SbBlokData } from '@storyblok/react';
import { NoContentSlate } from '../../Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { FileUploadModal } from '../../../components/FileUploadModal/FileUploadModal';
import { Button } from '../../Button/Button';
import { TUploadState } from './UploadCSV';
import { MortgageAttachmentModel } from '../../../api/inni/data-contracts';
import { ICSFile, ICSUploadTable } from '../../../hooks/useUploadDocumentsTable';
import StandardFileField from '../../StandardFileField/StandardFileField';
import { useModalDialog } from '../../../hooks/useModalDialog';
type Props = { blok: SbBlokData };
interface CSUploadState extends TUploadState {
	mortgageAccountId: number;
}

const CompletionStatementUpload = ({ blok }: Props) => {
	const { uploadMortgageDocumentsFormik } = useContext(OnboardingContext);
	const onboardingApi = useInniAPI(Onboarding);
	const { cid } = useContext(CompanyContext);
	const [showModalDialog, modalDialog] = useModalDialog();
	const [tableLoader, setTableLoader] = useState(false);
	const [showNewFiles, setShowNewFiles] = useState({} as { [key: string]: boolean });
	const [uploadState, setUploadState] = useState<CSUploadState>({
		showModal: false,
		id: 0,
		isNew: false,
		mortgageAccountId: 0,
	});
	const { values, setValues } = useMemo(() => uploadMortgageDocumentsFormik, [uploadMortgageDocumentsFormik]);
	const getClickedItem = useCallback((id: number): ICSUploadTable | undefined => values.find((v) => v.id === id), [
		values,
	]);
	const onUploadNew = useCallback(
		(files: File[], currentItem: ICSUploadTable) => {
			const updatedItem: ICSUploadTable = {
				id: uploadState.id,
				name: currentItem ? currentItem.name : '',
				showNewBtn: true,
				files: [
					...(currentItem?.files || []),
					{
						fileToUpload: files[0],
						fileName: files[0].name,
						isNew: true,
						isUpdated: false,
						mortgageDocumentId: new Date().getTime(),
						mortgageAccountId: uploadState.mortgageAccountId,
					},
				],
			};
			currentItem && setValues((prev) => prev.map((form) => (form.id === currentItem.id ? updatedItem : form)));
		},
		[setValues, uploadState.id, uploadState.mortgageAccountId]
	);
	const updateFile = (currentItem: ICSUploadTable, id: number, files: File[]) => {
		return currentItem?.files.map((v, i) =>
			v.mortgageAccountId === id
				? {
						...v,
						fileToUpload: files[0] || null,
						isNew: v.isNew,
						fileName: files[0].name || '',
						isUpdated: !v.isNew,
				  }
				: v
		);
	};
	const updateFiles = useCallback(
		(currentItemId: number, files: ICSFile[]) => {
			setValues((prev) =>
				prev.map((form) =>
					form.id === currentItemId
						? {
								...form,
								showNewBtn: true,
								files,
						  }
						: form
				)
			);
		},
		[setValues]
	);
	const uploadFiles = useCallback(
		(files: File[]) => {
			const currentItem = getClickedItem(uploadState.id);
			if (currentItem) {
				if (uploadState.isNew) {
					onUploadNew(files, currentItem);
				} else {
					const updateItem = currentItem.files.find(
						(v) => v.mortgageAccountId === uploadState.mortgageAccountId
					);
					const updatedFiles = updateFile(currentItem, updateItem?.mortgageAccountId || 0, files);
					updateFiles(currentItem.id, updatedFiles);
				}
			}

			currentItem &&
				setShowNewFiles((prev) => ({ ...prev, [`${currentItem.id}-${uploadState.mortgageAccountId}`]: true }));
			setUploadState((prev) => ({
				...prev,
				id: 0,
				showModal: !prev.showModal,
				isNew: false,
				isUpdate: false,
				mortgageAccountId: 0,
			}));
		},
		[getClickedItem, onUploadNew, updateFiles, uploadState.id, uploadState.isNew, uploadState.mortgageAccountId]
	);

	const showNewBtn = (mortgageStatements: MortgageAttachmentModel[]) =>
		!!(
			(mortgageStatements && mortgageStatements.length > 0 && mortgageStatements[0].fileName) ||
			(mortgageStatements && mortgageStatements.length > 1)
		);

	useEffect(() => {
		if (onboardingApi && cid) {
			setTableLoader(true);
			onboardingApi
				.getFileAttachments(cid)
				.then(({ data }) => {
					if (data && data.properties) {
						setValues(
							data.properties
								.filter((v) => v.mortgageStatements?.length)
								.map((v) => ({
									name: v.name || '',
									id: v.relatedId,
									showNewBtn: showNewBtn(v.mortgageStatements || []),
									files: v.mortgageStatements?.length
										? v.mortgageStatements.map((v) => ({
												...v,
												fileToUpload: null,
												isNew: v.fileName ? false : true,
												isUpdated: false,
										  }))
										: [],
								})),
							false
						);
					}
				})
				.finally(() => setTableLoader(false));
		}
	}, [cid, onboardingApi, setValues]);

	const addNewEmptyRow = (id: number) => {
		setUploadState((prev) => ({
			...prev,
			id,
			showModal: !prev.showModal,
			isNew: true,
			mortgageAccountId: new Date().getTime(),
		}));
	};
	const removeAttachmentFromList = useCallback(
		(currentItem: ICSUploadTable, mortgageAccountId: number, propertyId: number) => {
			const updatedCurrentItem = {
				...currentItem,
				files: currentItem.files.filter((file) => file.mortgageAccountId !== mortgageAccountId),
			};
			setValues((prev) => prev.map((v) => (v.id === propertyId ? updatedCurrentItem : v)));
		},
		[setValues]
	);

	const removeAttachment = useCallback(
		(id: number, mortgageAccountId: number, isNew: boolean) => {
			const currentItem = getClickedItem(id);
			if (currentItem) {
				isNew && removeAttachmentFromList(currentItem, mortgageAccountId, id);
				if (!isNew && onboardingApi && cid) {
					onboardingApi
						.deleteMortgageLenders(cid, mortgageAccountId)
						.then(() => removeAttachmentFromList(currentItem, mortgageAccountId, id));
				}
			}
		},
		[cid, getClickedItem, onboardingApi, removeAttachmentFromList]
	);

	const showDeleteModal = (id: number, mortgageAccountId: number, isNew?: boolean) => {
		showModalDialog(
			'Delete mortgage statement?',
			`Are you sure you want to delete this mortgage statement?`,
			[
				<Button
					key="yes"
					variant="danger"
					label="Yes"
					onClick={() => removeAttachment(id, mortgageAccountId, isNew || false)}
				/>,
				<Button key="no" variant="secondary" label="No" onClick={() => {}} />,
			],
			false
		);
	};

	return (
		<>
			<DataGrid.Table>
				<thead>
					<tr>
						<th style={{ width: '33%' }}>Property</th>
						<th style={{ width: '67%' }}>Mortgage statement(s)</th>
					</tr>
				</thead>
				<tbody>
					{tableLoader && (
						<>
							<DataGrid.LoadingRow cols={2} />
							<DataGrid.LoadingRow cols={2} />
						</>
					)}
					{!tableLoader && (
						<>
							{values.length > 0 ? (
								values.map(({ name, id, files, showNewBtn }, i) => (
									<tr key={id}>
										<td style={{ width: '33%' }}>{name}</td>
										<td className="px-0" style={{borderLeft: '1px solid rgba(10, 10, 10, 0.1)'}}>
											{files.map((file: ICSFile, index: number) => (
												<div key={`${file.mortgageAccountId}-${id}`}>
													<div className='px-3'>
														<StandardFileField
															id={`[${i}-${blok.component}-${index}].files`}
															name={`[${i}-${index}].files`}
															onClick={() =>
																setUploadState((prev) => ({
																	...prev,
																	id,
																	showModal: !prev.showModal,
																	mortgageAccountId: file.mortgageAccountId,
																}))
															}
															fileName={file.fileName || ''}
															showNewFile={
																showNewFiles[`${id}-${file.mortgageAccountId}`]
															}
															showDeleteBtn={files.length > 1}
															onDelete={() =>
																showDeleteModal(id, file.mortgageAccountId, file.isNew)
															}
														/>
													</div>
													{showNewBtn && <hr className="m-0 my-2" />}
												</div>
											))}
											{showNewBtn && (
												<Button
													className="px-3 ml-3 my-0"
													buttonType="new"
													small
													onClick={() => addNewEmptyRow(id)}
												/>
											)}
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={2} style={{ padding: 0 }}>
										<NoContentSlate type={DatagridType['Mortgages']} termsKey="emptyTerms" />
									</td>
								</tr>
							)}
						</>
					)}
				</tbody>
			</DataGrid.Table>

			{uploadState.showModal && (
				<FileUploadModal
					onCancelClick={() => setUploadState((prev) => ({ ...prev, id: 0, showModal: !prev.showModal }))}
					onFilesDropped={(acceptedFiles) => uploadFiles(acceptedFiles)}
					maxFiles={1}
					uploading={false}
					maxSizeMB={10}
					accept={'documents'}
					showDropZoneText
				/>
			)}

			{modalDialog}
		</>
	);
};

export default CompletionStatementUpload;
