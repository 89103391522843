import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { useContext, useMemo, useState } from 'react';
import { TenancyPostModel } from '../../../api/inni/data-contracts';
import { Text, Currency, SelectList, ListOption, Switch, Submit, SubHeading, DateSelector }  from '../../../elements/EditRow/EditRow'
import { useBrandingThemesList } from '../../../hooks/lists/useBrandingThemesList';
import { LettableUnitListItem } from '../../../hooks/lists/useLettableUnitsList';
import { useLettingAgentsList } from '../../../hooks/lists/useLettingAgentsList';
import { formatLettingType } from '../../../utils/formatters/formatProperty';
import AddNewAgentOptionModal from './AddNewAgentOptionModal';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { Entity, Action } from '../../../utils/EntityAction';
import styles from '../Tenancy.module.scss';
import classnames from 'classnames';
import CompanyContext from '../../../context/CompanyContext';

export interface TenancyEditFormProps {
    initialValues: TenancyPostModel,
    readOnlyFields?: Record<string, string>,
    formSubmit: (values: TenancyPostModel, actions: FormikHelpers<TenancyPostModel>) => Promise<void>,
    formValidate: (values: TenancyPostModel) => Promise<FormikErrors<TenancyPostModel>>,
    onCancelClick?: () => void,
    lettableUnits: LettableUnitListItem[]
}

/*
Not confident this will work when editing existing

const RentReviewWatcher = () => {
    const { values, setFieldValue } = useFormikContext<TenancyPostModel>();
    const [lastReviewPeriod, setLastReviewPeriod] = useState('');

    useEffect(() => {
        if (values.rentReviewInterval !== lastReviewPeriod) {
            setLastReviewPeriod(values.rentReviewInterval || '');
            if (values.rentReviewInterval !== '') {
                let months = parseInt(values.rentReviewInterval || '0');
                if (months > 0) {
                    let d = new Date(values.startDate);
                    d.setMonth(d.getMonth() + months);
                    setFieldValue('rentReviewDate', d.toISOString().split('T')[0]);
                }
            }
        }

    }, [values, lastReviewPeriod, setFieldValue])

    return null;
}
*/

export const TenancyEditForm = ({initialValues, readOnlyFields, formSubmit, formValidate, onCancelClick, lettableUnits} : TenancyEditFormProps) => {
    const [showAddNewAgentModal, setShowAddNewAgentModal] = useState(false);
    const [themes] = useBrandingThemesList();
    const {agents, addNewAgentOption} = useLettingAgentsList();
    const unitList = useMemo(() => {
        let out:Array<ListOption> = [];
        lettableUnits.forEach(unit => {
            out.push({
                value: unit.id.toString(), 
                label: unit.fullName,
                icon: formatLettingType(unit.lettingType).icon,
                iconColor: 'var(--primary)'
            })
        })
        return out;
    }, [lettableUnits])
    const companyContext = useContext(CompanyContext)
    const v8Styling = companyContext.company?.useV8UI;

    const themeList = useMemo(() => {
        let out:Array<ListOption> = [];
        themes.forEach(theme => {
            out.push({
                value: theme.id.toString(), 
                label: theme.name||''
            })
        } )
        return out;
    }, [themes])

    const agentsList = useMemo(() => {
        let out:Array<ListOption> = [];
        agents.forEach(theme => {
            out.push({
                value: theme.id.toString(), 
                label: theme.name||''
            })
        } )
        return out;
    }, [agents])

    const readOnlyMessage = (fieldName: string) => {
        if (readOnlyFields) {
            if (fieldName in readOnlyFields) {
                return readOnlyFields[fieldName];
            }
        }
        return undefined;
    }

    const hasPermission = useHasPermission()
    const hasInvoicing = hasPermission(Entity.Invoice, Action.All)[0] || hasPermission(Entity.InvoiceV7, Action.All)[0] //InvoiceV7 still admin only

    return (
        <>
            <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={formSubmit}
            validateOnChange={false}       
            validate={formValidate}       
        >
            {({ isSubmitting, values, setFieldValue }) => (
                <Form >
                    {v8Styling && <h2 className={styles.subheading}>Rental information</h2>}
                    <SelectList<TenancyPostModel>
                        name="propertyLettableUnitId"
                        label="Property"
                        options={unitList}
                        readOnlyWithMessage={readOnlyMessage('propertyLettableUnitId')}
                    /> 

                    <DateSelector<TenancyPostModel> name='startDate' label="Tenancy start date" />

                    <DateSelector<TenancyPostModel>
                        name="endDate"
                        label="Tenancy end date"
                        help="Can be left blank for ongoing / periodic contracts"
                    />

                    <DateSelector<TenancyPostModel>
                        name='moveInDate'
                        label="Move in date"
                        help='Agreed move in date, can be left blank'
                    />

                    <DateSelector<TenancyPostModel>
                        name="moveOutDate"
                        label="Move out date"
                        help="Agreed move out date, can be left blank"
                    />

                    <Currency<TenancyPostModel>
                        name='rentAmount'
                        intervalLabel='per'
                        intervals={['W','M','Q','A']}
                        intervalFieldName='rentInterval'
                        min={0}
                        step='0.01'
                        onlyPositiveNumbers
                    />

                    <Text<TenancyPostModel>
                        name="rentReviewInterval"
                        label="Rent review period"
                        type="number"
                        suffix="months"
                        help="How often rent should be reviewed, can be left blank"
                        min={0}
                        onlyPositiveNumbers
                        onlyIntegers
                    />

                    <DateSelector<TenancyPostModel> name='rentReviewDate'  label='Next rent review'  help='Can be left blank' />

                    <Text<TenancyPostModel> name='notes' type='textarea' />

                    {!v8Styling && <SubHeading>Management</SubHeading>}

                    <Switch<TenancyPostModel>
                        name="tenancyIsManaged"
                        label="Agency managed"
                        help="Does an agent or another firm manage this tenancy?"
                    />

                    {values.tenancyIsManaged &&
                        <>
                            <SelectList<TenancyPostModel> name='lettingAgentId' label='Agent name' addNew={() => setShowAddNewAgentModal(true)} options={agentsList} /> 
                            <Currency<TenancyPostModel> 
                                name='agentFees'  
                                intervalLabel='per' 
                                intervals={['W','M','Q','A']} 
                                intervalFieldName='agentFeesInterval'  
                                min={0}
                                step='0.01'
                                onlyPositiveNumbers 
                            />
                        </>
                    }
                    
                    { v8Styling ?
                        <h2 className={classnames(styles.subheading, styles.seperation)}>Lead tenant
                            {values.tenancyIsManaged &&
                                <small className='ml-2'>(optional)</small>
                            }
                        </h2>
                    : 
                        <SubHeading>
                            <>
                                Lead tenant 
                                {values.tenancyIsManaged &&
                                    <small className='ml-2'>(optional)</small>
                                }
                            </>
                        </SubHeading>

                    }
                    
                    <Text<TenancyPostModel> name='tenantTitle' label='Title' help='Mrs, Mr, etc' small />
                    <Text<TenancyPostModel> name='tenantFirstName' label='First name' />
                    <Text<TenancyPostModel> name='tenantLastName' label='Last name' />
                    <Text<TenancyPostModel> name='tenantEmail' label='Email address' type='email' />
                    <Text<TenancyPostModel> name='tenantPhoneMobile' label='Phone (mobile)' />
                    <Text<TenancyPostModel> name='tenantPhoneHome' label='Phone (home)' />
                    <Text<TenancyPostModel> name='tenantPhoneWork' label='Phone (work)' />

                    {hasInvoicing && 
                        <>
                            { v8Styling ?
                                <h2 className={classnames(styles.subheading, styles.seperation)}>Invoicing</h2>
                            : 
                                <SubHeading>Invoicing</SubHeading>
                            }

                            <Text<TenancyPostModel> name='invoiceDescription' />
                            <Text<TenancyPostModel> name='invoiceMessage' type='textarea' help='Shown on each invoice: this is an ideal place to put your bank details' />
                            <SelectList<TenancyPostModel> name='brandingThemeId' label='Invoice theme' options={themeList} /> 
                            <Text<TenancyPostModel> name='autoEmailSubject' label='Email subject' />
                            <Text<TenancyPostModel> name='autoEmailBody' type='textarea'  label='Email message'/>
                            <Text<TenancyPostModel> 
                                name='invoiceDueDays' 
                                label='Payment due within' 
                                type='number' suffix='days' 
                                help='How long, after the date of the invoice, is the payment due?' 
                                min={0}
                                onlyPositiveNumbers
                                onlyIntegers
                            />
                        </>
                    }
                    
                    <Submit disabled={isSubmitting} onCancelClick={onCancelClick} marginTop />

                    {showAddNewAgentModal && (
                        <AddNewAgentOptionModal 
                            hide={() => setShowAddNewAgentModal(false)} 
                            create={(pm)=> addNewAgentOption(pm, ({id}) => {
                                setShowAddNewAgentModal(false)
                                setFieldValue('lettingAgentId', id)
                            })}
                        />
                    )}
                </Form>
                
            )}
            </Formik>  

           
        </>

    )

}