import { PvSatrPostModel } from "../../../../api/inni/data-contracts";
import FormikField from "../../../../elements/FormikField/FormikField";
import styles from '../Income.module.scss'
import Attachments from "../../../../components/Attachments/Attachments";
import { useEffect, useState } from "react";
import { FileRejection } from "react-dropzone";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";

interface OtherIncomeProps{

    onFilesDropped: (acceptedFiles: File[], fileRejections: FileRejection[],  section: string) => void;
    filesToUpload: File[]|undefined|null;
    removePendingFile: (fileName: string, section: string) => void;
    removeAllFiles: (section: string) => void;
}

export default function OtherIncome ({onFilesDropped, filesToUpload, removePendingFile, removeAllFiles}:OtherIncomeProps){

    const { values, setFieldValue } = useFormikContext<PvSatrPostModel>();
    const[showOtherIncome, setShowOtherIncome] = useState(
                                                            !!values.otherIncomeDetails ||
                                                            values.attachments?.some(x => x.section === "Other")
                                                        )
    
    useEffect(() =>{
        if(!showOtherIncome)
        {
            setFieldValue("otherIncomeDetails", undefined, false)
            removeAllFiles("Other");
        }

    },[showOtherIncome])
    
    const handleFileDrop=(acceptedFiles: File[], fileRejections: FileRejection[])=>{
        onFilesDropped(acceptedFiles, fileRejections, "Other" )
    }

    const handleRemoveFile =(fileName:string)=>{
        removePendingFile(fileName, "Other" )
    }

    
    return(
        <>
            <Form.Group>
                <Form.Label>Have you received any other forms of income in this tax year?</Form.Label>
                    <Form.Check
                        type='checkbox'
                        checked={showOtherIncome}
                        onChange={(e) => setShowOtherIncome(e.target.checked)}
                    />
            </Form.Group>

            {showOtherIncome &&

                <>
                    <FormikField<PvSatrPostModel> 
                        className={styles.formikField}
                        name={"otherIncomeDetails"}
                        label="Please provide details of the amount and the source of any other income"
                        type="textarea"                                     
                        regularLabel
                        wrapLabel
                    />

                    <div className={styles.attachment}>
                        <Attachments
                            title='Attach files'
                            accept={['image/*','.pdf','.doc','.docx']}
                            onDrop={handleFileDrop}
                            message='Please upload any relevant supporting documents for this income'
                            attachedFiles={filesToUpload}
                            onRemoveFile={handleRemoveFile}
                            hidefiles
                            regularLabel
                            fullWidth
                        />
                    </div>
                </>

            }
            
        </>  
    )
}