import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import ErrorPage from "../../layouts/Components/ErrorPage";


const error:Array<EntityRoute> = [
    {
        path: '/company/:cid/*',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.ErrorPage,
        component: ErrorPage,
        action: Action.Read
    }, 
]

export default error;