import React from "react"
import { SimpleKpi } from "../../../elements/DashboardCards/SimpleKpi"
import { formatPercent } from "../../../utils/formatNumbers"


export const LtvKpi = ({loading, value} : {loading?: boolean, value?: number}) => {
    return (
        <SimpleKpi loading={loading}  label='LTV' value={formatPercent(value)} helpText='Loan to value ratio' height={4} />
    )
}
