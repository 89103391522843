import { useState, useEffect } from 'react';
import { useGetWidth } from './useGetWidth';
import configHelper from '../utils/configHelper';

const useIsMobile = (method: 'basic' | 'userAgent' = 'basic') => {
  const [isMobile, setIsMobile] = useState(false);
  const width = useGetWidth();

  useEffect(() => {
    const determineIsMobile = () => {
      if (method === 'userAgent') {
        // Allow 'iPad' for now
        return (configHelper.useMobile) || (/Mobi|Android|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
      }

      // Fallback to basic for legacy
      return width < 768 || configHelper.useMobile;
    };

    setIsMobile(determineIsMobile());
  }, [width, method]);

  return isMobile;
};

export default useIsMobile;
