import { useCallback, useState } from 'react';
import { Currency, DatePicker, Invoice, Market } from '../../../api/inni/data-contracts';
import { Filter } from '../../../components/AccountsFilter/Filter';
import SearchWithClear from '../../../elements/SearchWithClear/SearchWithClear';
import { InvoiceViewerNewLoading } from './Loadingtemplate';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { formatDate } from '../../../utils/formatDate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { Action, Entity } from '../../../utils/EntityAction';
import {
	getInvoicePaymentStatus,
	getMarketFilterTerm,
	InvoiceListTableHeadersIsCompany,
	InvoiceListTableHeadersIsProperty,
} from '../HelperFunctions/ShowField';
import styles from '../Invoice.module.css';
import { currencyConvertor, getUniqueValues } from '../HelperFunctions/HelperFunctions';
import Select from 'react-select';
import { Badge } from 'react-bootstrap';

type InvoiceViewerNewProps = {
	getFilter: (yearCode: string | undefined) => Promise<DatePicker>;
	getInvoiceData: Invoice[] | undefined;
	invoiceLoaded: boolean;
	market: Market | undefined;
	currentYear?: string;
	setCurrentYear: (yearCode: string | undefined) => void;
	currency: Currency[] | undefined;
};
type InvoiceFilterProps = {
	setFilter: any;
	options: Array<{ value: string; label: string }> | undefined;
	filterName: string;
};
interface InvoiceTablePageProps {
	invoiceData: Invoice[] | undefined;
	invoiceLoaded: boolean;
	market?: Market;
	currentYear?: string;
	currency: Currency[] | undefined;

}
function InvoiceListPageWrapper({ getFilter, getInvoiceData, invoiceLoaded, market, currentYear, setCurrentYear ,currency }: InvoiceViewerNewProps) {
	//FilterTab Logic
	const [dateSelection, setDateSelection] = useState<[Date | undefined, Date | undefined]>([undefined, undefined]);
	const [newMonth, setNewMonth] = useState<number | undefined | null>(undefined);
	const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
	const [filterByType, setFilterByType] = useState<string | undefined>(undefined);
	const [filterByTenant, setFilterByTenant] = useState<string | undefined>(undefined);
	const [filterByPayment, setFilterByPayment] = useState<string | undefined>(undefined);
	const filterMonthChanged = useCallback((startDate: Date, endDate: Date) => {
		setDateSelection([startDate, endDate]);
	}, []);

	//Filters the datalist based on the month selected by the user.
	const filterByMonth = (data: Invoice) => {
		const viewDate = new Date(data.date);
		return dateSelection[0] && dateSelection[1] && viewDate >= dateSelection[0] && viewDate <= dateSelection[1];
	};

	//Filter the data based on user input in the searchbar and select boxes
	const filterTableData = (data: Invoice[] | undefined) => {
		return data
			?.filter((x) => filterByMonth(x))
			.filter((x) =>
				searchTerm
					? (x.description && x.description.toLowerCase().includes(searchTerm.toLowerCase())) ||
					  (x.contract && x.contract.toLowerCase().includes(searchTerm.toLowerCase()))
					: true
			)
			.filter((x) => (filterByType ? x.documentTypeName === filterByType : true))
			.filter((x) => (filterByTenant ? x.contract === filterByTenant : true))
			.filter((x) => (filterByPayment ? x.status === filterByPayment : true));
	};

	return (
		<>
			<div className="d-md-flex justify-content-start mb-2 ">
				<InvoiceFilter
					options={getInvoiceData && getUniqueValues<Invoice>(getInvoiceData, 'documentTypeName').map((item: any) => ({ value: item, label: item }))}
					setFilter={setFilterByType}
					filterName="Any type"></InvoiceFilter>
				{market && (
					<InvoiceFilter
						options={getInvoiceData && getUniqueValues<Invoice>(getInvoiceData, 'contract').map((item: any) => ({ value: item, label: item }))}
						setFilter={setFilterByTenant}
						filterName={'All ' + getMarketFilterTerm(market.marketId)}></InvoiceFilter>
				)}
				<InvoiceFilter
					options={
						getInvoiceData && getUniqueValues<Invoice>(getInvoiceData, 'status').map((item: any) => ({ value: item, label: item }))
					}
					setFilter={setFilterByPayment}
					filterName="Any status"></InvoiceFilter>
				<div style={{ minWidth: '200px' }}>
					<SearchWithClear search={searchTerm || ''} setSearch={setSearchTerm} placeholder="Search documents...." />
				</div>
			</div>

			<Filter
				newMonth={newMonth}
				year={currentYear}
				searching={searchTerm !== undefined && searchTerm.length > 0}
				getFilter={getFilter}
				yearChanged={setCurrentYear}
				monthChanged={filterMonthChanged}
				clearNewMonth={() => setNewMonth(null)}
			/>

			{dateSelection && dateSelection[0] && dateSelection[1] ? (
				<InvoiceListComponent
				    currency={currency}
					currentYear={currentYear}
					market={market}
					invoiceLoaded={invoiceLoaded}
					invoiceData={filterTableData(getInvoiceData)}></InvoiceListComponent>
			) : (
				<InvoiceViewerNewLoading />
			)}
		</>
	);
}

const InvoiceListComponent = ({ invoiceData, invoiceLoaded, market, currentYear ,currency }: InvoiceTablePageProps) => {
	return (
		<>
			<DataGrid.Table>
				<thead>
					<tr>
						{market?.isProperty
							? Object.values(InvoiceListTableHeadersIsProperty).map((item) => (
									<th className="text-nowrap" key={item}>
										{item}
									</th>
							  ))
							: Object.values(InvoiceListTableHeadersIsCompany).map((item) => (
									<th className="text-nowrap" key={item}>
										{item}
									</th>
							  ))}
					</tr>
				</thead>
				<tbody>
					{!invoiceLoaded && (
						<>
							<DataGrid.LoadingRow cols={10} />
							<DataGrid.LoadingRow cols={10} />
							<DataGrid.LoadingRow cols={10} />
						</>
					)}
					{invoiceLoaded && invoiceData && invoiceData.length === 0 && (
						<tr>
							<td colSpan={12} style={{ padding: 0, cursor: 'default' }}>
								<NoContentSlate type={DatagridType.Invoices} termsKey="emptyTerms" />
							</td>
						</tr>
					)}
					{invoiceLoaded &&
						invoiceData &&
						invoiceData.length > 0 &&
						invoiceData
							.sort((a, b) => (new Date(a.date) > new Date(b.date) && a.invoiceNumber > b.invoiceNumber ? 1 : -1))
							//.sort((a, b) => (a.invoiceNumber > b.invoiceNumber ? 1 : -1))
							.map((invoice) => (
								<DataGrid.EntityNavigateRow
									key={invoice.id}
									entity={Entity.InvoiceV7}
									action={Action.Read}
									extraParams={{ id: invoice.id, year: currentYear ? currentYear : '' }}>
									<td>{formatDate(invoice.date)}</td>
									<td>{invoice.documentTypeName}</td>
									<td>{invoice.invoiceNumber === 0 ? '' : invoice.invoiceNumber}</td>
									<td>{invoice.invoiceReference}</td>
									{market?.isProperty && (
										<>
											<td style={{ width: '20%' }}>{invoice.property}</td>
											<td style={{ width: '20%' }}>{invoice.contract}</td>
										</>
									)}
									<td style={{ width: '25%' }}>{invoice.description}</td>
									{currency && (
										<td>
											{invoice.amount >= 0 && <span>&nbsp;</span>}{' '}
											{currencyConvertor(invoice.amount, currency.find((x) => x.code === invoice.invoiceCurrency)?.symbol)}
										</td>
									)}
									{currency && invoice.documentType !== 'InvoiceTemplate' ? (
										<>
											<td>{currencyConvertor(invoice.balance, currency.find((x) => x.code === invoice.invoiceCurrency)?.symbol)}</td>
											<td>{formatDate(invoice.dateDue)}</td>
										</>
									) : (
										<>
											<td></td>
											<td></td>
										</>
									)}
									<td>
										<InvoiceStatusBadge status={invoice.status} />
									</td>
								</DataGrid.EntityNavigateRow>
							))}
				</tbody>
			</DataGrid.Table>
		</>
	);
};

export const InvoiceStatusBadge = ({ status }: { status: string }) => {
	if (status && status) {
		return (
			<Badge style={{ borderRadius: '2px' }} variant={getInvoicePaymentStatus(status)} color="">
				{status === 'Blank' ? '' : status.toUpperCase()}
			</Badge>
		);
	} else return null;
};

const InvoiceFilter = ({ setFilter, options, filterName }: InvoiceFilterProps) => {
	return (
		<div data-cy="selectBtn">
			<Select
				className={styles.SelectFilter}
				classNamePrefix="SelectFilter"
				onChange={(value) => setFilter(value?.value)}
				options={options && filterName ? [{ value: '', label: filterName }, ...options?.filter((x) => x.label !== 'Blank')] : options}
				defaultValue={{ value: '', label: filterName }}
			/>
		</div>
	);
};

export default InvoiceListPageWrapper;
