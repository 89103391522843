import useCompareChange from '../../../hooks/useCompareChange';
import { CompanyInvoicingSettingsPostModel } from '../../../api/inni/data-contracts';
import { Form, Formik } from 'formik';
import { Text,Submit, Switch } from '../../../elements/EditRow/EditRow';
import { CompanySettingsProps } from '../CompanySettingsDataTypes';


function InvoiceSettingsTab<T extends CompanyInvoicingSettingsPostModel>({
  settings,
  handleSubmit,
  handleValidate
}: CompanySettingsProps<T>) {

  const formikRef = useCompareChange<T>();

  return (
    <Formik
      initialValues={settings as T}
      onSubmit={handleSubmit}
      validate={handleValidate}
      enableReinitialize
      innerRef={formikRef}
      validateOnBlur
      validateOnChange={false}
    >
      {({ isSubmitting, dirty }) => (
        <Form>
          <Text<CompanyInvoicingSettingsPostModel> name="nextInvoiceNumber" label="Next invoice number" />
          <Switch<CompanyInvoicingSettingsPostModel> name="enableForeignInvoices" label="Enable foreign invoices" />
          <Submit disabled={isSubmitting || !dirty} onCancelClick={() => {}} />
        </Form>
      )}
    </Formik>
  )
}

export default InvoiceSettingsTab