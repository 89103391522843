import { useFormik } from 'formik';
import { useCallback, useContext } from 'react';
import { OnboardingPropertyPostModel } from '../api/inni/data-contracts';
import { Onboarding } from '../api/inni/Onboarding';
import CompanyContext from '../context/CompanyContext';
import { useInniAPI } from './useInniAPI';
export interface IPropertyPostModel extends OnboardingPropertyPostModel {
	id: number;
	preAppointmentYearEnd: boolean;
	propertyId?: number;
}

const validateUploadForm = (values: IPropertyPostModel[]) => {
	const errors: { [key: string]: string } = {};
	values.forEach((v, i) => {
		if (!v.propertyId) {
			if (!v.name) {
				errors[`[${i}].name`] = 'This field is required';
			}
			if (!v.purchaseOrSaleDate) {
				errors[`[${i}].purchaseOrSaleDate`] = 'This field is required';
			}
			if (!v.purchaseOrSalePrice) {
				errors[`[${i}].purchaseOrSalePrice`] = 'This field is required';
			}
			if (!v.valuation && v.preAppointmentYearEnd && v.valuation !== undefined) {
				errors[`[${i}].valuation`] = 'This field is required';
			}
		}
	});
	return errors;
};
export const useOnboardingProperties = () => {
	const { cid } = useContext(CompanyContext);
	const onboardingApi = useInniAPI(Onboarding); 
	const ownedPropertiesFormik = useFormik({
		initialValues: [] as IPropertyPostModel[],
		onSubmit: (values) => {},
		validateOnMount: false,
		validate: validateUploadForm,
		validateOnChange: false,
	});
	const soldPropertiesFormik = useFormik({
		initialValues: [] as IPropertyPostModel[],
		onSubmit: (values) => {},
		validateOnMount: false,
		validate: validateUploadForm,
		validateOnChange: false,
	});

	const validateSubmitPropertyRecords = useCallback(() => {
		const ownedValidationPromise = ownedPropertiesFormik.validateForm().then((values) => {
			ownedPropertiesFormik.setErrors(values);
			return !Object.keys(values).length;
		});
		const soldValidationPromise = soldPropertiesFormik.validateForm().then((values) => {
			soldPropertiesFormik.setErrors(values);
			return !Object.keys(values).length;
		});
		ownedPropertiesFormik.submitForm();
		soldPropertiesFormik.submitForm();
		return [ownedValidationPromise, soldValidationPromise];
	}, [ownedPropertiesFormik, soldPropertiesFormik]);

	const mapValues = useCallback(
		(values: IPropertyPostModel[]): OnboardingPropertyPostModel[] =>
			values
				.filter(({ propertyId }) => !propertyId)
				.map(({ name, status, valuation, purchaseOrSaleDate, purchaseOrSalePrice }) => ({
					name,
					status,
					valuation,
					purchaseOrSaleDate,
					purchaseOrSalePrice,
				})),
		[]
	);
	const submitProperties = useCallback(
		(onSuccess?: () => void) => {
			if (onboardingApi && cid) {
				Promise.all(validateSubmitPropertyRecords())
					.then((data) => data.every((v) => v))
					.then((isValid) => {
						if (isValid) {
							onboardingApi
								?.createProperty(cid, [
									...mapValues(ownedPropertiesFormik.values),
									...mapValues(soldPropertiesFormik.values),
								])
								.then(onSuccess)
								.then(() => {
									ownedPropertiesFormik.setValues(prev => prev.map(v => v.propertyId ? v : ({...v, propertyId: v.id})))
									soldPropertiesFormik.setValues(prev => prev.map(v => v.propertyId ? v : ({...v, propertyId: v.id})))
								});
						}
					});
			}
		},
		[cid, mapValues, onboardingApi, ownedPropertiesFormik, soldPropertiesFormik, validateSubmitPropertyRecords]
	);

	return { ownedPropertiesFormik, soldPropertiesFormik, submitProperties };
};
