import { Tab as HorizontalTab, Tabs as HorizontalTabs } from '../../elements/HorizontalTabs/HorizontalTabs';
type Props = {
    activeTab: CompanyTabs ;
    setActiveTab: (i: CompanyTabs) => void;
    isAdmin: boolean;
};

export enum CompanyTabs{
    COMPANY_INFORMATION = 'companyInformation',
    ACCOUNT_SETTINGS = 'accountSettings',
    ONLINE_SYSTEMS = 'onlineSystems',
    VAT_SETTINGS = 'vatSettings',
    INVOICE_SETTINGS = 'invoiceSettings',
    PAYE_SETTINGS = 'payeSettings',
    PENSION_PROVIDER = 'pensionProvider',
    AE_ASSESSMENT = 'aeAssessment',
}

function CompanySettingsTabs({ activeTab,setActiveTab,isAdmin}: Props) {
	return (
		<HorizontalTabs>
			<HorizontalTab
				key="companyInformation"
				keyName="companyInformation"
				title="Company information"
				onClick={() => setActiveTab(CompanyTabs.COMPANY_INFORMATION)}
				selected={activeTab === CompanyTabs.COMPANY_INFORMATION}
			/>
            <HorizontalTab
                key="accountSettings"
                keyName="accountSettings"
                title="Account settings"
                onClick={() => setActiveTab(CompanyTabs.ACCOUNT_SETTINGS)}
                selected={activeTab === CompanyTabs.ACCOUNT_SETTINGS}
            />
            {isAdmin && <HorizontalTab
                key="vatSettings"
                keyName="vatSettings"
                title="VAT"
                onClick={() => setActiveTab(CompanyTabs.VAT_SETTINGS)}
                selected={activeTab === CompanyTabs.VAT_SETTINGS}
                adminOnly
            />}
            {isAdmin && <HorizontalTab
                key="invoiceSettings"
                keyName="invoiceSettings"
                title="Invoicing"
                onClick={() => setActiveTab(CompanyTabs.INVOICE_SETTINGS)}
                selected={activeTab === CompanyTabs.INVOICE_SETTINGS}
                adminOnly
            />}
           {isAdmin && <HorizontalTab
                key="onlineSystems"
                keyName="onlineSystems"
                title="Online systems"
                onClick={() => setActiveTab(CompanyTabs.ONLINE_SYSTEMS)}
                selected={activeTab === CompanyTabs.ONLINE_SYSTEMS}
                adminOnly
            />}
           {isAdmin && <HorizontalTab
                key="payeSettings"
                keyName="payeSettings"
                title="PAYE settings"
                onClick={() => setActiveTab(CompanyTabs.PAYE_SETTINGS)}
                selected={activeTab === CompanyTabs.PAYE_SETTINGS}
                adminOnly
            />}
           {isAdmin && <HorizontalTab
                key="aeAssessment"
                keyName="aeAssessment"
                title="AE assessment"
                onClick={() => setActiveTab(CompanyTabs.AE_ASSESSMENT)}
                selected={activeTab === CompanyTabs.AE_ASSESSMENT}
                adminOnly
            />}
           {isAdmin && <HorizontalTab
                key="pensionProvider"
                keyName="pensionProvider"
                title="Pension provider"
                onClick={() => setActiveTab(CompanyTabs.PENSION_PROVIDER)}
                selected={activeTab === CompanyTabs.PENSION_PROVIDER}
                adminOnly
            />}
		</HorizontalTabs>
	);
}

export default CompanySettingsTabs; 