
import CompanyContext from '../context/CompanyContext';
import { useContext, useState } from 'react';
import { useGetAccessToken } from './useGetAccessToken';

export const useCustomWebRequests = () : (apiPath:string)=>Promise<Response> => {
    const [token,setToken] = useState<string | null>();
    const getAccessToken = useGetAccessToken();
    getAccessToken().then(token => {
        if (token && token[0])
            setToken(token[0]);
        else
            console.error('Didn\'t find token, custom webrequest will fail');
    })
    const companyId = useContext(CompanyContext).cid;
    const apiUrl = window.appConfig.apiUrl;
    const getCustomrequest = async (apiPath: string) => {
        const reqHeaders = new Headers();
        reqHeaders.append('Host', `${apiUrl.replace('http' || 'https', '')}`);
        reqHeaders.append('Authorization', `Bearer ${token}`);
        const requestOptions = {
            method: 'GET',
            headers: reqHeaders,
            redirect: undefined
        };
        return await fetch(`${apiUrl}/api/v2/companies/${companyId}/${apiPath}`, requestOptions);
    }
    return getCustomrequest;
}