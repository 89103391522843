import { AssetAsDetailed, AssetCategories, AssetPostModel, Property } from "../../../api/inni/data-contracts";
import * as DataGrid from "../../../elements/DataGrid/DataGrid";
import styles from '../Asset.module.css'
import { useContext, useEffect, useState } from "react";
import { Formik, FormikHelpers, Form } from "formik";
import CompanyContext from "../../../context/CompanyContext";
import { useInniAPI } from "../../../hooks/useInniAPI";
import { Properties } from "../../../api/inni/Properties";
import FormikField from "../../../elements/FormikField/FormikField";
import { InvoiceReactSelect } from "../../Invoices/CreateInvoice";
import { Assets } from "../../../api/inni/Assets";
import { Submit, Switch } from "../../../elements/EditRow/EditRow";
import { ExpandedViewBackLabel } from "../../../elements/ExpandedViewBackLabel/ExpandedViewBackLabel";

interface EditAssetDataProps{
    data: AssetAsDetailed |undefined;
    isProperty?: boolean;
    onCancel: () => void;
    update: (values: AssetPostModel, actions: FormikHelpers<any>) => Promise<void>,
    back: () => void;
}

const EditAsset =({data, isProperty, onCancel, update, back}: EditAssetDataProps)=>{
    const companyContext = useContext(CompanyContext);
    const propertyAPI = useInniAPI(Properties);
    const [assetCategories,setAssetCategories] = useState<InvoiceReactSelect[]>();
    const assetAPI = useInniAPI(Assets, [400]);
    const [properties,setProperties] = useState<InvoiceReactSelect[]>();
    const [depreciationTypes] = useState([
        { value: "R", label: "Reducing" },
        { value: "S", label: "Straight Line" }
      ]);

    useEffect(() => {
		if (assetAPI && companyContext.company) {
			assetAPI.getAssetCategories(companyContext.cid).then((response: { data: AssetCategories[] }) =>
      setAssetCategories(
					response.data.filter(x => x.tenantId === companyContext.company?.tenantId || x.tenantId === undefined || x.tenantId === null)
            .map((assetCategories: AssetCategories) => {
						return {
							value: assetCategories.id,
							label: assetCategories.name,
						};
					})
				)
			);
		}
	}, [assetAPI, companyContext.cid, companyContext.company]);

    useEffect(() => {
		if (isProperty && propertyAPI) {
			propertyAPI.index(companyContext.cid).then((response: { data: Property[] }) =>
      setProperties(
					[
            {label: 'None', value: undefined},
            ...response.data.map((prop: Property) => {
						return {
							value: prop?.id,
							label: prop?.name,
						};
					})
				])
			);
		}
	}, [propertyAPI, companyContext.cid,isProperty]);

    const validate = (values: AssetPostModel)  => {
        return new Promise((resolve, reject) => {
            if (assetAPI && companyContext.cid && data) {
                return assetAPI
                        . validateUpdate(companyContext.cid, data.id, values)
                        .then(() => resolve({}))
                        .catch((error) => resolve(error.error));
            }				
            else {
                    reject();
                }
            });
    }

    return(
        <>
            { data &&
                <>
                    <ExpandedViewBackLabel backLabel="Assets" title={data.description} onClick={back} />
                   
                    <Formik
                        initialValues={data}
                        enableReinitialize         
                        validateOnChange={false}
                        validate={validate}
                        onSubmit={update}
                    >
                        {({ isSubmitting }) => (
                            <Form> 
                                <div className={styles.rowGroup}>

                                        <FormikField<AssetPostModel >
                                            className={"pl-0"}
                                            label="Purchase date"
                                            name="datePurchased"
                                            type="date"                                                                     
                                        />

                                        <div className={styles.switchWidth}>
                                            <Switch<AssetPostModel> 
                                                name="isFirstYear" 
                                                label="1st year"
                                                labelTop
                                                labelTextMuted
                                                useFullLabelWidth
                                            />
                                        </div>
                                        
                                        <FormikField<AssetPostModel>
                                            className={"pl-0"}
                                            label="Description"
                                            name="description"
                                            type="text"                                        
                                        />
                                        <FormikField<AssetPostModel>
                                            className={"pl-0"}
                                            label="Category"
                                            name="assetCategoryId"
                                            type="select"
                                            placeholder='None' 
                                            option={assetCategories}
                                            width="200px"                                                                           
                                        />

                                        <div className={styles.originalValueWidth}>
                                            <FormikField<AssetPostModel>
                                                className={"pl-0"}
                                                label="Original value"
                                                name="originalValue"
                                                prefix="£"
                                                type="number"   
                                                step="0.01"                                                                               
                                            />
                                         </div>

                                        <FormikField<AssetPostModel>
                                            className={"pl-0"}
                                            label="Disposal date"
                                            name="disposalDate"
                                            type="date"                          
                                        />

                                        { isProperty &&
                                            <FormikField<AssetPostModel>
                                                className={"pl-0"}
                                                label="Property"
                                                name="propertyId"
                                                type="select"
                                                placeholder="None"
                                                option={properties}
                                                width="200px"                                        
                                            />
                                        }
                                    
                                </div>
                                <DataGrid.Table noHover>
                                    <thead>
                                        <tr>
                                            <th>Method</th>
                                            <th>Rate-Year 1</th>
                                            <th>Rate-Year 2</th>
                                            <th>Current value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr key={"accounts"}>
                                            <td>
                                                <FormikField<AssetPostModel>
                                                    className={styles.formikTableInputs}
                                                    name="methodAccounts"
                                                    type="select"
                                                    option={depreciationTypes}
                                                    isTable
                                                />
                                            </td>
                                            <td>
                                                <FormikField<AssetPostModel>
                                                    className={styles.formikTableInputs}
                                                    name="rateFirstYearAccountsPct"
                                                    suffix="%"
                                                    type="number"
                                                    isTable 
                                                    min='0'
                                                    onlyPositiveNumbers   
                                                    step="0.01"                                                    
                                                />
                                            </td>
                                            <td>
                                                <FormikField<AssetPostModel>
                                                    className={styles.formikTableInputs}
                                                    name="rateSubsequentAccountsPct"
                                                    suffix="%"
                                                    type="number"  
                                                    isTable 
                                                    min='0'
                                                    onlyPositiveNumbers
                                                    step="0.01"                                     
                                                />
                                            </td>
                                            <td>
                                                <FormikField<AssetPostModel>
                                                    className={styles.formikTableInputs}
                                                    name="currentValue"
                                                    prefix="£"
                                                    type="number" 
                                                    isTable 
                                                    step="0.01"                                      
                                                />
                                            </td>                                            
                                        </tr>
                                        <tr key={"tax"}>
                                            <td>
                                                <FormikField<AssetPostModel>
                                                    className={styles.formikTableInputs}
                                                    name="methodTax"
                                                    type="select"
                                                    option={depreciationTypes}
                                                    isTable
                                                /> 
                                            </td>
                                            <td>
                                                <FormikField<AssetPostModel>
                                                    className={styles.formikTableInputs}
                                                    name="rateFirstYearTaxPct"
                                                    suffix="%"
                                                    type="number"  
                                                    isTable   
                                                    min='0'
                                                    onlyPositiveNumbers
                                                    step="0.01"                                   
                                                />
                                            </td>
                                            <td>
                                                <FormikField<AssetPostModel>
                                                    className={styles.formikTableInputs}
                                                    name="rateSubsequentTaxPct"
                                                    suffix="%"
                                                    type="number" 
                                                    isTable  
                                                    min='0'
                                                    onlyPositiveNumbers
                                                    step="0.01"
                                                /> 
                                            </td>
                                            <td>
                                                <FormikField<AssetPostModel>
                                                    className={styles.formikTableInputs}
                                                    name="currentValueTax"
                                                    prefix="£"
                                                    type="number"
                                                    isTable
                                                    step="0.01"
                                                />
                                            </td>                                        
                                        </tr>
                                    </tbody>
                                </DataGrid.Table>

                                <div className={styles.createFormButton}>
                                    <Submit
                                        disabled={isSubmitting} 
                                        onCancelClick={onCancel} 
                                        alignLeft
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            }
        </>
    )
}

export default EditAsset;