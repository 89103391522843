import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { BrandWrapper } from '../../../../components/BrandWrapper/BrandWrapper';
import { Button } from '../../../../elements/Button/Button';
import { LoadingPlaceholder } from '../../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import styles from './Modal.module.css';
type ModalProps = {
	setShow ?: (show: boolean) => void;
	show ?: boolean;
	children?: React.ReactNode;
	onClickSubmit: () => void;
	title: string;
	buttonCancel?: string;
	buttonSubmit?: string;
	type?: 'save' | 'close' | 'delete' | 'cancel' | 'new' | 'edit';
	size?: 'sm' | 'lg' | 'xl';
};

function ModalDialogbox({ show, onClickSubmit, setShow, children, title, size, type, buttonCancel = 'Cancel', buttonSubmit = 'Save' }: ModalProps) {
	return (
		<div>
			<Modal
			    data-cy = "invoiceModal"
		     	onHide={() => setShow && setShow(false)}
				contentClassName={styles.modalContent}
				className={classNames(styles.Modal, 'p-4')}
				show={show}
				size={size ? size : 'lg'}
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<BrandWrapper>
					<Modal.Header className={styles.ModalHeader}>
						<Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
					</Modal.Header>
					<Modal.Body className={classNames(styles.ModalBody)}>{children}</Modal.Body>
					<Modal.Footer className="justify-content-start m-0 border border-top-0">
						<Button
							buttonType={type ? type : 'save'}
							onClick={() => {
								onClickSubmit();
							}}
							label={buttonSubmit}
						/>
						<Button onClick={() => setShow && setShow(false)} buttonType="close" label={buttonCancel} />
					</Modal.Footer>
				</BrandWrapper>
			</Modal>
		</div>
	);
}

export const LoadingModal = () => {
	return (
		<div>
			<Modal
				contentClassName={styles.modalContent}
				className={classNames(styles.Modal, 'p-4')}
				show={true}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered>
				<BrandWrapper>
					<Modal.Header className={styles.ModalHeader}>
						<Modal.Title id="contained-modal-title-vcenter">
							<LoadingPlaceholder width="200px" />
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className={classNames(styles.ModalBody)}>
						<LoadingPlaceholder width="100%" height="700px" />
					</Modal.Body>
					<Modal.Footer className="justify-content-start m-0 border border-top-0">
						<LoadingPlaceholder />
					</Modal.Footer>
				</BrandWrapper>
			</Modal>
		</div>
	);
};
export default ModalDialogbox;
