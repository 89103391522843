import { useContext, useEffect, useState } from "react";
import { ApiConfig, HttpClient, HttpResponse } from "../../api/inni/http-client";
import CompanyContext from "../../context/CompanyContext";
import { useInniAPI } from "../useInniAPI";

interface HasDelete  extends HttpClient  {
    delete: (cid: number, id: number) => Promise<HttpResponse<object, any>>
}
 
export function useDeleteEntity<T extends HasDelete>(apiClass: { new (p1: ApiConfig): T  }): (id: number) =>  Promise<void|HttpResponse<object, any>> {

    const companyContext = useContext(CompanyContext);
    const api = useInniAPI(apiClass);

    return (id:number) => {
        if (api) {
            return api.delete(companyContext.cid, id)
        } else {
            return new Promise((resolve, reject) => {reject()})
        }
    }
 
}
