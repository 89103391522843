import { useState, useEffect, useContext } from 'react';
import { Modal as ReactModal } from 'react-bootstrap';
import {SelectlistModel} from '../../api/inni/data-contracts';
import { Button } from '../../elements/Button/Button';
import { useInniAPI } from '../../hooks/useInniAPI';
import CompanyContext from '../../context/CompanyContext';
import { ListOption } from '../../elements/EditRow/EditRow';
import { Accounts } from "../../api/inni/Accounts";
import Select from 'react-select';
import { BrandWrapper } from '../../components/BrandWrapper/BrandWrapper';

interface AssetModalProps {
    hide:()=>void;
    AssetToExpense:(values: number) => void
}

export const AssetPaymentChangeToExpenseModal = ({hide, AssetToExpense}:AssetModalProps) => {   
  const companyContext = useContext(CompanyContext);  
  const [expenseAccountslist, setexpenseAccounts] = useState<ListOption[]>();
  const accountsAPI = useInniAPI(Accounts);
  const [selectedChoice,setUserselectedChoice]=useState<any>()
  const [showErrorMessage,setShowErrorMessage]=useState(false)
    

  useEffect(() => {
    if (companyContext.cid) {
      let accounts:Array<ListOption>=[]
      accountsAPI?.expenseAccounts(companyContext.cid)
      .then((response: { data: SelectlistModel[] })=> response.data.forEach(
        i=>{
          accounts.push({            
            value: i.id.toString(),
            label: i.name||''
          })
        }
      ))
      setexpenseAccounts(accounts);    
    }    
  }, [ companyContext.cid,accountsAPI]);
   
  const checkSelectedAccount=(accountId:any)=>{
    if(accountId){
        setUserselectedChoice(accountId)
        setShowErrorMessage(false)
    }
    else{
        setShowErrorMessage(true)
    }
  };

  const submitAssetPaymentToExpense=()=>{
    if(selectedChoice){
        AssetToExpense(selectedChoice)
    }
    else{
        setShowErrorMessage(true)
    }
  }
  
  return (    
    <ReactModal  show={true} onHide={hide} centered>
      <BrandWrapper>
        {/* Header */}
        <ReactModal.Header closeButton>
          <ReactModal.Title id="inni-modal-title">
            Change asset to expense
          </ReactModal.Title>
        </ReactModal.Header>
      
        <ReactModal.Body>                
          <label>Account</label>
          <Select                 
            options={expenseAccountslist}   
            isClearable={true} 
            onChange={(choice)=>checkSelectedAccount(choice?.value)}
          ></Select>                     
          {showErrorMessage&& <span className='errorText'>Please select account</span>}     
        </ReactModal.Body>

        {/* Footer */}
        <ReactModal.Footer>
          <div style={{textAlign: 'left', width: '100%'}}>
            <Button  buttonType='save' onClick={()=>submitAssetPaymentToExpense()} />
            <Button  buttonType='cancel' onClick={hide}/>
          </div>
        </ReactModal.Footer>
      </BrandWrapper>
    </ReactModal>
  );
}
