import { RouteComponentProps } from "react-router-dom";
import { Action, Entity } from "../../utils/EntityAction";

export enum RouteContext {
    Public,
    User,
    Company
}

/*
    TODO for the future - mobile
        when implementing mobile, add an optional mobileComponent
        For desktop, render component
        If mobile:
            If component is responsive, render component
            If mobile-specific component, render mobileComponent
        Relies on the layout being desktop/mobile aware, obvs
*/

/**
 * Defines an internal or external route
 * If no action is specified, it will default to 'List' - see useGetLink
 */
export interface EntityRoute  {
    entity? : Entity,
    action? : Action,
    context: RouteContext,
    path?: string,
    externalUrl?: string,
    exact?: boolean,
    component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}