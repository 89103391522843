import { useContext } from "react";
import UserContext from "../context/UserContext";


/**
 * Purely a convenience wrapper around use context 
 */
export const useGetAccessToken = () :  () => Promise<[string, Date | null] | null> => {
    const userContext = useContext(UserContext);
    return userContext.getAccessToken;
}