import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import { PropertyListPage } from "../../features/Properties/PropertyListPage";
import { PropertyReadPage } from "../../features/Properties/PropertyReadPage";
import { PropertyImport } from "../../features/Properties/PropertyImport/PropertyImport";
import { PropertyNewPage } from "../../features/Properties/PropertyNewPage";

const dashboard:Array<EntityRoute> = [
    {
        path: '/company/:cid/property',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Property,
        component: PropertyListPage
    },
    {
        path: '/company/:cid/property/new',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Property,
        action: Action.Create,
        component: PropertyNewPage
    },
    {
        path: '/company/:cid/property/:id/:defaultTab?',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Property,
        action: Action.Read,
        component: PropertyReadPage
    },
    {
        path: '/company/:cid/import_property',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Property,
        action: Action.LinkToExternal,
        component: PropertyImport
    },
    
]

export default dashboard;
