import { useEffect, useState } from 'react'; 
import styles from './BasicFormLayout.module.scss';
import loadingShimStyles from '../../elements/LoadingShim/LoadingShim.module.css';
import { render as renderRichText } from 'storyblok-rich-text-react-renderer';
import { getStoryblokApi, SbBlokData } from '@storyblok/react';
import { Container } from 'react-bootstrap';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import Video from '../../elements/Storyblok/primitives/Video';
import Overlay from '../../components/Overlay/Overlay';
import classNames from 'classnames';
import { LoadingShim } from '../../elements/LoadingShim/LoadingShim';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';

interface BasicFormLayoutProps {
    title: string;
    description?: string;
    storyblokDescriptionId?: string;
    introLayout?: boolean;  // i.e. not a form, centered content, larger heading
    bigTitle?: boolean;
    children: React.ReactNode;
    hideNeedHelp?: boolean;
}

export const BasicFormLayout = ({
    title,
    description,
    storyblokDescriptionId,
    introLayout,
    bigTitle,
    hideNeedHelp,
    children
    }: BasicFormLayoutProps
) => {
    const [sbPageDescription, setSBPageDescription] = useState<SbBlokData>();
    const [sbPageVideo, setSBPageVideo] = useState<SbBlokData>();

    // Get description from storyblok if provided
    useEffect(() => {
        if (storyblokDescriptionId) {
            const storyblokApi = getStoryblokApi();
            storyblokApi.get('cdn/stories/satr-standalone/page-descriptions/' + storyblokDescriptionId, {version: 'published'}).then((res) => {
                const data = res.data.story.content.body;
                
                // Rich text only
                if (data.length === 1) {
                    setSBPageDescription(res.data.story.content.body[0].body);
                }
                // Video + Rich text
                else if (data.length === 2) {
                    setSBPageVideo(res.data.story.content.body[0]);
                    setSBPageDescription(res.data.story.content.body[1].body);
                } else {
                    console.warn('Unexpected storyblok format')
                }
            })
        }
    }, [storyblokDescriptionId])

    return (
        <div id={styles.basicFormLayout}>
            <Container className={classNames("d-flex", "flex-column", "flex-grow-1", {"ml-0": !introLayout})}>
                { introLayout || bigTitle
                    ? <h1 className="mt-5 mb-5 text-center">{ title }</h1>
                    : <h2 style={{fontWeight:600}}>{ title }</h2>
                }

                {/* Storyblok video */}
                { sbPageVideo && (
                    <div className="mt-4 mb-5">
                        <Video blok={sbPageVideo} />
                    </div>
                )}

                {/* Props description (avoid) */}
                { description && <p className="mt-1 mb-1">{ description }</p> }

                {/* Storyblok description */}

                { sbPageDescription && storyblokDescriptionId &&
                    <div className="mt-1 mb-1">
                        { renderRichText(sbPageDescription) }
                    </div>
                    
                }

                { title && !description && storyblokDescriptionId && !sbPageDescription && (
                        introLayout
                            ? <LoadingShim />
                            : (
                                <>
                                    <br />
                                    <LoadingPlaceholder dark />
                                    <LoadingPlaceholder dark />
                                    <LoadingPlaceholder dark width='500px' />
                                </>
                            )
                    )

                }

                { !title && (
                    <div className={loadingShimStyles.loader} />
                )}

                <div className="mt-4 mb-5">
                    { children }
                </div>

                { !hideNeedHelp && !introLayout && !bigTitle && (
                    <div className="pt-5" style={{marginTop: 'auto', justifySelf: 'flex-end', alignSelf: 'center'}}>
                        <a data-az-l="f1ec6b3b-67b5-411c-9372-1cffb583daa6">
                            <FontAwesomeIcon icon={faQuestionCircle} className='mr-2' />
                            Need help with something? We're here to help
                        </a>
                    </div>
                )}

            </Container>
        </div>
    )
}

export const BasicFormLayoutFullscreen = (props: BasicFormLayoutProps) => {
    return (
        <Overlay isOpen={true}>
            <div className="centerFlexContent w-100">
                <BasicFormLayout introLayout={true} {...props} />
            </div>
        </Overlay>
    )
}
