import { faCar, faClock, faFilter, faPlus, faReceipt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { createRef, useContext, useEffect, useRef, useState } from 'react'
import { Container } from 'react-bootstrap'
import Select from 'react-select'
import { Companies } from '../../api/inni/Companies'
import { Contracts } from '../../api/inni/Contracts'
import { Contract, ExpenseAsSuggestion, Mileage, QuickEntry } from '../../api/inni/data-contracts'
import { Expenses } from '../../api/inni/Expenses'
import { Mileages } from '../../api/inni/Mileages'
import CompanyContext from '../../context/CompanyContext'
import { Button } from '../../elements/Button/Button'
import { ListOption } from '../../elements/EditRow/EditRow'
import SearchWithClear from '../../elements/SearchWithClear/SearchWithClear'
import { useFetchEntityList } from '../../hooks/entities/useFetchEntityList'
import { useHasPermission } from '../../hooks/useHasPermission'
import { useInniAPI } from '../../hooks/useInniAPI'
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout'
import { Action, Entity } from '../../utils/EntityAction'
import QEListMobile from './Components/QEListMobile'
import styles from './QuickEntry.module.css'
import MobileBottomBar from '../../components/MobileBottomBar/MobileBottomBar'
import BrandContext from '../../context/BrandContext'
import provestorBrand from '../../components/BrandWrapper/pvBrand'

export type QETypes = 'E' | 'T' | 'M'
export interface QEFilters {
    search?: string,
    relatedId?: number
    billable?: '-1' | '0' | '1', // undefined is all
    year?: number,
    month?: number,
    typesToShow: QETypes[] // set of the current types showing, TODO default val = all of them
}

const months : ListOption[] = [
    {value: '-1', label: 'All'}, 
    {value: '1', label: 'January'}, 
    {value: '2', label: 'February'}, 
    {value: '3', label: 'March'},
    {value: '4', label: 'April'},
    {value: '5', label: 'May'},
    {value: '6', label: 'June'},
    {value: '7', label: 'July'},
    {value: '8', label: 'August'},
    {value: '9', label: 'September'},
    {value: '10', label: 'October'},
    {value: '11', label: 'November'},
    {value: '12', label: 'December'}]

const billableSL : ListOption[] = [
    {value: '-1', label: 'All'},
    {value: '0', label: 'Yes'},
    {value: '1', label: 'No'}
]

const QuickEntryMobile = () => {
    
    const eAPI = useInniAPI(Expenses)
    const mAPI = useInniAPI(Mileages)

    const companiesAPI = useInniAPI(Companies)
    const companyContext = useContext(CompanyContext)
    const brandContext = useContext(BrandContext)
    const [creating, setCreating] = useState<QETypes | undefined>(undefined)

    const [qeFilters, setQeFilters] = useState<QEFilters>({ billable: '-1', year: -1, month: -1, typesToShow: ['E','T','M'], relatedId: -1 })
    const [popup, setPopup] = useState(false)
    const [years, setYears] = useState<ListOption[]>([])
    const hasPermission = useHasPermission()
    const [showFilters, setShowFilters] = useState(false)

    const [selected, setSelected] = useState<undefined | QuickEntry>(undefined)

    const [contracts, contractsLoaded] = useFetchEntityList<Contract, Contracts>(Contracts)
    const [contractsSL, setContractsSL] = useState<ListOption[]>([])


    const [showSuggestions, setShowSuggestions] = useState<QETypes | undefined>(undefined)
    const [expenseSuggestions, setExpenseSuggestions] = useState<ExpenseAsSuggestion[]>([])
    const [expenseSuggestionsLoaded, setExpenseSuggestionsLoaded] = useState(false)
    const [mileageSuggestions, setMileageSuggestions] = useState<Mileage[]>([])
    const [mileageSuggestionsLoaded, setMileageSuggestionsLoaded] = useState(false)

    const [suggestionData, setSuggestionData] = useState<ExpenseAsSuggestion | Mileage | undefined>(undefined)

    const containerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (eAPI && !expenseSuggestionsLoaded) {
            eAPI.newSuggestions(companyContext.cid)
            .then(res => {
                setExpenseSuggestions(res.data)
                setExpenseSuggestionsLoaded(true)
            })
        }
    }, [companyContext.cid, eAPI, expenseSuggestionsLoaded])

    useEffect(() => {
        if (mAPI && !mileageSuggestionsLoaded) {
            mAPI.newSuggestions(companyContext.cid)
            .then(res => {
                setMileageSuggestions(res.data)
                setMileageSuggestionsLoaded(true)
            })
        }
    }, [companyContext.cid, mAPI, mileageSuggestionsLoaded])

    useEffect(() => {
        if (contractsLoaded) {
            let tempCtr : ListOption[] = [{value: '-1', label: 'All'}]
            contracts.map(x => tempCtr.push({ value: String(x.id), label: x.name || '' }))
            setContractsSL(tempCtr)
        }
    }, [contracts, contractsLoaded])
    
    useEffect(() => {
        if (companiesAPI) {
            companiesAPI.activeCalendarYearsSelectList(companyContext.cid)
            .then(res => {
                let tempSL : ListOption[] = [{value: '-1', label: 'All'}]
                tempSL = [...tempSL, ...res.data.map(x => {return {value: x.id.toString(), label: x.name || ''}})]
                setYears(tempSL)
            })
        }
    }, [companiesAPI, companyContext.cid])


    const toggleTypesToShow = (t : QETypes) => {
        let index = qeFilters.typesToShow.findIndex(x => x === t)
        if (index !== -1) {
            let tempTypes = qeFilters.typesToShow
            tempTypes.splice(index, 1)
            setQeFilters({...qeFilters, typesToShow: tempTypes})
        } else {
            let tempTypes = qeFilters.typesToShow
            tempTypes.push(t)
            setQeFilters({...qeFilters, typesToShow: tempTypes })
        }
    }

    const showAll = () => setQeFilters({...qeFilters, typesToShow: allActive() ? [] : ['M', 'E', 'T']})

    const allActive = () => qeFilters.typesToShow.findIndex(x => x === 'E') !== -1 
                    && qeFilters.typesToShow.findIndex(x => x === 'M') !== -1
                    && (qeFilters.typesToShow.findIndex(x => x === 'T') !== -1 
                    || !hasPermission(Entity.Time, Action.List)[0])

    return (
        <DefaultLayout 
            backFnc={() => {setSelected(undefined); setCreating(undefined)}} 
            backIcon={creating !== undefined || selected !== undefined} 
            noPadding 
            useFullWidth 
            entity={Entity.QuickEntry} 
            title={brandContext.brand === provestorBrand ? 'Expenses' : "Quick entry"}
        >   
            <div className={styles.qeMobile}>
                <div ref={containerRef} id="qeContainer" className={styles.content}>
                    <QEListMobile wrapperRef={containerRef} suggestionData={suggestionData} selected={selected} setSelected={setSelected} filters={qeFilters} creating={creating} setCreating={setCreating} />
                </div>
                <div onClick={() => {setPopup(false); setShowFilters(false); setShowSuggestions(undefined)}} style={{top: showFilters ? '46px' : ''}} className={classNames(styles.overlay, {[styles.overlayVisible] : popup || showFilters})}></div>
                {!selected && !creating && 
                <MobileBottomBar>
                    <FontAwesomeIcon
                        onClick={() => {setPopup(false); setShowFilters(!showFilters)}}
                        icon={faFilter}
                    />
                    <FontAwesomeIcon
                        onClick={() => {setShowFilters(false); setPopup(!popup)}}
                        icon={faPlus}
                    />
                    {/* Popup for adding new entries */}
                    <ul className={classNames(styles.popup, {[styles.popupActive] : popup})}>
                        {!showSuggestions && <><li onClick={() => {setShowSuggestions('M')}}>
                            <FontAwesomeIcon size="2x" icon={faCar} /> Mileage
                        </li>
                        <li onClick={() => {setShowSuggestions('E')}}>
                            <FontAwesomeIcon size="2x" icon={faReceipt} /> Expense
                        </li>
                        {hasPermission(Entity.Time, Action.List)[0] && 
                            <li onClick={() => {setCreating('T'); setPopup(false)}}>
                                <FontAwesomeIcon size="2x" icon={faClock} /> Time
                            </li>}</>}
                        
                        {showSuggestions === 'E' && <>
                            {expenseSuggestions.map((x, i) => {
                                return (<li onClick={() => {setSuggestionData(x); setCreating('E'); setPopup(false); setShowSuggestions(undefined)}} key={i}>
                                    <FontAwesomeIcon size="2x" icon={faReceipt} /> {x.description}
                                </li>)
                            })}
                            <li onClick={() => {setSuggestionData(undefined); setCreating('E'); setPopup(false); setShowSuggestions(undefined)}}><FontAwesomeIcon size="2x" icon={faReceipt} /> New expense</li>
                        </>}

                        {showSuggestions === 'M' && <>
                            {mileageSuggestions.map((x, i) => {
                                return (<li onClick={() => {setSuggestionData(x); setCreating('M'); setPopup(false); setShowSuggestions(undefined)}} key={i}>
                                    <FontAwesomeIcon size="2x" icon={faCar} /> {x.description}
                                </li>)
                            })}
                            <li onClick={() => {setSuggestionData(undefined); setCreating('M'); setPopup(false); setShowSuggestions(undefined)}}><FontAwesomeIcon size="2x" icon={faCar} /> New mileage</li>
                        </>}

                        <li style={{display: 'flex', justifyContent: 'center', padding: '5px'}}>
                            <Button onClick={() => {setPopup(false); setShowSuggestions(undefined)}} buttonType="cancel" mobile />
                        </li>
                    </ul>

                    {/* Filter popup */}
                    <div className={classNames(styles.filters, {[styles.filterActive] : showFilters})} >
                        <Container>
                            <label className={styles.qeLabel}>Search</label>
                            <SearchWithClear setSearch={(e) => setQeFilters({...qeFilters, search: e})} search={qeFilters.search || ''} />
                            <label className={styles.qeLabel}>Year</label>
                            <Select onChange={(e) => setQeFilters({...qeFilters, year: parseInt(e?.value || ''), month: e?.value === '-1' ? -1 : qeFilters.month})} value={years.find(x => x.value === String(qeFilters.year))} options={years} />
                            <label className={styles.qeLabel}>Month</label>
                            <Select isDisabled={qeFilters.year === -1} onChange={(e) => setQeFilters({...qeFilters, month: parseInt(e?.value || '')})} value={months.find(x => x.value === String(qeFilters.month))} options={months} />
                            <label className={styles.qeLabel}>Billable</label>
                            <Select onChange={(e) => setQeFilters({...qeFilters, billable: e?.value as ('-1' | '0' | '1') || '-1'})} value={billableSL.find(x => x.value === String(qeFilters.billable))} options={billableSL} />
                            <label className={styles.qeLabel}>Related to</label>
                            <Select onChange={(e) => setQeFilters({...qeFilters, relatedId: parseInt(e?.value || '')})} value={contractsSL.find(x => x.value === String(qeFilters.relatedId))} options={contractsSL} />

                            <label className={styles.qeLabel}>Show</label>
                            <div style={{display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
                                <label className={styles.filtersCheckboxLabel}>
                                    <input
                                        type="checkbox"
                                        readOnly
                                        checked={allActive()} onClick={showAll}
                                    />
                                    All
                                </label>
                                <label className={styles.filtersCheckboxLabel}>
                                    <input
                                        type="checkbox"
                                        onChange={() => toggleTypesToShow('E')} checked={qeFilters.typesToShow.findIndex(x => x === 'E') !== -1}
                                        onClick={showAll}
                                    />
                                    Expenses
                                </label>
                                <label className={styles.filtersCheckboxLabel}>
                                    <input
                                        type="checkbox"
                                        onChange={() => toggleTypesToShow('M')}
                                        checked={qeFilters.typesToShow.findIndex(x => x === 'M') !== -1}
                                        onClick={showAll}
                                    />
                                    Mileage
                                </label>
                                {hasPermission(Entity.Time, Action.List)[0] &&
                                <label className={styles.filtersCheckboxLabel}>
                                    <input
                                        type="checkbox"
                                        onChange={() => toggleTypesToShow('T')}
                                        checked={qeFilters.typesToShow.findIndex(x => x === 'T') !== -1}
                                        onClick={showAll}
                                    />
                                    Time
                                </label>}
                            </div>

                            <div className="mt-4 mb-3 d-flex">
                                <Button
                                    buttonType="cancel"
                                    onClick={() => {
                                        setQeFilters({
                                            billable: '-1',
                                            month: -1,
                                            typesToShow: ['E','T','M'],
                                            year: -1,
                                            search: '',
                                            relatedId: -1
                                        });
                                        setShowFilters(false)
                                    }}
                                    mobile
                                />
                                <Button
                                    onClick={() => {
                                        setQeFilters({
                                            billable: '-1',
                                            month: -1,
                                            typesToShow: ['E','T','M'],
                                            year: -1,
                                            search: '',
                                            relatedId: -1
                                        })
                                    }}
                                    variant="primary"
                                    mobile>Reset</Button>
                                <Button onClick={() => setShowFilters(false)} variant="primary" mobile>Update</Button>
                            </div>
                        </Container>
                    </div>
                </MobileBottomBar>}
            </div>
        </DefaultLayout>
    )
}

export default QuickEntryMobile