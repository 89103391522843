import Select from 'react-select';
import { Button } from '../../elements/Button/Button';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import styles from './Queries.module.css';
import { Action, Entity } from '../../utils/EntityAction';
import * as DataGrid from '../../elements/DataGrid/DataGrid';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { NoContentSlate } from '../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../hooks/terms/useDatagridTerms';
import { formatDate } from '../../utils/formatDate';
import {
    Account,
	AccountingPeriod,
	AccountingPeriodReviewItem,
	AccountingPeriodReviewItemPostModel,
	BankTransaction,
} from '../../api/inni/data-contracts';
import CreateQueryModal from './CreateQueryModal';
import useTransactionQueries from '../../hooks/query/useTransactionQueries';
import { TableIcon } from '../../elements/TableIcon/TableIcon';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { AccountingPeriodsReviewItem } from '../../api/inni/AccountingPeriodsReviewItem';
import { useInniAPI } from '../../hooks/useInniAPI';
import { BankTransactions } from '../../api/inni/BankTransactions';
import { Accounts } from '../../api/inni/Accounts';
import { formatCurrency } from '../../utils/formatNumbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort,faSortUp,faSortDown} from "@fortawesome/pro-solid-svg-icons";
import SearchWithClear from '../../elements/SearchWithClear/SearchWithClear';
import { AccountingPeriods } from '../../api/inni/AccountingPeriods';
import {   LoadingPlaceholderFYSelector } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { useHistoryWrapper } from '../../hooks/useHistoryWrapper';


type QueriesFilterProps = {
	setFilter:(a:number | undefined)=>void;
	options: Array<{ value: string; label: string }> | undefined;  
    placeHolder?:string;
    isLoaded:boolean;
};
const CreateQuery = () => {
    const { cid } = useContext(CompanyContext);
    const [loading,setLoading]=useState<boolean>(true);
    const transactionsApi = useInniAPI(BankTransactions); 
    const accountsApi = useInniAPI(Accounts);
	const [accounts, setAccounts] = useState<Account[]>([]);  
    const accountingPeriodAPI = useInniAPI(AccountingPeriods);
    const [transactions, setTransactions] = useState<BankTransaction[]>([]) 
    const [filteredTransactions, setFilteredTransactions] = useState<BankTransaction[]>([])   
    const [accountingPeriods, setAccountingPeriods] = useState<AccountingPeriod[]|undefined>(undefined);
    const [periodLoaded, setPeriodLoaded] = useState(false);
    const [searchParam, setSearchParam] = useState<string | undefined>(undefined);
    const [periodID, setPeriodID] = useState<number|undefined>(undefined);
    const { queries, setQueries } = useTransactionQueries(periodID!, true)
    const reviewAPI = useInniAPI(AccountingPeriodsReviewItem);
    const [createQueryModalProps, setCreateQueryModalProps] = useState({ show: false, key: '', sourceID: 0 });
    const [selectedAccount, setSelectedAccount] = useState<number|undefined>(undefined);
    const [accountsLoaded, setAccountsLoaded] = useState(false)
    const [order, setOrder] = useState<'default'|'asc'|'desc'>("default");
    const [field, setField] = useState<'default'|'amount'|'date'>("default");
    const history = useHistoryWrapper();
    const [accountsOptions, setAccountsOptions] = useState<Account[]>([]);
    const companyContext = useContext(CompanyContext)

    useEffect(() => {
        if (accountingPeriodAPI) {
            accountingPeriodAPI?.index(cid)
            .then(response => {
                let sorted = response.data.sort((a,b) => a.dateFrom > b.dateFrom ? -1 : 1)
                if (sorted && sorted[0]) {
                    setAccountingPeriods(sorted);  
                    setPeriodID(sorted[0].id); 
                    setPeriodLoaded(true);                           
                }
            })
        }
    }, [accountingPeriodAPI, cid])

    useEffect(() => {
        if(accountsApi)
        {
            accountsApi?.index(cid).then(
                response => { 
                    let sorted = response.data.sort((a,b) => a.name.localeCompare(b.name))  
                    if (sorted && sorted[0]) {
                        if(companyContext.product === CompanyProduct.isCap) {
                            setAccounts(sorted.filter(x => x.hasCategory))
                        }
                        else {
                            setAccounts(sorted)
                        }
                    }                 
                }
            )
        }
    }, [accountsApi, cid, companyContext.product]);

    useEffect(()=>{        
        if (transactionsApi) {
            transactionsApi?.getAllTransactions(cid,{accountingPeriodId:periodID, accountId:undefined}).then((response) => {                
                setLoading(false);
                setOrder("default");
                setTransactions(response.data); 
                             
            });
          }
    },[cid, transactionsApi, periodID])
   

    const switchAccountingPeriod = (val : number|undefined) => {
        setPeriodID(val);
        setLoading(true);
    }

    const switchAccount = (num : number|undefined) => {
        if (num !== selectedAccount) {
            setSelectedAccount(num);           
        }       
    }
    const journalTypes = useMemo(
		() => ({
			BP: { label: 'Bank Payment', key: 'bankPaymentId' },
			BT: { label: 'Bank Transfer', key: 'bankTransferId' },
		}),
		[]
	);
    const getJournalTypeById = useCallback(
		(id: keyof typeof journalTypes, type: 'key' | 'label') =>
			id  && journalTypes[id as keyof typeof journalTypes] ? journalTypes[id as keyof typeof journalTypes][type] : '',
		[journalTypes]
	);
	
    const findTransactionQuery = useCallback(
		(journalId: 'BP' | 'BT' | undefined, sourceId: number) =>
			queries.find(
				(q) =>
					journalId &&
					q[getJournalTypeById(journalId, 'key') as 'bankPaymentId' | 'bankTransferId'] === sourceId
			),
		[getJournalTypeById, queries]
	);
    
    useEffect(()=>{
        const transactionList = transactions
                                        .filter((x) => searchParam? x.description?.toLowerCase().includes(searchParam as unknown as string): true)
                                        .filter((x) => selectedAccount? x.bankAccountId===selectedAccount: true);
        if(field==='amount'){
            transactionList.sort((a,b)=>order==='asc'? 
                    ( a.amount===b.amount ? a.date.localeCompare(b.date) : a.amount-b.amount): 
                    (b.amount===a.amount ? b.date.localeCompare(a.date): b.amount-a.amount))
        }
        else if(field==='date')
        {
            transactionList.sort((a,b)=>order==='asc'? ( a.date.localeCompare(b.date)): ( b.date.localeCompare(a.date)))
        }
        setFilteredTransactions(transactionList);

    },[searchParam, transactions, selectedAccount, field, order]);
         

    const sortHandleChange=(field:'default'|'amount'|'date')=>{       
        const sortOrder= order==='asc'?'desc':'asc';
        setField(field)
        setOrder(sortOrder);
    };
    
    
    useEffect(()=>{
        if(transactions && accounts){
            const existingAccounts= transactions.map(item=>item.bankAccountId).filter((item,index,reference)=>reference.indexOf(item)===index)
            const accountsSelected=accounts.filter((x)=>existingAccounts.includes(x.id))
            setAccountsOptions(accountsSelected);
            setAccountsLoaded(true);
        }

    },[transactions, accounts]);


    const createQuery: (pm: AccountingPeriodReviewItemPostModel, onSuccess: () => void) => void = useCallback(
		(pm: AccountingPeriodReviewItemPostModel, onSuccess: () => void) => {
			if (reviewAPI && cid) {
				reviewAPI
					.saveReviewItems(cid, periodID!, pm)
					.then(({ data }) => {
						setQueries((prev) => [
							...prev,
							{
								...pm,
								id: +data,
								companyID: 0,
								accountingPeriodID: periodID,
								raisedByStaffId: 0,
								raisedOn: '',
								isPublished: false,
							} as AccountingPeriodReviewItem,
						]);
						onSuccess();
					})
					.catch((err) => console.log(err));
			}
		},
		[reviewAPI, cid, periodID, setQueries]
	);

    const navigateToQueries = (state: {periodId: number|undefined; queryId?: number}) => (
        history.push(`/company/${cid}/queries${periodID ? "/" + periodID : ''}`, state)
    )

	return (
		<>
			<DefaultLayout				
				backIcon
				title="Create query"
				useFullWidth
				loading={false}
				entity={Entity.AccountingPeriodsReviewItem}
			>
         

		
            <div className="d-md-flex justify-content-start mb-2 ">				
                
                
                <QueriesFilter
                    options={accountingPeriods?.map((item)=>({value:item.id.toString(), label:"FY to end "+ formatDate(item.dateTo)}))}
                    setFilter={switchAccountingPeriod}
                    placeHolder="Select period ...."  
                    isLoaded={periodLoaded}                
                />
                <QueriesFilter
                    options={accountsOptions?.map((item)=>({value:item.id.toString(), label:item.name}))}
                    setFilter={switchAccount}
                    placeHolder="Select account ...." 
                    isLoaded={accountsLoaded}                                  
                />
                              
				<div style={{ minWidth: '200px' }}>
					<SearchWithClear search={searchParam|| ''} setSearch={setSearchParam} placeholder="Search ...." />
				</div>
			</div>
				
				<DataGrid.Table>
					<thead>
						<tr>
							<th style={{ width: '10%' }}>Payee</th>
							<th style={{ width: '20%' }}>Description</th>
							<th style={{ width: '9%' }}>Category</th>
							<th style={{ width: '10%' }}>Journal type</th>
							<th style={{ width: '7%' }}>Confirmed</th>
							<th onClick={()=>sortHandleChange("date")} style={{ width: '10%' }}>
                                Date <FontAwesomeIcon icon={field==='date'?(order==='asc'? faSortDown:faSortUp):faSort} />
                            </th>
                            <th onClick={()=>sortHandleChange("amount")} style={{ width: '10%' }}>
                                Amount <FontAwesomeIcon icon={field==='amount'?(order==='asc'? faSortDown:faSortUp):faSort} />
                            </th>
							<th style={{ width: '8%' }}>Query responded</th>
							<th style={{ width: '10%' }}></th>
						</tr>
					</thead>
					<tbody>
                        {loading && (
                                <>
                                    <DataGrid.LoadingRow cols={9} />
                                    <DataGrid.LoadingRow cols={9} />
                                    <DataGrid.LoadingRow cols={9} />
                                    <DataGrid.LoadingRow cols={9} />
                                </>
                        )}   
                      
                        {!loading &&                      
                                (filteredTransactions.length > 0 ? (
                                    filteredTransactions.map((transaction,i) => {
                                        const query = findTransactionQuery(
                                            transaction.journalTypeId as keyof typeof journalTypes,
                                            transaction.sourceID || 0
                                        );
                                        return (
                                            <tr key={transaction.id + ':' + transaction.sourceID + i}>
                                                <td>{transaction.payee || ''}</td>
                                                <td>{transaction.description || ''}</td>
                                                <td>{accounts.find((v) => v.id === transaction.accountId)?.name || ''}</td>
                                                <td>
                                                    {getJournalTypeById(
                                                        transaction.journalTypeId as keyof typeof journalTypes,
                                                        'label'
                                                    )}
                                                </td>
                                                <td>{transaction.confirmed ? 'Yes' : 'No'}</td>
                                                <td>{formatDate(transaction.date)}</td>
                                                <td>{formatCurrency(transaction.amount)}</td>
                                                <td>
                                                    {(query?.response || query?.suggestionAcceptedOn) && (
                                                        <TableIcon icon={'check'} />
                                                    )}
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-end">
                                                        <div style={{width:'150px'}}>
                                                            <Button
                                                                variant="primary"
                                                                label="View in bookkeeping"
                                                                tableBtnMd
                                                                entity={Entity.BookkeepingV7}
                                                                action={Action.List}
                                                                extraParams={{
                                                                    aid: transaction.bankAccountId,
                                                                    sourceId: +(transaction.sourceID || 0),
                                                                }}
                                                                isNavigateInNewTab
                                                            />
                                                        </div>
                                                        <div style={{width:'100px'}}>
                                                            {!query ? (
                                                                <Button
                                                                    variant="positive"
                                                                    label="Create query"
                                                                    tableBtnMd
                                                                    onClick={() =>
                                                                        setCreateQueryModalProps((prev) => ({
                                                                            ...prev,
                                                                            show: true,
                                                                            sourceID: transaction.sourceID || 0,
                                                                            key: getJournalTypeById(
                                                                                transaction.journalTypeId as keyof typeof journalTypes,
                                                                                'key'
                                                                            ),
                                                                        }))
                                                                    }
                                                                />
                                                            ) : (
                                                                <Button
                                                                    variant="positive"
                                                                    label="View query"
                                                                    tableBtnMd
                                                                    onClick={() =>
                                                                        navigateToQueries({periodId: periodID, queryId: query.id})
                                                                    
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                        

                                                       
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={9} style={{ padding: 0 }}>
                                            <NoContentSlate type={DatagridType.Transactions} termsKey="emptyTerms" />
                                        </td>
                                    </tr>
                                ))}                           
									
					</tbody>
				</DataGrid.Table>
			</DefaultLayout>


            {createQueryModalProps.show && (
				<CreateQueryModal
					accounts={accounts}
					hide={() => setCreateQueryModalProps((prev) => ({ ...prev, show: false }))}
					create={(pm) =>
						createQuery(
							{
								...pm,
								newAccountId: pm.newAccountId ? +pm.newAccountId : undefined,
								[createQueryModalProps.key]: createQueryModalProps.sourceID,
							},
							() => setCreateQueryModalProps((prev) => ({ ...prev, show: false, sourceID: 0, key: '' }))
						)
					}
				/>
			)}

			
		</>
	);    
};


const QueriesFilter = ({ setFilter, options,placeHolder,isLoaded }: QueriesFilterProps) => {
	return (
        <>
            {!isLoaded &&
            <div className='mr-2'>
                <LoadingPlaceholderFYSelector/>
            </div>
                
            }

            {isLoaded &&
                <div data-cy="selectBtn">
                    <Select
                        className={styles.SelectFilter}
                        classNamePrefix="SelectFilter"
                        onChange={(value) => value ? setFilter(+value.value) : setFilter(undefined)}
                        options={options && options}   
                        placeholder={placeHolder}
                        isClearable
                    />
                </div>
            }
            
        </>
       
            
        
	);
};

export default CreateQuery;
