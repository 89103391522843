import { useContext, useEffect, useRef, useState } from "react";
import CompanyContext from "../../../context/CompanyContext";
import SimpleFullscreen from "../../../components/Fullscreen/SimpleFullscreen";
import { Form, Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import { BrandWrapper } from "../../../components/BrandWrapper/BrandWrapper";
import { Button } from "../../../elements/Button/Button";
import styles from "../People.module.scss";
import {Submit, ListOption } from '../../../elements/EditRow/EditRow';
import { Text, YesNo, Date as DateText } from '../../../elements/DetailRow/DetailRow';
import { Form as ReactForm  } from "react-bootstrap";
import Icon from "../../../elements/Icon/Icon";
import { dateIsValid, getTimeDifference } from "../../../utils/formatDate";
import OptionSelector from "../../../components/OptionSelector/OptionSelector";
import FormikField from "../../../elements/FormikField/FormikField";
import { Summary } from "../../../elements/Summary/Summary";
import classNames from "classnames";
import { PersonCreateModel } from "../../../api/inni/data-contracts";
import Alert from "../../../elements/Alert/Alert";
import { useInniAPI } from "../../../hooks/useInniAPI";
import { People } from "../../../api/inni/People";

interface CreatePeopleProps{
    isOpen: boolean;
    hide: ()=>void;
    onCancel: () => void;
    intialValues: PersonCreateModel;
    formSubmit: (values: PersonCreateModel, actions: FormikHelpers<PersonCreateModel>) => Promise<void>;
    formValidate: (values: PersonCreateModel) => Promise<FormikErrors<PersonCreateModel>>;
    ageWarning: number|null;
    nationalityOptions: ListOption[];
    taxRegimeSl: ListOption[];
    checkAge: (dob:string) => void;
}



const CreatePeople =({hide, isOpen, onCancel, intialValues, formSubmit, formValidate, ageWarning, nationalityOptions, taxRegimeSl, checkAge}:CreatePeopleProps) =>{

    //stages
    const [stageFlow, setStageFlow] = useState<string[]>()
    const [currentStage, setCurrentStage] = useState<string>();

    //context
    const companyContext = useContext(CompanyContext);

    //API
    const peopleApi = useInniAPI(People)
    const formikRef = useRef<FormikProps<PersonCreateModel>>(null);
    
    useEffect(() =>{
        if(peopleApi){
            peopleApi.getPersonCreateStages(companyContext.cid)
            .then((res) =>{
                setStageFlow(res.data)
                setCurrentStage("intro")
            })
            .catch((error) =>{
                console.error(error)
            })
        }
    },[companyContext.cid, peopleApi])

    const getStageDetails = () => {
        switch(currentStage) {
            case "intro":
                return  {    
                            Title: "Add a new person to your company", 
                            Subtitle: `Ready to add a new person to your company? Once set up, you will be able to allocate them shares and a director's loan account if applicable.
                            Let's get started.`,
                        }
            case "name": // has surname and forename
                return  {    
                            Title: "New person's name"
                        }
            case "addInfo": // has gender, date of birth and nationality
                return  {    
                            Title: "Additional information", 
                        }
            case "isOverseas": // checking the person is overseas citizen
                return  {    
                            Title: "Do they live overseas?", 
                        }
            case "address": // address, postcode and country(if overseas)
                return  {    
                            Title:  "Address", 
                        }
            case "taxRegion": // tax region in uk
                return  {    
                            Title:  "Tax region", 
                            Subtitle: "The region they live in will have significance on the amount of tax they pay."
                        }
            case "jobDetails": // job title, shares(if eligible for shares) 
                return  {    
                            Title: "Job title", 
                            Subtitle: "If they are an employee, what is their role within the company?"
                        }
            case "utr": // UTR number
                return  {    
                            Title: "Unique taxpayer reference", 
                            Subtitle: "What is their unique taxpayer reference (UTR) number?"
                        }
            case "isDirector": // is person director
                return  {    
                            Title:  "Is this person a director?", 
                        }
            case "appointment": // director appointment
                return  {    
                            Title:  "Appointment date?", 
                            Subtitle: "When was this person appointed as a director?"
                        }
            case "isCorporate": // is person belongs to corporate
                return  {    
                            Title:  "Is it a corporate entity?", 
                        }
            case "repayMileage": //checking if repaying mileage directly
                return  {    
                            Title:  "Repay mileage directly?", 
                        }
            case "fuelrate": // default advisory fuel rate
                return  {    
                            Title: "Fuel rate", 
                        }
            case "submit": // overview
                return  {    
                            Title: "Summary", 
                            Subtitle: "Please review the information you have provided to ensure its accuracy, you can make any changes if necessary before finalising."
                        }
            default: // intro
                return {    
                            Title: "Add a new person to your company", 
                            Subtitle: `Ready to add a new person to your company? Once set up, you will be able to allocate them shares and a director's loan account if applicable.
                            Let's get started.`,
                        }
        }
    }

    const SkipAppointmentStage = ()=>{
        if(stageFlow && currentStage){
            const skipStage = "appointment"
            if(stageFlow.includes(skipStage)){
                stageFlow.splice(stageFlow.indexOf(skipStage),1)
            }
                
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])
        }
    }

    const AddAppointmentStage = ()=>{
        if(stageFlow && currentStage){
            const addStage = "appointment"
            if(!stageFlow.includes(addStage)){
                stageFlow.splice(stageFlow.indexOf(currentStage)+1,0,addStage)
            }   
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])
        }
    }

    const nextStage = () => {
        if(stageFlow && currentStage) {
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])  
        }
    }

    const validatedDOB = () => {
        if (formikRef.current) {  
            if (formikRef.current.values.dob) {
                const dtNow = new Date();
                const dtDOB = new Date(formikRef.current.values.dob);
                const age = getTimeDifference(dtNow, dtDOB, 'years');
                if ((age === 0 || age !== null) && age < 16) {
                    formikRef.current.setFieldTouched("dob", true, false)
                    formikRef.current.setFieldError("dob", "Your employee must be at least 16 years old")
                } else {
                    formikRef.current.setFieldTouched("dob", false)
                    formikRef.current.setFieldError("dob", undefined)
                    nextStage();
                }
            } else
                nextStage();
        }
    }

    const showField = (fieldName: string) => {
        if (stageFlow) {
            if (fieldName in stageFlow) {
                return false ;
            }
        }
        return true;
    }
    
    
    return(
        <SimpleFullscreen
            isOpen={isOpen}
            onClose={hide}
            onBack={stageFlow && currentStage && stageFlow.indexOf(currentStage) > 0 ?
                () => setCurrentStage(stageFlow[stageFlow.indexOf(currentStage) - 1]) :
                undefined}
            title={getStageDetails().Title}
            subTitle={getStageDetails().Subtitle} 
        >
            <BrandWrapper>
                {stageFlow && 
                    <Formik
                        initialValues={intialValues}
                        enableReinitialize
                        onSubmit={formSubmit}
                        validate={formValidate}
                        validateOnChange={false}
                        innerRef={formikRef}
                    >
                        {({ isSubmitting, values, setFieldValue, errors }) => (
                        <Form>
                             
                            {currentStage === "intro" && 
                                <Button marginTop  variant='primary' thin onClick={nextStage}>Let's go <Icon className={styles.nextArrow} name='arrowNext'/></Button>
                            }
                            {currentStage === "name" &&
                                <>
                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="Title"
                                        name="title"
                                        type="text"
                                    />
                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="Forename"
                                        name="forenames"
                                        type="text"
                                    />
                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="Surname"
                                        name="surname"
                                        type="text"
                                    />

                                    <Button 
                                        disabled={!values.forenames || !values.surname }
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={nextStage}
                                    >
                                        Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                                 
                            }
                            {currentStage === "addInfo" &&
                                <>
                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="Date of birth"
                                        name="dob"
                                        type="date"
                                        afterFormikOnChange={e => {
                                            if(dateIsValid(e.target.value)) {
                                                checkAge(e.target.value)
                                            }
                                        }}
                                    />

                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="Nationality"
                                        name="nationality"
                                        type="select"
                                        option={nationalityOptions.map((x) =>{
                                            return {value: x.value||'', label: x.label}
                                        })}
                                        width="300px"
                                    />

                                    <Button
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={validatedDOB}   
                                    >
                                        Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>

                                    { ageWarning && (
                                        <Alert variant="info" alertMessage={`This employee is ${ ageWarning } years old, is this correct?`} setAlertMessage={() => {}} />
                                    )}
                                </>
                                 
                            }
                            {currentStage === "isOverseas" &&
                                <>
                                    <OptionSelector preventSaveState onChange={(opt) => {
                                            if(opt[0].value === "true"){
                                                setFieldValue('overseas', true)
                                            } 
                                            else{
                                                setFieldValue('overseas', false)
                                                setFieldValue("country", undefined)
                                            }
                                            nextStage();
                                        }}
                                        options={{singleEntry: true, list: [
                                            {label: `Yes`, value: "true"}, 
                                            {label: `No`, value: "false"}
                                        ]}}
                                    />

                                </>
                                 
                            }
                            {currentStage === "address" &&
                                <>
                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="Address"
                                        name="address"
                                        type="textarea"
                                        width="300px"
                                    />

                                    {values.overseas &&
                                            <FormikField<PersonCreateModel>
                                                className={styles.formikFieldsAuto}
                                                label="Country"
                                                name="country"
                                                type="text"
                                            />
                                    }
                                        
                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="Postcode"
                                        name="postcode"
                                        type="text"
                                    />
                                    
                                    
                                    <Button 
                                        disabled={!values.address || !values.postcode || (values.overseas ? !values.country : false) }
                                        variant='primary' 
                                        thin  
                                        marginTop 
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                                 
                            }
                            {currentStage === "taxRegion" &&
                                <>
                                    <OptionSelector preventSaveState onChange={(opt) => {
                                            if(opt[0].value)
                                                setFieldValue('taxRegime', opt[0].value)
                                            nextStage();
                                        }}
                                        options={{singleEntry: true, list: taxRegimeSl.sort((a,b) => b.label.localeCompare(a.label)).map((x) =>{ return {label: x.label, value: x.value||''}})}}
                                    />
                                </>
                                 
                            }
                            {currentStage === "jobDetails" &&
                                <>
                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="Job title"
                                        name="jobTitle"
                                        type="text"
                                    />

                                    <Button
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                                 
                            }
                            {currentStage === "utr" &&
                                <>
                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="UTR"
                                        name="utr"
                                        type="text"
                                        placeholder="Please enter the 10 or 13 digits"
                                    />

                                    <Button
                                        disabled={values.utr !== undefined && values.utr !== "" && (values.utr.length !== 10 && values.utr.length !== 13)}
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                            }
                            {currentStage === "isDirector" &&
                                <>

                                    <OptionSelector preventSaveState onChange={(opt) => {
                                            if(opt[0].value === "true"){
                                                setFieldValue('isDirector', true)
                                                AddAppointmentStage();
                                            }
                                            else{

                                                setFieldValue('isDirector', false)
                                                SkipAppointmentStage();
                                                setFieldValue("directorAppointmentDate", undefined)
                                            }
                                            nextStage();
                                        }}
                                        options={{singleEntry: true, list: [
                                            {label: `Yes`, value: "true"}, 
                                            {label: `No`, value: "false"}
                                        ]}}
                                    />
                                </>
                                 
                            }
                            {currentStage === "appointment" &&
                                <>
                                    
                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="Appointment date"
                                        name="directorAppointmentDate"
                                        type="date"
                                    />

                                    <Button
                                        disabled={!values.directorAppointmentDate}
                                        variant='primary' 
                                        thin  
                                        marginTop 
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                                 
                            }

                            {currentStage === "isCorporate" &&
                                <>
                                    <OptionSelector preventSaveState onChange={(opt) => {
                                            if(opt[0].value === "true")
                                                setFieldValue('isCorporateEntity', true)
                                            else
                                                setFieldValue('isCorporateEntity', false)
                                            nextStage();
                                        }}
                                        options={{singleEntry: true, list: [
                                            {label: `Yes`, value: "true"}, 
                                            {label: `No`, value: "false"}
                                        ]}}
                                    />

                                </>
                                 
                            }
                            {currentStage === "repayMileage" &&
                                <>
                                    <OptionSelector preventSaveState onChange={(opt) => {
                                            if(opt[0].value === "true")
                                                setFieldValue('repayMileageDirectly', true)
                                            else
                                                setFieldValue('repayMileageDirectly', false)
                                            nextStage();
                                        }}
                                        options={{singleEntry: true, list: [
                                            {label: `Yes`, value: "true"}, 
                                            {label: `No`, value: "false"}
                                        ]}}
                                    />

                                    {/* <Switch<PersonCreateModel> name="repayMileageDirectly" labelTop labelTextMuted/> */}
                                </>
                                 
                            }
                            {currentStage === "fuelrate" &&
                                <>
                                    

                                    <FormikField<PersonCreateModel>
                                        className={styles.formikFieldsAuto}
                                        label="Default advisory fuelrate band"
                                        name="defaultAdvisoryFuelrateBand"
                                        type="text"
                                    />

                                    <Button
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                                 
                            }
                            {currentStage === "submit" &&
                                <>
                                    <Summary>
                                        <Text fixedWidth ensureRender entity={values} name={'title'} v8Summary />
                                        <Text fixedWidth ensureRender entity={values} name={'forenames'} v8Summary />
                                        <Text fixedWidth ensureRender entity={values} name={'surname'} v8Summary />
                                        <DateText fixedWidth ensureRender entity={values} name={'dob'} label="Date of birth" v8Summary  />
                                        <Text fixedWidth ensureRender entity={nationalityOptions.find(x => x.value === values.nationality)!} name={'label'} v8Summary  label="Nationality"/>
                                        <Text fixedWidth ensureRender entity={values} name={'address'} v8Summary />

                                        {showField("isOverseas") && 
                                            <YesNo fixedWidth ensureRender entity={values} name={'overseas'} v8Summary />
                                        }

                                        {values.overseas ?
                                                <Text fixedWidth ensureRender entity={values} name={'country'} v8Summary />
                                            :
                                                <Text fixedWidth ensureRender entity={values} name={'postcode'} v8Summary />
                                        }

                                        <Text fixedWidth ensureRender entity={taxRegimeSl.find(x => x.value === values.taxRegime)!} name={'label'} v8Summary label="Tax regime" />
                                        <YesNo fixedWidth ensureRender entity={values} name='isDirector' label="Director" v8Summary />

                                        {values.isDirector &&
                                            <DateText fixedWidth ensureRender entity={values} name={'directorAppointmentDate'} v8Summary />
                                        }

                                        <Text fixedWidth ensureRender entity={values} name={'utr'} label="UTR" v8Summary  />
                                        <Text fixedWidth ensureRender entity={values} name={'jobTitle'} v8Summary />

                                        {showField("isCorporate") &&
                                            <YesNo fixedWidth ensureRender entity={values} name='isCorporateEntity' v8Summary />
                                        }
                                        {showField("repayMileage") &&
                                            <YesNo fixedWidth ensureRender entity={values} name='repayMileageDirectly' v8Summary />
                                        }
                                        {showField("fuelrate") &&
                                            <Text fixedWidth ensureRender entity={values} name={'defaultAdvisoryFuelrateBand'} v8Summary />
                                        }
                                    </Summary>
                                    
                                    {Object.keys(errors).length > 0 &&
                                        <ReactForm.Control.Feedback className={classNames(styles.errorFeedback, 'mb-2')} type="invalid">Please clear the errors in previous stages before submitting</ReactForm.Control.Feedback>
                                    }

                                    <Submit
                                        disabled={isSubmitting} 
                                        onCancelClick={onCancel} 
                                        alignLeft
                                    />
                                </>
                            }

                        </Form>
                        )}
                    </Formik>
                }
            </BrandWrapper>

        </SimpleFullscreen>
    );
  
}

export default CreatePeople;