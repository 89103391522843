import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import Dashboard from "../../features/Dashboard/Dashboard";
import AccountEdit from "../../features/AccountEdit/AccountEdit";

const accountEdit:Array<EntityRoute> = [
    {
        path: '/company/:cid/accounts/editaccounts',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Account,
        action: Action.Edit,
        component: AccountEdit
    },
]

export default accountEdit;

