import { EntityRoute, RouteContext } from './routeTypes';
import Onboarding from '../../features/onboarding/Onboarding';
import { Action, Entity } from '../../utils/EntityAction';

const onboarding: Array<EntityRoute> = [
	{
		path: '/company/:cid/onboarding',
		exact: true,
		context: RouteContext.Company,
		component: Onboarding,
		action: Action.Read,
		entity: Entity.Onboarding
	},
];

export default onboarding;
