import { FormikErrors, FormikHelpers } from 'formik'
import { useContext, useEffect, useState } from 'react'
import { Account, Contract, Property, RegularPaymentPostModel, VatCodeListOption } from '../../../api/inni/data-contracts'
import { Properties } from '../../../api/inni/Properties'
import { RegularPayments as RecurringPayments } from '../../../api/inni/RegularPayments'
import provestorBrand from '../../../components/BrandWrapper/pvBrand'
import BrandContext from '../../../context/BrandContext'
import CompanyContext from '../../../context/CompanyContext'
import { GroupedListOption, ListOption } from '../../../elements/EditRow/EditRow'
import { FormikRowInput, FormikRowLogic, FormikRowSubmitBtns } from '../../../elements/FormikTableEditor/FormikTableEditor'
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { formatSubGroup } from '../../../utils/formatters/formatAccount'

const intervalTypeSL : ListOption[] = [
    { value: 'D', label: 'Day(s)' },
    { value: 'M', label: 'Month(s)' },
    { value: 'Y', label: 'Year(s)' },
]

interface RecurringPaymentEditorProps {
    rp : RegularPaymentPostModel, 
    accounts : Account[], 
    contracts : Contract[], 
    cancelCallback : () => void, 
    creating?: boolean, 
    id?: number, 
    createEditCallback : (values : RegularPaymentPostModel, id : number, creating : boolean) => void,
    showVat : boolean,
    vatRates : VatCodeListOption[]
}

const CreateEditView = ({rp, accounts, contracts, cancelCallback, creating, id, createEditCallback, showVat, vatRates} : RecurringPaymentEditorProps) => {
    const recPaymentAPI = useInniAPI(RecurringPayments, [400])
    const companyContext = useContext(CompanyContext)
    const brandContext = useContext(BrandContext)

    const [accountSelectList, setAccountsSelectList] = useState<GroupedListOption[]>([])
    const [contractSelectList, setContractSelectList] = useState<ListOption[]>([])
    const [properties, propertiesLoaded] = useFetchEntityList<Property, Properties>(Properties, [403]) // [403] - hide 403 error bar (access forbidden - always happens on iA since no properties)

    const [allowAutoBudget, setAllowAutoBudget] = useState(false)

    const [vatLO, setVATLO] = useState<ListOption[]>([...vatRates.map(x => {return {value: x.id, label: x.name || ''}})])

    useEffect(() => {
        let tempAccSL : GroupedListOption[] = []
        accounts.filter(x => x.accountGroup === 'Expenses' || x.accountSubGroup === 'BankAccounts').map(i => {
            const exists = tempAccSL.findIndex(x => x.label === formatSubGroup(i.accountSubGroup))
            if (exists === -1) {
                tempAccSL.push({
                    label: formatSubGroup(i.accountSubGroup) || '',
                    options: [{value: i.id.toString(), label: i.name || ''}]
                })
            } else {
                tempAccSL[exists].options?.push({value: i.id.toString(), label: i.name || ''})
            }
        })
        setAccountsSelectList(tempAccSL)
    }, [accounts])

    useEffect(() => {
        let tempContractSL : ListOption[] = []
        tempContractSL.push({value:'', label: 'None'})
        contracts.filter(x => x.status === 'Active').map(x => tempContractSL.push(
            { 
                value: String(x.id), 
                label: x.contractSubType === "Tenancy" ? `${properties.find(y => y.id === x.propertyId)?.name}/${x.name}` : x.name || ''
            }))
        setContractSelectList(tempContractSL)
    }, [contracts, properties, propertiesLoaded])

    const validateCreate = (values: RegularPaymentPostModel & { recurring: boolean }) : Promise<FormikErrors<RegularPaymentPostModel & { recurring: boolean }>> => {
        return new Promise((resolve, reject) => {

            if (recPaymentAPI) {
                recPaymentAPI.validateCreate(companyContext.cid, values)
                .then(res => {
                    resolve({})
                }).catch(err => {
                    resolve(err.error)
                })
            } else {
                reject()
            }

        })
    }

    const validateUpdate = (id: number, values: RegularPaymentPostModel & { recurring: boolean }) : Promise<FormikErrors<RegularPaymentPostModel & { recurring: boolean }>> => {
        return new Promise((resolve, reject) => {
            if (recPaymentAPI) {
                recPaymentAPI.validateUpdate(companyContext.cid, { recPaymentId: id }, values)
                .then(res => {
                    resolve({})
                }).catch(err => {
                    resolve(err.error)
                })
            } else {
                reject()
            }
        })
    }

    const createCall = (values: RegularPaymentPostModel & { recurring: boolean }, actions: FormikHelpers<RegularPaymentPostModel & { recurring: boolean }>) : Promise<number> => {
        const submitValues = showVat ? values : {...values, vatCode: 'N'}
        return new Promise((resolve, reject) => {
            if (recPaymentAPI) {
                recPaymentAPI.create(companyContext.cid, submitValues)
                .then(res => {
                    // 201 = created
                    if (res.status === 201) {
                        createEditCallback(values, Number(res.data), true)
                        cancelCallback()
                    }
                })
            }
        })
    }

    const editCall = (values: RegularPaymentPostModel & { recurring: boolean }, actions: FormikHelpers<RegularPaymentPostModel & { recurring: boolean }>, id : number) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (recPaymentAPI) {
                recPaymentAPI.update(companyContext.cid, {recPaymentId: id}, values)
                .then(res => {
                    if (res.status === 200) {
                        createEditCallback(values, id, false)
                        cancelCallback()
                    }
                })
            }
        })
    }

     

    const formik = FormikRowLogic<RegularPaymentPostModel & { recurring: boolean }>({
        editRowValues:{...rp, recurring: (rp.interval || 0) > 0}, 
        validateUpdate: validateUpdate, 
        validateCreate: validateCreate, 
        onCreate: createCall, 
        onUpdate: editCall, 
        addingNewRow: creating || false,
        id: id    
    })

    // below as otherwise formik won't be defined...
    useEffect(() => {
        let intervalLength = (formik.values.interval || 0) * ((formik.values.intervalType === 'M') ? 30 : ((formik.values.intervalType === 'Y') ? 365 : 1))
        if (intervalLength >= 60) {
            setAllowAutoBudget(true)
        } else {
            formik.setFieldValue('autoBudget', false)
            setAllowAutoBudget(false)
        }
    }, [formik.values.interval, formik.values.intervalType])   

    useEffect(() => {
        if (contracts.find(x => x.id === Number(formik.values.contractId))?.contractSubType === "Property") {
            formik.setFieldValue('billable', false)
        }
    }, [contracts, formik.values.contractId])

    useEffect(() => {
        let a = accounts.find(x => x.id === Number(formik.values.accountId))?.defaultVatCode
        if (a !== null) {
            formik.setFieldValue('vatCode', a)
        }
    }, [accounts, formik.values.accountId])

    return (<>
        <tr style={{background: 'var(--data-grid-evenrow)'}}>
            <td style={{borderBottom: '0px'}}>
                <label>Due</label>
                <FormikRowInput<RegularPaymentPostModel> formik={formik} property="nextPaymentDate" type="date" />
            </td>
            <td style={{borderBottom: '0px'}}>
                <label>Description</label>
                <FormikRowInput<RegularPaymentPostModel> formik={formik} property="description" type="text" />
            </td>
            <td style={{borderBottom: '0px'}} colSpan={2}>
                <label>Amount (inc. VAT)</label>
                <FormikRowInput<RegularPaymentPostModel> formik={formik} property="amount" type="number" prefix="£" inputProps={{min: 0, step: 0.01}} />
            </td>
            {showVat && <td style={{borderBottom: '0px'}}>
                 <>
                    <label>VAT rate</label>
                    <FormikRowInput<RegularPaymentPostModel> formik={formik} property="vatCode" type="select" options={vatLO} />
                </>
            </td>}
            {showVat && <td style={{borderBottom: '0px'}}></td>}
        </tr>
        <tr style={{background: 'var(--data-grid-evenrow)'}}>
            <td style={{borderTop: '0px', borderBottom: '0px'}}></td>
            <td style={{borderTop: '0px', borderBottom: '0px'}}>
                <label>Payee</label>
                <FormikRowInput<RegularPaymentPostModel> formik={formik} property="payee" type="text" />
            </td>
            <td style={{borderTop: '0px', borderBottom: '0px'}} colSpan={2}>
                <label>Category</label>
                <FormikRowInput<RegularPaymentPostModel> formik={formik} property="accountId" type="groupedselect" groupedOptions={accountSelectList} />
            </td>
            <td style={{borderTop: '0px', borderBottom: '0px'}} colSpan={1}>
                <div className='d-flex justify-content-start'>
                <div className='w-75'>
                <label>{brandContext.brand === provestorBrand ? 'Property/tenancy' : 'Contract'}</label>
                <FormikRowInput<RegularPaymentPostModel> formik={formik} property="contractId" type="select" options={contractSelectList} />
                </div>
               <div className='pl-5'>
               <label>Rebill</label>
                {/* Disabled if contract is type of Property -> you can't rebill directly to a property (do through tenancy) */}
                <FormikRowInput<RegularPaymentPostModel> disabled={contracts.find(x => x.id === Number(formik.values.contractId))?.contractSubType === "Property"} formik={formik} property="billable" type="check" />
               </div>
                </div>
            </td>
            <td></td>
        </tr>
        <tr style={{background: 'var(--data-grid-evenrow)'}}>
            <td style={{borderTop: '0px'}}>
                <label>Repeating payment</label>
                <FormikRowInput<RegularPaymentPostModel & {recurring: true}> formik={formik} property="recurring" type="check" />
            </td>            
            <td style={{borderTop: '0px'}}>
                <label>Auto budget</label>
                <FormikRowInput<RegularPaymentPostModel> formik={formik} property="autoBudget" type="check"  disabled={!allowAutoBudget} />
                {!allowAutoBudget && <div style={{width: '100%', marginTop: '-15px'}} className="text-muted small">N/A or interval too short</div>}
            </td>
            {/* This stuff is hidden when repeating payment is not checked, when not checked we just display an empty td w/ colspan 2 */}
            {formik.values.recurring ? <>
                <td style={{borderTop: '0px'}}>
                    <label>Payment interval</label>
                    <FormikRowInput<RegularPaymentPostModel> inputProps={{min: '0'}} formik={formik} property="interval" type="number" />                
                </td>
                <td style={{borderTop: '0px'}}>
                    <label>&nbsp;</label>
                    <FormikRowInput<RegularPaymentPostModel> formik={formik} property="intervalType" type="select" options={intervalTypeSL} />
                </td>
                <td style={{borderTop: '0px'}} colSpan={2}></td>
            </>
            : <td colSpan={4} style={{borderTop: '0px'}}></td>}
        </tr>
        <tr style={{background: 'var(--data-grid-evenrow)'}}>            
            <td colSpan={showVat ? 6 : 5}>
                <FormikRowSubmitBtns small={false} handleSubmit={formik.submitForm} handleClose={cancelCallback} />
            </td>
        </tr>
    </>)
}

export default CreateEditView