import { useContext } from 'react';
import styles from './LoadingShim.module.css'
import classNames from 'classnames';
import CompanyContext from '../../context/CompanyContext';

export const LoadingShim = () => {

    const companyContext = useContext(CompanyContext);
    const v8Styling = companyContext.company?.useV8UI || false;

    return (
        <div className={classNames(styles.loadingShim, {[styles.v8LoadingBackground] : v8Styling })}>
            <div className={styles.loader} />
        </div>
    )
}