import styles from './HorizontalTabs.module.scss'
import React from 'react'
import classNames from 'classnames'
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export interface TabsProps {
    children: any,
    strictMargin?: boolean
}

export const Tabs = ({ children, strictMargin }: TabsProps) => {
    return (
        <div className={classNames(styles.horizontalTabContainer, {'mb-5': strictMargin})}>
            {children}
        </div>
    )
}

interface TabProps {
    onClick?: (k: string) => void,
    selected?: boolean,
    title: string,
    keyName: string,
    small?: JSX.Element | JSX.Element[] | string,
    adminOnly?: boolean,
    disabled?: boolean,
    disabledMessage?: string
}

export const Tab = ({ selected, title, keyName, onClick, small, adminOnly, disabled, disabledMessage }: TabProps) => {

    const tooltip = (props: any) => (
        <Tooltip id={title + "tooltip"} {...props}>
            {disabledMessage}
        </Tooltip>
    )

    const tabElement = (
        <div
            data-cy="horizontalTab"
            data-adminonly={adminOnly}
            onClick={() => onClick && !disabled && onClick(keyName)}
            className={`
                ${styles.horizontalTab}
                ${selected ? styles.selected : ''}
                ${adminOnly ? styles.adminOnly : ''} 
                ${disabled && !selected ? styles.disabled : ''}`}
        >
            {title}
            {/* Maybe move below? */}
            {small && <span className={classNames('small', 'text-muted', styles.smallTxt)}> ({small})</span>}
        </div>
    )

    if (disabled && disabledMessage) {
        return (
            <OverlayTrigger placement='bottom' overlay={tooltip}>
                { tabElement }
            </OverlayTrigger>
        )
    } else {
        return tabElement
    }


}

export const TabLoading = () => {

    return (
        <div className={styles.horizontalTab}>
            <LoadingPlaceholder width="100px" />
        </div>
    )

}