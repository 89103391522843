

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import configHelper  from '../utils/configHelper';


const AI_PROD_CONNECTION = 'InstrumentationKey=b90ac76c-1473-4b33-b878-8fc59d95ccca;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/;ApplicationId=f0b9709d-358c-46b6-abdc-565fb687b218'
const AI_DEV_CONNECTION = 'InstrumentationKey=ed6fbb34-ffee-4008-817a-19792cc9ed69;IngestionEndpoint=https://uksouth-1.in.applicationinsights.azure.com/;LiveEndpoint=https://uksouth.livediagnostics.monitor.azure.com/;ApplicationId=31cc1333-7deb-409d-904e-e1f101d49540'

var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        connectionString: configHelper.environment !== 'dev' ? AI_PROD_CONNECTION : AI_DEV_CONNECTION,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin]
        }
    }
);
appInsights.loadAppInsights();

export { reactPlugin, appInsights };
