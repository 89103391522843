import Stages from "./Components/onBoardingStages"
import styles from './OnBoarding.module.css'
import { getStoryblokApi} from "@storyblok/react"; 
import { SbBlokData } from "@storyblok/react";
import { useContext, useEffect, useState } from "react";
import { useInniAPI } from "../../hooks/useInniAPI";
import {OnboardingSetup} from "../../api/inni/OnboardingSetup"
import CompanyContext from "../../context/CompanyContext";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout";
import { Action, Entity } from "../../utils/EntityAction";
import UserContext from "../../context/UserContext";
import { Person, SetupStatus } from "../../api/inni/data-contracts";
import LoadingSetup from "../../elements/LoadingOnboardingSetup/LoadingOnboardingSetup";
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder/LoadingPlaceholder";
import { formatNaturalLanguageDate } from "../../utils/formatDate";
import Confetti from 'react-confetti'
import { People } from "../../api/inni/People";
import { useGetUrl } from "../../hooks/useGetUrl";
 
export interface StagesSBData extends SbBlokData {
    Key:string,
    Title: string,
    Info: string,
    ButtonText: string,
    CompletedButtonText: string,
    InfoLink: string,
    InfoLinkText:string
    ButtonRedirect:string,
    CompletedButtonRedirect:string,
    ButtonDisableText: string
}

interface headerSBData extends SbBlokData {
    Header: string,
    Protip: string,
    Footnotes: string
}


const OnboardingSetupPage= ()=>{
    const onboardingSetupAPI = useInniAPI(OnboardingSetup, [403])
    const peopleAPI = useInniAPI(People, [403])
    const companyContext = useContext(CompanyContext);
    const userContext = useContext(UserContext);
    const companyId = companyContext.cid;
    const [stagesStatus, setStagesStatus] = useState<SetupStatus>();
    const [stagesData, setStagesData]= useState<StagesSBData[]|undefined>()
    const [headerData, setHeaderData]= useState<headerSBData|undefined>()
    const [loading,setLoading]=useState(true);    
    const  [allStagesCompleted, setALLStagesCompleted] = useState(false);
    const [people, setPeople] = useState<Person[]|undefined>();
    const getUrl = useGetUrl();

    useEffect(()=>{
        setLoading(true)
        if(onboardingSetupAPI){
            onboardingSetupAPI.index(companyId).then(res => {
                //TODO: Remove below line, after company setup  status is made true  in API
                res.data.companySetupCompleted = true;
                setStagesStatus(res.data)
            }).catch(err => {
                if(err.status === 403){
                    setALLStagesCompleted(true)
                    setLoading(false)
                }
                else{
                    console.error(err)
                }
                
            });
        }

    },[onboardingSetupAPI, companyId]);


    useEffect(()=>{
        if (stagesStatus && stagesStatus?.storyblokPath) {
            const storyblokApi = getStoryblokApi();
            storyblokApi.get(stagesStatus.storyblokPath, { version: 'published'})
            .then(res => {
                const {Header, Protip, Footnotes, Stages} = res.data.story.content
                
                const newData: headerSBData = {
                    Header, Protip, Footnotes, _uid: "", component: ""
                };

                // Set the state with the new object
                setHeaderData(newData);
                setStagesData(Stages)
                setLoading(false)

            }).catch(err => {
                console.error(err)
            });
        }
    }, [stagesStatus])

    useEffect (() =>{
        if(peopleAPI){
            peopleAPI.index(companyContext.cid)
            .then((res) =>{
               setPeople(res.data)
            })
            .catch((err) =>{
                console.error(err)
            })
        }
    }, [companyContext.cid, peopleAPI])

    const ActiveStage=()=>{
        if(stagesStatus && stagesData){
            for (const stage of stagesData) {
                if(!(stagesStatus as any)[stage.Key]) {
                    return stage.Key
                }
            }
        }
        return undefined
    }

    const getButtonRedirection = (stage:string, redirectLink: string): string =>{
        if(stage === "setupShareholdings"){
            if(people && people.length > 0){
                return redirectLink
            }else{
                return getUrl(Entity.PersonV7, Action.Create).url
            }
        }
        else
            return redirectLink
    }

    const getLinkText =(stage: string, storyBlokLink: string ) : string =>{
        if(stagesStatus){
            if(stage === "setupMissingTransactionsCompleted"){
                //TODO: Uncomment below once the deisgn is finalised
                //return stagesStatus.missingTransactionsMessage || ""
                return ""
            }
            else if(stage === "setupAllTransactionsAllocated"){
                return stagesStatus.pendingUnallocatedExpenses || ""
            }
            else{
                return storyBlokLink;
            }
        }
        else{
            return storyBlokLink
        }
    }

    const getInfo =(stage: string, info: string ) : string =>{
        if(stagesStatus){
            if(stage === "setupFinal"){
                return `${info} ${formatNaturalLanguageDate(stagesStatus.submissionDate)}.` || ""
            }
            else{
                return info;
            }
        }
        else{
            return info
        }
    }

    const isButtonDisabled = (stage:string): boolean =>{
        if(stagesStatus){
            if(stage === "setupMissingTransactionsCompleted" || stage === "setupAllTransactionsAllocated"){
                return !stagesStatus.setupOnlineBanking
            }
            else
                return false
        }
        else{
            return false
        }
    }

    return(
        <DefaultLayout 
            entity={Entity.OnboardingSetup}
            title="Setup"
        >
            { allStagesCompleted &&
                    <div className={styles.allDone}>
                    <div className="my-auto text-center">
                        <Confetti
                            height={window.innerHeight}
                            width={1220}
                            numberOfPieces={100}
                            recycle={false}
                            confettiSource={
                                {
                                    x:0,
                                    y: 0,
                                    w: window.innerWidth,
                                    h: 400 
                                }
                            }
                            initialVelocityY={{min: -6, max: -8}}
                            gravity={0.3}
                            opacity={0.3}
                        />
                        <img src="./images/flat-icons/confetti.png" alt="Confetti" />
                        <h3><b>Great job!</b> Setup is complete</h3>
                    </div>
                </div>
            }

            {!allStagesCompleted &&
                <div className={styles.outerLayout}>
                    <div className={styles.innerLayout}>

                        {loading  ?

                                <LoadingPlaceholder width="70%" height="3rem" />
                            : 
                                headerData && headerData.Header &&
                                    <p className={styles.welcomeText}>🌟 Hey {userContext.user?.firstName},&nbsp;{headerData.Header}</p>
                        }
                        
                        <div className={styles.stagesLayout}>

                            {(loading || !stagesData) &&
                                <>
                                    {Array(4)
                                        .fill(0)
                                        .map((_, index) => (
                                            <div key={index}>
                                                <LoadingSetup/>
                                            </div>
                                        ))}
                                </>
                            }

                            {!loading && stagesData  && stagesStatus && stagesData.map((data:StagesSBData,index:number)=>{
                                return(
                                    <Stages 
                                        key={data.Key}
                                        number={index + 1}
                                        title={data.Title}
                                        info={getInfo(data.Key, data.Info)}
                                        buttonText={data.ButtonText}
                                        completedButtonText={data.CompletedButtonText}
                                        link={data.InfoLink}
                                        linkText={getLinkText(data.Key, data.InfoLinkText)}
                                        status={(stagesStatus as any)[data.Key]}
                                        active={ActiveStage()===data.Key}
                                        buttonRedirect={getButtonRedirection(data.Key, data.ButtonRedirect) || data.ButtonRedirect} 
                                        completedButtonRedirect={data.CompletedButtonRedirect}
                                        buttonDisabled ={isButtonDisabled(data.Key)}
                                        disabledMessage={data.ButtonDisableText}
                                    />
                                );

                            })}


                            { (loading || (headerData && headerData.Protip)) &&
                                <div className={styles.protipOuter}>
                                    <div className={styles.protipInner}>
                                        <p className={styles.proTiptitle}> 💡 Pro tip:</p>
                                        {loading ?
                                                <LoadingPlaceholder height="3rem" />
                                            :
                                                headerData && headerData.Protip && 
                                                    <p className={styles.proTipInfo}>{headerData.Protip}</p>
                                        }      
                                    </div>
                                </div>
                            }

                            {loading ?
                                    <LoadingPlaceholder width="70%" height="3rem"/>
                                :
                                    headerData && headerData.Footnotes &&
                                            <p className={styles.endText}>{headerData.Footnotes.split('.')[0]}.<br />{headerData.Footnotes.slice(headerData.Footnotes.indexOf('.') + 1)}</p>
                            }
                        </div>
                    </div>
                </div>
            }
            
        </DefaultLayout>
    );

}


export default OnboardingSetupPage;