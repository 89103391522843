/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CompletionStatementStatusPostModel,
  LinkPropertyResult,
  Property,
  PropertyAsSummary,
  PropertyCreateModel,
  PropertyPortfolioSummary,
  PropertyUpdatePostModel,
  SelectlistModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Properties<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Properties
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/properties
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Property[], any>({
      path: `/api/v2/companies/${companyId}/properties`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/properties
   * @secure
   */
  create = (companyId: number, propertyDetails: PropertyCreateModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/properties`,
      method: "POST",
      body: propertyDetails,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/properties
   * @secure
   */
  delete = (companyId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/properties/{propertyId}
   * @secure
   */
  update = (companyId: number, propertyId: number, postModel: PropertyUpdatePostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name DeleteProperty
   * @summary Deletes specific property
   * @request DELETE:/api/v2/companies/{companyId}/properties/{propertyId}
   * @secure
   */
  deleteProperty = (companyId: number, propertyId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name ArchiveProperty
   * @summary Archives the property
   * @request PUT:/api/v2/companies/{companyId}/properties/archive/{propertyId}
   * @secure
   */
  archiveProperty = (companyId: number, propertyId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/archive/${propertyId}`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name UnarchiveProperty
   * @summary unarchives the property
   * @request PUT:/api/v2/companies/{companyId}/properties/unarchive/{propertyId}
   * @secure
   */
  unarchiveProperty = (companyId: number, propertyId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/unarchive/${propertyId}`,
      method: "PUT",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name List
   * @request GET:/api/v2/companies/{companyId}/properties/List
   * @secure
   */
  list = (companyId: number, params: RequestParams = {}) =>
    this.request<PropertyAsSummary[], any>({
      path: `/api/v2/companies/${companyId}/properties/List`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name ListAsDetailed
   * @request GET:/api/v2/companies/{companyId}/properties/ListAsDetailed
   * @secure
   */
  listAsDetailed = (companyId: number, params: RequestParams = {}) =>
    this.request<Property[], any>({
      path: `/api/v2/companies/${companyId}/properties/ListAsDetailed`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name IndexAsSelectList
   * @request GET:/api/v2/companies/{companyId}/properties/SelectList
   * @secure
   */
  indexAsSelectList = (companyId: number, params: RequestParams = {}) =>
    this.request<SelectlistModel[], any>({
      path: `/api/v2/companies/${companyId}/properties/SelectList`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name PortfolioSummary
   * @request GET:/api/v2/companies/{companyId}/properties/PortfolioSummary
   * @secure
   */
  portfolioSummary = (companyId: number, params: RequestParams = {}) =>
    this.request<PropertyPortfolioSummary, any>({
      path: `/api/v2/companies/${companyId}/properties/PortfolioSummary`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name Get
   * @request GET:/api/v2/companies/{companyId}/properties/{propertyid}
   * @secure
   */
  get = (companyId: number, propertyid: number, params: RequestParams = {}) =>
    this.request<Property, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name UploadImage
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyid}/uploadImage
   * @secure
   */
  uploadImage = (companyId: number, propertyid: number, data: { fileToUpload: File }, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/uploadImage`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name DeleteImage
   * @request DELETE:/api/v2/companies/{companyId}/properties/{propertyid}/Image
   * @secure
   */
  deleteImage = (companyId: number, propertyid: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/Image`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name UploadCompletionStatement
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyid}/uploadCompletionStatement
   * @secure
   */
  uploadCompletionStatement = (
    companyId: number,
    propertyid: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/uploadCompletionStatement`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name UploadSaleCompletionStatement
   * @request POST:/api/v2/companies/{companyId}/properties/{propertyid}/uploadSaleCompletionStatement
   * @secure
   */
  uploadSaleCompletionStatement = (
    companyId: number,
    propertyid: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/uploadSaleCompletionStatement`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name DeleteCompletionStatement
   * @request DELETE:/api/v2/companies/{companyId}/properties/{propertyid}/CompletionStatement
   * @secure
   */
  deleteCompletionStatement = (companyId: number, propertyid: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/CompletionStatement`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/properties/Validate
   * @secure
   */
  validateCreate = (companyId: number, postmodel: PropertyCreateModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name CreateMinimalProperty
   * @request POST:/api/v2/companies/{companyId}/properties/CreateMinimalProperty/{propertyName}
   * @secure
   */
  createMinimalProperty = (companyId: number, propertyName: string, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/properties/CreateMinimalProperty/${propertyName}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/properties/{propertyid}/Validate
   * @secure
   */
  validateUpdate = (
    companyId: number,
    propertyid: number,
    postmodel: PropertyUpdatePostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyid}/Validate`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name LinkExternalProperty
   * @summary Attaches a property from an external service to one which has already been created in the database
   * @request PUT:/api/v2/companies/{companyId}/properties/LinkExternalProperty
   * @secure
   */
  linkExternalProperty = (companyId: number, linkProperties: Record<string, string>, params: RequestParams = {}) =>
    this.request<LinkPropertyResult[], any>({
      path: `/api/v2/companies/${companyId}/properties/LinkExternalProperty`,
      method: "PUT",
      body: linkProperties,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name CreateFromExternalProperty
   * @request POST:/api/v2/companies/{companyId}/properties/CreateFromExternalProperty
   * @secure
   */
  createFromExternalProperty = (companyId: number, externalPropertyIds: string[], params: RequestParams = {}) =>
    this.request<LinkPropertyResult[], any>({
      path: `/api/v2/companies/${companyId}/properties/CreateFromExternalProperty`,
      method: "POST",
      body: externalPropertyIds,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Properties
   * @name UpdateCompletionStatement
   * @request PUT:/api/v2/companies/{companyId}/properties/{propertyId}/UpdateCompletionStatement
   * @secure
   */
  updateCompletionStatement = (
    companyId: number,
    propertyId: number,
    statementData: CompletionStatementStatusPostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/properties/${propertyId}/UpdateCompletionStatement`,
      method: "PUT",
      body: statementData,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
