import { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import provestorBrand from '../../components/BrandWrapper/pvBrand'
import BrandContext from '../../context/BrandContext'
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext'
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout'
import { Action, Entity } from '../../utils/EntityAction'
import LeftHandCard from './Components/LeftHandCard'
import RightHandCard from './Components/RightHandCard'
import configHelper from '../../utils/configHelper'
import { useHasPermission } from '../../hooks/useHasPermission'
import { useOnboardingMessages } from '../../hooks/onboarding/useOnboardingMessages'

const Dashboard = () => {
    const companyContext = useContext(CompanyContext)
    const brandContext = useContext(BrandContext)
    const isMobile = configHelper.useMobile;
    const hasLiveCash = useHasPermission()(Entity.LiveCash, Action.Read)[0]
	const [unreadPageMessages, setMessageRead, getOnboardingComponent] = useOnboardingMessages('dashboard');
    const isCap = companyContext.product === CompanyProduct.isCap;
    const v8Styling = companyContext.company?.useV8UI || false;

    return (
        <DefaultLayout 
            entity={brandContext.brand === provestorBrand? Entity.PropertyDashboard : Entity.CompanyDashboard} 
            greyBackground 
            title={companyContext.company?.name || ''} 
            useFullWidth
        >  
			{ unreadPageMessages?.includes('info-boxes/onboarding/dashboard_welcome') &&
                <Container className="ml-0 pb-3">
                    { getOnboardingComponent('dashboard_welcome') }
                </Container>
            }
            
            { hasLiveCash
                ? (
                    <Row>
                        { !isMobile && (
                            <Col md={12} lg={12} xl={5}>
                                <LeftHandCard />
                            </Col>
                        )}

                        <Col md={12} lg={12} xl={isMobile ? 12 : 5}>
                            <RightHandCard />
                        </Col>
                    </Row>
                )
                : (
                    <Container className={v8Styling ? 'ml-0 p-0' : 'ml-0'}>
                        { isMobile && isCap
                            ? (
                                <h4 className="mt-5 text-muted">
                                    Sorry, the features on your subscription are not supported on mobile devices yet.
                                    <br /><br />
                                    Please sign in on a desktop device.
                                </h4>
                            )
                            : (
                                <LeftHandCard />
                            )
                        }
                    </Container>
                )
            }
        </DefaultLayout>
    )
}

export default Dashboard