import React from 'react';
import { CardWrapper } from './CardWrapper';
import styles from './DashboardCards.module.scss'
import { ResponsivePie, DefaultRawDatum, PieCustomLayerProps, PieCustomLayer, ComputedDatum, PieLayer } from '@nivo/pie'


export interface PieChartValue {
    id: string | number,
    value: number,
    color?: string,
    colorName?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark",
    label?: string 
}

export interface PieChartProps {
    label: string,
    helpText?: string,
    loading?: boolean,
    data: Array<PieChartValue>,
    calculateTotal?: boolean,
    totalText?: string,
    showLegend?: boolean,
    labels?: "value" | "label",
    valueFormat?: (value: number) => string | number,
    sliceLabelColor?: "primary" | "secondary" | "success" | "danger" | "warning" | "info" | "light" | "dark",
    height?: number,
    controls?: React.ReactChild
}


export const PieChart = ({label, helpText, loading, data, calculateTotal, valueFormat, sliceLabelColor, showLegend, labels, height, totalText, controls}: PieChartProps) => {

    const CenteredMetric = ({ dataWithArc , centerX , centerY  }: PieCustomLayerProps<any>)  => {
        let total = 0
        dataWithArc.forEach(datum  => {
            total += datum.value
        })

        const value = () => {
            if (totalText) return totalText;
            return valueFormat ? valueFormat(total) : total;
        }
    
        return (
            <text className={styles.pieChartTotal}
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '30px' 
                }}
            >
                {value()}
            </text>
        )
    }


    const layers = () : PieLayer<PieChartValue>[] | undefined => {
        if (calculateTotal || totalText) {
            return ['slices', 'sliceLabels', 'radialLabels', 'legends', CenteredMetric]
        } 
        return undefined;
    }

    const hasColors = () => {
        let h = false;
        data.forEach(item => {
            if (item.colorName || item.color) h = true
        })
        return h;
    }

    const getColor = (dataPoint: PieChartValue) : string  => {
        if (dataPoint.colorName) return `var(--${dataPoint.colorName})`;
        if (dataPoint.color) return dataPoint.color || '';
        return ''         
    }
    /*

    */

    return (
        <CardWrapper label={label} helpText={helpText} loading={loading} height={height || 3} controls={controls}>
            {!loading &&
                <ResponsivePie 
                data={data}
                enableRadialLabels={false}
                enableSliceLabels={true}
                sliceLabelsTextColor={sliceLabelColor ? `var(--${sliceLabelColor})` : undefined}
                sliceLabel={labels ? (datum) => (labels === 'label' ? datum.label : datum.value) : ''}
                sliceLabelsRadiusOffset={1.5}
                colors={hasColors() ? (datum) => getColor(datum.data) : { scheme: 'paired' } }
                valueFormat={valueFormat}
                tooltip={(datum) => <div className={styles.pieChartTip}><strong>{datum.datum.label}</strong><br/>{datum.datum.formattedValue}</div>}
                innerRadius={(calculateTotal || totalText) ? 0.75 : undefined}
                layers={layers()}
                margin={{
                    bottom: showLegend ? 50 : 25,
                    top: 25
                }}                
                legends={showLegend ? [
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        itemWidth: 100,
                        itemHeight: 15,
                        translateY: 40
                    }
                ] : []}
                />     
            }
        </CardWrapper>
    )

}