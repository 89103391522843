import { PvSatrPostModel } from "../../../../api/inni/data-contracts";
import FormikField from "../../../../elements/FormikField/FormikField";
import styles from '../Income.module.scss'
import Attachments from "../../../../components/Attachments/Attachments";
import { FileRejection } from "react-dropzone";
import { useFormikContext } from "formik";
import { ChangeEvent, useEffect } from "react";
import { Form } from "react-bootstrap";

interface TaxableStateBenefitsProps{

    onFilesDropped: (acceptedFiles: File[], fileRejections: FileRejection[],  section: string) => void;
    filesToUpload: File[]|undefined|null;
    removePendingFile: (fileName: string, section: string) => void;
    removeAllFiles: (section: string) => void;
    showError?: boolean
    showTaxableBenefits: boolean
    setShowTaxableBenefits:(showPension: boolean) => void
}

export default function TaxableStateBenefits ({onFilesDropped, filesToUpload, removePendingFile, removeAllFiles, showError, showTaxableBenefits, setShowTaxableBenefits}:TaxableStateBenefitsProps){

    const { values, setFieldValue } = useFormikContext<PvSatrPostModel>();

    useEffect(() =>{
        setShowTaxableBenefits(
                !!values.stateBenefitsDetails || !!values.attachments?.some(x => x.section === "Taxable")
            )
    },[])

    const handleFileDrop=(acceptedFiles: File[], fileRejections: FileRejection[])=>{
        onFilesDropped(acceptedFiles, fileRejections, "Taxable" )

    }

    const handleRemoveFile =(fileName:string)=>{
        removePendingFile(fileName,  "Taxable")
    }

    const handleShowTaxBenefitsChange =(e: ChangeEvent<HTMLInputElement>)=>{
        const isChecked = e.target.checked
        setShowTaxableBenefits(isChecked)

        if(!isChecked){
            setFieldValue("stateBenefitsDetails", undefined, false)
            removeAllFiles("Taxable");
        }
    }
  
    return(
        <>
            <Form.Group>
                <Form.Label>Have you received taxable state benefits this tax year?</Form.Label>
                    <Form.Check
                        type='checkbox'
                        checked={showTaxableBenefits}
                        data-cy="showTaxBenefits"
                        onChange={handleShowTaxBenefitsChange}
                    />
            </Form.Group>
            
            {showTaxableBenefits &&
                <>
                    <FormikField<PvSatrPostModel> 
                        className={styles.formikField}
                        name={"stateBenefitsDetails"}
                        label="For each benefit, please specify the total amount received and the type of benefit"
                        type="textarea"                                    
                        regularLabel
                        wrapLabel
                    />

                    <div className={styles.attachment}>
                        <Attachments
                            title='Attach files'
                            accept={['image/*','.pdf','.doc','.docx']}
                            onDrop={handleFileDrop}
                            message='Please upload your P60 or P45 detailing the benefit(s), or any other relevant supporting documents'
                            attachedFiles={filesToUpload}
                            onRemoveFile={handleRemoveFile}
                            hidefiles
                            fullWidth
                            darkenedMode
                            regularLabel
                            showError={showError}
                        />

                        {showError &&
                            <Form.Control.Feedback 
                                className={styles.errorFeedback} 
                                type="invalid"
                            >
                                    Please upload the relevant document(s)
                            </Form.Control.Feedback>
                        }
                    </div>
                </>
            }
            
        </>  
    )
}