import { Form, Formik } from 'formik'
import { CompanyVATSettingsPostModel} from '../../../api/inni/data-contracts'
import { DateSelector,ListOption,SelectList,Submit, Switch, Text } from '../../../elements/EditRow/EditRow';
import useCompareChange from '../../../hooks/useCompareChange';
import { CompanySettingsProps } from '../CompanySettingsDataTypes';

type VATSettingsProps = {
  flaterateCategories:NonNullable<ListOption[]>
}

function VATSettingsTab<T extends CompanyVATSettingsPostModel>({
	settings,
	handleSubmit,
	handleValidate,
	flaterateCategories,
}: CompanySettingsProps<T> & VATSettingsProps) {
	const formikRef = useCompareChange<T>();

	return (
		<Formik initialValues={settings as T}
		 onSubmit={handleSubmit}
		 validate={handleValidate}
		 enableReinitialize
		 validateOnBlur
		 validateOnChange={false}
		 innerRef={formikRef}>
			{({ isSubmitting, dirty,values,initialValues }) => (
				<Form>
					<Text<CompanyVATSettingsPostModel> name="vatRegistrationNumber" label="Vat Registration number" />
					<SelectList<CompanyVATSettingsPostModel> name="vatFlatRateCategoryID"
           label="VAT flat rate category" options={flaterateCategories} />
					<DateSelector<CompanyVATSettingsPostModel>
						name="overrideNextVATReturnDate"
						label="Override next VAT return date"
						help="When used with MTD it will only affect the preview (not the real return).
             Obligations for periods ending before this date will be ignored and will not create tracker entries."
					/>
					<Switch<CompanyVATSettingsPostModel> name="vatDirectDebit" label="VAT direct debit" />
					<Switch<CompanyVATSettingsPostModel> name="vatReturnsRequireAccountantReview" label="Vat returns require accountant review" />
					<Text<CompanyVATSettingsPostModel> name="vatNotes" label="VAT notes" type="textarea" />
					<Submit disabled={isSubmitting || !dirty} onCancelClick={() => {}} />
				</Form>
			)}
		</Formik>
	);
}

export default VATSettingsTab