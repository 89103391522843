import { SimpleKpi } from "../../../elements/DashboardCards/SimpleKpi"

export const ConsultationCountKpi = ({loading, numFree, numUsed} : {loading?: boolean, numFree?: number, numUsed?: number}) => {
    const count = () => {
        return (
            <>
                <span className="text-success">{numFree && numFree >= 0 ? numFree : 0}</span>&nbsp;/&nbsp;
                <span className="text-danger">{numUsed}</span>&nbsp;/&nbsp;
                <span className={`${numFree && numFree < 0 ? 'text-danger' : ''}`}>{(numFree || 0) + (numUsed || 0)}</span>
            </>
        )
    }

    return (
        <SimpleKpi loading={loading} label="Consultations" value={count()} helpText="Free / used / total" />
    )
}
