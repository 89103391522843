

export const CompareValues = (datasetOne: any, datasetTwo: any, replaceNull?: boolean): boolean => {
	if (typeof datasetOne !== typeof datasetTwo) return false;
	if (replaceNull) {
		if (typeof datasetOne === "object" && typeof datasetTwo === "object") {
			let objData = [datasetOne, datasetTwo];
			objData.forEach((obj: any) => {
				Object.keys(obj).forEach(function (key) {
					if (obj[key] === null) {
						obj[key] = "";
					}
				});
			});
		}
	}
	// Object comparison
	if (JSON.stringify(datasetOne) === JSON.stringify(datasetTwo)) return true;
	//Array comparison
	if ((Array.isArray(datasetOne) && Array.isArray(datasetTwo))) {
		if (datasetOne.length !== datasetTwo.length && datasetOne.every((v: any, i: any) => v === datasetTwo[i])) return true;
	}
	// String,int,bool comparison
	if (datasetOne === datasetTwo) return true;
	return false;
};
