// General functions and types for live cash

import { LiveCashItemV6 } from '../../../api/inni/data-contracts';
import { PieChartValue } from '../../../elements/DashboardCards/PieChart';

export interface LiveCashTree {
    key?: string,
    cashItem: LiveCashItemV6 | undefined,
    children?: LiveCashTree[]
    referenceValue?: number
    valueOffset?: number
}

const colorMap : Map<string, string[]> = new Map([
    ["AS", ['var(--livecash-positive)', 'var(--livecash-positive-disabled)']],
    ["LB", ['var(--livecash-negative)', 'var(--livecash-negative-disabled)']],
    ["CA", ['var(--livecash-available)', 'var(--livecash-available)']]
])

export const getPieChartData = (key : string, data : LiveCashTree, parent? :string) => {

    let pieData : PieChartValue[] = []

    let pieChart = { ...data.children?.find(x => x.key === key) }
    if (key === 'CA')
        pieChart = data

    if (!pieChart.cashItem)
        return []

    const enabled = pieChart.referenceValue === 0 ? 0 : (pieChart.cashItem.valueToDisplay / (pieChart.referenceValue || 1)) * 100
    const disabled = pieChart.referenceValue === 0 ? 0 : (pieChart.cashItem.valueOfDisabled / (pieChart.referenceValue || 1)) * 100
    const offset = pieChart.referenceValue === 0 ? 0 : ((pieChart.valueOffset || 1) / (pieChart.referenceValue || 1)) * 100
    const isNegative = pieChart.cashItem.valueIsNegative
    const remaining = 100 - offset - enabled - disabled;

    let c = colorMap.get(key)
    if (parent) {
        c = colorMap.get(parent)
    }

    if (offset > 0) {
        pieData.push({ id: 0, value: offset, color: 'var(--livecash-remainderfill)', label: key })
    }
    if (enabled > 0) {
        pieData.push({ id: 1, value: enabled, color: c && !isNegative ? c[0] : 'var(--livecash-negative)', label: key }) // if for colour
    }
    if (disabled > 0) {
        pieData.push({ id: 2, value: disabled, color: c && !isNegative ? c[1] : 'var(--livecash-negative', label: key }) // if for colour
    }
    if (remaining > 0) {
        pieData.push({ id: 3, value: remaining, color: 'var(--livecash-remainderfill)', label: key })
    }

    return pieData;

}

export const unflattenData = (data : LiveCashItemV6[],parent?: LiveCashTree,tree?: LiveCashTree[]) => {

    let t = tree ? tree : []
    parent = parent ? parent : { key: 'CA', cashItem: data.find(x => x.key === 'CA') } 

    let children : LiveCashTree[] = []
    data.filter(x => x.parent === parent?.key).map(x => children.push({
        key: x.key,
        cashItem: x
    }))

    if (children.length) {
        if (parent?.key === 'CA') {
            tree = children
        } else {
            parent['children'] = children
        }
        children.forEach( child => unflattenData(data, child) )
    }

    return tree

}

export const calcListItem = (item : LiveCashTree) => {

    if (item.cashItem) {
        let valDisplay = 0;
        let valDisabled = 0;

        item.cashItem.valueIsNegative = false;

        let children = item.children

        if (!children || children.length === 0) {
            if (item.cashItem.enabled) {
                valDisplay = item.cashItem.valueToDisplay + item.cashItem.valueOfDisabled
            } else {
                valDisabled = item.cashItem.valueToDisplay + item.cashItem.valueOfDisabled
            }
        } else {
            let endEnabled = 0
            let endDisabled = 0

            children.map(child => {
                calcListItem(child)
                const childDisplay = child.cashItem?.valueToDisplay
                const childDisabled = child.cashItem?.valueOfDisabled

                if (!item.cashItem?.enabled && child.cashItem)
                    child.cashItem.enabled = false

                if (item.cashItem?.enabled) {
                    valDisplay += childDisplay || 0
                    valDisabled += childDisabled || 0
                } else {
                    valDisplay = 0;
                    valDisabled += (childDisabled || 0) + (childDisplay || 0)
                }

                if ((childDisplay || 0 > 0) && child.cashItem) {
                    child.valueOffset = endEnabled
                } else if (child.cashItem) {
                    child.valueOffset = endDisabled
                }

                endEnabled += childDisplay || 0;
                endDisabled += (childDisplay || 0) + (childDisabled || 0);

            })

        }

        item.cashItem.valueToDisplay = valDisplay;
        item.cashItem.valueOfDisabled = valDisabled;
        item.valueOffset = 0;

    }      

}

export const recalcData = (data: LiveCashTree | undefined) => {

    if (!data)
        return undefined

    if (data.children) {
        let lcIn = data.children.find(x => x.key === 'AS')
        let lcOut = data.children.find(x => x.key === 'LB')
        let lcAvailable = data

        if (!lcIn || !lcOut || !lcIn.children || !lcOut.children || !lcAvailable.cashItem || !lcAvailable)
            return data;
        
        calcListItem(lcIn)
        calcListItem(lcOut)

        const balance = (lcIn?.cashItem?.valueToDisplay || 0) - (lcOut?.cashItem?.valueToDisplay || 0)

        lcAvailable.cashItem.valueIsNegative = (balance < 0)
        lcAvailable.cashItem.valueToDisplay = Math.abs(balance)
        lcAvailable.cashItem.valueOfDisabled = 0

        let topRef = (lcIn?.cashItem?.valueToDisplay || 0) > (lcOut?.cashItem?.valueToDisplay || 0) ?
            lcIn?.cashItem?.valueToDisplay : lcOut?.cashItem?.valueToDisplay

        lcAvailable.referenceValue = topRef
        lcIn.referenceValue = topRef
        lcOut.referenceValue = topRef

        lcIn.children.forEach((child) => {
            child.referenceValue = topRef
        })
        lcOut.children.forEach((child) => {
            child.referenceValue = topRef
        })

        if (balance > 0) {
            lcAvailable.valueOffset = (lcAvailable.referenceValue || 0) - balance;
        } else {
            lcAvailable.valueOffset = 0;
        }

        return data

    }   
    
}