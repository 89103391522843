import {
	SbBlokData,
	StoryblokComponent,
	storyblokEditable,
} from '@storyblok/react';
import { useContext } from 'react';
import OnboardingContext from '../../../context/OnboardingContext';
type Props = {
	blok: SbBlokData;
};
const StatusLine = ({ blok }: Props) =>  {
	const { showStatusLine } = useContext(OnboardingContext)

	return (
		<div {...storyblokEditable(blok)}>
			{blok.statuses
				? (blok.statuses as SbBlokData[]).map((blok) => showStatusLine(blok.system_key as string) && (
						<StoryblokComponent blok={blok} key={blok._uid} />
				  ))
				: null}
		</div>
	)
};

export default StatusLine;
