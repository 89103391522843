'use client';
import React, { FC, Fragment, useEffect, useState } from 'react';
import styles from './OptionSelector.module.scss';

type Option<T> = { label: string | JSX.Element; value: T; disabled?: boolean };

type OptionGroup<T> = {
	singleEntry: boolean;
	list: Option<T>[];
};

type Props<T> = {
	options: OptionGroup<T> | OptionGroup<T>[];
	onChange: (value: Option<T>[]) => void;
	defaultValues?: Option<T>[];
	preventSaveState?: boolean;
};

const OptionSelector = <T extends string | number>({ options, onChange, defaultValues, preventSaveState }: Props<T>) => {
	const [values, setValues] = useState<Option<T>[]>(defaultValues || []);
	const [sectionIndex, setSectionIndex] = useState<number | null>(null);
	let liCount = 0;

	const isOptionsArray = (options: OptionGroup<T> | OptionGroup<T>[]): options is OptionGroup<T>[] => Array.isArray(options);

	const onClick = (option: Option<T>, ulIndex: number | null = null, singleEntry: boolean) => {
		if (singleEntry) {
			const newValues = values?.some((v) => v.value === option.value) ? [] : [option]
			if(!preventSaveState) setValues(newValues);
			if(newValues.length > 0  || (!preventSaveState && newValues.length === 0 )) onChange(newValues);
		} else {
			if (values.some((v) => v.value === option.value)) {
				const newValues = Array.isArray(values) ? values.filter((v) => v.value !== option.value) : values
				if(!preventSaveState) setValues(newValues);
				setSectionIndex(null);
				if(newValues.length > 0) onChange(newValues);
				return;
			}
			const newValues = [...(sectionIndex === ulIndex ? values || [] : []), option]
			if(!preventSaveState) setValues(newValues);
			if(newValues.length > 0) onChange(newValues);
		}

		setSectionIndex(ulIndex);
	};

	useEffect(() => {
        document.addEventListener('keydown', handleKeyPress as any);
        
        return () => {
            document.removeEventListener('keydown', handleKeyPress as any);
        };
    }, [options, defaultValues]);

	const handleKeyPress = (e: any) => {
		blurActiveElement();
        const key = e.key.toLowerCase();
        const optionsArray = isOptionsArray(options) ? options : [options];
        
        let currentIndex = 0;
        for (const optionGroup of optionsArray) {
            for (const option of optionGroup.list) {
                if (String.fromCharCode(97 + currentIndex) === key && !option.disabled) {
                    onClick(option, isOptionsArray(options) ? optionsArray.indexOf(optionGroup) : null, optionGroup.singleEntry);
                    return;
                }
                currentIndex++;
            }
        }
    };

	const blurActiveElement = () => {
		if (document.activeElement instanceof HTMLElement) {
		  document.activeElement.blur();
		}
	};

	const isActive = (item: Option<T>) => values.some((v) => v.value === item.value);

	const renderOptions = (options: OptionGroup<T>, ulIndex: number | null = null) =>
		options.list.map((item) => (
			<li className={item.disabled ? styles.disabled : ''} key={item.value} onClick={() => item.disabled ? null : onClick(item, ulIndex, options.singleEntry)}>
				<span className={isActive(item) ? styles.active : ''}>{String.fromCharCode(97 + liCount++)}</span>
				{item.label}
			</li>
		));

	// useEffect(() => {
	// 	//TODO: Make user click override this check so they can select the same thing to advance
	// 	if(values.length > 0 && !_.isEqual(values, defaultValues) ) {
	// 		onChange(values);
	// 	}
	// }, [defaultValues, onChange, values]);

	return (
		<div className={styles.optionSelector}>
			{isOptionsArray(options) ? (
				options.filter(x => x.list.length > 0).map((ul, i, arr) => (
					<Fragment key={i}>
						<ul>{renderOptions(ul, i)}</ul>
						{i < arr.length - 1 && <div className={styles.or}>OR</div>}
					</Fragment>
				))
			) : (
				<ul>{renderOptions(options)}</ul>
			)}
		</div>
	);
};

export default OptionSelector;
