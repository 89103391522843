import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Invoice } from '../../../api/inni/data-contracts';
import { Tenancies } from '../../../api/inni/Tenancies';
import CompanyContext from '../../../context/CompanyContext';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { InvoiceTable } from '../../Invoices/Components/InvoiceTable';



export const TenancyInvoices = ({id}: {id: number}) => {

    const companyContext = useContext(CompanyContext);
    const tenancyApi = useInniAPI(Tenancies);
    const [invoices, setInvoices] = useState<Array<Invoice>>([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (tenancyApi && !loaded && !loading) {
            setLoading(true);
            tenancyApi.listInvoices(companyContext.cid, id).then(response => {
                setInvoices(response.data);
                setLoaded(true);
            })
        }
    }, [tenancyApi, loaded, loading, companyContext.cid, id ])


  
    return (
        <>
            <Container style={{marginLeft:0}}>
                <Row className='mb-2'>
                    <Col>
                        <InvoiceTable loaded={loaded} invoices={invoices} />
                    </Col>
                </Row>
            </Container>

        </>
    )

}