import { useState } from 'react';
import { Button, ButtonVariant } from '../../../elements/Button/Button';
import { OverlayTrigger, Popover, Table } from 'react-bootstrap';
import { Invoice, InvoiceContracts, Market, MarketTerms } from '../../../api/inni/data-contracts';
import { Action, Entity } from '../../../utils/EntityAction';
import { InvoicePageButtons } from '../HelperFunctions/ShowField';
import { useParams } from 'react-router-dom';
import styles from '../Invoice.module.css';
import { formatCurrency } from '../../../utils/formatNumbers';
import classNames from 'classnames';
import { BrandWrapper } from '../../../components/BrandWrapper/BrandWrapper';
type ButtonPopUpProps = {
	getInvoiceData: Invoice[] | undefined;
	variants?: ButtonVariant | undefined;
	doctype: string;
	contracts: InvoiceContracts[] | undefined;
	markets: Market | undefined;
	marketTerm: MarketTerms | undefined;
};

type TableProps = {
	item: InvoiceContracts;
	doctype: string;
	markets: Market | undefined;
};

type RouteParams = {
	year: string;
};

const ButtonPopUp = ({ variants, doctype, contracts, markets, marketTerm }: ButtonPopUpProps) => {
	const [showAllMerchants, setAllMerchants] = useState<boolean>(false);
	return (
		<>
			<OverlayTrigger
				trigger="click"
				rootClose
				key="bottom"
				placement="bottom-start"
				overlay={
					<Popover id={`popover-positioned-bottom`}
						data-cy="button-popover" style={{ maxWidth: '100%' }} className="shadow">
						<BrandWrapper>
							<Table
								style={{
									padding: '2px',
									borderRadius: '2px',
									overflow: 'hidden',
									marginBottom: '0px',
								}}
								borderless={false}>
								<thead>
									<tr style={{ border: 0 }}>
										<th style={{ border: 0 }}>{marketTerm && marketTerm.newIQCTitleTerm}</th>
										{doctype !== 'Quote' && doctype !== 'InvoiceTemplate' && markets?.showTimeElements && (
											<th style={{ border: 0 }}>Time</th>
										)}
										{doctype !== 'Quote' && doctype !== 'InvoiceTemplate' && <th style={{ border: 0 }}>Expenses</th>}
										{doctype !== 'Quote' && doctype !== 'InvoiceTemplate' && !markets?.isProperty && (
											<th style={{ border: 0 }}>Miles</th>
										)}
									</tr>
								</thead>
								{showAllMerchants && (
									<tbody>
										<tr>
											<td colSpan={5} className="text-center">
												<b>{contracts && contracts.filter((x) => !x.isActive).length}</b> &nbsp; Inactive {marketTerm?.contractsTerm?.toLocaleLowerCase()}&nbsp;
												<Button onClick={() => setAllMerchants(!showAllMerchants)} small variant='secondary'>{showAllMerchants ? 'Hide' : 'Show'}</Button>
											</td>
										</tr>
									</tbody>
								)}
								<tbody className={styles.InvoicePopup}>
									{contracts &&
										contracts
											.filter((x) => x.isActive || showAllMerchants)
											.map((item) => <TableColumn markets={markets} key={item.contractId} item={item} doctype={doctype}></TableColumn>)}
								</tbody>
								{!showAllMerchants && (
									<tbody className={styles.InvoicePopup}>
										<tr>
											<td colSpan={5} className="text-center">
												{contracts && contracts.filter((x) => !x.isActive).length} Inactive {marketTerm?.contractsTerm?.toLocaleLowerCase()}&nbsp;
												<Button onClick={() => setAllMerchants(!showAllMerchants)} small variant='secondary'>{showAllMerchants ? 'Hide' : 'Show'}</Button>
											</td>
										</tr>
									</tbody>
								)}
								<tbody className={classNames(styles.InvoicePopup, 'table-active')}>
									<tr>
										<td>{'Free-type ' + InvoicePageButtons[doctype as keyof typeof InvoicePageButtons]}</td>
										{doctype !== 'Quote' && doctype !== 'InvoiceTemplate' && markets?.showTimeElements ? (
											<>
												<td></td>
												<td></td>
												<td></td>
											</>
										) : (
											doctype !== 'Quote' && doctype !== 'InvoiceTemplate' && <td></td>
										)}
										<td>
											<Button
												variant="primary"
												entity={Entity.InvoiceV7}
												action={Action.Create}
												extraParams={{
													doctype: doctype,
												}}
												tableBtn={true}>
												Select
											</Button>
										</td>
									</tr>
								</tbody>
							</Table>
						</BrandWrapper>
					</Popover>
				}>
				<Button
					buttonType="new"
					variant={variants ? variants : 'light'}
					label={'New ' + InvoicePageButtons[doctype as keyof typeof InvoicePageButtons]}></Button>
			</OverlayTrigger>
		</>
	);
};

const TableColumn = ({ item, doctype, markets }: TableProps) => {
	return (
		<>
			<tr>
				<td>
					{' '}
					{item.clientname}
					<br></br>
					{item.contractName}
				</td>
				{doctype !== 'Quote' && doctype !== 'InvoiceTemplate' && markets?.showTimeElements && (
					<td>{item.unbilledTime < 1 ? '' : item.unbilledTime + ' ' + item.rateType}</td>
				)}
				{doctype !== 'Quote' && doctype !== 'InvoiceTemplate' && (
					<td>{item.unbilledExpense === 0 ? '' : formatCurrency(item.unbilledExpense)}</td>
				)}
				{doctype !== 'Quote' && doctype !== 'InvoiceTemplate' && !markets?.isProperty && (
					<td>{item.unbilledMileage === 0 ? '' : item.unbilledMileage}</td>
				)}
				<td>
					<Button
						entity={Entity.InvoiceV7}
						action={Action.Create}
						tableBtn={true}
						variant="primary"
						extraParams={{
							id: item.contractId,
							doctype: doctype,
							year: useParams<RouteParams>().year,
						}}>
						Select
					</Button>
				</td>
			</tr>
		</>
	);
};

export default ButtonPopUp;
