/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { RegularPayment, RegularPaymentPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class RegularPayments<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags RegularPayments
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/regularpayments
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<RegularPayment[], any>({
      path: `/api/v2/companies/${companyId}/regularpayments`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RegularPayments
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/regularpayments
   * @secure
   */
  update = (
    companyId: number,
    query: { recPaymentId: number },
    model: RegularPaymentPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/regularpayments`,
      method: "PUT",
      query: query,
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags RegularPayments
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/regularpayments
   * @secure
   */
  create = (companyId: number, model: RegularPaymentPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/regularpayments`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags RegularPayments
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/regularpayments
   * @secure
   */
  delete = (companyId: number, query: { recPaymentId: number }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/regularpayments`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags RegularPayments
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/regularpayments/ValidateCreate
   * @secure
   */
  validateCreate = (companyId: number, model: RegularPaymentPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/regularpayments/ValidateCreate`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags RegularPayments
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/regularpayments/ValidateUpdate
   * @secure
   */
  validateUpdate = (
    companyId: number,
    query: { recPaymentId: number },
    model: RegularPaymentPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/regularpayments/ValidateUpdate`,
      method: "PUT",
      query: query,
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags RegularPayments
   * @name Archive
   * @request POST:/api/v2/companies/{companyId}/regularpayments/Archive
   * @secure
   */
  archive = (companyId: number, query: { recPaymentId: number }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/regularpayments/Archive`,
      method: "POST",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags RegularPayments
   * @name UnArchive
   * @request POST:/api/v2/companies/{companyId}/regularpayments/UnArchive
   * @secure
   */
  unArchive = (companyId: number, query: { recPaymentId: number }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/regularpayments/UnArchive`,
      method: "POST",
      query: query,
      secure: true,
      ...params,
    });
}
