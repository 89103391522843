import { EntityRoute, RouteContext } from "./routeTypes";
import SelectPage from "../../features/Bank/BankSelection/BankSelectionPage";

const bankSelection:Array<EntityRoute> = [
    {
        path: '/company/:cid/bankselection',
        exact: true,
        context: RouteContext.Company,
        component:SelectPage
    }
]

export default bankSelection;