import classNames from "classnames"
import { ReactNode, useState} from "react"
import { DropdownButton as BootstrapDropdownBtn, OverlayTrigger, Tooltip } from "react-bootstrap"
import { ButtonVariant } from "react-bootstrap/esm/types"
import { useHasPermission } from "../../hooks/useHasPermission"
import { Action, Entity } from "../../utils/EntityAction"
import styles from "./DropdownButton.module.scss"
import { faWarning, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DropdownButtonProps {
    title: ReactNode | string, 
    children : any, 
    variant?: ButtonVariant, 
    entity?:Entity, 
    action?:Action, 
    disabled?: boolean, 
    disabledMessage?: string,
    icon?: 'attachment' | 'warning'
}

const DropdownButton = ({title, children, variant, entity, action, disabled, disabledMessage, icon} : DropdownButtonProps) => {

    const [showTooltip, setShowTooltip] = useState(false);

    const checkPermission = useHasPermission()

    const BtnElement = (
        <BootstrapDropdownBtn 
            disabled={disabled} 
            className={classNames(
                styles.dropdownBtn, 
                variant ? styles[variant] : '', 
                { [styles.adminOnly] : entity && action && checkPermission(entity, action)[1] }
            )} 
            title={icon
                ? (
                    <>
                        { icon === 'attachment' && <><FontAwesomeIcon icon={faPaperclip} />{ title }</> }
                        { icon === 'warning' && <><FontAwesomeIcon icon={faWarning} />{ title }</> }
                    </>
                )
                : title
            }
        >
            {children}
        </BootstrapDropdownBtn>
    )

    const tooltip = <Tooltip id='disabled-tip'>{disabledMessage}</Tooltip>

    const hasPermission = () => {
        if (!entity) return true;   
        const p = checkPermission(entity, action || Action.List);
        return p[0];
    }


    const handleTrigger = (show:boolean) => {
        if (disabled && disabledMessage)
            setShowTooltip(show);
    }

    if (hasPermission()) {
        if (disabledMessage) {
            return (
                <OverlayTrigger show={showTooltip} onToggle={(show) => handleTrigger(show)} overlay={tooltip}>
                    <span className="d-inline-block">
                        { BtnElement }
                    </span>
                </OverlayTrigger>
            )
        } else {
            return BtnElement
        }
    } else {
        return null;
    }
}

export default DropdownButton