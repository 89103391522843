import numbro from 'numbro';

// http://numbrojs.com/format.html

export function formatCurrency(value: number | undefined, hidePound?: boolean, removeSign?: boolean):string {
    const initValue = removeSign ? Math.abs(value || 0) : value || 0;

    const numberFormatted = numbro(initValue).format(
        {
            thousandSeparated: true,
            mantissa: 2
        }
    )
    
    return hidePound ? numberFormatted : ("£" + numberFormatted)
}

export function formatDecimal(value: number | undefined):string {
    return numbro(value || 0).format(
        {
            thousandSeparated: true,
            mantissa: 2
        }
    )
}

export function formatCurrency0dp(value: number | undefined):string {
    return "£" + numbro(value || 0).format(
        {
            thousandSeparated: true,
            mantissa: 0
        }
    )
}

export function formatCurrencyAbbreviated(value: number | undefined):string {
    return "£" + numbro(value || 0).format(
        {
            thousandSeparated: true,
            spaceSeparated: false,
            average: true,
            mantissa: 2,
            optionalMantissa: true
        }
    )
}

export function formatPercent(value: number | undefined):string {
    return numbro(value || 0).format(
        {
            output: "percent",
            mantissa: 1
        }
    )
}

export function formatPercentWithoutSymbol(value: number | undefined | string): string {
    if(value === undefined || value === '')
        return ''
    
    const valueAsString = value.toString();

    if(valueAsString.indexOf('.') === -1)
        return valueAsString + '.0'

    const decimals = valueAsString?.split('.')[1]
    if (decimals.length === 0)
        return valueAsString + '0';

    return valueAsString
}