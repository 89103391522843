import { useState, useEffect, useContext } from "react";
import {  Modal as ReactModal} from "react-bootstrap";
import { Property, AssetAsDetailed, AssetChangetoExpense} from "../../api/inni/data-contracts";
import { Button } from "../../elements/Button/Button";
import { useInniAPI } from "../../hooks/useInniAPI";
import CompanyContext from "../../context/CompanyContext";
import { Properties } from "../../api/inni/Properties";
import { formatDate } from "../../utils/formatDate";
import { formatCurrency, formatPercent } from "../../utils/formatNumbers";
import { useModalDialog } from "../../hooks/useModalDialog";
import { Action, Entity } from "../../utils/EntityAction";
import { useHasPermission } from "../../hooks/useHasPermission";
import { BrandWrapper } from "../../components/BrandWrapper/BrandWrapper";
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder/LoadingPlaceholder";
import * as DataGrid from "../../elements/DataGrid/DataGrid";
import styles from "./Asset.module.css";
import { AssetPaymentChangeToExpenseModal } from "./AssetPaymentChangeToExpense";

interface AssetModalProps {
  hide: () => void;
  edit: (values: number) => void;
  deleteAsset: (values: number) => void;
  changeToExpense: (values: AssetChangetoExpense) => void;
  disposeAssetAtZero: (values: number) => void;
  isProperty?: boolean;
  data: AssetAsDetailed;
}

export const AssetReadModal = ({ hide, deleteAsset, changeToExpense, data, isProperty, edit,disposeAssetAtZero}: AssetModalProps) => {
  const hasPermission = useHasPermission();
  const companyContext = useContext(CompanyContext);
  const propertyAPI = useInniAPI(Properties);
  const [showModalDialog, modalDialog] = useModalDialog();  
  const [property, setProperties] = useState<Property>();
  const {editability}=data;
  const [showAssetPaymentChange, setAssetPaymentChangeModal] = useState(false);

  const showDeleteAssetDialog = () => {
    showModalDialog(
      'Delete asset?',
      `Are you sure you want to delete this asset?`,
      [
        <Button
          variant="danger"
          label="Yes"
          onClick={() => deleteAsset(data.id)}
        />,
        <Button variant="primary" outline label="No" onClick={() => {}} />,
      ],
      false
    );
  };
  const showDisposeAssetAtZeroDialog = () => {
    showModalDialog(
      'Dispose of the asset at zero?',
      <p>Confim that you have not received any money for the asset, including insurance.<br />
      if you did, record the bank payment as the asset disposal.<br /><br />
      Use this only if you've lost it, had it stolen, or it's broken and you've thrown it away.
      </p>,
      [
        <Button
          variant="danger"
          label="Yes"
          onClick={() => disposeAssetAtZero(data.id)}
        />,
        <Button variant="primary" outline label="No" onClick={() => {}} />,
      ],
      false
    );
  };
  useEffect(() => {
    if (isProperty && companyContext.cid && data.propertyId) {
      propertyAPI
        ?.get(companyContext.cid, data.propertyId)
        .then((response: { data: Property }) => setProperties(response.data));
    }
  }, [propertyAPI, companyContext.cid, isProperty,data.propertyId]);

 


 const checkChnageAssetToExpense=(accountId:any)=>{
    if(accountId){
        const temp:AssetChangetoExpense={
          accountId:accountId
        }
        changeToExpense(temp)
        setAssetPaymentChangeModal(false)       
    } 
 };

 const showAssetPaymentChangeModal=()=>{
      setAssetPaymentChangeModal(true)      
 }

const hasAdminPermission = hasPermission(Entity.AssetAdmin, Action.All)[0]
const isAdminDecorated = hasPermission(Entity.AssetAdmin, Action.All)[1]

  return (
    <ReactModal size="xl" show={true} onHide={hide} centered>
      {/* Header */}
      <BrandWrapper>
        <ReactModal.Header closeButton>
          <ReactModal.Title id="inni-modal-title">Asset</ReactModal.Title>
        </ReactModal.Header>

        <ReactModal.Body>
          {modalDialog}
          <DataGrid.Table noHover>
              <thead>
                  <tr>
                    <th>Date purchased</th>
                    <th>1st year</th>
                    <th>Description</th>
                    <th>Category</th>
                    <th>Original value</th>
                    <th>Disposal date</th>
                    {data.propertyId && <th>Property name</th>}
                  </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{formatDate(data.datePurchased)}</td>
                  <td>{data.isFirstYear ? "Yes" : "No"}</td>
                  <td>{data.description}</td>
                  <td>{data.assetCategoryName}</td>
                  <td>{formatCurrency(data.originalValue)}</td>
                  <td>{formatDate(data.disposalDate)}</td>
                  {data.propertyId && <td>{property?.name ? property?.name : <LoadingPlaceholder />}</td>}
                </tr>
              </tbody>
          </DataGrid.Table> 
          <div className={styles.assetRead}>         
              <DataGrid.Table noHover>
                  <thead>
                      <tr>
                        <th>Method (book)</th>                    
                        <th>Rate (year 1)</th>
                        <th>Rate (year 2+)</th>
                        <th>Current value</th>
                        <th>Value at end of FY</th>                  
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data.methodAccounts === "R" ? "Reducing" : "Straight Line"}</td>                  
                      <td>{formatPercent(data.rateFirstYearAccountsPct?(data.rateFirstYearAccountsPct)/100:data.rateFirstYearAccountsPct)}</td>
                      <td>{formatPercent(data.rateSubsequentAccountsPct?(data.rateSubsequentAccountsPct)/100:data.rateSubsequentAccountsPct)}</td>
                      <td>{formatCurrency(data.currentValue)}</td>
                      <td>{formatCurrency(data.forecastValueAccounts)}</td>                  
                    </tr>
                  </tbody>
              </DataGrid.Table>
          
              <DataGrid.Table noHover>
                  <thead>
                      <tr>
                        <th>Method (CA)</th>                    
                        <th>Rate (year 1)</th>
                        <th>Rate (year 2+)</th>
                        <th>Current value</th>
                        <th>Value at end of FY</th>                  
                      </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{data.methodTax === "R" ? "Reducing" : "Straight Line"}</td>                  
                      <td>{formatPercent(data.rateFirstYearTaxPct?(data.rateFirstYearTaxPct)/100:data.rateFirstYearTaxPct)}</td>
                      <td>{formatPercent(data.rateSubsequentTaxPct?(data.rateSubsequentTaxPct)/100:data.rateSubsequentTaxPct)}</td>
                      <td>{formatCurrency(data.currentValueTax)}</td>
                      <td>{formatCurrency(data.forecastValueTax)}</td>                  
                    </tr>
                  </tbody>
              </DataGrid.Table>
          </div>          
        </ReactModal.Body>

        {/* Footer */}
        <ReactModal.Footer>
          <div style={{ textAlign: "left", width: "100%" }}>
            <Button
              variant="primary"
              buttonType="done"
              onClick={hide}
              label="Done"
            />

              <Button
                variant="primary"
                buttonType="edit"
                onClick={() => edit(data.id)}
                label="Edit"
                disabled={!editability?.canEdit}
                disabledMessage={editability?.cantEditReason}
                entity={Entity.Asset}
                action={Action.Edit}
              />

            {hasAdminPermission && (data.editability?.canChangeToExpense || data.editability?.cantChangeToExpenseReason) && (
              
                <Button 
                  className={styles.buttondisabled}                 
                  admin={isAdminDecorated}
                  variant="secondary"
                  disabled={!editability?.canChangeToExpense}
                  disabledMessage={editability?.cantChangeToExpenseReason}
                  onClick={showAssetPaymentChangeModal}
                  label="Change to expense"
                ></Button> 
            )}

              <Button 
                className={styles.buttondisabled}                 
                buttonType="delete"
                onClick={showDeleteAssetDialog}
                label="Delete"
                disabled={!editability?.canDelete}
                disabledMessage={editability?.cantDeleteReason}
                entity={Entity.Asset}
                action={Action.Delete}
              />

            {hasAdminPermission && (!data.isDisposed) &&(
              <Button                                 
                admin={isAdminDecorated}
                variant="secondary"               
                onClick={showDisposeAssetAtZeroDialog}
                label="Dispose at zero"
              ></Button> 
            )}  
          </div>
        </ReactModal.Footer>
        {showAssetPaymentChange && 
         <AssetPaymentChangeToExpenseModal  
            hide ={()=> setAssetPaymentChangeModal(false)} 
            AssetToExpense= {checkChnageAssetToExpense}        
          />

        }
      </BrandWrapper>
    </ReactModal>
  );
};
