import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import CreateShareholdings from "../../features/People/Components/Shareholdings/CreateShareholdings";

const shareholdings:Array<EntityRoute> = [
    {
        path: '/company/:cid/shareholdings/new',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Shareholdings,
        action: Action.CreateWizard,
        component:CreateShareholdings
    }
]

export default shareholdings;