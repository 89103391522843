const getCurrencySymbol = (code:string) => {
    //https://www.toptal.com/designers/htmlarrows/currency/
    //https://stackoverflow.com/questions/4670985/html-and-character-encoding-vs-html-entity
    const codeToSymbols : {[key: string]: any} = {
        "GBP": <>&#163;</>,
        "USD": <>&#36;</>,
        "EUR": <>&#8364;</>
    }
    return codeToSymbols[code] || ""
}

export default getCurrencySymbol;