import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import NoCompanies from "../../features/UserPortal/NoCompanies";

export const nocompany:Array<EntityRoute> = [
    {
        path: '/user/nocompany',
        exact: true,
        context: RouteContext.User,
        entity: Entity.NoCompany,
        component: NoCompanies
    }
]

export const hub:Array<EntityRoute> = [
    {
        path: '/user',
        exact: true,
        context: RouteContext.User,
        entity: Entity.NoCompany,
        component: NoCompanies
    }
]
