import { Card } from "react-bootstrap";
import styles from "./LoadingBankCard.module.scss"
import { LoadingPlaceholder } from "../LoadingPlaceholder/LoadingPlaceholder";
import classNames from "classnames";

interface LoadingNewBankProp{
    isRecommended?:boolean;
}

export function LoadingOpenBankCard(){
    return(
        <Card className={styles.openBankingLayout} >
            <Card.Body className={styles.openBankingBodyWrapper}>
                <div className={styles.openBankingImageWrapper}/>
                <LoadingPlaceholder width="100px"/>
                <LoadingPlaceholder width="47px"/>
            </Card.Body>
        </Card>
    );
}

export function LoadingNewBankCard({isRecommended}:LoadingNewBankProp){
    return(
        <div style={{width:"100%"}}>

            {isRecommended && 
                <p className={`${styles.pBold} ${styles.bankRecommended}`}>Provestor Recommends</p> 
            }

            <Card className={isRecommended ? `${styles.loadingBankAccountCard} ${styles.loadingRecommended}` : styles.loadingBankAccountCard}>
                <div className={styles.LoadingCardContent}>
                    <Card.Header className={styles.loadingCardHeader}>
                        <div className="d-flex align-items-center" style={{width:"100%"}}>
                            <div className={classNames(styles.imageWrapper, styles.loading)}/>
                            <div style={{width:"50%"}}>
                                <LoadingPlaceholder height="2em" width="50%"/>
                                <LoadingPlaceholder />
                            </div>
                        </div>
                        <div className={classNames(styles.loadingButton, styles.loading)}/>
                    </Card.Header>

                    <Card.Body className={styles.loadingCardBody}>
						<div>
							<small className={styles.pSmall}>Interest</small>
							<LoadingPlaceholder dark/>
						</div>
						<div>
							<small className={styles.pSmall}>Monthly fee</small>
							<LoadingPlaceholder dark/>
						</div>
						<div>
							<small className={styles.pSmall}>Overdraft</small>
							<LoadingPlaceholder dark/>
						</div>
					</Card.Body>

                    <Card.Footer className={styles.loadingCardFooter}>
                        <ul>
                            <small className={styles.pSmall}>What we like</small>
                            <>
                                {Array(4)
                                        .fill(0)
                                        .map((_, index) => (
                                            <li key={index}>
                                                <LoadingPlaceholder />
                                            </li>
                                ))}
                            </> 
                        </ul>

                        <ul>
                            <small className={styles.pSmall}>Things to check</small>
                            <>
                                {Array(4)
                                        .fill(0)
                                        .map((_, index) => (
                                            <li key={index}>
                                                <LoadingPlaceholder/>
                                            </li>
                                        ))}
                            </>
                        </ul>
                    </Card.Footer>
                </div>
            </Card>
        </div>

    );
}

