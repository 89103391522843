import classNames from 'classnames'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Categories } from '../../api/inni/Categories'
import { Asset, Category, ImportedTransactionPotentialMatch } from '../../api/inni/data-contracts'
import styles from './CategorySelector.module.scss'
import SearchInput from './Components/SearchInput'
import { useInniAPI } from '../../hooks/useInniAPI'
import CompanyContext from '../../context/CompanyContext'
import { Button } from '../../elements/Button/Button'
import { SpecialCategoryFields } from '../../features/Bookkeeping2022/RecordCreator'
import { StoryblokComponent, SbBlokData, getStoryblokApi } from '@storyblok/react'
import { breakStrByCase } from '../../utils/formatters/formatStrings'
import { formatDate } from '../../utils/formatDate'
import { formatCurrency } from '../../utils/formatNumbers'
import React from 'react'
import { getAutocompleteSuggestion } from './categorySelectorUtilsAndFormatters'
import _ from 'lodash';
import { useLogEventToAppInsights } from '../../hooks/useLogEventToAppInsights'

const MIN_SEARCH_LENGTH = 2

interface iSBCategory extends SbBlokData {
    key: string,
    value: string
}

interface CategorySelectorProps {
    outgoing: boolean,
    setSelectedAccount: (
        selected: number,
        category: Category | undefined) => void,
    setSpecialCategoryFields: (fields: SpecialCategoryFields) => void,
    selectedAccountId?: number,
    isSplitting: boolean,
    categories?: Category[] | undefined,
    selectedBPType?: string,
    bankAccountId: number,
    setFormDefocused?: (focus: boolean) => void,
    potentialMatches?: ImportedTransactionPotentialMatch[]
    addToSplit: (match:ImportedTransactionPotentialMatch) => void,
    currentAccIsDLA: boolean,
    currentAccIsMortgage: boolean,
    assets: Asset[],
    showBuildCategory?: boolean,
    showAllCategories?: boolean,
    showDLA?: boolean,
    showMortgageAccounts?: boolean
}

interface CategoryWithOverride extends Category {
    overrideDesc?: string
}

const CategorySelectorSearch = ({
        outgoing,
        setSelectedAccount,
        setSpecialCategoryFields,
        potentialMatches,
        addToSplit,
        selectedBPType,
        assets,
        selectedAccountId,
        bankAccountId,
        isSplitting,
        categories,
        setFormDefocused,
        currentAccIsDLA,
        currentAccIsMortgage,
        showBuildCategory,
        showAllCategories=false,
        showDLA=true,
        showMortgageAccounts=true
    }: CategorySelectorProps) => {  

    const hasSellableAssets = assets.filter(x => x.canDispose).length > 0

    // Apis
    const categoriesApi = useInniAPI(Categories)
    const [workingCategories, setWorkingCategories] = useState<CategoryWithOverride[]>([])
    const companyContext = useContext(CompanyContext)

    // Hooks
    const logEventToAppInsights = useLogEventToAppInsights();

    // State
    const [search, setSearch] = useState('')
    const [searchFound, setSearchFound] = useState(false)
    const [knownTags, setKnownTags] = useState<{[key: string]: string[]}>({})
    const [focused, setFocused] = useState(false)
    const [expandClicked, setExpandClicked] = useState(false)
    const [descriptions, setDescriptions] = useState<{[key: string]: iSBCategory} | undefined>();
    const [searchResults, setSearchResults] = useState<CategoryWithOverride[]>([])

    // Create descriptions object from storyblok data on load
    useEffect(() => {
        if(descriptions === undefined) {
            const storyblokApi = getStoryblokApi();			
            storyblokApi.get('cdn/stories/bookkeeping/category-list', {version: 'published'}).then((res) => {
                const buildDescriptions:{[key: string]: iSBCategory} = {};
                const sbDescriptions = res.data.story
                if(sbDescriptions) {
                    sbDescriptions.content.Body.forEach((entry: iSBCategory) => {
                        buildDescriptions[entry.key] = entry
                    });
                    setDescriptions(buildDescriptions)
                }
            });
        }
    }, [descriptions])

    // Set filtered (working) categories
    useEffect(() => {
        if (categories) {
            const bucketLinkedIds = ["LettingAgentAcc", "SolicitorAcc"]
            let newWorkingCategories:Category[] = []
            if(showAllCategories){
                newWorkingCategories = categories.filter(x => x.accountId !== bankAccountId)
            }
            else{
                if (outgoing) {
                    if (isSplitting) {
                        newWorkingCategories = categories.filter(x => (x.type !== 'income' && x.accountId !== bankAccountId && 
                            !bucketLinkedIds.includes(x.linkedID || '')))
                    } else {
                        newWorkingCategories = categories.filter(x => (x.type !== 'refund' && x.type !== 'income' && x.accountId !== bankAccountId))
                    }
                } else {
                    if(isSplitting) {
                        newWorkingCategories = categories.filter(x => (x.type !== 'expense' && x.accountId !== bankAccountId &&
                            !bucketLinkedIds.includes(x.linkedID || '')))
                    } else {
                        newWorkingCategories = categories.filter(x => (x.type !== 'expense' && x.accountId !== bankAccountId))
                    }
                }
    
            }
            
            if(showDLA) {
                newWorkingCategories = newWorkingCategories.filter(x => !x.name?.toLowerCase().includes("director loan account"))
                newWorkingCategories.push({
                    accountId: -1, //DLA - ID is only important thing here as that's picked up later
                    name: "Director loan account",
                    isEligibleForAssets: false,
                    showPaymentCoversDateRange: 'No',
                    showIsReplacementItem: 'No',
                    showIncreasesValue: 'No',
                    showIsBusinessExpense: 'No',
                    showContract: 'No',
                    showIsBillable: 'No'
                })
            }

            if(showMortgageAccounts) {
                newWorkingCategories = newWorkingCategories.filter(x => !x.name?.toLowerCase().includes("mortgage account"))
                newWorkingCategories.push({
                    accountId: -2, //Mortgage account - ID is only important thing here as that's picked up later
                    name: "Mortgage account",
                    isEligibleForAssets: false,
                    showPaymentCoversDateRange: 'No',
                    showIsReplacementItem: 'No',
                    showIncreasesValue: 'No',
                    showIsBusinessExpense: 'No',
                    showContract: 'No',
                    showIsBillable: 'No'
                })
            }

            newWorkingCategories.sort((a,b) => {
                if (a.type === 'refund' && b.type !== 'refund') {
                    return 1;
                } 
                else if (a.type !== 'refund' && b.type === 'refund') {
                    return -1;
                } 
                else {
                    return (a.name ? a.name.toLowerCase() : "").localeCompare(b.name ? b.name.toLowerCase() : "");
                }
            })

            setWorkingCategories(newWorkingCategories)
            setSearchResults(newWorkingCategories)
            setSearch("")
            setSearchFound(false)
        }
    }, [categories, isSplitting, outgoing, bankAccountId, showDLA, showMortgageAccounts])

    const debounceTagLookup = useCallback(_.debounce(async (newSearch:string) => {
        categoriesApi && categoriesApi.getCategoryByTag(companyContext.cid, { tag: newSearch })
        .then(res => {
            const newKnownTags = {...knownTags, [newSearch]: res.data}
            setKnownTags(newKnownTags)

            const results = workingCategories.filter(x => (
                (x.name?.toLowerCase().includes(newSearch.toLowerCase()) || 
                (x.name && newKnownTags[newSearch.toLocaleLowerCase()]?.includes(x.name)))||
                (newSearch.toLocaleLowerCase() === "transfer" && x.type === "transfer")
            ))
            setSearchFound(results.length > 0)
            setSearchResults(results)
            logEventToAppInsights('Bookkeeping.Matching.CategorySelectorSearch', {SearchText: newSearch}, {NumberOfMatchingCategories: results.length})
        })
    }, 500), [categoriesApi, companyContext.cid, knownTags, workingCategories])

    const updateSearch = (search: string) => {
        setSearch(search.toLocaleLowerCase());
        
        if (!knownTags.hasOwnProperty(search) && categoriesApi && search.trim() !== '' && search.length > MIN_SEARCH_LENGTH) {
            debounceTagLookup(search)
        } 
        else {
            debounceTagLookup.cancel()
            const results = workingCategories.filter(x => (
                (x.name?.toLowerCase().includes(search.toLowerCase()) || 
                (x.name && knownTags[search.toLocaleLowerCase()]?.includes(x.name))) ||
                (search.toLocaleLowerCase() === "transfer" && x.type === "transfer")
            ))
    
            setSearchFound(results.length > 0)
            setSearchResults(results)
            logEventToAppInsights('Bookkeeping.Matching.CategorySelectorSearch', {SearchText: search}, {NumberOfMatchingCategories: results.length})
        }
    }

    // Update parent focus
    useEffect(() => {
        if (setFormDefocused) {
            if (expandClicked || focused) {
                setFormDefocused(false)
            } else {
                setFormDefocused(true)
            }
        }
    }, [expandClicked, focused, setFormDefocused])

    useEffect(() => {
        if(selectedAccountId && workingCategories) {
            const match = workingCategories.find(x => 
                currentAccIsDLA ? x.accountId === -1 : 
                currentAccIsMortgage ? x.accountId === -2 : 
                (x.accountId === selectedAccountId && x.bpProcessorType === selectedBPType))
            if(match) {
                setSearch(match.name || "")
                setSearchFound(true)
                setSearchResults([match])
            }
        }
    }, [currentAccIsDLA, currentAccIsMortgage, selectedAccountId, workingCategories, selectedBPType])

    //TODO: make refund categories clearer
    const selectCategory = (category:Category) => {
        if (typeof(category.accountId) === 'number') {
            setFocused(false)
            
            setExpandClicked(false)

            setSpecialCategoryFields({
                showIsAsset: category.isEligibleForAssets || category.linkedID === "OtherGoods",
                showIsOtherGoodsAsset: category.linkedID === "OtherGoods",
                showPaymentCoversDateRange: category.showPaymentCoversDateRange === "Yes", 
                showIncreasesValue: category.showIncreasesValue === "Yes", 
                showIsReplacementItem: category.showIsReplacementItem === "Yes",
                showIsBusinessExpense: category.showIsBusinessExpense === "Yes",
                attachmentRequiredMessage: category.attachmentRequiredMessage,
                showContract: category.showContract === "Yes",
                showIsBillable: category.showIsBillable === "Yes"
            })

            setSelectedAccount(
                category.accountId,
                category
            )

            setSearch('')
        } else {
            console.error(`Invalid id ${category.accountId}`)
        }
    }

    const selectExistingTxn = (pm: ImportedTransactionPotentialMatch) => {
        setFocused(false)
        setExpandClicked(false)
        setSearch('')

        setSpecialCategoryFields({
            showIsAsset: false,
            showIsOtherGoodsAsset: false,
            showPaymentCoversDateRange: false, 
            showIncreasesValue: false, 
            showIsReplacementItem: false,
            showIsBusinessExpense: false,
            attachmentRequiredMessage: undefined,
            showContract: false,
            showIsBillable: false
        })

        addToSplit(pm)
    }

    const autoComplete = (name:string) => {
        updateSearch(name)
    }

    const selectMatchBySearch = () => {
        if(searchResults && search) {
            const exactMatch = searchResults.find(x => x.name?.toLowerCase() === search.toLowerCase())
            if(exactMatch) {
                selectCategory(exactMatch)
            }
            else if(search.length >= 3) {
                selectCategory(searchResults[0])
            }
        }
    }

    // Get selected storyblok description
    const getDescription = (category: Category) => {
        if(!hasSellableAssets && category.linkedID === "AssetSales") {
            return <span style={{color: "var(--danger)"}}><b>Sorry, there are currently no assets available to sell</b></span>
        }
        else if (descriptions && workingCategories) {
            const entry = category.type === "transfer" ? "Transfer" : category.name || ""
            const colIndex = 3
            let key = ''

            const categoryDetails = workingCategories.find(x => x.name === entry);

            /*
            General rule - use linkedid for key (unique) if exists, i.e. entries; otherwise fall back to name, e.g. sub-categories.
            Exceptions are 'buckets' which have no id, but we know they're entries by colIndex == 3.
            Special cases are 'transfers' since they have dynamic names (so ignored), and there are name clashes on Goods/Services > Other/Property
            Little bit ugly but unfortunately categories weren't developed in a way each stage has concept of the last (and expensive to look up)
            */
            if (entry === 'Transfer') {
                key = '[category]transfer'  // ignore
            } else if ((category.subType === 'goods' || category.subType === 'services') &&
                (entry === 'Other' || entry === 'Property' || entry === 'Business')) {
                key = `[category]${category.subType}-${(entry || 'missing!').toLowerCase()}`  // make unique
            } else if (colIndex === 3) {
                key = '[entry]' + breakStrByCase(
                    (category.linkedID) || entry.replace(/ /g, '-').toLowerCase(),
                    true,
                    '-');
            }

            if (categoryDetails?.overrideDesc)
                key += '-override-' + categoryDetails.overrideDesc

            if (descriptions && descriptions[key]) {
                return <StoryblokComponent blok={descriptions[key]} />
            }
            return <></>
        }
        return <></>
    }

    const buildCategoryFlow = (category: Category) => {
        const arr:React.ReactNode[] = []

        if (category.type)
            arr.push(
                <span style={category.type === "refund"
                    ? {color: 'var(--ui-warning)', fontWeight: 'bold'}
                    : category.type === "expense"
                        ? {color: 'var(--grey-black)', fontWeight: 'bold'}
                        : {color: 'var(--incomingTxnColor)', fontWeight: 'bold'}}
                >
                    {category.type.charAt(0).toUpperCase() + category.type.slice(1)}
                </span>
            )
        if (category.subType) arr.push(category.subType.charAt(0).toUpperCase() + category.subType.slice(1))
        if (category.subGroup) arr.push(category.subGroup.charAt(0).toUpperCase() + category.subGroup.slice(1))
        if (category.name) arr.push(category.name.charAt(0).toUpperCase() + category.name.slice(1))

        return (<>{arr.map((part, index) => 
            <React.Fragment key={index}>{part}{index >= 0 && arr.length > 1 && index < arr.length - 1 && " > "}</React.Fragment>)
        }</>)
    }

    const enterSelectsCategory = () => {
        if(categories && search.length >= 3) {
            const autoSuggest = getAutocompleteSuggestion(categories, search)?.toLowerCase()
            const categoryNamesFromTag = knownTags && knownTags[search.toLowerCase()]
            const anyCategoryFromTag = categoryNamesFromTag && categories.some(category => categoryNamesFromTag.includes(category.name || ""));

            return autoSuggest === search.toLowerCase() || 
                (!autoSuggest && categories.find(x => x.name && x.name.toLowerCase().includes(search.toLocaleLowerCase())) !== undefined) ||
                (!autoSuggest && anyCategoryFromTag)
        }
        return false
    }

    return (
        <div style={{position: 'relative'}}>
            <div>
                <SearchInput 
                    focused={focused}
                    expandClicked={expandClicked}
                    setSearch={updateSearch}
                    search={search}
                    setExpandClicked={setExpandClicked}
                    setFocused={setFocused}
                    categories={workingCategories}
                    allowFocusHandler={selectedAccountId === undefined}
                    checkAutoComplete={autoComplete}
                    placeholder="Search for a category"
                    useV8
                    selectMatchBySearch={selectMatchBySearch}
                    enterSelectsCategory={enterSelectsCategory}
                />
                <div className={classNames(styles.categorySelector, { [styles.show] : focused || expandClicked }, styles.catSearchResults)} data-cy="categorySelectorColumns">
                    {potentialMatches && potentialMatches.length > 0 && (
                        <div data-cy="existingTxnsResults" className={styles.existingTransaction}>
                            <p className={styles.catSearchResultsTitle}>Waiting to be assigned:</p>
                            {potentialMatches.map((pm, index) => {
                                return <div key={index} className={styles.catSearchResult}>
                                    <div>
                                        <p>{pm.text}</p>
                                        <small>{formatCurrency(pm.amount)} on {formatDate(pm.date)}</small>
                                    </div>
                                    <Button outline variant='primary' onClick={() => selectExistingTxn(pm)}>Select</Button>
                                </div>
                            })}
                            <hr style={{marginTop: 0}}/>
                        </div>
                    )}
                    
                    { workingCategories.length !== 0
                        && (
                            (searchFound === false)
                                ? (
                                    <div className="text-muted text-center p-5">
                                        <h5>{search ? "No results found" : "Search for a category"}</h5>
                                    </div>
                                )
                                : (
                                    <div data-cy="catSelectResults">
                                        <p className={styles.catSearchResultsTitle}>Results:</p>
                                        { searchResults.map((result, index) => (
                                            <div key={index} className={styles.catSearchResult}>
                                                <div>
                                                    <p>{result.name}</p>
                                                    {showBuildCategory && <small>{buildCategoryFlow(result)}</small>}
                                                    <span>{getDescription(result)}</span>
                                                </div>
                                                <Button
                                                    outline
                                                    variant='primary'
                                                    onClick={() => selectCategory(result)}
                                                    className={classNames('mt-3 align-self-start', 
                                                        {
                                                            [styles.highlightSelect]: enterSelectsCategory() && index === 0
                                                        }
                                                    )}
                                                    disabled={result.linkedID === "AssetSales" && !hasSellableAssets}
                                                >
                                                    Select{result.type === "refund" ? " refund" : ""}
                                                </Button>
                                            </div>
                                        ))}
                                    </div>
                                )
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default CategorySelectorSearch
