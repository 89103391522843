import { useState, useEffect, useRef } from 'react';
import { Person, OptimisedPlanShareholderSetupModel, ShareholderSetupAllocation, SetupShareClassPostModel  } from '../../../../../api/inni/data-contracts';
import { Button } from '../../../../../elements/Button/Button';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import FormikField from '../../../../../elements/FormikField/FormikField';
import { BrandWrapper } from '../../../../../components/BrandWrapper/BrandWrapper';
import SimpleFullscreen from '../../../../../components/Fullscreen/SimpleFullscreen';
import Icon from '../../../../../elements/Icon/Icon';
import { Summary } from '../../../../../elements/Summary/Summary';
import { Text, Currency } from '../../../../../elements/DetailRow/DetailRow';
import { Submit } from '../../../../../elements/EditRow/EditRow';
import styles from '../shareholdings.module.scss'
import classNames from 'classnames';
import OptionSelector from '../../../../../components/OptionSelector/OptionSelector';
import { Alert } from 'react-bootstrap';

const initialValues: OptimisedPlanShareholderSetupModel = {
    classA: {name:"Class A", numberOfShares:0, capitalPerShare:0},
    classB: {name:"Class B", numberOfShares:0, capitalPerShare:0}
}

interface createShareholdingsProps{
    formSubmit: (values: OptimisedPlanShareholderSetupModel, actions: FormikHelpers<OptimisedPlanShareholderSetupModel>) => Promise<void>;
    hide: () => void;
    plan: string |undefined
    createNewPerson: () => void;
    persons: Person[];
    canAddNewPerson?: boolean;
} 

const CreateOptimisedShareholdings = ({formSubmit, plan, hide, createNewPerson, persons, canAddNewPerson}: createShareholdingsProps) => {

    //stages
    type STAGE_NAMES = "intro"|"ordinaryShareDetails"|"persons"|"shareclass"|"classAShareDetails"|"classBShareDetails"|"singleShareclass"|"secondShareclass"|"submit"
    const [stageFlow, setStageFlow] = useState<STAGE_NAMES[]>()
    const [currentStage, setCurrentStage] = useState<STAGE_NAMES>();
  
    const formikRef = useRef<FormikProps<OptimisedPlanShareholderSetupModel>>(null);

    const [shareclassType, setShareclassType] = useState<string|undefined>()
    const [singleShareCountWarning, setSingleShareCountWarning] = useState(false);
    const [secondShareCountWarning, setSecondShareCountWarning] = useState(false);

    useEffect(() => {
        if(stageFlow === undefined && plan) {
            let stages:STAGE_NAMES[] = ["intro", "persons", "shareclass" ];
            setStageFlow(stages)
            setCurrentStage("intro")
        }
    }, [plan, stageFlow])

    const addStages = (sharesType: string) => {
        if(stageFlow){
            setShareclassType(sharesType)
            const stages:STAGE_NAMES[] =[]

            if(sharesType === "single"){
                stages.push("intro", "persons", "shareclass", "classAShareDetails", "singleShareclass", "submit")
                formikRef.current?.setFieldValue(`classA.name`, "Ordinary")
                formikRef.current?.setFieldValue(`classB.numberOfShares`, 0)
                formikRef.current?.setFieldValue(`classB.capitalPerShare`, 0)
            }
            else{
                stages.push("intro", "persons", "shareclass", "classAShareDetails", "classBShareDetails", "singleShareclass", "secondShareclass", "submit")
                formikRef.current?.setFieldValue(`classA.name`, "Class A")
            }

            formikRef.current?.setFieldValue('shareAllocations', [])
            setSingleShareCountWarning(false)
            setSecondShareCountWarning(false)
            setStageFlow(stages)
            setCurrentStage("classAShareDetails")
        }
    }

    const getStageDetails = () => {
        switch(currentStage) {
            case "intro":
                return  {    
                            Title: "Confirm your company structure"
                        }
            case "persons":
                return  {    
                            Title: "Your shareholders",
                            Subtitle: "Below are your eligible shareholders. If you are missing a shareholder, please add them now"
                        }
            case "shareclass":
                return  {    
                            Title: "Your company's share type",
                            Subtitle: "Please confirm below whether your company uses 'Ordinary' shares, or 'A and B' shares"
                        }
            case "classAShareDetails":
                return  {    
                            // Title: (shareclassType && shareclassType === "single") ? "Shares details" : "Class A shares details"
                            Title: (shareclassType && shareclassType === "single")
                                ? "Detail your company's shares"
                                : "Detail your company's shares (1/2)",
                            Subtitle: (shareclassType && shareclassType === "single")
                                ? "Please tell us how your company's share structure is formed"
                                : "Please tell us how your company's share structure is formed, starting with the first share class"
                        }
            case "classBShareDetails":
                return  {    
                            Title: "Detail your company's shares (2/2)",
                            Subtitle: "Great. Now tell us about the second share class"
                        }
            case "singleShareclass":
                return  {    
                            Title: `Allocate '${formikRef.current?.values.classA?.name}' shares`,
                            Subtitle:  formikRef.current?.values.classA?.numberOfShares === 1 ?
                                        `Please choose who to allocate the single ${formikRef.current?.values.classA?.name} share to.`
                                        :
                                        `Detail how the shares are allocated to your shareholders, you must allocate all ${formikRef.current?.values.classA?.numberOfShares} ${formikRef.current?.values.classA?.name} shares`
                        }
            case "secondShareclass":
                return  {    
                            Title: `Allocate '${formikRef.current?.values.classB?.name}' shares`,
                            Subtitle:  formikRef.current?.values.classB?.numberOfShares === 1 ?
                                        `Please choose who to allocate the single ${formikRef.current?.values.classB?.name} share to.`
                                        :
                                        `Detail how the shares are allocated to your shareholders, you must allocate all ${formikRef.current?.values.classB?.numberOfShares} ${formikRef.current?.values.classB?.name} shares`
                        }
            case "submit":
                return  {    
                            Title: "Summary of shares", 
                            Subtitle: `Below is a summary of all the details you have provided.
                            Please review the information to ensure its accuracy, and make any necessary adjustments before finalising.`
                        }
            default:
                return {    
                            Title: ""
                        }
        }
    }

    
    const nextStage = () => {
        if(stageFlow && currentStage) {
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])  
        }
    }

    const addshareAllocations = (shareType: string) =>{
        if(persons && formikRef.current){
            persons.forEach((person, index) =>{
                const shareclass  = shareType === "classA"? formikRef.current?.values.classA?.name : formikRef.current?.values.classB?.name
                const i = shareType === "classA"? index : (index+persons.length )
                formikRef.current?.setFieldValue( `shareAllocations[${i}].personId` , person.id);
                formikRef.current?.setFieldValue( `shareAllocations[${i}].shareClass`, shareclass);
                formikRef.current?.setFieldValue( `shareAllocations[${i}].numberOfShares`, 0);
            });
            nextStage();
        }
    }

    const checkSharesCount = (sharesType: string) =>{
        const values = formikRef.current?.values
        if(values){
            if(sharesType === "classA"){
                const count = values.shareAllocations?.filter(x => x.shareClass === values.classA?.name).reduce((sum, x) => sum + (Number(x.numberOfShares) || 0), 0);
                if(values.classA?.numberOfShares === count){
                    setSingleShareCountWarning(false);
                    nextStage();
                }
                else{
                    setSingleShareCountWarning(true);
                }
            }
            else{
                const count = values.shareAllocations?.filter(x => x.shareClass === values.classB?.name).reduce((sum, x) => sum + (Number(x.numberOfShares) || 0), 0);
                if(values.classB?.numberOfShares === count){
                    setSecondShareCountWarning(false);
                    nextStage();
                }
                else{
                    setSecondShareCountWarning(true);
                }
            } 
        }
    }

    return (
        <SimpleFullscreen
            isOpen={true}
            onClose={hide}
            onBack={stageFlow && currentStage && stageFlow.indexOf(currentStage) > 0 ?
                () => setCurrentStage(stageFlow[stageFlow.indexOf(currentStage) - 1]) :
                undefined}
            title={getStageDetails().Title}
            subTitle={getStageDetails().Subtitle} 
        >
            <BrandWrapper>
                {stageFlow && 
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize         
                        validateOnChange={false}
                        onSubmit={formSubmit}
                        innerRef={formikRef}
                    >
                {({ isSubmitting, values }) => (
                    <Form>                
                            {currentStage === "intro" &&
                                <>
                                    <div className={styles.subtitle}>
                                        <p>Your current plan supports up to two shareholders, and up to two share classes (either Ordinary, or A and B), with all of the shares allocated to the shareholders.</p>
                                        <p>If your company isn't set up like this, please get in touch to discuss your requirements by visiting: </p>
                                        <a target="_new" rel="noopener noreferrer" href='https://www.provestor.co.uk/about/contact' className="linkText">www.provestor.co.uk/about/contact</a> 
                                        <p>Otherwise, please confirm to continue.</p>
                                    </div>
                                    
                                    <div>
                                        <Button 
                                            variant='primary' 
                                            marginTop
                                            onClick={nextStage}>
                                                Confirm
                                        </Button>
                                        <Button 
                                            buttonType='close'
                                            marginTop
                                            onClick={hide}
                                        />
                                    </div>
                                </>
                            }

                            {currentStage === "persons" &&
                                <>
                                    <ul>
                                            {persons.map((person) => {
                                                return(
                                                    <li>{person.name}</li>
                                                )
                                            })}
                                    </ul>

                                    <div>
                                        <Button 
                                            thin
                                            variant='primary' 
                                            marginTop
                                            onClick={nextStage}>
                                            
                                                Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                        </Button>

                                        { canAddNewPerson && 
                                                <Button 
                                                    variant='secondary'
                                                    marginTop
                                                    onClick={createNewPerson}>
                                                        Add new shareholder
                                                </Button>
                                        }
                                        
                                    </div>
                                </>
                            }

                            {currentStage === "shareclass" &&
                                    <OptionSelector preventSaveState onChange={(opt) => {
                                            if(opt[0].value){
                                                addStages(opt[0].value)
                                            } 
                                        }}
                                        options={{singleEntry: true, list: [
                                            {label: `A single share class (also known as 'Ordinary' shares)`, value: "single"}, 
                                            {label: `Two share classes (also known as 'A and B' shares)`, value: "double"}
                                        ]}}
                                    />
                            }

                            {currentStage === "classAShareDetails" &&
                                <>
                                    <FormikField<SetupShareClassPostModel>
                                        key={`classA.name`}
                                        className={styles.formikFields}
                                        label="Share class name"
                                        name={`classA.name` as 'name'}
                                        type="text"                                        
                                    />

                                    <FormikField<SetupShareClassPostModel>
                                        key={`classA.numberOfShares`}
                                        className={classNames(styles.formikFields, styles.inputMargin)}
                                        label="Total number of shares of this type"
                                        name={`classA.numberOfShares` as 'numberOfShares'}
                                        type="number"
                                        min='0'
                                        onlyIntegers
                                        onlyPositiveNumbers                             
                                    />

                                    <FormikField<SetupShareClassPostModel >
                                        key={`classA.capitalPerShare`}
                                        className={classNames(styles.formikFieldCapital, styles.inputMargin) }
                                        label="Capital per share"
                                        name={`classA.capitalPerShare` as 'capitalPerShare'}
                                        type="number"
                                        prefix='£'  
                                        min='0' 
                                        onlyPositiveNumbers                                                                 
                                    />

                                    <Button
                                        disabled={!values.classA?.name || !values.classA?.capitalPerShare || !values.classA?.numberOfShares}
                                        variant='primary' 
                                        thin  
                                        marginTop 
                                        onClick={() => addshareAllocations("classA")}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </> 
                            }

                            {currentStage === "classBShareDetails" &&
                                <>
                                    <FormikField<SetupShareClassPostModel>
                                        key={`classB.name`}
                                        className={styles.formikFields}
                                        label="Share class name"
                                        name={`classB.name` as 'name'}
                                        type="text"
                                                                               
                                    />

                                    <FormikField<SetupShareClassPostModel>
                                        key={`classB.numberOfShares`}
                                        className={classNames(styles.formikFields, styles.inputMargin)}
                                        label="Total number of shares of this type"
                                        name={`classB.numberOfShares` as 'numberOfShares'}
                                        type="number"
                                        min='0' 
                                        onlyIntegers    
                                        onlyPositiveNumbers                            
                                    />

                                    <FormikField<SetupShareClassPostModel >
                                        key={`classB.capitalPerShare`}
                                        className={classNames(styles.formikFieldCapital, styles.inputMargin) }
                                        label="Capital per share"
                                        name={`classB.capitalPerShare` as 'capitalPerShare'}
                                        type="number" 
                                        prefix='£'
                                        min='0'
                                        onlyPositiveNumbers                                                                   
                                    />

                                    <Button
                                        disabled={!values.classB?.name || !values.classB?.capitalPerShare || !values.classB?.numberOfShares}
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={() => addshareAllocations("classB")}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </> 
                            }

                            {currentStage === "singleShareclass" &&
                                <>
                                    <FieldArray 
                                        name="ShareAllocations"
                                        render={arrayHelpers => (
                                            <>
                                                {persons.map((person, index) => (
                                                        <div key={index}>
                                                            <FormikField<ShareholderSetupAllocation>
                                                                key={index}
                                                                className={classNames(styles.formikFields, styles.inputMargin)}
                                                                label={`Shares for ${person.name}`}
                                                                name={`shareAllocations[${index}].numberOfShares` as "numberOfShares"} 
                                                                type="number"
                                                                min='0'
                                                                onlyIntegers
                                                                onlyPositiveNumbers                             
                                                            />
                                                        </div>   
                                                        
                                                    ))
                                                }
                                            </>
                                        )}
                                    />

                                    { singleShareCountWarning && values.shareAllocations?.filter(x => x.shareClass === values.classA?.name).reduce((sum, x) => sum + (Number(x.numberOfShares) || 0), 0) !== values.classA?.numberOfShares &&
                                        <Alert variant="danger" style={{maxWidth: '780px'}}>
                                            Total total shares is {values.classA?.numberOfShares}, but you are trying to assign {values.shareAllocations?.filter(x => x.shareClass === values.classA?.name).reduce((sum, x) => sum + (Number(x.numberOfShares) || 0), 0)} shares
                                        </Alert>
                                    }
                                    
                                    <Button
                                        variant='primary' 
                                        thin  
                                        marginTop 
                                        onClick={() =>checkSharesCount("classA")}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button> 
                                </> 
                            }

                            {currentStage === "secondShareclass" &&
                                <>
                                    <FieldArray 
                                        name="ShareAllocations"
                                        render={arrayHelpers => (
                                            <>
                                                {persons.map((person, index) => (
                                                        <div key={index + persons.length}>
                                                            <FormikField<ShareholderSetupAllocation>
                                                                key={index + persons.length}
                                                                className={classNames(styles.formikFields, styles.inputMargin)}
                                                                label={`Shares for ${person.name}`}
                                                                name={`shareAllocations[${index + persons.length}].numberOfShares` as "numberOfShares"}
                                                                type="number"
                                                                min='0'
                                                                onlyIntegers
                                                                onlyPositiveNumbers                             
                                                            />
                                                        </div>   
                                                    ))
                                                }
                                            </>
                                        )}
                                    />

                                    { secondShareCountWarning && values.shareAllocations?.filter(x => x.shareClass === values.classB?.name).reduce((sum, x) => sum + (Number(x.numberOfShares) || 0), 0) !== values.classB?.numberOfShares &&
                                        <Alert variant="danger" style={{maxWidth: '780px'}}>
                                            Total total shares is {values.classB?.numberOfShares}, but you are trying to assign {values.shareAllocations?.filter(x => x.shareClass === values.classB?.name).reduce((sum, x) => sum + (Number(x.numberOfShares) || 0), 0)} shares
                                        </Alert>
                                    }
                                    
                                    <Button
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={() =>checkSharesCount("classB")}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button> 
                                </> 
                            }
                            
                            {currentStage === "submit" &&
                                <>
                                    <Summary>
                                        <h2 className={styles.subheading}>{`${values.classA?.name} shares`}</h2>
                                        <Text fixedWidth ensureRender entity={values.classA!} name={"numberOfShares"} label='Number of shares' v8Summary/>
                                        <Currency fixedWidth ensureRender entity={values.classA!} name={"capitalPerShare"} label='Capital per share' v8Summary/>

                                        {values?.shareAllocations
                                            ?.filter(x => x.shareClass === values?.classA?.name)
                                            ?.map((shares: ShareholderSetupAllocation) =>
                                                <>
                                                    <Text 
                                                        fixedWidth 
                                                        ensureRender 
                                                        entity={shares} 
                                                        name={"numberOfShares"} 
                                                        label={`Shares assigned to ${persons.find(x => x.id === shares.personId)?.forenames}`}
                                                        v8Summary
                                                    />
                                                </>
                                                
                                            )

                                        }

                                        {shareclassType && shareclassType !== "single" &&
                                            <>
                                                <h2 className={classNames(styles.subheading, styles.seperation)}>{`${values.classB?.name} shares`}</h2>
                                                <Text fixedWidth ensureRender entity={values.classB!} name={"numberOfShares"} label='Number of shares' v8Summary/>
                                                <Currency fixedWidth ensureRender entity={values.classB!} name={"capitalPerShare"} label='Capital per share' v8Summary/>

                                                {values?.shareAllocations
                                                    ?.filter(x => x.shareClass === values?.classB?.name)
                                                    ?.map((shares: ShareholderSetupAllocation, index) =>
                                                        <>
                                                            <Text 
                                                                key={index}
                                                                fixedWidth 
                                                                ensureRender 
                                                                entity={shares} 
                                                                name={"numberOfShares"} 
                                                                label={`Shares assigned to ${persons.find(x => x.id === shares.personId)?.forenames}`}
                                                                v8Summary
                                                            />
                                                        </>
                                                        
                                                    )

                                                }
                                            </>
                                        }

                                    </Summary>

                                    <Submit
                                        disabled={isSubmitting} 
                                        onCancelClick={hide} 
                                        alignLeft
                                    />
                                </>
                                
                            }
                    </Form>
                )}
                </Formik>
            }
      </BrandWrapper> 
    </SimpleFullscreen>
  )
}

export default CreateOptimisedShareholdings