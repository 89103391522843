import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, InputGroup } from 'react-bootstrap'
import styles from './SearchWithClear.module.css'

interface SearchWithClearProps {
    search: string,
    setSearch: (i: string) => void,
    placeholder?: string,
    disabled?: boolean
}

const SearchWithClear = ({ search, setSearch, placeholder, disabled }: SearchWithClearProps) => {

    const checkEscapeKey = (key: string) => {
        if (key === 'Escape')
            setSearch('');
    }

    return (
        <InputGroup className={styles.clearSearchControl} data-cy="searchBar">
            <Form.Control
                type="text"
                placeholder={placeholder ? placeholder : "Search..."}
                className={`inputWithControlRight d-inline ${styles.searchControlShadow}`}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyUp={(e: { key: string; }) => { checkEscapeKey(e.key) }}
                disabled={disabled}
            />
            <InputGroup.Append>
                <InputGroup.Text className={styles.clearSearch} onClick={() => setSearch('')}>
                    <FontAwesomeIcon icon={faTimes} />
                </InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    )
}

export default SearchWithClear;