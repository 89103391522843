import { SimpleKpi } from "../../../elements/DashboardCards/SimpleKpi"
import { formatDate } from "../../../utils/formatDate"

export const ConsultationCycleEndKpi = ({loading, date} : {loading?: boolean, date?: string}) => {
    const dateFormat = () => {
        return (
            <span>{formatDate(date) === 'Invalid Date' ? 'N/A' : formatDate(date)}</span>
        )
    }

    return (
        <SimpleKpi loading={loading} label="Cycle end date" value={dateFormat()}/>
    )
}
