/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Datagrid, Receipt, ReceiptAsSummary } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Receipts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Receipts
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/receipts
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<ReceiptAsSummary[], any>({
      path: `/api/v2/companies/${companyId}/receipts`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name GetHistory
   * @request GET:/api/v2/companies/{companyId}/receipts/ReceiptHistory
   * @secure
   */
  getHistory = (companyId: number, params: RequestParams = {}) =>
    this.request<Receipt[], any>({
      path: `/api/v2/companies/${companyId}/receipts/ReceiptHistory`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name GetReceipt
   * @request GET:/api/v2/companies/{companyId}/receipts/{receiptId}
   * @secure
   */
  getReceipt = (companyId: number, receiptId: number, params: RequestParams = {}) =>
    this.request<Receipt, any>({
      path: `/api/v2/companies/${companyId}/receipts/${receiptId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name DeleteReceipt
   * @request DELETE:/api/v2/companies/{companyId}/receipts/{receiptId}
   * @secure
   */
  deleteReceipt = (companyId: number, receiptId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/receipts/${receiptId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name Detatch
   * @request POST:/api/v2/companies/{companyId}/receipts/detatch/{receiptId}
   * @secure
   */
  detatch = (companyId: number, receiptId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/receipts/detatch/${receiptId}`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name Detach
   * @request POST:/api/v2/companies/{companyId}/receipts/detach/{receiptId}
   * @secure
   */
  detach = (companyId: number, receiptId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/receipts/detach/${receiptId}`,
      method: "POST",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name Attach
   * @request POST:/api/v2/companies/{companyId}/receipts/attach/{receiptId}
   * @secure
   */
  attach = (companyId: number, receiptId: number, bankPaymentId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/receipts/attach/${receiptId}`,
      method: "POST",
      body: bankPaymentId,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name Upload
   * @request POST:/api/v2/companies/{companyId}/receipts/upload
   * @secure
   */
  upload = (companyId: number, data: { fileToUpload: File }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/receipts/upload`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name UploadForBankPayment
   * @request POST:/api/v2/companies/{companyId}/receipts/uploadForBp/{bankPaymentId}
   * @secure
   */
  uploadForBankPayment = (
    companyId: number,
    bankPaymentId: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/receipts/uploadForBp/${bankPaymentId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name UploadForIbtRecords
   * @request POST:/api/v2/companies/{companyId}/receipts/uploadForIbt/{ibtId}
   * @secure
   */
  uploadForIbtRecords = (companyId: number, ibtId: number, data: { fileToUpload: File }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/receipts/uploadForIbt/${ibtId}`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name DeleteForIbt
   * @request DELETE:/api/v2/companies/{companyId}/receipts/deleteForIbt/{ibtId}/{documentId}
   * @secure
   */
  deleteForIbt = (companyId: number, ibtId: number, documentId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/receipts/deleteForIbt/${ibtId}/${documentId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name GetForIbt
   * @request GET:/api/v2/companies/{companyId}/receipts/getForIbt/{ibtId}
   * @secure
   */
  getForIbt = (companyId: number, ibtId: number, params: RequestParams = {}) =>
    this.request<ReceiptAsSummary[], any>({
      path: `/api/v2/companies/${companyId}/receipts/getForIbt/${ibtId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Receipts
   * @name DeleteAttachedReceipt
   * @request DELETE:/api/v2/companies/{companyId}/receipts/attached/{receiptId}/{paymentId}
   * @secure
   */
  deleteAttachedReceipt = (companyId: number, receiptId: number, paymentId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/receipts/attached/${receiptId}/${paymentId}`,
      method: "DELETE",
      secure: true,
      ...params,
    }); /**
 * No description
 * 
 * @tags Receipts
 * @name Filter
 * @summary This will get the metadata required for a grid
It's very year centric though
 * @request GET:/api/v2/companies/{companyId}/receipts/Filter
 * @secure
 */
  filter = (companyId: number, query?: { yearCode?: string }, params: RequestParams = {}) =>
    this.request<Datagrid, any>({
      path: `/api/v2/companies/${companyId}/receipts/Filter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
