import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout'
import { Entity } from '../../utils/EntityAction'
import * as DataGrid from '../../elements/DataGrid/DataGrid'
import { useFetchEntityList } from '../../hooks/entities/useFetchEntityList'
import { Account, CompanySummary, Contract, RegularPayment, RegularPaymentPostModel, VatCodeListOption, VatSetting } from '../../api/inni/data-contracts'
import { RegularPayments as RecurringPaymentsAPI } from '../../api/inni/RegularPayments'
import { asYearMonthDay, formatDate } from '../../utils/formatDate'
import { formatCurrency } from '../../utils/formatNumbers'
import React, { useContext, useEffect, useState } from 'react'
import { Button } from '../../elements/Button/Button'
import CreateEditView from './Components/CreateEditView'
import { Accounts } from '../../api/inni/Accounts'
import { formatAccount } from '../../utils/formatters/formatAccount'
import { Badge } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Contracts } from '../../api/inni/Contracts'
import { useInniAPI } from '../../hooks/useInniAPI'
import CompanyContext from '../../context/CompanyContext'
import { useModalDialog } from '../../hooks/useModalDialog'
import BrandContext from '../../context/BrandContext'
import provestorBrand from '../../components/BrandWrapper/pvBrand'
import { Expenses } from '../../api/inni/Expenses'
import { Companies } from '../../api/inni/Companies'
import { VatSettings } from '../../api/inni/VatSettings'
import { NoContentSlate } from '../../elements/Slates/NoContentSlate'
import { DatagridType } from '../../hooks/terms/useDatagridTerms'

export type IntervalType = 'M' | 'Y' | 'D'
const formatIntervalType = (n : number, t : IntervalType) => {
    if (n === 0) {
        return 'Single payment'
    }
    
    switch (t) {
        case 'D':
            if (n === 1)
                return 'Daily'
            else 
                return `Every ${n} days`
        case 'M':
            if (n === 1)
                return 'Monthly'
            else if (n === 3)
                return 'Quarterly'
            else 
                return `Every ${n} months`
        case 'Y':
            if (n === 1)
                return 'Annually'
            else 
                return `Every ${n} years`
    }
    
}

const initial : RegularPaymentPostModel = {
    nextPaymentDate: asYearMonthDay(new Date()),
    description: '',
    payee: '',
    vatCode: 'E',
    amount: 0,
    accountId: -1,
    interval: 0,
    billable: false,
    autoBudget: false
}

const RecurringPayments = () => {

    const recPaymentAPI = useInniAPI(RecurringPaymentsAPI)
    const expensesAPI = useInniAPI(Expenses)
    const vatSettingsAPI = useInniAPI(VatSettings, [403])
    const companyContext = useContext(CompanyContext)
    const brandContext = useContext(BrandContext)

    const [vatSetting, setVatSetting] = useState<VatSetting | undefined>(undefined)
    const [vatSettingLoaded, setVatSettingLoaded] = useState(false)

    const [payments, setPayments] = useState<RegularPayment[] | undefined>(undefined)
    const [paymentsLoaded, setPaymentsLoaded] = useState(false)

    const [showDetail, setShowDetail] = useState<number | undefined>(undefined)
    const [editing, setEditing] = useState<number | undefined>(undefined)
    const [creating, setCreating] = useState(false)
    
    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts)
    const [contracts, contractsLoaded] = useFetchEntityList<Contract, Contracts>(Contracts)

    const [vatRates, setVatRates] = useState<VatCodeListOption[] | undefined>(undefined)
    const [vatRatesLoaded, setVateRatesLoaded] = useState(false)

    const [showModalDialog, modalDialog] = useModalDialog();

    useEffect(() => {
        if (vatSettingsAPI && !vatSettingLoaded) {
            vatSettingsAPI.getAtDate(companyContext.cid, { date: asYearMonthDay(new Date()) })
            .then(res => {
                setVatSetting(res.data)
                setVatSettingLoaded(true)
            }).catch(() => setVatSettingLoaded(true))
        }
    }, [companyContext.cid, vatSettingLoaded, vatSettingsAPI])

    useEffect(() => {
        if (!paymentsLoaded) {
            if (recPaymentAPI) {
                recPaymentAPI.index(companyContext.cid)
                .then(res => {
                    setPayments(res.data)
                    setPaymentsLoaded(true)
                })
            }
        }
    }, [companyContext.cid, paymentsLoaded, recPaymentAPI])

    useEffect(() => {
        if (expensesAPI && !vatRatesLoaded) {
            expensesAPI.vatRates(companyContext.cid)
            .then(res => {
                setVatRates(res.data)
                setVateRatesLoaded(true)
            })
        }
    }, [companyContext.cid, expensesAPI, vatRatesLoaded])

    const accountName = (id?: number) => {
        if (id) {
            const account = accounts.find(a => a.id === id)
            return account ? account.name : undefined
        }
        return undefined;
    }

    const accountBadge = (id?: number) => {
        const name = accountName(id);
        if (name) {
            const meta = formatAccount(name);
            return <Badge pill style={{backgroundColor: '#fff', fontWeight: 'normal', color: meta.color, border: `1px solid ${meta.color}`, marginLeft: '0.5em'}}><FontAwesomeIcon icon={meta.icon} /> {name}</Badge>
        }
    }

    const cancelCallback = () => {
        setCreating(false)
        setEditing(undefined)
    }

    const updateCreatePayment = (values : RegularPaymentPostModel, id : number, creating : boolean) => {
        if (!creating && payments) {
            let tempPayments = [...payments]
            const paymentI = tempPayments?.findIndex(x => x.id === id)
            if (paymentI !== -1) {
                tempPayments[paymentI] = {...tempPayments[paymentI], ...values}
                setPayments(tempPayments)
            } else {
                console.error("Tried to update a payment which doesn't exist")
            }
        } else {
            let tempPayments = payments
            if (!tempPayments) {
                tempPayments = []
            }
            // Can we assume these are 0 as the payment should be in the future or should we calculate them?? or do we use get to get the new values
            tempPayments.push({
                ...values,
                id: id,
                wholePayments: 0,
                amountWholePayments: 0,
                budgetPayments: 1,
                amountBudgetPayments: values.amount,
                isTransfer: false,
                isArchived: false,
                editability:{
                                canDelete: true,
                                canArchive: true,
                                canEdit: true,
                                allowAnyStatus: false
                            }
            })
            setPayments(tempPayments);
        }
    }

    const showDeleteDialog = (id: number) => {
        showModalDialog(
            'Delete recurring payment?',
            'Are you sure you want to delete this recurring payment?',
            [
                <Button variant="danger" label="Yes" onClick={() => deletePayment(id)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const showArchiveDialog = (id: number) => {
        showModalDialog(
            'Archive recurring payment?',
            'You won\'t see this in your pending payment list',
            [
                <Button variant="danger" label="Yes" onClick={() => archivePayment(id)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const showUnArchiveDialog = (id: number) => {
        showModalDialog(
            'Un-archive recurring payment?',
            'The repeating payment will be reinstated, you might want to adjust the date of the next payment',
            [
                <Button variant="primary" label="Yes" onClick={() => unArchivePayment(id)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const deletePayment = (id : number) => {
        if (recPaymentAPI) {
            recPaymentAPI.delete(companyContext.cid, { recPaymentId: id })
            .then(res => {
                if (res.status === 200) {
                    setPayments(payments?.filter(x => x.id !== id))
                    setShowDetail(undefined)
                }
            })
        }
    }

    const archivePayment = (id : number) => {
        if (recPaymentAPI) {
            recPaymentAPI.archive(companyContext.cid, { recPaymentId: id })
                .then(res => {
                    if (res.status === 200) {
                        if (payments) {
                            let updatePayments = [...payments];
                            const payment = updatePayments.find(x => x.id === id)
                            if (payment) {
                                payment.isArchived = true;
                                setPayments(updatePayments);
                            }
                        }
                        setShowDetail(undefined)
                    }
                })
                .catch(error => {
                    console.error(error.error)
                })
        }
    }

    const unArchivePayment = (id : number) => {
        if (recPaymentAPI) {
            recPaymentAPI.unArchive(companyContext.cid, { recPaymentId: id })
                .then(res => {
                    if (res.status === 200) {
                        if (payments) {
                            let updatePayments = [...payments];
                            const payment = updatePayments.find(x => x.id === id)
                            if (payment) {
                                payment.isArchived = false;
                                setPayments(updatePayments);
                            }
                        }
                        setShowDetail(undefined)
                    }
                })
                .catch(error => {
                    console.error(error.error)
                })
        }
    }
    
    const getVatRateName = (vatCode:string|undefined) => {
        if(!vatCode) return "Unknown"
        if(vatRates) return vatRates.find(x => x.id === vatCode)?.name || vatCode
        return "Unknown"
    }

    return (
        <DefaultLayout useFullWidth entity={Entity.RegularPayment} title="Regular payments and savings">
            <DataGrid.Table>
                <thead>
                    <tr>
                        <th style={{width: '10%'}}>Next payment</th>
                        <th style={{width: '25%'}}>Description</th>
                        <th style={{width: '10%'}}>Amount (inc. VAT)</th>
                        {!vatSetting?.isExempt && <th style={{width: '10%'}}>VAT Rate</th>}{/* hide the vat rate column if the company isn't VAT registered */}
                        <th style={{width: '15%'}}>Frequency</th>
                        <th style={{width: '30%'}}>Payment details</th>
                    </tr>
                </thead>

                <tbody>
                    { (!paymentsLoaded || !contractsLoaded || !accountsLoaded || !payments || !vatSettingLoaded) && <>
                        <DataGrid.LoadingRow cols={!vatSetting?.isExempt ? 6 : 5} />
                        <DataGrid.LoadingRow cols={!vatSetting?.isExempt ? 6 : 5} />
                        <DataGrid.LoadingRow cols={!vatSetting?.isExempt ? 6 : 5} />
                        <DataGrid.LoadingRow cols={!vatSetting?.isExempt ? 6 : 5} />
                        <DataGrid.LoadingRow cols={!vatSetting?.isExempt ? 6 : 5} />
                    </>}
                    { paymentsLoaded && contractsLoaded && accountsLoaded && vatSettingLoaded && payments && payments.length === 0 &&
                        <tr>
                            <td colSpan={!vatSetting?.isExempt ? 6 : 5}><NoContentSlate whiteBg type={DatagridType.RegularPayments} termsKey="emptyTerms" /></td>
                        </tr>
                    }
                    { paymentsLoaded && contractsLoaded && accountsLoaded && vatSettingLoaded && payments && payments.length > 0 && <>
                        {payments.map((x,i) => {
                            return (<React.Fragment key={i}>
                                {editing !== i && <>
                                {(showDetail === undefined || showDetail !== i) ? 
                                <tr onClick={() => setShowDetail(i)}>
                                    <td>{formatDate(x.nextPaymentDate)}</td>
                                    <td>{x.description}</td>
                                    <td>{formatCurrency(x.amount)}</td>
                                    {!vatSetting?.isExempt && <td>{getVatRateName(x.vatCode)}</td>}
                                    <td>{formatIntervalType(x.interval || 0, x.intervalType as IntervalType)}</td>
                                    <td>{(x.isArchived) ?
                                        <>Repeating payment is archived.</>
                                        :
                                        <>
                                        <b>{x.wholePayments} payment(s) overdue ({formatCurrency(x.amountWholePayments)})</b><br />
                                        {x.autoBudget && <>{formatCurrency(x.amountBudgetPayments)} budgeted for future payments</>}
                                        </>}
                                    </td>
                                </tr> : 
                                <>
                                    <tr style={{background: 'var(--data-grid-evenrow)'}}>
                                        <td style={{borderBottom: '0px'}}>
                                            <label className="text-muted">Due</label><br />
                                            {formatDate(x.nextPaymentDate)}
                                        </td>
                                        <td style={{borderBottom: '0px'}}>
                                            <label className="text-muted">Description</label><br />
                                            {x.description}
                                        </td>
                                        <td style={{borderBottom: '0px'}}>
                                            <label className="text-muted">Amount (inc. VAT)</label><br />
                                            {formatCurrency(x.amount)}
                                        </td>
                                        {!vatSetting?.isExempt && <td style={{borderBottom: '0px'}}>
                                            <label className="text-muted">VAT Rate</label><br />
                                            {getVatRateName(x.vatCode)}
                                        </td>}
                                        <td style={{borderBottom: '0px'}}>
                                            <label className="text-muted">Frequency</label><br />
                                            {formatIntervalType(x.interval || 0, x.intervalType as IntervalType)}
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr style={{background: 'var(--data-grid-evenrow)'}}>
                                        <td style={{borderTop: '0px'}}>
                                        </td>
                                        <td style={{borderTop: '0px'}}>
                                            <label className="text-muted">Payee</label><br />
                                            {x.payee}
                                        </td>
                                        <td style={{borderTop: '0px'}}>
                                            <label className="text-muted">Category</label><br />
                                            {accountBadge(x.accountId)}
                                        </td>
                                        <td style={{borderTop: '0px'}}>
                                            <label className="text-muted">{brandContext.brand === provestorBrand ? 'Property/tenancy' : 'Contract'}</label><br />
                                            {contracts.find(y => y.id === x.contractId)?.name || 'None'}
                                        </td>
                                        <td style={{borderTop: '0px'}}>
                                            <label className="text-muted">Rebill</label><br />
                                            {x.billable ? 'Yes' : 'No'}
                                        </td>
                                        {!vatSetting?.isExempt && <td style={{borderTop: '0px'}}></td>}
                                    </tr>
                                    <tr>
                                        <td colSpan={!vatSetting?.isExempt ? 6 : 5}>
                                            <Button onClick={() => setShowDetail(undefined)} buttonType="done" />
                                            <Button buttonType="edit" onClick={() => {setCreating(false); setEditing(i)}} />
                                            <Button buttonType="delete" disabled={!x.editability?.canDelete} disabledMessage={x.editability?.cantDeleteReason} onClick={() => showDeleteDialog(x.id)} />
                                            {(x.isArchived) ?
                                                <Button variant="primary" label="Unarchive" onClick={() => showUnArchiveDialog(x.id)} />
                                                :((x.editability?.canDelete) ? "" : <Button buttonType="archive" label="Archive" onClick={() => showArchiveDialog(x.id)} />)}
                                        </td>
                                    </tr>                                    
                                </>}</>}
                                {editing === i && vatRates &&
                                        <CreateEditView 
                                            createEditCallback={updateCreatePayment} 
                                            id={payments[editing].id} 
                                            creating={false} 
                                            cancelCallback={cancelCallback} 
                                            contracts={contracts} 
                                            accounts={accounts} 
                                            rp={payments[editing] as RegularPaymentPostModel}
                                            showVat={true}
                                            vatRates={vatRates}
                                        />}
                            </React.Fragment>)
                        })}
                    </>}
                </tbody>

                {editing === undefined && !creating && 
                <tfoot>
                    <tr>
                        <td colSpan={!vatSetting?.isExempt ? 6 : 5}>
                            <Button buttonType="new" onClick={() => {setShowDetail(undefined); setCreating(true)}} />
                        </td>                        
                    </tr>
                </tfoot>}
                {creating && vatRates && 
                <tfoot>
                    <CreateEditView 
                        createEditCallback={updateCreatePayment} 
                        creating={true} 
                        cancelCallback={cancelCallback} 
                        contracts={contracts} 
                        accounts={accounts} 
                        rp={initial}
                        showVat={!(vatSetting?.isExempt)}
                        vatRates={vatRates}
                    />
                </tfoot>}

            </DataGrid.Table>
            { modalDialog }
        </DefaultLayout>
    )

}

export default RecurringPayments