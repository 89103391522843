import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { faCalendarAlt, faFileAlt, faFileUpload, faSpinner, faTag, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faInboxIn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';

import { Document, DocumentSourceViewModel, TaxYearViewModel, DocumentType, FinancialYearViewModel } from '../../api/inni/data-contracts';
import { Files } from '../../api/inni/Files';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { Button } from '../../elements/Button/Button';
import DismissibleBanner, { DismissibleBannerProps } from '../../elements/DismissibleBanner/DismissibleBanner';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { useAssertPermission } from '../../hooks/useAssertPermission';
import { useHasPermission } from '../../hooks/useHasPermission';
import { useInniAPI } from '../../hooks/useInniAPI';
import { useModalDialog } from '../../hooks/useModalDialog';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import Toolbar from '../../layouts/Desktop/Toolbar';
import { Action, Entity } from '../../utils/EntityAction';
import DocumentsSidebar, { SideBarItem } from './Components/DocumentsSidebar/DocumentsSidebar';
import FilesLayOut from './Components/FilesLayout/FileDetailsCard';
import styles from './DocumentsPage.module.css';
import { countAllStrings } from './helperfunctions/CountStringsinArray';
import { EditDocumentModal } from './Components/EditDocumentModal';
import { useOnboardingMessages } from '../../hooks/onboarding/useOnboardingMessages';
import classNames from 'classnames';

type RouteParams = {
	cid: string;
};

export enum DocumentActions {
	Edit = 'Edit',
	Delete = 'Delete',
	Download = 'Download',
	GoogleDrive = 'GoogleDrive',
	DropBox = 'DropBox',
	Copy = 'Copy',
	Restore = 'Restore',
}

interface LoadedData {
	documentsLoaded:boolean;
	documentNameTypeLoaded: boolean;
	financialYearLoaded: boolean;
	taxYearLoaded: boolean;
	documentSourcesLoaded: boolean;
	tagsLoaded: boolean;
  }
  
  const initialLoadedData: LoadedData = {
	documentsLoaded:false,
	documentNameTypeLoaded: false,
	financialYearLoaded: false,
	taxYearLoaded: false,
	documentSourcesLoaded: false,
	tagsLoaded: false,
  };

function DocumentsPage() {
	const hasPermission = useHasPermission();
	useAssertPermission(Entity.DocumentV7, Action.All);
	const filesAPI = useInniAPI(Files, [400]);
	const companyId = parseInt(useParams<RouteParams>().cid);
	const companyContext = useContext(CompanyContext);
	const [documents, setDocuments] = useState<Document[]>();
	const [loadedData, setLoadedData] = useState<LoadedData>(initialLoadedData);
	const [selectedLabel, setSelectedLabel] = useState<string>();
	const [selectedTaxYear, setSelectedTaxYear] = useState<string>();
	const [selectedFinancialYear, setSelectedFinancialYear] = useState<string>();
	const [selectedInbox, setSelectedInbox] = useState<boolean>(false);
	const [selectedSource, setSelectedSource] = useState<string>();
	const [selectedDelete, setSelectedDelete] = useState<boolean>(false);
	const [sideBarItems, setSideBarItems] = useState<SideBarItem[]>();
	const [documentNames, setDocumentNames] = useState<DocumentType[]>([]);
	const [taxYears, setTaxYears] = useState<TaxYearViewModel[]>([]);
	const [financialYears, setFinancialYears] = useState<FinancialYearViewModel[]>([]);
	const [documentSources, setDocumentSources] = useState<DocumentSourceViewModel[]>([]);
	const [tags, setTags] = useState<string[]>();
	const [showEditModal, setShowEditModal] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [selectedDocumentId, setSelectedDocumentId] = useState<number>();
	const [search, setSearch] = useState<string>('');
	const [fileBanner, setFileBanner] = useState<DismissibleBannerProps>();
	const [showModalDialog, modalDialog] = useModalDialog();
	const [isAdmin, setIsAdmin] = useState<boolean>(false);
	const [isAdminDecorated, setIsAdminDecorated] = useState<boolean>(false);
	const [parentItem, setParentItems] = useState<SideBarItem>();
	const [childItem, setChildItems] = useState<SideBarItem>();
	const [filteredDocuments, setFilteredDocuments] = useState<Document[]>();
	const [defaultedToInbox, setDefaultedToInbox] = useState(false)
	const [canAddDocument, setCanAddDocument] = useState(false)

	const [unreadPageMessages, setMessageRead, getOnboardingComponent] = useOnboardingMessages('files');

	// TODO: quick (nasty) hack for advisory V8 release, document permissions need looking at task: 15103
	// Admins and SaaS can add documents, V8 advisory has Saas flag but shouldn't - so check for AM support as well (advisory has AM support).
	// const canAddDocument = hasPermission(Entity.CompanySettingsAdmin, Action.All)[1] || (companyContext.product === CompanyProduct.isCap && !hasPermission(Entity.AccountManagerSupport, Action.All)[0])

	const sideBarChildItemTaxMemo = useMemo(() => {
		if (documents && selectedLabel && taxYears && loadedData.taxYearLoaded) {
			let tyData = countAllStrings(
				documents
					?.filter((x) => x.tags?.includes(selectedLabel))
					.filter((x)=> x.taxYear ? taxYears.some((y) => y.year=== x.taxYear) : true)
					.filter((x) => !x.isDeleted)
					.map((x) => x['taxYearName'] ?? 'No tax year'),
				true
			);
			if (tyData && tyData.length > 0 && tyData?.filter((x) => x.name !== 'No tax year').length > 0) {
				return tyData;
			}
		}
	}, [documents, loadedData.taxYearLoaded, selectedLabel, taxYears]);

	const sideBarChildItemFinYearMemo = useMemo(() => {
		if (documents && selectedLabel && loadedData.financialYearLoaded) {
			let fyData = countAllStrings(
				documents
					?.filter((x) => x.tags?.includes(selectedLabel))
					.filter((x) => !x.isDeleted)
					.map((x) => x['financialYearName'] ?? 'No financial year'),
				true
			);
			if (fyData && fyData.length > 0 && fyData?.filter((x) => x.name !== 'No financial year').length > 0) {
				return fyData;
			}
		}
	}, [documents, loadedData.financialYearLoaded, selectedLabel]);

	const sideBarDocuments: SideBarItem[] | undefined = useMemo(() => {
		if (documents) {
			return [
				{
					name: 'Inbox',
					count: documents?.filter((x) => x.isInbox && !x.isDeleted).length,
					type: 'inbox',
					icon: faInboxIn,
					display: isAdmin,
					isAdminOnly: isAdminDecorated,
					isChildrenAvailable: false,
					activeLabel: selectedLabel
				},
				{
					name: 'Label',
					children: isAdmin
						? countAllStrings(
								documents?.filter((x) => !x.isDeleted && !x.isInbox).flatMap((x) => x.tags),
								true
						  )
						: countAllStrings(
								documents?.filter((x) => !x.isDeleted && !x.isInbox).flatMap((x) => x.tags),
								true
						  )?.filter((x) => x.name !== 'Client Visible'),
					type: 'label',
					activeLabel: selectedLabel,
					icon: faTag,
					display: true,
					isAdminOnly: false,
					isChildrenAvailable: true,
				},
				{
					name: 'Tax year',
					children: !selectedInbox && !selectedDelete ? sideBarChildItemTaxMemo : [],
					type: 'taxYear',
					activeLabel: selectedTaxYear,
					icon: faCalendarAlt,
					display: isAdmin,
					isAdminOnly: isAdminDecorated,
					isChildrenAvailable: true,
				},
				{
					name: 'Financial year',
					children: !selectedInbox && !selectedDelete ? sideBarChildItemFinYearMemo : [],
					type: 'financialYear',
					activeLabel: selectedFinancialYear,
					icon: faCalendarAlt,
					display: isAdmin,
					isAdminOnly: isAdminDecorated,
					isChildrenAvailable: true,
				},
				{
					name: 'Source',
					children:
						!selectedInbox && !selectedDelete
							? [
									{
										name: 'Client upload',
									},
									{
										name: 'Internal',
									},
									{
										name: 'Previous accountant',
									},
									{
										name: 'Sync',
									},
							  ]
							: [],
					type: 'source',
					activeLabel: selectedSource,
					icon: faFileUpload,
					display: isAdmin,
					isAdminOnly: isAdminDecorated,
					isChildrenAvailable: true,
				},
				{
					name: 'Recycle bin',
					count: documents?.filter((x) => x.isDeleted).length,
					type: 'recycleBin',
					icon: faTrash,
					display: isAdmin,
					isAdminOnly: isAdminDecorated,
					isChildrenAvailable: false,
					activeLabel: selectedLabel
				},
			];
		}
	}, [
		documents,
		isAdmin,
		isAdminDecorated,
		selectedDelete,
		selectedFinancialYear,
		selectedInbox,
		selectedLabel,
		selectedSource,
		selectedTaxYear,
		sideBarChildItemFinYearMemo,
		sideBarChildItemTaxMemo,
	]);

	const filterDocuments = useCallback(
		(document: Document[]) => { 
			if (selectedInbox) return document.filter((x) => x.documentName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
												.filter((x) => x.isInbox && !x.isDeleted);
			if (selectedDelete) return document.filter((x) => x.documentName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
												.filter((x) => x.isDeleted);
			return document
				.filter((x) => x.documentName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
				.filter((x) =>
					selectedTaxYear && selectedTaxYear !== 'No tax year'
						? x.taxYearName === selectedTaxYear
						: selectedTaxYear === 'No tax year'
						? x.taxYearName === null
						: true
				)
				.filter((x) =>
					selectedFinancialYear && selectedFinancialYear !== 'No financial year'
						? x.financialYearName === selectedFinancialYear
						: selectedFinancialYear === 'No financial year'
						? x.financialYearName === null
						: true
				)
				.filter((x) => (selectedSource ? x.sourceName?.toLowerCase() === selectedSource.replace(/ /g, '').toLowerCase() : true)) //removes white space between words in selectedSource
				.filter((x) => (selectedLabel ? x.tags?.includes(selectedLabel) : true))
				.filter((x) => !x.isInbox && !x.isDeleted);
		},
		[search, selectedDelete, selectedFinancialYear, selectedInbox, selectedLabel, selectedSource, selectedTaxYear]
	);

	const updateLoadedState = (key: keyof LoadedData, value: boolean) => {
		setLoadedData((prevData) => ({
		  ...prevData,
		  [key]: value,
		}));
	  };

	  const handleReloadDocuments = () =>{
		setLoadedData(initialLoadedData);
	  }

	useEffect(() => {
		if(!loadedData.documentsLoaded && filesAPI){
			filesAPI?.listDocuments(companyId).then((response) => {
				setDocuments(response.data.documents);
				setCanAddDocument(response.data.listEditabilityMetaData?.canAddNew || false)
			});
			updateLoadedState('documentsLoaded',true)
		}
	}, [companyId, filesAPI, loadedData.documentsLoaded]);

	useEffect(() => {
		if(!loadedData.documentNameTypeLoaded && filesAPI){
			filesAPI?.documentNameTypesList(companyId).then((response) => {
			setDocumentNames(response.data);
		});
		updateLoadedState('documentNameTypeLoaded',true);
		}
	}, [filesAPI, companyId, loadedData.documentNameTypeLoaded]);

	useEffect(() => {
		if(!loadedData.financialYearLoaded && filesAPI){
			filesAPI?.getFinancialYears(companyId).then((response) => {
			setFinancialYears(response.data);
		});
		updateLoadedState('financialYearLoaded',true);
		}
	}, [filesAPI, companyId, loadedData.financialYearLoaded]);

	useEffect(() => {
		if(!loadedData.taxYearLoaded && filesAPI){
		 filesAPI?.getTaxYears(companyId).then((response) => {
			setTaxYears(response.data);
		});
		updateLoadedState('taxYearLoaded',true)
	}
	}, [filesAPI, companyId, loadedData.financialYearLoaded, loadedData.taxYearLoaded]);

	useEffect(() => {
		if(!loadedData.documentSourcesLoaded && filesAPI){
			filesAPI?.getDocumentSources(companyId).then((response) => {
			setDocumentSources(response.data);
		});
		updateLoadedState('documentSourcesLoaded',true);
	}
	}, [filesAPI, companyId, loadedData.documentSourcesLoaded]);

	useEffect(() => {
		if(!loadedData.tagsLoaded && filesAPI) {
			filesAPI?.tags(companyId).then((response) => {
			setTags(response.data);
		});
		updateLoadedState('tagsLoaded',true)
	}
	}, [filesAPI, companyId, loadedData.tagsLoaded]);

	useEffect(() => {
		if (documents) {
			//move scroll to top of page slowly
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}, [selectedLabel, selectedTaxYear, selectedFinancialYear, selectedInbox, selectedSource, selectedDelete, documents]);

	useEffect(() => {
		setIsAdmin(hasPermission(Entity.DocumentAdmin, Action.All)[0]);
		setIsAdminDecorated(hasPermission(Entity.DocumentAdmin, Action.All)[1]);
	}, [hasPermission]);

	useEffect(() => {
		if (documents) {
			setFilteredDocuments(filterDocuments(documents).sort((a, b) => (a.documentName > b.documentName ? 1 : -1)));
		}
	}, [documents, filterDocuments]);

	useEffect(() => {
		if (documents && sideBarDocuments) {
			setSideBarItems(sideBarDocuments);
			if(documents.filter(x => !x.isInbox && !x.isDeleted).length === 0 && !defaultedToInbox) {
				setSelectedInbox(true)
				setSelectedLabel('Inbox')
				setDefaultedToInbox(true)
			}
		}
	}, [defaultedToInbox, documents, sideBarDocuments]);

	useEffect(() => {
		if (parentItem && childItem) {
			switch (parentItem.type) {
				case 'inbox':
					setSelectedInbox(true);
					setSelectedDelete(false);
					setSelectedLabel(childItem.name);
					break;
				case 'label':
					childItem.name !== 'All' ? setSelectedLabel(childItem.name) : setSelectedLabel(undefined);
					setSelectedTaxYear(undefined);
					setSelectedFinancialYear(undefined);
					setSelectedSource(undefined);
					setSelectedInbox(false);
					setSelectedDelete(false);
					break;
				case 'taxYear':
					childItem.name !== 'All' ? setSelectedTaxYear(childItem.name) : setSelectedTaxYear(undefined);
					break;
				case 'source':
					childItem.name !== 'All' ? setSelectedSource(childItem.name) : setSelectedSource(undefined);
					break;
				case 'financialYear':
					childItem.name !== 'All' ? setSelectedFinancialYear(childItem.name) : setSelectedFinancialYear(undefined);
					break;
				case 'recycleBin':
					setSelectedTaxYear(undefined);
					setSelectedSource(undefined);
					setSelectedFinancialYear(undefined);
					setSelectedLabel(childItem.name);
					setSelectedInbox(false);
					setSelectedDelete(true);
					break;
			}
		}
	}, [childItem, parentItem]);

	const handleFileUpload =(file: File):Promise<void>=>{
		return new Promise((resolve, reject) => {
			filesAPI?.upload(companyId, { fileToUpload: file })
			.then((response) => {
				resolve()
			})
			.catch((err) => {
				console.error(err);
				reject()
			})
		})
	}

	const uploadAllFiles = async(acceptedFiles: File[]) => {
		try {
            for (const acceptedFile of acceptedFiles) {
                await handleFileUpload(acceptedFile); 
            }
        } catch (error) {
            console.error('Error occurred while uploading the file', error);
        } finally {
            handleReloadDocuments();
			setUploading(false);
        }
	}

	const showFileUploadMessage = (files: File[]) => {
		setUploading(true);
		showModalDialog(
			`Uploading document`,
			`Are you sure you want to upload ${files.length > 1 ? "these documents" : "this document"}  to ${companyContext.company?.ownerFullName}, ${companyContext.company?.name}?`,
			[
				<Button variant="primary" label="Yes" onClick={() => uploadAllFiles(files)} />,
				<Button variant="secondary" label="No" onClick={() => {setUploading(false);}} />,
			],
			false,
			undefined,
			() => setUploading(false)
		);
	};

	const handleDelete = useCallback((documentId: number) => {
		filesAPI
			?.update(companyId, documentId, {
				...documents?.find((x) => x.id === documentId),
				documentName: documents?.find((x) => x.id === documentId)?.documentName || 'document' + documentId,
				isPermanentlyDeleted: documents?.find((x) => x.id === documentId)?.isDeleted ? true : false,
				isDeleted: true,
			})
			.then((response) => {
				if (response.statusText === 'OK') {
					handleReloadDocuments();
				}
			});
	},[documents, filesAPI, companyId])

	const showDeleteDocumentDialog = useCallback((documentId: number) => {
		showModalDialog(
			`Delete document${documents?.find((x) => x.id === documentId)?.isDeleted ? ' permanently' : ''}?`,
			`Are you sure you want to delete this document${documents?.find((x) => x.id === documentId)?.isDeleted ? ' permanently' : ''}?`,
			[
				<Button variant="danger" label="Yes" onClick={() => handleDelete(documentId)} />,
				<Button variant="secondary" label="No" onClick={() => {}} />,
			],
			false
		);
	}, [documents, handleDelete, showModalDialog]);

	const handleDocumentClick = useCallback((documentId: number,action: DocumentActions) => {
			setSelectedDocumentId(documentId);
			switch (action) {
				case 'Edit':
					setSelectedDocumentId(documentId);
					hasPermission(Entity.DocumentV7, Action.Edit)[0] && setShowEditModal(true);
					break;
				case 'Download':
					filesAPI?.getTemporaryUrl(companyId, { docId: documentId }).then((response) => {
						if(response.ok){
							const link = document.createElement('a');
							link.href = response.data;
							link.target="_blank";
							link.rel="noopener noreferrer";
							link.download = documents?.find((x) => x.id === documentId)?.documentName || 'file.pdf';
							link.click();
							window.URL.revokeObjectURL(response.data);
						}
						else{
							setFileBanner({ type: 'danger', body: `Error! ${response.status}`, showBanner: true });
						}
					});
					break;
				case 'GoogleDrive':
					filesAPI?.getTemporaryUrl(companyId, { docId: documentId }).then((response) => {
						if(response.ok){
							window.open('https://docs.google.com/viewer?url=' + escape(response.data), '_blank');
						}
						else{
							setFileBanner({ type: 'danger', body: `Error! ${response.status}`, showBanner: true });
						}
					});
					break;
				case 'DropBox':
					filesAPI?.getTemporaryUrl(companyId, { docId: documentId }).then((response) => {
						if(response.ok){
							const options = {
								files: [
									{ url: response.data },
									// ...
								],
								success: function () {
									setFileBanner({ type: 'success', body: 'File saved to dropbox', showBanner: true });
								},
								progress: function (progress: any) {},
								// Cancel is called if the user presses the Cancel button or closes the Saver.
								cancel: function () {},
								error: function (errorMessage: any) {
									setFileBanner({ type: 'danger', body: 'File failed to save to dropbox', showBanner: true });
								},
							};
							(window as any).Dropbox.save(options);
						}
						else{
							setFileBanner({ type: 'danger', body: `Error! ${response.status}`, showBanner: true });
						}
					});
					break; 
				case 'Copy':
					filesAPI?.getTemporaryUrl(companyId, { docId: documentId }).then((response) => {
						//save file to dropbox
						if(response.ok){
							navigator.clipboard.writeText(response.data);
						}
						else{
							setFileBanner({ type: 'danger', body: `Error! ${response.status}`, showBanner: true });
						}
					});
					break;
				case 'Delete':
					showDeleteDocumentDialog(documentId);
					break;
				case 'Restore':
					filesAPI
						?.update(companyId, documentId, {
							...documents?.find((x) => x.id === documentId),
							documentName: documents?.find((x) => x.id === documentId)?.documentName || 'document' + documentId,
							isDeleted: false,
							isInbox: true,
						})
						.then((response) => {
							setFileBanner({ type: 'success', body: 'File restored successfully', showBanner: true });
							handleReloadDocuments()
						});
					break;
			}
	}, [companyId, documents, filesAPI, hasPermission, showDeleteDocumentDialog]);

	const EditModalWrap = useMemo(() => {
		const handleSubmit = (postModal: Document, action: 'save' | 'saveAndFile') => {
			if (action === 'save') {
				selectedDocumentId &&
					filesAPI?.update(companyId, selectedDocumentId, postModal).then((response) => {
						setShowEditModal(false);
						handleReloadDocuments()
					});
			}
			if (action === 'saveAndFile') {
				selectedDocumentId &&
					filesAPI?.update(companyId, selectedDocumentId, postModal).then((response) => {
						setShowEditModal(false);
						handleReloadDocuments()
					});
			}
		};
		return (
			<EditDocumentModal
				document={documents?.find((x) => x.id === selectedDocumentId)}
				show={showEditModal}
				handleClose={() => setShowEditModal(false)}
				documentNames={documentNames}
				tags={tags}
				taxYears={taxYears}
				financialYears={financialYears}
				documentSources={documentSources}
				handleSubmit={handleSubmit}
				isAdmin={isAdmin}
			/>
		);
	}, [documents, showEditModal, documentNames, tags, taxYears, financialYears, documentSources, isAdmin, selectedDocumentId, filesAPI, companyId]);

	return (
		<DefaultLayout
			entity={Entity.DocumentV7}
			title={isAdminDecorated ? '' : `Documents - ${selectedLabel || 'All documents'}`}
			greyBackground
			useFullWidth
			noPadding
		>
			{/* Admins require extra info in header, otherwise use layout title for correct formatting */}
			{ isAdminDecorated && (
				<Toolbar>
					<div className="d-flex p-2">
						<h3 className="font-weight-normal">
							Documents &nbsp;
							<span className="font-weight-light text-muted">{selectedLabel || 'All documents'} &nbsp;</span>
							<span className="font-weight-bold">{companyContext.company?.name} &nbsp;</span>
							<span className="font-weight-bold">{companyContext.company?.ownerFullName}</span>
						</h3>
					</div>
				</Toolbar>
			)}

			{ unreadPageMessages?.includes('info-boxes/onboarding/documents_welcome') && (
				<div className="mx-3 my-2">
					{ getOnboardingComponent('documents_welcome') }
				</div>
			)}

			<div className="d-flex">
				<DocumentsSidebar
					handleSearch={(value) => setSearch(value)}
					sideBarItems={sideBarItems}
					setParentItems={setParentItems}
					setChildItems={setChildItems}
					showSearch
					showCount
				/>
				{documents ? (
					<div className="w-100">
						{selectedInbox && canAddDocument && <MyDropzone uploading={uploading} handleUpload={showFileUploadMessage} />}
						{filteredDocuments && filteredDocuments.length > 0 ? (
							<FilesLayOut isAdminDecorated={isAdminDecorated} isAdmin={isAdmin} onClick={handleDocumentClick} documents={filteredDocuments} />
						) : (
							<NoDocuments></NoDocuments>
						)}
					</div>
				) : <FilesLayOutPlaceholder />} 
			</div>
			{showEditModal ? EditModalWrap : null}
			{modalDialog}
			<DismissibleBanner bannerProps={fileBanner} />
		</DefaultLayout>
	);
}

const MyDropzone = ({ handleUpload,uploading }: { handleUpload: (files: File[]) => void,uploading:boolean }) => {
	const onDrop = useCallback(
		(acceptedFiles) => {
			if(!uploading){
				handleUpload(acceptedFiles);
			}	
		},
		[handleUpload]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
	return (
		<div {...getRootProps()} className={classNames(styles.dropZone, {[styles.noCursor]: uploading})}>
			<input {...getInputProps()} disabled={uploading}/>
			{!uploading && (isDragActive ? <p>Drop your file(s) here...</p> : <p>Drop your file(s) here, or click to browse</p>)}
			{uploading &&
            <FontAwesomeIcon style={{color: '#888888'}} spin size="2x" icon={faSpinner} />}
		</div>
	);
};

const NoDocuments = () => {
	return (
		<div className={styles.noDocuments}>
			<div className="d-flex justify-content-center align-items-center flex-column m-4">
				<FontAwesomeIcon icon={faFileAlt} size="3x" color="secondary" />
				<h3 className="text-grey">No documents found</h3>
			</div>
		</div>
	);
};

const FilesLayOutPlaceholder = memo(() => (
	<div className="h-75 mx-2 mt-3">
						<div className="d-flex flex-wrap flex-start">
							{Array(5)
								.fill(0)
								.map((x, i) => (
									<div className="m-1" key={i}>
										<LoadingPlaceholder width="290px" height="200px" key={i}></LoadingPlaceholder>
									</div>
								))}
						</div>
	</div>
));

export default DocumentsPage;
