import classNames from 'classnames'
import { Form as FromikForm, FormikErrors, FormikHelpers, Formik, FormikProps } from 'formik'
import { useContext, useEffect, useRef, useState } from 'react'
import { Mileage, MileageEditabilityMetaData, MileagePostModel, VatSetting } from '../../../api/inni/data-contracts'
import { Mileages } from '../../../api/inni/Mileages'
import provestorBrand from '../../../components/BrandWrapper/pvBrand'
import { Modal } from '../../../components/Modal/Modal'
import BrandContext from '../../../context/BrandContext'
import CompanyContext from '../../../context/CompanyContext'
import { Button } from '../../../elements/Button/Button'
import { ListOption, Submit } from '../../../elements/EditRow/EditRow'
import { FormikRowInput, FormikRowLogic } from '../../../elements/FormikTableEditor/FormikTableEditor'
import { useInniAPI } from '../../../hooks/useInniAPI'
import styles from '../QuickEntry.module.css'
import { Form } from 'react-bootstrap'
import SimpleFullscreen from "../../../components/Fullscreen/SimpleFullscreen";
import FormikField from "../../../elements/FormikField/FormikField";
import Icon from "../../../elements/Icon/Icon";
import OptionSelector from "../../../components/OptionSelector/OptionSelector";
import { BrandWrapper } from "../../../components/BrandWrapper/BrandWrapper";
import { Summary } from '../../../elements/Summary/Summary'
import { Text, Date as DateText } from '../../../elements/DetailRow/DetailRow';

interface CreateMileageProps { 
    editingId?: number, 
    editingData?: MileagePostModel, 
    contracts: ListOption[], 
    mileageTypesOption : ListOption[], 
    advisoryFuelRateBandOption : ListOption[], 
    onCancel : () => void, 
    personId : number, 
    setMileageData : (v : Mileage[]) => void, mileageData : Mileage[],
    vatSetting: VatSetting,
    showCreateModal: boolean,
    onDelete: (i : number) => void,
    canDelete?: boolean,
    canEdit?: boolean
    isClone?: boolean
}

const CreateMileage = ({ showCreateModal, editingId, editingData, contracts, mileageTypesOption, advisoryFuelRateBandOption, onCancel, personId, setMileageData, mileageData, vatSetting, onDelete, canDelete, canEdit, isClone } : CreateMileageProps) => {

    const brandContext = useContext(BrandContext)
    const companyContext = useContext(CompanyContext)

    const mileageAPI = useInniAPI(Mileages, [400]);

    const [saveAndAdd, setSaveAndAdd] = useState(false);
    const [previousId,setPreviousId] = useState<number>();
    const [count, setCount] = useState(1);
    const [stageNum, setStageNum] = useState(0);
    const v8Styling = companyContext.company?.useV8UI;
    const formikRef = useRef<FormikProps<MileagePostModel>>(null);
    
    const prevStage = () => {
        if(currentPage.id === "overview"){
            if((vatSetting.isStandard && mileageTypesOption.find(x => Number(x.value) === Number(formikRef.current?.values.mileageTypeId))?.label !== "Bicycle" 
            && mileageTypesOption.find(x => Number(x.value) === Number(formikRef.current?.values.mileageTypeId))?.label !== "Passenger") || 
            (mileageTypesOption.find(x => Number(x.value) === Number(formikRef.current?.values.mileageTypeId))?.label === "Fuel only" )){
                setStageNum(stageNum => stageNum - 1);
            }
            else{
                setStageNum(stageNum => stageNum - 2);
            }

        }
        else{
            setStageNum(stageNum => stageNum - 1);
        }
    }

    const nextStage = () => {

        if(currentPage.id === "journey_date" && formikRef.current){
            validateCreate(formikRef.current.values).then((res) =>{
                if(res.date){
                    formikRef.current?.setFieldTouched("date", true)
                    return 
                }
                else{
                    formikRef.current?.setFieldError("date",undefined)
                    setStageNum(stageNum => stageNum + 1);
                }
            })
               
        }
        else{
            setStageNum(stageNum => stageNum + 1);
        }
    }

    const stages = [
        //TODO: Add this stage later with relevant text
        // {
        //     id: 'intro',
        //     title:  'Let\'s add new journey',
        //     subtitle: 'This process will guide you through adding the journey. Ensure you have all relevant details on hand.',
        //     buttonLabel: 'Let\'s go'
        // },
        {
            id: 'description',
            title: 'Description',
            subtitle: 'Start by providing a brief description for this entry to make it easy to identify in the future',
            buttonLabel: 'Continue'
        },
        {
            id: 'contract',
            title: 'Which property is this mileage for?',
            subtitle: 'If applicable, select the property you would like to attribute this journey to'
        },
        {
            id: 'journey_date',
            title: 'Journey date',
            subtitle: 'When did this journey take place?',
            buttonLabel: 'Continue'
        },
        {
            id: 'journey_details',
            title: 'Journey details',
            subtitle: `Please enter source and destination of the journey`,
            buttonLabel: 'Continue'
        },
        {
            id: 'distance',
            title: 'Distance',
            subtitle: `Please enter the total distance traveled during the journey`,
            buttonLabel: 'Continue'
        },
        {
            id: 'vehicle_type',
            title: 'Vehicle type',
            subtitle: 'Please select the vehicle type from the options below'
        },
        {
            id: 'engine_type',
            title: 'Engine type & fuel ',
            subtitle: `If applicable, select the engine type from the list below`
        },
        {
            id: 'overview',
            title: 'Overview',
            subtitle: `Before finalising, please review all the details you\'ve entered for this mileage entry`
        },
    ];

    
    const validateCreate = (values: MileagePostModel) : Promise<FormikErrors<MileagePostModel>> => {
        return new Promise((resolve, reject) => {
            if (mileageAPI) {
                return mileageAPI.validateCreate(companyContext.cid, {...values, personId: personId})
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }
    const validateUpdate = (id: number, values: MileagePostModel) : Promise<FormikErrors<MileagePostModel>> => {
        return new Promise((resolve, reject) => {
            if (mileageAPI) {
                return mileageAPI.validateCreate(companyContext.cid, {...values, personId: personId})
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }
    const createCall = (values: MileagePostModel, actions: FormikHelpers<MileagePostModel>) : Promise<number> => {
        return new Promise((resolve, reject) => {
            if (mileageAPI) {
                values.personId = (personId && personId) || 0
                mileageAPI.create(companyContext.cid, {...values, personId: personId})
                .then((res) => {
                    if (res.status === 201) {
                        let tempMileageData = mileageData
                        tempMileageData.push(
                            {...values, 
                            billedRate: values.billedRate || 0, 
                            id: Number(res.data),
                            isReadonly: false, 
                            billedAmount: 0.00, 
                            employeePayment: 0, 
                            suggestionId: 0, 
                            isPaid: false, 
                            contractName: contracts?.find(x => Number(x.value) === Number(values.contractId))?.label
                        })
                        
                        !v8Styling && setPreviousId(Number(res.data));
                        setMileageData([...tempMileageData])
                        setCount(prev => prev + 1);
                        if(v8Styling){
                            handleOnCancelClick()
                        }
                        else if(!saveAndAdd){
                            onCancel();
                        }
                        setSaveAndAdd(false);
                        actions.resetForm()
                        resolve(parseInt(res.data));
                    }
                })
            }
            else {
                reject();
            }
        })
    }

    const editCall = (values: MileagePostModel, actions: FormikHelpers<MileagePostModel>, id : number) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (mileageAPI && editingId) {
                values.personId = (personId && personId) || 0
                mileageAPI.put(companyContext.cid, editingId, {...values, personId: personId})
                .then((res) => {
                    if (res.status === 200) {
                        let tempMileageData = mileageData
                        let index = tempMileageData.findIndex(x => x.id === editingId)
                        tempMileageData[index] = {...values,
                            billedRate: values.billedRate || 0, 
                            id: id, 
                            isReadonly: false, 
                            billedAmount: 0.00, 
                            employeePayment: 0, 
                            suggestionId: 0, 
                            isPaid: mileageData.find(x => x.id === editingId)?.isPaid || false, 
                            contractName: contracts?.find(x => Number(x.value) === Number(values.contractId))?.label,
                            mileageTypeName: mileageTypesOption.find(x => Number(x.value) === Number(values.mileageTypeId))?.label,
                            advisoryFuelRateBandName: advisoryFuelRateBandOption.find(x => x.value === values.advisoryFuelRateBand)?.label || undefined
                        }
                        setMileageData([...tempMileageData])
                        onCancel()
                        resolve(true);
                    }
                })
            } 
            else {
                reject();
            }
        })
    }
    const handleSave = () => {
        setSaveAndAdd(false);
        formik.handleSubmit()
    }

    const handleSaveAddNew = () => {
        setSaveAndAdd(true);
        formik.handleSubmit()
    }
    const handleCopyPreviousValues = () => {
        formik.setValues({...formik.initialValues, ...mileageData.find(x => x.id === previousId)});
    }
    //Setup formik instance to be attached to inputs
    const formik = FormikRowLogic<MileagePostModel>({
        editRowValues: editingData, 
        validateUpdate: validateUpdate, 
        validateCreate: validateCreate, 
        onCreate: createCall, 
        onUpdate: editCall, 
        addingNewRow: editingId === undefined,
        id: editingId,
    })
    
    useEffect(() => {
        if (mileageTypesOption.find(x => Number(x.value) === Number(formik.values.mileageTypeId))?.label === "Bicycle" 
        || mileageTypesOption.find(x => Number(x.value) === Number(formik.values.mileageTypeId))?.label === "Passenger"
        || (mileageTypesOption.find(x => Number(x.value) === Number(formik.values.mileageTypeId))?.label !== "Fuel only" && vatSetting.isExempt)) {
            formik.setFieldValue('advisoryFuelRateBand', "")
        }
    }, [formik.values.mileageTypeId, mileageTypesOption, vatSetting.isExempt])

    const buttons = () => { 
        const mileage = mileageData.find(x => x.id === editingId)
        return [
        (mileage && mileage.editability ? mileage.editability.canEdit : !mileage?.isReadonly) && <Button buttonType="save" onClick={handleSave} key="submit"/>,
        (mileage && mileage.editability ? mileage.editability.canEdit : !mileage?.isReadonly) && !editingId && !isClone && <Button buttonType="save" label="Save & add another" onClick={handleSaveAddNew} key="submitAndNew"/>,
        (mileage && mileage.editability ? mileage.editability.canEdit : !mileage?.isReadonly) && <Button buttonType="cancel" onClick={onCancel} key="cancel"/>,
        (mileage && mileage.editability?.canDelete) && editingId && <Button buttonType="delete" onClick={() => onDelete(editingId)} key="delete"/>,
        (mileage && mileage.editability ? !mileage.editability.canEdit : mileage?.isReadonly) && <Button variant="secondary" onClick={onCancel} key="close">Close</Button>,
    ]}

    const getDisabled = (field?:keyof MileageEditabilityMetaData|undefined):boolean => {
        const mileage = mileageData.find(x => x.id === editingId)
        if(mileage) {
            if(field && mileage.editability)
                return  !mileage.editability[field]
            else
                return mileage.editability ? !mileage.editability.canEdit : mileage.isReadonly
        }
        return false
    }
    
    const showAFRB = (vatSetting.isStandard && mileageTypesOption.find(x => Number(x.value) === Number(formik.values.mileageTypeId))?.label !== "Bicycle" 
        && mileageTypesOption.find(x => Number(x.value) === Number(formik.values.mileageTypeId))?.label !== "Passenger") || 
        (mileageTypesOption.find(x => Number(x.value) === Number(formik.values.mileageTypeId))?.label === "Fuel only" )

    const currentPage = stages[stageNum];

    const handleVehicleType=(value: string)=>{
        if((vatSetting.isStandard 
            && mileageTypesOption.find(x => x.value === value)?.label !== "Bicycle" 
            && mileageTypesOption.find(x => x.value === value)?.label !== "Passenger") || 
            (mileageTypesOption.find(x =>x.value === value)?.label === "Fuel only" )
        ){
            setStageNum(stageNum => stageNum + 1)
        }
        else{
            formikRef.current?.setFieldValue("advisoryFuelRateBand", undefined)
            setStageNum(stageNum => stageNum + 2)
        }
    }

    const handleOnCancelClick =() =>{
        setStageNum(0);
        onCancel();
    }

    return (
    
    v8Styling  && !editingId && !isClone
        ?
            (
                <SimpleFullscreen
                    isOpen={showCreateModal}
                    onClose={ handleOnCancelClick}
                    onBack={stageNum !== 0 ? prevStage : undefined}
                    title={currentPage.title}
                    subTitle={currentPage.subtitle} 
                >
                    <BrandWrapper>
                        <Formik
                            initialValues={{} as MileagePostModel}
                            enableReinitialize         
                            validateOnChange={false}
                            validate={validateCreate}
                            onSubmit={createCall}
                            innerRef={formikRef}
                        >
                            {({ isSubmitting, setFieldValue, values }) => (
                                <FromikForm>   
                                    { currentPage.id === 'description' && 
                                        <>
                                            <FormikField<MileagePostModel>
                                                className={styles.formikFields}
                                                label="Description"
                                                name="description"
                                                type="text"                                        
                                                prefixWidth="large"
                                            /> 
                                        </>
                                    }

                                    { currentPage.id === 'contract' && 
                                        <>
                                            <OptionSelector 
                                                preventSaveState 
                                                onChange={ (opt) => {
                                                            if(opt[0].value === 'NA'){
                                                                setFieldValue('contractId', undefined)
                                                            }
                                                            else{
                                                                setFieldValue('contractId', opt[0].value)
                                                            } 
                                                            nextStage();
                                                        }}
                                                options={[   
                                                            {singleEntry: true, 
                                                                list: contracts.map(opt => {return {value: opt.value || "", label: opt.label}})
                                                            },
                                                            {singleEntry: true, list:
                                                                [
                                                                    { label: 'No property', value: 'NA' },
                                                                ]
                                                            }
                                                        ]}
                                            />
                                        </>
                                    }

                                    { currentPage.id === 'journey_date' && 
                                        <>
                                            <FormikField<MileagePostModel>
                                                className={styles.formikFieldsAuto}
                                                label="Date"
                                                name="date"
                                                type="date"                                        
                                            /> 
                                        </>
                                    }

                                    { currentPage.id === 'journey_details' && 
                                        <>
                                            <FormikField<MileagePostModel>
                                                className={styles.formikFields}
                                                label="Journey from"
                                                name="from"
                                                type="text"                                        
                                            /> 
                                            <FormikField<MileagePostModel>
                                                className={styles.formikFields}
                                                label="Journey to"
                                                name="to"
                                                type="text"                                        
                                            /> 
                                        </>
                                    }

                                    { currentPage.id === 'distance' && 
                                        <>
                                            <FormikField<MileagePostModel>
                                                className={styles.formikFields}
                                                label="Distance (miles)"
                                                name="distance"
                                                type="number"  
                                                min='0'
                                                onlyPositiveNumbers                                      
                                            /> 
                                        </>
                                    }

                                    { currentPage.id === 'vehicle_type' && 
                                        <>
                                            <OptionSelector 
                                                preventSaveState 
                                                onChange={ (opt) => {
                                                            if(opt[0].value){
                                                                setFieldValue('mileageTypeId', opt[0].value)
                                                                handleVehicleType(opt[0].value);
                                                            } 
                                                        }}
                                                options={{singleEntry: true, 
                                                            list: mileageTypesOption.map(opt => {return {value: opt.value || "", label: opt.label}})
                                                        }}
                                            />
                                        </>
                                    }

                                    { currentPage.id === 'engine_type' && 
                                        <>
                                            <OptionSelector 
                                                preventSaveState 
                                                onChange={ (opt) => {
                                                            if(opt[0].value){
                                                                setFieldValue('advisoryFuelRateBand', opt[0].value)
                                                            }
                                                            nextStage();
                                                        }}
                                                options={{singleEntry: true, 
                                                            list: advisoryFuelRateBandOption.map(opt => {return {value: opt.value || "", label: opt.label}})
                                                        }}
                                            />
                                    </>
                                    }

                                    { currentPage.id === 'overview' && 
                                        <>
                                            <Summary>
                                                <h2 className={styles.subheading}>Mileage details</h2>
                                                <Text fixedWidth ensureRender entity={values} name={'description'} v8Summary />
                                                <DateText fixedWidth ensureRender entity={values} name={'date'} v8Summary />
                                                <Text 
                                                    label='Contract'
                                                    fixedWidth 
                                                    ensureRender 
                                                    entity={contracts.find(cntr => cntr.value === values.contractId?.toString())|| {label: '', value: ''}}
                                                    name={'label'} 
                                                    v8Summary 
                                                />
                                                <Text fixedWidth ensureRender entity={values} name={'from'} v8Summary label="Journey from" />
                                                <Text fixedWidth ensureRender entity={values} name={'to'} v8Summary label="Journey to" />
                                                <Text fixedWidth ensureRender entity={values} name={'distance'} v8Summary label="Distance (miles)" />
                                                <Text 
                                                    label='Vehicle type'
                                                    fixedWidth 
                                                    ensureRender 
                                                    entity={mileageTypesOption.find(mto => mto.value === values.mileageTypeId?.toString())!}
                                                    name={'label'} 
                                                    v8Summary 
                                                />
                                                {values.advisoryFuelRateBand && 
                                                    <Text 
                                                        label='Engine type & fuel'
                                                        fixedWidth 
                                                        ensureRender 
                                                        entity={advisoryFuelRateBandOption.find(mto => mto.value === values.advisoryFuelRateBand?.toString()) || {label: '', value: ''}}
                                                        name={'label'} 
                                                        v8Summary 
                                                    />
                                                }
                                                
                                            </Summary>

                                            <Submit 
                                                alignLeft 
                                                disabled={isSubmitting} 
                                                onCancelClick={handleOnCancelClick}
                                            />
                                        </>
                                    }

                                    { currentPage.buttonLabel && (
                                        <Button
                                            marginTop
                                            variant="primary"
                                            thin
                                            onClick={nextStage}
                                            disabled={ currentPage.id === "description" 
                                                        ? !values.description
                                                        : currentPage.id === "journey_date"
                                                            ? !values.date
                                                            : currentPage.id === "journey_details"
                                                                ? !(values.from && values.to)
                                                                : currentPage.id === "distance"
                                                                    ? !values.distance
                                                                    : false
                                                    }
                                        >
                                            {currentPage.buttonLabel} <Icon name='arrowNext' />
                                        </Button>
                                    )}
                                </FromikForm>
                            )}
                        </Formik>
                    </BrandWrapper>
                </SimpleFullscreen>
            )
        
        :
            (   
                <Modal size="xl" showModal={showCreateModal} hideModal={onCancel} title={`${editingId ? 'Edit' : 'Create' } mileage ${(!editingId && !isClone && count !== 1) ? count : ''}`} buttons={buttons()}>
                    <div className={styles.editorRow}>
                        <div className={styles.editorField}><label>Date</label><FormikRowInput<MileagePostModel> formik={formik} property="date" type="date" disabled={getDisabled()}/></div>
                        <div className={styles.editorField}><label>Contract</label><FormikRowInput<MileagePostModel> formik={formik} property="contractId" type="select" options={contracts} disabled={getDisabled("canEditContractID")}/></div>
                    </div>
                    <div className={styles.editorRow}>
                        <div className={styles.editorField}><label>Journey from</label><FormikRowInput<MileagePostModel> formik={formik} property="from" type="text" disabled={getDisabled()}/></div>
                        <div className={styles.editorField}><label>Journey to</label><FormikRowInput<MileagePostModel> formik={formik} property="to" type="text" disabled={getDisabled()}/></div>
                    </div>
                    <div className={classNames(styles.editorRow, styles.description)}>
                        <div className={styles.editorField}><label>Description</label><FormikRowInput<MileagePostModel> formik={formik} property="description" type="text" disabled={getDisabled()}/></div>
                    </div>
                    {brandContext.brand !== provestorBrand && <div className={styles.editorRow}>          
                        <div className={styles.editorField}><label>Bill to client</label><FormikRowInput<MileagePostModel> formik={formik} property="isBillable" type="check" disabled={getDisabled("canEditBillable")}/></div>
                        <div className={styles.editorField}><label>Re-bill rate (£/mile)</label><FormikRowInput<MileagePostModel> formik={formik} prefix="£/mile" property="billedRate" type="number" disabled={!formik.values.isBillable ? !formik.values.isBillable : getDisabled("canEditBilledRate")} /></div>
                    </div>}
                    <div className={styles.editorRow}>
                        <div className={styles.editorField}><label>Distance</label><FormikRowInput<MileagePostModel> formik={formik} property="distance" type="number" disabled={getDisabled("canEditDistance")}/></div>
                        <div className={styles.editorField}><label>Vehicle type</label><FormikRowInput<MileagePostModel> formik={formik} property="mileageTypeId" type="select" options={mileageTypesOption} disabled={getDisabled("canEditMileageType")}/></div>
                    </div>
                    <div className={classNames(styles.editorRow,"mb-0")}>
                        <div className={styles.editorField}>
                            { showAFRB && 
                                <>
                                    <label>Vehicle engine & fuel</label>
                                    <FormikRowInput<MileagePostModel> formik={formik} property="advisoryFuelRateBand" type="select" options={advisoryFuelRateBandOption} disabled={getDisabled()}/>
                                </>
                            }
                        </div>
                    <div>
                    </div>
                    </div>
                    {(!editingId && previousId )&& <Form.Text muted className={styles.smallText} onClick={handleCopyPreviousValues}>
                        Copy previous entry's values
                    </Form.Text>}
                </Modal>

            )
    )

}

export default CreateMileage