import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import ConnectToBank from "../../features/Bank/ConnectToBank/ConnectToBankPage";

const connectToBank:Array<EntityRoute> = [
    {
        path: '/company/:cid/connecttobank/:failedConsentType?',
        exact: true,
        context: RouteContext.Company,
        entity:Entity.BankFeed,
        action:Action.List,
        component:ConnectToBank
    }
]

export default connectToBank;