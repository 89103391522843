import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faTrash, faEdit, faCog, faUndo, faEllipsisV, faCopy } from '@fortawesome/pro-regular-svg-icons'
import styles from './CogOptionsDropdown.module.css';
import classNames from 'classnames';

// TODO: this component needs renaming since it is no longer just a cog option.
// Slightly risky for our current situation so leaving for now - DD 30/01/24

export interface CogOptionsDropdownProps {
  edit?: () => void
  del?: () => void
  unassign?: () => void
  archive?: () => void
  revoke?: () => void
  reauth?: () => void
  rename?: () => void
  reconnect?: () => void
  copy?: () => void
  archived?: boolean
  adminOnly?: boolean
  adminOnlyOptions?: string[]
  style?: object
  disabled?: string[]
  big?: boolean
  hamburger?: boolean
  bottomAlign?: boolean
  rightAlign?: boolean
}

/**
 * Options dropdown with cog icon
 * New types can be added here but think if it's likely to be re-used - if not we could add some kind of custom parameter - DD
 * 
 * @param edit - edit callback function, displays option only if present
 * @param del - delete callback function (delete is keyword), displays option only if present
 * @param unassign - unassign callback function, displays option only if present
 * @param archive - archive callback function, displays option only if present
 * @param achived - boolean used to denote whether to display the 'archive' or 'restore' option
 * @param adminOnly - displays with the admin pink side border everywhere, button and items, overrides adminOnlyOptions
 * @param adminOnlyOptions - displays with the admin pink side border on passed items in array only, e.g. 'edit' or 'del'
 * @param style - custom css styling
 * @param disabled - disables an item if string representation present in array, e.g. 'edit' or 'del'
 * @param big - the same, but chunkier. Same as regular button size, e.g. when not used in a table
 * @param hamburger - vertical ellipsis instead of cog (v8)
 * @param bottomAlign - remove bottom padding to ensure it sits on a line with e.g. text
 */
export const CogOptionsDropdown = ({
  edit,
  del,
  unassign,
  archive,
  revoke,
  rename,
  reauth,
  reconnect,
  copy,
  archived=false,
  adminOnly,
  adminOnlyOptions=[],
  style={},
  disabled=[],
  big=false,
  hamburger=false,
  bottomAlign=false,
  rightAlign=false
}: CogOptionsDropdownProps) => {
  const adminStyle = {borderRight: '4px solid var(--admin-only-color)'};
  const componentDisabled = !edit && !del && !archive && !unassign && !revoke && !reauth && !reconnect && !copy;  // used for e.g. placeholders

  return (
    <div className={classNames('d-inline-block', {'ml-auto': rightAlign})}>
      <Dropdown id={styles.dropdown} style={style} data-cy="cogOptionsDropdown">
        <Dropdown.Toggle
            name="cogOptionsDropdown"
            variant="primary"
            size="sm"
            className={classNames(
              'button',
              styles.button,
              {
                [styles.adminOnly]: adminOnly,
                [styles.small]: !big,
                [styles.hamburger]: hamburger,
                "pb-1": bottomAlign
              }
            )}
            disabled={componentDisabled}
        >
          <FontAwesomeIcon icon={hamburger ? faEllipsisV : faCog} />
        </Dropdown.Toggle>

        <Dropdown.Menu style={adminOnly ? adminStyle: {}}>
          {/* Edit */}
          { edit && (
            <Dropdown.Item
              onClick={edit}
              disabled={disabled && disabled.includes('edit')}
              style={(!adminOnly && adminOnlyOptions && adminOnlyOptions.includes('edit')) ? adminStyle : {}}
            >
              <FontAwesomeIcon icon={faEdit} className="fa-fw mr-2" />
              Edit
            </Dropdown.Item>
          )}

          {/* Archive */}
          { archive && (
          <Dropdown.Item
            onClick={archive}
            disabled={disabled && disabled.includes('archive')}
            style={(!adminOnly && adminOnlyOptions && adminOnlyOptions.includes('archive')) ? adminStyle : {}}
          >
            <FontAwesomeIcon icon={archived ? faUndo : faArchive} className="fa-fw mr-2" />
            {archived ? 'Restore' : 'Archive'}
          </Dropdown.Item>
          )}

          {/* Delete (reserved word) */}
          { del && (
            <Dropdown.Item
              onClick={del}
              disabled={disabled && disabled.includes('del')}
              style={(!adminOnly && adminOnlyOptions && adminOnlyOptions.includes('del')) ? adminStyle : {}}
            >
              <FontAwesomeIcon icon={faTrash} className="fa-fw mr-2" />
              Delete
            </Dropdown.Item>
          )}

          {/* Unassign */}
          { unassign && (
            <Dropdown.Item
              onClick={unassign}
              disabled={disabled && disabled.includes('unassign')}
              style={(!adminOnly && adminOnlyOptions && adminOnlyOptions.includes('unassign')) ? adminStyle : {}}
            >
              {/* <FontAwesomeIcon icon={faTrash} className="fa-fw mr-2" /> */}
              Unassign
            </Dropdown.Item>
          )}

          {/* Reauth*/}
          { reauth && (
            <Dropdown.Item
              onClick={reauth}
              disabled={disabled && disabled.includes('reauth')}
              style={(!adminOnly && adminOnlyOptions && adminOnlyOptions.includes('reauth')) ? adminStyle : {}}
              data-cy="cogOptionsDropdown_reauthorise"
            >
              Reauthorise
            </Dropdown.Item>
          )}

          {/* Revoke */}
          { revoke && (
            <Dropdown.Item
              onClick={revoke}
              disabled={disabled && disabled.includes('revoke')}
              style={(!adminOnly && adminOnlyOptions && adminOnlyOptions.includes('revoke')) ? adminStyle : {}}
            >
              Revoke
            </Dropdown.Item>
          )}

          {/* Reconnect */}
          { reconnect && (
            <Dropdown.Item
              onClick={reconnect}
              disabled={disabled && disabled.includes('reconnect')}
              style={(!adminOnly && adminOnlyOptions && adminOnlyOptions.includes('reconnect')) ? adminStyle : {}}
            >
              Reconnect
            </Dropdown.Item>
          )}

          {/* Rename */}
          { rename && (
            <Dropdown.Item
              onClick={rename}
              disabled={disabled && disabled.includes('rename')}
              style={(!adminOnly && adminOnlyOptions && adminOnlyOptions.includes('rename')) ? adminStyle : {}}
              data-cy="cogOptionsDropdown_rename"
            >
              Rename
            </Dropdown.Item>
          )}

          {/* Copy */}
          { copy && (
            <Dropdown.Item
              onClick={copy}
              disabled={disabled && disabled.includes('copy')}
              style={(!adminOnly && adminOnlyOptions && adminOnlyOptions.includes('copy')) ? adminStyle : {}}
            >
              <FontAwesomeIcon icon={faCopy} className="fa-fw mr-2" />
              Copy
            </Dropdown.Item>
          )}


        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
