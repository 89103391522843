import { faPaperclip } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState, useEffect } from "react";
import { Alert, Card, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { DividendDetailed, DividendEmail, DividendEmailPostModel, DividendLine, DividendTimeLine } from "../../api/inni/data-contracts";
import { Dividends } from "../../api/inni/Dividends";
import CompanyContext, { CompanyProduct } from "../../context/CompanyContext";
import { Button } from "../../elements/Button/Button";
import { useInniAPI } from "../../hooks/useInniAPI";
import { useNavigateToEntity } from "../../hooks/useNavigateToEntity";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout"
import { Action, Entity } from "../../utils/EntityAction";
import { formatDate } from "../../utils/formatDate";
import { formatCurrency } from "../../utils/formatNumbers";
import TimeLine from "../Invoices/Elements/Timeline/TimeLine";
import styles from "./Dividend.module.css"
import { useCustomWebRequests } from "../../hooks/useCustomWebRequests";
import { Modal } from "../../components/Modal/Modal";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { Form as ReactForm } from 'react-bootstrap';
import { useModalDialog } from "../../hooks/useModalDialog";
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder/LoadingPlaceholder";
import { ExpandedViewBackLabel } from "../../elements/ExpandedViewBackLabel/ExpandedViewBackLabel";
import Toolbar from "../../layouts/Desktop/Toolbar";
import { useHistoryWrapper } from '../../hooks/useHistoryWrapper';
import classNames from "classnames";

interface RouteParams {
    // id: string
    lineId: string
}


export const DividendReadPage = () => {
    const companyContext = useContext(CompanyContext)
    const dividendsAPI = useInniAPI(Dividends);
    // const dividendId = parseInt(useParams<RouteParams>().id);
    const lineId = parseInt(useParams<RouteParams>().lineId);
    
    const navigate = useNavigateToEntity();

    const [dividend, setDividend] = useState<DividendDetailed>()
    const [line, setLine] = useState<DividendLine>()
    const [timeLine, setTimeLine] = useState<DividendTimeLine[]>()

    const [showEmailModal, setShowEmailModal] = useState(false)
    const [emailValues, setEmailValues] = useState<DividendEmail>({})
    const [showEmailSentStatus, setShowEmailSentStatus] = useState(false)
    const [sendingEmail, setSendingEmail] = useState(false)
    const [emailSuccessfullySent, setEmailSucessfullySent] = useState(false)
    
    const [isDeleting, setIsDeleting] = useState(false)

    const [showDeleteModalDialog, deleteModalDialog] = useModalDialog();

    const v8Styling = companyContext.company?.useV8UI || false
    const history = useHistoryWrapper();

    useEffect(() => {
        if(dividendsAPI && lineId && !dividend) {
            dividendsAPI.getOneByLineId(companyContext.cid, lineId).then(results => {
                if (results.data) {
                    setDividend(results.data)
                    setLine(results.data.dividendLines?.find(x => x.id === lineId))

                    dividendsAPI.getTimeLine(companyContext.cid, lineId).then((res) => {
                        setTimeLine(res.data)
                    })
                    
                    dividendsAPI.getEmailVoucher(companyContext.cid, lineId).then((res) => {
                        setEmailValues(res.data)
                    })
                }
            }).catch(error => {
                console.error(error)
            })
        }
    }, [companyContext.cid, line, dividendsAPI, lineId, dividend])

    const customRequest = useCustomWebRequests();
    
    const downloadOnClick = (url: string, fileName: string) => {
        customRequest(url).then((data) => {
            if(data.status === 200) {                
                return data.blob();
            }
            else {
                console.warn(data);
            }
        })
        .then((response) => {
            const url = response ? window.URL.createObjectURL(response) : '#';
            let a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = url;
            a.target = '_blank';
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        })
        .catch((error) => console.error(error));
    }

    const deleteDividend = () => {
        if(dividendsAPI && dividend) {
            setIsDeleting(true)
            dividendsAPI.deleteDividend(companyContext.cid, dividend.id).then(() => {
                v8Styling ? navigate(Entity.BookkeepingV8, undefined,{"activePage" : "dividends"}) : navigate(Entity.DividendV7) 
            }).catch((error) => {
                console.error(error)
            })
        }
    }

    const showDeleteModal = () => {
		showDeleteModalDialog(
			'Delete dividend?',
			`Are you sure you want to delete this dividend?`,
			[
				<Button
					key="yes"
					variant="danger"
					label="Yes"
					onClick={deleteDividend}
				/>,
				<Button key="no" variant="secondary" label="No" onClick={() => {}} />,
			],
			false
		);
	};

    const submitEmail = (values: DividendEmailPostModel, formikHelpers: FormikHelpers<DividendEmailPostModel>) => {
        if(dividendsAPI && dividend && values.subject && values.to) {
            setSendingEmail(true)
            dividendsAPI.emailPdf(companyContext.cid, lineId, values).then(() => {
                setEmailSucessfullySent(true)
            }).catch((error) => {
                console.error(error)
            }).finally(() => {
                setSendingEmail(false)
                setShowEmailSentStatus(true)
            })
        }
    }

    const fullAttachmentName = (props:any) => (
        <Tooltip id="button-tooltip" {...props}>
          {emailValues?.attachmentDisplayName}
        </Tooltip>
    );

    const cantDeleteReason = (props:any) => (
        <Tooltip id="button-tooltip" {...props}>
            {dividend && dividend.cantDeleteReason}
        </Tooltip>
    );

    const closeEmailModal = () => {
        setShowEmailModal(false)
        //Avoid buttons changing mid close
        setTimeout(() => {
            setShowEmailSentStatus(false)
            setEmailSucessfullySent(false)
        }
        , 150)
    }

    return (
        <DefaultLayout
            entity={Entity.DividendV7} 
            greyBackground 
            title={!v8Styling
                ? line
                    ? `${line.shareholder}'s dividend`
                    : "Dividends"
                : undefined
            } 
        >
            { (dividend && line)
                ? (
                    <>
                        {/* top panel buttons */}
                        { v8Styling
                            ? (
                                <ExpandedViewBackLabel
                                    backLabel="Dividends"
                                    title={line ? `${line.shareholder}'s dividend` : "Dividends"}
                                    // TODO: go back is technically wrong, i.e. if navigated by URL
                                    // need a way for entity system to support the nested v8 pages
                                    onClick={() => history.goBack()}
                                >
                
                                    <Toolbar>
                                        { !dividend.isMigration && (
                                            <>
                                                {dividend.canDelete ?
                                                    <Button 
                                                        disabled={isDeleting} 
                                                        entity={Entity.DividendV7} 
                                                        action={Action.Delete} 
                                                        buttonType="delete" 
                                                        onClick={showDeleteModal}
                                                        headerBtn
                                                    />
                                                :
                                                    <Button
                                                        disabled={true}
                                                        entity={Entity.DividendV7}
                                                        action={Action.Delete}
                                                        buttonType="delete"
                                                        onClick={showDeleteModal}
                                                        headerBtn
                                                        disabledMessage={dividend.cantDeleteReason}
                                                    />
                                                }
                                            </>
                                        )}
                                    </Toolbar>
                                </ExpandedViewBackLabel>
                            )
                            : (
                                <div style={{marginBottom: '1rem'}}>
                                    <div style={{display: 'inline-block'}}>
                                        <Button buttonType="done" entity={Entity.DividendV7} onClick={() => navigate(Entity.DividendV7)} />
                                    </div>
                                    
                                    {!dividend.isMigration && <div style={{display: 'inline-block'}}>
                                        {dividend.canDelete ?
                                            <Button 
                                                disabled={isDeleting} 
                                                entity={Entity.DividendV7} 
                                                action={Action.Delete} 
                                                buttonType="delete" 
                                                onClick={showDeleteModal}
                                            />
                                        :
                                        <OverlayTrigger overlay={cantDeleteReason}>
                                            <span style={{marginRight: "0.5rem"}}>
                                                <Button
                                                    disabled={true}
                                                    entity={Entity.DividendV7}
                                                    action={Action.Delete}
                                                    buttonType="delete"
                                                    onClick={showDeleteModal}
                                                />
                                            </span>
                                        </OverlayTrigger>}
                                    </div>}

                                    {dividend.date >= dividend.migrationDate && <div style={{display: 'inline-block'}}>
                                        <Button
                                            buttonType="file"
                                            variant="dark"
                                            outline
                                            onClick={() => {
                                                downloadOnClick(`dividends/ViewMinutes/${dividend.id}`, `Meeting Minutes (Dividend) ${formatDate(dividend.date)}.pdf`)
                                            }}
                                            label="View minutes"
                                        />

                                        <Button
                                            buttonType="file"
                                            variant="dark" 
                                            outline
                                            onClick={() => {
                                                downloadOnClick(`dividends/ViewVoucher/${dividend.id}`, `Dividend Vouchers ${formatDate(dividend.date)}.pdf`)
                                            }}
                                            label="View vouchers"
                                        />

                                        <Button
                                            buttonType="file"
                                            variant="dark"
                                            outline
                                            onClick={() => {setShowEmailModal(true)}}
                                            label="Email PDF"
                                        />
                                    </div>}
                                </div>
                            )
                        }

                        { v8Styling && dividend.date >= dividend.migrationDate && (
                            <div className="d-inline-block mb-3">
                                <Button
                                    buttonType="file"
                                    variant="dark"
                                    outline
                                    onClick={() => {
                                        downloadOnClick(`dividends/ViewMinutes/${dividend.id}`, `Meeting Minutes (Dividend) ${formatDate(dividend.date)}.pdf`)
                                    }}
                                    label="View minutes"
                                />

                                <Button
                                    buttonType="file"
                                    variant="dark" 
                                    outline
                                    onClick={() => {
                                        downloadOnClick(`dividends/ViewVoucher/${dividend.id}`, `Dividend Vouchers ${formatDate(dividend.date)}.pdf`)
                                    }}
                                    label="View vouchers"
                                />

                                <Button
                                    buttonType="file"
                                    variant="dark"
                                    outline
                                    onClick={() => {setShowEmailModal(true)}}
                                    label="Email PDF"
                                />
                            </div>
                        )}

                        { (dividend.date < dividend.migrationDate) && (
                            <Alert style={{borderLeft: '5px solid #1c535a'}} variant="info">
                                <strong>This is a migration dividend</strong>
                                <p style={{marginBottom: '0'}}>
                                    This dividend was created as part of the migration process and relates to dividend(s) raised prior to our appointment.
                                    {dividend.migratedMinutesDocumentId ? "" : " As such, the vouchers and minutes cannot be generated by the software."}
                                </p>
                            </Alert>
                        )}

                        <div className={styles.contentContainer}>
                            <Card
                                className={classNames(
                                    styles.dividendVoucherCard,
                                    {
                                        [styles.dividendVoucherCardV8]: v8Styling,
                                        "w-100": (!timeLine || timeLine.length === 0)
                                    }
                                )}
                            >
                                <Card.Body>
                                    <Card.Title>
                                        <div className="mb-5" style={{display: 'flex'}}>
                                            <span style={{flexGrow: 1}}>Dividend voucher</span>
                                            <span>{formatDate(dividend.date)}</span>
                                        </div>
                                    </Card.Title>

                                    <div className={styles.multiInfoLine}>
                                        <div>
                                            <label className="text-muted">Shareholder</label>
                                            <p><b>{line.shareholder}</b></p>
                                        </div>

                                        <div>
                                            <label className="text-muted">Net dividend payment</label>
                                            <p style={{textAlign: 'right'}}><b>{formatCurrency(line.amount)}</b></p>
                                        </div>
                                    </div>

                                    <div className={styles.infoLineSingle}>
                                        <label className="text-muted">Shares held</label>
                                        <p>{line.shares} {dividend.shareClass} share{line.shares > 1 ? "s" : ""}</p>
                                    </div>

                                    <div className={styles.infoLineSingle}>
                                        <label className="text-muted">Dividends agreed</label>
                                        <p>{formatCurrency(line.amount / line.shares)} per {dividend.shareClass} share</p>
                                    </div>
                                </Card.Body>
                            </Card>

                            { timeLine && timeLine.length > 0 && (
                                // <div> prevents card taking all available height
                                <div>
                                    <Card className={classNames(styles.timeLine, {[styles.timeLineV8]: v8Styling})}>
                                        <Card.Body>
                                            <TimeLine 
                                                timeLine={timeLine.map(x => {return {...x, actionId: 0, elementType: x.iconClass}})} 
                                                onClickInvoiceTimeLineButton={() => {}}
                                            />
                                        </Card.Body>
                                    </Card>
                                </div>
                            )}
                        </div>

                        <Modal 
                            showModal={showEmailModal}
                            hideModal={() => {setShowEmailModal(false)}}
                            title="Email dividend voucher"
                        >
                            {showEmailSentStatus && <Alert 
                                onClose={() => setShowEmailSentStatus(false)} 
                                variant={emailSuccessfullySent ? "success" : "danger"} 
                                style={{textAlign: 'left'}}
                            >
                                {emailSuccessfullySent ? "Your email has been sent" : "Sorry, something went wrong"}
                            </Alert>}

                            {emailValues && <Formik<DividendEmailPostModel>
                                enableReinitialize
                                initialValues={{to: emailValues.toAddress, subject: emailValues.subject, body: ""}}
                                onSubmit={submitEmail}
                            >
                                <Form>
                                    <EmailHeaderInput<DividendEmailPostModel> label="To:" name="to"/>
                                    <ReadOnlyInput label="From:" value={emailValues.fromAddress || ""}/>
                                    <EmailHeaderInput<DividendEmailPostModel> label="Subject:" name="subject">
                                        <OverlayTrigger overlay={fullAttachmentName}>
                                            <div className={styles.attachmentName}>
                                                <FontAwesomeIcon icon={faPaperclip}/> {emailValues.attachmentDisplayName}
                                            </div>
                                        </OverlayTrigger>
                                    </EmailHeaderInput>
                                    <Field name={"body"}>
                                        {({field}: FieldProps) => (
                                            <ReactForm.Control as="textarea" type={'textarea'} rows={12} {...field} value={field.value || ''}/>
                                        )}
                                    </Field>
                                    {/*Ideally should be in the modal footer but then we lose formik context*/}
                                    <div style={{width: '100%', textAlign: 'left', marginTop: '1rem', paddingTop: '1rem'}}>
                                        {emailSuccessfullySent ? <>
                                            <Button buttonType="done" variant="primary" onClick={closeEmailModal} />
                                        </> :
                                        <>
                                            <Button buttonType="save" variant="change" submitButton disabled={sendingEmail} label="Send" />
                                            <Button buttonType="cancel" outline variant="dark" onClick={closeEmailModal} />
                                        </>}
                                    </div>
                                </Form>
                            </Formik>}
                        </Modal>

                        {deleteModalDialog}
                    </>
                )
                : (
                    v8Styling
                    ? (
                        <ExpandedViewBackLabel backLabel="Dividends" title=" " onClick={()=>{}}>
                            <Toolbar>
                                <Button 
                                    disabled={true} 
                                    buttonType="delete" 
                                    onClick={() => {}}
                                    headerBtn
                                />
                            </Toolbar>
                            <LoadingPlaceholder height="5rem"/>
                            <LoadingPlaceholder height="20rem"/>
                        </ExpandedViewBackLabel>
                    )
                    :(
                        <div>
                            <LoadingPlaceholder height="5rem"/>
                            <LoadingPlaceholder height="20rem"/>
                        </div>
                    )
                )
            }
        </DefaultLayout>
    )
}

interface EmailHeaderInputProps<T> {
    label: string
    name: keyof T
    children?: React.ReactNode
}

const ReadOnlyInput = ({label, value} : {label: string, value: string}) => {
    return <InputGroup className={styles.emailInputHeaderRow}>
        <InputGroup.Text className={styles.emailHeaderInputLabel}>{label}</InputGroup.Text>
        <ReactForm.Control type={'text'} value={value} className={styles.emailHeaderInput} readOnly/>
    </InputGroup>
}

const EmailHeaderInput = <T, >({label, name, children} : EmailHeaderInputProps<T>) => {
    return <InputGroup className={styles.emailInputHeaderRow}>
        <InputGroup.Text className={styles.emailHeaderInputLabel}>{label}</InputGroup.Text>
        <Field name={name}>
            {({field}: FieldProps) => (
                <ReactForm.Control type={'text'} {...field} value={field.value || ''} className={styles.emailHeaderInput}/>
            )}
        </Field>
        {children && <InputGroup.Text>
            {children}    
        </InputGroup.Text>}
    </InputGroup>
}