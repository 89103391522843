import classNames from 'classnames'
import { FormikErrors, FormikHelpers, FormikValues, useFormik } from 'formik'
import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import Select, { components, GroupTypeBase, MenuProps } from 'react-select';
import { asHtml5Date } from '../../utils/formatDate'
import { Button } from '../Button/Button'
import { GroupedListOption, ListOption } from '../EditRow/EditRow'
import editRowStyles from '../EditRow/EditRow.module.css'
import editModalstyles from "../../features/Bookkeeping2022/EditorModal.module.scss";
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from '../../components/Modal/Modal';
import InfoBanner from '../InfoBanner/InfoBanner';

export type FormikRowFieldTypes = 'number'|'text'|'date'|'select'|'groupedselect'|'check'|'textarea'
type CustomMenuProps = React.ComponentType<MenuProps<any, false, GroupTypeBase<any>>>;

export interface formikEditRowInterfaceProps<T> {
    onUpdate: (values: T, actions: FormikHelpers<T>, id: number) => Promise<void|boolean>,
    onCreate: (values: T, actions: FormikHelpers<T>) => Promise<number>,
    validateCreate: (values: T) => Promise<FormikErrors<T>>,
    validateUpdate: (id: number, values: T) => Promise<FormikErrors<T>>,
    editRowValues?: T,
    addingNewRow: boolean,
    id?: number
}

export interface addNewModalData {
    title: string
    label: string
    infoMsg?: string
    apiCall: (value: string) => Promise<number>
}

export interface formikRowInputProps<T> {
    formik:FormikValues, 
    property:Extract<keyof T, string>, 
    type:FormikRowFieldTypes, 
    placeholder?:string, 
    prefix?:React.ReactNode, 
    suffix?:React.ReactNode,
    inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
    options?: ListOption[],
    groupedOptions?: GroupedListOption[],
    autoComplete?: boolean
    autoFocus?: boolean,
    onFocusFnc?: () => void
    onBlurFnc?: () => void
    disabled?: boolean,
    flipToPositive?: boolean,
    addNew?: () => void,
    onPrependedCheckboxClick?: () => void,
    prependedCheckboxChecked?: boolean,
    preArrow?: boolean,
    numberSelect?: boolean,
    customCheckYesStatus?: React.ReactNode,
    customCheckNoStatus?: React.ReactNode,
    // TODO: not happy with this arbitrary increased margin, should be vert. centered
    increasedMarginTop?: boolean,
    addNewModal?: addNewModalData
}

//Id 0 is considered false so specifically check for undefined
const checkId = (id:number|undefined) => {
    if(id === undefined) return -1
    return id
}

//As long as the view and post model share the same properties, you can pass the view model into editRowValues where it will then be seen as the post model anyway
const FormikRowLogic = <T, >({editRowValues, validateCreate, validateUpdate, onCreate, onUpdate, addingNewRow, id} : formikEditRowInterfaceProps<T>) => useFormik({
    validate: addingNewRow ? validateCreate : (values) => validateUpdate(checkId(id), values),
    initialValues: editRowValues || {} as T,
    onSubmit: addingNewRow ? onCreate : (values, actions) => onUpdate(values, actions, checkId(id)), //Assumption here is that if you're not adding a new row, ID is provided, otherwise -1 will fail in the API
    enableReinitialize: true,
    validateOnChange: false
})

const FormikRowInput = <T, >({
    formik, property, type, addNewModal,
    placeholder, prefix, suffix,
    inputProps, options, groupedOptions,
    autoFocus, onFocusFnc, onBlurFnc, numberSelect,
    onPrependedCheckboxClick, prependedCheckboxChecked,
    autoComplete = true, disabled, flipToPositive,
    addNew, preArrow, customCheckYesStatus,
    customCheckNoStatus, increasedMarginTop} : formikRowInputProps<T>) => {

    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [modalValue, setModalValue] = useState("")
    const [isSavingNewDD, setIsSavingNewDD] = useState(false)

    const CustomMenu: CustomMenuProps = ({children, ...props}) => (
        <components.Menu
            {...props}
        >
            <>
                {children}
                <div className="p-2">
                    <Button label='Add new' buttonType='new' variant='primary' className="btn-block" onClick={addNewModal ? () => setShowAddNewModal(true) : addNew} />
                </div>
            </>
        </components.Menu>
    )

    const onNewModalSave = () => {
        if(!isSavingNewDD && modalValue) {
            setIsSavingNewDD(true)
            addNewModal && addNewModal.apiCall(modalValue).then(res => {
                formik.setFieldValue(property, res)
                setShowAddNewModal(false); 
                setModalValue("");
            }).finally(() => {
                setIsSavingNewDD(false)
            })
        }
    }

    const newModalsaveIfEnterKey = (key: string) => {
        if (key === 'Enter' && !isSavingNewDD && modalValue) {
            onNewModalSave()
        }
    }

    return <>
        {type === 'textarea' &&
        <>
            <InputGroup className={classNames((formik.getFieldMeta(property).error && formik.getFieldMeta(property).touched) && "is-invalid", editRowStyles.inputGroup, editRowStyles.editRow)} style={{marginBottom: '0'}}>
                {prefix && <InputGroup.Prepend><InputGroup.Text>{prefix}</InputGroup.Text></InputGroup.Prepend>}
                <Form.Control
                    {...formik.getFieldProps(property)}
                    type={type}
                    placeholder={placeholder}
                    value={formik.values[property]} //Note: asHtml5Date can fail on dates which aren't formatted as yyyy-mm-dd on some browsers
                    className={classNames([
                        (formik.getFieldMeta(property).error && formik.getFieldMeta(property).touched) && "is-invalid"])
                    }
                    autoFocus={autoFocus}
                    onFocus={onFocusFnc}
                    onBlur={onBlurFnc || formik.handleBlur}
                    autoComplete={autoComplete ? 'on' : 'off'}
                    rows={type === 'textarea' ? 5 : 0}
                    as='textarea'
                    {...inputProps}
                />
                {suffix && <InputGroup.Append><InputGroup.Text>{suffix}</InputGroup.Text></InputGroup.Append>}
            </InputGroup>
            {formik.getFieldMeta(property).touched && formik.getFieldMeta(property).error && <small className="invalid-feedback">{formik.getFieldMeta(property).error}</small>}
        </>
        }
        { type !== 'select' && type !== 'groupedselect' && type !== 'check' && type !== 'textarea' && (
            <>
                <InputGroup
                    className={classNames(
                        (formik.getFieldMeta(property).error && formik.getFieldMeta(property).touched) && "is-invalid",
                        editRowStyles.inputGroup,
                        editRowStyles.editRow,
                        {[editRowStyles.flexAlignCenter]: onPrependedCheckboxClick !== undefined}
                    )
                    }
                    style={{marginBottom: '0'}}
                >
                    { onPrependedCheckboxClick !== undefined && (
                        <span>
                            <input
                                type="checkbox" 
                                checked={prependedCheckboxChecked}
                                onChange={onPrependedCheckboxClick}
                                style={{boxShadow: 'none', marginRight: '7px'}}
                                className={classNames({'mt-2': increasedMarginTop})}
                            />
                        </span>
                    )}
                    {(onPrependedCheckboxClick === undefined || prependedCheckboxChecked) && <>
                        { preArrow &&
                            <span style={{display: 'flex', alignItems: 'center', position: 'relative', left: '-12px'}}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </span>
                        }
                        {prefix && <InputGroup.Prepend><InputGroup.Text>{prefix}</InputGroup.Text></InputGroup.Prepend>}
                        <Form.Control
                            {...formik.getFieldProps(property)}
                            type={type}
                            placeholder={placeholder}
                            onChange={(e) => {
                                formik.setFieldValue(
                                property,
                                type === "number" ? parseFloat(e.target.value) : e.target.value
                                );
                            }}
                            value={
                                type === 'date'
                                    //Note: asHtml5Date can fail on dates which aren't formatted as yyyy-mm-dd on some browsers
                                    ? asHtml5Date(formik.values[property])
                                    : flipToPositive
                                        ? Math.abs(formik.values[property])
                                        : (formik.values[property] !== null && formik.values[property] !== undefined) ? formik.values[property] : ''}
                            className={classNames([
                                (formik.getFieldMeta(property).error && formik.getFieldMeta(property).touched) && "is-invalid"])
                            }
                            autoFocus={autoFocus}
                            onFocus={onFocusFnc}
                            onBlur={onBlurFnc || formik.handleBlur}
                            autoComplete={autoComplete ? 'on' : 'off'}
                            {...inputProps}
                            disabled={disabled}
                        />
                        {suffix && <InputGroup.Append><InputGroup.Text>{suffix}</InputGroup.Text></InputGroup.Append>}
                    </>}
                </InputGroup>
                {formik.getFieldMeta(property).touched && formik.getFieldMeta(property).error && <small className="invalid-feedback">{formik.getFieldMeta(property).error}</small>}
            </>
        )}

        {/* Select type */}
        {type === 'select' &&
        <>
            <InputGroup
                data-cy={property}
                className={classNames(
                    (formik.getFieldMeta(property).error && formik.getFieldMeta(property).touched) && "is-invalid",
                    editRowStyles.inputGroup, editRowStyles.editRow
                )}
            >
                {prefix && <InputGroup.Prepend><InputGroup.Text>{prefix}</InputGroup.Text></InputGroup.Prepend>}
                <Select                 
                    {...formik.getFieldProps(property)}
                    onChange= { (selectedOption: {value: string; label: any;} | null) => {
                        formik.setFieldValue(property, selectedOption && (numberSelect && selectedOption.value ? parseFloat(selectedOption.value) : selectedOption.value))
                        formik.setFieldTouched(property, true, false);
                    }}
                    options={options}
                    isSearchable={true}
                    value={(options && formik.values[property] !== undefined ? options.find(option => String(option.value) === String(formik.values[property])) : '') as any}
                    className={classNames(editRowStyles.selectList, editRowStyles.fullWidth)}
                    classNamePrefix="selectList"
                    isDisabled={disabled}
                    components={addNewModal || addNew ? {Menu: CustomMenu} : undefined}
                    menuPortalTarget={document.body} //https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown, https://github.com/JedWatson/react-select/issues/1537
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
                {suffix && <InputGroup.Append><InputGroup.Text>{suffix}</InputGroup.Text></InputGroup.Append>}
            </InputGroup>
            {formik.getFieldMeta(property).touched && formik.getFieldMeta(property).error && <small className="invalid-feedback">{formik.getFieldMeta(property).error}</small>}
        </>}

        {type === 'groupedselect' &&
        <>
            <InputGroup data-cy={property} className={classNames((formik.getFieldMeta(property).error && formik.getFieldMeta(property).touched) && "is-invalid", editRowStyles.inputGroup)}>
                {prefix && <InputGroup.Prepend><InputGroup.Text>{prefix}</InputGroup.Text></InputGroup.Prepend>}
                <Select 
                    className={classNames(editRowStyles.selectList, editRowStyles.fullWidth)}
                    classNamePrefix="selectList"
                    options={groupedOptions}
                    isSearchable={true}
                    value={(groupedOptions ? groupedOptions.map(i => i.options?.find(options => String(options.value) === String(formik.values[property]))) : '') as any}
                    onChange={(e) => {
                                        formik.setFieldValue(property, e.value);
                                        formik.setFieldError(property, undefined)
                                    }}
                    isDisabled={disabled}
                    onBlur={() => formik.setFieldTouched(property, true)}
                    menuPortalTarget={document.body} //https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown, https://github.com/JedWatson/react-select/issues/1537
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
                {suffix && <InputGroup.Append><InputGroup.Text>{suffix}</InputGroup.Text></InputGroup.Append>}
            </InputGroup>
            {formik.getFieldMeta(property).touched && formik.getFieldMeta(property).error && <small className="invalid-feedback">{formik.getFieldMeta(property).error}</small>}
        </>}

        {/* Checkboxes */}
        { type === 'check' &&
        <>
            <InputGroup
                className={classNames(
                    (formik.getFieldMeta(property).error && formik.getFieldMeta(property).touched) && "is-invalid",
                    editRowStyles.inputGroup,
                    editRowStyles.editRow,
                    {'mt-2': increasedMarginTop}
                )}
            >
                {prefix && <InputGroup.Prepend><InputGroup.Text>{prefix}</InputGroup.Text></InputGroup.Prepend>}
                <input 
                    {...formik.getFieldProps(property)}
                    type="checkbox"
                    value={(formik.values[property] !== undefined && formik.values[property] !== null) ? Number(formik.values[property]) : 0}
                    checked={(formik.values[property] !== undefined && formik.values[property] !== null) ? formik.values[property] : false}
                    {...inputProps}
                    onChange={(e) => formik.setFieldValue(property, e.target.checked)}
                    disabled={disabled}
                    style={{boxShadow: 'none'}}
                />
                {suffix && <InputGroup.Append><InputGroup.Text>{suffix}</InputGroup.Text></InputGroup.Append>}

                {/* Status label */}
                { formik.values[property] !== undefined && formik.values[property] !== null && (
                    <>
                        { customCheckYesStatus && formik.values[property] === true && <span className="ml-2">{ customCheckYesStatus }</span> }
                        { customCheckNoStatus && formik.values[property] === false && <span className="ml-2">{ customCheckNoStatus }</span> }
                        &nbsp;
                    </>
                )}

            </InputGroup>
            { formik.getFieldMeta(property).touched && formik.getFieldMeta(property).error && (
                <small className="invalid-feedback">{formik.getFieldMeta(property).error}</small>
            )}
        </>
        }

        {addNewModal && <Modal
            title={addNewModal.title}
            showModal={showAddNewModal}
            hideModal={() => {setShowAddNewModal(false); setModalValue("");}}
            showCloseBtn
            buttons={[<Button key="saveNewProp" variant="change" label="Save" onClick={onNewModalSave} disabled={isSavingNewDD}/>]}
            >
            <div className={editModalstyles.editorRow} style={{marginBottom: "1rem"}}>
                <div className={editModalstyles.editorField} style={{width: "100%", marginLeft: "0", marginTop: "0.5rem"}}>
                    <label style={{fontWeight: "normal"}}>{addNewModal.label}</label>
                    <div className={classNames(editModalstyles.editRow, "input-group")}>
                        <input onKeyUp={(e: { key: string; }) => {newModalsaveIfEnterKey(e.key)}} autoFocus className="form-control" type="text" value={modalValue} onChange={e => setModalValue(e.target.value)}/>
                    </div>
                </div>
            </div>
            {addNewModal.infoMsg && <InfoBanner type="info" thin noBottomMargin noTopMargin body={addNewModal.infoMsg}/>}
        </Modal>}
    </>
}

//Ideally set min-width to 190px on parent component to keep the buttons inline
const FormikRowSubmitBtns = ({handleSubmit, handleClose, small = true, infoBanner, addLabel}:
    { handleSubmit: () => void, handleClose: () => void, small?: boolean, infoBanner?: JSX.Element, addLabel?: boolean }) => {
    return <>
        {infoBanner}
    <Button small={small} rowWithErrorBtn buttonType="save" label={addLabel ? 'Add' : undefined} onClick={handleSubmit}/> 
        <Button small={small} rowWithErrorBtn buttonType="cancel" onClick={handleClose}/>
    </>
}

//Why not build view model from post model instead of calling the api? Because of unknown default values, for example default booleans which may be true of false, or default IDs for other entries
//If we wrap these two in a row component, can we use context so we don't need to pass in formik to formikRowInput? Like Formik itself does with <Formik> and <Form>... Could also be less rendering, although we'd also loose some flexibility
export {FormikRowLogic, FormikRowInput, FormikRowSubmitBtns}