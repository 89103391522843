import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { Button } from '../../../elements/Button/Button';
import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import FormikField from '../../../elements/FormikField/FormikField';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { CompanyPensionProviderSettings, CompanyPensionProviderSettingsPostModel } from '../../../api/inni/data-contracts';
import { Modal } from '../../../components/Modal/Modal';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { CompanySettings } from '../../../api/inni/CompanySettings';
import CompanyContext from '../../../context/CompanyContext';
import { formatDate } from '../../../utils/formatDate';
import { EnumMapper } from '../CompanySettingsDataTypes';


type PensionSettingsModalProps = {
    show:boolean;
    hideModal:()=>void;
    isEdit:boolean;
    pension?:CompanyPensionProviderSettings | CompanyPensionProviderSettingsPostModel;
    handlePensionSettings:(values:CompanyPensionProviderSettingsPostModel,actions:FormikHelpers<CompanyPensionProviderSettingsPostModel>)=>void;
    handleValidatePensionProviderSettings:(values:CompanyPensionProviderSettingsPostModel)=> 
    Promise<FormikErrors<CompanyPensionProviderSettingsPostModel>>;
}

type ModalProps = {
    isEdit:boolean;
    showModal:boolean;
    pensionId?:number;
    reload:boolean;
}

function PensionProviderTab() {

const companyApi = useInniAPI(CompanySettings, [400]);
const companyContext = useContext(CompanyContext);
const [newPensionProvider,setNewPensionProvider] = useState<CompanyPensionProviderSettingsPostModel>();
const [pensionProviders, setPensionProviders] = useState<CompanyPensionProviderSettings[]>();
const [modalProps,setModalProps] = useState<ModalProps>({
    isEdit:false,
    showModal:false,
    pensionId:undefined,
    reload:false
});

const {isEdit,showModal,pensionId,reload} = modalProps;

 function showEditModal(Id:number){
    setModalProps({
        isEdit:true,
        showModal:true,
        pensionId:Id,
        reload:false
    });
}

useEffect(() => {
        try{
            companyApi?.getPensionProviders(companyContext.cid).then((response) => {
                if(response.ok){
                    setPensionProviders(response.data as CompanyPensionProviderSettings[]);

                }
            });
        }
        catch(e){
            console.log(e);
        }
}, [companyApi, companyContext.cid,reload]); 

async function handleNewPensionProvider(){
    try{
        await companyApi?.newPensionProviderSettings(companyContext.cid)
        .then((response) => {
            if(response.ok){
                setNewPensionProvider(response.data);
                setModalProps({
                    isEdit:false,
                    showModal:true,
                    pensionId:undefined,
                    reload:false
                });
            }
        });
    }catch(e){
        console.log(e);
    }
}

async function handleValidatePensionProviderSettings(values: CompanyPensionProviderSettingsPostModel) {
    try {
        await companyApi?.validateCreatePensionProviderSettings(companyContext.cid, values);
        return {};
    } catch (e) {
        return e.error as Error;
    }
}

async function handleValidateUpdatePensionProviderSettings(values: CompanyPensionProviderSettingsPostModel) {
    try {
        pensionId &&
        await companyApi?.validateUpdatePensionProviderSettings(companyContext.cid, pensionId, values);
        return {};
    } catch (e) {
        return e.error as Error;
    }
}

async function handleAddPensionProvider(values: CompanyPensionProviderSettingsPostModel,
    actions:FormikHelpers<CompanyPensionProviderSettingsPostModel>) {
    try {
        await companyApi?.createPensionProviderSettings(companyContext.cid,values)
        .then((response) => {
            if(response.ok){
                actions.resetForm();
                setModalProps({
                    isEdit:false,
                    showModal:false,
                    pensionId:undefined,
                    reload:true
                });
            }
        });
    } catch (e) {
        return e.error as Error;
    }
}

async function handleUpdatePensionProvider(values: CompanyPensionProviderSettingsPostModel,
    actions:FormikHelpers<CompanyPensionProviderSettingsPostModel>) {
    try {
       modalProps.pensionId &&
        await companyApi?.updatePensionProviderSettings(companyContext.cid,modalProps.pensionId,values)
        .then((response) => {
            if(response.ok){
                actions.resetForm();
                setModalProps({
                    isEdit:false,
                    showModal:false,
                    pensionId:undefined,
                    reload:true
                });
            }
        });
    } catch (e) {
        return e.error as Error;
    }
}

  return (
     <DataGrid.Table>
        <thead>
            <tr>
                <th className='text-nowrrap'>Start date</th>
                <th className='text-nowrrap'>End date</th>
                <th className='text-nowrrap'>Pension provider</th>
                <th className='text-nowrrap'>ESPR</th>
                <th className='text-nowrrap'>Pension type</th>
                <th className='text-nowrrap'>Pension contributions</th>
                <th className='text-nowrrap'>Track compliance</th>
                <th className='text-nowrrap'>DD date</th>
                <th className='text-nowrrap'>Pays by DD</th>
                <th className='text-nowrrap'>Suppress payment email</th>
                <th className='text-nowrrap'>No. employees in the pension</th>
            </tr>
        </thead>
        <tbody>
            {!pensionProviders &&
             (
                <>
                    <DataGrid.LoadingRow cols={11} />
                    <DataGrid.LoadingRow cols={11} />
                    <DataGrid.LoadingRow cols={11} />
                </>)}
            { pensionProviders && pensionProviders.map((pension,index)=>(
                 <tr onClick={()=>showEditModal(pension.id)}>
                 <td>{formatDate(pension.startDate)}</td>
                 <td>{formatDate(pension.endDate)}</td>
                 <td>{pension.pensionProvider}</td>
                 <td>{pension.employerId}</td>
                 <td>{pension.pensionType === 'N' ? 'Net pay' : 'Relief at source'}</td>
                 <td>{pension.contributionCalculationMethod}</td>
                 <td>{(pension.trackCompliance) ? 'True' :'False'}</td>
                 <td>{pension.paymentDate}</td>
                 <td>{pension.directDebitPayment ? 'Yes':'No'}</td>
                 <td>{pension.suppressPaymentEmail ? 'Yes':'No'}</td>
                 <td>{pension.employeeCount}</td>
             </tr>
            ))}
        </tbody>
     <Button buttonType='new' className='m-2' label='Add settings' onClick={handleNewPensionProvider}/>
     <PensionSettingsModal
     isEdit={isEdit} show={showModal}
     handleValidatePensionProviderSettings={isEdit ? handleValidateUpdatePensionProviderSettings : handleValidatePensionProviderSettings}
     handlePensionSettings={isEdit ? handleUpdatePensionProvider : handleAddPensionProvider}
     hideModal={()=> setModalProps({
            isEdit:false,
            showModal:false,
            pensionId:undefined,
            reload:false
        })
     }
     pension={isEdit  ? pensionProviders?.find(x => x.id === pensionId) : newPensionProvider}/>
    </DataGrid.Table>
  )
}

function PensionSettingsModal({
    show,
    hideModal,
    isEdit,
    pension,
    handlePensionSettings,
    handleValidatePensionProviderSettings
}:PensionSettingsModalProps){

    const title = isEdit ? 'Edit pension settings' : 'Add pension settings';
    const pensionContributionType : EnumMapper<CompanyPensionProviderSettingsPostModel['contributionCalculationMethod']>[]=[
        {value:'NotSet',label:'Not set'},
        {value:'Unbanded',label:'Unbanded'},
        {value:'Banded',label:'Banded'}
    ];

    const pensionType = [{
        value:'N',label:'Net pay'},
        {value:'R',label:'Relief at source'}]

    return(
        <>
    <Formik
        initialValues={pension ||{} as CompanyPensionProviderSettingsPostModel}
        onSubmit={handlePensionSettings} 
        validateOnChange={false}
        validateOnBlur
        enableReinitialize
        validate={handleValidatePensionProviderSettings}
        >
            {({isSubmitting,dirty,handleSubmit})=>(
                <Modal title={title} size='lg' showModal={show}  
                hideModal={hideModal}
                buttons={<Button buttonType='save' label='Save' onClick={handleSubmit} disabled={isSubmitting || !dirty} />}
                showCloseBtn
                 >
                <Form style={{textAlign:'start'}}>
                <Row>
                <div className='d-flex'>
                <FormikField<CompanyPensionProviderSettingsPostModel> label='Start date' name='startDate' type='date' readonly={isEdit} />
                <FormikField<CompanyPensionProviderSettingsPostModel> label='End date' name='endDate' type='date' />
                <FormikField<CompanyPensionProviderSettingsPostModel> label='Pension provider' name='pensionProvider' type='text' readonly={isEdit} />
                </div>
            </Row>
            <Row>
                <div className='d-flex'>
                <FormikField<CompanyPensionProviderSettingsPostModel> label='ESPR' name='employerId' type='text'  />
                <FormikField<CompanyPensionProviderSettingsPostModel> name='pensionType' label='Pension type' 
                    type='select' option={pensionType} readonly={isEdit} />
                <FormikField<CompanyPensionProviderSettingsPostModel>
                    name='contributionCalculationMethod' option={pensionContributionType} label='Pension contributions' type='select' />
                </div>
            </Row>
            <Row>
                <FormikField<CompanyPensionProviderSettingsPostModel> label='Direct debit date' name='paymentDate' type='number' />
                <FormikField<CompanyPensionProviderSettingsPostModel> 
                label='Pays by direct debit' name='directDebitPayment' type='checkbox' width='0px'/>
                <FormikField<CompanyPensionProviderSettingsPostModel> 
                label='Suppress payment email' name='suppressPaymentEmail' type='checkbox' width='0px' />
                <FormikField<CompanyPensionProviderSettingsPostModel> label='Track compliance' name='trackCompliance' type='checkbox' width='0px'/>
            </Row>
            </Form>
            </Modal> 
            )}
        </Formik>
        </>
    )
}

export default PensionProviderTab