import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import { Help } from "../../features/Help/Help";

const dashboard:Array<EntityRoute> = [
    {
        path: '/company/:cid/help',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Help,
        component: Help,
    },
]

export default dashboard;

