import React, { useContext, useState } from 'react';
import { Action, Entity } from '../../utils/EntityAction';
import { Account, PropertyCreateModel } from '../../api/inni/data-contracts';
import { FormikErrors, FormikHelpers } from 'formik';
import { useAssertPermission } from '../../hooks/useAssertPermission';
import CompanyContext from '../../context/CompanyContext';
import { Properties } from '../../api/inni/Properties';
import { useInniAPI } from '../../hooks/useInniAPI';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import { PropertyEditForm } from './Components/PropertyEditForm';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { useFetchEntityList } from '../../hooks/entities/useFetchEntityList';
import { Accounts } from '../../api/inni/Accounts';
import { PropertyCreateFormV8 } from './Components/PropertyCreateFormV8';
import { useHasPermission } from '../../hooks/useHasPermission';

const InitialValue:PropertyCreateModel = {
    name: "", 
    addressLine1: "", 
    postTown: "", 
    postcode: "", 
    purchaseDate: ""
}

export const PropertyNewPage = () => {
    useAssertPermission(Entity.Property, Action.Create);
    const navigateToEntity = useNavigateToEntity();
    const companyContext = useContext(CompanyContext);
    const propertyApi = useInniAPI(Properties,[400]);
    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts);
    
    const [filesToUpload, setFilesToUpload] = useState<File[]|undefined|null>(undefined);

    const formSubmit = (values: PropertyCreateModel, actions: FormikHelpers<PropertyCreateModel>) : Promise<void>=> {
        return new Promise((resolve, reject) => {
            if (propertyApi) {
                propertyApi.create(companyContext.cid, values)
                .then(data => {
                    if(filesToUpload && filesToUpload.length > 0) {
                        propertyApi.uploadImage(companyContext.cid, parseInt(data.data), {fileToUpload: filesToUpload[0]}).then(() => {
                            //All is well
                        }).catch(error => {
                            console.error(error)
                        }).finally(() => {
                            setFilesToUpload(null)
                            navigateToEntity(Entity.Property, Action.Read, {id: data.data});
                            resolve();
                        })
                    }
                    else {
                        navigateToEntity(Entity.Property, Action.Read, {id: data.data});
                        resolve();
                    }
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } else {
                reject();
            }
        })
    }

    const formValidate =  (values: PropertyCreateModel) : Promise<FormikErrors<PropertyCreateModel>> => {
        return new Promise((resolve, reject) => {
            if (propertyApi) {
                return propertyApi.validateCreate(companyContext.cid, values)
                .then(() => resolve({}))    
                .catch(error => resolve(error.error))            
            } else {
                reject();
            }  
        })          
    }

    const onCancelClick = () => {
        navigateToEntity(Entity.Property, Action.List);
    }

    const USE_V8_CREATE =companyContext.company?.useV8UI

    return (
        <DefaultLayout entity={Entity.Property} title='New property' loading={false} >     
            { accountsLoaded && !USE_V8_CREATE && <PropertyEditForm initialValues={InitialValue} formSubmit={formSubmit} formValidate={formValidate} onCancelClick={onCancelClick} accounts={accounts}/> }
            { accountsLoaded && USE_V8_CREATE && <PropertyCreateFormV8 onCancel={onCancelClick} filesToUpload={filesToUpload} setFilesToUpload={setFilesToUpload} initialValues={InitialValue} formSubmit={formSubmit} formValidate={formValidate} onCancelClick={onCancelClick} /> }
        </DefaultLayout>
    )
}