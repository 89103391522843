import { Form, Formik, } from 'formik';
import { DateSelector, Submit, Switch, Text } from '../../../elements/EditRow/EditRow';
import { CompanyAccountSettingsPostModel } from '../../../api/inni/data-contracts';
import { formatDate } from '../../../utils/formatDate';
import useCompareChange from '../../../hooks/useCompareChange';
import { CompanySettingsProps } from '../CompanySettingsDataTypes';


function AccountsSettingTab<T extends CompanyAccountSettingsPostModel>({ settings,isAdmin,handleSubmit,handleValidate}: CompanySettingsProps<T>) {

	const formikRef = useCompareChange<T>();

	const isDisabled = (fieldName: string) => {
		if (settings?.editabilityMetaData?.readOnlyFields) {
			if (fieldName in settings.editabilityMetaData.readOnlyFields) {
				return true ;
			}
		}
		return false;
	}

	return (
		<Formik
			initialValues={settings as T }
			validateOnBlur
			validateOnChange={false}
			enableReinitialize
			onSubmit={handleSubmit}
			validate={handleValidate}
			innerRef={formikRef}
		>
			{({ isSubmitting, values , dirty }) => (
				<Form >
					<Text<CompanyAccountSettingsPostModel> name="companyRegistrationNumber" type='text' disabled={isDisabled("companyRegistrationNumber")}/>
					<DateSelector<CompanyAccountSettingsPostModel> name="currentFinancialYearStartDate" disabled={isDisabled("currentFinancialYearStartDate")} />
					<DateSelector<CompanyAccountSettingsPostModel> name="currentFinancialYearEndDate" disabled={isDisabled("currentFinancialYearEndDate")} />
					<Text<CompanyAccountSettingsPostModel> name="utr" type="number" label="UTR (Unique Taxpayer Reference)" disabled={isDisabled("utr")}/>
					<DateSelector<CompanyAccountSettingsPostModel> name="confirmationStatementDate"
					 help={values.lastCheckWithCHData ? `Last checked: ${formatDate(values.lastCheckWithCHData)}`:''} disabled={isDisabled("confirmationStatementDate")}/>
					<DateSelector<CompanyAccountSettingsPostModel> name="incorporationDate" disabled={isDisabled("incorporationDate")} />
					<DateSelector<CompanyAccountSettingsPostModel> name="dS01SentToCH" label="DS01 sent to Companies House" disabled={isDisabled("dS01SentToCH")}/>
					{isAdmin &&
						<>
						<Switch<CompanyAccountSettingsPostModel> name="trackCessation" disabled={isDisabled("trackCessation")}/>
						<DateSelector<CompanyAccountSettingsPostModel> name="cessationDate" disabled={isDisabled("cessationDate")}/>
						</>
					}
					<Submit disabled={isSubmitting || !dirty } showCancel={false} onCancelClick={() => {}} />
				</Form>
			)}
		</Formik>
	);
}

export default AccountsSettingTab;
 