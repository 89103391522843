import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import { InvoicePage } from "../../features/Invoices/InvoicePage";
import ReadInvoice from "../../features/Invoices/ReadInvoice";
import CreateInvoice from "../../features/Invoices/CreateInvoice";
import EditInvoice from "../../features/Invoices/EditInvoice";

const invoices:Array<EntityRoute> = [
    {
        path: '/company/:cid/invoicing/:year?',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.InvoiceV7,
        component:InvoicePage
    },
    {
        path: '/company/:cid/invoicing/year/:year?/view/:id',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.InvoiceV7,
        action: Action.Read,
        component:ReadInvoice
    },
    {
        path: '/company/:cid/invoicing/year/:year?/create/contractId/:id?/invoiceId/:rid?/type/:doctype?/:action?',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.InvoiceV7,
        action: Action.Create,
        component:CreateInvoice
    },
    {
        path: '/company/:cid/invoicing/year/:year?/edit/:id',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.InvoiceV7,
        action: Action.Edit,
        component:EditInvoice
    },
    {
        path: '/company/:cid/invoicing/:code?',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.InvoiceV7,
        action: Action.Delete,
        component:InvoicePage
    }
]

export default invoices;
