import { PendingPaymentModel } from '../../../api/inni/data-contracts';
import { usePagination } from '../../../hooks/usePagination';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { formatDate } from '../../../utils/formatDate';
import { Pagination } from 'react-bootstrap';
import { Button } from '../../../elements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';

interface PendingPaymentsProps {
    pendingPayments : PendingPaymentModel[],
    resetEditor : () => void,
    setupPendingPayment : (i : PendingPaymentModel) => void,
}

const PendingPaymentHead = () => {
    return (
        <thead>
            <tr>
                <th style={{width: '10%'}}>Date</th>
                <th style={{width: '20%'}}>Paid to / from</th>
                <th style={{width: '40%'}}>Description</th>
                <th style={{width: '15%'}}>To pay in</th>
                <th style={{width: '15%'}}>To pay out</th>
                <th style={{minWidth: '150px'}}></th>
            </tr>
        </thead>
    )
}

export const PendingPaymentsLoading = () => {
    return (<>
        <br />
        <h5>Pending payments</h5>
        <hr/>
        <DataGrid.Table noHover>
            <PendingPaymentHead />
            <tbody>
                <DataGrid.LoadingRow cols={6} />
                <DataGrid.LoadingRow cols={6} />
                <DataGrid.LoadingRow cols={6} />
            </tbody>
        </DataGrid.Table>
    </>)
}

const PendingPayments = ({pendingPayments, resetEditor, setupPendingPayment} : PendingPaymentsProps) => {

    const pageHook = usePagination(pendingPayments, 30, undefined ,'date', false, true)
    
    return (
        <>
            <br/>
            <h5>Pending payments</h5>
            <hr />
            <DataGrid.Table noHover={pendingPayments.length === 0}>
                <PendingPaymentHead />
                <tbody data-cy="pendingPaymentsTbl">
                    {pendingPayments && pageHook.getCurrentData().map((pendingPayment, index) =>
                        <tr key={"pp" + index} onClick={() => {
                                resetEditor()
                                setupPendingPayment(pendingPayment);
                            }}>
                            <td style={{verticalAlign: 'middle'}}>{formatDate(pendingPayment.date)}</td>
                            <td style={{verticalAlign: 'middle'}}>
                                {pendingPayment.payee && <span>{pendingPayment.payee}</span>}
                                {pendingPayment.payee && pendingPayment.ref && <br/>}
                                {pendingPayment.ref && <span>Ref: {pendingPayment.ref}</span>}
                            </td>
                            <td style={{verticalAlign: 'middle'}}>{pendingPayment.description}</td>
                            <td style={{verticalAlign: 'middle'}}>{pendingPayment.toPayIn === "&nbsp;" ? "" : pendingPayment.toPayIn}</td>
                            <td style={{verticalAlign: 'middle'}}>{pendingPayment.toPayOut === "&nbsp;" ? "" : pendingPayment.toPayOut}</td>
                            <td style={{textAlign: 'right', verticalAlign: 'middle'}}>
                                <Button variant="primary" tableBtn><FontAwesomeIcon icon={faCheck} /> Match</Button>
                            </td>
                        </tr>
                    )}
                    {pendingPayments && pendingPayments.length === 0 && 
                        <tr>
                            <td colSpan={5}><NoContentSlate whiteBg termsKey="emptyTerms" type={DatagridType.PendingPayments} /></td>
                        </tr>
                    }
                </tbody>
                {pendingPayments && pendingPayments.length > 30 &&
                <tfoot>
                    <tr>
                        <td colSpan={6}>
                            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Pagination>{pageHook.getControls()}</Pagination>
                            </div>
                        </td>
                    </tr>
                </tfoot>}
            </DataGrid.Table>
        </>
    )

}

export default PendingPayments