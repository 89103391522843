import { Form, Formik, FormikErrors } from "formik"
import { Text, Switch, DateSelector, ListOption, SelectList, RowWrapper } from "../../../elements/EditRow/EditRow"
import { Text as TextRead } from "../../../elements/DetailRow/DetailRow"
import { Button } from "../../../elements/Button/Button"
import { PayrollSetupPostModel, SetupWizardForm } from "../../../api/inni/data-contracts"
import { useInniAPI } from "../../../hooks/useInniAPI"
import { SetupWizard } from "../../../api/inni/SetupWizard"
import { useContext, useState } from "react"
import CompanyContext from "../../../context/CompanyContext"
import styles from '../SetupWizard.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons"
import { Form as BSForm } from "react-bootstrap"
import { WarningText } from "./WarningText"

const payrollIntervalSL : ListOption[] = [
    { value: "W", label: "Weeks" },
    { value: "M", label: "Months" },    
    { value: "Y", label: "Years" },
]

const accountsOfficeSL : ListOption[] = [
    {value: "S", label: "Shipley"},
    {value: "C", label: "Cumbernauld"}
]

const Payroll = ({setNextPage, setPrevPage, formState} : {setNextPage : (fs?: SetupWizardForm) => void, setPrevPage : () => void, formState : SetupWizardForm}) => {

    const swAPI = useInniAPI(SetupWizard, [400])
    const companyContext = useContext(CompanyContext)

    const [setupPayrollToggle, setSetupPayrollToggle] = useState(true)

    const savePayroll = (values : PayrollSetupPostModel) => {
        if (swAPI) {
            swAPI.updateCompanyPayroll(companyContext.cid, values)
            .then(res => {
                if (res.status === 200) {
                    setNextPage({...formState, ...values})
                }
            })
        }
    }

    const validatePayroll = (values : PayrollSetupPostModel) : Promise<FormikErrors<PayrollSetupPostModel>> => {
        return new Promise<FormikErrors<PayrollSetupPostModel>>((resolve, reject) => {
            if (swAPI) {
                swAPI?.validateUpdatePayroll(companyContext.cid, values).then(x => {
                    resolve({})
                }).catch(err => resolve(err.error))
            } else {
                reject()
            }            
        })
    }

    return (
        <div>
            <Formik
                initialValues={formState as PayrollSetupPostModel}
                validateOnChange={false}
                enableReinitialize
                onSubmit={savePayroll}
                validate={validatePayroll}
            >
                {({ isSubmitting, values }) => (

                    <Form>
                        <p style={{cursor: 'pointer'}} onClick={setPrevPage}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
                        <h1>Payroll setup</h1>
                        <p>Please submit the following information in order to pay tax and national insurance contributions to HM Revenue & Customs, you will need your yellow Employer Payslip Booklet. <br />
                        If you do not yet have the required information from HMRC please enter 'TBC' or '0' in the boxes below.</p>
                        <hr />
                        {formState.setupPayroll ? 
                        <>
                            <p>The Payroll for this company have already been confirmed, please reset the wizard on the confirmation page to make changes.</p>
                            <div className={styles.formButtons}>
                                <Button onClick={setNextPage} variant="change">Continue</Button>
                            </div>
                        </> : <>
                            <RowWrapper name={"switchSetup"} label={"Setup payroll (allows skipping to next page)"}>
                                <div style={{display: 'flex', alignItems: 'center', height: '100%'}}>
                                    <BSForm.Check id="switchSetup" type='switch' checked={setupPayrollToggle} onChange={(e) => setSetupPayrollToggle(!setupPayrollToggle)} />
                                </div>
                            </RowWrapper>
                            {setupPayrollToggle && <>
                            <h4>Payroll information</h4>
                            <div style={{display: 'flex'}}>
                                <div style={{width: '75%'}}>
                                    <TextRead entity={formState} ensureRender editStyling name="currentPAYEYear" label="1. Income tax year" />
                                    <Text<PayrollSetupPostModel> name="payeAccountsOfficeRef" label="2. Accounts Office Ref" />
                                    <Text<PayrollSetupPostModel> name="payeOfficeNo" label="3. Paye Office No." />
                                    <Text<PayrollSetupPostModel> name="payeOfficeRef" label="4. Paye Office Reg" />
                                    <SelectList<PayrollSetupPostModel> name="payeAccountsOffice" options={accountsOfficeSL} label="5. Issued by" />
                                    <Switch<PayrollSetupPostModel> name="payeQuarterly" label="Do you pay your PAYE payments on a quarterly basis?" />
                                </div>
                                <img style={{width: '25%', objectFit: 'contain'}} src={`./images/setupwizard/PayslipBook.png`} alt="HMRC Yellow employer payslip booklet" />
                            </div>
                            <hr />
                            <h4>Payroll settings</h4>
                            {/* Suffix below possibly confusing - may think they can set the period? Maybe move interval above? */}
                            <Text<PayrollSetupPostModel> name="payrollInterval" suffix={payrollIntervalSL.find(x => x.value === values.payrollIntervalType)?.label} label="How often do you want to pay your employees?" />
                            <SelectList<PayrollSetupPostModel> name="payrollIntervalType" options={payrollIntervalSL} label="Interval" />
                            <DateSelector<PayrollSetupPostModel> name="nextPayrollDate" label="When do you next want to pay your employees?"
                                help="IMPORTANT: Please ensure this is a working day (i.e. Mon to Fri, not a bank holiday)." />
                            <Text<PayrollSetupPostModel> min={1} type="number" name="payrollDay" label="Preferred payroll day (of week/month)"
                                help="IMPORTANT: For weekly pay enter 1 (Monday) to 5 (Friday). For monthly pay enter 1 to 31." />
                            <DateSelector<PayrollSetupPostModel> name="rtiEnabledFrom" label="When do you want to start submitting RTI information?" /></>}

                            <div className={styles.formButtons}>
                                {setupPayrollToggle ? <Button submitButton variant="change">Continue</Button> : <Button onClick={setNextPage} variant="change">Continue</Button>}                                
                            </div>
                            <WarningText />
                        </>}
                    </Form>

                )}
            
            
            </Formik>
        </div>
            
    )
}

export default Payroll