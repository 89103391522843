import { BasicFormLayoutFullscreen } from '../../layouts/Desktop/BasicFormLayout';
import { LoadingShim } from '../../elements/LoadingShim/LoadingShim';


const HoldingPage = () => {
    return (
        <BasicFormLayoutFullscreen title="">
            <LoadingShim />
        </BasicFormLayoutFullscreen>
    )
}

export default HoldingPage;