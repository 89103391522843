import { useContext, useEffect, useState } from "react";
import Attachments from "../../components/Attachments/Attachments";
import { BasicFormLayout } from "../../layouts/Desktop/BasicFormLayout";
import { FileRejection } from "react-dropzone";
import CompanyContext from "../../context/CompanyContext";
import { useInniAPI } from "../../hooks/useInniAPI";
import { PvSatr } from "../../api/inni/PvSatr";
import { Alert, Form } from "react-bootstrap";
import { Button } from "../../elements/Button/Button";
import { useStandaloneSATRStatus } from "../../hooks/useStandaloneSATRStatus";
import { PageAlert } from "../../components/Alerts/PageAlert";
import Icon from "../../elements/Icon/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { Files } from "../../api/inni/Files";
import FormikField from "../../elements/FormikField/FormikField";
import { FieldArray, Formik } from "formik";
import { useModalDialog } from "../../hooks/useModalDialog";


interface SATRFiles {
    filesToUpload: File
    section: string
    id?: number
    docId?: number
}

export default function SATRFinanceUploads() {

    const companyContext = useContext(CompanyContext)
    const pvsatrAPI = useInniAPI(PvSatr)
    const filesAPI = useInniAPI(Files, [400]);
    const { pvSatrDetails, completeStep, nextStep, resetStep } = useStandaloneSATRStatus();
    const [fileInvalidMessage, setFileInvalidMessage] = useState<string | undefined>(undefined);
    const [showFileInvalidAlert, setShowFileInvalidAlert] = useState(false)
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    
    const [uploadedFiles, setUploadedFiles] = useState<SATRFiles[]>([]);
    const [showModalDialog, modalDialog] = useModalDialog();


     useEffect(() =>{
        if(pvSatrDetails){
            setUploadedFiles(
                (pvSatrDetails.attachments || []).filter((x) => x.section === "Finance").map((y) => ({
                    filesToUpload: {name: y.name},
                    section: y.section || "Finance",
                    id: y.id || 0,
                    docId: y.documentid || 0,
                })) as SATRFiles[]
            );
        }
    },[pvSatrDetails])

    useEffect(() => {
        if(fileInvalidMessage) {
            setShowFileInvalidAlert(true)
        }
    }, [fileInvalidMessage])

    const handleFileDrop=(acceptedFiles: File[], fileRejections: FileRejection[])=>{
        onFilesDropped(acceptedFiles, fileRejections, "Finance" )
    }

    const uploadDocument =(file: File, section: string)=>{
        if(pvsatrAPI && pvSatrDetails)
        {
            pvsatrAPI.attachDocument(companyContext.cid, pvSatrDetails.id, {section: section, fileName: file.name} , {fileToUpload: file})
            .then((res) =>{
                setUploadedFiles((prevFiles) =>
                    prevFiles.map((f) =>
                        f.filesToUpload && f.filesToUpload.name === file.name && f.section === section
                            ? { ...f, id: res.data.id, docId: res.data.documentid }
                            : f
                    )
                );
                if (pvSatrDetails?.progress?.setupFinancialRecords)
                    setShowSavedMessage(true);
            })
            .catch((error) =>{
                console.error(error)
            })
        }
    }

    const onFilesDropped = (acceptedFiles:File[], fileRejections:FileRejection[], section: string) => {
        if(acceptedFiles.length > 0) {
            const newFiles = acceptedFiles.map((file) => ({ filesToUpload: file, section }));
            setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
            acceptedFiles.forEach((file) => uploadDocument(file, section));
            
        }
        if(fileRejections.length > 0){
            setFileInvalidMessage(`${fileRejections.length} file types are not supported, these have been ignored`);
        }
    };

    const handleRemoveFile = (fileName:string) => {

        if(pvSatrDetails?.progress?.setupFinancialRecords && uploadedFiles.filter(x => x.section === "Finance").length === 1){
            showModalDialog(
                'Delete document?',
                `Are you sure you want to delete the last document? This will unconfirm your financial records.`,
                [
                    <Button key="delete" variant="danger" label="Yes" onClick={() => removeFile(fileName, true)} />,
                    <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
                ],
                false
            );
        }
        else{
            removeFile(fileName)
        }
    }

    const removeFile =(fileName: string, resetStage?: boolean)=>{
        const documents = uploadedFiles.filter(x => x.filesToUpload.name=== fileName && x.section === "Finance")

        documents.forEach((doc) =>{
            if(doc.id && pvsatrAPI && pvSatrDetails){
                pvsatrAPI.deleteDocument(companyContext.cid, pvSatrDetails.id, doc.id)
                .then((x) =>{
                    setUploadedFiles((prevFiles) =>
                        prevFiles.filter(
                          (file) => !(file.filesToUpload.name === fileName &&  file.section === "Finance" && file.docId === doc.docId)
                        )
                    );
                    if (pvSatrDetails?.progress?.setupFinancialRecords && !resetStage){
                        setShowSavedMessage(true);
                    }
                    else if(resetStage){
                        resetStep('SetupFinancialRecords')
                    }    
                })
                .catch((error) =>{
                    console.error(error)
                })
                
            }
        }) 
    }

    const handleOnDownloadClick =(docId: number)=>{
        filesAPI?.getTemporaryUrl(companyContext.cid, { docId: docId })
        .then((response) => {
            if(response.ok){
                const link = document.createElement('a');
                link.href = response.data;
                link.target="_blank";
                link.rel="noopener noreferrer";
                link.download = uploadedFiles?.find((x) => x.id === docId)?.filesToUpload.name || 'file.pdf';
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(response.data);
            }
        })
        .catch((error) =>{
            console.error(error)
        })
    }

    const getFilesBySection = ():File[] => {

        return uploadedFiles.filter((file) => file.section === "Finance").map(y => y.filesToUpload);
       
    };

    const confirmFinance = () => {
        completeStep('SetupFinancialRecords')
    }

    const nextPage = () => {
        nextStep('SetupFinancialRecords');
    }



    return (
        <BasicFormLayout title="Financial records" storyblokDescriptionId="finances-upload-page">
            <>  
                { pvSatrDetails?.progress?.setupSubmitted 
                    ?   
                        <>
                            { uploadedFiles.length > 0 && (
                                <Formik
                                    initialValues={uploadedFiles}
                                    enableReinitialize={true}
                                    onSubmit={() => {}}
                                >
                                    {({ values }) => (
                                        <Form>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '500px' }}>File name</th>
                                                        <th style={{ width: '50px '}}>Download</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <FieldArray name="file">
                                                        {() => (
                                                            <>
                                                                {values.map((file, index) => {
                                                                    return (
                                                                    <tr key={`file-${index}`}>
                                                                        
                                                                        <td>
                                                                            <FormikField<File>
                                                                                name={`${index}.filesToUpload.name` as 'name'}
                                                                                type="text"
                                                                                noPadding
                                                                                positionHack
                                                                                readonly
                                                                            />
                                                                        </td>
                                                                        
                                                                        <td className="text-center">
                                                                            <FontAwesomeIcon
                                                                                icon={faDownload}
                                                                                className="standardIcon"
                                                                                size='lg'
                                                                                onClick={() => handleOnDownloadClick(file.docId || 0)}
                                                                            />       
                                                                        </td>
                                                                    </tr>
                                                                )})}
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </tbody>
                                            </table>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </>
                        
                    :
                        <>
                            <div>
                                <Attachments
                                    title='Attach files'
                                    accept={['image/*','.pdf', '.xls', '.xlsx', '.csv', '.doc', '.docx']}
                                    onDrop={handleFileDrop}
                                    message="Please upload your financial records"
                                    attachedFiles={getFilesBySection()}
                                    onRemoveFile={handleRemoveFile}
                                    hidefiles
                                    regularLabel
                                    showError={showFileInvalidAlert}
                                />
                            </div>

                            <PageAlert ignoreSidebar={true} variant="danger" showAlert={showFileInvalidAlert} message={fileInvalidMessage}
                            hideAlert={() => {setShowFileInvalidAlert(false); setTimeout(() => setFileInvalidMessage(undefined), 250)}}/>
                        
                        </>
                        
                }   
                
                { pvSatrDetails?.progress?.setupSubmitted 
                ? (
                    <>
                        <Alert className="mt-5" variant="info">
                            <b>You have completed your tax return. Please contact us if you need to make changes.</b>
                        </Alert>

                        <Button 
                            variant="primary" 
                            onClick={nextPage}
                            marginTop 
                            thin
                        >
                            Continue <Icon name='arrowNext' />
                        </Button>
                    </>
                )
                : (
                    <>
                        { pvSatrDetails?.progress?.setupFinancialRecords
                            ? (
                                <>
                                    <Alert className="mt-5" variant="info">
                                        <b>You have already confirmed your financial records, but you can still make changes.</b>
                                    </Alert>
                                    <Button 
                                        variant="primary" 
                                        onClick={nextPage}
                                        marginTop 
                                        thin
                                    >
                                        Continue <Icon name='arrowNext' />
                                    </Button>
                                </>
                            )
                            : (
                                <div className="mt-3">
                                    <Button
                                        variant="primary"
                                        label="Confirm financial records"
                                        onClick={confirmFinance}
                                        marginTop
                                        disabled={uploadedFiles.length === 0}
                                    />
                                </div>
                            )
                        }
                    </>
                )
            }
            {modalDialog}

            <PageAlert
                ignoreSidebar={true}
                variant="success"
                showAlert={showSavedMessage}
                message="Financial records updated."
                hideAlert={() => { setShowSavedMessage(false) }}
            />
            </>
        </BasicFormLayout>
    )
    
}