import { useContext, useState } from "react"
import { AdjustmentListPage } from "./AdjustmentListPage"
import { AdjustmentReadPage } from "./AdjustmentReadPage"
import CompanyContext, { CompanyProduct } from "../../context/CompanyContext"
import CreateAdjustment from "./component/AdjustmentCreate"

// This is essentially a wrapper for the adjustment summaries and the editor to avoid full page reloads
// setExpandedView is only passed/used on v8
export const Adjustments = ({setExpandedView}: {setExpandedView?: (show: boolean) => void}) => {
    const [adjustmentId, setAdjustmentId] = useState<number|null|undefined>()
    const [addingNewAdjustment, setAddingNewAdjustment] = useState<boolean>(false)

    const companyContext = useContext(CompanyContext);
    const isCAP = companyContext.product === CompanyProduct.isCap;

    const editEntry = (id: number) => {
        if (setExpandedView)
            setExpandedView(true);
        setAdjustmentId(id);
    }

    const onCompletion = () => {
        setAdjustmentId(null);
        setAddingNewAdjustment(false);
        if (setExpandedView)
            setExpandedView(false);
    }

    return (
        (addingNewAdjustment || adjustmentId)
            ? isCAP && ! adjustmentId
                ? <CreateAdjustment isOpen={addingNewAdjustment} hide={() => setAddingNewAdjustment(false)}/>
                : <AdjustmentReadPage id={adjustmentId} onCompletion={onCompletion} /> 
            : <AdjustmentListPage onEditRow={editEntry} onNewRow={() => setAddingNewAdjustment(true)} />
    )     
}

export default Adjustments