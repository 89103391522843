import { useContext, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { Button } from '../../elements/Button/Button'
import { useHasPermission } from '../../hooks/useHasPermission'
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout'
import Toolbar from '../../layouts/Desktop/Toolbar'
import { Action, Entity } from '../../utils/EntityAction'
import AccountList from './Components/AccountList'
import CompanyContext from '../../context/CompanyContext'

const AccountEdit = () => {
    const companyContext = useContext(CompanyContext)
    const [showCreateModal, setShowCreateModal] = useState(false)
    const v8Styling = companyContext.company?.useV8UI || false;
    
    const hasPermission = useHasPermission()

    return (
        <DefaultLayout 
            entity={Entity.Account} 
            title="Accounts"
            subLayout={v8Styling}
            greyBackground
        >
            <Toolbar>
                <Button onClick={() => setShowCreateModal(true)} entity={Entity.Account} action={Action.Create} buttonType="new" />
            </Toolbar>
            { hasPermission(Entity.BookkeepingV8, Action.Create)[0] && 
                <Alert variant="warning">
                    Please note that new accounts for <b>Company Accounts Pro</b> won't appear in the category selector when matching transactions
                </Alert>
            }
            <AccountList showCreateModal={showCreateModal} setShowCreateModal={setShowCreateModal} />
        </DefaultLayout>
    )
}

export default AccountEdit