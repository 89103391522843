import { Form, Formik, FormikErrors, FormikTouched } from 'formik'
import { useContext, useState } from 'react'
import { PayeViaMigrationSetupPostModel, PersonAsDetailed } from '../../../../api/inni/data-contracts'
import { Modal } from '../../../../components/Modal/Modal'
import CompanyContext from '../../../../context/CompanyContext'
import { Currency, DateSelector, ListOption, SelectList, Switch, Text } from '../../../../elements/EditRow/EditRow'
import { useInniAPI } from '../../../../hooks/useInniAPI'
import { payPeriodTypes } from '../../../../utils/commonLists'
import { People } from '../../../../api/inni/People'
import { useValidateFormikPage } from '../../../../hooks/useValidateFormikPage'
import styles from '../../People.module.scss'
import { Button } from '../../../../elements/Button/Button'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatDate } from '../../../../utils/formatDate'

const pageFields : Array<Array<keyof PayeViaMigrationSetupPostModel>> = [
    ['employeeStartDate', 'nino', 'niTable', 'salary', 'studentLoan', 'postGraduateLoan', 'defaultEmployee'],
    ['periodType', 'lastPayPeriod', 'taxCode', 'grossPayToDate', 'totalTaxToDate', 'employerNICToDate', 
    'employeeNICToDate', 'carMiles'] 
]

interface PayeViaMigrationSetupModalProps {
    personId : number, 
    show: boolean, 
    onClose : () => void, 
    reloadPeople : () => void, 
    noPay : boolean, 
    migrationDate?: string, 
    taxYearEndDate?: string,
    person : PersonAsDetailed | undefined
}


const PayeViaMigrationSetupModal = ({personId, show, onClose, reloadPeople, noPay, migrationDate, taxYearEndDate, person} : PayeViaMigrationSetupModalProps) => {

    const companyContext = useContext(CompanyContext)
    const peopleApi = useInniAPI(People, [400])

    const [payPeriodSl] = useState<ListOption[]>(payPeriodTypes.map(x => ({ value: x.value, label: x.label } as ListOption)))

    const [page, setPage] = useState(1)
    const validatePage = useValidateFormikPage<PayeViaMigrationSetupPostModel>()

    // We have two post models one for pay one for w/ no pay the models are the same minus a few extra fields on the pay 
    // they have different validation and save method but we convert the more pay model to the no pay when needed - saves
    // have another component with pretty much the same code
    const validateUpdatePay = (values: PayeViaMigrationSetupPostModel) : Promise<FormikErrors<PayeViaMigrationSetupPostModel>> => {
        return new Promise((resolve) => {
            if (peopleApi) {
                peopleApi.validatePayeViaMigrationSetup(companyContext.cid, personId, values)
                .then(() => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const updateCallPay = (values: PayeViaMigrationSetupPostModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (peopleApi) {
                peopleApi.payeViaMigrationSetup(companyContext.cid, personId, values)
                .then(res => {
                    if (res.status === 200) {
                        reloadPeople()
                        onClose()
                        resolve()
                    }
                }).catch(err => reject(err.error))
            }
        })
    }

    const validateUpdateNoPay = (values: PayeViaMigrationSetupPostModel) : Promise<FormikErrors<PayeViaMigrationSetupPostModel>> => {
        return new Promise((resolve) => {
            if (peopleApi) {
                peopleApi.validatePayeViaMigrationNoPaySetup(companyContext.cid, personId, values)
                .then(() => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const updateCallNoPay = (values: PayeViaMigrationSetupPostModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (peopleApi) {
                peopleApi.payeViaMigrationNoPaySetup(companyContext.cid, personId, values)
                .then(res => {
                    if (res.status === 200) {
                        reloadPeople()
                        onClose()
                        resolve()
                    }
                }).catch(err => reject(err.error))
            }
        })
    }

    const handleClose = () => {
        setPage(1)
        onClose()
    }

    const validateCurPage = async (
        setTouched: (touched: FormikTouched<PayeViaMigrationSetupPostModel>, shouldValidate?: boolean | undefined) => void, 
        validateForm: (values?: any) => Promise<FormikErrors<PayeViaMigrationSetupPostModel>>
    ) => {
        if (await validatePage(pageFields[page-1], setTouched, validateForm)) 
            setPage(page+1)
    }

    return (
        <Modal size="xl" showModal={show} hideModal={handleClose} title="Setup PAYE">
            
            <Formik
                initialValues={{
                    employeeStartDate: "",
                    salary: 0,
                    nino: "",
                    niTable: "",
                    studentLoan: false,
                    postGraduateLoan: false,
                    defaultEmployee: false,
                    taxCode: "",
                    grossPayToDate: 0,
                    totalTaxToDate: 0,
                    employeeNICToDate: 0,
                    employerNICToDate: 0,
                    carMiles: 0,
                    periodType: 'W',
                    lastPayPeriod: 0,
                    payBefore5April: true
                } as PayeViaMigrationSetupPostModel}
                validateOnChange={false}
                onSubmit={noPay ? updateCallNoPay : updateCallPay}
                validate={noPay ? validateUpdateNoPay : validateUpdatePay}
            >
                {({ setTouched, validateForm }) => (

                    <Form>
                    
                        {page === 1 && <>
                            <DateSelector<PayeViaMigrationSetupPostModel> labelTop name="employeeStartDate" />
                            <Currency<PayeViaMigrationSetupPostModel> labelTop name="salary" />
                            <Text<PayeViaMigrationSetupPostModel> labelTop name="nino" label="N.I. number" />
                            <Text<PayeViaMigrationSetupPostModel> labelTop name="niTable" label="N.I. table" />
                            <Switch<PayeViaMigrationSetupPostModel> labelTop name="studentLoan" />
                            <Switch<PayeViaMigrationSetupPostModel> labelTop name="postGraduateLoan" />
                            <Switch<PayeViaMigrationSetupPostModel> labelTop name="defaultEmployee" />
                            {person?.isDirector &&
                                <Switch<PayeViaMigrationSetupPostModel> labelTop name="useAlternateNICal" label="Use alternative NI calculation"/>
                            }                            
                        </>}
                        
                        {noPay ? <>
                            <h4>From Last Payslip before migration date {formatDate(migrationDate || '')}</h4>
                            <p>Employee WAS NOT and WILL NOT BE paid between {formatDate(migrationDate || '')} and {formatDate(taxYearEndDate || '')}</p>
                            <p>No mileage tracking can be done for this period either</p>
                            <p>We will have no history of any prior pay.</p>
                            <p>If you disagree with any of these click the other migration option.</p>
                        </> : 
                        page === 2 && <>
                            <h4>From last payslip before migration date</h4>
                            <Text<PayeViaMigrationSetupPostModel> labelTop name="taxCode" />
                            <Currency<PayeViaMigrationSetupPostModel> labelTop name="grossPayToDate" />
                            <Currency<PayeViaMigrationSetupPostModel> labelTop name="totalTaxToDate" />
                            <Currency<PayeViaMigrationSetupPostModel> labelTop name="employerNICToDate" />
                            <Currency<PayeViaMigrationSetupPostModel> labelTop name="employeeNICToDate" />
                            <Text<PayeViaMigrationSetupPostModel> labelTop name="carMiles" type="number" />
                            <SelectList<PayeViaMigrationSetupPostModel> labelTop name="periodType" options={payPeriodSl} />
                            <Text<PayeViaMigrationSetupPostModel> labelTop name="lastPayPeriod" type="number" />
                        </>}

                        <div className={styles.buttonContainer}>
                            <Button buttonType="cancel" onClick={handleClose} />
                            {page !== 1 && <Button onClick={() => setPage(page-1)} buttonType="back" />}
                            {(page === 1 && !noPay) && 
                                <Button onClick={() => validateCurPage(setTouched, validateForm)} variant="primary">
                                    Continue <FontAwesomeIcon icon={faChevronRight} style={{marginRight: '0px', marginLeft: '0.5em'}} />
                                </Button>}
                            {(page === 2 || (page === 1 && noPay)) && <Button submitButton buttonType="save" />}
                        </div>

                    </Form>
                )}
            </Formik>

        </Modal>
    )

}

export default PayeViaMigrationSetupModal