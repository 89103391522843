/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AutoEnrolment, CompanySummary, SelectlistModel } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Companies<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
 * No description
 * 
 * @tags Companies
 * @name Index
 * @summary Narrow entity listing the companies you have access to
Can't have require feature because that needs a feature list, which without a companyid, you don't have.
 * @request GET:/api/v2/companies
 * @secure
 */
  index = (query?: { q?: string; includeDemo?: boolean }, params: RequestParams = {}) =>
    this.request<CompanySummary[], any>({
      path: `/api/v2/companies`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Companies
   * @name Get
   * @summary Not the full thing, needed for editing by admins etc - it's what you need to load up to be able to use the app on that co. Ends up in the react context
   * @request GET:/api/v2/companies/{companyId}
   * @secure
   */
  get = (companyId: number, params: RequestParams = {}) =>
    this.request<CompanySummary, any>({
      path: `/api/v2/companies/${companyId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Companies
   * @name GetAutoEnrolment
   * @request GET:/api/v2/companies/{companyId}/AutoEnrolment
   * @secure
   */
  getAutoEnrolment = (companyId: number, params: RequestParams = {}) =>
    this.request<AutoEnrolment, any>({
      path: `/api/v2/companies/${companyId}/AutoEnrolment`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Companies
   * @name GetActivePayeYears
   * @request GET:/api/v2/companies/{companyId}/ActivePAYEYears
   * @secure
   */
  getActivePayeYears = (companyId: number, params: RequestParams = {}) =>
    this.request<SelectlistModel[], any>({
      path: `/api/v2/companies/${companyId}/ActivePAYEYears`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Companies
   * @name ActiveCalendarYearsSelectList
   * @request GET:/api/v2/companies/{companyId}/ActiveCalendarYearsSelectList
   * @secure
   */
  activeCalendarYearsSelectList = (companyId: number, params: RequestParams = {}) =>
    this.request<SelectlistModel[], any>({
      path: `/api/v2/companies/${companyId}/ActiveCalendarYearsSelectList`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
