// Some reminders use POST method here we define in theory we could pass a post url but it is better the use swagger instead
import { useContext } from "react"
import { Ir35Assessment } from "../../../api/inni/Ir35Assessment"
import { VatReturns } from "../../../api/inni/VatReturns"
import { YearEnds } from "../../../api/inni/YearEnds"
import CompanyContext from "../../../context/CompanyContext"
import { useInniAPI } from "../../../hooks/useInniAPI"

// return Promise<bool> which is true if the action succeeded then we can reload calander if needed
export const useCalanderPostAction = () => {

    const companyContext = useContext(CompanyContext)
    const yeApi = useInniAPI(YearEnds)
    const vatReturns = useInniAPI(VatReturns)
    const ir35Api = useInniAPI(Ir35Assessment)

    return (command : string) : Promise<boolean> => {
        // we have commands in the form Action-RelatedId-Type
        let splitCommand = command.split('-')

        switch (splitCommand[2]) {
            case 'YearEnd':
                if (splitCommand[0] === "OPEN") {
                    return new Promise<boolean>((resolve, reject) => {
                        yeApi?.open(companyContext.cid).then(res => {
                            if (res.status === 200) {
                                resolve(true)
                            }
                            reject(`Error - Unexpected status code ${res.status}`)
                        }).catch(() => reject("Unexpected error occured when trying to reopen a FY"))
                    })
                } else if (splitCommand[0] === "CLOSE") {
                    return new Promise<boolean>((resolve, reject) => {
                        yeApi?.close(companyContext.cid).then(res => {
                            if (res.status === 200) {
                                resolve(true)
                            }
                            reject(`Error - Unexpected status code ${res.status}`)
                        }).catch(() => reject("Unexpected error occured when trying to close a FY"))
                    })
                }
                break;
            case 'VATReturnHeld':
                // this will only be release since 'View' is now just a HREF action
                return new Promise((resolve, reject) => {
                    vatReturns?.release(companyContext.cid, parseInt(splitCommand[1]))
                    .then(res => {
                        if (res.status === 200) {
                            resolve(true)
                        }
                        reject(`Error - Unexpected status code ${res.status}`)
                    }).catch(() => reject("Unexpected error when trying to hold a VAT Return"))
                })
            case 'VATReturnAwaitingSubmission':
                // same as above 'view' is now a link
                return new Promise((resolve, reject) => {
                    vatReturns?.hold(companyContext.cid, parseInt(splitCommand[1]))
                    .then(res => {
                        if (res.status === 200) {
                            resolve(true)
                        }
                        reject(`Error - Unexpected status code ${res.status}`)
                    }).catch(() => reject("Unexpected error when trying to hold a VAT Return"))
                })
            case 'IR35WPADue':
                return new Promise((resolve, reject) => {
                    ir35Api?.dismissNotification(companyContext.cid, parseInt(splitCommand[1]))
                    .then(res => {
                        if (res.status === 200) {
                            resolve(true)
                        }
                        reject(`Error - Unexpected status code ${res.status}`)
                    }).catch(() => reject("Unexpected error when trying to dismiss notification"))
                })
            default:
                return new Promise<boolean>(resolve => resolve(true))
        }

        return new Promise<boolean>(resolve => resolve(true))
    }
    
}