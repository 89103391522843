// We're using React Context for many things - so why not here?
// Because this config will often be used outside of the React component tree
// e.g. setting up a global instance of App Insights, or API calls, etc
// Conversely this means that it should be rare that components touch this

export enum AuthType {
    inniAccounts = 'ia',
    Provestor = 'pv',
    Admin = 'admin',
    Test = 'test'
}

export enum Brand {
  inniAccounts = 'ia',
  Provestor = 'pv'
}

export enum Environment {
  Production = 'prod',
  Test = 'test',
  Dev = 'dev'
}

export enum Platform {
  WebApp = 'webapp',
  Hub = 'hub',
}

// The INPUT
// The settings we can change in the head tag on the page:
type PageAppConfig = {
  auth: AuthType,
  brand: Brand,
  environment: Environment,   
  apiUrl: string,
  useMobile: boolean,
  platform: Platform,
  externalDomain: string
}

type AppZiConfig = {
  userId: string,
  data: {
    name: string,
    email: string,
    brand: Brand,
    environment: Environment,
    isSaas: boolean,
    companyId: number,
    companyName: string,
    companyCreatedDate: string
  }
}

// 'Casting' of sorts, of window.appConfig
declare global {
    interface Window {
      appConfig: PageAppConfig;
      appziSettings?: AppZiConfig;
    }
  }
  
// The OUTPUT
// The expanded list of config settings
export type AppConfig = {
  auth: AuthType,
  brand: Brand,
  environment: Environment,     
  isProduction: boolean,
  apiUrl: string, 
  logRocketKey?: string,
  appInsightsKey?: string,
  useMobile: boolean,
  platform: Platform,
  externalDomain: string
}

function buildConfig():AppConfig {
  const settings = window.appConfig || {};
  const isProduction = settings.environment === Environment.Production;

  const config:AppConfig ={
    auth: settings.auth,
    brand: settings.brand,
    environment: settings.environment,
    isProduction: isProduction,
    apiUrl: settings.apiUrl,
    logRocketKey: isProduction ? 'todo' : undefined,
    appInsightsKey: isProduction ? 'todo' : undefined,
    useMobile: settings.useMobile,
    platform: settings.platform,
    externalDomain: settings.externalDomain
  }

  return config
}

export default buildConfig();