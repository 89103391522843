import { faSearch, faChevronDown, faLevelDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, useRef, useState, useCallback } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { Category } from '../../../api/inni/data-contracts';
import styles from '../CategorySelector.module.scss'
import { getAutocompleteSuggestion } from '../categorySelectorUtilsAndFormatters';

interface SearchProps {
    focused: boolean, 
    expandClicked: boolean, 
    setSearch: (i: string) => void, 
    search: string, 
    setExpandClicked: (i: boolean) => void, 
    setFocused: (i: boolean) => void,
    categories?: Category[]
    allowFocusHandler: boolean,
    checkAutoComplete: (i: string) => void,
    placeholder: string,
    useV8?: boolean,
    selectMatchBySearch?: () => void,
    enterSelectsCategory?: () => boolean
}

const hideFirst = (string : string, size : number) => {
    return (size < string.length)
        ? "".repeat(size) + string.substring(size)
        : "".repeat(string.length);               
}

const SearchInput = ({
    focused, 
    expandClicked, 
    setSearch, 
    search, 
    setExpandClicked, 
    setFocused, 
    categories,
    allowFocusHandler,
    checkAutoComplete,
    placeholder='Search...',
    useV8,
    selectMatchBySearch,
    enterSelectsCategory

} : SearchProps) => {

    const [autoComplete, setAutocomplete] = useState<string | undefined>(undefined)

    const ref = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (categories) {
            setAutocomplete(getAutocompleteSuggestion(categories, search))
        }
    }, [categories, search])

    // Focus on keypress for convenience, but only when no category selected
    const keyHandler = useCallback((e: KeyboardEvent) => {
        if (allowFocusHandler && ref.current)
            ref.current.focus();
    }, [allowFocusHandler])

    useEffect(() => {
        const listener = (e: KeyboardEvent) => keyHandler(e);
        document.addEventListener('keydown', listener);
        return () => document.removeEventListener('keydown', listener);
    }, [keyHandler])

    // Remove focus when finished, important when auto completing
    useEffect(() => {
        if (!focused && ref.current)
            ref.current.blur();
    }, [focused])

    const checkKey = (key: string) => {
        if (key === 'Enter') {
            if(categories && selectMatchBySearch && search.length >= 3) {
                if(enterSelectsCategory && enterSelectsCategory()) {
                    selectMatchBySearch()
                }
                else {
                    checkAutoComplete(autoComplete || '');
                }
            }
            else {
                checkAutoComplete(autoComplete || '');
            }
        }
        else if (key === 'Escape')
            setSearch('')
    }

    // We need to store the current state of the dropdown (focused or unfocused)
    // so we can apply css to the inputs siblings
    const onFocus = () => {setFocused(true); setExpandClicked(true);}
    const onBlur = () => { 
        if (search.length === 0) setFocused(false) 
    }

    return (
        <InputGroup className={classNames(styles.inputGroup, {[styles.v8SearchInput] : useV8})}>
            <InputGroup.Text 
                onClick={() => { if (ref.current !== null) ref.current.focus() }} 
                className={classNames(styles.inputPrefix, {[styles.focused] : focused || expandClicked})}
            >
                <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>

            {/* Autocomplete - the input grows as the length of the text increases, the autocomplete text moves to the right */}
            {/* and the letters get removed based on the length of search */}
            <FormControl 
                value={search} 
                onChange={(e) => setSearch(e.target.value)} 
                ref={ref} 
                onFocus={onFocus} 
                onBlur={onBlur}
                className={classNames(styles.formControl, {[styles.focused] : focused || expandClicked}, styles.searchInput)} 
                placeholder={placeholder ? placeholder : 'Search...'}
                htmlSize={search.length === 0 ? placeholder?.length + 1 : search.length + 2}
                onKeyUp={(e: { key: string; }) => {checkKey(e.key)}}
                maxLength={37}
                data-cy="categorySearch"
            />
             <InputGroup.Text 
                onClick={() => { if (ref.current !== null) ref.current.focus() }} 
                className={classNames(styles.autocomplete, {[styles.focused] : focused || expandClicked})}
            >
                {/* autocomplete */}
                { (search.length >= 3) && hideFirst(autoComplete?.replace(/ /g, "\u00a0") || '', search.length) }

                {/* enter icon */}
                { (search.length >= 3 && autoComplete && autoComplete?.length !== 0 && search.toLowerCase() !== autoComplete.toLowerCase()) &&
                    <div className={styles.enterPrompt}>Enter<FontAwesomeIcon rotation={90} icon={faLevelDown} /></div>
                }
            </InputGroup.Text>

            {/* Dropdown arrow */}
            <InputGroup.Text 
                onClick={() => {setExpandClicked(!expandClicked); if (search.length > 0) setFocused(false)}} 
                className={classNames(styles.inputSuffix, {[styles.focused] : focused || expandClicked})}
            >
                <FontAwesomeIcon rotation={expandClicked || focused ? 180 : undefined} icon={faChevronDown} />
            </InputGroup.Text>
        </InputGroup>
    )
}

export default SearchInput
