import { FormikHelpers, FormikErrors } from "formik"
import { useCallback, useContext, useEffect, useState } from "react"
import { Account, Asset, BankPaymentDisplayModel, BankTransaction, BankTransactionAsDetailed, BankTransactionPostModel, Contract, ExpenseAsSuggestion, Property, VatSetting } from "../../../api/inni/data-contracts"
import { GroupedListOption, ListOption } from "../../../elements/EditRow/EditRow"
import { FormikRowLogic, FormikRowInput, FormikRowSubmitBtns, FormikRowFieldTypes } from "../../../elements/FormikTableEditor/FormikTableEditor"
import { getPaymentTypeLong, ProcessorType, GetBankingFields, getPaymentTypeShort } from "../BankingTypes"
import getCurrencySymbol from "../../../utils/currencySymbols"
import { useInniAPI } from "../../../hooks/useInniAPI"
import { VatSettings } from "../../../api/inni/VatSettings"
import CompanyContext from "../../../context/CompanyContext"
import { Expenses } from "../../../api/inni/Expenses"
import { BankTransactions } from "../../../api/inni/BankTransactions"
import { accountsLettingAgentTrade, accountsBank, accountsBankAndManualExpenses, accountsExpenditurePersonal, accountsIncome} from "../../../utils/accountsFilterHelper"
import SuggestionContainer from "../../AccountViewer/SuggestionContainer"
import { useFetchEntityList } from "../../../hooks/entities/useFetchEntityList"
import { Contracts } from "../../../api/inni/Contracts"
import BrandContext from "../../../context/BrandContext"
import { Properties } from "../../../api/inni/Properties"
import provestorBrand from "../../BrandWrapper/pvBrand"
import _ from "lodash"
import { isAfter } from "date-fns"
import InfoBanner from "../../../elements/InfoBanner/InfoBanner"
import styles from "./EditorModal.module.css"
import classNames from "classnames"
import { Modal } from "../../Modal/Modal"
import { Button } from "../../../elements/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTv, faArrowDown } from "@fortawesome/pro-regular-svg-icons"
import { Entity, Action } from "../../../utils/EntityAction"
import { getViewButtonLabel } from "./FormatViewButton"
import { LoadingTextInput } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder'
import { formatCurrency } from "../../../utils/formatNumbers"
import { asYearMonthDay } from "../../../utils/formatDate"

interface BankPaymentModalEditorProps {
    showModal: boolean,
    afterCreate: (pendingPaymentId?: number, type?: string, date?: string, reloadAssets?: boolean) => void,
    afterUpdate: () => void,
    closeEditor: () => void,
    paymentType?: string,
    bankPaymentToEdit?: BankTransactionAsDetailed,
    bankTransaction?: BankTransaction
    pendingPaymentToAdd?: BankPaymentDisplayModel,
    id?: number,
    assetToSell?: Asset,
    currency?: string,
    isQuickEntry?: boolean,
    employeeId?: number,
    vatSetting?: VatSetting,
    strictReconciliation: boolean,
    bankAccountId: number,
    accounts: Account[],
    deleteBankPaymentDialog: (payment: BankTransaction) => void,
    setUploadingModal : (i : BankTransaction) => void,
    setReceiptModal : (i : BankTransaction) => void,
    showAssetConvertConfirm: (txn: BankTransaction) => void,
    maxAssetValue : number | undefined,
    isBankFeedAndNotDetached?: boolean,
    BALatestConfirmedTransaction?: string
}

export const BankPaymentModalEditor = ({
    showModal,
    closeEditor, 
    afterCreate, 
    afterUpdate, 
    paymentType, 
    bankPaymentToEdit, 
    bankTransaction, 
    pendingPaymentToAdd, 
    id, 
    assetToSell, 
    currency, 
    isQuickEntry, 
    employeeId, 
    vatSetting, 
    strictReconciliation,
    bankAccountId,
    accounts,
    deleteBankPaymentDialog,
    setUploadingModal,
    setReceiptModal,
    showAssetConvertConfirm,
    maxAssetValue,
    isBankFeedAndNotDetached,
    BALatestConfirmedTransaction
} : BankPaymentModalEditorProps) => {
    //General networking and data
    const companyContext = useContext(CompanyContext)
    const expensesAPI = useInniAPI(Expenses)
    const bookkeepingApi = useInniAPI(BankTransactions, [400]);
    const brandContext = useContext(BrandContext)
    const propertiesApi = useInniAPI(Properties)
    const vatSettingsAPI = useInniAPI(VatSettings, [403])

    //Prepare editor values
    const paymentTypeLong = getPaymentTypeLong(paymentType || "")
    const bankingFields = GetBankingFields(ProcessorType[paymentTypeLong as keyof typeof ProcessorType])
    const newRow = {date: '', amount: 0, description: '', accountId: 0, type: paymentType} as BankTransactionPostModel

    //=========VAT=========//
    const [vatRates, setVatRates] = useState<ListOption[]>([])
    const [vatRatesLoaded, setVatRatesLoaded] = useState(false)

    const [allVatSettings, setAllVatSettings] = useState<VatSetting[] | undefined>(undefined)
    const [allVatSettingsLoaded, setAllVatSettingsLoaded] = useState(false)

    useEffect(() => {
        if (vatSettingsAPI && !allVatSettingsLoaded) {
            vatSettingsAPI.index(companyContext.cid).then(res => {
                setAllVatSettings(res.data)
                setAllVatSettingsLoaded(true)
            }).catch(err => {
                setAllVatSettings([])
                setAllVatSettingsLoaded(true)
            })
        }
    }, [allVatSettingsLoaded, companyContext.cid, vatSettingsAPI])

    useEffect(() => {
        if (!vatRatesLoaded && expensesAPI) {
            expensesAPI.vatRates(companyContext.cid).then(res => {
                let options : ListOption[] = []
                res.data.forEach(x => {
                    options.push({value: x.id, label: x.name || x.id})
                })
                setVatRates(options)
                setVatRatesLoaded(true)
            })
        }
    },[companyContext.cid, expensesAPI, vatRatesLoaded])
    //=====================//

    //====Bank payments====//
    const createBankPayment = (values: BankTransactionPostModel, actions: FormikHelpers<BankTransactionPostModel>) : Promise<number> => {
        return new Promise((resolve, reject) => {
            if (bookkeepingApi) {
                values.bankAccountId = bankAccountId
                if(pendingPaymentToAdd && pendingPaymentToAdd.isRefund) {
                    values.isRefund = true
                }
                bookkeepingApi.createBankPayment(companyContext.cid, isQuickEntry || false, values)
                .then(res => {                   
                    if(res.status === 201) {
                        resolve(parseInt(res.data))
                        afterCreate(pendingPaymentToAdd?.relatedId, bankPaymentToEdit?.type, values.date, assetToSell !== undefined || values.type === getPaymentTypeShort(ProcessorType.assetPurchase))
                    } else {
                        console.error(`Unexpected response code: ${res.status}`)
                        reject()
                    }
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } 
            else {
                reject();
            }
        })
    }

    const isForeignCurrency = bankPaymentToEdit ? bankPaymentToEdit.sourceCurrency !== bankPaymentToEdit.bankAccountCurrency : 
                        pendingPaymentToAdd ? pendingPaymentToAdd.sourceCurrency !== pendingPaymentToAdd.bankAccountCurrency : 
                        false

    const updateBankPayment = (values: BankTransactionPostModel, actions: FormikHelpers<BankTransactionPostModel>, id:number) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (bookkeepingApi) {
                if(!isForeignCurrency) {
                    //If we're not using foreign currency we need to update the source amount here as it's not shown in the UI
                    values.amountInSourceCurrency = values.amount
                }
                bookkeepingApi.updateBankPayment(companyContext.cid, id, values)
                .then(res => {                    
                    if(res.status === 200) {
                        resolve(true);
                        afterUpdate()
                    } else {
                        console.error(`Unexpected response code: ${res.status}`)
                        reject()
                    }
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } 
            else {
                reject();
            }
        })
    }

    const validateCreateBp = (values: BankTransactionPostModel) : Promise<FormikErrors<BankTransactionPostModel>> => {
        return new Promise((resolve, reject) => {
            values.bankAccountId = bankAccountId
            if (bookkeepingApi) {
                return bookkeepingApi.validateCreate(companyContext.cid, values)
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }

    const validateUpdateBp = (id: number, values: BankTransactionPostModel) : Promise<FormikErrors<BankTransactionPostModel>> => {
        return new Promise((resolve, reject) => {
            values.bankAccountId = bankAccountId
            if (bookkeepingApi) {
                return bookkeepingApi.validateCreate(companyContext.cid, values)
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }
    //=====================//

    //=======Accounts======//
    const [accountOptions, setAccountOptions] = useState<GroupedListOption[]>([])
    
    useEffect(() => {
        let tempOptions: Array<GroupedListOption> = []
        let dropDownAccounts: Array<Account> = []
        switch(paymentTypeLong) {
            case ProcessorType.recurringPayment:
            case ProcessorType.expense:
            case ProcessorType.refund:
                dropDownAccounts = accountsExpenditurePersonal(accounts)
                break;
            case ProcessorType.otherIncome:
                dropDownAccounts = accountsIncome(accounts)
                break;
            case ProcessorType.recurringTransfer:
            case ProcessorType.creditCardPayment:
                dropDownAccounts = accountsBank(accounts)
                break;
            case ProcessorType.transfer:
                dropDownAccounts = accountsBankAndManualExpenses(accounts)
                break;
            case ProcessorType.lettingReceipt:
            case ProcessorType.lettingPayment:
                dropDownAccounts = accountsLettingAgentTrade(accounts)
                break;
        }

        dropDownAccounts.forEach(i => {
            const exists = tempOptions.findIndex(x => x.label === i.accountSubGroupName)
            if (exists === -1) {
                tempOptions.push({
                    label: i.accountSubGroupName || '',
                    options: [{value: i.id.toString(), label: i.name || ''}]
                })
            } else {
                tempOptions[exists].options?.push({value: i.id.toString(), label: i.name || ''})
            }
        })
        tempOptions.length > 0 && setAccountOptions(tempOptions)
    }, [accounts, paymentTypeLong])

    //=====================//

    //=Expense suggestions=//
    const [suggestionsLoaded, setSuggestionsLoaded] = useState(false)
    const [suggestions, setSuggestions] = useState<ExpenseAsSuggestion[] | undefined>(undefined)
    const [showSuggestions, setShowSuggestions] = useState(false)

    useEffect(() => {
        if (expensesAPI && !suggestionsLoaded && employeeId) {
            expensesAPI.newSuggestions(companyContext.cid, {employeeId : employeeId}).then(res => {
                setSuggestions(res.data)
                setSuggestionsLoaded(true)
            })
        }
    }, [companyContext.cid, employeeId, expensesAPI, suggestionsLoaded])

    const setAutocompleteValues = (index : number) => {
        if (suggestions) {
            let suggestion = suggestions[index]
            formikBankPayment.setFieldValue('description', suggestion.description)
            formikBankPayment.setFieldValue('payee', suggestion.payee || '')
            formikBankPayment.setFieldValue('vatCode', suggestion.vatCode || '')
            formikBankPayment.setFieldValue('refNo', suggestion.refNo || '')
            formikBankPayment.setFieldValue('contractId', suggestion.contractId)
            formikBankPayment.setFieldValue('accountId', suggestion.accountId)
            formikBankPayment.setFieldValue('isBillable', suggestion.isBillable)
        }
    }
    //=====================//

    //=====Properties======//
    const [properties, setProperties] = useState<Property[] | undefined>(undefined)
    const [propertiesLoaded, setPropertiesLoaded] = useState(false)
    
    useEffect(() => {
        if (brandContext.brand === provestorBrand) {
            if (propertiesApi && !propertiesLoaded) {
                propertiesApi.index(companyContext.cid)
                .then(res => {
                    setProperties(res.data)
                    setPropertiesLoaded(true)
                })
            }
        } else {
            setPropertiesLoaded(true)
        }
    }, [brandContext.brand, companyContext.cid, propertiesApi, propertiesLoaded])
    
    //=====================//

    //======Contracts======//
    const [contracts, contractsLoaded] = useFetchEntityList<Contract, Contracts>(Contracts)
    const [contractOptions, setContractOptions] = useState<ListOption[]>([])

    useEffect(() => {
        if (contractsLoaded && contracts && propertiesLoaded) {
            let options : ListOption[] = []
            options.push({value: '', label: 'None'})
            if (properties)   {
                contracts.forEach(x => {                    
                    options.push({ value: x.id.toString(), label: x.contractSubType === "Tenancy" ? properties.find(y => y.id === x.propertyId)?.name + " / " + x.name : x.name || '' }) //(All, active or not) Do for now. Ideally not VERY OLD tenancies. but past is OK
                })
            } else {
                contracts.filter(y => y.status === "Active" || (bankPaymentToEdit && y.id === bankPaymentToEdit.contractID)).forEach(x => {
                    options.push({ value: x.id.toString(), label: x.name || '' })
                })
            }
            setContractOptions(options)
        }        
    }, [contracts, contractsLoaded, propertiesLoaded, properties, bankPaymentToEdit])

    //=====================//

    const formikBankPayment = FormikRowLogic<BankTransactionPostModel>({
        editRowValues:newRow,
        validateUpdate: validateUpdateBp,
        validateCreate: validateCreateBp,
        onCreate: createBankPayment,
        onUpdate: updateBankPayment,
        id: id,
        addingNewRow: id === undefined})

    const selectCurrency = (useSourceCurrency?: boolean) => {
        if(paymentTypeLong === ProcessorType.salesInvoice && bankPaymentToEdit) return useSourceCurrency ? bankPaymentToEdit.sourceCurrency || "" : bankPaymentToEdit.bankAccountCurrency || ""
        if(paymentTypeLong === ProcessorType.salesInvoice && pendingPaymentToAdd) return useSourceCurrency ? pendingPaymentToAdd.sourceCurrency || "" : pendingPaymentToAdd.bankAccountCurrency || ""
        return currency || ""
    }

    //Editor setup, prepopulate fields, etc
    const [canEditAmountInSource, setCanEditAmountInSource] = useState(false)
    const setFullyPaid = () => {
        if(canEditAmountInSource) {
            //Subtly different fields are used if editing existing
            if(bankPaymentToEdit) {
                formikBankPayment.setFieldValue("amountInSourceCurrency", bankPaymentToEdit.fullAmountInSourceCurrency)
            }
            else if(pendingPaymentToAdd) {
                formikBankPayment.setFieldValue("amountInSourceCurrency", pendingPaymentToAdd.amountInSourceCurrency)
            }
        }

        setCanEditAmountInSource(!canEditAmountInSource)
    }

    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        if(!loaded) {
            if(pendingPaymentToAdd) {    
                bankingFields.payee && formikBankPayment.setFieldValue("payee", pendingPaymentToAdd.payee)
                bankingFields.description && formikBankPayment.setFieldValue("description", pendingPaymentToAdd.description)
                bankingFields.amount && formikBankPayment.setFieldValue("amount", pendingPaymentToAdd.amount)
                bankingFields.matches && !isBankFeedAndNotDetached && formikBankPayment.setFieldValue("isConfirmed", true)
                bankingFields.ref && formikBankPayment.setFieldValue("refNo", pendingPaymentToAdd.refNo)
    
                formikBankPayment.setFieldValue("relatedId", pendingPaymentToAdd.relatedId)
                pendingPaymentToAdd.accountId && formikBankPayment.setFieldValue("accountId", pendingPaymentToAdd.accountId)
                bankingFields.billable && formikBankPayment.setFieldValue("isBillable", pendingPaymentToAdd.billable)
                bankingFields.contract && formikBankPayment.setFieldValue("contractId", pendingPaymentToAdd.contractID)
    
                formikBankPayment.setFieldValue("vatCode", pendingPaymentToAdd.vatCode)
                if(!pendingPaymentToAdd.vatCode) formikBankPayment.setFieldValue("vatCode", "N")
                if((paymentTypeLong === ProcessorType.salesInvoice || paymentTypeLong === ProcessorType.creditNote) && 
                        pendingPaymentToAdd.bankAccountCurrency !== pendingPaymentToAdd.sourceCurrency) {
                    formikBankPayment.setFieldValue("amountInSourceCurrency", pendingPaymentToAdd.amountInSourceCurrency)
                }
            }else if(paymentTypeLong === ProcessorType.lettingReceipt || paymentTypeLong === ProcessorType.lettingPayment){
                formikBankPayment.setFieldValue("vatCode", "N")
            }else {
                formikBankPayment.setFieldValue("vatCode", "S")
            }
        
            if(bankPaymentToEdit && bankTransaction) {
                formikBankPayment.setValues({...bankPaymentToEdit, date: bankTransaction.date, bankAccountId: bankTransaction.bankAccountId,
                    isBillable: bankPaymentToEdit.billable, employeeId: employeeId || 0, contractId: bankPaymentToEdit.contractID,
                    type: bankTransaction.type || '', isReplacementItem: false, increasesPropertyValue: false
                })
                if(paymentTypeLong === ProcessorType.salesInvoice) {
                    formikBankPayment.setFieldValue("amountInSourceCurrency", bankPaymentToEdit.amountInSourceCurrency)
                    setCanEditAmountInSource(!bankPaymentToEdit.isFullyPaid || false)
                }
            }
    
            if(assetToSell && paymentTypeLong === ProcessorType.assetSale) {
                formikBankPayment.setFieldValue("description", `Sale of ${assetToSell.description}`)
                formikBankPayment.setFieldValue("amount", assetToSell.currentValue)
            }
    
            if([ProcessorType.otherIncome, ProcessorType.expense, ProcessorType.refund, ProcessorType.assetSale, ProcessorType.assetPurchase, ""].includes(paymentTypeLong)) {
                if(pendingPaymentToAdd) {
                    formikBankPayment.setFieldValue("relatedId", pendingPaymentToAdd.relatedId)
                }
                else if(assetToSell) {
                    formikBankPayment.setFieldValue("relatedId", assetToSell.id)
                }
            }
    
            if (paymentTypeLong !== ProcessorType.transfer && paymentTypeLong !== ProcessorType.recurringTransfer && id === undefined && (isQuickEntry || !isBankFeedAndNotDetached)) formikBankPayment.setFieldValue("isConfirmed", true)

            if(bankPaymentToEdit || pendingPaymentToAdd || assetToSell || !id) {
                setLoaded(true)
            }
        }
    }, [bankPaymentToEdit, pendingPaymentToAdd, assetToSell, id, bankingFields, bankTransaction, employeeId, formikBankPayment, loaded, paymentTypeLong])

    const handleSubmit = () => {
        formikBankPayment.setFieldValue('type', paymentType)
        if(!formikBankPayment.isSubmitting) formikBankPayment.submitForm().then((newIdOrSuccess:number|void|boolean) => {
            if(newIdOrSuccess !== undefined && newIdOrSuccess !== false) {
                closeEditor()
            }
        })
    }
    
    const buildFormikFieldBp = (
        fieldName:keyof BankTransactionPostModel, 
        name:string, 
        type:FormikRowFieldTypes, 
        readOnly?:boolean, 
        inputProps?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
        useSourceCurrency? : boolean
    ) => {
        const isQuickEntryExpenseDescription = fieldName === "description" && paymentTypeLong === ProcessorType.expense && isQuickEntry
        const isLoading = !contractsLoaded || !vatRatesLoaded || (id !== undefined && (!bankPaymentToEdit || !bankTransaction))
        return <div data-cy="editorField" className={styles.editorField}>
            <label>{name}</label>
            {isLoading && type !== "check" ? <LoadingTextInput /> :
            <FormikRowInput<BankTransactionPostModel>
                formik={formikBankPayment}
                property={fieldName}
                type={type} 
                prefix={type === "number" && getCurrencySymbol(selectCurrency(useSourceCurrency))} 
                groupedOptions={type === 'groupedselect' ? accountOptions : undefined} 
                options={type === 'select' && fieldName === "vatCode" ? vatRates : type === 'select' && fieldName === "contractId" ? contractOptions : undefined}
                disabled={readOnly || isLoading}
                inputProps={inputProps}
                autoComplete={!isQuickEntryExpenseDescription}
                autoFocus={isQuickEntryExpenseDescription}
                onFocusFnc={isQuickEntryExpenseDescription ? () => setShowSuggestions(true) : undefined} 
                onBlurFnc={isQuickEntryExpenseDescription ? () => setTimeout(() => setShowSuggestions(false), 200) : undefined}
                />}

            {isQuickEntryExpenseDescription && showSuggestions && suggestions && !id && <SuggestionContainer suggestions={suggestions} setVals={setAutocompleteValues} />}
        </div>
    }

    const isReadOnly = bankPaymentToEdit && bankPaymentToEdit.editability ? !bankPaymentToEdit.editability.canEdit : bankPaymentToEdit ? bankPaymentToEdit.readOnly : false

    const isReadOnlyField = (oldField: keyof BankTransactionAsDetailed | keyof BankPaymentDisplayModel, newField: string) => {
        if (isReadOnly) return true

        if (bankPaymentToEdit) {
            if(bankPaymentToEdit.editability) {
                return bankPaymentToEdit.editability.readOnlyFields && bankPaymentToEdit.editability.readOnlyFields[newField] !== undefined
                //e.g description (Not canChangeDescription, which is here for now for backward compatibility)
            }
            return bankPaymentToEdit[oldField as keyof BankTransactionAsDetailed] === false //e.g canChangeDescription
        }
        
        if (pendingPaymentToAdd) {
            if(pendingPaymentToAdd.editability) {
                return pendingPaymentToAdd.editability.readOnlyFields && pendingPaymentToAdd.editability.readOnlyFields[newField] !== undefined
            }
            return pendingPaymentToAdd[oldField as keyof BankPaymentDisplayModel] === false
        }

        return false
    }

    //=====Default VAT Code=====//
    const [showCT61Warning, setShowCT61Warning] = useState<boolean>(false);
    const [currentAccountId, setCurrentAccountId] = useState<number|undefined>()
    // Set's default VAT code on accountId change
    useEffect(() => {
        if(currentAccountId !== formikBankPayment.values.accountId) {
            let account = accounts?.find(x => Number(formikBankPayment.values.accountId) === x.id)
            if (account?.defaultVatCode && account?.defaultVatCode !== formikBankPayment.values.vatCode) {
                formikBankPayment.setFieldValue('vatCode', account?.defaultVatCode)
            }
            if(account?.linkedId === "InterestIndividual" && id === undefined) {
                setShowCT61Warning(true)
            } else {
                showCT61Warning && setShowCT61Warning(false)
            }
            setCurrentAccountId(formikBankPayment.values.accountId)
        }
    }, [formikBankPayment, accounts, currentAccountId, id, showCT61Warning])

    //=====Default contract billable=====//
    const [billableEnabled, setBillableEnabled] = useState<boolean>(false)
    const [currentContractId, setCurrentContractId] = useState<number|undefined>()
    useEffect(() => {
        if(currentContractId !== formikBankPayment.values.contractId) {
            let contract = contracts?.find(x => Number(formikBankPayment.values.contractId) === x.id)
            if (contract) {
                // if the contract subtype is property it will always be false
                if (contract.contractSubType === "Property") {
                    formikBankPayment.setFieldValue('isBillable', false)
                } else if(bankPaymentToEdit && bankPaymentToEdit.contractID === contract.id) {
                    formikBankPayment.setFieldValue('isBillable', bankPaymentToEdit.billable)
                } else {
                    formikBankPayment.setFieldValue('isBillable', contract.billByDefault)
                }
                if(contract.canRebillExpenseToClient && !(contract.contractSubType === "Property")) {
                    setBillableEnabled(true)
                } else {
                    setBillableEnabled(false)
                }
            } else {
                formikBankPayment.setFieldValue('isBillable', false)
                setBillableEnabled(false)
            }
            contractsLoaded && setCurrentContractId(formikBankPayment.values.contractId)
        }
    }, [formikBankPayment, contracts, bankPaymentToEdit, currentContractId, contractsLoaded])

    //===MinTxnDate===//

    const [minTxnDate, setMinTxnDate] = useState<Date>(new Date())

    const getMinTxnDate = useCallback(() => {
        if (allVatSettingsLoaded && allVatSettings) {
            if (allVatSettings?.length > 0) {
                let fed = _.sortBy(allVatSettings, 'effectiveDate').reverse().pop()?.effectiveDate
                let d = isAfter(new Date(companyContext.company?.currentFinancialYearStartDate || ''), new Date(fed || '')) ? new Date(companyContext.company?.currentFinancialYearStartDate || '') : new Date(fed || '')
                if (strictReconciliation && BALatestConfirmedTransaction) {
                    return isAfter(d, new Date(BALatestConfirmedTransaction || '')) ? d : new Date(BALatestConfirmedTransaction || '')
                }
                return d;
            } else {
                return new Date(companyContext.company?.currentFinancialYearStartDate || '')
            }   
        }
        return new Date()
    }, [BALatestConfirmedTransaction, allVatSettings, allVatSettingsLoaded, companyContext.company?.currentFinancialYearStartDate, strictReconciliation])

    useEffect(() => {
        setMinTxnDate(getMinTxnDate())
    }, [allVatSettings, allVatSettingsLoaded, getMinTxnDate])

    const ct61Warning = <InfoBanner 
        title="Interest paid to an individual" 
        body={<>
            <p>Note that interest paid on a loan supplied by an individual to a limited company should be paid net of basic rate income tax. The tax deduction should be paid, and reported to HMRC directly via a CT61 form.</p>
            <a href="https://www.gov.uk/government/publications/corporation-tax-return-of-income-tax-on-company-payments-ct61" target="_new">https://www.gov.uk/government/publications/corporation-tax-return-of-income-tax-on-company-payments-ct61</a>
        </>} 
        type="warning"
    />

    const buildAmountTitle = (useSourceCurrency?: boolean) => {
        if(paymentTypeLong === ProcessorType.salesInvoice) {
            if(bankPaymentToEdit && bankPaymentToEdit.sourceCurrency !== bankPaymentToEdit.bankAccountCurrency)
                return `Amount (${useSourceCurrency ? bankPaymentToEdit.sourceCurrencySymbol : bankPaymentToEdit.bankAccountCurrencySymbol})`
            if(pendingPaymentToAdd && pendingPaymentToAdd.sourceCurrency !== pendingPaymentToAdd.bankAccountCurrency)
                return `Amount (${useSourceCurrency ? pendingPaymentToAdd.sourceCurrencySymbol : pendingPaymentToAdd.bankAccountCurrencySymbol})`
        }
        return "Amount"
    }

    const modalButtons = () => {
        //TODO - Disable save button while loading. It doesn't let you save anything blank in the API but we should still disable it
        const buttons = [<FormikRowSubmitBtns small={false} handleSubmit={handleSubmit} handleClose={closeEditor} key="submit"/>]

        if(bankTransaction?.hasImage) {
            buttons.push(<Button buttonType="viewReceipt" onClick={() => setReceiptModal(bankTransaction)} key="viewReceipt"/>)
        }
        else if(bankTransaction?.hasLink) {
            buttons.push(<a href={bankTransaction.getLink}><Button label={getViewButtonLabel(bankTransaction.type || '')} buttonType="view" key="viewDocument"/></a>)
        }
        else {
            bankTransaction && buttons.push(<Button buttonType="upload" onClick={() => setUploadingModal(bankTransaction)} key="upload"/>)
        }

        if(bankPaymentToEdit?.canDelete && bankTransaction)
            buttons.push(<Button buttonType="delete" onClick={() => deleteBankPaymentDialog(bankTransaction)} key="delete"/>)

        if(bankTransaction?.type === "EX" && bankTransaction?.amount >= 200)
            buttons.push(<Button onClick={() => showAssetConvertConfirm(bankTransaction)} entity={Entity.Company} action={Action.Edit} variant="primary" key="convert">
            <span className="fa-layers">
                <FontAwesomeIcon style={{ width: '14px', height: '14px' }} icon={faTv} />
                <FontAwesomeIcon style={{ width: '14px', height: '14px' }} icon={faArrowDown} transform="shrink-10 up-1.5" />
            </span>
            Convert to asset
        </Button>)

        return buttons
    }

    return <Modal size="lg" restoreFocus={false} showModal={showModal} hideModal={closeEditor} footerChildren={showCT61Warning ? ct61Warning : undefined} title={id === undefined ? "New transaction" : "Edit transaction"} buttons={modalButtons()}>
        {!isReadOnly && paymentTypeLong === ProcessorType.assetPurchase && 
            <InfoBanner type="info"
                body={<>If the asset is valued at less than £200, record it as an expense, rather than an asset. <a rel="noreferrer" style={{textDecoration: "underline"}} 
                        href={brandContext.brand !== provestorBrand ? 'https://www.inniaccounts.co.uk/guide/assets/' : "https://knowledge.provestor.co.uk/portal/en/kb/articles/recording-assets-paid-with-personal-money"} 
                        target='_new'>Read more.</a>
                        {maxAssetValue ? ` If the asset is valued at over ${formatCurrency(maxAssetValue)}, speak to your account manager.` : ''}
                    </>}
            />
        }   

        {(isReadOnly || (bankPaymentToEdit && bankPaymentToEdit.readOnlyReason)) && 
            <InfoBanner type="warning"
                body={<><b>You cannot edit this item:</b> <span>{(bankPaymentToEdit && bankPaymentToEdit.editability && bankPaymentToEdit.editability.cantEditReason) ? 
                    bankPaymentToEdit.editability.cantEditReason : bankPaymentToEdit ? bankPaymentToEdit.readOnlyReason : ""}</span></>}
            />
        }
        <div className={styles.editorRow}>
            {bankingFields.date && buildFormikFieldBp("date", "Date", "date", isReadOnlyField("canChangeDate", "date"), { min: asYearMonthDay(minTxnDate) })}
            {bankingFields.payee && buildFormikFieldBp("payee", "Paid to / from", "text", isReadOnlyField("canChangeDescription", "description"))}
        </div>

        <div className={classNames(styles.editorRow, styles.description)}>
            {bankingFields.description && buildFormikFieldBp("description", "Description", "text", isReadOnlyField("canChangeDescription", "description"))}
        </div>

        <div className={styles.editorRow}>
            {/* Asset de minimis limit */}
            {bankingFields.amount && buildFormikFieldBp("amount", buildAmountTitle(), "number", isReadOnlyField("canChangeAmount", "amount"), paymentTypeLong === ProcessorType.assetPurchase ? {min: 50} : {min: 0})}
            
            {bankingFields.amount && paymentTypeLong === ProcessorType.salesInvoice && isForeignCurrency && <div data-cy="editorField" style={{height: '100%', width: '180px', margin: '0 0.5rem'}}>
                <label>Fully paid</label>
                <div className="input-group">
                    <input type="checkbox" disabled={isReadOnly} checked={!canEditAmountInSource} onChange={setFullyPaid}/>
                </div>
            </div>}

            {bankingFields.category && buildFormikFieldBp("accountId", "Category", "groupedselect", isReadOnlyField("canChangeAccount", "accountId"))}
            {bankingFields.vatRate && vatSetting && !vatSetting.isExempt && buildFormikFieldBp("vatCode", "VAT Rate", "select", isReadOnlyField("canChangeVatCode", "vatCode"))}
        </div>

        <div className={styles.editorRow}>
            {bankingFields.contract && buildFormikFieldBp("contractId", brandContext.brand === provestorBrand ? "Property/tenancy" : "Contract", "select", isReadOnlyField("canChangeBillTo", "billable"))}
            {(bankingFields.billable && formikBankPayment.values.contractId) ? buildFormikFieldBp("isBillable", "Billable", "check", isReadOnlyField("canChangeBillTo", "billable") || !billableEnabled) : null}
        </div>

        <div className={styles.editorRow}>
            {bankingFields.ref && buildFormikFieldBp("refNo", "Ref", "text", isReadOnlyField("canChangeDescription", "description"))}
            {bankingFields.amount && paymentTypeLong === ProcessorType.salesInvoice && isForeignCurrency && buildFormikFieldBp("amountInSourceCurrency", buildAmountTitle(true), "number", !canEditAmountInSource, {min: 0}, true)}
            {bankingFields.matches && !isQuickEntry && !isBankFeedAndNotDetached && buildFormikFieldBp("isConfirmed", "Matches statement", "check", isReadOnlyField("canChangeConfirmed", "isConfirmed"))}
        </div>
    </Modal>
}