import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SbBlokData } from '@storyblok/react';
import * as DataGrid from '../../DataGrid/DataGrid';
import { Onboarding } from '../../../api/inni/Onboarding';
import CompanyContext from '../../../context/CompanyContext';
import OnboardingContext from '../../../context/OnboardingContext';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { NoContentSlate } from '../../Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { FileUploadModal } from '../../../components/FileUploadModal/FileUploadModal';
import StandardFileField from '../../StandardFileField/StandardFileField';
type Props = { blok: SbBlokData };
export type TUploadState = {
	showModal: boolean;
	id: number;
};
const FinanceUploadTable = ({ blok }: Props) => {
	const { uploadTableFinanceFormik, onChangeNotifyAccountantMessage } = useContext(OnboardingContext);
	const onboardingApi = useInniAPI(Onboarding);
	const { cid } = useContext(CompanyContext);
	const [tableLoader, setTableLoader] = useState(false);
	const [showNewFiles, setShowNewFiles] = useState({} as { [key: string]: boolean });
	const [uploadState, setUploadState] = useState<TUploadState>({
		showModal: false,
		id: 0,
	});
	const { values, setValues } = useMemo(() => uploadTableFinanceFormik, [uploadTableFinanceFormik]);
	const uploadFiles = useCallback(
		(files: File[]) => {
			const currentItem = values.find((v) => v.id === uploadState.id);
			currentItem &&
				setValues((prev) =>
					prev.map((form) =>
						form.id === currentItem.id
							? { ...form, file: files[0], fileName: files[0].name }
							: form
					)
				);
			currentItem && setShowNewFiles((prev) => ({ ...prev, [`${currentItem.id}`]: true }));
			setUploadState((prev) => ({ ...prev, id: 0, showModal: !prev.showModal }));
		},
		[setValues, uploadState.id, values]
	);

	useEffect(() => {
		if (onboardingApi && cid) {
			setTableLoader(true);
			onboardingApi
				.getFileAttachments(cid)
				.then(({ data }) => {
					data &&
						data.properties &&
						setValues(
							data.properties.map((v) => ({
								name: v.name || '',
								id: v.relatedId,
								file: null,
								fileName: v.fileName || '',
                				type: v.tenureLifecycleStatus === 'Sold' ? 'Sale' : 'Purchase'
							})),
							false
						);
				})
				.finally(() => setTableLoader(false));
		}
	}, [cid, onboardingApi, setValues]);

	useEffect(() => {
		// unmount
		return () => {
			onChangeNotifyAccountantMessage('');
		};
	}, []);

	return (
		<>
			<DataGrid.Table>
				<thead>
					<tr>
						<th style={{width: '33%'}} >Property</th>
						<th style={{width: '27%'}} >Type</th>
						<th style={{width: '40%'}} >Completion statement</th>
					</tr>
				</thead>
				<tbody>
					{tableLoader && (
						<>
							<DataGrid.LoadingRow cols={3} />
							<DataGrid.LoadingRow cols={3} />
						</>
					)}
					{!tableLoader && (
						<>
							{values.length > 0 ? (
								values.map(({ name, fileName, id, type }, i) => (
									<tr key={id}>
										<td style={{width: '33%'}}>{name}</td>
										<td style={{width: '27%'}}>{type}</td>
										<td>
											<StandardFileField
												id={`[${i}-${blok.component}].file`}
												name={`[${i}].file`}
												onClick={() =>
													setUploadState((prev) => ({
														...prev,
														id,
														showModal: !prev.showModal,
													}))
												}
												fileName={fileName || ''}
												showNewFile={showNewFiles[id]}
											/>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={3} style={{ padding: 0 }}>
										<NoContentSlate type={DatagridType['Properties']} termsKey="emptyTerms" />
									</td>
								</tr>
							)}
						</>
					)}
				</tbody>
			</DataGrid.Table>

			{uploadState.showModal && (
				<FileUploadModal
					onCancelClick={() => setUploadState((prev) => ({ ...prev, id: 0, showModal: !prev.showModal }))}
					onFilesDropped={(acceptedFiles) => uploadFiles(acceptedFiles)}
					maxFiles={1}
					uploading={false}
					maxSizeMB={10}
					accept={'documents'}
					showDropZoneText
				/>
			)}
		</>
	);
};
export default FinanceUploadTable;
