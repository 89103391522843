import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import { CompanyHomeRedirector } from "../../components/CompanyHomeRedirector/CompanyHomeRedirector";
import { PortfolioDashboard } from "../../features/PortfolioDashboard/PortfolioDashboard";
import Dashboard from "../../features/Dashboard/Dashboard";

const dashboard:Array<EntityRoute> = [
    {
        path: '/company/:cid/dashboard',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.CompanyDashboard,
        component: Dashboard
    },
    {
        path: '/company/:cid/dashboard',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.PropertyDashboard,
        component: Dashboard
    }
]

export default dashboard;

