import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import CorporationTaxReport from "../../features/CorporationTax/CorporationTax";

const corporationTax:Array<EntityRoute> = [
    {
        path: '/company/:cid/corporationtax',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.CorporationTaxV7,
        component: CorporationTaxReport
    }
]

export default corporationTax;

