/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ChangeBankPaymentPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class VatReturns<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags VatReturns
   * @name Hold
   * @request POST:/api/v2/companies/{companyId}/VatReturns/{workflowId}/Hold
   * @secure
   */
  hold = (companyId: number, workflowId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/VatReturns/${workflowId}/Hold`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags VatReturns
   * @name Release
   * @request POST:/api/v2/companies/{companyId}/VatReturns/{workflowId}/Release
   * @secure
   */
  release = (companyId: number, workflowId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/VatReturns/${workflowId}/Release`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * @description This isn't used directly, it's an exact copy of the webapp version, for testsing
   *
   * @tags VatReturns
   * @name UpdateBankPayment
   * @summary ///
   * @request POST:/api/v2/companies/{companyId}/VatReturns/UpdateBankPayment
   * @secure
   */
  updateBankPayment = (companyId: number, bankPayment: ChangeBankPaymentPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/VatReturns/UpdateBankPayment`,
      method: "POST",
      body: bankPayment,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
