import styles from './NoContentSlate.module.css';
import { DatagridType, useDatagridTerms } from '../../hooks/terms/useDatagridTerms';
import { useContext } from 'react';
import CompanyContext from '../../context/CompanyContext';
import { Button } from '../Button/Button';
import classNames from 'classnames';

type TermsKey = 'emptyTerms' | 'noResultsTerms';

interface NoContentSlateProps {
  type: DatagridType,
  termsKey: TermsKey,
  whiteBg?: boolean,
  appendURL?: string,
  largeMargin?: boolean
}

export const NoContentSlate = ({
    type,
    termsKey,
    whiteBg = false,
    appendURL,
    largeMargin = false
  }: NoContentSlateProps) => {

  const datagridTerms = useDatagridTerms(type);
  const companyContext = useContext(CompanyContext);
  const v8Styling = companyContext.company?.useV8UI || false;

  let terms;
  let url;
  let btnLabel;

  if (termsKey === 'emptyTerms') {
    terms = datagridTerms?.emptyTerms;
    
    if(datagridTerms?.emptyTerms?.linkUrl && datagridTerms?.emptyTerms?.linkText) {
      url = datagridTerms.emptyTerms.linkUrl.includes("http") ? 
          datagridTerms.emptyTerms.linkUrl : 
          `${datagridTerms?.emptyTerms?.urlPrefix || ""}/company/${companyContext.cid}/${datagridTerms?.emptyTerms?.linkUrl}${appendURL? "/" + appendURL : ""}`
      btnLabel = datagridTerms.emptyTerms.linkText
    } 
      
  } else if (termsKey === 'noResultsTerms') {
    terms = datagridTerms?.noResultsTerms;

    if(datagridTerms?.noResultsTerms?.linkUrl && datagridTerms?.noResultsTerms?.linkText) {
      url = datagridTerms.noResultsTerms.linkUrl.includes("http") ? 
          datagridTerms.noResultsTerms.linkUrl : 
          `${datagridTerms?.emptyTerms?.urlPrefix || ""}/company/${companyContext.cid}/${datagridTerms?.noResultsTerms?.linkUrl}`
      btnLabel = datagridTerms.noResultsTerms.linkText
    }  
  }

  return (
    <div
      data-cy={`slate_${type}`}
      id={styles.slate}
      className={classNames({
        'bg-white': whiteBg || v8Styling,
        [styles.v8Styling]: v8Styling,
        [styles.largeMargin]: largeMargin
      })}
    >
      { terms && 
        <>
          { !v8Styling && <img src={`./images/${terms.imageUrl}`} alt="No results found"/> }
          <div className={styles.text}>
            <h5>{terms.title}</h5>
            <div>
              { v8Styling ? terms.body?.replace(/'New'/g, "'Add'") : terms.body }
              { url && btnLabel && (
                <a style={{display: 'block', marginTop: '1rem'}} href={url}>
                  <Button small variant="primary" label={btnLabel}/>
                </a>
              )}
            </div>
          </div>
        </>
      }
    </div>
  )
}
