import { useCallback } from 'react';
import { FormikProps } from 'formik';

function useCompareChange<T extends object>() {
  const refVal = useCallback((node: FormikProps<T>) => {
    if (node !== null) {
      const isDisabled = Object.keys(node.values).some(
        (key: string | number) => {
          const value = node.values[key as keyof T];
          return value as unknown as string === '';
        }
      );
      if (isDisabled) {
        Object.keys(node.values).forEach((key: string | number) => {
          const value = node.values[key as keyof T];
          if (value as unknown as string === '') {
            const initialValue = node.initialValues[key as keyof T];
            if (initialValue === null) {
              node.setFieldValue(key.toString(), null);
            }
          }
        });
      }
    }
  }, []);

	return refVal;
}

export default useCompareChange;
