import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { StatutoryPayments } from '../../../api/inni/StatutoryPayments'
import { useContext, useEffect, useState } from 'react';
import CompanyContext from '../../../context/CompanyContext';
import { StatutoryPayment } from '../../../api/inni/data-contracts'
import { formatDate } from '../../../utils/formatDate';
import { formatCurrency } from '../../../utils/formatNumbers';
import { TableIcon } from '../../../elements/TableIcon/TableIcon';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';

const StatPayments = ({employmentId} : {employmentId : number}) => {

    const statPaymentsApi = useInniAPI(StatutoryPayments)
    const companyContext = useContext(CompanyContext)

    const [statPaymentsList, setStatPaymentsList] = useState<StatutoryPayment[] | undefined>(undefined)
    const [statPaymentsLoaded, setStatPaymnentsLoaded] = useState(false)

    useEffect(() => {
        setStatPaymnentsLoaded(false)
    }, [employmentId])
    
    useEffect(() => {
        if (statPaymentsApi && !statPaymentsLoaded) {
            statPaymentsApi.getForEmployment(companyContext.cid, employmentId)
            .then(res => {
                setStatPaymentsList(res.data)
                setStatPaymnentsLoaded(true)
            })
        }
    }, [companyContext.cid, employmentId, statPaymentsApi, statPaymentsLoaded])

    return (
        <DataGrid.Table noHover>
            <thead>
                <tr>
                    <th>Pay date</th>
                    <th>Amount</th>
                    <th>Type</th>
                    <th>Paid</th>
                </tr>
            </thead>
            <tbody>
                {statPaymentsLoaded && statPaymentsList && statPaymentsList.length === 0 &&
                    <tr>
                        <td colSpan={4}><NoContentSlate whiteBg termsKey="emptyTerms" type={DatagridType.StatutoryPayments} /></td>
                    </tr>
                }
                {statPaymentsList && statPaymentsLoaded ? statPaymentsList.map(x => 
                <tr key={x.id}>
                    <td>{formatDate(x.date)}</td>
                    <td>{formatCurrency(x.amount)}</td>
                    <td>{x.type}</td>
                    <td>{x.payslipID ? <TableIcon icon="check" /> : <TableIcon icon="cross" />}</td>
                </tr>) : <>
                    <DataGrid.LoadingRow cols={4} />
                    <DataGrid.LoadingRow cols={4} />
                    <DataGrid.LoadingRow cols={4} />
                </>}
            </tbody>
        </DataGrid.Table>
    )
}

export default StatPayments