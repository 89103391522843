import { EntityRoute, RouteContext } from './routeTypes';
import { Action, Entity } from '../../utils/EntityAction';
import Queries from '../../features/Query/Queries';
import CreateQuery from '../../features/Query/CreateQuery';


const queries: Array<EntityRoute> = [
	
	{
		path: '/company/:cid/queries/create',
		exact: true,
		context: RouteContext.Company,
		component: CreateQuery,
		action: Action.Create,
		entity: Entity.AccountingPeriodsReviewItem
	},
	{
		path: '/company/:cid/queries/:yearId?',
		exact: true,
		context: RouteContext.Company,
		component: Queries,
		action: Action.Read,
		entity: Entity.AccountingPeriodsReviewItem,
		
	},


	
];

export default queries;
