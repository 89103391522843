import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { floor } from 'lodash'
import React, { useState } from 'react'
import styles from './Stepper.module.css'

export interface StepDefinition {
    children?: any
    label?: string,
    complete?: boolean,
    active?: boolean,
    disabled?: boolean,
    hideCircle?: boolean,
    onClick?: () => void
}

const Step = ({children, label, complete, active, disabled, hideCircle, onClick} : StepDefinition) => {
    return (
        <li style={{cursor: onClick !== undefined ? 'pointer' : ''}} onClick={onClick} className={classNames(complete ? styles.complete : '', active ? styles.active : '', disabled ? styles.disabled : '', hideCircle ? styles.hideCircle : '')}>
            <p onClick={onClick} className={styles.desc}>{label}</p> 
            <div onClick={onClick} className={styles.stepperContent}>{children}</div>
        </li>
    )
}

// TODO Define some defaults for the colours
const Stepper = ({children, vertical = false,activeColor = '#000', inactiveColor = '#000', maxItems} : {children : any[], vertical?: boolean, activeColor? : string, inactiveColor? : string, maxItems? : number}) => {

    let activeItem = children.findIndex(x => x ? x.props['active'] : false)

    const [rightExpanded, setRightExpanded] = useState(false)
    const [leftExpanded, setLeftExpanded] = useState(false)

    return (
        <div style={{'--step-active-color' : activeColor, '--step-inactive-color' : inactiveColor} as React.CSSProperties} className={classNames(styles.stepperContainer, vertical ? styles.stepperContainerVertical : '')}>
            <ol className={vertical ? styles.stepperWrapperVertical : styles.stepperWrapper}>
                {children.filter(x => x).map((x, i) => {
                    // There no point in showing the ... if it takes up the same amount of space
                    if (!maxItems || maxItems >= (children.filter(x => x).length+2))
                        return x;
                    
                    let start = activeItem - floor(maxItems / 2)
                    let end = activeItem + floor(maxItems / 2)

                    if (end > children.filter(x => x).length - 1) {
                        start -= (end - children.filter(x => x).length) + 1
                        end = children.filter(x => x).length - 1
                    }

                    if (start <= 0) {
                        end += ((start-1)*(-1)) - 1
                        start = 0
                    }

                    if (leftExpanded)
                        start = 0
                    if (rightExpanded)
                        end = children.filter(x => x).length

                    if (i >= start && i <= end)
                        return <React.Fragment key={i}>{x}</React.Fragment>;
                    else if (i === start - 1 || i === end + 1)
                        return <Step key={i} onClick={() => i === start - 1 ? setLeftExpanded(true) : setRightExpanded(true)} hideCircle active={i === (activeItem - floor(maxItems / 2) + (maxItems % 2 === 0 ? 1 : 0) ) - 1} complete={i === start - 1}>
                            <div style={{marginBottom: '-10px', cursor: 'pointer'}}><FontAwesomeIcon size="2x" color="var(--step-inactive-color)" icon={faEllipsisH} style={{paddingBottom: '3px'}} /></div>
                        </Step>
                    return <React.Fragment key={i}></React.Fragment>
                })}
            </ol>
        </div>
    )
}



export {Stepper, Step}