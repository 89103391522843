import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import { Adjustments } from "../../features/Adjustments/Adjustments";

const adjustments:Array<EntityRoute> = [
    {
        path: '/company/:cid/adjustments',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.AdjustmentV7,
        component: Adjustments
    }
]

export default adjustments;
