import { useContext, useEffect, useState } from 'react';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { Alert } from 'react-bootstrap';
// API
import { PersonCreateModel, AutoEnrolment } from '../../../api/inni/data-contracts';
import { People } from '../../../api/inni/People';
import { Companies } from '../../../api/inni/Companies';
import { useInniAPI } from '../../../hooks/useInniAPI';
// Utils
import { Entity, Action } from '../../../utils/EntityAction';
import { nationalityCodesToListOptions } from '../../../utils/formatters/formatCodes';
import { getTimeDifference } from '../../../utils/formatDate';
// Hooks
import CompanyContext, { CompanyProduct } from '../../../context/CompanyContext';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
// Components
import { DefaultLayout } from '../../../layouts/Desktop/DefaultLayout';
import {
  Text as EditText,
  DateSelector,
  Switch,
  Submit,
  SelectList,
  ListOption } from '../../../elements/EditRow/EditRow';
import InfoBanner from '../../../elements/InfoBanner/InfoBanner';
import { useHasPermission } from '../../../hooks/useHasPermission';
import CreatePeople from './CreatePeople';

const NewEmployee = () => {
  const companyContext = useContext(CompanyContext);
  const peopleApi = useInniAPI(People, [400]);
  const companiesApi = useInniAPI(Companies);
  const [nationalityOptions] = useState<ListOption[]>(nationalityCodesToListOptions());
  const navigateToEntity = useNavigateToEntity()
  const [ageWarning, setAgeWarning] = useState<null | number>(null);
  const [autoEnrolmentStatus, setAutoEnrolmentStatus] = useState<AutoEnrolment>();
  const [taxRegimeSl, setTaxRegimeSl] = useState<ListOption[]>([])
  const v8Styling  = companyContext.company?.useV8UI;

  const initialValues: PersonCreateModel = {
    title: '',
    forenames: '',
    surname: '',
    gender: 'male',
    dob: '',
    nationality: 'GB',
    address: '',
    postcode: '',
    isDirector: false,
    directorAppointmentDate: '',
    shares: 0,
    overseas: false,
    utr: '',
    jobTitle: '',
    isCorporateEntity: false,
    repayMileageDirectly: false
  }

  useEffect(() => {
    if (companiesApi) {
      companiesApi.getAutoEnrolment(companyContext.cid)
      .then(data => {
        setAutoEnrolmentStatus(data.data);
      })
      .catch(error => {
        console.error(error);
      })
    }
  }, [companyContext.cid, companiesApi]);

  useEffect(() => {
    if (peopleApi) {
        peopleApi.getTaxReigmeSelectList(companyContext.cid)
            .then(res => {
                let tempSL: ListOption[] = []
                res.data.map(x => tempSL.push({ value: x.id.toString(), label: x.name || '' }))
                setTaxRegimeSl(tempSL)
            })
    }
}, [companyContext.cid, peopleApi])

  const formSubmit = (values: PersonCreateModel, actions: FormikHelpers<PersonCreateModel>): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (peopleApi) {
        peopleApi.create(companyContext.cid, values)
        .then(data => {
          if (data.status === 201) {
            v8Styling? navigateToEntity(Entity.CompanySettingsV7,Action.List,{"activePage" : "people"}):navigateToEntity(Entity.PersonV7, Action.List);
            resolve();
          } else {
            reject('Unexpected return status');
          }
        })
        .catch(error => {
          actions.setErrors(error.error);
          reject(error.error);
        })
      } else {
        reject('Missing API');
      }
    });
  }

  const checkAge = (dob: string) => {
    const dtNow = new Date();
    const dtDOB = new Date(dob);
    const age = getTimeDifference(dtNow, dtDOB, 'years');
    setAgeWarning((age && age >= 100) ? age : null);
  }

  const formValidate = (values: PersonCreateModel) : Promise<FormikErrors<PersonCreateModel>> => {
    if (values.dob) {
      checkAge(values.dob)
    }

    return new Promise((resolve, reject) => {
      if (peopleApi) {
        return peopleApi.validateCreate(companyContext.cid, values)
        .then(() => resolve({}))    
        .catch(error => resolve(error.error))            
      } else {
        reject();
      }  
    });
  }

  const simpleSharesOnly = !useHasPermission()(Entity.AlphabetShares, Action.Read)[0]

  const pageTitle = "New person"; // employee is wrong, it's a hangover.

  return (
    <>
    
      {v8Styling ?
          <CreatePeople 
            isOpen={true} 
            hide={() => navigateToEntity(Entity.CompanySettingsV7,Action.List,{"activePage" : "people"})} 
            onCancel={() => navigateToEntity(Entity.CompanySettingsV7,Action.List,{"activePage" : "people"})} 
            intialValues={initialValues} 
            formSubmit={formSubmit} 
            formValidate={formValidate}
            ageWarning={ageWarning}         
            nationalityOptions={nationalityOptions}
            taxRegimeSl={taxRegimeSl}
            checkAge={checkAge}
        />
        :
          <>

            <Formik
              initialValues={initialValues}
              enableReinitialize
              onSubmit={formSubmit}
              validateOnChange={false}       
              validate={formValidate}
            >
              {({ isSubmitting, values }) => (
                <DefaultLayout title={pageTitle}>
                  { autoEnrolmentStatus?.autoEnrolExempt && (
                    <InfoBanner
                      type="info"
                      title="Auto enrolment"
                      body="This company has been declared exempt from auto-enrolment. Any changes to the number of employees and or wages may require this exemption to be re-evaluated against auto-enrolment criteria"
                    />
                  )}

                  <Form>
                    <EditText<PersonCreateModel> name="title" />
                    <EditText<PersonCreateModel> name="forenames" />
                    <EditText<PersonCreateModel> name="surname" />
                    <EditText<PersonCreateModel> name="address" type="textarea" />
                    <Switch<PersonCreateModel> name="overseas" />
                    {values.overseas ? <EditText<PersonCreateModel> name="country" /> : <EditText<PersonCreateModel> name="postcode" />}
                    <SelectList<PersonCreateModel> name="gender" options={[{value:'male', label:'Male'}, {value:'female', label:'Female'}]} />
                    <DateSelector<PersonCreateModel> name="dob" label="Date of birth" />
                    <SelectList<PersonCreateModel> name="nationality" options={nationalityOptions} /> 
                    <EditText<PersonCreateModel> name="utr" label="UTR"/>
                    <SelectList<PersonCreateModel> name="taxRegime" options={taxRegimeSl} />
                    <EditText<PersonCreateModel> name="jobTitle" />
                    {simpleSharesOnly && <EditText<PersonCreateModel> name="shares" type="number" min={0}/>}
                    <Switch<PersonCreateModel> name="isDirector" />
                    { values.isDirector && <DateSelector<PersonCreateModel> name="directorAppointmentDate" /> }
                    { ageWarning && (
                      <Alert variant="danger" style={{maxWidth: '780px'}}>
                        This employee is { ageWarning } years old, is this correct?
                      </Alert> 
                    )}
                    <Switch<PersonCreateModel> name="isCorporateEntity" />
                    <EditText<PersonCreateModel> name="defaultAdvisoryFuelrateBand" />
                    <Switch<PersonCreateModel> name="repayMileageDirectly" />
                    {/* No accounts for a person who doesn't exist yet */}
                    {/* <SelectList<PersonCreateModel> name="repayMileageDirectlyToAccountId" label="Repay mileage to account" options={[{ label: 'Repay manually from pending payment', value: null }]} /> */}
                    <Submit disabled={isSubmitting} onCancelClick={() => {v8Styling? navigateToEntity(Entity.CompanySettingsV7,Action.List,{"activePage" : "people"}): navigateToEntity(Entity.PersonV7, Action.List) }} />
                  </Form>       
                </DefaultLayout>
              )}
            </Formik>  
          </>
      }
    </>
  )
}

export default NewEmployee;
