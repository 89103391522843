import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import UserContext from '../../../context/UserContext';
import { setPropToStoryblokText } from '../../../utils/setPropToStoryblok';
type Props = {
	blok: SbBlokData;
};
const StoryblokAlert = ({ blok }: Props) => {
	const { user } = useContext(UserContext);

	return (
		<Alert
			key={blok.variant as string}
			variant={blok.variant as string}
			className="mt-3"
			{...storyblokEditable(blok)}
		>
			{setPropToStoryblokText(blok.body as string, {
				firstName: user?.firstName || '',
			})}
		</Alert>
	);
};

export default StoryblokAlert;
