import { getStoryblokApi, SbBlokData, StoryData } from '@storyblok/react';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { InitialiseDateModel, NotifyOfUpdatePostModel, PageProgress, PropertyFinancingDetailsPostModel } from '../api/inni/data-contracts';
import { Onboarding } from '../api/inni/Onboarding';
import CompanyContext from '../context/CompanyContext';
import UserContext from '../context/UserContext';
import { checkUTR } from '../utils/checkUTR';
import { compareAscByString } from '../utils/sort';
import { useUploadCSV } from './onboarding/useUploadCSV';
import { useInniAPI } from './useInniAPI';
import { useOnboardingProperties } from './useOnboardingProperties';
import { useUploadDocumentsTable } from './useUploadDocumentsTable';
export enum OnboardingSystemKeys {
	pageCompleteYes = 'pageCompleteYes',
	pageCompleteNo = 'pageCompleteNo',
	registeredOfficeYes = 'registeredOfficeYes',
	registeredOfficeNo = 'registeredOfficeNo',
	readyToConnect = 'readyToConnect',
	Connected = 'Connected',
	recordsCoverMDNo = 'recordsCoverMDNo',
	recordsCoverMDYes = 'recordsCoverMDYes',
	previousAccYes = 'previousAccYes',
	previousAccNo = 'previousAccNo',
	profClearNo = 'profClearNo',
	financesAddedNo = 'financesAddedNo',
	propertiesAddedNo = 'propertiesAddedNo',
	bookkeepingCompleteNo = 'bookkeepingCompleteNo',
	CSVUploadedNo = 'CSVUploadedNo',
	
}
type TStoryData = StoryData<{
	[index: string]: any;
}>;
type InfoItem = {
	url: string;
	_uid: string;
	pageId: string;
}
export type ExtraPageProps = {
	cached_url: string;
	id: string;
	pageId: string;
};
export interface FinanceTableForm extends Omit<PropertyFinancingDetailsPostModel, 'type'> {
    name: string;
	type: "Mortgage" | "Loan" | "NotFinanced" | "";
	status?: string;
	modified?: boolean;
}

const validateFinanceForm = (values: FinanceTableForm[]) => {
	const errors: {[key: string]: string} = {};

	values.forEach((v, i) => {
		if (!v.modified){
			if (!v.lendersName && v.type !== 'NotFinanced') {
				errors[`[${i}].lendersName`] = 'This field is required'
			}
			if (!v.type) {
				errors[`[${i}].type`] = 'This field is required'
			}
		}
	})
	return errors
}
const TEST_PAGE_NAV_ID = '6326187f-ec8e-401f-b828-87eb9df1c2f0';

export const useOnboardingStory = () => {
	const { user } = useContext(UserContext);
	const { company } = useContext(CompanyContext);
	const onboardingApi = useInniAPI(Onboarding);
	const [story, setStory] = useState<TStoryData>();
	const [initialData, setInitialData] = useState<InitialiseDateModel>({} as InitialiseDateModel);
	const [selectedUid, setSelectedUid] = useState<string>('');
	const [selectedPageUrl, setSelectedPageUrl] = useState<string>('');
	const [pageStory, setPageStory] = useState<TStoryData | null>(null);
	const [showNotifyModal, setShowNotifyModal] = useState(false);
	const [utr, setUtr] = useState('');
	const firstInitRef = useRef(true);
	const [alertMessage, setAlertMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [showExcept, setShowExcept] = useState({});
	const [firstTimeRendered, setFirstTimeRendered] = useState(false);
	const [pageStatuses, setPageStatuses] = useState<{[key: string]: any}>({})
	const {setNotifyAccountantsMessage, notifyAccountantsMessage, uploadTableFinanceFormik, uploadMortgageDocumentsFormik, submitPropertyRecords} = useUploadDocumentsTable();
	const {ownedPropertiesFormik, soldPropertiesFormik, submitProperties} = useOnboardingProperties();
	const {uploadCVSFormik, onUploadCVSFiles} = useUploadCSV();
	const financeTableFormik = useFormik({
		initialValues: [] as FinanceTableForm[],
		onSubmit: (values) => submitFinanceTable(values.filter(v => !v.modified).map(({propertyId, type, lendersName, interestOnly}) => ({propertyId, type, lendersName, interestOnly}) as PropertyFinancingDetailsPostModel)),
		validate: validateFinanceForm,
	});

	const getOnboardingPagesInfo = useCallback(() => 
	story && (story.content.items as Array<SbBlokData>)
		.map(v => (v.items as Array<SbBlokData>)
		.map(j => ({url: (j.page as ExtraPageProps).cached_url, _uid: j._uid, pageId: (j.page as ExtraPageProps).id})))
		.reduce((acc, t) => [...acc, ...t], [])
		.filter(v => v._uid !== TEST_PAGE_NAV_ID), [story]);

	const onNextPage = () => {
		const info = getOnboardingPagesInfo() || [];
		const index = info ? info.findIndex((v: InfoItem) => v.pageId === pageStory?.uuid || v.url === selectedPageUrl) : 0;
		const nextPage = index !== -1 && index < info.length - 1 ? info[index + 1] : null;
		nextPage && onNavItemSelect(nextPage._uid, nextPage.url);
	}
	const pageCompleteAction = () => {
		onCompletePage(pageStory?.uuid || '', initialData.processDefinitionId || '', onNextPage);
	}
	const setCurrentPageCompletionStatus = (): boolean =>
		initialData && initialData?.checklistProgress
			? initialData.checklistProgress.find((v) => v.uid === selectedUid)
					?.pageCompleted || false
			: false;

	const onCompletePage = useCallback(
		(pageId: string, processDefinitionId: string, onSuccess?: () => void) => {
			onboardingApi
				?.markPageCompleted(company?.id || 0, { pageId, processDefinitionId })
				.then(() => {
					setInitialData(
						(prevData) =>
							prevData && {
								...prevData,
								checklistProgress: prevData.checklistProgress?.map((v) =>
									v.uid === selectedUid ? { ...v, pageCompleted: true } : v
								),
							}
					);
					onSuccess && onSuccess();
				})
				.catch((err) => {
					console.error(err);
				});
		},
		[company?.id, onboardingApi, selectedUid]
	);
	
	const onIncompletePage = useCallback(() => {
		setInitialData(
			(prevData) =>
				prevData && {
					...prevData,
					checklistProgress: prevData.checklistProgress?.map((v) =>
						v.uid === selectedUid ? { ...v, pageCompleted: false } : v
					),
				}
		);
	}, [selectedUid])

	const notifyOfCompanyUpdate = useCallback(
		(pm: NotifyOfUpdatePostModel) => {
			if (onboardingApi && company?.id) {
				onboardingApi.notifyOfCompanyUpdate(company?.id, pm).then(() => {
					setShowNotifyModal(false);
					onCompletePage(pageStory?.uuid || '', initialData?.processDefinitionId || '');
					setAlertMessage(`Thanks ${user?.firstName}! We've sent your message to our team and they'll take a look and drop you an email soon.`)
				});
			}
		},
		[company?.id, initialData.processDefinitionId, onCompletePage, onboardingApi, pageStory?.uuid, user?.firstName]
	);

	const submitUTR = () => {
		if (onboardingApi && company?.id && initialData && !initialData.submittedUTR && checkUTR(utr)) {
			onboardingApi.submitCompanyUtr(company?.id, {utr}).then((data) => {
				setErrorMessage('');
				setAlertMessage('Thank you, please wait for your authorisation codes from HMRC in the post')
				setInitialData(prev => ({...prev, submittedUTR: utr}));
			}).catch(({error: {errorUser}})=> setErrorMessage(errorUser))
		}
	}

	const resendIDEmails = () => {
		if (onboardingApi && company?.id) {
			onboardingApi.resendIdVerificationRequest(company.id).then((data) => {
				setIsSubmitted(true);
				setAlertMessage('The email was resent to all unverified members')
			})
		}
	}

	const submitFinanceTable = (body: PropertyFinancingDetailsPostModel[]) => {
		if (onboardingApi && company?.id) {
			onboardingApi
				.submitPropertyFinancingDetails(company.id, body)
				.then(() =>
					setCurrentPageCompletionStatus()
						? setAlertMessage('The finances was successfully saved')
						: pageCompleteAction()
				);
		}
	};

	const toggleNotifyUsModal = () => setShowNotifyModal((prev) => !prev);

	const submitPropertyRecordsAction = () => submitPropertyRecords((canComplete) => {
		setAlertMessage('The notification was successfully sent to our accountants');
		if (!setCurrentPageCompletionStatus() && canComplete) {
			pageCompleteAction()
		} else {
			onNextPage()
		}
	})
	const handlers = {
		save: pageCompleteAction,
		gotoUpdate: toggleNotifyUsModal,
	}
	const onClickAction = ({title, system_key}: SbBlokData) => {
		if (pageStory?.slug === 'property-records' && system_key === 'save') {
			return submitPropertyRecordsAction
		}

		if (pageStory?.slug === 'add-your-properties' && system_key === 'save') {
			return () => submitProperties(title === 'Submit' ? () => setAlertMessage('New properties were successfully added') : pageCompleteAction)
		}
		if (pageStory?.slug === 'import-bank-transactions' && system_key === 'save') {
			return () => onUploadCVSFiles(() => 
							!setCurrentPageCompletionStatus() ? 
								pageCompleteAction() : 
								setAlertMessage('The CSV file(s) were successfully uploaded')
						)
				
		}
		if (title === 'Submit' && system_key === 'save') {
			return submitUTR;
		}
		if (title === 'Resend ID emails' && system_key === 'save') {
			return resendIDEmails;
		}
		if (pageStory?.slug === 'setup-your-finances' && system_key === 'save') {
			return () => financeTableFormik.handleSubmit();
		}
		
		return system_key && handlers ? handlers[system_key as keyof typeof  handlers] || null : null;
	}
	const onNavItemSelect = (uid: string, pageUrl: string) => {
		setSelectedUid(uid);
		setSelectedPageUrl(pageUrl);
		setIsSubmitted(false);
	};

	useEffect(() => {
		if (!selectedPageUrl) {
			setPageStory(null);
			return;
		}

		const fetchStory = async () => {
			setPageStory(null);
			const storyblokApi = getStoryblokApi();
			let { data } = await storyblokApi.get(`cdn/stories/${selectedPageUrl}`, {
				version: 'draft',
			});
			setPageStory(data.story);
		};

		fetchStory();
	}, [selectedPageUrl]);
	const updateCheckListProps = useCallback(
		(story: TStoryData) => {
			if (story && Object.keys(initialData).length && Object.keys(story).length) {
				const storyItems = story.content.items?.map((navGroup: any) => ({
					...navGroup,
					items: navGroup.items?.map((v: any) => {
						const item = initialData.checklistProgress?.find((k) => k.uid === v._uid);
						return item ? { ...v, testing_complete: item.pageCompleted, hide: item.hidePage } : v;
					}),
				}));

				return {
					...story,
					content: { ...story.content, items: storyItems },
				} as TStoryData;
			}
		},
		[initialData]
	);

	const getInitialData = useCallback(async () => {
		try {
			if (onboardingApi) {
				const response = await onboardingApi.getInitialOnboardingData(company?.id!);
				setInitialData(response.data);
				setUtr(response.data.submittedUTR || '')
			}
		} catch (error) {}
	}, [company?.id, onboardingApi]);

	useEffect(() => {
		getInitialData();
	}, [getInitialData]);

	useEffect(() => {
		const fetchStory = async () => {
			if (initialData.processDefinitionId && firstInitRef.current) {
				const storyblokApi = getStoryblokApi();
				const { data } = await storyblokApi.get(`cdn/stories/onboarding/${initialData.processDefinitionId}`, {
					version: 'draft',
				});
				setStory(updateCheckListProps(data.story));
				firstInitRef.current = false;
			} else {
				setStory((prev) => prev && updateCheckListProps(prev));
			}
		};
		fetchStory();
	}, [initialData.processDefinitionId, updateCheckListProps]);
	const statusLine = {
		connected: initialData.bankAccountConnected,
		pending: initialData.bankAccountConnected === false,
		waiting: initialData.professionalClearanceStatus === "WaitingForClearance",
		furtherInformation: initialData.professionalClearanceStatus === 'RequestedFurtherInfo',
		complete: initialData.professionalClearanceStatus === 'Complete',
		outstandingTransactions: initialData.outstandingTransactions,
		transactionsNeedAction: initialData.recordsCoverMD && initialData.outstandingTransactions,
		upload: !initialData.recordsCoverMD,
		completeBankTransactions: initialData.recordsCoverMD && !initialData.outstandingTransactions,
	}
	const showStatusLine = (system_key: string) => {
		if (selectedPageUrl === 'onboarding/pages/pre-appointment-accounts') {
			if (system_key === 'pending') {
				return initialData.professionalClearanceStatus !== 'Complete'
			}
			if (system_key === 'received') {
				return initialData.professionalClearanceStatus === 'Complete'
			}
			const keys = system_key.split(':')
			return keys[1] === 'complete' ? pageStatuses[keys[0]] || false : !(pageStatuses[keys[0]] || false)
		}
		return statusLine[system_key as keyof typeof statusLine] || false;
	}

	const pageCompletionStatuses = useCallback(() => {
		const pagesInfo = (getOnboardingPagesInfo() || []).sort(compareAscByString('pageId'));
		const checkList = (initialData.checklistProgress || [])
			.sort(compareAscByString<PageProgress>('pageId'))
			.reduce((acc, v) => ({...acc, [v.pageId || '']: v.pageCompleted}), {} as {[key: string]: boolean});
		setPageStatuses(pagesInfo.reduce((acc, v, i) => ({...acc, [v.url]: checkList[v.pageId] || false}), {}))
	}, [getOnboardingPagesInfo, initialData.checklistProgress])

	useEffect(() => {
		// Show onboarding completed page if all pages are completed
		if (initialData.checklistProgress?.every(page => page.pageCompleted)) {
			setSelectedPageUrl('onboarding/pages/onboarding-complete')
		} else {
			const checklistProgressExceptLast = initialData.checklistProgress?.slice(0, initialData.checklistProgress.length - 1);
			!checklistProgressExceptLast?.every(page => page.pageCompleted) && setShowExcept({'pre-appointment-accounts': true});
		}
		pageCompletionStatuses();
	}, [pageCompletionStatuses, initialData.checklistProgress])
	
	useEffect(() => {
		// Navigate to first incomplete page from nav list
		if (!firstTimeRendered && !isEmpty(pageStatuses)) {
			const initialPage = getOnboardingPagesInfo()?.find(v => !pageStatuses[v.url]);
			initialPage && onNavItemSelect(initialPage._uid, initialPage.url);
			initialPage && setFirstTimeRendered(true);
		}
	}, [firstTimeRendered, getOnboardingPagesInfo, pageStatuses])
	
	return {
		getInitialData,
		initialData,
		story,
		notifyOfCompanyUpdate,
		pageStory,
		onNavItemSelect,
		selectedUid,
		selectedPageUrl,
		showNotifyModal,
		toggleNotifyUsModal,
		utr,
		setUtr,
		alertMessage,
		onClickAction,
		setCurrentPageCompletionStatus,
		showStatusLine,
		errorMessage,
		isSubmitted,
		financeTableFormik,
		setNotifyAccountantsMessage,
		notifyAccountantsMessage,
		uploadTableFinanceFormik,
		uploadMortgageDocumentsFormik,
		soldPropertiesFormik,
		ownedPropertiesFormik,
		showExcept,
		uploadCVSFormik,
		setAlertMessage,
		onIncompletePage,
	};
};
