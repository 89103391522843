import styles from './LinkPropertyInfo.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';

const LinkPropertyInfo = () => (
    <div className={`mt-2 mb-1 ${styles.linkPropertyInfo}`}>
      <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
      Linking an import to an existing Provestor property will avoid creating a duplicate if a matching property already exists
    </div>
  )

export default LinkPropertyInfo;
