import { useContext } from "react";
import CompanyContext from "../context/CompanyContext";
import { Action, Entity } from "../utils/EntityAction";

/**
 * Checks permission, based on current CompanyContext
 * @param Entity 
 * @param Action 
 * @returns {[boolean,boolean]} hasPermission and isAdminPermission
 */
export function useHasPermission(): (entity: Entity, action: Action) => [boolean, boolean]  {
    const features = useContext(CompanyContext).features;

    return (entity: Entity, action: Action) => {

        if (!features)
            return [false, false];
    
        // Do they have 'all' permission?
        let search = features?.find(f => f.entity === entity && f.action === Action.All)
        if (search)
            return [true, search.isAdminOnly];

        // Check action
        search = features?.find(f => f.entity === entity && f.action === action)
        if (search)
            return [true, search.isAdminOnly];

        return [false, false];
    
    }

}