import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import Receipts from "../../features/Receipts/Receipts";

const receipts:Array<EntityRoute> = [
    {
        path: '/company/:cid/receipts/:tab',
        exact: true,
        context: RouteContext.Company,
        action: Action.Read,
        entity: Entity.Receipt,
        component: Receipts
    },
    {
        path: '/company/:cid/receipts/',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Receipt,
        component: Receipts
    },    
]

export default receipts;