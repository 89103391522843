import useIsMobile from "../../hooks/useIsMobile"
import QuickEntry from "./QuickEntry"
import QuickEntryMobile from "./QuickEntryMobile"

const QuickEntryWrapper = () => {
    // inject QEMobile if we are on the mobile site...
    // probably a better/more generic way of doing this...
    // possibly define a mobile component as well in routing then switch depending on wrapper used?
    // fallback to desktop version if no mobile version is defined
    // Ideally though majority of the code will be shared
    // but for now...

    // Also not sure if the plan is to put all in one app, i.e. no more m.inni...

    const isMobile = useIsMobile()

    return (
        isMobile ? <QuickEntryMobile /> : <QuickEntry />
    )
}

export default QuickEntryWrapper