import React, { useEffect, useState } from 'react'
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import WeekSelector, { findPrevMonday } from './WeekSelector';
import { add } from 'date-fns'
import { useInniAPI } from '../../../hooks/useInniAPI';
import { QuickEntries } from '../../../api/inni/QuickEntries';
import { Contract, QuickEntry, TimeTaskPostModel } from '../../../api/inni/data-contracts';
import { useContext } from 'react';
import CompanyContext from '../../../context/CompanyContext';
import TimeWeekRow from './TimeWeekRow';
import { Button } from '../../../elements/Button/Button';
import { Time } from '../../../api/inni/Time';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { asYearMonthDay } from '../../../utils/formatDate';

const dayOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export interface taskTimeWeek {
    id : number,
    contractId: number,
    values : {value: number, readOnly?: boolean}[]
}

type SetDefaultMileage = [number, string, boolean] // [contractid, date, enable]

const TimeWeekView = ({curEmployeeId, contracts} : {curEmployeeId : number, contracts: Contract[]}) => {

    const [startDate, setStartDate] = useState<Date>(findPrevMonday(new Date()))


    const quickEntryAPI = useInniAPI(QuickEntries);
    const timeAPI = useInniAPI(Time)

    const [quickEntryData, setQuickEntryData] = useState<QuickEntry[] | undefined>(undefined)
    const [isQELoaded, setIsQELoaded] = useState(false)
    const companyContext = useContext(CompanyContext)

    const [weekArr, setWeekArr] = useState<taskTimeWeek[] | undefined>(undefined)
    const [weekArrLoaded, setWeekArrLoaded] = useState(false)

    const [valsTouched, setValsTouched] = useState(false)
    const [defaultMileageArr, setDefaultMileageArr] = useState<SetDefaultMileage[]>([])

    useEffect(() => {
        setIsQELoaded(false);
    }, [startDate, curEmployeeId])

    useEffect(() => {

        if (quickEntryAPI && !isQELoaded) {
            quickEntryAPI.get(companyContext.cid, {
                showTimes: true,
                showMileages: true,
                start: asYearMonthDay(startDate),
                end: asYearMonthDay(add(startDate, {days: 7})),
                employeeId: curEmployeeId ? curEmployeeId : undefined
            })
            .then(res => {
                setIsQELoaded(true);
                setQuickEntryData(res.data.items)
                setWeekArrLoaded(false);
            })
        }

    }, [companyContext.cid, curEmployeeId, isQELoaded, quickEntryAPI, startDate])

    useEffect(() => {        
        if (quickEntryData && contracts && !weekArrLoaded) {
            let tempTime : taskTimeWeek[] = [];
            contracts.filter(x => 
                ((!x.startDate || new Date(x.startDate) <= startDate) && 
                    (!x.endDate || new Date(x.endDate) >= startDate) &&
                    (x.tasks && x.tasks.some(y => y.active)) 
                    && x.enableTimeTracking)
                    || 
                    quickEntryData?.find(y => x.id === y.contractId && y.amount !== 0)
                ).forEach(c => {
                if (c.tasks) {
                    c.tasks.forEach((t,i) => {                       
                        let vals : {value: number, readOnly?: boolean}[] = [
                            {value: 0, readOnly: false},
                            {value: 0, readOnly: false},
                            {value: 0, readOnly: false},
                            {value: 0, readOnly: false},
                            {value: 0, readOnly: false},
                            {value: 0, readOnly: false},
                            {value: 0, readOnly: false}
                        ];
                        [...Array(7)].forEach((x,j) => {
                            let curDate = add(startDate, {days: j})
                            let qe = quickEntryData?.filter(x => x.entryType === "T").find(y => 
                                (y.date ? asYearMonthDay(new Date(y.date)) === asYearMonthDay(curDate) && y.contractTaskId === t.id : false)
                            )
                            if (qe && qe.amount) {
                                vals[j].value = qe.amount;
                                vals[j].readOnly = qe.isReadOnlyTask;
                            }
                            else {
                                vals[j].value = 0;
                            }
                        })
                        tempTime.push({id : t.id, contractId: t.contractId, values: vals})
                    })
                }
            })
            setWeekArr(tempTime)
            setWeekArrLoaded(true)
        }

    }, [contracts, quickEntryData, startDate, weekArrLoaded])

    // Submit data for each day using weeksArr to add timesheet for each day
    const submitData = () => {
        if (timeAPI) {
            let payload : TimeTaskPostModel[] = []

            weekArr?.forEach(d => {
                d.values.forEach((v,i) => {
                    let mileageIndex = defaultMileageArr.findIndex(x => d.contractId === x[0] && x[1] === asYearMonthDay(add(startDate, {days: i})))
                    payload.push({ 
                        contractId: d.contractId, 
                        contractTaskId: d.id, 
                        employeeId: curEmployeeId, 
                        date: asYearMonthDay(add(startDate, {days: i})), 
                        hours: v.value,
                        saveDefaultMileage: mileageIndex !== -1 ? defaultMileageArr[mileageIndex][2] : false 
                    })
                    setDefaultMileageArr([...defaultMileageArr.filter(x => x[0] !== d.contractId)])
                })                
            })
            
            timeAPI.put(companyContext.cid, payload)
                .then(res => { setValsTouched(false); setIsQELoaded(false) })

        }        
    }

    const updateArrValues = (curVals : {value: number, readOnly?: boolean}[], taskId : number) : void => {
        if (weekArr) {
            setValsTouched(true)
            let i = weekArr.findIndex(x => x.id === taskId)
            let tempTimes = [...weekArr];
            tempTimes[i].values = curVals
            setWeekArr(tempTimes)
        }
    }

    const handleMileageChange = (contractId : number, date : string) => {
        setValsTouched(true)
        let existsInd = defaultMileageArr.findIndex(x => x[0] === contractId && x[1] === date)
        if (existsInd !== -1) {
            let tempDefaultMil = defaultMileageArr
            tempDefaultMil[existsInd] = [contractId, date, !tempDefaultMil[existsInd][2]]
            setDefaultMileageArr([...defaultMileageArr])
        } else {
            let tempDefaultMil = defaultMileageArr
            tempDefaultMil.push([contractId, date, true])
            setDefaultMileageArr([...defaultMileageArr])
        }
    }

    const contractHasTime = (c:Contract) => quickEntryData && quickEntryData?.some(y => c.id === y.contractId && y.amount !== 0)
    const taskHasTime = (taskId: number) => quickEntryData && quickEntryData.some(x => x.contractTaskId === taskId && x.amount > 0)
    
    const shouldShowContract = (c:Contract) => {
        const trackingEnabledInDateWithTasks = (c.enableTimeTracking || c.enableMileageTracking) && 
            (!c.startDate || new Date(c.startDate) <= startDate) && 
            (!c.endDate || new Date(c.endDate) >= startDate) &&
            (c.tasks && c.tasks.some(x => x.active))
            
        return trackingEnabledInDateWithTasks || contractHasTime(c) || false
    }

    const filteredContracts = contracts.filter(x => shouldShowContract(x))

    const showTimeTracking = (c:Contract) => {
        return c.enableTimeTracking || contractHasTime(c)
    }

    const anyTimeTrackingContracts = filteredContracts.filter(x => showTimeTracking(x)).length > 0

    const getContractRateName = (type: string) => {
        switch(type) {
            case 'H':
                return 'Hours'
            case 'D':
                return 'Days'
            default:
                return;
        }
    }

    return (
        <div style={{marginTop: '20px', marginBottom: '50px'}}>
            <h4 className="mb-3">Time tracking</h4>
            <DataGrid.Table noHover>
                <thead>
                    <tr>
                        <th style={{width: '30%', background: 'var(--primary)'}}><WeekSelector setStartDate={setStartDate} /></th>
                        {[...Array(7)].map((x, i) => {
                            return (
                                <th key={i + "days"} style={{width: '9%', background: 'var(--primary)', color: '#fefefe', textAlign: 'center', verticalAlign: 'middle'}}>
                                    {dayOfWeek[add(startDate, {days: i}).getDay()].slice(0,3)}<br />{add(startDate, {days: i}).toISOString().slice(8,10)}/{add(startDate, {days: i}).toISOString().slice(5,7)}
                                </th>
                            )
                        })}
                        <th style={{background: 'var(--primary)', textAlign: 'right', color: '#fefefe'}}>{anyTimeTrackingContracts ? "Totals" : ""}</th>
                    </tr>
                </thead>
                <tbody>
                    { isQELoaded
                        ? ( 
                            filteredContracts.length === 0 ? 
                            <tr>
                                <td colSpan={9}>
                                    <NoContentSlate whiteBg type={DatagridType.QuickEntryTimesheet} termsKey="emptyTerms" />
                                </td>
                            </tr>:
                            filteredContracts.map((i,j) => {
                                return(
                                    <React.Fragment key={i.id}>
                                        <tr>
                                            <td style={{background: '#f2f2f2', verticalAlign: 'middle'}} colSpan={9}>
                                                <b>{i.clientName} / {i.name}</b>
                                            </td>
                                        </tr>
                                        {showTimeTracking(i) && i.tasks &&
                                            i.tasks.filter(x => x.active || taskHasTime(x.id)).map((t,f) => {
                                                    return (
                                                        <tr data-cy="timeWeekTableRow" key={t.id}>
                                                            <td>
                                                                {t.description} {i.contractRateType && <span>({getContractRateName(i.contractRateType)})</span>}
                                                            </td>
                                                            <TimeWeekRow
                                                                unit={t.contractRateType || ''}
                                                                setCurVals={updateArrValues}
                                                                taskId={t.id}
                                                                curVals={weekArr && weekArr?.find(x => x.id === t.id)
                                                                    ? weekArr?.find(x => x.id === t.id)?.values || []
                                                                    : []}
                                                            />
                                                        </tr>
                                                    )
                                                
                                                
                                            })
                                        }

                                        {i.hasDefaultJourneySet && i.enableMileageTracking && (
                                            <tr>
                                                <td>Default Mileage</td>
                                                {[...Array(7)].map((_, f) => {
                                                    let curDay = asYearMonthDay(add(startDate, {days: f}))
                                                    let hasMileage = quickEntryData?.find(x => x.contractId === i.id && x.entryType === "M" && x.date.split('T')[0] === curDay)
                                                    let curItem = defaultMileageArr.find(x => x[0] === i.id && x[1] === curDay)
                                                    return (<td style={{textAlign: showTimeTracking(i) ? 'right' : 'center'}}>
                                                        {/* if we already have mileage for a given day for a given contract we remove the box */}
                                                        {hasMileage ? hasMileage.amount + ' miles' : <input checked={curItem ? curItem[2] : false} onChange={() => handleMileageChange(i.id, curDay)} type="checkbox" />}
                                                    </td>)
                                                })}
                                                <td></td>
                                            </tr>
                                        )}

                                        {showTimeTracking(i) && <tr style={{borderBottom: '2px solid #CCC'}}>
                                            <td style={{background: '#f2f2f2'}}>Total:</td>
                                            {[...Array(7)].map((_, f) => {
                                                return (
                                                    <td data-cy="dayTotal" style={{background: '#f2f2f2', verticalAlign: 'middle', textAlign: 'right'}} key={f + "days"}>
                                                        {weekArr?.filter(x => Number(x.contractId) === i.id).reduce((a,v) => a = a + v.values[f].value, 0).toFixed(2)}{i.contractRateType}
                                                    </td>
                                                )
                                            })}
                                            <td style={{background: '#f2f2f2', verticalAlign: 'middle', textAlign: 'right'}}>
                                                {weekArr?.filter(x => Number(x.contractId) === i.id).reduce((a,v) => a = a + v.values.reduce((b,c) => b + c.value, 0), 0).toFixed(2)}{i.contractRateType}
                                            </td>
                                        </tr>}
                                    </React.Fragment>
                                )
                            })
                        )
                        : (
                            <>
                                <DataGrid.LoadingRow cols={9} />
                                <DataGrid.LoadingRow cols={9} />
                            </>
                        )
                    }
                </tbody>
                <tfoot>
                    <tr style={{ padding: '20px' }}>
                        <td colSpan={9}>
                            <Button disabled={!valsTouched} buttonType="save" onClick={submitData} />
                            <Button disabled={!valsTouched} buttonType="cancel" onClick={() => {setWeekArrLoaded(false); setValsTouched(false); setDefaultMileageArr([])}} />
                        </td>   
                    </tr>
                </tfoot>
            </DataGrid.Table>
        </div>
    )

}

export default TimeWeekView