import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikErrors, FormikHelpers } from 'formik';
import { useContext, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Accounts } from '../../../api/inni/Accounts';
import { Account, CreditorsPostModel, SetupWizardForm } from '../../../api/inni/data-contracts';
import { SetupWizard } from '../../../api/inni/SetupWizard';
import CompanyContext from '../../../context/CompanyContext';
import { Button } from '../../../elements/Button/Button';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { ListOption } from '../../../elements/EditRow/EditRow';
import { FormikRowInput, FormikRowLogic } from '../../../elements/FormikTableEditor/FormikTableEditor';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { useModalDialog } from '../../../hooks/useModalDialog';
import { formatDate } from '../../../utils/formatDate';
import { formatCurrency } from '../../../utils/formatNumbers';
import { formatAccount } from '../../../utils/formatters/formatAccount';

const Creditors = ({formState, setFormState} : {formState : SetupWizardForm, setFormState : (i : SetupWizardForm) => void}) => {

    const swAPI = useInniAPI(SetupWizard, [400])
    const companyContext = useContext(CompanyContext)

    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts)

    const [creating, setCreating] = useState(false)
    const [editingRow, setEditingRow] = useState<number | undefined>(undefined)

    const [showModalDialog, modalDialog] = useModalDialog();

    const accountName = (id?: number) => {
        if (id) {
            const account = accounts.find(a => a.id === id)
            return account ? account.name : undefined
        }
        return undefined;
    }

    const accountBadge = (id?: number) => {
        const name = accountName(id);
        if (name) {
            const meta = formatAccount(name);
            return <Badge pill style={{backgroundColor: '#fff', fontWeight: 'normal', color: meta.color, border: `1px solid ${meta.color}`, marginLeft: '0.5em'}}><FontAwesomeIcon icon={meta.icon} /> {name}</Badge>
        }
    }

    const validateCreate = (values: CreditorsPostModel) : Promise<FormikErrors<CreditorsPostModel>>=> {
        return new Promise((resolve, reject) => {
            if (swAPI) {
                swAPI.validateCreateCreditor(companyContext.cid, values)
                .then(res => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    const validateUpdate = (id: number, values: CreditorsPostModel) : Promise<FormikErrors<CreditorsPostModel>>=> {
        return new Promise((resolve, reject) => {
            if (swAPI) {
                swAPI.validateUpdateCreditor(companyContext.cid, { id: id }, values)
                .then(res => resolve({}))
                .catch(err => resolve(err.error))
            }
        })
    }
    const createCall = (values: CreditorsPostModel, actions: FormikHelpers<CreditorsPostModel>) : Promise<number> => {
        return new Promise((resolve, reject) => {
            if (swAPI) {
                swAPI.createCreditor(companyContext.cid, values)
                .then(res => {
                    let tempFs = formState
                    if (!tempFs.generalCreditors) tempFs.generalCreditors = []
                    tempFs.generalCreditors?.push({...values, id : parseInt(res.data), amountPaid: 0, unknownAmount: false, accountID: values.accountId, companyID:companyContext.cid})
                    setFormState(tempFs)
                    setCreating(false)
                    resolve(parseInt(res.data))
                })
            }
        })
    }
    const editCall = (values: CreditorsPostModel, actions: FormikHelpers<CreditorsPostModel>) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (swAPI) {
                let id = formState.generalCreditors ? formState.generalCreditors[editingRow || 0].id : 0
                swAPI.updateCreditor(companyContext.cid, { id: id }, values)
                .then(res => {
                    if (res.status === 200) {
                        let tempFs = formState
                        let i = tempFs.generalCreditors?.findIndex(x => x.id === id)
                        if (i !== undefined && i !== -1 && tempFs.generalCreditors) {
                            tempFs.generalCreditors[i] = {...tempFs.generalCreditors[i], ...values}
                        }
                        setFormState(tempFs)
                        setEditingRow(undefined)
                    }
                    resolve(true)
                })
            }
        })
    }

    const generateAccountsSL = () => {
        let tempAccSL : ListOption[] = []

        if (accountsLoaded) {
            tempAccSL.push({ label: 'Unpaid payslips', value: accounts.find(x => x.linkedId === "NetWages")?.id.toString() || "" })
            tempAccSL.push({ label: 'Unpaid dividends', value: accounts.find(x => x.linkedId === "DividendLiability")?.id.toString() || "" })
        }
        return tempAccSL
    }

    const showDeleteDialog = (creditorId: number) => {
        showModalDialog(
            'Delete unpaid payment?',
            'Are you sure you want to delete this unpaid payment?',
            [
                <Button variant="danger" label="Yes" onClick={() => deleteCreditor(creditorId)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const deleteCreditor = (creditorId : number) => {
        if (swAPI) {
            swAPI.deleteCreditor(companyContext.cid, { id: creditorId })
            .then(res => {
                if (res.status === 200) {
                    let tempFs = formState
                    if (tempFs.generalCreditors) {
                        let i = tempFs.generalCreditors.findIndex(x => x.id === creditorId)
                        if (i !== -1) {
                            if (tempFs.generalCreditors.length === 1) {
                                tempFs.generalCreditors = []
                            } else {
                                tempFs.generalCreditors.splice(i, 1)
                            }
                        }
                    }
                    setFormState({...tempFs})
                }
            })
        }
    }

    //Setup formik instance to be attached to inputs
    const formik = FormikRowLogic<CreditorsPostModel>({
        validateUpdate: validateUpdate, 
        validateCreate: validateCreate, 
        onCreate: createCall, 
        onUpdate: editCall, 
        addingNewRow: creating,
        id: creating || !editingRow ? undefined : formState.generalCreditors ? formState.generalCreditors[editingRow || 0].id : undefined,
        editRowValues: creating || editingRow === undefined ? 
            { date: '', amount: 0, accountId: accountsLoaded ? parseInt(generateAccountsSL()[0].value || '') : 0 } as CreditorsPostModel : 
            formState.generalCreditors ? 
            {...formState.generalCreditors[editingRow || 0], date: formState.generalCreditors[editingRow || 0].date || '', accountId: formState.generalCreditors[editingRow || 0].accountID || 0} : undefined})

    return (
        <DataGrid.Table noHover>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Ref No</th>
                    <th>Description</th>
                    <th>Amount</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {formState.generalCreditors?.length === 0 && 
                <tr>
                    <td colSpan={4}>
                        <NoContentSlate termsKey="emptyTerms" whiteBg type={DatagridType.Transactions} />
                    </td>
                </tr>}
                {formState.generalCreditors?.map((x, i) => {
                    return (<>
                        {editingRow !== i &&
                        <tr>
                            <td>{formatDate(x.date)}</td>
                            <td>{x.refNo}</td>
                            <td>{x.description}<br />{accountBadge(x.accountID)}</td>
                            <td>{formatCurrency(x.amount)}</td>
                            <td style={{textAlign: 'right'}}>{generateAccountsSL().find(y => y.value === String(x.accountID)) && <>
                                <Button tableBtn onClick={() => setEditingRow(i)} buttonType="edit" />
                                <Button tableBtn onClick={() => showDeleteDialog(x.id)} buttonType="delete" />
                            </>}</td>
                        </tr>}
                        {editingRow === i && <>
                        <tr>
                            <td>
                                <label>Date</label>
                                <FormikRowInput<CreditorsPostModel> formik={formik} property="date" type="date"  />
                            </td>
                            <td>
                                <label>Ref no</label>
                                <FormikRowInput<CreditorsPostModel> formik={formik} property="refNo" type="text"  />
                            </td>
                            <td>
                                <label>Description</label>
                                <FormikRowInput<CreditorsPostModel> formik={formik} property="description" type="text"  />
                            </td>
                            <td>
                                <label>Amount</label>
                                <FormikRowInput<CreditorsPostModel> formik={formik} property="amount" type="number" prefix="£"  />
                            </td>
                            <td>
                                <label>Payment type</label>
                                <FormikRowInput<CreditorsPostModel> formik={formik} property="accountId" type="select" options={generateAccountsSL()    } />
                            </td>
                        </tr>
                        <tr>                            
                            <td colSpan={4}></td>
                            <td>
                                <Button onClick={formik.submitForm} buttonType="save" />
                                <Button buttonType="cancel" onClick={() => setEditingRow(undefined)} />
                            </td>
                        </tr>
                        </>}
                    </>)
                })}

                {creating && <>
                <tr>
                    <td>
                        <label>Date</label>
                        <FormikRowInput<CreditorsPostModel> formik={formik} property="date" type="date"  />
                    </td>
                    <td>
                        <label>Ref no</label>
                        <FormikRowInput<CreditorsPostModel> formik={formik} property="refNo" type="text"  />
                    </td>
                    <td>
                        <label>Description</label>
                        <FormikRowInput<CreditorsPostModel> formik={formik} property="description" type="text"  />
                    </td>
                    <td>
                        <label>Amount</label>
                        <FormikRowInput<CreditorsPostModel> formik={formik} property="amount" type="number" prefix="£"  />
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <label>Payment type</label>
                        <FormikRowInput<CreditorsPostModel> formik={formik} property="accountId" type="select" options={generateAccountsSL()    } />
                    </td>
                    <td colSpan={4}></td>
                </tr>
                </>}
            </tbody>
            <tfoot> 
                <tr>
                    <td colSpan={6}>
                        {!creating && <Button onClick={() => {setEditingRow(undefined); setCreating(true)}} variant="change">New Payment</Button>}
                        {creating && <>
                            <Button onClick={formik.submitForm} buttonType="save" />
                            <Button onClick={() => setCreating(false)} buttonType="cancel" />
                        </>}
                    </td>
                </tr>
            </tfoot>
            { modalDialog }
        </DataGrid.Table>
    )
}

export default Creditors