import { useContext, useEffect, useState } from 'react'
import { PersonAsDetailed } from '../../../api/inni/data-contracts'
import CompanyContext from '../../../context/CompanyContext'
import { useInniAPI } from '../../../hooks/useInniAPI'
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { Payslips as PayslipsAPI } from '../../../api/inni/Payslips'
import { formatDate } from '../../../utils/formatDate';
import { Payslip } from '../../../api/inni/data-contracts';
import { formatCurrency } from '../../../utils/formatNumbers';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { Action, Entity } from '../../../utils/EntityAction';
import { usePagination } from '../../../hooks/usePagination';
import { Pagination } from 'react-bootstrap';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';

const Payslips = ({person} : {person : PersonAsDetailed | undefined}) => {

    const payslipApi = useInniAPI(PayslipsAPI)
    const companyContext = useContext(CompanyContext)

    const [payslips, setPayslips] = useState<Payslip[] | undefined>(undefined)
    const [payslipsLoaded, setPayslipsLoaded] = useState(false)

    const nav = useNavigateToEntity()

    const pagination = usePagination(payslips || [], 30, undefined,'date', false)

    useEffect(() => {
        if (payslipApi && person && person.employmentId) {
            payslipApi.getForEmployment(companyContext.cid, person.employmentId)
            .then(res => {
                setPayslips(res.data)
                setPayslipsLoaded(true)
            })
        }
    }, [companyContext.cid, person, payslipApi])

    return (<>
    <DataGrid.Table noHover={payslips && payslipsLoaded && payslips.length === 0}>
        <thead>
            <tr>
                <th>Date</th>
                <th>Tax year</th>
                <th>Period</th>
                <th>Salary</th>
                <th>Statutory</th>
                <th>Mileage</th>
                <th>Expenses</th>
                <th>Tax & NIC</th>
                <th>Total pension contribution</th>
                <th>Net payment</th>
            </tr>
        </thead>
        <tbody>
            {payslips && payslipsLoaded && payslips.length === 0 && <tr><td colSpan={11}><NoContentSlate whiteBg termsKey="emptyTerms" type={DatagridType.Payslips} /></td></tr>}
            {/* Go to MVC details page on row click for now */}
            {payslips && payslipsLoaded ?
            pagination.getCurrentData().map(x => 
            <tr onClick={() => nav(Entity.Payslip, Action.Read, { 'cid' : companyContext.cid, 'psid' : x.id })} key={x.id}>
                <td>{formatDate(x.date)}</td>
                <td>{x.taxYear}</td>
                <td>{x.taxPeriodName}</td>
                <td>{formatCurrency(x.salaryGross)}</td>
                <td>{formatCurrency(x.totalSP)}</td>
                <td>{formatCurrency(x.mileageGross)}</td>
                <td>{formatCurrency(x.expensesGross)}</td>
                <td>{formatCurrency(x.totalTaxNIC)}</td>
                <td>{formatCurrency(x.totalPensionContribution)}</td>
                <td>{formatCurrency(x.totalPayment)}</td>   
            </tr>) : <>
                <DataGrid.LoadingRow cols={10} />
                <DataGrid.LoadingRow cols={10} />
                <DataGrid.LoadingRow cols={10} />
            </>}
        </tbody>
    </DataGrid.Table>
    <div style={{justifyContent: 'flex-end', width: '100%', display: 'flex'}}>
        <Pagination>
            {pagination.getControls()}
        </Pagination>
    </div>
    </>)
}

export default Payslips