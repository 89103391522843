/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { StatutoryPayment, StatutoryPaymentPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class StatutoryPayments<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags StatutoryPayments
   * @name GetForEmployment
   * @request GET:/api/v2/companies/{companyId}/employments/{employmentId}/StatutoryPayments
   * @secure
   */
  getForEmployment = (companyId: number, employmentId: number, params: RequestParams = {}) =>
    this.request<StatutoryPayment[], any>({
      path: `/api/v2/companies/${companyId}/employments/${employmentId}/StatutoryPayments`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags StatutoryPayments
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/employments/{employmentId}/StatutoryPayments/ValidateCreate
   * @secure
   */
  validateCreate = (
    companyId: number,
    employmentId: number,
    model: StatutoryPaymentPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/employments/${employmentId}/StatutoryPayments/ValidateCreate`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags StatutoryPayments
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/employments/{employmentId}/StatutoryPayments/Create
   * @secure
   */
  create = (companyId: number, employmentId: number, model: StatutoryPaymentPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/employments/${employmentId}/StatutoryPayments/Create`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
