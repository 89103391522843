import { useContext, useEffect, useState } from "react"
import { getPaymentTypeShort, getPaymentTypeLong, ProcessorType } from "./BankingTypes"
import * as DataGrid from '../../elements/DataGrid/DataGrid';
import { AccountWithOLBS, Asset, BankTransactionAsDetailed, BankTransferModel, Market, PendingPaymentModel, VatSetting } from "../../api/inni/data-contracts"
import { BankTransaction, Account } from '../../api/inni/data-contracts';
import { GroupedListOption } from "../../elements/EditRow/EditRow"
import { accountsBank, accountsBankAndManualExpenses, accountsExpenditurePersonal, accountsIncome } from "../../utils/accountsFilterHelper"
import { Button } from "../../elements/Button/Button"
import { useInniAPI } from "../../hooks/useInniAPI";
import CompanyContext from "../../context/CompanyContext";
import { NoContentSlate } from "../../elements/Slates/NoContentSlate";
import { DatagridType } from "../../hooks/terms/useDatagridTerms";
import { useModalDialog } from "../../hooks/useModalDialog";
import { Receipts } from "../../api/inni/Receipts";
import { FileUploadModal } from "../FileUploadModal/FileUploadModal";
import NewTransactionDropdown from "./Components/NewTransactionDropdown";
import PendingPayments from "./Components/PendingPayments";
import QuickView from "./Components/QuickView";
import { Alert, DropdownButton } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { VatSettings } from "../../api/inni/VatSettings";
import { BankTransactions } from "../../api/inni/BankTransactions";
import { Markets } from "../../api/inni/Markets";
import styles from './AccountViewerNew.module.css'
import { BankPaymentModalEditor } from "./Components/BankPaymentModalEditor";
import { BankTransferModalEditor } from "./Components/BankTransferModalEditor";
import { ReadOnlyTransactionModal } from "./Components/ReadOnlyTransactionModal";
import { asYearMonthDay } from "../../utils/formatDate";
import ReceiptModal from "./Components/ReceiptModal";

export enum AccountViewerFeatureSet {
    BOOKKEEPING = 'bookkeeping',
    QUICKENTRY = 'quickentry',
    RECEIPTS = 'receipts',
    PROPERTY_TRANSACTIONS = 'property_transactions',
    NONE = 'none'
} // none - we have no special features available

interface BookkeepingProps {
    accounts: Account[],
    transactionsExist: boolean,
    transactions: BankTransaction[],
    pendingPayments?: PendingPaymentModel[],
    assets?: Asset[],
    bankAccountId: number,
    searching: boolean,
    afterCreateBankPayment: (pendingId?: number, type?: string, date?: string, reloadAssets?: boolean) => void,
    afterUpdateBankPayment: () => void,
    onDeleteBankPayment: (id:number, reloadAssets? : boolean) => void,
    onDeleteBankTransfer: (id:number) => void,
    reloadTransactions: () => void,
    featureSet?: AccountViewerFeatureSet,
    currentAccount?: AccountWithOLBS
    initialModalSourceId?: string;
}

const TransactionTableHead = ({featureSet, account} : {featureSet : AccountViewerFeatureSet, account?: Account}) => (
    <thead>
        <tr>
            {/* Thumbnail */}
            {featureSet === AccountViewerFeatureSet.RECEIPTS && (
                <th>Thumbnail</th>
            )}

            {/* Date */}
            <th style={{width: '10%'}}>Date</th>

            {/* Paid to/from */}
            <th style={{width: '15%'}}>
                { featureSet === AccountViewerFeatureSet.QUICKENTRY || featureSet === AccountViewerFeatureSet.RECEIPTS
                    ? 'Paid to'
                    : 'Paid to / from'
                }
            </th>

            {/* Description */}
            <th style={{width: '30%'}}>Description</th>

            {/* Repaid */}
            {(featureSet === AccountViewerFeatureSet.QUICKENTRY && (!account || !account.payExpensesViaPayroll)) && (
                <th>Repaid</th>
            )}

            {/* Paid in */}
            {(featureSet === AccountViewerFeatureSet.NONE ||
                featureSet === AccountViewerFeatureSet.BOOKKEEPING ||
                featureSet === AccountViewerFeatureSet.PROPERTY_TRANSACTIONS) && (
                    <th style={{width: '10%'}}>Paid in</th>
            )}

            {/* Paid out */}
            {(featureSet === AccountViewerFeatureSet.NONE ||
                featureSet === AccountViewerFeatureSet.BOOKKEEPING ||
                featureSet === AccountViewerFeatureSet.QUICKENTRY ||
                featureSet === AccountViewerFeatureSet.PROPERTY_TRANSACTIONS) && (
                    <th style={{width: '10%'}}>Paid out</th>
            )}

            {/* Amount */}
            {(featureSet === AccountViewerFeatureSet.RECEIPTS) && <th>Amount</th>}

            {/* Balance */}
            {(featureSet === AccountViewerFeatureSet.BOOKKEEPING ||
                (featureSet === AccountViewerFeatureSet.QUICKENTRY && (!account || !account.payExpensesViaPayroll))) && (
                    <th style={{width: '10%'}}>Balance</th>
            )}

            {/* Match */}
            {(featureSet === AccountViewerFeatureSet.BOOKKEEPING) && (
                <th style={{width: '7.5%'}}>Match</th>
            )}

            {/* Receipt */}
            {featureSet !== AccountViewerFeatureSet.RECEIPTS
                ? <th style={{width: '7.5%'}}>Receipt</th>
                : <th></th>
            }
        </tr>
    </thead>
)

export const AccountViewerNewLoading = ({featureSet, account} : {featureSet : AccountViewerFeatureSet, account?: Account}) => {
    const colCount = featureSet === AccountViewerFeatureSet.BOOKKEEPING ? 8 : featureSet === AccountViewerFeatureSet.QUICKENTRY ? 7 : 6
    return (
        <DataGrid.Table noHover>
            <TransactionTableHead featureSet={featureSet} account={account} />
            <tbody>
                <DataGrid.LoadingRow cols={colCount} />
                <DataGrid.LoadingRow cols={colCount} />
                <DataGrid.LoadingRow cols={colCount} />
            </tbody>
            {(featureSet === AccountViewerFeatureSet.BOOKKEEPING || featureSet === AccountViewerFeatureSet.QUICKENTRY) && <tfoot>
                <tr>
                    <td colSpan={8}>
                        <DropdownButton
                            className="d-inline mr-2"
                            title={<><FontAwesomeIcon icon={faPlus} /> Enter new transaction </>}
                            disabled={true}
                        />
                        {featureSet === AccountViewerFeatureSet.QUICKENTRY && <Button
                            buttonType="import"
                            disabled={true}
                            label="Import expenses from CSV"
                            onClick={() => {}}
                        />}
                    </td>
                </tr>
            </tfoot>}
        </DataGrid.Table>
    )
}

export const AccountViewerNew = ({accounts, transactionsExist, transactions,
    pendingPayments, bankAccountId, assets,
    searching, currentAccount, afterCreateBankPayment,
    afterUpdateBankPayment, onDeleteBankPayment, onDeleteBankTransfer,
    reloadTransactions, featureSet = AccountViewerFeatureSet.NONE, initialModalSourceId} : BookkeepingProps) => {

    const rAPI = useInniAPI(Receipts);
    const vatSettingAPI = useInniAPI(VatSettings, [403])
    const bankTransactionsAPI = useInniAPI(BankTransactions, [400]);
    const marketApi = useInniAPI(Markets)

    const [uploadingFile, setUploadingFile] = useState(false)

    const companyContext = useContext(CompanyContext)

    const [showModalDialog, modalDialog] = useModalDialog();

    const [accountOptions, setAccountOptions] = useState<GroupedListOption[]>([])
    const [paymentType, setPaymentType] = useState<string>()
    const [journalLineId, setJournalLineId] = useState<number>();
    const [isEditing, setIsEditing] = useState(false);
    const [pendingPaymentToAdd, setPendingPaymentToAdd] = useState<PendingPaymentModel>();
    const [assetToSell, setAssetToSell] = useState<Asset>();
    const [bankPaymentDetail, setBankPaymentDetail] = useState<BankTransactionAsDetailed>();
    const [bankTransferDetail, setBankTransferDetail] = useState<BankTransferModel>();

    const [receiptModal, setReceiptModal] = useState<BankTransaction | undefined>(undefined)
    const [uploadingModal, setUploadingModal] = useState<BankTransaction | undefined>(undefined)

    const [vatSetting, setVatSetting] = useState<VatSetting | undefined>(undefined)
    const [vatSettingLoaded, setVatSettingLoaded] = useState(false)

    const [market, setMarket] = useState<Market | undefined>(undefined)
    const [marketLoaded, setMarketLoaded] = useState(false)

    const alertShowTime = 2000 // the time to show alerts for (popups on btm of screen, mainly relate to images)
    const [successMessage, setSuccessMessage] = useState<String | undefined>(undefined)

    const [showModalInner, setShowModalInner] = useState(false) //A little hacky, but we need to delay the modal rendering so the values can correctly update within it

    

    //==================//

    //=========Market Model=========//  
    useEffect(() => {
        if (marketApi && !marketLoaded) {
            marketApi.index(companyContext.cid)
            .then(res => {
                setMarket(res.data)
                setMarketLoaded(true)
            })
        }
    }, [companyContext.cid, marketApi, marketLoaded])

    //=== Get as detailed ===//
    const getBankPaymentAsDetailed = (id:number) : Promise<BankTransactionAsDetailed> => {
        return new Promise((resolve, reject) => {
            bankTransactionsAPI && bankTransactionsAPI.getPaymentAsDetailed(companyContext.cid, id).then(results => {
                if (results.data) {
                    resolve(results.data)
                }
            }).catch(error => {
                console.error(error)
            })
        })
    }

    const getBankTransferAsDetailed = (id:number) : Promise<BankTransferModel> => {
        return new Promise((resolve, reject) => {
            bankTransactionsAPI && bankTransactionsAPI.getBankTransfer(companyContext.cid, id).then(results => {
                if (results.data) {
                    resolve(results.data)
                }
            }).catch(error => {
                console.error(error)
            })
        })
    }

    //=== VATSettings ===//

    useEffect(() => {
        if (vatSettingAPI && !vatSettingLoaded) {
            vatSettingAPI.getAtDate(companyContext.cid, { date: asYearMonthDay(new Date()) })
            .then(x => {
                setVatSetting(x.data)
                setVatSettingLoaded(true)
            })
        }
    }, [companyContext.cid, vatSettingAPI, vatSettingLoaded])

    //==================//

    const setAccountsDropdown = (type: ProcessorType) => {
        let tempOptions: Array<GroupedListOption> = []
        let dropDownAccounts: Array<Account> = []
        switch(type) {
            case ProcessorType.recurringPayment:
            case ProcessorType.expense:
                dropDownAccounts = accountsExpenditurePersonal(accounts) // shouldn't show dir loans on QE
                break;
            case ProcessorType.otherIncome:
                dropDownAccounts = accountsIncome(accounts)
                break;
            case ProcessorType.recurringTransfer:
            case ProcessorType.creditCardPayment:
                dropDownAccounts = accountsBank(accounts)
                break;
            case ProcessorType.transfer:
                dropDownAccounts = accountsBankAndManualExpenses(accounts)
                break;           
        }

        dropDownAccounts.forEach(i => {
            const exists = tempOptions.findIndex(x => x.label === i.accountSubGroup)
            if (exists === -1) {
                tempOptions.push({
                    label: i.accountSubGroup || '',
                    options: [{value: i.id.toString(), label: i.name || ''}]
                })
            } else {
                tempOptions[exists].options?.push({value: i.id.toString(), label: i.name || ''})
            }
        })
        tempOptions.length > 0 && setAccountOptions(tempOptions)
    }

    //=======================//

    const afterCreateBP = (pendingId?: number, type?: string, date?: string, reloadAssets?: boolean) : void => {
        setSuccessMessage("Successfully created bank transaction")
        setTimeout(() => setSuccessMessage(undefined), alertShowTime)
        afterCreateBankPayment(pendingId, type, date, reloadAssets)
    }

    //=====================//

    const resetEditor = () => {
        //Full reset!
        setIsEditing(false)
        setJournalLineId(undefined)
        setPendingPaymentToAdd(undefined)
        setBankPaymentDetail(undefined)
        setBankTransferDetail(undefined)
        setAssetToSell(undefined)
        showModalInner && setTimeout(() => setShowModalInner(false), 150)
    }

    const setupModalData = (txn:BankTransaction) => {
        resetEditor()
        setJournalLineId(txn.journalLineId);
        if(txn.sourceID !== undefined && txn.sourceID !== null) {
            setBankPaymentDetail(undefined); setBankTransferDetail(undefined)
            if(txn.journalTypeId === "BP") {
                getBankPaymentAsDetailed(txn.sourceID).then(res => {setBankPaymentDetail(res)})
            } else if(txn.journalTypeId === "BT") {
                getBankTransferAsDetailed(txn.sourceID).then(res => {setBankTransferDetail(res)})
            }

            setupEditorForType(getPaymentTypeLong(txn.type || ""), (txn.journalTypeId === "MJ" || txn.journalTypeId === "PS" || txn.journalTypeId === "FX"))

        } else {
            setupEditorForType(null, true) // if we don't have a source ID we use the readonly modal to show date, amount and description (this is the case for Opening balances, YE Balances and maybe some other stuff)
        }
    }

    const allowBTChanges = (bt:BankTransferModel) => {
        if(currentAccount && currentAccount.strictReconciliation) {
            const bankAccountTo = accounts.find(x => x.id === bt.bankAccountToId)
            const bankAccountFrom = accounts.find(x => x.id === bt.bankAccountFromId)
            const baToIsDLA = bankAccountTo && bankAccountTo.accountSubGroup === "DirectorsLoans"
            const baFromIsDLA = bankAccountFrom && bankAccountFrom.accountSubGroup === "DirectorsLoans"

            const allowDeleteTo = !bt.confirmedTo || baToIsDLA
            const allowDeleteFrom = !bt.confirmedFrom || baFromIsDLA
            return allowDeleteTo === true && allowDeleteFrom === true
        }
        return true
    }

    const deleteBankPaymentDialog = (payment: BankTransaction) => {
        showModalDialog(
            'Delete transaction?',
            `Are you sure you want to delete this bank transaction?`,
            [
                <Button key="delete" variant="danger" label="Yes" onClick={() => {onDeleteBankPayment(payment.sourceID || 0, payment.type === "AS" || payment.type === "AP"); resetEditor()}} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const deleteBankTransferDialog = (transfer: BankTransferModel) => {
        showModalDialog(
            'Delete transaction?',
            `Are you sure you want to delete this bank transaction?`,
            [
                <Button key="delete" variant="danger" label="Yes" onClick={() => {onDeleteBankTransfer(transfer.id); resetEditor()}} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const setupPendingPayment = (pendingPayment: PendingPaymentModel) => {
        setPendingPaymentToAdd(pendingPayment)
        setupEditorForType(getPaymentTypeLong(pendingPayment.processorTypeShortString || ""))
    }

    const setupEditorForType = (typeLong: string|null, readOnlyMode?: boolean) => {
        const currentTxn = transactions?.find(x => x.journalLineId === journalLineId)
        if(readOnlyMode) {
            setPaymentType("ReadOnly")
        }
        else if(typeLong && typeLong !== ProcessorType.transfer && typeLong !== ProcessorType.recurringTransfer) {
            setAccountsDropdown(ProcessorType[typeLong as keyof typeof ProcessorType]); 
            setPaymentType(getPaymentTypeShort(ProcessorType[typeLong as keyof typeof ProcessorType]));
        } 
        else if(currentTxn?.journalTypeId !== "BP" || typeLong === ProcessorType.recurringTransfer) {
            setAccountsDropdown(ProcessorType.transfer); 
            setPaymentType(getPaymentTypeShort(ProcessorType.transfer));
        }

        setShowModalInner(true)
        setIsEditing(true)
    }

    // === Receipts Stuff === //

    const showDeleteReceiptDialog = (documentId : number, paymentId : number) => {
        showModalDialog(
            'Delete receipt?',
            `Are you sure you want to delete this receipt?`,
            [
                <Button key="delete" variant="danger" label="Yes" onClick={() => deleteReceipt(documentId, paymentId)} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const detatchReceipt = (documentId : number) => {
        rAPI?.detatch(companyContext.cid, documentId)
        .then(res => {
            if (res.status === 200) {
                setSuccessMessage("Successfully moved to inbox!")
                setTimeout(() => setSuccessMessage(undefined), alertShowTime)
                reloadTransactions()
                setReceiptModal(undefined)
            } else {
                console.error(`Unexpected response code: ${res.status}`)
            }
        })
    }

    const deleteReceipt = (documentId : number, paymentId : number) => {
        rAPI?.deleteAttachedReceipt(companyContext.cid, documentId, paymentId)
        .then(res => {
            if (res.status === 200) {
                setSuccessMessage("Successfully deleted receipt")
                setTimeout(() => setSuccessMessage(undefined), alertShowTime)
                reloadTransactions()
                setReceiptModal(undefined)
            } else {
                console.error(`Unexpected response code: ${res.status}`)
            }
        })
    }

    const uploadReceipt = (file : File, paymentId : number) => {
        setUploadingFile(true)
        rAPI?.uploadForBankPayment(companyContext.cid, paymentId, { fileToUpload: file })
        .then(res => {
            if (res.status === 200) {
                setSuccessMessage("Successfully uploaded receipt")
                setTimeout(() => setSuccessMessage(undefined), alertShowTime) // create and remove alert after 2000 seconds
                reloadTransactions()
                setUploadingModal(undefined)
            } else {
                console.error(`Unexpected response code: ${res.status}`)
            }
            setUploadingFile(false)
        })
    }

    const onNewDropDownSelect = (typeLong: string | null, assetToSell?: Asset | undefined) => {
        resetEditor()
        typeLong && setupEditorForType(typeLong)
        assetToSell && setAssetToSell(assetToSell)
    }

    const closeAndReload = () => {
        resetEditor()
        setSuccessMessage("Successfully updated bank transaction")
        setTimeout(() => setSuccessMessage(undefined), alertShowTime)
        afterUpdateBankPayment()
    }

    const convertToAsset = (txn : BankTransaction) => {
        if (txn.sourceID && txn.type === "EX" && bankTransactionsAPI) {
            bankTransactionsAPI.convertExpenseToAsset(companyContext.cid, { bpId: txn.sourceID }).then(res => closeAndReload())
        }
    }

    const showAssetConvertConfirm = (txn: BankTransaction) => {
        showModalDialog(
            'Convert to asset',
            'Are you sure you want to convert this expense to an asset?',
            [
                <Button key="yes" variant="danger" label="Yes" onClick={() => convertToAsset(txn)} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    return (
        <>
            <DataGrid.Table noHover>
                <TransactionTableHead account={currentAccount} featureSet={featureSet} />
                <tbody id="transactionTableBody">
                    {transactions && transactions.map((txn, index) =>  {
                            return <QuickView
                                key={index}
                                txn={txn} 
                                currentAccount={currentAccount} 
                                searching={searching}
                                setupModalData={setupModalData} 
                                setUploadingModal={setUploadingModal} 
                                setReceiptModal={setReceiptModal}
                                accounts={accounts}
                                featureSet={featureSet}
                                deleteReceipt={showDeleteReceiptDialog}
                                initialModalSourceId={initialModalSourceId ? +initialModalSourceId : undefined}
                            />
                    })}
                    {transactionsExist && transactions.length === 0 && 
                        <tr>
                            <td colSpan={8}>
                                <NoContentSlate
                                    whiteBg
                                    termsKey="noResultsTerms"
                                    type={featureSet === AccountViewerFeatureSet.PROPERTY_TRANSACTIONS
                                        ? DatagridType.PropertyTransactions
                                        : DatagridType.Transactions
                                    }
                                />
                            </td>
                        </tr>
                    }
                    {!transactionsExist && transactions.length === 0 &&
                        <tr>
                            <td colSpan={8}>
                                { featureSet === AccountViewerFeatureSet.PROPERTY_TRANSACTIONS && (
                                    <NoContentSlate
                                        whiteBg
                                        termsKey="emptyTerms"
                                        type={DatagridType.PropertyTransactions}
                                    />
                                )}
                                { featureSet === AccountViewerFeatureSet.QUICKENTRY && (
                                    <NoContentSlate
                                        whiteBg
                                        termsKey="emptyTerms"
                                        type={DatagridType.Expenses}
                                    />
                                )}
                                { (featureSet !== AccountViewerFeatureSet.QUICKENTRY && featureSet !== AccountViewerFeatureSet.PROPERTY_TRANSACTIONS) && (
                                    <NoContentSlate
                                        whiteBg
                                        termsKey="emptyTerms"
                                        type={currentAccount?.accountGroup === 'DirectorsLoans'
                                            ? DatagridType.LoanTransactions
                                            : DatagridType.Transactions
                                        }
                                    />
                                )}
                            </td>
                        </tr>
                    }
                    
                    {market && (featureSet === AccountViewerFeatureSet.QUICKENTRY || featureSet === AccountViewerFeatureSet.BOOKKEEPING) && 
                        <>
                            <NewTransactionDropdown 
                                assets={assets || []}
                                onNewDropDownSelect={onNewDropDownSelect}
                                journalLineId={journalLineId}
                                isQuickEntry={featureSet === AccountViewerFeatureSet.QUICKENTRY}
                                market={market}
                                isEditing={isEditing}
                                accounts={accounts}
                                accountId={currentAccount ? currentAccount.id : undefined}
                            />
                        </>
                    }

                </tbody>
            </DataGrid.Table>

            {featureSet === AccountViewerFeatureSet.BOOKKEEPING && pendingPayments && 
            <PendingPayments
                pendingPayments={pendingPayments}
                resetEditor={resetEditor}
                setupPendingPayment={setupPendingPayment}
            />}

            {currentAccount && currentAccount.fcaTermsNavigateUrl && <p>
                The account information shown here is provided by Inni Ltd. You can read the terms of use and complaints process <a rel="noreferrer" href={currentAccount.fcaTermsNavigateUrl} style={{textDecoration: 'underline'}} target="_blank">here</a>.
            </p>}
            
            { modalDialog }

            {/* Receipt modal */}
            {receiptModal &&
                <ReceiptModal 
                    documentId={receiptModal.documentId} 
                    hideModal={()=>setReceiptModal(undefined)}
                    deleteReceipt={deleteReceipt}
                    detachReceipt={detatchReceipt}        
                />
            }

            {/* File upload modal */}
            {uploadingModal && 
                <FileUploadModal 
                    onCancelClick={() => setUploadingModal(undefined)}
                    onFilesDropped={(acceptedFiles) => uploadReceipt(acceptedFiles[0], uploadingModal.sourceID || 0)}
                    maxFiles={1}
                    uploading={uploadingFile}
                    maxSizeMB={10}
                    accept={'documents'}
                />}

                {currentAccount && showModalInner && paymentType !== "TR" && paymentType !== "MJ" && paymentType !== "ReadOnly" &&
                <BankPaymentModalEditor
                    showModal={isEditing}
                    bankTransaction={transactions.find(x => x.journalLineId === journalLineId && x.journalTypeId === "BP")} 
                    bankPaymentToEdit={bankPaymentDetail} 
                    pendingPaymentToAdd={pendingPaymentToAdd?.newBankPaymentDisplay} 
                    paymentType={paymentType} 
                    id={transactions.find(x => x.journalLineId === journalLineId)?.sourceID} 
                    assetToSell={assetToSell}
                    closeEditor={resetEditor} 
                    afterCreate={afterCreateBP} 
                    afterUpdate={closeAndReload} 
                    currency={currentAccount.currency}
                    vatSetting={vatSetting}
                    strictReconciliation={currentAccount.strictReconciliation}
                    isQuickEntry={featureSet === AccountViewerFeatureSet.QUICKENTRY}
                    employeeId={currentAccount.personId}
                    bankAccountId={currentAccount.id}
                    accounts={accounts}
                    deleteBankPaymentDialog={deleteBankPaymentDialog}
                    setReceiptModal={setReceiptModal}
                    setUploadingModal={setUploadingModal}
                    showAssetConvertConfirm={showAssetConvertConfirm}
                    maxAssetValue={market?.maxAssetValue}
                    isBankFeedAndNotDetached={currentAccount.isBankFeedAndNotDetached}
                    BALatestConfirmedTransaction={currentAccount.latestConfirmedTransaction}
                />}

                {showModalInner && paymentType === "TR" && <BankTransferModalEditor
                    showModal={isEditing}
                    bankTransferToEdit={bankTransferDetail} 
                    id={transactions.find(x => x.journalLineId === journalLineId)?.sourceID} 
                    accountOptions={accountOptions} 
                    bankAccountId={bankAccountId}
                    closeEditor={resetEditor}
                    pendingPaymentToAdd={pendingPaymentToAdd}
                    afterCreate={afterCreateBP} 
                    afterUpdate={closeAndReload} 
                    currency={currentAccount?.currency}
                    allowBTChanges={allowBTChanges}
                    deleteBankTransferDialog={deleteBankTransferDialog}
                    setReceiptModal={setReceiptModal}
                    bankTransaction={transactions.find(x => x.journalLineId === journalLineId && x.journalTypeId === "BT")} 
                />}

                {currentAccount && showModalInner && (paymentType === "MJ" || paymentType === "ReadOnly") && <ReadOnlyTransactionModal 
                    closeEditor={resetEditor} 
                    showModal={isEditing} 
                    bankTransaction={transactions.find(x => x.journalLineId === journalLineId)} 
                    currency={currentAccount.currency}/>}

            <Alert
                show={successMessage !== undefined}
                variant="success" 
                dismissible 
                onClose={() => setSuccessMessage(undefined)}
                className={`${styles.alert} shadow`}
            >
                <FontAwesomeIcon size="lg" icon={faCheckCircle} /> {successMessage}
            </Alert>
        </>
    )
}
