/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Consultation,
  ConsultationPostModel,
  FactFindingForm,
  FactFindingFormPostModel,
  FactFindingSummary,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ContactConsultations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name Index
   * @request GET:/api/v2/contacts/{contactId}/consultations
   * @secure
   */
  index = (contactId: number, params: RequestParams = {}) =>
    this.request<Consultation[], any>({
      path: `/api/v2/contacts/${contactId}/consultations`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name Create
   * @request POST:/api/v2/contacts/{contactId}/consultations
   * @secure
   */
  create = (contactId: number, postModel: ConsultationPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/contacts/${contactId}/consultations`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name GetFactFindingForm
   * @request GET:/api/v2/contacts/{contactId}/consultations/GetForm
   * @secure
   */
  getFactFindingForm = (contactId: number, params: RequestParams = {}) =>
    this.request<FactFindingForm, any>({
      path: `/api/v2/contacts/${contactId}/consultations/GetForm`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name UpdateForm
   * @request PUT:/api/v2/contacts/{contactId}/consultations/UpdateForm/{id}
   * @secure
   */
  updateForm = (contactId: number, id: number, postModel: FactFindingFormPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/contacts/${contactId}/consultations/UpdateForm/${id}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name GetConsultationUrl
   * @request GET:/api/v2/contacts/{contactId}/consultations/GetConsultationUrl
   * @secure
   */
  getConsultationUrl = (contactId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/contacts/${contactId}/consultations/GetConsultationUrl`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name Get
   * @request GET:/api/v2/contacts/{contactId}/consultations/{consultationId}
   * @secure
   */
  get = (contactId: number, consultationId: number, params: RequestParams = {}) =>
    this.request<Consultation, any>({
      path: `/api/v2/contacts/${contactId}/consultations/${consultationId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name Update
   * @request PUT:/api/v2/contacts/{contactId}/consultations/{consultationId}
   * @secure
   */
  update = (contactId: number, consultationId: number, postModel: ConsultationPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/contacts/${contactId}/consultations/${consultationId}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name Delete
   * @request DELETE:/api/v2/contacts/{contactId}/consultations/{consultationId}
   * @secure
   */
  delete = (contactId: number, consultationId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/contacts/${contactId}/consultations/${consultationId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name ValidateUpdate
   * @request PUT:/api/v2/contacts/{contactId}/consultations/{ConsultationId}/Validate
   * @secure
   */
  validateUpdate = (
    contactId: number,
    consultationId: number,
    postmodel: ConsultationPostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/contacts/${contactId}/consultations/${consultationId}/Validate`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name ValidateCreate
   * @request POST:/api/v2/contacts/{contactId}/consultations/Validate
   * @secure
   */
  validateCreate = (contactId: number, postmodel: ConsultationPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/consultations/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name PreviousFactFindingForm
   * @request GET:/api/v2/contacts/{contactId}/consultations/PreviousFactFindingForm
   * @secure
   */
  previousFactFindingForm = (contactId: number, query: { id: number }, params: RequestParams = {}) =>
    this.request<FactFindingForm, any>({
      path: `/api/v2/contacts/${contactId}/consultations/PreviousFactFindingForm`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name FactFindingSummaries
   * @request GET:/api/v2/contacts/{contactId}/consultations/FactFindingSummaries
   * @secure
   */
  factFindingSummaries = (contactId: number, params: RequestParams = {}) =>
    this.request<FactFindingSummary[], any>({
      path: `/api/v2/contacts/${contactId}/consultations/FactFindingSummaries`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name UploadRecording
   * @request POST:/api/v2/contacts/{contactId}/consultations/{consultationId}/UploadRecording
   * @secure
   */
  uploadRecording = (
    contactId: number,
    consultationId: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/contacts/${contactId}/consultations/${consultationId}/UploadRecording`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactConsultations
   * @name DeleteRecording
   * @request DELETE:/api/v2/contacts/{contactId}/consultations/{consultationId}/Recording
   * @secure
   */
  deleteRecording = (contactId: number, consultationId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/contacts/${contactId}/consultations/${consultationId}/Recording`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
