import { Form, Formik, FormikErrors, FormikProps } from 'formik'
import { useCallback, useContext} from 'react'
import { ItrNotesPostModel, PersonAsDetailed } from '../../../api/inni/data-contracts'
import CompanyContext from '../../../context/CompanyContext'
import { Text as TextDetail } from '../../../elements/DetailRow/DetailRow'
import { Submit, Text } from '../../../elements/EditRow/EditRow'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { People } from '../../../api/inni/People'
import { TabEditProps } from '../People'
import { CompareValues } from '../../../utils/compareValues'
import { Button } from '../../../elements/Button/Button'
import { Entity, Action } from '../../../utils/EntityAction'


const ITR = ({person, setPerson, editing, setEditing,setDisabled}:
    {person : PersonAsDetailed | undefined, setPerson : (i : PersonAsDetailed | undefined) => void,setDisabled: (disabled: boolean) => void} & TabEditProps) => {

    const companyContext = useContext(CompanyContext)
    const peopleApi = useInniAPI(People)
	const refVal = useCallback(
		(node: FormikProps<any>) => {
			if (node !== null) {
				setDisabled(!CompareValues({ ...node.values }, { ...node.initialValues }, true));
			}
		},
		[setDisabled]
	);

    const validateUpdate = (values: ItrNotesPostModel) : Promise<FormikErrors<ItrNotesPostModel>> => {
        return new Promise((resolve) => {
            if (peopleApi) {
                peopleApi.validateUpdateItrNotes(companyContext.cid, person?.id || 0, values)
                .then(() => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const updateCall = (values: ItrNotesPostModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (peopleApi) {
                peopleApi.updateItrNotes(companyContext.cid, person?.id || 0, values)
                .then(res => {
                    if (res.status === 200) {
                        let tempPerson = person
                        if (tempPerson) {
                            tempPerson.itrNotes = values.itrNotes
                        }
                        setPerson(tempPerson)
                        setEditing(false)
                        resolve()
                    }
                }).catch(err => reject(err.error))
            }
        })
    }

    return (
        <>
            { person && (
                <>
                    { !editing
                        ? (
                            <>
                                <TextDetail editStyling entity={person} name="itrNotes" ensureRender label="ITR notes" />
                                <Button
                                    buttonType="edit"
                                    entity={Entity.PersonV7}
                                    action={Action.Edit}
                                    onClick={() => setEditing(true)}
                                    disabled={editing}
                                    marginTop
                                />
                            </>
                        )
                        : (
                            <Formik
                                initialValues={{ itrNotes: person.itrNotes } as ItrNotesPostModel}
                                validateOnChange={false}
                                enableReinitialize
                                onSubmit={updateCall}
                                validate={validateUpdate}
                                innerRef={refVal}
                            >
                                {({ isSubmitting }) => (

                                    <Form>
                                        <Text name="itrNotes" type="textarea" label="ITR notes" />

                                        <Submit disabled={isSubmitting} onCancelClick={() => setEditing(false)} />

                                    </Form>
                                )}
                            </Formik>
                        )
                    }
                </>
            )}
        </>
    )
}

export default ITR