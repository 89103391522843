import { useContext } from 'react';
import Icon from '../../elements/Icon/Icon';
import Overlay from '../Overlay/Overlay';
import styles from './Fullscreen.module.css';
import BrandContext from '../../context/BrandContext';
import classNames from 'classnames';

interface SimpleFullscreenProps {
    isOpen: boolean
    children: React.ReactNode
    title?: string | undefined
    subTitle?: string | undefined
    adminOnly?: boolean
    onClose: () => void
    onBack?: () => void
}

const SimpleFullscreen = ({isOpen, children, title, subTitle, adminOnly = false, onBack, onClose} : SimpleFullscreenProps) => {
     const brandContext = useContext(BrandContext);
 
    return (
        <Overlay isOpen={isOpen}>
            <div className={styles.container}>
                {/* Header */}
                <div className={`${styles.header} ${styles.simple}`}>
                    <div className={styles.outerColumn}>
                        <img className={styles.brandLogo} src={brandContext.brand.logoDarkUrl} alt={brandContext.brand.name} />
                    </div>
                    <div className={styles.centerColumn}>

                    </div>
                    <div className={styles.outerColumn}>
                        <button className={`${styles.cleanBtn} ${styles.headerCloseBtn}`} onClick={onClose}>
                            <span style={{marginRight: '0.5rem'}}>Close</span><Icon size='small' name='close'/>
                        </button>
                    </div>
                </div>
                {/* Content */}
                <div className={styles.body}>
                    <div className={styles.outerColumn}>
                        {onBack && 
                        <>
                            <h2 className={styles.contentTitleHeader}>&nbsp;</h2> {/*Btn height alignment fix*/}
                            <button className={`${styles.cleanBtn}`} onClick={onBack}>
                                <Icon name='backCircle' size='large'/>
                            </button>
                        </>}
                    </div>
                    <div className={styles.centerColumn}>
                    { title &&
                            <div className={classNames(styles.contentTitle, {[styles.contentTitleHeaderAdmin]: adminOnly})}>
                                <h2 className={styles.contentTitleHeader}>{title}</h2>
                                {subTitle && <p className={styles.contentSubtitleHeader}>{subTitle}</p>}
                            </div>
                        }
                        
                        <div className={styles.content}>
                            {children}
                        </div>
                    </div>
                    <div className={styles.outerColumn}></div>
                </div>
            </div>
        </Overlay>
    )
}

export default SimpleFullscreen