import { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import CompanyContext from "../../context/CompanyContext";
import { useGetUrl } from "../../hooks/useGetUrl";
import { useHasPermission } from "../../hooks/useHasPermission";
import { Action, Entity } from "../../utils/EntityAction";

const SetupRedirection = () => {
    const hasPermission = useHasPermission();
    const getUrl = useGetUrl();
    const {company} = useContext(CompanyContext);
    const history = useHistory()
    const [redirectUrl, setRedirectUrl] = useState<string|undefined>(undefined);

    useEffect(() => {
        // If standalone SATR redirect to a blank loading page ready for the sidebar to do the heavy lifting in terms of page redirects, this avoids loading the page details twice
        if (company?.onboardingWizardType === 'PvPhpSatr' && history.location.pathname === getUrl(Entity.CompanyDashboard ).url) {
            setRedirectUrl(`/company/${company.id}/SATR/redirect`);
        }
        else if (history.location.pathname === getUrl(Entity.CompanyDashboard ).url && hasPermission(Entity.OnboardingSetup, Action.Read)[0] && company?.id) {
            setRedirectUrl(getUrl(Entity.OnboardingSetup, Action.List, {cid: company?.id}).url)
        }
        else if ((history.location.pathname === getUrl(Entity.OnboardingSetup ).url) && !hasPermission(Entity.OnboardingSetup, Action.Read)[0] && company?.id) {
            setRedirectUrl(getUrl(Entity.CompanyDashboard, Action.List, {cid: company?.id}).url)
        }
    }, [])
    
    if (redirectUrl) {
        return <Redirect to={redirectUrl} />
    }
    return <></>
}

export default SetupRedirection;