import { render } from 'storyblok-rich-text-react-renderer';
import { SbBlokData } from "@storyblok/react";
import styles from './KVRichText.module.css'

type Props = {
	blok: SbBlokData;
};

const KVRichText = ({ blok }: Props) => {
  return (
		<div key={`div_${blok._uid}`} className={styles.KVRichText}>
			{ render(blok.value) }
		</div>
  )
};
 
export default KVRichText;
