/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Account, AccountAsDetailed, AccountWithBalance, AccountWithOLBS, SelectlistModel } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Accounts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Accounts
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/accounts
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Account[], any>({
      path: `/api/v2/companies/${companyId}/accounts`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name ListAsDetailed
   * @request GET:/api/v2/companies/{companyId}/accounts/listasdetailed
   * @secure
   */
  listAsDetailed = (companyId: number, params: RequestParams = {}) =>
    this.request<AccountAsDetailed[], any>({
      path: `/api/v2/companies/${companyId}/accounts/listasdetailed`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name ListWithBalances
   * @request GET:/api/v2/companies/{companyId}/accounts/ListWithBalances
   * @secure
   */
  listWithBalances = (companyId: number, query?: { date?: string }, params: RequestParams = {}) =>
    this.request<AccountWithBalance[], any>({
      path: `/api/v2/companies/${companyId}/accounts/ListWithBalances`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name ExpenseAccounts
   * @request GET:/api/v2/companies/{companyId}/accounts/ExpenseAccounts
   * @secure
   */
  expenseAccounts = (companyId: number, params: RequestParams = {}) =>
    this.request<SelectlistModel[], any>({
      path: `/api/v2/companies/${companyId}/accounts/ExpenseAccounts`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name Get
   * @request GET:/api/v2/companies/{companyId}/accounts/{accountId}
   * @secure
   */
  get = (companyId: number, accountId: number, params: RequestParams = {}) =>
    this.request<AccountAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/accounts/${accountId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name GetWithOlbs
   * @request GET:/api/v2/companies/{companyId}/accounts/{accountId}/olbs
   * @secure
   */
  getWithOlbs = (companyId: number, accountId: number, params: RequestParams = {}) =>
    this.request<AccountWithOLBS, any>({
      path: `/api/v2/companies/${companyId}/accounts/${accountId}/olbs`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Accounts
   * @name UpdateCustomBankAccountName
   * @request POST:/api/v2/companies/{companyId}/accounts/{accountId}/updateCustomBankAccountName
   * @secure
   */
  updateCustomBankAccountName = (
    companyId: number,
    accountId: number,
    query?: { customBankAccountName?: string },
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/accounts/${accountId}/updateCustomBankAccountName`,
      method: "POST",
      query: query,
      secure: true,
      ...params,
    });
}
