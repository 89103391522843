/// from https://github.com/mrdavidjcole/wistia-player-react/blob/master/src/components/wistia_embed.js

import classNames from 'classnames';
import React, { useEffect } from 'react';

type Props = { shadow?: string; hashedId: string };
let handle: {remove: () => void;};

const WistiaEmbed = ({hashedId, shadow}: Props) => {
	useEffect(() => {
		
		if (!document.getElementById("wistia_script")) {
			var wistiaScript = document.createElement("script");
			wistiaScript.id = "wistia_script"
			wistiaScript.type = "text/javascript"
			wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js"
			wistiaScript.async = true
			document.body.appendChild(wistiaScript);
		  }
	  
		  (window as any)._wq = (window as any)._wq || [];
		  (window as any)._wq.push({
			id: hashedId,
			onEmbedded: (video: { remove: () => void; }) => {
				handle = video;
			}
		  });
		  return  handle && handle.remove();
	}, [hashedId])
	return (
		<div
			className={classNames('wistia_responsive_padding', {
				shadow,
			})}
			style={{ padding: '56.25% 0 0 0', position: 'relative' }}
		>
			<div
				className="wistia_responsive_wrapper "
				style={{
					height: '100%',
					left: '0',
					position: 'absolute',
					top: '0',
					width: '100%',
				}}
			>
				<div
					className={`wistia_embed wistia_async_${
						hashedId
					} videoFoam=true w-100 h-100`}
				>
					&nbsp;
				</div>
			</div>
		</div>
	);
};

export default WistiaEmbed;
