import React, { useEffect, useState } from 'react';
import styles from './SideBar.module.scss'; 
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import * as Icon from './icons';
import { NavItem } from '../../api/inni/data-contracts'

interface SideBarLinkToggleProps {
    navItem: NavItem,
    toggleCollapsed: () => void
}

interface sideBarItemProps {
    navItem: NavItem,
    useMobileLayout: boolean,
    onClickCb?: () => void
}

interface SideBarLinkProps {
    navItem: NavItem,
    onClickCb?: () => void,
    isChild: boolean,
    useMobileLayout: boolean
}


export const SideBarLink = ({navItem, onClickCb, isChild, useMobileLayout}: SideBarLinkProps) => {
    const location = useLocation();
    const isSelected = navItem.url && location.pathname.toLowerCase().startsWith(navItem.url.toLowerCase().replace('~/v7', ''));

    const className = classNames(
        {
            [styles.sideBarLink] : !useMobileLayout,
            [styles.sideBarLinkMobile] : useMobileLayout, 
            [styles.child] : isChild, 
            [styles.adminOnly] : navItem.isAdminOnly,
            [styles.selected] : isSelected && !useMobileLayout,
            [styles.selectedMobile] : isSelected && useMobileLayout,
            [styles.sideBarLinkNewTag] : navItem.isNew
        }
    );

    const getIcon = () => {
        if (!navItem.icon || !(navItem.icon in Icon)) {
            return null;
        }

        const IconComponent = Icon[navItem.icon as keyof typeof Icon] as React.ElementType;
        return <IconComponent />;
    }

    return (
        <>
            { navItem.url && (
                navItem.url.startsWith('~/v7/') // React router link, else mvc redirect
                ? (
                    <Link
                        data-cy="sidebarLink"
                        data-testid={`sidebarLink-${navItem.name?.replace(/ /g, '')}${navItem.isNew ? '-new' : ''}`}
                        onClick={onClickCb}
                        to={navItem.url.replace('~/v7', '')}
                        className={className}
                    >
                        { !isChild && navItem.icon &&
                            <div className={useMobileLayout ? styles.iconMobile : styles.icon}>
                                { getIcon() }
                            </div>
                        }
                        { navItem.name }
                    </Link>
                )
                : (
                    <a data-cy="sidebarLink" onClick={onClickCb} href={navItem.url.replace('~', '')} className={className}>
                        {!isChild && navItem.icon &&
                            <div className={useMobileLayout ? styles.iconMobile : styles.icon}>
                                { getIcon() }
                            </div>
                        }
                        { navItem.name }
                    </a>
                )
            )}
        </>
    )
}

export const SideBarLinkToggle = ({navItem, toggleCollapsed}: SideBarLinkToggleProps) => {
    const className = classNames(
        styles.sideBarLink,
        {
            [styles.adminOnly]: navItem.isAdminOnly,
            [styles.sideBarLinkNewTag]: navItem.isNew
        }
    );

    const getIcon = () => {
        if (!navItem.icon || !(navItem.icon in Icon)) {
            return null;
        }

        const IconComponent = Icon[navItem.icon as keyof typeof Icon] as React.ElementType;
        return <IconComponent />;
    }

    return (
        <div data-cy = {navItem.isNew ? 'sidebarNewTag' : 'sidebarTag'} className={className} onClick={toggleCollapsed}>
            {navItem.icon &&
                <div className={styles.icon}>
                    { getIcon() }
                </div>
            }
            {navItem.name}
            <div className={styles.disclose}>
                <Icon.Disclose />
            </div>
        </div>
    )
}

export const SideBarItem = ({navItem, useMobileLayout, onClickCb}: sideBarItemProps) => {
    const [collapsed, setCollapsed] = useState(true);
    const location = useLocation();

    // onload check if the top level item should be expanded
    useEffect(() => {
        const CheckUrlIsSelected = (url: string | undefined) => {
            return url && location.pathname.startsWith(url.replace('~/v7', ''));
        }

        if (navItem.children && navItem.children.length > 0) {
            let childIsSelected = navItem.children.some(child => {
                if (!child.isLabel)
                    return CheckUrlIsSelected(child.url)
                else if (child.children && child.children.length > 0)
                    return child.children.some(labelChild => CheckUrlIsSelected(labelChild.url))
                else
                    return false;
            });

            if (childIsSelected)
                setCollapsed(false);
        }
    }, [location.pathname, navItem])

    const toggleCollapsed = () => {
        setCollapsed(prev => !prev);
    }

    return (
        <>
            <div
                data-cy='sidebarItem'
                className={classNames(styles.sideBarItem, {[styles.collapsed] : collapsed})}
            >
                { navItem.children?.length === 0
                    ? <SideBarLink navItem={navItem} isChild={false} useMobileLayout={useMobileLayout} onClickCb={onClickCb} />
                    : (
                        <>
                            <SideBarLinkToggle navItem={navItem} toggleCollapsed={toggleCollapsed} />
                            <div className={styles.children}>
                                {navItem.children?.map((child, i) => (
                                    <React.Fragment key={i}>
                                        { child.isLabel
                                            ? (
                                                <>
                                                    {/* Label */}
                                                    <div className={styles.sideBarLabel}>
                                                        <u>{child.name}</u>
                                                    </div>
                                                    {/* Children */}
                                                    { child.children?.map((labelChild, j) => (
                                                        <SideBarLink key={j} navItem={labelChild} isChild={true} useMobileLayout={useMobileLayout} />
                                                    ))}
                                                </>
                                            )
                                            : <SideBarLink navItem={child} isChild={true} useMobileLayout={useMobileLayout} />    
                                        }
                                    </React.Fragment>
                                ))}
                            </div>
                        </>
                    )
                }
            </div>
        </>
    )
}
