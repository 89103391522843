import { useContext, useEffect, useState } from 'react'
import { LiveCashV6 } from '../../../api/inni/data-contracts'
import { useInniAPI } from '../../../hooks/useInniAPI';
import { LiveCash as LiveCashAPI } from '../../../api/inni/LiveCash';
import CompanyContext from '../../../context/CompanyContext';
import { LiveCashTree, recalcData, unflattenData } from './LiveCashFunctions';
import { ViewSwitcher } from '../../../elements/ViewSwitcher/ViewSwitcher';
import LiveCashPieView, { LiveCashPiesLoading } from './LiveCashPieView';
import LiveCashTableView from './LiveCashTableView';
import InfoBanner from '../../../elements/InfoBanner/InfoBanner';
import configHelper from '../../../utils/configHelper';
import { CompanySetupNotComplete } from './NoDataMessage';

const LiveCash = ({setLoaded} : {setLoaded : (i : boolean) => void}) => { 

    const [view, setView] = useState<string>('pie')

    const liveCashAPI = useInniAPI(LiveCashAPI)
    const companyContext = useContext(CompanyContext)

    const [liveCashData, setLiveCashData] = useState<LiveCashV6 | undefined>(undefined);
    const [isLoaded, setIsLoaded] = useState(false);

    const [nestedData, setNestedData] = useState<LiveCashTree | undefined>()

    const isMobile = configHelper.useMobile
    const v8Styling = companyContext.company?.useV8UI || false;

    useEffect(() => {
        if (liveCashAPI && !isLoaded) {
            liveCashAPI.index(companyContext.cid)
            .then(res => {
                setLiveCashData(res.data);
                setIsLoaded(true)
            })
        }
    }, [companyContext.cid, isLoaded, liveCashAPI])

    useEffect(() => {
        reload()
        setLoaded(isLoaded)
    }, [isLoaded, liveCashData])

    const reload = () => {
        if (liveCashData && liveCashData.data && isLoaded) {
            let data = unflattenData(liveCashData.data, undefined, undefined)
            if (data) {
                
                setNestedData(recalcData(
                    {
                        key: "CA",
                        children: data,
                        cashItem: liveCashData.data[0]
                    }
                ))
            }
            
        }
    }

    const apiDisable = (keys : string[]) => {

        if (liveCashAPI) {
            liveCashAPI.post(companyContext.cid, {
                command: 'disable',
                disabledKeys: keys
            })
        }

    }

    const toggleDisabled = (item : LiveCashTree | undefined, secondLevelActive : string) => { 
        
        if (!item)
            return

        let key = item.key

        let temp = liveCashData

        if (!temp || !temp.data)
            return;

        temp.isCustomized = true;

        let x = temp?.data.find(x => x.key === key)

        if (x) {
            const isEnabled = !x.enabled
            x.enabled = isEnabled
            
            if (item.key === secondLevelActive) {
                temp.data.filter(x => x.parent === key).map(y => x ? y.enabled = x.enabled : y.enabled = y.enabled)

                const parentKey = temp.data.filter(x => x.parent === key).map((y) => y.key)

                temp.data.filter((x) => parentKey.includes(x.parent)).map((y) => y.enabled = isEnabled)
            } else {

                if(item.children) {
                    temp.data.filter(x => x.parent === item.cashItem?.key).map(x => x.enabled = isEnabled)
                }

                temp.data.filter(x => x.key === item.cashItem?.parent).map(x => x.enabled = true)
            }
        }

        setLiveCashData(temp)
        reload()

        let dk = temp.data.filter(x => !x.enabled).map(x => x.key || '')
        if (dk)
            apiDisable(dk)
    }

    const apiReset = () => {
        if (liveCashAPI) {
            liveCashAPI.post(companyContext.cid, {
                command: 'reset',
            }).then(r => setIsLoaded(false))
        }
    }

    if (liveCashData?.isNotAvailable) {
        return (
            <>
                { CompanySetupNotComplete(v8Styling) }
            </>
        )
    } else {
        return (<>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', flexDirection: 'row'}}>
                {liveCashData?.isCustomized ? <div style={{marginLeft: '10px', width: isMobile ? '100%' : '80%'}}>
                    <InfoBanner thin 
                        body={<>LiveCash has been customised and may not reflect your company's finances. <span style={{textDecoration: 'underline', cursor: 'pointer'}} 
                        onClick={() => apiReset()}>Reset</span></>} type="warning" />
                    </div> : <div></div>}
                {!isMobile && <div style={{marginRight: '20px'}}><ViewSwitcher small viewChanged={setView} currentView={view} pie table /></div>}        
            </div>
            { view === 'pie' && <>
                {!isLoaded && <LiveCashPiesLoading />}
                {isLoaded && <LiveCashPieView nestedData={nestedData} isLoaded={isLoaded} toggleDisabled={toggleDisabled} />}
            </>}
            { (view === 'table' || isMobile) &&
                <LiveCashTableView nestedData={nestedData} isLoaded={isLoaded} toggleDisabled={toggleDisabled} />
            }
        </>)
    }
    
}

export default LiveCash