import { SbBlokData, storyblokEditable } from "@storyblok/react";
import WistiaEmbed from "./WistiaEmbed";
 type Props = {
    blok: SbBlokData
 }
const VideoX2 = ({ blok }: Props) => {
  return (
        <div {...storyblokEditable(blok)} >
            <div className="row mb-2">
                <div className="col-sm">
                    <WistiaEmbed hashedId={blok.wistia_id_1 as string} />
                </div>
                <div className="col-sm">
                    <WistiaEmbed hashedId={blok.wistia_id_2  as string} />
                </div>
            </div>
        </div>
  );
};
 
export default VideoX2;
 