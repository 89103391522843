import { useState, useEffect, useContext } from "react";
import { PvSatr } from "../api/inni/PvSatr"
import { useInniAPI } from "./useInniAPI"
import { PvSatrAsDetailed } from "../api/inni/data-contracts";
import CompanyContext from "../context/CompanyContext";
import { useHistory } from "react-router-dom";
import UserContext from "../context/UserContext";

// NOTE: really handling this belongs in a dedicated SATR context (but tight dev deadline)

type SatrStages = {
    // Backend doesn't use PvSatrSetupStatus for stepComplete for some reason so we define them...
    key: "SetupWelcomePage" |"SetupBasicDetails" | "SetupIncome" | "SetupProperty" | "SetupFinancialRecords" | "SetupPropertyIncomePageData" | "SetupSubmitted",
    url: string
}

const stageOrder: SatrStages[] = [
    { key: 'SetupWelcomePage', url: 'SATR/welcome' },
    { key: 'SetupBasicDetails', url: 'SATR/basic' },
    { key: 'SetupIncome', url: 'SATR/income' },
    { key: 'SetupProperty', url: 'SATR/properties' },
    { key: 'SetupFinancialRecords', url: 'SATR/financial-records' },
    { key: 'SetupPropertyIncomePageData', url: 'SATR/past-balances' },
    { key: 'SetupSubmitted', url: 'SATR/submit' }
]

export const useStandaloneSATRStatus = () => {
    const companyContext = useContext(CompanyContext);
    const pvSatrApi = useInniAPI(PvSatr);
    const [pvSatrDetails, setPvSatrDetails] = useState<PvSatrAsDetailed>();
    const history = useHistory();
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (pvSatrApi && companyContext.cid && !pvSatrDetails) {
            pvSatrApi?.getCurrent(companyContext.cid).then((res) => {
                setPvSatrDetails(res.data);
            }).catch((e) => {
                console.error(e.error);
            })
        }
    }, [companyContext.cid, pvSatrApi, pvSatrDetails]);

    const completeStep = (step: SatrStages['key']) => {
        if (pvSatrDetails && pvSatrApi) {
            pvSatrApi.stepComplete(companyContext.cid, pvSatrDetails.id, step).then((res) => {
                // Move to next page
                nextStep(step);
            }).catch((e) => {
                console.error(e);
            })
        }
    };

    const resetStep = (step: SatrStages['key']) => {
        if (pvSatrDetails && pvSatrApi) {
            pvSatrApi.stepIncomplete(companyContext.cid, pvSatrDetails.id, step).then((res) => {
                companyContext.reloadMenu && companyContext.reloadMenu();
            }).catch((e) => {
                console.error(e);
            })
        }
    }

    const nextStep = (step: SatrStages['key']) => {
        const idx = stageOrder.findIndex(stage => stage.key === step);
        if (idx !== -1 && idx !== stageOrder.length - 1) {
            if (userContext.clearErrorMessage) userContext.clearErrorMessage();
            history.push(`/company/${companyContext.cid}/${stageOrder[idx + 1].url}`);
        }
    }



    // Dev helper only
    const clearAllSteps = () => {
        if (pvSatrDetails && pvSatrApi) {
            pvSatrApi.stepIncomplete(companyContext.cid, pvSatrDetails.id, 'SetupWelcomePage').then((res) => {
                pvSatrApi.stepIncomplete(companyContext.cid, pvSatrDetails.id, 'SetupBasicDetails').then((res) => {
                    pvSatrApi.stepIncomplete(companyContext.cid, pvSatrDetails.id, 'SetupFinancialRecords').then((res) => {
                        pvSatrApi.stepIncomplete(companyContext.cid, pvSatrDetails.id, 'SetupIncome').then((res) => {
                            pvSatrApi.stepIncomplete(companyContext.cid, pvSatrDetails.id, 'SetupPropertyIncomePageData').then((res) => {
                                pvSatrApi.stepIncomplete(companyContext.cid, pvSatrDetails.id, 'SetupProperty').then((res) => {})
                            })
                        })
                    })
                })
            })
        }
    }

    return { pvSatrDetails, completeStep, nextStep, clearAllSteps, resetStep };
}