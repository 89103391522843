import { Form, Formik, FormikErrors, FormikProps } from 'formik'
import { useCallback, useContext} from 'react'
import { Employment, EmploymentPostModel, PersonAsDetailed } from '../../../../api/inni/data-contracts'
import { Employments } from '../../../../api/inni/Employments'
import CompanyContext from '../../../../context/CompanyContext'
import { Text, Currency, DateSelector, ListOption, SelectList, Submit, Switch } from '../../../../elements/EditRow/EditRow'
import { useInniAPI } from '../../../../hooks/useInniAPI'
import { CompareValues } from '../../../../utils/compareValues'

interface EmploymentTabEditProps {
    employment: Employment,
    person: PersonAsDetailed,
    workingBandSL: ListOption[],
    reloadPeople: () => void,
    setEditing: (i: boolean) => void,
    studentLoanPlanTypeSL: ListOption[],
    setDisabled: (disabled: boolean) => void
}

const EmploymentTabEdit = ({ employment, person, workingBandSL, reloadPeople, setEditing, setDisabled,studentLoanPlanTypeSL }: EmploymentTabEditProps) => {

    const companyContext = useContext(CompanyContext)
    const employmentsApi = useInniAPI(Employments, [400])
    const refVal = useCallback(
		(node: FormikProps<any>) => {
			if (node !== null) {
				setDisabled(!CompareValues({ ...node.values }, { ...node.initialValues }, true));
			}
		},
		[setDisabled]
	);
    const validateUpdate = (values: EmploymentPostModel): Promise<FormikErrors<EmploymentPostModel>> => {
        return new Promise((resolve) => {
            if (employmentsApi) {
                employmentsApi.validateUpdateEmployment(companyContext.cid, employment?.id || 0, values)
                    .then(() => {
                        resolve({})
                    })
                    .catch(err => resolve(err.error))
            }
        })
    }

    const updateCall = (values: EmploymentPostModel): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (employmentsApi) {
                employmentsApi.updateEmployment(companyContext.cid, employment?.id || 0, values)
                    .then(res => {
                        if (res.status === 200 && employment) {
                            reloadPeople()
                            setEditing(false)
                            resolve()
                        }
                    }).catch(err => reject(err.error))
            }
        })
    }

    return (
        <Formik
            initialValues={{
                startDate: employment.startDate,
                leavingDate: employment.leavingDate,
                normalWorkingHoursBand: employment.normalWorkingHoursBand,
                nino: person.nino,
                passportNumber: person.passportNumber,
                defaultEmployee: person.defaultEmployee,
                targetSalary: employment.targetSalary,
                studentLoanStart: person.studentLoanStart,
                studentLoanEnd: person.studentLoanEnd,
                postGraduateLoanStart: person.postGraduateLoanStart,
                postGraduateLoanEnd: person.postGraduateLoanEnd,
                employeeNumber: employment.employeeNumber,
                previousEmployeeNumber: employment.previousEmployeeNumber,
                hasEmploymentContract: person.hasEmploymentContract,
                repayTaxRefunds: employment.repayTaxRefunds,
                studentLoanPlanType: person.studentLoanPlanType || ''
            } as EmploymentPostModel}
            validateOnChange={false}
            enableReinitialize
            onSubmit={updateCall}
            validate={validateUpdate}
            innerRef={refVal}
        >
            {({ isSubmitting,values }) => (
                
                <Form>
                    <DateSelector name="startDate" />
                    <DateSelector name="leavingDate" />
                    <SelectList options={workingBandSL} name="normalWorkingHoursBand" />
                    <Text name="nino" label="National Insurance number" />
                    <Text name="passportNumber" />
                    <Switch name="defaultEmployee" />
                    <Currency name="targetSalary" label="Salary" />
                    <DateSelector name="studentLoanStart" label="Student loan repayment start date" />
                    <DateSelector name="studentLoanEnd" label="Student loan repayment end date" />
                    <SelectList name="studentLoanPlanType" options={studentLoanPlanTypeSL} label="Student loan plan type" />
                    <DateSelector name="postGraduateLoanStart" label="Postgraduate loan repayment start date" />
                    <DateSelector name="postGraduateLoanEnd" label="Postgraduate loan repayment end date" />
                    <Text min={0} name="employeeNumber" type="number" />
                    <Text min={0} name="previousEmployeeNumber" type="number" />
                    <Switch name="hasEmploymentContract" />
                    <Switch name="repayTaxRefunds" />
                    { person.isDirector &&
                        <Switch name="useAlternateNICal" label='Use alternative NI calculation'/>
                    }
                    
                    <Submit disabled={isSubmitting} onCancelClick={() => setEditing(false)} />

                </Form>
            )}
        </Formik>
    )

}

export default EmploymentTabEdit