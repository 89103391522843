import { useState, useEffect, useContext } from 'react';
import {  Container, Form, FormLabel, Modal as ReactModal, Row,Col } from 'react-bootstrap';
import { AssetPostModel, AssetCategories,Property, AssetAsDetailed} from '../../api/inni/data-contracts';
import { Button } from '../../elements/Button/Button';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import FormikField from '../../elements/FormikField/FormikField';
import { Assets } from '../../api/inni/Assets';
import { useInniAPI } from '../../hooks/useInniAPI';
import CompanyContext from '../../context/CompanyContext';
import { Properties } from '../../api/inni/Properties';
import { InvoiceReactSelect } from '../Invoices/CreateInvoice';
import { BrandWrapper } from '../../components/BrandWrapper/BrandWrapper';
import { LoadingPlaceholderAccountSelector } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import styles from "./Asset.module.css";

interface AssetModalProps {
  hide: () => void;
  create: (values: AssetPostModel, actions: FormikHelpers<any>) => Promise<void>;
  update: (values: AssetPostModel, actions: FormikHelpers<any>) => Promise<void>,
  data: AssetAsDetailed;
  isProperty?:boolean;
}

export const AssetCreateModal = ({ hide, create,update,data,isProperty }: AssetModalProps) => {   
  const [editing, setEditing] = useState<boolean>(false);
  const [title,setTitle]=useState<string>("Create Asset");
  const assetAPI = useInniAPI(Assets, [400]);
  const companyContext = useContext(CompanyContext);
  const [depreciationTypes] = useState([
                                    { value: "R", label: "Reducing" },
                                    { value: "S", label: "Straight Line" }
                                  ]);
  const propertyAPI = useInniAPI(Properties);
  const [properties,setProperties] = useState<InvoiceReactSelect[]>();
  const [assetCategories,setAssetCategories] = useState<InvoiceReactSelect[]>();

  useEffect(()=>{
    if(data.id){
      setEditing(true)
      setTitle("Edit Asset")
    }
    else{
      setEditing(false)
      setTitle("Create Asset")
    }

  },[data]);
  useEffect(() => {
		if (assetAPI && companyContext.company) {
			assetAPI.getAssetCategories(companyContext.cid).then((response: { data: AssetCategories[] }) =>
      setAssetCategories(
					response.data.filter(x => x.tenantId === companyContext.company?.tenantId || x.tenantId === undefined || x.tenantId === null)
            .map((assetCategories: AssetCategories) => {
						return {
							value: assetCategories.id,
							label: assetCategories.name,
						};
					})
				)
			);
		}
	}, [assetAPI, companyContext.cid, companyContext.company]);

  useEffect(() => {
		if (isProperty && propertyAPI) {
			propertyAPI.index(companyContext.cid).then((response: { data: Property[] }) =>
      setProperties(
					[
            {label: 'None', value: ''},
            ...response.data.map((properties: Property) => {
						return {
							value: properties.id,
							label: properties.name,
						};
					})
				])
			);
		}
	}, [propertyAPI, companyContext.cid,isProperty]);

  const validate = (values: AssetPostModel)  => {
    return new Promise((resolve, reject) => {
			if (assetAPI && companyContext.cid) {
        if(editing){
          return assetAPI
					. validateUpdate(companyContext.cid, data.id, values)
					.then(() => resolve({}))
					.catch((error) => resolve(error.error));
        }
        else {
          return assetAPI
					. validateCreate(companyContext.cid,values)
					.then(() => resolve({}))
					.catch((error) => resolve(error.error));
        }				
			} else {
				reject();
			}
		});
  }

  return (
    <ReactModal size="xl" show={true} onHide={hide} centered>
      <BrandWrapper>
        <Formik
          initialValues={data}
          enableReinitialize         
          validateOnChange={true}
          validate={validate}
          onSubmit={editing ? update : create}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              {/* Header */}
              <ReactModal.Header closeButton>
                <ReactModal.Title id="inni-modal-title">
                {title}
                </ReactModal.Title>
              </ReactModal.Header>
          
              <ReactModal.Body>                
                      <Container>
                        <Row> 
                                                      
                              <FormikField<AssetPostModel >
                                          label="Date Purchased"
                                          name="datePurchased"
                                          type="date"                                                                       
                              />                         
                              <div style={{width:'80px'}}>
                                <FormikField<AssetPostModel>
                                            label="1st year"
                                            name="isFirstYear"
                                            type="checkbox"                                                                               
                                />
                              </div>                                                                            
                              <FormikField<AssetPostModel>
                                          label="Description"
                                          name="description"
                                          type="text"                                        
                              />

                            {assetCategories?
                                (<FormikField<AssetPostModel>
                                                label="Asset category"
                                                name="assetCategoryId"
                                                type="select"
                                                placeholder='None' 
                                                option={assetCategories}                                                                               
                                />):
                                <>
                                <div>
                                  <Form.Group as={Col} style={{ marginBottom: '0'}} className={styles.inputStyle}>
                                      <FormLabel className='text-muted'>Asset category</FormLabel>
                                      <LoadingPlaceholderAccountSelector/>
                                    </Form.Group>
                                </div>
                                    
                                </>
                              }
                              
                              <div style={{width:'180px'}}>
                                <FormikField<AssetPostModel>
                                            label="Original value"
                                            name="originalValue"
                                            prefix="£"
                                            type="number"
                                            step='0.01'                                                                                 
                                />
                              </div>                            
                              <FormikField<AssetPostModel>
                                        label="Disposal date"
                                        name="disposalDate"
                                        type="date"                           
                              />
                              {isProperty &&(
                                properties? 
                                  (<FormikField<AssetPostModel>
                                                label="Property"
                                                name="propertyId"
                                                type="select"
                                                placeholder="None"
                                                option={properties}                                        
                                  />):
                                  <>
                                    <div>
                                      <Form.Group as={Col} style={{ marginBottom: '0'}} className={styles.inputStyle}>
                                        <FormLabel className='text-muted'>Properties</FormLabel>
                                        <LoadingPlaceholderAccountSelector/>
                                      </Form.Group>
                                    </div>                                  
                                  </>)
                                  
                              }                    
                        </Row>                     
                        <Row>
                              <FormikField<AssetPostModel>
                                          label="Method (book)"
                                          name="methodAccounts"
                                          type="select"
                                          option={depreciationTypes}
                              />                        
                              <FormikField<AssetPostModel>
                                          label="Rate (year 1)"
                                          name="rateFirstYearAccountsPct"
                                          suffix="%"
                                          type="number"
                                          min='0'
                                          step='0.01'                                                                                   
                                          onlyPositiveNumbers
                              />
                              <FormikField<AssetPostModel>
                                          label="Rate (year 2+)"
                                          name="rateSubsequentAccountsPct"
                                          suffix="%"
                                          type="number"
                                          min='0'
                                          step='0.01'                                                                                  
                                          onlyPositiveNumbers                                       
                              />                        
                              <FormikField<AssetPostModel>
                                          label="Current value"
                                          name="currentValue"
                                          prefix="£"
                                          type="number"
                                          step='0.01'                                        
                              />
                              <FormikField<AssetPostModel>
                                          label="Value at end of FY"
                                          name="forecastValueAccounts"
                                          prefix='£'
                                          type="number"
                                          readonly
                              />
                        </Row>
                        <Row>
                              <FormikField<AssetPostModel>
                                          label="Method (CA)"
                                          name="methodTax"
                                          type="select"
                                          option={depreciationTypes}
                              />                        
                              <FormikField<AssetPostModel>
                                          label="Rate (year 1)"
                                          name="rateFirstYearTaxPct"
                                          suffix="%"
                                          type="number" 
                                          min='0'
                                          step='0.01'                                                                                   
                                          onlyPositiveNumbers                                       
                              />
                              <FormikField<AssetPostModel>
                                          label="Rate (year 2+)"
                                          name="rateSubsequentTaxPct"
                                          suffix="%"
                                          type="number"
                                          min='0'
                                          step='0.01'                                                                                   
                                          onlyPositiveNumbers
                              />                        
                              <FormikField<AssetPostModel>
                                          label="Current value"
                                          name="currentValueTax"
                                          prefix="£"
                                          type="number"
                                          step='0.01'
                              />
                              <FormikField<AssetPostModel>
                                          label="Value at end of FY"
                                          name="forecastValueTax"
                                          prefix="£"
                                          type="number"
                                          readonly
                              />                            
                        </Row>
                      </Container>
              </ReactModal.Body>

              {/* Footer */}
              <ReactModal.Footer>
                <div style={{textAlign: 'left', width: '100%'}}>
                  <Button  buttonType='save' submitButton disabled={isSubmitting} />
                  <Button  buttonType='cancel' onClick={hide}/>
                </div>
              </ReactModal.Footer>
            </FormikForm>
          )}
        </Formik>
      </BrandWrapper> 
    </ReactModal>
  )
}
