import { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import {InvoiceAsDetailed } from '../../../api/inni/data-contracts';
import { Button } from '../../../elements/Button/Button';
import InfoBanner from '../../../elements/InfoBanner/InfoBanner';
import { formatDate } from '../../../utils/formatDate';
import { formatCurrency } from '../../../utils/formatNumbers';
import ModalDialogbox from '../Elements/Modal/Modal';

export const IR35Labels = (invoiceDetails: InvoiceAsDetailed, setShow: (a: boolean) => void) => {
	const { iR35Status, amountTotal, amountPaid, netPayment, overdue } = invoiceDetails;

	if (overdue) {
	}
	switch (iR35Status) {
		case 'unpaid':
			return {
				header: 'Invoice awaiting payment (IR35 off payroll rules applied)',
				body:
					'You have indicated that IR35 off payroll rules apply to this contract. This means your agent or client will deduct Income Tax and National Insurance and pay it to HMRC on your behalf. As a result you will receive a payment lower than your invoice value, and no further Income Tax or National Insurance will be deducted.',
				type: 'warning',
			};
		case 'partpaid':
			return {
				header: 'Confirm IR35 off payroll Income Tax and National Insurance deductions',
				body: (
					<>
						<p>{`You have received a payment for this invoice. Click to confirm that the remaining balance of this invoice ${formatCurrency(
							amountTotal - amountPaid
						)} is Income Tax and National Insurance deducted by your agency or client.`}</p>
						<Button label="Confirm tax deductions" buttonType="edit" variant="warning" onClick={() => setShow(true)}></Button>
					</>
				),
				type: 'warning',
			};
		case 'deductionstaken':
			return {
				header: 'You have already recorded the deductions against this invoice',
				body:
					'As this invoice is for a contract where income tax deductions are taken at source, tax and National Insurance payments have already been paid' +
					'The remainder should be confirmed into your bookkeeping. If the amount is wrong, please get in contact.',
				type: 'warning',
			};
		case 'paid':
			return {
				header: 'Invoice paid under IR35 off payroll rules',
				body: `Payment of this invoice has been made using IR35 off payroll rules, meaning your agency or client have deducted Income Tax and National Insurance payments from this invoice." +
					The net payment of this invoice ${formatCurrency(netPayment)}
					will now be made to the employee on their next payslip, with no further Income Tax or National Insurance deducted`,
				type: 'positive',
			};
		case 'paidout':
			return {
				header: 'Invoice paid under IR35 off payroll rules',
				body: `Payment of this invoice has been made using IR35 off payroll rules, meaning your agency or client have deducted Income Tax and National Insurance payments from this invoice.
					The net payment of this invoice ${formatCurrency(netPayment)}
					has been added to a payslip, with no further Income Tax or National Insurance deducted`,
				type: 'positive',
			};
		case 'notimenopaymentrequired':
			return {
				header: 'Invoice has no tax deductible income',
				body: ' The invoice is on an IR35 contract, but has no income on it with tax deducted at source, therefore there is nothing to add to a payslip.',
				type: 'positive',
			};
	}
};

export const IR35Status = ({ invoiceDetails, confirmTax, companyId }: { invoiceDetails: InvoiceAsDetailed; confirmTax: () => void; companyId: number }) => {
	const [data, setData] = useState<{ header: string; body: string | JSX.Element ; type:string } | undefined>({ header: '', body: '', type: 'positive' });
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setData(IR35Labels(invoiceDetails,setShowModal));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoiceDetails]);

	return (
		<>
			<ModalDialogbox
				title="Confirm IR35 off payroll Income Tax and National Insurance deductions"
				buttonSubmit="Confirm"
				buttonCancel="Cancel"
				onClickSubmit={confirmTax}
				setShow={setShowModal}
				show={showModal}
				size="xl"
				type="save"
				children={
					<div className="p-3">
						<p>
							When IR35 off payroll rules apply, you don't receive full payment of invoices. Instead, your agent or client will calculate and deduct Income Tax
							and National Insurance, then pay it to HMRC on your behalf.
						</p>
						<p>
							{invoiceDetails.iR35WorkerEmployeeName} provided the services charged on this invoice and will receive a payment on their next payslip ,{formatDate(invoiceDetails.nextPayrollDate)} and no further Income Tax or National Insurance will be deducted.
						</p>
						<p>You must now confirm that the invoice payment received has had all Income Tax and National Insurance deductions made by your agent or client.</p>
						<div className="d-flex">
							<Ir35InputGroup label="Payment received" value={formatCurrency(invoiceDetails.amountPaid, true)} />
							<Ir35InputGroup label="Unpaid balance" value={formatCurrency(invoiceDetails.amountTotal - invoiceDetails.amountPaid, true)} />
						</div>
						<p>The unpaid balance should match the Income Tax and National Insurance withheld by your agent or client.</p>
						<p>
							The payment received includes {formatCurrency(invoiceDetails.amountVAT)} of VAT, which you will pay to HMRC at the end of your next VAT period.
							The amount below will be added to {invoiceDetails.iR35WorkerEmployeeName}'s next payslip,{formatDate(invoiceDetails.nextPayrollDate)} as nontaxable income.
						</p> 
						<Ir35InputGroup label="To be paid as non-taxable income" value={formatCurrency(invoiceDetails.amountPaid-invoiceDetails.amountVAT,true)} />
						<p>
							Click below to confirm that you have received all payments for this invoice and your agent or client has confirmed they have deducted Income Tax
							and National Insurance on your behalf. Once confirmed you can preview your next payslip and you will see the no taxable pay added.
						</p>
					</div>
				}
			/>
			{data && <InfoBanner title={data.header} body={data.body} type="positive"></InfoBanner>} 
		</>
	);
};

const Ir35InputGroup = ({ label, value }: { label: string; value: string | number }) => {
	return (
		<div className="d-flex flex-wrap w-75">
			<Form.Label className="w-100">{label}</Form.Label>
			<InputGroup className="mb-3 w-75">
				<InputGroup.Text>£</InputGroup.Text>
				<Form.Control value={value} readOnly />
			</InputGroup>
		</div>
	);
};
