import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import * as DataGrid from '../../DataGrid/DataGrid';
import { Onboarding } from '../../../api/inni/Onboarding';
import CompanyContext from '../../../context/CompanyContext';
import { useInniAPI } from '../../../hooks/useInniAPI';
import OnboardingContext from '../../../context/OnboardingContext';
import { NoContentSlate } from '../../Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { FileUploadModal } from '../../../components/FileUploadModal/FileUploadModal';
import { Button } from '../../Button/Button';
import { InitialiseDateModel } from '../../../api/inni/data-contracts';
import StandardFileField from '../../StandardFileField/StandardFileField';
import { useModalDialog } from '../../../hooks/useModalDialog';

export type TUploadState = {
	showModal: boolean;
	id: number;
	isNew: boolean;
};

type Props = {
	initialData: InitialiseDateModel;
};

const UploadCSV = ({ initialData }: Props) => {
	const csvApi = useInniAPI(Onboarding);
	const { cid } = useContext(CompanyContext);
	const [showModalDialog, modalDialog] = useModalDialog();
	const {
		uploadCVSFormik: { values, setValues, isSubmitting },
		onIncompletePage
	} = useContext(OnboardingContext);
	const requestDone = useRef(false);
	const [tableLoader, setTableLoader] = useState(true);
	const [showNewFiles, setShowNewFiles] = useState({} as { [key: string]: boolean });
	const [uploadState, setUploadState] = useState<TUploadState>({
		showModal: false,
		id: 0,
		isNew: false,
	});

    const getCsvFiles = useCallback(() => {
        if (csvApi && cid && !requestDone.current) {
			setTableLoader(true);
			csvApi
				.getUploadedCsvFiles(cid)
				.then(
					({ data: attachments }) =>
						attachments &&
						attachments.length &&
						setValues(
							attachments.map(({ documentId, fileName }) => ({
								documentId,
								fileName,
								isNew: false,
								fileToUpload: null,
							}))
						)
				)
				.finally(() => {
					setTableLoader(false);
					requestDone.current = true;
				});
		}
    }, [cid, csvApi, setValues])
	useEffect(() => {
		getCsvFiles()
	}, [getCsvFiles]);

	const onUploadNew = useCallback(
		(files: File[]) => {
			setValues(
				(prev) => [
					...prev,
					{ documentId: uploadState.id, isNew: true, fileToUpload: files[0], fileName: files[0].name },
				],
				false
			);
			setShowNewFiles((prev) => ({ ...prev, [`${uploadState.id}`]: true }));
		},
		[setValues, uploadState.id]
	);

	const uploadFile = useCallback(
		(files: File[]) => {
			if (uploadState.isNew) {
				onUploadNew(files);
			} else {
				const currentItem = values.find((v) => v.documentId === uploadState.id);
				currentItem &&
					setValues((prev) =>
						prev.map((form) =>
							form.documentId === currentItem.documentId
								? {
										...form,
										fileToUpload: files[0],
										fileName: files[0].name,
										isUpdated: !currentItem.isNew,
								  }
								: form
						)
					);
				currentItem && setShowNewFiles((prev) => ({ ...prev, [`${currentItem.documentId}`]: true }));
			}
			setUploadState((prev) => ({ ...prev, id: 0, showModal: !prev.showModal, isNew: false }));
		},
		[onUploadNew, setValues, uploadState.id, uploadState.isNew, values]
	);

	const addNewEmptyRow = () => {
		setUploadState((prev) => ({
			...prev,
			id: new Date().getTime(),
			showModal: !prev.showModal,
			isNew: true,
		}));
	};

	const removeAttachment = (id: number, isNew: boolean) => {
		if (!isNew && csvApi && cid) {
			csvApi.deleteUploadedCsvFile(cid, id).then(() => {
				values.length === 1 && onIncompletePage()
				setValues((prev) => prev.filter(({ documentId }) => documentId !== id));
			});
		} else {
			setValues((prev) => prev.filter(({ documentId }) => documentId !== id));
		}
	};
    useEffect(() => {
        if (isSubmitting) {
            requestDone.current = false;
            setShowNewFiles({});
            getCsvFiles();
        }
    }, [isSubmitting])

	
	const showDeleteModal = (documentId: number, isNew?: boolean) => {
        showModalDialog(
            'Delete CSV file?',
            `Are you sure you want to delete this CSV file?`,
            [
                <Button key="yes" variant="danger" label="Yes" onClick={() => removeAttachment(documentId, isNew || false)} />,
                <Button key="no" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

	return (
		<>
			<DataGrid.Table>
				<thead>
					<tr>
						<th>Upload CSV file(s)</th>
					</tr>
				</thead>
				<tbody>
					{tableLoader && (
						<>
							<DataGrid.LoadingRow cols={1} />
							<DataGrid.LoadingRow cols={1} />
						</>
					)}
					{!tableLoader && (
						<>
							{values.length > 0 ? (
								values.map(({ fileName, documentId, isNew }, i) => (
									<tr key={documentId}>
										<td>
											<StandardFileField
												id={`[${i}].fileToUpload`}
												name={`[${i}].fileToUpload`}
												onClick={() =>
													setUploadState((prev) => ({
														...prev,
														id: documentId,
														showModal: !prev.showModal,
													}))
												}
												fileName={fileName || ''}
												showNewFile={showNewFiles[documentId]}
												showDeleteBtn
												onDelete={() => showDeleteModal(documentId, isNew)}
											/>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan={2} style={{ padding: 0 }}>
										<NoContentSlate
											type={
												DatagridType[
													'FileUploads'
												]
											}
											termsKey="emptyTerms"
										/>
									</td>
								</tr>
							)}
							<tr>
								<td colSpan={initialData.preAppointmentYearEnd ? 4 : 3}>
									<Button buttonType="new" onClick={addNewEmptyRow} />
								</td>
							</tr>
						</>
					)}
				</tbody>
			</DataGrid.Table>

			{uploadState.showModal && (
				<FileUploadModal
					onCancelClick={() => setUploadState((prev) => ({ ...prev, id: 0, showModal: !prev.showModal }))}
					onFilesDropped={(acceptedFiles) => uploadFile(acceptedFiles)}
					maxFiles={1}
					uploading={false}
					maxSizeMB={10}
					accept='csv'
					showDropZoneText
				/>
			)}

			{modalDialog}
		</>
	);
};

export default UploadCSV;
