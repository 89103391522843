import { useState, useEffect, useContext, useRef } from 'react';
import { AssetPostModel, AssetCategories,Property, Market} from '../../../api/inni/data-contracts';
import { Button } from '../../../elements/Button/Button';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import FormikField from '../../../elements/FormikField/FormikField';
import { Assets } from '../../../api/inni/Assets';
import { useInniAPI } from '../../../hooks/useInniAPI';
import CompanyContext from '../../../context/CompanyContext';
import { Properties } from '../../../api/inni/Properties';
import { InvoiceReactSelect } from '../../Invoices/CreateInvoice';
import { BrandWrapper } from '../../../components/BrandWrapper/BrandWrapper';
import styles from "../Asset.module.css";
import SimpleFullscreen from '../../../components/Fullscreen/SimpleFullscreen';
import Icon from '../../../elements/Icon/Icon';
import OptionSelector from '../../../components/OptionSelector/OptionSelector';
import { Summary } from '../../../elements/Summary/Summary';
import { Text, YesNo, Date as DateText } from '../../../elements/DetailRow/DetailRow';
import { Submit } from '../../../elements/EditRow/EditRow';
import classNames from 'classnames';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { Action, Entity } from '../../../utils/EntityAction';
import { Markets } from '../../../api/inni/Markets';
import { formatCurrency, formatPercent } from '../../../utils/formatNumbers';

const initialValues: AssetPostModel = {
    originalValue: 0,
    rateFirstYearAccountsPct: 0,
    rateSubsequentAccountsPct: 0,
    currentValue: 0,
    rateFirstYearTaxPct: 0,
    rateSubsequentTaxPct: 0,
    currentValueTax: 0
  }

const AssetCreateV8Modal = () => {

  //stages
  type STAGE_NAMES = "intro"|"description"|"purchasedDate"|"firstYear"|"assetCategory"|"originalValue"|"disposalDate"|"property"|"depreciation"|"capitalAllowance"|"submit"
  const [stageFlow, setStageFlow] = useState<STAGE_NAMES[]>()
  const [currentStage, setCurrentStage] = useState<STAGE_NAMES>();

  const assetAPI = useInniAPI(Assets, [400]);
  const propertyAPI = useInniAPI(Properties);
  const marketAPI = useInniAPI(Markets);
  
  const companyContext = useContext(CompanyContext);
  const [properties,setProperties] = useState<Property[]>([]);
  const [market, setMarkets] = useState<Market>();
  const [assetCategories,setAssetCategories] = useState<InvoiceReactSelect[]>([]);
  const [depreciationTypes] = useState([
                                    { value: "R", label: "Reducing" },
                                    { value: "S", label: "Straight Line" }
                                  ]);
  
  const navigateToEntity = useNavigateToEntity();
  const formikRef = useRef<FormikProps<AssetPostModel>>(null);

  useEffect(() => {
        if(stageFlow === undefined && market) {
            let stages:STAGE_NAMES[] = ["intro", "description", "purchasedDate", "firstYear", "assetCategory", "originalValue"];
            if (market && market.isProperty){
                stages.push("property")
            }
            stages.push("disposalDate", "depreciation", "capitalAllowance", "submit");
            setStageFlow(stages)
            setCurrentStage("intro")
        }
    }, [market, stageFlow])

    const getStageDetails = () => {
        switch(currentStage) {
            case "intro":
                return  {    
                            Title: "Use with care"
                        }
            case "description":
                return  {    
                            Title: "Description",
                            Subtitle: "Give the asset a brief description so that you can easily identify it in the future"
                        }
            case "purchasedDate":
                return  {    
                            Title: "Date of purchase",
                            Subtitle: "When was the asset purchased?"
                        }
            case "firstYear":
                return  {    
                            Title: "Is it the first year of purchase?", 
                        }
            case "assetCategory":
                return  {    
                            Title: "Category of asset", 
                            Subtitle: "How should we categorise this asset?"
                        }
            case "originalValue":
                return  {    
                            Title:  "What is the original value of asset?", 
                        }
            case "disposalDate":
                return  {    
                            Title:  "Date of disposal", 
                            Subtitle: "The asset disposal date is the date on which you either have sold, transferred, or disposed of the asset, or the date on which you plan to do so"
                        }
            case "property":
                return  {    
                            Title: "Which property does the asset belong to?", 
                            Subtitle: "If applicable, select the property from the list below"
                        }
            case "depreciation":
                return  {    
                            Title: "Book value depreciation"                            
                        }
            case "capitalAllowance":
                return  {    
                            Title: "Asset capital allowance"
                        }
            case "submit":
                return  {    
                            Title: "Asset summary", 
                            Subtitle: "Below is a summary of all the details you have provided. Review the information to ensure its accuracy, and make any necessary adjustments before finalising."
                        }
            default:
                return {    
                            Title: "Use with care"
                        }
        }
    }

    const nextStage = () => {
        if(stageFlow && currentStage) {
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])  
        }
    }

    useEffect(() => {
		if (assetAPI && companyContext.company) {
			assetAPI.getAssetCategories(companyContext.cid).then((response: { data: AssetCategories[] }) =>
      setAssetCategories(
					response.data.filter(x => x.tenantId === companyContext.company?.tenantId || x.tenantId === undefined || x.tenantId === null)
            .map((assetCategories: AssetCategories) => {
						return {
							value: assetCategories.id,
							label: assetCategories.name,
						};
					})
				)
			);
		}
	}, [assetAPI, companyContext.cid, companyContext.company]);

  useEffect(() => {
		if (market && market.isProperty && propertyAPI) {
			propertyAPI.index(companyContext.cid).then((response) =>{
                setProperties(response.data || []);
            })
        }
	}, [propertyAPI, companyContext.cid, market]);

    useEffect(() => {
        if (marketAPI) {
          marketAPI?.index( companyContext.cid).then((response) => {
            setMarkets(response.data);
          });
        }
      }, [companyContext.cid, marketAPI]);

  const validate = (values: AssetPostModel)  => {
    return new Promise((resolve, reject) => {
		if (assetAPI && companyContext.cid) {
          return assetAPI
					. validateCreate(companyContext.cid,values)
					.then(() => resolve({}))
					.catch((error) => resolve(error.error));
        }				
	     else {
				reject();
			}
		});
  }

  const createAsset = (values: AssetPostModel, actions: FormikHelpers<AssetPostModel>): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (assetAPI) {
        assetAPI
          .create(companyContext.cid, values)
          .then((response) => {
            redirectToAssetList();
            resolve();
          })
          .catch((error) => {
            actions.setErrors(error.error);
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const redirectToAssetList = () =>{
    navigateToEntity(Entity.CompanySettingsV7,Action.List,{"activePage" : "assets"})
  }

  const validateDisposalDate = () =>{
    if(formikRef.current){
        if(formikRef.current.values.disposalDate && formikRef.current.values.datePurchased && (formikRef.current.values.disposalDate < formikRef.current.values.datePurchased)){
            formikRef.current.setFieldTouched("disposalDate", true, false)
            formikRef.current.setFieldError("disposalDate", "Disposal date should be after purchase date")
        }
        else{
            formikRef.current.setFieldTouched("disposalDate", false, false)
            formikRef.current.setFieldError("disposalDate", undefined)
            nextStage();
        }
    }
    
  }

  return (
        <SimpleFullscreen
            isOpen={true}
            onClose={redirectToAssetList}
            onBack={stageFlow && currentStage && stageFlow.indexOf(currentStage) > 0 ?
                () => setCurrentStage(stageFlow[stageFlow.indexOf(currentStage) - 1]) :
                undefined}
            title={getStageDetails().Title}
            subTitle={getStageDetails().Subtitle} 
        >
            <BrandWrapper>
                {stageFlow && 
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize         
                        validateOnChange={false}
                        validate={validate}
                        onSubmit={createAsset}
                        innerRef={formikRef}
                    >
                {({ isSubmitting, setFieldValue, values }) => (
                    <Form>                
                            {currentStage === "intro" &&
                                <>  
                                    <div className={styles.subtitle}>
                                        <p>This feature should only be used by your accountant at year-end to address issues with your accounts.</p>
                                        <p>Assets will normally be created for you if the purchase has been categorised correctly.</p>
                                        <p>Creating an asset here won't necessarily resolve the underlying problem. If you notice a missing asset, please review how the purchase was recorded in your bookkeeping.</p>
                                    </div>
                                    
                                    <Button 
                                        marginTop 
                                        variant='primary' 
                                        thin 
                                        onClick={nextStage}>
                                            Let's go <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                            }
                            {currentStage === "description" &&
                                <>
                                    <FormikField<AssetPostModel>
                                        className={styles.formikFields}
                                        label="Description"
                                        name="description"
                                        type="text"                                        
                                    /> 
                                    <Button
                                        disabled={!values.description}
                                        variant='primary' 
                                        thin  
                                        marginTop 
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                                
                            }
                            {currentStage === "purchasedDate" &&
                                <>
                                    <FormikField<AssetPostModel >
                                        className={styles.formikFieldsAuto}
                                        label="Purchase date"
                                        name="datePurchased"
                                        type="date"                                                                       
                                    /> 
                                    
                                    <Button
                                        disabled={!values.datePurchased}
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>

                                </>
                                
                            }
                            {currentStage === "firstYear" && 
                                
                                <OptionSelector preventSaveState onChange={(opt) => {
                                        if(opt[0].value === "true"){
                                            setFieldValue('isFirstYear', true)
                                        } 
                                        else{
                                            setFieldValue('isFirstYear', false)
                                        }
                                        nextStage();
                                    }}
                                    options={{singleEntry: true, list: [
                                        {label: `Yes`, value: "true"}, 
                                        {label: `No`, value: "false"}
                                    ]}}
                                />
                            }
                            {currentStage === "assetCategory" &&
                                <>
                                    <FormikField<AssetPostModel>
                                        className={styles.formikSelectField}
                                        label="Category"
                                        name="assetCategoryId"
                                        type="select"
                                        placeholder='None' 
                                        option={assetCategories}                                                                           
                                    />
                                    
                                    <Button
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                                
                            }
                            {currentStage === "originalValue" &&
                                <>
                                    <FormikField<AssetPostModel>
                                        className={styles.formikFields}
                                        label="Original value"
                                        name="originalValue"
                                        prefix="£"
                                        type="number"                                                                                   
                                    />
                                    
                                    <Button
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>                                
                            }
                            {currentStage === "disposalDate" &&
                                <>
                                    <FormikField<AssetPostModel>
                                        className={styles.formikFieldsAuto}
                                        label="Disposal date (optional)"
                                        name="disposalDate"
                                        type="date"                           
                                    />
                                    
                                    <Button
                                        variant='primary' 
                                        thin  
                                        marginTop 
                                        onClick={validateDisposalDate}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                                
                            }
                            {currentStage === "property" &&
                                <>
                                    <OptionSelector preventSaveState onChange={(opt) => {
                                        if(opt[0].value === "NA"){
                                            nextStage();
                                        }
                                        else
                                        {
                                            setFieldValue("propertyId", parseInt(opt[0].value))
                                            nextStage();
                                        }                                        
                                    }}
                                    options={
                                        [
                                            {singleEntry: true, list: [
                                                ...properties.map(c => {return {label: c.name, value: c.id.toString() }})
                                            ]},
                                            {singleEntry: true, list:
                                                [
                                                    { label: 'Not for a property', value: 'NA' },
                                                ]
                                            }
                                        ]
                                    }
                                    />
                                </>
                            }
                            {currentStage === "depreciation" && 
                                <>
                                    <div className={styles.subtitle}>
                                        <p>Depreciation reflects the gradual reduction in the value of your assets over time due to wear and tear, obsolescence, or other factors.</p>
                                        <p>Accurately tracking depreciation allows for precise financial reporting, tax calculations, and strategic decision-making.</p>
                                    </div>
                                    <FormikField<AssetPostModel>
                                        className={classNames(styles.formikSelectField, styles.mandatory)}
                                        label="Method"
                                        name="methodAccounts"
                                        type="select"
                                        option={depreciationTypes}
                                    />                        
                                    <FormikField<AssetPostModel>
                                        className={styles.formikFields}
                                        label="Rate for first year"
                                        name="rateFirstYearAccountsPct"
                                        suffix="%"
                                        type="number"
                                        min='0'
                                        onlyPositiveNumbers                                                   
                                    />
                                    <FormikField<AssetPostModel>
                                        className={styles.formikFields}
                                        label="Rate after first year"
                                        name="rateSubsequentAccountsPct"
                                        suffix="%"
                                        type="number"  
                                        min='0'
                                        onlyPositiveNumbers                                      
                                    />                        
                                    <FormikField<AssetPostModel>
                                        className={styles.formikFields}
                                        label="Current value"
                                        name="currentValue"
                                        prefix="£"
                                        type="number"                                        
                                    />
                                    
                                    <Button
                                        disabled={!values.methodAccounts}
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                                
                            }
                            {currentStage === "capitalAllowance" &&
                                <>
                                    <div className={styles.subtitle}>
                                        <p>Capital allowances play a pivotal role in financial planning and tax optimisation by allowing businesses to claim tax relief on qualifying capital expenditures.</p>
                                        <p>By accurately recording your asset capital allowances, you ensure compliance with tax regulations while maximizing your eligible deductions.</p>
                                    </div>
                                    <FormikField<AssetPostModel>
                                        className={classNames(styles.formikSelectField, styles.mandatory)}
                                        label="Method"
                                        name="methodTax"
                                        type="select"
                                        option={depreciationTypes}
                                    />                        
                                    <FormikField<AssetPostModel>
                                        className={styles.formikFields}
                                        label="Rate for first year"
                                        name="rateFirstYearTaxPct"
                                        suffix="%"
                                        type="number"
                                        min='0'
                                        onlyPositiveNumbers                                        
                                    />
                                    <FormikField<AssetPostModel>
                                        className={styles.formikFields}
                                        label="Rate after first year"
                                        name="rateSubsequentTaxPct"
                                        suffix="%"
                                        type="number"
                                        min='0'
                                        onlyPositiveNumbers
                                                
                                    />                        
                                    <FormikField<AssetPostModel>
                                        className={styles.formikFields}
                                        label="Current value"
                                        name="currentValueTax"
                                        prefix="£"
                                        type="number"
                                    />
                                   
                                    <Button
                                        disabled={!values.methodTax}
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </>
                            }
                            {currentStage === "submit" &&
                                <>
                                    <Summary>
                                        <h2 className={styles.subheading}>Asset details</h2>
                                        <Text fixedWidth ensureRender entity={values} name={'description'} v8Summary />
                                        <DateText fixedWidth ensureRender entity={values} name={'datePurchased'} label='Purchased date' v8Summary />
                                        <YesNo fixedWidth ensureRender entity={values} name={'isFirstYear'} label='First year' v8Summary />
                                        {assetCategories.length > 0 && values.assetCategoryId ?
                                                <Text
                                                    fixedWidth
                                                    ensureRender
                                                    entity={assetCategories.find(category => category.value === values.assetCategoryId)!}
                                                    name={'label'}
                                                    label='Category'
                                                    v8Summary
                                                />
                                            :
                                                <Text
                                                    fixedWidth
                                                    ensureRender
                                                    entity={values}
                                                    name={'assetCategoryId'}
                                                    label='Category'
                                                    v8Summary
                                                />
                                        }
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            valueFormatter={() => formatCurrency(values.originalValue)}
                                            name={'originalValue'}
                                            v8Summary
                                        />
                                        <DateText
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            name={'disposalDate'}
                                            v8Summary
                                        />
                                        {market && market.isProperty && properties.length >0 && values.propertyId ?
                                                <Text
                                                    fixedWidth
                                                    ensureRender
                                                    entity={properties.find(prop => prop.id === values.propertyId)!}
                                                    name={'name'}
                                                    label='Property'
                                                    v8Summary
                                                />
                                            :
                                                <Text
                                                    fixedWidth
                                                    ensureRender
                                                    entity={values}
                                                    name={'propertyId'}
                                                    label='Property'
                                                    v8Summary
                                                />
                                        }

                                        {/* depriciation */}
                                        <h2 className={classNames(styles.subheading, styles.seperation)}>Book value depreciation</h2>
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={depreciationTypes.find(x => x.value === values.methodAccounts)!}
                                            name={'label'}
                                            label='Method'
                                            v8Summary 
                                        />
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            name={'rateFirstYearAccountsPct'} 
                                            valueFormatter={() => formatPercent(values.rateFirstYearAccountsPct
                                                ? (values.rateFirstYearAccountsPct)/100
                                                : values.rateFirstYearAccountsPct)
                                            } 
                                            label='Rate for first year'
                                            v8Summary
                                        />
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            name={'rateSubsequentAccountsPct'} 
                                            valueFormatter={() => formatPercent(values.rateSubsequentAccountsPct
                                                ? (values.rateSubsequentAccountsPct)/100
                                                : values.rateSubsequentAccountsPct)
                                            } 
                                            label='Rate after first year'
                                            v8Summary
                                        />
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            name={'currentValue'}
                                            valueFormatter={() => formatCurrency(values.currentValue)}
                                            label="Current value"
                                            v8Summary
                                        />
                                        
                                        {/* allowance */}
                                        <h2 className={classNames(styles.subheading, styles.seperation)}>Asset capitalise allowance</h2>
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={depreciationTypes.find(x => x.value === values.methodTax)!}
                                            name={'label'}
                                            label='Method'
                                            v8Summary
                                        />
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            name={'rateFirstYearTaxPct'} 
                                            valueFormatter={() => formatPercent(values.rateFirstYearTaxPct
                                                ? (values.rateFirstYearTaxPct)/100
                                                : values.rateFirstYearTaxPct)
                                            } 
                                            label='Rate for first year'
                                            v8Summary
                                        />
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            name={'rateSubsequentTaxPct'} 
                                            valueFormatter={() => formatPercent(values.rateSubsequentTaxPct
                                                ? (values.rateSubsequentTaxPct)/100
                                                : values.rateSubsequentTaxPct )
                                            } 
                                            label='Rate after first year'
                                            v8Summary
                                        />
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            name={'currentValueTax'}
                                            valueFormatter={() => formatCurrency(values.currentValueTax)}
                                            label='Current value'
                                            v8Summary
                                        />
                                    </Summary>

                                    <Submit
                                        disabled={isSubmitting} 
                                        onCancelClick={redirectToAssetList} 
                                        alignLeft
                                    />
                                </>
                                
                            }
                    </Form>
                )}
                </Formik>
            }
      </BrandWrapper> 
    </SimpleFullscreen>
  )
}

export default AssetCreateV8Modal