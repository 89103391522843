import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { Alert } from 'react-bootstrap'

export const PageAlert = ({message, faIcon, showAlert, hideAlert, variant, ignoreSidebar} : 
    {message?:string|undefined, faIcon?: IconProp, hideAlert:() => void, variant:string, showAlert: boolean, ignoreSidebar?: boolean}) => {
    
    //Use as a child of <DefaultLayout>
    useEffect(() => {
        let id: NodeJS.Timeout;
        if (showAlert) {
            id = setTimeout(hideAlert, 5000)
        }
        return () => clearTimeout(id)
    }, [showAlert, hideAlert])
    
    //275px is sidenav width plus 20px margin each side
    return <Alert
        show={showAlert}
        style={ignoreSidebar ? 
            {position: 'fixed', bottom: 0, left: "400px", right: "400px", margin: '1rem 0', zIndex: 1100} : 
            {position: 'fixed', bottom: 0, width: 'calc(100% - 275px)', margin: '1rem 20px', zIndex: 1100}}
        variant={variant}
        dismissible
        onClose={hideAlert}
    >
        {faIcon && <FontAwesomeIcon icon={faIcon} style={{marginRight: '0.5rem'}}/>}
        <h4 className='m-0'>{message}</h4>
    </Alert>
}