import React, {useContext, useEffect, useState} from 'react'
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { Companies } from '../../api/inni/Companies';
import { CompanySummary, NavigationModel, TermsCollection } from '../../api/inni/data-contracts';
import { Features } from '../../api/inni/Features';
import { Terms } from '../../api/inni/Terms';
import { useInniAPI } from '../../hooks/useInniAPI';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { Action, Entity } from '../../utils/EntityAction';
import { Feature } from '../../utils/Feature';
import { LoadingFullPage } from '../../elements/LoadingFullPage/LoadingFullPage';
import BrandContext from '../../context/BrandContext';
import configHelper, { Brand as BrandConfig }  from '../../utils/configHelper';
import UserContext from '../../context/UserContext';
import _ from 'lodash';
import { ContactProfiles } from '../../api/inni/ContactProfiles';
import LogRocket from 'logrocket';
import { useGetUrl } from '../../hooks/useGetUrl';
import { Navigation } from '../../api/inni/Navigation';
// import useIsMobile from '../../hooks/useIsMobile';


type CompanyWrapperProps = {
    children?: React.ReactNode
    setHasAMSupport: (value: boolean) => void
}

interface RouteParams {
    cid: string
}

/** Gets the company ID from the route match */
export const CompanyWrapper = ({children, setHasAMSupport} : CompanyWrapperProps) => {
    const brand = useContext(BrandContext);
    const userContext = useContext(UserContext);

    const getUrl = useGetUrl()
    const history = useHistory()

    const userOid = _.get(userContext, 'user.oid', '');

    // Get the cid from the router route match
    const cid = parseInt(useParams<RouteParams>().cid);

    const companyAPI = useInniAPI(Companies);
    const termsAPI = useInniAPI(Terms);
    const featuresAPI = useInniAPI(Features);
    const navigationAPI = useInniAPI(Navigation);
    // const isMobile = useIsMobile()

    const [activeEntity, setActiveEntity] = useState<Entity|undefined>(undefined);

    const [company, setCompany] = useState<CompanySummary | undefined>(undefined);
    const [features, setFeatures] = useState<Array<Feature> | undefined>(undefined);
    const [terms, setTerms] = useState<TermsCollection | undefined>(undefined);
    const [product, setProduct] = useState<CompanyProduct | undefined>(undefined);
    const [baseProductName, setBaseProductName] = useState<string | undefined>(undefined);
    const [baseProductCategory, setBaseProductCategory] = useState<string | undefined>(undefined);
    const [menu, setMenu] = useState<NavigationModel| undefined>(undefined);
    const [loadingCompany, setLoadingCompany] = useState(false);
    const [loadingFeatures, setLoadingFeatures] = useState(false);
    const [loadingTerms, setLoadingTerms] = useState(false);
    

    const [errorLoading, setErrorLoading] = useState(false);
    const profileAPI=useInniAPI(ContactProfiles)
    
    useEffect(() => {
        if (brand.overrideBrand) brand.overrideBrand(undefined)
        setCompany(undefined);
        setFeatures(undefined);
        setTerms(undefined);     
        setProduct(undefined);
        setBaseProductCategory(undefined);
        setBaseProductName(undefined);
        setErrorLoading(false);   
        setHasAMSupport(false)
        setMenu(undefined);
    }, [cid]);

    // Get company
    useEffect(() => {
        if (companyAPI && cid && !company && !loadingCompany && userContext) {
            const sessionCompany = window.sessionStorage.getItem(`temp_${userOid}_${cid}_company`);

            const brandOverride = (tenantId: number) => {
                if (tenantId === 0 && brand.overrideBrand) {
                    brand.overrideBrand(BrandConfig.inniAccounts)
                } else if (tenantId === 1 && brand.overrideBrand) {
                    brand.overrideBrand(BrandConfig.Provestor)
                }
            }

            // First render from cache then check for updates from backend
            // When React only we can be more clever with updating cache
            if (userOid !== '' && sessionCompany) {
                const parsedCompany = JSON.parse(sessionCompany);
                setCompany(parsedCompany);
                if(parsedCompany) {
                    setProduct(parsedCompany.isCAP ? CompanyProduct.isCap : CompanyProduct.isManaged);
                }
                brandOverride(parsedCompany.tenantId);
            } 

            setLoadingCompany(true);
            companyAPI.get(cid)
            .then(
                response => {
                    setCompany(response.data)

                    if(response.data) {
                        setProduct(response.data.isCAP ? CompanyProduct.isCap : CompanyProduct.isManaged);
                        setBaseProductName(response.data.baseProductName);
                        setBaseProductCategory(response.data.baseProductCategory);
                    }

                    if (window.appConfig.brand === 'pv' && window.appConfig.environment !== 'dev')
                        window.appziSettings = {
                            userId: userContext.user?.email || '',
                            data: {
                                name: userContext.user?.fullName || '',
                                email: userContext.user?.email || '',
                                brand: window.appConfig.brand,
                                environment: window.appConfig.environment,
                                isSaas: response.data.isCAP,
                                companyId: response.data.id,
                                companyName: response.data.name || '',
                                companyCreatedDate: response.data.companyCreatedDate
                            }
                        }

                    setLoadingCompany(false);
                    brandOverride(response.data.tenantId);

                    // Save to session for subsequent updates
                    if (userOid !== '') {
                        window.sessionStorage.setItem(`temp_${userOid}_${cid}_company`, JSON.stringify(response.data));
                    }
                }
            ).catch(error => setErrorLoading(true))
        }
    }, [cid, companyAPI, company, loadingCompany, userContext, userOid]);


    // Get features
    useEffect(() => {
        if (featuresAPI && cid && !features && !loadingFeatures) {
            // Render from cache
            const sessionFeatures = window.sessionStorage.getItem(`temp_${userOid}_${cid}_features`);
            if (userOid !== '' && sessionFeatures) {
                setFeatures(JSON.parse(sessionFeatures));
            } 

            // Update from backend
            setLoadingFeatures(true)
            featuresAPI.index(cid)
            .then(
                (response) =>  {
                    let f:Array<Feature> = [];
                    response.data.forEach(feature => {
                        try {
                            // Cast strings back to enumes
                            const e = feature.entity ?  Entity[feature.entity as keyof typeof Entity] : undefined;
                            const a = feature.action ?  Action[feature.action as keyof typeof Action] : undefined;

                            if (e !== undefined && a !== undefined) {
                                f.push({
                                    entity: e,
                                    action: a,
                                    isAdminOnly: feature.isAdminOnly
                                })            
                            } else {
                                console.warn(`Skipping loading feature permission: ${feature.entity}.${feature.action}`);
                            }
                        } catch {
                            console.error(`Error loading feature permission: ${feature.entity}.${feature.action}`);
                        }
                    })
                    setLoadingFeatures(false);
                    setFeatures(f)
                    setHasAMSupport(f.find(x => x.entity === Entity.AccountManagerSupport) !== undefined)
                    // Save to session for subsequent updates
                    if (userOid !== '') {
                        window.sessionStorage.setItem(`temp_${userOid}_${cid}_features`, JSON.stringify(f));
                    }

                }
            ).catch(error => setErrorLoading(true))
        }
    }, [cid, featuresAPI, features, setHasAMSupport]);


    // Get terms
    useEffect(() => {
        if (termsAPI && cid && !terms && !loadingTerms) {
            const sessionTerms = window.sessionStorage.getItem(`temp_${userOid}_${cid}_terms`);
            if (userOid !== '' && sessionTerms) {
                setTerms(JSON.parse(sessionTerms));
            } else {
                setLoadingTerms(true);
                termsAPI.index(cid)
                .then(
                    response => {            
                        setLoadingTerms(false);
                        setTerms(response.data)
                        // Save to session for subsequent updates
                        if (userOid !== '') {
                            window.sessionStorage.setItem(`temp_${userOid}_${cid}_terms`, JSON.stringify(response.data));
                            
                        }
                    }
                ).catch(error => setErrorLoading(true))
            }
        }
    }, [cid, termsAPI, terms]);

    useEffect(() => {         
        if (profileAPI && userContext.user?.oid && !sessionStorage.getItem('temp_debugging')) {
                profileAPI.isdebuggingEnabled(userContext.user?.contactId)
                .then(response => {    
                    window.sessionStorage.setItem(`temp_debugging`, response.data);
                    if (sessionStorage.getItem('temp_debugging') ==='True' )  {
                        LogRocket.init('ke6fuy/react-v7-app');
                    }
                }).catch(error => {
                    console.error(error);
                })
            }
    }, [profileAPI, userContext.user, userContext.user?.contactId])

    //get navigation menu
    useEffect(() => {         
        if (navigationAPI && cid && cid > 0 && !menu) {
            navigationAPI.index(cid, {isMobile: false} ) //Keep all navigation items even if mobile, ticket 15084
                .then(response => {    
                    setMenu(response.data)
                })
                .catch(error => {
                    setErrorLoading(true)
                })
        }
    }, [navigationAPI, cid, menu])

    const billingPageUrl = company ? 
        company.isCAP ? 
            getUrl(Entity.BillingV7, Action.List, {cid: company?.id}).url : 
            getUrl(Entity.Subscription, Action.List, {cid: company?.id}).url : 
        undefined;
    
    const onBillingPage = billingPageUrl && history.location.pathname === billingPageUrl

    //reload menu ondemand
    const reloadMenu = () =>{
        setMenu(undefined)
    }

    return (
        <CompanyContext.Provider value={{
            cid: cid,
            product: product,
            baseProductName: baseProductName,
            baseProductCategory: baseProductCategory,
            company: company,
            features: features, 
            terms: terms,
            activeEntity: activeEntity,
            setActiveEntity: setActiveEntity,
            navMenu: menu,
            reloadMenu: reloadMenu
        }}>
            {!(company && features && terms) && 
                <LoadingFullPage entityName='company' errorMessage={errorLoading ? 'Sorry, there was an error loading your company. Please refresh this page or contact us for support.' : undefined} />
            }
            
            {company && company.showBillingBlocker && billingPageUrl && !onBillingPage &&
                <Redirect to={billingPageUrl} />
            }

            {company && features && terms &&
                <>
                    {children}
                </>
            }
        </CompanyContext.Provider>
    )

}