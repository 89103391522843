import { faHistory, faReceipt, faCloudUploadAlt, faChevronLeft, faCheck, faSpinner, faCamera, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { ToggleButtonGroup, ToggleButton, Card, Row, Col, Alert } from 'react-bootstrap'
import { Account, BankTransaction, BankTransactionPostModel, DatePicker, Receipt, ReceiptAsSummary } from '../../api/inni/data-contracts'
import { useInniAPI } from '../../hooks/useInniAPI'
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout'
import { Receipts as ReceiptsAPI } from '../../api/inni/Receipts'
import CompanyContext from '../../context/CompanyContext'
import { asYearMonthDay, formatDate } from '../../utils/formatDate'
import { placeholderImage } from '../../utils/formatters/formatProperty'
import Dropzone from 'react-dropzone'
import { Button } from '../../elements/Button/Button'
import { BankTransactions } from '../../api/inni/BankTransactions'
import { FormikErrors, FormikHelpers } from 'formik'
import { useModalDialog } from '../../hooks/useModalDialog'
import { useParams } from 'react-router-dom'
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity'
import { Action, Entity } from '../../utils/EntityAction'
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder'
import styles from './Receipts.module.css'
import useIsMobile from '../../hooks/useIsMobile'
import classNames from 'classnames'
import { AccountViewerNewWrapper } from '../../components/AccountViewerNew/AccountViewerNewWrapper'
import { AccountViewerFeatureSet } from '../../components/AccountViewerNew/AccountViewerNew'
import { useFetchEntityList } from '../../hooks/entities/useFetchEntityList'
import { Accounts } from '../../api/inni/Accounts'
import MobileBottomBar from '../../components/MobileBottomBar/MobileBottomBar'
import FileIcon from '../../elements/FileIcon/FileIcon'
import ReceiptModal from './Components/ReceiptModal'

export type ReceiptAccountTypes = 'company' | 'personal'

interface RouteParams {  
    tab: string
}

const LoadingReceipt = () => (
    <Col xs={12} md={6} lg={3} xl={2}>
        <Card style={{marginBottom: '30px'}}>
            <Card.Body style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                <img style={{border: '1px solid black', margin: '5px', maxWidth: '100%', height: '200px'}} src={placeholderImage}/>
                <p style={{textAlign: 'center', marginTop: '10px'}}><LoadingPlaceholder width="200px" /></p>
            </Card.Body>
        </Card>
    </Col>
)

const Receipts = () => {

    const tab = useParams<RouteParams>().tab || 'inbox';
    const navigateToEntity = useNavigateToEntity();

    const receiptAPI = useInniAPI(ReceiptsAPI);
    const bankTransactionsAPI = useInniAPI(BankTransactions, [400]);
    const [receiptData, setReceiptData] = useState<ReceiptAsSummary[] | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);
    const [showImageModal, setShowImageModal] = useState<boolean>(false);
    const [valuationModalId, setValuationModalId] = useState<number|undefined>(undefined);
    const [showSuccessfullyAttached, setShowSuccessfullyAttached] = useState(false)
    const [showModalDialog, modalDialog] = useModalDialog();


    const [uploading, setUploading] = useState(false)
    
    const companyContext = useContext(CompanyContext);

    useEffect(() => {
            if (receiptAPI && isLoading && tab.toLowerCase() === "inbox") {
                receiptAPI.index(companyContext.cid).then(res => {
                    setReceiptData(res.data);
                    setIsLoading(false);
                })
            }
        }
    ,[receiptAPI, isLoading, companyContext.cid])

    useEffect(() => {
        if(tab.toLowerCase() === "inbox"){
            setIsLoading(true)
        }
    }, [tab])

    const isMobile = useIsMobile()


    const uploadReceipt = async (file:File[]) => {
        if (receiptAPI) {
            
            file.map(x => {
                setUploading(true)
                receiptAPI.upload(companyContext.cid, {
                    fileToUpload:x
                }).then(res => {
                    receiptAPI.index(companyContext.cid).then(res => {
                        setReceiptData(res.data);
                    })
                    setUploading(false)
                })
            })
        }
    }

    const onDeleteDocument = (receiptId: number) => {
        if (receiptAPI) {
            receiptAPI.deleteReceipt(companyContext.cid, receiptId)
            .then(res => {
                if (receiptData) {
                    setReceiptData(receiptData.filter(x => x.id !== receiptId))
                }
            })
        }
    }

    const handleDeleteDocument = (valuationId: number) => {
        onDeleteDocument(valuationId)
        setShowImageModal(false)
    }

    const formValidate =  (values: BankTransactionPostModel & {isAsset: boolean}) : Promise<FormikErrors<BankTransactionPostModel & {isAsset: boolean}>> => {
        return new Promise((resolve, reject) => {
            if (bankTransactionsAPI) {
                return bankTransactionsAPI.validateCreate(companyContext.cid, {...values, type: values.isAsset ? 'AP' : 'EX'})
                .then(() => resolve({}))    
                .catch(error => resolve(error.error))            
            } else {
                reject();
            } 
        })         
    }

    const formSubmit = (values: BankTransactionPostModel & {isAsset: boolean}, actions: FormikHelpers<BankTransactionPostModel & {isAsset: boolean}>) : Promise<void>=> {
        return new Promise((resolve, reject) => {
            if (bankTransactionsAPI) {
                bankTransactionsAPI.createBankPayment(companyContext.cid, false, {...values, type: values.isAsset ? 'AP' : 'EX'})
                .then(data => {
                    if (valuationModalId !== undefined) {
                        receiptAPI?.attach(companyContext.cid, valuationModalId, Number(data.data))
                        .then ((res) => {
                            if (receiptData) {
                                setReceiptData(receiptData.filter(x => x.id !== valuationModalId))
                            }
                            setShowImageModal(false)
                            resolve();   

                        }).catch(error => {
                            actions.setErrors(error.error);
                            reject();
                        })
                        
                    }
                    setShowImageModal(false)
                    
                    resolve();               
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } else {
                reject();
            }
        })
    }

    const getFilter = (yearCode: string|undefined) :  Promise<DatePicker> => {
        const p = new Promise<DatePicker>((resolve, reject) => {
            receiptAPI?.filter(companyContext.cid, {
                yearCode: yearCode
            })
                .then(results => {
                    if (results.data.datePicker) {
                        resolve(results.data.datePicker)
                    } else reject();                    
                }).catch(error => reject(error))
        })
        return p;
    }

    const getReceipts = (yearCode: string) :  Promise<BankTransaction[]> => {
        const p = new Promise<BankTransaction[]>((resolve, reject) => {
            bankTransactionsAPI?.index(companyContext.cid, { yearCode: yearCode, hasImage: true, onlyThumbnail: true })
                .then(results => {
                    if (results.data) {
                        resolve(results.data)
                    } else reject();                    
                }).catch(error => reject(error))
        })
        return p;
    }

    const attachReceipt = (id : number, bpid : number) => {
        if (receiptAPI) {
            receiptAPI.attach(companyContext.cid, id, bpid).then((res) => {
                if (receiptData) {
                    setShowImageModal(false)                    
                    setIsLoading(true)
                    if (res.status === 200) {
                        setShowSuccessfullyAttached(true)
                        setTimeout(() => {
                            setShowSuccessfullyAttached(false)
                        }, 2500)
                    }
                    
                }
            })
        }
    }

    const showDeleteDialog = (id: number) => {
        showModalDialog(
            'Delete receipt?',
            'Are you sure you want to delete this receipt?',
            [
                <Button variant="danger" label="Yes" onClick={() => handleDeleteDocument(id)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    return (
        <DefaultLayout entity={Entity.Receipt} useFullWidth title='Receipts'>
            {!isMobile && 
            <ToggleButtonGroup type="radio" name="options" value={tab} onChange={(value) => navigateToEntity(Entity.Receipt, Action.Read, {tab: value})} style={{marginBottom: '20px'}}>
                <ToggleButton id="tbg-btn-1" value={'inbox'} variant={tab === 'inbox' ? 'outline-primary' : 'outline-secondary'}>
                    <FontAwesomeIcon icon={faReceipt} className={`mr-2`} />
                    Receipts
                </ToggleButton>
                <ToggleButton id="tbg-btn-2" value={'history'} variant={tab === 'history' ? 'outline-primary' : 'outline-secondary'}> 
                    <FontAwesomeIcon icon={faHistory} className={`mr-2`} />
                    History
                </ToggleButton>
            </ToggleButtonGroup>}
            {tab === 'inbox' && <>
            {/* This has been moved to the top so they don't have to scroll to upload a receipt */}
            {!isMobile && 
            <Dropzone onDrop={(f) => uploadReceipt(f)}>
                {
                    ({getRootProps, getInputProps}) => (
                        <div {...getRootProps()} data-cy="dropzone" style={{width: '100%', height: '200px', background: 'rgba(10, 10, 10, 0.03)', border: '2px dashed #ddd', marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {!uploading && <>
                            <input {...getInputProps()} />
                            <FontAwesomeIcon icon={faCloudUploadAlt} size='5x' style={{color: '#888888'}}/>
                            <div style={{color: '#888888', marginLeft: '20px'}}>
                                <h3>Drop your receipts here</h3>
                                <p>We currently support PNG, JPEG and PDF files, up to 10MB</p>
                                {/* TODO we don't have a help btn on v7 */}
                                {/* <p>For further information click the Help button</p> */}
                            </div></>}
                            {uploading &&
                            <FontAwesomeIcon style={{color: '#888888'}} spin size="5x" icon={faSpinner} />}
                        </div>
                    )
                }
            </Dropzone>}
            { isLoading && !receiptData &&
            <Row style={{marginTop: '20px'}}>
                <LoadingReceipt />
                <LoadingReceipt />
                <LoadingReceipt />
                <LoadingReceipt />
            </Row>}
            { (!isLoading || receiptData) &&
            <Row className={styles.rcpImgContainer} style={{marginTop: '20px'}}>
                {receiptData?.map(i => {
                    return (
                        <Col key={i.id} xs={12} md={6} lg={3} xl={2}>
                            {/* maybe rmv this shadow??? */}
                            <Card data-cy="rptCard" className={classNames(styles.rcpImgCard, 'shadow')} onClick={() => {setValuationModalId(i.id); setShowImageModal(true)}}>
                                <Card.Body style={{alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                                    {/* The `trash` icon isn't shown on desktop, it could be, but isn't on old desktop version */}
                                    <div onClick={(e) => {e.stopPropagation(); showDeleteDialog(i.id)}} className={styles.deleteBtn}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </div>
                                    {i.thumbnailUrl?.indexOf('.pdf') !== -1 ? <FileIcon iconType="pdf" /> :
                                    <img alt="Placeholder" style={{border: '1px solid black', margin: '5px', maxWidth: '100%', height: '200px'}} src={i.thumbnailUrl ? i.thumbnailUrl : placeholderImage}/>}
                                    <p style={{textAlign: 'center', marginTop: '10px'}}>{formatDate(i.date)}</p>
                                </Card.Body>
                            </Card>
                        </Col> )
                })} 
            </Row>}
            </>} 

            { showImageModal &&

                <ReceiptModal 
                    receiptId={valuationModalId || 0} 
                    deleteReceipt={handleDeleteDocument} 
                    attachReceipt={attachReceipt} 
                    formSubmit={formSubmit} 
                    formValidate={formValidate}
                    setShowImageModal={setShowImageModal}
                />

            }
           
            {tab === 'history' && <>
                <br /><AccountViewerNewWrapper getFilter={getFilter} getTransactions={getReceipts} featureSet={AccountViewerFeatureSet.RECEIPTS} bankAccountId={0} />
            </>}
            <Alert show={showSuccessfullyAttached} style={{position: 'fixed', bottom: isMobile ? '40px' : 0, width: '90%', left: '5%'}} variant="success" dismissible onClose={() => setShowSuccessfullyAttached(false)}>
                <FontAwesomeIcon icon={faCheck} style={{marginRight: '15px'}} />Successfully attached receipt to bank payment
            </Alert>
            {/* Only show on mobile - uses the mobile camera */}
            {/* We simply upload the file we are given */}
            {isMobile && tab === 'inbox' && (
                <MobileBottomBar>
                    <label>
                        <FontAwesomeIcon icon={faCamera} />
                        <input onChange={(e) => e.target.files ? uploadReceipt([e.target.files[0] as File]) : null} type="file" hidden id="imageFile" capture="environment" accept="image/*" />
                    </label>
                    <label>
                        <FontAwesomeIcon icon={faCloudUploadAlt} />
                        <input onChange={(e) => e.target.files ? uploadReceipt([e.target.files[0] as File]) : null} type="file" hidden id="imageFile" accept="image/*" />
                    </label>
                </MobileBottomBar>
            )}

            { modalDialog }
        </DefaultLayout>
    )
}
export default Receipts