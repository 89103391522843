import { useContext } from 'react';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder';
import styles from './InfoBanner.module.scss';  
import stylesV75 from './InfoBanner_V75.module.scss';  
import CompanyContext, { CompanyProduct } from "../../context/CompanyContext";

interface InfoBannerCommon {
  type: 'info' | 'warning' | 'danger' | 'positive' | 'admin',
  thin?: boolean,
}

interface InfoBannerProps extends InfoBannerCommon {
  title?: string | JSX.Element,
  body: string  | JSX.Element,
  noBottomMargin?: boolean,
  noTopMargin?: boolean,
  whiteBg?: boolean
}
 
const InfoBanner = ({ title, body, type, thin=false, noBottomMargin, noTopMargin, whiteBg } : InfoBannerProps) => {
  const companyContext = useContext(CompanyContext);
  const v75Styling = companyContext.company?.useV8UI;
  const styling = v75Styling ? stylesV75 : styles;

  return (
    <div
      data-cy="infoBanner"
      className={`
        ${styling.infoBanner}
        ${styling[type]}
        ${thin ? styling.thin : ''}
        ${noBottomMargin ? styling.noBottomMargin : ''}
        ${noTopMargin ? styling.noTopMargin : ''}
        ${whiteBg ? styling.whiteBg : ''}
      `}
    >
      { title && (
        <>
          { v75Styling
            ? <h5 className="mb-2"><strong>{ title }</strong></h5>
            : <p className="mb-3"><strong>{ title }</strong></p>
          }
        </>
      )}
      { body }
    </div>
  )
};

const LoadingInfoBanner = ({type, thin} : InfoBannerCommon) => (
  <div className={`${styles.infoBanner} ${styles.infoBannerInfo} ${thin ? styles.thin : ''}`}>
    <LoadingPlaceholder dark />
    <br />
    <LoadingPlaceholder dark />
  </div>
)

export { LoadingInfoBanner }

export default InfoBanner;
