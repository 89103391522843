import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { faHouse, faCheckCircle, faCircle, faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './PropertyImport.module.css';
import { ExternalProperty } from '../../../api/inni/data-contracts';
import Select from 'react-select';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import LinkPropertyInfo from './Components/LinkPropertyInfo';


type LinkableProperty = {
  linked: string  | null,
  id: number,
  name: string,
  address?: string,
  postcode: string
}

export type LinkedProperty = {
  value: number,
  label: string
}

interface PropertyImportEntryProps {
  entry: ExternalProperty,
  selected: boolean,
  linked: LinkedProperty | null,
  selectItem: (id: string) => void
  linkableProperties: LinkableProperty[]
  softLinkProperty: (
    externalPropertyId: string,
    oldLinkID: number | null,
    newLinkId: LinkedProperty | null) => void
  showLinkMessage: string | null,
  setShowLinkMessage: Dispatch<SetStateAction<string | null>>
};

export const PropertyImportEntry = ({
    entry,
    selected,
    linked,
    selectItem,
    linkableProperties,
    softLinkProperty,
    showLinkMessage,
    setShowLinkMessage
  }: PropertyImportEntryProps) => {

  const [selectLinkOptions, setSelectLinkOptions] = useState<LinkedProperty[]>([]);

  // Create <Select> options from the linkableProperties prop
  useEffect(() => {
    const linkables = Object.values(linkableProperties);
    let linkOptions = [];
    for (let i = 0; i < linkables.length; i += 1) {
      const property = linkables[i];
      if ((!property.linked && property.postcode.replace(/ /g, '') === entry.postcode?.replace(/ /g, '')) ||
        (property.linked === entry.id))
        linkOptions.push({value: property.id, label: `(${property.name}) - ${property.address}, ${property.postcode}`});
    }

    setSelectLinkOptions(linkOptions);
  }, [linkableProperties])

  useEffect(() => {
    // Show link property info message on first selected entry only
    if (!showLinkMessage && selectLinkOptions.length > 0 && selected) {
      setShowLinkMessage(entry.id);
    }
  }, [selectLinkOptions, showLinkMessage, selected])

  // Select property link
  const handleLinkProperty = (selected: LinkedProperty | null) => {
    softLinkProperty(entry.id, linked?.value || null, selected || null);
  }

  const renderTooltip = (props:any) => (
    <Tooltip id="button-tooltip" {...props}>
      Linked to a Provestor property
    </Tooltip>
  );

  return (
    <div className={`${styles.propertyImportEntry} ${selected ? styles.selPropertyImportEntry : null}`}>
      <div className={`row ${styles.selectableRow}`} onClick={() => selectItem(entry.id)}>
        {/* House photo, larger screens+ only */}
        <div className="col-2 d-none d-lg-flex p-0 align-items-center justify-content-center">
          { entry.mainImageUrl
            ? <img src={entry.mainImageUrl} alt="house photo" />
            : <FontAwesomeIcon icon={faHouse} className={styles.faIcon} />
          }
        </div>

        {/* House icon, medium screens only */}
        <div className={`col-2 d-none d-md-flex d-lg-none align-items-center ${styles.flexIcon}`}>
          <FontAwesomeIcon icon={faHouse} className={styles.faIcon} />
        </div>

        {/* Address */}
        <div className="col">
          <h3 style={{ display: 'inline-block' }}>{ entry.name }</h3>
          { linked && (
            <OverlayTrigger placement='top' overlay={renderTooltip}>
              <FontAwesomeIcon
                icon={faLink}
                className={styles.linkIcon}
              />
            </OverlayTrigger>
          )}
          <br />
          { entry.address && <span>{ entry.address }</span> }
        </div>

        {/* Divider */}
        <span className={`col-1 d-none d-md-block ${styles.divider}`} />

        {/* Check icon */}
        <div className={`col-12 col-md-2 d-flex align-items-center ${styles.flexIcon}`}>
          <FontAwesomeIcon
            icon={selected ? faCheckCircle : faCircle}
            className={`${styles.faIcon} ${styles.checkIcon} ${selected ? styles.checkIconSelected : null}`}
          />
        </div>
      </div>

      {/* Link property */}
      { (selected && (selectLinkOptions.length > 0)) && (
        <div className={styles.linkPropertyRow}>
          { showLinkMessage === entry.id && <LinkPropertyInfo /> }

          {/* NOTE: react select is using findDOMNode which is to be deprecated,
              used here for consistency but requires discussion  - DD */}
          <Select
            className={styles.linkProperty}
            options={selectLinkOptions}
            placeholder="Link to existing Provestor property?"
            onChange={handleLinkProperty}
            value={linked ? {value: linked.value, label: linked.label} : null}
            isClearable
            noOptionsMessage={() => "No properties remaining"}
          />
        </div>
      )}
    </div>
  )
}
