import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom';
import {  TenancyAsDetailed } from '../../api/inni/data-contracts';
import { useAssertPermission } from '../../hooks/useAssertPermission';
import { Action, Entity } from '../../utils/EntityAction';
import * as Tabs from '../../elements/VerticalTabs/VerticalTabs';
import { EmptyLayout } from '../../layouts/Desktop/EmptyLayout';
import { Tenancies } from '../../api/inni/Tenancies';
import { TenancyHeader } from './Components/TenancyHeader';
import { DetailsRead } from './Components/DetailsRead';
import { DetailsEdit } from './Components/DetailsEdit';
import { TenancyNotesPage } from './TenancyNotesPage';
import { useFetchSingleEntity } from '../../hooks/entities/useFetchSingleEntity';
import { useDeleteEntity } from '../../hooks/entities/useDeleteEntity';
import { TenancyName } from './TenancyUtilities';
import { useLettableUnitsList } from '../../hooks/lists/useLettableUnitsList';
import { TenancyInvoices } from './Components/TenancyInvoices';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { useModalDialog } from '../../hooks/useModalDialog';
import { Button } from '../../elements/Button/Button';
import { useHistoryWrapper } from '../../hooks/useHistoryWrapper';

interface RouteParams {  
    id: string
}

export const TenancyReadPage = () => {
    useAssertPermission(Entity.Tenancy, Action.Read);
    const tenancyId = parseInt(useParams<RouteParams>().id);
    const [tenancy, tenancyLoaded, reloadTenancy] = useFetchSingleEntity<TenancyAsDetailed, Tenancies>(Tenancies, tenancyId);
    const [selectedKey, setSelectedKey] = useState<string|undefined>('details');
    const deleteTenancy = useDeleteEntity(Tenancies);
    const [units, unitsLoaded] = useLettableUnitsList();
    const companyContext = useContext(CompanyContext)
    const [showEdit, setShowedit]= useState(false)
    const [showModalDialog, modalDialog] = useModalDialog(); 
    const v8Styling = companyContext.company?.useV8UI
    const history = useHistoryWrapper();

    const onEditClick = () => {
        setSelectedKey('edit');
    }

    const onEditClose = () => {
        setSelectedKey('details');
        window.scrollTo(0, 0);
    }
    const handleDeleteClick = () =>{
        showModalDialog(
            'Delete tenancy?',
            `Are you sure you want to delete this tenancy?`,
            [
                <Button key="delete" variant="danger" label="Yes" onClick={onDeleteClick} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );

    }
    
    const onDeleteClick = () => {
        deleteTenancy(tenancyId)
        .then(() => {
            history.goBack();
        })
        .catch((error)=>{
            console.error(error)
        })
    }

    const toggleEditView=()=>{
        setShowedit(!showEdit)
    }

    const hideRentPaymentsTab = companyContext.product === CompanyProduct.isCap
    const hideNotesTab = companyContext.product === CompanyProduct.isCap
 
    return (
        <EmptyLayout entity={Entity.Tenancy} title={TenancyName(tenancy)} loading={!tenancyLoaded || !unitsLoaded}>
            {modalDialog}
            <TenancyHeader 
                tenancy={tenancy} 
                units={units} 
                onEditClick={
                    v8Styling
                        ? !showEdit
                            ? toggleEditView
                            : undefined
                        : selectedKey === 'edit'
                            ? undefined
                            : onEditClick
                } 
                onDeleteClick={
                    v8Styling 
                        ? !showEdit
                            ? handleDeleteClick
                            : undefined
                        : selectedKey === 'edit'
                            ? undefined
                            : onDeleteClick
                }
            />

            { v8Styling && tenancy && (
                <>
                    { showEdit
                        ? <DetailsEdit id={tenancyId} tenancy={tenancy} close={toggleEditView} reloadTenancy={reloadTenancy} units={units} />
                        : <DetailsRead
                            tenancy={tenancy}
                            unit={units.find(u => u.id === tenancy.propertyLettableUnitId)}
                            v8Styling={v8Styling}
                            toggleEdit={toggleEditView}
                        />
                    }
                </>
            )}

            {!v8Styling &&
                <Tabs.Wrapper>
                    <Tabs.Tabs selectedKey={selectedKey === 'edit' ? 'details' : selectedKey} onChangeTab={setSelectedKey} >
                        <Tabs.Tab keyName="details">Tenancy details</Tabs.Tab>
                        <Tabs.Tab keyName="rent" hidden={hideRentPaymentsTab}>Rent &amp; payments</Tabs.Tab>
                        <Tabs.Tab keyName="notes" hidden={hideNotesTab}>Notes</Tabs.Tab>
                    </Tabs.Tabs>
                    <Tabs.Content>
                        {selectedKey === 'details' && tenancy &&
                            <DetailsRead tenancy={tenancy} toggleEdit={toggleEditView} />
                        }
                        {selectedKey === 'edit' && tenancy &&
                            <DetailsEdit id={tenancyId} tenancy={tenancy} close={onEditClose} reloadTenancy={reloadTenancy} units={units} />
                        }
                        {selectedKey === 'rent' && tenancy &&
                            <TenancyInvoices id={tenancyId} />
                        }
                        {selectedKey === 'notes' && tenancy &&
                            <TenancyNotesPage tenancyId={tenancyId} />
                        }
                    </Tabs.Content>
                </Tabs.Wrapper>
            }
        </EmptyLayout>
    )
}