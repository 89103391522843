import Select from "react-select";
import { LoadingPlaceholderFYSelector } from "../../../elements/LoadingPlaceholder/LoadingPlaceholder";
import styles from '../CorporationTax.module.scss'

type FinancialYearSelectorProps = {
	setFilter:(a:number | undefined)=>void;
	options: Array<{ value: string; label: string }> | undefined;  
    placeHolder?:string;
    isLoaded:boolean;
};

const FinancialYearSelector = ({ setFilter, options, placeHolder, isLoaded }: FinancialYearSelectorProps) => {
	return (
        <>
            {!isLoaded &&
            <div className='mr-2'>
                <LoadingPlaceholderFYSelector/>
            </div>
                
            }

            {isLoaded &&
                <div data-cy="selectBtn">
                    <Select
                        className={styles.SelectFilter}
                        classNamePrefix="SelectFilter"
                        onChange={(value) => value ? setFilter(+value.value) : setFilter(undefined)}
                        options={options && options}   
                        placeholder={placeHolder}
                        defaultValue={options && options[0]}
                    />
                </div>
            }
            
        </> 
	);
};

export default FinancialYearSelector;