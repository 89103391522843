import React, { useState, useEffect, useContext } from 'react';
import { ContactFeatures } from '../../api/inni/ContactFeatures';
import { ContactTerms } from '../../api/inni/ContactTerms';
import { TermsCollection } from '../../api/inni/data-contracts';
import BrandContext from '../../context/BrandContext';
import CompanyContext from '../../context/CompanyContext';
import UserContext from '../../context/UserContext';
import { useInniAPI } from '../../hooks/useInniAPI';
import configHelper, { Brand } from '../../utils/configHelper';
import { Entity, Action } from '../../utils/EntityAction';
import { Feature } from '../../utils/Feature';
import { LoadingFullPage } from '../../elements/LoadingFullPage/LoadingFullPage';
import { ContactProfiles } from '../../api/inni/ContactProfiles';
import LogRocket from 'logrocket';

const ContactWrapper = ({children} : {children?: React.ReactNode}) => {
    const [features, setFeatures] = useState<Array<Feature> | undefined>(undefined);
    const [terms, setTerms] = useState<TermsCollection | undefined>(undefined);

    const [loadingFeatures, setLoadingFeatures] = useState(false);
    const [loadingTerms, setLoadingTerms] = useState(false);

    const termsAPI = useInniAPI(ContactTerms);
    const featuresAPI = useInniAPI(ContactFeatures);

    const [activeEntity, setActiveEntity] = useState<Entity|undefined>(undefined);
    const [errorLoading, setErrorLoading] = useState(false);

    const userContext = useContext(UserContext)
    const brandContext = useContext(BrandContext)
    const profileAPI=useInniAPI(ContactProfiles)

    useEffect(() => {
        let oid = userContext.user?.oid
        if (featuresAPI && !features && !loadingFeatures) {
            // Render from cache
            const sessionFeatures = window.sessionStorage.getItem(`temp_${oid}_features`);
            if (oid !== '' && sessionFeatures) {
                setFeatures(JSON.parse(sessionFeatures));
            } 

            // Update from backend
            setLoadingFeatures(true)
            featuresAPI.index(0)
            .then(
                (response) =>  {
                    let f:Array<Feature> = [];
                    response.data.forEach(feature => {
                        try {
                            // Cast strings back to enumes
                            const e = feature.entity ?  Entity[feature.entity as keyof typeof Entity] : undefined;
                            const a = feature.action ?  Action[feature.action as keyof typeof Action] : undefined;

                            if (e !== undefined && a !== undefined) {
                                f.push({
                                    entity: e,
                                    action: a,
                                    isAdminOnly: feature.isAdminOnly,
                                    isGreyed: feature.isGrey
                                })            
                            } else {
                                console.warn(`Skipping loading feature permission: ${feature.entity}.${feature.action}`);
                            }
                        } catch {
                            console.error(`Error loading feature permission: ${feature.entity}.${feature.action}`);
                        }
                    })
                    setLoadingFeatures(false);
                    setFeatures(f)
                    // Save to session for subsequent updates
                    if (oid !== '') {
                        window.sessionStorage.setItem(`temp_${oid}_features`, JSON.stringify(f));
                    }

                }
            ).catch(error => setErrorLoading(true))
        }
    }, [featuresAPI, features]);

    // Get terms
    useEffect(() => {
        let oid = userContext.user?.oid
        if (termsAPI && !terms && !loadingTerms) {
            const sessionTerms = window.sessionStorage.getItem(`temp_${oid}_terms`);
            if (oid !== '' && sessionTerms) {
                setTerms(JSON.parse(sessionTerms));
            } else {
                setLoadingTerms(true);
                termsAPI.index(0)
                .then(
                    response => {            
                        setLoadingTerms(false);
                        setTerms(response.data)
                        // Save to session for subsequent updates
                        if (oid !== '') {
                            window.sessionStorage.setItem(`temp_${oid}_terms`, JSON.stringify(response.data));
                        }
                    }
                ).catch(error => setErrorLoading(true))
            }
        }
    }, [termsAPI, terms]);

    useEffect(() => {
        if (brandContext.overrideBrand && terms?.simpleTerms) {
            let val = terms?.simpleTerms.find((t) => t.term === 'AppName')?.value
            // force the brand if we need to / have one
            if (val && val !== null && val.length !== 0) {
                brandContext.overrideBrand(val === 'Provestor' ? Brand.Provestor : Brand.inniAccounts)
            }
        }
    }, [terms])

    useEffect(() => {      
        if (profileAPI && userContext.user?.oid && !sessionStorage.getItem('temp_debugging')) {
                profileAPI.isdebuggingEnabled(userContext.user?.contactId)
                .then(response => {    
                    window.sessionStorage.setItem(`temp_debugging`, response.data);
                    if (sessionStorage.getItem('temp_debugging') ==='True' )  {
                        LogRocket.init('ke6fuy/react-v7-app');
                    }
                })
                .catch(error => {
                    console.error(error);
                })
            }
    }, [profileAPI, userContext.user?.contactId, userContext.user?.oid])

    return (
        <CompanyContext.Provider value={{
            cid: -1,
            features: features, 
            terms: terms,
            activeEntity: activeEntity,
            setActiveEntity: setActiveEntity
        }}>
            {!(features && terms) && 
                <LoadingFullPage entityName='contact' errorMessage={errorLoading ? 'Sorry, there was an error loading the page. Please refresh this page or contact us for support.' : undefined} />
            }
            {features && terms &&
                <>
                    {children}
                </>
            }
        </CompanyContext.Provider>
    )

}

export default ContactWrapper
