import { FieldArray, Form, Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import {BasicFormLayout} from "../../../layouts/Desktop/BasicFormLayout";
import Employment from "./Components/Employment";
import {
    PvSatrAsDetailed,
    PvSatrAttachmentPostModel,
    PvSatrDividend,
    PvSatrEmploymentAttachmentPostModel,
    PvSatrEmploymentPostModel,
    PvSatrPostModel,
    PvSatrSavings
} from "../../../api/inni/data-contracts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from './Income.module.scss'
import { faCirclePlus } from '@fortawesome/pro-solid-svg-icons';
import { useContext, useEffect, useRef, useState } from "react";
import { useInniAPI } from "../../../hooks/useInniAPI";
import { PvSatr } from "../../../api/inni/PvSatr";
import CompanyContext from "../../../context/CompanyContext";
import Divider from "../../../elements/Divider/Divider";
import Pension from "./Components/Pension";
import Dividends from "./Components/Dividends";
import { Button } from "../../../elements/Button/Button";
import Icon from "../../../elements/Icon/Icon";
import { FileRejection } from "react-dropzone";
import TaxableStateBenefits from "./Components/TaxableBenefits";
import ChildBenefits from "./Components/ChildBenefits";
import OtherIncome from "./Components/OtherIncome";
import Outgoings from "./Components/Outgoings";
import IncomeSummary from "./Components/IncomeSummary";
import SavingsInterest from "./Components/SavingsInterest";
import { useStandaloneSATRStatus } from "../../../hooks/useStandaloneSATRStatus";
import { useModalDialog } from "../../../hooks/useModalDialog";
import { getStoryblokApi, SbBlokData } from "@storyblok/react";
import { render as renderRichText } from 'storyblok-rich-text-react-renderer';
import { Alert } from "react-bootstrap";
import { PageAlert } from "../../../components/Alerts/PageAlert";

interface uploadedFiles {
    filesToUpload: File
    section: string
    id?: number
    docId?: number
}

interface employementUploadedFiles {
    filesToUpload: File
    section: string
    docId?: number
    index?: number
    employmentId?: number
    id?: number
}

interface sectionDescription extends SbBlokData {
    key: string,
    value: string
}


export default function  Income(){

    const companyContext = useContext(CompanyContext)
    const pvsatrAPI = useInniAPI(PvSatr)
    const formikRef = useRef<FormikProps<PvSatrPostModel>>(null);
    const [fileInvalidMessage, setFileInvalidMessage] = useState<string | undefined>(undefined);
    const [showFileInvalidAlert, setShowFileInvalidAlert] = useState(false)
    const [uploadedFiles, setUploadedFiles] = useState<uploadedFiles[]>([]);
    const [employmentFiles, setEmploymentFiles] = useState<employementUploadedFiles[]>([]);
    const { pvSatrDetails, completeStep, nextStep } = useStandaloneSATRStatus();
    const [showSavedMessage, setShowSavedMessage] = useState(false);
    const [stageNumber, setStageNumber] = useState<number>()
    const [description, setDescription] = useState<string>()
    const [showModalDialog, modalDialog] = useModalDialog();
    const [sectionDescriptions, setSectionDescriptions] = useState<{[key: string]: sectionDescription} | undefined>();
    const [showErrors, setShowErrors] = useState(false)
    const pageRef = useRef<HTMLDivElement>(null);
    const[showPension, setShowPension] = useState<boolean>(false)
    const[showTaxableBenefits, setShowTaxableBenefits] = useState<boolean>(false)

    useEffect(() =>{
        if(pvSatrDetails && pvSatrDetails.progress)
        {
            setStageNumber(pvSatrDetails.progress.setupIncome ? 1 : 0)
            setDescription(pvSatrDetails.progress.setupIncome 
                ? "These are the details you have confirmed."
                : "Below is a summary of all the details you've provided. Review the information to ensure its accuracy, and make any necessary adjustments before confirming"

            )
        }
    }, [pvSatrDetails])

    useEffect(() => {
        if(sectionDescriptions === undefined) {
            const storyblokApi = getStoryblokApi();			
            storyblokApi.get('cdn/stories/satr-standalone/page-body/income-body', {version: 'published'}).then((res) => {
                const buildDescriptions:{[key: string]: sectionDescription} = {};
                const sbDescriptions = res.data.story
                if(sbDescriptions) {
                    sbDescriptions.content.Body.forEach((entry: sectionDescription) => {
                        buildDescriptions[entry.key] = entry
                    });
                    setSectionDescriptions(buildDescriptions)
                }
            });
        }
    }, [sectionDescriptions])
    
    useEffect(() =>{

        if(pvSatrDetails){
            setUploadedFiles(
                (pvSatrDetails.attachments || []).map((x) => ({
                    filesToUpload: {name: x.name},
                    section: x.section || "",
                    id: x.id || 0,
                    docId: x.documentid || 0,
                })) as uploadedFiles[]
            );

            setEmploymentFiles(
                (pvSatrDetails.employments || []).flatMap((employment, index) =>
                    (employment.attachments || []).map((attachment) => ({
                        filesToUpload: { name: attachment.name || "" },
                        section: "Employment",
                        docId: attachment.documentid,
                        index,
                        employmentId: employment.id,
                        id: attachment.id
                    })) as employementUploadedFiles[]
                )
            );
            
        }

    },[pvSatrDetails])

    const uploadDocument =(file: File, section: string)=>{
        if(pvsatrAPI && pvSatrDetails)
        {
            pvsatrAPI.attachDocument(companyContext.cid, pvSatrDetails.id, {section: section, fileName: file.name} , {fileToUpload: file})
            .then((res) =>{
                if(section !== "Employment")
                {
                    setUploadedFiles((prevFiles) =>
                        prevFiles.map((f) =>
                            f.filesToUpload && f.filesToUpload.name === file.name && f.section === section
                                ? { ...f, id: res.data.id, docId: res.data.documentid }
                                : f
                        )
                    );
                }
                
            })
            .catch((error) =>{
                console.error(error)
            })

        }
    }

    const OnSubmit = async (values: PvSatrPostModel, actions: FormikHelpers<PvSatrPostModel>): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (pvsatrAPI && pvSatrDetails) {

                // Update attachments in post model
                values.attachments =
                    uploadedFiles
                        .filter((x) => x.section !== "Employment")
                        .map((x) => ({
                            id: x.id,
                            itrId: pvSatrDetails?.id,
                            documentid: x.docId,
                            section: x.section,
                        })) as PvSatrAttachmentPostModel[] || [];

                // Update employement attachments in post model
                values.employments?.filter((x) => x.id !== undefined )
                .forEach((employement) =>{

                    employement.attachments = employmentFiles
                                                .filter((y)=> y.employmentId === employement.id && y.id !== undefined && y.docId !== undefined)
                                                .map((attachment) => ({
                                                    itrEmploymentId: attachment.employmentId,
                                                    documentid: attachment.docId,
                                                    id: attachment.id
                                                })) as PvSatrEmploymentAttachmentPostModel[] || [];
                })
    
                pvsatrAPI
                    .update(companyContext.cid, pvSatrDetails.id, values)
                    .then((response) => {
                        // Get updated details for employment ids
                        pvsatrAPI
                            .get(companyContext.cid, pvSatrDetails.id)
                            .then(async (UpdatedIncomeDetails) => {
                                const allEmploymentDocs = [...employmentFiles];
    
                                // Process all employments and attach documents
                                const employmentPromises = UpdatedIncomeDetails.data.employments?.map(
                                    async (employment, index) => {
                                        if (values && values.employments && values.employments[index]) {
                                            values.employments[index].id = employment.id
                                        }
                                        if (employmentFiles.some((x) => x.index === index && x.docId === undefined)) {
                                            const employmentDocs = allEmploymentDocs.filter((x) => x.index === index);
                                            await Promise.all(
                                                employmentDocs.map(async (employmentDoc) => {
                                                    try {
                                                        const employmentAttachment =
                                                            await pvsatrAPI.attachDocumentToEmployment(
                                                                companyContext.cid,
                                                                pvSatrDetails.id,
                                                                employment.id,
                                                                {fileName: employmentDoc.filesToUpload.name},
                                                                { fileToUpload: employmentDoc.filesToUpload }
                                                            );
    
                                                        // Update employment document details
                                                        allEmploymentDocs
                                                            .filter(
                                                                (x) =>
                                                                    x.index === index &&
                                                                    x.filesToUpload.name ===
                                                                        employmentDoc.filesToUpload.name
                                                            )
                                                            .forEach((item) => {
                                                                item.employmentId =
                                                                    employmentAttachment.data.itrEmploymentId;
                                                                item.docId = employmentAttachment.data.documentid;
                                                                item.id = employmentAttachment.data.id
                                                            });
                                                    } catch (ex) {
                                                        console.error(ex);
                                                    }
                                                })
                                            );
    
                                            // Update attachments for the specific employment
                                            if (values && values.employments && values.employments[index]) {
                                                values.employments[index].attachments =
                                                    allEmploymentDocs
                                                        .filter((x) => x.index === index)
                                                        .map((attachment) => ({
                                                            itrEmploymentId: attachment.employmentId,
                                                            documentid: attachment.docId,
                                                            id: attachment.id
                                                        })) as PvSatrEmploymentAttachmentPostModel[] || [];
                                            }
                                        }
                                    }
                                );
    
                                // Wait for all employments to be processed
                                if (employmentPromises) await Promise.all(employmentPromises);
    
                                // Update employment files
                                setEmploymentFiles([...allEmploymentDocs]);
                                
                                if(pvSatrDetails.progress?.setupIncome)
                                {
                                    setShowSavedMessage(true)
                                }

                                resolve();
                            })
                            .catch((err) => {
                                console.error(err);
                                reject(err);
                            });
                    })
                    .catch((error) => {
                        actions.setErrors(error.error);
                        reject(error);
                    });
            } else {
                reject(new Error("Missing API or initial values"));
            }
        });
    };
    

    const stageDetails = [ 
        {
            Key: "intro",
            Title: 'Income',
        },

        {
            Key: "summary",
            Title: 'Income summary',
        },
    ]

    const onFilesDropped = (acceptedFiles:File[], fileRejections:FileRejection[], section: string, index?: number) => {
        if(acceptedFiles.length > 0) {
            const newFiles = acceptedFiles.map((file) => ({ filesToUpload: file, section, index }));
            if(section === "Employment"){
                setEmploymentFiles((prevFiles) => [...prevFiles, ...newFiles]);
            }
            else{
                setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
                acceptedFiles.forEach((file) => uploadDocument(file, section));
            }
            
        }
        if(fileRejections.length > 0){
            if(fileRejections.filter(x => x.errors.filter(y => y.code === "too-many-files").length > 0).length > 0) {
                setFileInvalidMessage(`Please select one file`);
            }
            else if(fileRejections.length === 1) {
                setFileInvalidMessage(`This file type is not supported and has been ignored`);
            } 
            else {
                setFileInvalidMessage(`${fileRejections.length} file types are not supported, these have been ignored`);
            }
        }
    };

    const removePendingFile = (fileName:string, section: string, index?: number) => {
        const documents = uploadedFiles.filter(x => x.filesToUpload.name=== fileName && x.section === section)

        documents.forEach((doc) =>{
            if(doc.docId){
                setUploadedFiles((prevFiles) =>
                    prevFiles.filter(
                      (file) => !(file.filesToUpload.name === fileName &&  file.section === section && file.docId === doc.docId)
                    )
                );
            }
        }) 
        
    }

    const removeAllFilesInSection = (section: string) => {
        setUploadedFiles((prevFiles) =>
            prevFiles.filter(
              (file) => !( file.section === section )
            )
        );
    }

    const removeEmploymentFile = (section: string, index: number, fileName?:string,) => {
        setEmploymentFiles((prevFiles) =>
            prevFiles.filter(
                (file) => !(file.filesToUpload.name === fileName &&  file.section === section && file.index === index)
            )
        );

    }

    const removeEmploymentAllFiles = (section: string, index: number) => {        
        setEmploymentFiles((prevFiles) =>
            prevFiles
            .filter((file) => !(file.section === section && file.index === index))
            .map((item) =>({
                filesToUpload: item.filesToUpload,
                section: item.section,
                docId: item.docId,
                id: item.id,
                index: (item.index && index < item.index)? (item.index -1) : item.index,
                employmentId: item.employmentId
            }))
        );

    }

    const getFilesBySection = (section: string, index?: number) => {
        if(section === "Employment")
        {
            return employmentFiles.filter((file) => file.section === section && file.index === index).map(y => y.filesToUpload);
        }
        else{
            return uploadedFiles.filter((file) => file.section === section).map(y => y.filesToUpload);
        }
       
    };

    const handleOnClickSaveDraft = () =>{
        setShowErrors(false)
        formikRef.current?.submitForm()
    }

    const handleOnContinueClick = () =>{

        if (!validateForm()) {
            setShowErrors(true)
            return;
        }
        else{
            setShowErrors(false)
            formikRef.current?.submitForm()
            .then(() =>{
                setStageNumber(1)
                if (pageRef.current) {
                    // typescript bug doesn't allow 'instant'
                    pageRef.current.scrollIntoView({ behavior: 'instant' } as any);
                }
            })
        }
        
    }

    const handleOnConfirmClick = () =>{
        completeStep('SetupIncome');
    }

    const handleOnNextClick = () =>{
        nextStep('SetupIncome');
    }

    const handleEditDetails = () =>{
        setStageNumber(0)
        if (pageRef.current) {
            // typescript bug doesn't allow 'instant'
            pageRef.current.scrollIntoView({ behavior: 'instant' } as any);
        }
    }

    const validateForm = (): boolean => {
        if (!formikRef.current) return true;

        let hasErrors = true;

        formikRef.current.values.employments?.forEach((employment, index) =>{
            const fieldName = `employments.${index}.employerName`
            formikRef.current?.setFieldTouched(fieldName, false, false);
            formikRef.current?.setFieldError(fieldName, undefined);
            if(!employment.employerName){
                formikRef.current?.setFieldTouched(fieldName, true, false);
                formikRef.current?.setFieldError(fieldName, 'Required');
                hasErrors = false;
            }
        })
        
        formikRef.current.values.dividends?.forEach((dividend, index) =>{
            const fieldName = `dividends.${index}.name`
            formikRef.current?.setFieldTouched(fieldName, false, false);
            formikRef.current?.setFieldError(fieldName, undefined);
            if(!dividend.name){
                formikRef.current?.setFieldTouched(fieldName, true, false);
                formikRef.current?.setFieldError(fieldName, 'Required');
                hasErrors = false;
            }
        })

        formikRef.current.values.savings?.forEach((saving, index) =>{
            const fieldName = `savings.${index}.name`
            formikRef.current?.setFieldTouched(fieldName, false, false);
            formikRef.current?.setFieldError(fieldName, undefined);
            if(!saving.name){
                formikRef.current?.setFieldTouched(fieldName, true, false);
                formikRef.current?.setFieldError(fieldName, 'Required');
                hasErrors = false;
            }
        })

        //Pension
        if(showPension && !uploadedFiles.some(x => x.section === "Pension"))
        {
            hasErrors = false;
        }
            
        //state benefits
        if(showTaxableBenefits && !uploadedFiles.some(x => x.section === "Taxable"))
        {
            hasErrors = false;
        }
            
        //employmentFiles
        //compare count of employees  in files and formik
        //check the employment count from files based on the index
        const uniqueIndexesCount = new Set(
            employmentFiles
                .map(file => file.index) 
                .filter(index => index !== undefined)
        ).size;

        if(formikRef.current.values.employments?.length !== uniqueIndexesCount)
        {
            hasErrors = false;
        }

        return hasErrors;
    };

    const validate = (values: PvSatrPostModel) : Promise<FormikErrors<PvSatrPostModel>> => {
        return new Promise((resolve, reject) => {
            resolve({})
        })
    }

    const employmentDeleteDialog = (handleOnClickDelete: () => void) => {
        showModalDialog(
            'Delete employment?',
            `Are you sure you want to delete this employment entry?`,
            [
                <Button key="delete" variant="danger" label="Yes" onClick={handleOnClickDelete} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const dividendDeleteDialog = (handleOnClickDelete: () => void) => {
        showModalDialog(
            'Delete dividend?',
            `Are you sure you want to delete this dividend entry?`,
            [
                <Button key="delete" variant="danger" label="Yes" onClick={handleOnClickDelete} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const savingsDeleteDialog = (handleOnClickDelete: () => void) => {
        showModalDialog(
            'Delete savings interest?',
            `Are you sure you want to delete this savings interest entry?`,
            [
                <Button key="delete" variant="danger" label="Yes" onClick={handleOnClickDelete} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    return(
        <div ref={pageRef}>
            <BasicFormLayout
                title={stageDetails[stageNumber||0].Title}
                description={stageNumber === 1? description : undefined}
                storyblokDescriptionId={stageNumber === 0 ?  "income-page" : undefined}
            >
                <>
                    {pvSatrDetails &&
                        <Formik
                            initialValues={pvSatrDetails || { } as PvSatrPostModel}
                            enableReinitialize         
                            validateOnChange={false}
                            validate={validate}
                            onSubmit={OnSubmit}
                            innerRef={formikRef}
                        >   
                            {({ values, isSubmitting }) => (
                                <Form>
                                    {stageNumber === 0 && 
                                        <>
                                            {/* EMPLOYMENT */}
                                            <div className={styles.sectionHeading}>
                                                <h3>1. Employment</h3>
                                                { sectionDescriptions && sectionDescriptions["employment"] &&
                                                    <div className="mb-4">
                                                        { renderRichText(sectionDescriptions["employment"].value) }
                                                    </div>
                                                }
                                            </div>

                                            <div className={styles.sectionBody}>
                                                <p>
                                                    If applicable, please provide details of any employment positions you held during the tax year
                                                </p>
                                            
                                                <FieldArray name="employments">
                                                    {(filedArrayProps) => {
                                                        const { push, remove, form } = filedArrayProps;
                                                        const { values }: {
                                                            values: PvSatrAsDetailed;
                                                            errors: FormikErrors<any>;
                                                            touched: any;
                                                        } = form;
                                                        const { employments }: { employments?: PvSatrEmploymentPostModel[] | undefined } = values;

                                                        return (
                                                            <>
                                                                {employments && employments !== null && employments.length > 0 &&

                                                                        employments.map((item: PvSatrEmploymentPostModel, index: number) => (
                                                                            <Employment 
                                                                                key={index} 
                                                                                remove={() =>remove(index)} 
                                                                                index={index}
                                                                                onFilesDropped={onFilesDropped} 
                                                                                filesToUpload={getFilesBySection("Employment", index)} 
                                                                                removeEmploymentFiles={removeEmploymentFile}
                                                                                removeEmploymentAllFiles={removeEmploymentAllFiles}
                                                                                values={values.employments && values.employments[index]}
                                                                                showDeleteDialog={employmentDeleteDialog}
                                                                                showError={showErrors && !employmentFiles.some(x => x.index === index)}
                                                                            />
                                                                        ))
                                                                }

                                                                {/* Enable editing */}
                                                                <div 
                                                                    className="addNewDashedBox" 
                                                                    style={{ marginTop: '10px' }} 
                                                                    onClick={() => push({
                                                                        employerName: "",
                                                                        receivedBik: false,
                                                                        receivedRedundancyPayment: false,
                                                                        valueOfProfessionalFeesOrSubscriptions: 0,
                                                                        valueOfWorkingFromHomeRelief: 0,
                                                                        attachments: [],
                                                                    })}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faCirclePlus}
                                                                        className='mr-2'
                                                                        color='var(--feature-green)'
                                                                        size='lg'
                                                                    />
                                                                    &nbsp;Add an employment
                                                                </div>
                                                            </>           
                                                        );

                                                    }}
                                                        
                                                </FieldArray>
                                            </div>
                                    
                                            <Divider margin={"3rem 0px"}/>
                                    
                                            {/* PENSION */}
                                            <div className={styles.sectionHeading}>
                                                <h3>2. Pension</h3>
                                                { sectionDescriptions && sectionDescriptions["pension"] &&
                                                    <div className="mb-4">
                                                        { renderRichText(sectionDescriptions["pension"].value) }
                                                    </div>
                                                }
                                            </div>
                                        
                                            <div className={styles.sectionBody}>
                                                <Pension 
                                                    onFilesDropped={onFilesDropped} 
                                                    filesToUpload={getFilesBySection("Pension")} 
                                                    removePendingFile={removePendingFile}
                                                    removeAllFiles={removeAllFilesInSection}
                                                    showError={showErrors && showPension && !uploadedFiles.some(x => x.section === "Pension")}
                                                    showPension= {showPension}
                                                    setShowPension={setShowPension}
                                                />
                                            </div>

                                            <Divider margin={"3rem 0px"}/>
                                    
                                            {/* DIVIDENDS */}
                                            <div className={styles.sectionHeading}>
                                                <h3>3. Dividends</h3>
                                                { sectionDescriptions && sectionDescriptions["dividends"] &&
                                                    <div className="mb-4">
                                                        { renderRichText(sectionDescriptions["dividends"].value) }
                                                    </div>
                                                }
                                            </div>
                                        
                                            <div className={styles.sectionBody}>
                                                <p>
                                                    If applicable, please provide details of the dividends you received in this tax year
                                                </p>

                                                <FieldArray name="dividends">
                                                    {(filedArrayProps) => {
                                                        const { push, remove, form } = filedArrayProps;
                                                        const { values }: {
                                                            values: PvSatrAsDetailed;
                                                            errors: FormikErrors<any>;
                                                            touched: any;
                                                        } = form;
                                                        const { dividends }: { dividends?: PvSatrDividend[] | undefined } = values;

                                                        return (
                                                            <>
                                                                {dividends && dividends !== null && dividends.length > 0 &&
                                                                    dividends.map((item: PvSatrDividend, index: number) => (
                                                                            <>
                                                                                <Dividends 
                                                                                    key={index} 
                                                                                    remove={() =>remove(index)} 
                                                                                    index={index}
                                                                                    showDeleteDialog={dividendDeleteDialog}
                                                                                />
                                                                            </>
                                                                        ))
                                                                }

                                                                <div 
                                                                    className="addNewDashedBox" 
                                                                    style={{marginTop: '10px' }} 
                                                                    onClick={() =>
                                                                        push({
                                                                            name: "",
                                                                            grossAmount: 0,
                                                                            taxAlreadyDeducted: 0,
                                                                            notes: ""
                                                                    })}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faCirclePlus}
                                                                        className='mr-2'
                                                                        color='var(--feature-green)'
                                                                        size='lg' />
                                                                        &nbsp;Add a dividend
                                                                </div>
                                                                                
                                                            </>
                                                        );

                                                    }}
                                                        
                                                </FieldArray>
                                            </div>
                                    
                                            <Divider margin={"3rem 0px"}/>
                                    
                                            {/* TAXABLE BENEFITS */}
                                            <div className={styles.sectionHeading}>
                                                <h3>4. Taxable state benefits</h3>
                                                { sectionDescriptions && sectionDescriptions["taxablestatebenefits"] &&
                                                    <div className="mt-3 mb-5">
                                                        { renderRichText(sectionDescriptions["taxablestatebenefits"].value) }
                                                    </div>
                                                }
                                            </div>

                                            <div className={styles.sectionBody}>
                                                <TaxableStateBenefits
                                                    onFilesDropped={onFilesDropped} 
                                                    filesToUpload={getFilesBySection("Taxable")} 
                                                    removePendingFile={removePendingFile}
                                                    removeAllFiles={removeAllFilesInSection}
                                                    showError={showErrors && showTaxableBenefits && !uploadedFiles.some(x => x.section === "Taxable")}
                                                    showTaxableBenefits={showTaxableBenefits}
                                                    setShowTaxableBenefits={setShowTaxableBenefits}
                                                />
                                            </div>

                                            <Divider margin={"3rem 0px"}/>
                                    
                                            {/* INTEREST FROM SAVINGS */}
                                            <div className={styles.sectionHeading}>
                                                <h3>5. Interest from savings</h3>
                                                { sectionDescriptions && sectionDescriptions["savingsinterest"] &&
                                                    <div className="mb-4">
                                                        { renderRichText(sectionDescriptions["savingsinterest"].value) }
                                                    </div>
                                                }
                                            </div>

                                            <div className={styles.sectionBody}>

                                                <p>If applicable, please detail any interest you have received from savings</p>

                                                <FieldArray name="savings">
                                                    {(filedArrayProps) => {
                                                        const { push, remove, form } = filedArrayProps;
                                                        const { values }: {
                                                            values: PvSatrAsDetailed;
                                                            errors: FormikErrors<any>;
                                                            touched: any;
                                                        } = form;
                                                        const { savings  }: { savings?: PvSatrSavings[] | undefined } = values;

                                                        return (
                                                            <>
                                                                {savings && savings !== null && savings.length > 0 &&
                                                                    savings.map((item: PvSatrSavings, index: number) => (
                                                                            <>
                                                                                <SavingsInterest
                                                                                    key={index} 
                                                                                    remove={() =>remove(index)} 
                                                                                    index={index}
                                                                                    showDeleteDialog={savingsDeleteDialog}
                                                                                />
                                                                            </>
                                                                        ))
                                                                }

                                                                <div 
                                                                    className="addNewDashedBox" 
                                                                    style={{ marginTop: '10px' }} 
                                                                    onClick={() =>
                                                                        push({
                                                                            name: "",
                                                                            amount: 0,
                                                                            taxAlreadyDeducted: 0,
                                                                            details: ""
                                                                    })}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faCirclePlus}
                                                                        className='mr-2'
                                                                        color='var(--feature-green)'
                                                                        size='lg'
                                                                    />Add interest from savings
                                                                </div>
                                                                                
                                                            </>
                                                        );

                                                    }}
                                                        
                                                </FieldArray>
                                            </div>

                                            <Divider margin={"3rem 0px"}/>

                                            {/* CHILD BENEFITS */}
                                            <div className={styles.sectionHeading}>
                                                <h3>6. Child benefit</h3>
                                                { sectionDescriptions && sectionDescriptions["childbenefit"] &&
                                                    <div className="mb-4">
                                                        { renderRichText(sectionDescriptions["childbenefit"].value) }
                                                    </div>
                                                }
                                            </div>

                                            <div className={styles.sectionBody}>
                                                <ChildBenefits />
                                            </div>

                                            <Divider margin={"3rem 0px"}/>

                                            {/* OTHER INCOME */}
                                            <div className={styles.sectionHeading}>
                                                <h3>7. Other income</h3>
                                                { sectionDescriptions && sectionDescriptions["otherincome"] &&
                                                    <div className="mb-4">
                                                        { renderRichText(sectionDescriptions["otherincome"].value) }
                                                    </div>
                                                }
                                            </div>

                                            <div className={styles.sectionBody}>
                                                <OtherIncome
                                                    onFilesDropped={onFilesDropped} 
                                                    filesToUpload={getFilesBySection("Other")} 
                                                    removePendingFile={removePendingFile}
                                                    removeAllFiles={removeAllFilesInSection}
                                                />
                                            </div>
                                    
                                            <Divider margin={"3rem 0px"}/>

                                            {/* RELIEFS */}
                                            <div className={styles.sectionHeading}>
                                                <h3>8. Outgoing & tax reliefs</h3>
                                                { sectionDescriptions && sectionDescriptions["outgoing"] &&
                                                    <div className="mb-4">
                                                        { renderRichText(sectionDescriptions["otherincome"].value) }
                                                    </div>
                                                }
                                            </div>

                                            <div className={styles.sectionBody}>
                                                <Outgoings />
                                            </div>
                                        </>
                                    }

                                    {/**** Summary ****/}
                                    {stageNumber === 1 && 
                                    
                                        <IncomeSummary values={values}></IncomeSummary>
                                    }

                                    {stageNumber === 0 ?
                                            <>
                                                {showErrors &&
                                                    <div className="mt-5">
                                                        <Alert variant="danger">Please fix the errors above before continuing.</Alert>
                                                    </div>
                                                }
                                                {pvSatrDetails && pvSatrDetails.progress && pvSatrDetails?.progress?.setupIncome ?
                                                        <Button 
                                                            variant="primary" 
                                                            onClick={handleOnContinueClick}
                                                            marginTop 
                                                            disabled={ isSubmitting }
                                                            label="Save & confirm"
                                                        />
                                                    :
                                                        <div>
                                                            <Button 
                                                                variant="primary"
                                                                submitButton
                                                                label="Save draft"
                                                                marginTop 
                                                                disabled={isSubmitting}
                                                                onClick={handleOnClickSaveDraft}
                                                            />


                                                            <Button 
                                                                variant="primary" 
                                                                onClick={handleOnContinueClick}
                                                                marginTop 
                                                                disabled={ isSubmitting }
                                                                thin
                                                            >
                                                                Continue <Icon name='arrowNext' />
                                                            </Button>
                                                        </div>
                                                }
                                            </>
                                        :
                                            <>
                                                { pvSatrDetails?.progress?.setupSubmitted
                                                    // SATR submitted
                                                    ? (
                                                        <>
                                                            <Alert variant="info">
                                                                <b>You have completed your tax return. Please contact us if you need to make changes.</b>
                                                            </Alert>

                                                            <Button 
                                                                variant="primary" 
                                                                onClick={handleOnNextClick}
                                                                marginTop 
                                                                thin
                                                            >
                                                                Continue <Icon name='arrowNext' />
                                                            </Button>
                                                        </>
                                                    )
                                                    // SATR in progress
                                                    : (
                                                        <>
                                                            { pvSatrDetails?.progress?.setupIncome && (
                                                                <Alert variant="info">
                                                                    <b>You have already confirmed your income details, but you can still make changes.</b>
                                                                </Alert>
                                                            )}

                                                            <Button 
                                                                marginTop
                                                                variant="secondary"
                                                                onClick={handleEditDetails} 
                                                                label="Edit details "
                                                            />

                                                            { pvSatrDetails && pvSatrDetails.progress && (
                                                                !pvSatrDetails?.progress?.setupIncome
                                                                    ? (
                                                                        <Button 
                                                                            marginTop
                                                                            variant="primary" 
                                                                            label="Confirm details"
                                                                            onClick={handleOnConfirmClick}
                                                                        />
                                                                    )
                                                                    : (
                                                                        <Button 
                                                                            variant="primary" 
                                                                            onClick={handleOnNextClick}
                                                                            marginTop 
                                                                            thin
                                                                        >
                                                                            Continue <Icon name='arrowNext' />
                                                                        </Button>
                                                                    )
                                                            )}
                                                        </>
                                                    )
                                                }
                                            </>
                                    } 

                                </Form>
                            )}
                            
                        </Formik>
                    } 

                    <PageAlert
                        ignoreSidebar={true}
                        variant="success"
                        showAlert={showSavedMessage}
                        message="Income details updated."
                        hideAlert={() => { setShowSavedMessage(false) }}
                    />

                    {modalDialog} 
                </>            
            </BasicFormLayout>
        </div>
    )
}