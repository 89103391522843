import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Formik, FormikErrors } from 'formik'
import { useContext } from 'react'
import { CompanyDetailsPostModel, SetupWizardForm } from '../../../api/inni/data-contracts'
import { SetupWizard } from '../../../api/inni/SetupWizard'
import CompanyContext from '../../../context/CompanyContext'
import { Button } from '../../../elements/Button/Button'
import { DateSelector, Switch, Text } from '../../../elements/EditRow/EditRow'
import { Text as TextRead } from '../../../elements/DetailRow/DetailRow'
import { useInniAPI } from '../../../hooks/useInniAPI'
import styles from '../SetupWizard.module.css'
import { formatDate } from '../../../utils/formatDate'
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity'
import { Action, Entity } from '../../../utils/EntityAction'
import { WarningText } from './WarningText'

const CompanyDetails = ({setNextPage, initial, setPrevPage} : {setNextPage : (nfs?: SetupWizardForm) => void, initial : SetupWizardForm, setPrevPage : () => void}) => {

    const swAPI = useInniAPI(SetupWizard, [400])
    const companyContext = useContext(CompanyContext)

    const navToEntity = useNavigateToEntity()

    const isScraperSetup = initial.setupDataSource?.toUpperCase() === "NWSJD" // is the data from SJD or NW

    const saveCompanyDetails = (values : CompanyDetailsPostModel) => {
        if (swAPI) {
            swAPI.updateCompanyDetails(companyContext.cid, values)
            .then(res => {
                if (res.status === 200) {
                    let tempFs = {...initial, ...values}
                    setNextPage(tempFs as SetupWizardForm)
                } else {
                    console.error("Error updating company details returned status: " + res.status)
                }
            })
        }
    }

    const validateCompanyDetails = (values : CompanyDetailsPostModel) : Promise<FormikErrors<CompanyDetailsPostModel>> => {
        return new Promise<FormikErrors<CompanyDetailsPostModel>>((resolve, reject) => {
            if (swAPI) {
                swAPI?.validateUpdateCompanyDetails(companyContext.cid, values).then(x => {
                    resolve({})
                }).catch(err => resolve(err.error))
            } else {
                reject()
            }            
        })
    }

    return (
        <div>
            <Formik                
                initialValues={initial as CompanyDetailsPostModel}
                validateOnChange={false}
                enableReinitialize
                onSubmit={saveCompanyDetails}
                validate={validateCompanyDetails}
            >
                {({ values }) => (

                    <Form>
                        <p style={{cursor: 'pointer'}} onClick={setPrevPage}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
                        <h1>Your company details</h1>
                        <p>You've already provided some information about your company. Please check the following details and complete any missing entries.</p>
                        <hr />

                        <h4>Accounting details</h4>
                        {initial.setupCoInfoComplete ? <>
                            <p>The details for this company have already been confirmed, please reset the wizard on the confirmation page to make changes.</p>
                        </> : <>
                            
                            <Text<CompanyDetailsPostModel> name="companyRegistrationNumber" label="Company Registration Number" />
                            <DateSelector<CompanyDetailsPostModel> disabled={isScraperSetup} name="fyStartDate" label="FY Start Date" />
                            <DateSelector<CompanyDetailsPostModel> disabled={isScraperSetup} name="fyEndDate" label="FY End Date" />
                            
                        </>}

                        <hr />

                        <h4>VAT Settings</h4>

                        {initial.setupVATSettings || initial.setupCoInfoComplete ? 
                        <p>The VAT settings for this company have already been confirmed, please reset the wizard on the confirmation page to make changes.</p>
                        : <>
                            
                            <Switch<CompanyDetailsPostModel> disabled={isScraperSetup} name="vatRegistered" label="My company is VAT registered" />
                            { values.vatRegistered && <>
                                <Text<CompanyDetailsPostModel> disabled={isScraperSetup} name="vatRegistrationNumber" label="VAT registration number" />
                                <Switch<CompanyDetailsPostModel> disabled={isScraperSetup} name="cashAccounting" label="My company uses cash accounting VAT" />
                                <Switch<CompanyDetailsPostModel> disabled={isScraperSetup} name="isFlatRateVAT" label="My company uses flat rate VAT" />
                                {values.isFlatRateVAT &&
                                    <Text<CompanyDetailsPostModel> disabled={isScraperSetup} type="number" name="flatRate" label="My flat rate is" suffix="%" />
                                }
                            </>}
                            
                        </>}
                        <hr />

                        {initial.setupWizardType > 0 && <>
                        <h4>Migration date</h4>
                        <p>In order to import your company's financial information into inniAccounts, you need to set a migration date. 
                            This date is the point from which inniAccounts will hold detailed records of your company's finances.
                        </p>

                        <p><b>Quick Option</b></p>
                        <p>The quickest option is to set the migration date to the last day of your latest bank statement. 
                            You will provide a summary of your finances before this date and you will enter the details of any transactions that haven't yet appeared on your bank statements.
                        </p>

                        <p><b>Complete Option</b></p>
                        <p>For the most accurate picture of your company's finances you can set the migration date to the first day of your current financial year. 
                            You will need to re-enter the details of all of your transactions from the beginning of the year into inniAccounts. 
                            You will then be able to view detailed financial information for the entire year.
                        </p><br/>
                        {!initial.setupCoInfoComplete ? <DateSelector<CompanyDetailsPostModel> name="setupMigrationDate" label="Migration date" />
                        : <TextRead valueFormatter={formatDate} entity={initial} name="setupMigrationDate" />}
                        <h5>Migration balances</h5>
                        <p><b>Enter opening balances for each bank account via adjustments or opening balances. </b>
                            <span style={{cursor: 'pointer'}} onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': 'balancesheet' })}>Go to balance sheet</span></p></>}
                        
                        <div className={styles.formButtons}>
                            {!initial.setupCoInfoComplete ? <Button submitButton variant="change">Continue</Button> 
                            : <Button variant="change" onClick={setNextPage}>Continue</Button>}
                        </div>

                        <WarningText />
                        
                    </Form>

                )}
            
            
            </Formik>
        </div>
    )
}

export default CompanyDetails