import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SbBlokData, storyblokEditable } from "@storyblok/react";
import classNames from "classnames";
import styles from './navigation.module.css';
type Props = {
	blok: SbBlokData;
    onNavItemSelect: (uid: string, pageUrl: string) => void;
    selectedUid: string;
};
const NavItem = ({ blok, onNavItemSelect, selectedUid }: Props) => {

  const onClick = () => {
    const pageUrl = blok.page ? (blok.page as SbBlokData).cached_url as string : null;  
    onNavItemSelect(blok._uid, pageUrl || '')
  }

  return blok.hide ? <></> : (
    <div {...storyblokEditable(blok)} className={classNames(styles.navItem, { [styles.selected]: selectedUid === blok._uid})} onClick={onClick}  >
      {blok.testing_complete ?
        <FontAwesomeIcon
          icon={faCheckSquare}
          fixedWidth 
          style={{marginRight:'0.5em'}}
          className='text-success'
        />    
      :
        <FontAwesomeIcon
          icon={faSquare}
          fixedWidth 
          style={{marginRight:'0.5em'}}
        />
      }
      {blok.title}
    </div>
  )
};
 
export default NavItem; 