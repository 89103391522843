import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { Modal } from '../../../components/Modal/Modal';
import FormikField from '../../../elements/FormikField/FormikField';
import { Button } from '../../../elements/Button/Button';
import { Document, DocumentSourceViewModel, FinancialYearViewModel, TaxYearViewModel, DocumentType } from '../../../api/inni/data-contracts';
import { capitalizeFirstLetter } from '../helperfunctions/CountStringsinArray';

type EditDocumentModalProps = {
	document: Document | undefined;
	show: boolean;
	handleClose: () => void;
	documentNames: DocumentType[];
	taxYears: TaxYearViewModel[];
	financialYears: FinancialYearViewModel[];
	documentSources: DocumentSourceViewModel[];
	tags?: string[];
	handleSubmit: (document: Document,action:'save' | 'saveAndFile') => void;
	isAdmin: boolean; 
};

export const EditDocumentModal = ({
	document,
	documentNames,
	tags,
	taxYears,
	financialYears,
	documentSources,
	show,
	handleClose,
	handleSubmit,
	isAdmin
}: EditDocumentModalProps) => {
	const [showFY, setShowFY] = useState(false);
	const [showTY, setShowTY] = useState(false);
	const [customTag, setCustomTag] = useState<string>();

	useEffect(() => {
		setShowFY(false);
		setShowTY(false);
	}, [document]);

	return (
		<>
			{document && (
				<Modal showModal={show} title="Advanced edit document details" hideModal={handleClose}>
					<Formik initialValues={document} onSubmit={() => {}} validateOnChange={true} enableReinitialize>
						{({ values, setFieldValue }) => {
							return (
								<Form>
									<FormikField<Document>
										label="Document type"
										name="documentTypeId"
										type="selctwithparent"
										dropDownOptions={documentNames.map((x) => ({ value: x.id, label: x.name || '', parent: x.defaultTags }))}
									/>
									<FormikField<Document> label="Document name" name="documentName" type="text"></FormikField>
									<div className="d-flex justify-content-start px-4 ">
										{!showFY && !showTY && !values.financialYearId && !values.taxYear && (
											<Button label="Add FY" buttonType="calender" variant="secondary" onClick={() => setShowFY(true)}></Button>
										)}
										{!showFY && !showTY && !values.financialYearId && !values.taxYear && (
											<Button label="Add tax year" buttonType="calender" variant="secondary" onClick={() => setShowTY(true)} />
										)}
									</div>
									{(showTY || values.taxYear) && (
										<FormikField<Document>
											label="Tax year"
											name="taxYear"
											type="select"
											option={taxYears?.map((x) => ({ value: x.year, label: x.text || '' }))}></FormikField>
									)}
									{(showFY || values.financialYearId) && (
										<FormikField<Document>
											label="Financial year"
											name="financialYearId"
											type="select"
											option={financialYears?.map((x) => ({ value: x.id, label: x.text || '' }))}></FormikField>
									)}
									<FormikField<Document>
										label="Source"
										name="sourceId"
										type="select"
										option={documentSources.map((x) => ({ value: x.id?.toString(), label: x.text || '' }))}
										readonly={!isAdmin}
										></FormikField>
									<div className="p-4">
									<p className="m-0" style={{color:'#6c757d'}}>Tags</p>
										<div className="d-flex flex-wrap">
											<>
												{tags?.sort().map((x) => (
													<Form.Check
														style={{ width: '150px'}}
														className="m-1 text-left"
														key={x}
														label={<span style={{cursor:'pointer' }} onClick={
															(e) => {
																if (values.tags?.findIndex((y: string) => y === x) === -1) {
																		setFieldValue('tags', [...values.tags, x]);
																} else {
																	setFieldValue(
																		'tags',
																		values.tags?.filter((y: string) => y !== x)
																	);
																}
															}
														}>{x}</span> }
														checked={values.tags?.includes(x) || false}
														onChange={(e) => {
															if (e.target.checked) {
																values.tags?.findIndex((y: string) => y === x) === -1
																 && setFieldValue('tags', [...values.tags, x]);
															} else {
																setFieldValue(
																	'tags',
																	values.tags?.filter((y: string) => y !== x)
																);
															}
														}}
													/>
												))}
												<Form.Check
													className="m-1"
													checked={customTag ? true : false}
													onChange={(e) => {}}
													label={
														<FormControl
															placeholder="Add custom tag"
															type="text"
															size="sm"
															onChange={(e) => {
																if (e.target.value.length === 0) {
																	setCustomTag(undefined);
																}
																else{
																	setCustomTag(e.target.value);
																}
															}}></FormControl>
													}
												/>
											</>
										</div>
									</div>
									<hr></hr>
									<div className="float-left ml-4 pb-4">
									{!document.isInbox &&
										<Button
											label="Save"
											buttonType="save"
											onClick={() => {
												handleSubmit({...values,tags: values.tags && customTag ?
													 [...values.tags,capitalizeFirstLetter(customTag)] : values.tags },'save');
											}}></Button>}
										{document.isInbox &&
										<>
										
										<Button
											label="Save & file"
											buttonType="save" 
											onClick={() => {
												handleSubmit({...values,tags: values.tags && customTag ?
													 [...values.tags,capitalizeFirstLetter(customTag)] : values.tags,isInbox:false},'saveAndFile');
											}}></Button>
										<Button
											label="Save"
											buttonType="save"
											onClick={() => {
												handleSubmit({...values,tags: values.tags && customTag ?
													 [...values.tags,capitalizeFirstLetter(customTag)] : values.tags },'save');
											}}></Button>
										</>
										}
										<Button buttonType="cancel" onClick={handleClose}></Button>
									</div>
								</Form>
							);
						}}
					</Formik>
				</Modal>
			)}
		</>
	);
};
