import { faInboxIn } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Accounts } from "../../api/inni/Accounts";
import { BankTransactions } from "../../api/inni/BankTransactions";
import { AccountAsDetailed, AccountWithOLBS, BankTransaction, DatePicker } from "../../api/inni/data-contracts"
import { AccountViewerFeatureSet } from "../../components/AccountViewerNew/AccountViewerNew";
import { AccountViewerNewWrapper } from "../../components/AccountViewerNew/AccountViewerNewWrapper";
import CompanyContext from "../../context/CompanyContext";
import { Button } from "../../elements/Button/Button";
import { useFetchSingleEntity } from "../../hooks/entities/useFetchSingleEntity";
import { useInniAPI } from "../../hooks/useInniAPI";
import { useNavigateToEntity } from "../../hooks/useNavigateToEntity";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout";
import Toolbar from "../../layouts/Desktop/Toolbar";
import { Entity } from "../../utils/EntityAction";

interface RouteParams {  
    aid: string
}

export const Bookkeeping = () => {
    const companyContext = useContext(CompanyContext);
    const bookkeepingApi = useInniAPI(BankTransactions, [400]);
    const bankAccountId = parseInt(useParams<RouteParams>().aid)
    const accountsApi = useInniAPI(Accounts, [400]);
    const [accountOLBS, setAccountOLBS] = useState<AccountWithOLBS|null|undefined>()

    const [detailedAccount, detailedAccountLoaded, reloadBAD] = useFetchSingleEntity<AccountAsDetailed, Accounts>(Accounts, bankAccountId)
    const navToEntity = useNavigateToEntity()

    useEffect(() => {
        if (bankAccountId !== detailedAccount?.id)
            reloadBAD()
    }, [bankAccountId, detailedAccount?.id, reloadBAD])

    useEffect(() => {
        if(accountsApi) {
            setAccountOLBS(null)
            accountsApi.getWithOlbs(companyContext.cid, bankAccountId || 0).then(res => {
                if (res.data) {                    
                    setAccountOLBS(res.data)
                }
            }).catch(error => console.error(error))
        }
    }, [accountsApi, bankAccountId, companyContext.cid])
    
    const getFilter =useCallback( (yearCode: string|undefined) :  Promise<DatePicker> => {
        const p = new Promise<DatePicker>((resolve, reject) => {
            bookkeepingApi?.filter(companyContext.cid, {
                accountId: bankAccountId,
                yearCode: yearCode
            })
                .then(results => {
                    if (results.data.datePicker) {
                        resolve(results.data.datePicker)
                    } else reject();                    
                }).catch(error => reject(error))
        })
        return p;
    },[bankAccountId, bookkeepingApi, companyContext.cid])

    const getTransactions =useCallback( (yearCode : string) => {
        return new Promise<BankTransaction[]>((resolve, reject) => {
            bookkeepingApi && bookkeepingApi.index(companyContext.cid, {accountId: bankAccountId, yearCode: yearCode}).then(results => {
                if (results.data) {
                    resolve(results.data)
                }
            }).catch(error => {
                console.error(error)
                reject(error)
            })
        })
    },[bankAccountId, bookkeepingApi, companyContext.cid])

    return (
        <DefaultLayout entity={Entity.BookkeepingV7} title={detailedAccount?.name} useFullWidth>

            {detailedAccountLoaded && detailedAccount?.supportsBankFeed && accountOLBS &&
            <Toolbar>
                <Button onClick={() => accountOLBS.canSetupBankFeeds ? 
                    navToEntity(Entity.SetupStatementImporting, undefined, { 'aid' : bankAccountId }) : 
                    navToEntity(Entity.UploadStatement, undefined, { 'aid' : bankAccountId })} variant="change">
                    <FontAwesomeIcon icon={faInboxIn} /> {accountOLBS.canSetupBankFeeds ? "Setup statement importing" : accountOLBS.uploadStatementText}
                </Button>
            </Toolbar>}

            <AccountViewerNewWrapper getFilter={getFilter} getTransactions={getTransactions} bankAccountId={bankAccountId} featureSet={AccountViewerFeatureSet.BOOKKEEPING} dateFilterLoadingEnabled />
        </DefaultLayout>)
}