import { useContext, useEffect, useState } from 'react';
import { Form, Card } from 'react-bootstrap';
import DropdownButtons from '../Elements/Dropdown/DropdownButton';
import { ErrorMessage, FieldArray, Formik, FormikErrors, FormikHelpers, useFormikContext } from 'formik';
import { useAssertPermission } from '../../../hooks/useAssertPermission';
import { Action, Entity } from '../../../utils/EntityAction';
import {
	Account,
	BankTransactionUnbilledPayments,
	BrandingTheme,
	Country,
	Currency,
	InvoiceAsDetailed,
	InvoiceLine,
	InvoicePostModel,
	Market,
	MileageBillable,
	PaymentProvider,
	VatCodeListOption,
	TimesheetItem,
	InvoiceLinePostModel,
	MarketTerms,
} from '../../../api/inni/data-contracts';
import FormikField from '../../../elements/FormikField/FormikField';
import { InvoiceLayoutTableHeaders, InvoiceLayoutWrapper, TableTotalField } from './ReadInvoiceWrapper';
import { Expenses } from '../../../api/inni/Expenses';
import { InvoiceReactSelect } from '../CreateInvoice';
import { BankTransactions } from '../../../api/inni/BankTransactions';
import { BrandingThemes } from '../../../api/inni/BrandingThemes';
import { Countries } from '../../../api/inni/Countries';
import { Markets } from '../../../api/inni/Markets';
import { Mileages } from '../../../api/inni/Mileages';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList';
import { Accounts } from '../../../api/inni/Accounts';
import { Currencies } from '../../../api/inni/Currencies';
import { ActionType, getDocumentName, IntervalTypes, ShowField, ShowLabel, WeekDays } from '../HelperFunctions/ShowField';
import { currencyConvertor } from '../HelperFunctions/HelperFunctions';
import styles from '../Invoice.module.css';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { Time } from '../../../api/inni/Time';
import { PaymentProviders } from '../../../api/inni/PaymentProviders';
import { InvoiceLayoutLoading } from './Loadingtemplate';
import classNames from 'classnames';
import { Invoices } from '../../../api/inni/Invoices';
import Decimal from 'decimal.js';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { TableIcon } from '../../../elements/TableIcon/TableIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { asYearMonthDay } from '../../../utils/formatDate';
import CompanyContext from '../../../context/CompanyContext';

export interface EditFormProps {
	enableForeignInvoices: boolean;
	formikref: any;
	initialValues: InvoiceAsDetailed;
	invoiceNumber?: number;
	companyId: number;
	validateInvoice: (values: InvoicePostModel) => Promise<FormikErrors<InvoicePostModel>>;
	createInvoice: (values: InvoicePostModel, actions: FormikHelpers<InvoicePostModel>) => Promise<void>;
}

//This method is to access the formik values of InvoiceForm and assign the values which are dependent on other Invoice Values.
function UpdateInvoiceForm({ companyId }: { companyId: number }) {
	const invoiceAPI = useInniAPI(Invoices);
	const formik = useFormikContext<InvoiceAsDetailed>();
	const { values, setFieldValue } = formik;
	// useEffect(() => {
	// 	invoiceAPI?.exchangeRate(companyId, { invoiceDate: values.date, currency: values.invoiceCurrency ? values.invoiceCurrency : 'GBP' }).then((res) => {
	// 		setFieldValue('currencyRateAtTaxPoint', parseInt(res.data));
	// 	});
	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [values.invoiceCurrency, values.date, invoiceAPI, companyId]);

	useEffect(() => {
		if (values.invoiceCurrency !== 'GBP' && values.selectedPaymentProviderId !== null) {
			setFieldValue('selectedPaymentProviderId', null, false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values.invoiceCurrency, values.selectedPaymentProviderId]);
	return null;
}

function InvoiceForm({ initialValues, formikref, companyId, enableForeignInvoices, createInvoice, validateInvoice }: EditFormProps) {
	useAssertPermission(Entity.InvoiceV7, Action.Edit);
	const companyContext = useContext(CompanyContext)
	const hasPermission = useHasPermission();
	const countriesAPI = useInniAPI(Countries);
	const paymentProviderAPI = useInniAPI(PaymentProviders);
	const timeAPI = useInniAPI(Time);
	const currencyAPI = useInniAPI(Currencies);
	const mileageAPI = useInniAPI(Mileages);
	const marketApi = useInniAPI(Markets);
	const vatAPI = useInniAPI(Expenses);
	const bankpaymentsAPI = useInniAPI(BankTransactions);
	const brandingAPI = useInniAPI(BrandingThemes);
	const [accounts] = useFetchEntityList<Account, Accounts>(Accounts);
	const [paymentProviders, setPaymentsProviders] = useState<PaymentProvider[]>();
	const [markets, setMarkets] = useState<Market>();
	const [marketTerms, setMarketTerms] = useState<MarketTerms>();
	const [marketsLoaded, setmarketsLoaded] = useState(false);
	const [vatRate, setVatRate] = useState<VatCodeListOption[]>();
	const [branding, setBranding] = useState<InvoiceReactSelect[]>();
	const [mileage, setMileage] = useState<MileageBillable[]>();
	const [bankpayments, setBankpayments] = useState<BankTransactionUnbilledPayments[]>();
	const [countries, setCountires] = useState<InvoiceReactSelect[]>();
	const [currencies, setCurrencies] = useState<Currency[]>();
	const [timeSheet, setTimeSheet] = useState<TimesheetItem[]>();
	const intervalTypes: InvoiceReactSelect[] = Object.entries(IntervalTypes).map(([key, value]) => ({ value: key, label: value }));
	const daysOfWeek: InvoiceReactSelect[] = Object.entries(WeekDays).map(([key, value]) => {
		return { value: key, label: value };
	});
	const { documentType, amountVAT, showInvoiceVAT, contractId} = initialValues;

	useEffect(() => {
		if (countriesAPI && enableForeignInvoices) {
			countriesAPI.getCountries().then((results: { data: Country[] }) => {
				setCountires(
					results.data.map((country: Country) => {
						return {
							value: country.countryCode,
							label: country.name,
						};
					})
				);
			});
		}
	}, [countriesAPI, enableForeignInvoices]);

	useEffect(() => {
		paymentProviderAPI &&
			paymentProviderAPI
				.index(companyId)
				.then((results: { data: PaymentProvider[] }) => {
					setPaymentsProviders(results.data);
				})
				.catch((err) => {
					console.error(err);
				});
	}, [paymentProviderAPI, companyId]);

	useEffect(() => {
		if (marketApi && !marketsLoaded) {
			marketApi.index(companyId).then((res) => {
				setMarkets(res.data);
				setmarketsLoaded(true);
			});
		}
	}, [companyId, marketApi, marketsLoaded]);

	useEffect(() => {
		if (vatAPI && showInvoiceVAT) {
			vatAPI.vatRates(companyId).then((response) => setVatRate(response.data));
		}
	}, [vatAPI, companyId, showInvoiceVAT]);
	useEffect(() => {
		if (brandingAPI) {
			brandingAPI.get(companyId).then((response: { data: BrandingTheme[] }) =>
				setBranding(
					response.data.map((branding: BrandingTheme) => {
						return {
							value: branding.id,
							label: branding.name,
						};
					})
				)
			);
		}
	}, [brandingAPI, companyId]);

	useEffect(() => {
		if (mileageAPI && markets?.showTimeElements) {
			mileageAPI.getBillableMileages(companyId, { contractId: contractId ? contractId : undefined }).then((response) => setMileage(response.data));
		}
	}, [companyId, contractId, markets?.showTimeElements, mileageAPI]);

	useEffect(() => {
		if (bankpaymentsAPI) {
			bankpaymentsAPI
				.getUnbilledBankPayments(companyId, { contractId: contractId ? contractId : undefined })
				.then((response) => setBankpayments(response.data));
		}
	}, [bankpaymentsAPI, companyId, contractId]);

	useEffect(() => {
		if (currencyAPI && enableForeignInvoices) {
			currencyAPI.index().then((response: { data: Currency[] }) => setCurrencies(response.data));
		}
	}, [companyId, currencyAPI, enableForeignInvoices]);

	useEffect(() => {
		hasPermission(Entity.Time, Action.All)[0] &&
			contractId &&
			timeAPI
				?.getTimeSheets(companyId, contractId)
				.then((response) => {
					setTimeSheet(response.data);
				}) 
				.catch((err) => {
					console.error(err);
				});
	}, [companyId, contractId, timeAPI]);

	useEffect(() => {
		if (marketApi) {
			marketApi?.marketTerms(companyId).then((response) => {
				setMarketTerms(response.data);
			});
		}
	}, [markets, companyId, marketApi]);

	return (
		<>
			{markets && enableForeignInvoices !== undefined ? (
				<Formik
					onSubmit={createInvoice}
					initialValues={{ ...initialValues }}
					enableReinitialize
					validateOnChange={true}
					innerRef={formikref}
					validate={validateInvoice}>
					{({ handleSubmit, values }) => {
						return (
							<Form onSubmit={handleSubmit} id="formsubmit">
								<UpdateInvoiceForm companyId={companyId}/>
								<InvoiceLayoutWrapper>
									<Card.Body className="mx-0">
										<div className={classNames(styles.invoiceHeaderFont, styles.font, 'my-4 mx-3')}>
											{getDocumentName(documentType)?.toUpperCase()}
										</div>
										<div className={classNames(styles.invoiceEdit, styles.font, 'mb-3')}>
											{documentType === 'InvoiceTemplate' && ShowField(documentType, 'description', ActionType.Edit) && (
												<FormikField<InvoiceAsDetailed>
													label={ShowLabel<InvoiceAsDetailed>(documentType, 'description', ActionType.Edit)}
													name="description"
													type="text"
												/>
											)}
											{ShowField(documentType, 'invoiceNumber', ActionType.Edit) && (
												<FormikField<InvoiceAsDetailed>
													name="invoiceNumber"
													label={ShowLabel<InvoiceAsDetailed>(documentType, 'invoiceNumber', ActionType.Edit)}
													type="number"
													width="10rem"
													readonly={true}
												/>
											)}
											<Form.Group>
												{ShowField(documentType, 'invoiceReference', ActionType.Edit) && (
													<FormikField<InvoiceAsDetailed>
														name="invoiceReference"
														label={ShowLabel<InvoiceAsDetailed>(documentType, 'invoiceReference', ActionType.Edit)}
														type="text"
														width="10rem"
													/>
												)}
												{documentType !== 'InvoiceTemplate' && ShowField(documentType, 'description', ActionType.Edit) && (
													<FormikField<InvoiceAsDetailed>
														label={ShowLabel<InvoiceAsDetailed>(documentType, 'description', ActionType.Edit)}
														name="description"
														type="text"
														className=""
													/>
												)}
												{hasPermission(Entity.PaymentProvidersList, Action.All)[0] && documentType === 'Invoice' &&
													ShowField(documentType, 'selectedPaymentProviderId', ActionType.Edit) && (
														<FormikField<InvoiceAsDetailed>
															label="Payment provider"
															name="selectedPaymentProviderId"
															type="select"
															option={paymentProviders?.map((x) => {
																return { value: x.id, label: x.description };
															})}
															extraOption={[{ value: 0, label: 'None' }]}
															readonly={values.invoiceCurrency !== 'GBP'}
															placeholder="Select payment provider"
														/>
													)}
											</Form.Group>
											{ShowField(documentType, 'contractName', ActionType.Edit) && marketTerms && (
												<FormikField<InvoiceAsDetailed> name="contractName" label={marketTerms?.contractTerm} type="text" readonly={true} />
											)}
											{ShowField(documentType, 'invoiceAddress', ActionType.Edit) && (
												<FormikField<InvoiceAsDetailed>
													label={ShowLabel<InvoiceAsDetailed>(documentType, 'invoiceAddress', ActionType.Edit)}
													name="invoiceAddress"
													rows={6}
													cols={28}
													type="textarea"
												/>
											)}
											{enableForeignInvoices &&
												(currencies ? (
													ShowField(documentType, 'invoiceCurrency', ActionType.Edit) && (
														<FormikField<InvoiceAsDetailed>
															label={ShowLabel<InvoiceAsDetailed>(documentType, 'invoiceCurrency', ActionType.Edit)}
															name="invoiceCurrency"
															type="select"
															option={currencies?.map((x) => {
																return { value: x.code, label: x.code + '  ' + x.name };
															})}
														/>
													)
												) : (
													<LoadingPlaceholder />
												))}
											{enableForeignInvoices &&
												(countries ? (
													ShowField(documentType, 'supplyCountry', ActionType.Edit) && (
														<FormikField<InvoiceAsDetailed>
															label={ShowLabel<InvoiceAsDetailed>(documentType, 'supplyCountry', ActionType.Edit)}
															name="supplyCountry"
															type="select"
															option={countries}
															placeholder="Select country"
														/>
													)
												) : (
													<LoadingPlaceholder />
												))}
											{ShowField(documentType, 'date', ActionType.Edit) && (
												<FormikField<InvoiceAsDetailed>
													label={ShowLabel<InvoiceAsDetailed>(documentType, 'date', ActionType.Edit)}
													name="date"
													type="date"
													min={asYearMonthDay(new Date(companyContext.company?.currentFinancialYearStartDate || ''))}
													max={asYearMonthDay(new Date(companyContext.company?.currentFinancialYearEndDate || ''))}
												/>
											)}
											{ShowField(documentType, 'defaultDueDays', ActionType.Edit) && contractId !== null && contractId !== 0 && (
												<FormikField<InvoiceAsDetailed>
													label={ShowLabel<InvoiceAsDetailed>(documentType, 'defaultDueDays', ActionType.Edit)}
													name="defaultDueDays"
													type="number"
													width="15rem"
													readonly
												/>
											)}
											<Form.Group>
												{ShowField(documentType, 'dateDue', ActionType.Edit) && (
													<FormikField<InvoiceAsDetailed>
														label={ShowLabel<InvoiceAsDetailed>(documentType, 'dateDue', ActionType.Edit)}
														name="dateDue"
														type="date"
													/>
												)}
												{ShowField(documentType, 'autoEmailRepeatingInvoice', ActionType.Edit) && contractId !== null && contractId !== 0 && (
													<FormikField<InvoiceAsDetailed>
														label={ShowLabel<InvoiceAsDetailed>(documentType, 'autoEmailRepeatingInvoice', ActionType.Edit)}
														name="autoEmailRepeatingInvoice"
														type="checkbox"
														className="my-0"
													/>
												)}
												{documentType !== 'CreditNote' && (
													<Form.Group className="mb-0">
														{ShowField(documentType, 'intervalType', ActionType.Edit) && (
															<FormikField<InvoiceAsDetailed>
																label={ShowLabel<InvoiceAsDetailed>(documentType, 'intervalType', ActionType.Edit)}
																name="intervalType"
																type="select"
																option={intervalTypes}
																className="my-0"
															/>
														)}
														{values.intervalType === 'W' && ShowField(documentType, 'intervalDayOfWeek', ActionType.Edit) && (
															<FormikField<InvoiceAsDetailed>
																width="220px"
																label={ShowLabel<InvoiceAsDetailed>(documentType, 'intervalDayOfWeek', ActionType.Edit)}
																name="intervalDayOfWeek"
																type="select"
																option={daysOfWeek}
																className="my-0"
																placeholder="Select day"
															/>
														)}
														{values.intervalType === 'M' && ShowField(documentType, 'intervalDayOfMonth', ActionType.Edit) && (
															<FormikField<InvoiceAsDetailed>
																width="220px"
																label={ShowLabel<InvoiceAsDetailed>(documentType, 'intervalDayOfMonth', ActionType.Edit)}
																name="intervalDayOfMonth"
																type="number"
																className="my-0"
															/>
														)}
														{values.intervalType === 'Q' && (
															<FormikField
																label={ShowLabel(documentType, 'intervalDayQuarter', ActionType.Edit)}
																value="The 1st of new quarters"
																width="220px"
																type="label"
																name="intervalDayQuarter"
																readonly
																className="my-0"
															/>
														)}
													</Form.Group>
												)}
												{ShowField(documentType, 'brandingThemeId', ActionType.Edit) && contractId !== null && contractId !== 0 && (
													<FormikField<InvoiceAsDetailed>
														label={ShowLabel<InvoiceAsDetailed>(documentType, 'brandingThemeId', ActionType.Edit)}
														name="brandingThemeId"
														type="select"
														option={branding}
														placeholder="Select branding theme"
													/>
												)}
											</Form.Group>
											{documentType === 'InvoiceTemplate' &&
												ShowField(documentType, 'brandingThemeId', ActionType.Edit) &&
												(contractId === null || contractId === 0) && (
													<FormikField<InvoiceAsDetailed>
														label={ShowLabel<InvoiceAsDetailed>(documentType, 'brandingThemeId', ActionType.Edit)}
														name="brandingThemeId"
														type="select"
														option={branding}
														placeholder="Select branding theme"
													/>
												)}
										</div>
										{/* Invoice Lines */}
										<div className={styles.invoiceTable} data-cy="invoiceLinesTable" >
											<table className={classNames(styles.invoiceTableEdit)} style={{ verticalAlign: 'middle' }}>
												<InvoiceLayoutTableHeaders showVAT={ (amountVAT !== 0 || showInvoiceVAT ) && values.supplyCountry === 'GB'} editing />
												{
													<>
														<FieldArray name="lines">
															{(filedArrayProps) => {
																const { push, remove, form } = filedArrayProps;
																const {
																	values,
																	errors,
																	touched,
																}: {
																	values: InvoiceAsDetailed;
																	errors: FormikErrors<any>;
																	touched: any;
																} = form;
																const { lines }: { lines: InvoiceLinePostModel[] | undefined } = values;

																return (
																	<>
																		<tbody>
																			{lines && lines !== null && lines.length > 0 ? (
																				lines.map((item: InvoiceLinePostModel, index: number) => (
																					<tr className="bt-0" key={'lines' + index}>
																						<td >
																							<FormikField<InvoiceLine>
															 									name={`lines[${index}].description` as 'description'}
																								type="text"
																								className="px-0 mb-0"
																								isTable
																							/>
																						</td>
																						<td>
																							<FormikField<InvoiceLine>
																								name={`lines[${index}].quantity` as 'quantity'}
																								type="number"
																								className="px-0 text-right"
																								min="0"
																								isTable
																								readonly={lines[index].type !== 'Freetype'}
																								width="6rem"
																								showErrorLine={lines[index].quantity === 0 || lines[index]?.quantity! < 0} 
																							/>
																						</td>
																						<td>
																							<FormikField<InvoiceLine>
																								name={`lines[${index}].unitPrice` as 'unitPrice'}
																								type="number"
																								className="text-right px-0"
																								readonly={lines[index].type !== 'Freetype'}
																								width="8rem"
																								showErrorLine={lines[index].unitPrice === 0}
																								isTable
																							/>
																						</td>
																						{(amountVAT !== 0 || showInvoiceVAT) && values.supplyCountry === 'GB' && (
																							<td>
																								<FormikField<InvoiceLine>
																									name={`lines[${index}].vatCode` as 'vatCode'}
																									type="select"
																									className=""
																									width="12rem"
																									option={vatRate?.map((x) => ({
																										value: x.id,
																										label: x.name,
																									}))}
																									isTable
																								/>
																							</td>
																						)}
																						<td className='text-right'>
																							{item && item.quantity && item.unitPrice && currencyConvertor((item?.quantity * item?.unitPrice),currencies?.find((currency:Currency) => currency.code === values.invoiceCurrency)?.symbol as string)}
																						</td>
																						<td
																							style={{
																								textAlign: 'center',
																								padding: '8px 4px',
																								verticalAlign: 'middle',
																							}}>
																							<TableIcon icon="removeLine" onClick={() => remove(index)} />
																						</td>
																					</tr>
																				))
																			) : (
																				<InvoiceTableText />
																			)}
																		</tbody>
																		{/* erorrs for invoicetable, this is a temporary fix,efficent solution to be discussed */}
																		{touched.lines && errors && (errors.unitPrice || errors.quantity) && (
																			<tbody className="bt-0">
																				<tr>
																					<td colSpan={7}>
																						{touched.lines
																							? errors.quantity && (
																									<small className="invalid-feedback" style={{ display: 'block' }}>
																										{errors.quantity}
																									</small>
																							  )
																							: null}
																						{touched.lines
																							? errors.unitPrice && (
																									<small className="invalid-feedback" style={{ display: 'block' }}>
																										{errors.unitPrice}
																									</small>
																							  )
																							: null}
																					</td>
																				</tr>
																			</tbody>
																		)}
																		<tbody>
																			<tr>
																				<td colSpan={6} style={{ float: 'left' }}>
																					<DropdownButtons
																					    invoiceData={values}
																					    //showVAT={showInvoiceVAT}
																						accounts={accounts}
																						//TODO: check again if data available in test environment
																						mileageBillable={mileage?.filter((a) =>
																							lines
																								?.filter((x) => x.type === 'Mileage')
																								.flatMap((y) => y.mileageId)
																								.every((x) => x !== a.id)
																						)}
																						//TODO: check again if data available in test environment
																						bankpayements={bankpayments?.filter((a) =>
																							lines
																								?.filter((x) => x.type === 'BankPayment')
																								.flatMap((y) => y.bankPaymentId)
																								.every((x) => x !== a.id)
																						)}
																						showTimeElements={markets?.showTimeElements}
																						timeSheets={timeSheet?.filter((a) =>
																							lines
																								?.filter((x) => x.type === 'Timesheet')
																								.flatMap((y) => y.timesheetIds)
																								.every((x) => x !== a.id)
																						)}
																						onSelect={(eventKey, values) => {
																							push(values);
																						}}></DropdownButtons>
																				</td>
																			</tr>
																		</tbody>
																	</>
																);
															}}
														</FieldArray>
														<ErrorMessage name="lines" component="span" className="errorText"></ErrorMessage>
													</>
												}
												<tfoot>
													{showInvoiceVAT && values.supplyCountry === 'GB'? (
														<>
															<TableTotalField
														     	colspan={(amountVAT !== 0 || showInvoiceVAT) && values.supplyCountry === 'GB' ? 3 : 2}
																value={values && values.lines && values.lines.length > 0 ? values.lines.reduce((a, b) => a + ((b.unitPrice || 0) * (b.quantity || 0)), 0) : 0}
																text="Sub total"
																currency={currencies?.find((currency:Currency) => currency.code === values.invoiceCurrency)?.symbol}
																editing
															/>
															<TableTotalField
															    colspan={(amountVAT !== 0 || showInvoiceVAT) && values.supplyCountry === 'GB' ? 3 : 2}
																value={values && values.lines && values.lines.length > 0 ? new Decimal(values.lines.reduce((a, b) => a + ((b.unitPrice || 0) * (b.quantity || 0)) * (vatRate?.find(x => x.id === b.vatCode)?.rate || 0), 0)).toDecimalPlaces(2, Decimal.ROUND_FLOOR).toNumber() : 0}
																text="VAT"
																editing
																currency={currencies?.find((currency:Currency) => currency.code === values.invoiceCurrency)?.symbol}
															/>
															<TableTotalField
																colspan={(amountVAT !== 0 || showInvoiceVAT) && values.supplyCountry === 'GB' ? 3 : 2}
																editing={true}
																value={
																	values &&
																	values.lines &&
																	values.lines.reduce((a, b) => a + ((b.unitPrice || 0) * (b.quantity || 0)), 0) +
																	new Decimal(values.lines.reduce((a, b) => a + ((b.unitPrice || 0) * (b.quantity || 0)) * (vatRate?.find(x => x.id === b.vatCode)?.rate || 0) , 0)).toDecimalPlaces(2, Decimal.ROUND_FLOOR).toNumber()
																}
																currency={currencies?.find((currency: Currency) => currency.code === values.invoiceCurrency)?.symbol}
																text="Grand Total"
																bold
															/>
														</>
													) : (
														<TableTotalField
														colspan={(amountVAT !== 0 || showInvoiceVAT) && values.supplyCountry === 'GB' ? 3 : 2}
															editing={true}
															value={values && values.lines && values.lines.reduce((a, b) => a + ((b.unitPrice || 0) * (b.quantity || 0)), 0)}  
															text="Grand Total "
															bold
														/>
													)}
												</tfoot>
											</table>
										</div>
									</Card.Body>
								</InvoiceLayoutWrapper>
							</Form>
						);
					}}
				</Formik>
			) : (
				<InvoiceLayoutLoading />
			)}
		</>
	);
}

const InvoiceTableText = () => {
	return (
			<tr>
				<td colSpan={6} style={{ textAlign: 'center' }}>
					Use the button below to add a line
				</td>
			</tr>
	);
};

// delete icon for invoiceline table

export const DeleteIcon = ({ deleteAction }: { deleteAction: () => void }) => {
	const [color, setColor] = useState<string>('grey');

	return (
		<i
			onClick={deleteAction}
			onMouseEnter={() => setColor('red')}
			onMouseLeave={() => setColor('grey')}
			style={{ color: color, cursor: 'pointer', fontSize: '1.2rem' }}>
			<FontAwesomeIcon icon={faTrashAlt} />
		</i>
	);
};

export default InvoiceForm;
