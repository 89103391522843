import { useContext, useEffect, useState } from 'react'
import { Badge, Container } from 'react-bootstrap'
import { QuickEntry, BankTransactionViewModel, Account, BankTransactionPostModel, ExpenseAsSuggestion } from '../../../api/inni/data-contracts'
import { Expenses } from '../../../api/inni/Expenses'
import CompanyContext from '../../../context/CompanyContext'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { Date as DateRead, Text as TextRead, Currency as CurrencyRead } from '../../../elements/DetailRow/DetailRow'
import { formatAccount } from '../../../utils/formatters/formatAccount'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList'
import { Accounts } from '../../../api/inni/Accounts'
import { Text as EditText, Currency, SelectList, ListOption, DateSelector, Switch, GroupedListOption, GroupedSelectList }  from '../../../elements/EditRow/EditRow'
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik'
import { Button } from '../../../elements/Button/Button'
import { BankTransactions } from '../../../api/inni/BankTransactions'
import { useModalDialog } from '../../../hooks/useModalDialog'
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder'
import { EditorCommonProps } from './QEListMobile'
import styles from "../QuickEntry.module.css"
import { asYearMonthDay } from '../../../utils/formatDate'

let initialValues : BankTransactionViewModel = {
    id: 0,
    amount: 0,
    amountGbp: 0,
    accountId: 0,
    isBillable: false,
    relatedId: 0,
    isConfirmed: false,
    bankAccountId: 0,
    isReadOnly: false,
    employeeId: 0,
    suggestionId: 0,
    date: asYearMonthDay(new Date())
}

const ExpenseDetailRowLoading = () => <div style={{marginBottom: '1.5rem'}}><LoadingPlaceholder height="10px" width="20%" /><LoadingPlaceholder height="15px" /></div>

const ExpenseMobileEditor = ({qe, cancelCb, people, contracts, showAlert, suggestionData} : EditorCommonProps & { suggestionData?: ExpenseAsSuggestion }) => {
    const expensesAPI = useInniAPI(Expenses)
    const btAPI = useInniAPI(BankTransactions, [400])
    const companyContext = useContext(CompanyContext)

    const [expense, setExpense] = useState<BankTransactionViewModel>({...initialValues, ...suggestionData})
    const [expenseLoaded, setExpenseLoaded] = useState(false)

    const [vatRates, setVatRates] = useState<ListOption[]>([])

    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts)
    const [accountsSL, setAccountsSL] = useState<GroupedListOption[]>([])
    const [bankAccountsSL, setBankAccountsSL] = useState<ListOption[]>([])
    
    const [contractsSL, setContractsSL] = useState<ListOption[]>([])

    const [personSL, setPersonSL] = useState<ListOption[]>([])

    const [isEditing, setIsEditing] = useState(false)

    const [curEmployee, setCurEmployee] = useState<number | undefined>()
    const [creating, setCreating] = useState(false)

    const [showModalDialog, modalDialog] = useModalDialog()

    useEffect(() => {
        if (accountsLoaded) {
            let tempAccSL : ListOption[] = []
            accounts.filter(x => x.bankAccount && x.isExpenseAccount && x.personId === curEmployee).map(x => tempAccSL.push({ value: String(x.id), label: x.name || '' }))
            setBankAccountsSL(tempAccSL)
        }
    }, [accounts, accountsLoaded, curEmployee])

    useEffect(() => {
        if (accountsLoaded) {
            let tempOptions: Array<GroupedListOption> = []
            accounts.filter(i => i.accountGroup === 'Expenses' && !i.bankAccount).map(i => {
                const exists = tempOptions.findIndex(x => x.label === i.accountSubGroup)
                if (exists === -1) {
                    tempOptions.push({
                        label: i.accountSubGroup || '',
                        options: [{value: i.id.toString(), label: i.name || ''}]
                    })
                } else {
                    tempOptions[exists].options?.push({value: i.id.toString(), label: i.name || ''})
                }
            })
            setAccountsSL(tempOptions)
        }
    }, [accounts, accountsLoaded])

    useEffect(() => {
        if (expensesAPI && !expenseLoaded && qe) {
            expensesAPI.get(companyContext.cid, parseInt(qe.relatedId || '')).then(res => {
                if (res.status === 200) {
                    setExpense(res.data)
                    setExpenseLoaded(true)
                    setCurEmployee(res.data.employeeId)
                }
            })
        } else if (!qe && !suggestionData) {
            expensesAPI?.new(companyContext.cid)
            .then(res => {
                if (res.status === 200) {
                    setExpense({...res.data, date: asYearMonthDay(new Date())})
                    setExpenseLoaded(true)
                    setIsEditing(true)
                    setCreating(true)
                }
            })
        } else if (suggestionData) {
            setExpenseLoaded(true)
            setIsEditing(true)
            setCreating(true)
        }
    }, [companyContext.cid, expenseLoaded, expensesAPI, qe])

    useEffect(() => {
        if (people) {
            let tempPpl : ListOption[] = []
            people.map(x => tempPpl.push({ value: String(x.id), label: x.name || '' }))
            setPersonSL(tempPpl)
        }
    }, [people])

    useEffect(() => {
        if (contracts) {
            let tempCtr : ListOption[] = []
            contracts.map(x => tempCtr.push({ value: String(x.id), label: x.name || '' }))
            setContractsSL(tempCtr)
        }
    }, [contracts])

    useEffect(() => {
        if (expensesAPI) {
            expensesAPI.vatRates(companyContext.cid).then(res => {
                setVatRates(res.data.map(x => {return {value: x.id.toString(), label: x.name || ''} as ListOption}))
            })
        }
    }, [companyContext.cid, expensesAPI])

    const accountBadge = (id?: number) => {
        const name = accounts.find(x => x.id === id)?.name
        if (name) {
            const meta = formatAccount(name);
            return <Badge pill style={{backgroundColor: '#fff', fontWeight: 'normal', color: meta.color, border: `1px solid ${meta.color || 'black'}`, marginLeft: '0.5em'}}><FontAwesomeIcon icon={meta.icon} /> {name}</Badge>
        }
    }

    const formSubmit = (values: BankTransactionViewModel, actions: FormikHelpers<BankTransactionViewModel>) : Promise<void>=> {
        values.type = 'EX'
        return new Promise((resolve, reject) => {
            if (expensesAPI) {
                if (!creating) {
                    expensesAPI.put(companyContext.cid, values.id || 0, {...values, isReplacementItem: false, increasesPropertyValue: false} as BankTransactionPostModel)
                    .then(res => {
                        if (res.status === 200) {
                            let tempQe = qe
                            if (tempQe) {
                                tempQe.description = values.description
                                tempQe.subtitle = values.amount.toFixed(2)
                                tempQe.accountName = accounts.find(x => x.id === Number(values.accountId))?.name
                                tempQe.date = values.date || ''
                            }

                            showAlert('saved')
                            cancelCb(tempQe)
                            resolve();
                        } else {
                            showAlert('error')
                        }
                    })
                    .catch(error => {
                        actions.setErrors(error.error);
                        reject();
                    })
                } else {
                    expensesAPI.post(companyContext.cid, {...values, isReplacementItem: false, increasesPropertyValue: false} as BankTransactionPostModel)
                    .then(res => {
                        if (res.status === 201) {
                            let tempQe : QuickEntry = {
                                amount: values.amount,
                                amountGBP: values.amountGbp,
                                contractColour: 0,
                                contractId: values.contractId || 0,
                                contractTaskId: 0,
                                date: asYearMonthDay(new Date(values.date || '')),
                                billable: values.isBillable,
                                description: values.description,
                                subtitle: values.amount.toFixed(2),
                                accountName: accounts.find(x => x.id === Number(values.accountId))?.name,
                                relatedId: res.data,
                                entryType: 'E'
                            }
                            showAlert('saved')
                            cancelCb(tempQe)
                            resolve()
                        } else {
                            showAlert('error')
                        }
                    })
                    .catch(error => {
                        actions.setErrors(error.error);
                        reject();
                    })                    
                }
            } else {
                reject();
            }
        })
    }

    const formValidate =  (values: BankTransactionViewModel) : Promise<FormikErrors<BankTransactionViewModel>> => {
        values.type = 'EX'
        values.employeeId !== curEmployee && setCurEmployee(Number(values.employeeId))
        return new Promise((resolve, reject) => {
            if (btAPI) {
                return btAPI.validateCreate(companyContext.cid, {...values, isReplacementItem: false, increasesPropertyValue: false} as BankTransactionPostModel)
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }

    const showDeleteDialog = () => {
        showModalDialog(
            'Delete expense?',
            'Are you sure you want to delete this expense?',
            [
                <Button key="del" variant="danger" label="Yes" onClick={() => handleDelete()} />,
                <Button key="cancel" variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const handleDelete = () => {
        if (expensesAPI) {
            expensesAPI.delete(companyContext.cid, expense.id)
            .then(res => {
                if (res.status === 200) {
                    showAlert('deleted')
                    cancelCb(qe, true)
                } else {
                    showAlert('error')
                }
            })
        }
    }

    return (
        <Container>

            {!expenseLoaded && !accountsLoaded && <>
                <ExpenseDetailRowLoading />
                <ExpenseDetailRowLoading />
                <ExpenseDetailRowLoading />
                <ExpenseDetailRowLoading />
                <ExpenseDetailRowLoading />
            </>}
            
            {expenseLoaded && accountsLoaded &&
            <Formik
                initialValues={expense}
                enableReinitialize
                onSubmit={formSubmit}
                validateOnChange={false}
                validate={formValidate} 
            >
                {({ values }) => (
                    <Form>
                        
                        { expense && !isEditing && <>
                            <div style={{display: 'flex', justifyContent: 'space-between', margin: '20px 0px', alignItems: 'center', flexWrap: 'wrap', wordBreak: 'break-all'}}>
                                <h3>{values.description}</h3>
                                <div>{ accountBadge(Number(values.accountId)) }</div>
                            </div>
                            <CurrencyRead entity={expense} name="amount" />
                            <TextRead entity={expense} name="payee" />
                            <TextRead entity={expense} name="vatCode" label="VAT Code" valueFormatter={(i) => vatRates.find(x => x.value === i)?.label} />
                            <TextRead entity={expense} name="refNo" />
                            <DateRead entity={expense} name="date" />
                            <TextRead entity={expense} name="employeeName" />
                            <TextRead entity={expense} name="bankAccountName" />
                            <TextRead entity={expense} name="accountName" />
                            <TextRead entity={expense} name="contractName" />
                        </>}

                        {isEditing && 
                        <>
                            <EditText labelTop name="description" />
                            <Currency labelTop name="amount" />
                            <EditText labelTop name="payee" />
                            <SelectList allowSearch={false} labelTop name="vatCode" label="VAT Rate" options={vatRates} />
                            <EditText labelTop name="refNo" />
                            <DateSelector labelTop name="date" />
                            <SelectList allowSearch={false} readOnly={qe !== undefined} labelTop name="employeeId" label="Employee" options={personSL} />
                            <SelectList allowSearch={false} readOnly={qe !== undefined} labelTop name="bankAccountId" label="Bank account" options={bankAccountsSL} />
                            <GroupedSelectList labelTop name="accountId" label="Category" options={accountsSL} />
                            <SelectList allowSearch={false} labelTop name="contractId" label="Contract" options={contractsSL} />
                            <Switch labelTop name="isBillable" label="Billable" />
                        </>}

                        <div className={styles.editorButtons}>
                            <Button buttonType="cancel" onClick={() => isEditing && !creating ? setIsEditing(false) : cancelCb()} mobile />
                            {!expense.isReadOnly && !isEditing && <Button onClick={showDeleteDialog} buttonType="delete" mobile />}
                            {!isEditing && !expense.isReadOnly && <Button buttonType="edit" onClick={() => setIsEditing(true)} mobile />}
                            {isEditing && <Button buttonType="save" submitButton mobile />}
                        </div>
                    </Form>

                    
                )}

            </Formik>}
            { modalDialog }
        </Container>
    )
}

export default ExpenseMobileEditor