import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AccountingPeriodsReviewItem } from '../../api/inni/AccountingPeriodsReviewItem';
import { AccountingPeriodReviewItem, ReviewItemClientResponsePostModel } from '../../api/inni/data-contracts';
import CompanyContext from '../../context/CompanyContext';
import { ReviewItemsPostModel } from '../../features/Query/Queries';
import { useInniAPI } from '../useInniAPI';

const useTransactionQueries = (apid: number, admin?: boolean) => {
	const { cid } = useContext(CompanyContext);
	const reviewAPI = useInniAPI(AccountingPeriodsReviewItem);
	const [queries, setQueries] = useState<AccountingPeriodReviewItem[]>([]);
	const [isAdmin, setIsAdmin] = useState<boolean | null>(admin || null);
	const [alertMessage, setAlertMessage] = useState('');
	const [queriesLoading, setQueriesLoading] = useState(true);
	const requestQueryList = useCallback(
		async (apid: number) => {
			if (reviewAPI && cid && apid) {
				if (isAdmin) return reviewAPI.getReviewItems(cid, apid).then(({ data: queryList }) => queryList);
				if (isAdmin === false)
					return reviewAPI.getReviewItemsByClient(cid, apid).then(({ data: queryList }) => queryList?.filter(v => v.isPublished) || []);
			}
		},
		[cid, isAdmin, reviewAPI]
	);
	const getQueries = useCallback(
		async (isSubscribed: boolean) => {
			if (apid) {
				try {
					const list = await requestQueryList(apid);
					isSubscribed && list && setQueries(list);
				} catch (err) {
					console.log(err)
				}finally {
					isSubscribed && setQueriesLoading(false)
				}
			}
		},
		[apid, requestQueryList]
	);


	useEffect(() => {
		let isSubscribed = true;
		getQueries(isSubscribed)
		return () => {
			isSubscribed = false;
		}
	}, [getQueries]);

	useEffect(() => {
		return () => setQueriesLoading(true)
	}, [])


	const publishQueries = () => {
		if (cid && reviewAPI) {
			reviewAPI.publishItemsToClient(cid, apid).then(() => {
				setQueries((prev) => prev.map((v) => ({ ...v, isPublished: true })));
				setAlertMessage('The queries have been published successfully');
			});
		}
	};

	const onResponse = useCallback((riid: number, pm: ReviewItemClientResponsePostModel) => {
		if (cid && reviewAPI) {
			reviewAPI.clientResponse(cid, apid, riid, pm).then(() => {
				setQueries((prev) => prev.map((q) => (q.id === riid ? { ...q, response: pm.response || '' } : q)));
				setAlertMessage('The message has sent successfully');
			});
		}
	}, [apid, cid, reviewAPI]);

	const onAcceptSuggestion = (riid: number) => {
		if (cid && reviewAPI) {
			reviewAPI.acceptSuggestion(cid, apid, riid).then(() => {
				setQueries((prev) =>
					prev.map((q) => (q.id === riid ? { ...q, suggestionAcceptedByContactId: new Date().getTime() } : q))
				);
				setAlertMessage('You accepted the suggestion');
			});
		}
	};

	const onEditQuery: (pm: ReviewItemsPostModel, riid: number, onSuccess: () => void) => void = useCallback(
		(pm: ReviewItemsPostModel, riid: number, onSuccess: () => void) => {
			if (reviewAPI && cid) {
				reviewAPI
					.updateReviewItem(cid, apid, { riid }, { ...pm, newAccountId: +(pm.newAccountId || 0) })
					.then(() => {
						setQueries((prev) =>
							prev.map((q) =>
								q.id === riid ? { ...q, ...pm, newAccountId: +(pm.newAccountId || 0) } : q
							)
						);
						setAlertMessage('The query has been successfully edited');
						onSuccess();
					});
			}
		},
		[reviewAPI, cid, apid]
	);

	const isAllQueriesPublished = useMemo(() => queries?.every(v => v.isPublished), [queries])
	
	return {
		queries,
		publishQueries,
		setIsAdmin,
		setQueries,
		onResponse,
		onAcceptSuggestion,
		setAlertMessage,
		alertMessage,
		onEditQuery,
		isAllQueriesPublished,
		requestQueryList,
		queriesLoading
	};
};

export default useTransactionQueries;
