import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../../elements/Button/Button';
import { ReceiptAccountTypes } from '../Receipts';
import styles from '../Receipts.module.css';

const ReceiptPage2 = ({changePageCB, setAccountType} : {changePageCB : (i : number) => void, setAccountType : (i : ReceiptAccountTypes) => void}) => {
    return(
        <>
            <h5>How was it paid for?</h5>
            <Button variant="light" className={styles.blockButton} onClick={() => {setAccountType('personal'); changePageCB(3)}}>
                Using personal money <FontAwesomeIcon icon={faChevronRight}/>
            </Button>
            <Button variant="light" className={styles.blockButton} onClick={() => {setAccountType('company'); changePageCB(3)}}>
                Using my company's money <FontAwesomeIcon icon={faChevronRight}/>
            </Button>
            
        </>
    )
}
export default ReceiptPage2