import { useState, useEffect } from 'react';

export const useGetWidth = () => {
    const [width, setWidth] = useState(window.innerWidth)
    // listen for width changes - don't have to reload to switch between vertical and horizontal layouts for stepper
    useEffect(() => {

        const handleResize = () => setWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        // remove/tidy when component is no longer rendered
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

    return width;
}
