import { useContext, useEffect, useState } from "react";
import { BasicFormLayout } from "../../layouts/Desktop/BasicFormLayout";
import { getStoryblokApi, SbBlokData } from "@storyblok/react";
import { render as renderRichText } from 'storyblok-rich-text-react-renderer';
import { Alert, Form } from "react-bootstrap";
import { Button } from "../../elements/Button/Button";
import CompanyContext from "../../context/CompanyContext";
import { useInniAPI } from "../../hooks/useInniAPI";
import { PvSatr } from "../../api/inni/PvSatr";
import Confetti from 'react-confetti'
import styles from "../../layouts/Desktop/BasicFormLayout.module.scss"
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder/LoadingPlaceholder";
import { useStandaloneSATRStatus } from "../../hooks/useStandaloneSATRStatus";


export default function SATRSubmission (){

    const [sbPageDescription, setSBPageDescription] = useState<SbBlokData[]>();
    const [sbSuccessPageDescription, setSBSucccessPageDescription] = useState<SbBlokData>();
    const companyContext = useContext(CompanyContext)
    const pvsatrAPI = useInniAPI(PvSatr)
    const [additionalInfo, setAdditionalInfo] = useState<string>();
    const [isAgreed, setIsAgreed] = useState(false);
    
    const { pvSatrDetails } = useStandaloneSATRStatus();
    const storyblokApi = getStoryblokApi();
    
    useEffect(() => {
        storyblokApi.get('cdn/stories/satr-standalone/page-body/submit-page', {version: 'published'}).then((res) => {
            
            const data = res.data.story.content.body;
            
            if (data.length > 0) {
                const temp:SbBlokData[] = []
                res.data.story.content.body.forEach((data: any) => {
                    temp.push(data.body)
                });
                setSBPageDescription(temp)

            }
            else {
                console.warn('Unexpected storyblok format')
            }
        })
    }, [])

    useEffect(() => {
        storyblokApi.get('cdn/stories/satr-standalone/page-body/submit-success-body', {version: 'published'}).then((res) => {

            if (res.data.story.content.body.length > 0) {
                setSBSucccessPageDescription(res.data.story.content.body[0].body)
            }
            else {
                console.warn('Unexpected storyblok format')
            }
        })
    }, [])

    const allStagesConfirmed = (): boolean =>{

        if(!pvSatrDetails)
            return false

        return !!(   pvSatrDetails.progress?.setupBasicDetails && 
                    pvSatrDetails.progress?.setupIncome && 
                    pvSatrDetails.progress?.setupProperty && 
                    pvSatrDetails.progress?.setupFinancialRecords &&
                    pvSatrDetails.progress?.setupPropertyIncomePageData
                );
    }

    const OnSubmit = (): Promise<void> => {
        return new Promise((resolve, reject) => {
          if (pvsatrAPI && pvSatrDetails) {
                pvsatrAPI.submit(companyContext.cid, pvSatrDetails.id, {generalInformation : additionalInfo})
                .then((response) => {
                    if(companyContext.reloadMenu ){
                        companyContext.reloadMenu();
                    }

                    resolve();
                })
                .catch((error) => {
                    reject();
                });
          } else {
            reject();
          }
        });
    };

    return(
        <BasicFormLayout
            title={(pvSatrDetails?.progress?.setupSubmitted)
                ? 'Relax, you\'re all done.'
                : 'Submit'
            }
            bigTitle={(pvSatrDetails?.progress?.setupSubmitted)}
        >
            {(!pvSatrDetails || !sbPageDescription) &&
                <> 
                    {Array(3)
                        .fill(0)
                        .map((_, index) => (
                            <div className="mb-4" key={index}>
                                <LoadingPlaceholder dark />
                                <LoadingPlaceholder dark />
                                <LoadingPlaceholder dark />
                                <LoadingPlaceholder dark width='500px' />
                                <br />
                            </div>
                    
                    ))}
                    
                    <div className="w-100 mt-4 mr-4">
                        <LoadingPlaceholder width="20%" height="20px" dark/>
                        <LoadingPlaceholder width="100%"  height="100px" dark/>
                    </div>

                    <div className="w-100 mt-4 mr-4">
                        <LoadingPlaceholder width="20%" height="20px" dark/>
                        <LoadingPlaceholder width="100%"  height="20px" dark/>
                    </div>


                    <Button 
                        marginTop
                        variant="primary" 
                        label="Submit"
                        disabled
                    />
                    
                </>
            }

            { pvSatrDetails && pvSatrDetails?.progress?.setupSubmitted &&
                <div>
                    <Confetti
                        height={window.innerHeight}
                        width={window.innerWidth}
                        numberOfPieces={200}
                        recycle={false}
                        confettiSource={
                            {
                                x: 0,
                                y: 0,
                                w: window.innerWidth,
                                h: 400 
                            }
                        }
                        initialVelocityY={{min: -6, max: -8}}
                        gravity={0.3}
                        opacity={0.3}
                    />

                    { sbSuccessPageDescription &&
                        <div className="mt-2">
                            { renderRichText(sbSuccessPageDescription) }
                        </div>
                    }
                </div>
            }
            
            {pvSatrDetails && !pvSatrDetails.progress?.setupSubmitted && sbPageDescription &&
                <>
                    {sbPageDescription && sbPageDescription.map((richText, index) =>(
                            <div className="mt-6" key={index}>
                                { renderRichText(richText) }
                            </div>

                        ))
                    }

                    <Form.Group className={styles.margin}>
                        <Form.Label className={styles.boldLabel}>
                            If there is anything else you believe is important for us to know, please provide the details in the box below.
                        </Form.Label>
                        <Form.Control
                            className={styles.generalInfo}
                            autoComplete='off'
                            as='textarea'
                            type='text'
                            value={additionalInfo}
                            onChange={(e) => setAdditionalInfo(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className={styles.margin}>
                        <Form.Label className={styles.boldLabel}>I agree to the declaration and am ready to submit</Form.Label>
                            <Form.Check
                                type='checkbox'
                                checked={isAgreed}
                                onChange={(e) => setIsAgreed(e.target.checked)}
                            />
                    </Form.Group>

                    {pvSatrDetails && !allStagesConfirmed() &&
                        <Alert variant="danger">Please confirm the details in the previous stages before submitting.</Alert>
                    }

                    <Button 
                        marginTop
                        variant="primary" 
                        label="Submit"
                        submitButton
                        disabled = {!allStagesConfirmed() || !isAgreed }
                        onClick={OnSubmit}
                    />

                </> 
            }
        </BasicFormLayout>

    )
}