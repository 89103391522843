/* eslint-disable no-useless-concat */
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvoiceAsDetailed, InvoicePostModel } from '../../api/inni/data-contracts';
import { Invoices } from '../../api/inni/Invoices';
import { useAssertPermission } from '../../hooks/useAssertPermission';
import { useInniAPI } from '../../hooks/useInniAPI';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { Entity, Action } from '../../utils/EntityAction';
import InvoiceForm from './Components/InvoiceFormWrapper';
import { FormikErrors, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import Toolbar from '../../layouts/Desktop/Toolbar';
import { Button } from '../../elements/Button/Button';
import Loadingtemplate from './Components/Loadingtemplate';
import { getDocumentName} from './HelperFunctions/ShowField';
import { useHasPermission } from '../../hooks/useHasPermission';
import { useHistoryWrapper } from '../../hooks/useHistoryWrapper';

interface RouteParams {
	id: string;
	cid: string;
	action: string;
	rid: string;
	doctype: 'Invoice' | 'CreditNote'|'Quote'|'InvoiceTemplate';
	year: string;
}
export interface InvoiceReactSelect {
	value?: string | number;
	label?: string | number;
}

const CreateInvoice = (): ReactElement => {
	useAssertPermission(Entity.InvoiceV7, Action.Create);
	const hasPermission = useHasPermission();
	const [enableForeignInvoices, setEnableForeignInvoices] = useState(false);
	const history = useHistoryWrapper();
	const formikRef = useRef<FormikProps<FormikValues>>();
	const navigateToEntity = useNavigateToEntity();
	const contractId = parseInt(useParams<RouteParams>().id);
	const companyId = parseInt(useParams<RouteParams>().cid);
	const invoiceId = parseInt(useParams<RouteParams>().rid);
	const year = useParams<RouteParams>().year;
	const docType = useParams<RouteParams>().doctype;
	const actionType = useParams<RouteParams>().action;
	const [initialValues, setInitialValues] = useState<InvoiceAsDetailed | undefined>(undefined);
	const [isLoading, setIsLoading] = useState(true);
	const invoiceAPI = useInniAPI(Invoices, [400]);
	const invoiceAPIwithout400 = useInniAPI(Invoices, []);
	let pageTitle = actionType === 'copyinvoice' ? 'Copy ' : 'Create';

	useEffect(() => {
		invoiceAPI
			?.new(companyId, {
				contractId: contractId ? contractId : undefined,
				invoiceId: actionType && actionType !== 'issuecreditnote' ? invoiceId : undefined,
				createCopy: actionType === 'copyinvoice' ? true : undefined,
				docType: docType ? docType : undefined,
				createInvoice: actionType === 'createinvoice' ? true : undefined,
				creditForInvoiceId: actionType === 'issuecreditnote' ? invoiceId : undefined,
			})
			.then((response: { data: React.SetStateAction<InvoiceAsDetailed | undefined> }) => {
				setInitialValues(response.data);
				setIsLoading(false);
			});
	}, [companyId, contractId, actionType, docType, invoiceAPI, invoiceId]);

	useEffect(() => {
		setEnableForeignInvoices(hasPermission(Entity.ForeignInvoicing, Action.All)[0]);
	}, []);

	const handleCancel = () => {
		history.goBack();
	};

	const validateCreateInvoice = (values: InvoicePostModel): Promise<FormikErrors<InvoicePostModel>> => {
		return new Promise((resolve, reject) => {
			if (invoiceAPI && companyId) {
				return invoiceAPI
					.validateCreate(companyId, values)
					.then(() => resolve({}))
					.catch((error) => resolve(error.error));
			} else {
				reject();
			}
		});
	};

	const createInvoice = (values: InvoicePostModel, actions: FormikHelpers<InvoicePostModel>): Promise<void> => {
		return new Promise((resolve, reject) => {
			if (invoiceAPIwithout400 && companyId) {
				return invoiceAPIwithout400
					.create(companyId, values)
					.then((data: { data: string | number; }) => {
						navigateToEntity(Entity.InvoiceV7, Action.Read, { id: +data.data, year: year });
						resolve();
					})
					.catch((error) => {
						actions.setErrors(error.error);
					});
			} else {
				reject();
			}
		});
	};

	const handleSubmit = () => {
		if (formikRef.current) {
			formikRef.current.handleSubmit();
		}
	};

	return (
		<>
			{isLoading && <Loadingtemplate editing></Loadingtemplate>}
			{!isLoading && (
				<DefaultLayout title={pageTitle + ' ' + (initialValues && getDocumentName(initialValues.documentType)?.toLowerCase())}>
					<Toolbar>
						<Button buttonType="save" onClick={handleSubmit} disabled={formikRef.current?.isSubmitting}></Button>
						<Button buttonType="cancel" onClick={handleCancel}></Button>
					</Toolbar>
					{initialValues && (
						<InvoiceForm 
							enableForeignInvoices={enableForeignInvoices}
							formikref={formikRef}
							companyId={companyId}
							initialValues={initialValues}
							createInvoice={createInvoice}
							validateInvoice={validateCreateInvoice}></InvoiceForm>
					)}
				</DefaultLayout>
			)}
		</>
	);
};

export default CreateInvoice;
