import styles from './DetailRow.module.css';
import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { formatDate } from '../../utils/formatDate';
import { formatCurrency } from '../../utils/formatNumbers';
import { currencyIntervals } from '../EditRow/EditRow';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder';
import CompanyContext from '../../context/CompanyContext';
import { Form } from 'react-bootstrap';


const RowWrapper = ({
    name,
    label,
    children,
    skipRender,
    editStyling,
    fixedWidth,
    wideLabel,
    v8Summary,
    boldLabel,
    showLabelTop
} : {
    name: string,
    label?: string,
    children: React.ReactNode,
    skipRender?: boolean,
    editStyling?: boolean,
    fixedWidth?: boolean,
    wideLabel?: boolean,
    v8Summary?: boolean,
    boldLabel?:boolean,
    showLabelTop?: boolean
}) => {
    const companyContext = useContext(CompanyContext);
    const v8Styling = companyContext.company?.useV8UI || false;
    

    const generateLabel = () => {
        if (label) return label;
        let l = _.lowerCase(name);
        l = _.upperFirst(l);
        return l;
    }

    if (skipRender) {
        return null 
    } else {
        return (
            <div className={classNames(
                editStyling ? styles.editDetailRow : styles.detailRow,
                {
                    [styles.fixedWidth]: fixedWidth,
                    [styles.wideLabel]: wideLabel,
                    [styles.underline]: v8Styling && !v8Summary && !editStyling,
                    [styles.labelTop]: showLabelTop
                }
            )}>
                <label 
                    className={boldLabel 
                                ? 'font-weight-bold'
                                : v8Summary 
                                    ? 'text-muted' 
                                    : ''
                            }
                >
                    { generateLabel() }
                </label>
                <div className={styles.valueWrapper}>
                    {children}
                </div>
            </div>
        )    
    }
}

export interface RowCommonProps<T> {
    entity: T,
    name: Extract<keyof T, string>,
    label?: string,
    help?: string,
    suffix?: string,
    ensureRender?: boolean
    editStyling?: boolean,
    fixedWidth?: boolean,
    wideLabel?: boolean,
    v8Summary?: boolean,
    boldLabel?: boolean,
    showLabelTop?: boolean
}

export interface TextProps {
    valueFormatter?:(value: string | undefined) => string | undefined,
    textMuted?: boolean
}

const Text = <T,>({
    entity,
    name,
    label,
    suffix,
    valueFormatter,
    textMuted = false,
    ensureRender = false,
    editStyling,
    fixedWidth,
    wideLabel,
    v8Summary,
    help,
    boldLabel,
    showLabelTop
}: RowCommonProps<T> & TextProps) => {
    
    const [v8Styling, setv8Styling] = useState(false); 
    const companyContext = useContext(CompanyContext);
    
    useEffect(() => {
        setv8Styling(companyContext.company?.useV8UI || false);
    }, [companyContext.company]);

    return (
        <RowWrapper
            name={name}
            label={label}
            skipRender={!entity[name] && !ensureRender}
            editStyling={editStyling}
            fixedWidth={fixedWidth}
            wideLabel={wideLabel}
            v8Summary={v8Summary}
            boldLabel ={boldLabel}
            showLabelTop={showLabelTop}
        >
            <span data-cy={name} className={textMuted || !entity[name] ? 'text-muted' : ''}>
                {/* entity[name] is set to !== undefined because it is skiping when the property is of type boolean */}
                { valueFormatter
                    ? valueFormatter(String(entity[name])) !== undefined
                        ? valueFormatter(String(entity[name]))
                        : v8Styling
                            ? '--'
                            : 'None'
                    : entity[name] !== undefined
                        ? entity[name]
                            ? entity[name]
                            : v8Styling
                                ? '--'
                                : 'None'
                        : v8Styling
                            ? '--'
                            : 'None'
                }
                {suffix}
                { help && <Form.Text className={`text-muted`}>{ help }</Form.Text> }
            </span>
        </RowWrapper>
    )
}

// Original Text element should probably inherit this basic element, but currently not making risky changes - DD 20/02/24
interface TextValueProps {
    name: string,
    label: string,
    value?: string,
    editStyling?: boolean,
    fixedWidth?: boolean,
    textMuted?: boolean,
    v8Summary?: boolean
}

const TextValue = ({
    name,
    label,
    value,
    editStyling=false,
    fixedWidth=false,
    textMuted=false,
    v8Summary=false,
}: TextValueProps) => {
    return (
        <RowWrapper
            name={name}
            label={label}
            editStyling={editStyling}
            fixedWidth={fixedWidth}
            v8Summary={v8Summary}
        >
            <span data-cy={name} className={textMuted ? 'text-muted' : ''}>
                { value || '' }
            </span>
        </RowWrapper>
    )
}

const YesNo = <T,>({entity, name, label, help, fixedWidth, wideLabel, editStyling, v8Summary, showLabelTop}: RowCommonProps<T>) => {

    return (
        <RowWrapper name={name} label={label} fixedWidth={fixedWidth} wideLabel={wideLabel} editStyling={editStyling} v8Summary={v8Summary} showLabelTop={showLabelTop}>
            <span data-cy={name}>{entity[name] ? 'Yes' : 'No'} </span>
        </RowWrapper>
    )
}

const LoadingDetail = ({label, name, editStyling, v8Summary}: {label?: string, name: string, editStyling?: boolean, v8Summary?: boolean}) => {
    return (
        <RowWrapper name={name} label={label} editStyling={editStyling} v8Summary={v8Summary}>
            <span data-cy={name}><LoadingPlaceholder width='100px' height='17px' /></span>
        </RowWrapper>
    )
}

const getFormattedDate = (date: string, v8Styling:boolean) =>{

    let formattedDate = formatDate(date).replace('Invalid Date', v8Styling ? '--' : 'No entry')
    
    return formattedDate === '' ? (v8Styling ? '--' : 'No entry') : formattedDate;
}

const Date = <T,>({entity, name, label, help, ensureRender, editStyling, fixedWidth, wideLabel, v8Summary, boldLabel, showLabelTop}: RowCommonProps<T>) => {
    const [v8Styling, setv8Styling] = useState(false); 
    const companyContext = useContext(CompanyContext);
    
    useEffect(() => {
        setv8Styling(companyContext.company?.useV8UI || false);
    }, [companyContext.company]);

    return (
        <RowWrapper
            name={name}
            label={label}
            skipRender={!entity[name] && !ensureRender}
            editStyling={editStyling}
            fixedWidth={fixedWidth}
            wideLabel={wideLabel}
            v8Summary={v8Summary}
            boldLabel = {boldLabel}
            showLabelTop={showLabelTop}
        >
            <span className={entity[name] ? '' : 'text-muted'}>
            {getFormattedDate(String(entity[name]), v8Styling)}
            </span>
        </RowWrapper>
    )
}

export interface CurrencyProps<T> {
    intervalFieldName?: Extract<keyof T, string>,
    intervalLabel?: string
}


const Currency = <T,>({entity, name, label, help, intervalFieldName, intervalLabel, ensureRender, fixedWidth, wideLabel, v8Summary, showLabelTop}:
        RowCommonProps<T> & CurrencyProps<T>) => {

    const getInterval = () => {
        if (intervalFieldName) {
            const key = String(entity[intervalFieldName]);
            if (key in currencyIntervals) {
                return currencyIntervals[key];
            }
        }
        return null;
    }

    return (
        <RowWrapper
            name={name}
            label={label}
            skipRender={!entity[name] && !ensureRender}
            fixedWidth={fixedWidth}
            wideLabel={wideLabel}
            v8Summary={v8Summary}
            showLabelTop={showLabelTop}
        >
            {formatCurrency(parseFloat(String(entity[name])))}
            {intervalLabel && 
                <> {intervalLabel}</>
            }
            {intervalFieldName &&
                <> {getInterval()}</>
            }
        </RowWrapper>
    )
}


export {Text, TextValue, Date, Currency, YesNo, LoadingDetail} 