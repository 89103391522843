import { useState } from 'react';
import { Form } from 'react-bootstrap';

export interface PaidSelectorProps {
    paidCallback : (a : number) => void;
    defaultValue? : number
}

const PaidSelector = ({paidCallback, defaultValue} : PaidSelectorProps) => {
    const [selectPaid, setSelectPaid] = useState<number>(defaultValue || 0);

    const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        setSelectPaid(Number(e.target.value));
        paidCallback(Number(e.target.value));
    }
    
    return (
        <Form style={{marginRight: '7px'}}>
            <Form.Control data-cy="paidSelector" onChange={(e) => handleSelectionChange(e) } value={selectPaid} as="select">
                <option value={-1}>Any status</option>
                <option value={0}>Unpaid</option>
                <option value={1}>Paid</option>
            </Form.Control>
        </Form>
    )
}

export default PaidSelector