import { useState, useEffect, useRef } from 'react';
import { OrdinaryPlanShareholderSetupModel, Person} from '../../../../../api/inni/data-contracts';
import { Button } from '../../../../../elements/Button/Button';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import FormikField from '../../../../../elements/FormikField/FormikField';
import { BrandWrapper } from '../../../../../components/BrandWrapper/BrandWrapper';
import SimpleFullscreen from '../../../../../components/Fullscreen/SimpleFullscreen';
import Icon from '../../../../../elements/Icon/Icon';
import { Summary } from '../../../../../elements/Summary/Summary';
import { Text, Currency } from '../../../../../elements/DetailRow/DetailRow';
import { Submit } from '../../../../../elements/EditRow/EditRow';
import styles from '../shareholdings.module.scss'
import classNames from 'classnames';

interface createShareholdingsProps{
    formSubmit: (values: OrdinaryPlanShareholderSetupModel, actions: FormikHelpers<OrdinaryPlanShareholderSetupModel>) => Promise<void>;
    hide: () => void;
    persons: Person[]
}
const initialValues: OrdinaryPlanShareholderSetupModel = {
    name: "Ordinary",
    capitalPerShare: 0,
    numberOfShares:0
}

const CreateOrdinaryShareholdings = ({formSubmit, hide, persons}: createShareholdingsProps) => {

    //stages
    type STAGE_NAMES = "intro"|"shareDetails"|"submit"
    const stageFlow :STAGE_NAMES[] = ["intro", "shareDetails", "submit" ]
    const [currentStage, setCurrentStage] = useState<STAGE_NAMES>("intro");
    const formikRef = useRef<FormikProps<OrdinaryPlanShareholderSetupModel>>(null);

    const getStageDetails = () => {
        switch(currentStage) {
            case "intro":
                return  {    
                            Title: "Confirm your company structure"
                        }
            case "shareDetails":
                return  {    
                            Title: "Detail your company's shares",
                            Subtitle: "Please tell us how your company's share structure is formed"
                        }
            case "submit":
                return  {    
                            Title: "Summary of shares", 
                            Subtitle: `Below is a summary of the details you have provided.
                            Please review the information to ensure its accuracy, and make any necessary adjustments before finalising`
                        }
            default:
                return  {
                            Title: ""
                        }
        }
    }

    const nextStage = () => {
        if(stageFlow && currentStage) {
            setCurrentStage(stageFlow[stageFlow.indexOf(currentStage)+1])  
        }
    }

  return (
        <SimpleFullscreen
            isOpen={true}
            onClose={hide}
            onBack={stageFlow && currentStage && stageFlow.indexOf(currentStage) > 0 ?
                () => setCurrentStage(stageFlow[stageFlow.indexOf(currentStage) - 1]) :
                undefined}
            title={getStageDetails().Title}
            subTitle={getStageDetails().Subtitle} 
        >
            <BrandWrapper>
                {stageFlow && 
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize         
                        validateOnChange={false}
                        onSubmit={formSubmit}
                        innerRef={formikRef}
                    >
                {({ isSubmitting, values }) => (
                    <Form>                
                            {currentStage === "intro" &&
                                <>  
                                    <div className={styles.subtitle}>
                                        <p>Your current plan supports a single shareholder, who also serves as the sole director and owns all the shares of a single share class.</p>
                                        <p>If your company isn't set up like this, please get in touch to discuss your requirements by visiting: </p>
                                        <a target="_new" rel="noopener noreferrer" href='https://www.provestor.co.uk/about/contact' className="linkText">www.provestor.co.uk/about/contact</a> 
                                        <p>Otherwise, please confirm to continue.</p>
                                    </div>
                                    <div>
                                        <Button 
                                            variant='primary'
                                            marginTop 
                                            onClick={nextStage}>
                                                Confirm
                                        </Button>
                                        <Button 
                                            marginTop
                                            buttonType='close'
                                            onClick={hide}
                                        />
                                    </div>
                                </>
                            }

                            {currentStage === "shareDetails" &&
                                <>
                                    <FormikField<OrdinaryPlanShareholderSetupModel>
                                        className={styles.formikFields}
                                        label="Share class name"
                                        name="name"
                                        type="text"                                        
                                    />

                                    <FormikField<OrdinaryPlanShareholderSetupModel>
                                        className={classNames(styles.formikFields, styles.inputMargin)}
                                        label="Total number of shares"
                                        name="numberOfShares"
                                        type="number"
                                        min='0' 
                                        onlyIntegers
                                        onlyPositiveNumbers                            
                                    />

                                    <FormikField<OrdinaryPlanShareholderSetupModel >
                                        className={classNames(styles.formikFieldCapital, styles.inputMargin) }
                                        label="Capital per share"
                                        name="capitalPerShare"
                                        type="number" 
                                        prefix='£' 
                                        min='0' 
                                        onlyPositiveNumbers                                                                 
                                    />

                                    <Button
                                        disabled={!values.numberOfShares || !values.capitalPerShare || !values.name}
                                        variant='primary' 
                                        thin  
                                        marginTop
                                        onClick={nextStage}>
                                            Continue <Icon className={styles.nextArrow} name='arrowNext'/>
                                    </Button>
                                </> 
                            }
                            
                            {currentStage === "submit" &&
                                <>
                                    <Summary>
                                        <h2 className={styles.subheading}>Shares assigned to {persons[0].name}</h2>
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            name={'name'}
                                            label='Share class name'
                                            v8Summary
                                        />
                                        <Text
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            name={'numberOfShares'}
                                            label='Total number of shares'
                                            v8Summary
                                        />
                                        <Currency
                                            fixedWidth
                                            ensureRender
                                            entity={values}
                                            name={'capitalPerShare'}
                                            label='Capital per share'
                                            v8Summary
                                        />
                                    </Summary>

                                    <Submit
                                        disabled={isSubmitting} 
                                        onCancelClick={hide} 
                                        alignLeft
                                    />
                                </>
                            }
                    </Form>
                )}
                </Formik>
            }
      </BrandWrapper> 
    </SimpleFullscreen>
  )
}

export default CreateOrdinaryShareholdings