import { useEffect, useState } from 'react';
import styles from './SideBar.module.scss';
import { useInniAPI } from '../../hooks/useInniAPI';
import { PvSatr } from '../../api/inni/PvSatr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import { PvSatrSetupStatus } from '../../api/inni/data-contracts';
import { Link, useLocation, useHistory } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';

const UNRESTRICTED_NAV = false;

type PageProgress = {
    key: keyof PvSatrSetupStatus,
    label: string,
    completed: boolean,
    url: string
}

const initProgress: PageProgress[] = [
    {
        key: 'setupWelcomePage',
        label: 'Welcome',
        completed: false,
        url: 'SATR/welcome'
    },
    {
        key: 'setupBasicDetails',
        label: 'Basic details',
        completed: false,
        url: 'SATR/basic'
    },
    {
        key: 'setupIncome',
        label: 'Income',
        completed: false,
        url: 'SATR/income'
    },
    {
        key: 'setupProperty',
        label: 'Properties',
        completed: false,
        url: 'SATR/properties'
    },
    {
        key: 'setupFinancialRecords',
        label: 'Financial records',
        completed: false,
        url: 'SATR/financial-records'
    },
    {
        key: 'setupPropertyIncomePageData',
        label: 'Past balances',
        completed: false,
        url: 'SATR/past-balances'
    },
    {
        key: 'setupSubmitted',
        label: 'Submit',
        completed: false,
        url: 'SATR/submit'
    }
]

const SATRSideBarItems = ({cId}: {cId: number}) => {
    const pvSatrApi = useInniAPI(PvSatr);
    const [progress, setProgress] = useState<PageProgress[]>(initProgress);
    const [statusLoaded, setStatusLoaded] = useState(false);
    const location = useLocation();
    const history = useHistory();
    const isMobile = useIsMobile('userAgent');

    // Get latest page status
    useEffect(() => {
        if (cId && location && history && progress && pvSatrApi && !statusLoaded) {
            pvSatrApi.getCurrent(cId).then(res => {
                const responseData = res.data.progress;
                let redirectUrl = '';

                if (responseData) {
                    let updateProgress = [...progress];

                    for (let i = 0; i < updateProgress.length; i++) {
                        const page = updateProgress[i];
                        const pageCompleted = responseData[page.key];

                        page.completed = pageCompleted;

                        // set redirect path to the next page if completed
                        if (pageCompleted && i !== updateProgress.length - 1) {
                            redirectUrl = updateProgress[i + 1].url;
                        }
                    }
                    
                    setProgress(updateProgress);
                    setStatusLoaded(true);

                    if (location.pathname === `/company/${cId}/SATR/redirect`) {
                        if (isMobile) {
                            history.push(`/company/${cId}/mobilesupport`);
                        } else {
                            if (redirectUrl)
                                history.push(`/company/${cId}/${redirectUrl}`);
                            else 
                                history.push(`/company/${cId}/${progress[0].url}`);
                        }
                    }
                }

            }).catch((e) => {
                console.error(e.error);
            })
        }
    }, [cId, history, location, progress, pvSatrApi, statusLoaded, isMobile])
    
    // If the path changes re-pull latest page status
    useEffect(() => {
        setStatusLoaded(false);
    }, [location.pathname])

    return (
        <div id={styles.sideBarV8} className={styles.standaloneWizard}>
            <div className={classNames(styles.profile, styles.items)} style={{height: '100%'}}>
                { progress.map((entry, index) => (
                    <Link
                        key={entry.key}
                        className={classNames(
                            {
                                [styles.disabled]: !UNRESTRICTED_NAV && !entry.completed && index >= 1 && progress[index - 1].completed === false,
                                [styles.active]: location.pathname.includes(entry.url)
                            }
                        )}
                        to={`/company/${cId}/${entry.url}`}
                    >
                        <p>
                            { entry.completed && (
                                <FontAwesomeIcon
                                    icon={faCircleCheck}
                                    style={{marginRight: '8px'}}
                                    color='var(--button-primary)'
                                    size='lg'
                                />
                            )}
                            { !entry.completed && <span>{ `${index}. ` }</span> }
                            <span>{ entry.label }</span>
                        </p>
                    </Link>
                ))}
            </div>
        </div>
    )
}

export default SATRSideBarItems;