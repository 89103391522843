import React, { FC } from 'react';
import { Tabs } from 'react-bootstrap';
import styles from './PillTabs.module.scss';
import classNames from 'classnames';

type Props = {
	children: JSX.Element[] | JSX.Element;
	defaultActiveKey: string;
	id?: string;
	className?: string;
	onSelect: (k: string | null) => void;
};

const PillTabs: FC<Props> = ({ children, defaultActiveKey, id = 'pill-tab', className, onSelect }) => {
	return (
		<div className={styles.pillTabContainer} data-cy="financePillTabs">
			<Tabs
				key={defaultActiveKey}
				defaultActiveKey={defaultActiveKey}
				id={id} className={classNames(styles.pillTabs, className)}
				onSelect={onSelect ? (k) => onSelect(k) : undefined}
				fill
			>
				{children}
			</Tabs>
		</div>
	);
};

export default PillTabs;
