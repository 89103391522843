import React from "react";
import styles from "./ErrorPage.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "../../elements/Button/Button";
import { Container } from "react-bootstrap";
type Props = {};

function ErrorPage({}: Props) {
	return (
		<Container>
			<div className="d-flex d-flex justify-content-center ">
				<div className="m-4">
					<div className={styles.errorContentWrapper}>
						<p className="d-flex align-items-center ml-1">
							<FontAwesomeIcon icon={faExclamationTriangle} size="2x" className="mr-1" />
							<h3 className="mx-3 mb-1">Page not found</h3>
						</p>
						<hr className="mt0"></hr>
						<ul>
							<li>Unfortunately the page you were looking for could not be found.
								 It may be temporarily unavailable, moved or no longer exist.</li>
							<li>Check the URL you entered for any mistakes and try again. Alternatively, click back to navigate to previous page.</li>
						</ul>
						<div>
							<Button variant="primary" className="m-2" onClick={() => window.history.back()}>
								Back
							</Button>
						</div>
					</div>
				</div>
			</div>
		</Container>
	);
}

export default ErrorPage;
