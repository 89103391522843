import classNames from 'classnames';
import React, { useContext, useState } from 'react'
import { Property } from '../../api/inni/data-contracts';
import { Properties } from '../../api/inni/Properties';
import CompanyContext from '../../context/CompanyContext';
import { useInniAPI } from '../../hooks/useInniAPI';
import { FileUploadModal } from '../FileUploadModal/FileUploadModal'
import styles from './HeaderImageWithUpload.module.css'
import {placeholderImage} from '../../utils/formatters/formatProperty';

export interface HeaderImageWithUploadProps {
    property?: Property,
    reloadProperty?: () => void
}

export const HeaderImageWithUpload = ({property, reloadProperty}: HeaderImageWithUploadProps) => {
    const companyContext = useContext(CompanyContext);
    const propertiesApi = useInniAPI(Properties);

    const [showUpload, setShowUpload] = useState(false);
    const [uploading, setUploading] = useState(false);

    // External property images should be inserted into imageDocumetUrl to avoid missed use cases and less work - DD
    let propertyImage = placeholderImage;
    if (property?.imageDocumentUrl) {
        propertyImage = property.imageDocumentUrl;
    } else if (property?.externalImageUrl) {
        propertyImage = property.externalImageUrl;
    }
 
    const onUploadClick = () => {
        setShowUpload(true);
    }

    const onModalCancelClick = () => {
        setShowUpload(false);
    }

    const onFilesDropped = (files:File[]) => {
        if (propertiesApi && property?.id) {
            setUploading(true);
            propertiesApi.uploadImage(companyContext.cid, property.id, {fileToUpload: files[0]})
                .then(response => {
                    if (reloadProperty) reloadProperty();
                    setShowUpload(false);
                })
                .finally( () =>
                    setUploading(false)
                )    
        }
    }

    const deleteImage = () => {
        if (window.confirm('Are you sure you want to delete this image?')) {
            if (propertiesApi && property?.id) {
                propertiesApi.deleteImage(companyContext.cid, property.id)
                    .then(response => {
                        if (reloadProperty) reloadProperty();
                    })
            }    
        }
    }
 
    return (
        <div className={styles.outer}>
            {showUpload &&
                <FileUploadModal 
                    onCancelClick={onModalCancelClick}
                    onFilesDropped={onFilesDropped}
                    maxFiles={1}
                    uploading={uploading}
                    maxSizeMB={10}
                    accept={'images'}
                />
            }
            <img alt='Property' src={propertyImage} />
            <div className={classNames(styles.buttons, {[styles.hideButtons]: property?.imageDocumentUrl})}>
                
                { propertyImage === placeholderImage
                    ? (
                        <div className={styles.buttonFirstUpload} onClick={onUploadClick}>
                            Upload image
                        </div>
                    )
                    : (
                        <>
                            <div className={styles.buttonReplace} onClick={onUploadClick}>
                                Replace
                            </div>
                            <div className={styles.buttonDelete} onClick={deleteImage}>
                                Delete
                            </div>
                        </>
                    )
                }

            </div>
        </div>
    )
}