import { useContext, useEffect, useState } from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Calendar, dateFnsLocalizer, Event, NavigateAction, View } from 'react-big-calendar'
import {getDay, format, parse, startOfWeek} from 'date-fns'
import { enGB } from 'date-fns/locale';

import { Reminder } from '../../api/inni/data-contracts'
import { Reminders } from '../../api/inni/Reminders'
import { Modal } from '../../components/Modal/Modal';
import { useInniAPI } from '../../hooks/useInniAPI'
import CompanyContext from '../../context/CompanyContext'

const locales = {
  'en-gb': enGB
}

const localizer = dateFnsLocalizer({format,parse,startOfWeek,getDay,locales})

const PortfolioCalendar = () => {
  const remindersApi = useInniAPI(Reminders);
  const companyContext = useContext(CompanyContext)

  const [reminders, setReminders] = useState<Reminder[]>([]);
  const [remindersLoaded, setRemindersLoaded] = useState(false);
  const [activeReminder, setActiveReminder] = useState<Reminder|undefined>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [events, setEvents] = useState<Event[]>([])
  const [loadedDateFrom, setLoadedDateFrom] = useState<Date>()
  const [loadedDateTo, setLoadedDateTo] = useState<Date>()

  const updateRemindersAndEvents = (dateFrom:Date, dateTo: Date) => {
    remindersApi && remindersApi.calendar(companyContext.cid, {dateFrom: dateFrom.toDateString(), dateTo: dateTo.toDateString()}).then(response => {
      setReminders(response.data);
      !remindersLoaded && setRemindersLoaded(true);
      let newEvents:Event[] = []
      response.data.map((reminder) => {
        if(reminder.dueDate && !reminder.adminOnly) {
          newEvents.push({
            start: new Date(reminder.dueDate),
            end: new Date(reminder.dueDate),
            allDay: true,
            title: reminder.title
          })
        }
      })
      setEvents(newEvents)
    }).catch(error => {
        console.error(error)
    })
  }

  useEffect(() => {
    if (remindersApi) {
        const dateFrom = new Date
        dateFrom.setMonth(dateFrom.getMonth() - 1)
        dateFrom.setDate(1)

        const dateTo = new Date
        dateTo.setMonth(dateTo.getMonth() + 1)
        dateTo.setDate(1)

        setLoadedDateFrom(dateFrom)
        setLoadedDateTo(dateTo)

        updateRemindersAndEvents(dateFrom, dateTo)
    }
  }, [remindersApi, companyContext.cid])

  const handleEventClick = (event:Event) => {
    setActiveReminder(reminders.find(x => new Date(x.dueDate || '').toDateString() === event.start?.toDateString() && x.title === event.title))
    setShowModal(true)
  }

  const onNavigate = (newDate:Date, view:View, action:NavigateAction) => {
    let newDateToLoad = new Date(newDate)
    if(action === "NEXT") {
      newDateToLoad.setMonth(newDateToLoad.getMonth() + 1)
      if(loadedDateTo && newDateToLoad >= loadedDateTo) {
        loadedDateFrom && updateRemindersAndEvents(loadedDateFrom, newDateToLoad)
        setLoadedDateTo(newDateToLoad)
      }
    }
    if(action === "PREV") { 
      newDateToLoad.setMonth(newDateToLoad.getMonth() - 1)
      if(loadedDateFrom && newDateToLoad <= loadedDateFrom) {
        loadedDateTo && updateRemindersAndEvents(newDateToLoad, loadedDateTo)
        setLoadedDateFrom(newDateToLoad)
      }
    }
  }

  return (<div style={{padding: '14px', display: 'flex', flex: '1', flexDirection: 'column'}}>
      <Calendar 
        localizer={localizer}
        events={events}
        // views={['month']}
        onSelectEvent={event => handleEventClick(event)}
        startAccessor="start"
        endAccessor="end"
        onNavigate={onNavigate}/>

      <Modal
        title={activeReminder?.title || ''}
        showModal={showModal}
        hideModal={() => setShowModal(false)}
        showCloseBtn>
        <p>{activeReminder?.shortDescription}</p>
      </Modal>
    </div>)
}

export default PortfolioCalendar