import { Badge as BootstrapBadge } from 'react-bootstrap'
import styles from './Badge.module.css'

const Badge = ({color, children} : {color : string, children : any}) => {
    return (
        <BootstrapBadge pill className={styles.badge} style={{color: color, border: `1px solid ${color}`}}>
            {children}
        </BootstrapBadge>
    )
}

export default Badge