import { Pie } from '@nivo/pie'
import { ChartData2, ChartDataPoint } from '../../../api/inni/data-contracts'
import { PieChartValue } from '../../../elements/DashboardCards/PieChart'
import { formatCurrency0dp } from '../../../utils/formatNumbers'
import styles from '../Dashboard.module.scss'
import { useEffect, useState } from 'react'

const pieColours = [
    'var(--account-color-1)',
    'var(--account-color-2)',
    'var(--account-color-3)',
    'var(--account-color-5)',
    'var(--account-color-6)',
    'var(--account-color-7)',
    'var(--account-color-8)',
    'var(--account-color-9)',
    'var(--account-color-10)',
    'var(--account-color-101)',
    'var(--account-color-102)',
    'var(--account-color-201)',
    'var(--account-color-202)',
    'var(--account-color-4)',
]

const IncomeExpenses = ({chartData} : {chartData : ChartData2}) => {

    const [colorMap, setColorMap] = useState<{[key:string]:string}>({})

    const getPieDataFromDataPoint = (input : ChartDataPoint[]) => {

        let data : PieChartValue[] = []

        input.map(x => data.push({id: x.label || '', value: x.value}))
        //if same category has multiple values, add them together
        let returnData : PieChartValue[] = []
        data.map(x => {
            let found = returnData.find(y => y.id === x.id)
            if (found) {
                found.value += x.value
            } else {
                returnData.push(x)
            }
        })
        return returnData;

    }
    //assigning same colours to the pie chart of same category
    const mapColorFunc = (input : PieChartValue[]) => {
    let colorTable:{[key:string]:string} = {};
       //if colors are not enough, repeat the colours
         let i = 0;
        input.map(x => {
                if (colorTable[x.id]) {
                    return;
                }
                colorTable[x.id] = pieColours[i];
                i++;
                if (i === pieColours.length) {
                    i = 0;
                }
            })
        setColorMap(colorTable);
    }

    useEffect(() => {
        //merge income and expenses then assign colours
        let dataColor = chartData?.income?.concat(chartData.expenses || []);
        //remove duplicates
        let dataColor2 = dataColor?.filter((v,i,a)=>a.findIndex(t=>(t.label === v.label))===i);
        mapColorFunc(getPieDataFromDataPoint(dataColor2 || []));
    }, [chartData.expenses, chartData?.income]);

    return (
        <div className={styles.incomeExpensesContainer}>
            <div className={styles.incomeExpensesItem}>
                <p><b>Income</b></p>
                <p>{chartData.dateRange}</p>
                <Pie 
                    data={getPieDataFromDataPoint(chartData.income || [])}
                    enableRadialLabels={false}
                    enableSliceLabels={false}
                    margin={{ right: 10, bottom: 10, left: 10 }}
                    height={250}
                    width={250}
                    isInteractive
                    colors={ d => colorMap[d.id]}
                    //colors={ pieColours }
                    tooltip={(d) =>
                        <div className={styles.incomeExpensesTooltip}>
                            <div className={styles.legendIcon} style={{background: d.datum.color}}></div>
                            <b>{d.datum.label}:</b> {formatCurrency0dp(d.datum.value)}
                        </div>
                    }
                />
                <div className={styles.legend}>
                    {
                        getPieDataFromDataPoint(chartData.income || []).map((entry, i) => {
                            return (
                                <div key={entry.id}>
                                    <div className={styles.legendIcon} style={{background: colorMap[entry.id]}}></div>
                                    { entry.id }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={styles.incomeExpensesItem}>
                <p><b>Expenses</b></p>
                <p>{chartData.dateRange}</p>
                <Pie 
                    data={getPieDataFromDataPoint(chartData.expenses || [])}
                    enableRadialLabels={false}
                    enableSliceLabels={false}
                    margin={{ right: 10, bottom: 10, left: 10 }}
                    height={250}
                    width={250}
                    isInteractive
                    colors={ d => colorMap[d.id] }
                    tooltip={(d) => 
                        <div className={styles.incomeExpensesTooltip}>
                            <div className={styles.legendIcon} 
                            style={{background: d.datum.color}}
                            ></div>
                            <b>{d.datum.label}:</b> {formatCurrency0dp(d.datum.value)}
                        </div>
                    }
                />
                <div className={styles.legend}>
                    {
                        getPieDataFromDataPoint(chartData.expenses || []).map((entry, i) => {
                            return (
                                <div key={entry.id}>
                                    <div className={styles.legendIcon} style={{background: colorMap[entry.id]}}></div>
                                    { entry.id }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default IncomeExpenses