import { Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import styles from '../Help.module.scss';
import { Button } from '../../../elements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { placeholderImage } from '../../../utils/formatters/formatProperty';


interface ContactCardProps {
  title: string,
  name: string,
  photoUrl: string,
  canUpgrade: boolean,
  consultations?: number,
  consultationLink?: string | null,
  isMobile: boolean,
}

export const ContactCardPlaceholder = () => {
  return (
    <Card className={styles.contactCard}>
      <Card.Img variant='top' src={placeholderImage} />
      <Card.Body>
        <Card.Title>
          <LoadingPlaceholder width='80%' />
          <br />
          <br />
          <LoadingPlaceholder width='40%' />
        </Card.Title>
        <Card.Text>
          <LoadingPlaceholder width='100%' />
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export const ContactCard = ({title, name, photoUrl, canUpgrade, consultations, consultationLink, isMobile}: ContactCardProps) => {

  const renderTooltip = (props:any) => (
    <Tooltip id="button-tooltip" {...props}>
      {`For a personal ${title.toLowerCase()} speak to us about upgrading your package`}
    </Tooltip>
  );

  const navigateToConsultation = () => {
    if (consultationLink) {
      window.open(consultationLink, '_blank');
    } else {
      console.warn('Failed to get consultation link');
    }
  };

  return (
    <Card className={styles.contactCard}>
      <Card.Img
        variant="top"
        src={photoUrl}
      />
      <Card.Body>
        <Card.Title>
          <b>{ name }</b>
          { canUpgrade && (
            <OverlayTrigger placement='top' overlay={renderTooltip}>
              <FontAwesomeIcon icon={faInfoCircle} className={styles.infoCircle} />
            </OverlayTrigger>
          )}
          <br />
          {/* If we don't have an assigned manager/accountant don't print title as it's the name */}
          { (!name.includes('Account Manager') && !name.includes('Accountant')) && title }
        </Card.Title>
        <Card.Text>
          { (title === 'Accountant' && consultationLink && consultations !== undefined) && (
            <p className="text-muted">
              { consultations > 0 
                ? `You have ${consultations} consultations remaining in your package.`
                : 'You have no consultations remaining in your package, you can still book one for a fee.'
              }
            </p>
          )}
        </Card.Text>
        { (title === 'Accountant' && consultationLink) && (
          <div className="d-flex">
            <Button
              variant="positive"
              onClick={navigateToConsultation}
              mobile={isMobile}
            >
              Book a consultation
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}
