import { useState } from 'react'
import { PieChartValue } from '../../../elements/DashboardCards/PieChart';
import { PieCustomLayerProps, PieLayer, Pie, ResponsivePie } from '@nivo/pie';
import { getPieChartData, LiveCashTree } from './LiveCashFunctions';
import { formatCurrency, formatCurrency0dp } from '../../../utils/formatNumbers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEquals, faMinus, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { Card } from 'react-bootstrap';
import { LoadingPlaceholderPie } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import styles from '../Dashboard.module.scss';
import configHelper from '../../../utils/configHelper';
import classNames from 'classnames';

type ActiveSection = 'AS' | 'LB'

export const LiveCashPiesLoading = () => {
    const isMobile = configHelper.useMobile;

    return (
        <div style={{display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column', marginBottom: '20px'}}>
            <div style={{width: '600px', maxWidth: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div className={styles.pieContainer} style={{height: isMobile ? '100px' : ''}} >
                    <LoadingPlaceholderPie />
                </div>
                <FontAwesomeIcon icon={faMinus} />
                <div className={styles.pieContainer} style={{height: isMobile ? '100px' : ''}} >
                    <LoadingPlaceholderPie />
                </div>
                <FontAwesomeIcon icon={faEquals} />
                <div className={styles.pieContainer} style={{height: isMobile ? '100px' : ''}} >
                    <LoadingPlaceholderPie />
                </div>
                
            </div>
        </div>
    )
}

const LiveCashPieView = ({nestedData, isLoaded, toggleDisabled} : {nestedData : LiveCashTree | undefined, isLoaded : boolean, toggleDisabled : (item: LiveCashTree | undefined, i : string) => void}) => {

    const [active, setActive] = useState<ActiveSection | undefined>(undefined)

    const [secondLevelActive, setSecondLevelActive] = useState<string | undefined>(undefined)

    const isMobile = configHelper.useMobile;

    const getColor = (dataPoint: PieChartValue) : string  => {
        if (dataPoint.colorName) return `var(--${dataPoint.colorName})`;
        if (dataPoint.color) return dataPoint.color || '';
        return ''         
    }

    const layers = () : PieLayer<PieChartValue>[] | undefined => {
        return ['slices', 'sliceLabels', 'radialLabels', 'legends', CenteredMetric]
    }

    const CenteredMetric = ({ dataWithArc , centerX , centerY  }: PieCustomLayerProps<any>)  => {
        if (!dataWithArc || dataWithArc.length === 0) {
            return (<></>);
        }
        let label = dataWithArc[0].label || 'CA'
    
        return (<>
            <text
                x={centerX}
                y={isMobile ? centerY : centerY+15}
                textAnchor="middle"
                dominantBaseline="central"
                // className="text-muted"
                style={{
                    fontSize: isMobile ? '20px' : '15px',
                    fill: isMobile ? '#000' : '#b0b0b0' 
                }}
            >
                { label === 'CA'
                    ? nestedData?.cashItem?.valueIsNegative
                        ? (isMobile ? '' : 'OVERSPENT')
                        : (isMobile ? '' : 'AVAILABLE')
                    : label === 'AS'
                        ? (isMobile ? 'In' : 'IN')
                        : (isMobile ? 'Out' : 'OUT')
                }
            </text>
            {!isMobile && <>
            <text
                data-cy="pieTotal"
                x={centerX}
                y={centerY-10}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '20px',
                    fill: '#616161',
                    borderBottom: '1px dotted',
                }}
            >{label === 'CA' ? formatCurrency0dp(nestedData?.cashItem?.valueToDisplay) : formatCurrency0dp(nestedData?.children?.find(x => x.key === label)?.cashItem?.valueToDisplay)}</text>
            <line x1={centerX-40} x2={centerX+40} y1={centerY+4} y2={centerY+4} stroke="#616161" strokeWidth="0.5" strokeLinecap="round" strokeDasharray="1, 1.5"/></>}
        </>)
    }

    const pieDefaults = {
        innerRadius: isMobile ? 0.75 : 0.85,
        enableRadialLabels: false,
        enableSliceLabels: false,
        tooltip: () => <></>,
        layers: layers(),
    };

    const pieDefaultsSize = {
        ...pieDefaults,
        height: 150,
        width: 150,
    };
    
    return (
        <div style={{display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column', marginTop: '20px', marginBottom: '20px'}}>
        {nestedData && <>
            <div style={{width: '600px', maxWidth: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
                <div data-cy="asChart" className={classNames({[styles.pieContainer]: true, [styles.pieContainerMobile]: isMobile})}  onClick={() => {setActive('AS'); setSecondLevelActive(undefined)}} >
                    { isMobile
                        ? (
                            <ResponsivePie
                                data={getPieChartData("AS", nestedData)}
                                colors={(datum) => getColor(datum.data)}
                                onClick={() => setActive('AS')}
                                {...pieDefaults}
                            />
                        )
                        : (
                            <Pie
                                data={getPieChartData("AS", nestedData)}
                                colors={(datum) => getColor(datum.data)}
                                onClick={() => setActive('AS')}             
                                { ...pieDefaultsSize }
                            />
                        )
                    }
                </div>

                <FontAwesomeIcon fontWeight="800" icon={faMinus} />

                <div className={classNames({[styles.pieContainer]: true, [styles.pieContainerMobile]: isMobile})} onClick={() => {setActive('LB'); setSecondLevelActive(undefined)}} >
                    { isMobile 
                        ? (
                            <ResponsivePie
                                data={getPieChartData("LB", nestedData)}
                                colors={(datum) => getColor(datum.data)}
                                onClick={() => setActive('LB')}
                                { ...pieDefaults }
                            />
                        )
                        : (
                            <Pie
                                data={getPieChartData("LB", nestedData)}
                                colors={(datum) => getColor(datum.data)}
                                onClick={() => setActive('LB')}               
                                { ...pieDefaultsSize }
                            />
                        )
                    }
                </div>

                <FontAwesomeIcon fontWeight="800" icon={faEquals} />

                <div className={classNames({[styles.pieContainer]: true, [styles.pieContainerMobile]: isMobile})} onClick={() => {setActive(undefined); setSecondLevelActive(undefined)}} >
                    { isMobile
                        ? (
                            <ResponsivePie
                                data={getPieChartData("CA", nestedData)}
                                colors={(datum) => getColor(datum.data)}
                                onClick={() => setActive(undefined)}
                                { ...pieDefaults }
                            />

                        )
                        : (
                            <Pie
                                data={getPieChartData("CA", nestedData)}
                                colors={(datum) => getColor(datum.data)}
                                onClick={() => setActive(undefined)}                
                                { ...pieDefaultsSize }
                            />

                        )
                    }
                </div>
                
            </div>

            {isMobile && <div style={{width: '100%', marginBottom: '20px'}}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>                    
                    <div style={{textAlign: 'center', width: '33%', maxWidth: '200px'}}>                        
                        <h5>{formatCurrency0dp(nestedData?.children?.find(x => x.key === "AS")?.cashItem?.valueToDisplay)}</h5>
                    </div>

                    <div style={{textAlign: 'center', width: '33%', maxWidth: '200px'}}>                        
                        <h5>{formatCurrency0dp(nestedData?.children?.find(x => x.key === "LB")?.cashItem?.valueToDisplay)}</h5>
                    </div>

                    <div style={{textAlign: 'center', width: '33%', maxWidth: '200px'}}>                        
                        <h5 style={{color: nestedData?.cashItem?.valueIsNegative ?  'var(--livecash-negative)' : 'var(--livecash-available)'}}>{formatCurrency0dp(nestedData?.cashItem?.valueToDisplay)}</h5>
                    </div>
                </div>
            </div>}

            {active &&
            <Card style={{ marginTop: '20px', width: '95%', padding: '20px'}}>
                <div data-cy="pieBreakdown" className={styles.breakdownPieContainer}>
                    {isMobile && (
                        <FontAwesomeIcon
                            icon={faTimesCircle}
                            size="2x"
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                color: 'var(--grey-icon-color)',
                                cursor: 'pointer',
                            }}
                            onClick={() => setActive(undefined)}
                        />
                    )}
                    { active &&
                        nestedData.children?.find(x => x.key === active)?.children?.map(x => {
                            return(
                            <div key={x.key} style={{display: 'flex', flexDirection: 'column', flexBasis: '0', marginBottom: 'auto', textAlign: 'center', width: '150px'}}>
                                <div data-cy="secondLvlPie" onClick={() => setSecondLevelActive(x.key)} className={styles.breakdownPie}>
                                    <Pie
                                        data={getPieChartData(x.key || 'SI', nestedData.children?.find(x => x.key === active) || nestedData, active)}
                                        innerRadius={0.8}
                                        height={75}
                                        width={75}
                                        enableRadialLabels={false}
                                        enableSliceLabels={false}
                                        colors={(datum) => getColor(datum.data)}
                                        tooltip={() => <></>}
                                    />
                                </div>

                                <div className="p-0 mt-1 mb-3 w-100">
                                    <p className="p-0 m-0" style={{fontWeight: !isMobile && secondLevelActive === x.key ? 'bold' : undefined}}>
                                        {formatCurrency(x.cashItem?.valueToDisplay)}
                                    </p>
                                    <p className="px-2 py-0 m-0" style={{fontSize: '12px'}}>
                                        {x.cashItem?.name}
                                    </p>
                                </div>

                            </div>)
                        })
                    }
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    { !isMobile && secondLevelActive && active &&
                        <table className="w-100 mt-0">
                            <thead>
                                <th style={{width: '60%'}}></th>
                                <th style={{width: '20%'}}><i>Amount</i></th>
                                <th style={{width: '20%'}}><i>Exclude</i></th>
                            </thead>
                            <tbody>
                                { 
                                    nestedData.children?.find(x => x.key === active)?.children?.find(x => x.key === secondLevelActive)?.children?.map(x => {
                                        return (
                                        <tr data-cy="itmRow" key={x.key}>
                                            <td>{x.cashItem?.name}</td>
                                            <td data-cy="amount">{formatCurrency0dp((x.cashItem?.valueToDisplay || 0) + (x.cashItem?.valueOfDisabled || 0))}</td>
                                            <td><input type="checkbox" checked={!x.cashItem?.enabled} onChange={ () => { toggleDisabled(x, secondLevelActive) } } /></td>
                                        </tr>)
                                    })
                                
                                }
                                <tr>
                                    <th>Total</th>
                                    <th>{formatCurrency0dp( 
                                        (nestedData.children?.find(x => x.key === active)?.children?.find(x => x.key === secondLevelActive)?.cashItem?.valueToDisplay || 0) +
                                        (nestedData.children?.find(x => x.key === active)?.children?.find(x => x.key === secondLevelActive)?.cashItem?.valueOfDisabled || 0))}</th>
                                    <th><input type="checkbox" 
                                        checked={!nestedData.children?.find(x => x.key === active)?.children?.find(x => x.key === secondLevelActive)?.cashItem?.enabled} 
                                        onChange={ () => { toggleDisabled(nestedData.children?.find(x => x.key === active)?.children?.find(x => x.key === secondLevelActive), secondLevelActive) } } 
                                    /></th>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div>

            </Card>}
        </>}</div>
    )

}

export default LiveCashPieView