import { useContext, useState, useEffect } from "react";
import { Adjustments } from "../../api/inni/Adjustments";
import { AccountWithBalance, Adjustment } from "../../api/inni/data-contracts";
import CompanyContext, { CompanyProduct } from "../../context/CompanyContext";
import { Button } from "../../elements/Button/Button";
import { useInniAPI } from "../../hooks/useInniAPI";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout";
import Toolbar from "../../layouts/Desktop/Toolbar";
import { Entity, Action } from "../../utils/EntityAction";
import { asHtml5Date, asYearMonthDay, formatDate } from "../../utils/formatDate";
import { Accounts } from "../../api/inni/Accounts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { AdjustmentsTable } from "./AdjustmentsTable";
import { FormikErrors, FormikHelpers } from "formik";
import { useModalDialog } from "../../hooks/useModalDialog";
import * as DataGrid from "../../elements/DataGrid/DataGrid";
import classNames from "classnames";
import InfoBanner from "../../elements/InfoBanner/InfoBanner";
import { ExpandedViewBackLabel } from "../../elements/ExpandedViewBackLabel/ExpandedViewBackLabel";
import styles from "./Adjustments.module.scss";

export interface ReadPageProps {
    id: number|undefined|null,
    onCompletion: () => void
}

export const AdjustmentReadPage = ({id, onCompletion} : ReadPageProps) => {
    const adjustmentsApi = useInniAPI(Adjustments, [400])
    const accountsApi = useInniAPI(Accounts, [400])
    const companyContext = useContext(CompanyContext)

    const journalHeaderId = id ? id.toString() : "";

    const [adjustments, setAdjustments] = useState<Adjustment[]>()
    const [adjustmentsLoaded, setAdjustmentsLoaded] = useState(false);

    const [accounts, setAccounts] = useState<AccountWithBalance[]>()
    const [accountsLoaded, setAccountsLoaded] = useState(false);

    const [descriptionIsValid, setDescriptionIsValid] = useState(true);
    const [dateIsValid, setDateIsValid] = useState(true);
    const [lineFeedback, setLineFeedback] = useState<string|null|undefined>();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [adjustmentsUpdated, setAdjustmentsUpdated] = useState(false);

    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [description, setDescription] = useState('');
    const [adjustmentsReadOnly, setAdjustmentsReadOnly] = useState<boolean>()
    const [showModalDialog, modalDialog] = useModalDialog();

    const v8Styling = companyContext.company?.useV8UI || false;

    useEffect(() => {
        if (!adjustmentsLoaded && adjustmentsApi && !accountsLoaded && accountsApi) {
            if(journalHeaderId !== "") {
                adjustmentsApi.get(companyContext.cid, parseInt(journalHeaderId)).then(
                    response => {
                        setAdjustments(response.data.lines);
                        if (response.data.date)
                            setDate(response.data.date.split("T")[0])

                        if (response.data.description) {
                            setDescription(response.data.description || '');
                            setTitle(response.data.description || 'Adjustment');
                        }
                        setAdjustmentsReadOnly(response.data.readOnly)
                        setAdjustmentsLoaded(true);

                        accountsApi.listWithBalances(companyContext.cid, {date: response.data.date}).then(response => {
                            let tempAccounts = response.data
                            setAccounts(tempAccounts.filter(x => !x.isOnBankFeed));
                            setAccountsLoaded(true)
                        }).catch(e => {
                            console.error(e)
                        })
                    }
                )
            } else {
                const today = asHtml5Date(asYearMonthDay(new Date())) || ""
                setDate(today)
                setAdjustments([]);
                setAdjustmentsLoaded(true);
                accountsApi.listWithBalances(companyContext.cid, {date: today}).then(response => {
                    let tempAccounts = response.data
                    setAccounts(tempAccounts.filter(x => !x.isOnBankFeed));
                    setAccountsLoaded(true)
                }).catch(e => {
                    console.error(e)
                })
            }
        }
    }, [adjustmentsApi, adjustmentsLoaded, accountsApi, accountsLoaded, companyContext.product])

    //Update description after user finishes input
    const updateAndValidateDescription = () => {
        if(adjustments && adjustments.length > 0 && adjustmentsLoaded) {
            setAdjustmentsUpdated(true)
            validateAndUpdateLines(adjustments)
        }
      }

    //Update date after user finishes input
    const reloadAccountsForDate = () => {
        if(adjustments && adjustments.length > 0 && adjustmentsLoaded) {
            setAdjustmentsUpdated(true)
            validateAndUpdateLines(adjustments)
        }
        reloadBalances()
    }

      const reloadBalances = () => {
        //Get accounts for date
        accountsApi && accountsApi.listWithBalances(companyContext.cid, {date: date}).then(response => {
            let tempAccounts = response.data
            setAccounts(tempAccounts.filter(x => !x.isOnBankFeed));
            !accountsLoaded && setAccountsLoaded(true);

            const latestAccounts = tempAccounts //Avoid race condition
            if(adjustments) {
                const adjustmentsUpdatedBalances = adjustments.map(adj => {
                    const relatedAcc = latestAccounts.find(x => x.id === adj.accountId)
                    return {...adj, openingBalance: getOpeningBalance(adj, relatedAcc), closingBalance: getClosingBalance(adj, relatedAcc), date: date}
                })
                setAdjustments(adjustmentsUpdatedBalances)
            }
        })
      }
    

    const sendAdjustments = () => {
        //One network call to rule them all...
        //Will add, update and delete lines all at once!
        //In the api => Existing Id: Update, Id of 0 or less: Create, Missing Id: delete
        if (adjustmentsApi && !isSubmitting) {
            setIsSubmitting(true)
            if(adjustments && !isNew) {
                const adjHeader = {date: date, description: description, journalHeaderId: parseInt(journalHeaderId), lines: adjustments}
                adjustmentsApi.update(companyContext.cid, parseInt(journalHeaderId), adjHeader)
                    .then(res => {
                        if(res.status !== 200)
                            console.warn("Unexpected response code " + res.status)
                        res.status === 200 && onCompletion();
                    })
                    .catch(error => {
                        console.error(error)
                    }).finally(() => {
                        setIsSubmitting(false)
                    })
            } else if(adjustments) {
                const adjHeader = {date: date, description: description, journalHeaderId: -1, lines: adjustments}
                adjustmentsApi.create(companyContext.cid, adjHeader)
                .then(res => {
                    if(res.status !== 201)
                        console.warn("Unexpected response code " + res.status)
                        
                    res.status === 201 && onCompletion();
                })
                .catch(error => {
                    console.error(error)
                }).finally(() => {
                    setIsSubmitting(false)
                })
            }
        }
        else {
            if(!adjustmentsApi) console.error("API not found")
            if(isSubmitting) console.warn("Aleady submitting")
        }
    }

    const updateAdjustment = (value: Adjustment, actions: FormikHelpers<Adjustment>, id: number) : Promise<boolean>=> {
        return new Promise((resolve, reject) => {
            if(adjustments) {
                !adjustmentsUpdated && setAdjustmentsUpdated(true)
                const newAdjustments = [...adjustments.map(adj => adj.id === value.id ? 
                    {...value, accountId: parseInt(value.accountId.toString()), date: date, openingBalance: getOpeningBalance(value),
                        closingBalance: getClosingBalance(value), description: description, journalHeaderId: !isNew ? parseInt(journalHeaderId) : -1} : adj)]
                //TODO balances
                validateAndUpdateLines(newAdjustments)
            }
            resolve(true)
        })
    }

    const addNewAdjustment = (value: Adjustment, actions: FormikHelpers<Adjustment>) : Promise<number>=> {
        return new Promise((resolve, reject) => {
            if(adjustments) {
                !adjustmentsUpdated && setAdjustmentsUpdated(true)
                const tempId = -Math.abs(adjustments.length) //We use a negative number so that we can identify and edit new rows before sending them
                const newAdjustments = [...adjustments, 
                    {...value, accountId: parseInt(value.accountId.toString()), date: date, openingBalance: getOpeningBalance(value),
                        closingBalance: getClosingBalance(value), description: description, id: tempId, journalHeaderId: !isNew ? parseInt(journalHeaderId) : -1}] //-1 new journalheader
                //TODO balances
                validateAndUpdateLines(newAdjustments)
            }
            resolve(1)
        })
    }

    const getOpeningBalance = (adj:Adjustment, newAcc?:AccountWithBalance) => {        
        const relatedAcc = newAcc || accounts && accounts.find(x => x.id === parseInt(adj.accountId.toString()))
        if(relatedAcc && relatedAcc.balance !== undefined) {
            return relatedAcc.balance - (getPolarity(relatedAcc.accountGroup || "") * (adj.amountOriginal || 0))
        }
        
        return 0
    }

    const getClosingBalance = (adj:Adjustment, newAcc?:AccountWithBalance) => {
        const relatedAcc = newAcc || accounts && accounts.find(x => x.id === parseInt(adj.accountId.toString()))
        if(relatedAcc && relatedAcc.balance !== undefined) {
            const amount = (adj.debit || 0) - (adj.credit || 0)
            return getOpeningBalance(adj, relatedAcc) + (getPolarity(relatedAcc.accountGroup || "") * amount)
        }

        return 0
    }

    const removeAdjustment = (id: number) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if(adjustments) {
                !adjustmentsUpdated && setAdjustmentsUpdated(true)
                let newRows = [...adjustments]
                newRows.splice(adjustments.findIndex(x => x.id === id), 1)
                //TODO balances
                validateAndUpdateLines(newRows)
            }
            resolve();
        })
    }

    const validateAndUpdateLines = (newAdjustments:Adjustment[]) => {
        setAdjustments(newAdjustments)
        if(adjustmentsApi) {
            if(isNew) {
                const adjHeader = {date: date, description: description, journalHeaderId: -1, lines: newAdjustments}
                adjustmentsApi.validateCreate(companyContext.cid, -1, adjHeader).then(res => {
                    if(res.status === 200) {
                        !descriptionIsValid && setDescriptionIsValid(true)
                        !dateIsValid && setDateIsValid(true)
                        lineFeedback && setLineFeedback(null)
                    }
                }).catch(error => {
                    setDescriptionIsValid(!error.error.description)
                    setDateIsValid(error.error.date === null || error.error.date === undefined)
                    setLineFeedback(error.error.lines)
                    console.error(error)
                })
            } 
            else {
                const adjHeader = {date: date, description: description, journalHeaderId: parseInt(journalHeaderId), lines: newAdjustments}
                adjustmentsApi.validateUpdate(companyContext.cid, parseInt(journalHeaderId), adjHeader).then(res => {
                    if(res.status === 200) {
                        !descriptionIsValid && setDescriptionIsValid(true)
                        !dateIsValid && setDateIsValid(true)
                        lineFeedback && setLineFeedback(null)
                    }
                }).catch(error => {
                    setDescriptionIsValid(!error.error.description)
                    setDateIsValid(error.error.date === null || error.error.date === undefined)
                    setLineFeedback(error.error.lines)
                    console.error(error)
                })
            }
        }
    }

    const validateCreate =  (value: Adjustment) : Promise<FormikErrors<Adjustment>> => {
        return new Promise((resolve, reject) => { resolve({}) })
    }

    const validateUpdate =  (id:number, value: Adjustment) : Promise<FormikErrors<Adjustment>> => {
        return new Promise((resolve, reject) => { resolve({}) })
    }

    const isNew = journalHeaderId === ""
    const isReadOnly = (adjustments && adjustments[0]) ? (adjustmentsReadOnly || false) : isNew ? false : true
    const financialYearStart = companyContext.company ? companyContext.company.currentFinancialYearStartDate : ""

    const handleComplete = () => {
        if(description !== "" && date !== "") {
            if(isReadOnly || !adjustmentsUpdated) {
                onCompletion()
            } else {
                sendAdjustments()
            }
        }
    }

    const nextDayReversal = () => {
        if(adjustments && adjustmentsApi) {
            adjustmentsApi.nextDayReversal(companyContext.cid, parseInt(journalHeaderId)).then(res => {
                if(res.status !== 200)
                    console.warn("Unexpected response code " + res.status)

                res.status === 200 && onCompletion();
            })
        }
    }

    const deleteJournalHeader = () => {
        if(adjustments && adjustmentsApi) {
            adjustmentsApi.delete(companyContext.cid, parseInt(journalHeaderId)).then(res => {
                if(res.status !== 200)
                    console.warn("Unexpected response code " + res.status)

                res.status === 200 && onCompletion();
            })
        }
    }

    const getPolarity = (accGroup:string) => {
        if(["Liabilities", "Income", "Capital"].includes(accGroup)) {
            return -1
        } else {
            return 1
        }
    }

    const showDeleteDialog = () => {
        showModalDialog(
            "Delete adjustment?",
            "Are you sure you want to delete this adjustment?",
            [
                <Button variant="danger" label="Yes" onClick={() => deleteJournalHeader()} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const showReversalDialog = () => {
        showModalDialog(
            "Create a next day reversal?",
            "Are you sure you want to create a next day reversal?",
            [
                <Button variant="primary" label="Yes" onClick={nextDayReversal} />,
                <Button variant="primary" outline label="No" onClick={() => {}} />,
            ],
            false
        );
    }
    
    return (
        <DefaultLayout
            entity={Entity.AdjustmentV7}
            title={!v8Styling ? 'Adjustments' : undefined}
            greyBackground
            backIcon={!v8Styling}
            backFnc={onCompletion}
            subLayout={v8Styling}
        >
            { (!isNew && !v8Styling) && (
                <Toolbar>
                    <div className="ml-3">
                        <Button
                            onClick={showReversalDialog}
                            entity={Entity.AdjustmentV7}
                            action={Action.Create}
                            buttonType="new" children={<><FontAwesomeIcon icon={faPlus}/>Next day reversal</>}
                        />

                        <Button
                            onClick={showDeleteDialog}
                            entity={Entity.AdjustmentV7}
                            action={Action.Delete}
                            buttonType={"delete"}
                            disabled={isReadOnly}
                        />
                    </div>
                </Toolbar>
            )}

            { v8Styling && (
                <ExpandedViewBackLabel backLabel="Adjustments" title={title || ' '} onClick={onCompletion}>
                    <Toolbar>
                        <Button
                            onClick={showReversalDialog}
                            entity={Entity.AdjustmentV7}
                            action={Action.Create}
                            buttonType="new"
                            children={<><FontAwesomeIcon icon={faPlus}/>Next day reversal</>}
                            headerBtn
                        />

                        <Button
                            onClick={showDeleteDialog}
                            entity={Entity.AdjustmentV7}
                            action={Action.Delete}
                            buttonType={"delete"}
                            disabled={isReadOnly}
                            headerBtn
                        />
                    </Toolbar>
                </ExpandedViewBackLabel>
            )}

            {accounts && accounts.filter(x => x.currency !== "GBP" && x.currency !== null).length > 0 && <InfoBanner 
                    title="Foreign currency bank accounts" 
                    body={<>
                        <p style={{marginBottom: "0.5rem"}}>You cannot use manual adjustments on foreign currency bank accounts.</p>
                        <p style={{marginBottom: "0.5rem"}}>The main use of this feature was to revalue the account at the yearend and journal the forex loss.</p>
                        <p style={{marginBottom: "0.5rem"}}>The system now creates a revaluation journal at yearend as soon as the yearend is passed and it is maintained until the YE is closed. No need for a manual adjustment</p>
                    </>}
                    type="info"
                />}
            
            {adjustments && accounts && (
                <div style={{marginBottom: "18rem"}}> {/* Margin for dropdown */}
                    <div style={{display: "flex"}}>
                        <div style={{display: "inline-block", marginRight: "2rem", marginBottom: "1rem"}}>
                            <label className={styles.fieldLabel}>Date</label>
                            { isReadOnly && !isNew ? <p>{formatDate(date)}</p> :
                                <input
                                    required
                                    min={asHtml5Date(financialYearStart)}
                                    className={classNames(["form-control", !dateIsValid && "is-invalid"])}
                                    style={{paddingLeft: "0.571429rem"}}
                                    type="date"
                                    value={asHtml5Date(date)}
                                    onChange={e => {setDate(e.target.value); setAdjustmentsUpdated(true)}}
                                    onBlur={reloadAccountsForDate}
                                />}
                                {!dateIsValid && <small style={{display: "block"}} className="invalid-feedback">Required</small>}
                        </div>
                        <div style={{display: "inline-block", maxWidth: "25rem", width: "100%", marginBottom: "1rem"}}>
                            <label className={styles.fieldLabel}>Description</label>
                            { isReadOnly && !isNew ? <p>{description}</p> :
                                <input
                                    required
                                    className={classNames(["form-control", !descriptionIsValid && "is-invalid"])}
                                    style={{paddingLeft: "0.571429rem"}}
                                    onChange={e => {setDescription(e.target.value); setAdjustmentsUpdated(true)}}
                                    value={description}
                                    onBlur={updateAndValidateDescription}
                                />
                            }
                            {!descriptionIsValid && <small style={{display: "block"}} className="invalid-feedback">Required</small>}
                        </div>
                    </div>

                    <AdjustmentsTable
                        adjustments={adjustments}
                        adjustmentsLoaded={adjustmentsLoaded}
                        accounts={accounts}
                        isReadOnly={isReadOnly}
                        onCreate={addNewAdjustment}
                        onUpdate={updateAdjustment}
                        onDelete={removeAdjustment}
                        validateCreate={validateCreate}
                        validateUpdate={validateUpdate}
                    />

                    { lineFeedback && (
                        <small style={{display: "block", marginBottom: "1rem", marginTop: "0"}} className="invalid-feedback">{lineFeedback}</small>
                    )}

                    {/* { !isReadOnly && adjustmentsUpdated && ( */}
                    { !isReadOnly && (
                        <div className="mt-5">
                            <Button
                                onClick={handleComplete}
                                entity={Entity.AdjustmentV7}
                                action={Action.List}
                                buttonType="save"
                                disabled={isSubmitting}
                            />

                            <Button
                                onClick={onCompletion}
                                entity={Entity.AdjustmentV7}
                                action={Action.List}
                                buttonType={"cancel"} 
                            />
                        </div>
                    )}
                </div>
            )}

            { (!accountsLoaded || !adjustmentsLoaded) &&
                <DataGrid.Table>
                    <tbody>
                        <DataGrid.LoadingRow cols={5}/>
                        <DataGrid.LoadingRow cols={5}/>
                        <DataGrid.LoadingRow cols={5}/>
                    </tbody>
                </DataGrid.Table>
            }

            { modalDialog }

        </DefaultLayout>
    )
}
