import React, { useEffect, useState } from 'react';
import { HelpLink, HelpSection } from '../../api/inni/data-contracts';
import styles from './HelpBar.module.scss';
import classNames from 'classnames';
import * as Tabs from '../../elements/VerticalTabs/VerticalTabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import SearchWithAction from '../../elements/SearchWithAction/SearchWithAction';

const HelpButton = ({btnText, url, index} : {btnText:string, url:string, index:number}) => {
    return (<a className={classNames(styles.helpBarButton, {[styles.helpBarButton2]: index === 1, [styles.helpBarButton3]: index === 2, [styles.helpBarButton4]: index === 3})} 
                href={url} target="_blank" rel="noopener noreferrer">{btnText}</a>)
}

const sortHelpButtons = (links:HelpLink[]) => {
    let sortedButtons:React.ReactElement[] = []
    let temporary = []
    for (let i = 0; i < links.length; i += 2) {
        temporary = links.slice(i, i + 2);
        sortedButtons.push(<div key={"btnGroup" + i} className={styles.helpButtonGroup}>
            {temporary.map((link, key3) => {return <HelpButton key={key3} btnText={link.title || ""} url={link.url || ""} index={links.indexOf(link)}/>})}
        </div>)
    }
    return sortedButtons
}

export const HelpBar = ({helpSection, onClose, isOpen} : {helpSection:HelpSection, onClose: () => void, isOpen: boolean}) => {
    const [selectedKey, setSelectedKey] = useState<string|undefined>();
    const [searchTerm, setSearchTerm] = useState<string>("");
    
    useEffect(() => {
        helpSection.groups && setSelectedKey(helpSection.groups[0].title)
    }, [helpSection])

    const searchKnowledgeHub = () => {
        const newWindow = window.open("https://www.inniaccounts.co.uk/?s=" + searchTerm, '_blank', 'noopener, noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return (<div className={classNames(styles.helpBar, {[styles.helpBarOpen]: isOpen})}>
        <Tabs.Wrapper>
            {helpSection.groups && 
                <>
                    <Tabs.Tabs selectedKey={selectedKey} onChangeTab={setSelectedKey} className={styles.helpTabs} 
                        appendEnd={<div className={classNames(styles.helpTab, styles.helpSearch)}><SearchWithAction search={searchTerm} setSearch={setSearchTerm} action={searchKnowledgeHub}/></div>}>
                        {helpSection.groups.map((group, key) => {return <Tabs.Tab className={classNames(styles.helpTab, {[styles.selected]: selectedKey === group.title})} key={key} keyName={group.title || ""}>{group.title}</Tabs.Tab>})}
                    </Tabs.Tabs>
                    <Tabs.Content className={styles.helpTabContent}>
                        <div style={{display:'flex'}}>
                            <div style={{display:'flex', flexGrow: 1}}>
                                {helpSection.groups.map((group, key2) => {return selectedKey === group.title && group.links && sortHelpButtons(group.links)})}
                            </div>
                            <FontAwesomeIcon icon={faTimesCircle} size={"lg"} className={styles.closeHelp} style={{color: "#fff", width: "22px", height: "22px"}} onClick={onClose}/>
                        </div>
                    </Tabs.Content>
                </>
            }
        </Tabs.Wrapper>
    </div>)
}