import { Property, PropertyLettableUnit } from '../../../api/inni/data-contracts';
import { Link } from 'react-router-dom';
// Components
import { Card } from 'react-bootstrap';
import styles from './PropertyCard.module.scss';
import { Button } from '../../../elements/Button/Button';
// Utils
import { Action, Entity } from '../../../utils/EntityAction';
import { formatLettingType, placeholderImageV8 } from '../../../utils/formatters/formatProperty';
import { formatCurrency0dp } from '../../../utils/formatNumbers';
import { convertSentenceCase, breakStrByCase } from '../../../utils/formatters/formatStrings';
// Hooks
import { useGetUrl } from '../../../hooks/useGetUrl';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';

interface PropertyCardProps {
  property: Property;
  imageUrl: string;
}

export const PropertyCard = ({ property, imageUrl }: PropertyCardProps) => {
  const getUrl = useGetUrl();

  const getLettingType = (units?: PropertyLettableUnit[]) => {
    if (units) {
      if (units.length === 1)
        return formatLettingType(units[0].lettingType).name;
      else
        return 'Multiple units';
    } else {
      return '';
    }
  };

  return (
    <Card id={styles.PropertyCard}>
      <Link to={getUrl(Entity.Property, Action.Read, { id: property.id }).url}>
        <Card.Img variant="top" src={imageUrl} />
      </Link>
      <Card.Body>
        <div>
          <h5>{getLettingType(property.lettableUnits)}</h5>
          { property.tradingStatus && property.tradingStatus !== 'Idle' && (
            <span className="text-muted"> • {convertSentenceCase(breakStrByCase(property.tradingStatus))}</span>
          )}
        </div>

        <h3>{property.nickname || property.name}</h3>

        <div className={styles.value}>
          { property.currentValue
            ? (
              <>
                <span className="text-muted mr-2">Current value</span>
                <h5>{formatCurrency0dp(property.currentValue)}</h5>
              </>
            )
            : <h5>&nbsp;</h5>
          }
        </div>

        <Button
          variant='dark'
          entity={Entity.Property}
          action={Action.Read}
          extraParams={{id: property.id}}
          label="View details"
          className={styles.viewButton}
          outline
          jumbo
        />
      </Card.Body>
    </Card>
  );
};

export const PropertyCardLoading = () => {
  return (
    <Card id={styles.PropertyCard}>
      <Card.Img variant="top" src={placeholderImageV8} />
      <Card.Body>
        <div>
          <h5 style={{width: '80%'}}><LoadingPlaceholder/></h5>
        </div>

        <h3><LoadingPlaceholder/></h3>

        <div className={styles.value}>
          <h5 style={{width: '80%'}}><LoadingPlaceholder/></h5>
        </div>

        <Button
          variant='dark'
          entity={Entity.Property}
          action={Action.Read}
          label="View details"
          className={styles.viewButton}
          outline
          jumbo
          disabled
        />
      </Card.Body>
    </Card>
  );
};
