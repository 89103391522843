import React, { useContext, useEffect, useState } from 'react';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import CompanyInformationTabs, { CompanyTabs } from './CompanySettingsTabs';
import { useInniAPI } from '../../hooks/useInniAPI';
import CompanySettingsEditLayout from './CompanySettingsEditLayout';
import { Action, Entity } from '../../utils/EntityAction';
import { CompanySettings } from '../../api/inni/CompanySettings';
import Toolbar from '../../layouts/Desktop/Toolbar';
import { Button } from '../../elements/Button/Button';
import { CompanySettingView } from '../../api/inni/data-contracts';
import { useAssertPermission } from '../../hooks/useAssertPermission';
import { useHasPermission } from '../../hooks/useHasPermission';
interface companyInfoProps{
	showExpandedView?: boolean;
	setExpandedView?: (show: boolean) => void
  }

const CompanyInfomation = ({showExpandedView, setExpandedView}: companyInfoProps) => {
	useAssertPermission(Entity.CompanySettingsV7,Action.Edit);
	const hasPermission = useHasPermission();
	const isAdmin = hasPermission(Entity.CompanySettingsAdmin, Action.Edit)[0];

	const companyContext = useContext(CompanyContext);
	const companyApi = useInniAPI(CompanySettings, [400]);
	const [activeTab, setActiveTab] = useState(CompanyTabs.COMPANY_INFORMATION);
	const [companySettings, setCompanySettings] = useState<CompanySettingView>();
	const [reLoad, setReLoad] = useState(false);
	const [reScanLoading, setReScanLoading] = useState(false);
	const [loading,setLoading] = useState(false);
	const v8Styling = companyContext.company?.useV8UI || false;

	//call it only once to hide the expanded view at the top on intial load
	useEffect(() =>{
		if(showExpandedView && setExpandedView){
			setExpandedView(false);
		}
	},[])

	useEffect(() => {
		setLoading(true);
		if (companyApi) {
			try {
				companyApi.index(companyContext.cid).then((response) => {
					setCompanySettings(response.data);
					setLoading(false);
				});
			} catch (error) {
				console.log(error);
			}
		}
	}, [companyApi, companyContext.cid, reLoad]);

	function handleResetWizard() {
		companyApi?.resetAutoEnrolment(companyContext.cid).then((response) => {
			setReLoad(!reLoad);
		});
	}

	function handleRescanConfirmationStatus() {
		setReScanLoading(true);
		companyApi?.rescanCsd(companyContext.cid).then((response) => {
			setReLoad(!reLoad);
			setReScanLoading(false);
		});
	}

	function updateCompanySettingsFromCH() {
		companyApi?.index(companyContext.cid).then((response) => {
			setCompanySettings(response.data);
		});
	}

	return (
		<DefaultLayout
			entity={Entity.CompanySettingsV7}
			greyBackground
			useFullWidth={!v8Styling}
			noPadding
			title={v8Styling ? "Company information" : companyContext.company?.name}
			loading={loading}
			subLayout={v8Styling}
		>

			{ isAdmin &&
				<Toolbar>
						<div className='ml-2'>
							<Button
								onClick={handleRescanConfirmationStatus}
								buttonType="sync"
								label="Rescan confirmation status"
								disabled={reScanLoading}
								headerBtn={v8Styling}
							/>
							<Button
								onClick={handleResetWizard}
								buttonType="delete"
								label="Reset autoenrolment wizard"
								entity={Entity.CompanySettingsAdmin}
								headerBtn={v8Styling}
							/>
						</div>
				</Toolbar>
			}

			<CompanyInformationTabs activeTab={activeTab} setActiveTab={setActiveTab} isAdmin={isAdmin}/>

			{companySettings && (
				<CompanySettingsEditLayout
					updateCompanySettingsFromCH={updateCompanySettingsFromCH}
					activeTab={activeTab}
					companySettings={companySettings}
					setReload={setReLoad}
					setLoading={setLoading}
					isAdmin={isAdmin}
					v8Styling={v8Styling}
				/>
			)}
		</DefaultLayout>
	);
}

export default CompanyInfomation;
