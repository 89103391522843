import { isString } from "lodash";

// compare functions for sort
export const compareAscByString = <T extends {}>(key: keyof T) => {
	return (a: T, b: T) => {
        let comparison = 0;
        const bandA = a[key];
        const bandB = b[key]
        if (isString(bandA) && isString(bandB)) {
            if (bandA > bandB) {
                comparison = 1;
            } else if (bandA < bandB) {
                comparison = -1;
            }
            return comparison;
        }
        return comparison
	};
};


 //to overcome sorting issue for different browser 
 export const StableSort=<T,>(arr: T[], cmp: (a: T, b: T) => number, cmp2?: (a: T, b: T) => number): T[] =>{
    const n = arr.length;
    for (let i = 0; i < n - 1; i++) {
        let swapped = false;
        for (let j = 0; j < n - i - 1; j++) {
            if (cmp(arr[j], arr[j + 1]) > 0) {
                [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
                swapped = true;
            }
            if (cmp2&&cmp2(arr[j], arr[j + 1]) > 0) {
                [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]];
                swapped = true;
            }
        }
        if (!swapped) break;
    }
    
    return arr;
}