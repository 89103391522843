import { SbBlokData } from '@storyblok/react';
import { FormikErrors } from 'formik';
import React, { ChangeEvent, SetStateAction } from 'react';
import { FinanceTableForm } from '../hooks/useOnboardingStory';
import { IPropertyPostModel } from '../hooks/useOnboardingProperties';
import { ICSV } from '../hooks/onboarding/useUploadCSV';
import { ICSUploadTable, IFinanceUploadTable } from '../hooks/useUploadDocumentsTable';
export type BaseFormik<T> = {
	setValues: (
		values: SetStateAction<T[]>,
		shouldValidate?: boolean | undefined
	) => Promise<(FormikErrors<T> | undefined)[]> | Promise<any>;
	values: T[];
	handleChange: (e: ChangeEvent<any>) => void;
	errors: (FormikErrors<T> | undefined)[] | { [key: string]: string };
	isSubmitting: boolean;
	isValidating: boolean
	setErrors: (errors: (FormikErrors<T> | undefined)[]) => void;
	isValid: boolean;
};
export type TFinanceTableFormik = BaseFormik<FinanceTableForm>
export type UploadFinanceTableFormik = BaseFormik<IFinanceUploadTable>;
export type UploadCSTableFormik = BaseFormik<ICSUploadTable>
export type PropertiesFomik = BaseFormik<IPropertyPostModel>;
export type UploadCSVFormik = BaseFormik<ICSV>;
export type OnboardingHandlers = {
	save: () => void;
	gotoUpdate: () => void;
};
export interface OnboardingContext {
	onNavItemSelect: (uid: string, pageUrl: string) => void;
	selectedUid: string;
	selectedPageUrl: string;
	onChangeUtr: (value: any) => void;
	utr: string;
	onClickAction: (blok: SbBlokData, handlers?: OnboardingHandlers) => (() => void) | null | undefined;
	setCurrentPageCompletionStatus: () => boolean;
	showStatusLine: (system_key: string) => boolean;
	errorMessage: string;
	isSubmitted: boolean;
	financeTableFormik: TFinanceTableFormik;
	notifyAccountantsMessage: string;
	onChangeNotifyAccountantMessage: (value: string) => void;
	uploadTableFinanceFormik: UploadFinanceTableFormik; // update type
	uploadMortgageDocumentsFormik: UploadCSTableFormik;
	ownedPropertiesFormik: PropertiesFomik;
	soldPropertiesFormik: PropertiesFomik;
	showExcept: {[key: string]: boolean};
	uploadCVSFormik: UploadCSVFormik;
	onIncompletePage: () => void;
}

const CompanyContext = React.createContext<OnboardingContext>({} as OnboardingContext);

export default CompanyContext;
