/**
 * @prettier
 */
import classnames from 'classnames';
import { TenancyAsDetailed } from '../../../api/inni/data-contracts';
import { Text, Date, Currency, YesNo } from '../../../elements/DetailRow/DetailRow';
import { SubHeading } from '../../../elements/EditRow/EditRow';
import { LettableUnitListItem } from '../../../hooks/lists/useLettableUnitsList';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { Entity, Action } from '../../../utils/EntityAction';
import styleSheet from '../Tenancy.module.scss';

export interface DetailsReadProps {
  tenancy: TenancyAsDetailed;
  unit?: LettableUnitListItem;
  v8Styling?: boolean;
  toggleEdit: () => void;
}

export const DetailsRead = ({ tenancy, unit, v8Styling, toggleEdit }: DetailsReadProps) => {
  const hasPermission = useHasPermission();
  const hasInvoicing = hasPermission(Entity.Invoice, Action.All)[0] || hasPermission(Entity.InvoiceV7, Action.All)[0]; //InvoiceV7 still admin only

  return (
    <div data-test-id="detailsRead">
      {v8Styling && <h2 className={styleSheet.subheading}>Rental information</h2>}

      {/* TODO: ensureRender should automatically be inferred at component level for V8 */}
      {unit && <Text entity={unit} fixedWidth={v8Styling} ensureRender={v8Styling} name="fullName" label="Property" />}
      <Date entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="startDate" label={'Tenancy start date'} />
      <Date entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="endDate" label={'Tenancy end date'} />
      <Date entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="moveInDate" />
      <Date entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="moveOutDate" />
      <Currency entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="rentAmount" intervalLabel="per" intervalFieldName="rentInterval" />
      <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="rentReviewInterval" label="Rent review period" suffix="months" />
      <Date entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="rentReviewDate" label="Next rent review" />

      <Text fixedWidth={v8Styling} ensureRender={v8Styling} entity={tenancy} name="notes" />
      {v8Styling && <YesNo fixedWidth={v8Styling} ensureRender={v8Styling} entity={tenancy} name="tenancyIsManaged" label="Agency managed" />}
      {tenancy.tenancyIsManaged && (
        <>
          {!v8Styling && <SubHeading>Management</SubHeading>}
          {!v8Styling && <YesNo fixedWidth={v8Styling} entity={tenancy} name="tenancyIsManaged" />}
          <Text fixedWidth={v8Styling} entity={tenancy} ensureRender={v8Styling} name="lettingAgentName" label="Agent name" />
          <Currency fixedWidth={v8Styling} ensureRender={v8Styling} entity={tenancy} name="agentFees" intervalLabel="per" intervalFieldName="agentFeesInterval" />
        </>
      )}

      {v8Styling ? <h2 className={classnames(styleSheet.subheading, styleSheet.seperation)}>Lead tenant</h2> : <SubHeading>Lead tenant</SubHeading>}
      <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="tenantTitle" label="Title" />
      <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="tenantFirstName" label="First name" />
      <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="tenantLastName" label="Last name" />
      <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="tenantEmail" label="Email address" />
      <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="tenantPhoneMobile" label="Phone (mobile)" />
      <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="tenantPhoneHome" label="Phone (home)" />
      <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="tenantPhoneWork" label="Phone (work)" />

      {hasInvoicing && (
        <>
          {v8Styling ? <h2 className={classnames(styleSheet.subheading, styleSheet.seperation)}>Invoicing</h2> : <SubHeading>Invoicing</SubHeading>}
          {tenancy.invoiceDescription || tenancy.invoiceMessage || tenancy.autoEmailBody ? (
            <>
              <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="invoiceDescription" />
              <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="invoiceMessage" />
              <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="autoEmailSubject" label="Email subject" />
              <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="autoEmailBody" label="Email message" />
              <Text entity={tenancy} fixedWidth={v8Styling} ensureRender={v8Styling} name="invoiceDueDays" label="Payment due within" suffix="days" />
            </>
          ) : (
            <p className="text-muted">
              Invoicing is not currently set up.&nbsp;
              <span style={{ cursor: 'pointer' }} onClick={toggleEdit}>
                <u>Edit</u>
              </span>
              &nbsp;to add this now.
            </p>
          )}
        </>
      )}
    </div>
  );
};
