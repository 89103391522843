/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Datagrid,
  Invoice,
  InvoiceAsDetailed,
  InvoiceContracts,
  InvoiceEmailModel,
  InvoiceEmailPostModel,
  InvoicePostModel,
  InvoiceRefund,
  InvoiceTaxDeduction,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Invoices<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Invoices
   * @name Index
   * @summary Returns list of invoice summaries
   * @request GET:/api/v2/companies/{companyId}/invoices
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Invoice[], any>({
      path: `/api/v2/companies/${companyId}/invoices`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name Create
   * @summary creates new invoice
   * @request POST:/api/v2/companies/{companyId}/invoices
   * @secure
   */
  create = (companyId: number, postModel: InvoicePostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/invoices`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name IndexAsDetailed
   * @summary With the lines embedded
   * @request GET:/api/v2/companies/{companyId}/invoices/AsDetailed
   * @secure
   */
  indexAsDetailed = (companyId: number, query: { contractId: number }, params: RequestParams = {}) =>
    this.request<InvoiceAsDetailed[], any>({
      path: `/api/v2/companies/${companyId}/invoices/AsDetailed`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name Get
   * @summary Returns complete details of specific invoice
   * @request GET:/api/v2/companies/{companyId}/invoices/{invoiceId}
   * @secure
   */
  get = (companyId: number, invoiceId: number, params: RequestParams = {}) =>
    this.request<InvoiceAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/invoices/${invoiceId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name Update
   * @summary Updates invoice details and returns new invoice id
   * @request PUT:/api/v2/companies/{companyId}/invoices/{invoiceId}
   * @secure
   */
  update = (companyId: number, invoiceId: number, postModel: InvoicePostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/invoices/${invoiceId}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name Delete
   * @summary Deletes
   * @request DELETE:/api/v2/companies/{companyId}/invoices/{invoiceId}
   * @secure
   */
  delete = (companyId: number, invoiceId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/invoices/${invoiceId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name ValidateCreate
   * @summary validates invoice create postmodel
   * @request POST:/api/v2/companies/{companyId}/invoices/Validate
   * @secure
   */
  validateCreate = (companyId: number, postModel: InvoicePostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/invoices/Validate`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name Filter
   * @summary Returns invoices datagrid based on the yearcode
   * @request GET:/api/v2/companies/{companyId}/invoices/Filter
   * @secure
   */
  filter = (companyId: number, query?: { yearCode?: string }, params: RequestParams = {}) =>
    this.request<Datagrid, any>({
      path: `/api/v2/companies/${companyId}/invoices/Filter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name ValidateUpdate
   * @summary validates invoice update postmodel
   * @request PUT:/api/v2/companies/{companyId}/invoices/{invoiceId}/Validate
   * @secure
   */
  validateUpdate = (companyId: number, invoiceId: number, postModel: InvoicePostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/invoices/${invoiceId}/Validate`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name GetInvoicePdf
   * @summary Returns PDF of invoice in HTTPResponseMessage
   * @request GET:/api/v2/companies/{companyId}/invoices/PDF/{invoiceId}
   * @secure
   */
  getInvoicePdf = (companyId: number, invoiceId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/invoices/PDF/${invoiceId}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name GetMigrationPdf
   * @summary Returns PDF for migration invoice in HTTPResponseMessage
   * @request GET:/api/v2/companies/{companyId}/invoices/MigrationPDF/{migrationDocId}
   * @secure
   */
  getMigrationPdf = (companyId: number, migrationDocId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/invoices/MigrationPDF/${migrationDocId}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name New
   * @summary This endpoint returns the all the pre requisite values for invoice creation or updation
   * @request GET:/api/v2/companies/{companyId}/invoices/New
   * @secure
   */
  new = (
    companyId: number,
    query?: {
      docType?: "Invoice" | "CreditNote" | "Quote" | "InvoiceTemplate";
      createInvoice?: boolean;
      createCopy?: boolean;
      invoiceId?: number;
      contractId?: number;
      creditForInvoiceId?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<InvoiceAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/invoices/New`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name GetEmailModel
   * @summary Returns required values for sending invoice email
   * @request GET:/api/v2/companies/{companyId}/invoices/Email/{invoiceId}
   * @secure
   */
  getEmailModel = (companyId: number, invoiceId: number, params: RequestParams = {}) =>
    this.request<InvoiceEmailModel, any>({
      path: `/api/v2/companies/${companyId}/invoices/Email/${invoiceId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name EmailInvoice
   * @summary Sends invoice as email
   * @request POST:/api/v2/companies/{companyId}/invoices/Email/{invoiceId}
   * @secure
   */
  emailInvoice = (
    companyId: number,
    invoiceId: number,
    invoiceEmail: InvoiceEmailPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/invoices/Email/${invoiceId}`,
      method: "POST",
      body: invoiceEmail,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name ValidateEmail
   * @summary validates Email postmodel
   * @request POST:/api/v2/companies/{companyId}/invoices/Email/{invoiceId}/Validate
   * @secure
   */
  validateEmail = (
    companyId: number,
    invoiceId: number,
    invoiceEmail: InvoiceEmailPostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/invoices/Email/${invoiceId}/Validate`,
      method: "POST",
      body: invoiceEmail,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name GetInvoiceableContracts
   * @summary Gets contracts that are invoiceable
   * @request GET:/api/v2/companies/{companyId}/invoices/InvoiceableContracts
   * @secure
   */
  getInvoiceableContracts = (companyId: number, params: RequestParams = {}) =>
    this.request<InvoiceContracts[], any>({
      path: `/api/v2/companies/${companyId}/invoices/InvoiceableContracts`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name ClearIr35
   * @summary Clears IR35 on the Invoice
   * @request DELETE:/api/v2/companies/{companyId}/invoices/ClearIR35/{invoiceId}
   * @secure
   */
  clearIr35 = (companyId: number, invoiceId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/invoices/ClearIR35/${invoiceId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name ZeroRemainingBalance
   * @summary updates the total amount paid to total amount
   * @request PUT:/api/v2/companies/{companyId}/invoices/ZeroBalance/{invoiceId}
   * @secure
   */
  zeroRemainingBalance = (companyId: number, invoiceId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/invoices/ZeroBalance/${invoiceId}`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name ConfirmTaxDeducted
   * @request POST:/api/v2/companies/{companyId}/invoices/TaxDeductionConfirmation/{invoiceId}
   * @secure
   */
  confirmTaxDeducted = (companyId: number, invoiceId: number, ITD: InvoiceTaxDeduction, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/invoices/TaxDeductionConfirmation/${invoiceId}`,
      method: "POST",
      body: ITD,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name DeleteBalancingPayments
   * @request DELETE:/api/v2/companies/{companyId}/invoices/DeleteBalancePayments/{actionId}
   * @secure
   */
  deleteBalancingPayments = (companyId: number, actionId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/invoices/DeleteBalancePayments/${actionId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name Refund
   * @request POST:/api/v2/companies/{companyId}/invoices/Refund/{invoiceId}
   * @secure
   */
  refund = (
    companyId: number,
    invoiceId: number,
    query: { paymentProviderId: number },
    IR: InvoiceRefund,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/invoices/Refund/${invoiceId}`,
      method: "POST",
      query: query,
      body: IR,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name InvoicesZip
   * @summary Exports all invoices into Zip file
   * @request GET:/api/v2/companies/{companyId}/invoices/ZipInvoices
   * @secure
   */
  invoicesZip = (companyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/invoices/ZipInvoices`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name ReJournal
   * @request POST:/api/v2/companies/{companyid}/invoices/Rejournal
   * @secure
   */
  reJournal = (companyid: number, query: { id: number }, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyid}/invoices/Rejournal`,
      method: "POST",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Invoices
   * @name ExchangeRate
   * @request GET:/api/v2/companies/{companyID}/invoices/ExchangeRate
   * @secure
   */
  exchangeRate = (companyId: number, query: { invoiceDate: string; currency: string }, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/invoices/ExchangeRate`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
