/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  MortgageAccount,
  MortgageAccountPostModel,
  MortgageStatementUploadData,
  StatementUploadReminder,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class MortgageAccounts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/mortgageaccounts
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<MortgageAccount[], any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/mortgageaccounts
   * @secure
   */
  create = (companyId: number, accountDetails: MortgageAccountPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts`,
      method: "POST",
      body: accountDetails,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name Get
   * @request GET:/api/v2/companies/{companyId}/mortgageaccounts/{mortgageAccountId}
   * @secure
   */
  get = (companyId: number, mortgageAccountId: number, params: RequestParams = {}) =>
    this.request<MortgageAccount, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/${mortgageAccountId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/mortgageaccounts/{mortgageAccountId}
   * @secure
   */
  update = (
    companyId: number,
    mortgageAccountId: number,
    postModel: MortgageAccountPostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/${mortgageAccountId}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/mortgageaccounts/{mortgageAccountId}
   * @secure
   */
  delete = (companyId: number, mortgageAccountId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/${mortgageAccountId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/mortgageaccounts/Validate
   * @secure
   */
  validateCreate = (companyId: number, postmodel: MortgageAccountPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/mortgageaccounts/{mortgageAccountId}/Validate
   * @secure
   */
  validateUpdate = (
    companyId: number,
    mortgageAccountId: number,
    postmodel: MortgageAccountPostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/${mortgageAccountId}/Validate`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name GetMortgageAccountsForProperty
   * @request GET:/api/v2/companies/{companyId}/mortgageaccounts/property/{propertyId}
   * @secure
   */
  getMortgageAccountsForProperty = (companyId: number, propertyId: number, params: RequestParams = {}) =>
    this.request<MortgageAccount[], any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/property/${propertyId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name GetUploadData
   * @request GET:/api/v2/companies/{companyId}/mortgageaccounts/{mortgageAccountId}/GetUploadData
   * @secure
   */
  getUploadData = (companyId: number, mortgageAccountId: number, params: RequestParams = {}) =>
    this.request<MortgageStatementUploadData, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/${mortgageAccountId}/GetUploadData`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name UpdateReviewDate
   * @request POST:/api/v2/companies/{companyId}/mortgageaccounts/{mortgageAccountId}/UpdateReviewDate
   * @secure
   */
  updateReviewDate = (
    companyId: number,
    mortgageAccountId: number,
    newReminder: StatementUploadReminder,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/${mortgageAccountId}/UpdateReviewDate`,
      method: "POST",
      body: newReminder,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name LinkMortgageToProperty
   * @request POST:/api/v2/companies/{companyId}/mortgageaccounts/linkMortgageToProperty/{mortgageAccountId}/{propertyId}
   * @secure
   */
  linkMortgageToProperty = (
    companyId: number,
    mortgageAccountId: number,
    propertyId: number,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/linkMortgageToProperty/${mortgageAccountId}/${propertyId}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name UnlinkMortgageAccount
   * @request POST:/api/v2/companies/{companyId}/mortgageaccounts/unlinkMortgageAccount/{mortgageAccountId}/{propertyId}
   * @secure
   */
  unlinkMortgageAccount = (
    companyId: number,
    mortgageAccountId: number,
    propertyId: number,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/unlinkMortgageAccount/${mortgageAccountId}/${propertyId}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags MortgageAccounts
   * @name UploadMortgageStatement
   * @request POST:/api/v2/companies/{companyId}/mortgageaccounts/{mortgageAccountId}/uploadMortgageStatement
   * @secure
   */
  uploadMortgageStatement = (
    companyId: number,
    mortgageAccountId: number,
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/mortgageaccounts/${mortgageAccountId}/uploadMortgageStatement`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
}
