import { render } from 'storyblok-rich-text-react-renderer';

import { SbBlokData, storyblokEditable } from '@storyblok/react';
import { setPropToStoryblokText } from '../../../utils/setPropToStoryblok';
import { ReactNode, useContext } from 'react';
import UserContext from '../../../context/UserContext';
import CompanyContext from '../../../context/CompanyContext';
import { InitialiseDateModel } from '../../../api/inni/data-contracts';
import { formatDate, formatDateTime } from '../../../utils/formatDate';
import { subMonths } from 'date-fns';
import OnboardingContext from '../../../context/OnboardingContext';
type Props = {
	blok: SbBlokData;
	initialData: InitialiseDateModel;
};

type LinkProps = {
	linktype?: string;
	href?: string;
	target?: string;
	anchor?: string;
	uuid?: string;
  }
const Text = ({ blok, initialData }: Props) => {
	const { user } = useContext(UserContext);
	const { company } = useContext(CompanyContext);
	const { onNavItemSelect } = useContext(OnboardingContext);

	const textResolver = (text: string): string =>
		setPropToStoryblokText(text, {
			firstName: user?.firstName || '',
			contactId: `${user?.contactId}`,
			companyName: company?.name || '',
			migrationDate: formatDate(initialData?.migrationDate || ''),
			'migration date': formatDate(initialData?.migrationDate || ''),
			financialYearStartDate: formatDateTime(
				initialData?.financialYearStartDate || ''
			),
			'earliest imported transaction':
				formatDate(initialData?.earliestImportedTransaction || ''),
			idDaysRemaining: `${initialData?.idDaysRemaining || 0} days `,
			today: formatDate(new Date().toDateString()),
			'1 month before migration date': formatDate(subMonths(new Date(initialData?.migrationDate || ''), 1).toDateString())
		});

	const getNavIdByPageId = (id: string) => {
		const page = initialData?.checklistProgress?.find(v => v.pageId === id);
		return page?.uid || '';
	}

	const navigateToStoryPage = ( {uuid, href}: LinkProps) => {
		getNavIdByPageId(uuid || '')
		onNavItemSelect(getNavIdByPageId(uuid || ''), href || '')
	}

	const markResolvers = {
		link: (children: ReactNode, props: LinkProps) => {
			if (props.linktype === 'story') {
				return <a href={`/company/${company?.id}/onboarding`} onClick={(e) => {
					e.preventDefault()
					navigateToStoryPage(props)
				}}>{children}</a>
			}
			return <a href={props.href?.replace('<COMPANYID>', `${company?.id || ''}`)}>{children}</a>
		}
	}
	return (
		<div {...storyblokEditable(blok)}>
			{render(blok.body, {textResolver, markResolvers})}
		</div>
	);
};

export default Text;
