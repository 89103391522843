import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { faFlag, faStar, faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { useContext, useEffect, useState } from 'react'
import { Asset, Category } from '../../api/inni/data-contracts'
import { formatTypeString, getUniqueForLevel, getHeadingsForType, formatSubType, formatTypeByLevel } from './categorySelectorUtilsAndFormatters'
import styles from './CategorySelector.module.scss'
import CompanyContext from '../../context/CompanyContext'
import { Button } from '../../elements/Button/Button'
import { formatSubGroup } from '../../utils/formatters/formatAccount'
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder'
import { getPaymentTypeLong, ProcessorType } from '../AccountViewerNew/BankingTypes'
import { SpecialCategoryFields } from '../../features/Bookkeeping2022/RecordCreator'
import { StoryblokComponent, SbBlokData, getStoryblokApi } from '@storyblok/react'
import { breakStrByCase } from '../../utils/formatters/formatStrings'
import { useModalDialog } from '../../hooks/useModalDialog'
import { useHistoryWrapper } from '../../hooks/useHistoryWrapper'
import { Modal } from '../../components/Modal/Modal';

const DLOAN_STR = `Director's loan`
const levelKeys : (keyof Category)[] = ['type', 'subType', 'subGroup', 'name']

interface iSBCategory extends SbBlokData {
    key: string,
    value: string
}

interface CategorySelectorProps {
    outgoing?: boolean,
    setSelectedAccount: (
        selected: number,
        category: Category | undefined) => void,
    setSpecialCategoryFields: (fields: SpecialCategoryFields) => void,
    isSplitting: boolean,
    categories?: Category[] | undefined,
    // setBPType: (pt: ProcessorType) => void,
    bankAccountId: number,
    hideModal: () => void,
    assets: Asset[],
    bookkeeping?: boolean,
    showRefunds?: boolean,
    showNewMortgage?: boolean
    showNewDirector?: boolean
}

interface CategoryWithOverride extends Category {
    overrideDesc?: string
}

const CategorySelectorBrowser = ({
        outgoing,
        setSelectedAccount,
        setSpecialCategoryFields,
        bankAccountId,
        isSplitting,
        assets,
        categories,
        hideModal,
        bookkeeping=true,
        showRefunds,
        showNewMortgage = true,
        showNewDirector = true
    }: CategorySelectorProps) => {  

    // Apis
    const [workingCategories, setWorkingCategories] = useState<CategoryWithOverride[]>([])
    const companyContext = useContext(CompanyContext)

    // State
    const [selection, setSelection] = useState<string[]>(['','','',''])
    const [catSelectorId, setCatSelectorId] = useState<number | string | null>(null)
    const [isRefund, setIsRefund] = useState(false)
    const [lastSelection, setLastSelection] = useState<[string, number]>(['', 0])
    const [hovered, setHovered] = useState<[string, number] | null>(null)
    const [descriptions, setDescriptions] = useState<{[key: string]: iSBCategory} | undefined>();
    const [shownDescription, setShownDescription] = useState<JSX.Element|null>(null);
    const [columnUniqueType, setColumnUniqueType] = useState<string[]>([])
    const [columnUniqueSubType, setColumnUniqueSubType] = useState<string[]>([])
    const [columnUniqueSubGroup, setColumnUniqueSubGroup] = useState<string[]>([])
    const [columnUniqueSubName, setColumnUniqueSubName] = useState<string[]>([])

    const [showModalDialog, modalDialog, showModal] = useModalDialog();
    const history = useHistoryWrapper();

    const redirectNewEmployee = () => {
        document.body.classList.remove("overlay-open")
        history.push(`/company/${companyContext.cid}/manageemployees/new`);
    }

    const hasSellableAssets = assets.filter(x => x.canDispose).length > 0

    const showNewDirectorDialog = () => {
        showModalDialog(
            'Create new director?',
            `This will take you to the 'New person' page, go there now?`,
            [
                <Button variant="primary" label="Yes" onClick={() => redirectNewEmployee()} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const showNewMortgageDialog = () => {
        showModalDialog(
            'New mortgage',
            <>
                <p>
                    After breaking down your completion statement for a property, mortgage accounts will be created for you.<br />
                    If you haven't done this yet, please check your dashboard for a reminder.<br />
                    In the meantime, you can choose to skip this transaction.
                </p>
            </>,
            [],
            true,
            'lg'
        );
    }

    // Create descriptions object from storyblok data on load
    useEffect(() => {
        if(descriptions === undefined) {
            const storyblokApi = getStoryblokApi();			
            storyblokApi.get('cdn/stories/bookkeeping/category-list', {version: 'published'}).then((res) => {
                const buildDescriptions:{[key: string]: iSBCategory} = {};
                const sbDescriptions = res.data.story
                if(sbDescriptions) {
                    sbDescriptions.content.Body.forEach((entry: iSBCategory) => {
                        buildDescriptions[entry.key] = entry
                    });
                    setDescriptions(buildDescriptions)
                }
            });
        }
    }, [descriptions])

    // Set filtered (working) categories
    useEffect(() => {
        if (categories) {
            if (bookkeeping) {
                const bucketLinkedIds = ["LettingAgentAcc", "SolicitorAcc"]
                if (outgoing) {
                    if (isSplitting) {
                        setWorkingCategories(categories.filter(x => (x.type !== 'income' && x.accountId !== bankAccountId && 
                            !bucketLinkedIds.includes(x.linkedID || ''))))
                    } else {
                        setWorkingCategories(categories.filter(x => (x.type !== 'refund' && x.type !== 'income' && x.accountId !== bankAccountId)))
                    }
                } else {
                    if(isSplitting) {
                        setWorkingCategories(categories.filter(x => (x.type !== 'expense' && x.accountId !== bankAccountId &&
                            !bucketLinkedIds.includes(x.linkedID || ''))))
                    } else {
                        setWorkingCategories(categories.filter(x => (x.type !== 'expense' && x.accountId !== bankAccountId)))
                    }
                }
            } else {
                // if not bookkeeping we don't need to filter
                setWorkingCategories(categories)
            }
        }
    }, [categories, isSplitting, outgoing, bankAccountId, bookkeeping])

    const setLevel = (level : number, value : string) => {
        let tempSelection = [...selection]
        tempSelection[level] = value

        for (let i = level + 1; i < 4; i ++)
            tempSelection[i] = ""

        if (level === 0) {
            setIsRefund(value === "refund")
        }

        if (value === DLOAN_STR) {
            setCatSelectorId(-1)  // we return -1 to form to denote directors loan
        } else if (level === 3) {
            let item = workingCategories.find(x => x.name === value)    
            setCatSelectorId(item?.accountId || null)
        } else {
            setCatSelectorId(null)
        }

        setSelection(tempSelection)
        setLastSelection([value, level])
    }

    const selectCategory = () => {
        if (typeof(catSelectorId) === 'number') {
            const findCategory: Category | undefined = workingCategories.find(x => x.accountId === catSelectorId && (isRefund ? x.bpProcessorType === "RX" : x.bpProcessorType !== "RX"));
            setSelectedAccount(
                catSelectorId,
                findCategory
            )

            const account = workingCategories.find(x => x.accountId === catSelectorId && (isRefund ? x.bpProcessorType === "RX" : x.bpProcessorType !== "RX"))

            if (account) {
                const accountType = getPaymentTypeLong(account.bpProcessorType || "")
                // setBPType(accountType !== "" ? accountType : ProcessorType.expense)
                setSpecialCategoryFields({
                    showIsAsset: account.isEligibleForAssets || account.linkedID === "OtherGoods",
                    showIsOtherGoodsAsset: account.linkedID === "OtherGoods",
                    showPaymentCoversDateRange: account.showPaymentCoversDateRange === "Yes", 
                    showIncreasesValue: account.showIncreasesValue === "Yes", 
                    showIsReplacementItem: account.showIsReplacementItem === "Yes",
                    showIsBusinessExpense: account.showIsBusinessExpense === "Yes",
                    showContract: account.showContract === "Yes",
                    showIsBillable: account.showIsBillable === "Yes",
                    attachmentRequiredMessage: account.attachmentRequiredMessage})
            }
        } else {
            console.error(`Invalid id ${catSelectorId}`)
        }
    }

    // Get selected storyblok description
    useEffect(() => {
        if (descriptions && workingCategories && hovered) {
            const entry = hovered[0]
            const colIndex = hovered[1]
            let key = ''

            const categoryDetails = workingCategories.find(x => x.name === entry);

            /*
            General rule - use linkedid for key (unique) if exists, i.e. entries; otherwise fall back to name, e.g. sub-categories.
            Exceptions are 'buckets' which have no id, but we know they're entries by colIndex == 3.
            Special cases are 'transfers' since they have dynamic names (so ignored), and there are name clashes on Goods/Services > Other/Property
            Little bit ugly but unfortunately categories weren't developed in a way each stage has concept of the last (and expensive to look up)
            */
            if (selection[0] === 'transfer' && (hovered[0] === '' || colIndex > 0)) {
                key = '[category]transfer'  // ignore
            } else if ((selection[1] === 'goods' || selection[1] === 'services') &&
                (entry === 'Other' || entry === 'Property' || entry === 'Business')) {
                key = `[category]${selection[1]}-${(entry || 'missing!').toLowerCase()}`  // make unique
            } else if (colIndex === 3) {
                key = '[entry]' + breakStrByCase(
                    (categoryDetails?.linkedID) || entry.replace(/ /g, '-').toLowerCase(),
                    true,
                    '-');
            } else {
                key = `[category]${breakStrByCase(entry, true, '-')}`
            }

            if (categoryDetails?.overrideDesc)
                key += '-override-' + categoryDetails.overrideDesc

            if (descriptions && descriptions[key]) {
                setShownDescription(<StoryblokComponent blok={descriptions[key]} />)
            }
            else {
                setShownDescription(<></>)
            }
        }
    }, [descriptions, hovered, selection, workingCategories])

    // Update the column lists when selection changes
    useEffect(() => {
        setColumnUniqueType(getUniqueForLevel(workingCategories, 'type'));

        setColumnUniqueSubType(getUniqueForLevel(workingCategories.filter(x => x.type === selection[0]), 'subType'));

        setColumnUniqueSubGroup(getUniqueForLevel(workingCategories.filter(x =>
            (x.subType === selection[1] || x.subType === null) && x.type === selection[0]), 'subGroup'))

        setColumnUniqueSubName(getUniqueForLevel(workingCategories.filter(x =>
            x.name !== null && (x.subGroup === selection[2] || x.subGroup === null) &&
            (x.subType === selection[1] || x.subType === null) &&
            x.type === selection[0]), 'name'))

    }, [selection, workingCategories])

    return (
        <>
            <Modal
                title="Select a category"
                size="xl"
                showModal={true}
                hideModal={hideModal}
                buttons={
                    [
                        <Button 
                            key="bttnSelect"
                            label="Select category" 
                            variant="primary" 
                            disabled={catSelectorId === null || (categories?.find(x => x.accountId === catSelectorId)?.linkedID === "AssetSales" && !hasSellableAssets)} 
                            onClick={() => selectCategory()} 
                        />,
                        <Button
                            key="bttnClose"
                            label="Close"
                            variant="light"
                            onClick={hideModal}
                        />
                    ]
                }
            >
                <div data-cy="categorySelectorColumns" className={classNames(styles.categorySelector, styles.show, styles.categorySelectorBrowser)}>
                    { workingCategories.length !== 0
                        ? (
                            // COLUMN 1
                            <div className={styles.top}>
                                <div>
                                    <h5>Transaction type</h5>
                                    { columnUniqueType.map(x => (
                                        <div 
                                            key={x}
                                            className={classNames(
                                                styles.categorySelectorItem,
                                                x === selection[0] ? styles.selected : ''
                                            )}
                                            onMouseEnter={() => setHovered([x, 0])}
                                            onMouseLeave={() => setHovered(lastSelection)}
                                            onClick={() => setLevel(0, x)}
                                            onDoubleClick={catSelectorId ? selectCategory : undefined}
                                        >
                                            <span>
                                                {formatTypeString(x)}
                                                { x === 'refund' && (
                                                    <span className="text-muted" style={{fontSize: '12px'}}>(incoming)</span>
                                                )}
                                            </span>

                                            <FontAwesomeIcon className={`text-muted ${styles.hideSmall}`} icon={faChevronRight} />
                                        </div>
                                    ))}
                                    { showRefunds && bookkeeping && outgoing && !isSplitting && (
                                        <div
                                            className={styles.categorySelectorItem}
                                            style={{color: 'grey'}}
                                            onMouseEnter={() => setHovered(['Refund (disabled)', 0])}
                                            onMouseLeave={() => setHovered(lastSelection)}
                                            onClick={(e) => e.preventDefault()}
                                        >
                                            Refund
                                        </div>
                                    )}
                                </div>

                                {/* COLUMN 2 */}
                                { columnUniqueSubType.length !== 0 && (
                                    <div>
                                        <h5>{ getHeadingsForType(selection[0])[0] }</h5>
                                        { columnUniqueSubType.map(x => (
                                            <div
                                                key={x}
                                                className={classNames(
                                                    styles.categorySelectorItem,
                                                    x === selection[1] ? styles.selected : ''
                                                )}
                                                onMouseEnter={() => setHovered([x, 1])}
                                                onMouseLeave={() => setHovered(lastSelection)}
                                                onClick={() => setLevel(1, x)}
                                                onDoubleClick={catSelectorId ? selectCategory : undefined}
                                            >
                                                <span>{formatSubType(x)}</span>
                                                <FontAwesomeIcon className={`text-muted ${styles.hideSmall}`} icon={faChevronRight} />
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* COLUMN 3 */}
                                { columnUniqueSubGroup.length > 0 && (
                                    <div>
                                        <h5>{ getHeadingsForType(selection[0])[1] }</h5>
                                        { columnUniqueSubGroup.map(x => (
                                            <div
                                                key={x}
                                                className={classNames(
                                                    styles.categorySelectorItem,
                                                    x === selection[2] ? styles.selected : ''
                                                )}
                                                onMouseEnter={() => setHovered([x, 2])}
                                                onMouseLeave={() => setHovered(lastSelection)}
                                                onClick={() => setLevel(2, x)}
                                                onDoubleClick={catSelectorId ? selectCategory : undefined}
                                            >
                                                <span>{formatSubGroup(x)}</span>
                                                { x !== DLOAN_STR && (
                                                    <FontAwesomeIcon className={`text-muted ${styles.hideSmall}`} icon={faChevronRight} />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* COLUMN 4 */}
                                <div style={{maxHeight: '420px', overflowY: 'auto'}}>
                                    <h5>{ getHeadingsForType(selection[0])[2] }</h5>
                                    { columnUniqueSubName.map(x => {
                                        return (
                                            <div 
                                                key={x}
                                                className={classNames(
                                                    styles.categorySelectorItem,
                                                    x === selection[3] ? styles.selected : ''
                                                )} 
                                                style={{justifyContent: 'left'}}
                                                onMouseEnter={() => setHovered([selection[0] === "transfer"?"Transfer":x, 3])}
                                                onMouseLeave={() => setHovered([selection[0] === "transfer"?"Transfer":lastSelection[0], lastSelection[1]])}
                                                onClick={() => setLevel(3, x)}
                                                onDoubleClick={catSelectorId ? selectCategory : undefined}
                                            >
                                                {/* Not tax allowable flag */}
                                                { (workingCategories.find(y => y.name === x)?.taxAllowableStatus === 'NotAllowed') &&
                                                    <FontAwesomeIcon size="xs" className={styles.taxFlag} icon={faFlag} />
                                                }
                                                <span>{ formatTypeString(x) }</span> 
                                            </div>
                                        )
                                    })}
                                    { (bookkeeping && selection[0] === "transfer" && !outgoing && showNewDirector) && (
                                        <div
                                            className={classNames(styles.categorySelectorItem)}
                                            onClick={showNewDirectorDialog}
                                        >
                                            <span style={{fontStyle: 'italic'}}>Create new director</span>
                                        </div>
                                    )}
                                    { (bookkeeping && selection[0] === "transfer" && outgoing && showNewMortgage) && (
                                        <div
                                            className={classNames(styles.categorySelectorItem)}
                                            onClick={showNewMortgageDialog}
                                        >
                                            <span style={{fontStyle: 'italic'}}>New mortgage</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                        : (
                            <div className={styles.top}>                
                                <div>
                                    <h5>Transaction type</h5>
                                    <LoadingPlaceholder width="200px" />
                                    <br />
                                    <LoadingPlaceholder width="200px" />
                                    <br />
                                    <LoadingPlaceholder width="200px" />
                                    <br />
                                </div>
                            </div>
                        )
                    }
                </div>

                {/* Bottom section */}
                <div className={styles.bottom} style={{padding: 0, border: 'none', marginTop: '10px'}}>
                    <div className={styles.helpTextBrowser} style={{alignSelf: 'stretch'}}>
                        { hovered && hovered[0] !== '' 
                            ? (
                                <>
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faCircleInfo} 
                                        style={{
                                            color: (hovered && hovered[1] === 3 && workingCategories.find(x =>
                                                x[levelKeys[hovered[1]]] as string === hovered[0])?.taxAllowableStatus === 'NotAllowed')
                                                    ? 'red'
                                                    : ''
                                        }}
                                    />
                                    <div style={{width: '100%'}}>
                                        <div style={{display: 'block',
                                                marginBottom: '7px',
                                                position: 'relative'}}>
                                            <b style={{
                                                color: (hovered && hovered[1] === 3 && workingCategories.find(x =>
                                                        x[levelKeys[hovered[1]]] as string === hovered[0])?.taxAllowableStatus === 'NotAllowed')
                                                            ? 'red'
                                                            : 'var(--text-feature-color-blue)',
                                                fontSize: '1.1em'
                                                
                                                }}
                                            >
                                                { formatTypeByLevel(hovered[1], hovered[0]) }:
                                            </b>

                                            {/* Might need this one day (eligible for assets) */}
                                            {/* { hovered && hovered[1] === 3 && (
                                                workingCategories.find(x => x[levelKeys[hovered[1]]] as string === hovered[0])?.isEligibleForAssets ||
                                                workingCategories.find(x => x[levelKeys[hovered[1]]] as string === hovered[0])?.linkedID === 'OtherGoods') && (
                                                    <span style={{position: 'absolute', right: 0}}>
                                                        <Badge variant="secondary" pill className="ml-1">Eligible for assets</Badge>
                                                    </span>
                                                )
                                            } */}
                                        </div>
                                        { shownDescription }

                                        {hovered && hovered[1] === 3 && workingCategories.find(x =>
                                            x[levelKeys[hovered[1]]] as string === hovered[0])?.linkedID === "AssetSales" && !hasSellableAssets && 
                                            <p style={{color: "var(--danger)"}}><b>Sorry, there are currently no assets available to sell.</b></p> 
                                        }
                                    </div>
                                </>
                            )
                            : (
                                bookkeeping 
                                    ? (
                                        <div style={{width: '100%'}}>
                                            <b style={{
                                                color: 'var(--text-feature-color-blue)',
                                                display: 'block',
                                                marginBottom: '7px',
                                                fontSize: '1.1em'
                                            }}>
                                                Multiple categories?
                                            </b>
                                            If a transaction consists of multiple categories you will need to split
                                            the transaction into multiple records, you can do this after entering the details for the first category.
                                        </div>
                                    )
                                    : (
                                        <div style={{width: '100%'}} className="centerFlexContentBoth text-muted">
                                            <i>Browse the categories above to begin</i>
                                        </div>

                                    )
                            )
                        }
                    </div>
                </div>
            </Modal>

            { modalDialog }
        </>
    )
}

export default CategorySelectorBrowser;
