import { useEffect, useState } from "react";
import { PvSatrPostModel } from "../../../../api/inni/data-contracts";
import FormikField from "../../../../elements/FormikField/FormikField";
import styles from '../Income.module.scss'
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";

export default function Outgoings (){
    const { values, setFieldValue } = useFormikContext<PvSatrPostModel>();
    const[showMileageDetails, setShowMileageDetails] = useState(!!(values.mileageDetails))
    
    useEffect(( )=>{
        if(values && !values.hasDonatedToCharity)
            setFieldValue("charityDonationAmount", 0, false)
    },[values?.hasDonatedToCharity])

    useEffect(( )=>{
        if(values && !values.hasPersonalPensionPayments)
            setFieldValue("pensionPaymentAmount", 0, false)
    },[values?.hasPersonalPensionPayments])

    useEffect(() =>{
        if(!showMileageDetails)
        {
            setFieldValue("mileageDetails", undefined, false)
        }
    },[showMileageDetails])
    
    return(
        <>
            <FormikField<PvSatrPostModel> 
                className={styles.formikFieldsAuto}
                name={"isRequiredToPayStudentLoan"}
                label="Did you pay towards a student loan in this tax year?"
                type="checkbox"                                   
                regularLabel
                wrapLabel
            />

            <FormikField<PvSatrPostModel> 
                className={styles.formikFieldsAuto}
                name={"hasDonatedToCharity"}
                label="Have you donated to charity in this tax year?"
                type="checkbox"                                   
                regularLabel
                wrapLabel
            />

            {values?.hasDonatedToCharity && 
                <>
                    <FormikField<PvSatrPostModel> 
                        className={styles.formikNumberField}
                        name={"charityDonationAmount"}
                        label="Total charity donation amount"
                        type="number"
                        step="0.01" 
                        prefix="£"
                        min="0"
                        onlyPositiveNumbers                                     
                        regularLabel
                    />
                    <br />
                </>
            }
            
            <FormikField<PvSatrPostModel> 
                className={styles.formikFieldsAuto}
                name={"hasPersonalPensionPayments"}
                label="Have you made personal pension contributions in this tax year?"
                type="checkbox"                                   
                regularLabel
                wrapLabel
            />

            {values?.hasPersonalPensionPayments && 
                <>
                    <FormikField<PvSatrPostModel> 
                        className={styles.formikNumberField}
                        name={"pensionPaymentAmount"}
                        label="Pension contribution amount"
                        type="number"
                        step="0.01" 
                        prefix="£"
                        min="0"
                        onlyPositiveNumbers                                     
                        regularLabel
                    />
                    <br />
                </>
            }

            <Form.Group>
                <Form.Label>Do you want to claim any mileage that's not related to your property business, e.g. mileage not fully reimbursed by an employer?</Form.Label>
                    <Form.Check
                        type='checkbox'
                        checked={showMileageDetails}
                        onChange={(e) => setShowMileageDetails(e.target.checked)}
                    />
            </Form.Group>

            {showMileageDetails &&
                <FormikField<PvSatrPostModel> 
                    className={styles.formikField}
                    name={"mileageDetails"}
                    label="Please provide the total applicable distance travelled and by what mode of transport, for example: car, van, motorcycle, or bicycle"
                    type="textarea"                                   
                    regularLabel
                    wrapLabel
                />
            }
            

        </>  
    )
}