import { Button } from "../../../elements/Button/Button";
import { BrandWrapper } from "../../../components/BrandWrapper/BrandWrapper";
import ZoomImage from "../../../elements/ZoomImage/ZoomImage";
import ReceiptPage2 from "./ReceiptPage2";
import ReceiptPage3 from "./ReceiptPage3";
import ReceiptPage4 from "./ReceiptPage4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from '../Receipts.module.css';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { useContext, useEffect, useState } from "react";
import ReceiptPage1 from "./ReceiptPage1";
import { useInniAPI } from "../../../hooks/useInniAPI";
import { Receipts as ReceiptsAPI } from '../../../api/inni/Receipts'
import CompanyContext from "../../../context/CompanyContext";
import { Account, BankTransactionPostModel, Receipt } from "../../../api/inni/data-contracts";
import { ReceiptAccountTypes } from "../Receipts";
import { asYearMonthDay } from "../../../utils/formatDate";
import { useModalDialog } from "../../../hooks/useModalDialog";
import { FormikErrors, FormikHelpers } from "formik";
import useIsMobile from "../../../hooks/useIsMobile";
import { useFetchEntityList } from "../../../hooks/entities/useFetchEntityList";
import { Accounts } from "../../../api/inni/Accounts";
import { Modal } from "../../../components/Modal/Modal";

interface ReceiptModalProps{
    receiptId: number;
    deleteReceipt : (i : number) => void,
    attachReceipt : (id : number, bpid : number) => void
    setShowImageModal: (i: boolean) => void;
    formSubmit: (values: BankTransactionPostModel & {isAsset: boolean}, actions: FormikHelpers<any>) => Promise<void>;
    formValidate: (values: BankTransactionPostModel & { isAsset: boolean; }) => Promise<FormikErrors<BankTransactionPostModel & { isAsset: boolean; }>>;
}

const initialValues: BankTransactionPostModel = {
    date: asYearMonthDay(new Date()),
    description: '',
    amount: 0.00,
    accountId: 0,
    isBillable: false,
    isConfirmed: false,
    bankAccountId: 0,
    employeeId: 0,
    type: 'EX',
    isReplacementItem: false,
    increasesPropertyValue: false,
}

const ReceiptModal=({ receiptId, deleteReceipt, attachReceipt, formSubmit, formValidate, setShowImageModal}: ReceiptModalProps)=> {
    
    const companyContext = useContext(CompanyContext);
    const receiptAPI = useInniAPI(ReceiptsAPI);
    const [pageNum, setPageNum] = useState<number>(1);
    const [receiptData, setReceiptData] = useState<Receipt|undefined>();
    const [showModalDialog, modalDialog] = useModalDialog();
    const isMobile = useIsMobile()
    const [accountId, setAccountId] = useState<number>(-1);
    const [accountType, setAccountType] = useState<ReceiptAccountTypes>('personal');
    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts)
    
    useEffect(() =>{
        if(receiptAPI && receiptId!==0){
            receiptAPI.getReceipt(companyContext.cid, receiptId)
                .then((res) =>{
                    setReceiptData(res.data)
                    setShowImageModal(true)
                })
        }
    },[receiptId, companyContext.cid, receiptAPI])

    const showDeleteDialog = (id: number) => {
        showModalDialog(
            'Delete receipt?',
            'Are you sure you want to delete this receipt?',
            [
                <Button variant="danger" label="Yes" onClick={() => deleteReceipt(id)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const hideModal = () => {
        setShowImageModal(false); 
        setPageNum(1)
    }

    return(
        <>
            {receiptData &&
                <Modal
                    showModal
                    size="xl"
                    title="Attach receipt"
                    hideModal={hideModal}
                    buttons={[
                        pageNum !== 1 && (
                            <Button key="backBtn" variant="primary" onClick={() => setPageNum(pageNum-1)} mobile={isMobile}>
                                { !isMobile && <FontAwesomeIcon icon={faChevronLeft}/> } Back
                            </Button>
                        ),
                        receiptId !== undefined && (
                            <Button key="delBtn" buttonType="delete" onClick={() => receiptId !== undefined && showDeleteDialog(receiptId)} mobile={isMobile} />
                        ),
                        <a key="downloadLink" href={receiptData?.imageDownloadUrl} style={isMobile ? {width: '100%', marginRight: '7px'} : {}}>
                            <Button buttonType="download" mobile={isMobile} />
                        </a>
                    ]}
                    showCloseBtn={(isMobile && pageNum !== 1) ? false : true}>
                        {/* add brand wrapper to get css vars in modal as it is rendered outside the root ;) */}
                        <BrandWrapper>
                            <div className={styles.rcpModal}>
                                <div className={styles.lhs}>
                                    {receiptData?.imageUrl?.indexOf('.pdf') !== -1 ? 
                                    <div className={styles.altText}>
                                        Sorry, this PDF does not have a thumbnail yet. <br/> Alternatively, you can use the download link below
                                    </div> : 
                                    <ZoomImage 
                                        src={receiptData?.imageUrl || ''} // this will be a thumbnailed pdf or full size img
                                        alt="Receipt" 
                                    />}
                                </div>
                                {/* <img style={{width: '40%'}} src={receiptData.find(x => x.id === valuationModalId)?.imageUrl} alt="Receipt"/> */}
                                <div className={styles.rhs}>
                                    { pageNum === 1 && receiptId !== undefined && <ReceiptPage1 attachReceipt={attachReceipt} id={receiptId} 
                                    date={receiptData?.date || asYearMonthDay(new Date())} changePageCB={setPageNum}/>}
                                    { pageNum === 2 && <ReceiptPage2 setAccountType={setAccountType} changePageCB={setPageNum}/>}
                                    { pageNum === 3 && <ReceiptPage3 
                                        accountsData={accounts}
                                        changeAccountId={setAccountId} 
                                        changePageCB={setPageNum} 
                                        accountType={accountType}
                                    />}
                                    { pageNum === 4 && <ReceiptPage4
                                    initialValues={initialValues} 
                                    accountId={accountId}
                                    formSubmit={formSubmit} 
                                    formValidate={formValidate} 
                                    />}
                                </div>
                            </div>
                        </BrandWrapper>
                </Modal>
            }    
            {modalDialog}
        </>
        
    )
}

export default ReceiptModal;