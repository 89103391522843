import provestorLogoLight from './images/provestor-logo-light.svg';
import provestorLogoDark from './images/provestor-logo-dark.svg';
import provestorLogoWhite from './images/provestor-logo-white.svg';
import favIcon from './images/provestor-icon-sq-192.png';
import { Brand } from '../../context/BrandContext';

const provestorBrand: Brand = { 
    name: 'Provestor',
    legalName: 'Provestor Accounts Ltd',
    logoLightUrl: provestorLogoLight,
    logoDarkUrl: provestorLogoDark,
    logoWhiteUrl: provestorLogoWhite,
    favIconUrl: favIcon,
    cssVariablesClassName: 'provestor'
}

export default provestorBrand; 