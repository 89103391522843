/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CombinedOpenBankingAccountsResponse,
  CreatedBookkeepingAccountResponse,
  ImportOpenBankingAccountRequest,
  ImportOpenBankingAccountResponse,
  OpenBankingAccountsResponse,
  ReconnectOpenBankingAccountRequest,
  ReconnectOpenBankingAccountResponse,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class OpenBankingAccounts<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags OpenBankingAccounts
   * @name ListExternalOpenBankingAccounts
   * @request GET:/api/v2/companies/{companyId}/openbankingaccounts/listexternalopenbankingaccounts
   * @secure
   */
  listExternalOpenBankingAccounts = (companyId: number, query?: { consentId?: number }, params: RequestParams = {}) =>
    this.request<OpenBankingAccountsResponse, any>({
      path: `/api/v2/companies/${companyId}/openbankingaccounts/listexternalopenbankingaccounts`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OpenBankingAccounts
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/openbankingaccounts
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<OpenBankingAccountsResponse, any>({
      path: `/api/v2/companies/${companyId}/openbankingaccounts`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OpenBankingAccounts
   * @name ImportOpenBankingAccount
   * @request POST:/api/v2/companies/{companyId}/openbankingaccounts/importopenbankingaccount
   * @secure
   */
  importOpenBankingAccount = (
    companyId: number,
    request: ImportOpenBankingAccountRequest,
    params: RequestParams = {},
  ) =>
    this.request<ImportOpenBankingAccountResponse, any>({
      path: `/api/v2/companies/${companyId}/openbankingaccounts/importopenbankingaccount`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OpenBankingAccounts
   * @name CreateBookkeepingAccount
   * @request POST:/api/v2/companies/{companyId}/openbankingaccounts/createbookkeepingaccount
   * @secure
   */
  createBookkeepingAccount = (companyId: number, query: { openBankingAccountId: number }, params: RequestParams = {}) =>
    this.request<CreatedBookkeepingAccountResponse, any>({
      path: `/api/v2/companies/${companyId}/openbankingaccounts/createbookkeepingaccount`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OpenBankingAccounts
   * @name ReconnectOpenBankingAccount
   * @request POST:/api/v2/companies/{companyId}/openbankingaccounts/reconnectopenbankingaccount
   * @secure
   */
  reconnectOpenBankingAccount = (
    companyId: number,
    request: ReconnectOpenBankingAccountRequest,
    params: RequestParams = {},
  ) =>
    this.request<ReconnectOpenBankingAccountResponse, any>({
      path: `/api/v2/companies/${companyId}/openbankingaccounts/reconnectopenbankingaccount`,
      method: "POST",
      body: request,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OpenBankingAccounts
   * @name ListCombinedOpenBankingAccounts
   * @request GET:/api/v2/companies/{companyId}/openbankingaccounts/listcombinedopenbankingaccounts
   * @secure
   */
  listCombinedOpenBankingAccounts = (
    companyId: number,
    query: {
      dataProcessorTypeId:
        | "NotSupported"
        | "HSBCScraper"
        | "HSBCnet"
        | "HSBCText"
        | "MetroBankCSV"
        | "BarclaysBBIZ"
        | "GenericCSV"
        | "Santander"
        | "CaterAllen"
        | "Lloyds"
        | "RBS"
        | "NatWest"
        | "CashPlus"
        | "OpenBankingStep1"
        | "ModeloOpenBanking"
        | "BankOfFakeOB"
        | "StarlingBank"
        | "BarclaysCsvOfx"
        | "CaterAllenNew"
        | "StarlingCSV"
        | "BarclaysOpenBanking"
        | "HSBCOpenBanking"
        | "SandboxBarclaysYapily"
        | "SandboxStarlingBankYapily"
        | "StarlingBankYapily"
        | "BarclaysBusinessYapily"
        | "SandboxSantanderYapily"
        | "SantanderUKYapily"
        | "SandboxHSBCYapily"
        | "HSBCBusinessUKYapily"
        | "SandboxCashPlusYapily"
        | "CashPlusYapily"
        | "CaterAllenYapily"
        | "SandboxLloydsYapily"
        | "LloydsBusinessYapily"
        | "SandboxNatwestYapily"
        | "NatwestYapily"
        | "SandboxRBSYapily"
        | "RBSYapily"
        | "MonzoYapily"
        | "TideYapily"
        | "RevolutYapily"
        | "TsbYapily"
        | "YorkshireBankYapily"
        | "ClydesdaleYapily"
        | "NationwideYapily"
        | "MBNA"
        | "BankOfScotland"
        | "BarclaycardPersonal"
        | "HalifaxPersonal"
        | "BarclaycardBusiness"
        | "VirginMoney"
        | "VirginMoneyEssentials"
        | "HSBCPersonal"
        | "SandboxModeloYapily"
        | "OpenBankingDemo"
        | "Wise"
        | "LloydsPersonal"
        | "UlsterBankYapily"
        | "CapitalOneYapily"
        | "MettleYapily"
        | "NatwestSandboxYapily"
        | "AmericanExpressUKYapily"
        | "FirstDirectYapily"
        | "CooperativeBankYapily"
        | "CooperativeBankSandboxYapily"
        | "MetroBankYapily"
        | "CouttsAndCompanyYapily"
        | "HSBCKineticYapily"
        | "ChaseUKYapily"
        | "key86Yapily"
        | "key87Yapily"
        | "key88Yapily"
        | "key89Yapily"
        | "key90Yapily";
    },
    params: RequestParams = {},
  ) =>
    this.request<CombinedOpenBankingAccountsResponse, any>({
      path: `/api/v2/companies/${companyId}/openbankingaccounts/listcombinedopenbankingaccounts`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
