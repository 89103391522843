import BasicDetails from '../../features/SATR/BasicDetails/BasicDetails';
import PropertyLoses from '../../features/SATR/BasicDetails/PropertyLoses';
import PropertyDetails from '../../features/SATR/SATRPropertyDetails';
import Income from '../../features/SATR/Income/Income';
import { EntityRoute, RouteContext } from './routeTypes';
import SATRWelcome from '../../features/SATR/SATRWelcome';
import HoldingPage from '../../features/SATR/HoldingPage';
import SATRFinances from '../../features/SATR/SATRFinances';
import SATRSubmission from '../../features/SATR/SATRSubmit';
import SATRFinanceUploads from '../../features/SATR/SATRFinanceUploads';

const SATR:Array<EntityRoute> = [
    {
        path: '/company/:cid/SATR/redirect',
        exact: true,
        context: RouteContext.Company,
        component: HoldingPage
    },
    {
        path: '/company/:cid/SATR/basic',
        exact: true,
        context: RouteContext.Company,
        component: BasicDetails
    },
    {
        path: '/company/:cid/SATR/past-balances',
        exact: true,
        context: RouteContext.Company,
        component: PropertyLoses
    },
    {
        path: '/company/:cid/SATR/properties',
        exact: true,
        context: RouteContext.Company,
        component: PropertyDetails
    },
    {
        path: '/company/:cid/SATR/welcome',
        exact: true,
        context: RouteContext.Company,
        component: SATRWelcome
    },
    {
        path: '/company/:cid/SATR/financial-records',
        exact: true,
        context: RouteContext.Company,
        component: SATRFinanceUploads
    },
    {
        path: '/company/:cid/SATR/income',
        exact: true,
        context: RouteContext.Company,
        component: Income
    },
    {
        path: '/company/:cid/SATR/Submit',
        exact: true,
        context: RouteContext.Company,
        component: SATRSubmission
    },
]

export default SATR;