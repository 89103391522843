import { FormikErrors, FormikTouched } from 'formik'

// Hook to check if a provided array of fields type T are valid if not set errors + return false
// Initially used to validate multi page forms but may be useful elsewhere... (may need a rename)
// There is a slight limitation in that if we have 2 'levels' of validaiton on a model e.g. annotations
// and IVali..Obj.. then this won't work as an error on page one could appear AFTER we have gone passed
// maybe something to look into fixing
export const useValidateFormikPage = <T>() : (
    pageFields: Array<keyof T>,
    setTouched: (touched: FormikTouched<T>, shouldValidate?: boolean | undefined) => void, 
    validateForm: (values?: any) => Promise<FormikErrors<T>>
) => Promise<boolean> => {

    return async (
        pageFields: Array<keyof T>,
        setTouched: (touched: FormikTouched<T>, shouldValidate?: boolean | undefined) => void, 
        validateForm: (values?: any) => Promise<FormikErrors<T>>
    ) => {
        // Validate using API endpoint
        let errors = await validateForm()

        // are any of the current pages fields in the array
        let error = !pageFields.every(x => errors[x] === undefined)

        if (!error) {
            return true
        } else {
            setTouched(pageFields.reduce((a, b) => {
                return {...a, [b] : true}
            }, {}), true)
            return false
        }
    }

}
