/**
 * @prettier
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { Badge } from 'react-bootstrap';
import styles from './ExpandedViewBackLabel.module.css';

interface ExpandedViewBackLabelProps {
  children?: React.ReactNode;
  backLabel: string;
  title?: string;
  onClick: () => void;
  label?: string; // label needs expanding for different colours etc.
}

export const ExpandedViewBackLabel = ({ children, backLabel, title, onClick, label }: ExpandedViewBackLabelProps) => {
  const childArray = React.Children.toArray(children);
  const isToolbarItem = (child: React.ReactNode) => React.isValidElement(child) && child.props.__TYPE === 'DefaultToolbar';
  const toolbarChildren = childArray.filter((child) => isToolbarItem(child));

  return (
    <div id={styles.expandedView}>
      <h5 className="mb-5 mt-1">
        <span onClick={onClick}>
          <FontAwesomeIcon icon={faArrowLeft} className={styles.backArrow} />
          {backLabel}
        </span>
      </h5>

      <div className={styles.toolBar}>
        {title && (
          <span className="d-flex">
            <h2>{title}</h2>
            {label && (
              <Badge pill className={styles.badge}>
                {label}
              </Badge>
            )}
          </span>
        )}

        {toolbarChildren?.length > 0 && <>{toolbarChildren}</>}
      </div>
    </div>
  );
};
