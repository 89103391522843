import classNames from "classnames";
import { LoadingPlaceholder } from "../LoadingPlaceholder/LoadingPlaceholder";
import styles from "./LoadingOnboardingSetup.module.css"


export default function  LoadingSetup(){

    return(
        <LoadingStage/>
    );

}


function LoadingStage (){
    return(
        <div className={styles.loadingStageLayout}>
            <div className={styles.loadingStage}>
                <div className={styles.loadingStageInner}>
                    <div className={classNames( styles.loadingStageNumber, styles.loading)}/>
                    <div className={styles.loadingStageDetails}>
                        <LoadingPlaceholder width="30%"/>
                        <div>
                            <LoadingPlaceholder />
                            <LoadingPlaceholder />
                            <LoadingPlaceholder />
                        </div>
                        
                    </div> 
                </div>
                <div className={classNames(styles.loadingButton, styles.loading)}/>
            </div>

        </div>
    );
}


