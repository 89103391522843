import { CompanyPAYESettingsPostModel, CompanySettingView } from '../../../api/inni/data-contracts'
import useCompareChange from '../../../hooks/useCompareChange';
import { Form, Formik } from 'formik';
import { DateSelector, ListOption, SelectList, Submit, Switch, Text } from '../../../elements/EditRow/EditRow';
import { CompanySettingsProps,EnumMapper } from '../CompanySettingsDataTypes';
import InfoBanner from '../../../elements/InfoBanner/InfoBanner';
function PayeSettingsTab<T extends CompanyPAYESettingsPostModel & Partial<CompanySettingView>>({
  settings,
  handleSubmit,
  handleValidate
}: CompanySettingsProps<T>) {

  const formikRef = useCompareChange<T>();
  const payrollInterval = [
    { value: 'Y', label: 'Years' },
    { value: 'M', label: 'Months' }
  ]

  const employeeAllowanceEligibility : EnumMapper<CompanyPAYESettingsPostModel['employmentAllowanceEligibility']>[] = [
    {value:'Automatic', label:'Automatic'},
    {value:'Eligible', label:'Eligible'},
    {value:'NotEligible', label:'Not eligible'}
  ];
{
  const payeAccountsOffice :ListOption[] = [{
    value:'S',
    label:'Shipley'
  },{
    value:'C',
    label:'Cumbernauld'
  }]

  return (
    <>
    <Formik
      initialValues={settings}
      onSubmit={handleSubmit}
      validate={handleValidate}
      enableReinitialize
      innerRef={formikRef}
      validateOnBlur
      validateOnChange={false}
    >
      {({ isSubmitting,dirty,values }) => (
        <Form>
        {values.schemeCloseDateReadOnlyReason &&
        <InfoBanner
          title=""
          body={`${values.schemeCloseDateReadOnlyReason}`}
          type="info"
          thin
        />}
       {!values.schemeCloseDateReadOnlyReason &&
        <DateSelector<CompanyPAYESettingsPostModel> name="payeSchemeCloseDate" label="PAYE scheme close date" />}
        <Text<CompanyPAYESettingsPostModel> name="payeOfficeNo" label="PAYE office number" />
        <Text<CompanyPAYESettingsPostModel> name="payeOfficeRef" label="PAYE office reference" />
        <SelectList<CompanyPAYESettingsPostModel> name="payeAccountsOffice" label="PAYE accounts office" options={payeAccountsOffice}/>
        <Text<CompanyPAYESettingsPostModel> name="payeAccountsOfficeRef" label="PAYE accounts office reference" />
        <Switch<CompanyPAYESettingsPostModel> name="payeQuarterly" label="PAYE quarterly" />
        <Text<CompanyPAYESettingsPostModel> name="payrollInterval" label="Payroll interval" />
        <SelectList<CompanyPAYESettingsPostModel> name="payrollIntervalType" label="Payroll interval type" options={payrollInterval} />
        <Text<CompanyPAYESettingsPostModel> name="payrollDay" label="Payroll day" type='number' />
        {values.minNextPayrollDateMessage &&
        <InfoBanner
          title=""
          body={values.minNextPayrollDateMessage}
          type="info"
          thin
        />}
        <DateSelector<CompanyPAYESettingsPostModel> name="nextPayrollDate" label="Next payroll date" />
        <DateSelector<CompanyPAYESettingsPostModel> name="rtiEnabledFrom" label="RTI enabled from" />
        <Switch<CompanyPAYESettingsPostModel> name="payeRemindersDisabled" label="PAYE reminders disabled" />
        <Switch<CompanyPAYESettingsPostModel> name="payrollRemindersDisabled" label="Payroll reminders disabled" />
        <SelectList<CompanyPAYESettingsPostModel> name="employmentAllowanceEligibility" label="Employment allowance eligibility"
         options={employeeAllowanceEligibility}/>
        <Submit disabled={isSubmitting || !dirty} onCancelClick={() => {}} />
        </Form>
      )}
    </Formik>
    </>
  )
}
}

export default PayeSettingsTab