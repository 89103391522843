import * as DataGrid from '../../../../elements/DataGrid/DataGrid';
import { formatDate } from '../../../../utils/formatDate';
import { NoContentSlate } from '../../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../../hooks/terms/useDatagridTerms';
import { Action, Entity } from '../../../../utils/EntityAction';
import { Button } from '../../../../elements/Button/Button';
import { FormikRowInput, FormikRowLogic, FormikRowSubmitBtns } from '../../../../elements/FormikTableEditor/FormikTableEditor';
import { useHasPermission } from '../../../../hooks/useHasPermission';
import React, { useContext, useEffect, useState } from 'react';
import { Person, Shareholding, ShareholdingPostModel } from '../../../../api/inni/data-contracts';
import { FormikErrors, FormikHelpers } from 'formik';
import { CogOptionsDropdown } from '../../../../elements/CogOptionsDropdown/CogOptionsDropdown';
import { useModalDialog } from '../../../../hooks/useModalDialog';
import CompanyContext from '../../../../context/CompanyContext';
import { useNavigateToEntity } from '../../../../hooks/useNavigateToEntity';
import BlankStateCard from '../../../../components/BlankStateCard/BlankStateCard';
import { getStoryblokApi, SbBlokData } from '@storyblok/react';

export interface ShareholdingsTableProps {
    shareholdings: Shareholding[],
    person: Person,
    onCreate: (values: ShareholdingPostModel, actions: FormikHelpers<ShareholdingPostModel>) => Promise<number>,
    validateCreate: (values: ShareholdingPostModel) => Promise<FormikErrors<ShareholdingPostModel>>,
    onUpdate: (values: ShareholdingPostModel, actions: FormikHelpers<ShareholdingPostModel>, id: number) => Promise<boolean>,
    validateUpdate: (id: number, values: ShareholdingPostModel) => Promise<FormikErrors<ShareholdingPostModel>>,
    onDelete: (id: number) => Promise<void>
}

export const ShareholdingsTable = ({shareholdings, person, onCreate, validateCreate, onUpdate, validateUpdate, onDelete}:ShareholdingsTableProps) => {
    const [showAddNewRow, setShowAddNewRow] = useState<boolean>(false);
    const [editingRowId, setEditingRowId] = useState<number|null|undefined>();
    const hasPermission = useHasPermission();
    const [showModalDialog, modalDialog] = useModalDialog();
    const companyContext = useContext(CompanyContext)
    const v8Styling = companyContext.company?.useV8UI || false;
    const navigateToEntity = useNavigateToEntity();
    const [blankSlateInfo, setBlankSlateInfo]= useState<SbBlokData>();
    const hasDIYShares = hasPermission(Entity.Shareholdings, Action.CreateWizard)

    const newEntry:ShareholdingPostModel = {
        personId: person.id,
        dateFrom: "",
        capitalPerShare: 0,
        shareClass: "",
        numberOfShares: 0
    }

    const formik = FormikRowLogic<ShareholdingPostModel>({
        validateCreate: validateCreate, 
        validateUpdate: validateUpdate, 
        onCreate: onCreate, 
        onUpdate: onUpdate, 
        editRowValues: (editingRowId === null || editingRowId === undefined) ? newEntry : shareholdings.find(x => x.id === editingRowId), 
        addingNewRow: editingRowId === null || editingRowId === undefined,
        id: editingRowId || undefined
    })

    useEffect(()=>{
        const storyblokApi = getStoryblokApi();
        storyblokApi.get( hasDIYShares[0] ? 'cdn/stories/blank-slate/shares' :  'cdn/stories/blank-slate/shares-advisory', { version: 'published'})
        .then(res => {
            setBlankSlateInfo(res.data.story.content)
        }).catch(err => {
            console.error(err)
        });
    },[])
      
    const handleSubmit = () => {
        if(!formik.isSubmitting) formik.submitForm().then((newIdOrSuccess:number|void|boolean) => {
            if(newIdOrSuccess !== undefined && newIdOrSuccess !== false) {
                handleClose()
            }
        })
    }

    const handleClose = () => {
        setShowAddNewRow(false);
        setEditingRowId(null)
        formik.setValues(newEntry);
        formik.setErrors({})
    }

    const showDeleteDialog = (row: Shareholding) => {
        showModalDialog(
            'Delete shareholding?',
            'Are you sure you want to delete this entry?',
            [
                <Button variant="danger" label="Yes" onClick={() => onDelete(row.id)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }


    const renderCreateEditRow = () => {
        return (<React.Fragment key="createEditRow">
            <tr>
                <td style={{verticalAlign: 'top', paddingTop: '1.3rem'}}>{person.name}</td>
                <td style={{verticalAlign: 'top'}}><FormikRowInput<ShareholdingPostModel> formik={formik} property="numberOfShares" type="number" inputProps={{min:0}}/></td>
                <td style={{verticalAlign: 'top'}}><FormikRowInput<ShareholdingPostModel> formik={formik} property="shareClass" type="text" /></td>
                <td style={{verticalAlign: 'top'}}><FormikRowInput<ShareholdingPostModel> formik={formik} property="capitalPerShare" type="number" inputProps={{min:0}}/></td>
                <td style={{verticalAlign: 'top'}}><FormikRowInput<ShareholdingPostModel> formik={formik} property="dateFrom" type="date" /></td>
                <td style={{verticalAlign: 'top'}}>{<FormikRowInput<ShareholdingPostModel> formik={formik} property="dateTo" type="date" />}</td>
                <td style={{ verticalAlign: 'top', minWidth: '190px', textAlign: "right"}}><FormikRowSubmitBtns handleSubmit={handleSubmit} handleClose={handleClose}/></td>
            </tr>
        </React.Fragment>)
    }

    const handleEdit = (holdingId: number) =>{
        handleClose();
        setEditingRowId(holdingId)
    }

    return (<>
    {(shareholdings.length > 0 || !v8Styling || hasDIYShares[1] ) &&
    <DataGrid.Table noHover>
        <thead>
            <tr>
                <th style={{width:'15.66%'}}>Name</th>
                <th style={{width:'15.66%'}}>Shares</th>
                <th style={{width:'15.66%'}}>Share class</th>
                <th style={{width:'15.66%'}}>Capital per share</th>
                <th style={{width:'15.66%'}}>From</th>
                <th style={{width:'15.66%'}}>To</th>
                { hasPermission(Entity.Shareholdings, Action.Edit)[1] &&
                    <th style={{width:'6%', minWidth: '190px'}}></th>
                }
                
            </tr>
        </thead>
        <tbody>
            <>
                {shareholdings.length > 0 ? (
                        shareholdings.map(holding => 
                            editingRowId === holding.id ?
                            renderCreateEditRow() :
                            <tr key={holding.id}>
                                <td>{person.name}</td>
                                <td>{holding.numberOfShares}</td>
                                <td>{holding.shareClass}</td>
                                <td>{holding.capitalPerShare}</td>
                                <td>{formatDate(holding.dateFrom)}</td>
                                <td>{formatDate(holding.dateTo)}</td>
                                {(hasPermission(Entity.Shareholdings, Action.Edit)[0] || hasPermission(Entity.Shareholdings, Action.Delete)[0])&&
                                    <td style={{textAlign: "right", paddingRight: "1.5rem"}}> 
                                        <CogOptionsDropdown 
                                            edit={hasPermission(Entity.Shareholdings, Action.Edit)[0] ? () => handleEdit(holding.id) : undefined}
                                            del={hasPermission(Entity.Shareholdings, Action.Delete)[0] ? () => showDeleteDialog(holding) : undefined}
                                            hamburger={v8Styling}
                                        />
                                    </td>
                                }
                            </tr>
                        )
                    )
                    : (
                        !showAddNewRow && <tr>
                            <td colSpan={7} style={{ padding: 0 }}>
                                <NoContentSlate type={DatagridType.Shareholdings} termsKey='emptyTerms' /> 
                            </td>
                        </tr>
                    )
                }

                {!showAddNewRow && hasPermission(Entity.Shareholdings, Action.Create)[0] && 
                    <tr>
                        <td colSpan={7}>
                            <Button 
                                onClick={() =>  setShowAddNewRow(true)} 
                                buttonType="new" 
                                action={Action.Create} 
                                entity={Entity.Shareholdings}
                            />
                        </td>
                    </tr>
                }
                {showAddNewRow && renderCreateEditRow()}
            </>
        </tbody>
    </DataGrid.Table>
    }

    {shareholdings.length === 0 && v8Styling && !hasDIYShares[1] &&
        <BlankStateCard 
            content={blankSlateInfo} 
            buttonEntity={Entity.Shareholdings}
            buttonAction={Action.CreateWizard}
        />                     
    }

    { modalDialog }
    </>)
}