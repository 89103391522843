import { Card } from 'react-bootstrap';
import { useGetUrl } from '../../../hooks/useGetUrl';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { Entity, Action } from '../../../utils/EntityAction';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import styles from '../Reporting.module.css';


export const ReportSelectCardPlaceholder = () => {
    return (
        <Card style={{ width: '25%' }}>
            <Card.Body className={styles.reportCard}>
                <Card.Title>
                    <LoadingPlaceholder width='60%' />
                </Card.Title>
                <Card.Text>
                    <LoadingPlaceholder width='100%' />
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export interface ReportSelectCardProperties {
    title: string,
    desc: string,
    filename: string,
    admin?: boolean,
    isCAP?: boolean
};

export const ReportSelectCard = ({title, desc, filename, admin= false, isCAP} : ReportSelectCardProperties) => {
    const navigateToEntity = useNavigateToEntity();
    const getUrl = useGetUrl();
    
    return (
        <Card onClick={() =>isCAP ? navigateToEntity(Entity.BookkeepingV8, undefined, {"activePage": "reports", "id" :filename ? filename : '' }) :  navigateToEntity(Entity.ReportV7, Action.Read, {id: filename ? filename : ''})} style={{ height: '100%', borderRight: admin ? '3px solid var(--admin-only-color)' : ''}}>
            <Card.Body className={styles.reportCard}>
                <Card.Title>{title}</Card.Title>
                <Card.Text className="text-muted">
                    {desc}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}
