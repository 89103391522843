

interface dividerProps {
	width?: string,
    margin?: string
}

export default function Divider({width, margin}: dividerProps){

    return(
        <div 
            style={{
                width: width ? width : '100%',
                borderBottom: "1px solid var(--entity-border-color)",
                margin: margin? margin: "1rem 0px"
            }}
        />
    )
}