import React, { useContext } from 'react';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder';
import styles from './DashboardCards.module.scss'
import stylesV75 from './DashboardCards_V75.module.scss';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';

export interface CardWrapperProps {
    label: string,
    helpText?: string,
    loading?: boolean,
    children? : React.ReactNode,
    height?: number,
    className?: string,
    controls? : React.ReactNode
    v75LightBg? : boolean;
}

export const CardWrapper = ({label, helpText, loading, children, height, className, controls, v75LightBg}: CardWrapperProps) => {
    const companyContext = useContext(CompanyContext);
    const styling = (companyContext.company?.useV8UI) ? stylesV75 : styles;

    return (
        <div
            className={`
                ${styling.cardWrapper}
                ${styling['height-' + (height || 1)]}
                ${className ? className : ''}
                ${v75LightBg ? styling.cardWrapperV75LightBg : ''}
            `}
        >
            <div className={styling.label}>{label}</div>
            {controls &&
                <div className={styling.controls}>{controls}</div>
            }            
            <div className={styling.value}>
                {loading && 
                    <LoadingPlaceholder width='50%' />
                }
                {!loading &&
                    <>{children}</>
                }
            </div>
            <div className={styling.helpText}>{helpText}</div>
        </div>
    )

}