export enum Entity {
    CompanyHomeRedirector,
    Contract,
    CompanyDashboard,
    PropertyDashboard,
    Time,
    Mileage,
    Expense,
    QuickEntry,
    Property,
    Tenancy,
    Invoice,
    BrandingTheme,
    PaymentProvider,
    Ir35ActionPlan,
    PersonalTaxPlanner,
    Payslip,
    Payroll,
    Dividend,
    OpenBankingDashboard,
    Bookkeeping,
    VatReturn,
    VatObligation,
    PayePayment,
    CorporationTax,
    Report,
    Asset,
    AssetV7,
    AssetAdmin,
    Adjustment,
    RegularPayment,
    Document,
    Receipt,
    Company,
    Person,
    WorkplacePension,
    AuditTrail,
    VatSetting,
    Account,
    Subscription,
    UserProfile,
    PropertyCertificate,
    Arthur,
    ReportV7,
    Reminders,
    MortgageAccount,
    MortgageAccountInterestRate,
    Consultation,
    Help,
    NoCompany,
    Ownership,
    Shareholdings,
    PersonV7,
    ExpenseImport,
    PortalConsultations,
    Incorporation,
    IncorporationDocuments,
    Contact,
    AdjustmentV7,
    SetupWizard,
    QuickEntryV7,
    SetupWizardOLD,
    SetupStatementImporting,
    UploadStatement,
    BookkeepingV7,
    EmployeeTaxCodes,
    Bookkeeping2022,
    InvoiceV7,
    ForeignInvoicing,
    InvoiceAdmin,
    PaymentProvidersList,
    Onboarding,
    LettingStatement,
    AccountingPeriodsReviewItem,
    // FactFinding,
    // Messages,,
    CompletionStatement,
    CompletionStatementAdmin,
    DocumentV7,
    ErrorPage,
    ExpenseImportV7,
    DividendV7,
    ExpenseAI,
    CompanySettingsV7,
    CompanySettingsAdmin,
    PropertyLettableUnit,
    PropertyAsset,
    PropertyTenure,
    AccountManagerSupport,
    LiveCash,
    AlphabetShares,
    AllLettingTypes,
    BillingV7,
    LinkMortgageAccount,
    TestData,
    OnboardingSetup,
    BankTransaction,
    OpenBankingAccounts,
    BankFeed,
    BookkeepingV8,
    OpenBanking,
    PropertyV8,
    ErrorMessage,
    CorporationTaxV7,
    PersonalTaxPlannerV8,
    PropertyTransactions,
    ExpensesV8,
    Valuation,
	DocumentAdmin,
    BookkeepingV6Admin
}


export enum Action {
    All,    // not an action, a permission
    Edit,
    List,
    Read,
    Create,
    Delete,
    AllPayments,    // Bookkeeping / admin
    UseGoCardless,   // Billing
    Search,
    LinkToExternal,
    ExportAll,
    AllPaymentsAdmin,
    CreateWizard
}