import { Form, Formik, FormikErrors } from 'formik'
import { Modal } from '../../../../components/Modal/Modal'
import { AddP45PostModel, PersonAsDetailed } from '../../../../api/inni/data-contracts'
import { Currency, DateSelector, ListOption, SelectList, Switch, Text } from '../../../../elements/EditRow/EditRow'
import { useContext, useState } from 'react'
import { payPeriodTypes } from '../../../../utils/commonLists'
import CompanyContext from '../../../../context/CompanyContext'
import { People } from '../../../../api/inni/People'
import { useInniAPI } from '../../../../hooks/useInniAPI'
import styles from '../../People.module.scss'
import { Button } from '../../../../elements/Button/Button'

const AddP45Modal = ({show, personId, reloadPeople, onClose, person} : {show : boolean, personId : number, reloadPeople : () => void, onClose : () => void, person?: PersonAsDetailed}) => {

    const [payPeriodSl] = useState<ListOption[]>(payPeriodTypes.map(x => ({ value: x.value, label: x.label } as ListOption)))

    const companyContext = useContext(CompanyContext)
    const peopleApi = useInniAPI(People, [400])

    const validateUpdate = (values: AddP45PostModel) : Promise<FormikErrors<AddP45PostModel>> => {
        return new Promise((resolve) => {
            if (peopleApi) {
                peopleApi.validateAddP45(companyContext.cid, personId, values)
                .then(() => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const updateCall = (values: AddP45PostModel) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (peopleApi) {
                peopleApi.addP45(companyContext.cid, personId, values)
                .then(res => {
                    if (res.status === 200) {
                        reloadPeople()
                        onClose()
                        resolve()
                    }
                }).catch((err) => {
                    reject(err.error)
                })
            }
        })
    }

    return (
        <Modal size="xl" showModal={show} hideModal={onClose} title="Add/update P45">            
            <Formik
                initialValues={{
                    leavingDate: person && person.employments ? person?.employments[person?.employments?.length-1]?.previousLeavingDate : "",
                    payToDate: person && person.employments ? person?.employments[person?.employments?.length-1]?.previousPayToDate : 0,
                    taxToDate: person && person.employments ? person?.employments[person?.employments?.length-1]?.previousTaxToDate : 0,
                    taxCode: person && person.employments ? person?.employments[person?.employments?.length-1]?.previousTaxCode : "",
                    _1WM: person && person.employments ? person?.employments[person?.employments?.length-1]?.previous1WM || false : false,  
                    taxOffice: person && person.employments ? person?.employments[person?.employments?.length-1]?.previousTaxOfficeAndRef?.split("/")[0] : "",
                    taxRef: person && person.employments ? person?.employments[person?.employments?.length-1]?.previousTaxOfficeAndRef?.split("/")[1] : "",
                    periodType: person && person.employments && person?.employments[person?.employments?.length-1]?.previousMonth ? "M" : "W",
                    period: person && person.employments ? person?.employments[person?.employments?.length-1]?.previousMonth ? 
                        person?.employments[person?.employments?.length-1]?.previousMonth : 
                        person?.employments[person?.employments?.length-1]?.previousWeek : 0
                } as AddP45PostModel}
                validateOnChange={false}
                enableReinitialize
                onSubmit={updateCall}
                validate={validateUpdate}
            >
                {() => (

                    <Form>
                    
                        <DateSelector<AddP45PostModel> labelTop name="leavingDate" />
                        <Currency<AddP45PostModel> flipToPositive name="payToDate" labelTop />
                        <Currency<AddP45PostModel> flipToPositive name="taxToDate" labelTop />
                        <Text<AddP45PostModel> name="taxCode" labelTop />
                        <Switch<AddP45PostModel> name="_1WM" labelTop label="1 week / month" />
                        <Text<AddP45PostModel> name="taxOffice" labelTop label="PAYE office No" />
                        <Text<AddP45PostModel> name="taxRef" labelTop label="PAYE ref No" />
                        <SelectList<AddP45PostModel> name="periodType" labelTop options={payPeriodSl} />
                        <Text<AddP45PostModel> min={0} name="period" type="number" labelTop label="Period number" />

                        <div className={styles.buttonContainer}>
                            <Button buttonType="cancel" onClick={onClose} />
                            <Button buttonType="save" submitButton />
                        </div>


                    </Form>
                )}
            </Formik>

        </Modal>
    )
}

export default AddP45Modal