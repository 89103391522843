import { BasicFormLayoutFullscreen } from '../../layouts/Desktop/BasicFormLayout';
import { Button } from '../../elements/Button/Button';
import Icon from '../../elements/Icon/Icon';
import { useStandaloneSATRStatus } from '../../hooks/useStandaloneSATRStatus';


const SATRWelcome = () => {

    const { completeStep } = useStandaloneSATRStatus();

    const handleOnContinueClick = () => {
        completeStep('SetupWelcomePage');
    }

    return (
        <BasicFormLayoutFullscreen
            title="Welcome to Provestor, let's simplify your tax return"
            storyblokDescriptionId="welcome-page"
        >
            {/* Buttons */}
            <div className="mt-3">
                <Button
                    variant="primary"
                    onClick={handleOnContinueClick}
                    marginTop
                    thin
                >
                    Continue <Icon name='arrowNext'/>
                </Button>
            </div>

        </BasicFormLayoutFullscreen>
    )
}

export default SATRWelcome;