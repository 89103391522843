/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { NavigationModel } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Navigation<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Navigation
   * @name Index
   * @summary Returns menu navigation for a company. If the user is not an admin, admin items will not appear in the response.
   * @request GET:/api/v2/companies/{companyId}/navigation
   * @secure
   */
  index = (companyId: number, query: { isMobile: boolean }, params: RequestParams = {}) =>
    this.request<NavigationModel, any>({
      path: `/api/v2/companies/${companyId}/navigation`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
