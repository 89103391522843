import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "../../elements/Button/Button"
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout"
import { Action, Entity } from "../../utils/EntityAction"
import { faCheck, faMagnifyingGlass, faSpinner, faTimes } from "@fortawesome/pro-regular-svg-icons"
import { useNavigateToEntity } from "../../hooks/useNavigateToEntity"
import { useContext, useEffect, useState } from "react"
import { CalculationResults, DividendPostModel, NewDividendViewData, ReportItem } from "../../api/inni/data-contracts"
import { Dividends } from "../../api/inni/Dividends"
import CompanyContext, { CompanyProduct } from "../../context/CompanyContext"
import { useInniAPI } from "../../hooks/useInniAPI"
import { Alert, Card, InputGroup, Form as ReactForm } from "react-bootstrap"
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik"
import { formatCurrency } from "../../utils/formatNumbers"
import styles from "./Dividend.module.css"
import { Modal } from "../../components/Modal/Modal"
import React from "react"
import classNames from "classnames"
import { asHtml5Date, dateIsValid } from "../../utils/formatDate"
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder/LoadingPlaceholder"
import editRowStyles from '../../elements/EditRow/EditRow.module.css';
import Select, { OptionTypeBase } from 'react-select';
import { useHasPermission } from "../../hooks/useHasPermission"
import { debounce } from "lodash"
// v8
import SimpleFullscreen from '../../components/Fullscreen/SimpleFullscreen';
import Icon from '../../elements/Icon/Icon';
import OptionSelector from "../../components/OptionSelector/OptionSelector";
import { Summary } from '../../elements/Summary/Summary';
import { TextValue } from '../../elements/DetailRow/DetailRow';


export const DividendNewPage = () => {
    const companyContext = useContext(CompanyContext)
    const v8Flow = companyContext.product === CompanyProduct.isCap;

    const dividendsAPI = useInniAPI(Dividends, [400]);

    const navigate = useNavigateToEntity();

    const [newDividendData, setNewDividendData] = useState<NewDividendViewData>()
    const [dividendValues, setDividendValues] = useState<DividendPostModel>()
    const [calculationData, setCalculationData] = useState<CalculationResults>()
    const [recalculating, setRecalculating] = useState(false)
    const [newDataError, setNewDataError] = useState<string|undefined|null>()

    const [showProfitBreakdown, setShowProfitBreakdown] = useState(false)
    const [isFirstDividend, setIsFirstDividend] = useState<boolean | undefined>()

    // v8
    const [pageNum, setPageNum] = useState(0);
    const [hasSetOffset, setHasSetOffset] = useState(false);  // if we have answered the offset question show the answer when revisting page
    const [submitErrors, setSubmitErrors] = useState<string[]>([]);

    useEffect(() => {
        if (dividendsAPI) {
            dividendsAPI.index(companyContext.cid).then((res) => {
                if (res.data.length === 0)
                    setIsFirstDividend(true);
                else
                    setIsFirstDividend(false);
            })
        }
    }, [companyContext.cid, dividendsAPI])

    useEffect(() => {
        if(dividendsAPI && newDividendData === undefined) {
            dividendsAPI.createDividendData(companyContext.cid).then((res) => {
                setNewDividendData(res.data)
                setNewDataError(null)
                if(res.data.newDividend) {
                    setDividendValues(res.data.newDividend)
                    setCalculationData({
                        shareSplit: res.data.shareSplit,
                        availableProfit: res.data.availableProfit,
                        isValid: false,
                        preMigrationDividend: res.data.preMigrationDividend,
                        reportItem: res.data.reportItem,
                        errors: undefined,
                        newDividendMinDate: res.data.newDividendMinDate,
                        profitCheckAvailable: res.data.profitCheckAvailable,
                        profitCheckNotAvailableReason: res.data.profitCheckNotAvailableReason,
                        shareClasses: res.data.shareClasses,
                    })
                }
            }).catch((error) => {
                console.error(error)
                setNewDataError(error.error.message)
            })
        }
    }, [companyContext.cid, dividendsAPI, newDividendData])

    const recursiveBreakdown = (reportItem: ReportItem, level: number) => {
        return <React.Fragment>
            <div 
                className={classNames(styles.reportItemParent, styles[`level-${level}`])} 
                style={level > 1 ? {paddingLeft: `${level}rem`} : {}}
            >
                <label>{reportItem.name}</label>
                <p>{formatCurrency(reportItem.amountWithChildrenDisplay)}</p>
            </div>
            {reportItem.children && reportItem.children.map((item, index) => <React.Fragment key={index}>
                {recursiveBreakdown(item, level + 1)}
            </React.Fragment>)}
        </React.Fragment>
    }

    const recalculate = (values: DividendPostModel) => {
        //Do we need to recalculate anything?
        if((values.dividendDate !== dividendValues?.dividendDate || 
            values.totalNetDividend !== dividendValues?.totalNetDividend ||
            values.shareClass !== dividendValues.shareClass) && 
            dateIsValid(values.dividendDate)) {
            if(dividendsAPI && (!calculationData?.profitCheckAvailable || values.totalNetDividend <= (calculationData?.availableProfit || 0)) ) {
                setRecalculating(true)
                dividendsAPI.calculateDividend(companyContext.cid, {...values, totalNetDividend: values.totalNetDividend || 0}).then((res) => {
                    setCalculationData(res.data)
                }).catch((error) => {
                    console.error(error)
                }).finally(() => {
                    setRecalculating(false)
                })
            }
            setDividendValues({...values, confirmMeeting: false})
        }
    }

    const prevPage = () => {
        setPageNum(pageNum => pageNum - 1);
    }

    const nextPage = () => {
        setPageNum(pageNum => pageNum + 1);
    }

    const pages = [
        {
            id: 'intro',
            title: isFirstDividend === undefined
                ? ''
                : isFirstDividend
                    ? 'Let\'s issue your first dividend'
                    : 'Let\'s issue your next dividend',

            subtitle: isFirstDividend === undefined
                ? ''
                : isFirstDividend
                    ? `You're about to issue a dividend for the first time.
                    This process will guide you through declaring and distributing dividends to shareholders. Ensure you have all relevant details on hand.`
                    : `You're about to issue your next dividend.
                    This process will guide you through declaring and distributing dividends to shareholders. Ensure you have all relevant details on hand.`,
            buttonLabel: 'Let\'s go'
        },
        {
            id: 'share_class',
            title: 'Choose your share class',
            subtitle: `Different share classes come with varied rights and benefits. Select the share class you're issuing dividends for.`,
            buttonLabel: 'Continue'
        },
        {
            id: 'dividend_date',
            title: 'Dividend issue date',
            subtitle: `When will the dividend be issued? Select the date to ensure accurate record-keeping of your dividend distributions.`,
            buttonLabel: 'Continue'
        },
        {
            id: 'dividend_amount',
            title: 'Dividend amount',
            subtitle: `How much will you be distributing? Enter the total dividend amount for this issuance.`,
            buttonLabel: 'Continue'
        },
        {
            id: 'offset',
            title: 'Offset against loan amount',
            subtitle: `Would you like to use this dividend to offset or reduce your shareholder/director loan account balance?`
        },
        {
            id: 'board_confirmation',
            title: 'Board meeting confirmation',
            subtitle: `Before proceeding, please confirm that a board meeting has taken place and the decision to issue this dividend has been agreed upon by the relevant parties.`,
            buttonLabel: 'Continue'
        },
        {
            id: 'overview',
            title: 'Overview',
            subtitle: `Before finalising, please review all the details you\'ve entered for this dividend issuance.
                Ensure everything is accurate to avoid any discrepancies.`
        },
    ];


    const submit = (values: DividendPostModel, formikHelpers: FormikHelpers<DividendPostModel>) => {
        if(dividendsAPI) {
            formikHelpers.setSubmitting(true)
            dividendsAPI.createDividend(companyContext.cid, values).then((res) => {
                v8Flow ? navigate(Entity.BookkeepingV8, undefined,{"activePage" : "dividends"}) : navigate(Entity.DividendV7) 
                formikHelpers.setSubmitting(false)
            }).catch((error) => {
                formikHelpers.setSubmitting(false)
                console.error(error)
                const newErrors = [];
                if (error.hasOwnProperty('error')) {
                    if (error.error.hasOwnProperty('dividendDate'))
                        newErrors.push('Dividend date')
                    if (error.error.hasOwnProperty('shareClass'))
                        newErrors.push('Share class')
                    if (error.error.hasOwnProperty('totalNetDividend'))
                        newErrors.push('Dividend amount')
                    if (error.error.hasOwnProperty('confirmMeeting'))
                        newErrors.push('Board confirmation')
                }

                setSubmitErrors(newErrors);
            })
        }
    }

    const hasPermission = useHasPermission();
    const hasLiveCash = hasPermission(Entity.LiveCash, Action.Read)[0]
    const hasAMSupport = hasPermission(Entity.AccountManagerSupport, Action.All)[0]
    const currentPage = pages[pageNum];

    const handleKeyDown =(event: React.KeyboardEvent<HTMLElement>) =>{
        if (event.key === '-' ) {
            event.preventDefault();
        }        
    }

    return (
        v8Flow 
        ? (
            <SimpleFullscreen 
                onBack={pageNum !== 0 ? prevPage : undefined}
                isOpen={true} 
                title={currentPage.title} 
                subTitle={currentPage.subtitle}
                onClose={()=>navigate(Entity.BookkeepingV8, undefined,{"activePage" : "dividends"})}             
            >
                {/* Error getting init data */}
                { newDataError && (
                    <>
                        <Alert variant="info">
                            <p style={{marginBottom: '0'}}>{newDataError}</p>
                        </Alert>
                        <Button
                            buttonType="cancel"
                            variant="dark"
                            outline
                            onClick={() => navigate(Entity.BookkeepingV8, undefined,{"activePage" : "dividends"})}
                        />
                    </>
                )}

                {/* Main flow */}
                { (!newDataError && newDividendData && calculationData && dividendValues)
                    ? (
                        <>
                            <Formik<DividendPostModel>
                                initialValues={dividendValues}
                                onSubmit={submit}
                                validate={debounce((values) => {recalculate(values)}, 500)}
                                enableReinitialize
                            >
                                {({values, isSubmitting, setFieldValue}) => (
                                    <Form>
                                        {/* Share class */}
                                        { currentPage.id === 'share_class' && (
                                            <>
                                                <label>Share class</label>
                                                <div style={{maxWidth: '150px'}}>
                                                    <Field name="shareClass">
                                                        {({field}: FieldProps) => (
                                                            <Select 
                                                                // disabled={recalculating}
                                                                className={editRowStyles.selectList}
                                                                classNamePrefix='selectList'
                                                                options={newDividendData.shareClasses}
                                                                onChange={(e) => setFieldValue('shareClass', e.value)}
                                                                value={(
                                                                    newDividendData.shareClasses
                                                                        ? newDividendData.shareClasses.find(sc => String(sc.value) === String(values.shareClass))
                                                                        : ''
                                                                ) as OptionTypeBase}
                                                                menuPlacement="auto"
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            </>
                                        )}

                                        {/* Dividend date */}
                                        { currentPage.id === 'dividend_date' && (
                                            <>
                                                <label>Dividend date</label>
                                                <div style={{maxWidth: "250px"}}>
                                                    <Field name="dividendDate">
                                                        {({field}: FieldProps) => (
                                                            <ReactForm.Control
                                                                type={'date'}
                                                                // disabled={recalculating}
                                                                {...({
                                                                    ...field,
                                                                    min: asHtml5Date(newDividendData?.newDividendMinDate),
                                                                    value: asHtml5Date(field.value)})
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            </>
                                        )}

                                        {/* Dividend amount */}
                                        { currentPage.id === 'dividend_amount' && (
                                            <>
                                                {(hasLiveCash || !calculationData.profitCheckAvailable) && (
                                                    !calculationData?.profitCheckAvailable
                                                        ? (
                                                            <Alert variant='warning'>
                                                                <strong>Your profit figures are not available</strong>
                                                                <p>{calculationData.profitCheckNotAvailableReason}</p>
                                                                <p>This means we cannot give guidance on the amount of available profit to pay as dividends
                                                                    or the personal tax that will be due on any dividends taken whilst the company is in the
                                                                    process of being setup on our software.
                                                                </p>
                                                                {  hasAMSupport &&
                                                                    <p style={{marginBottom: "0"}}>
                                                                        Please contact your account manager if you need further support.
                                                                    </p>
                                                                }
                                                            </Alert>
                                                        )
                                                        : (
                                                            <>
                                                                <div className={styles.amountAvailable}>
                                                                    <h4>Profit available</h4>
                                                                    <p className="my-4">The maximum amount you can legally pay as a dividend</p>
                                                                    <h3>{ formatCurrency(calculationData?.availableProfit) }</h3>
                                                                    <br />
                                                                    <Button
                                                                        thin
                                                                        variant="positive"
                                                                        onClick={() => setShowProfitBreakdown(true)}
                                                                        className="mt-3"
                                                                        buttonType="drilldown"
                                                                        label="View breakdown"
                                                                    />
                                                                </div>

                                                                <div className={styles.amountAvailable}>
                                                                    <h4>Cash available</h4>
                                                                    <p className="my-4">
                                                                        The amount of cash you have to hand to pay dividend, after accounting for all known liabilities
                                                                    </p>
                                                                    <h3>{ formatCurrency(newDividendData?.availableCash)}</h3>
                                                                    { newDividendData?.liveCashCustomised && (
                                                                        <p className="text-muted mt-2">
                                                                            This figure may not be reliable as your LiveCash settings have been customised
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )
                                                )}

                                                <div className="mt-5">
                                                    <label>Enter amount</label>
                                                    <div style={{width: '200px'}}>
                                                        <Field name="totalNetDividend">
                                                            {({field}: FieldProps) => (
                                                                <InputGroup>
                                                                    <InputGroup.Prepend>
                                                                        <InputGroup.Text>&pound;</InputGroup.Text>
                                                                    </InputGroup.Prepend>   
                                                                    <ReactForm.Control 
                                                                        type={'number'} 
                                                                        {...field} 
                                                                        min={0} 
                                                                        max={newDividendData.setupBS
                                                                            ? calculationData.availableProfit > 0
                                                                                ? calculationData.availableProfit
                                                                                : 0
                                                                            : 99999999
                                                                        }
                                                                        // disabled={recalculating}
                                                                        className={classNames({["is-invalid"]: (calculationData.profitCheckAvailable  && values.totalNetDividend > calculationData.availableProfit)})}
                                                                        onKeyDown={handleKeyDown}
                                                                    />
                                                                </InputGroup>
                                                            )}
                                                        </Field>
                                                    </div>
                                                    {calculationData.profitCheckAvailable && values.totalNetDividend > calculationData.availableProfit && (
                                                        <p className="mt-2" style={{color: 'var(--error-text)'}}>
                                                            Payment should be {formatCurrency(calculationData.availableProfit)} or less, as this is the amount of available profit in your company.
                                                        </p>
                                                    )}
                                                </div>
                                            </>
                                        )}

                                        {/* Offset */}
                                        { currentPage.id === 'offset' && (
                                            <>
                                                <OptionSelector
                                                    onChange = {(opt) => {
                                                        if (opt[0].value === 'true') {
                                                            setFieldValue("payOffDLA", true);
                                                            setHasSetOffset(true);
                                                            nextPage();
                                                        }
                                                        else {
                                                            setFieldValue("payOffDLA", false)
                                                            setHasSetOffset(true);
                                                            nextPage();
                                                        }
                                                    }} 
                                                    options = {{
                                                        singleEntry: true,
                                                        list: [
                                                            { label: 'Yes - pay off directors\' loan accounts', value: 'true' }, 
                                                            { label: 'No', value: 'false' }
                                                        ]
                                                    }}
                                                    defaultValues={hasSetOffset
                                                        ? (
                                                            [
                                                                {
                                                                    label: 'Yes - pay off directors\' loan accounts',
                                                                    value: (hasSetOffset && values.payOffDLA) ? 'true' : 'false'
                                                                } 
                                                            ]
                                                        )
                                                        : undefined
                                                    }
                                                    preventSaveState
                                                />
                                            </>
                                        )}

                                        {/* Board confirmation */}
                                        { currentPage.id === 'board_confirmation' && (
                                            <div className="v8CheckBoxContainer" style={{width: '400px'}}>
                                                <Field name="confirmMeeting">
                                                    {({field}: FieldProps) => (
                                                        <>
                                                            {/* {recalculating && <FontAwesomeIcon icon={faSpinner} spin style={{marginRight: '0.5rem'}}/>} */}
                                                            <ReactForm.Check
                                                                id="confirmMeeting"
                                                                {...field}
                                                                checked={field.value}
                                                                // disabled={recalculating}
                                                                className="d-inline-block"
                                                            />
                                                            <label className="m-0 p-0 ml-2" htmlFor="confirmMeeting">
                                                                I confirm a board meeting has taken place
                                                            </label>
                                                        </>
                                                    )}
                                                </Field>
                                            </div>
                                        )}

                                        {/* Overview */}
                                        { currentPage.id === 'overview' && (
                                            <>
                                                {/* ExportAll is an admin only action, so we'll use it for the admin check*/}
                                                { (hasPermission(Entity.DividendV7, Action.ExportAll) && calculationData.preMigrationDividend) && (
                                                    <Alert variant="danger" style={{borderRight: "5px solid var(--admin-only-color)"}}>
                                                        <strong>Pre-migration dividend</strong>
                                                        <p style={{marginBottom: '0'}}>
                                                            The date you have chosen pre-dates the migration date. The dividend will be recorded, BUT NOT JOURNALLED.
                                                        </p>
                                                    </Alert>
                                                )}

                                                { (calculationData && !calculationData.shareSplit) && (
                                                    <Alert variant="warning" style={{marginTop: "1rem", marginBottom: "0"}}>
                                                        <strong>No dividend to pay</strong>
                                                    </Alert>
                                                )}

                                                { (calculationData && calculationData.shareSplit) && (
                                                    calculationData.shareSplit.map((share, index) => (
                                                        <Summary key={index}>
                                                            {/* Shareholder */}
                                                            <TextValue
                                                                fixedWidth
                                                                name="shareholder"
                                                                label="Shareholder"
                                                                value={share.name}
                                                                v8Summary={true}
                                                            />
                                                            {/* Shares held */}
                                                            <TextValue
                                                                fixedWidth
                                                                name="sharesheld"
                                                                label="Shares held"
                                                                value={`${share.shares} ${share.shareClass} shares`}
                                                                v8Summary={true}
                                                            />
                                                            {/* Dividend agreed */}
                                                            <TextValue
                                                                fixedWidth
                                                                name="dividend_agreed"
                                                                label="Dividend agreed"
                                                                value={`${formatCurrency(share.amountPerShare)} per ${share.shareClass} share`}
                                                                v8Summary={true}
                                                            />
                                                            {/* Dividend payment */}
                                                            <TextValue
                                                                fixedWidth
                                                                name="dividend_payment"
                                                                label="Dividend payment"
                                                                value={formatCurrency(share.netDividend)}
                                                                v8Summary={true}
                                                            />
                                                            {/* Payoff director's loan account */}
                                                            <TextValue
                                                                fixedWidth
                                                                name={"payOffDLA"}
                                                                label={"Pay off director's loan account"}
                                                                value={values.payOffDLA ? "Yes (if applicable)" : "No"}
                                                                v8Summary={true}
                                                            />
                                                            {/* Income tax payable */}
                                                            <div className={styles.infoLineSingle}>
                                                                { share.showTaxPayable
                                                                    ? (
                                                                        <div>
                                                                            <TextValue
                                                                                fixedWidth
                                                                                name="taxPayable"
                                                                                label="Income tax payable"
                                                                                value={formatCurrency(share.taxPayable)}
                                                                                v8Summary={true}
                                                                            />

                                                                            { share.taxPayable > 0 && (
                                                                                <div className="mt-5">
                                                                                    { share.taxBands && share.taxBands.map((band, index) => (
                                                                                        <div
                                                                                            key={index}
                                                                                            className={classNames(styles.band, styles[band.name || ""])}
                                                                                        >
                                                                                            <span>{band.rate}%</span>
                                                                                            <div className={styles.bar}>
                                                                                                <span
                                                                                                    className={styles.previous}
                                                                                                    style={{width: `${band.previous}%`}}
                                                                                                ></span>
                                                                                                <span
                                                                                                    className={styles.current}
                                                                                                    style={{width: `${band.current}%`}}
                                                                                                ></span>
                                                                                                <span
                                                                                                    className={styles.previous}
                                                                                                    style={{width: `${band.remaining}%`}}
                                                                                                ></span>
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}

                                                                                    <p className="text-muted">
                                                                                        Estimated income tax due on this dividend, paid via Self Assessment. Based on information<br />
                                                                                        in <a style={{color: 'var(--link-blue-color)'}} target="_blank" rel="noopener noreferrer" href={`/company/${companyContext.cid}/taxplanning?personid=${share.personId}`}>{share.name}'s personal tax planner</a>.
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )
                                                                    : ( share.dontShowTaxTaxPlannerNotSupported
                                                                        ? (
                                                                            <div className="mt-5">
                                                                                <p>We cannot provide {share.name} with an accurate tax forecast as <a style={{color: 'var(--link-blue-color)'}} target="_blank" rel="noopener noreferrer" href={`/company/${companyContext.cid}/taxplanning?personid=${share.personId}`}>their personal tax planner</a> does not support capital gains or rental income.</p>
                                                                            </div>
                                                                        )
                                                                        : ( share.dontShowTaxAdditionalRate
                                                                            ? (
                                                                                <div className="mt-5">
                                                                                    <p>We cannot provide {share.name} with an accurate tax forecast as they are an additional rate tax payer.</p>
                                                                                </div>
                                                                            )
                                                                            : ( share.dontShowPTPNotInPackage && (
                                                                                <div className="mt-5">
                                                                                    <p>Remember that personal tax is payable on dividends.</p>
                                                                                </div>
                                                                            ))
                                                                        )
                                                                    )
                                                                }
                                                            </div>
                                                        </Summary>
                                                    ))
                                                )}

                                                { submitErrors.length > 0 && (
                                                    <Alert variant="danger">
                                                        <strong>There was a problem creating your dividend</strong>
                                                        <p className="mt-2">
                                                            { submitErrors.length === 1
                                                                ? <>We were unable to create your dividend, please check the {submitErrors[0].toLowerCase()} is correct.</>
                                                                : (
                                                                    <div>
                                                                        We were unable to create your dividend, please check the following are correct:
                                                                        <ul>
                                                                            { submitErrors.map((error, idx) => (
                                                                                <li key={idx}>{ error }</li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                )
                                                            }
                                                        </p>
                                                    </Alert>
                                                )}

                                                {/* Save / Cancel */}
                                                <div>
                                                    <Button
                                                        buttonType="save"
                                                        submitButton
                                                        variant="change"
                                                        disabled={!values.confirmMeeting || isSubmitting || recalculating}
                                                    />

                                                    <Button
                                                        buttonType="cancel"
                                                        variant="dark"
                                                        outline
                                                        onClick={() => navigate(Entity.BookkeepingV8, undefined,{"activePage" : "dividends"})}
                                                    />
                                                </div>
                                            </>
                                        )}

                                        { currentPage.buttonLabel && (
                                            <Button
                                                variant="primary"
                                                thin
                                                onClick={nextPage}
                                                className="mt-4"
                                                disabled={
                                                    (currentPage.id === 'board_confirmation' && values.confirmMeeting === false)
                                                        ? true
                                                        : (currentPage.id === 'dividend_amount' && ((values.totalNetDividend <= 0) || ( calculationData.profitCheckAvailable && values.totalNetDividend > calculationData.availableProfit))) 
                                                            ? true
                                                            : false
                                                }
                                            >
                                                {currentPage.buttonLabel} <Icon name='arrowNext' />
                                            </Button>
                                        )}
                                    </Form>
                                )}
                            </Formik>
                        </>
                    )
                    : (
                        <div className="mt-5">
                            <LoadingPlaceholder height="40px" width="130px" dark />
                        </div>
                    )
                }

                <Modal 
                    showModal={showProfitBreakdown} 
                    hideModal={() => {setShowProfitBreakdown(false)}} 
                    title="Profit"
                    buttons={<Button buttonType="done" variant="primary" onClick={() => setShowProfitBreakdown(false)} />}
                >
                    {calculationData?.reportItem && recursiveBreakdown(calculationData?.reportItem, 0)}
                </Modal>
            </SimpleFullscreen>
        )
        : (
            <DefaultLayout
                entity={Entity.DividendV7} 
                greyBackground 
                title="New dividend"
            >

                { newDataError && (
                    <>
                        <Alert variant="info">
                            <p style={{marginBottom: '0'}}>{newDataError}</p>
                        </Alert>
                        <Button
                            buttonType="cancel"
                            variant="dark"
                            outline
                            onClick={() => navigate(Entity.DividendV7)}
                        />
                    </>
                )}

                {newDividendData && calculationData && dividendValues ? <>
                    {/* ExportAll is an admin only action, so we'll use it for the admin check*/}
                    {hasPermission(Entity.DividendV7, Action.ExportAll) && calculationData.preMigrationDividend && <Alert variant="danger" style={{borderRight: "5px solid var(--admin-only-color)"}}>
                        <strong>Pre-migration dividend</strong>
                        <p style={{marginBottom: '0'}}>The date you have chosen pre-dates the migration date. The dividend will be recorded, BUT NOT JOURNALLED.</p>
                    </Alert>}

                    <Formik<DividendPostModel>
                        initialValues={dividendValues}
                        onSubmit={submit}
                        validate={debounce((values) => {recalculate(values)}, 500)}
                        enableReinitialize
                    >
                        {({values, isSubmitting, setFieldValue}) => (
                            <Form>
                                <div style={{marginBottom: '1rem'}}>
                                    <Button
                                        buttonType="save"
                                        submitButton
                                        variant="change"
                                        disabled={!values.confirmMeeting || isSubmitting || recalculating}
                                    />
                                    <Button
                                        buttonType="cancel"
                                        variant="dark"
                                        outline
                                        onClick={() => navigate(Entity.DividendV7)}
                                    />
                                </div>

                                <Card style={{marginBottom: '1rem'}}>
                                    <Card.Body>
                                        {newDividendData.shareClasses && newDividendData.shareClasses.length > 0 && <div className={styles.newDividendDetail}>
                                            <div>
                                                <label>Share class</label>
                                                <p>Which class of share are you paying a dividend to</p>
                                            </div>
                                            <div style={{minWidth: "150px"}}>
                                                <Field name="shareClass">
                                                    {({field}: FieldProps) => (
                                                        <Select 
                                                            disabled={recalculating}
                                                            className={editRowStyles.selectList}
                                                            classNamePrefix='selectList'
                                                            options={newDividendData.shareClasses}
                                                            onChange={(e) => setFieldValue("shareClass", e.value)}
                                                            value={(newDividendData.shareClasses ? newDividendData.shareClasses.find(sc => String(sc.value) === String(values.shareClass)) : '') as any}
                                                            menuPlacement="auto"
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>}

                                        <div className={styles.newDividendDetail}>
                                            <div>
                                                <label>Dividend date</label>
                                                <p>The date these dividends will be issued.</p>
                                            </div>
                                            <div>
                                                <Field name="dividendDate">
                                                    {({field}: FieldProps) => (
                                                        <ReactForm.Control type={'date'} disabled={recalculating} {...({...field, min: asHtml5Date(newDividendData.newDividendMinDate), value: asHtml5Date(field.value)})}/>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>

                                        {(hasLiveCash || !calculationData.profitCheckAvailable) && <>
                                            {!calculationData.profitCheckAvailable ? <Alert variant="warning" style={{marginTop: "1rem", marginBottom: "0"}}>
                                                <strong>Your profit figures are not available</strong>
                                                <p>{calculationData.profitCheckNotAvailableReason}</p>
                                                <p>This means we cannot give guidance on the amount of available profit to pay as dividends or the personal tax that will be due on any dividends taken whilst the company is in the process of being setup on our software.</p>
                                                {hasAMSupport && <p style={{marginBottom: "0"}}>Please contact your account manager if you need further support.</p>}
                                            </Alert>
                                            : <>
                                                <div className={styles.newDividendDetail}>
                                                    <div>
                                                        <label>Profit available</label>
                                                        <p>The maximum amount you can legally pay as a dividend</p>
                                                    </div>
                                                    <p className={styles.profit}>
                                                        <Button
                                                            variant="positive"
                                                            buttonType="drilldown"
                                                            onClick={() => setShowProfitBreakdown(true)}
                                                            label="View breakdown"
                                                        />
                                                        <span style={{marginLeft: '0.5rem'}}>{formatCurrency(calculationData.availableProfit)}</span>
                                                    </p>
                                                </div>

                                                <div className={styles.newDividendDetail}>
                                                    <div>
                                                        <label>Cash available</label>
                                                        <p>The amount of cash you have to hand to pay a dividend, after accounting for all of your known liabilities</p>
                                                        {newDividendData.liveCashCustomised && <p style={{color: "var(--yellow-dark)"}}>This figure may not be reliable as your LiveCash settings have been customised</p>}
                                                    </div>
                                                    <p>{formatCurrency(newDividendData.availableCash)}</p>
                                                </div>
                                            </>}
                                        </>}

                                        <div className={styles.newDividendDetail}>
                                            <div>
                                                <label>Amount to pay</label>
                                                <p>The total dividend you wish to pay to your shareholders</p>
                                                { values.totalNetDividend > calculationData.availableProfit && (
                                                    <p style={{color: '#d00'}}>
                                                        Payment should be {formatCurrency(calculationData.availableProfit)} or less, as this is the amount of available profit in your company.
                                                    </p>
                                                )}
                                            </div>
                                            <div>
                                                <Field name="totalNetDividend">
                                                    {({field}: FieldProps) => (
                                                        <InputGroup>
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text>&pound;</InputGroup.Text>
                                                            </InputGroup.Prepend>   
                                                            <ReactForm.Control 
                                                                type={'number'} 
                                                                {...field} 
                                                                min={0} 
                                                                max={newDividendData.setupBS ? calculationData.availableProfit > 0 ? calculationData.availableProfit : 0 : 99999999}
                                                                disabled={recalculating}
                                                                className={classNames({["is-invalid"]: values.totalNetDividend > calculationData.availableProfit})}
                                                            />
                                                        </InputGroup>
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className={styles.newDividendDetail}>
                                            <div>
                                                <label>Pay off your director's loan account</label>
                                                <p>Use this dividend to pay off your shareholder/director loan account balance</p>
                                            </div>
                                            <Field name="payOffDLA">
                                                {({field}: FieldProps) => (
                                                    <ReactForm.Check {...field} checked={field.value}/>
                                                )}
                                            </Field>
                                        </div>
                                        <div className={styles.newDividendDetail}>
                                            <div>
                                                <label>Meeting to agree dividend</label>
                                                <p>Please confirm that a board meeting has taken place to confirm the issuing of this dividend.</p>
                                            </div>
                                            <Field name="confirmMeeting">
                                                {({field}: FieldProps) => (
                                                    <>
                                                        {recalculating && <FontAwesomeIcon icon={faSpinner} spin style={{marginRight: '0.5rem'}}/>}
                                                        <ReactForm.Check {...field} checked={field.value} disabled={recalculating}/>
                                                    </>
                                                )}
                                            </Field>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Form>
                        )}
                    </Formik>

                    {calculationData && !calculationData.shareSplit && <Alert variant="warning" style={{marginTop: "1rem", marginBottom: "0"}}>
                        <strong>No dividend to pay</strong>
                    </Alert>}

                    {calculationData && calculationData.shareSplit && calculationData.shareSplit.map((share, index) => <Card key={index} style={{marginBottom: '1rem'}}>
                        <Card.Body>
                            {/*Left*/}
                            <div className={styles.cardColumn}>
                                <div className={styles.infoLineSingle}>
                                    <label className="text-muted">Shareholder</label>
                                    <p><b>{share.name}</b></p>
                                </div>
                                <div className={styles.infoLineSingle}>
                                    <label className="text-muted">Shares held</label>
                                    <p>{share.shares} {share.shareClass} shares</p>
                                </div>
                                <div className={styles.infoLineSingle}>
                                    <label className="text-muted">Dividend agreed</label>
                                    <p>{formatCurrency(share.amountPerShare)} per {share.shareClass} share</p>
                                </div>
                            </div>
                            
                            {/*Right*/}
                            <div className={classNames(styles.cardColumn, styles.right)}>
                                <div className={styles.infoLineSingle}>
                                    <div style={{textAlign: 'right'}}>
                                        <label className="text-muted">Dividend payment</label>
                                        <p>{recalculating && <FontAwesomeIcon icon={faSpinner} spin style={{marginRight: '0.5rem'}}/>}<b>{formatCurrency(share.netDividend)}</b></p>
                                    </div>
                                </div>

                                <div className={styles.infoLineSingle}>
                                    {share.showTaxPayable ? <div style={{textAlign: 'right'}}>
                                        <label className="text-muted">Income tax payable</label>
                                        {share.taxPayable > 0 ? <div className={styles.taxChart}>
                                            {share.taxBands && share.taxBands.map((band, index) => <div key={index} className={classNames(styles.band, styles[band.name || ""])}>
                                                <span>{band.rate}%</span>
                                                <div className={styles.bar}>
                                                    <span className={styles.previous} style={{width: `${band.previous}%`}}></span>
                                                    <span className={styles.current} style={{width: `${band.current}%`}}></span>
                                                    <span className={styles.previous} style={{width: `${band.remaining}%`}}></span>
                                                </div>
                                            </div>)}
                                            <p style={{marginBottom: '0.5rem'}}>{recalculating && <FontAwesomeIcon icon={faSpinner} spin style={{marginRight: '0.5rem'}}/>}{formatCurrency(share.taxPayable)}</p>
                                            <p className="text-muted">
                                                Estimated income tax due on this dividend, paid via Self Assessment. 
                                                Based on information in <a style={{color: 'var(--link-blue-color)'}} target="_blank" rel="noopener noreferrer" href={`/company/${companyContext.cid}/taxplanning?personid=${share.personId}`}>{share.name}'s personal tax planner</a>.
                                            </p>
                                        </div> :
                                        <p>{recalculating && <FontAwesomeIcon icon={faSpinner} spin style={{marginRight: '0.5rem'}}/>}{formatCurrency(share.taxPayable)}</p>
                                        }
                                    </div> :
                                    share.dontShowTaxTaxPlannerNotSupported ? <div className={styles.newDividendMeta}>
                                        {/*TODO, proper tax planner link*/}
                                        <p>We cannot provide {share.name} with an accurate tax forecast as <a style={{color: 'var(--link-blue-color)'}} target="_blank" rel="noopener noreferrer" href={`/company/${companyContext.cid}/taxplanning?personid=${share.personId}`}>their personal tax planner</a> does not support capital gains or rental income.</p>
                                    </div> :
                                    share.dontShowTaxAdditionalRate ? <div className={styles.newDividendMeta}>
                                        <p>We cannot provide {share.name} with an accurate tax forecast as they are an additional rate tax payer.</p>
                                    </div> :
                                    share.dontShowPTPNotInPackage ? <div className={styles.newDividendMeta}>
                                        <p>Remember that personal tax is payable on dividends.</p>
                                    </div> : null
                                    }
                                </div>
                            </div>
                        </Card.Body>
                    </Card>)}

                    <Modal 
                        showModal={showProfitBreakdown} 
                        hideModal={() => {setShowProfitBreakdown(false)}} 
                        title="Profit"
                        buttons={<Button variant="primary" onClick={() => setShowProfitBreakdown(false)}><FontAwesomeIcon icon={faCheck}/>Done</Button>}
                    >
                        {calculationData.reportItem && recursiveBreakdown(calculationData.reportItem, 0)}
                    </Modal>
                </> : !newDataError ?
                <div>
                    <LoadingPlaceholder height="5rem"/>
                    <LoadingPlaceholder height="20rem"/>
                    <LoadingPlaceholder height="10rem"/>
                    <LoadingPlaceholder height="10rem"/>
                </div> :
                null}
            </DefaultLayout>
        )
    )
}
