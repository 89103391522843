import { add } from 'date-fns'
import { startOfToday } from 'date-fns/esm'
import React, { useState, useEffect, useContext } from 'react'
import { Container, Form, InputGroup } from 'react-bootstrap'
import Select from 'react-select'
import { TimeTask } from '../../../api/inni/data-contracts'
import { Time } from '../../../api/inni/Time'
import CompanyContext from '../../../context/CompanyContext'
import { Button } from '../../../elements/Button/Button'
import { ListOption } from '../../../elements/EditRow/EditRow'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { asHtml5Date, asYearMonthDay } from '../../../utils/formatDate'
import { EditorCommonProps } from './QEListMobile'
import styles from "../QuickEntry.module.css"

const TimeDayEditor = ({contracts, people, qe, cancelCb, showAlert} : EditorCommonProps) => {

    const companyContext = useContext(CompanyContext)

    const [peopleLO, setPeopleLO] = useState<ListOption[]>([])
    const [date, setDate] = useState(qe?.date || asYearMonthDay(startOfToday())) // use start of day - as this is how they are stored
    const [person, setPerson] = useState(parseInt(qe?.relatedId?.split('_')[1] || people.find(x => x.defaultEmployee)?.id.toString() || people[0].id.toString()))

    const timeAPI = useInniAPI(Time)
    const [times, setTimes] = useState<TimeTask[] | undefined>(undefined)
    const [timesLoaded, setTimesLoaded] = useState(false)

    useEffect(() => {
        if (timeAPI && !timesLoaded) {
            timeAPI.get(companyContext.cid, person, { date: date || asYearMonthDay(new Date()) })
            .then(res => {
                setTimes(res.data)
                setTimesLoaded(true)
            })
        }
    }, [companyContext.cid, date, person, timeAPI, timesLoaded])

    useEffect(() => {
        setTimesLoaded(false)
    }, [date, person])

    useEffect(() => {
        let tempLO : ListOption[] = []
        tempLO = 
            [
                ...tempLO, 
                ...people.filter(x => x.isActiveEmployee).map(x => {
                    return { value: x.id.toString(), label: x.name || '' }
                })
            ]
        setPeopleLO(tempLO)
    }, [people])

    const saveTimes = () => {
        if (timeAPI) {
            timeAPI.put(companyContext.cid, times || []).then(res => {
                if (res.status === 200) {
                    showAlert('saved')
                    cancelCb(undefined, undefined, true)
                } else {
                    showAlert('error')
                }
            })
        }
    }

    const updateTime = (value : number, contractId : number, contractTaskId : number) => {
        let tempTimes = times || []
        let t = tempTimes?.find(x => x.contractTaskId === contractTaskId)
        // create a new time entry if one doesn't exist
        if (t !== undefined) {
            t.hours = value
            setTimes([...tempTimes])
        } else {
            setTimes([...tempTimes, { contractTaskId: contractTaskId, contractId: contractId, employeeId: person, date: date, hours: value, readOnly: false }])
        }        
    }

    return (
        <Container>
            <div style={{marginBottom: '20px', marginTop: '20px'}}>
                <Form.Control style={{marginBottom: '20px'}} onChange={(e) => setDate(e.target.value)} value={asHtml5Date(asYearMonthDay(add(new Date(date), {hours: 1})))} type='date' />
                <Select isSearchable={false} onChange={(e) => setPerson(parseInt(e?.value || '-1'))} value={peopleLO.find(x => x.value === person.toString())} options={peopleLO} classNamePrefix='selectList' />
            </div>
            {contracts.filter(x => !x.endDate).map(x => {
                return (
                <React.Fragment key={x.id}>
                    <p>{x.name}</p>
                    {x.tasks?.map(y => {
                        return (
                        <div key={y.id} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <p style={{marginLeft: '10px'}}>{y.description}</p>
                            <InputGroup style={{width: '40%'}}>
                                <Form.Control disabled={(!timesLoaded) || times?.find(z => z.contractTaskId === y.id)?.readOnly} step='0.1' type='number' 
                                    onChange={(e) => updateTime(Number(e.target.value), x.id, y.id)} value={times?.find(z => z.contractTaskId === y.id)?.hours || 0} 
                                />
                                <InputGroup.Append style={{height: '41px'}}>
                                    <InputGroup.Text>{x.contractRateType}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </div>)
                    })}
                </React.Fragment>)
            })}
            <div className={styles.editorButtons}>
                <Button buttonType='cancel' onClick={cancelCb} mobile />
                <Button buttonType='save' onClick={saveTimes} mobile />
            </div>
        </Container>
    )
}

export default TimeDayEditor