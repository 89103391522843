/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CompanyAccountSettingsPostModel,
  CompanyAESettingsPostModel,
  CompanyInformationPostModel,
  CompanyInvoicingSettingsPostModel,
  CompanyOnlineSystemSettingsPostModel,
  CompanyPAYESettingsPostModel,
  CompanyPensionProviderSettings,
  CompanyPensionProviderSettingsPostModel,
  CompanySettingView,
  CompanyVATSettingsPostModel,
  SelectItem,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class CompanySettings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags CompanySettings
   * @name Index
   * @summary Returns Company details
   * @request GET:/api/v2/companies/{companyId}/companysettings
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<CompanySettingView, any>({
      path: `/api/v2/companies/${companyId}/companysettings`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name UpdateCompanyInformationSettings
   * @summary Updates company info details
   * @request PUT:/api/v2/companies/{companyId}/companysettings/UpdateCompanyInformationSettings
   * @secure
   */
  updateCompanyInformationSettings = (
    companyId: number,
    companyPostModel: CompanyInformationPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/UpdateCompanyInformationSettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name ValidateUpdateCompanyInformationSettings
   * @summary validate company settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/ValidateUpdateCompanyInformationSettings
   * @secure
   */
  validateUpdateCompanyInformationSettings = (
    companyId: number,
    companyPostModel: CompanyInformationPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/ValidateUpdateCompanyInformationSettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name UpdateCompanyAccountSettings
   * @summary Update company account settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/UpdateCompanyAccountSettings
   * @secure
   */
  updateCompanyAccountSettings = (
    companyId: number,
    companyPostModel: CompanyAccountSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/UpdateCompanyAccountSettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name ValidateUpdateCompanyAccountSettings
   * @summary validate company account settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/ValidateUpdateCompanyAccountSettings
   * @secure
   */
  validateUpdateCompanyAccountSettings = (
    companyId: number,
    companyPostModel: CompanyAccountSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/ValidateUpdateCompanyAccountSettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name UpdateCompanyOnlineSystemsSettings
   * @summary Update company online system settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/UpdateCompanyOnlineSystemsSettings
   * @secure
   */
  updateCompanyOnlineSystemsSettings = (
    companyId: number,
    companyPostModel: CompanyOnlineSystemSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/UpdateCompanyOnlineSystemsSettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name ValidateUpdateCompanyOnlineSystemsSettings
   * @summary Validate company online system settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/ValidateCompanyOnlineSystemsSettings
   * @secure
   */
  validateUpdateCompanyOnlineSystemsSettings = (
    companyId: number,
    companyPostModel: CompanyOnlineSystemSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/ValidateCompanyOnlineSystemsSettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name UpdateCompanyVatSettings
   * @summary Update company VAT settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/UpdateCompanyVATSettings
   * @secure
   */
  updateCompanyVatSettings = (
    companyId: number,
    companyPostModel: CompanyVATSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/UpdateCompanyVATSettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name ValidateUpdateCompanyVatSettings
   * @summary Validate company VAT settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/ValidateCompanyVATSettings
   * @secure
   */
  validateUpdateCompanyVatSettings = (
    companyId: number,
    companyPostModel: CompanyVATSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/ValidateCompanyVATSettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name UpdateCompanyInvoicingSettings
   * @summary Update company invoicing settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/UpdateCompanyInvoicingSettings
   * @secure
   */
  updateCompanyInvoicingSettings = (
    companyId: number,
    companyPostModel: CompanyInvoicingSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/UpdateCompanyInvoicingSettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name ValidateCompanyInvoicingSettings
   * @summary Validate company Invoicing settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/ValidateCompanyInvoicingSettings
   * @secure
   */
  validateCompanyInvoicingSettings = (
    companyId: number,
    companyPostModel: CompanyInvoicingSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/ValidateCompanyInvoicingSettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name UpdateCompanyPayeSettings
   * @summary Update company PAYE settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/UpdateCompanyPAYESettings
   * @secure
   */
  updateCompanyPayeSettings = (
    companyId: number,
    companyPostModel: CompanyPAYESettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/UpdateCompanyPAYESettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name ValidateCompanyPayeSettings
   * @summary Validate company PYAE settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/ValidatePAYESettings
   * @secure
   */
  validateCompanyPayeSettings = (
    companyId: number,
    companyPostModel: CompanyPAYESettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/ValidatePAYESettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name UpdateCompanyAeSettings
   * @summary Update company AE settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/UpdateCompanyAESettings
   * @secure
   */
  updateCompanyAeSettings = (
    companyId: number,
    companyPostModel: CompanyAESettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/UpdateCompanyAESettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name ValidateCompanyAeSettings
   * @summary Validate company AE settings
   * @request PUT:/api/v2/companies/{companyId}/companysettings/ValidateCompanyAESettings
   * @secure
   */
  validateCompanyAeSettings = (
    companyId: number,
    companyPostModel: CompanyAESettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/ValidateCompanyAESettings`,
      method: "PUT",
      body: companyPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name ResetAutoEnrolment
   * @summary Reset AutoEnrolment
   * @request PUT:/api/v2/companies/{companyId}/companysettings/ResetAutoEnrolment
   * @secure
   */
  resetAutoEnrolment = (companyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/ResetAutoEnrolment`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name RescanCsd
   * @summary Updates Confirmation Status Date From CoHouse
   * @request PUT:/api/v2/companies/{companyId}/companysettings/UpdateConfirmationStatusDateFromCoHouse
   * @secure
   */
  rescanCsd = (companyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/UpdateConfirmationStatusDateFromCoHouse`,
      method: "PUT",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name GetVatLoginTypes
   * @summary Returns VAT Login Types
   * @request GET:/api/v2/companies/{companyId}/companysettings/VatLoginTypes
   * @secure
   */
  getVatLoginTypes = (companyId: number, params: RequestParams = {}) =>
    this.request<SelectItem[], any>({
      path: `/api/v2/companies/${companyId}/companysettings/VatLoginTypes`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name GetVatFlatRateCategories
   * @summary Returns VAT Flat Rate categories
   * @request GET:/api/v2/companies/{companyId}/companysettings/VATFlatRateCategories
   * @secure
   */
  getVatFlatRateCategories = (companyId: number, params: RequestParams = {}) =>
    this.request<SelectItem[], any>({
      path: `/api/v2/companies/${companyId}/companysettings/VATFlatRateCategories`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name GetPensionProviders
   * @summary Returns Providers List
   * @request GET:/api/v2/companies/{companyId}/companysettings/PensionProviders
   * @secure
   */
  getPensionProviders = (companyId: number, params: RequestParams = {}) =>
    this.request<CompanyPensionProviderSettings[], any>({
      path: `/api/v2/companies/${companyId}/companysettings/PensionProviders`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name GetPensionProvider
   * @summary Returns Particular pension provider
   * @request GET:/api/v2/companies/{companyId}/companysettings/PensionProvider
   * @secure
   */
  getPensionProvider = (companyId: number, query: { pensionProviderId: number }, params: RequestParams = {}) =>
    this.request<CompanyPensionProviderSettings, any>({
      path: `/api/v2/companies/${companyId}/companysettings/PensionProvider`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name CreatePensionProviderSettings
   * @summary creates pension provider settings
   * @request POST:/api/v2/companies/{companyId}/companysettings/PensionProvider
   * @secure
   */
  createPensionProviderSettings = (
    companyId: number,
    companyPensionProviderSettingsPostModel: CompanyPensionProviderSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/companysettings/PensionProvider`,
      method: "POST",
      body: companyPensionProviderSettingsPostModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name NewPensionProviderSettings
   * @summary Returns default payment provider settings
   * @request GET:/api/v2/companies/{companyId}/companysettings/NewPensionProvider
   * @secure
   */
  newPensionProviderSettings = (companyId: number, params: RequestParams = {}) =>
    this.request<CompanyPensionProviderSettingsPostModel, any>({
      path: `/api/v2/companies/${companyId}/companysettings/NewPensionProvider`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name ValidateCreatePensionProviderSettings
   * @summary validate provider settings
   * @request POST:/api/v2/companies/{companyId}/companysettings/ValidatePensionProvider
   * @secure
   */
  validateCreatePensionProviderSettings = (
    companyId: number,
    companyPensionProviderSettingsPostModel: CompanyPensionProviderSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/companysettings/ValidatePensionProvider`,
      method: "POST",
      body: companyPensionProviderSettingsPostModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name UpdatePensionProviderSettings
   * @summary Returns Providers List
   * @request PUT:/api/v2/companies/{companyId}/companysettings/PensionProvider/{pensionProviderId}
   * @secure
   */
  updatePensionProviderSettings = (
    companyId: number,
    pensionProviderId: number,
    companyPensionProviderSettingsPostModel: CompanyPensionProviderSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/PensionProvider/${pensionProviderId}`,
      method: "PUT",
      body: companyPensionProviderSettingsPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name ValidateUpdatePensionProviderSettings
   * @summary Returns Providers List
   * @request PUT:/api/v2/companies/{companyId}/companysettings/ValidatePensionProvider/{pensionProviderId}
   * @secure
   */
  validateUpdatePensionProviderSettings = (
    companyId: number,
    pensionProviderId: number,
    companyPensionProviderSettingsPostModel: CompanyPensionProviderSettingsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/ValidatePensionProvider/${pensionProviderId}`,
      method: "PUT",
      body: companyPensionProviderSettingsPostModel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanySettings
   * @name UpdateCompanyHouseData
   * @request PUT:/api/v2/companies/{companyId}/companysettings/UpdateCompanyDetails
   * @secure
   */
  updateCompanyHouseData = (companyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/companysettings/UpdateCompanyDetails`,
      method: "PUT",
      secure: true,
      ...params,
    });
}
