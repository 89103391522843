import { Form, Formik } from 'formik';
import { Submit, Switch, Text } from '../../../elements/EditRow/EditRow';
import { CompanyInformationPostModel} from '../../../api/inni/data-contracts';
import useCompareChange from '../../../hooks/useCompareChange';
import { CompanySettingsProps } from '../CompanySettingsDataTypes';
import InfoBanner from '../../../elements/InfoBanner/InfoBanner';
import { Button } from '../../../elements/Button/Button';

type CompanyInformationProps={
  handleUpdate:()=>void;
  disableUpdate:boolean;
}

function CompanyInformationTab<T extends CompanyInformationPostModel>({
    settings,
    handleSubmit,
    handleValidate,
    handleUpdate,
    isAdmin,
    disableUpdate
}: CompanySettingsProps<T> & CompanyInformationProps) {

  const formikRef = useCompareChange<T>();

  const isDisabled = (fieldName: string) => {
    if (settings?.editabilityMetaData?.readOnlyFields) {
        if (fieldName in settings.editabilityMetaData.readOnlyFields) {
            return true ;
        }
    }
    return false;
}

  return (
    <Formik
    initialValues={settings as T }
    validateOnChange={false}
    enableReinitialize
    onSubmit={handleSubmit}
    validate={handleValidate}
    innerRef={formikRef}
    validateOnBlur
>
    {({ isSubmitting,dirty}) => (

        <Form>
          <InfoBanner
            title="Is everything up to date?"
            body={
              <>
                <span className='mr-3'>
                  Data from Companies House can't be changed directly.<br />
                  If you need to make changes, fill out the relevant forms with them and click to update.
                </span>

                <Button
                  buttonType='sync'
                  variant='success'
                  onClick={handleUpdate}
                  tableBtn
                  label='Update'
                  disabled={disableUpdate}
                />
              </>
            }
            type="info"
            thin
          />
          <Text<CompanyInformationPostModel> name="name" label="Company name"  disabled={isDisabled("name")}/>
          <Text<CompanyInformationPostModel> name="phone" label="Company phone number" disabled={isDisabled("phone")}/>
          <Text<CompanyInformationPostModel> name="email" type="email" disabled={isDisabled("email")}/>
          <Text<CompanyInformationPostModel> name="address" type="textarea" label="Registered address" disabled={isDisabled("address")}/>
          <Text<CompanyInformationPostModel> name="postCode" label="Registered postcode" disabled={isDisabled("postCode")}/>
          <Text<CompanyInformationPostModel> name="tradingAddress" type="textarea" disabled={isDisabled("tradingAddress")}/>
          <Text<CompanyInformationPostModel> name="tradingPostCode" disabled={isDisabled("tradingPostCode")}/>
          <Text<CompanyInformationPostModel> name="sicCode" label="SIC code" disabled={isDisabled("sicCode")}/>
          { isAdmin && <>
            <Text<CompanyInformationPostModel> name="companySector" disabled={isDisabled("companySector")}/>
            <Switch<CompanyInformationPostModel> name="personalIDProvided" disabled={isDisabled("personalIDProvided")}/>
            <Switch<CompanyInformationPostModel> name="form648Completed" label="64-8 Completed" disabled={isDisabled("form648Completed")}/>
            <Switch<CompanyInformationPostModel> name="hideITRReminder" label="Hide SATR dashboard message" disabled={isDisabled("hideITRReminder")}/>
          </> }
          <Text<CompanyInformationPostModel>
            name="numAdditionalCompanies"
            type="number"
            label="Additional companies" help="This is important for claiming the lower rate of corporation tax, see https://www.gov.uk/hmrc-internal-manuals/company-taxation-manual/ctm03940. If in doubt, seek professional advice."
            disabled={isDisabled("numAdditionalCompanies")}
          />
          <Submit showCancel={false} disabled={isSubmitting || !dirty} onCancelClick={() => {}} />
        </Form>
    )}
</Formik>
  )
}

export default CompanyInformationTab;
