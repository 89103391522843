import { useContext, useEffect, useState } from "react";
import { DefaultLayout } from "../../../layouts/Desktop/DefaultLayout";
import * as DataGrid from "../../../elements/DataGrid/DataGrid";
import { Button } from "../../../elements/Button/Button";
import { Entity } from "../../../utils/EntityAction";
import { useInniAPI } from "../../../hooks/useInniAPI";
import { OpenBankingAccounts } from "../../../api/inni/OpenBankingAccounts";
import CompanyContext from "../../../context/CompanyContext";
import { CreatedBookkeepingAccountResponse, ImportOpenBankingAccountRequest, ImportedOpenBankingAccount, NotReconnectedAccount, OpenBankingAccount, OpenBankingAccountsResponse, ReconnectedAccount } from "../../../api/inni/data-contracts";
import { useParams } from "react-router-dom";
import {ImportCompleted, LoadingImportSuccess, ReconnectionCompleted} from "./Components/ImportSuccessful";
import { useModalDialog } from "../../../hooks/useModalDialog";
import InfoBanner, { LoadingInfoBanner } from "../../../elements/InfoBanner/InfoBanner";
import { useHistoryWrapper } from "../../../hooks/useHistoryWrapper";


interface RouteParams {  
    cnstId?: string;
    acctId?: string;
    isreauth?: string;
}

export default function ImportBanks(){
    const [loading, setLoading]=useState<boolean>(true);
    const [remoteAccounts,setRemoteAccounts]=useState<OpenBankingAccountsResponse|undefined>();
    const [importedBanks,setImportedBanks]=useState<OpenBankingAccountsResponse|undefined>();
    const [createdBookkeepingAccounts, setCreatedBookkeepingAccounts]= useState<CreatedBookkeepingAccountResponse[]>([]);
    const [ConnectedAccounts, setConnectedAccounts]= useState<ReconnectedAccount[]|undefined>();
    const [notConnectedAccounts, setNotConnectedAccounts]= useState<NotReconnectedAccount[]|undefined>();
    const [importBanks,setImportBanks]=useState<ImportOpenBankingAccountRequest>({
        openBankingConsentId: 0,
        institutionAccountIds: []
    })
    const [reauthorisedBanks, setReauthorisedBanks]=useState<string[]>([])
    const [notReauthorisedBanks, setNotReauthorisedBanks]=useState<string[]>([])
    const [reauthorisedAccountsWithConsent, setReauthorisedAccountsWithConsent] = useState<string[]>([]);
    const [reauthorisedNoconsentAccounts, setReauthorisedNoconsentAccounts] = useState<string[]>([]);
    const companyContext=useContext(CompanyContext)
    const openBankingAccountsAPI= useInniAPI(OpenBankingAccounts)
    const [isReconnectionCompleted, setIsReconnectionCompleted] = useState(false);
    const history = useHistoryWrapper();
    const {cnstId, acctId, isreauth} = useParams<RouteParams>()
    const [showModalDialog, modalDialog] = useModalDialog();
    const [isImportCompleted, setIsImportedCompleted] = useState(false);
    const isReauth = isreauth === "isreauth" ? true : false;
    const [isImporting, setIsImporting] = useState(false)
    //get accounts from yapily
    useEffect(()=>{
        if (openBankingAccountsAPI && cnstId){
            if(acctId){
                openBankingAccountsAPI.reconnectOpenBankingAccount(companyContext.cid, {bookkeepingAccountId:parseInt(acctId), openBankingConsentId:parseInt(cnstId)})
                .then((res)=>{
                    setConnectedAccounts(res.data.reconnectedAccounts);
                    setNotConnectedAccounts(res.data.notReconnectedAccountsWithMatches);
                    setIsReconnectionCompleted(true);
                })
                .catch((err)=>{
                        console.error(err)
                        history.push(`/company/${companyContext.cid}/import/failed?isreconnectionfailed=true`)
                    }
                );
            }
            else{
                openBankingAccountsAPI.listExternalOpenBankingAccounts(companyContext.cid, {consentId:parseInt(cnstId)})
                .then((res)=>{
                    setRemoteAccounts(res.data)
                    setLoading(false);
                })
                .catch((err)=>
                    console.error(err)
                );
            }            
        }
    },[cnstId, companyContext.cid, openBankingAccountsAPI]);

    //get imported banks in the application
    useEffect(()=>{
        if (openBankingAccountsAPI){
            openBankingAccountsAPI.index(companyContext.cid).then((res)=>{
                setImportedBanks(res.data)
            }).catch((err)=>
                console.error(err)
            );
        }
    },[companyContext.cid, openBankingAccountsAPI]);

    //combined list
    useEffect(()=>{
        if (isReauth && importedBanks?.accountsBankConsent && cnstId) {
            const accountsReauthorised = importedBanks?.accountsBankConsent?.find(x=>x.openBankingConsentId === parseInt(cnstId));
            const accountsUnderotherConsents = importedBanks?.accountsBankConsent?.filter(x=>x.openBankingConsentId !== parseInt(cnstId));
            const remoteBankAccounts = remoteAccounts?.accountsBankConsent?.find(x=>x.openBankingConsentId === parseInt(cnstId));

            const tempReauthorisedAccounts:string[] = [];
            const tempReauthorisedAccountsWithConsent:string[] = [];
            const tempReauthorisedAccountsUnderOtherConsent:string[] = [];
            const tempRemoteAccounts:string[] = [];
            const lstReauthorisedNoConsentAccounts: string[] =[]

            if(remoteBankAccounts){
                remoteBankAccounts.accounts?.forEach( (account) => {
                    if(account.institutionOpenBankingAccountId)
                        tempRemoteAccounts.push(account.institutionOpenBankingAccountId);
                } )
            }

            
            if(accountsReauthorised){
                accountsReauthorised.accounts?.forEach( (account) => {
                    if(account.institutionOpenBankingAccountId){
                        //list of banks consent got extended
                        tempReauthorisedAccounts.push(account.institutionOpenBankingAccountId);

                        //list of banks that are reauthorised and have bank authorisation
                        if(tempRemoteAccounts?.includes(account.institutionOpenBankingAccountId))
                            tempReauthorisedAccountsWithConsent.push(account.institutionOpenBankingAccountId);

                        //list of banks that are reauthorised and don't have bank authorisation
                        if(!tempRemoteAccounts?.includes(account.institutionOpenBankingAccountId))
                            lstReauthorisedNoConsentAccounts.push(account.institutionOpenBankingAccountId);
                    }
                    
                } )
            }
            setReauthorisedBanks([...tempReauthorisedAccounts]);
            setReauthorisedAccountsWithConsent([...tempReauthorisedAccountsWithConsent])
            setReauthorisedNoconsentAccounts([...lstReauthorisedNoConsentAccounts]);

            //list of banks that are authorised in the bank page but are under different consent in the application
            accountsUnderotherConsents.forEach((consents) => {
                consents.accounts?.forEach( (account) => {
                    if(account.institutionOpenBankingAccountId)
                    tempReauthorisedAccountsUnderOtherConsent.push(account.institutionOpenBankingAccountId);
                } )
            })
            
            //list of banks that are authorised in the bank page but are under different consent in the application
            const tempNotReauthorisedAccounts:string [] = tempRemoteAccounts.filter(x => tempReauthorisedAccountsUnderOtherConsent.includes(x));
            setNotReauthorisedBanks([...tempNotReauthorisedAccounts]);

        }

    },[importedBanks, isReauth, cnstId, remoteAccounts]);

    const handleImportButton = () => {
        if (openBankingAccountsAPI && importBanks.institutionAccountIds.length >0 ) {
            setIsImporting(true)
          openBankingAccountsAPI.importOpenBankingAccount(companyContext.cid, importBanks)
            .then((res) => {
              if (res.data && res.data.importedOpenBankingAccounts) {

                let errorImportedAccounts: ImportedOpenBankingAccount[] = [];

                let promises = res.data.importedOpenBankingAccounts.map(x =>
                  openBankingAccountsAPI.createBookkeepingAccount(companyContext.cid, { openBankingAccountId: x.openBankingAccountId })
                    .then((res) => {
                      setCreatedBookkeepingAccounts(prev => [...prev, res.data]);
                    })
                    .catch((error) => {
                      errorImportedAccounts.push(x);
                      console.error(error);
                    })
                );
      
                Promise.all(promises)
                  .then(() => {

                    if (errorImportedAccounts.length > 0) {
                      showModalDialog(
                        'Bookkeeping account creation failed',

                        <div>
                            {(errorImportedAccounts.length === importBanks.institutionAccountIds.length )?
                                    <p> An error occurred while creating bookkeeping accounts for the following </p>
                                :
                                    <p>  An error occurred while creating bookkeeping accounts for the below, remaining bookkeeping accounts created successfully.</p>
                            }
                          
                          <ul>
                            {errorImportedAccounts.map(account => (
                              <li key={account.openBankingAccountInstitutionId}>
                                {account.openBankingAccountInstitutionId}
                              </li>
                            ))}
                          </ul>
                        </div>,

                        [
                            <Button 
                                variant="secondary" 
                                label="Ok"
                                onClick={() => (errorImportedAccounts.length === importBanks.institutionAccountIds.length) ?
                                                {}
                                            :
                                                setIsImportedCompleted(true)
                                        } 
                            />,
                        ],
                        false
                      );
                    } else {
                        setIsImporting(false)
                        setIsImportedCompleted(true);
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }
            })
            .catch((err) => {
              showModalDialog(
                'Import failed',
                `An error occurred while importing the accounts. Please try again later.`,
                [
                  <Button variant="secondary" label="Ok" onClick={() => { }} />,
                ],
                false
              );
              console.error(err);
            });
        }
    };
      

    const handleOnChange=(accountID:string| undefined, consentId:number)=>{
        if(accountID){
            const tempImportBanks = { ...importBanks };

            if(tempImportBanks?.institutionAccountIds && tempImportBanks?.institutionAccountIds.includes(accountID)){
                tempImportBanks.institutionAccountIds =tempImportBanks?.institutionAccountIds.filter(x=> x !== accountID)
            }
            else{
                tempImportBanks.openBankingConsentId = consentId;
                tempImportBanks.institutionAccountIds = [...tempImportBanks.institutionAccountIds, accountID];                
            }
            setImportBanks(tempImportBanks);
        }
    };

    const toggleCheckbox = (openBankId:string|undefined, consentId:number)=>{
        if(!importBanks || !openBankId){
            return false;
        }
        else if(importBanks.institutionAccountIds.length ===0 && !isReauth){
            return false;
        }            
        else if(importBanks.institutionAccountIds.find((x)=> x === openBankId)){
            return true;
        }
        else if(isReauth && importedBanks?.accountsBankConsent){
            if(openBankId){
                return isAlreadyImported(openBankId);
            }
            return false;
            
        }
        else{
            return false;
        }
    }


    const isAlreadyImported = (openBankId:string|undefined) => {
        if (!importedBanks || !importedBanks.accountsBankConsent) {
          return false;
        }
      
        return importedBanks.accountsBankConsent.some(consent => {
            if(consent.accounts)
                return consent?.accounts.some(account => {
                    return account.institutionOpenBankingAccountId === openBankId;  
                });
            else
                return false
        });
    }

    return(

        <DefaultLayout
            title={(acctId && !isReconnectionCompleted)
                ? "Relinking ..."
                : isReconnectionCompleted
                    ? "Relinking completed"
                    : isreauth
                        ? "Reauthorisation completed"
                        : isImportCompleted
                            ? "Import completed"
                            : "Select accounts"}
            entity={Entity.OpenBankingAccounts}
        >
            {modalDialog}

            {acctId && isReconnectionCompleted &&

                <ReconnectionCompleted 
                    reconnectedAccounts={ConnectedAccounts} 
                    notConnectedAccounts={notConnectedAccounts} 
                    bankName={undefined} 
                />

            }

            { acctId && !isReconnectionCompleted &&

                <LoadingImportSuccess/>

            }

            {(isImportCompleted || isReauth) &&

                <ImportCompleted 
                    bookkeepingAccounts={createdBookkeepingAccounts}
                    bankName={remoteAccounts?.accountsBankConsent && remoteAccounts?.accountsBankConsent[0].bankName }
                    isReauth = {isReauth}
                    reauthorisedAccounts={reauthorisedBanks}
                    reauthorisedAccountsWithAuthorisation={reauthorisedAccountsWithConsent}
                    reauthorisedAccountsNoAuthorisation={reauthorisedNoconsentAccounts}
                    NotReauthorisedAccounts={notReauthorisedBanks}
                />
            }

            
            {!isReauth && !isImportCompleted && !acctId &&
                <>
                    {remoteAccounts  ?
                            <InfoBanner
                                body={`Please choose the accounts you would like to import from ${remoteAccounts.accountsBankConsent && remoteAccounts.accountsBankConsent[0].bankName}. You don't need to select accounts that are already imported.`}
                                noTopMargin
                                type="info"
                            />
                        :
                            <LoadingInfoBanner type={"info"}/>
                    }

                    <div style={{maxWidth:"1440px"}} data-cy="ImportBanks_SelectAccount">
                        <DataGrid.Table>
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Account name</th>
                                    <th>Nickname</th>
                                    <th>Currency</th>
                                    <th>Account number</th>
                                    <th>Sort code</th>
                                    <th>IBAN</th>
                                    <th>Account type</th>
                                    <th>Usage type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loading && !isReauth && remoteAccounts && remoteAccounts.accountsBankConsent && importedBanks && importedBanks.accountsBankConsent  ? 
                                    
                                    <>
                                        {remoteAccounts.accountsBankConsent.map((consent) => {
                                                return(
                                                    consent.accounts?.map((account)=>(
                                                        <tr
                                                            key={account.institutionOpenBankingAccountId}
                                                            onClick={()=>
                                                                !isAlreadyImported(account.institutionOpenBankingAccountId) ?
                                                                    handleOnChange(account.institutionOpenBankingAccountId, consent.openBankingConsentId)
                                                                :
                                                                    {}
                                                            }
                                                        >
                                                            <td style={{alignItems:"center"}}>
                                                                <input
                                                                    key={account.institutionOpenBankingAccountId}
                                                                    type="checkbox"
                                                                    checked={toggleCheckbox(account.institutionOpenBankingAccountId, consent.openBankingConsentId)}
                                                                    onChange={()=>{
                                                                        handleOnChange(account.institutionOpenBankingAccountId, consent.openBankingConsentId)
                                                                    }}
                                                                    disabled={isAlreadyImported(account.institutionOpenBankingAccountId)}
                                                                />
                                                            </td>
                                                            <td>{account.name}</td>
                                                            <td>{account.nickname}</td>
                                                            <td>{account.currency}</td>
                                                            <td>{account.accountNumber}</td>
                                                            <td>{account.sortCode}</td>
                                                            <td>{account.iban}</td>
                                                            <td>{account.accountType}</td>
                                                            <td>{account.usageType}</td>
                                                        </tr>
                                                        
                                                    ))
                                                )                                 
                                            })
                                        } 
                                    </>
                                :
                                    <>
                                        <DataGrid.LoadingRow cols={9} />
                                        <DataGrid.LoadingRow cols={9} />
                                        <DataGrid.LoadingRow cols={9} />
                                    </>
                                }
                                
                                <tr>
                                    <td colSpan={9}>
                                        <Button 
                                            buttonType="import"  
                                            label="Next" 
                                            onClick={handleImportButton} 
                                            disabled={(isreauth? !(reauthorisedBanks.length > 0 || importBanks.institutionAccountIds.length > 0) : importBanks.institutionAccountIds.length <= 0) || isImporting }
                                        />
                                    </td>
                                </tr>

                            </tbody>
                        </DataGrid.Table>
                    </div>
                </>
            }

        </DefaultLayout>
    );

}