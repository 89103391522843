import { useState, useEffect, useContext } from 'react';
import { Alert } from 'react-bootstrap';
import { Form, Formik, FormikHelpers } from 'formik';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { Action, Entity } from '../../utils/EntityAction';
import { useHasPermission } from '../../hooks/useHasPermission';
import CompanyContext from '../../context/CompanyContext';
// API
import { ContactProfiles } from '../../api/inni/ContactProfiles';
import { ContactProfile, ResetPasswordModel } from '../../api/inni/data-contracts';
import { useInniAPI } from '../../hooks/useInniAPI';
// Components
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder/LoadingPlaceholder";
import InfoBanner from '../../elements/InfoBanner/InfoBanner';
import { Text } from '../../elements/DetailRow/DetailRow';
import { Text as EditText }  from '../../elements/EditRow/EditRow'
import { Submit } from '../../elements/EditRow/EditRow';

const PortalProfile = () => {
  const contactProfilesApi = useInniAPI(ContactProfiles);
  const [profileData, setProfileData] = useState<ContactProfile|null>(null);
  const [initialValues, setInitialValues] = useState<ResetPasswordModel>({password: '', confirmPassword: ''});
  const [showSuccess, setShowSuccess] = useState(false);
  const hasAMSupport = useHasPermission()(Entity.AccountManagerSupport, Action.Read)[0]
  const companyContext = useContext(CompanyContext);

  useEffect(() => {
    if (contactProfilesApi) {
      contactProfilesApi
        .index(0)
        .then((response) => {
          setProfileData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [contactProfilesApi]);

  const formValidate =  (values: ResetPasswordModel) => {
    let errors:any = {};

    if (values.password?.trim() === '')
      errors.password = 'Required';

    if (values.confirmPassword?.trim() === '') {
      errors.confirmPassword = 'Required';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords don\'t match';
    }

    setShowSuccess(false);

    return errors;
  }

  const formSubmit = (values: ResetPasswordModel, actions: FormikHelpers<ResetPasswordModel>): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (contactProfilesApi) {
        contactProfilesApi.resetPassword(0, values)
        .then(response => {
          if (response.status === 200) {
            actions.resetForm();
            setShowSuccess(true);
          }
          resolve();
        })
        .catch(error => {
          actions.setErrors(error.error);
          reject();
        })
      } else {
        reject();
      }
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={formSubmit}
      validateOnChange={false}       
      validate={formValidate}
    >
      {() => (
        <Form>
          <DefaultLayout entity={Entity.UserProfile} title="My profile and password">

            { hasAMSupport && (
              <InfoBanner
                title="Is everything up to date?"
                body="These are the contact details we currently hold for you. Please contact us if you need to change anything."
                type="info"
              />
            )}

            <br />

            { profileData 
              ? (
                <>
                  <Text entity={profileData} editStyling name='title' />
                  <Text entity={profileData} editStyling name='firstName' />
                  <Text entity={profileData} editStyling name='lastName' />
                  <Text entity={profileData} editStyling name='phone' label='Phone number' />
                  <Text entity={profileData} editStyling name='email' />
                </>
              )
              : (
                <>
                  <LoadingPlaceholder width={'30%'} /><br /><br />
                  <LoadingPlaceholder width={'30%'} /><br /><br />
                  <LoadingPlaceholder width={'30%'} /><br /><br />
                  <LoadingPlaceholder width={'30%'} /><br /><br />
                  <LoadingPlaceholder width={'30%'} /><br /><br />
                </>
              )
            }

            <EditText<ResetPasswordModel> name='password' type='password' />
            <EditText<ResetPasswordModel> name='confirmPassword' type='password' />

            <Submit showCancel={false} />

            { showSuccess && (
              <Alert variant="success" className="mt-5" >
                Password updated
              </Alert>
            )}

          </DefaultLayout>
        </Form>       
      )}
    </Formik>  
  )
}

export default PortalProfile
