import { Card, Row } from 'react-bootstrap';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { LoadingPlaceholder, LoadingText } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { DefaultLayout } from '../../../layouts/Desktop/DefaultLayout';
import Toolbar from '../../../layouts/Desktop/Toolbar';
import { InvoiceLayoutWrapper } from './ReadInvoiceWrapper';

function Loadingtemplate({ editing , width}: { editing?: boolean,width?:string }) {
	return (
		<DefaultLayout title=" " noPadding useFullWidth>
			<Toolbar>
				<h1><LoadingPlaceholder width="200px" /></h1> 
			</Toolbar>
			<Toolbar>
				<div className="d-flex">
					{Array(2)
						.fill(0)
						.map((x, index) => (
							<div className="mr-2" key={index}>
								<LoadingPlaceholder width="120px" height="36.4px" />
							</div>
						))}
				</div>
			</Toolbar>
			{!editing && (
				<div className="vw-100 bg-light py-3">
					<div className="d-md-flex ml-3" style={{ maxWidth: width || '1200px' }}>
						{Array(3)
							.fill(0)
							.map((_, index) => (
								<div className="w-100 mr-3" key={index}>
									<LoadingPlaceholder height="8rem" />
								</div>
							))}
					</div>
				</div>
			)}
			<div className={!editing ? 'd-xl-flex ' : 'mt-3'} style={{maxWidth:width || '1200px'}}>
				<div className='ml-3 w-100'>
				<InvoiceLayoutLoading />
				</div>
				{!editing && (
					<div className="ml-3 w-25">
						<LoadingPlaceholder height="20rem" />
					</div>
				)}
			</div>
		</DefaultLayout>
	);
}

export function InvoiceLayoutLoading() {
	return (
		<div className="w-100">
			<InvoiceLayoutWrapper>
				<Card.Body className="my-5 pb-5 ">
					<LoadingPlaceholder width="25%" height="50px" />
					<>
						<InvoiceLayoutRowLoading items={3} />
						<InvoiceLayoutRowLoading items={3} />
					</>
					<div className=" mt-5">
						<LoadingPlaceholder height="40px" />
						<hr></hr>
						<LoadingPlaceholder height="30px" />
						<hr></hr>
						<LoadingPlaceholder height="30px" />
					</div>
					<hr></hr>
					<div className="w-100">
						<LoadingPlaceholder width="30%" height="40px" float="right" />
					</div>
				</Card.Body>
			</InvoiceLayoutWrapper>
		</div>
	);
}

function InvoiceLayoutRowLoading({ items }: { items: number }) {
	const arr = new Array(items).fill(0);
	
	return (
		<>
			<Row className="mb-1" xl={4} md={3} sm={2} lg={4} xs={1}>
				{arr.map((_, index) => {
					return (
						<div key={index} className="ml-3 mt-4 mr-4">
							<LoadingPlaceholder width="120px" height="20px"></LoadingPlaceholder>
							<LoadingText></LoadingText>
						</div>
					);
				})}
			</Row>
		</>
	);
}

export default Loadingtemplate;

const InvoiceTableHead = () => (
	<thead>
		<tr>
			<th style={{ width: '10%' }}>Date</th>
			<th style={{ width: '15%' }}>Type</th>
			<th style={{ width: '10%' }}>No</th>
			<th style={{ width: '10%' }}>Tenancy</th>
			<th style={{ width: '10%' }}>Property</th>
			<th>Amount</th>
			<th style={{ width: '15%' }}>Description</th>
			<th style={{ width: '7.5%' }}>Amount</th>
			<th style={{ width: '7.5%' }}>Balance</th>
			<th style={{ width: '7.5%' }}>Duedate</th>
			<th style={{ width: '7.5%' }}>Status</th>
		</tr>
	</thead>
);
export const InvoiceViewerNewLoading = () => {
	const colCount = 11;
	
	return (
		<DataGrid.Table noHover>
			<InvoiceTableHead />
			<tbody>
				<DataGrid.LoadingRow cols={colCount} />
				<DataGrid.LoadingRow cols={colCount} />
				<DataGrid.LoadingRow cols={colCount} />
			</tbody>
		</DataGrid.Table>
	);
};
