/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Invoice, Tenancy, TenancyAsDetailed, TenancyPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Tenancies<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Tenancies
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/tenancies
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Tenancy[], any>({
      path: `/api/v2/companies/${companyId}/tenancies`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tenancies
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/tenancies
   * @secure
   */
  create = (companyId: number, postModel: TenancyPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/tenancies`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tenancies
   * @name DeleteAll
   * @request DELETE:/api/v2/companies/{companyId}/tenancies
   * @secure
   */
  deleteAll = (companyId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/tenancies`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tenancies
   * @name Get
   * @summary For the edit page? I'm expecting another endpoint for tenancy stats...
   * @request GET:/api/v2/companies/{companyId}/tenancies/{tenancyid}
   * @secure
   */
  get = (companyId: number, tenancyid: number, params: RequestParams = {}) =>
    this.request<TenancyAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyid}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tenancies
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/tenancies/{tenancyid}
   * @secure
   */
  update = (companyId: number, tenancyid: number, postModel: TenancyPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyid}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tenancies
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/tenancies/{tenancyid}
   * @secure
   */
  delete = (companyId: number, tenancyid: number, params: RequestParams = {}) =>
    this.request<object, string>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyid}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tenancies
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/tenancies/{tenancyid}/Validate
   * @secure
   */
  validateUpdate = (companyId: number, tenancyid: number, postmodel: TenancyPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyid}/Validate`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tenancies
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/tenancies/Validate
   * @secure
   */
  validateCreate = (companyId: number, postmodel: TenancyPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/tenancies/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Tenancies
   * @name ListInvoices
   * @request GET:/api/v2/companies/{companyId}/tenancies/{tenancyId}/invoices
   * @secure
   */
  listInvoices = (companyId: number, tenancyId: number, params: RequestParams = {}) =>
    this.request<Invoice[], any>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyId}/invoices`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
