/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TaxCode, TaxCodePostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class TaxCodes<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags TaxCodes
   * @name GetForEmployment
   * @request GET:/api/v2/companies/{companyId}/employments/{employmentId}/taxcodes
   * @secure
   */
  getForEmployment = (companyId: number, employmentId: number, params: RequestParams = {}) =>
    this.request<TaxCode[], any>({
      path: `/api/v2/companies/${companyId}/employments/${employmentId}/taxcodes`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaxCodes
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/employments/{employmentId}/taxcodes
   * @secure
   */
  create = (companyId: number, employmentId: number, model: TaxCodePostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/employments/${employmentId}/taxcodes`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TaxCodes
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/employments/{employmentId}/taxcodes/ValidateCreate
   * @secure
   */
  validateCreate = (companyId: number, employmentId: number, model: TaxCodePostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/employments/${employmentId}/taxcodes/ValidateCreate`,
      method: "POST",
      body: model,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags TaxCodes
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/employments/{employmentId}/taxcodes/{taxcodeId}
   * @secure
   */
  delete = (companyId: number, employmentId: number, taxcodeId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/employments/${employmentId}/taxcodes/${taxcodeId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
}
