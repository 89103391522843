import styles from './VerticalTabs.module.css';
import React from 'react';
import classNames from 'classnames';


const Wrapper = ({children} : {children: React.ReactNode}) => {
    return (
        <div className={styles.wrapper}>
            {children}
        </div>
    )
}

// noPadding is a required for e.g. v8 reports where we have removed the actual tabs
const Content = ({children, className, noPadding} : {children: React.ReactNode, className?: string, noPadding?: boolean}) => {
    return (
        <div className={classNames(styles.content, className, {'p-0': noPadding})}>
            {children}
        </div>
    )
}

export interface TabsProps {
    selectedKey?: string, 
    onChangeTab: (newTab: string|undefined) => void, 
    children: React.ReactElement<TabProps>|React.ReactElement<TabProps>[],
    appendEnd?: React.ReactElement, // used to add some element onto the end - e.g. the button on QE - dont want it to be a tab item though!
    className?: string
}

const Tabs = ({selectedKey, onChangeTab, children, appendEnd, className} : TabsProps) => {
    return (
        <div className={classNames(styles.tabs, className)}>
            {React.Children.map(children, (child) => 
                React.cloneElement(child, {selected: child.props.keyName === selectedKey, onClick:onChangeTab})
            )}
            {appendEnd}
        </div>
    )
}

export interface TabProps {
    keyName?: string,
    selected?: boolean,
    disabled?: boolean, 
    children?: React.ReactNode,
    onClick?: (keyName: string|undefined) => void,
    admin?: boolean,
    hidden?: boolean,
    className?: string
}

const Tab = ({keyName, selected, disabled, children, onClick, admin=false, hidden=false, className} : TabProps) => {
    const click = () => {
        if (keyName && onClick)
            onClick(keyName);
    }

    if (!hidden)
        return (
                <button
                    data-cy={keyName ? `verticalTab_${keyName}` : 'tab_heading'}
                    data-selected={selected} 
                    disabled={disabled}
                    onClick={click}
                    className={classNames(
                        styles.tab,
                        {
                            [styles.selected]: selected,
                            [styles.disabled]: disabled,
                            [styles.admin]: admin,
                            [styles.label]: keyName === undefined
                        }
                    )}
                >
                    {children}
                </button>
        )

    return null;
}

export {Wrapper, Content, Tabs, Tab}