import { formatCurrency } from '../../../utils/formatNumbers';

export function getUniqueValues<T>(obj: T[], filterKey: keyof T): any[] {
	return obj.map((x) => x[filterKey]).filter((v, i, a) => a.indexOf(v) === i);
}

export const currencyConvertor = (value: number, currency: string | undefined) => {
	if(currency){
		if(value < 0){
			return '-' + currency + '' +formatCurrency(Math.abs(value),true);
		}

		return currency + '' + formatCurrency(Math.abs(value),true);
	}

	return value < 0 ? '-' + formatCurrency(Math.abs(value)) : '' + formatCurrency(value);
};
