import classNames from 'classnames';
import { Col, Form } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useAssertPermission } from '../../../hooks/useAssertPermission';
import { Entity, Action } from '../../../utils/EntityAction';
import DisplayCards from '../Elements/DisplayCards/DisplayCards';
import InfoBanner from '../../../elements/InfoBanner/InfoBanner';
import TimeLine from '../Elements/Timeline/TimeLine';
import { Currency, InvoiceAsDetailed, InvoiceRefund, InvoiceTimeLine, Market, MarketTerms } from '../../../api/inni/data-contracts';
import { InvoiceLine } from '../../../api/inni/data-contracts';
import { formatDate } from '../../../utils/formatDate';
import styles from '../Invoice.module.css';
import {
	ActionType,
	getDocumentName,
	InvoiceLayoutTableHeadersVATDisabled,
	InvoiceLayoutTableHeadersVATEnabled,
	ShowField,
	ShowLabel,
} from '../HelperFunctions/ShowField';
import { useState, useEffect } from 'react';
import { Markets } from '../../../api/inni/Markets';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { IR35Status } from './ir35status';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { Button } from '../../../elements/Button/Button';
import { currencyConvertor } from '../HelperFunctions/HelperFunctions';
import { Currencies } from '../../../api/inni/Currencies';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';

type ReadInvoiceWrapperProps = {
	invoiceDetails: InvoiceAsDetailed;
	confirmTaxdeducton: () => void;
	companyId: number;
	refundAmount: (invoiceRefund?: InvoiceRefund) => void;
	onClickInvoiceTimeLineButton:(id:number)=>void;
};

type TimeLineCardProps = {
	timeLineData: InvoiceTimeLine[];
	onClickInvoiceTimeLineButton:(id:number)=>void;
};
type InvoiceLayoutProps = {
	children: React.ReactNode;
};

type TableTotalFieldProps = {
	value: number | undefined;
	bold?: boolean;
	text: string;
	colspan?: number;
	editing?: boolean;
	currency?: string;
};
export function ReadInvoiceWrapper({ invoiceDetails, companyId, refundAmount, confirmTaxdeducton ,onClickInvoiceTimeLineButton }: ReadInvoiceWrapperProps) {
	useAssertPermission(Entity.InvoiceV7, Action.Read);
	const hasPermission = useHasPermission();
	const [refundValue, setRefundValue] = useState<InvoiceRefund>();
	const {
		invoiceNumber,
		documentType,
		contractId,
		overdue,
		amountPaid,
		amountTotal,
		selectedPaymentProviderId,
		amountVAT,
		invoiceReference,
		supplyCountry,
		invoiceCurrency,
		iR35Status,
		stripeCustomerAmountPaidTotal,
		isMigrationInvoice,
		maximumStripeRefund,
	} = invoiceDetails;

	const marketApi = useInniAPI(Markets);
	const [markets, setMarkets] = useState<Market>();
	const [marketTerm, setMarketTerm] = useState<MarketTerms>();
	const [marketsLoaded, setmarketsLoaded] = useState(false);
	const currencyAPI = useInniAPI(Currencies);
	const [currencies, setCurrencies] = useState<Currency>();

	//Market API call is used for getting the market name for the Contract in invoice i,e Contract,Tenancy,Project....
	useEffect(() => {
		if (marketApi && !marketsLoaded) {
			marketApi.index(companyId).then((res) => {
				setMarkets(res.data);
				setmarketsLoaded(true);
			});
		}
	}, [companyId, marketApi, marketsLoaded]);
	useEffect(() => {
		if(marketApi){
			marketApi?.marketTerms(companyId)
			.then((response) => {
				setMarketTerm(response.data);
			})
		}
	}, [markets, companyId, marketApi]);

	useEffect(() => {
		if (currencyAPI ) {
			currencyAPI.index().then((response: { data: Currency[] }) => setCurrencies(response.data.find((currency) => currency.code === invoiceCurrency))); 
		}
	}, [companyId, currencyAPI, invoiceCurrency]);

	return (
		<>
			{documentType !== 'InvoiceTemplate' && documentType !== 'Quote' && (
				<div className={styles.displayCardWrapper}>
					<div style={{ maxWidth: '1220px' }} className="w-100 px-3">
						<div className="d-md-flex justify-content-space">
							{invoiceDetails && (
								<>
									<DisplayCards
										classname="mr-2 w-100 "
										cardDetails={
											documentType !== 'Invoice'
												? currencyConvertor(Math.abs(amountTotal), currencies?.symbol)
												: currencyConvertor(amountTotal, currencies?.symbol)
										}
										showBanner={true}
										bold={false}
										bannertext={amountVAT !== 0 ? 'Including VAT' : ''}>
										{getDocumentName(documentType) + ' total'}
									</DisplayCards>
									<DisplayCards
										classname="mr-2 w-100"
										cardDetails={
											documentType !== 'Invoice'
												? currencyConvertor(Math.abs(amountPaid), currencies?.symbol)
												: currencyConvertor(amountPaid, currencies?.symbol)
										}>
										Paid to date
									</DisplayCards>
									<DisplayCards
										classname="w-100"
										cardDetails={
											invoiceDetails && documentType !== 'Invoice'
												? currencyConvertor(Math.abs(amountTotal - amountPaid), currencies?.symbol)
												: currencyConvertor(amountTotal - amountPaid, currencies?.symbol)
										}
										balanceStatus={overdue}
										showBanner={true}
										bold={overdue}
										bannertext=" Due by"
										dueDate={invoiceDetails.dateDue}>
										{overdue ? <b>Overdue balance</b> : 'Balance'}
									</DisplayCards>
								</>
							)}
						</div>
					</div>
				</div>
			)}
			{documentType !== 'CreditNote' && selectedPaymentProviderId !== null && amountTotal - amountPaid > 0 ? (
				<div className="px-3">
					<InfoBanner
						title="Client invoice payment"
						body={`Your client can pay this invoice online through Stripe. Click "Email PDF" to email this invoice
                    (including a link enabling them to pay online with a Credit or Debit Card) to your client.`}
						type="info"
					/>
				</div>
			) : (
				''
			)}
			{isMigrationInvoice && (
				<InfoBanner
					title="Migration invoice"
					body="This invoice is a migration invoice. It is not a real invoice."
					type="info"
				/>
			)}
			{invoiceDetails && documentType !== 'Quote' && overdue ? (
				<div className="px-3">
					<InfoBanner
						title={`${documentType} payment overdue`}
						body={`The balance of this ${documentType} is ${currencyConvertor(
							amountTotal - amountPaid,
							currencies?.symbol
						)} and was due by ${formatDate(invoiceDetails?.dateDue)}.
          Once you have received payment please confirm the transaction in bookkeeping
          to mark this invoice as paid.`}
						type="danger"></InfoBanner>
				</div>
			) : (
				''
			)}
			<div className="px-3">
				{iR35Status !== null && (
					<IR35Status companyId={companyId} invoiceDetails={invoiceDetails} confirmTax={confirmTaxdeducton} />
				)}
			</div>
			{documentType === 'CreditNote' &&
			hasPermission(Entity.PaymentProvidersList, Action.All)[0] &&
			stripeCustomerAmountPaidTotal && stripeCustomerAmountPaidTotal > 0 &&
			Math.abs(amountPaid) < Math.abs(amountTotal) ? ( 
				<div className="px-3">
				<InfoBanner
					title="Credit note is unpaid"
					body={
						<>
							<>This credit note is unpaid. You can pay the credit note by supplying the refund amount here and pressing pay</>
							<div className="d-flex">
								<Form.Control
									style={{ width: '150px' }}
									className=""
									type="number"
									max={maximumStripeRefund}
									value={maximumStripeRefund}
									onChange={(e) => {
										setRefundValue({ ...refundValue, amount: +e.target.value });
									}}
									size="sm"
									isInvalid={refundValue?.amount && maximumStripeRefund ? refundValue?.amount > maximumStripeRefund : false}
								/>
								<Button label="Pay now" className="ml-2" buttonType="save" onClick={() => refundAmount(refundValue)} tableBtn></Button>
							</div>
						</>
					}
					type="warning"
				/>
				</div> 
			) : (
				''
			)}
			<div
				className={classNames(
					!overdue && 'mt-3',
					(documentType === 'Invoice' || documentType === 'CreditNote') &&
						(invoiceCurrency !== 'GBP' || supplyCountry !== 'GB')
						? 'd-xl-flex mb-3 px-3 '
						: 'd-lg-flex mb-3 px-3'
				)}>
				<InvoiceLayoutWrapper>
					<Card.Body>
						<div className={classNames(styles.invoiceHeaderFont, 'my-4 mx-3')}>
							{getDocumentName(documentType)?.toUpperCase()}
						</div>
						{invoiceDetails && (
							<div className={classNames(documentType === 'Quote' ? styles.quoteRead : styles.invoiceRead, 'px-2')}>
								<>
									{'InvoiceTemplate' === documentType && ShowField(documentType, 'description', ActionType.Read) && (
										<>
											<Form.Group as={Col}>
												<Form.Label className="mb-0">
													{ShowLabel<InvoiceAsDetailed>(documentType, 'description', ActionType.Read)}
												</Form.Label>
												<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
													{invoiceDetails.description}
												</Form.Text>
											</Form.Group>
											<div></div>
										</>
									)}
									{invoiceReference !== null &&
										invoiceReference !== '' &&
										ShowField(documentType, 'invoiceReference', ActionType.Read) && (
											<>
												<Form.Group as={Col}>
													<Form.Label className="mb-0">
														{ShowLabel<InvoiceAsDetailed>(documentType, 'invoiceReference', ActionType.Read)}
													</Form.Label>
													<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
														{invoiceDetails.invoiceReference}
													</Form.Text>
												</Form.Group>
												<Form.Group as={Col}>
													{ShowField(documentType, 'invoiceNumber', ActionType.Read) && (
														<>
															<Form.Label className="mb-0">
																{ShowLabel<InvoiceAsDetailed>(documentType, 'invoiceNumber', ActionType.Read)}
															</Form.Label>
															<Form.Text className="" style={{ fontSize: '14px' }}>
																{invoiceNumber.toString().padStart(4, '0')}
															</Form.Text>
														</>
													)}
													{ShowField(documentType, 'description', ActionType.Read) && (
														<>
															<Form.Label className="mt-4 mb-0">
																{ShowLabel<InvoiceAsDetailed>(documentType, 'description', ActionType.Read)}
															</Form.Label>
															<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
																{invoiceDetails.description}
															</Form.Text>
														</>
													)}
												</Form.Group>
											</>
										)}
									{(invoiceReference === null || invoiceReference === '') && (
										<>
											{ShowField(documentType, 'invoiceNumber', ActionType.Read) && (
												<Form.Group as={Col}>
													<Form.Label className="mb-0">
														{ShowLabel<InvoiceAsDetailed>(documentType, 'invoiceNumber', ActionType.Read)}
													</Form.Label>
													<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
														{invoiceDetails.invoiceNumber.toString().padStart(4, '0')}
													</Form.Text>
												</Form.Group>
											)}
											{'InvoiceTemplate' !== documentType && ShowField(documentType, 'description', ActionType.Read) && (
												<Form.Group as={Col}>
													<Form.Label className="mb-0">
														{ShowLabel<InvoiceAsDetailed>(documentType, 'description', ActionType.Read)}
													</Form.Label>
													<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
														{invoiceDetails.description}
													</Form.Text>
												</Form.Group>
											)}
										</>
									)}
									{ShowField(documentType, 'contractName', ActionType.Read) && (
										<Form.Group as={Col}>
											{marketTerm ? (
												<Form.Label className="mb-0">{marketTerm?.contractTerm}</Form.Label>
											) : (
												<LoadingPlaceholder></LoadingPlaceholder>
											)}
											<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
												{invoiceDetails.contractName}
											</Form.Text>
										</Form.Group>
									)}
									{ShowField(documentType, 'invoiceAddress', ActionType.Read) && (
										<Form.Group as={Col}>
											<Form.Label className="mb-0">
												{ShowLabel<InvoiceAsDetailed>(documentType, 'invoiceAddress', ActionType.Read)}
											</Form.Label>
											<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
												{invoiceDetails.invoiceAddress}
											</Form.Text>
										</Form.Group>
									)}
									{(invoiceCurrency !== 'GBP' || supplyCountry !== 'GB') &&
										ShowField(documentType, 'invoiceCurrency', ActionType.Read) && (
											<Form.Group as={Col}>
												<Form.Label className="mb-0">
													{ShowLabel<InvoiceAsDetailed>(documentType, 'invoiceCurrency', ActionType.Read)}
												</Form.Label>
												<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
													{invoiceDetails.invoiceCurrency}
												</Form.Text>
											</Form.Group>
										)}
									{(invoiceCurrency !== 'GBP' || supplyCountry !== 'GB') &&
										ShowField(documentType, 'currencyRateAtTaxPoint', ActionType.Read) && (
											<Form.Group as={Col}>
												<Form.Label className="mb-0">
													{ShowLabel<InvoiceAsDetailed>(documentType, 'currencyRateAtTaxPoint', ActionType.Read)}
												</Form.Label>
												<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
													{currencyConvertor(invoiceDetails.currencyRateAtTaxPoint, currencies?.symbol)}
												</Form.Text>
												<Form.Label className="mb-0">
													{ShowLabel<InvoiceAsDetailed>(documentType, 'supplyCountry', ActionType.Read)}
												</Form.Label>
												<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
													{invoiceDetails.supplyCountry}
												</Form.Text>
											</Form.Group>
										)}
									{ShowField(documentType, 'date', ActionType.Read) && (
										<Form.Group as={Col}>
											<Form.Label className="mb-0">
												{ShowLabel<InvoiceAsDetailed>(documentType, 'date', ActionType.Read)}
											</Form.Label>
											<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
												{formatDate(invoiceDetails.date)}
											</Form.Text>
										</Form.Group>
									)}
									{ShowField(documentType, 'dateDue', ActionType.Read) && (
										<Form.Group as={Col}>
											<Form.Label className="mb-0">
												{ShowLabel<InvoiceAsDetailed>(documentType, 'dateDue', ActionType.Read)}
											</Form.Label>
											<Form.Text className="" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
												{formatDate(invoiceDetails.dateDue)}
											</Form.Text>
										</Form.Group>
									)}
									{ShowField(documentType, 'autoEmailRepeatingInvoice', ActionType.Read) && (
										<Form.Group as={Col}>
											<Form.Label className="mb-0">
												{ShowLabel<InvoiceAsDetailed>(documentType, 'autoEmailRepeatingInvoice', ActionType.Read)}
											</Form.Label>
											<Form.Text className="mt-0" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
												{invoiceDetails.autoEmailRepeatingInvoice ? 'Yes' : 'No'}
											</Form.Text>
											<span></span>
											{contractId !== null && contractId !== 0 && (
												<>
													<Form.Label className="mt-1">
														{ShowLabel<InvoiceAsDetailed>(documentType, 'defaultDueDays', ActionType.Read)}
													</Form.Label>
													<Form.Text className="mt-0" style={{ fontSize: '14px', whiteSpace: 'pre-line' }}>
														{invoiceDetails.defaultDueDays}
													</Form.Text>
												</>
											)}
										</Form.Group>
									)}
								</>
							</div>
						)}
						<div className={classNames(styles.invoiceTable)}>
							<table className="" data-cy="invoiceLinesTable">
								<InvoiceLayoutTableHeaders showVAT={amountVAT !== 0} />
								<tbody>
									{invoiceDetails?.lines?.map((item: InvoiceLine) => (
										<tr key={item.id}>
											<td className="text-left">{item.description}</td>
											<td className="text-right">{item.quantity?.toFixed(2)}</td>
											<td className="text-right">
												{documentType !== 'Invoice'
													? item.unitPrice && currencyConvertor(Math.abs(item.unitPrice), currencies?.symbol).padEnd(2, '0')
													: currencyConvertor(item.unitPrice || 0, currencies?.symbol).padEnd(2, '0')}
											</td>
											{amountVAT !== 0 && <td className="text-right">{item.vatCode}</td>}
											<td className="text-right">
												{documentType !== 'Invoice'
													? currencyConvertor(Math.abs(item.amountExVAT), currencies?.symbol)
													: currencyConvertor(item.amountExVAT, currencies?.symbol)}
											</td>
										</tr>
									))}
								</tbody>
								<tfoot>
									{amountVAT !== 0 && (
										<>
											<TableTotalField
												value={documentType !== 'Invoice' ? Math.abs(invoiceDetails.amountNet) : invoiceDetails.amountNet}
												currency={currencies?.symbol}
												text="Sub total"
											/>
											<TableTotalField
												value={documentType !== 'Invoice' ? Math.abs(invoiceDetails.amountVAT) : invoiceDetails.amountVAT}
												currency={currencies?.symbol}
												text="VAT"
											/>
										</>
									)}
									<TableTotalField
										colspan={amountVAT !== 0 ? 3 : 2}
										value={documentType !== 'Invoice' ? Math.abs(invoiceDetails.amountTotal) : invoiceDetails.amountTotal}
										text="Grand Total"
										currency={currencies?.symbol}
										bold
									/>
								</tfoot>
							</table>
						</div>
					</Card.Body>
				</InvoiceLayoutWrapper>
				{invoiceDetails?.timeLines && (
					<div
						className={
							(documentType === 'Invoice' || documentType === 'CreditNote') &&
							(invoiceCurrency !== 'GBP' || supplyCountry !== 'GB')
								? 'pl-xl-2 mt-lg-2 mt-xl-0 w-lg-100 w-xl-50'
								: 'pl-lg-2 mt-md-2 mt-lg-0 w-md-100 w-lg-50'
						}>
						<TimeLineCard
							timeLineData={invoiceDetails.timeLines}
							onClickInvoiceTimeLineButton={onClickInvoiceTimeLineButton}
						/>
					</div>
				)}
			</div>
		</>
	);
}

export const InvoiceLayoutWrapper = ({ children }: InvoiceLayoutProps) => {
	return <Card className={styles.invoiceLayout} data-cy="invoiceLayout">{children}</Card>;
};

export const InvoiceLayoutTableHeaders = ({ showVAT ,editing }: { editing?: boolean; showVAT: boolean }) => {
	const findHeadersType = (showVAT: boolean): string[] => {
		return showVAT ? Object.values(InvoiceLayoutTableHeadersVATEnabled) : Object.values(InvoiceLayoutTableHeadersVATDisabled);
	};

	return (
		<>
			<thead>
				<tr style={{ color: '#6c757d' }}>
					{findHeadersType(showVAT)?.map((item, index) => (
						<th
							key={item}
							style={{
								width: index === 0 ? '100%' : undefined,
								textAlign: index !== 0 ? 'right' : 'left',
							}}
							className={classNames(index === 0 ? styles.invoiceTableHeader : '','text-nowrap text-uppercase font-weight-normal')}>
							{item}
						</th>
					))}
					{editing && <th></th>} 
				</tr>
			</thead>
		</>
	);
};

export const TableTotalField = ({ value, bold, text, editing = false, colspan,currency }: TableTotalFieldProps) => {
	return (
		<tr data-cy="totalField">
			<td style={{ background: 'none' }} className='py-1'></td>
			<td style={{ textAlign: 'right', fontWeight: bold ? 'bold' : 'normal', verticalAlign: 'bottom', background: 'none' }} className="text-nowrap py-1">
			</td>
			<td colSpan={colspan ? colspan : 3} style={{ textAlign: 'right', fontWeight: bold ? 'bold' : 'normal', verticalAlign: 'bottom', background: 'none' }} className='py-1'>
				{text}: {value ? currencyConvertor(value,currency) : currencyConvertor(0,currency)}
			</td>
		</tr>
	);
};

const TimeLineCard = ({ timeLineData, onClickInvoiceTimeLineButton}: TimeLineCardProps) => {
	if (timeLineData.length === 0) {
		return null;
	}

	return (
		<Card className={styles.invoiceTimeLine}>
			<Card.Body>
				<TimeLine timeLine={timeLineData} onClickInvoiceTimeLineButton={onClickInvoiceTimeLineButton}></TimeLine>
			</Card.Body>
		</Card>
	);
};
