import {
	storyblokEditable,
	StoryblokComponent,
	SbBlokData,
} from '@storyblok/react';
import styles from './navigation.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/pro-regular-svg-icons';
type Props = {
	blok: SbBlokData;
    onNavItemSelect: (uid: string, pageUrl: string) => void;
    selectedUid: string;
};
const NavGroup = ({ blok, onNavItemSelect, selectedUid }: Props) => {
	return (
		<div {...storyblokEditable(blok)}>
			<div className={styles.navGroup}>
				{blok.icon && (
					<FontAwesomeIcon
						icon={[
							(blok.icon as string).split('-')[0] as IconPrefix,
							(blok.icon as string).split(
								/-(.+)/
							)[1] as IconName,
						]}
						fixedWidth
						style={{ marginRight: '0.5em' }}
					/>
				)}
				{blok.title}
			</div>
			{blok.items
				? (blok.items as SbBlokData[]).map((blok) => (
						<StoryblokComponent
							blok={blok}
							key={blok._uid}
							onNavItemSelect={onNavItemSelect}
							selectedUid={selectedUid}
						/>
				  ))
				: null}
		</div>
	);
};

export default NavGroup;
