import Select from 'react-select';
import { Button } from '../../elements/Button/Button';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import Toolbar from '../../layouts/Desktop/Toolbar';
import { Action, Entity } from '../../utils/EntityAction';
import { useAccountingPeriods } from '../../hooks/query/useAccountingPeriods';
import useTransactionQueries from '../../hooks/query/useTransactionQueries';
import { createRef, Fragment, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import useTransactions from '../../hooks/query/useTransactions';
import {
	AccountingPeriodReviewItem,
	AccountingPeriodReviewItemPostModel,
	BankTransaction,
} from '../../api/inni/data-contracts';
import QueryCard from './QueryCard';
import { TenancyNoteEntryPlaceholder } from '../Tenancies/Components/TenancyNotePlaceholder';
import queriesStyles from './Queries.module.css';
import { useHasPermission } from '../../hooks/useHasPermission';
import CreateQueryModal from './CreateQueryModal';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { useModalDialog } from '../../hooks/useModalDialog';
import { NoContentSlate } from '../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../hooks/terms/useDatagridTerms';
import { useLocation, useParams } from 'react-router-dom';
import { useHistoryWrapper } from '../../hooks/useHistoryWrapper';

type EditModalProps = {
	show: boolean;
	query: string;
	newAccountId: number;
	id: number;
	bankPaymentId: undefined | number;
	bankTransferId: undefined | number;
};

type SeparatedQueries = { accountName: string; accountId: number; queries: AccountingPeriodReviewItem[] }[];
export interface ReviewItemsPostModel extends Omit<AccountingPeriodReviewItemPostModel, 'newAccountId'> {
	newAccountId?: string;
}

const defaultModalProps = {
	show: false,
	query: '',
	newAccountId: 0,
	id: 0,
	bankPaymentId: undefined,
	bankTransferId: undefined,
};

type Params = {yearId?: string, cid: string}

const Queries = () => {
	const [showModalDialog, modalDialog] = useModalDialog();
	const historyWrapper = useHistoryWrapper();
	const params = useParams<Params>()
	
	const { state, pathname } = useLocation<{ periodId: number; queryId?: number }>();
	const { periodOptions, setPeriod, period, cid, defaultAccountingPeriodId } = useAccountingPeriods(state?.periodId);
	const {
		queries,
		publishQueries,
		setIsAdmin,
		onResponse,
		onAcceptSuggestion,
		setAlertMessage,
		alertMessage,
		onEditQuery,
		isAllQueriesPublished,
		queriesLoading,
	} = useTransactionQueries(period?.value || 0);
	const [editQueryModalProps, setEditQueryModalProps] = useState<EditModalProps>(defaultModalProps);
	const { accounts: accountList, loading, transactions, setCurrentYearCode } = useTransactions(true);

	const checkPermission = useHasPermission();
	const refs = queries.reduce((acc, value) => {
		acc[value.id] = createRef();
		return acc;
	}, {} as { [key: number]: RefObject<HTMLDivElement> });

	const isAdminPermission = useMemo(() => {
		const p = checkPermission(Entity.AccountingPeriodsReviewItem, Action.Create);
		return p[1];
	}, [checkPermission]);

	useEffect(() => {
		setIsAdmin(isAdminPermission);
	}, [isAdminPermission, setIsAdmin]);

	useEffect(() => {
		if (period?.value) {
			setCurrentYearCode(`f${period.value}`);
		}
	}, [period?.value, setCurrentYearCode]);

	useEffect(() => {
		if (Object.keys(refs).length && queries.length && state?.queryId && refs[state?.queryId]?.current) {
			const yOffset = -50; 
			const top = refs[state?.queryId]?.current?.getBoundingClientRect()?.top;
			top && window.scrollTo({top: top + window.pageYOffset + yOffset, behavior: 'smooth'})
			historyWrapper.history.replace(pathname, {periodId: state.periodId})
		}
	}, [refs, queries, pathname, historyWrapper.history, state?.periodId, state?.queryId]);

	useEffect(() => {
		let defaultOption: typeof periodOptions[0] = periodOptions[0] || { value: 0, label: '' }
		if (params?.yearId) {
			defaultOption = periodOptions.find(v => v.value === +(params?.yearId || 0)) || periodOptions[0]
		}
		setPeriod(defaultOption);
	}, [params?.yearId, periodOptions, setPeriod]);

	const transactionList: { [key: string]: BankTransaction } = useMemo(
		() =>
			transactions.reduce(
				(acc, t) => ({
					...acc,
					[`${t.sourceID}`]: t,
				}),
				{}
			),
		[transactions]
	);

	const getSuggestedCategory = useCallback(
		(newAccountId: number) => {
			return accountList?.find((v) => v?.id === newAccountId)?.name || '';
		},
		[accountList]
	);

	const queriesSeparatedByAccount: SeparatedQueries = useMemo(() => {
		if (queries && queries.length && accountList && accountList.length) {
			const queriesData: { [key: number]: AccountingPeriodReviewItem[] } = {};
			const queriesDataNoBA: { [key: number]: AccountingPeriodReviewItem[] } = {};

			queries?.forEach(
				(q) => 
					q?.bankAccountId &&
					(queriesData[q?.bankAccountId]
						? queriesData[q.bankAccountId].push(q)
						: (queriesData[q.bankAccountId] = [q]))
			);
						
			return accountList
				.map((a) => ({ accountName: a.name, accountId: a.id, queries: queriesData[a.id] || [] }))
				.filter((a) => a.queries.length);

		}
		return [];
	}, [accountList, queries]);
	const editQuery = (pm: ReviewItemsPostModel, riid: number, onSuccess: () => void) =>
		onEditQuery(
			{
				...pm,
				bankPaymentId: editQueryModalProps.bankPaymentId,
				bankTransferId: editQueryModalProps.bankTransferId,
			},
			riid,
			onSuccess
		);

	const onCloseModal = () => setEditQueryModalProps((prev) => defaultModalProps);

	useEffect(() => {
		let id: NodeJS.Timeout;
		if (alertMessage) {
			id = setTimeout(() => setAlertMessage(''), 5000);
		}
		return () => clearTimeout(id);
	}, [alertMessage, setAlertMessage]);

	const showPublishConfirmModal = () => {
		showModalDialog(
			'Publish queries?',
			`Are you sure you want to publish the queries?`,
			[
				<Button key="yes" variant="primary" label="Yes" onClick={publishQueries} />,
				<Button key="no" variant="secondary" label="No" onClick={() => {}} />,
			],
			false
		);
	};

	return (
		<>
			<DefaultLayout
				noPadding
				title="Queries"
				loading={false}
				entity={Entity.AccountingPeriodsReviewItem}
			>
				<Toolbar>
					<div
						id={queriesStyles.queriesToolbar}
						className={`d-flex  ${isAdminPermission ? 'justify-content-between' : ''} `}
					>
						<div>
							
							<Button
								entity={Entity.AccountingPeriodsReviewItem}
								action={Action.Create}
								label="Create query"
								buttonType="new"
								onClick={() =>
									historyWrapper.push(`/company/${cid}/queries/create`, {
										periodId: `f${params?.yearId || defaultAccountingPeriodId}`,
									})
								}
							/>
							<Button
								entity={Entity.AccountingPeriodsReviewItem}
								action={Action.Create}
								buttonType="save"
								label="Publish"
								variant="positive"
								disabled={isAllQueriesPublished}
								onClick={showPublishConfirmModal}
							/>
						</div>
						 <Select
							className={queriesStyles.SelectFilter}
							classNamePrefix="SelectFilter"
							onChange={(value) => value && setPeriod(value)}
							options={periodOptions}
							value={period}
						/>
					</div>
				</Toolbar>
				<div id={queriesStyles.queries} className="mt-3 mx-3">				
					{!queriesLoading &&
						queriesSeparatedByAccount.map(({ accountName, queries, accountId }, i) => (
							<Fragment key={accountId}>
								<h2 data-testid="account-label" className={i === 0 ? 'mt-4' : `mt-5`}>
									{accountName}
								</h2>
								{queries.map(
									({
										id,
										response,
										bankPaymentId,
										bankTransferId,
										query = '',
										newAccountId = 0,
										suggestionAcceptedByContactId,
										isPublished,
									}) => (
										<div key={id} ref={refs[id]}>
											<QueryCard
												bankAccountId={accountId}
												transaction={
													transactionList[
														(bankPaymentId ||
															bankTransferId) as keyof typeof transactionList
													] || {}
												}
												query={query || ''}
												response={response || ''}
												newAccountId={newAccountId}
												suggestionAccepted={suggestionAcceptedByContactId ? true : false}
												category={
													accountList.find(
														(a) =>
															a.id ===
															transactionList[
																(bankPaymentId ||
																	bankTransferId) as keyof typeof transactionList
															]?.accountId
													)?.name || ''
												}
												getSuggestedCategory={getSuggestedCategory}
												onEdit={() =>
													setEditQueryModalProps((prev) => ({
														...prev,
														show: true,
														query,
														newAccountId,
														id,
														bankPaymentId,
														bankTransferId,
													}))
												}
												isPublished={isPublished}
												isAdmin={isAdminPermission}
												onResponse={(pm) => onResponse(id, pm)}
												onAccept={() => onAcceptSuggestion(id)}
												transactionLoading={loading}
											/>
										</div>
									)
								)}
							</Fragment>
						))}

					{!queriesLoading && queriesSeparatedByAccount.length === 0 && (
						<NoContentSlate whiteBg type={DatagridType.Queries} termsKey="emptyTerms" />
					)}

					{queriesLoading && (
						<>
							<TenancyNoteEntryPlaceholder />
							<TenancyNoteEntryPlaceholder />
						</>
					)}
				</div>
				{editQueryModalProps.show && (
					<CreateQueryModal
						accounts={accountList}
						initialValue={{
							query: editQueryModalProps.query,
							newAccountId: (editQueryModalProps.newAccountId || '').toString(),
						}}
						hide={onCloseModal}
						create={(pm) => editQuery(pm, editQueryModalProps.id, onCloseModal)}
						title="Edit query"
					/>
				)}
				{modalDialog}
				{alertMessage && (
					<Alert
						show={alertMessage.length > 0}
						style={{ position: 'fixed', bottom: 0, width: '90%', left: '5%' }}
						variant="success"
						dismissible
						onClose={() => setAlertMessage('')}
					>
						<FontAwesomeIcon icon={faCheck} style={{ marginRight: '15px' }} />
						{alertMessage}
					</Alert>
				)}
			</DefaultLayout>
		</>
	);
};

export default Queries;
