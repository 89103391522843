import { useState, useEffect } from 'react';
import { Modal as ReactModal } from 'react-bootstrap';
import _ from 'lodash';
import { TenancyNote, TenancyNotePostModel } from '../../../api/inni/data-contracts';
import { Button } from '../../../elements/Button/Button';
import { Text as EditText }  from '../../../elements/EditRow/EditRow'
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { BrandWrapper } from '../../../components/BrandWrapper/BrandWrapper';

interface NoteModalProps {
  hide: () => void,
  create: (values: TenancyNotePostModel, actions: FormikHelpers<TenancyNotePostModel>) => Promise<void>,
  update: (values: TenancyNotePostModel, actions: FormikHelpers<TenancyNotePostModel>) => Promise<void>,
  data: TenancyNote | null,
}

export const TenancyNoteModal = ({ hide, create, update, data }: NoteModalProps) => {
  const [initialValues, setInitialValues] = useState<TenancyNotePostModel>({title:'', text: '', archived: false});
  const [editing, setEditing] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setInitialValues({title: data.title, text: data.text, archived: data.archived });
      setEditing(true);
    } else {
      setInitialValues({title: '', text: '', archived: false});
      setEditing(false);
    }
  }, [data])

  const validate = (values: TenancyNotePostModel)  => {
    let errors:any = {};
    if (!values.title)
      errors.title = 'Required';

    if (!values.text)
      errors.text = 'Required';

    return errors;
  }

  return (
    <ReactModal size="lg" show={true} onHide={hide} data-cy="tenancyNotesModal">
      <BrandWrapper>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validateOnChange={false}  
          validate={validate}
          onSubmit={editing ? update : create}
        >
          {({ isSubmitting }) => (
            <FormikForm>
              {/* Header */}
              <ReactModal.Header closeButton>
                <ReactModal.Title id="inni-modal-title">
                  Tenancy note
                </ReactModal.Title>
              </ReactModal.Header>

              {/* Body */}
              <ReactModal.Body>
                <EditText<TenancyNotePostModel> name="title" labelTop />
                <EditText<TenancyNotePostModel> name="text" label="Note" type="textarea" labelTop />
              </ReactModal.Body>

              {/* Footer */}
              <ReactModal.Footer>
                <div style={{textAlign: 'left', width: '100%'}}>
                  <Button variant="change" submitButton disabled={isSubmitting}>Save</Button>
                  <Button buttonType="close" onClick={hide}>Cancel</Button>
                </div>
              </ReactModal.Footer>
            </FormikForm>
          )}
        </Formik>
      </BrandWrapper>
    </ReactModal>
  )
}
