import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import Overlay from '../Overlay/Overlay';
import Pill from '../../elements/Pill/Pill';
import styles from './Fullscreen.module.css';
import * as Icons from '../../layouts/Components/icons';
import Icon from '../../elements/Icon/Icon';
import { useContext } from 'react';
import BrandContext from '../../context/BrandContext';
import classNames from 'classnames';

interface FullscreenProps {
    isOpen: boolean
    children: React.ReactNode
    headerTitle: string
    subTitle?: string
    date?: string
    pillText?: string
    pillColor?: string
    pillBackgroundColor?: string
    contentTitle: string
    onClose: () => void
    onBack?: () => void
    breadcrumbs?: {link?: string, text: string}[]
    contentSubtitle?: string
    pillIcon?: React.ReactNode
    brandIcon?: boolean
}

const Fullscreen = ({
        isOpen,
        children,
        headerTitle,
        subTitle,
        date,
        pillText,
        pillColor,
        pillBackgroundColor,
        pillIcon,
        contentTitle,
        onBack,
        onClose,
        breadcrumbs,
        contentSubtitle,
        brandIcon
    } : FullscreenProps) => {
    const brandContext= useContext(BrandContext);
    
    return (
        <Overlay isOpen={isOpen}>
            <div className={styles.container} data-cy="fullscreenOverlay">
                {/* Header */}
                <div className={brandIcon ? `${styles.header} ${styles.simple}` : styles.header}>
                    {/* Col 1 */}
                    <div className={styles.outerColumn}>
                        {brandIcon &&
                            <img className={styles.brandLogo} src={brandContext.brand.logoDarkUrl} alt={brandContext.brand.name} />
                        }
                    </div>
                    {/* Col 2 */}
                    <div className={`${styles.headerContent} ${styles.centerColumn}`}>
                        {pillText && <Pill text={pillText} color={pillColor} backgroundColor={pillBackgroundColor} pillIcon={pillIcon}/> }
                        {date && <p className={styles.date}>{date}</p>}
                        <h3>{headerTitle}</h3>
                        {subTitle && <p className={styles.subTitle}>{subTitle}</p>}
                    </div>
                    {/* Col 3 */}
                    <div className={styles.outerColumn}>
                        <button className={`${styles.cleanBtn} ${styles.headerCloseBtn}`} onClick={onClose}>
                            <span style={{marginRight: '0.5rem'}}>Close</span><Icon size='small' name='close'/>
                        </button>
                    </div>
                </div>

                {/* Body */}
                <div className={classNames(styles.body, {[styles.withCrumbs]: breadcrumbs && breadcrumbs.length > 0})}>
                    {/* Col 1 */}
                    <div className={styles.outerColumn}>
                        {onBack && <>
                            <h2 className={styles.contentTitleHeader}>&nbsp;</h2> {/*Btn height alignment fix*/}
                            <button 
                                data-cy="fullscreenBackbutton"
                                className={classNames(styles.cleanBtn, styles.backBtn, {[styles.withCrumbs]: breadcrumbs && breadcrumbs.length > 0})} 
                                onClick={onBack}
                            >
                                <Icons.BackV8/>
                            </button>
                        </>}
                    </div>
                    {/* Col 2 */}
                    <div className={styles.centerColumn}>
                        {breadcrumbs && breadcrumbs.length > 0 && <div className={classNames(styles.breadcrumbTrailContainer)}>
                            <Breadcrumbs crumbs={breadcrumbs}/>
                        </div>}
                        {contentTitle && <div className={styles.contentTitle}>
                            <h2 className={styles.contentTitleHeader}>{contentTitle}</h2>
                            {contentSubtitle && <p className={styles.contentSubtitleHeader}>{contentSubtitle}</p>}
                        </div>}
                        <div className={styles.content}>
                            {children}
                        </div>
                    </div>
                    {/* Col 3 */}
                    <div className={styles.outerColumn}>
                        {/* Note for future use: top body padding changes with breadcrumbs */}
                    </div>
                </div>
            </div>
        </Overlay>
    )
}

export default Fullscreen