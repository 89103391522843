import styles from './RAGIndicator.module.css'

export type RAGIndicatorColours = 'red' | 'amber' | 'green'

// set colours for rga indicator in map
const colorsMap : Map<RAGIndicatorColours , string> = new Map([
    ['red', 'var(--danger)'],
    ['amber', 'var(--warning)'],
    ['green', 'var(--success)']
])

const RAGIndicator = ({color, size} : {color : RAGIndicatorColours, size? : number}) => {

    return (
        <div
            style={{
                background: `${colorsMap.get(color)}`,
                width: `${size}px`,
                height: `${size}px`,
                display: 'inline-block',
                flexShrink: 0,
            }}
            className={styles.ragIndicator}
        />
    )
    
}

export default RAGIndicator