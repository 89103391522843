import React, { useCallback, useContext, useState } from 'react';
import ContractSelector from '../../Reporting/Components/ContractSelector'
import styles from '../QuickEntry.module.css';
import PaidSelector from './PaidSelector';
import { useEffect } from 'react';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { Mileages } from '../../../api/inni/Mileages';
import CompanyContext from '../../../context/CompanyContext';
import { AdvisoryFuelRateBand, Contract, DatePicker, Mileage, MileagePostModel, MileageType, Person, VatSetting } from '../../../api/inni/data-contracts';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { asHtml5Date, asYearMonthDay, formatDate } from '../../../utils/formatDate';
import { Button } from '../../../elements/Button/Button';
import { ListOption } from '../../../elements/EditRow/EditRow';
import Select from 'react-select';
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList';
import { MileageTypes } from '../../../api/inni/MileageTypes';
import { AdvisoryFuelRateBands } from '../../../api/inni/AdvisoryFuelRateBands';
import { People } from '../../../api/inni/People';
import SearchWithClear from '../../../elements/SearchWithClear/SearchWithClear';
import InfoBanner, { LoadingInfoBanner } from '../../../elements/InfoBanner/InfoBanner';
import { useModalDialog } from '../../../hooks/useModalDialog';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import CreateMileage from './CreateMileage';
import { VatSettings } from '../../../api/inni/VatSettings';
import { Filter, FilterLoading } from '../../../components/AccountsFilter/Filter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { usePersistedState } from '../../../hooks/usePersistedState';
import BrandContext from '../../../context/BrandContext';
import provestorBrand from '../../../components/BrandWrapper/pvBrand';
import { CogOptionsDropdown } from '../../../elements/CogOptionsDropdown/CogOptionsDropdown';

const MileageView = ({personId, person, peopleLoaded, contractsData, showViewMileage} : {personId:number|undefined, person: Person | undefined, peopleLoaded : boolean, contractsData : Contract[], showViewMileage?: (id: number) => void }) => {

    const mileageAPI = useInniAPI(Mileages, [400]);
    const vatSettingsApi = useInniAPI(VatSettings)
    const peopleAPI = useInniAPI(People);

    const [viewOrder, setViewOrder] = usePersistedState('local', 'MileageView_sort', 'asc', ['asc', 'desc']);
    
    const companyContext = useContext(CompanyContext);
    const brandContext = useContext(BrandContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [contracts, setContracts] = useState<ListOption[]>();
    const [selectedContract, setSelectedContract] = useState<number>(-1);
    const [selectPaid, setSelectPaid] = useState<number>();
    const [mileageData, setMileageData] = useState<Mileage[] | undefined>(undefined);
    const [sortedMileageData, setSortedMileageData] = useState<Mileage[]>([]);
    const [editingRow, setEditingRow] = useState<number|undefined>(undefined);
    const [addingNewRow, setAddingnewRow] = useState<boolean>(false);
    const [isClone, setIsClone] = useState<boolean>(false);
    const [cloneId, setCloneId] = useState<number|undefined>(undefined);
    const [isDefaultSaved, setIsDefaultSaved] = useState<boolean>(false);
    const [defaultVehicleEngine, setDefaultVehicleEngine] = useState<AdvisoryFuelRateBand | undefined>(undefined);
    const [defaultVehicleType, setDefaultVehicleType] = useState<MileageType | undefined>();

    const [expandedRow, setExpandedRow] = useState<number | undefined>(undefined)

    const [mileageTypes, mileageTypesLoaded] = useFetchEntityList<MileageType, MileageTypes>(MileageTypes);
    const [mileageTypesOption, setMileageTypesOption] = useState<ListOption[]>([]);

    const [advisoryFuelRateBands, advisoryFuelRateBandsLoaded] = useFetchEntityList<AdvisoryFuelRateBand, AdvisoryFuelRateBands>(AdvisoryFuelRateBands);
    const [advisoryFuelRateBandOption, setAdvisoryFuelRateBandOption] = useState<ListOption[]>([]);

    const [vatSetting, setVatSetting] = useState<VatSetting | undefined>(undefined)
    const [vatSettingLoaded,  setVatSettingLoaded] = useState(false)

    const [search, setSearch] = useState<string>('')

    const [showModalDialog, modalDialog] = useModalDialog();

    const [doPast, setDoPast] = useState(false)

    //===Filter stuff===//
    const [currentYearCode, setCurrentYearCode] = useState<string|undefined>(undefined);
    const [dateSelection, setDateSelection] = useState<[Date|undefined, Date|undefined]>([undefined, undefined]);
    const [newMonth, setNewMonth] = useState<number|undefined|null>(undefined);
    const [showPaidSelector, setShowPaidSelector] = useState(false)

    const v8Styling = companyContext.company?.useV8UI

    const filterMonthChanged =useCallback((startDate: Date, endDate: Date) => {
        setDateSelection([startDate, endDate]);
    },[]);

    const getFilter = useCallback((yearCode: string|undefined) :  Promise<DatePicker> => {                
        const p = new Promise<DatePicker>((resolve, reject) => {
            mileageAPI?.filter(companyContext.cid,  { employeeId: personId, yearCode: yearCode})
                .then(results => {
                    if (results.data.datePicker) {
                        resolve(results.data.datePicker)
                    } else reject();                    
                }).catch(error => reject(error))
        })
        return p;
    }, [companyContext.cid, mileageAPI, personId])

    useEffect(()=>{
       setCurrentYearCode(undefined)
    },[personId,mileageData])

    useEffect(()=>{
        if(person){
            setSelectPaid(person.repayMileageDirectly ? -1 : 0)
            setShowPaidSelector(!person.repayMileageDirectly)
        }
     },[personId])

	//Filter the data based on user input in the searchbar and select boxes
    useEffect(() => {
        if (vatSettingsApi && !vatSettingLoaded) {
            vatSettingsApi.getAtDate(companyContext.cid, { date: asYearMonthDay(new Date()) })
            .then(res => {
                setVatSetting(res.data)
                setVatSettingLoaded(true)
            })
        }
    }, [companyContext.cid, vatSettingLoaded, vatSettingsApi])

    useEffect(() => {
        if (mileageAPI && personId && mileageTypesLoaded && advisoryFuelRateBandsLoaded && advisoryFuelRateBands && mileageTypes) {
            mileageAPI.getDefaults(companyContext.cid, Number(personId)).then(res => {
                setDefaultVehicleEngine(advisoryFuelRateBands.find(x => x.id === res.data.advisoryFuelRateBand));
                setDefaultVehicleType(mileageTypes.find(x => x.id === res.data.mileageTypeId || 10))
            })
            setExpandedRow(undefined)
        }
    }, [personId, mileageTypesLoaded, advisoryFuelRateBandsLoaded, mileageAPI, companyContext.cid, advisoryFuelRateBands, mileageTypes])

    useEffect(() => {
        if (mileageAPI && !isLoaded) {
            mileageAPI.index(companyContext.cid)
            .then(res => {
                setMileageData(res.data);
                setIsLoaded(true);
            })
        }
    }, [mileageAPI, isLoaded, companyContext.cid])

    // Create Select list
    useEffect(() => {
        if (contractsData) {
            let contractSelectList:Array<ListOption> = [];

            if(!v8Styling){
                contractSelectList.push({label: 'None', value: '-1'})
            }

            contractsData.filter(x => x.canBookMileage).forEach(contract => {
                contractSelectList.push({
                    value: contract.id.toString(), 
                    label: contract.name || '',
                })
            })
            setContracts(contractSelectList)
        }

    }, [companyContext.cid, contractsData])
    
    // Create Select list
    useEffect(() => {
        let mileageTypeSelectList:Array<ListOption> = [];
        mileageTypes.forEach(x => {
            mileageTypeSelectList.push({
                value: x.id.toString(),
                label: x.name || '',
            })
        })
        setMileageTypesOption(mileageTypeSelectList)
    }, [mileageTypes])

    useEffect(() => {
        // Filters the datalist based on the month selected by the user.
        const filterByMonth = (data: Mileage) => {
            if (data.date) {
                    const viewDate = new Date(data.date);
                    return dateSelection[0] && dateSelection[1] && viewDate >= dateSelection[0] && viewDate <= dateSelection[1];
            }
        };

        if (mileageData) {
            const sortMultipler = (viewOrder === 'asc') ? 1 : -1;
            let tempMd = mileageData?.filter((x) => filterByMonth(x)).filter(x => (
                    ( search.length === 0 || (
                        new RegExp('.*' + search + '.*').test(x.description || '') ||
                        new RegExp('.*' + search.toLowerCase() + '.*').test(x.contractName?.toLowerCase() || '') ||
                        new RegExp('.*' + search.toLowerCase() + '.*').test((formatDate(x.date) || '')) ||
                        new RegExp('.*' + search.toLowerCase() + '.*').test(x.from?.toLowerCase() || '') ||
                        new RegExp('.*' + search.toLowerCase() + '.*').test(x.to?.toLowerCase() || '') ||
                        new RegExp('.*' + search.toLowerCase() + '.*').test(x.billedAmount.toString())
                    ))
                )
                && (Number(x.isPaid) === selectPaid || selectPaid === -1)
                && (Number(x.contractId) === selectedContract || selectedContract === -1)
                && ((personId || '') === x.personId)
            ).sort((a,b) => {
                if (!a.date || !b.date) return 1;
                if (a.date > b.date) return (1 * sortMultipler);
                if (a.date < b.date) return (-1 * sortMultipler);
                return 0;
            })

            setSortedMileageData(tempMd)

        } else setSortedMileageData([])

    },[mileageData, search, selectPaid, selectedContract, personId, dateSelection, viewOrder]);
    
    // Create Select list
    useEffect(() => {
        let advisoryFuelSelectList:Array<ListOption> = [];

        if(!v8Styling){
            advisoryFuelSelectList.push({label: 'None', value: ''})
        }
        
        advisoryFuelRateBands.forEach(x => {
            advisoryFuelSelectList.push({
                value: x.id || '', 
                label: x.name || '',
            })
        })
        setAdvisoryFuelRateBandOption(advisoryFuelSelectList)
    }, [advisoryFuelRateBands])

    const getOrCreatePostModel = () => {
        let postModel: MileagePostModel = {
            date: asHtml5Date(asYearMonthDay(new Date())) || '',
            from: '',
            to: '',
            description: '',
            mileageTypeId: isDefaultSaved && Number(defaultVehicleType) ? Number(defaultVehicleType?.id) : person?.defaultMileageTypeId || 0,
            distance: 0,
            isBillable: false,
            personId: 0,
            billedRate: 0,
            advisoryFuelRateBand: isDefaultSaved && String(defaultVehicleEngine) ? String(defaultVehicleEngine) : person?.defaultAdvisoryFuelrateBand || '',
            contractId: undefined,
        }

        postModel.mileageTypeId = defaultVehicleType ? defaultVehicleType?.id || 0 : person ? person?.defaultMileageTypeId || 0 : 0;
        postModel.advisoryFuelRateBand = (defaultVehicleType) ? (defaultVehicleEngine?.id || '') : (person ? person?.defaultAdvisoryFuelrateBand || '' : '');

        if (addingNewRow && !isClone)
            return postModel

        let tempMd = sortedMileageData
        let index = tempMd.findIndex(x => x.id === (editingRow || cloneId))
        if ((editingRow !== undefined || cloneId !== undefined) && tempMd && index !== -1) {
            postModel.date = tempMd[index].date || ''
            postModel.from = tempMd[index].from || ''
            postModel.to = tempMd[index].to || ''
            postModel.description = tempMd[index].description || ''
            if (tempMd[index].mileageTypeId)
                postModel.mileageTypeId = tempMd[index].mileageTypeId || 0
            postModel.distance = tempMd[index].distance
            postModel.isBillable = tempMd[index].isBillable
            postModel.personId = tempMd[index].personId
            postModel.billedRate = tempMd[index].billedRate
            if (tempMd[index].advisoryFuelRateBand)
                postModel.advisoryFuelRateBand = tempMd[index].advisoryFuelRateBand
            postModel.contractId = tempMd[index].contractId
        }
        return postModel
    }

    const switchContract = (num : number) => {
        if (num !== selectedContract) {
            setSelectedContract(num);
        }       
    }

    const switchPaid = (num : number) => {
        if (num !== selectPaid) {
            setSelectPaid(num);
        }       
    } 

    const defaultVehicle = () => {
        if (peopleAPI && defaultVehicleEngine) {
            peopleAPI.update(companyContext.cid, Number(personId || '0'), { defaultAdvisoryFuelrateBand: defaultVehicleEngine?.id, defaultMileageTypeId: 10, doPrevious: doPast })
            setIsDefaultSaved(true);
        }
    }

    // Delete stuff
    const onDelete = (number : number) => {
        if (mileageAPI) {
            mileageAPI.delete(companyContext.cid, number).then(res => {
                setMileageData(mileageData?.filter(x => x.id !== number))
                setExpandedRow(undefined)
            })
        }
    }

    const showDeleteDialog = (row: number) => {
        showModalDialog(
            'Delete mileage entry?',
            'Are you sure you want to delete this mileage entry?',
            [
                <Button variant="danger" label="Yes" onClick={() => onDelete(row)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const handleOnClick = (mileageId: number) =>{
        
        if(v8Styling && showViewMileage){
            showViewMileage(mileageId)
        }
        else{
            setAddingnewRow(false); 
            setEditingRow(mileageId); 
        }
        
    }

    const CloneMileage = (mileageId: number) => {
        setEditingRow(undefined)
        setAddingnewRow(true); 
        setIsClone(true); 
        setCloneId(mileageId)
    }

    const handleCancel = () =>{
        setAddingnewRow(false); 
        setIsClone(false); 
        setCloneId(undefined)
    }

    return (
        <>
            {peopleLoaded && <>
                {!(isDefaultSaved || person?.defaultAdvisoryFuelrateBand) && vatSetting?.isStandard &&
                    <InfoBanner 
                        title={`Set ${person?.name}'s default vehicle`}
                        type="info"
                        body={<>
                            <div>       
                                Please set the default vehicle engine and fuel type for {person?.name}. This will be used to claim VAT on their recorded mileage. If you're unsure of their vehicle's engine size (CC), click&nbsp;
                                <a target="_new" href="https://vehicleenquiry.service.gov.uk/" style={{textDecoration: 'underline'}}>here</a>
                                &nbsp;to find out. 
                            </div>
                            
                            <div style={{marginBottom: '20px'}}>
                                <div><label style={{marginTop: '20px'}}>Vehicle engine and fuel</label></div>
                                <div style={{width: '25%'}} data-cy="bandDD">
                                    <Select
                                        onChange={(selectedOption: ListOption | null) => {
                                            if (selectedOption) {
                                                setDefaultVehicleEngine(advisoryFuelRateBands?.find(x => x.id === selectedOption.value) || undefined);
                                            }                              
                                        }}
                                        options={advisoryFuelRateBandOption}
                                        value={advisoryFuelRateBandOption.find(x => x.value === defaultVehicleEngine?.id) || {label: 'None', value: ""}}
                                    />
                                </div><br />
                                <label><input checked={doPast} type="checkbox" onChange={() => setDoPast(!doPast)} /> Apply this to all mileage since {formatDate(vatSetting.effectiveDate)}</label>
                            </div>
                            <div data-cy="bandDDSaveBtnContainer">
                                <Button buttonType='save' onClick={defaultVehicle}/>
                            </div>
                        </>}                                             
                    />
                }
                {(isDefaultSaved || person?.defaultAdvisoryFuelrateBand) && !vatSetting?.isExempt && <>
                    <InfoBanner 
                        title={`${person?.name}'s default vehicle`} 
                        body={`${person?.name}'s current default vehicle engine and fuel type is ${defaultVehicleEngine?.name}. To change this default, update the most recent mileage entry with the new engine and fuel type.`} 
                        type='info' />
                </>}
            </>}
            { !peopleLoaded &&
                <LoadingInfoBanner type="info" />
            }

            { person?.repayMileageDirectly && (
                <InfoBanner
                    type="info"
                    body="When you add a new journey, we calculate the tax-efficient amount and add it to your expense account. Your company can reimburse you at any time you choose."
                    noTopMargin
                />
            )}

            {isLoaded && 
            <div className={styles.controlHolder}>
                <ContractSelector 
                    filter={(i : Contract[]) => [...i.filter(x => x.canBookMileage)]} 
                    contractCallback={switchContract} 
                    loadedCb={() => {}} fltLoaded={true}
                    addContract={brandContext.brand !== provestorBrand}
                /> 

                {showPaidSelector &&
                    <PaidSelector paidCallback={switchPaid} key={personId}/>
                }
                
                <div style={{maxWidth: '200px'}}><SearchWithClear search={search} setSearch={setSearch} /></div>
                { v8Styling && 
                    <div className={styles.addbutton}>
                        <Button buttonType="new" onClick={() => {setExpandedRow(undefined); setEditingRow(undefined); setAddingnewRow(true)}}/>
                    </div>
                }
                
            </div>}

            <div style={{marginBottom: '300px'}}>
                {isLoaded && 
                    <Filter 
                        newMonth={newMonth} 
                        year={currentYearCode} 
                        searching={search !== undefined && search.length > 0} 
                        getFilter={getFilter} 
                        yearChanged={setCurrentYearCode} 
                        monthChanged={filterMonthChanged} 
                        clearNewMonth={() => setNewMonth(null)} 
                        watchId={personId}
                    />
                }   
                {!isLoaded && 
                    <FilterLoading />
                }

                <DataGrid.Table noHover={sortedMileageData.length === 0}>
                    <thead>
                        <tr>
                            <th
                                style={{width: '10%', cursor: 'pointer'}}
                                onClick={() => { viewOrder === 'asc' ? setViewOrder('desc') : setViewOrder('asc'); }}
                            >
                                Date <FontAwesomeIcon className="ml-1" icon={viewOrder === 'asc' ? faSortDown : faSortUp} />
                            </th>
                            <th style={{width: '25%'}}>Contract</th>
                            <th style={{width: '15%'}}>From</th>
                            <th style={{width: '15%'}}>To</th>
                            <th style={{width: '20%'}}>Description</th>
                            <th style={{width: '15%'}}>Distance</th>
                            <th style={{width: '10%'}}></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoaded && 
                            <>
                                <DataGrid.LoadingRow cols={7}  />
                                <DataGrid.LoadingRow cols={7}  />
                                <DataGrid.LoadingRow cols={7}  />
                                <DataGrid.LoadingRow cols={7}  />
                                <DataGrid.LoadingRow cols={7}  />
                            </>
                        }
                        {isLoaded && mileageData && 
                            <>
                                { sortedMileageData.length === 0 &&
                                    <tr>
                                        <td colSpan={6} className="p-0">
                                            <NoContentSlate 
                                                type={DatagridType.Mileage} 
                                                termsKey={mileageData.some(x => x.personId  === personId) 
                                                            ? "noResultsTerms"
                                                            : "emptyTerms" 
                                                        } 
                                            />
                                        </td>
                                    </tr>
                                }
                                {sortedMileageData.map((mileage, rowIndex) => 
                                    <React.Fragment key={mileage.id}>
                                        { editingRow === mileage.id && !addingNewRow && vatSetting && 

                                            <CreateMileage
                                                showCreateModal={editingRow !== undefined}
                                                mileageData={mileageData} 
                                                setMileageData={setMileageData} 
                                                personId={personId || 0} 
                                                onCancel={() => {setAddingnewRow(false); setEditingRow(undefined)}} 
                                                editingData={getOrCreatePostModel()} 
                                                contracts={contracts || []} 
                                                mileageTypesOption={mileageTypesOption || []} 
                                                advisoryFuelRateBandOption={advisoryFuelRateBandOption || []}
                                                editingId={editingRow}
                                                vatSetting={vatSetting}
                                                onDelete={showDeleteDialog}
                                            />
                                        }

                                        { mileage.isReadonly && expandedRow === mileage.id && 
                                        <tr>
                                            <td style={{background: 'white', borderBottom: '0px', borderTop: '3px solid var(--light-border-color)'}} colSpan={6}>
                                                <InfoBanner type="info" thin body={<><b>You cannot edit this entry: </b>{mileage.readonlyReason}</>} />
                                            </td>
                                        </tr>}
                                        <tr data-cy="mileageRow" onClick={() => handleOnClick(mileage.id)} key={mileage.id} >
                                            <td style={{background: expandedRow === mileage.id ? 'white' : '', border: expandedRow === mileage.id ? '0px' : ''}}>
                                                {expandedRow === mileage.id && <><label>Date</label><br /></>}
                                                {formatDate(mileage.date)}
                                            </td>
                                            <td style={{background: expandedRow === mileage.id ? 'white' : '', border: expandedRow === mileage.id ? '0px' : ''}}>
                                                {expandedRow === mileage.id && <><label>Contract</label><br /></>}
                                                {mileage.contractName}
                                            </td>
                                            <td style={{background: expandedRow === mileage.id ? 'white' : '', border: expandedRow === mileage.id ? '0px' : ''}}>
                                            {expandedRow === mileage.id && <><label>Journey from</label><br /></>}
                                                {mileage.from}
                                            </td>
                                            <td style={{background: expandedRow === mileage.id ? 'white' : '', border: expandedRow === mileage.id ? '0px' : ''}}>
                                                {expandedRow === mileage.id && <><label>Journey to</label><br /></>}
                                                {mileage.to}
                                            </td>
                                            <td style={{background: expandedRow === mileage.id ? 'white' : '', border: expandedRow === mileage.id ? '0px' : ''}}>
                                                {expandedRow === mileage.id && <><label>Description</label><br /></>}
                                                {mileage.description}
                                            </td>
                                            <td style={{background: expandedRow === mileage.id ? 'white' : '', border: expandedRow === mileage.id ? '0px' : ''}}>
                                                {expandedRow === mileage.id && <><label>Distance</label><br /></>}
                                                {mileage.distance}
                                            </td>
                                            <td style={{background: expandedRow === mileage.id ? 'white' : '', border: expandedRow === mileage.id ? '0px' : ''}} onClick={(e) => e.stopPropagation()} >
                                                <CogOptionsDropdown
                                                    copy={ () => CloneMileage(mileage.id)}
                                                    hamburger={v8Styling}
                                                />
                                            </td>
                                            
                                            
                                        </tr>
                                    </React.Fragment>
                                )}
                                {!v8Styling &&
                                    <tr>
                                        <td colSpan={7}>
                                            <Button buttonType="new" onClick={() => {setExpandedRow(undefined); setEditingRow(undefined); setAddingnewRow(true)}}/>
                                        </td>
                                    </tr>
                                }

                                
                                {/* Possibly move to top so don't have to scroll all the way to the bottom to create a new entry???? */}
                                {/* { addingNewRow && <>
                                    {renderCreateEditRow()}
                                </>} */}
                                {vatSetting && 
                                    <CreateMileage 
                                        showCreateModal={addingNewRow}
                                        mileageData={mileageData} 
                                        setMileageData={setMileageData} 
                                        personId={personId || 0} 
                                        onCancel={handleCancel} 
                                        editingData={getOrCreatePostModel()} 
                                        contracts={contracts || []} 
                                        mileageTypesOption={mileageTypesOption || []} 
                                        advisoryFuelRateBandOption={advisoryFuelRateBandOption || []}
                                        vatSetting={vatSetting}
                                        onDelete={showDeleteDialog}
                                        isClone={isClone}
                                    />}
                                    
                            </>
                        }
                    </tbody>
                </DataGrid.Table>
            </div>
            { modalDialog }
        </>
    )
}
export default MileageView
