/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Category, CategoryDescription, LSAccount } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Categories<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Categories
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/Categories
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Category[], any>({
      path: `/api/v2/companies/${companyId}/Categories`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetDescriptions
   * @request GET:/api/v2/companies/{companyId}/Categories/GetDescriptions
   * @secure
   */
  getDescriptions = (companyId: number, params: RequestParams = {}) =>
    this.request<CategoryDescription[], any>({
      path: `/api/v2/companies/${companyId}/Categories/GetDescriptions`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetCategoryByTag
   * @summary Returns a category for a search term if one exists, otherwise returns null
   * @request GET:/api/v2/companies/{companyId}/Categories/GetCategoryByTag
   * @secure
   */
  getCategoryByTag = (companyId: number, query: { tag: string }, params: RequestParams = {}) =>
    this.request<string[], any>({
      path: `/api/v2/companies/${companyId}/Categories/GetCategoryByTag`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetLsCategories
   * @request GET:/api/v2/companies/{companyId}/Categories/lsCategories
   * @secure
   */
  getLsCategories = (companyId: number, params: RequestParams = {}) =>
    this.request<Category[], any>({
      path: `/api/v2/companies/${companyId}/Categories/lsCategories`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Categories
   * @name GetLsAccounts
   * @request GET:/api/v2/companies/{companyId}/Categories/lsaccounts
   * @secure
   */
  getLsAccounts = (companyId: number, params: RequestParams = {}) =>
    this.request<LSAccount[], any>({
      path: `/api/v2/companies/${companyId}/Categories/lsaccounts`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
