import { useContext } from 'react';
import {
	storyblokEditable,
	StoryblokComponent,
	SbBlokData,
	StoryData,
} from '@storyblok/react';
import styles from './navigation.module.css';
import { InitialiseDateModel } from '../../../api/inni/data-contracts';
import OnboardingContext from '../../../context/OnboardingContext';
type Props = {
	blok: SbBlokData;
	initialData: InitialiseDateModel;
	pageStory: TStoryData
};
type TStoryData = StoryData<{
	[index: string]: SbBlokData;
}>;

const NavBar = ({ blok, initialData,  pageStory }: Props) => {
	const {selectedUid, onNavItemSelect, setCurrentPageCompletionStatus} = useContext(OnboardingContext)
	
	return (
		<div id="containerWrapper" className="h-100">
			<div id="container" className="noPadding h-100">
				<div
					{...storyblokEditable(blok as SbBlokData)}
					className={styles.navBarOuter}
				>
					<div className={styles.navBar}>
						{blok.items
							? (blok.items as Array<SbBlokData>).map((blok) => (
									<StoryblokComponent
										blok={blok}
										key={blok._uid}
										onNavItemSelect={onNavItemSelect}
										selectedUid={selectedUid}
									/>
							  ))
							: null}
					</div>
					<div className={styles.navBarPageOuter}>
						{pageStory && (pageStory as TStoryData).content && (
							<StoryblokComponent
								blok={(pageStory as TStoryData).content as SbBlokData}
								initialData={initialData}
								isCompletedPage={setCurrentPageCompletionStatus()}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default NavBar;
