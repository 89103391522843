import React, { useEffect, useState } from 'react';
import configHelper, { Brand as BrandConfig }  from '../../utils/configHelper';
import BrandContext, { Brand } from '../../context/BrandContext';
import {Helmet} from "react-helmet";
 
type BrandWrapperProps = {
   children: React.ReactNode
}
/**
 * tl;dr; useContext(BrandContext) has everything you need.
 * 
 * Dynamically loads a brand's assets (css and logo) and config (name) and
 * stuffs them into a brand context for later use.
 * 
 * Why do we do all of this? To keep the payload low - so iA users don't have 
 * to load PV stylesheets. The import() statements below are dynamic and use 
 * webpack's code splitting features.
 * 
 * You don't really need to know any of this - just access everything via the 
 * BrandContext
 */
export const BrandWrapper = ({children}:BrandWrapperProps) => {
    const [brand, setBrand] = useState<Brand|undefined>(undefined);
    const [overridenBrand, setOverriddenBrand] = useState<BrandConfig|undefined>(undefined);

    useEffect(() => {
        if ((overridenBrand === BrandConfig.Provestor) || configHelper.brand === BrandConfig.Provestor) {
            import('./pvBrand').then( b => 
                setBrand(b.default)
            )    
        } else {
            import('./iaBrand').then( b =>
                setBrand(b.default)
            )    
        }
    }, [overridenBrand])
      
 
    if (brand) {
        return (
            <BrandContext.Provider value={{brand: brand, overrideBrand: setOverriddenBrand}}>
                <Helmet>
                    <title>{brand.name}</title>
                    <link rel="icon" href={brand.favIconUrl}/>
                </Helmet>
                {/* This is where our CSS variables are attached: */}
                <div id='brandWrapper' className={brand.cssVariablesClassName}>
                    {children}
                </div>
            </BrandContext.Provider>
        )
    } else {
        return <></>;   
    }

 
}
