/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Datagrid,
  Mileage,
  MileageBillable,
  MileageDefaults,
  MileagePostModel,
  SelectlistModel,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Mileages<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Mileages
   * @name SelectList
   * @summary This is the regular journeys
   * @request GET:/api/v2/companies/{companyId}/mileages/selectlist
   * @secure
   */
  selectList = (companyId: number, params: RequestParams = {}) =>
    this.request<SelectlistModel[], any>({
      path: `/api/v2/companies/${companyId}/mileages/selectlist`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name New
   * @request GET:/api/v2/companies/{companyId}/mileages/new
   * @secure
   */
  new = (companyId: number, params: RequestParams = {}) =>
    this.request<Mileage, any>({
      path: `/api/v2/companies/${companyId}/mileages/new`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/mileages
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Mileage[], any>({
      path: `/api/v2/companies/${companyId}/mileages`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/mileages
   * @secure
   */
  create = (companyId: number, mileage: MileagePostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/mileages`,
      method: "POST",
      body: mileage,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name NewSuggestions
   * @request GET:/api/v2/companies/{companyId}/mileages/newsuggestions
   * @secure
   */
  newSuggestions = (companyId: number, params: RequestParams = {}) =>
    this.request<Mileage[], any>({
      path: `/api/v2/companies/${companyId}/mileages/newsuggestions`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/mileages/ValidateCreate
   * @secure
   */
  validateCreate = (companyId: number, mileage: MileagePostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/mileages/ValidateCreate`,
      method: "POST",
      body: mileage,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name Get
   * @request GET:/api/v2/companies/{companyId}/mileages/{id}
   * @secure
   */
  get = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<Mileage, any>({
      path: `/api/v2/companies/${companyId}/mileages/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name Put
   * @request PUT:/api/v2/companies/{companyId}/mileages/{id}
   * @secure
   */
  put = (companyId: number, id: number, mileage: MileagePostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/mileages/${id}`,
      method: "PUT",
      body: mileage,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/mileages/{id}
   * @secure
   */
  delete = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/mileages/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name GetDefaults
   * @request GET:/api/v2/companies/{companyId}/mileages/GetDefaults/{personId}
   * @secure
   */
  getDefaults = (companyId: number, personId: number, params: RequestParams = {}) =>
    this.request<MileageDefaults, any>({
      path: `/api/v2/companies/${companyId}/mileages/GetDefaults/${personId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name GetBillableMileages
   * @request GET:/api/v2/companies/{companyId}/mileages/BillableMileages
   * @secure
   */
  getBillableMileages = (companyId: number, query?: { contractId?: number }, params: RequestParams = {}) =>
    this.request<MileageBillable[], any>({
      path: `/api/v2/companies/${companyId}/mileages/BillableMileages`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Mileages
   * @name Filter
   * @summary Returns Mileage datagrid based on the yearcode
   * @request GET:/api/v2/companies/{companyId}/mileages/Filter
   * @secure
   */
  filter = (companyId: number, query?: { employeeId?: number; yearCode?: string }, params: RequestParams = {}) =>
    this.request<Datagrid, any>({
      path: `/api/v2/companies/${companyId}/mileages/Filter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
