import { Employment, PersonAsDetailed } from '../../../../api/inni/data-contracts'
import { ListOption } from '../../../../elements/EditRow/EditRow'
import { LoadingFullPage } from '../../../../elements/LoadingFullPage/LoadingFullPage'
import { TabEditProps } from '../../People'
import EmploymentView from './EmploymentView'
import EmploymentTabEdit from './EmploymentTabEdit'
import { Button } from '../../../../elements/Button/Button'
import { Entity, Action } from '../../../../utils/EntityAction'

const workingBandSL: ListOption[] = [
    { value: "A", label: "Up to 15.99 hrs" },
    { value: "B", label: "16-29.99 hrs" },
    { value: "C", label: "30 hrs or more" }
]

const studentLoanPlanTypeSL: ListOption[] = [
    { value: "", label: "None" },
    { value: "01", label: "Type 1" },
    { value: "02", label: "Type 2" }
]

const EmploymentTab = ({ employment, person, reloadPeople, editing, setEditing,setDisabled }:
    { employment: Employment | undefined, person: PersonAsDetailed | undefined, reloadPeople: () => void,setDisabled:(i:boolean)=>void} & TabEditProps) => {

    return (
        <>
            { (person && employment)
                ? (
                    <>
                        { !editing
                            ? (
                                <>
                                    <EmploymentView employment={employment} person={person} workingBandSL={workingBandSL} />
                                    <Button
                                        buttonType="edit"
                                        entity={Entity.PersonV7}
                                        action={Action.Edit}
                                        onClick={() => setEditing(true)}
                                        disabled={editing}
                                        marginTop
                                    />
                                </>
                            )
                            : (
                                <EmploymentTabEdit
                                    setDisabled={setDisabled}
                                    employment={employment}
                                    person={person}
                                    workingBandSL={workingBandSL}
                                    reloadPeople={reloadPeople}
                                    setEditing={setEditing}
                                    studentLoanPlanTypeSL={studentLoanPlanTypeSL}
                                />
                            )
                        }
                    </>
                )
                : <LoadingFullPage fullScreen={false} entityName="Employment details" />
            }
        </>
    )
}

export default EmploymentTab