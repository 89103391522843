import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronCircleRight,
  faChevronCircleDown,
  faPhoneAlt,
  faDesktop,
  faDownload
} from '@fortawesome/pro-regular-svg-icons';
import { formatDateTime } from '../../utils/formatDate';
import styles from './ConsultationReport.module.css';
import BrandContext from '../../context/BrandContext';
import provestorBrand from '../../components/BrandWrapper/pvBrand';
import { SimpleTerm, useSimpleTerms } from '../../hooks/terms/useSimpleTerms';
import ReactMarkdown from 'react-markdown';
// Components
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
// API
import { Companies } from '../../api/inni/Companies';
import { ContactConsultations } from '../../api/inni/ContactConsultations';
import { FactFindingForm } from '../../api/inni/data-contracts';
import { useInniAPI } from '../../hooks/useInniAPI';
import { CompanySummary } from '../../api/inni/data-contracts';
import classNames from 'classnames';


interface RouteParams {
  contactId: string,
  rid: string
}

const ConsultationReport = () => {
  const [expandedWhatElse, setExpandedWhatElse] = useState(false);
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState<FactFindingForm>();

  const companiesApi = useInniAPI(Companies);
  const [companies, setCompanies] = useState<Array<CompanySummary>>([]);
  const [companiesLoaded, setCompaniesLoaded] = useState(false);
  const brandContext = useContext(BrandContext);
  const simpleTerms = useSimpleTerms();

  const contactId = parseInt(useParams<RouteParams>().contactId);
  const reportId = parseInt(useParams<RouteParams>().rid);
  const consultationApi = useInniAPI(ContactConsultations);

  // Get report
  useEffect(() => {
    if (consultationApi && reportId) {
      consultationApi
        .previousFactFindingForm(contactId, {id: reportId})
        .then((response) => {
          if (response.status === 200) {
            setReport(response.data);
            setLoading(false);
          } else {
            console.warn(`Unexpected response: '${response.status}''`);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [consultationApi, reportId, contactId]);

  // Get companies list for cta
  useEffect(() => {
    if (!companiesLoaded && companiesApi) {
        companiesApi.index().then(response => {
          setCompaniesLoaded(true);
          setCompanies(response.data);
        })
    }
  }, [companiesApi, companiesLoaded])

  return (
      <DefaultLayout title="Consultation" backIcon={true} greyBackground>
        <Container id={styles.consultationRecord}>
          <Card className="shadow-sm">
            
            <Card.Header className={styles.header}>
              { loading
                ? (
                  <>
                    <span className={styles.circle}></span>
                    <LoadingPlaceholder dark width="60%" />
                    <LoadingPlaceholder dark width="60%" />
                    <LoadingPlaceholder dark width="60%" />
                    <LoadingPlaceholder dark width="60%" />
                  </>
                )
                : (
                  <>
                    { report?.staffImg && (
                      <img
                        src={report?.staffImg}
                        className={styles.circle}
                        alt="Consultant profile picture"
                      />
                    )}
                    <h4>Consultation with {report?.consultantName}</h4>
                      { report?.consultationDate && <h4>{formatDateTime(report?.consultationDate)}</h4> }
                    <h5 className="mt-4 mb-2"><b>{report?.title}</b></h5>
                  </>
                )
              }
            </Card.Header>

            <Card.Body className="pt-5">
              <h5 className={styles.reportHeading}>Reason for consultation:</h5>
              <div className={styles.reportText}>
                { loading 
                  ? (
                    <>
                      <LoadingPlaceholder width="90%" />
                      <LoadingPlaceholder width="80%" />
                      <LoadingPlaceholder width="65%" />
                    </>
                  )
                  : report?.customerNotes
                }
              </div>

              <hr />

              { (report?.propertiesOwned || report?.howOwned || report?.longTermGoal) && (
                <>
                  <span onClick={() => setExpandedWhatElse(!expandedWhatElse)}>
                    <FontAwesomeIcon icon={expandedWhatElse ? faChevronCircleDown : faChevronCircleRight} className={styles.expanderIcon} />
                    <h5 className={styles.expandingHeading}>What else you told us...</h5>
                    { expandedWhatElse && (
                      <div className={styles.whatElseBox}>
                        {/* How many properties */}
                        { report.propertiesOwned && (
                          <>
                            <h5 className={styles.reportHeading}><u>How many properties do you currently own?</u></h5>
                            <div className={styles.reportText}>
                              {report?.propertiesOwned}
                            </div>
                          </>
                        )}

                        {/* How owned */}
                        { report.howOwned && (
                          <>
                            <h5 className={styles.reportHeading}><u>How are these properties legally owned?</u></h5>
                            <div className={styles.reportText}>
                              {report?.howOwned}
                            </div>
                          </>
                        )}

                        {/* Plans */}
                        { report.longTermGoal && (
                          <>
                            <h5 className={styles.reportHeading}><u>What are your property buying/selling plans for the next 4-5 years?</u></h5>
                            <div className={styles.reportText}>
                              {report?.longTermGoal}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </span>

                  <hr />
                </>
              )}

              <h5 className={styles.reportHeading}><u>Consultation summary:</u></h5>
              <div className={classNames(styles.recommendation, styles.multiLineText)}>
                { loading 
                  ? (
                    <>
                      <LoadingPlaceholder dark width="90%" />
                      <LoadingPlaceholder dark width="75%" />
                      <LoadingPlaceholder dark width="40%" />
                      <LoadingPlaceholder dark width="65%" />
                      <LoadingPlaceholder dark width="75%" />
                    </>
                  )
                  : <ReactMarkdown>{ (report?.accountantFeedback || '').replace(/\r\n/g, '  \r\n') }</ReactMarkdown>
                }
              </div>

              <hr />
              
              {report?.callRecordingUrl && <div>
                <h5 className={styles.reportHeading}><u>Replay your consultation</u></h5>
                <div className={styles.reportText}>
                  <audio controls className="mt-1">
                    <source src={report?.callRecordingUrl} type="audio/mpeg" />
                    Your browser is unable to stream this audio, but you can still download it
                  </audio>
                  <div className="mt-3">
                    <a href={report?.callRecordingDownloadUrl}>
                      <FontAwesomeIcon icon={faDownload} size='lg' style={{color: '#000'}}/> Download
                    </a>
                  </div>
                </div>
              </div>}

              {report?.callRecordingUrl && report?.usefulGuides && <hr/>}

              {report?.usefulGuides && <><h5 className={styles.reportHeading}><u>Useful guides:</u></h5>
              <div className={classNames(styles.recommendation, styles.multiLineText)}>
                <ReactMarkdown>{ (report?.usefulGuides || '').replace(/\r\n/g, '  \r\n') }</ReactMarkdown>
              </div></>}
            </Card.Body>
          </Card>

          {/* Google link */}
          { report?.askForReview && (
            <Card className={styles.subCard} style={{cursor: 'pointer'}} onClick={() => { window.open('https://g.page/r/CS3-kgMp-nLHEAg/review', '_blank', 'noopener') }}>
              <Row >
                  {/* SMALL ONLY */}
                  <Col sm={12} className="d-md-none">
                    <div className="centerFlexContent">
                      <img src="./images/google-review-200.png" className={styles.googleIcon} alt="Google icon" />
                    </div>
                  </Col>
                  <Col sm={12} className="d-md-none centerFlexContentBoth" style={{margin: '15px'}}>
                      If you've enjoyed using our service please consider writing us a review on Google Reviews
                  </Col>

                  {/* MEDIUM+ ONLY */}
                  <Col md={12} className="d-none d-md-block">
                    <div className="centerFlexContentBoth" style={{textAlign: 'center'}}>
                      <img src="./images/google-review-200.png" className={styles.googleIcon} style={{marginRight: '20px'}} alt="Google icon" />
                      <div className={styles.subCardContent}>
                        If you've enjoyed using our service please consider writing us a review on <u>Google Reviews</u>
                        <br />
                        ⭐⭐⭐⭐⭐
                      </div>
                    </div>
                  </Col>
              </Row>
            </Card>
          )}

          {/* Provestor link */}
          { (brandContext.brand === provestorBrand && companies.length === 0) && (
            <Card className={styles.subCard}>
              <Row >
                  {/* SMALL ONLY */}
                  <Col sm={12} className="d-md-none">
                    <div className="centerFlexContent">
                      <img src="./images/provestor-icon-192.png" className={styles.provestorIcon} alt="Provestor icon" />
                    </div>
                  </Col>
                  <Col sm={12} className="d-md-none centerFlexContentBoth" style={{margin: '15px'}}>
                    <div className="centerFlexContent" style={{flexDirection: 'column'}}>
                      Our fixed-fee accountancy service includes everything you need to run your property portfolio, join Provestor today
                      <div className="mt-3 text-center">
                        <a href="https://www.provestor.co.uk/join" target="_blank" rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faDesktop} /> www.provestor.co.uk/join
                        </a>
                        <br />
                        <FontAwesomeIcon icon={faPhoneAlt} /> {simpleTerms(SimpleTerm.AppContactPhone)}
                      </div>
                    </div>
                  </Col>

                  {/* MEDIUM+ ONLY */}
                  <Col md={12} className="d-none d-md-block">
                    <div className="centerFlexContent">
                      <img
                        src="./images/provestor-icon-192.png"
                        className={styles.provestorIcon}
                        style={{position: 'relative', left: '10px', marginRight: '40px'}}
                        alt="Provestor icon"
                      />
                      <div className={styles.subCardContent}>
                        <p className="mt-3 mb-2">
                          Our fixed-fee accountancy service includes everything you need to run your property portfolio, join Provestor today
                        </p>
                        <div className="mb-3 text-center">
                          <a href="https://www.provestor.co.uk/join" target="_blank" rel="noopener noreferrer" style={{display: 'inline-block', marginRight: '20px'}}>
                            <FontAwesomeIcon icon={faDesktop} /> www.provestor.co.uk/join
                          </a>
                          <p style={{display: 'inline-block'}}>
                            <FontAwesomeIcon icon={faPhoneAlt} /> {simpleTerms(SimpleTerm.AppContactPhone)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
              </Row>
            </Card>
          )}

        </Container>
    </DefaultLayout>
  )
}

export default ConsultationReport
