/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { CompletionStatement, CompletionStatementPostModel, CSLineTypeOption } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class CompletionStatements<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags CompletionStatements
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/completionStatements
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<CompletionStatement[], any>({
      path: `/api/v2/companies/${companyId}/completionStatements`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompletionStatements
   * @name CreateCompletionStatement
   * @request POST:/api/v2/companies/{companyId}/completionStatements
   * @secure
   */
  createCompletionStatement = (companyId: number, pm: CompletionStatementPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/completionStatements`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompletionStatements
   * @name GetOne
   * @request GET:/api/v2/companies/{companyId}/completionStatements/{id}
   * @secure
   */
  getOne = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<CompletionStatement, any>({
      path: `/api/v2/companies/${companyId}/completionStatements/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompletionStatements
   * @name GetLineTypeOptions
   * @request GET:/api/v2/companies/{companyId}/completionStatements/LineTypeOptions
   * @secure
   */
  getLineTypeOptions = (companyId: number, params: RequestParams = {}) =>
    this.request<CSLineTypeOption[], any>({
      path: `/api/v2/companies/${companyId}/completionStatements/LineTypeOptions`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompletionStatements
   * @name Replace
   * @request PUT:/api/v2/companies/{companyId}/completionStatements/replace
   * @secure
   */
  replace = (
    companyId: number,
    query: { completionStatementId: number },
    pm: CompletionStatementPostModel,
    params: RequestParams = {},
  ) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/completionStatements/replace`,
      method: "PUT",
      query: query,
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompletionStatements
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/completionStatements/Validate
   * @secure
   */
  validateCreate = (companyId: number, pm: CompletionStatementPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/completionStatements/Validate`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
