import { useContext, useEffect, useState } from "react";
import { BrandingThemes } from "../../api/inni/BrandingThemes";
import { BrandingTheme } from "../../api/inni/data-contracts";
import CompanyContext from "../../context/CompanyContext";
import { useInniAPI } from "../useInniAPI";

export function useBrandingThemesList(): [Array<BrandingTheme>, boolean] {

    const companyContext = useContext(CompanyContext);
    const brandApi = useInniAPI(BrandingThemes);
    const [themes, setThemes] = useState<Array<BrandingTheme>>([]);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (companyContext && brandApi && !loaded && !loading) {
            setLoading(true);
            brandApi.get(companyContext.cid).then(
                response => {
                    setThemes(response.data);
                    setLoaded(true);
                }
            ).catch(error => setLoaded(true))
        }
    }, [companyContext, brandApi, loaded, loading])

    return [themes, loaded];
}