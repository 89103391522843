import { useContext, useEffect, useState } from "react"
import CompanyContext from "../../../../context/CompanyContext"
import { useInniAPI } from "../../../../hooks/useInniAPI";
 import {Shareholdings} from "../../../../api/inni/Shareholdings"
import { FormikHelpers } from "formik";
import { ManagePeopleDetails, OptimisedPlanShareholderSetupModel, OrdinaryPlanShareholderSetupModel, Person, ShareholderSetupData } from "../../../../api/inni/data-contracts";
import { useNavigateToEntity } from "../../../../hooks/useNavigateToEntity";
import { Action, Entity } from "../../../../utils/EntityAction";
import { useHistory } from "react-router-dom";
import CreateOptimisedShareholdings from "./Components/CreateOptimisedShareholdings";
import { People } from "../../../../api/inni/People";
import CreateComplexShareholdings from "./Components/CreateComplexShareholdings";
import CreateOrdinaryShareholdings from "./Components/CreateOrdinaryShareholdings";
import { useAssertPermission } from "../../../../hooks/useAssertPermission";


const CreateShareholdings =()=>{
    useAssertPermission(Entity.Shareholdings, Action.CreateWizard);
    const companyContext = useContext(CompanyContext);
    const ShareholdingsAPI = useInniAPI(Shareholdings)
    const [onboardingData, setOnboardingData] = useState<ShareholderSetupData|undefined>();
    const navigateToEntity = useNavigateToEntity();
    const history = useHistory();

    const peopleAPI = useInniAPI(People)
    const [managePeopleDetails, setManagePeopleDetails] = useState<ManagePeopleDetails>();

    useEffect(() =>{
        if(ShareholdingsAPI){
            ShareholdingsAPI.onboardingData(companyContext.cid)
            .then((res) =>{
                setOnboardingData(res.data)
            })
            .catch((error)=>{
                console.error(error)
            })
        }
    },[ShareholdingsAPI, companyContext.cid])

    useEffect(() => {
        if(peopleAPI) {
            peopleAPI.getForManagePeople(companyContext.cid)
            .then((res) =>{
                setManagePeopleDetails(res.data)
            })
            .catch((err) =>{
                console.error(err)
            })
        }
    }, [companyContext.cid, peopleAPI])

    const createOrdinaryShares =(values: OrdinaryPlanShareholderSetupModel, actions: FormikHelpers<OrdinaryPlanShareholderSetupModel>): Promise<void> => {
        return new Promise((resolve, reject) => {
            if(ShareholdingsAPI){
                ShareholdingsAPI.ordinaryPlanShareholderSetup(companyContext.cid, values)
                .then((res) =>{
                    navigateToEntity(Entity.CompanySettingsV7,Action.List,{"activePage" : "people"})
                    resolve();
                })
                .catch((error)=>{
                    console.error(error)
                })
                
            }
            else{
                reject();
            }
        });
    }

    const createOptimisedShares =(values: OptimisedPlanShareholderSetupModel, actions: FormikHelpers<OptimisedPlanShareholderSetupModel>): Promise<void> => {
        return new Promise((resolve, reject) => {
            if(ShareholdingsAPI){
                ShareholdingsAPI.optimisedPlanShareholderSetup(companyContext.cid, values)
                .then((res) =>{
                    navigateToEntity(Entity.CompanySettingsV7,Action.List,{"activePage" : "people"})
                    resolve();
                })
                .catch((error)=>{
                    console.error(error)
                })
                
            }
            else{
                reject();
            }
        });
    }

    return(
        <>
            {managePeopleDetails &&  managePeopleDetails.people && managePeopleDetails.maxPeople && managePeopleDetails.people.length > managePeopleDetails.maxPeople &&
                <CreateComplexShareholdings 
                    hide={() =>{ history.goBack()}} 
                />
            }

            {managePeopleDetails &&  managePeopleDetails.people && managePeopleDetails.maxPeople &&  managePeopleDetails.people.length <= managePeopleDetails.maxPeople  &&
                <>
                    {onboardingData && onboardingData?.model === "Ordinary" &&
                        <CreateOrdinaryShareholdings 
                            formSubmit={createOrdinaryShares}
                            hide={() =>{ history.goBack()}} 
                            persons={managePeopleDetails.people}
                        />
                    }

                    {onboardingData && onboardingData?.model === "Optimised" &&
                        <CreateOptimisedShareholdings 
                            formSubmit={createOptimisedShares}
                            hide={() =>{ history.goBack()}} 
                            plan={onboardingData?.model}
                            createNewPerson ={() => navigateToEntity(Entity.PersonV7, Action.Create)}
                            persons={managePeopleDetails.people}
                            canAddNewPerson ={ managePeopleDetails.personEditabilityMetaData?.canAddNew}
                            
                        />
                    }
                </>
            } 
        </>
    )
}

export default CreateShareholdings