import { format } from 'date-fns';
import { useContext, useEffect, useState } from 'react';
import { AccountingPeriods } from '../../api/inni/AccountingPeriods';
import { AccountingPeriod } from '../../api/inni/data-contracts';
import CompanyContext from '../../context/CompanyContext';
import { useInniAPI } from '../useInniAPI';
type Option = {
	value: number;
	label: string;
};
export const useAccountingPeriods = (defaultPeriodId?: number) => {
	const { cid } = useContext(CompanyContext);
	const accountingPeriodsAPI = useInniAPI(AccountingPeriods);
	const [accountingPeriods, setAccountingPeriods] = useState<AccountingPeriod[]>([]);
	const [periodOptions, setPeriodOptions] = useState<Option[]>([]);
	const [defaultAccountingPeriodId, setDefaultAccountingPeriodId] = useState<number | undefined>();
	const [period, setPeriod] = useState({ value: 0, label: '' });
	useEffect(() => {
		if (accountingPeriodsAPI && cid) {
			accountingPeriodsAPI.index(cid).then(({ data: periods }) => setAccountingPeriods(periods));
		}
	}, [cid, accountingPeriodsAPI]);

	useEffect(() => {
		const sortedPeriods = accountingPeriods
			.sort((a, b) => new Date(b.dateTo).getTime() - new Date(a.dateTo).getTime())
			.map(({ id, dateTo }) => ({
				value: id,
				label: format(new Date(dateTo) || '', 'dd MMMM yyyy'),
			}))
		sortedPeriods && sortedPeriods[0] && setDefaultAccountingPeriodId(sortedPeriods[0].value)
		setPeriodOptions(sortedPeriods);
	}, [accountingPeriods]);

	useEffect(() => {
		defaultPeriodId && setPeriod(periodOptions.find(v => v.value === defaultPeriodId) || {value: 0, label: ''});
	}, [defaultPeriodId, periodOptions]);

	return { accountingPeriods, periodOptions, setPeriod, period, cid, defaultAccountingPeriodId };
};
