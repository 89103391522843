/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContactProfile, ContactProfileAsDetailed, ContactSupportData, ResetPasswordModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ContactProfiles<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ContactProfiles
   * @name Index
   * @summary Get contact id based on the current user context
   * @request GET:/api/v2/contacts/{contactId}/profile
   * @secure
   */
  index = (contactId: number, params: RequestParams = {}) =>
    this.request<ContactProfile, any>({
      path: `/api/v2/contacts/${contactId}/profile`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactProfiles
   * @name GetAsDetailed
   * @summary Get contact id based on the current user context
   * @request GET:/api/v2/contacts/{contactId}/profile/GetAsDetailed
   * @secure
   */
  getAsDetailed = (contactId: number, params: RequestParams = {}) =>
    this.request<ContactProfileAsDetailed, any>({
      path: `/api/v2/contacts/${contactId}/profile/GetAsDetailed`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactProfiles
   * @name ResetPassword
   * @summary Get contact id based on the current user context
   * @request POST:/api/v2/contacts/{contactId}/profile/ResetPassword
   * @secure
   */
  resetPassword = (contactId: number, pm: ResetPasswordModel, params: RequestParams = {}) =>
    this.request<ContactProfile, any>({
      path: `/api/v2/contacts/${contactId}/profile/ResetPassword`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactProfiles
   * @name GetSupportFeatures
   * @summary Get contact id based on the current user context
   * @request GET:/api/v2/contacts/{contactId}/profile/SupportFeatures
   * @secure
   */
  getSupportFeatures = (contactId: number, params: RequestParams = {}) =>
    this.request<ContactSupportData, any>({
      path: `/api/v2/contacts/${contactId}/profile/SupportFeatures`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactProfiles
   * @name IsdebuggingEnabled
   * @summary Check if debugging is enabled
   * @request GET:/api/v2/contacts/{contactId}/profile/IsDebuggingEnabled
   * @secure
   */
  isdebuggingEnabled = (contactId: number, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/contacts/${contactId}/profile/IsDebuggingEnabled`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
