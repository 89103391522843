import { Account } from "../api/inni/data-contracts";

export const accountsExpenditure = (accounts:Account[]) => {
    return accounts.filter(x => 
        x.accountSubGroup === "TravelEnt" ||
        x.accountSubGroup === "Office" ||
        x.accountSubGroup === "ProfFees" ||
        x.accountSubGroup === "GeneralExpenses" ||
        x.accountSubGroup === "Property"
    )
}

export const accountsExpenditurePersonal = (accounts:Account[]) => {
    return accounts.filter(x => 
        x.accountSubGroup === "TravelEnt" ||
        x.accountSubGroup === "Office" ||
        x.accountSubGroup === "ProfFees" ||
        x.accountSubGroup === "GeneralExpenses" ||
        x.accountSubGroup === "Property" ||
        (x.accountSubGroup === "DirectorsLoans" && !x.isExpenseAccount && x.bankAccount)
    )
}

export const accountsExpenditureDirLoan = (accounts:Account[]) => {
    return accounts.filter(x => 
        x.accountSubGroup === "TravelEnt" ||
        x.accountSubGroup === "Office" ||
        x.accountSubGroup === "ProfFees" ||
        x.accountSubGroup === "GeneralExpenses" ||
        x.accountSubGroup === "Property" ||
        x.accountSubGroup === "DirectorsLoans"
    )
}

export const accountsIncome = (accounts:Account[]) => {
    return accounts.filter(x => 
        x.accountSubGroup === "Sales" ||
        x.accountSubGroup === "OtherIncome"
    )
}

export const accountsBankAndManualExpenses = (accounts:Account[]) => {
    return accounts.filter(x => x.bankAccount && !x.payExpensesViaPayroll)
}

export const accountsBank = (accounts : Account[]) => {
    return accounts.filter(x => 
        x.bankAccount &&
        !x.isExpenseAccount
    )
}
export const accountsLettingAgentTrade = (accounts : Account[]) => {
    return accounts.filter(x => 
        x.accountSubGroup === "LettingAgentTradeAccounts" 
    )    
}