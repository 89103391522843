import { Form, Formik, FormikErrors, FormikHelpers, FormikProps } from "formik";
import { BasicFormLayout } from "../../../layouts/Desktop/BasicFormLayout";
import FormikField from "../../../elements/FormikField/FormikField";
import { PvSatrPropertyIncomePageData, PvSatrPropertyIncomePageDataPostModel } from "../../../api/inni/data-contracts";
import styles from "./BasicDetails.module.scss"
import { useContext, useEffect, useRef, useState } from "react";
import { useInniAPI } from "../../../hooks/useInniAPI";
import { PvSatr } from "../../../api/inni/PvSatr";
import CompanyContext from "../../../context/CompanyContext";
import { Button } from "../../../elements/Button/Button";
import Icon from "../../../elements/Icon/Icon";
import { useStandaloneSATRStatus } from "../../../hooks/useStandaloneSATRStatus";
import { LoadingLabel, LoadingTextInput } from "../../../elements/LoadingPlaceholder/LoadingPlaceholder";
import { Alert } from "react-bootstrap";

interface ValidationRules {
    fieldName: keyof PvSatrPropertyIncomePageData;
    regex?: RegExp;
    errorMessage?: string;
    required: boolean;
}

export default function PropertyLoses() {
    const companyContext = useContext(CompanyContext)
    const pvsatrAPI = useInniAPI(PvSatr)
    const formikRef = useRef<FormikProps<PvSatrPropertyIncomePageData>>(null);
    const { pvSatrDetails, completeStep, nextStep } = useStandaloneSATRStatus();
    const [incomeData, setIncomeData] = useState<PvSatrPropertyIncomePageData>(
        {propertyLossesBroughtForward: 0, unusedResidentialPropertyFinanceCostsBroughtForward: 0});
    const [incomeDataLoaded, setIncomeDataLoaded] = useState(false);

    // Get existing data
    useEffect(() => {
        if (!incomeDataLoaded && pvsatrAPI && pvSatrDetails) {
            pvsatrAPI.getPropertyIncomePageData(companyContext.cid, pvSatrDetails.id).then((res) => {
                setIncomeData({
                    propertyLossesBroughtForward: res.data.propertyLossesBroughtForward || 0,
                    unusedResidentialPropertyFinanceCostsBroughtForward: res.data.unusedResidentialPropertyFinanceCostsBroughtForward || 0
                });
                setIncomeDataLoaded(true);
            }).catch((err) => {
                console.error(err.error);
            })
        }
    }, [pvSatrDetails, pvsatrAPI, incomeData, companyContext.cid, incomeDataLoaded])

    const onSubmit = (values: PvSatrPropertyIncomePageDataPostModel, actions: FormikHelpers<PvSatrPropertyIncomePageDataPostModel>): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (pvsatrAPI && pvSatrDetails) {
                pvsatrAPI.updatePropertyIncomePageData(companyContext.cid, pvSatrDetails.id, values).then((response) => {
                    completeStep('SetupPropertyIncomePageData');
                    resolve();
                }).catch((error) => {
                    actions.setErrors(error.error);
                    reject();
                });
            } else {
                if (formikRef.current){
                    actions.setErrors(formikRef.current.errors);
                }
                reject();
            }
        });
    };

    const validateForm = (): boolean => {
        if (!formikRef.current) return true;

        const validationRules: ValidationRules[] = [
            {
                fieldName: 'propertyLossesBroughtForward',
                required: true
            },
            {
                fieldName: 'unusedResidentialPropertyFinanceCostsBroughtForward',
                required: true
            }
        ];
    
        const validateField = (rule: ValidationRules): boolean => {
            const { fieldName, regex, errorMessage, required } = rule;
            const value = formikRef.current?.values[fieldName];

            formikRef.current?.setFieldTouched(fieldName, false, false);
            formikRef.current?.setFieldError(fieldName, undefined);

            if (required && !value && value !== 0) {
                formikRef.current?.setFieldTouched(fieldName, true, false);
                formikRef.current?.setFieldError(fieldName, 'Required');
                return false;
            }

            // if (regex && value && !regex.test(value.toString())) {
            //     formikRef.current?.setFieldTouched(fieldName, true, false);
            //     formikRef.current?.setFieldError(fieldName, errorMessage);
            //     return false;
            // }
    
            return true;
        };
    
        // Validate all fields and return overall result
        return validationRules.map(validateField).every(Boolean);
    };

    const handleOnContinueClick = () =>{
        if (!validateForm())
            return;

        // formikRef.current?.submitForm().then(() =>{
        //     completeStep('SetupPropertyIncomePageData');
        // })
        formikRef.current?.submitForm();
    }

    // const validateCreate = (values: PvSatrPropertyIncomePageDataPostModel) : Promise<FormikErrors<PvSatrPropertyIncomePageDataPostModel>> => {
    //     return new Promise((resolve, reject) => {
    //         resolve({})
    //     })
    // }

    const nextPage = () => {
        nextStep('SetupProperty');
    }

    return(
        <BasicFormLayout
            title="Past balances"
            storyblokDescriptionId="past-balances-page"
        >
            { pvSatrDetails && incomeDataLoaded
                ? (
                    <Formik
                        initialValues={incomeData || { } as PvSatrPropertyIncomePageData}
                        enableReinitialize         
                        validateOnChange={false}
                        // validate={validateCreate}
                        onSubmit={onSubmit}
                        innerRef={formikRef}
                    >   
                        {({ values, isSubmitting }) => (
                            <Form>
                                <div style={{width: '300px'}}>
                                    <FormikField<PvSatrPropertyIncomePageDataPostModel> 
                                        className={styles.formikField}
                                        label="Loss carried forward to be applied to this year’s profits"
                                        name="propertyLossesBroughtForward"
                                        type="number"                                        
                                        step="0.01"
                                        prefix="£"
                                        min="0"
                                        onlyPositiveNumbers
                                        boldLabel
                                        readonly={pvSatrDetails.progress?.setupSubmitted}
                                    />

                                    <br />

                                    <FormikField<PvSatrPropertyIncomePageDataPostModel> 
                                        className={styles.formikField}
                                        label="Unused residential property expenses carried forward"
                                        name="unusedResidentialPropertyFinanceCostsBroughtForward"
                                        type="number"                                        
                                        step="0.01"
                                        prefix="£"
                                        min="0"
                                        onlyPositiveNumbers
                                        boldLabel
                                        readonly={pvSatrDetails.progress?.setupSubmitted}
                                    />

                                    <br />

                                </div>
                                        
                                {/* Buttons */}
                                { pvSatrDetails?.progress?.setupSubmitted 
                                    // SATR submitted
                                    ? (
                                        <>
                                            <Alert className="mt-5" variant="info">
                                                <b>You have completed your tax return. Please contact us if you need to make changes.</b>
                                            </Alert>

                                            <Button 
                                                variant="primary" 
                                                onClick={nextPage}
                                                marginTop 
                                                thin
                                            >
                                                Continue <Icon name='arrowNext' />
                                            </Button>
                                        </>
                                    )
                                    // SATR in progress
                                    : (
                                        <>
                                            { pvSatrDetails?.progress?.setupPropertyIncomePageData
                                                ? (
                                                    <>
                                                        <Alert className="mt-5" variant="info">
                                                            <b>You have already confirmed your balances, but you can still make changes.</b>
                                                        </Alert>
                                                        <Button 
                                                            variant="primary" 
                                                            onClick={handleOnContinueClick}
                                                            marginTop 
                                                            thin
                                                        >
                                                            Save & continue <Icon name='arrowNext' />
                                                        </Button>
                                                    </>
                                                )
                                                : (
                                                    <div className="mt-3">
                                                        <Button
                                                            variant="primary"
                                                            label="Confirm past balances"
                                                            onClick={handleOnContinueClick}
                                                            marginTop
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Form>
                        )}
                    </Formik>

                )
                : (
                    <div style={{width: '300px'}}>
                        <div className="ml-3 mt-4 mr-4">
                            <LoadingLabel />
                            <LoadingTextInput/>
                        </div>
                        <div className="ml-3 mt-4 mr-4">
                            <LoadingLabel />
                            <LoadingTextInput/>
                        </div>
                    </div>

                )
            }
        </BasicFormLayout>
    )
}