import React, { useContext, useEffect, useState } from 'react';
import { FormikProps } from 'formik';
import { useParams } from 'react-router-dom';
import { LettingAgent, LettingStatement, LettingStatementPostModel, Tenancy } from '../../../api/inni/data-contracts';
import { LettingAgents } from '../../../api/inni/LettingAgents';
import { LettingStatements } from '../../../api/inni/LettingStatements';
import CompanyContext, { CompanyProduct } from '../../../context/CompanyContext';
import { Button } from '../../../elements/Button/Button';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { DefaultLayout } from '../../../layouts/Desktop/DefaultLayout';
import Toolbar from '../../../layouts/Desktop/Toolbar';
import { Action, Entity } from '../../../utils/EntityAction';
import { formatDate } from '../../../utils/formatDate';
import Loadingtemplate from '../Components/Loadingtemplate';
import ReadAgentStatementWrapper, { CreateEditAgentStatementTablePlaceholder } from './ReadAgentStatementWrapper';
import { Alert, Card, Container } from 'react-bootstrap';
import Dropzone, { FileRejection } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCloudArrowUp, faCloudXmark, faRotate } from '@fortawesome/pro-regular-svg-icons';
import styles from './AgentStatement.module.css'
import invoiceStyles from '../Invoice.module.css'; 
import { Tenancies } from '../../../api/inni/Tenancies';
import InfoBanner from '../../../elements/InfoBanner/InfoBanner';
import classNames from 'classnames';
import { Modal } from '../../../components/Modal/Modal';
import { placeholderImage } from '../../../utils/formatters/formatProperty';
import { useOnboardingMessages } from '../../../hooks/onboarding/useOnboardingMessages';
import { ExpandedViewBackLabel } from "../../../elements/ExpandedViewBackLabel/ExpandedViewBackLabel";


interface RouteParams {
	id: string;
	fromReminder?: string;
}

function ReadAgentStatements() {
	const hasPermission = useHasPermission();
	const navigateToEntity = useNavigateToEntity();
	const agentStatementId = parseInt(useParams<RouteParams>().id);
	const defaultToEdit = useParams<RouteParams>().fromReminder === "fromReminder";
	const agentStatementsAPI = useInniAPI(LettingStatements, [400]);
	const formikRef = React.useRef<FormikProps<LettingStatementPostModel>>(null);
	const agentDetailsAPI = useInniAPI(LettingAgents);
	const lettingStatementApi = useInniAPI(LettingStatements);
	const tentancyApi = useInniAPI(Tenancies);
	const companyContext = useContext(CompanyContext);
	const companyId = companyContext.cid;
	const diyAgentStatements = useHasPermission()(Entity.LettingStatement, Action.All)[0]
	const [agentStatement, setAgentStatement] = useState<LettingStatement>();
	const [agentStatementLoaded, setAgentStatementLoaded] = useState(false);
	const [agentDetails, setAgentDetails] = useState<LettingAgent[]>();
	const [agentDeatilsLoaded, setAgentDetailsLoaded] = useState(false);
	const [editing, setEdting] = useState(false);
	const [completeAgentStatement, setCompleteAgentStatement] = useState(false);
	const [initialEditChecked, setInitialEditChecked] = useState(false);
	const [markCompletedError, setMarkCompletedError] = useState("");
	const [validationError, setValidationError] = useState("");

	const [uploadingStatementFile, setUploadingStatementFile] = useState(false)
	const [uploadStatementFileFailed, setUploadStatementFileFailed] = useState(false)
	const [statementFileUploaded, setStatementFileUploaded] = useState(false)
	const [fileAlreadyUploaded, setFileAlreadyUploaded] = useState(false)
	const [tenancies, setTenancies] = useState<Tenancy[]>()

	const [statementDocumentModal, setStatementDocumentModal] = useState<string>()

	const [unreadPageMessages, setMessageRead, getOnboardingComponent] = useOnboardingMessages('lettingstatements');

	const v8Styling = companyContext.company?.useV8UI || false

	useEffect(() => {
		if (!agentStatementLoaded && agentStatementsAPI) {
			agentStatementsAPI.getOne(companyId, agentStatementId).then((response) => {
				setAgentStatement(response.data);
				setAgentStatementLoaded(true);
				if((defaultToEdit || (!response.data.detailCompletedOn && diyAgentStatements)) && !initialEditChecked) {
					setEdting(true);
				}
				setInitialEditChecked(true)
			});
		}
	}, [agentStatementLoaded, agentStatementsAPI, setAgentStatementLoaded, companyId, 
		agentStatementId, defaultToEdit, initialEditChecked, diyAgentStatements]);

	useEffect(() => {
		setAgentStatementLoaded(false);
		if(!editing) {
			setMarkCompletedError("")
			setValidationError("")
		}
	}, [editing]);

	useEffect(() => {
		if (agentStatement && agentStatement.detailCompletedOn !== null) {
			setCompleteAgentStatement(true);
		}
		if(agentStatement && agentStatement.statementDocumentID) {
			setFileAlreadyUploaded(true)
		}
	}, [agentStatement]);

	useEffect(() => {
		if (!agentDeatilsLoaded && agentDetailsAPI) {
			agentDetailsAPI.index(companyId).then((response) => {
				setAgentDetails(response.data);
				setAgentDetailsLoaded(true);
			});
		}
	}, [agentDeatilsLoaded, agentDetailsAPI, setAgentDetailsLoaded, companyId]);

	useEffect(() => {
		if (tentancyApi && tenancies === undefined) {
			tentancyApi.index(companyId).then((response) => {
				setTenancies(response.data);
			});
		}
	}, [tentancyApi, companyId, tenancies]);

	const isFromReminder = useParams<RouteParams>().fromReminder === "fromReminder";

	const handleSubmit = () => {
		if (editing && formikRef.current !== null) {
			setMarkCompletedError("");
			setValidationError("");
			if(formikRef.current && formikRef.current.errors) formikRef.current.errors = {}

			formikRef.current.submitForm().then(() => {
				if(diyAgentStatements && isFromReminder) {
					navigateToEntity(Entity.BookkeepingV8, Action.List, {"activePage" : "lettingstatements"})
				}
				else {
					setEdting(false);
				}
			}).catch((error) => {
				if(error.status === 400) {
					//Bad request, probably have a user friendly message
					const errorKeys = Object.keys(error.error)
					if(errorKeys.length > 0) {
						setValidationError(error.error[errorKeys[0]])
					}
					else {
						setValidationError(error.error);
					}

				} else {
					console.error(error);
				}
			});
		}
	};
	
	const handleComplete = () => {
		if (editing && formikRef.current !== null) {
			setMarkCompletedError("");
			setValidationError("")

			if(formikRef.current && formikRef.current.errors) formikRef.current.errors = {}
			
			formikRef.current.submitForm().then(() => {
				if(formikRef.current && formikRef.current.isValid) {
					//Form saved, now try mark as completed
					agentStatementsAPI?.markAsDetailsCompleted(companyId, agentStatementId).then((response) => {
						if (response.status === 200) {
							setAgentStatementLoaded(false);
							setCompleteAgentStatement(true);
							if(diyAgentStatements && isFromReminder) {
								navigateToEntity(Entity.BookkeepingV8, Action.List, {"activePage" : "lettingstatements"})
							}
							else {
								setEdting(false);
							}
						}
					}).catch((error) => {
						if(error.status === 400) {
							//Bad request, probably have a user friendly message
							setMarkCompletedError(error.error);
						} else {
							console.error(error);
						}
					});
				}
				else if(formikRef.current && !formikRef.current.isValid && Object.keys(formikRef.current.errors).length > 0) {
					const firstError = Object.keys(formikRef.current.errors)[0]
					setMarkCompletedError(formikRef.current.errors[firstError as keyof LettingStatementPostModel] || "");
				}
				else {
					setMarkCompletedError("Sorry, something went wrong");
				}
			}).catch((error) => {
				console.error(error.error)
				if(error.status === 400) {
					//Bad request, probably have a user friendly message
					const errorKeys = Object.keys(error.error)
					if(errorKeys.length > 0) {
						setMarkCompletedError(error.error[errorKeys[0]])
					}
					else {
						setMarkCompletedError(error.error);
					}

				} else {
					console.error(error);
				}
			});

		}
	};

	const handleForceSave = () => {
		if (editing && formikRef.current !== null) {
			setMarkCompletedError("");
			setValidationError("")

			if(formikRef.current && formikRef.current.errors) formikRef.current.errors = {}
			
			formikRef.current.submitForm().then(() => {
				if(formikRef.current && formikRef.current.isValid) {
					//Form saved, now try mark as completed
					agentStatementsAPI?.forceSave(companyId, agentStatementId).then((response) => {
						if (response.status === 200) {
							setAgentStatementLoaded(false);
							setCompleteAgentStatement(true);
							if(diyAgentStatements && isFromReminder) {
								navigateToEntity(Entity.LettingStatement, Action.List)
							}
							else {
								setEdting(false);
							}
						}
					}).catch((error) => {
						if(error.status === 400) {
							//Bad request, probably have a user friendly message
							setMarkCompletedError(error.error);
						} else {
							console.error(error);
						}
					});
				}
				else if(formikRef.current && !formikRef.current.isValid && Object.keys(formikRef.current.errors).length > 0) {
					const firstError = Object.keys(formikRef.current.errors)[0]
					setMarkCompletedError(formikRef.current.errors[firstError as keyof LettingStatementPostModel] || "");
				}
				else {
					setMarkCompletedError("Sorry, something went wrong");
				}
			}).catch((error) => {
				console.error(error.error)
				if(error.status === 400) {
					//Bad request, probably have a user friendly message
					const errorKeys = Object.keys(error.error)
					if(errorKeys.length > 0) {
						setMarkCompletedError(error.error[errorKeys[0]])
					}
					else {
						setMarkCompletedError(error.error);
					}

				} else {
					console.error(error);
				}
			});

		}
	};

	const uploadStatementFile = (acceptedFiles:File[], fileRejections:FileRejection[]) => {
        if(acceptedFiles.length > 0 && agentStatementId && lettingStatementApi) {
            setUploadingStatementFile(true)
            setUploadStatementFileFailed(false)
            setStatementFileUploaded(false)
            setFileAlreadyUploaded(false)
			lettingStatementApi.uploadLettingStatement(companyContext.cid, agentStatementId, { fileToUpload: acceptedFiles[0] }).then((res) => {
				setStatementFileUploaded(true)
			}).catch((err) => {
				setUploadStatementFileFailed(true)
			}).finally(() => {
				setUploadingStatementFile(false)
			})
        }
        else {
            console.warn("File was of an invalid type, you tried to upload:")
            console.warn(fileRejections)
			console.warn("Or the API is unavailable")
        }
    }

	return (
		<>
			{ agentStatement
				? (
					<DefaultLayout
						backIcon
					>
						<Container className="ml-0 p-0">
							<ExpandedViewBackLabel
								backLabel="Letting statements"
								title="Letting statement"
								onClick={() => navigateToEntity(Entity.BookkeepingV8, Action.List, {"activePage" : "lettingstatements"})}
							>
								{/* Removed editing ability in V8 flow for now */}
								{ !v8Styling && (
									<Toolbar>
										{!editing && (
												<>
													{hasPermission(Entity.InvoiceAdmin, Action.All)[0] && (
														<Button
															label="Edit"
															buttonType="edit"
															disabled={agentStatement?.editability !== null && !agentStatement?.editability?.canEdit}
															disabledMessage={agentStatement?.editability?.cantEditReason}
															admin={hasPermission(Entity.InvoiceAdmin, Action.All)[1]}
															onClick={() => setEdting(true)}></Button>
													)}

													{agentStatement.statementUrl && <Button buttonType="file" label='View attachment' onClick={() => setStatementDocumentModal(agentStatement.statementUrl)}/>}
												</>
											)}
									</Toolbar>
								)}
							</ExpandedViewBackLabel>

							<div style={{maxWidth: '1600px'}}>

								{ editing && getOnboardingComponent('letting_statements_help')}

								{tenancies !== undefined && tenancies.length === 0 && (
									<InfoBanner
										type="warning"
										title="No tenancies"
										body={
											<>
												<p style={{marginBottom: '0.25rem'}}>
													Note that you have no tenancies currently setup for any of your properties, would you like to do this now?
												</p>
												<Button entity={Entity.Tenancy} action={Action.Create} buttonType='new' small>Create tenancy</Button>
											</>
										}
									/>
								)}

								{diyAgentStatements && editing && (
									<Dropzone 
										accept={['image/*','.pdf','.doc','.docx']}
										onDrop={uploadStatementFile} 
										multiple={false} 
										disabled={uploadingStatementFile}
									>
										{
											({getRootProps, getInputProps}) => (
												<div {...getRootProps({className: classNames(styles.dropzone, {[styles.dropzoneV75]: v8Styling})})}>
													<input {...getInputProps()} />

													{!uploadingStatementFile && !statementFileUploaded && !uploadStatementFileFailed && !fileAlreadyUploaded && (
														<>
															<FontAwesomeIcon icon={faCloudArrowUp} size="6x"/>
															<div className={styles.dropzoneText}>
																<h4 className="text-muted">Drop your statement file here</h4>
																<p className="text-muted">Or click to browse</p>
															</div>
														</>
													)}

													{uploadingStatementFile && (
														<>
															<FontAwesomeIcon icon={faRotate} size="6x" spin/>
															<div className={styles.dropzoneText}>
																<h4 className="text-muted">Uploading your statement</h4>
																<p className="text-muted">Please wait...</p>
															</div>
														</>
													)}

													{uploadStatementFileFailed && (
														<>
															<FontAwesomeIcon icon={faCloudXmark} size="6x"/>
															<div className={styles.dropzoneText}>
																<h4 className="text-muted">Sorry, something went wrong</h4>
																<p className="text-muted">Please try again</p>
															</div>
														</>
													)}

													{statementFileUploaded &&(
														<>
															<FontAwesomeIcon icon={faCheckCircle} size="6x"/>
															<div className={styles.dropzoneText}>
																<h4 className="text-muted">File uploaded successfully</h4>
																<p className="text-muted">You may now fill out the statement below</p>
															</div>
														</>
													)}

													{fileAlreadyUploaded && (
														<>
															<FontAwesomeIcon icon={faCheckCircle} size="6x"/>
															<div className={styles.dropzoneText}>
																<h4 className="text-muted">Your file has already been uploaded</h4>
																<p className="text-muted">You may now fill out the statement below, or reupload if needed</p>
															</div>
														</>
													)}
												</div>
											)
										}
									</Dropzone>
								)}

								<ReadAgentStatementWrapper
									formikRef={formikRef}
									setEditing={setEdting}
									editing={editing}
									agentName={agentDetails?.find((agent) => agent.id === agentStatement.lettingAgentID)?.name || ''}
									agentStatement={agentStatement}
									setCompleteAgentStatement={setCompleteAgentStatement}
									diyAgentStatements={diyAgentStatements}
									setAgentDetailsLoaded={setAgentDetailsLoaded}
									v8Styling={v8Styling}
								>
							<div className='p-3'>
								<Toolbar>
								{editing && !diyAgentStatements && (
									<>
										<Button
											label="Save"
											buttonType="save"
											onClick={handleSubmit}
											disabled={agentStatement.detailCompletedOn !== null && agentStatement.detailCompletedOn !== undefined}
										/>
										{agentStatement.lettingStatementLines && agentStatement.lettingStatementLines?.length > 0 && (
											<Button
												label="Mark as completed"
												buttonType="submit"
												onClick={() => handleComplete()}
												disabled={completeAgentStatement}
												disabledMessage={
													agentStatement.detailCompletedOn === null
														? "Total doesn't match the net payment to the landlord"
														: agentStatement.detailCompletedOn !== null
														? 'Agent statement was completed on ' + formatDate(agentStatement.detailCompletedOn)
														: 'Please save the letting statement first'
												}
											/>
										)}
									</>
								)}
								
								{/* Error messages */}
								<div>
									{markCompletedError && (
										<Alert
											variant="danger"
											dismissible
											onClose={() => setMarkCompletedError("")} style={{maxWidth: '1600px'}}
										>
											<span className='mr-3'>
												{markCompletedError}
											</span>
											{markCompletedError.includes("save anyway") &&
												<>
													<br />
													<Button label="Save" buttonType="save" onClick={() => handleForceSave()} small />
												</>
											}
											
										</Alert>
									)}

									{validationError && (
										<Alert
											variant="danger"
											style={{maxWidth: '1600px'}}
										>
											{validationError}
										</Alert>
									)}
								</div>

								{editing && diyAgentStatements && (
									<>
										<Button
											label="Save"
											buttonType="save"
											onClick={() => handleComplete()}
											disabled={completeAgentStatement}
											disabledMessage={
												agentStatement.detailCompletedOn === null
													? "Total doesn't match the net payment to the landlord"
													: agentStatement.detailCompletedOn !== null
													? 'Agent statement was completed on ' + formatDate(agentStatement.detailCompletedOn)
													: 'Please save the letting statement first'
											}
										/>
										<Button
											label="Save draft"
											buttonType="new"
											onClick={handleSubmit}
											disabled={agentStatement.detailCompletedOn !== null && agentStatement.detailCompletedOn !== undefined}
										/>
									</>
								)}
							</Toolbar>
						</div>
						</ReadAgentStatementWrapper> 
					</div>

							<Modal
								size="xl"
								showModal={statementDocumentModal !== undefined}
								hideModal={() => setStatementDocumentModal(undefined)}
								title="Agent statement"
								buttons={[
									<Button buttonType="close" key="closeBtn" onClick={() => setStatementDocumentModal(undefined)} />,
									<a key="downloadBtn" href={statementDocumentModal || ''} target="_blank" download rel="noopener noreferrer">
										<Button buttonType="download" />
									</a>
								]}>

								{statementDocumentModal && /.pdf|.xls|.csv/.test(statementDocumentModal) && 
										<div className={styles.noPreview}>
											<img src={placeholderImage} alt="Placeholder" />
											<>Sorry, there is currently no preview available, please download the file instead</>
										</div>}

								{statementDocumentModal && !/.pdf|.xls|.csv/.test(statementDocumentModal) && 
									<img style={{maxWidth: '100%'}} src={statementDocumentModal} alt="Statement"/>}
							</Modal>
						</Container>
					</DefaultLayout>
				)
				: 
				defaultToEdit
					? <DefaultToEditPlaceholder v8Styling={v8Styling} />
					: <Loadingtemplate width="900px" editing />
			}
		</>
	);
}

export const DefaultToEditPlaceholder = ({v8Styling}: {v8Styling: boolean}) => {
	return (<DefaultLayout
		backIcon
		title={'Letting statement'}
		useFullWidth
	>
		<Toolbar>
			<Button
				label="Save"
				buttonType="save"
				disabled={true}
			/>
		</Toolbar>
		<div style={{maxWidth: '1600px'}}>
			<Dropzone disabled={true}>
				{
					() => (
						<div className={classNames(styles.dropzone, {[styles.dropzoneV75]: v8Styling})}>
							<FontAwesomeIcon icon={faCloudArrowUp} size="6x"/>
							<div className={styles.dropzoneText}>
								<h4 className="text-muted">Drop your statement file here</h4>
								<p className="text-muted">Or click to browse</p>
							</div>
						</div>
					)
				}
			</Dropzone>
			<div className="d-lg-flex mb-3">
				<div style={{ maxWidth: '1600px', width: '100%' }}> 
					<Card className={invoiceStyles.lettingStatementLayout} data-cy="lettingStatementLayout">
						<div className={classNames(invoiceStyles.invoiceHeaderFont, invoiceStyles.lettingStatement, 'my-3 mx-3')}>Letting Statement</div>
						<CreateEditAgentStatementTablePlaceholder/>
					</Card>
				</div>
			</div>
		</div>
	</DefaultLayout>)
}

export default ReadAgentStatements;
