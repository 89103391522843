/**
 * Contains our different auth configs
 * The config that's exported is the one that's used in msal.ts
 */

import configHelper, { AuthType } from "../../utils/configHelper";

export interface AuthDefinition  {
   clientId: string,
   tenantName: string,
   signInPolicy?: string, 
   passwordResetPolicy?: string,
   isB2C: boolean,
   enableLogging: boolean,
   scopes: Array<string>
}

const testB2C : AuthDefinition = {
   clientId: '72f0377f-af89-4062-9767-7684485206f6',
   tenantName: 'innib2c',
   signInPolicy: 'B2C_1_desktop_signin',
   passwordResetPolicy: 'B2C_1_password_reset',
   isB2C: true,
   enableLogging: false,
   scopes: ['https://innib2c.onmicrosoft.com/api/app.access']
}

// Done - this is the IR35 action plan app in ADB2C
const iaB2C : AuthDefinition = {
   clientId: 'bf1992ab-0734-4c8d-ad96-d294477fba2b',
   tenantName: 'inniaccountsb2c',
   signInPolicy: 'B2C_1_desktop_signin',
   passwordResetPolicy: 'B2C_1_password_reset',
   isB2C: true,
   enableLogging: false,
   scopes: ['https://inniaccountsb2c.onmicrosoft.com/api/app.access']
}

// Done - this is the PV mobile login app in ADB2C
const pvB2C : AuthDefinition = {
   clientId: '4de4594d-71ee-4bb9-a1f4-e56eeea5d6c0',
   tenantName: 'provestorb2c',
   signInPolicy: 'B2C_1_desktop_signin',
   passwordResetPolicy: 'B2C_1_password_reset',
   isB2C: true,
   enableLogging: false,
   scopes: ['https://provestorb2c.onmicrosoft.com/api/app.access']
}


/*
   AD config notes:
      - Updated claim in AD -> app registrations -> inniAccounts React App (desktop) -> Token Configuration -> optional claims (ID)
      - Added redirect URI in above -> authentication (this needs to be a single-page application, not a web app)

*/
const managementAD : AuthDefinition = {
   // 'inniAccounts React App (desktop)' app in AD
   clientId: '7e03edad-6730-4b3f-99b4-b0d9b8c07c8a',
   tenantName: 'inniaccounts.co.uk',   // a6f504d9-8450-4ea0-9551-57bd23c0e256
   isB2C: false,
   enableLogging: false,
   scopes: ['https://api.inni.co.uk/Client.Access','https://api.inni.co.uk/Admin.Access']  // TODO we should have a scope set up in iA AD
}

function getActiveAuth():AuthDefinition {
   switch (configHelper.auth) {
      case AuthType.Provestor:
         return pvB2C;
      case AuthType.inniAccounts:
         return iaB2C;
      case AuthType.Admin:
         return managementAD;
   }
   return testB2C;
}
 
// This is the config to use:
 export default getActiveAuth(); //managementAD;