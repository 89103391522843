import React, { FC } from 'react';
import { useDropzone, DropzoneRootProps, DropzoneInputProps, FileRejection } from 'react-dropzone';
import styles from './Attachments.module.scss';
import cn from 'classnames';
import Icon from '../../elements/Icon/Icon';
import classNames from 'classnames';

type Props = {
	title?: string;
	message?: string | React.ReactNode;
	optional?: boolean;
	className?: string;
	accept?: string | string[]; // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
	onDrop: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
	attachedFiles?: File[] | null
	onRemoveFile?: (fileName: string) => void
	singleSelect?: boolean
	hidefiles?: boolean
	fullWidth?: boolean
	darkenedMode?: boolean
	regularLabel?: boolean
	showError?: boolean
};

const Attachments: FC<Props> = ({
		className,
		accept = 'image/*',
		onDrop,
		title,
		message,
		optional,
		attachedFiles,
		onRemoveFile,
		singleSelect,
		hidefiles,
		fullWidth,
		darkenedMode,
		regularLabel,
		showError
	}) => {

	const { getRootProps, getInputProps } = useDropzone({
		accept,
		onDrop,
		maxFiles: singleSelect ? 1 : undefined
	});
	const files = attachedFiles && attachedFiles.map((file, i, arr) => (
		<span style={{color: 'var(--ink200)'}} key={file.name}>
			{file.name}
			{i !== arr.length - 1 ? ', ' : ''}
		</span>
	));

	return (
		<section className={classNames(styles.attachments, {[styles.fullWidth]: fullWidth, [styles.darkenedMode]: darkenedMode})}>
			{ title && (
				<>
					{ regularLabel
						? (
							<>
								{title} {optional && <span>(optional)</span>}
							</>
						)
						: (
							<>
								<b>{title}</b> {optional && <span>(optional)</span>}
							</>
						)
					}
				</>
			)}
			{ message && <p>{message}</p>}
			{files && files.length > 0 && !hidefiles && (
				<div className="mb-2">
					<b>Files: </b> {files}
				</div>
			)}
			<div {...getRootProps({ className: cn(styles.dropZone, className, {[styles.error]: showError}), 'data-cy': 'dropzone' } as DropzoneRootProps)}>
				<input {...(getInputProps() as DropzoneInputProps)}  multiple={!singleSelect}/>
				<div className="d-flex">
					<Icon name="uploadFile" />
					<p className={cn('text-ink200', styles.attachmentsText)}>Click to choose a file, or drag here</p>
				</div>
			</div>
			{attachedFiles && <div className={styles.attachedFiles}>
				{attachedFiles.map((af, index) => <div key={index}>
					<span>{af.name}</span>
					{onRemoveFile && <span className={styles.removeAttachment} onClick={() => onRemoveFile(af.name)}><Icon size="small" name="close"/></span> }
				</div> )}
			</div>}
		</section>
	);
};

export default Attachments;
