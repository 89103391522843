import {useContext, useEffect, useState} from 'react';
import {useInniAPI} from "../../../../hooks/useInniAPI";
import {OpenBankingAccounts} from "../../../../api/inni/OpenBankingAccounts";
import BrandContext from '../../../../context/BrandContext';
import styles from "../ConnectToBank.module.css"
import {
    BankFeedInterstitial,
    CombinedOpenBankingAccountsResponse,
    CreatedBookkeepingAccountResponse,
    ImportedOpenBankingAccount,
    ImportOpenBankingAccountRequest,
    OBAuthorisationRequest,
    OpenBankingAccount
} from "../../../../api/inni/data-contracts";
import * as DataGrid from "../../../../elements/DataGrid/DataGrid";
import {Button} from "../../../../elements/Button/Button";
import CompanyContext from "../../../../context/CompanyContext";
import {useModalDialog} from "../../../../hooks/useModalDialog";
import {formatDate} from "../../../../utils/formatDate";
import SelectedBank from "./SelectedBank";
import {useNavigateToEntity} from "../../../../hooks/useNavigateToEntity";
import {addDays} from "date-fns";
import {Action, Entity} from "../../../../utils/EntityAction";
import { useHasPermission } from '../../../../hooks/useHasPermission';

interface SelectedBankUnimportedAccountsProps {
    companyId: number;
    bankName: string;
    onCancelClick: () => void;
    requestModel: OBAuthorisationRequest;
    overrideConnection: boolean;
    motd: string | undefined;
    combinedOpenBankingAccounts: CombinedOpenBankingAccountsResponse | undefined;
    changeTitle: (title: string) => void;
    bankFeedMessage:BankFeedInterstitial|undefined;
}

/* Importing additional accounts for an existing authorized consent which were not previously imported when this consent was setup
* @param companyId number
* @param bankName name of bank importing account for
* @param onCancelClick cancel method
* @param requestModel used when navigating to the bank as this consent might not have the account the client wants
* @param overrideConnection for banks where PV goes through iA e.g. Wise
* @param motd bank feed message if there is one
* @param combinedOpenBankingAccounts - consent to import from and a list of unimported accounts
 */
export default function SelectedBankUnimportedAccounts({companyId, bankName, onCancelClick, requestModel, overrideConnection, motd, combinedOpenBankingAccounts, changeTitle, bankFeedMessage}: SelectedBankUnimportedAccountsProps) {
    const companyContext = useContext(CompanyContext);
    const consentId: number = combinedOpenBankingAccounts?.openBankingConsentId!;
    const consentExpiresAt: string = combinedOpenBankingAccounts?.consentExpiresAt!;
    const openBankingAccountsAPI = useInniAPI(OpenBankingAccounts);
    const [accountIdsToImport, setAccountIdsToImport] = useState<string[]>([]);
    const [createdBookkeepingAccounts, setCreatedBookkeepingAccounts] = useState<CreatedBookkeepingAccountResponse[]>([]);
    const [isImportCompleted, setIsImportCompleted] = useState<boolean>(false);
    const [isGoToBank, setIsGoToBank] = useState<boolean>(false);
    const [showModalDialog, modalDialog] = useModalDialog();
    const navigateToEntity = useNavigateToEntity()
    const brandContext = useContext(BrandContext)
    const hasPermission = useHasPermission()
    const useV8 = hasPermission(Entity.BookkeepingV8, Action.Create)[0]

    useEffect(()=> {

        if (isImportCompleted) {
            changeTitle("Import complete")
        } else {
            changeTitle("Import accounts")
        }

        if (isGoToBank) {
            changeTitle("")
        }

    }, [isImportCompleted, isGoToBank]);

    /*
    * Called when user clicks on a row in order to toggle the account selector checkbox
     */
    const handleOnChange = (accountId: string) => {
        if (accountId && accountIdsToImport) {
            let importAccountIds: string[] = [...accountIdsToImport];

            if (importAccountIds && importAccountIds.includes(accountId)) {
                importAccountIds = importAccountIds.filter(x => x !== accountId);
            } else {
                importAccountIds = [...importAccountIds, accountId];
            }

            setAccountIdsToImport(importAccountIds);
        }
    }

    const toggleCheckbox = (accountId: string)=> {
        if (!accountId || !accountIdsToImport) {
            return false;
        } else if (accountId && accountIdsToImport.find((x) => x === accountId)) {
            return true
        }
    }

    /*
    * Called when user has selected an account and clicked import.
    * Using the id(s) of the chosen account(s) the open banking accounts are then imported
    * and a bookkeeping account is created for each one and they are linked together
     */
    const importOnClick = () => {
        if (openBankingAccountsAPI && accountIdsToImport.length > 0) {

            let params: ImportOpenBankingAccountRequest = {
                openBankingConsentId: consentId,
                institutionAccountIds: accountIdsToImport
            };

            openBankingAccountsAPI.importOpenBankingAccount(companyContext.cid, params)
                .then((res) => {
                    if (res.data && res.data.importedOpenBankingAccounts) {
                        let errorImportedAccounts: ImportedOpenBankingAccount[] = [];

                        let promises = res.data.importedOpenBankingAccounts.map(x =>
                            openBankingAccountsAPI.createBookkeepingAccount(companyContext.cid, {openBankingAccountId: x.openBankingAccountId})
                                .then((res) => {
                                    setCreatedBookkeepingAccounts(prev => [...prev, res.data]);
                                })
                                .catch((error) => {
                                    errorImportedAccounts.push(x);
                                })
                        );

                        Promise.all(promises)
                            .then(() => {
                                if (errorImportedAccounts.length > 0) {
                                    showModalDialog('Bookkeeping account creation failed',
                                        <div>
                                            {(errorImportedAccounts.length === accountIdsToImport.length) ?
                                                <p>An error occurred while creating bookkeeping accounts for the
                                                    following</p>
                                                :
                                                <p>An error occurred while creating bookkeeping accounts for the below,
                                                    remaining bookkeeping accounts created successfully.</p>
                                            }

                                            <ul>
                                                {errorImportedAccounts.map(account => (
                                                    <li key={account.openBankingAccountInstitutionId}>
                                                        {account.openBankingAccountInstitutionId}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>,
                                        [
                                            <Button
                                                variant="secondary"
                                                label="Ok"
                                                onClick={() => (errorImportedAccounts.length === accountIdsToImport.length) ?
                                                    {}
                                                    :
                                                    setIsImportCompleted(true)
                                                }
                                            />
                                        ],
                                        false
                                    );
                                } else {
                                    setIsImportCompleted(true);
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                })
                .catch((error) => {
                    showModalDialog(
                        'Import failed',
                        `An error occurred while importing the accounts, Please try again later or click go to bank.`,
                        [
                            <Button variant="secondary" label="Ok" onClick={() => {
                            }}/>
                        ],
                        false
                    );
                    console.error(error);
                });
        }
    }

    const goToBankOnClick = () => {
        setIsGoToBank(true);
    }

    const cancelGoToBankOnClick = () => {
        setIsGoToBank(false);
    }

    return (
        <>
            {(!isImportCompleted && !isGoToBank) &&
                <div>
                    <p>
                        You have already consented access to some accounts at {bankName} but haven't imported them. Select accounts to import
                        below or click "Go to bank" if you want to import an account not shown below.
                    </p>
                    <div style={{maxWidth: "1440px"}}>
                        <DataGrid.Table>
                            <thead>
                            <tr>
                                <th>Select</th>
                                <th>Account name</th>
                                <th>Nickname</th>
                                <th>Currency</th>
                                <th>Account number</th>
                                <th>Sort code</th>
                                <th>IBAN</th>
                                <th>Account type</th>
                                <th>Usage type</th>
                            </tr>
                            </thead>
                            <tbody>
                            <>
                                {
                                    combinedOpenBankingAccounts?.unimportedAccounts?.map((account) => {
                                        return (

                                            <tr
                                                key={account.institutionOpenBankingAccountId}
                                                onClick={() => handleOnChange(account.institutionOpenBankingAccountId!)}>

                                                <td style={{alignItems: "center"}}>
                                                    <input
                                                        key={account.institutionOpenBankingAccountId}
                                                        type="checkbox"
                                                        checked={toggleCheckbox(account.institutionOpenBankingAccountId!)}
                                                        onChange={() => handleOnChange(account.institutionOpenBankingAccountId!)}
                                                    />
                                                </td>
                                                <td>{account.name}</td>
                                                <td>{account.nickname}</td>
                                                <td>{account.currency}</td>
                                                <td>{account.accountNumber}</td>
                                                <td>{account.sortCode}</td>
                                                <td>{account.iban}</td>
                                                <td>{account.accountType}</td>
                                                <td>{account.usageType}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </>
                            </tbody>
                        </DataGrid.Table>
                    </div>

                    <div className={styles.footer}>
                        <div>
                            <Button
                                buttonType="import"
                                label="Next"
                                onClick={importOnClick}
                                variant="primary"
                                disabled={accountIdsToImport.length < 1}
                                marginTop={true}
                            />
                            <Button
                                label="Go to bank"
                                variant="primary"
                                onClick={goToBankOnClick}
                                marginTop={true}
                            />
                        </div>
                        <p className={styles.linkText} onClick={() => onCancelClick()}>Cancel</p>
                    </div>

                </div>
            }

            {(isImportCompleted && !isGoToBank) &&
                <>
                    <div>
                        <p><b>{createdBookkeepingAccounts.length === 1 ? "Your account has been created." : "Your accounts have been created."}</b></p>

                        <p>The following {createdBookkeepingAccounts.length === 1 ? "account has" : "accounts have"} been created and linked to {bankName} through Open Banking.</p>
                        <ul>
                            {createdBookkeepingAccounts && createdBookkeepingAccounts.map((account) => {
                                return (
                                    <li key={account.bookkeepingAccountId}>
                                        {account.name}
                                    </li>
                                )
                            })}
                        </ul>

                        <p>
                            In order to keep your transaction data flowing through to {brandContext.brand.name}, you are
                            required to reauthorise every 90 days due to SCA requirements. You will be able to do this
                            from the Finances section. Your next reauthorisation date
                            is: {consentExpiresAt ? formatDate(consentExpiresAt) : addDays(new Date(), 90).toDateString()}.
                        </p>
                    </div>

                    <div style={{float: "left"}}>
                        {useV8 ?
                        <Button variant='primary' onClick={() => navigateToEntity(Entity.BookkeepingV8)} label='Go to accounts' marginTop />
                        :
                        <Button variant='primary' onClick={() => navigateToEntity(Entity.CompanyDashboard)} label='Go to dashboard' marginTop />
                        }
                    </div>
                </>
            }

            {(isGoToBank && !isImportCompleted) &&
                <SelectedBank
                    companyId={companyId}
                    name={bankName}
                    onClick={cancelGoToBankOnClick}
                    requestModel={requestModel}
                    overrideConnection={overrideConnection}
                    motd={motd}
                    bankFeedMessage={bankFeedMessage}
                />
            }
        </>
    );
}