import { PvSatrDividend } from "../../../../api/inni/data-contracts";
import FormikField from "../../../../elements/FormikField/FormikField";
import { Summary } from "../../../../elements/Summary/Summary";
import styles from '../Income.module.scss'
import { Button } from "../../../../elements/Button/Button";

interface DividendProps{
    remove:() => void;
    index: number
    showDeleteDialog: (handleOnClickDelete: () => void) => void
}

export default function Dividends({remove, index, showDeleteDialog}:DividendProps){

    const handleOnClickDelete =() =>{
        showDeleteDialog(remove)
    }

    return(
        <Summary 
            useFullWidth
            showClose
            onClose={handleOnClickDelete}
            stackedContent
        >
                <h5 className="mb-3">{`Dividend ${index+1}`}</h5>
                <FormikField<PvSatrDividend> 
                    className={styles.formikField}
                    name={`dividends.${index}.name` as "name"}
                    label="Issuer name"
                    type="text"                                        
                />
                <FormikField<PvSatrDividend> 
                    className={styles.formikNumberField}
                    name={`dividends.${index}.grossAmount` as "grossAmount"}
                    label="Gross amount received"
                    type="number" 
                    prefix="£"
                    step="0.01"
                    min="0"                                        
                    onlyPositiveNumbers                                       
                />
                <br />
                <FormikField<PvSatrDividend> 
                    className={styles.formikNumberField}
                    name={`dividends.${index}.taxAlreadyDeducted` as "taxAlreadyDeducted"}
                    label="Tax already deducted"
                    type="number"
                    prefix="£"
                    step="0.01"
                    min="0"                                        
                    onlyPositiveNumbers
                />
                <br />
                <FormikField<PvSatrDividend> 
                    className={styles.formikField}
                    name={`employments.${index}.notes` as "notes"}
                    label="Please let us know if there's anything else you think we should know about this dividend payment"
                    type="textarea" 
                    wrapLabel                                   
                />

                <Button buttonType="delete" label="Delete" onClick={handleOnClickDelete} marginTop />
        </Summary>
    );
}
