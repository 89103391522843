import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserCircle, faGear, faPaperPlane, faStar } from '@fortawesome/pro-solid-svg-icons';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import React, { useContext, useEffect } from 'react';
import UserContext from '../../context/UserContext';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { useOnboardingStory } from '../../hooks/useOnboardingStory';
import NotifyUpdateModal from '../../elements/Storyblok/modals/NotifyUpdateModal';
import OnboardingContext, {OnboardingContext as IOnboardingContext} from '../../context/OnboardingContext';
import { Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Entity } from '../../utils/EntityAction';
library.add(...([faUserCircle, faGear, faPaperPlane, faStar] as any));

const Onboarding = () => {
	const {
		initialData,
		story,
		notifyOfCompanyUpdate,
		onNavItemSelect,
		selectedUid,
		selectedPageUrl,
		pageStory,
		toggleNotifyUsModal,
		showNotifyModal,
		setUtr,
		utr,
		alertMessage,
		onClickAction,
		setCurrentPageCompletionStatus,
		showStatusLine,
		errorMessage,
		isSubmitted,
		financeTableFormik,
		setNotifyAccountantsMessage,
		notifyAccountantsMessage,
		uploadTableFinanceFormik,
		uploadMortgageDocumentsFormik,
		soldPropertiesFormik,
		ownedPropertiesFormik,
		showExcept,
		uploadCVSFormik,
		setAlertMessage,
		onIncompletePage,
	} = useOnboardingStory();
	const { user } = useContext(UserContext);
	
	const providerConfig: IOnboardingContext = {
		onNavItemSelect,
		selectedUid,
		selectedPageUrl,
		onChangeUtr: (value: string) => setUtr(value),
		utr,
		onClickAction,
		setCurrentPageCompletionStatus,
		showStatusLine,
		errorMessage,
		isSubmitted,
		financeTableFormik,
		notifyAccountantsMessage,
		onChangeNotifyAccountantMessage: (value: string) => setNotifyAccountantsMessage(value),
		uploadTableFinanceFormik,
		uploadMortgageDocumentsFormik,
		soldPropertiesFormik,
		ownedPropertiesFormik,
		showExcept,
		uploadCVSFormik,
		onIncompletePage,
	};

	useEffect(() => {
		let id: NodeJS.Timeout;
		if (alertMessage) {
			id = setTimeout(() => setAlertMessage(''), 5000)
		}
		return () => clearTimeout(id)
	}, [alertMessage, setAlertMessage])
	
	return (
		<>
			<DefaultLayout
				noPadding
				title={`Welcome to Provestor, ${user?.firstName}`}
				loading={!story || !story?.content}
				entity={Entity.Onboarding}
			>
				<OnboardingContext.Provider value={providerConfig}>
					{story && story?.content && (
						<StoryblokComponent
							blok={story?.content as SbBlokData}
							initialData={initialData}
							pageStory={pageStory}
						/>
					)}
				</OnboardingContext.Provider>
			</DefaultLayout>
			{showNotifyModal && <NotifyUpdateModal hide={toggleNotifyUsModal} create={notifyOfCompanyUpdate} />}
			{alertMessage && (
				<Alert
					show={alertMessage.length > 0}
					style={{ position: 'fixed', bottom: 0, width: '90%', left: '5%' }}
					variant="success"
					dismissible
					onClose={() => setAlertMessage('')}
				>
					<FontAwesomeIcon icon={faCheck} style={{ marginRight: '15px' }} />
					{alertMessage}
				</Alert>
			)}
		</>
	);
};

export default Onboarding;
