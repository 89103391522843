import { useContext } from 'react'
import { Accounts } from '../../../../api/inni/Accounts'
import { Account } from '../../../../api/inni/data-contracts'
import { PersonAsDetailed } from '../../../../api/inni/data-contracts'
import { LoadingDetail } from '../../../../elements/DetailRow/DetailRow'
import InfoBanner from '../../../../elements/InfoBanner/InfoBanner'
import { useFetchEntityList } from '../../../../hooks/entities/useFetchEntityList'
import { TabEditProps } from '../../People'
import DetailsEdit from './DetailsEdit'
import DetailsView from './DetailsView'
import { useHasPermission } from '../../../../hooks/useHasPermission'
import { Entity, Action } from '../../../../utils/EntityAction'
import CompanyContext, { CompanyProduct } from '../../../../context/CompanyContext'
import { Button } from '../../../../elements/Button/Button';

interface PersonDetailsTabProps {
    person: PersonAsDetailed | undefined,
    loaded: boolean,
    defaultName: string | undefined,
    reloadPeople: () => void,
    isCorporate: boolean,
    showInvalidSharesBanner: boolean,
    showAutoEnrollExempt: boolean,
    setDisabled: (disabled: boolean) => void,
    showAdminManagedMessage?: boolean
}

const LoadingDetails = ({ isCorporate }: { isCorporate: boolean }) => (
    <>
        <LoadingDetail editStyling name='title' />
        <LoadingDetail editStyling name='forenames' />
        <LoadingDetail editStyling name='surname' />
        <LoadingDetail editStyling name='address' />
        {/* <LoadingDetail editStyling name='gender' /> */}
        <LoadingDetail editStyling label="Date of birth" name='dob' />
        <LoadingDetail editStyling name='nationality' />
        <LoadingDetail editStyling name='taxRegime' />
        <LoadingDetail editStyling name='utr' />
        {isCorporate && <>
            <LoadingDetail editStyling name='jobTitle' />
            <LoadingDetail editStyling name="repayMileageDirectlyToAccountId" label="Repay mileage" />
            <LoadingDetail editStyling name='shares' />
            <LoadingDetail editStyling label="Director" name='isDirector' />
            <LoadingDetail editStyling label="Corporate entity" name='isCorporateEntity' />
            <LoadingDetail editStyling name='directorAppointmentDate' label="Appointment date" />
            {/* Add this as we don't show it on the list anymore */}
            <LoadingDetail editStyling label="Employee" name='isActiveEmployee' />
            <LoadingDetail editStyling label="Shareholder" name='isShareholder' />
            <LoadingDetail editStyling label="Is spouse" name='isSpouse' />
        </>}
    </>
)

const PersonDetailsTab = ({ person, loaded, defaultName, reloadPeople, isCorporate, showInvalidSharesBanner, showAutoEnrollExempt, setDisabled,editing, setEditing, showAdminManagedMessage } : PersonDetailsTabProps & TabEditProps) => {

    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts)

    const hasAMSupport = useHasPermission()(Entity.AccountManagerSupport, Action.Read)[0]
    const hasMileage = useHasPermission()(Entity.Mileage, Action.All)[0]
    const simpleSharesOnly = !useHasPermission()(Entity.AlphabetShares, Action.Read)[0]
    const companyContext = useContext(CompanyContext);
    const v8Styling = companyContext.company?.useV8UI;

    return (<>
        {/* The placement of these banners is a bit strange - they are company wide, but w/o the first page there is no where else to put them really */}
        {/* Do we not show this on the default employee and/or his/her spouse, we could BUT this warning might be useful so they know there is some error */}
        {showInvalidSharesBanner &&
            <InfoBanner
                type="admin"
                title="There are one or more people with invalid shares"
                body="There are one or more people with shares when they are not recorded as default employee or spouse. Please reconcile."
            />
        }
        {showAutoEnrollExempt && isCorporate &&
            <InfoBanner
                type="admin"
                title="Your company is currently exempt from pension auto-enrolment"
                body="You have declared that your company is exempt from pension auto-enrolment. 
                Any changes to the number of employees and or wages may require this exemption to be re-evaluated against auto-enrolment criteria"
            />
        }
        {showAdminManagedMessage && <InfoBanner
            type="info"
            title="Is everything up to date?"
            body={`This is the information we currently hold for ${person?.forenames}. Please contact your account manager if you need to change anything.`}
        />}
        { (person && loaded && accountsLoaded)
            ? !editing
                ? (
                    <>
                        <DetailsView
                            accounts={accounts}
                            isCorporate={isCorporate}
                            person={person}
                            hasMileage={hasMileage}
                            simpleSharesOnly={simpleSharesOnly}
                            v8Styling={!!v8Styling}
                        />
                        <Button
                            buttonType="edit"
                            entity={Entity.PersonV7}
                            action={Action.Edit}
                            onClick={() => setEditing(true)}
                            disabled={editing}
                            marginTop
                        />
                    </>
                )
                : <DetailsEdit
                    setDisabled={setDisabled}
                    person={person}
                    reloadPeople={reloadPeople}
                    setEditing={setEditing}
                    isCorporate={isCorporate}
                    defaultName={defaultName || ''}
                    accounts={accounts}
                    hasMileage={hasMileage}
                    simpleSharesOnly={simpleSharesOnly}
                    v8Styling={!!v8Styling }
                />
            : <LoadingDetails isCorporate={isCorporate} />
        }
    </>)
}

export default PersonDetailsTab