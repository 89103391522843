import AgentStatements from '../../features/Invoices/AgentStatements/AgentStatements';
import CreateLettingStatement from '../../features/Invoices/AgentStatements/Components/CreateLettingStatement';
import ReadAgentStatements from '../../features/Invoices/AgentStatements/ReadAgentStatements';
import { Entity, Action } from '../../utils/EntityAction';
import { EntityRoute, RouteContext } from './routeTypes';

const lettingstatements:Array<EntityRoute> = [
    {
        path: '/company/:cid/lettingstatements/',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.LettingStatement,
        component:AgentStatements
    },
    {
        path: '/company/:cid/lettingstatements/edit/:id',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.LettingStatement,
        action: Action.Edit,
        component: CreateLettingStatement
    }
    ,
    {
        path: '/company/:cid/lettingstatements/:id/:fromReminder?',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.LettingStatement,
        action: Action.Read,
        component:ReadAgentStatements
    }
]
export default lettingstatements;