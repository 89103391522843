import { Account, PersonAsDetailed } from '../../../../api/inni/data-contracts'
import { Date, Text, YesNo } from '../../../../elements/DetailRow/DetailRow'
import { nationalityCodes } from '../../../../utils/commonLists'

const DetailsView = ({ person, isCorporate, hasMileage, simpleSharesOnly, accounts, v8Styling }: 
    { person: PersonAsDetailed, isCorporate: boolean, hasMileage: boolean, simpleSharesOnly: boolean, accounts: Account[], v8Styling: boolean }) => {

    //In this case v8Styling and isSaas are used independently so a bit more complex.
    //    e.g. isCorporateEntity shouldn't be driven by v8u
    // Wrong, should be... OR maybe th eprop is v8Styling?
    //TODO

    const formatRepayMileage = (val: string | undefined) => {
        const account = accounts.find(x => x.id === parseInt(val || ''))
        if (!person.repayMileageDirectly)
            return 'Mileage paid on payslips'
        else if (!person.repayMileageDirectlyToAccountId)
            return 'Mileage paid manually using pending payment'
        return `Mileage automatically transferred to account ${account ? `"${account.name}"` : "(Account not found)"}`
    }

    const showField = (fieldName: string) => {
        if (person && person.editability && person.editability.readOnlyFields) {
            if (fieldName in person.editability.readOnlyFields) {
                return false ;
            }
        }
        return true;
    }

    return (
        <>
            <Text fixedWidth={v8Styling} ensureRender entity={person} name='title' />
            <Text fixedWidth={v8Styling} ensureRender entity={person} name='forenames' />
            <Text fixedWidth={v8Styling} ensureRender entity={person} name='surname' />
            <Text
                fixedWidth={v8Styling}
                ensureRender
                entity={person}
                name='address'
                valueFormatter={(i) => i?.replaceAll(', ', '\n') + `\n${person.overseas ? person.country : person.postcode}`}
            />
            { !v8Styling && 
                <Text
                    fixedWidth={v8Styling}
                    ensureRender
                    entity={person}
                    name='gender'
                    valueFormatter={(i) => (i || '').charAt(0).toUpperCase() + i?.slice(1)}
                />
            }
            <Date fixedWidth={v8Styling} ensureRender entity={person} label="Date of birth" name='dob' />
            <Text
                fixedWidth={v8Styling}
                ensureRender
                entity={person}
                name='nationality'
                valueFormatter={(i) => nationalityCodes.find(x => x.code === i)?.name || ''}
            />
            <Text fixedWidth={v8Styling} ensureRender entity={person} name='taxRegimeText' label="Tax regime" />
            <Text fixedWidth={v8Styling} ensureRender entity={person} name='utr' label="UTR" />
            {isCorporate && <>
                <Text fixedWidth={v8Styling} ensureRender entity={person} name='jobTitle' />
                {hasMileage && <Text fixedWidth={v8Styling} ensureRender entity={person} name="repayMileageDirectlyToAccountId"
                    valueFormatter={formatRepayMileage}
                    label="Repay mileage"
                />}
                {simpleSharesOnly && !v8Styling && <Text fixedWidth={v8Styling} ensureRender entity={person} name='shares' />}
                <YesNo fixedWidth={v8Styling} ensureRender entity={person} label="Director" name='isDirector' />
                {showField("isCorporateEntity") && <YesNo fixedWidth={v8Styling} ensureRender entity={person} label="Corporate entity" name='isCorporateEntity' />}
                <Date fixedWidth={v8Styling} ensureRender entity={person} name='directorAppointmentDate' label="Appointment date" />
                {/* Add this as we don't show it on the list anymore */}
                { showField("isActiveEmployee") && <YesNo fixedWidth={v8Styling} ensureRender entity={person} label="Employee" name='isActiveEmployee' />}
                { (!v8Styling && showField("isShareholder")) && <YesNo fixedWidth={v8Styling} ensureRender entity={person} label="Shareholder" name='isShareholder' /> }
                {showField("isSpouse") && <YesNo fixedWidth={v8Styling} ensureRender entity={person} label="Is spouse" name='isSpouse' />}
            </>}
        </>
    )
}

export default DetailsView