import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from '../Button/Button';
type Props = {
	id: string;
	name: string;
	onClick: () => void;
	fileName: string;
	showNewFile: boolean;
	showDeleteBtn?: boolean;
	onDelete?: () => void;
};
const StandardFileField = ({ id, name, onClick, fileName, showNewFile, showDeleteBtn = false, onDelete }: Props) => {
	const getFileName = (fileName: string) =>
		fileName
			.split('/')
			.splice(fileName.split('/').length - 1)
			.join('');
	return (
		<div className="d-flex justify-content-between align-items-center">
			<input
				id={id}
				type="file"
				name={name}
				onClick={(e) => {
					onClick();
					e.preventDefault();
				}}
				className="d-none"
			/>
			<label className="m-0 w-100" htmlFor={id} style={{cursor: 'pointer'}}>
				<Button  buttonType="upload" variant='secondary' label='Browse' small pointerEventOff />
				{getFileName(fileName) || 'No file selected'}
				{showNewFile && <FontAwesomeIcon className="ml-2" icon={faCloudUpload} />}
			</label>
			{showDeleteBtn && onDelete && <Button small iconOnly buttonType="delete" onClick={onDelete} />}
		</div>
	);
};

export default StandardFileField;
