import { FormikValues } from 'formik';
import { TaxCodePostModel } from '../../../../api/inni/data-contracts';
import { FormikRowInput } from '../../../../elements/FormikTableEditor/FormikTableEditor';
import { asYearMonthDay, formatDate } from '../../../../utils/formatDate';

const TaxCodeEditRow = ({formik} : {formik : FormikValues}) => (
    <tr>
        <td style={{verticalAlign: 'middle'}}>
            {formatDate(asYearMonthDay(new Date()))} 
        </td>
        <td style={{verticalAlign: 'top'}}>
            <FormikRowInput<TaxCodePostModel> formik={formik} property="effectiveDate" type="date" />
        </td>
        <td style={{verticalAlign: 'top'}}>
            <FormikRowInput<TaxCodePostModel> formik={formik} property="code" type="text" />
        </td>
        <td style={{verticalAlign: 'middle'}}>
            <FormikRowInput<TaxCodePostModel> formik={formik} property="taxCode1WM" type="check" />
        </td>
        <td></td>
    </tr>
)

export default TaxCodeEditRow