import { useContext, useEffect, useState } from "react"
import { Adjustments } from "../../api/inni/Adjustments"
import { AdjustmentSummary } from "../../api/inni/data-contracts"
import CompanyContext, { CompanyProduct } from "../../context/CompanyContext"
import { Button } from "../../elements/Button/Button"
import { useInniAPI } from "../../hooks/useInniAPI"
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout"
import Toolbar from "../../layouts/Desktop/Toolbar"
import { Action, Entity } from "../../utils/EntityAction"
import * as DataGrid from '../../elements/DataGrid/DataGrid';
import { formatCurrency } from "../../utils/formatNumbers"
import { formatDate } from "../../utils/formatDate"
import { NoContentSlate } from '../../elements/Slates/NoContentSlate';
import { DatagridType } from "../../hooks/terms/useDatagridTerms"
import InfoBanner from "../../elements/InfoBanner/InfoBanner"
import BlankStateCard from "../../components/BlankStateCard/BlankStateCard"
import { SbBlokData, getStoryblokApi } from "@storyblok/react"

export interface ListPageProps {
    onNewRow: () => void,
    onEditRow: (id:number) => void
}

export const AdjustmentListPage = ({onNewRow, onEditRow} : ListPageProps) => {
    const adjustmentsApi = useInniAPI(Adjustments, [400])
    const companyContext = useContext(CompanyContext)

    const [adjustmentsData, setAdjustmentsData] = useState<AdjustmentSummary[]>()
    const [adjustmentsLoaded, setAdjustmentsLoaded] = useState(false);
    const [blankSlateInfo, setBlankSlateInfo]= useState<SbBlokData>();
    const v8Styling = companyContext.company?.useV8UI || false;
    
    useEffect(() => {
        if (!adjustmentsLoaded && adjustmentsApi) {
            adjustmentsApi.index(companyContext.cid).then(
                response => {
                    setAdjustmentsData(response.data);
                    setAdjustmentsLoaded(true);
                }
            )
        }
    }, [companyContext.cid, adjustmentsApi, adjustmentsLoaded])

    useEffect(()=>{
        const storyblokApi = getStoryblokApi();
        storyblokApi.get('cdn/stories/blank-slate/adjustments', { version: 'published'})
        .then(res => {
            setBlankSlateInfo(res.data.story.content)
        }).catch(err => {
            console.error(err)
        });
    },[])

    return (
        <DefaultLayout
            title="Adjustments"
            entity={Entity.AdjustmentV7}
            subLayout={v8Styling}
            greyBackground
        >
            { (!v8Styling || (adjustmentsLoaded && adjustmentsData && adjustmentsData?.length > 0)) &&
                <Toolbar>
                    <Button
                        entity={Entity.AdjustmentV7}
                        action={Action.Edit}
                        buttonType="new"
                        onClick={onNewRow}
                        headerBtn={v8Styling}
                    />
                </Toolbar>
            }

            { !v8Styling && adjustmentsLoaded && (
                <>
                    <InfoBanner
                        title="Use with care"
                        body={
                            <>
                                Adjustments are used by your accountant to make changes to the way things have been recorded. E.g. to correct mistakes or change something in light of new information when they prepare your year-end accounts. It is important to handle adjustments with care.
                            </>
                        }
                        type="info"
                        noTopMargin
                    />
                </> 
            )}

            {v8Styling && adjustmentsLoaded && adjustmentsData?.length===0 &&
                <BlankStateCard 
                    content={blankSlateInfo} 
                    onButtonClick={onNewRow} 
                    loading={!blankSlateInfo}
                    buttonEntity={Entity.AdjustmentV7}
                    buttonAction={Action.Create}
                />
            }

            { (!(adjustmentsLoaded && adjustmentsData?.length===0) || !v8Styling )&&

                <DataGrid.Table>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Description</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!adjustmentsLoaded && 
                            <>
                                <DataGrid.LoadingRow cols={3}  />
                                <DataGrid.LoadingRow cols={3}  />
                                <DataGrid.LoadingRow cols={3}  />
                            </>
                        }
                        { adjustmentsLoaded && adjustmentsData && adjustmentsData.length === 0 && 
                            <tr>
                                <td colSpan={3} style={{ padding: 0, cursor: "default" }}>
                                    <NoContentSlate type={DatagridType.ManualAdjustments} termsKey='emptyTerms' /> 
                                </td>
                            </tr>
                        }
                        {adjustmentsLoaded && adjustmentsData && adjustmentsData.length > 0 && adjustmentsData.sort((a,b) => new Date(a.date) > new Date(b.date) ? 1 : -1).map((adj, index) => <tr key={index} onClick={() => onEditRow(adj.journalHeaderId)}>
                            <td>{formatDate(adj.date)}</td>
                            <td>{adj.description}</td>
                            <td>{formatCurrency(adj.amount)}</td>
                        </tr>)}
                    </tbody>
                </DataGrid.Table>
            }
        </DefaultLayout>
    )
}