import React from 'react';
import { CompanySummary, NavigationModel, TermsCollection } from '../api/inni/data-contracts';
import { Entity } from '../utils/EntityAction';
import { Feature } from '../utils/Feature';

export interface CompanyContextInterface {
    cid: number,
    company?: CompanySummary,
    features?: Array<Feature>,
    terms?: TermsCollection,
    product?: CompanyProduct,
    baseProductName?: string,       // use for app insights
    baseProductCategory?: string,   // use for app insights
    /** For nav bar */
    activeEntity?: Entity,
    setActiveEntity?: (entity: Entity|undefined) => void,
    navMenu?: NavigationModel
    reloadMenu?: () => void,
}

const CompanyContext = React.createContext<CompanyContextInterface>({cid: 0});

export default CompanyContext;

export enum CompanyProduct {
    isManaged,
    isCap,
}