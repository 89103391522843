import { add, isAfter } from "date-fns"
import styles from '../Dashboard.module.scss'

export type DashboardStatus = 'Overdue' | 'DueSoonUrgent' | 'DueSoon' | 'Upcoming'

const StatusIcon = ({status, dueDate} : {status : DashboardStatus, dueDate?: Date}) => {

    const color = status === 'Overdue' && (!dueDate || isAfter(add(new Date(), { days: -1}), dueDate))  ? '#d00' : 
        status === 'DueSoon' || status === 'DueSoonUrgent' ? 'var(--yellow-dark)' : 'var(--reminder-upcoming)'

    return (
        <div style={{ background: color }} className={styles.statusIcon}></div>
    )

}

export default StatusIcon