import React, { ReactNode, Children } from 'react';
import styles from './Summary.module.css';
import classNames from 'classnames';
import Icon from '../Icon/Icon';

interface SummaryProps {
  children: ReactNode;
  useFullWidth?: boolean
  showClose?: boolean
  onClose?: () =>void
  stackedContent?: boolean
}

export const Summary: React.FC<SummaryProps> = ({ children, useFullWidth, showClose, onClose, stackedContent }) => {
  const childElements = Children.toArray(children);

  const handleOnClickClose =(e: React.MouseEvent<HTMLElement>) =>{
    e.preventDefault();
    if(onClose)
      onClose();
  }

  return (
    <div id={styles.summary} className={classNames({[styles.fullScreen]: useFullWidth, "mb-5": !stackedContent, "mb-4": stackedContent} )}>
      {showClose &&
        <button className={`${styles.closeBtn}`} onClick={handleOnClickClose}>
            <Icon size='small' name='close'/>
        </button>
      }
      
      { childElements }
    </div>
  );
};
