export const getViewButtonLabel = (type:string) : string => {
    switch(type.toUpperCase()) {
        case "PS": return 'View payslip'
        case "VR": return 'View VAT return'
        case "CN":
        case "IP":
        case "SI": 
            return 'View invoice'
        case "PR": return 'View PAYE Payment'
        case "CT": return 'View corporation tax'
        case "EX": return 'View expenses'
        case "DP": return 'View dividend'
        default: return "View"
    }
    
}