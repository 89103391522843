import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { useParams } from "react-router-dom";
import { Account, ClassifyTranPostModel, Contract, ImportedTransactionPostModel } from "../../api/inni/data-contracts";
import { ExpensesImport } from "../../api/inni/ExpensesImport";
import CompanyContext from "../../context/CompanyContext";
import { Button } from "../../elements/Button/Button";
import { Table } from "../../elements/DataGrid/DataGrid";
import { useInniAPI } from "../../hooks/useInniAPI";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout";
import { Action, Entity } from "../../utils/EntityAction";
import { Modal } from '../../components/Modal/Modal';
import styles from './expenseImport.module.css'
import { faArrowDownToSquare, faCheck, faCircleCheck, faPencil, 
    faRotate, faUpload, faCloudArrowUp, faCloudXmark } from "@fortawesome/pro-regular-svg-icons";
import { useFetchEntityList } from "../../hooks/entities/useFetchEntityList";
import { Contracts } from "../../api/inni/Contracts";
import { Accounts } from "../../api/inni/Accounts";
import { Expenses } from "../../api/inni/Expenses";
import { Formik, FieldArray, Form, FormikHelpers, Field, FieldProps} from 'formik';
import { accountsExpenditure } from "../../utils/accountsFilterHelper";
import { Alert, Badge } from "react-bootstrap";
import editRowStyles from '../../elements/EditRow/EditRow.module.css';
import { Form as ReactForm } from 'react-bootstrap';
import { asHtml5Date } from "../../utils/formatDate";
import Select from 'react-select';
import { GroupedListOption, ListOption } from "../../elements/EditRow/EditRow";
import { formatSubGroup } from "../../utils/formatters/formatAccount";
import _ from "lodash";
import classNames from "classnames";
import { useHasPermission } from "../../hooks/useHasPermission";

interface RouteParams {
    accountId: string
}

interface BulkEditExpenseValues extends ImportedTransactionPostModel {
    nullBillable: boolean|null //Yes/No/Unset
}

interface ExpenseImportFormikWrapper {
    expenses: ImportedTransactionPostModel[];
    bulkEditExpense: BulkEditExpenseValues;
}

const ExpenseImport = () => {
    const companyContext = useContext(CompanyContext)
    const expenseImportAPI = useInniAPI(ExpensesImport, [400]);
    const expensesAPI = useInniAPI(Expenses)
    const accountId = parseInt(useParams<RouteParams>().accountId);
    const hasPermission = useHasPermission();
    const hasAMSupport = hasPermission(Entity.AccountManagerSupport, Action.All)[0]

    const [contracts, contractsLoaded] = useFetchEntityList<Contract, Contracts>(Contracts)
    const [accounts, accountsLoaded] = useFetchEntityList<Account, Accounts>(Accounts)
    const [contractsDD, setContractsDD] = useState<ListOption[]>([])
    const [accountsDD, setAccountsDD] = useState<GroupedListOption[]>([])
    
    const [vatRates, setVatRates] = useState<ListOption[]>([])
    const [vatRatesLoaded, setVatRatesLoaded] = useState(false)

    const [uploadingCSV, setUploadingCSV] = useState(false)
    const [processingCSV, setProcessingCSV] = useState(false)
    const [CSVProcessingFailed, setCSVProcessingFailed] = useState(false)
    const [offerTxnFlip, setOfferTxnFlip] = useState(false)

    const initialBEExpense:BulkEditExpenseValues = {accountId: 0, datePosted: '', description: '', amount: 0, billable: false, nullBillable: null, vatCode: '', contractId: 0}
    const [showBulkEditRow, setShowBulkEditRow] = useState(false)
    const [bulkEditIndexes, setBulkEditIndexes] = useState<number[]>([])

    const [uploadingExpenses, setUploadingExpenses] = useState(false)
    const [showExpenseUploadSuccess, setShowExpenseUploadSuccess] = useState(false)
    const [showExpenseUploadFailed, setShowExpenseUploadFailed] = useState(false)
    
    const [classifyingCategories, setClassifyingCategories] = useState(false)
    const [classifyCategoriesAttempted, setClassifyCategoriesAttempted] = useState(false)

    //Loads expenses from CSV, asks wherever or not to flip the amounts then formik handles the values from then on
    const [initialNewExpenses, setInitialNewExpenses] = useState<ImportedTransactionPostModel[]|null|undefined>()

    useEffect(() => {
        if (!vatRatesLoaded && expensesAPI) {
            setVatRatesLoaded(true)
            expensesAPI.vatRates(companyContext.cid).then(res => {
                setVatRates([{value: "", label: "None"}, 
                ...res.data.map(x => {return {value: x.id, label: x.name || x.id}})])
            })
        }
    },[companyContext.cid, expensesAPI, vatRatesLoaded])

    useEffect(() => {
        if(contractsLoaded && contracts) {
            setContractsDD([{value: "0", label: "None"}, 
                ...contracts.filter(x => x.status === "Active").map(x => {return {value: x.id.toString(), label: x.name || ''}})
            ])
        }
    }, [contractsLoaded, contracts])

    useEffect(() => {
        if(accountsLoaded && accounts) {
            let tempOptions: Array<GroupedListOption> = []
            accountsExpenditure(accounts).forEach(i => {
                const exists = tempOptions.findIndex(x => x.label === formatSubGroup(i.accountSubGroup))
                if (exists === -1) {
                    tempOptions.push({
                        label: formatSubGroup(i.accountSubGroup || ''),
                        options: [{value: i.id.toString(), label: i.name || ''}]
                    })
                } else {
                    tempOptions[exists].options?.push({value: i.id.toString(), label: i.name || ''})
                }
            })

            tempOptions = _.sortBy(tempOptions, ['label']);
            tempOptions.forEach(group => {
                if(group.options) group.options = _.sortBy(group.options, ['label'])
            })

            tempOptions.push({label: "Asset", options: [{label: "Asset Purchase", value: -99}]})

            setAccountsDD(tempOptions)
        }
    }, [accountsLoaded, accounts])

    useEffect(() => {
        let id: NodeJS.Timeout;
        if (showExpenseUploadSuccess) {
            id = setTimeout(() => setShowExpenseUploadSuccess(false), 5000)
        }
        return () => clearTimeout(id)
    }, [showExpenseUploadSuccess])

    const uploadAndProcessCSV = (acceptedFiles: File[]) => {
        if(expenseImportAPI && !uploadingCSV && !processingCSV) {
            setCSVProcessingFailed(false)
            setUploadingCSV(true)
            expenseImportAPI.uploadCsv(companyContext.cid, {fileToUpload: acceptedFiles[0]}).then(res => {
                setProcessingCSV(true)
                expenseImportAPI.processUploaded(companyContext.cid, accountId, res.data).then(res2 => {
                    let validVatCodes:string[] = []
                    if(vatRates && vatRatesLoaded) {
                        //Try validate vat rates, clear if invalid
                        validVatCodes = vatRates.map(x => x.value || '')
                    }
                    const txns = res2.data.map(x => {return {...x, accountId: x.accountId || 0, contractId: x.contractId || 0,
                        vatCode: validVatCodes.length > 0 && x.vatCode && validVatCodes.includes(x.vatCode.toUpperCase()) ? 
                            x.vatCode.toUpperCase() : ""}})
                    //If there are more negative txns than there are positive txns, offer to flip because expenses should be recorded as positive
                    if(txns.filter(x => x.amount < 0).length > txns.filter(x => x.amount > 0).length) {
                        setOfferTxnFlip(true)
                    }
                    setInitialNewExpenses(txns)
                }).catch(() => {
                    setCSVProcessingFailed(true)
                }).finally(() => {
                    setProcessingCSV(false)
                })
            }).catch(() => {
                setCSVProcessingFailed(true)
            }).finally(() => {
                setUploadingCSV(false)
            })
        }
    }

    const expenseIsValid = (exp:ImportedTransactionPostModel) => {
        if(exp.amount && exp.accountId && exp.datePosted && exp.description) {
            return true
        }
        return false
    }

    const validExpenseCount = (exps:ImportedTransactionPostModel[]) => {
        return exps.filter(x => expenseIsValid(x)).length
    }

    const importExpenses = (values: {expenses: ImportedTransactionPostModel[]; bulkEditExpense: BulkEditExpenseValues;}, 
        formikHelpers: FormikHelpers<{expenses: ImportedTransactionPostModel[]; bulkEditExpense: BulkEditExpenseValues;}>) => {
        if(expenseImportAPI && !uploadingExpenses) {
            let validExpenses = [...values.expenses.filter(x => expenseIsValid(x))]
            validExpenses.forEach(validExpense => {
                if(validExpense.contractId === 0) validExpense.contractId = undefined
                if(!validExpense.contractId || !contracts.find(x => x.id === validExpense.contractId)?.canRebillExpenseToClient) validExpense.billable = false
            });

            if(validExpenses.length > 0) {
                setUploadingExpenses(true)
                expenseImportAPI.importExpenses(companyContext.cid, accountId, validExpenses).then((res) => {
                    setShowExpenseUploadSuccess(true)
                    setShowExpenseUploadFailed(false)
                    
                    if(validExpenses.length === values.expenses.length) {
                        //Reset page ready for next upload
                        setInitialNewExpenses(null)
                        setShowBulkEditRow(false)
                        setBulkEditIndexes([])
                    } else {
                        //Update formik with remaining invalid expenses
                        formikHelpers.setFieldValue("expenses", values.expenses.filter(x => !expenseIsValid(x)))
                    }
                }).catch((error) => {
                    setShowExpenseUploadFailed(true)
                    console.error(error)
                }).finally(() => {
                    setUploadingExpenses(false)
                })
            }
        }
    }

    const importSingle = (txn:ImportedTransactionPostModel, onSuccess: () => void) => {
        if(expenseImportAPI && !uploadingExpenses) {
            setUploadingExpenses(true)
            expenseImportAPI.importExpenses(companyContext.cid, accountId, 
                [{...txn, 
                    contractId: txn.contractId === 0 ? undefined : txn.contractId,
                    billable: (txn.contractId && contracts.find(x => x.id === txn.contractId)?.canRebillExpenseToClient) ? txn.billable : false}]).then((res) => {
                setShowExpenseUploadSuccess(true)
                setShowExpenseUploadFailed(false)
                onSuccess()
            }).catch((error) => {
                setShowExpenseUploadFailed(true)
                console.error(error)
            }).finally(() => {
                setUploadingExpenses(false)
            })
        }
    }

    const flipAmounts = () => {
        if(initialNewExpenses) {
            setInitialNewExpenses(initialNewExpenses.map(x => {return {...x, amount: -x.amount}}))
        }
        setOfferTxnFlip(false)
    }

    const modalButtons = [
        <Button label="Yes" variant="primary" onClick={flipAmounts} key="yes"/>,
        <Button label="No" variant="secondary" onClick={() => setOfferTxnFlip(false)} key="no"/>
    ]

    const isInBulkEdit = (index:number) => {
        return bulkEditIndexes.find(x => x === index) !== undefined
    }

    const updateBulkEditIndexes = (index:number) => {
        if(isInBulkEdit(index)) {
            let newEdits = [...bulkEditIndexes]
            newEdits.splice(newEdits.findIndex(x => x === index), 1)
            setBulkEditIndexes(newEdits)
        } else {
            setBulkEditIndexes([...bulkEditIndexes, index])
        }
    }

    const bulkEditToggleAll = (expenseCount: number) => {
        if(bulkEditIndexes.length === expenseCount) {
            setBulkEditIndexes([])
        } else {
            setBulkEditIndexes(Array.from(Array(expenseCount).keys()))
        }
    }

    const bulkEditBillableOptions:ListOption[] = [
        {value: '', label: 'Unset'},
        {value: 'yes', label: 'Yes'},
        {value: 'no', label: 'No'}
    ]

    const tryClassifyCategories = (setFieldValue: (field: string, value: any) => void, expenses: ImportedTransactionPostModel[]) => {
        if(expenseImportAPI) {
            setClassifyingCategories(true)

            //Just for future reference, we're relying on the array order not changing. 
            //No reason it would, but just something to be aware of!
            //In the API we only really need the description and the categoryId to be sent
            const postModelData:ClassifyTranPostModel[] = expenses.map((exp) => {
                return {
                    datePosted: exp.datePosted,
                    billable: exp.billable,
                    amount: exp.amount,
                    categoryId: exp.accountId,
                    description: exp.description
                }
            })

            expenseImportAPI.classifyExpenses(companyContext.cid, postModelData).then((res) => {
                setClassifyingCategories(false)
                setClassifyCategoriesAttempted(true)
                res.data.forEach((classified, index) => {
                    const account = accounts.find(x => x.name.toLowerCase() === (classified.category || "").toLowerCase())
                    if(account) {
                        setFieldValue(`expenses[${index}].accountId`, account.id)
                        setFieldValue(`expenses[${index}].aiCategoryPicked`, account.id)
                    }
                })
            })
        }
    }

    return (
        <DefaultLayout 
            entity={Entity.ExpenseImportV7} 
            greyBackground 
            title={"Import expenses"} 
            useFullWidth
        >
            <Button buttonType="done" entity={Entity.QuickEntryV7} action={Action.List}/>
            {!initialNewExpenses && <Dropzone onDrop={uploadAndProcessCSV} multiple={false} disabled={uploadingCSV || processingCSV}>
                {
                    ({getRootProps, getInputProps}) => (
                        <div {...getRootProps({className: styles.dropzone})}>
                            <input {...getInputProps()} />

                            {!uploadingCSV && !processingCSV && !CSVProcessingFailed && <>
                                <FontAwesomeIcon icon={faCloudArrowUp} size="8x"/>
                                <div className={styles.dropzoneText}>
                                    <h4>Drop your expenses file here</h4>
                                    <p>To upload your expenses into the software your file needs to be formatted correctly.</p>
                                    <p>The file should be formatted with columns for the Date, Description and Amount. It should then be saved as a CSV file.</p>
                                    {/*TODO, click on this button also opens dropzone without noclick. Maybe try using stopPropagation*/}
                                    {/* {!showTemplate && <Button label="What does this mean?" variant="info" onClick={() => {setShowTemplate(true);}}/>} */}
                                    {/*showTemplate &&*/<p>For more information and guidance on how to create your file please <a onClick={(e) => e.stopPropagation()} target="_blank" download rel="noreferrer" href="/Resources/Expenses template.xls">download this template</a></p>}
                                </div>
                            </>}

                            {(uploadingCSV || processingCSV) && <>
                                <FontAwesomeIcon icon={faRotate} size="8x" spin/>
                                <div className={styles.dropzoneText}>
                                    {uploadingCSV && <h4>Uploading your expenses</h4>}
                                    {processingCSV && <h4>Processing your expenses</h4>}
                                    <p>Please wait while we upload your file and import your expenses</p>
                                </div>
                            </>}

                            {CSVProcessingFailed && <>
                                <FontAwesomeIcon icon={faCloudXmark} size="8x"/>
                                <div className={styles.dropzoneText}>
                                    <h4>Sorry, something went wrong</h4>
                                    <p>Please ensure your CSV is formatted correctly with the right fields</p>
                                    <p>The file should be formatted with columns for the Date, Description and Amount. It should then be saved as a CSV file.</p>
                                    <p>For more information and guidance on how to create your file please <a target="_blank" download rel="noreferrer" href="/Resources/Expenses template.xls">download this template</a></p>
                                </div>
                            </>}
                        </div>
                    )
                }
            </Dropzone>}
            {initialNewExpenses && <div style={{marginTop: '1rem', overflowX: 'auto'}}>
                {/*Our imported expense array needs an object name for field array helpers to work*/}
                <Formik<ExpenseImportFormikWrapper>
                    onSubmit={importExpenses}
                    initialValues={{expenses: [...initialNewExpenses], bulkEditExpense: initialBEExpense}}
                    enableReinitialize
                    validateOnChange
                    validate={() => {}}>
                    {({values, handleSubmit, setFieldValue}) => {
                        return (
                        <Form className={styles.reducedInputPadding}>
                            <FieldArray name="expenses">
                                {({remove, push, form}) => (
                                <>
                                    {hasPermission(Entity.ExpenseAI, Action.All)[0] && <Alert variant="secondary">
                                        <div className={styles.aiBanner}>
                                            <img className={styles.aiLogo} 
                                                src={classifyingCategories ? "./images/ai/ai-logo-animated.gif" : "./images/ai/ai-logo.gif"} 
                                                alt="Accounting intelligence"
                                            />
                                            <div>
                                                <span>
                                                    <strong className={styles.aiTitle}>Accounting Intelligence</strong>
                                                    <Badge variant="warning" pill>BETA</Badge>
                                                </span>
                                                <p style={{maxWidth: '850px'}}>
                                                    We're test piloting our latest innovation - Accounting Intelligence.
                                                    <br />
                                                    Simply press the button below and we'll apply AI to predict the category of your expenses based on your previous bookkeeping.
                                                </p>
                                                <Button variant="secondary" onClick={() => tryClassifyCategories(setFieldValue, values.expenses)}>
                                                    Apply AI
                                                </Button>

                                                {classifyCategoriesAttempted && !classifyingCategories && 
                                                    <span style={{paddingLeft: "0.25rem", color: 'var(--big-positive-button-color)'}}>Complete</span>
                                                }
                                            </div>
                                        </div>
                                    </Alert>}

                                    <Alert variant="info">Please ensure <b>Date</b>, <b>Description</b>, <b>Category</b> and <b>Amount</b> are all set before submitting</Alert>

                                    <Table noHover>
                                        <thead>
                                            <tr>
                                                {showBulkEditRow && <th><input type="checkbox" onChange={() => bulkEditToggleAll(values.expenses.length)} checked={values.expenses.length === bulkEditIndexes.length}/></th>}
                                                <th>Date</th>
                                                <th>Paid to</th>
                                                <th>Reference</th>
                                                <th>Description</th>
                                                <th>Category</th>
                                                <th>Amount</th>
                                                <th>VAT Rate</th>
                                                <th>Contract</th>
                                                <th>Billable</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className={styles.importTableBody}>
                                            {values.expenses.map((exp, index) => (
                                                <tr key={index} className={classNames({[styles.noBottomCellBorder]: index + 1 === values.expenses.length})}>
                                                    {showBulkEditRow && <td><input type="checkbox" onChange={() => updateBulkEditIndexes(index)} checked={isInBulkEdit(index)}/></td>}
                                                    <td>
                                                        <Field name={`expenses[${index}].datePosted` as 'datePosted'}>
                                                            {({field}: FieldProps) => (
                                                                <ReactForm.Control type={'date'} {...({...field, value: asHtml5Date(field.value)})}/>
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td className={styles.payeeCell}>
                                                        <Field name={`expenses[${index}].payee` as 'payee'}>
                                                            {({field}: FieldProps) => (
                                                                <ReactForm.Control type={'text'} {...field} value={field.value || ''}/>
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td className={styles.refCell}>
                                                        <Field name={`expenses[${index}].reference` as 'reference'}>
                                                            {({field}: FieldProps) => (
                                                                <ReactForm.Control type={'text'} {...field} value={field.value || ''}/>
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td>
                                                        <Field name={`expenses[${index}].description` as 'description'}>
                                                            {({field}: FieldProps) => (
                                                                <ReactForm.Control type={'text'} {...field} value={field.value || ''}/>
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td className={styles.selectListCell}>
                                                        <div className={classNames({[styles.aiSelected]: values.expenses[index].aiCategoryPicked !== undefined && values.expenses[index].aiOverwrittenCategory === undefined})}>
                                                            <Field name={`expenses[${index}].accountId` as 'accountId'}>
                                                                {({field}: FieldProps) => (
                                                                    <Select 
                                                                        className={editRowStyles.selectList}
                                                                        classNamePrefix='selectList'
                                                                        options={accountsDD}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`expenses[${index}].accountId`, parseInt(e.value))
                                                                            if(values.expenses[index].aiCategoryPicked !== undefined) {
                                                                                setFieldValue(`expenses[${index}].aiOverwrittenCategory`, parseInt(e.value))
                                                                            }
                                                                        }}
                                                                        value={(accountsDD ? accountsDD.map(i => i.options?.find(options => String(options.value) === String(values.expenses[index].accountId))) : '') as any}
                                                                        isSearchable={true}
                                                                        menuPlacement="auto"
                                                                        menuPortalTarget={document.body} //https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown, https://github.com/JedWatson/react-select/issues/1537
                                                                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </div>
                                                    </td>
                                                    <td className={styles.amountCell}>
                                                        <Field name={`expenses[${index}].amount` as 'amount'}>
                                                            {({field}: FieldProps) => (
                                                                <ReactForm.Control type={'number'} {...field}/>
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td className={styles.selectListCell}>
                                                        <Field name={`expenses[${index}].vatCode` as 'vatCode'}>
                                                            {({field}: FieldProps) => (
                                                                <Select 
                                                                    className={editRowStyles.selectList}
                                                                    classNamePrefix='selectList'
                                                                    options={vatRates}
                                                                    onChange={(e) => setFieldValue(`expenses[${index}].vatCode`, e.value)}
                                                                    value={(vatRates ? vatRates.find(vatRate => String(vatRate.value) === String(values.expenses[index].vatCode)) : '') as any}
                                                                    menuPlacement="auto"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td className={styles.selectListCell}>
                                                        <Field name={`expenses[${index}].contractId` as 'contractId'}>
                                                            {({field}: FieldProps) => (
                                                                <Select 
                                                                    className={editRowStyles.selectList}
                                                                    classNamePrefix='selectList'
                                                                    options={contractsDD}
                                                                    onChange={(e) => setFieldValue(`expenses[${index}].contractId`, parseInt(e.value))}
                                                                    value={(contractsDD ? contractsDD.find(contract => String(contract.value) === String(values.expenses[index].contractId)) : '') as any}
                                                                    isSearchable={true}
                                                                    menuPlacement="auto"
                                                                    menuPortalTarget={document.body}
                                                                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                                />
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td>
                                                        <Field name={`expenses[${index}].billable` as 'billable'}>
                                                            {({field}: FieldProps) => (
                                                                <ReactForm.Check {...field} checked={field.value}/>
                                                            )}
                                                        </Field>
                                                    </td>
                                                    <td style={{minWidth: '140px', textAlign:'right'}}>
                                                        <Button variant="change" onClick={
                                                            () => importSingle(exp, () => {
                                                                if(values.expenses.length === 1) {
                                                                    //Just uploadded the last expense in the list
                                                                    setInitialNewExpenses(null)
                                                                    setShowBulkEditRow(false)
                                                                    setBulkEditIndexes([])
                                                                } else {
                                                                    remove(index)
                                                                }
                                                            })
                                                        } 
                                                        disabled={!expenseIsValid(values.expenses[index]) || uploadingExpenses}>
                                                            <FontAwesomeIcon icon={faArrowDownToSquare}/>Import
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                                    
                                            {showBulkEditRow && <>
                                            <tr className={styles.bulkEditRowTop}>
                                                <td style={{verticalAlign: 'middle'}}><FontAwesomeIcon icon={faPencil}/></td>
                                                <td style={{verticalAlign: 'middle'}} colSpan={10}>
                                                    <span style={{display: 'block'}}>Use this to edit multiple rows at once. Select rows using the check boxes above on the left and then click apply to confirm any changes.</span>
                                                    <span style={{display: 'block'}}>Fields without a value will not be applied.</span>
                                                </td>
                                            </tr>
                                            <tr className={styles.bulkEditRowBottom}>
                                                <td></td>

                                                <td>
                                                    <Field name={`bulkEditExpense.datePosted` as 'datePosted'}>
                                                        {({field}: FieldProps) => (
                                                            <ReactForm.Control type={'date'} {...({...field, value: asHtml5Date(field.value)})}/>
                                                        )}
                                                    </Field>
                                                </td>
                                                <td className={styles.payeeCell}>
                                                    <Field name={`bulkEditExpense.payee` as 'payee'}>
                                                        {({field}: FieldProps) => (
                                                            <ReactForm.Control type={'text'} {...field} value={field.value || ''}/>
                                                        )}
                                                    </Field>
                                                </td>
                                                <td className={styles.refCell}>
                                                    <Field name={`bulkEditExpense.reference` as 'reference'}>
                                                        {({field}: FieldProps) => (
                                                            <ReactForm.Control type={'text'} {...field} value={field.value || ''}/>
                                                        )}
                                                    </Field>
                                                </td>
                                                <td>
                                                    <Field name={`bulkEditExpense.description` as 'description'}>
                                                        {({field}: FieldProps) => (
                                                            <ReactForm.Control type={'text'} {...field} value={field.value || ''}/>
                                                        )}
                                                    </Field>
                                                </td>
                                                <td className={styles.selectListCell}>
                                                    <Field name={`bulkEditExpense.accountId` as 'accountId'}>
                                                        {({field}: FieldProps) => (
                                                            <Select 
                                                                className={editRowStyles.selectList}
                                                                classNamePrefix='selectList'
                                                                options={accountsDD}
                                                                onChange={(e) => setFieldValue(`bulkEditExpense.accountId`, parseInt(e.value))}
                                                                value={(accountsDD ? accountsDD.map(i => i.options?.find(options => String(options.value) === String(values.bulkEditExpense.accountId))) : '') as any}
                                                                isSearchable={true}
                                                                menuPlacement="auto"
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        )}
                                                    </Field>
                                                </td>
                                                <td className={styles.amountCell}>
                                                    <Field name={`bulkEditExpense.amount` as 'amount'}>
                                                        {({field}: FieldProps) => (
                                                            <ReactForm.Control type={'number'} {...field}/>
                                                        )}
                                                    </Field>
                                                </td>
                                                <td className={styles.selectListCell}>
                                                    <Field name={`bulkEditExpense.vatCode` as 'vatCode'}>
                                                        {({field}: FieldProps) => (
                                                            <Select 
                                                                className={editRowStyles.selectList}
                                                                classNamePrefix='selectList'
                                                                options={vatRates}
                                                                onChange={(e) => setFieldValue(`bulkEditExpense.vatCode`, e.value)}
                                                                value={(vatRates ? vatRates.find(vatRate => String(vatRate.value) === String(values.bulkEditExpense.vatCode)) : '') as any}
                                                                menuPlacement="auto"
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        )}
                                                    </Field>
                                                </td>
                                                <td className={styles.selectListCell}>
                                                    <Field name={`bulkEditExpense.contractId` as 'contractId'}>
                                                        {({field}: FieldProps) => (
                                                            <Select 
                                                                className={editRowStyles.selectList}
                                                                classNamePrefix='selectList'
                                                                options={contractsDD}
                                                                onChange={(e) => setFieldValue(`bulkEditExpense.contractId`, parseInt(e.value))}
                                                                value={(contractsDD ? contractsDD.find(contract => String(contract.value) === String(values.bulkEditExpense.contractId)) : '') as any}
                                                                isSearchable={true}
                                                                menuPlacement="auto"
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        )}
                                                    </Field>
                                                </td>
                                                <td style={{width: '125px'}}>
                                                    <Field name={`bulkEditExpense.nullBillable` as 'nullBillable'}>
                                                        {({field}: FieldProps) => (
                                                            <Select 
                                                                className={editRowStyles.selectList}
                                                                classNamePrefix='selectList'
                                                                options={bulkEditBillableOptions}
                                                                onChange={(e) => setFieldValue(`bulkEditExpense.nullBillable`, e.value === 'yes' ? true : e.value === 'no' ? false : null)}
                                                                value={(bulkEditBillableOptions.find(x => 
                                                                    field.value === true ? x.value === 'yes' : 
                                                                    field.value === false ? x.value === 'no' : 
                                                                    x.value === '')) as any}
                                                                menuPlacement="auto"
                                                                menuPortalTarget={document.body}
                                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                            />
                                                        )}
                                                    </Field>
                                                </td>
                                                
                                                <td style={{minWidth: '140px', textAlign:'right'}}>
                                                    <Button 
                                                        variant="primary" 
                                                        disabled={bulkEditIndexes.length === 0}
                                                        onClick={() => {
                                                            let editedExpenses:ImportedTransactionPostModel[] = [...values.expenses]
                                                            editedExpenses.forEach((exp, index) => {
                                                                if(bulkEditIndexes.includes(index)) {
                                                                    if(values.bulkEditExpense.datePosted) exp.datePosted = values.bulkEditExpense.datePosted
                                                                    if(values.bulkEditExpense.payee) exp.payee = values.bulkEditExpense.payee
                                                                    if(values.bulkEditExpense.reference) exp.reference = values.bulkEditExpense.reference
                                                                    if(values.bulkEditExpense.description) exp.description = values.bulkEditExpense.description
                                                                    if(values.bulkEditExpense.accountId) exp.accountId = values.bulkEditExpense.accountId
                                                                    if(values.bulkEditExpense.amount) exp.amount = values.bulkEditExpense.amount
                                                                    if(values.bulkEditExpense.vatCode) exp.vatCode = values.bulkEditExpense.vatCode
                                                                    if(values.bulkEditExpense.contractId) exp.contractId = values.bulkEditExpense.contractId
                                                                    if(values.bulkEditExpense.nullBillable !== null) exp.billable = values.bulkEditExpense.nullBillable
                                                                }
                                                            });
                                                            setFieldValue("expenses", editedExpenses)
                                                            setFieldValue("bulkEditExpense", initialBEExpense)
                                                            setBulkEditIndexes([])
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faCheck}/>Apply
                                                    </Button>
                                                </td>
                                            </tr>
                                            </>}
                                        </tbody>
                                        <tfoot>
                                            <tr className={styles.darkBorderTop}>
                                                <th colSpan={5} style={showBulkEditRow ? {border: 'none'} : {}}>
                                                    <Button label="Bulk edit" variant="change" 
                                                        onClick={() => {setShowBulkEditRow(!showBulkEditRow);
                                                            !showBulkEditRow && setFieldValue("bulkEditExpense", initialBEExpense);
                                                            !showBulkEditRow && setBulkEditIndexes([])}}/>
                                                </th>
                                                <th colSpan={showBulkEditRow ? 6 : 5} style={{textAlign:'right', border: showBulkEditRow ? 'none' : undefined}}>
                                                    <Button variant="change" onClick={handleSubmit} disabled={validExpenseCount(values.expenses) === 0 || uploadingExpenses}>
                                                        <FontAwesomeIcon icon={faArrowDownToSquare}/>{validExpenseCount(values.expenses) > 0 ? 
                                                                                                        `Import ${validExpenseCount(values.expenses)} expense${validExpenseCount(values.expenses) > 1 ? 's' : ''}` :
                                                                                                        'Import expenses'}
                                                    </Button>
                                                </th>
                                            </tr>
                                        </tfoot>
                                    </Table>
                                </>
                                )}
                            </FieldArray>
                        </Form>
                    )}}
                </Formik>
            </div>}

            <Modal showModal={offerTxnFlip} hideModal={() => setOfferTxnFlip(false)} title="Flip amounts?" buttons={modalButtons}>
                <p>We noticed that the majority of the expenses you imported are negative values, most expenses should be positive. Would you like the flip the amounts?</p>
            </Modal>

            <Alert variant="success" show={showExpenseUploadSuccess} onClose={() => setShowExpenseUploadSuccess(false)} dismissible>
                <FontAwesomeIcon icon={faCircleCheck} style={{marginRight: '0.5rem'}}/>
                <span>Your expenses have been uploaded</span>
            </Alert>

            <Alert variant="danger" show={showExpenseUploadFailed} onClose={() => setShowExpenseUploadFailed(false)} dismissible>
                <FontAwesomeIcon icon={faUpload} style={{marginRight: '0.5rem'}}/>
                <span>Sorry, your expenses have failed to upload. Please check all your entries are valid{hasAMSupport ? " or contact your account manager." : "."}</span>
            </Alert>
        </DefaultLayout>
    )
}

export default ExpenseImport