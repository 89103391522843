import { useContext, useEffect, useState } from 'react'
import { PersonAsDetailed, TaxCode, TaxCodePostModel } from '../../../../api/inni/data-contracts'
import CompanyContext from '../../../../context/CompanyContext'
import { useInniAPI } from '../../../../hooks/useInniAPI'
import * as DataGrid from '../../../../elements/DataGrid/DataGrid';
import { TaxCodes as TaxCodesAPI } from '../../../../api/inni/TaxCodes'
import { asYearMonthDay, formatDate } from '../../../../utils/formatDate';
import { Button } from '../../../../elements/Button/Button';
import { TableIcon } from '../../../../elements/TableIcon/TableIcon';
import InfoBanner from '../../../../elements/InfoBanner/InfoBanner';
import { FormikErrors, FormikHelpers } from 'formik';
import { FormikRowLogic } from '../../../../elements/FormikTableEditor/FormikTableEditor';
import { Action, Entity } from '../../../../utils/EntityAction';
import { isAfter } from 'date-fns';
import { CogOptionsDropdown } from '../../../../elements/CogOptionsDropdown/CogOptionsDropdown';
import { useModalDialog } from '../../../../hooks/useModalDialog';
import _ from 'lodash';
import { NoContentSlate } from '../../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../../hooks/terms/useDatagridTerms';
import { useHasPermission } from '../../../../hooks/useHasPermission';
import TaxCodeEditRow from './TaxCodeEditRow';

const TaxCodes = ({person} : {person : PersonAsDetailed | undefined}) => {

    const taxCodeApi = useInniAPI(TaxCodesAPI, [400])
    const companyContext = useContext(CompanyContext)

    const hasPermission = useHasPermission()

    const [taxCodeHistory, setTaxCodeHistory] = useState<TaxCode[] | undefined>(undefined)
    const [taxCodeHistoryLoaded, setTaxCodeHistoryLoaded] = useState(false)

    const [adding, setAdding] = useState(false)

    const [showModalDialog, modalDialog] = useModalDialog();

    useEffect(() => {
        if (taxCodeApi && person && person.employmentId) {
            taxCodeApi.getForEmployment(companyContext.cid, person.employmentId)
            .then(res => {
                setTaxCodeHistory(res.data)
                setTaxCodeHistoryLoaded(true)
            })
        }
    }, [companyContext.cid, person, taxCodeApi])

    const validateCreate = (values: TaxCodePostModel) : Promise<FormikErrors<TaxCodePostModel>> => {
        return new Promise((resolve) => {
            if (taxCodeApi) {
                taxCodeApi.validateCreate(companyContext.cid, person?.employmentId || 0, values)
                .then(() => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    
    const createCall = (values: TaxCodePostModel) : Promise<number> => {
        return new Promise((resolve, reject) => {
            if (taxCodeApi) {
                taxCodeApi.create(companyContext.cid, person?.employmentId || 0, values)
                .then(res => {
                    if (res.status === 200) {
                        setTaxCodeHistory([...(taxCodeHistory || []),
                        {
                            id: parseInt(res.data),
                            effectiveDate: values.effectiveDate,
                            dateChanged: asYearMonthDay(new Date()),
                            code: values.code,
                            taxCode1WM: values.taxCode1WM
                        }])
                        setAdding(false)
                        resolve(parseInt(res.data))
                    }
                    reject(`Unexpected status code ${res.status}`)
                }).catch(err => reject(err.error))
            }
        })
    }

    // Not used but need to satisfy input for FormikRowLogic
    const validateUpdate = (id: number, values: TaxCodePostModel) : Promise<FormikErrors<TaxCodePostModel>> => {
        return new Promise((resolve, reject) => {})
    }
    const editCall = (values: TaxCodePostModel, actions: FormikHelpers<TaxCodePostModel>) : Promise<boolean> => {
        return new Promise((resolve, reject) => {resolve(true)})
    }

    const showDeleteDialog = (id : number) => {
        showModalDialog(
            'Delete tax code?',
            `Are you sure you want to delete this tax code?`,
            [
                <Button variant="danger" label="Yes" onClick={() => deleteTaxCode(id)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const deleteTaxCode = (id : number) => {
        if (taxCodeApi) {
            taxCodeApi.delete(companyContext.cid, person?.employmentId || 0, id)
            .then(res => {
                if (res.status === 200)
                    setTaxCodeHistory(taxCodeHistory?.filter(x => x.id !== id))
                else
                    console.error(`Unexpected status code ${res.status}`)
            })
        }
    }

    //Setup formik instance to be attached to inputs
    const formik = FormikRowLogic<TaxCodePostModel>({
        editRowValues: { 
            code: "",
            effectiveDate: ""
        }, 
        validateUpdate: validateUpdate,
        validateCreate: validateCreate, 
        onCreate: createCall, 
        onUpdate: editCall, 
        addingNewRow: adding
    })

    return (<>
    {taxCodeHistory && taxCodeHistory?.length > 0 && <InfoBanner type="info" body={<>{person?.forenames}'s tax code is currently <b>{taxCodeHistory && taxCodeHistory[taxCodeHistory?.length-1]?.code}</b></>} />}
    <DataGrid.Table noHover>
        <thead>
            <tr>
                <th style={{width: '25%'}}>Date changed</th>
                <th style={{width: '25%'}}>Effective date</th>
                <th style={{width: '25%'}}>Tax code</th>
                <th style={{width: '25%'}}>1 week / month</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            {/* No content slate */}
            {taxCodeHistory && taxCodeHistoryLoaded && taxCodeHistory.length === 0 && 
                <tr>
                    <td colSpan={5}><NoContentSlate whiteBg termsKey="emptyTerms" type={DatagridType.TaxCodes} /></td>
                </tr>
            }

            {/* Output data or loading shim depending on if loading */}
            {taxCodeHistory && taxCodeHistoryLoaded ?
            // Sort by effective date (not date changed)
            _.sortBy(taxCodeHistory, 'effectiveDate').map(x => <tr key={x.id}>
                <td>{formatDate(x.dateChanged)}</td>
                <td>{formatDate(x.effectiveDate)}</td>
                <td>{x.code}</td>
                <td>{x.taxCode1WM ? <TableIcon icon="check" /> : <TableIcon icon="cross" />}</td>
                {/* NOT admin only (MVC) - only show if we haven't gone past effective date */}
                <td>{isAfter(new Date(x.effectiveDate || ''), new Date()) && <CogOptionsDropdown del={() => showDeleteDialog(x.id)} />}</td>
            </tr>) : 
            <>
                <DataGrid.LoadingRow cols={5} />
                <DataGrid.LoadingRow cols={5} />
                <DataGrid.LoadingRow cols={5} />
            </>}
            {/* Add row */}
            {adding && <TaxCodeEditRow formik={formik} />}
        </tbody>
        {/* Hide tfoot if cannot create emp taxcodes */}
        {hasPermission(Entity.EmployeeTaxCodes, Action.Create)[0] &&
        <tfoot>
            <tr>
                <td colSpan={4}>
                    {!adding ? <Button entity={Entity.EmployeeTaxCodes} action={Action.Create} onClick={() => setAdding(true)} buttonType="new" /> :
                    <>
                        <Button entity={Entity.EmployeeTaxCodes} action={Action.Create} buttonType="save" onClick={formik.handleSubmit} />
                        <Button buttonType="cancel" onClick={() => setAdding(false)} />                            
                    </>}    
                </td>
            </tr>
        </tfoot>}
    </DataGrid.Table>
    { modalDialog }
    </>)
}

export default TaxCodes