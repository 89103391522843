import { SbBlokData, StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useContext } from "react";
import OnboardingContext from "../../../context/OnboardingContext";
type Props = {
	blok: SbBlokData;
};

const ButtonRow = ({ blok }: Props) => {
  const { onClickAction} = useContext(OnboardingContext);
  return (
    <div {...storyblokEditable(blok)}>
      {blok.buttons
        ? (blok.buttons as SbBlokData[]).map((blok) => (
            <StoryblokComponent blok={blok} key={blok._uid} onClick={onClickAction(blok)}/>
          ))
        : null}
    </div>
  )
};
 
export default ButtonRow;