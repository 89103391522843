import { lazy, Suspense } from "react";
import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import { ReportSelectPagePlaceholder, ReportPagePlaceholder } from "../../features/Reporting/ReportSelectPagePlaceholder";
const ReportSelectPage = lazy(() => import("../../features/Reporting/ReportSelectPage"));


const SuspendedReportSelectPage = () => {
    return (
        <Suspense fallback={ReportSelectPagePlaceholder}>
            <ReportSelectPage />
        </Suspense>
    );
}

const SuspendedReportPage = () => {
    return (
        <Suspense fallback={ReportPagePlaceholder}>
            <ReportSelectPage />
        </Suspense>
    );
}

const reporting:Array<EntityRoute> = [
    {
        path: '/company/:cid/reporting',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.ReportV7,
        component: SuspendedReportSelectPage
    },
    {
        path: '/company/:cid/reporting/:id',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.ReportV7,
        action: Action.Read,
        component: SuspendedReportPage
    }
]

export default reporting;
