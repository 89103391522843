/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PvSatrAsDetailed,
  PvSatrAttachment,
  PvSatrEmploymentAttachment,
  PvSatrList,
  PvSatrPersonalDetailsPostModel,
  PvSatrPostModel,
  PvSatrProperty,
  PvSatrPropertyIncomePageData,
  PvSatrPropertyIncomePageDataPostModel,
  PvSatrPropertyList,
  PvSatrPropertyPostModel,
  PvSatrSetupStatus,
  PvSatrSubmitITR,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class PvSatr<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags PvSatr
   * @name List
   * @request GET:/api/v2/companies/{companyId}/pvsatr
   * @secure
   */
  list = (companyId: number, params: RequestParams = {}) =>
    this.request<PvSatrList, any>({
      path: `/api/v2/companies/${companyId}/pvsatr`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name GetByPersonAndTaxYear
   * @request GET:/api/v2/companies/{companyId}/pvsatr/{personId}/{taxYear}
   * @secure
   */
  getByPersonAndTaxYear = (companyId: number, personId: number, taxYear: number, params: RequestParams = {}) =>
    this.request<PvSatrAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${personId}/${taxYear}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name Get
   * @request GET:/api/v2/companies/{companyId}/pvsatr/{id}
   * @secure
   */
  get = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<PvSatrAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/pvsatr/{id}
   * @secure
   */
  update = (companyId: number, id: number, pm: PvSatrPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${id}`,
      method: "PUT",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name GetCurrent
   * @request GET:/api/v2/companies/{companyId}/pvsatr/GetCurrent
   * @secure
   */
  getCurrent = (companyId: number, params: RequestParams = {}) =>
    this.request<PvSatrAsDetailed, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/GetCurrent`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name AttachDocument
   * @request POST:/api/v2/companies/{companyId}/pvsatr/{id}/uploadDocument
   * @secure
   */
  attachDocument = (
    companyId: number,
    id: number,
    query: { section: string; fileName: string },
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<PvSatrAttachment, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${id}/uploadDocument`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name DeleteDocument
   * @request DELETE:/api/v2/companies/{companyId}/pvsatr/{id}/DeleteDocument/{attachmentId}
   * @secure
   */
  deleteDocument = (companyId: number, id: number, attachmentId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${id}/DeleteDocument/${attachmentId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name AttachDocumentToEmployment
   * @request POST:/api/v2/companies/{companyId}/pvsatr/{id}/employments/{itrEmploymentId}/uploadDocument
   * @secure
   */
  attachDocumentToEmployment = (
    companyId: number,
    id: number,
    itrEmploymentId: number,
    query: { fileName: string },
    data: { fileToUpload: File },
    params: RequestParams = {},
  ) =>
    this.request<PvSatrEmploymentAttachment, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${id}/employments/${itrEmploymentId}/uploadDocument`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.FormData,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name UpdatePersonalDetails
   * @request PUT:/api/v2/companies/{companyId}/pvsatr/{id}/UpdatePersonalDetails
   * @secure
   */
  updatePersonalDetails = (
    companyId: number,
    id: number,
    pm: PvSatrPersonalDetailsPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${id}/UpdatePersonalDetails`,
      method: "PUT",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/pvsatr/{id}/Validate
   * @secure
   */
  validateUpdate = (companyId: number, id: number, pm: PvSatrPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${id}/Validate`,
      method: "PUT",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name PropertyList
   * @request GET:/api/v2/companies/{companyId}/pvsatr/PropertyList
   * @secure
   */
  propertyList = (companyId: number, params: RequestParams = {}) =>
    this.request<PvSatrPropertyList, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/PropertyList`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name ListProperties
   * @request GET:/api/v2/companies/{companyId}/pvsatr/Properties
   * @secure
   */
  listProperties = (companyId: number, params: RequestParams = {}) =>
    this.request<PvSatrProperty[], any>({
      path: `/api/v2/companies/${companyId}/pvsatr/Properties`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name CreateProperty
   * @request POST:/api/v2/companies/{companyId}/pvsatr/Properties
   * @secure
   */
  createProperty = (companyId: number, pm: PvSatrPropertyPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/Properties`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name UpdateProperty
   * @request PUT:/api/v2/companies/{companyId}/pvsatr/Properties/{propertyId}
   * @secure
   */
  updateProperty = (companyId: number, propertyId: number, pm: PvSatrPropertyPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/Properties/${propertyId}`,
      method: "PUT",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name DeleteProperty
   * @request DELETE:/api/v2/companies/{companyId}/pvsatr/Properties/{propertyId}
   * @secure
   */
  deleteProperty = (companyId: number, propertyId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/Properties/${propertyId}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name GetPropertyIncomePageData
   * @request GET:/api/v2/companies/{companyId}/pvsatr/{id}/PropertyIncomePageData
   * @secure
   */
  getPropertyIncomePageData = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<PvSatrPropertyIncomePageData, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${id}/PropertyIncomePageData`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name UpdatePropertyIncomePageData
   * @request PUT:/api/v2/companies/{companyId}/pvsatr/{id}/PropertyIncomePageData
   * @secure
   */
  updatePropertyIncomePageData = (
    companyId: number,
    id: number,
    pm: PvSatrPropertyIncomePageDataPostModel,
    params: RequestParams = {},
  ) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${id}/PropertyIncomePageData`,
      method: "PUT",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name StepComplete
   * @request POST:/api/v2/companies/{companyId}/pvsatr/{itrId}/StepComplete/{step}
   * @secure
   */
  stepComplete = (
    companyId: number,
    itrId: number,
    step:
      | "SetupWelcomePage"
      | "SetupBasicDetails"
      | "SetupIncome"
      | "SetupProperty"
      | "SetupFinancialRecords"
      | "SetupPropertyIncomePageData"
      | "SetupSubmitted",
    params: RequestParams = {},
  ) =>
    this.request<PvSatrSetupStatus, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${itrId}/StepComplete/${step}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name StepIncomplete
   * @request POST:/api/v2/companies/{companyId}/pvsatr/{itrId}/StepIncomplete/{step}
   * @secure
   */
  stepIncomplete = (
    companyId: number,
    itrId: number,
    step:
      | "SetupWelcomePage"
      | "SetupBasicDetails"
      | "SetupIncome"
      | "SetupProperty"
      | "SetupFinancialRecords"
      | "SetupPropertyIncomePageData"
      | "SetupSubmitted",
    params: RequestParams = {},
  ) =>
    this.request<PvSatrSetupStatus, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${itrId}/StepIncomplete/${step}`,
      method: "POST",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags PvSatr
   * @name Submit
   * @request POST:/api/v2/companies/{companyId}/pvsatr/{itrId}/Submit
   * @secure
   */
  submit = (companyId: number, itrId: number, pm: PvSatrSubmitITR, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/pvsatr/${itrId}/Submit`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
