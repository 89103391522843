import {
	calendar,
	dropdown,
	more,
	upload,
	close,
	closeLine,
	edit,
	trash,
	incoming,
	outgoing,
	pdf,
	csv,
	xls,
	rocket,
	home,
	tenant,
	property,
	coinsStack,
	document,
	graduationCap,
	businessCard,
	accountSetting,
	uploadFile,
	backArrow,
	negative,
	positive,
	arrowSkip,
	threeColumns,
	backCircle,
	arrowNext
} from './Icons';

const IconList = {
	calendar,
	upload,
	more,
	dropdown,
	close,
	closeLine,
	edit,
	trash,
	incoming,
	outgoing,
	pdf,
	csv,
	xls,
	rocket,
	home,
	tenant,
	property,
	coinsStack,
	document,
	graduationCap,
	businessCard,
	accountSetting,
	uploadFile,
	backArrow,
	negative,
	positive,
	arrowSkip,
	threeColumns,
	backCircle,
	arrowNext
};

export type TIcons = keyof typeof IconList;

export default IconList;
