import { PvSatrEmploymentPostModel, PvSatrPostModel } from "../../../../api/inni/data-contracts";
import FormikField from "../../../../elements/FormikField/FormikField";
import { Summary } from "../../../../elements/Summary/Summary";
import styles from '../Income.module.scss'
import { Button } from "../../../../elements/Button/Button";
import Attachments from "../../../../components/Attachments/Attachments";
import { FileRejection } from "react-dropzone";
import { useEffect } from "react";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";

interface EmploymentProps{
    remove:() => void;
    index: number
    onFilesDropped: (acceptedFiles: File[], fileRejections: FileRejection[],  section: string, index?: number) => void;
    filesToUpload: File[]|undefined|null;
    removeEmploymentFiles: (section: string, index: number, fileName?: string) => void;
    removeEmploymentAllFiles: (section: string, index: number, ) => void;
    values?: PvSatrEmploymentPostModel
    showDeleteDialog: (handleOnClickDelete: () => void) => void
    showError?: boolean
}

export default function Employment({remove, index, onFilesDropped, filesToUpload, removeEmploymentFiles, removeEmploymentAllFiles, values, showDeleteDialog, showError}:EmploymentProps){

    const { setFieldValue } = useFormikContext<PvSatrPostModel>();
    
    const handleFileDrop=(acceptedFiles: File[], fileRejections: FileRejection[])=>{
        onFilesDropped(acceptedFiles, fileRejections, "Employment", index )
    }

    const handleRemoveFile =(fileName:string)=>{
        removeEmploymentFiles("Employment", index, fileName )
    }

    const handleOnClickDelete =()=>{
        removeEmploymentAllFiles("Employment", index )
        remove();
    }

    const handleDeleteDialog = () =>{
        showDeleteDialog(handleOnClickDelete)
    }

    useEffect(() =>{
        if(values){
            if(!values.wasDirector){
                setFieldValue(`employments.${index}.closeCompany`, false, false)
                setFieldValue(`employments.${index}.directorResignationdate`, undefined, false)
            }
        }

    }, [values?.wasDirector])


    return(
        <Summary 
            useFullWidth 
            showClose
            onClose={handleDeleteDialog}
            stackedContent
        >
                <h5 className="mb-3">{`Employment ${index+1}`}</h5>
                <FormikField<PvSatrEmploymentPostModel> 
                    className={styles.formikField}
                    name={`employments.${index}.employerName` as "employerName"}
                    label="Name of company"
                    type="text"                                        
                />

                <FormikField<PvSatrEmploymentPostModel> 
                    className={styles.formikFieldsAuto}
                    name={`employments.${index}.wasDirector` as "wasDirector"}
                    label="Were you a director?"
                    type="checkbox"                                        
                />

                

                {values && values.wasDirector &&
                    <>
                        <FormikField<PvSatrEmploymentPostModel> 
                            className={styles.formikFieldsAuto}
                            name={`employments.${index}.closeCompany` as "closeCompany"}
                            label="Was it a 'close' company? (controlled by 5 or fewer people)"
                            type="checkbox" 
                            wrapLabel                                       
                        /> 

                        <FormikField<PvSatrEmploymentPostModel> 
                            className={styles.formikFieldsAuto}
                            name={`employments.${index}.directorResignationdate` as "directorResignationdate"}
                            label="Did you resign as a director in 2023/24, if so, what date did you resign?"
                            type="date" 
                            wrapLabel                                      
                        />
                    </>
                }

                <FormikField<PvSatrEmploymentPostModel> 
                    className={styles.formikFieldsAuto}
                    name={`employments.${index}.receivedBik` as "receivedBik"}
                    label="Did you receive any benefits in kind? (If so, please ensure you attach your P11D document)"
                    type="checkbox"   
                    wrapLabel                                     
                />
                <FormikField<PvSatrEmploymentPostModel> 
                    className={styles.formikFieldsAuto}
                    name={`employments.${index}.receivedRedundancyPayment` as "receivedRedundancyPayment"}
                    label="Did you receive a redundancy payment?"
                    type="checkbox"                                        
                />
                <FormikField<PvSatrEmploymentPostModel> 
                    className={styles.formikNumberField}
                    name={`employments.${index}.valueOfProfessionalFeesOrSubscriptions` as "valueOfProfessionalFeesOrSubscriptions"}
                    label="Have you paid for any professional fees or subscriptions to carry out this job? If so, how much"
                    type="number"
                    prefix="£"
                    step="0.01"
                    min="0"
                    width="350px"
                    onlyPositiveNumbers    
                    wrapLabel                                    
                />
                <br />
                <FormikField<PvSatrEmploymentPostModel> 
                    className={styles.formikNumberField}
                    name={`employments.${index}.valueOfWorkingFromHomeRelief` as "valueOfWorkingFromHomeRelief"}
                    label="Do you want to claim tax relief for working from home? If so, how much"
                    type="number"
                    prefix="£"
                    step="0.01"
                    min="0"
                    onlyPositiveNumbers 
                    wrapLabel                                      
                />
                <br />
                <div className={styles.attachment}>
                    <Attachments
                        title='Attach files'
                        accept={['image/*','.pdf','.doc','.docx']}
                        onDrop={handleFileDrop}
                        message={
                            <p>Please upload your P60 or P45, and your P11D if you received benefits. These will have been provided by the employer.
                                <br />
                            If you left employment or were made redundant, please also include your last payslip</p>
                        }
                        attachedFiles={filesToUpload}
                        onRemoveFile={handleRemoveFile}
                        hidefiles
                        fullWidth
                        regularLabel
                        showError={showError}
                    />
                    {showError && 
                            <Form.Control.Feedback 
                                className={styles.errorFeedback} 
                                type="invalid"
                            >
                                    Please upload the relevant document(s)
                            </Form.Control.Feedback>
                        }
                </div>
                
                <Button buttonType="delete" label="Delete" onClick={handleDeleteDialog} marginTop />

        </Summary>
    );
}
