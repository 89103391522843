import React, { useContext } from 'react';
import { EmptyLayout } from '../../layouts/Desktop/EmptyLayout';
import * as Tabs from '../../elements/VerticalTabs/VerticalTabs';
import { Action, Entity } from '../../utils/EntityAction';
import { TenancyHeader } from './Components/TenancyHeader';
import { TenancyEditForm } from './Components/TenancyEditForm';
import { TenancyPostModel } from '../../api/inni/data-contracts';
import { FormikErrors, FormikHelpers } from 'formik';
import { useAssertPermission } from '../../hooks/useAssertPermission';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { Tenancies } from '../../api/inni/Tenancies';
// import { PageMessages } from '../../api/inni/PageMessages';
import { useInniAPI } from '../../hooks/useInniAPI';
import { useLettableUnitsList } from '../../hooks/lists/useLettableUnitsList';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import { asYearMonthDay } from '../../utils/formatDate';
import { useHasPermission } from '../../hooks/useHasPermission';
import CreateTenancy from './Components/CreateTenancy';
import { useHistory, useParams } from 'react-router-dom';


// TEDIOUS - but Formik needs default values set, otherwise validation fails / won't work properly
const InitialValue:TenancyPostModel = {
    propertyLettableUnitId: 0,
    startDate: asYearMonthDay(new Date()),
    endDate: '',
    moveInDate: '',
    moveOutDate: '',
    rentAmount: 0,
    rentInterval: 'M',
    rentReviewInterval: 12, 
    rentReviewDate: '',
    notes: '',
    tenancyIsManaged: false, 
    lettingAgentName: '',
    agentFees: 0,
    agentFeesInterval: 'M',
    tenantEmail: '',
    tenantTitle: '',
    tenantFirstName: '',
    tenantLastName: '',
    tenantPhoneHome: '',
    tenantPhoneMobile: '',
    tenantPhoneWork: '',
    invoiceDescription: '',
    invoiceMessage: '',
    autoEmailBody: '',
    autoEmailSubject: '',
    invoiceDueDays: 7,
}

interface RouteParams {
    lettableUnitId: string
    propertyId: string
}

export const TenancyNewPage = () => {
    useAssertPermission(Entity.Tenancy, Action.Create);
    const navigateToEntity = useNavigateToEntity();
    const companyContext = useContext(CompanyContext);
    const tenanciesApi = useInniAPI(Tenancies,[400]);
    // const pageMessagesApi = useInniAPI(PageMessages);
    const [units, unitsLoaded] = useLettableUnitsList();
    const history = useHistory();
    const lettableUnitId: string = useParams<RouteParams>().lettableUnitId
    const propertyId: string = useParams<RouteParams>().propertyId

    const onCancelClick = () => {
        navigateToEntity(Entity.Tenancy, Action.List);
    }

    const formSubmit = (values: TenancyPostModel, actions: FormikHelpers<TenancyPostModel>) : Promise<void>=> {
        return new Promise((resolve, reject) => {
            if (tenanciesApi) {
                tenanciesApi.create(companyContext.cid, values)
                // .then(data => {
                //     if (pageMessagesApi && companyContext.product === CompanyProduct.isCap)
                //         pageMessagesApi.markAsRead(companyContext.cid, {storyBlokId: 'info-boxes/onboarding/tenancies_welcome'})

                //     return data;
                // })
                .then(data => {
                    if(propertyId && hideRentPaymentsTab){
                        navigateToEntity(Entity.Property, Action.Read, {id: propertyId, defaultTab: "tenantsandunits"})
                    }
                    else{
                        navigateToEntity(Entity.Tenancy, Action.Read, {id: data.data});
                    }
                        
                    resolve();
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } else {
                reject();
            }
        })
    }

    const formValidate =  (values: TenancyPostModel) : Promise<FormikErrors<TenancyPostModel>> => {
        return new Promise((resolve, reject) => {
            if (tenanciesApi) {
                return tenanciesApi.validateCreate(companyContext.cid, values)
                .then(() => resolve({}))    
                .catch(error => resolve(error.error))            
            } else {
                reject();
            }  
        })          
    }

    const hasPermission = useHasPermission()
    const hasInvoicing = hasPermission(Entity.Invoice, Action.All)[0] || hasPermission(Entity.InvoiceV7, Action.All)[0] //InvoiceV7 still admin only
    const hideRentPaymentsTab = companyContext.product === CompanyProduct.isCap
    return (
        <>
        { hideRentPaymentsTab ?
                <CreateTenancy 
                    isOpen={true} 
                    intialValues={InitialValue}  
                    hide={()=> history.goBack()} 
                    onCancel={()=>history.goBack()}
                    formSubmit={formSubmit} 
                    formValidate={formValidate}
                    lettableUnitId={parseInt(lettableUnitId)}
                />
        :
            <EmptyLayout entity={Entity.Tenancy} title='New tenancy' loading={!unitsLoaded}  >     
                <TenancyHeader title='New tenancy' units={units}/>       
                { hideRentPaymentsTab &&
                    <div style={{padding:"32px"}}>
                        <TenancyEditForm 
                            initialValues={
                                {...InitialValue, 
                                    invoiceDueDays: hasInvoicing ? InitialValue.invoiceDueDays : undefined}
                            } 
                            formSubmit={formSubmit} 
                            formValidate={formValidate} 
                            onCancelClick={onCancelClick} 
                            lettableUnits={units} /> 
                    </div>
                }
                { !hideRentPaymentsTab &&
                    <Tabs.Wrapper>
                        <Tabs.Tabs selectedKey='details' onChangeTab={() => {return null}} >
                            <Tabs.Tab keyName="details">Tenancy details</Tabs.Tab>
                            <Tabs.Tab keyName="rent" disabled>Rent &amp; payments</Tabs.Tab>
                        </Tabs.Tabs>
                        <Tabs.Content>
                            <TenancyEditForm 
                                initialValues={
                                    {...InitialValue, 
                                        invoiceDueDays: hasInvoicing ? InitialValue.invoiceDueDays : undefined}
                                } 
                                formSubmit={formSubmit} 
                                formValidate={formValidate} 
                                onCancelClick={onCancelClick} 
                                lettableUnits={units} /> 
                        </Tabs.Content>
                    </Tabs.Wrapper>
                }
                
            </EmptyLayout>
        }
        </>
    )
}