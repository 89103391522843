import { faPhoneAlt, faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SimpleTerm, useSimpleTerms } from '../../../hooks/terms/useSimpleTerms'
import styles from '../NoCompanies.module.css'

const ContactDetails = () => {
    const simpleTerms = useSimpleTerms()

    return (
        <div>
            <hr />
            <p className="m-2">If you have any questions or need assistance, we're here to help.</p>
            <div className={styles.contactDetail}>
                <FontAwesomeIcon icon={faPhoneAlt} />
                <span>{simpleTerms(SimpleTerm.AppContactPhone)}</span>
            </div>
            <div className={styles.contactDetail}>
                <FontAwesomeIcon icon={faEnvelope} />
                <span><a href={`mailto:${simpleTerms(SimpleTerm.AppContactEmail)}`}>{simpleTerms(SimpleTerm.AppContactEmail)}</a></span>
            </div>
        </div>
    )
}

export default ContactDetails