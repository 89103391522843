import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SetupWizardForm } from '../../../api/inni/data-contracts'
import { Button } from '../../../elements/Button/Button'
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity'
import { Entity } from '../../../utils/EntityAction'
import styles from '../SetupWizard.module.css'
import { WarningText } from './WarningText'

const Assets = ({setNextPage, setPrevPage, formState} : {setNextPage : () => void, setPrevPage : () => void, formState : SetupWizardForm}) => {

    const navToEntity = useNavigateToEntity()

    return (
        <div>
            <p style={{cursor: 'pointer'}} onClick={setPrevPage}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
            <h1>Assets</h1>
            <hr />
            { formState.setupAssets ? 
            <p>The assets for this company have already been confirmed, please reset the wizard on the confirmation page to make changes.</p>
            : <>                
                <p>Company assets can be setup using the assets page, this can be found in the menu or click the button below</p>
                <Button variant="primary" onClick={() => navToEntity(Entity.AssetV7)}>Go to assets page</Button>
            </>

            }
            <div className={styles.formButtons}>
                <Button onClick={setNextPage} variant="change">Continue</Button>
            </div>
            <WarningText />
        </div>
    )
}

export default Assets