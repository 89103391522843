import React from 'react'
import { PropertyLettableUnit } from '../../../api/inni/data-contracts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formatLettingType } from '../../../utils/formatters/formatProperty';
import { formatDate } from '../../../utils/formatDate';

export interface LettableUnitBadgeProps {
    unit: PropertyLettableUnit, 
    className?: string, 
    /** The current date for tenancy ending warnings. Defaults to today if not set */
    currentDate? : Date,
    hideName?: boolean
}

export const LettableUnitBadge = ({unit, className, currentDate, hideName}: LettableUnitBadgeProps) => {


    const variant = () => {
        if (unit.isVacant) return 'danger';
        if (vacantSoon()) return 'warning';
        return 'success';
    }

    const vacantSoon = () => {
        if (!unit.isVacant && unit.letUntil) {
            let today = currentDate ? new Date(currentDate) : new Date();
            let cutoff = new Date(unit.letUntil);
            cutoff.setMonth(cutoff.getMonth() - 3);
            if (cutoff <= today ) return true;
        }
        return false;
    }

    const renderTooltip = (props:any) => (
        <Tooltip id="button-tooltip" {...props}>
            {!hideName && unit.name &&
                <>{unit.name}<br /></>
            }            
            {formatLettingType(unit.lettingType).name}<br />
            <strong>
                {unit.isVacant ? 'Vacant' : ''}
                {vacantSoon() ? `Vacant from ${formatDate(unit.letUntil)}`  : ''}
            </strong>
        </Tooltip>
    );

      
    return (
        <OverlayTrigger placement='bottom' overlay={renderTooltip}>
            <Badge variant={variant()} pill className={className} style={{cursor: 'help'}}>
                <FontAwesomeIcon icon={formatLettingType(unit.lettingType).icon} />
            </Badge>
        </OverlayTrigger>
    )
}

export const LettableUnitsBadges = ({units} : {units: Array<PropertyLettableUnit> | undefined}) => {
    if (!units) return <></>;

    return (
        <>
            {units.map(unit => (
                <LettableUnitBadge unit={unit} key={unit.id} className='mr-1' hideName={units.length === 1} />
            ))}
        </>
    )
}