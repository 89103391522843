import { Employment, PersonAsDetailed } from '../../../../api/inni/data-contracts';
import { YesNo, Date, Text, Currency } from '../../../../elements/DetailRow/DetailRow';
import { ListOption } from '../../../../elements/EditRow/EditRow';

const EmploymentView = ({ employment, person, workingBandSL }: { employment: Employment, person: PersonAsDetailed, workingBandSL: ListOption[] }) => {
    return (
        <>
            <YesNo editStyling entity={employment} name="isActiveEmployee" label="Employee" />
            <Date editStyling entity={employment} name="startDate" />
            <Date editStyling entity={employment} name="leavingDate" />
            <Text editStyling entity={employment} name="targetSalary" />
            <Text editStyling entity={employment} name="normalWorkingHoursBand" valueFormatter={(i) => workingBandSL.find(x => i === x.value)?.label} />
            <Text editStyling entity={person} name="nino" label="N.I. Number" />
            <Text editStyling entity={person} name="passportNumber" label="Passport number" ensureRender />
            <Date editStyling entity={person} name="studentLoanStart" label="Student loan repayment start date" ensureRender />
            <Date editStyling entity={person} name="studentLoanEnd" label="Student loan repayment end date" ensureRender />
            <Text editStyling entity={person} name="studentLoanPlanType" label="Student loan plan type" ensureRender />
            <Date editStyling entity={person} name="postGraduateLoanStart" label="Postgraduate loan repayment start date" ensureRender />
            <Date editStyling entity={person} name="postGraduateLoanEnd" label="Postgraduate loan repayment end date" ensureRender />
            <Text editStyling entity={person} name="defaultEmployee" valueFormatter={(i) => i === null ? '' : Boolean(i) === true ? 'Yes' : 'No'} />
            <Text editStyling entity={employment} name="employeeNumber" />
            <Text editStyling entity={employment} name="previousEmployeeNumber" />
            <YesNo editStyling entity={person} name="hasEmploymentContract" />
            <YesNo editStyling entity={employment} name="repayTaxRefunds" />
            {person.isDirector && employment.directorsNICCalculationMethod &&
                 <Text editStyling entity={employment} name="directorsNICCalculationMethod"  label='Director NI calculation method'/>
            }
            {employment.showPrevious && <>
                <Text editStyling entity={employment} name="previousTaxOfficeAndRef" />
                <Date editStyling entity={employment} name="previousLeavingDate" />
                {employment.previousWeek > 0 ?
                    <Text editStyling entity={employment} name="previousWeek" /> :
                    <Text editStyling entity={employment} name="previousMonth" />
                }
                <Text entity={employment} name="previousTaxCode" />
                <Currency editStyling entity={employment} name="previousPayToDate" />
                <Currency editStyling entity={employment} name="previousTaxToDate" />
            </>}
        </>
    )
}

export default EmploymentView