import {
	SbBlokData,
	StoryblokComponent,
	storyblokEditable,
} from '@storyblok/react';
import { useContext } from 'react';
import { InitialiseDateModel } from '../../../api/inni/data-contracts';
import OnboardingContext from '../../../context/OnboardingContext';
import { OnboardingSystemKeys } from '../../../hooks/useOnboardingStory';
type Props = {
	blok: SbBlokData;
	isCompletedPage: boolean;
	initialData: InitialiseDateModel;
};
const ShowIf = ({ blok, isCompletedPage, initialData }: Props) => {
	const { onClickAction, showExcept, selectedPageUrl } = useContext(OnboardingContext)

	const showIf = () => (
		(isCompletedPage &&
			blok.system_key === OnboardingSystemKeys.pageCompleteYes) ||
		(!isCompletedPage &&
			blok.system_key === OnboardingSystemKeys.pageCompleteNo) ||
		(initialData.registeredOffice &&
			blok.system_key === OnboardingSystemKeys.registeredOfficeYes) ||
		(!initialData.registeredOffice &&
			blok.system_key === OnboardingSystemKeys.registeredOfficeNo) ||
		(!initialData.recordsCoverMD &&
			blok.system_key === OnboardingSystemKeys.recordsCoverMDNo) ||
		(initialData.recordsCoverMD && initialData.outstandingTransactions &&
			blok.system_key === OnboardingSystemKeys.recordsCoverMDYes) ||
		(initialData.previousAccountant &&
			blok.system_key === OnboardingSystemKeys.previousAccYes) ||
		(!initialData.previousAccountant &&
			blok.system_key === OnboardingSystemKeys.previousAccNo) ||
		(!initialData.bankAccountConnected &&
			blok.system_key === OnboardingSystemKeys.readyToConnect) ||
		(initialData.bankAccountConnected &&
			blok.system_key === OnboardingSystemKeys.Connected) ||
		(initialData.professionalClearanceStatus !== 'Complete' &&  blok.system_key === OnboardingSystemKeys.profClearNo)  ||
		(!initialData.propertiesAdded && blok.system_key === OnboardingSystemKeys.propertiesAddedNo) ||
		(!initialData.financesAdded && blok.system_key === OnboardingSystemKeys.financesAddedNo) ||
		(!initialData.bookkeepingComplete && blok.system_key === OnboardingSystemKeys.bookkeepingCompleteNo) ||
		(!initialData.csvUploaded && blok.system_key === OnboardingSystemKeys.CSVUploadedNo)
	);

	const showBtnExcept = (except: {[key: string]: boolean}, showIf: () => boolean) => { 
		const page = selectedPageUrl.split('/')[2];
		if (Object.keys(except).includes(page) && except[page] && !isCompletedPage && blok.system_key === OnboardingSystemKeys.pageCompleteNo) {
			return false
		}
		return showIf();
	}

	return showBtnExcept(showExcept, showIf) ? (
		<div
			{...storyblokEditable(blok)}
		>
			{blok.body
				? (blok.body as SbBlokData[]).map((blok) => ( 
						<StoryblokComponent initialData={initialData} isCompletedPage={isCompletedPage} blok={blok} key={blok._uid} onClick={onClickAction(blok)}/>
				  ))
				: null}
		</div>
	) : null;
};

export default ShowIf;
