import { PvSatrPostModel } from "../../../../api/inni/data-contracts";
import { Summary } from "../../../../elements/Summary/Summary";
import styles from "../Income.module.scss"
import { Text, Date as DateText, Currency, YesNo } from '../../../../elements/DetailRow/DetailRow';
import classNames from "classnames";

interface IncomeSummaryProps{
    values: PvSatrPostModel
}


export default function IncomeSummary ({values}: IncomeSummaryProps){

    return(

        <div className={styles.summaryContainer}>
            <Summary>
                <h2 className={styles.subheading}>Employment</h2>

                {values.employments?.length === 0 &&

                    <div className={classNames(styles.noEntries, "text-muted")}>
                        No employments
                    </div>
                }

                {values.employments?.map((employment, index) =>  {

                    return(
                        <div key={index}>
                            <p className={classNames(styles.title, "font-weight-bold")}>{`${index+1}. ${employment.employerName ? employment.employerName : "Employment name not provided" }`}</p>
                            <div className="ml-3">
                                <YesNo fixedWidth wideLabel ensureRender entity={employment} name={'wasDirector'} label="Were you a director?" v8Summary  showLabelTop/>
                                {employment.wasDirector &&
                                    <YesNo fixedWidth wideLabel ensureRender entity={employment} name={'closeCompany'} label="Was it a close company?" v8Summary showLabelTop/>
                                }
                                {employment.directorResignationdate &&
                                    <DateText fixedWidth wideLabel ensureRender entity={employment} name={'directorResignationdate'} label="Date of resignation" v8Summary showLabelTop/>
                                }
                                <YesNo fixedWidth wideLabel ensureRender entity={employment} name={'receivedBik'} label="Did you receive benefits in kind?" v8Summary showLabelTop/>
                                <YesNo fixedWidth wideLabel ensureRender entity={employment} name={'receivedRedundancyPayment'} label="Did you receive a redundancy payment?" v8Summary showLabelTop/>
                                <Currency fixedWidth wideLabel ensureRender entity={employment} name={'valueOfProfessionalFeesOrSubscriptions'} label="Professional fees / subscriptions" v8Summary showLabelTop/>
                                <Currency fixedWidth wideLabel ensureRender entity={employment} name={'valueOfWorkingFromHomeRelief'} label="Tax relief for working from home" v8Summary showLabelTop/>
                            </div>
                            
                        </div>
                    )
                })}
            </Summary>

            <Summary>
                <h2 className={styles.subheading}>Pension</h2>

                {values.pensionIncomeDetails ?
                        <Text
                            fixedWidth
                            wideLabel
                            ensureRender
                            entity={values}
                            name={'pensionIncomeDetails'}
                            label="Pension income details"
                            v8Summary
                            showLabelTop
                        />
                    :
                        <div className={classNames(styles.noEntries, "text-muted")}>
                            No pension income
                        </div>
                }
            </Summary>

            <Summary>
                <h2 className={styles.subheading}>Dividends</h2>

                {values.dividends?.length === 0 &&

                    <div className={classNames(styles.noEntries, "text-muted")}>
                        No dividends
                    </div>
                }

                {values.dividends?.map((dividend, index) =>  {

                        return(
                            <div key={index}>
                                <p className={classNames(styles.title, "font-weight-bold")}>{`${index+1}. ${dividend.name ? dividend.name : "Dividend name not provided"}`}</p>
                                <div className="ml-3">
                                    <Currency fixedWidth wideLabel ensureRender entity={dividend} name={'grossAmount'} label="Dividend amount" v8Summary showLabelTop/>
                                    <Currency fixedWidth wideLabel ensureRender entity={dividend} name={'taxAlreadyDeducted'} label="Tax already deducted" v8Summary showLabelTop/>
                                    <Text fixedWidth wideLabel ensureRender entity={dividend} name={'notes'} label="Notes" v8Summary showLabelTop/>
                                </div>
                            </div>
                        )
                })}
            </Summary>

            <Summary>
                <h2 className={styles.subheading}>Taxable state benefits</h2>

                {values.stateBenefitsDetails ?
                        <Text fixedWidth wideLabel ensureRender entity={values} name={'stateBenefitsDetails'} label="State benefit details" v8Summary showLabelTop/>
                    :
                        <div className={classNames(styles.noEntries, "text-muted")}>
                            No taxable state benefits
                        </div>
                }
            </Summary>

            <Summary>
                <h2 className={styles.subheading}>Savings interest</h2>

                {values.savings?.length === 0 &&
                    <div className={classNames(styles.noEntries, "text-muted")}>
                        No savings interest
                    </div>
                }
                
                {values.savings?.map((saving, index) =>  {

                    return(
                        <div key={index}>
                            <p className={classNames(styles.title, "font-weight-bold")}>{`${index+1}. ${saving.name ? saving.name : "Savings name not provided"}`}</p>
                            <div className="ml-3">
                                <Currency fixedWidth wideLabel ensureRender entity={saving} name={'amount'} label="Interest amount" v8Summary showLabelTop/>
                                <Currency fixedWidth wideLabel ensureRender entity={saving} name={'taxAlreadyDeducted'} v8Summary showLabelTop/>
                                <Text fixedWidth wideLabel ensureRender entity={saving} name={'details'} v8Summary showLabelTop/>
                            </div>
                        </div>
                    )
                })}
            </Summary>

            <Summary>
                <h2 className={styles.subheading}>Child benefit</h2>

                { ( values.childBenefitHowManyChildren 
                    || values.childBenefitHowMuch 
                    || values.childBenefitThisEmployeeHighestEarner 
                    || values.childBenefitStoppedReceivingOnDate
                  ) ?
                        <>
                            <Text fixedWidth wideLabel ensureRender entity={values} name={'childBenefitHowManyChildren'} label="How many children?" v8Summary showLabelTop/>
                            <Currency fixedWidth wideLabel ensureRender entity={values} name={'childBenefitHowMuch'} label="Child benefit amount" v8Summary showLabelTop/>
                            <YesNo  fixedWidth wideLabel ensureRender entity={values} name={'childBenefitThisEmployeeHighestEarner'} label="Who was higher earner" v8Summary showLabelTop/>
                            <DateText fixedWidth wideLabel ensureRender entity={values} name={'childBenefitStoppedReceivingOnDate'}  label="Date stopped" v8Summary showLabelTop/>
                        </>
                    :
                        <div className={classNames(styles.noEntries, "text-muted")}>
                            No child benefits
                        </div>
                }
                

            </Summary>

            <Summary>
                <h2 className={styles.subheading}>Other income</h2>

                {values.otherIncomeDetails ?
                        <Text fixedWidth wideLabel ensureRender entity={values} name={'otherIncomeDetails'} label="Other Income details" v8Summary showLabelTop/>
                    :
                        <div className={classNames(styles.noEntries, "text-muted")}>
                            No other income
                        </div>
                }
                
            </Summary>

            <Summary>
                <h2 className={styles.subheading}>Outgoing & reliefs in tax year</h2>

                { ( values.isRequiredToPayStudentLoan 
                    || values.hasDonatedToCharity
                    || values.hasPersonalPensionPayments
                    || values.mileageDetails
                  ) ?
                        <>
                            {values.isRequiredToPayStudentLoan &&
                                <YesNo  fixedWidth wideLabel ensureRender entity={values} name={'isRequiredToPayStudentLoan'} label="Are you required to pay student loan?" v8Summary showLabelTop/>
                            }

                            {values.hasDonatedToCharity &&
                                <>
                                    <YesNo  fixedWidth wideLabel ensureRender entity={values} name={'hasDonatedToCharity'} label="Have you donated to charity?" v8Summary showLabelTop/>
                                    <Currency fixedWidth wideLabel ensureRender entity={values} name={'charityDonationAmount'} label="Donation amount" v8Summary showLabelTop/>
                                </>
                            }

                            {values.hasPersonalPensionPayments &&
                                <>
                                    <YesNo  fixedWidth wideLabel ensureRender entity={values} name={'hasPersonalPensionPayments'} label="Do you have personal pension payments?" v8Summary showLabelTop/>
                                    <Currency fixedWidth wideLabel ensureRender entity={values} name={'pensionPaymentAmount'} label="Pension payment amount" v8Summary showLabelTop/>
                                </>
                            }
                            
                            {values.mileageDetails &&
                                <Text fixedWidth wideLabel ensureRender entity={values} name={'mileageDetails'} v8Summary showLabelTop/>
                            }
                        </>
                    :
                        <div className={classNames(styles.noEntries, "text-muted")}>
                            No outgoing or reliefs
                        </div>
                }
                
            </Summary>
        </div>

    )

}