import { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { ContactOnboardings } from '../../api/inni/ContactOnboardings';
import { ContactOnboarding } from '../../api/inni/data-contracts';
import BrandContext from '../../context/BrandContext';
import { SimpleTerm, useSimpleTerms } from '../../hooks/terms/useSimpleTerms';
import { useInniAPI } from '../../hooks/useInniAPI';
import ContactDetails from './Components/ContactDetails';
import OnboardingProcess from './Components/OnboardingProcess/OnboardingProcess'
import styles from './NoCompanies.module.css'
import { useHasPermission } from '../../hooks/useHasPermission';
import { useGetUrl } from '../../hooks/useGetUrl';
import { Entity, Action } from '../../utils/EntityAction';
import { Redirect } from 'react-router-dom';
import useIsMobile from '../../hooks/useIsMobile';

const NoCompanies = () => {

    const brandContext = useContext(BrandContext);
    const simpleTerms = useSimpleTerms()
    const onboardingApi = useInniAPI(ContactOnboardings);
    const [isLoaded, setIsLoaded] = useState(false)
    const [data, setData] = useState<ContactOnboarding[] | undefined>(undefined)

    const [redirectUrl, setRedirectUrl] = useState<string|undefined>();
    const hasPermission = useHasPermission();
    const getUrl = useGetUrl();

    useEffect(() => {
        if (hasPermission(Entity.PortalConsultations, Action.Read)[0])
            setRedirectUrl(getUrl(Entity.PortalConsultations, Action.Read).url);
    }, [hasPermission, getUrl]);

    useEffect(() => {
        if (onboardingApi && !isLoaded) {
            onboardingApi.index(0)
            .then(res => {
                setData(res.data)
                setIsLoaded(true)
            })
        }
    }, [isLoaded, onboardingApi, brandContext])

    const isMobile = useIsMobile()

    if (redirectUrl) {
        return <Redirect to={redirectUrl} />
    } else {
        return (
            <div id={styles.noCompany}>
                {/* If there is some kind of onboarding data */}
                { data && data.length !== 0 &&
                    <OnboardingProcess data={data} isLoaded={isLoaded} />
                }
                {/* If not we just show no access for now */}
                { data && data.length === 0 &&
                    <Container style={{background: `${isMobile ? '' : '#fff'}`}}>
                        <div className={`${styles.onboardingProcess} ${isMobile ? 'shadow' : ''}`}>
                            <img style={{height: '50px'}} src={brandContext.brand.logoDarkUrl} alt={"logo"} />
                            <h4>Your login doesn't have access, please contact <a href={`mailto:${simpleTerms(SimpleTerm.AppContactEmail)}`}>{simpleTerms(SimpleTerm.AppContactEmail)}</a></h4>
                            <ContactDetails />
                        </div>
                    </Container>
                }
            </div>
        )
    }
}

export default NoCompanies