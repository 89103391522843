import { useCallback, useContext, useEffect, useState } from "react";
import { Filter } from "../../components/AccountsFilter/Filter"
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout"
import { Action, Entity } from "../../utils/EntityAction"
import * as DataGrid from '../../elements/DataGrid/DataGrid';
import { DatePicker, Dividend, DividendEligibilityModel, DividendLine, PersonWithDividendData } from "../../api/inni/data-contracts";
import { formatDate } from "../../utils/formatDate";
import CompanyContext, { CompanyProduct } from "../../context/CompanyContext";
import { useInniAPI } from "../../hooks/useInniAPI";
import { Dividends } from "../../api/inni/Dividends";
import { formatCurrency } from "../../utils/formatNumbers";
import { Button } from "../../elements/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useCustomWebRequests } from "../../hooks/useCustomWebRequests";
import Select from "react-select";
import styles from "./Dividend.module.css"
import { useNavigateToEntity } from "../../hooks/useNavigateToEntity";
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder/LoadingPlaceholder";
import { NoContentSlate } from "../../elements/Slates/NoContentSlate";
import { DatagridType } from '../../hooks/terms/useDatagridTerms';
import Badge from "../../elements/Badge/Badge";
import { useHasPermission } from "../../hooks/useHasPermission";
// import { useOnboardingMessages } from '../../hooks/onboarding/useOnboardingMessages';
import InfoBanner from "../../elements/InfoBanner/InfoBanner";
import { SbBlokData, getStoryblokApi } from "@storyblok/react";
import BlankStateCard from "../../components/BlankStateCard/BlankStateCard";
import Toolbar from "../../layouts/Desktop/Toolbar";


export const DividendListPage = () => {
    const companyContext = useContext(CompanyContext)
    const dividendsAPI = useInniAPI(Dividends);
    
    const [dividends, setDividends] = useState<Dividend[]>()
    const [people, setPeople] = useState<PersonWithDividendData[]>()
    const [dividendEligibility, setDividendEligibility] = useState<DividendEligibilityModel>()

	// const [unreadPageMessages, setMessageRead, getOnboardingComponent] = useOnboardingMessages('dividends');
    const isCAP= companyContext.product===CompanyProduct.isCap;
    const v8Styling = companyContext.company?.useV8UI || false;
    const [blankSlateInfo, setBlankSlateInfo]= useState<SbBlokData>();
    const navigateToEntity = useNavigateToEntity();
    
    const getFilter = (yearCode: string|undefined) :  Promise<DatePicker> => {
        const p = new Promise<DatePicker>((resolve, reject) => {
            dividendsAPI?.filter(companyContext.cid, {yearCode: yearCode})
                .then(results => {
                    if (results.data.datePicker) {
                        resolve(results.data.datePicker)
                    } else reject();
                }).catch(error => {
                    console.error(error)
                })
        })
        return p;
    }

    useEffect(() => {
        if(dividendsAPI) {
            dividendsAPI.index(companyContext.cid).then(results => {
                if (results.data) {
                    setDividends(results.data)
                }
            }).catch(error => {
                console.error(error)
            })

            dividendsAPI.dividendEligibility(companyContext.cid).then(res => {
                if(res.data) {
                    setDividendEligibility(res.data)
                }
            }).catch(error => {
                console.error(error)
            })

            dividendsAPI.peopleWithDividendData(companyContext.cid).then(results => {
                if (results.data) {
                    setPeople(results.data)
                }
            }).catch(error => {
                console.error(error)
            })
        }
    }, [companyContext.cid, dividendsAPI])

    const customRequest = useCustomWebRequests();

    const getAllVouchersMinutes = () => {
        customRequest("dividends/AllVouchersAndMinutesZipped").then((data) => {
            if(data.status === 200) {                
                return data.blob();
            }
            else {
                console.warn(data);
            }
        })
        .then((response) => {
            const url = response ? window.URL.createObjectURL(response) : '#';
            let a = document.createElement('a');
            a.setAttribute('type', 'hidden');
            a.href = url;
            a.target = '_blank';
            a.download = `${companyContext.company?.name} dividend vouchers and minutes.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        })
        .catch((error) => console.error(error));
    }

    useEffect(()=>{
        const storyblokApi = getStoryblokApi();
        storyblokApi.get('cdn/stories/blank-slate/dividends', { version: 'published'})
        .then(res => {
            setBlankSlateInfo(res.data.story.content)
        }).catch(err => {
            console.error(err)
        });
    },[])

    const hasPermission = useHasPermission();
    const isAdminDecorated = hasPermission(Entity.DividendV7, Action.ExportAll)[1]

    const hasAMSupport = hasPermission(Entity.AccountManagerSupport, Action.All)[0]

    return (<DefaultLayout
        subLayout={v8Styling ? true : false}
        entity={Entity.DividendV7} 
        greyBackground 
        title="Dividends"
    >
        {/* { getOnboardingComponent('dividends_welcome') } */}

        { v8Styling
            ? (
                <Toolbar>
                    { dividendEligibility?.dividendsAvailable && !dividendEligibility?.shareStructureNotSupported && !(isCAP && dividends?.length===0) && (
                        <Button
                            variant="change"
                            buttonType="new"
                            entity={Entity.DividendV7}
                            action={Action.Create}
                            headerBtn
                            label="Issue dividend"
                        />
                    )}

                    { isAdminDecorated &&
                        <Button
                            buttonType="download"
                            variant="secondary"
                            admin={isAdminDecorated}
                            onClick={getAllVouchersMinutes}
                            entity={Entity.DividendV7}
                            action={Action.ExportAll}
                            headerBtn
                            label="Export all"
                        />
                    }
                </Toolbar>
            )
            : (
                <>
                    { dividendEligibility?.dividendsAvailable && !dividendEligibility?.shareStructureNotSupported && !(isCAP && dividends?.length===0) && (
                        <Button
                            buttonType="new"
                            variant="change"
                            entity={Entity.DividendV7}
                            action={Action.Create}
                            className="mb-5"
                            label="Issue dividend"
                        />
                    )}

                    { isAdminDecorated &&
                        <Button
                            variant="secondary"
                            admin={isAdminDecorated}
                            onClick={getAllVouchersMinutes}
                            entity={Entity.DividendV7}
                            action={Action.ExportAll}
                            className="mb-5"
                        >
                            <FontAwesomeIcon icon={faFile} />Export all
                        </Button>
                    }
                </>
            )
        }

        { !v8Styling && !dividendEligibility?.dividendsAvailable && 
            <InfoBanner
                title={"Dividends unavailable"}
                body={"You cannot issue a dividend at this time because your company currently has no shareholders."}
                type={"warning"}
                noTopMargin
            />
        }

        { !v8Styling && dividendEligibility?.shareStructureNotSupported && (
            <InfoBanner
                title="Dividends unavailable"
                body={
                    <>
                        <p style={dividendEligibility.shareStructureNotSupportedMessage ? {} : {marginBottom: '0'}}>
                            Your shareholder structure is not supported by your price plan.{hasAMSupport ? " Please contact your account manager." : ""}
                        </p>
                        { dividendEligibility.shareStructureNotSupportedMessage && (
                            <p style={{marginBottom: '0'}}>
                                {dividendEligibility.shareStructureNotSupportedMessage}
                            </p>
                        )}
                    </>
                }
                type="warning"
                noTopMargin
            />
        )}

        {!(dividends && people) ?
                <>
                    <LoadingPlaceholder height="5rem"/>
                    <LoadingPlaceholder height="15rem"/>
                </>
            : v8Styling && dividends?.length===0 ?
                <BlankStateCard 
                    content={blankSlateInfo}  
                    loading={!blankSlateInfo}
                    warningMessage={
                        !dividendEligibility?.dividendsAvailable
                            ? 'You cannot issue a dividend at this time because your company currently has no shareholders.'
                            : dividendEligibility?.shareStructureNotSupported 
                                ? 'Your shareholder structure is not supported by your price plan, please contact us to discuss your requirements.'
                                : undefined
                    }
                    showContactLink={dividendEligibility?.dividendsAvailable && dividendEligibility.shareStructureNotSupported}
                    buttonEntity={dividendEligibility && dividendEligibility.dividendsAvailable && !dividendEligibility.shareStructureNotSupported
                                ? Entity.DividendV7
                                : undefined
                            }
                    buttonAction={Action.Create}
                />
            :
                <DividendListPageInner dividends={dividends} getFilter={getFilter} people={people}/>
        }

    </DefaultLayout>
    )
}

interface DividendsListPageInnerProps {
    dividends:Dividend[], 
    people: PersonWithDividendData[],
    getFilter: (yearCode: string | undefined) => Promise<DatePicker>
}

const DividendListPageInner = ({dividends, getFilter, people} : DividendsListPageInnerProps) => {
    const [dateSelection, setDateSelection] = useState<[Date | undefined, Date | undefined]>([undefined, undefined]);
	const [newMonth, setNewMonth] = useState<number | undefined | null>();
    const [yearCode, setYearCode] = useState<string>()
    const [shareholderFilter, setShareholderFilter] = useState<number|null>()
    const [isPaidFilter, setIsPaidFilter] = useState<boolean|null>()
    
    const filterMonthChanged = useCallback((startDate: Date, endDate: Date) => {
		setDateSelection([startDate, endDate]);
	}, []);

    const filterByMonth = (txn:Dividend) => {
        const txnDate = new Date(txn.date)
        return dateSelection[0] && dateSelection[1] && txnDate >= dateSelection[0] && txnDate <= dateSelection[1]
    }

    const filteredDividends = () => {
        if(dividends) {
            return dividends.filter(x => filterByMonth(x))
        }
        return []
    }

    const filteredLines = (lines:DividendLine[]) => {
        return lines.filter(x => (shareholderFilter ? x.personId === shareholderFilter : true) && 
            (isPaidFilter !== undefined && isPaidFilter !== null ? x.isPaid === isPaidFilter : true))
    }

    const peopleOptions = [
        {value: '0', label: 'All shareholders'},
        ...people.filter(x => x.isShareholder || x.hasDividends).map((x) => ({ value: x.id.toString(), label: x.name || x.id.toString() }))
    ]

    const statusOptions = [
        {value: '', label: 'Any status'},
        {value: 'true', label: 'Paid'},
        {value: 'false', label: 'Unpaid'}
    ]

    const navigate = useNavigateToEntity();

    const dateFilterLoaded = dateSelection !== undefined && dateSelection[0] !== undefined && dateSelection[1] !== undefined

    return (<div>
        <div className={styles.selectListFilter}>
            <Select
                classNamePrefix="SelectFilter"
                onChange={(value) => setShareholderFilter(parseInt(value?.value || "0"))}
                options={peopleOptions as any}
                defaultValue={{ value: '0', label: 'All shareholders' }}
            />
        </div>
        <div className={styles.selectListFilter}>
            <Select
                classNamePrefix="SelectFilter"
                onChange={(value) => setIsPaidFilter(value?.value === 'true' ? true : value?.value === 'false' ? false : null)}
                options={statusOptions as any}
                defaultValue={{ value: '', label: 'Any status' }}
            />
        </div>
        <Filter
            newMonth={newMonth}
            year={yearCode}
            getFilter={getFilter}
            yearChanged={setYearCode}
            monthChanged={filterMonthChanged}
            clearNewMonth={() => setNewMonth(null)}
        />
        <DataGrid.Table>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Shareholder</th>
                    <th style={{textAlign: 'right'}}>Shares</th>
                    <th style={{textAlign: 'right'}}>Payment</th>
                    <th style={{textAlign: 'right'}}>Tax credit</th>
                    <th style={{textAlign: 'right'}}>Status</th>
                </tr>
            </thead>
            <tbody>
                {dateFilterLoaded ? filteredDividends().map((dividend) => 
                    dividend.dividendLines && filteredLines(dividend.dividendLines).map((line, index) => 
                    <tr key={index} onClick={() => navigate(Entity.DividendV7, Action.Read, {lineId: line.id})}>
                        <td>{index === 0 ? formatDate(dividend.date) : ""}</td>
                        <td>{line.shareholder}</td>
                        <td style={{textAlign: 'right'}}>{line.shares}</td>
                        <td style={{textAlign: 'right'}}>{formatCurrency(line.amount)}</td>
                        <td style={{textAlign: 'right'}}>{formatCurrency(line.taxCredit)}</td>
                        <td style={{textAlign: 'right'}}>
                            <Badge color={line.isPaid ? "var(--green-dark)" : '#DDD'}>{line.isPaid ? "PAID" : "UNPAID"}</Badge>
                        </td>
                    </tr>)
                ) : <>
                    <DataGrid.LoadingRow cols={6}/>
                    <DataGrid.LoadingRow cols={6}/>
                    <DataGrid.LoadingRow cols={6}/>
                </>}

                {dateFilterLoaded && dividends.length > 0 &&
                    filteredDividends().every(item => !item.dividendLines || filteredLines(item.dividendLines).length === 0) && (
                    <tr>
                        <td colSpan={6} style={{ padding: 0 }}>
                            <NoContentSlate type={DatagridType['Dividends']} termsKey="noResultsTerms" />
                        </td>
                    </tr>
                )}

                {dateFilterLoaded && dividends.length === 0 && <tr>
                    <td colSpan={6} style={{ padding: 0 }}>
                        <NoContentSlate type={DatagridType['Dividends']} termsKey="emptyTerms" />
                    </td>
                </tr>}
            </tbody>
        </DataGrid.Table>
    </div>)
}

export default DividendListPage