import { Datum, ResponsiveLine } from '@nivo/line'
import { ChartData2 } from '../../../api/inni/data-contracts'
import { formatCurrency } from '../../../utils/formatNumbers'

const CashBalance = ({chartData} : {chartData : ChartData2}) => {    
    //Watch out! - Need to avoid calling setState in a function used in rendering
    //https://stackoverflow.com/questions/45358974/react-setstate-in-promise-causing-infinite-loop/45359089
    
    let data : Datum[] = []
    let min = 0
    let max = 0
    chartData?.cashflow?.map(x => {
        if (!min || x.value < min)
            min = x.value
        if (!max || x.value > max)
            max = x.value
        data.push({ x: x.label, y: x.value })
    })

    return (
        <div style={{height: '300px', width: '100%'}}>
            <ResponsiveLine 
                data={[{id: "cashBal", data: data}]} 
                margin={{ bottom: 50, left: 80, right: 20 }}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: min-100, max: max+100, stacked: true, reverse: false }}
                pointSize={10}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                axisLeft={{format: (x) => formatCurrency(x as number)}}
                axisBottom={{ tickRotation: -45 }}
                enableGridY
                isInteractive
                useMesh
                enableCrosshair={false}
            />
        </div>
    )
}

export default CashBalance