import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Formik, FormikErrors } from 'formik'
import { useContext } from 'react'
import { PayePaymentPostModel, SetupWizardForm } from '../../../api/inni/data-contracts'
import { SetupWizard } from '../../../api/inni/SetupWizard'
import CompanyContext from '../../../context/CompanyContext'
import { Button } from '../../../elements/Button/Button'
import { Currency } from '../../../elements/EditRow/EditRow'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { formatDate } from '../../../utils/formatDate'
import styles from '../SetupWizard.module.css'
import { WarningText } from './WarningText'

const PayePayments = ({setNextPage, formState, setPrevPage} : {setNextPage : (fs?: SetupWizardForm) => void, formState : SetupWizardForm, setPrevPage : () => void}) => {

    const swAPI = useInniAPI(SetupWizard, [400])
    const companyContext = useContext(CompanyContext)

    const savePayePayments = (values : PayePaymentPostModel) => {
        if (swAPI) {
            swAPI.updatePayePayments(companyContext.cid, values)
            .then(res => {
                if (res.status === 200) {
                    setNextPage({...formState, ...values})
                } else {
                    // err
                }
            })
        }
    }

    const validatePayePayments = (values : PayePaymentPostModel) : Promise<FormikErrors<PayePaymentPostModel>> => {
        return new Promise<FormikErrors<PayePaymentPostModel>>((resolve, reject) => {
            if (swAPI) {
                swAPI?.validateUpdatePayePayments(companyContext.cid, values).then(x => {
                    resolve({})
                }).catch(err => resolve(err.error))
            } else {
                reject()
            }            
        })
    }


    return (
        <div>
            <Formik
                initialValues={formState as PayePaymentPostModel}
                validateOnChange={false}
                enableReinitialize
                onSubmit={savePayePayments}
                validate={validatePayePayments}
            >
                {() => (

                    <Form>
                        
                        <p style={{cursor: 'pointer'}} onClick={setPrevPage}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
                        <h1>PAYE payments</h1>
                        <p>Please submit the following information in order to pay tax and national insurance contributions to HM Revenue & Customs, you will need your yellow Employer Payslip Booklet. <br />
                        If you do not yet have the required information from HMRC please enter '0' in the boxes below.</p>
                        <hr />
                        {formState.setupPAYEPayments ? 
                        <>
                            <p>PAYE payments for this company have already been confirmed, please reset the wizard on the confirmation page to make changes.</p>
                            <div className={styles.formButtons}>
                                <Button onClick={setNextPage} variant="change">Continue</Button>
                            </div>
                        </> : 
                        <>
                            <p style={{color:'red'}}><b>DO NOT FORGET TO INCLUDE THE EMPLOYER ALLOWANCE!</b></p>
                            <hr />
                            <p>Are there PAYE payments due <b>before</b> the migration date, but not on your bank statements at {formatDate(formState.setupMigrationDate)}? <br />
                            If so, please enter the amount below. This goes into the <u>PAYE Liability account</u>.</p>
                            <p>Payments for returns before {formatDate(formState.setupMigrationDate)} but not on your bank statement <b>would generally have the employer allowance deducted</b>.</p>
                            <Currency<PayePaymentPostModel> name="unpaidPayePayment" label="Unpaid PAYE Payment" />
                            <p>If you have issued any payslips before {formatDate(formState.setupMigrationDate)} that have not appeared on a PAYE return, please enter the amount of PAYE due below. <b>Do not forget to deduct any employer allowance claimed.</b> <br />
                            This goes into the <u>PAYE IN PERIOD account</u> and will be transfered to the PAYE liability account when the next PAYE payment is calculated. </p>
                            <Currency<PayePaymentPostModel> name="payeNotSent" label={`Payments prior to ${formatDate(formState.setupMigrationDate)} but not on a return`} />
                            <div className={styles.formButtons}>
                                <Button submitButton variant="change">Continue</Button>
                            </div>
                            <WarningText />
                        </>}
                    </Form>

                )}
            
            
            </Formik>
        </div>
    )
}

export default PayePayments