import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faHouse, faBuilding, faDoorClosed, faConciergeBell, faSuitcase } from '@fortawesome/pro-regular-svg-icons'
import placeholderImage from './placeholder.svg';
import placeholderImageV8 from './placeholder_v8.svg';
import { lettingTypes, lettingTypesType } from '../commonLists';

export interface LettingTypeMeta {
    name: string,
    icon: IconDefinition
}

export const formatLettingType = (lettingType: lettingTypesType | undefined) => {
    switch(lettingType) {
        case 'ResidentialSingleUnit':
            return {
                name: 'Residential',
                icon: faHouse
            };
        case 'HmoRoom':
            return {
                name: 'HMO room',
                icon: faDoorClosed
            };
        case 'Commercial':
            return {
                name: 'Commercial',
                icon: faBuilding
            };
        case 'HolidayLet':
            return {
                name: 'Holiday let',
                icon: faSuitcase
            };
        case 'ServicedAccommodation':
            return {
                name: 'Serviced accomodation',
                icon: faConciergeBell
            };
        case 'Other':
            return {
                name: 'Other',
                icon: faHouse
            };
        default:
            return {
                name: '',
                icon: faHouse
        };
    }
     
}

export const lettingTypesToListOptions = (showNullDefault: boolean = false) => {
    if (showNullDefault)
        return [
            {value: null, label: 'Please select...'},
            ...lettingTypes.map((entry) => ({value: entry, label: formatLettingType(entry).name}))
        ];

    return lettingTypes.map((entry) => ({value: entry, label: formatLettingType(entry).name}));
}

export const formatTenure = (tenure: string | undefined) => {
    return tenure === 'RentToRent' ? 'Rent-to-rent' : tenure;
}

export const formatTenureLifecycleStatus = (status: string | undefined) => {
    return status === 'UnderOffer' ? 'Under offer' : status;
}

export {placeholderImage};
export {placeholderImageV8};
