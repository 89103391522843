import {Tab, Tabs} from '../../../elements/HorizontalTabs/HorizontalTabs'
import {Contract, Person} from '../../../api/inni/data-contracts'
import {useHasPermission} from '../../../hooks/useHasPermission'
import {Action, Entity} from '../../../utils/EntityAction'
import Expenses from './Expenses'
import MileageView from './MileageView'
import TimeWeekView from './TimeWeekView'
import {useContext, useState} from 'react'
import {useFetchEntityList} from '../../../hooks/entities/useFetchEntityList'
import {Contracts} from '../../../api/inni/Contracts'
import {LoadingShim} from '../../../elements/LoadingShim/LoadingShim'
import CompanyContext from "../../../context/CompanyContext";

interface EmployeeTabProps {
    curEmployeeId:number|undefined, 
    curEmployeeName: string | undefined, 
    person: Person | undefined, 
    peopleLoaded : boolean
}

const EmployeeTab = ({curEmployeeId, curEmployeeName, person, peopleLoaded} : EmployeeTabProps) => {

    const [contracts, contractsLoaded] = useFetchEntityList<Contract, Contracts>(Contracts)

    const [mileageViewed, setMileageViewed] = useState(false) // we don't load mileage until it's visited, BUT we don't want to reload it everytime...

    const [currentView, setCurrentView] = useState('timesexp');
    const hasPerm = useHasPermission();
    const companyContext = useContext(CompanyContext);
    const v8Styling = (companyContext.company?.useV8UI);

    //ATM this code is used for v8 mileage page and V7 qe/mileage
    //TODO If they don't have both, don't bother with tabs
    //ATM you get mileage only (expenses being v8ey) on v8 advisory. This might go away if mileage is V8ed elsewhere
    //This is a bit of a hack to get the V7 mileage components in on V8 finances to test other functionality pending a proper V8 mileage UI
    //So might go away shortly
    return (!v8Styling?
        <>
            <div data-cy="tabs">
                <Tabs>
                    <Tab
                        onClick={setCurrentView}
                        selected={currentView === "timesexp"}
                        keyName="timesexp"
                        title={hasPerm(Entity.Time, Action.All)[0] ? 'Time and expenses' : 'Expenses'}
                    />
                    <Tab
                        onClick={() => {setCurrentView('mileage'); setMileageViewed(true)}}
                        selected={currentView === "mileage"}
                        keyName="mileage"
                        title="Mileage"
                    />
                </Tabs>
            </div>
            {/* Set display to none to avoid reloading if switching between tabs */}
            { !contractsLoaded &&
                <LoadingShim />
            }
            { contractsLoaded && curEmployeeId !== undefined && (
                <>
                    <div style={{display: currentView === 'timesexp' ? 'block' : 'none'}}>
                        {
                            hasPerm(Entity.Time, Action.All)[0] && <TimeWeekView contracts={contracts} curEmployeeId={curEmployeeId} />
                        }
                        <Expenses employeeName={curEmployeeName} employeeId={curEmployeeId} />
                    </div>
                    {(currentView === 'mileage' || mileageViewed) && (
                        // keep mileage rendered (but displays none) so we don't reload data after the first time we change tab
                        <div style={{display: currentView === 'mileage' ? 'block' : 'none'}}>
                            <MileageView contractsData={contracts} person={person} personId={curEmployeeId} peopleLoaded={peopleLoaded} />
                        </div>
                    )}
                </>
            )}
        </>
            :
        <>
            {/* Set display to none to avoid reloading if switching between tabs */}
            { !contractsLoaded &&
                <LoadingShim />
            }
            { contractsLoaded && curEmployeeId !== undefined && (
                <MileageView contractsData={contracts} person={person} personId={curEmployeeId}
                             peopleLoaded={peopleLoaded}/>
            )}
        </>
    )
}

export default EmployeeTab