export const lettingTypes = [
  'ResidentialSingleUnit',
  'HmoRoom',
  'Commercial',
  'HolidayLet',
  'ServicedAccommodation',
  'Other'
] as const;

export type lettingTypesType = typeof lettingTypes[number];

export const payPeriodTypes = [
  { label: 'Weekly', value: 'W' },
  { label: 'Monthly', value: 'M' }
]

export type payPeriodType = typeof payPeriodTypes[number]['value']

export const countryCodes = [
  { name: "United Kingdom", code:"GB" },
  { name: "Austria", code:"AT" },
  { name: "Belgium", code:"BE" },
  { name: "Bulgaria", code:"BG" },
  { name: "Croatia", code:"HR" },
  { name: "Cyprus", code:"CY" },
  { name: "Czech Republic", code:"CZ" },
  { name: "Denmark", code:"DK" },
  { name: "Estonia", code:"EE" },
  { name: "Finland", code:"FI" },
  { name: "France", code:"FR" },
  { name: "Germany", code:"DE" },
  { name: "Greece", code:"GR" },
  { name: "Hungary", code:"HU" },
  { name: "Ireland", code:"IE" },
  { name: "Italy", code:"IT" },
  { name: "Latvia", code:"LV" },
  { name: "Lithuania", code:"LT" },
  { name: "Luxembourg", code:"LU" },
  { name: "Malta", code:"MT" },
  { name: "Netherlands", code:"NL" },
  { name: "Poland", code:"PL" },
  { name: "Portugal", code:"PT" },
  { name: "Romania", code:"RO" },
  { name: "Slovakia", code:"SK" },
  { name: "Slovenia", code:"SI" },
  { name: "Spain", code:"ES" },
  { name: "Sweden", code:"SE" },
  { name: "Afghanistan", code:"AF" },
  { name: "Åland Islands", code:"AX" },
  { name: "Albania", code:"AL" },
  { name: "Algeria", code:"DZ" },
  { name: "American Samoa", code:"AS" },
  { name: "Andorra", code:"AD" },
  { name: "Angola", code:"AO" },
  { name: "Anguilla", code:"AI" },
  { name: "Antarctica", code:"AQ" },
  { name: "Antigua and Barbuda", code:"AG" },
  { name: "Argentina", code:"AR" },
  { name: "Armenia", code:"AM" },
  { name: "Aruba", code:"AW" },
  { name: "Australia", code:"AU" },
  { name: "Azerbaijan", code:"AZ" },
  { name: "Bahamas", code:"BS" },
  { name: "Bahrain", code:"BH" },
  { name: "Bangladesh", code:"BD" },
  { name: "Barbados", code:"BB" },
  { name: "Belarus", code:"BY" },
  { name: "Belize", code:"BZ" },
  { name: "Benin", code:"BJ" },
  { name: "Bermuda", code:"BM" },
  { name: "Bhutan", code:"BT" },
  { name: "Bolivia, Plurinational State of", code:"BO" },
  { name: "Bonaire, Sint Eustatius and Saba", code:"BQ" },
  { name: "Bosnia and Herzegovina", code:"BA" },
  { name: "Botswana", code:"BW" },
  { name: "Bouvet Island", code:"BV" },
  { name: "Brazil", code:"BR" },
  { name: "British Indian Ocean Territory", code:"IO" },
  { name: "Brunei Darussalam", code:"BN" },
  { name: "Burkina Faso", code:"BF" },
  { name: "Burundi", code:"BI" },
  { name: "Cambodia", code:"KH" },
  { name: "Cameroon", code:"CM" },
  { name: "Canada", code:"CA" },
  { name: "Cape Verde", code:"CV" },
  { name: "Cayman Islands", code:"KY" },
  { name: "Central African Republic", code:"CF" },
  { name: "Chad", code:"TD" },
  { name: "Chile", code:"CL" },
  { name: "China", code:"CN" },
  { name: "Christmas Island", code:"CX" },
  { name: "Cocos (Keeling) Islands", code:"CC" },
  { name: "Colombia", code:"CO" },
  { name: "Comoros", code:"KM" },
  { name: "Congo", code:"CG" },
  { name: "Congo, the Democratic Republic of the", code:"CD" },
  { name: "Cook Islands", code:"CK" },
  { name: "Costa Rica", code:"CR" },
  { name: "Côte d'Ivoire", code:"CI" },
  { name: "Cuba", code:"CU" },
  { name: "Curaçao", code:"CW" },
  { name: "Djibouti", code:"DJ" },
  { name: "Dominica", code:"DM" },
  { name: "Dominican Republic", code:"DO" },
  { name: "Ecuador", code:"EC" },
  { name: "Egypt", code:"EG" },
  { name: "El Salvador", code:"SV" },
  { name: "Equatorial Guinea", code:"GQ" },
  { name: "Eritrea", code:"ER" },
  { name: "Ethiopia", code:"ET" },
  { name: "Falkland Islands (Malvinas)", code:"FK" },
  { name: "Faroe Islands", code:"FO" },
  { name: "Fiji", code:"FJ" },
  { name: "French Guiana", code:"GF" },
  { name: "French Polynesia", code:"PF" },
  { name: "French Southern Territories", code:"TF" },
  { name: "Gabon", code:"GA" },
  { name: "Gambia", code:"GM" },
  { name: "Georgia", code:"GE" },
  { name: "Ghana", code:"GH" },
  { name: "Gibraltar", code:"GI" },
  { name: "Greenland", code:"GL" },
  { name: "Grenada", code:"GD" },
  { name: "Guadeloupe", code:"GP" },
  { name: "Guam", code:"GU" },
  { name: "Guatemala", code:"GT" },
  { name: "Guernsey", code:"GG" },
  { name: "Guinea", code:"GN" },
  { name: "Guinea-Bissau", code:"GW" },
  { name: "Guyana", code:"GY" },
  { name: "Haiti", code:"HT" },
  { name: "Heard Island and McDonald Islands", code:"HM" },
  { name: "Holy See (Vatican City State)", code:"VA" },
  { name: "Honduras", code:"HN" },
  { name: "Hong Kong", code:"HK" },
  { name: "Iceland", code:"IS" },
  { name: "India", code:"IN" },
  { name: "Indonesia", code:"ID" },
  { name: "Iran, Islamic Republic of", code:"IR" },
  { name: "International Waters", code:"IW" },
  { name: "Iraq", code:"IQ" },
  { name: "Isle of Man", code:"IM" },
  { name: "Israel", code:"IL" },
  { name: "Jamaica", code:"JM" },
  { name: "Japan", code:"JP" },
  { name: "Jersey", code:"JE" },
  { name: "Jordan", code:"JO" },
  { name: "Kazakhstan", code:"KZ" },
  { name: "Kenya", code:"KE" },
  { name: "Kiribati", code:"KI" },
  { name: "Korea, Democratic People's Republic of", code:"KP" },
  { name: "Korea, Republic of", code:"KR" },
  { name: "Kuwait", code:"KW" },
  { name: "Kyrgyzstan", code:"KG" },
  { name: "Lao People's Democratic Republic", code:"LA" },
  { name: "Lebanon", code:"LB" },
  { name: "Lesotho", code:"LS" },
  { name: "Liberia", code:"LR" },
  { name: "Libya", code:"LY" },
  { name: "Liechtenstein", code:"LI" },
  { name: "Macao", code:"MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code:"MK" },
  { name: "Madagascar", code:"MG" },
  { name: "Malawi", code:"MW" },
  { name: "Malaysia", code:"MY" },
  { name: "Maldives", code:"MV" },
  { name: "Mali", code:"ML" },
  { name: "Marshall Islands", code:"MH" },
  { name: "Martinique", code:"MQ" },
  { name: "Mauritania", code:"MR" },
  { name: "Mauritius", code:"MU" },
  { name: "Mayotte", code:"YT" },
  { name: "Mexico", code:"MX" },
  { name: "Micronesia, Federated States of", code:"FM" },
  { name: "Moldova, Republic of", code:"MD" },
  { name: "Monaco", code:"MC" },
  { name: "Mongolia", code:"MN" },
  { name: "Montenegro", code:"ME" },
  { name: "Montserrat", code:"MS" },
  { name: "Morocco", code:"MA" },
  { name: "Mozambique", code:"MZ" },
  { name: "Myanmar", code:"MM" },
  { name: "Namibia", code:"NA" },
  { name: "Nauru", code:"NR" },
  { name: "Nepal", code:"NP" },
  { name: "New Caledonia", code:"NC" },
  { name: "New Zealand", code:"NZ" },
  { name: "Nicaragua", code:"NI" },
  { name: "Niger", code:"NE" },
  { name: "Nigeria", code:"NG" },
  { name: "Niue", code:"NU" },
  { name: "Norfolk Island", code:"NF" },
  { name: "Northern Mariana Islands", code:"MP" },
  { name: "Norway", code:"NO" },
  { name: "Oman", code:"OM" },
  { name: "Pakistan", code:"PK" },
  { name: "Palau", code:"PW" },
  { name: "Palestine, State of", code:"PS" },
  { name: "Panama", code:"PA" },
  { name: "Papua New Guinea", code:"PG" },
  { name: "Paraguay", code:"PY" },
  { name: "Peru", code:"PE" },
  { name: "Philippines", code:"PH" },
  { name: "Pitcairn", code:"PN" },
  { name: "Puerto Rico", code:"PR" },
  { name: "Qatar", code:"QA" },
  { name: "Réunion", code:"RE" },
  { name: "Russian Federation", code:"RU" },
  { name: "Rwanda", code:"RW" },
  { name: "Saint Barthélemy", code:"BL" },
  { name: "Saint Helena, Ascension and Tristan da Cunha", code:"SH" },
  { name: "Saint Kitts and Nevis", code:"KN" },
  { name: "Saint Lucia", code:"LC" },
  { name: "Saint Martin (French part)", code:"MF" },
  { name: "Saint Pierre and Miquelon", code:"PM" },
  { name: "Saint Vincent and the Grenadines", code:"VC" },
  { name: "Samoa", code:"WS" },
  { name: "San Marino", code:"SM" },
  { name: "Sao Tome and Principe", code:"ST" },
  { name: "Saudi Arabia", code:"SA" },
  { name: "Senegal", code:"SN" },
  { name: "Serbia", code:"RS" },
  { name: "Seychelles", code:"SC" },
  { name: "Sierra Leone", code:"SL" },
  { name: "Singapore", code:"SG" },
  { name: "Sint Maarten (Dutch part)", code:"SX" },
  { name: "Solomon Islands", code:"SB" },
  { name: "Somalia", code:"SO" },
  { name: "South Africa", code:"ZA" },
  { name: "South Georgia and the South Sandwich Islands", code:"GS" },
  { name: "South Sudan", code:"SS" },
  { name: "Sri Lanka", code:"LK" },
  { name: "Sudan", code:"SD" },
  { name: "Suriname", code:"SR" },
  { name: "Svalbard and Jan Mayen", code:"SJ" },
  { name: "Swaziland", code:"SZ" },
  { name: "Switzerland", code:"CH" },
  { name: "Syrian Arab Republic", code:"SY" },
  { name: "Taiwan, Province of China", code:"TW" },
  { name: "Tajikistan", code:"TJ" },
  { name: "Tanzania, United Republic of", code:"TZ" },
  { name: "Thailand", code:"TH" },
  { name: "Timor-Leste", code:"TL" },
  { name: "Togo", code:"TG" },
  { name: "Tokelau", code:"TK" },
  { name: "Tonga", code:"TO" },
  { name: "Trinidad and Tobago", code:"TT" },
  { name: "Tunisia", code:"TN" },
  { name: "Turkey", code:"TR" },
  { name: "Turkmenistan", code:"TM" },
  { name: "Turks and Caicos Islands", code:"TC" },
  { name: "Tuvalu", code:"TV" },
  { name: "Uganda", code:"UG" },
  { name: "Ukraine", code:"UA" },
  { name: "United Arab Emirates", code:"AE" },
  { name: "United States", code:"US" },
  { name: "United States Minor Outlying Islands", code:"UM" },
  { name: "Uruguay", code:"UY" },
  { name: "Uzbekistan", code:"UZ" },
  { name: "Vanuatu", code:"VU" },
  { name: "Venezuela, Bolivarian Republic of", code:"VE" },
  { name: "Viet Nam", code:"VN" },
  { name: "Virgin Islands, British", code:"VG" },
  { name: "Virgin Islands, U.S.", code:"VI" },
  { name: "Wallis and Futuna", code:"WF" },
  { name: "Western Sahara", code:"EH" },
  { name: "Yemen", code:"YE" },
  { name: "Zambia", code:"ZM" },
  { name: "Zimbabwe", code:"ZW" },
];

export type countryCodesType = typeof countryCodes[number]["code"];

export const nationalityCodes = [
  { code: "GB", name: "British"},
  { code: "US", name: "American"},
  { code: "AF", name: "Afghan"},
  { code: "AL", name: "Albanian"},
  { code: "DZ", name: "Algerian"},
  { code: "AS", name: "American Samoan"},
  { code: "AD", name: "Andorran"},
  { code: "AE", name: "Emirati"},
  { code: "AO", name: "Angolan"},
  { code: "AI", name: "Anguillan"},
  { code: "AG", name: "Antiguan/Barbudan"},
  { code: "AR", name: "Argentinean"},
  { code: "AM", name: "Armenian"},
  { code: "AW", name: "Aruban"},
  { code: "AU", name: "Australian"},
  { code: "AT", name: "Austrian"},
  { code: "AZ", name: "Azerbaijani"},
  { code: "BS", name: "Bahamian"},
  { code: "BH", name: "Bahraini"},
  { code: "BD", name: "Bangladeshi"},
  { code: "BB", name: "Barbadian"},
  { code: "BY", name: "Belarusian"},
  { code: "BE", name: "Belgian"},
  { code: "BZ", name: "Belizean"},
  { code: "BJ", name: "Beninese"},
  { code: "BM", name: "Bermudian"},
  { code: "BT", name: "Bhutanese"},
  { code: "BA", name: "Bosnian/Herzegovinian"},
  { code: "BW", name: "Motswana"},
  { code: "BR", name: "Brazilian"},
  { code: "BG", name: "Bulgarian"},
  { code: "BI", name: "Burundian"},
  { code: "KH", name: "Cambodian"},
  { code: "CM", name: "Cameroonian"},
  { code: "CA", name: "Canadian"},
  { code: "CV", name: "Cape Verdean"},
  { code: "KY", name: "Caymanian"},
  { code: "CF", name: "Central African"},
  { code: "TD", name: "Chadian"},
  { code: "CL", name: "Chilean"},
  { code: "CN", name: "Chinese"},
  { code: "CX", name: "Christmas Island"},
  { code: "CC", name: "Cocos Island"},
  { code: "CO", name: "Colombian"},
  { code: "KM", name: "Comorian"},
  { code: "CK", name: "Cook Island"},
  { code: "CR", name: "Costa Rican"},
  { code: "CI", name: "Ivorian"},
  { code: "HR", name: "Croatian"},
  { code: "CU", name: "Cuban"},
  { code: "CY", name: "Cypriot"},
  { code: "CZ", name: "Czech"},
  { code: "DK", name: "Danish"},
  { code: "DJ", name: "Djiboutian"},
  { code: "DM", name: "Dominicand"},
  { code: "DO", name: "Dominicane"},
  { code: "EC", name: "Ecuadorian"},
  { code: "EG", name: "Egyptian"},
  { code: "SV", name: "Salvadoran"},
  { code: "GQ", name: "Equatorial Guinean"},
  { code: "ER", name: "Eritrean"},
  { code: "EE", name: "Estonian"},
  { code: "ET", name: "Ethiopian"},
  { code: "FO", name: "Faroese"},
  { code: "FJ", name: "Fijian"},
  { code: "FI", name: "Finnish"},
  { code: "FR", name: "French"},
  { code: "GF", name: "French Guianese"},
  { code: "PF", name: "French Polynesian"},
  { code: "GA", name: "Gabonese"},
  { code: "GM", name: "Gambian"},
  { code: "GE", name: "Georgian"},
  { code: "DE", name: "German"},
  { code: "GH", name: "Ghanaian"},
  { code: "GI", name: "Gibraltar"},
  { code: "GR", name: "Greek"},
  { code: "GL", name: "Greenlandic"},
  { code: "GD", name: "Grenadian"},
  { code: "GP", name: "Guadeloupe"},
  { code: "GU", name: "Guamanian"},
  { code: "GT", name: "Guatemalan"},
  { code: "GN", name: "Guinean"},
  { code: "GW", name: "Guinean"},
  { code: "GY", name: "Guyanese"},
  { code: "HT", name: "Haitian"},
  { code: "HN", name: "Honduran"},
  { code: "HK", name: "Hongkongese"},
  { code: "HU", name: "Hungarian"},
  { code: "IS", name: "Icelandic"},
  { code: "IN", name: "Indian"},
  { code: "ID", name: "Indonesian"},
  { code: "IQ", name: "Iraqi"},
  { code: "IE", name: "Irish"},
  { code: "IM", name: "Manx"},
  { code: "IL", name: "Israeli"},
  { code: "IT", name: "Italian"},
  { code: "JM", name: "Jamaican"},
  { code: "JP", name: "Japanese"},
  { code: "JO", name: "Jordanian"},
  { code: "KZ", name: "Kazakh"},
  { code: "KE", name: "Kenyan"},
  { code: "KI", name: "I-Kiribati"},
  { code: "KW", name: "Kuwaiti"},
  { code: "KG", name: "Kyrgyzstani"},
  { code: "LV", name: "Latvian"},
  { code: "LB", name: "Lebanese"},
  { code: "LS", name: "Basotho"},
  { code: "LR", name: "Liberian"},
  { code: "LY", name: "Libyan"},
  { code: "LI", name: "Liechtenstein"},
  { code: "LT", name: "Lithuanian"},
  { code: "LU", name: "Luxembourgish"},
  { code: "MG", name: "Malagasy"},
  { code: "MW", name: "Malawian"},
  { code: "MY", name: "Malaysian"},
  { code: "MV", name: "Maldivian"},
  { code: "ML", name: "Malian"},
  { code: "MT", name: "Maltese"},
  { code: "MH", name: "Marshallese"},
  { code: "MQ", name: "Martiniquais"},
  { code: "MR", name: "Mauritanian"},
  { code: "MU", name: "Mauritian"},
  { code: "YT", name: "Mahoran"},
  { code: "MX", name: "Mexican"},
  { code: "FM", name: "Micronesian"},
  { code: "MC", name: "Monégasque, Monacan"},
  { code: "MN", name: "Mongolian"},
  { code: "ME", name: "Montenegrin"},
  { code: "MS", name: "Montserratian"},
  { code: "MA", name: "Moroccan"},
  { code: "MZ", name: "Mozambican"},
  { code: "NA", name: "Namibian"},
  { code: "NR", name: "Nauruan"},
  { code: "NP", name: "Nepali"},
  { code: "NL", name: "Dutch"},
  { code: "NC", name: "New Caledonian"},
  { code: "NZ", name: "New Zealand"},
  { code: "NI", name: "Nicaraguan"},
  { code: "NE", name: "Nigerien"},
  { code: "NG", name: "Nigerian"},
  { code: "NU", name: "Niuean"},
  { code: "NO", name: "Norwegian"},
  { code: "OM", name: "Omani"},
  { code: "PK", name: "Pakistani"},
  { code: "PW", name: "Palauan"},
  { code: "PA", name: "Panamanian"},
  { code: "PG", name: "Papua New Guinean"},
  { code: "PY", name: "Paraguayan"},
  { code: "PE", name: "Peruvian"},
  { code: "PH", name: "Filipino"},
  { code: "PL", name: "Polish"},
  { code: "PT", name: "Portuguese"},
  { code: "PR", name: "Puerto Rican"},
  { code: "QA", name: "Qatari"},
  { code: "RE", name: "Réunionese"},
  { code: "RO", name: "Romanian"},
  { code: "RW", name: "Rwandan"},
  { code: "WS", name: "Samoan"},
  { code: "SM", name: "Sammarinese"},
  { code: "SA", name: "Saudi Arabian"},
  { code: "SN", name: "Senegalese"},
  { code: "RS", name: "Serbian"},
  { code: "SC", name: "Seychellois"},
  { code: "SL", name: "Sierra Leonean"},
  { code: "SG", name: "Singapore"},
  { code: "SK", name: "Slovak"},
  { code: "SI", name: "Slovenian"},
  { code: "SB", name: "Solomon Island"},
  { code: "SO", name: "Somalian"},
  { code: "ZA", name: "South African"},
  { code: "SS", name: "South Sudanese"},
  { code: "ES", name: "Spanish"},
  { code: "LK", name: "Sri Lankan"},
  { code: "SD", name: "Sudanese"},
  { code: "SZ", name: "Swazi"},
  { code: "SE", name: "Swedish"},
  { code: "CH", name: "Swiss"},
  { code: "TJ", name: "Tajikistani"},
  { code: "TH", name: "Thai"},
  { code: "TG", name: "Togolese"},
  { code: "TO", name: "Tongan"},
  { code: "TT", name: "Trinidadian"},
  { code: "TN", name: "Tunisian"},
  { code: "TR", name: "Turkish"},
  { code: "TM", name: "Turkmen"},
  { code: "TV", name: "Tuvaluan"},
  { code: "UG", name: "Ugandan"},
  { code: "UA", name: "Ukrainian"},
  { code: "UY", name: "Uruguayan"},
  { code: "UZ", name: "Uzbekistani"},
  { code: "VE",  name: "Venezuelan"},
  { code: "VU", name: "Ni-Vanuatu"},
  { code: "WF", name: "Wallisian/Futunan"},
  { code: "EH", name: "Sahrawian"},
  { code: "YE", name: "Yemeni"},
  { code: "ZM", name: "Zambian"},
  { code: "ZW", name: "Zimbabwean"}
];

export type nationalityCodesType = typeof countryCodes[number]["code"];

export interface Month {
  value: string,
  label: string,
  days: number,
  monthNumber: number
}

export const months: Month[] = [
  { value: "january", label: "January", days: 31, monthNumber: 1 },
  { value: "february", label: "February", days: 28, monthNumber: 2 },
  { value: "march", label: "March", days: 31, monthNumber: 3 },
  { value: "april", label: "April", days: 30, monthNumber: 4 },
  { value: "may", label: "May", days: 31, monthNumber: 5 },
  { value: "june", label: "June", days: 30, monthNumber: 6 },
  { value: "july", label: "July", days: 31, monthNumber: 7 },
  { value: "august", label: "August", days: 31, monthNumber: 8 },
  { value: "september", label: "September", days: 30, monthNumber: 9 },
  { value: "october", label: "October", days: 31, monthNumber: 10 },
  { value: "november", label: "November", days: 30, monthNumber: 11 },
  { value: "december", label: "December", days: 31, monthNumber: 12 }
];

export const getMonthByNumber = (monthNum:number) => {
  return months.find(x => x.monthNumber === monthNum) || months[0]
}