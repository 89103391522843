import { useState, lazy, Suspense, useContext, useEffect } from "react";
import PillTabs from "../../components/PillTabs/PillTabs";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout";
import { Tab } from "react-bootstrap";
import { Entity } from "../../utils/EntityAction";
import { useParams } from "react-router-dom";
import CompanyContext from "../../context/CompanyContext";
import { useHistoryWrapper } from "../../hooks/useHistoryWrapper";
import CorporationTax from "../CorporationTax/CorporationTax";
import styles from './Bookkeeping2022.module.scss';

const BookkeepingV8 = lazy(() => import("./Bookkeeping2022"));
const AgentStatements = lazy(() => import("../Invoices/AgentStatements/AgentStatements"));
const DividendListPage = lazy(() => import("../Dividends/DividendListPage"));
const ReportSelectPage = lazy(() => import("../Reporting/ReportSelectPage"));
const Adjustments = lazy(() => import("../Adjustments/Adjustments"));
const PersonalTaxPlanner = lazy(() => import("../PersonalTaxPlanner/PersonalTaxPlanner"));
const AccountEdit = lazy(() => import("../AccountEdit/AccountEdit"));
const Mileage = lazy(() => import("../QuickEntry/Components/Mileage v8/Mileage"));

interface TabItem {
    eventKey: string;
    title: string;
    adminOnly: boolean;
}

export enum FinanceMenuEnum{
    BANK_ACCOUNTS = 'bankaccounts',
    EXPENSES = 'expenses',
    MILEAGE = 'mileage',
    LETTING_STATEMENT = 'lettingstatements',
    DIVIDENDS = 'dividends',
    REPORTS = 'reports',
    DIRECTORS_LOANS = 'directorsloans',
    ADJUSTMENTS = 'adjustments',
    OPEN_BANKING = 'openbanking',
    MORTGAGE='mortgages',
    CORPORATION_TAX='corporationtax',
    PERSONAL_TAX_PLANNER='personaltaxplanner',
    ACCOUNTS = "accounts"
}

interface RouteParams {
    activePage: string
}

export default function FinanceMenu(){
    const companyContext = useContext(CompanyContext);
    const activePage: string =useParams<RouteParams>().activePage
    const [activeTab, setActiveTab] = useState<FinanceMenuEnum>(getTabName(activePage));
    const history = useHistoryWrapper();
    const [showExpandedView, setShowExpandedView] = useState(false);

    useEffect(() =>{
        const tabName = getTabName(activePage);
        setActiveTab(tabName);
    }, [activePage])

    function GetMenu() {
        let tabs: Array<TabItem>= [];
        if(companyContext && companyContext.navMenu && companyContext.navMenu.navItems){
            companyContext.navMenu.navItems.find(x => x.name === "Finances")?.v8TopNavTabs?.forEach((item) => {
                tabs.push({ eventKey: getTabName(item.name?.replaceAll(" ", "").replaceAll('\'', '').toLowerCase() || ""), 
                            title: item.name || "", 
                            adminOnly: item.isAdminOnly 
                        });
            }); 
        }
        return tabs;
    }

    const handleTabSelect = (value: string | null) => {
        let selectedTab = getTabName(value || "bankaccounts" );
        history.push(`/company/${companyContext.cid}/finances/${selectedTab}`);
    };

    function getTabName(value: string): FinanceMenuEnum {
        if (Object.values(FinanceMenuEnum).includes(value as FinanceMenuEnum))
            return (value as FinanceMenuEnum)
        else 
            return FinanceMenuEnum.BANK_ACCOUNTS
    }

    return(
        <DefaultLayout
            title={showExpandedView ? undefined : 'Finances'}
            entity={Entity.BookkeepingV8}
            useFullWidth
        >
            { !showExpandedView && (
                <PillTabs
                    defaultActiveKey={activeTab}
                    onSelect={handleTabSelect}
                >
                    { GetMenu().map((tab) =>
                        <Tab 
                            key={tab.eventKey} 
                            eventKey={tab.eventKey} 
                            title={tab.adminOnly
                                ? <span className={styles.adminOnly} onClick={(e) => e.preventDefault()}>{tab.title}</span> 
                                : tab.title
                            }
                        />
                    )}
                </PillTabs> 
            )}

            <MenuList active={activeTab} setExpandedView={setShowExpandedView} showExpandedView={showExpandedView}/>

        </DefaultLayout>
    );
}


type props={
    active: FinanceMenuEnum,
    setExpandedView: (show: boolean) => void
    showExpandedView: boolean;
}

export function MenuList({active, setExpandedView, showExpandedView}: props){

    return (
        <>
            {active===FinanceMenuEnum.BANK_ACCOUNTS &&
                 <Suspense fallback={null}>
                     <BookkeepingV8 accountsubgroupProp="BankAccounts" setExpandedView={setExpandedView} showExpandedView={showExpandedView}/>
                 </Suspense>
            }
            
            {active===FinanceMenuEnum.DIRECTORS_LOANS &&
                <Suspense fallback={null}>
                    <BookkeepingV8  accountsubgroupProp="DirectorsLoans"/>
                </Suspense>
            }

            {active===FinanceMenuEnum.MORTGAGE &&
                 <Suspense fallback={null}>
                     <BookkeepingV8  accountsubgroupProp="Mortgages"/>
                 </Suspense>
            }

            {active===FinanceMenuEnum.EXPENSES &&
                <Suspense fallback={null}>
                    <BookkeepingV8 accountsubgroupProp="Expenses"/>
                </Suspense>
            }

            {active===FinanceMenuEnum.MILEAGE &&
                <Suspense fallback={null}>
                    <Mileage  setExpandedView={setExpandedView} showExpandedView={showExpandedView} />
                </Suspense>
            }

            {active===FinanceMenuEnum.LETTING_STATEMENT &&
                <Suspense fallback={null}>
                    <AgentStatements/>
                </Suspense>
            }
            {active===FinanceMenuEnum.DIVIDENDS &&
                <Suspense fallback={null}>
                    <DividendListPage/>  
                </Suspense>
            }
            {active===FinanceMenuEnum.REPORTS &&
                <Suspense fallback={null}>
                    <ReportSelectPage/>    
                </Suspense>
            }            
            
            {active===FinanceMenuEnum.ADJUSTMENTS &&
                <Suspense fallback={null}>
                    <Adjustments setExpandedView={setExpandedView} />
                </Suspense>
            }
            {active===FinanceMenuEnum.CORPORATION_TAX &&
                <Suspense fallback={null}>
                    <CorporationTax />
                </Suspense>
            }
            {active===FinanceMenuEnum.PERSONAL_TAX_PLANNER &&
                <Suspense fallback={null}>
                    <PersonalTaxPlanner />
                </Suspense>
            }
            {active===FinanceMenuEnum.ACCOUNTS &&
                <Suspense fallback={null}>
                    <AccountEdit />
                </Suspense>
            }
        </>
    );
}
