import { useContext, useEffect, useState } from 'react';
import CompanyContext from '../../../context/CompanyContext';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { NoContentSlate } from '../../Slates/NoContentSlate';
import { Form } from 'react-bootstrap';
import OnboardingContext from '../../../context/OnboardingContext';
import { FinanceTableForm } from '../../../hooks/useOnboardingStory';
import { Onboarding } from '../../../api/inni/Onboarding';

const TypeOfFinancingOptions = [
	{ label: 'Mortgage', value: 'Mortgage' },
	{ label: 'Loan', value: 'Loan' },
	{ label: 'Not financed', value: 'NotFinanced' },
];

type Props = { isCompletedPage: boolean };

type Errors = { [key: string]: string };
const FinanceTable = ({ isCompletedPage }: Props) => {
	const onboardingApi = useInniAPI(Onboarding);
	const { company } = useContext(CompanyContext);
	const {
		financeTableFormik: { setValues, values, handleChange, errors, isSubmitting },
	} = useContext(OnboardingContext);
	const [loader, setLoader] = useState(false);
	const [touched, setTouched] = useState({} as { [key: string]: boolean });

	useEffect(() => {
		setLoader(true);
	
		if (onboardingApi && company?.id) {
			onboardingApi
				.getPropertyFinancingDetails(company?.id)
				.then(({ data }) => {
					setValues(
						data.map((v) => ({
							name: v.propertyName || '',
							propertyId: v.propertyId,
							type: v.type || '',
							lendersName: v.lendersName || '',
							interestOnly: v.interestOnly || false,
							status: v.status || '-',
							modified: v.modified,
						}))
					);
				})
				.finally(() => setLoader(false));
		}
	}, [onboardingApi, company?.id, setValues]);

	useEffect(() => {
		if (isSubmitting && Object.keys(errors).length) {
			setTouched(Object.keys(errors).reduce((acc, t) => ({ ...acc, [t]: true }), {}));
		}
	}, [isSubmitting, errors]);

	return (
		<DataGrid.Table>
			<thead>
				<tr>
					<th>Property</th>
					<th>Type of financing</th>
					<th>Lenders name</th>
					<th>Interest only?</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				{loader && (
					<>
						<DataGrid.LoadingRow cols={5} />
						<DataGrid.LoadingRow cols={5} />
					</>
				)}
				{!loader && (
					<>
						{values.length > 0 ? (
							(values as FinanceTableForm[]).map(
								({ name, type, propertyId, lendersName, modified, interestOnly, status }, i) =>
									modified ? (
										<tr key={`${propertyId}-${i}`}>
											<td>{name}</td>
											<td>{type}</td>
											<td>{lendersName || ''}</td>
											<td>
												<div className="d-flex justify-content-center">
													<Form.Check
														type="checkbox"
														id={`[${i}].interestOnly`}
														checked={values[i].interestOnly}
														name={`[${i}].interestOnly`}
														readOnly
													/>
												</div>
											</td>
											<td>{status}</td>
										</tr>
									) : (
										<tr key={`${propertyId}-${i}`}>
											<td>{name}</td>
											<td>
												<Form.Control
													data-cy={`[${i}].type`}
													name={`[${i}].type`}
													onChange={handleChange}
													onBlur={() => setTouched({ ...touched, [`[${i}].type`]: true })}
													value={type || ''}
													as="select"
													className={
														(errors as Errors)[`[${i}].type`] && touched[`[${i}].type`]
															? 'is-invalid'
															: ''
													}
												>
													<option key={`[${i}].type.default`} hidden>
														Select type of financing
													</option>
													{TypeOfFinancingOptions.map((type) => (
														<option key={`[${i}].type.${type.value}`} value={type.value}>
															{type.label}
														</option>
													))}
												</Form.Control>
											</td>
											<td>
												<Form.Control
													name={`[${i}].lendersName`}
													onChange={handleChange}
													onBlur={() =>
														setTouched({ ...touched, [`[${i}].lendersName`]: true })
													}
													value={lendersName || ''}
													style={{ padding: '0.64rem' }}
													placeholder="Enter Lenders name"
													disabled={type === 'NotFinanced'}
													className={
														(errors as Errors)[`[${i}].lendersName`] &&
														touched[`[${i}].lendersName`]
															? 'is-invalid'
															: ''
													}
												/>
											</td>

											<td>
												<div className="d-flex justify-content-center">
													<Form.Check
														type="checkbox"
														id={`[${i}].interestOnly`}
														checked={interestOnly}
														name={`[${i}].interestOnly`}
														onChange={handleChange}
													/>
												</div>
											</td>
											<td>{status}</td>
										</tr>
									)
							)
						) : (
							<tr>
								<td colSpan={5} style={{ padding: 0 }}>
									<NoContentSlate type={DatagridType.Tenancy} termsKey="emptyTerms" />
								</td>
							</tr>
						)}
					</>
				)}
			</tbody>
		</DataGrid.Table>
	);
};

export default FinanceTable;
