import React, { useContext } from 'react';
import styles from '../Tenancy.module.scss';
import stylesV75 from '../Tenancy_V75.module.scss';
import Gravatar from 'react-gravatar'; 
import { TenancyAsDetailed } from '../../../api/inni/data-contracts';
import { Button } from '../../../elements/Button/Button';
import { Action, Entity } from '../../../utils/EntityAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { Link } from 'react-router-dom';
import { useGetUrl } from '../../../hooks/useGetUrl';
import { TenancyName } from '../TenancyUtilities';
import { LettableUnitListItem } from '../../../hooks/lists/useLettableUnitsList';
import CompanyContext from '../../../context/CompanyContext';
import { ExpandedViewBackLabel } from '../../../elements/ExpandedViewBackLabel/ExpandedViewBackLabel';
import Toolbar from '../../../layouts/Desktop/Toolbar';
import { useHistoryWrapper } from '../../../hooks/useHistoryWrapper';

export interface TenancyHeaderProps {
    tenancy?: TenancyAsDetailed,
    title?: string,
    units: LettableUnitListItem[],
    onEditClick?: () => void,
    onDeleteClick?: () => void
}

export const TenancyHeader = ({title, tenancy, units, onEditClick, onDeleteClick} : TenancyHeaderProps) => {
    const companyContext = useContext(CompanyContext);
    const v8Styling = companyContext.company?.useV8UI || false
    const styling = v8Styling ? stylesV75 : styles;
    const history = useHistoryWrapper();
    
    const getUrl = useGetUrl();

    const getTitle = () => {
        if (title) return title;
        return TenancyName(tenancy);
    }

    const getAddress = () => {
        if (tenancy) {
            const unit = units.find(u => u.id === tenancy.propertyLettableUnitId)
            return unit?.fullName;
        }
        return null;
    }

    const getPropertyUrl = () => {
        if (tenancy) {
            const unit = units.find(u => u.id === tenancy.propertyLettableUnitId)
            if (unit) {
                return getUrl(Entity.Property, Action.Read, {id: unit.propertyId}).url
            } 
        }
        return ''
    }


    return (
        <div id={styling.tenancyHeader} data-cy="tenancyHeader">
            { v8Styling 
                ? (
                    <ExpandedViewBackLabel
                        backLabel="Tenancies"
                        title={getAddress() || ''}
                        onClick={() => history.goBack()}
                    >
                        <Toolbar>
                            { onEditClick &&
                                <Button
                                    entity={Entity.Tenancy}
                                    action={Action.Edit}
                                    buttonType='edit'
                                    onClick={onEditClick}
                                    headerBtn
                                />                        
                            }
                            { onDeleteClick &&
                                <Button
                                    entity={Entity.Tenancy}
                                    action={Action.Delete}
                                    buttonType='delete'
                                    onClick={onDeleteClick}
                                    disabled={!tenancy?.editability?.canDelete}
                                    disabledMessage={tenancy?.editability?.cantDeleteReason}
                                    headerBtn
                                />                        
                            }
                        </Toolbar>
                    </ExpandedViewBackLabel>
                )
                : (
                    <>
                        <div className={styling.image}>
                            <Gravatar className={styling.gravatar} email={tenancy?.tenantEmail||''} size={200} rating='pg' default='mm' />
                        </div>
                        
                        <div className={styling.content}>
                            <h1>{ getTitle() }</h1>
                            <div className={styling.address}>
                                { getAddress() }
                                { tenancy && <Link to={getPropertyUrl()}><FontAwesomeIcon icon={faExternalLink} /></Link> }
                            </div>
                            { tenancy &&  
                                <div className={styling.buttons}>
                                    { onEditClick &&
                                        <Button
                                            entity={Entity.Tenancy}
                                            action={Action.Edit}
                                            buttonType='edit'
                                            onClick={onEditClick}
                                        />                        
                                    }
                                    { onDeleteClick &&
                                        <Button entity={Entity.Tenancy} action={Action.Delete} buttonType='delete' onClick={onDeleteClick} 
                                            confirmMessage='Are you sure you want to delete this tenancy?'
                                            disabled={!tenancy.editability?.canDelete}
                                            disabledMessage={tenancy.editability?.cantDeleteReason}
                                        />                        
                                    }
                                </div>            
                            }
                        </div>
                    </>
                )
            }
        </div>
    )
}