import { useState, useCallback, useEffect, useContext } from 'react';
import { Modal as ReactModal } from 'react-bootstrap';
import Select from 'react-select';
import { getMonthByNumber, Month, months } from '../../utils/commonLists';
import { Button } from '../../elements/Button/Button';
import { Form as FormikForm, Formik, FormikHelpers } from 'formik';
import { SimpleFormikField } from '../../elements/SimpleFormikFields/SimpleFormikField';
import editRowStyles from '../../elements/EditRow/EditRow.module.css';
import styles from './MortgageStatementModal.module.css';
import uploadStyles from '../../components/FileUploadModal/FileUploadModal.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faCloudUploadAlt, faSpinner, faTimesCircle } from '@fortawesome/pro-regular-svg-icons'
import { useDropzone } from 'react-dropzone'
import { nth } from '../../utils/formatDate';
import { MortgageAccounts } from '../../api/inni/MortgageAccounts';
import { useInniAPI } from '../../hooks/useInniAPI';
import CompanyContext from '../../context/CompanyContext';
import { MortgageStatementUploadData } from '../../api/inni/data-contracts';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { BrandWrapper } from '../../components/BrandWrapper/BrandWrapper';


export interface MortgageStatementModalProps {
  mortgageAccountId?: number,
  onClose: () => void,
  showModal: boolean
  postUpload?: () => void
}

export const MortgageStatementModal = ({mortgageAccountId, onClose, showModal, postUpload}: MortgageStatementModalProps) => {
  const mortgageAccountApi = useInniAPI(MortgageAccounts)
  const companyContext = useContext(CompanyContext)

  const [uploading, setUploading] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [reminderDataLoaded, setReminderDataLoaded] = useState(false);
  const [reminderData, setReminderData] = useState<MortgageStatementUploadData>();

  const initialValues = () => {
    if(reminderData && reminderData.nextReviewDate) {
      //2022-09-23
      const parsedMonth = getMonthByNumber(parseInt(reminderData.nextReviewDate.substring(5,7)));
      const parsedDay = parseInt(reminderData.nextReviewDate.substring(8,10));
      return {
        month: parsedMonth, 
        day: parsedDay
      }
    }
    return {month: months[0], day: 1}
  }
  const maxSizeMB = 10;

  const closeIfNotUploading = () => {
    //We might not acutally remove the modal, just hide it so manually reset the state
    if(!uploading) {
      const hasUploaded = uploaded //Avoid race condition
      setUploadFailed(false)
      setUploaded(false)
      setReminderData(undefined)
      setReminderDataLoaded(false)
      if(postUpload && hasUploaded) postUpload()
      onClose() 
    }
  }

  const submit = (values: {month: Month, day: number}, actions: FormikHelpers<{ month: Month; day: number; }>) => {
    if(mortgageAccountApi && mortgageAccountId) {
      mortgageAccountApi.updateReviewDate(companyContext.cid, mortgageAccountId, {day: values.day, month: values.month.monthNumber}).then(() => {
        closeIfNotUploading()
      })
    }
  }

  const validate = (values:{month: Month, day: number}) => {
    let errors: {day?: string} = {};
    if (values.day > values.month.days) {
      errors.day = "Invalid date";
    }

    return errors;
  }

  const onDrop = useCallback((acceptedFiles:File[]) => {
    setUploading(true);
    setUploadFailed(false);
    if(mortgageAccountApi && acceptedFiles && !uploading && mortgageAccountId) {
      mortgageAccountApi.uploadMortgageStatement(companyContext.cid, mortgageAccountId, {fileToUpload: acceptedFiles[0]}).then(() => {
        setUploading(false);
        setUploaded(true);
        setUploadFailed(false)
      }).catch(() => {
        setUploading(false);
        setUploadFailed(true);
      })
    }

    // if (onFilesDropped) onFilesDropped(acceptedFiles);
  }, [companyContext.cid, mortgageAccountApi, mortgageAccountId, uploading]);

  const {getRootProps, getInputProps} = useDropzone({
    onDrop, 
    multiple: true, 
    maxFiles: 1, 
    accept: ['image/*','.pdf','.doc','.docx'],
    maxSize: maxSizeMB * 1024 * 1024,
  })

  useEffect(() => {
    if(mortgageAccountApi && !reminderDataLoaded && mortgageAccountId)
      mortgageAccountApi.getUploadData(companyContext.cid, mortgageAccountId).then(data => {
        setReminderData(data.data)
      }).finally(() => {
        setReminderDataLoaded(true)
      })
  }, [mortgageAccountId, companyContext.cid, mortgageAccountApi, reminderDataLoaded])

  const yearFrom = () => {
    return reminderData && reminderData.nextReviewDate ? (new Date(reminderData.nextReviewDate)).getFullYear() : (new Date()).getFullYear()
  }

  const yearTo = () => {
    return reminderData && reminderData.nextReviewDate ? (new Date(reminderData.nextReviewDate)).getFullYear() + 1 : (new Date()).getFullYear() + 1
  }

  const properties = () => {
    if(reminderData && reminderData.properties && reminderData.properties.length > 0) {
      return `for ${reminderData.properties.length === 1 ? reminderData.properties[0] : reminderData.properties.join(", ")}`
    }
    return ""
  }

  return (
    <ReactModal size="lg" show={showModal} onHide={closeIfNotUploading}>
      <BrandWrapper>
        {reminderDataLoaded ? 
          <>
            <Formik
              initialValues={initialValues()}
              validate={validate}
              onSubmit={submit}
            >
              {({ values, setFieldValue, submitForm }) => (<>
                <ReactModal.Header>
                  <ReactModal.Title>
                    Upload mortgage statement
                  </ReactModal.Title>
                </ReactModal.Header>
                <ReactModal.Body>
                  <p className="mb-4" style={{fontWeight: 600}}>
                    Please upload your {yearFrom()} to {yearTo()} mortgage statement {properties()}
                  </p>
                    <p>Remind me to upload this mortgage's statement on the:</p>
                        <FormikForm id={styles.form} className='d-flex'>
                          <SimpleFormikField name="day" type="number" size="sm" min={1} max={31} />
                          <p style={{marginLeft: "0.25rem"}}>{nth(values.day)} of</p>
                          <Select
                            className={`${editRowStyles.selectList} ${styles.selectList}`}
                            classNamePrefix='selectList'
                            name="month"
                            options={months}
                            value={values.month}
                            onChange={(e) => {setFieldValue("month", e)}}
                          />
                          <p>each year.</p>
                        </FormikForm>
                  
                  <div id={styles.uploader} className={uploadStyles.outer}>
                    <div {...getRootProps({className: uploadStyles.dropzone})}>
                        {!uploaded && <>
                          <input {...getInputProps()} />
                          <div className={styles.uploadIcon}>
                              <FontAwesomeIcon icon={faCloudUploadAlt} />
                          </div>
                          <div className={styles.meta}>
                              Image files, PDFs or Word Documents only.&nbsp;
                              { maxSizeMB && <>{maxSizeMB}MB max</> }
                          </div>
                        </>}
                        {!uploading && !uploaded &&
                            <div>
                                Drop your file here < br/>
                                Or click to browse
                            </div>
                        }
                        {uploading &&
                            <div><FontAwesomeIcon icon={faSpinner} pulse /></div>
                        }
                        { uploaded &&
                          <div><FontAwesomeIcon icon={faCheckCircle}/> Uploaded</div>
                        }
                        { uploadFailed &&
                          <div style={{marginTop: '1rem', color: 'var(--danger)'}}>
                            <FontAwesomeIcon icon={faTimesCircle}/> Upload failed
                          </div>
                        }
                    </div>
                  </div>

                </ReactModal.Body>
                <ReactModal.Footer>
                  <div className="w-100 text-align-left">
                    <Button buttonType="save" onClick={submitForm}/>
                    <Button buttonType="cancel" onClick={closeIfNotUploading}/>
                  </div>
                </ReactModal.Footer>
              </>)}
            </Formik>
          </> : 
          <>
            <ReactModal.Header>
              <ReactModal.Title>
                Upload mortgage statement
              </ReactModal.Title>
            </ReactModal.Header>
            <ReactModal.Body>
              <LoadingPlaceholder height='75px'/>
            </ReactModal.Body>
            <ReactModal.Footer>
              <div className="w-100 text-align-left">
                <Button buttonType="save" disabled/>
                <Button buttonType="cancel" disabled/>
              </div>
            </ReactModal.Footer>
          </>
        }
      </BrandWrapper>
    </ReactModal>
  )
}
