import { FormikHelpers, FormikErrors } from "formik"
import { useContext, useEffect, useState } from "react"
import { BankTransactions } from "../../../api/inni/BankTransactions"
import { BankTransaction, BankTransferModel, PendingPaymentModel } from "../../../api/inni/data-contracts"
import CompanyContext from "../../../context/CompanyContext"
import { GroupedListOption } from "../../../elements/EditRow/EditRow"
import { FormikRowLogic, FormikRowInput, FormikRowSubmitBtns, FormikRowFieldTypes } from "../../../elements/FormikTableEditor/FormikTableEditor"
import { useInniAPI } from "../../../hooks/useInniAPI"
import getCurrencySymbol from "../../../utils/currencySymbols"
import { getPaymentTypeLong, ProcessorType } from "../BankingTypes"
import styles from "./EditorModal.module.css"
import { Modal } from "../../Modal/Modal"
import classNames from "classnames"
import { Button } from "../../../elements/Button/Button"
import { getViewButtonLabel } from "./FormatViewButton"
import { LoadingTextInput } from "../../../elements/LoadingPlaceholder/LoadingPlaceholder"
import { asYearMonthDay } from "../../../utils/formatDate"

const newTransfer = {date: asYearMonthDay(new Date()), description: '', bankAccountToId: -1, amountReceivedBACurr: 0, amountSentBACurr: 0} as BankTransferModel
interface BankTransferEditorProps {
    afterCreate: (pendingPaymentId?: number, type?: string, date?: string) => void,
    afterUpdate: () => void,
    closeEditor: () => void,
    pendingPaymentToAdd?: PendingPaymentModel,
    id?: number,
    accountOptions: GroupedListOption[],
    bankTransferToEdit?: BankTransferModel,
    bankAccountId: number,
    currency?: string,
    showModal: boolean,
    bankTransaction?: BankTransaction,
    allowBTChanges: (i: BankTransferModel) => boolean,
    deleteBankTransferDialog: (transfer: BankTransferModel) => void,
    setReceiptModal : (i : BankTransaction) => void
}

export const BankTransferModalEditor = ({afterCreate, afterUpdate, closeEditor, 
        id, accountOptions, bankTransferToEdit, bankAccountId, pendingPaymentToAdd, currency, showModal, 
        allowBTChanges, deleteBankTransferDialog, bankTransaction, setReceiptModal} : BankTransferEditorProps) => {
    const bookkeepingApi = useInniAPI(BankTransactions, [400])
    const companyContext = useContext(CompanyContext)

    const isReceivingAccount = bankTransferToEdit?.bankAccountToId === bankAccountId

    const handleSubmit = () => {
        if(!formikBankTransfer.isSubmitting) formikBankTransfer.submitForm().then((newIdOrSuccess:number|void|boolean) => {
            if(newIdOrSuccess !== undefined && newIdOrSuccess !== false) {
                closeEditor()
            }
        })
    }

    const onCreate = (values: BankTransferModel, actions: FormikHelpers<BankTransferModel>) : Promise<number> => {
        return new Promise((resolve, reject) => {
            if (bookkeepingApi) {
                values.bankAccountId = bankAccountId
                bookkeepingApi.createBankTransfer(companyContext.cid, values)
                .then(res => {                    
                    if(res.status === 201) {
                        afterCreate(pendingPaymentToAdd?.relatedID, pendingPaymentToAdd?.processorTypeShortString, values.date)
                        resolve(parseInt(res.data));
                    } else {
                        console.error(`Unexpected response code: ${res.status}`)
                        reject()
                    }
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } 
            else {
                reject();
            }
        })
    }

    const onUpdate = (values: BankTransferModel, actions: FormikHelpers<BankTransferModel>, id:number) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (bookkeepingApi) {
                bookkeepingApi.updateBankTransfer(companyContext.cid, id, values)
                .then(res => {                    
                    if(res.status === 200) {
                        afterUpdate()
                        resolve(true);
                    } else {
                        console.error(`Unexpected response code: ${res.status}`)
                        reject()
                    }
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } 
            else {
                reject();
            }
        })
    }
        
    const validateCreate = (values: BankTransferModel) : Promise<FormikErrors<BankTransferModel>> => {
        return new Promise((resolve, reject) => {
            values.bankAccountId = bankAccountId
            if (bookkeepingApi) {
                return bookkeepingApi.validateCreateTransfer(companyContext.cid, values)
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }

    const validateUpdate = (id: number, values: BankTransferModel) : Promise<FormikErrors<BankTransferModel>> => {
        return new Promise((resolve, reject) => {
            values.bankAccountId = bankAccountId
            if (bookkeepingApi) {
                return bookkeepingApi.validateCreateTransfer(companyContext.cid, values)
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }

    const formikBankTransfer = FormikRowLogic<BankTransferModel>({
        editRowValues: newTransfer,
        validateUpdate: validateUpdate,
        validateCreate: validateCreate,
        onCreate: onCreate,
        onUpdate: onUpdate,
        id: id,
        addingNewRow: id === undefined})

    const buildFormikFieldBt = (fieldName:keyof BankTransferModel, name:string, type:FormikRowFieldTypes, readOnly?:boolean) => {
        const isLoading = !accountOptions || (id !== undefined && (!bankTransferToEdit || !bankTransaction))
        return <div data-cy="editorField" className={styles.editorField}>
            <label>{name}</label>
            {isLoading && type !== "check" ? <LoadingTextInput /> :
            <FormikRowInput<BankTransferModel>
                formik={formikBankTransfer} 
                property={fieldName}
                type={type} 
                disabled={isLoading || readOnly}
                prefix={type === "number" && getCurrencySymbol(currency||"")} 
                groupedOptions={type === 'groupedselect' ? accountOptions : undefined} 
                inputProps={type === "number" ? {disabled: readOnly, min: 0} : {disabled: readOnly}}/>
            }
        </div>
    }

    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        if(!loaded) {
            if(bankTransferToEdit) {
                formikBankTransfer.setValues(bankTransferToEdit)
            }
            if(pendingPaymentToAdd) {
                formikBankTransfer.setFieldValue("description", pendingPaymentToAdd.description)
                formikBankTransfer.setFieldValue(isReceivingAccount ? "amountReceivedBACurr" : "amountSentBACurr", pendingPaymentToAdd.amountInSourceCurrency || pendingPaymentToAdd.amount)

                const paymentTypeLong = getPaymentTypeLong(pendingPaymentToAdd.processorTypeShortString || "")

                formikBankTransfer.setFieldValue("bankAccountToId", paymentTypeLong === ProcessorType.transfer ? pendingPaymentToAdd.relatedID : pendingPaymentToAdd.accountId) //See line 1068 in BankViewer.ascx.vb

                if(paymentTypeLong === ProcessorType.recurringTransfer) {
                    formikBankTransfer.setFieldValue("recurringPaymentId", pendingPaymentToAdd.relatedID)
                }
            }

            if(bankTransferToEdit || pendingPaymentToAdd) {
                setLoaded(true)
            }
        }
        
    }, [bankTransferToEdit, pendingPaymentToAdd, isReceivingAccount, loaded, formikBankTransfer])

    const modalButtons = () => {
        const buttons = [<FormikRowSubmitBtns small={false} handleSubmit={handleSubmit} handleClose={closeEditor} key="submit"/>]

        if(bankTransferToEdit && allowBTChanges(bankTransferToEdit))
            buttons.push(<Button buttonType="delete" onClick={() => deleteBankTransferDialog(bankTransferToEdit)} key="delete"/>)

        if(bankTransaction?.hasImage) {
            buttons.push(<Button buttonType="viewReceipt" onClick={() => setReceiptModal(bankTransaction)} key="receipt"/>)
        }
        else if(bankTransaction?.hasLink) {
            buttons.push(<a href={bankTransaction.getLink}><Button label={getViewButtonLabel(bankTransaction.type || '')} buttonType="view" key="viewReceipt"/></a>)
        }

        return buttons
    }

    return <Modal size="xl" restoreFocus={false} showModal={showModal} hideModal={closeEditor} title={id === undefined ? "New transfer" : "Edit transfer"} buttons={modalButtons()}>
        <div className={styles.editorRow}>
            {buildFormikFieldBt("date", "Date", "date")}
            {buildFormikFieldBt(isReceivingAccount ? "amountReceivedBACurr" : "amountSentBACurr", isReceivingAccount ? "Amount received" : "Amount sent", "number")}
        </div>
        <div className={classNames(styles.editorRow, styles.description)}>
            {buildFormikFieldBt("description", "Description", "text")}
        </div>
        <div className={styles.editorRow}>
            {buildFormikFieldBt(isReceivingAccount ? "bankAccountFromId" : "bankAccountToId", isReceivingAccount ? "Transferred from" : "Transferred to", "groupedselect", isReceivingAccount)}
            {buildFormikFieldBt(isReceivingAccount ? "confirmedTo" : "confirmedFrom", "Match", "check")}
        </div>
    </Modal>
}