import React, { useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder'
import { ViewSwitcherButton } from '../../../elements/ViewSwitcher/ViewSwitcher'
import { formatCurrency } from '../../../utils/formatNumbers'
import { LiveCashTree } from './LiveCashFunctions'
import configHelper from '../../../utils/configHelper'

type ShowOptions = 'in' | 'out'

const LiveCashTableLoading = () => 
    <tr>
        <td><LoadingPlaceholder /></td>
        <td><LoadingPlaceholder /></td>
        <td><LoadingPlaceholder /></td>
    </tr>

const LiveCashTableView = ({nestedData, isLoaded, toggleDisabled} : {nestedData : LiveCashTree | undefined, isLoaded : boolean, toggleDisabled : (item: LiveCashTree | undefined, i : string) => void}) => {

    const isMobile = configHelper.useMobile
    const [show, setShow] = useState<ShowOptions>('in')
    
    return (<div style={{display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'column', marginBottom: '20px'}}>
        { isMobile && 
        // <div style={{width: '100%', display: 'flex', justifyContent: 'space-around'}}>
        //     <p onClick={() => setShow('in')} style={{width: '50%', textAlign: 'center', padding: '5px', borderBottom: show === 'in' ? '2px solid var(--topbar-background-color)' : ''}}>In</p>    
        //     <p onClick={() => setShow('out')} style={{width: '50%', textAlign: 'center', padding: '5px', borderBottom: show === 'out' ? '2px solid var(--topbar-background-color)' : ''}}>Out</p>
        // </div>}
            <ButtonGroup style={{width: '100%', marginBottom: '20px'}}>
                <ViewSwitcherButton keyName="in" text="In" active={show === 'in'} onClick={(i) => setShow(i as ShowOptions)} />
                <ViewSwitcherButton keyName="out" text="Out" active={show === 'out'} onClick={(i) => setShow(i as ShowOptions)} />
            </ButtonGroup>
        }
        { nestedData &&
            <table style={{width: !isMobile ? '80%' : '98%'}}>
                <thead>
                    <tr>
                        <td></td>
                        <td></td>
                        <td style={{color: '#666'}}><i>Exclude</i></td>
                    </tr>
                    {isLoaded && 
                    <tr style={{borderTop: '1px solid #000'}}>
                        <th style={{color: nestedData.cashItem?.valueIsNegative ? '#d00' : ''}}>{nestedData.cashItem?.valueIsNegative ? 'Overspent' : 'Available'}</th>
                        <th style={{color: nestedData.cashItem?.valueIsNegative ? '#d00' : ''}}>{formatCurrency(nestedData.cashItem?.valueToDisplay)}</th>
                        <td></td>
                    </tr>}
                </thead>
                <tbody>
                    {!isLoaded && <>
                        <LiveCashTableLoading />
                        <LiveCashTableLoading />
                        <LiveCashTableLoading />
                        <LiveCashTableLoading />
                    </>}
                    { isLoaded && nestedData.children && nestedData.children.filter(x => !isMobile || x.key === (show === 'in' ? 'AS' : 'LB')).map(x => {
                        return (
                            <React.Fragment key={x.key}>
                            <tr style={{borderTop: '2px solid #000'}}>
                                <th>{x.cashItem?.name}</th>
                                <th>{formatCurrency(x.cashItem?.valueToDisplay)}</th>
                            </tr>
                            { x.children && x.children.map(y => {
                                return (<React.Fragment key={y.key}>
                                    <tr style={{color: !y.cashItem?.enabled ? '#ccc' : '', borderTop: '1px solid #ccc'}}>
                                        <td style={{paddingLeft: '10px', padding: isMobile ? '10px' : ''}}>{y.cashItem?.name}</td>
                                        <td>{formatCurrency((y.cashItem?.valueToDisplay || 0) + (y.cashItem?.valueOfDisabled || 0))}</td>
                                        <td style={{paddingTop: '8px', textAlign: 'center'}}><input type="checkbox" 
                                            checked={!y.cashItem?.enabled}
                                            style={ isMobile ? {width: '25px', height: '25px'} : {}}
                                            onChange={ () => { toggleDisabled(y, y.key || '') } } 
                                        /></td>
                                    </tr>

                                    { y.children && y.children.map(g => {
                                        return (
                                            <tr key={g.key} style={{color: !g.cashItem?.enabled ? '#ccc' : '' , borderTop: '0.5px solid #ccc'}}>
                                                <td style={{padding: isMobile ? '10px 20px' : '', paddingLeft: '20px'}}>{g.cashItem?.name}</td>
                                                <td>{formatCurrency((g.cashItem?.valueToDisplay || 0) + (g.cashItem?.valueOfDisabled || 0))}</td>
                                                <td style={{paddingTop: '8px', textAlign: 'center'}}>
                                                        <input type="checkbox" 
                                                            checked={!g.cashItem?.enabled}
                                                            style={ isMobile ? {width: '25px', height: '25px'} : {}}
                                                            onChange={() => { toggleDisabled(g, x.key || '') }} 
                                                        />
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </React.Fragment>)
                            })

                            }
                            </React.Fragment>
                        )
                    })}
                </tbody>
            </table>
        }
    </div>)

}

export default LiveCashTableView
