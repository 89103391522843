import { useContext, useEffect, useState } from 'react';
import { iInfoBox, iInfoBoxVariant } from '../../elements/Storyblok/primitives/InfoBox';
import { getStoryblokApi } from '@storyblok/react';
import { useInniAPI } from '../../hooks/useInniAPI';
import { PageMessages } from '../../api/inni/PageMessages';
import CompanyContext from '../../context/CompanyContext'
import { StoryblokComponent } from '@storyblok/react'


type tSBMessages = {[key: string]: iInfoBox} | undefined;
type tHookExport = [
	unreadPageMessages: string[] | undefined,
	setMessageRead: (page: string) => void,
	getComponent: (sbKey: string, variant?: string, showOverride?: boolean) => React.ReactElement
]

export const useOnboardingMessages = (page: string): tHookExport => {
  const [sbMessages, setSBMessages] = useState<tSBMessages>();
	const [unreadPageMessages, setUnreadPageMessages] = useState<string[]>();
	const companyContext = useContext(CompanyContext)
	const pageMessagesApi = useInniAPI(PageMessages);

	// load unread page messages
	useEffect(() => {
		if (!unreadPageMessages && pageMessagesApi) {
			pageMessagesApi.getForPage(companyContext.cid, {page: page}).then(res => {
				setUnreadPageMessages(res.data);
			})
		}
	}, [companyContext.cid, pageMessagesApi, unreadPageMessages, page])

	// get storyblok messages - could be in the infoBox component, but what if there are multple messages
	useEffect(() => {
		if (unreadPageMessages) {
			const storyblokApi = getStoryblokApi();
			storyblokApi.get('cdn/stories/info-boxes/onboarding', {version: 'published'}).then((res) => {
				const buildMessages: tSBMessages = {};
				const apiData = res.data.story;

				// convert to k:v for easy parsing
				if (apiData) {
					apiData.content.Body.forEach((entry: iInfoBox) => {
						const variants = entry.value.reduce((acc: {[key:string]: iInfoBoxVariant}, obj) => {
							acc[obj.variant] = obj;
							return acc;
						}, {});

						buildMessages[entry.key] = {...entry, variants: variants}
					})
					setSBMessages(buildMessages)
				}
			});
		}
	}, [unreadPageMessages])

	// return storyblok component
	const getOnboardingComponent = (sbKey: string, variant: string = 'default', showOverride: boolean = false) => {
		if ((showOverride || unreadPageMessages?.includes('info-boxes/onboarding/' + sbKey)) && sbMessages?.hasOwnProperty(sbKey))
			return <StoryblokComponent blok={sbMessages[sbKey]} setMessageRead={setMessageRead} variant={variant} />;

		return <></>;
	}

	const setMessageRead = (sbKey: string) => {
		setUnreadPageMessages(unreadPageMessages?.filter(item => item !== sbKey));
	}

  return [unreadPageMessages, setMessageRead, getOnboardingComponent];
}
