import { useState } from "react";
import { Action, Entity } from "../utils/EntityAction";
import { useHasPermission } from "./useHasPermission";


export function useAssertPermission(entity: Entity, action: Action)  {
    const checkHasPermission = useHasPermission();
    const [hasPermission, isAdminPermission] = checkHasPermission(entity, action)

    if (!hasPermission) {
        // TODO
        throw new Error("Access denied");        
    }
}