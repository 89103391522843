import { Form, Formik, FormikErrors, FormikProps} from 'formik'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Account, OverviewPostModel, PersonAsDetailed } from '../../../../api/inni/data-contracts'
import CompanyContext from '../../../../context/CompanyContext'
import { useInniAPI } from '../../../../hooks/useInniAPI'
import { People } from '../../../../api/inni/People'
import { Switch, Text, DateSelector, SelectList, ListOption, Submit } from '../../../../elements/EditRow/EditRow'
import { nationalityCodesToListOptions } from '../../../../utils/formatters/formatCodes'
import { CompareValues } from '../../../../utils/compareValues'

const genderSl: ListOption[] = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
]

interface DetailsEditProps { 
    person: PersonAsDetailed, 
    reloadPeople: () => void, 
    setEditing: (i: boolean) => void, 
    isCorporate: boolean, 
    hasMileage: boolean, 
    simpleSharesOnly: boolean, 
    accounts: Account[], 
    defaultName: string,
    setDisabled: (disabled: boolean) => void,
    v8Styling: boolean
}

const DetailsEdit = ({
        person,
        reloadPeople,
        setEditing,
        isCorporate,
        hasMileage,
        simpleSharesOnly,
        accounts,
        defaultName,
        setDisabled,
        v8Styling
    } : DetailsEditProps) => {

    const companyContext = useContext(CompanyContext)
    const peopleApi = useInniAPI(People, [400])


    const [nationalitiesSl] = useState<ListOption[]>(nationalityCodesToListOptions());
    const [accountsSl, setAccountsSl] = useState<ListOption[]>([])
    const [taxRegimeSl, setTaxRegimeSl] = useState<ListOption[]>([])
    const refVal = useCallback(
		(node: FormikProps<OverviewPostModel>) => {
			if (node !== null) {
                if(node.values.overseas === false && (node.values.country !== null && node.values.country !== undefined && node.values.country !== '')){
                    node.setFieldValue('country',null)
                    setDisabled(!CompareValues({ ...node.values,country:null }, { ...node.initialValues }, true));
                }
				setDisabled(!CompareValues({ ...node.values }, { ...node.initialValues }, true));
			}
		},
		[setDisabled]
	);
    useEffect(() => {
        if (accounts) {
            let tempSL: ListOption[] = []
            tempSL.push({ label: 'Repay manually from pending payment', value: null })
            accounts
                .filter(x => x.personId === person?.id && !x.payExpensesViaPayroll && x.accountSubGroup === 'DirectorsLoans' && x.currency === 'GBP')
                .map(x => tempSL.push({ value: x.id.toString(), label: x.name || '' }))
            setAccountsSl(tempSL)
        }
    }, [accounts, person?.id])

    useEffect(() => {
        if (peopleApi) {
            peopleApi.getTaxReigmeSelectList(companyContext.cid)
                .then(res => {
                    let tempSL: ListOption[] = []
                    res.data.map(x => tempSL.push({ value: x.id.toString(), label: x.name || '' }))
                    setTaxRegimeSl(tempSL)
                })
        }
    }, [companyContext.cid, peopleApi])

    const validateUpdate = (values: OverviewPostModel): Promise<FormikErrors<OverviewPostModel>> => {
        return new Promise((resolve, reject) => {
            if (peopleApi) {
                peopleApi.validateUpdateOverview(companyContext.cid, person?.id || 0, values)
                    .then(() => {
                        resolve({})
                    })
                    .catch(err => resolve(err.error))
            }
        })
    }

    const updateCall = (values: OverviewPostModel): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (peopleApi) {
                peopleApi.updateOverview(companyContext.cid, person?.id || 0, values)
                    .then(res => {
                        if (res.status === 200) {
                            reloadPeople()
                            setEditing(false)
                            resolve()
                        } else {
                            console.error(`Unexpected response code ${res.status} when `)
                        }
                    }).catch(err => reject(err.error))
            }
        })
    }

    const showField = (fieldName: string) => {
        if (person && person.editability && person.editability.readOnlyFields) {
            if (fieldName in person.editability.readOnlyFields) {
                return false ;
            }
        }
        return true;
    }

    return (
        <Formik
            initialValues={{
                title: person.title,
                forenames: person.forenames,
                surname: person.surname,
                address: person.address,
                overseas: person.overseas,
                postcode: person.postcode,
                country: person.country,
                gender: person.gender,
                dob: person.dob,
                nationality: person.nationality,
                utr: person.utr,
                taxRegime: person.taxRegime,
                jobTitle: person.jobTitle,
                shares: person.shares,
                isDirector: person.isDirector || false,
                directorAppointmentDate: person.directorAppointmentDate,
                isCorporateEntity: person.isCorporateEntity,
                defaultAdvisoryFuelrateBand: person.defaultAdvisoryFuelrateBand,
                repayMileageDirectly: person.repayMileageDirectly,
                repayMileageDirectlyToAccountId: person.repayMileageDirectlyToAccountId,
                isSpouse: person.isSpouse
            } as OverviewPostModel}
            validateOnChange={false}
            enableReinitialize
            onSubmit={updateCall}
            validate={validateUpdate}
            innerRef={refVal}
        >
            {({ isSubmitting, values }) => (

                <Form>

                    <Text name="title" />
                    <Text name="forenames" />
                    <Text name="surname" />
                    <Text name="address" type="textarea" />
                    {showField("overseas") && <Switch name="overseas" /> }
                    {values.overseas ? <Text name="country" /> : <Text name="postcode" />}
                    {!v8Styling && <SelectList name="gender" options={genderSl} />}
                    <DateSelector name="dob" label="Date of birth" />
                    <SelectList name="nationality" options={nationalitiesSl} />
                    <SelectList options={taxRegimeSl} name="taxRegime" />
                    <Text name="utr" label="UTR" />
                    {isCorporate && <>
                        <Text name="jobTitle" />
                        {(simpleSharesOnly && !v8Styling) && <Text min={0} name="shares" type="number" label="Shares held" />}
                        <Switch name="isDirector" label="Director" />
                        <DateSelector name="directorAppointmentDate" label="Appointment date" />
                        { showField("isCorporateEntity") && <Switch name="isCorporateEntity" /> }
                        {hasMileage && <>
                            { showField("defaultAdvisoryFuelrateBand") && <Text name="defaultAdvisoryFuelrateBand" />}
                            { showField("repayMileageDirectly") && <Switch name="repayMileageDirectly" />}
                            { showField("repayMileageDirectlyToAccountId") && <SelectList label="Repay mileage to account" options={accountsSl} name="repayMileageDirectlyToAccountId" />}
                            {!person.defaultEmployee && !person.isActiveEmployee && showField("isSpouse") &&
                                <Switch name="isSpouse" help={`Tick if the person is married to or in a civil partnership with ${defaultName}. Do not tick if they are only cohabiting.`} />}
                        </>}
                    </>}
                    <Submit disabled={isSubmitting} onCancelClick={() => setEditing(false)} />

                </Form>
            )}
        </Formik>
    )

}

export default DetailsEdit