import React, { useContext } from 'react';
import { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Formik, FieldArray, FormikHelpers, FormikProps} from 'formik';
import { Card, Col, Dropdown, DropdownButton, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Account, LSAccount, LettingAgent, LettingAgentPostModel, LettingStatement, LettingStatementLine,
	LettingStatementPostModel, Property, VatCodeListOption } from '../../../api/inni/data-contracts';
import { Expenses } from '../../../api/inni/Expenses';
import { LettingStatements } from '../../../api/inni/LettingStatements';
import FormikField from '../../../elements/FormikField/FormikField';
import { LoadingPlaceholder,LoadingText } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { asYearMonthDay, formatDate } from '../../../utils/formatDate';
import dropdownStyles from '../Elements/Dropdown/Dropdown.module.css';
import TimeLine from '../Elements/Timeline/TimeLine';
import { currencyConvertor, getUniqueValues } from '../HelperFunctions/HelperFunctions';
import styles from '../Invoice.module.css'; 
import { LettingAgents } from '../../../api/inni/LettingAgents';
import { InvoiceReactSelect } from '../CreateInvoice';
import { Properties } from '../../../api/inni/Properties';
import Select from 'react-select';
import selectstyle from '../../../elements/FormikField/FormikField.module.css'
import { TableIcon } from '../../../elements/TableIcon/TableIcon';
import { TableTotalField } from '../Components/ReadInvoiceWrapper';
import { useModalDialog } from '../../../hooks/useModalDialog';
import { Button } from '../../../elements/Button/Button';
import { faCircleQuestion, faStar } from '@fortawesome/pro-solid-svg-icons';
import CompanyContext, { CompanyProduct } from '../../../context/CompanyContext';
import AddNewAgentOptionModal from '../../Tenancies/Components/AddNewAgentOptionModal';
import FullScreen from '../../../components/Fullscreen/Fullscreen';
import { format } from 'date-fns';
import { formatCurrency } from '../../../utils/formatNumbers';
import Icon from '../../../elements/Icon/Icon';
import OptionSelector from '../../../components/OptionSelector/OptionSelector';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { Action, Entity } from '../../../utils/EntityAction';
import Alert from '../../../elements/Alert/Alert';
import { CogOptionsDropdown } from '../../../elements/CogOptionsDropdown/CogOptionsDropdown';
import { Categories } from '../../../api/inni/Categories';


type ReadAgentStatementWrapperProps = {
	agentStatement: LettingStatement;
	agentName: string;
	editing: boolean;
	setEditing: (editing: boolean) => void;
	formikRef: React.MutableRefObject<FormikProps<LettingStatementPostModel> | null>;
	setCompleteAgentStatement: (complted: boolean) => void;
	diyAgentStatements: boolean;
	children: React.ReactNode;
	setAgentDetailsLoaded: (loaded: boolean) => void;
	v8Styling: boolean;
};

type LettingStatementLayoutProps = {
	children: React.ReactNode;
};

interface RouteParams {
	cid: string;
	id: string;
	fromReminder?: string;
}

interface AgentProps {
	formikRef: React.MutableRefObject<FormikProps<LettingStatementPostModel> | null>;
	setEditing: (editing: boolean) => void;
	setCompleteAgentStatement: (completed: boolean) => void;
	initialValues: LettingStatement;
	diyAgentStatements: boolean;
	setAgentDetailsLoaded: (loaded: boolean) => void
}

type LettingStatementReactSelect = {
	label:string,
	value:number
}



function ReadAgentStatementWrapper({
		agentStatement, agentName, editing,
		formikRef, setEditing, setCompleteAgentStatement,
		diyAgentStatements ,children, setAgentDetailsLoaded,
		v8Styling}: ReadAgentStatementWrapperProps) {

	return (
		<div className="d-lg-flex mb-3">
			<div style={{ maxWidth: !editing ?'900px' : '1600px', width: '100%' }}> 
				<LettingStatementLayoutWrapper>				
						
						<div className={classNames(styles.invoiceHeaderFont, styles.lettingStatement, 'my-3 mx-3')}>
							{!agentStatement.detailCompletedOn && <span style={{marginLeft: '0.5rem',fontSize:'1.5rem'}} className='text-muted font-italic'>(draft)</span>}
							{agentStatement.transactionDescription &&
								<div className={classNames(styles.invoiceSubHeaderFont, styles.lettingStatement)}>{agentStatement.transactionDescription}</div>
							}
						</div>
						{!editing && 
							<> 							
								<div className={classNames(styles.agentStatementRead, 'px-2')}>
									<Form.Group as={Col}>								
										<Form.Label className="mb-0">Statement number</Form.Label>
										<Form.Text className="">{agentStatement?.statementNumber}</Form.Text>
									</Form.Group>								
									<Form.Group as={Col}>
										<Form.Label className="mb-0">Letting agent</Form.Label>
										<Form.Text className="">{agentName}</Form.Text>
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label className="mb-0">Payment to {agentStatement?.netPaymentToLandlord > 0 ? "you" : "letting agent"}</Form.Label>
										<Form.Text className="">{currencyConvertor(agentStatement?.netPaymentToLandlord,undefined)}</Form.Text>
									</Form.Group>
									<Form.Group as={Col}>
										<Form.Label className="mb-0">Created on</Form.Label>
										<Form.Text className="">{formatDate(agentStatement?.date)}</Form.Text>
									</Form.Group>
								</div>
							
							</>
						}
						
						{!editing && agentStatement?.lettingStatementLines && agentStatement?.lettingStatementLines?.length > 0 && (
							<div
								className={classNames(styles.invoiceTable, 'text-right')}
								data-cy="lettingStatementLinesTable"
							>
								<table>
									<thead>
										<tr className="text-muted">
											<th className="text-left" style={{width: '100%'}}>
												Description
											</th>
											{!agentStatement.showVat && <th></th>}
											<th className="text-right">Amount</th>
											{agentStatement.showVat &&
												<>
												<th className="text-right">VAT Code</th>
												<th className="text-right">Total</th>
												</>
											}	
										</tr>
									</thead>
									<tbody>
										{agentStatement?.lettingStatementLines?.sort((x,y) => ((x.lineNumber || 0) - (y.lineNumber || 0))).map((detail) => (
											<tr key={detail.id}>
											<td className="text-left">{detail.description}</td>
											{!agentStatement.showVat && <td></td>}
											<td className="text-right">{detail.amountExVAT && currencyConvertor(detail.amountExVAT, undefined)}</td>
											{agentStatement.showVat &&
											<>												
											<td className="text-right">{detail.vatCode}</td>
											<td className="text-right">{currencyConvertor((detail?.amountVAT||0)+(detail?.amountExVAT||0), undefined)}</td>
											</>
											}
											</tr>
										))}
									</tbody>
									<tfoot>									
										{agentStatement.showVat &&
											<>
												<TableTotalField
													colspan={2}													
													text="Sub total"
													value={
														agentStatement?.lettingStatementLines && agentStatement?.lettingStatementLines
															? agentStatement?.lettingStatementLines.reduce((a, b) => a + (b.amountExVAT || 0), 0)
															: 0
													}
												/>												
												<TableTotalField	
													colspan={2}													
													text="Total VAT"
													value={
														agentStatement?.lettingStatementLines &&
														(agentStatement?.lettingStatementLines
															? agentStatement?.lettingStatementLines.reduce((a, b) => a + (b.amountVAT || 0), 0)
															: 0)
													}
												/>												
											</>
										}										
										<TableTotalField
											colspan={agentStatement.showVat?2:1}
											text="Grand Total"
											value={
												agentStatement?.lettingStatementLines && agentStatement?.lettingStatementLines
													? agentStatement?.lettingStatementLines.reduce((a, b) => a + (b.amountVAT || 0), 0)+
													agentStatement?.lettingStatementLines.reduce((a, b) => a + (b.amountExVAT || 0), 0)
													: 0
											}
											bold
										/>
																		
									</tfoot>
								</table>
							</div>
						)}
						{editing && (
							v8Styling
							? (
								<CreateAgentStatement
									initialValues={agentStatement}
									setEditing={setEditing}
									setCompleteAgentStatement={setCompleteAgentStatement}
									formikRef={formikRef}
									diyAgentStatements={diyAgentStatements}
									setAgentDetailsLoaded={setAgentDetailsLoaded}
								/>
							)
							: (
								<EditAgentStatementWrapper
									initialValues={agentStatement}
									setEditing={setEditing}
									setCompleteAgentStatement={setCompleteAgentStatement}
									formikRef={formikRef}
									diyAgentStatements={diyAgentStatements}
									setAgentDetailsLoaded={setAgentDetailsLoaded}
								/>
							)
						)}
				{children}
				</LettingStatementLayoutWrapper>
			</div>
			{!editing && !diyAgentStatements && (
				<div className="ml-2">
					<Card className={styles.invoiceTimeLine}>
						<Card.Body>
							<TimeLine timeLine={agentStatement.lettingStatementTimeLines} onClickInvoiceTimeLineButton={() => {}}></TimeLine>
						</Card.Body>
					</Card>
				</div>
			)}
		</div>
	);
}


const CreateAgentStatement = ({ formikRef, initialValues }: AgentProps) => {
	const [currentStage, setCurrentState] = useState(0);
	const [addingNew, setAddingNew] = useState(false);
	const companyId = parseInt(useParams<RouteParams>().cid);
	const agentStatementId = parseInt(useParams<RouteParams>().id);
	const agentDetailsAPI = useInniAPI(LettingStatements, [400]);
	const categoriesApi = useInniAPI(Categories, [400]);
	const lettingAgentsAPI= useInniAPI(LettingAgents, [400]);
	const [accounts, setAccounts] = useState<LSAccount[]>();
	const vatAPI = useInniAPI(Expenses);
	const [vatRate, setVatRate] = useState<VatCodeListOption[]>();
	const [agents, setAgents] = useState<InvoiceReactSelect[]>();
	const propertyAPI = useInniAPI(Properties);
	const [propertyDDOptions,setPropertyDDOptions] = useState<LettingStatementReactSelect[]>();  
	const [selectedPropertyId, setSelectedPropertyId]=useState<number>(0);
	const [showModalDialog, modalDialog] = useModalDialog();
	const agentStatementsAPI = useInniAPI(LettingStatements, [400]);
	const navigateToEntity = useNavigateToEntity();
	const [errorMessage, setErrorMessage] = useState<string>();
	const [allowSaveAnyway, setAllowSaveAnyway] = useState(false);
	const companyContext = useContext(CompanyContext);
	
	useEffect(() => {
		if (vatAPI) { 
			vatAPI.vatRates(companyId).then((response) => setVatRate(response.data));
		}
	}, [vatAPI, companyId]);

	useEffect(() => {
		if(categoriesApi && accounts === undefined) {
			categoriesApi.getLsAccounts(companyId).then(res => {
					setAccounts(res.data);
			})
		}
	}, [categoriesApi, companyId, accounts]);

	useEffect(()=>{
		lettingAgentsAPI?.index(companyId).then((response: { data: LettingAgent[] })=>{
		setAgents(
				response.data.map((agents: LettingAgent)=>{
					return {
						value: agents.id,
						label: agents.name,
					};
				}).sort(function (a, b) {
					if (a.label && b.label && (a.label < b.label)) {
						return -1;
					}
					if (a.label && b.label &&(a.label > b.label)) {
						return 1;
					}
					return 0;
				})
			);
		});
	},[lettingAgentsAPI,companyId]);

	useEffect(() => {propertyAPI && propertyAPI.index(companyId).then((response: { data: Property[] }) => {
		let propOptions: { value: number; label: string }[] = [];
		response.data.forEach((properties: Property) => {
			propOptions.push({
				value: properties.id,
				label: properties.name,
			});
		})
		propOptions.push()
		setPropertyDDOptions(propOptions)
	})}, [propertyAPI, companyId]);

	useEffect(()=>{		
		if(initialValues) {
			if(initialValues.lettingStatementLines && initialValues.lettingStatementLines.length > 0) {
				const propertyIds = initialValues.lettingStatementLines?.map(x=>x.propertyId)
				if(propertyIds[0] && propertyIds.every((val, index, arr) => val === arr[0])){
					setSelectedPropertyId(propertyIds[0]);			
				}
			}
		}
	},[initialValues]);

	const UpdateAgentStatement = (values: LettingStatementPostModel, actions: FormikHelpers<LettingStatementPostModel>) => {
		setAllowSaveAnyway(false);
		setErrorMessage(undefined);

		let newValues = { ...values };
		if(newValues.lettingStatementLines) {
			newValues.lettingStatementLines.forEach(x => {
				if(x.propertyId === 0) x.propertyId = undefined
			})
		}

		// we submit save request first, then a 'complete' request, this is bcos previously we had a draft save feature
		return new Promise((resolve, reject) => {
			agentDetailsAPI?.updateLettingStatement(companyId, agentStatementId, newValues).then((response) => {
				if (response.status === 200) {
					agentStatementsAPI?.markAsDetailsCompleted(companyId, agentStatementId).then((response) => {
						if (response.status === 200) {
							resolve(response);
							navigateToEntity(Entity.BookkeepingV8, Action.List, {'activePage' : 'lettingstatements'})
						} else {
							console.warn('Unexpected return code')
							setErrorMessage('Sorry, something went wrong. Please try again.')
							reject(response);
						}
					}).catch((error) => {
						// Bad request, we probably have a user friendly message
						if (error.status === 400) {
							setErrorMessage(error.error)
							if (error.error.includes('save anyway'))
								setAllowSaveAnyway(true);
						} else {
							setErrorMessage('Sorry, something went wrong. Please try again.')
							console.error(error)
						}
						reject(error);
					})
				} else {
					console.warn('Unexpected return code')
					setErrorMessage('Sorry, something went wrong. Please try again.')
					reject(response);
				}
			}).catch((error) => {
				// Bad request, we probably have a user friendly message
				if (error.status === 400) {
					setErrorMessage(
						(error.hasOwnProperty('error') &&
						Object.values(error.error)[0]) ||
						JSON.stringify(error.error) ||
						'Sorry, something went wrong. Please try again.'
					);
				} else {
					setErrorMessage('Sorry, something went wrong. Please try again.')
					console.error(error)
				}
				reject(error);
			})
		})
	}

	const AddNewAgentOption = (values: LettingAgentPostModel, actions: FormikHelpers<LettingAgentPostModel>) => {
		return new Promise((resolve, reject) => {
			if (companyContext && lettingAgentsAPI) {
				lettingAgentsAPI.create(companyContext.cid, values).then(({data}) => {
					setAgents(prev => prev ? [...prev, {value: +data, label: values.name}] : [{value: +data, label: values.name}]);
					resolve('ok');
					prevStage();
				})
				.catch((error) => {
					console.error(error.error);
					reject(error);
				})
			} else {
				console.error('Context not reaady')
				reject();
			}
		})
	}

	const handleForceSave = () => {
		agentStatementsAPI?.forceSave(companyId, agentStatementId).then((response) => {
			if (response.status === 200) {
				navigateToEntity(Entity.BookkeepingV8, Action.List, {'activePage' : 'lettingstatements'})
			} else {
				console.warn('Unexpected return code')
				setErrorMessage('Sorry, something went wrong. Please try again.')
			}
		}).catch((error) => {
			// Bad request, we probably have a user friendly message
			if (error.status === 400) {
				setErrorMessage(error.error)
				if (error.error.includes('save anyway'))
					setAllowSaveAnyway(true);
			} else {
				setErrorMessage('Sorry, something went wrong. Please try again.')
				console.error(error.error)
			}
		});
	}

	const setPropertyForAll=(propertyId:number,values:LettingStatementPostModel)=>{
		setSelectedPropertyId(propertyId)
		if(propertyId !== 0){
			values.lettingStatementLines?.forEach((item)=>{
				item.propertyId=propertyId;
			});	
		}
	}

	const accountCanBeReplacement = (accountId:number) => {
		if (accounts) {
			const account = accounts.find(account => account.id === accountId);
			if(account) return account.showIsReplacementItem;
			return true;
		}
		return true;
	}

	const accountEligibleForAsset = (accountId:number) => {
		if (accounts) {
			const account = accounts.find(account => account.id === accountId);
			if(account) return account.isEligibleForAssets;
			return true;
		}
		return true;
	}

	const accountCanIncreasePropValue = (accountId:number) => {
		if (accounts) {
			const account = accounts.find(account => account.id === accountId);
			if(account) return account.showIncreasesValue;
			return true;
		}
		return true;
	}

	const getHelpTextProperty = (props: any) => {
        return (
            <Tooltip {...props}>
                <div style={{textAlign: 'left'}}>
					Upgrades are taxed differently and need to be accounted for correctly. E.g, adding an en-suite that increases the property value vs a like-for-like bathroom replacement.
                </div>
            </Tooltip>
        )
    }

	const getHelpTextAsset = (props: any) => {
        return (
            <Tooltip {...props}>
                <div style={{textAlign: 'left'}}>
					Assets are valuable items costing £200+ that have future monetary value and increase the value of your business. Usually these are physical goods e.g. furniture, computers and appliances.
                </div>
            </Tooltip>
        )
    }

	const getHelpTextReplacement = (props: any) => {
        return (
            <Tooltip {...props}>
                <div style={{textAlign: 'left'}}>
					Replacements items are taxed differently and need to be accounted for correctly. E.g, when replacing a kitchen appliance.
                </div>
            </Tooltip>
        )
    }

	const getHelpTextDate = (props: any) => {
        return (
            <Tooltip {...props}>
                <div style={{textAlign: 'left'}}>
					If this payment covers a specific date range, please enter the date range it covers to ensure it's correctly accounted for.
                </div>
            </Tooltip>
        )
    }
	
	const isPositiveLine = (line:LettingStatementLine) => {
        if(accounts) {
            const account = accounts.find(x => x.id === line.accountID)
            if(account) {
                return account.accountGroup !== "Expenses"
            }
            return true
        }
        return true
    }

	const stages = [
		{
			key: 'intro',
			title: 'Categorise your letting statement',
			subtitle: ''
		},
        {
            key: 'agent',
            title: 'Your letting agent',
            subtitle: 'Tell us which agent this statement is from. If you haven\'t already, you can create the agent now.',
			adding: {
				title: 'Add new agent',
				subtitle: ''
			}
        },
		{
			key: 'statementNumber',
			title: 'Statement number',
			subtitle: 'If your statements are numbered, enter the number below.'
		},
		{
			key: 'selectProperty',
			title: 'Which property?',
			subtitle: 'Which property is this statement for?'
		},
		{
            key: 'categorise',
            title: 'Categorise your statement',
            subtitle: 'Enter each entry listed on your letting statement and assign it the appropriate category and value.'
        },
	]

	const nextStage = () => {
		setCurrentState(prevState => prevState + 1)
	}

	const prevStage = () => {
		if (addingNew)
			setAddingNew(false);
		else
			setCurrentState(prevState => prevState - 1)
	}

	const onChange = () => {
		// we don't validate until save, but remove the force save & error messages on updates
		setAllowSaveAnyway(false);
		setErrorMessage(undefined);
	}

	return (
		<FullScreen
			isOpen={true}
			onClose={() => navigateToEntity(Entity.BookkeepingV8, Action.List, {'activePage' : 'lettingstatements'})}
			contentTitle={addingNew ? stages[currentStage].adding?.title || '' : stages[currentStage].title}
			contentSubtitle={addingNew ? stages[currentStage].adding?.subtitle || '' : stages[currentStage].subtitle}
			headerTitle={'Letting statement'}
			subTitle={`Payment to landlord: ${formatCurrency(initialValues.netPaymentToLandlord)}`}
			date={format(new Date(initialValues.date), "EEEE do MMMM ''yy")}
			onBack={currentStage !==  0 ? prevStage : undefined}
		>
			{ !addingNew
				? (
					<Formik
						onSubmit={UpdateAgentStatement}
						innerRef={formikRef}
						initialValues={{ ...initialValues }}
						enableReinitialize
						validateOnChange
						validate={onChange}
					>
						{({ values, handleSubmit, errors, touched, isSubmitting }) => {
							return (						
								<Form onSubmit={handleSubmit}>
									{ stages[currentStage].key === 'intro' && (
										<>
											<p>
												Previously, during bookkeeping, you categorised a transaction as a letting agent statement. We will now begin to categorise this in more detail.
												<br />
												If this is incorrect, and you do not have a letting agent-managed letting, please go back and re-categorise this entry.
											</p>
											<p>You will need your statement ready in front of you so that we can accurately break it down line by line.</p>
											<p>If you have multiple letting statements, please check the details above to identify the correct one.</p>

											<Button 
												marginTop
												variant='primary' 
												thin  
												onClick={nextStage}
											>
												Let's go <Icon className="m-0" name='arrowNext'/>
											</Button>
										</>	
									)}

									{ stages[currentStage].key === 'agent' && (
										<>
											<FormikField<LettingStatement>
												type="select"
												name="lettingAgentID"
												label="Letting agent"											
												option={agents}
												placeholder="None"
												width='300px'
												className='p-0 mb-3'
											/>

											<Button 
												marginTop
												variant='primary' 
												thin  
												onClick={nextStage}
											>
												Continue <Icon className="m-0" name='arrowNext'/>
											</Button>

											<Button 
												marginTop
												variant="secondary" 
												onClick={() => setAddingNew(true)}
											>
												Add new agent
											</Button>
										</>
									)}

									{ stages[currentStage].key === 'statementNumber' && (
										<>
											<FormikField<LettingStatement>
												type="number"
												name="statementNumber"
												label='Statement number'
												width='200px'
												className='p-0 mb-3'
											/>

											<Button 
												marginTop
												variant='primary' 
												thin
												onClick={nextStage}
											>
												Continue <Icon className="m-0" name='arrowNext'/>
											</Button>
										</>
									)}

									{ stages[currentStage].key === 'selectProperty' && (
										<OptionSelector
											onChange={(propertyId) => {
												setPropertyForAll(propertyId[0].value, values)
												nextStage()
											}}
											preventSaveState
											options={[
												{singleEntry: true, list: [{label: 'It is for more than one property', value: 0}]},
												{singleEntry: true, list: propertyDDOptions ? propertyDDOptions : []}
											]}
										/>
									)}

									{ stages[currentStage].key === 'categorise' && (
										<>
											<div
												className={classNames(
													styles.invoiceTable,
													styles.lettingStatement,
													styles.invoiceTableV8,
													'text-right'
												)}
												data-cy="lettingStatementLinesTable"
											>
												<table>
													<thead>
														<tr className="text-muted" style={{verticalAlign: 'bottom'}}>
															<th className="text-left">Description</th>
															<th className="text-left" style={{width: '100px'}}>Amount</th>
															{initialValues.showVat &&														
																<th className="text-left">VAT Code</th>
															}
															<th className={classNames("text-left", styles.helpIcon)}>
																Start date 
																<OverlayTrigger placement="top" overlay={getHelpTextDate}>
																	<FontAwesomeIcon icon={faCircleQuestion} className="ml-2" />
																</OverlayTrigger>
															</th>
															<th className={classNames("text-left", styles.helpIcon)}>
																End date 
																<OverlayTrigger placement="top" overlay={getHelpTextDate}>
																	<FontAwesomeIcon icon={faCircleQuestion} className="ml-2" />
																</OverlayTrigger>
															</th>
															<th className={classNames("text-left", styles.helpIcon)}>
																Replacement
																<br />
																item
																<OverlayTrigger placement="top" overlay={getHelpTextReplacement}>
																	<FontAwesomeIcon icon={faCircleQuestion} className="ml-2" />
																</OverlayTrigger>
															</th>
															<th className={classNames("text-left", styles.helpIcon)}>
																Increases
																<br />
																property value
																<OverlayTrigger placement="top" overlay={getHelpTextProperty}>
																	<FontAwesomeIcon icon={faCircleQuestion} className="ml-2" />
																</OverlayTrigger>
															</th>
															<th className={classNames("text-left", styles.helpIcon)}>
																New asset
																<OverlayTrigger placement="top" overlay={getHelpTextAsset}>
																	<FontAwesomeIcon icon={faCircleQuestion} className="ml-2" />
																</OverlayTrigger>
															</th>
															<th className="text-left">Property</th>
															{initialValues.showVat && <th className="text-right">Total</th>}
															<th></th>
														</tr>
													</thead>
													<FieldArray name="lettingStatementLines">
														{({ remove, push, form}) => (													
															<>
																{modalDialog}
																
																<tbody>
																	{values.lettingStatementLines&&values.lettingStatementLines?.length>0?(
																		values.lettingStatementLines?.sort((x,y) => ((x.lineNumber || 0) - (y.lineNumber || 0)))
																			.map((item, index) => (
																				<tr key={index + 'lettingstatementline'}>
																					<td>
																						<FormikField<LettingStatementLine>
																							name={`lettingStatementLines[${index}].description` as 'description'}
																							type="text"
																							isTable
																							className="px-0 mb-0"
																						/>
																					</td>																	
																					<td>
																						<div style={{width:"100%", minWidth:"100px"}}>
																							<FormikField<LettingStatementLine>
																								name={`lettingStatementLines[${index}].amountExVAT` as 'amountExVAT'}
																								prefix={isPositiveLine(item) ? "+" : "-"}
																								prefixWidth={"small"}
																								type="number"
																								className="px-0 mb-0"
																								width="5rem"
																								isTable
																							/>
																						</div>
																						
																					</td>
																					{initialValues?.showVat &&	
																						<td>
																							<FormikField<LettingStatementLine>
																								name={`lettingStatementLines[${index}].vatCode` as 'vatCode'}
																								type="select"
																								className="px-0 mb-0 text-left"
																								isTable
																								option={vatRate?.map((x) => ({
																									value: x.id,
																									label: x.name
																								}))}
																								width='180px'
																							/>
																						</td>
																					}
																					<td>
																						<FormikField<LettingStatementLine>
																						name={`lettingStatementLines[${index}].applicablePeriodStart` as 'applicablePeriodStart'}
																						type="date"
																						className="px-0 mb-0"
																						isTable
																						showErrorLine={errors.lettingStatementLines === undefined ? true : false} 
																						/>
																					</td>
																					<td>
																						<FormikField<LettingStatementLine>
																						name={`lettingStatementLines[${index}].applicablePeriodEnd` as 'applicablePeriodEnd'}
																						type="date"
																						className="px-0 mb-0"
																						isTable
																						/>
																					</td>
																					<td>
																						<FormikField<LettingStatementLine>
																						name={`lettingStatementLines[${index}].isReplacementItem` as 'isReplacementItem'}
																						type="checkbox"
																						className="px-0 mb-0"
																						width="6rem"
																						isTable
																						readonly={values.lettingStatementLines && !accountCanBeReplacement(values.lettingStatementLines[index].accountID)}
																						/>
																					</td>
																					<td>
																						<FormikField<LettingStatementLine>
																						name={`lettingStatementLines[${index}].increasesPropertyValue` as 'increasesPropertyValue'}
																						type="checkbox"
																						className="px-0 mb-0"
																						width="6rem"
																						isTable
																						readonly={values.lettingStatementLines && !accountCanIncreasePropValue(values.lettingStatementLines[index].accountID)}
																						/>
																					</td>
																					<td>
																						<FormikField<LettingStatementLine>
																						name={`lettingStatementLines[${index}].createAsset` as 'createAsset'}
																						type="checkbox"
																						className="px-0 mb-0"
																						width="6rem"
																						isTable
																						readonly={values.lettingStatementLines && !accountEligibleForAsset(values.lettingStatementLines[index].accountID)}
																						/>
																					</td>
																					
																					<td>
																						<FormikField<LettingStatementLine>
																							name={`lettingStatementLines[${index}].propertyId` as 'propertyId'}
																							type="select"
																							className="px-0 mb-0 text-left"
																							isTable
																							option={propertyDDOptions && propertyDDOptions.filter(x => x.value !== 0).length > 0 ? 
																								propertyDDOptions.filter(x => x.value !== 0) : 
																								undefined}
																							width={'120px'}
																							readonly={selectedPropertyId !== 0 && selectedPropertyId !== undefined}
																						/>
																					</td>
																					{initialValues?.showVat &&
																						<td>																		
																							{item &&
																							currencyConvertor((item?.amountExVAT||0)+(item?.amountVAT||0),undefined) as
																							string}																	 
																						</td>
																					}																	
																					<td>																		
																						<CogOptionsDropdown
																							hamburger
																							del={() => {
																								showModalDialog(
																									'Delete letting statement line?',
																									`Are you sure you want to delete this letting statement line?`,
																									[
																										<Button variant="danger" label="Yes" onClick= {()=> remove(index) } />,
																										<Button variant="primary" outline label="No" onClick={() => {}} />
																									],
																									false
																								);
																							}}
																						/>
																					</td>
																				</tr>
																			))
																		):(
																			<tr>
																				<td
																					colSpan={initialValues.showVat ? 11 : 9}
																					className="text-muted"
																					style={{ textAlign: 'center', padding: '3rem 0' }}
																				>
																					To begin, add a line using the button below
																				</td>
																			</tr>
																	)}
																	
																</tbody>
																
																{touched && errors.lettingStatementLines && (
																	<tbody>
																		<tr>
																			<td colSpan={initialValues.showVat ? 11 : 9} className="text-left">
																				<small className="invalid-feedback" style={{ display: 'block' }}>
																					{errors.lettingStatementLines}
																				</small>
																			</td>
																		</tr>
																	</tbody>
																)}
																												
																<tbody>
																	<tr>
																		<td colSpan={initialValues.showVat ? 11 : 9} style={{ float: 'left' }}>
																			<AgentStatementsDropDown
																				lines={values.lettingStatementLines}
																				accounts={accounts || []}
																				setTableValues={(e, values) => {
																					push(values);
																				}}
																				showVAT={initialValues?.showVat}
																				propertyId={selectedPropertyId !== 0 ? selectedPropertyId : undefined}
																			/>
																		</td>
																	</tr>
																</tbody>
															</>
														)}
													</FieldArray>
													<tfoot>											
														{initialValues?.showVat ?(
															<>
																<TableTotalField
																	colspan={8}															
																	text="Sub total"
																	value={
																		values?.lettingStatementLines?.reduce((sum: number, lsl: LettingStatementLine) => {
																			const amountExVAT = lsl.amountExVAT || 0;
																			const lineExVATAmount = isPositiveLine(lsl) ? amountExVAT  : -(amountExVAT);
																			return sum + lineExVATAmount;
																		}, 0) || 0
																	}
																/>
																<TableTotalField	
																	colspan={8}															
																	text="Total VAT"
																	value={
																		values?.lettingStatementLines?.reduce((sum: number, lsl: LettingStatementLine) => {
																			const amountVAT = (lsl.amountExVAT || 0)*(vatRate?.find(x => x.id === lsl.vatCode)?.rate || 0);
																			const lineVATAmount = isPositiveLine(lsl) ? amountVAT : -(amountVAT);
																			return sum + lineVATAmount;
																		}, 0) || 0
																	}
																/>
																<TableTotalField
																	colspan={8}																
																	text="Grand Total"
																	value={
																		values?.lettingStatementLines?.reduce((sum: number, lsl: LettingStatementLine) => {
																			const amountExVAT = lsl.amountExVAT || 0;
																			const amountVAT = (lsl.amountExVAT || 0)*(vatRate?.find(x => x.id === lsl.vatCode)?.rate || 0);
																			const lineTotalAmount = isPositiveLine(lsl) ? amountExVAT + amountVAT : -(amountExVAT + amountVAT);
																			return sum + lineTotalAmount;
																		}, 0) || 0
																	}
																	bold
																/>	
															</>
														):(
															<TableTotalField
																	colspan={7}
																	text="Grand Total"
																	value={
																		values?.lettingStatementLines?.reduce((sum: number, lsl: LettingStatementLine) => {
																			const amountExVAT = lsl.amountExVAT || 0;
																			const lineAmount = isPositiveLine(lsl) ? amountExVAT  : -(amountExVAT);
																			return sum + lineAmount;
																		}, 0) || 0
																	}
																	bold
																/>	
														)}																						
													</tfoot>
												</table>
											</div>
											{ errorMessage && (
												<Alert
													variant="info"
													alertMessage={errorMessage}
													setAlertMessage={() => {}}
													inline
												/>
											)}

											{ allowSaveAnyway
												? <Button buttonType="save" label="Save anyway" onClick={handleForceSave} disabled={isSubmitting} />
												: <Button buttonType="save" marginTop={!errorMessage} submitButton disabled={isSubmitting || values.lettingStatementLines?.length === 0} />
											}
										</>
									)}
								</Form>
							)
						}}
					</Formik>
				)
				: (
					// Add new letting agent form
					<Formik
						onSubmit={AddNewAgentOption}
						initialValues={{name: ''} as LettingAgentPostModel}
					>
						{({ values, handleSubmit, errors, touched, setFieldValue, isSubmitting }) => {
							return (						
								<Form onSubmit={handleSubmit} style={{width: '300px'}}>
									<FormikField<LettingAgentPostModel>
										type="text"
										name="name"
										label="Letting agent name"
										className="p-0 mb-3"
									/>
									<Button buttonType="save" label="Add" marginTop submitButton />
								</Form>
							)
						}}
					</Formik>
				)
			}
		</FullScreen>
	)
}


function EditAgentStatementWrapper({ formikRef, setEditing, setCompleteAgentStatement, initialValues, diyAgentStatements, setAgentDetailsLoaded }: AgentProps) {
	const companyId = parseInt(useParams<RouteParams>().cid);
	const agentStatementId = parseInt(useParams<RouteParams>().id);
	const agentDetailsAPI = useInniAPI(LettingStatements, [400]);
	const lettingAgentsAPI= useInniAPI(LettingAgents, [400]);
	const categoriesApi = useInniAPI(Categories, [400]);
	const [accounts, setAccounts] = useState<LSAccount[]>();
	const vatAPI = useInniAPI(Expenses);
	const [vatRate, setVatRate] = useState<VatCodeListOption[]>();
	const [agents, setAgents] = useState<InvoiceReactSelect[]>();
	const propertyAPI = useInniAPI(Properties);
	const [propertyDDOptions,setPropertyDDOptions] = useState<LettingStatementReactSelect[]>();  
	const [selectedPropertyId, setSelectedPropertyId]=useState<number>(0);
	const [showModalDialog, modalDialog] = useModalDialog();
	const [showAddNewAgentModal, setShowAddNewAgentModal] = useState(false);
	const companyContext = useContext(CompanyContext);
	
	useEffect(() => {
		if (vatAPI) { 
			vatAPI.vatRates(companyId).then((response) => setVatRate(response.data));
		}
	}, [vatAPI, companyId]);

	useEffect(() => {
		if(categoriesApi && accounts === undefined) {
			categoriesApi.getLsAccounts(companyId).then(res => {
				setAccounts(res.data);
			})
		}
	}, [categoriesApi, companyId, accounts]);

	useEffect(()=>{
		lettingAgentsAPI?.index(companyId).then((response: { data: LettingAgent[] })=>{
		setAgents(
				response.data.map((agents: LettingAgent)=>{
					return {
						value: agents.id,
						label: agents.name,
					};
				}).sort(function (a, b) {
					if (a.label && b.label && (a.label < b.label)) {
						return -1;
					}
					if (a.label && b.label &&(a.label > b.label)) {
						return 1;
					}
					return 0;
				})
			);
		});
	},[lettingAgentsAPI,companyId]);

	useEffect(() => {propertyAPI && propertyAPI.index(companyId).then((response: { data: Property[] }) => {
		let propOptions = [{value: 0, label: 'None'}]
		response.data.forEach((properties: Property) => {
			propOptions.push({
				value: properties.id,
				label: properties.name,
			});
		})
		propOptions.push()
		setPropertyDDOptions(propOptions)
	})}, [propertyAPI, companyId]);

	useEffect(()=>{		
		if(initialValues) {
			if(initialValues.lettingStatementLines && initialValues.lettingStatementLines.length > 0) {
				const propertyIds = initialValues.lettingStatementLines?.map(x=>x.propertyId)
				if(propertyIds[0] && propertyIds.every((val, index, arr) => val === arr[0])){
					setSelectedPropertyId(propertyIds[0]);			
				}
			}
		}
	},[initialValues]);

	const UpdateAgentStatement = (values: LettingStatementPostModel, actions: FormikHelpers<LettingStatementPostModel>) => {
			let newValues = { ...values };
			if(newValues.lettingStatementLines) {
				newValues.lettingStatementLines.forEach(x => {
					if(x.propertyId === 0) x.propertyId = undefined
				})
			}
			return new Promise((resolve, reject) => {
				agentDetailsAPI
					?.updateLettingStatement(companyId, agentStatementId, newValues)
					.then((response) => {
						if(response.status === 200) {
							resolve(response);
						} 
						else {
							reject(response);
						}
					})
					.catch((error) => {
						reject(error);
					});
			});
	};

	const addNewAgentOption = (pm: LettingAgentPostModel, onSuccess?: (data: {id: number, name?: string}) => void) => {
        if(companyContext && lettingAgentsAPI) {
            lettingAgentsAPI.create(companyContext.cid, pm)
                .then(({data}) => {
                    setAgents(prev => prev ? [...prev, {value: +data, label: pm.name}] : [{value: +data, label: pm.name}]);
                    return {id: +data, name: pm.name};
                })
                .then(onSuccess)
                .catch(err => console.error(err))
        }
    }

	// const validate = (values: LettingStatementPostModel): Promise<FormikErrors<LettingStatementPostModel>> => {
	// 	let newValues = { ...values };
	// 	if (newValues.lettingStatementLines && newValues.lettingStatementLines.length > 0) {
	// 		newValues.lettingStatementLines.forEach((line) => {
	// 			line.amountVAT =  (line.amountExVAT || 0) * ((vatRate?.find((vat) => vat.id === line.vatCode)?.rate) || 0);
	// 		});		
	// 	}
	// 	return new Promise((resolve) => {
	// 		agentDetailsAPI
	// 			?.validateUpdate(companyId, { lettingStatementId: agentStatementId }, newValues)
	// 			.then((response) => {
	// 				resolve({});
	// 			})
	// 			.catch((error) => {
	// 				resolve(error.error);
	// 			});
	// 	});
	// };

	const setPropertyForAll=(propertyId:number,values:LettingStatementPostModel)=>{
		setSelectedPropertyId(propertyId)
		if(propertyId !== 0){
			values.lettingStatementLines?.forEach((item)=>{
				item.propertyId=propertyId;
			});	
		}
	}

	const accountCanBeReplacement = (accountId:number) => {
		if (accounts) {
			const account = accounts.find(account => account.id === accountId);
			if(account) return account.showIsReplacementItem;
			return true;
		}
		return true;
	}

	const accountEligibleForAsset = (accountId:number) => {
		if (accounts) {
			const account = accounts.find(account => account.id === accountId);
			if(account) return account.isEligibleForAssets;
			return true;
		}
		return true;
	}

	const accountCanIncreasePropValue = (accountId:number) => {
		if (accounts) {
			const account = accounts.find(account => account.id === accountId);
			if(account) return account.showIncreasesValue;
			return true;
		}
		return true;
	}

	const getHelpTextProperty = (props: any) => {
        return (
            <Tooltip {...props}>
                <div style={{textAlign: 'left'}}>
					Upgrades are taxed differently and need to be accounted for correctly. E.g, adding an en-suite that increases the property value vs a like-for-like bathroom replacement.
                </div>
            </Tooltip>
        )
    }

	const getHelpTextAsset = (props: any) => {
        return (
            <Tooltip {...props}>
                <div style={{textAlign: 'left'}}>
					Assets are valuable items costing £200+ that have future monetary value and increase the value of your business. Usually these are physical goods e.g. furniture, computers and appliances.
                </div>
            </Tooltip>
        )
    }

	const getHelpTextReplacement = (props: any) => {
        return (
            <Tooltip {...props}>
                <div style={{textAlign: 'left'}}>
					Replacements items are taxed differently and need to be accounted for correctly. E.g, when replacing a kitchen appliance.
                </div>
            </Tooltip>
        )
    }

	const getHelpTextDate = (props: any) => {
        return (
            <Tooltip {...props}>
                <div style={{textAlign: 'left'}}>
					If this payment covers a specific date range, please enter the date range it covers to ensure it's correctly accounted for.
                </div>
            </Tooltip>
        )
    }
	
	const isPositiveLine = (line:LettingStatementLine) => {
        if(accounts) {
            const account = accounts.find(x => x.id === line.accountID)
            if(account) {
                return account.accountGroup !== "Expenses"
            }
            return true
        }
        return true
    }

	return (
		<>
			{initialValues && vatRate && accounts ? (				
				<Formik
					onSubmit={UpdateAgentStatement}
					innerRef={formikRef}
					initialValues={{ ...initialValues }}
					enableReinitialize
					validateOnChange
					validate={() => {}}>
					{({ values, handleSubmit, errors, touched, setFieldValue }) => {
						return (						
							<Form onSubmit={handleSubmit}>
								<>
									<div className={classNames(styles.agentStatementRead)}>										
									<Row>
										<Col>
											<FormikField<LettingStatement>
												type="number"
												name="statementNumber"
												label='Statement number'
											/>
										</Col>
										<Col>
											<FormikField<LettingStatement>
												type="select"
												name="lettingAgentID"
												label="Letting agent"											
												option={agents}
												selectAddNew={diyAgentStatements ? () => setShowAddNewAgentModal(true) : undefined}
												placeholder="None"
											/>
										</Col>
										<Col>
											<FormikField<LettingStatement>
												prefix={"£"}
												type="number"
												name="netPaymentToLandlord"
												label={values.netPaymentToLandlord > 0 ? "Payment to you" : "Payment to letting agent"}
												readonly
												toFixed2DP
											/>
										</Col>
										<Col>
											<FormikField<LettingStatement>
												type="date"
												name="date"
												label="Created on"
												readonly
											/>
										</Col>
										<Col>											
											<Form.Group data-cy="formSelectField" as={Col} style={{ marginBottom: '0'}} className={selectstyle.inputStyle}>
												<Form.Label className='text-muted'>Use this property for all entries</Form.Label>						
												<Select 
												className={classNames(selectstyle.formikSelectStyle)}
												classNamePrefix={'formikSelectStyle'}
												options={propertyDDOptions}
												onChange={(propertyId)=>setPropertyForAll(propertyId ? propertyId.value : 0, values)}
												value={propertyDDOptions?.find(x=>x.value === selectedPropertyId)}
												></Select>
											</Form.Group>											
										</Col>
									</Row>	
								</div>
								</>
								<>
									<div className={classNames(styles.invoiceTable, styles.lettingStatement, 'text-right')} data-cy="lettingStatementLinesTable">
										<table>
											<thead>
												<tr className="text-muted" style={{verticalAlign: 'bottom'}}>
													<th className="text-left" style={{width:'18%'}}>Description</th>
													<th className="text-left">Amount</th>
													{initialValues.showVat &&														
														<th className="text-left">VAT Code</th>
													}
													<th className={classNames("text-left", styles.helpIcon)}>
														Start date 
														<OverlayTrigger placement="top" overlay={getHelpTextDate}>
															<FontAwesomeIcon icon={faCircleQuestion} className="ml-2" />
														</OverlayTrigger>
													</th>
													<th className={classNames("text-left", styles.helpIcon)}>
														End date 
														<OverlayTrigger placement="top" overlay={getHelpTextDate}>
															<FontAwesomeIcon icon={faCircleQuestion} className="ml-2" />
														</OverlayTrigger>
													</th>
													<th className={classNames("text-left", styles.helpIcon)}>
														Is it a replacement 
														<br/>
														item
														<OverlayTrigger placement="top" overlay={getHelpTextReplacement}>
															<FontAwesomeIcon icon={faCircleQuestion} className="ml-2" />
														</OverlayTrigger>
													</th>
													<th className={classNames("text-left", styles.helpIcon)}>
														Does it increase the 
														<br/>
														property value
														<OverlayTrigger placement="top" overlay={getHelpTextProperty}>
															<FontAwesomeIcon icon={faCircleQuestion} className="ml-2" />
														</OverlayTrigger>
													</th>
													<th className={classNames("text-left", styles.helpIcon)}>
														Is it creating a
														<br />new asset
														<OverlayTrigger placement="top" overlay={getHelpTextAsset}>
															<FontAwesomeIcon icon={faCircleQuestion} className="ml-2" />
														</OverlayTrigger>
													</th>
													<th className="text-left">Property</th>
													{initialValues.showVat && <th className="text-right">Total</th>}
													<th></th>
												</tr>
											</thead>
											<FieldArray name="lettingStatementLines">
												{({ remove, push, form}) => (													
													<>
														{modalDialog}
														
														<tbody>
															{values.lettingStatementLines&&values.lettingStatementLines?.length>0?(
																values.lettingStatementLines?.sort((x,y) => ((x.lineNumber || 0) - (y.lineNumber || 0)))
																	.map((item, index) => (
																		<tr key={index + 'lettingstatementline'}>
																			<td>
																				<FormikField<LettingStatementLine>
																					name={`lettingStatementLines[${index}].description` as 'description'}
																					type="text"
																					isTable
																					className="px-0 mb-0"
																				/>
																			</td>																	
																			<td  >
																				<div style={{width:"100%", minWidth:"100px"}}>
																					<FormikField<LettingStatementLine>
																						name={`lettingStatementLines[${index}].amountExVAT` as 'amountExVAT'}
																						prefix={isPositiveLine(item) ? "+" : "-"}
																						prefixWidth={"small"}
																						type="number"
																						className="px-0 mb-0"
																						width="5rem"
																						isTable
																					/>
																				</div>
																				
																			</td>
																			{initialValues?.showVat &&	
																				<td>
																					<FormikField<LettingStatementLine>
																						name={`lettingStatementLines[${index}].vatCode` as 'vatCode'}
																						type="select"
																						className="px-0 mb-0 text-left"
																						isTable
																						option={vatRate?.map((x) => ({
																							value: x.id,
																							label: x.name
																						}))}
																						width='180px'
																					/>
																				</td>
																			}
																			<td>
																				<FormikField<LettingStatementLine>
																				name={`lettingStatementLines[${index}].applicablePeriodStart` as 'applicablePeriodStart'}
																				type="date"
																				className="px-0 mb-0"
																				isTable
																				showErrorLine={errors.lettingStatementLines === undefined ? true : false} 
																				/>
																			</td>
																			<td>
																				<FormikField<LettingStatementLine>
																				name={`lettingStatementLines[${index}].applicablePeriodEnd` as 'applicablePeriodEnd'}
																				type="date"
																				className="px-0 mb-0"
																				isTable
																				/>
																			</td>
																			<td>
																				<FormikField<LettingStatementLine>
																				name={`lettingStatementLines[${index}].isReplacementItem` as 'isReplacementItem'}
																				type="checkbox"
																				className="px-0 mb-0"
																				width="6rem"
																				isTable
																				readonly={values.lettingStatementLines && !accountCanBeReplacement(values.lettingStatementLines[index].accountID)}
																				/>
																			</td>
																			<td>
																				<FormikField<LettingStatementLine>
																				name={`lettingStatementLines[${index}].increasesPropertyValue` as 'increasesPropertyValue'}
																				type="checkbox"
																				className="px-0 mb-0"
																				width="6rem"
																				isTable
																				readonly={values.lettingStatementLines && !accountCanIncreasePropValue(values.lettingStatementLines[index].accountID)}
																				/>
																			</td>
																			<td>
																				<FormikField<LettingStatementLine>
																				name={`lettingStatementLines[${index}].createAsset` as 'createAsset'}
																				type="checkbox"
																				className="px-0 mb-0"
																				width="6rem"
																				isTable
																				readonly={values.lettingStatementLines && !accountEligibleForAsset(values.lettingStatementLines[index].accountID)}
																				/>
																			</td>
																			
																			<td>
																				<FormikField<LettingStatementLine>
																					name={`lettingStatementLines[${index}].propertyId` as 'propertyId'}
																					type="select"
																					className="px-0 mb-0 text-left"
																					isTable
																					option={propertyDDOptions && propertyDDOptions.filter(x => x.value !== 0).length > 0 ? 
																						propertyDDOptions.filter(x => x.value !== 0) : 
																						undefined}
																					width={'120px'}
																					readonly={selectedPropertyId !== 0 && selectedPropertyId !== undefined}
																				/>
																			</td>
																			{initialValues?.showVat &&
																				<td>																		
																					{item &&
																					currencyConvertor((item?.amountExVAT||0)+(item?.amountVAT||0),undefined) as
																					string}																	 
																				</td>
																			}																	
																			<td>																		
																				<TableIcon
																					icon='removeLine'
																					onClick={() => {
																						showModalDialog(
																							'Delete letting statement line?',
																							`Are you sure you want to delete this letting statement line?`,
																							[<Button variant="danger" label="Yes" onClick= {()=> remove(index) } />, <Button variant="secondary" label="No" onClick={() => {}} />],
																							false
																						);
																						
																					}}></TableIcon>
																			</td>
																		</tr>
																	))
																):(
																	<tr>
																		<td
																			colSpan={initialValues.showVat ? 11 : 9}
																			className="text-muted"
																			style={{ textAlign: 'center', padding: '3rem 0' }}
																		>
																			Use the button below to add a line
																		</td>
																	</tr>

															)}

															
															
														</tbody>
														
														{touched && errors.lettingStatementLines && (
															<tbody>
																<tr>
																	<td colSpan={initialValues.showVat ? 11 : 9} className="text-left">
																		<small className="invalid-feedback" style={{ display: 'block' }}>
																			{errors.lettingStatementLines}
																		</small>
																	</td>
																</tr>
															</tbody>
														)}
																										
														<tbody>
															<tr className="bg-white">
																<td colSpan={initialValues.showVat ? 11 : 9} style={{ float: 'left' }}>
																	<AgentStatementsDropDown
																	    lines={values.lettingStatementLines}
																		accounts={accounts}
																		setTableValues={(e, values) => {
																			push(values);
																		}}
																		showVAT={initialValues?.showVat}
																		propertyId={selectedPropertyId !== 0 ? selectedPropertyId : undefined}
																	/>
																</td>
															</tr>
														</tbody>
													</>
												)}
											</FieldArray>
											<tfoot>											
												{initialValues?.showVat ?(
													<>
														<TableTotalField
															colspan={8}															
															text="Sub total"
															value={
																values?.lettingStatementLines?.reduce((sum: number, lsl: LettingStatementLine) => {
																	const amountExVAT = lsl.amountExVAT || 0;
																	const lineExVATAmount = isPositiveLine(lsl) ? amountExVAT  : -(amountExVAT);
																	return sum + lineExVATAmount;
																}, 0) || 0
															}
														/>
														<TableTotalField	
															colspan={8}															
															text="Total VAT"
															value={
																values?.lettingStatementLines?.reduce((sum: number, lsl: LettingStatementLine) => {
																	const amountVAT = (lsl.amountExVAT || 0)*(vatRate?.find(x => x.id === lsl.vatCode)?.rate || 0);
																	const lineVATAmount = isPositiveLine(lsl) ? amountVAT : -(amountVAT);
																	return sum + lineVATAmount;
																}, 0) || 0
															}
														/>
														<TableTotalField
															colspan={8}																
															text="Grand Total"
															value={
																values?.lettingStatementLines?.reduce((sum: number, lsl: LettingStatementLine) => {
																	const amountExVAT = lsl.amountExVAT || 0;
																	const amountVAT = (lsl.amountExVAT || 0)*(vatRate?.find(x => x.id === lsl.vatCode)?.rate || 0);
																	const lineTotalAmount = isPositiveLine(lsl) ? amountExVAT + amountVAT : -(amountExVAT + amountVAT);
																	return sum + lineTotalAmount;
																}, 0) || 0
															}
															bold
														/>	
													</>
												):(
													<TableTotalField
															colspan={7}
															text="Grand Total"
															value={
																values?.lettingStatementLines?.reduce((sum: number, lsl: LettingStatementLine) => {
																	const amountExVAT = lsl.amountExVAT || 0;
																	const lineAmount = isPositiveLine(lsl) ? amountExVAT  : -(amountExVAT);
																	return sum + lineAmount;
																}, 0) || 0
															}
															bold
														/>	
												)}																						
											</tfoot>
										</table>
									</div>
									{showAddNewAgentModal && (
										<AddNewAgentOptionModal 
											hide={() => setShowAddNewAgentModal(false)} 
											create={(pm)=> addNewAgentOption(pm, ({id}) => {
												setShowAddNewAgentModal(false)
												setFieldValue('lettingAgentID', id)
												setAgentDetailsLoaded(false)
											})}
										/>
									)}
								</>
							</Form>
												
						);
					}}
				</Formik>
				
			) : (
				<CreateEditAgentStatementTablePlaceholder/>
			)}
		</>
	);
}

const AgentStatementsDropDown = 
	({ setTableValues, accounts , lines,showVAT,propertyId}: { setTableValues: (e: string|undefined, values: LettingStatementLine) => void; accounts: Account[] ,lines:LettingStatementLine[] | undefined, showVAT:boolean,propertyId:number|undefined }) => {
	const selectedDropDown = (eventKey: string | null) => {
		eventKey !== null &&
			setTableValues(eventKey, {
				description: accounts.find((account) => account.id === parseInt(eventKey))?.name,
				amountExVAT: 0,
				vatCode: showVAT ? (accounts.find((account) => account.id === parseInt(eventKey))?.defaultVatCode || 'S'):'E',
				accountID: parseInt(eventKey),
				date: asYearMonthDay(new Date()),
				isReplacementItem: false,
				increasesPropertyValue: false,
				id: 0,
				lineNumber: lines?.length || 0,
				propertyId:propertyId,
				createAsset:false
			});
	};
	return (
		<DropdownButton
			id="dropdown-button-drop-right"
			title={
				<>
					<FontAwesomeIcon icon={faPlus} />
					&nbsp; Add line
				</>
			}
			className={dropdownStyles.invoiceDropdownParentButton}
			onSelect={(e) => selectedDropDown(e)}
			variant="secondary"
			size="sm"
			drop="right"
		>
			<>
				{accounts &&
					getUniqueValues<Account>(accounts, 'accountGroup')
						.reverse()
						.map((item: any) => {
							return (
								<React.Fragment key={item}>
									{accounts?.filter((x) => x.accountGroup === item).length > 0 && (
										<>
											<Dropdown.ItemText className="mt-3">
												<b>{item}</b>
											</Dropdown.ItemText>
											<Dropdown.Divider />
											{item==='Expenses' &&
												accounts
													?.filter((x) => x.name === "Management fees"|| x.name === "Maintenance & building services")
													.map((item) => (
														<Dropdown.Item key={item.id} eventKey={item.id}>
															<FontAwesomeIcon className={styles.searchStar} icon={faStar} /> 
															{item.name}
														</Dropdown.Item>
												))
											}

											{ item === 'Expenses' && <Dropdown.Divider /> }

											{accounts
												?.filter((x) => x.accountGroup === item)
												.map((item) => (
													<Dropdown.Item key={item.id} eventKey={item.id}>
														{item.name}
													</Dropdown.Item>
												))}
										</>
									)}
								</React.Fragment>
							);
						})}
			</>
		</DropdownButton>
	);
};

export const LettingStatementLayoutWrapper = ({ children }: LettingStatementLayoutProps) => {
	const companyContext = useContext(CompanyContext);
	const v75Styling = companyContext.company?.useV8UI;
	return (
		<Card
			className={v75Styling ? styles.lettingStatementLayoutV75 : styles.lettingStatementLayout}
			data-cy="lettingStatementLayout"
		>
			{children}
		</Card>
	);
};

export const CreateEditAgentStatementTablePlaceholder = () => {
	return (
		<div className="d-flex justify-content-around w-90 mb-2 ml-1">
			<div style={{width: '95%' }}>
				<Row className="mb-2" xl={6} md={3} sm={3} lg={4} xs={1}>
				{Array(5).fill(0).map((_, index) => ( 			
					<div key={index} className="ml-3 mt-4 mr-4">
						<LoadingPlaceholder width="120px" height="20px" float="left"/>
						<LoadingText ></LoadingText>
					</div>
					))} 				
				</Row>
				
				<div className=" mt-5">
					<LoadingPlaceholder height="40px" />
					<hr></hr>
					<LoadingPlaceholder height="30px" />
					<hr></hr>
					<LoadingPlaceholder height="30px" />
				</div>
				<hr></hr>
				<div className="w-100">
					<LoadingPlaceholder width="30%" height="40px" float="right" />
				</div>					
			</div>
		</div>
	)
}


export default ReadAgentStatementWrapper;