import styles from './AccountViewer.module.css'

// all we really need is the summary at the moment - maybe this could be a type on BE e.g. AsSuggestion which we extend to add additional autofill values
export type Suggestion = {
    summary?: string
}

// We set the values using the index of the suggestion in the list...
const SuggestionContainer = ({suggestions, setVals} : {suggestions : Suggestion[], setVals : (suggestionIndex : number) => void}) => (
        <div className={styles.suggestionContainer}>
            {suggestions?.map((x,i) => {
                return (<div onClick={(e) => setVals(i)} key={i}>{x.summary}</div>)
            })}
        </div>
)

export default SuggestionContainer