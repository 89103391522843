import { SbBlokData, storyblokEditable } from "@storyblok/react";
import { useContext, useState } from "react";
import { Form } from "react-bootstrap";
import { InitialiseDateModel } from "../../../api/inni/data-contracts";
import OnboardingContext from "../../../context/OnboardingContext";
import { checkUTR } from "../../../utils/checkUTR";
import styles from './inputtext.module.css';


type Props = {
	blok: SbBlokData;
  initialData: InitialiseDateModel;
};

const InputText = ({ blok, initialData }: Props) => {
	const {onChangeUtr, utr, errorMessage: error, notifyAccountantsMessage, onChangeNotifyAccountantMessage} = useContext(OnboardingContext);
  const [touched, setTouched] = useState(false);
  const inputHandlers = {
    companyUtr: {
      value: utr,
      changeValue: onChangeUtr,
      disabled: initialData?.submittedUTR ? true : false,
      isInvalid: touched && !checkUTR(utr),
      errorMessage: 'Please enter the correct UTR number!'
    },
    accountantInfoFreeType: {
      value: notifyAccountantsMessage,
      changeValue: onChangeNotifyAccountantMessage,
      disabled: false,
      isInvalid: false,
      errorMessage: ''
    },
    defaultInputHandler: {value: '', changeValue: null, disabled: false, isInvalid: false, errorMessage: ''},
  }
  const {value, changeValue, disabled, isInvalid, errorMessage} = inputHandlers[blok.system_key as keyof typeof inputHandlers] || inputHandlers.defaultInputHandler
  return (
        <div {...storyblokEditable(blok)} className={styles.inputText}>
            <Form.Label>{blok.label}</Form.Label>
            {blok.multiline ?
                <Form.Control value={value}  onChange={changeValue ? (e) => changeValue(e.target.value) : (() => {})} as={'textarea'} rows={5} />
              :
                <>
                  <Form.Control type="text" className={isInvalid || error ? 'is-invalid' : ''} disabled={disabled} value={value} onBlur={() => setTouched(true)} onChange={changeValue ? (e) => changeValue(e.target.value) : (() => {})}/>
                  <div className="invalid-feedback">{error || errorMessage}</div>
                </>
            }
            <Form.Text className="text-muted">
              {blok.help}
            </Form.Text>
        </div>
  );
};
 
export default InputText;