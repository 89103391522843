import { Button } from '../../../elements/Button/Button'
import { SimpleTerm, useSimpleTerms } from '../../../hooks/terms/useSimpleTerms'
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity'
import { Entity } from '../../../utils/EntityAction'
import styles from '../SetupWizard.module.css'
import { WarningText } from './WarningText'

const Welcome = ({setNextPage} : {setNextPage : () => void}) => {

    const navigateToEntity = useNavigateToEntity()
    const simpleTerms = useSimpleTerms()

    return (
    <div>
        <h1>Welcome to the setup wizard</h1>
        <hr />
        <div>
            <p>In order to set up your company with {simpleTerms(SimpleTerm.AppName)}, you need to complete a short series of steps in our setup wizard. You may find it helpful to gather the following information before starting the wizard:</p>
            <ul>
                <li>Your company's Certificate of Incorporation</li>
                <li>Details of your company's share allotment</li>
                <li>Your yellow PAYE payslip book</li>
                <li>Your, and your employees, last payslips</li>
                <li>Your company's bank statements</li>
            </ul>
            {/* Removed for now since the old link doesn't exist anymore */}
            {/* <p>For guidance on where to find this information please read this page in our help centre. (NEED LINK)</p> */}

            <hr />
        </div>

        <div>
            <p>If you have all or some of this information you can continue with the setup wizard, otherwise exit and you will be prompted to run the wizard next time you sign in.</p>
        </div>

        <div className={styles.formButtons}>
            <Button buttonType="cancel" onClick={() => navigateToEntity(Entity.CompanyDashboard)}>Exit</Button>
            <Button variant="change" onClick={setNextPage}>Continue</Button>
        </div>
        <WarningText />
    </div>)
}

export default Welcome