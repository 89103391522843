import { Card } from 'react-bootstrap';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import styles from '../TenancyNote.module.css';


export const TenancyNoteEntryPlaceholder = () => {
  return (
    <Card className="mb-3 shadow-sm">
      <Card.Header className={`${styles.alignItemsBottom} flex-row justify-content-between`}>
        <LoadingPlaceholder width='300px' />
      </Card.Header>

      <Card.Body>
        <Card.Text>
          <LoadingPlaceholder width="70%" />
          <br />
          <LoadingPlaceholder width="40%" />
          <br />
          <LoadingPlaceholder width="40%" />
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
