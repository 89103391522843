import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CompanyContext from "../../context/CompanyContext";
import { Table } from "../../elements/DataGrid/DataGrid";
import { useInniAPI } from "../../hooks/useInniAPI";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout";
import {Action, Entity} from "../../utils/EntityAction";
import styles from './completionStatement.module.css'
import { Properties } from "../../api/inni/Properties";
import { CSLineTypeOption, CompletionStatement, Property } from "../../api/inni/data-contracts";
import { formatCurrency } from "../../utils/formatNumbers";
import { CompletionStatements } from "../../api/inni/CompletionStatements";
import { LoadingPlaceholder } from "../../elements/LoadingPlaceholder/LoadingPlaceholder";
import { TextValue, Text, Date, YesNo } from '../../elements/DetailRow/DetailRow'
import { Button } from "../../elements/Button/Button";
import { Modal } from "../../components/Modal/Modal";
import { breakStrByCase } from "../../utils/formatters/formatStrings";
import Toolbar from "../../layouts/Desktop/Toolbar";
import { placeholderImage } from "../../utils/formatters/formatProperty";
import { ExpandedViewBackLabel } from "../../elements/ExpandedViewBackLabel/ExpandedViewBackLabel";
import { useHistoryWrapper } from "../../hooks/useHistoryWrapper";

interface RouteParams {
    id: string
}

const ViewCompletionStatement = () => {
    const companyContext = useContext(CompanyContext)
    const propertyAPI = useInniAPI(Properties)
    const completionStatementApi = useInniAPI(CompletionStatements, [400])
    const id = parseInt(useParams<RouteParams>().id);

    const [property, setProperty] = useState<Property>()
    const [completionStatement, setCompletionStatement] = useState<CompletionStatement>()
    const [showFileModal, setShowFileModal] = useState<boolean>(false)

    const [lineTypes, setLineTypes] = useState<CSLineTypeOption[]>();

    const history = useHistoryWrapper();

    useEffect(() => {
        if(propertyAPI && completionStatement) {
            propertyAPI?.get(companyContext.cid, completionStatement.propertyId).then((res) => {
                setProperty(res.data)
            })
        }
    }, [propertyAPI, companyContext.cid, completionStatement])

    useEffect(() => {
        if(completionStatementApi && id) {
            completionStatementApi.getOne(companyContext.cid, id).then((res) => {
                setCompletionStatement(res.data)
            })
        }
    }, [companyContext.cid, completionStatementApi, id])

    useEffect(() => {
		if(completionStatementApi && lineTypes === undefined) {
			completionStatementApi.getLineTypeOptions(companyContext.cid).then(res => {
				setLineTypes(res.data);
			})
		}
	}, [completionStatementApi, companyContext.cid, lineTypes]);

    const getLineTypeName = (id: number) => {
        if(lineTypes) {
            const lineType = lineTypes.find(x => x.id === id)
            if(lineType) return lineType.name || ""
            return ""
        }
        return ""
    }

    return (
        <DefaultLayout 
            entity={Entity.CompletionStatement} 
            greyBackground 
        >
            <ExpandedViewBackLabel
                backLabel="Purchase and sale"
                title='Completion statement'
                onClick={history.goBack}
            >
                <Toolbar>
                    <Button buttonType="download" onClick={() => setShowFileModal(true)}>View statement</Button>

                    { completionStatement?.fileDownloadUrl && (
                        <a href={completionStatement.fileDownloadUrl}><Button buttonType="download" label="Download statement" /></a>
                    )}

                    { completionStatement && completionStatement.statementType === "PropertyPurchase" &&
                        property && property.canReplacePurchaseStatement && (
                        <Button
                            action={Action.Edit}
                            variant="change"
                            entity={Entity.CompletionStatement}
                            extraParams={{
                                propertyId: property.id,
                                statementType: "PropertyPurchase",
                                completionStatementId: id
                            }}
                        >
                            Replace
                        </Button>
                    )}

                    {completionStatement && completionStatement.statementType === "PropertySale" &&
                        property && property.canReplaceSaleStatement && (
                        <Button
                            variant="change"
                            entity={Entity.CompletionStatement}
                            extraParams={{propertyId: property.id, statementType: "PropertySale"}}
                        >
                            Replace
                        </Button>
                    )}
                </Toolbar>

            </ExpandedViewBackLabel>

            {
                property && completionStatement && completionStatement.completionStatementLines ? <>
                    <div className="my-5">
                        <TextValue fixedWidth name='propertyName' label="Property" value={property.name} />
                        <Date fixedWidth entity={completionStatement} name='completionDate' />
                        <Text fixedWidth entity={completionStatement} name='statementType' valueFormatter={(val) => breakStrByCase(val||"")} />
                        <YesNo fixedWidth entity={completionStatement} name='transactionWasSuccessful'/>
                    </div> 
                
                    <h4 className="mb-3">Credits</h4>
                    <Table noHover>
                        <thead>
                            <tr>
                                <th style={{width: '220px'}}>Type</th>
                                <th>Description</th>
                                <th className={styles.amountReadCell}>Amount</th>
                            </tr>
                        </thead>
                        <tbody className={styles.csLineTableBody}>
                            {completionStatement.completionStatementLines.filter(x => !x.isWithdrawal).map((line, index) => (
                                <tr key={index}>
                                    <td style={{width: '220px'}}>{getLineTypeName(line.lineTypeId)}</td>
                                    <td>{line.description}</td>
                                    <td className={styles.amountReadCell}>{formatCurrency(line.amount)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <h4 className="mt-5 mb-3">Debits</h4>
                    <Table noHover>
                        <thead>
                            <tr>
                                <th style={{width: '220px'}}>Type</th>
                                <th>Description</th>
                                <th className={styles.amountReadCell}>Amount</th>
                            </tr>
                        </thead>
                        <tbody className={styles.csLineTableBody}>
                            {completionStatement.completionStatementLines.filter(x => x.isWithdrawal).map((line, index) => (
                                <tr key={index}>
                                    <td style={{width: '220px'}}>{getLineTypeName(line.lineTypeId)}</td>
                                    <td>{line.description}</td>
                                    <td className={styles.amountReadCell}>{formatCurrency(line.amount)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    {completionStatement.fileUrl && <Modal
                        title="View statement"
                        showModal={showFileModal}
                        hideModal={() => setShowFileModal(false)}
                        showCloseBtn>

                        {/.pdf|.xls|.csv/.test(completionStatement.fileUrl) && 
                        <div className={styles.noPreview}>
                            <img src={placeholderImage} alt="Placeholder" />
                            <>Sorry, there is currently no preview available, please download the file instead</>
                        </div>}

                        {!/.pdf|.xls|.csv/.test(completionStatement.fileUrl) && 
                            <img style={{maxWidth: '100%'}} src={completionStatement.fileUrl} alt="Statement"/>}
                    </Modal>}
                </> :
                <>
                    <LoadingPlaceholder height="150px"/>
                    <LoadingPlaceholder height="75px"/>
                    <LoadingPlaceholder height="75px"/>
                </>
            }
        </DefaultLayout>
    )
}

export default ViewCompletionStatement