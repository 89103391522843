import React from 'react';
import styles from './MobileBottomBar.module.css';

const MobileBottomBar = ({children}: {children: React.ReactNode}) => {
  return (
    <div id={styles.mobileBottomBar}>
      { children }
    </div>
  );
}

export default MobileBottomBar;
