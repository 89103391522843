import React from 'react'
import PropTypes from 'prop-types';

/*
    Intentioanally NOT as typescript file - can't get default props working

*/

// __TYPE from https://mparavano.medium.com/find-filter-react-children-by-type-d9799fb78292 

 

const Toolbar = ({children, __TYPE}) => {
    return (
        <div className='toolbar'>
            {children}
        </div>
    )
}


Toolbar.propTypes = {
    children: PropTypes.node,
    __TYPE: PropTypes.string
};

Toolbar.defaultProps = {
    __TYPE: 'DefaultToolbar',
};

export default Toolbar;