import _ from "lodash";
import React from "react";
import { useEffect, useState, useContext } from "react"
import { Form } from "react-bootstrap";
import { Contracts } from "../../../api/inni/Contracts";
import { Contract, Property } from "../../../api/inni/data-contracts";
import CompanyContext from "../../../context/CompanyContext";
import { LoadingPlaceholderContractSelector } from "../../../elements/LoadingPlaceholder/LoadingPlaceholder";
import { useInniAPI } from "../../../hooks/useInniAPI";
import formStyles from "../../../elements/FormikField/FormikField.module.css"
import ReactSelect, { GroupTypeBase, MenuProps, components } from "react-select";
import { Button } from "../../../elements/Button/Button";
import { useModalDialog } from "../../../hooks/useModalDialog";
import { useHistoryWrapper } from "../../../hooks/useHistoryWrapper";
import BrandContext from "../../../context/BrandContext";
import { Properties } from "../../../api/inni/Properties";
import provestorBrand from "../../../components/BrandWrapper/pvBrand";

export interface ContractSelectorProps {
    contractCallback : (a : number) => void,
    allowAll? : boolean,
    loadedCb  : (i : boolean) => void,
    fltLoaded : boolean
    filter? : (i : Contract[]) => Contract[]
    dateRange?: Date[],
    showContractDateFilter?: boolean,
    addContract?: boolean
}

type CustomMenuProps = React.ComponentType<MenuProps<any, false, GroupTypeBase<any>>>;

const ContractSelector = ({contractCallback, allowAll = true, loadedCb, fltLoaded, filter = (i) => i, dateRange, showContractDateFilter,addContract=false} : ContractSelectorProps) => {

    const companyContext = useContext(CompanyContext);
    const brandContext = useContext(BrandContext);
    const ContractAPI = useInniAPI(Contracts);
    const propertiesApi = useInniAPI(Properties);
	const history = useHistoryWrapper();
    const [contracts, setContracts] = useState<Contract[] | undefined>(undefined);
    const [properties, setProperties] = useState<Property[]>()
    const [contractsNoDateFilter, setContractsNoDateFilter] = useState<Contract[] | undefined>(undefined);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [showModalDialog, modalDialog] = useModalDialog();

    const [filterByDates, setFilterByDates] = useState(showContractDateFilter === true)
    const [previousFilterByDatesState, setPreviousFilterByDatesState] = useState(false); //Avoid unnecessary useEffects firing
    const [previousDateRange, setPreviousDateRange] = useState<Date[]>()

    const [selectedContract, setSelectedContract] = useState<number>(-1);
    const [selectOptions, setSelectOptions] = useState<{value:number,label:string}[]>()

    useEffect(() => {
        // API Call to Contracts API
        if (!isLoaded && ContractAPI && !isLoading) {
            setIsLoading(true)
            ContractAPI.index(companyContext.cid)
                .then(response => {
                    const filteredContracts = filter(response.data)
                    let filteredContractsByDate = filteredContracts
                    if(dateRange && filterByDates) {
                        filteredContractsByDate = filteredContracts.filter(x => (x.endDate ? new Date(x.endDate) >= dateRange[1] : true))
                        setContracts(filteredContractsByDate)
                    } else {
                        setContracts(filteredContracts)
                    }

                    setContractsNoDateFilter(filteredContracts)
                    setIsLoaded(true)
                    if(dateRange && filterByDates && filteredContractsByDate[0] && !allowAll) {
                        setSelectedContract(filteredContractsByDate[0].id)
                        contractCallback(filteredContractsByDate[0].id)
                    }
                    else if (filteredContracts[0] && !allowAll) {
                        setSelectedContract(filteredContracts[0].id)
                        contractCallback(filteredContracts[0].id)
                    }
                }).finally(() => {
                    setIsLoading(false)
                })
        }
    }, [ContractAPI, allowAll, companyContext.cid, isLoaded, contractCallback, filter, dateRange, filterByDates, isLoading])

    useEffect(() => {
        if(ContractAPI && filterByDates && contractsNoDateFilter && !previousFilterByDatesState && dateRange) {
            setPreviousFilterByDatesState(true)
            const filteredContracts = contractsNoDateFilter.filter(x => (x.endDate ? new Date(x.endDate) >= dateRange[1] : true))
            setContracts(filteredContracts)

            if(selectedContract !== -1) {
                const currentContract = filteredContracts.find(x => x.id === selectedContract)
                if(currentContract === undefined) {
                    setSelectedContract(filteredContracts[0].id);
                    contractCallback(filteredContracts[0].id);
                }
            }
        } 
        else if(!filterByDates && previousFilterByDatesState) {
            setContracts(contractsNoDateFilter)
            setPreviousFilterByDatesState(false)
        }
    }, [ContractAPI, filterByDates, contracts, previousFilterByDatesState, 
        contractsNoDateFilter, dateRange, selectedContract, contractCallback])

    useEffect(() => {
        if(filterByDates && contractsNoDateFilter && dateRange && !_.isEqual(dateRange, previousDateRange)) {
            const filteredContracts = contractsNoDateFilter.filter(x => (x.endDate ? new Date(x.endDate) >= dateRange[1] : true))
            setPreviousDateRange(dateRange)
            setContracts(filteredContracts)

            if(selectedContract !== -1) {
                const currentContract = filteredContracts.find(x => x.id === selectedContract)
                if(currentContract === undefined) {
                    setSelectedContract(filteredContracts[0].id);
                    contractCallback(filteredContracts[0].id);
                }
            }
        }
    }, [contractsNoDateFilter, dateRange, previousDateRange, filterByDates, selectedContract, contractCallback])

    useEffect(() => {
        loadedCb(isLoaded)
    }, [isLoaded, loadedCb])

    useEffect(() => {
        if (brandContext.brand === provestorBrand) {
            if (propertiesApi && !properties) {
                propertiesApi.index(companyContext.cid)
                .then(res => {
                    setProperties(res.data)
                })
            }
        } 
    }, [brandContext.brand, companyContext.cid, propertiesApi])

    useEffect(() => {
        if(contracts) {
            const options = contracts.map(x => {
                if(properties){
                    return {
                        value: x.id, 
                        label: (x.contractSubType === "Tenancy" ? 
                                    properties.find(y => y.id === x.propertyId)?.name +( x.name?.trim() ? ( " -> "  + x.name): ( " -> Agent managed ("  + x.id + ")"))
                                    : x.name?.trim()
                                )|| ''
                    } 
                }
                else{
                    return {value: x.id, label: x.name || ''}
                }
            })
            allowAll && options.unshift({value: -1, label: brandContext.brand === provestorBrand ? 'All properties' : 'All contracts'})

            setSelectOptions(options)
        }
    }, [allowAll, contracts, properties])

    const handleSelectionChange = (e:number) => {
        setSelectedContract(Number(e));
        contractCallback(Number(e));
    }
    
	const showRedirectContractCreatePage = () => {
		showModalDialog(
			"Create new contract?",
			`This will take you to the 'create contract' page, go there now?`,
			[
				//Company/2465/Contracts
				<Button variant="danger" label="Yes" onClick={() => history.push(`/Company/${companyContext.cid}/Contracts`)} />,
				<Button variant="secondary" label="No" onClick={() => {}} />,
			],
			false
		);
	};
	const CustomMenu: CustomMenuProps = ({ children, ...props }) => (
		<components.Menu {...props}>
			<>
				{children}
				<div className="p-2">  
					<Button
						label="Create new contract"
						buttonType="new"
						variant="primary"
						className="btn-block"
						onClick={showRedirectContractCreatePage}
					/>
				</div>
			</>
		</components.Menu>
	);

    return (
        <Form style={{marginRight: '7px'}} onSubmit={(e)=> e.preventDefault()}>
            { (!isLoaded || !fltLoaded) &&
                <LoadingPlaceholderContractSelector />
            }
            { isLoaded && fltLoaded &&
                <ReactSelect
                    className={formStyles.formikSelectStyle}
                    classNamePrefix={'formikSelectStyle'}
                    options={selectOptions}
                    value={selectOptions?.find(x => x.value === selectedContract)} 
                    onChange={(e) => {
                        handleSelectionChange(e?.value || -1)
                    }}
					components={addContract ? { Menu: CustomMenu } : undefined}></ReactSelect>
            }
            {showContractDateFilter && dateRange && <span style={{display: 'flex'}} className="mt-2">
                <input style={{marginRight: '0.25rem'}} type='checkbox' checked={filterByDates} onChange={() => setFilterByDates(!filterByDates)}/>
                <span>Only show active contracts in the date range</span>
            </span>}
			{modalDialog}
        </Form>
    )

}

export default ContractSelector