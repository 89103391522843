import React, {useEffect, useState} from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingFullPage } from '../../elements/LoadingFullPage/LoadingFullPage';
import {msalConfig, msalInstance} from './msal'

export const LoginCallback = () => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [error, setError] = useState(false);

    useEffect(() => {
        msalInstance.handleRedirectPromise().then((tokenResponse) => {
            if (tokenResponse !== null) {
                setLoggedIn(true);
            } else {
                setError(true);
            }
        }).catch((error) => {
            // Forgot password flow
            if (error.errorMessage && error.errorMessage.indexOf("AADB2C90118") > -1) {
                try {
                  // Password reset policy/authority
                  msalInstance.loginRedirect({
                      authority: msalConfig.passwordResetAuthority, 
                        scopes: msalConfig.scopes});
                } catch(err) {
                    setError(true)
                    console.error(err);
                }
              } else {
                setError(true)
              }
        });    
    }, [])


    const redirectUrl = () => {
        return localStorage.getItem('loginRedirectPath') || '/'        
    }

    return (
        <>
            <LoadingFullPage entityName='user account' errorMessage={error ? 'Sorry, there was a problem logging you in. Please press back and try again.' : undefined} />
            {loggedIn && 
                <Redirect to={redirectUrl()} />
            }
        </>
    )

}