const TimeWeekRow = ({curVals, setCurVals, taskId, unit} : {curVals : {value: number, readOnly?: boolean}[], setCurVals : (curVals : {value: number, readOnly?: boolean}[], taskId : number) => void, taskId : number, unit : string}) => {
    const getTotal = () : number => {
        let total = 0;
        curVals.map(i => total += i.value)
        return total
    }

    const handleChange = (index : number, value : number) => {
        let tempTimes = [...curVals];
        if (value >= 0)
            tempTimes[index].value = value;
        else 
            tempTimes[index].value = 0;
        setCurVals(tempTimes, taskId)
    }

    return (
        <>
            {[...Array(7)].map((x,i) => {
                return (
                    <td key={taskId + 'row' + i} style={{justifyContent: 'center'}}>
                        <input type="number" step={unit === 'H' ? '0.01' : '0.1'} disabled={(curVals[i] && curVals[i].readOnly) || false} value={(curVals[i] && curVals[i].value) || 0} onChange={(e) => handleChange(i, Number(e.target.value))} style={{width: '100%', textAlign: 'right'}} />
                    </td>
                )
            })}
            <td data-cy="taskTimeWeekTotal" style={{verticalAlign: 'middle', textAlign: 'right'}}>{getTotal().toFixed(2)}{unit}</td>
        </>
    )

}

export default TimeWeekRow