import styles from './SimpleFormikFields.module.css';
import React from 'react';
import { Form } from 'react-bootstrap';
import { Field, FieldProps } from 'formik';
import _ from 'lodash';
import { asHtml5Date } from '../../utils/formatDate';

export interface SimpleFormikFieldProps<T> {
    name: Extract<keyof T, string>,
    type?: 'text' | 'number' | 'date' | 'checkbox',
    readOnly?: boolean,
    size?: 'sm' | 'auto',
    min?: number,
    max?: number,
}

const SimpleFormikField = <T, >({name, type, size="auto", min, max, readOnly} : SimpleFormikFieldProps<T>) => {

    const formatValue = (value:any) => {
        if (type === 'date') return asHtml5Date(value)
        return value || ''
    }

    if(type === 'checkbox')
        return (<Field disabled={readOnly} name={name} type='checkbox' className={styles.simpleFormikField}/>)

    return (
    <Field name={name}>
        {({field, form, meta}: FieldProps) => (
            // width currently mvp, set to sensible values for e.g. md, lg when required - DD
            <div style={{width: (size === 'auto') ? '100%' : '100px' }}>
                <Form.Control
                    readOnly={readOnly}
                    type={type ? type : 'text'}
                    {...field}
                    value={formatValue(field.value)}
                    isInvalid={meta.touched && meta.error !== undefined}
                    className={styles.simpleFormikField}
                    min={min}
                    max={max}
                />
                { meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}                    
            </div>
        )}
    </Field>)
}

export { SimpleFormikField }