import { faComment } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import styles from './Feedback.module.scss'

const Feedback = () => {
    const renderFeedbackTooltip = (props:any) => (
        <Tooltip id="button-tooltip" {...props}>
          Leave feedback
        </Tooltip>
    );

    return (<>
        <OverlayTrigger
            placement="left"
            overlay={renderFeedbackTooltip}
        >
            <div data-az-l="dd2eeca4-3d59-42c4-85de-3131b3c60ef7" id={styles.showBtn} className="shadow">
                <FontAwesomeIcon icon={faComment} />
            </div>
        </OverlayTrigger>
        </>
    )

}

export default Feedback