import { Card, Image  } from "react-bootstrap"
import styles from "../ConnectToBank.module.css"

interface bankCardProps{
    name:string;
    isBeta:Boolean;
    image:string;
    altText?:string
    onClick:()=>void;
    isSelected:boolean;
}

const BankCard=({name,isBeta,image,altText,onClick,isSelected}:bankCardProps)=>{
    return(
        <Card className={isSelected? `${styles.banklayout} ${styles.selected}` : styles.banklayout} onClick={onClick} >
            <Card.Body className={styles.bodyWrapper}>

                <div className={styles.imageWrapper}>
                {image ?
                    <Image width="50" height="50" src={image} alt={altText} />
                    :<b className="text-muted" style={{fontSize:'28px'}}>{name[0].toUpperCase()}</b>
                }
                </div>
                
                <p className={styles.SectionTitle}>{name}</p> 

                {isBeta &&
                    <small className={styles.betaText}>Beta</small>
                }
            </Card.Body>

        </Card>
    );
}

export default BankCard