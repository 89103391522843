import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useInniAPI } from "../../hooks/useInniAPI";
import { OpenBankingConsents } from "../../api/inni/OpenBankingConsents";
import { OBAuthExchangeRequest } from "../../api/inni/data-contracts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import styles from "./openBanking.module.css"
import { useHistoryWrapper } from "../../hooks/useHistoryWrapper";


export default function CallbackRedirect() {
  const openBankingConsentssAPI = useInniAPI(OpenBankingConsents);
  const location = useLocation();  
  const history = useHistoryWrapper();

  useEffect(() => {

    const queryParams = new URLSearchParams(location.search);
    
    if (openBankingConsentssAPI && queryParams) {

      const callbackRequest: OBAuthExchangeRequest = {
          applicationUserId: queryParams.get("application-user-id") || "",
          userUuid: queryParams.get("user-uuid") || "",
          institution: queryParams.get("institution") || "",
          errorSource: queryParams.get("error-source") || "",
          errorDescription: queryParams.get("error-description") || "",
          error: queryParams.get("error") || "",
          oneTimeToken: queryParams.get("one-time-token") || "",
          companyId: parseInt(queryParams.get("coid") || "0"),
          contactId: parseInt(queryParams.get("ctid") || "0"),
          accountId: parseInt(queryParams.get("acctid") || "0"),
          dataProcessorTypeId: (queryParams.get("dptid") || "0") as OBAuthExchangeRequest['dataProcessorTypeId'],
          isSaas: (queryParams.get("issaas")?.toLocaleLowerCase()=== "true" ? true : false),
          isReauth: (queryParams.get("isreauth")?.toLocaleLowerCase()=== "true" ? true : false),
          isOlbPresetup: (queryParams.get("isolbpresetup")?.toLocaleLowerCase()=== "true" ? true : false),
          openBankingConsentId: parseInt(queryParams.get("obcid") || "0"),
          oldOpenBankingConsentId: parseInt(queryParams.get("oldobcid") || "0")
        };
      
      if(queryParams.get("error") !== null && queryParams.get("one-time-token") === null ){
        history.push(`/company/${queryParams.get("coid")}/import/failed?iscancelled=true&isreauth=${queryParams.get("isreauth")?.toLocaleLowerCase()}`)
      }
      else{
        openBankingConsentssAPI
        ?.exchangeOneTimeToken(parseInt(queryParams.get("coid")||"0"), callbackRequest)
        .then((res) => {
            if(res.data.isAuthFlowFail || res.data.isExchangeFail || res.data.authorisationStatus!=="AUTHORIZED")
              history.push(`/company/${queryParams.get("coid")}/import/failed?iscancelled=false&isreauth=${queryParams.get("isreauth")?.toLocaleLowerCase()}&isottfailed=true`)
            
            if(queryParams.get("acctid") !== null && queryParams.get("acctid") !== "0")
              history.push(`/company/${queryParams.get("coid")}/reconnect/${res.data.openBankingConsentId}/${queryParams.get("acctid")}`)
            else
              history.push(`/company/${queryParams.get("coid")}/importbanks/${res.data.openBankingConsentId}${queryParams.get("isreauth")?.toLocaleLowerCase()=== "true" ? "/isreauth":""}`)
        })
        .catch((err) => {
          console.error(err);
          history.push(`/company/${queryParams.get("coid")}/import/failed?iscancelled=false&isreauth=${queryParams.get("isreauth")?.toLocaleLowerCase()}&isottfailed=true`)
        });
      }
      
    }
  }, [openBankingConsentssAPI]);

  return (
        <div className={styles.callback}>   
                <FontAwesomeIcon size="2x" icon={faSpinnerThird} spin    />
                <h4>One moment, returning back to app...</h4>
        </div>

  );
  
  
}
