import { Card } from 'react-bootstrap';
import { Button } from '../../../elements/Button/Button';
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity';
import { Action, Entity } from '../../../utils/EntityAction';
import styles from './importbank.module.scss'
import { DefaultLayout } from '../../../layouts/Desktop/DefaultLayout';
import { useLocation } from 'react-router-dom';
import { useHasPermission } from '../../../hooks/useHasPermission';


export default function ImportFailed (){
    const location = useLocation(); 
    const queryParams = new URLSearchParams(location.search);
    const navigateToEntity = useNavigateToEntity();
    const isReauth = queryParams.get("isreauth") === "true" ? true : false;
    const isCancelled = queryParams.get("iscancelled") === "true" ? true : false;
    const isOneTimeTokenFailed = queryParams.get("isottfailed") === "true" ? true : false;
    const isReconnectionFailed = queryParams.get("isreconnectionfailed") === "true" ? true : false;
    const hasPermission = useHasPermission()
    const useV8 = hasPermission(Entity.BookkeepingV8, Action.Create)[0]
    
    return(
        <DefaultLayout
            title="Authorisation failed" 
            entity={Entity.OpenBankingAccounts}
        >
            <Card>
                <Card.Header className={styles.failedHeader}>
                    <p className='mb-0'> Authorisation failed during consent flow.</p>
                </Card.Header>
                <Card.Body>
                    <div>
                        <div>
                            <p>This could be because the process was cancelled whilst going through your bank's screens or an error occurred at your bank.</p>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div style={{float: "right"}}>
                        {/* <Button variant='primary' label='Go back to dashboard'/>  */}
                        {useV8 ?
                        <Button variant='primary' onClick={() => navigateToEntity(Entity.BookkeepingV8)} label='Go to accounts' marginTop />
                        :
                        <Button variant='primary' onClick={() => navigateToEntity(Entity.CompanyDashboard)} label='Go to dashboard' marginTop />
                        }
                    </div>
                </Card.Footer>
            </Card>
        </DefaultLayout>
       
    )

}