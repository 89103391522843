import { useContext } from 'react';
import { DatagridTerm } from '../../api/inni/data-contracts';
import CompanyContext from '../../context/CompanyContext';
import _ from 'lodash';

// add at bottom (don't move around) as the numbers used in cy test :) - JW
export enum DatagridType {
    Tenancy,
    LettableUnit,
    Transactions,
    PropertyOwnership,
    Invoices,
    PropertyCertificates,
    TaxConsultations,
    PropertyValuations,
    MortgageRates,
    Reports,
    Mileage,
    MortgagesAndLoans,
    Shareholdings,
    Receipts,
    IncorpShareholdersAndDirectors,
    Incorporation,
    Consultations,
    RegularPayments,
    QuickEntries,
    ManualAdjustments,
    IncorporationDocuments,
    IncorpPSC,
    PendingPayments,
    Expenses,
    LoanTransactions,
    ExpenseTransactions,
    PensionSettings,
    StatutoryPayments,
    Payslips,
    TaxCodes,
    QuickEntryTimesheet,
    Mortgages,
    Properties,
    LettingStatements,
    FileUploads,
    Assets,
    Queries,
    TenancyNotesArchived,
    TenancyNotes,
    PropertyCertificatesArchived,
    Dividends,
    Reminders,
    PropertiesNew,
    People,
    PropertyTenancy,
    MortgageRatesV8,
    OBImportedTransactions,
    PropertyTransactions,
    SelectReport
}

export const useDatagridTerms = (datagrid: DatagridType) : (DatagridTerm | undefined) => {
    let terms = useContext(CompanyContext).terms?.datagridTerms;

    if (terms) {
        const typeTerms = _.find(terms, {datagrid: DatagridType[datagrid]});
        if (typeTerms)
            return typeTerms;

        console.error(`Couldn't find datagrid term '${DatagridType[datagrid]}'`);
        return undefined;
    } else {
        console.error('Datagrid terms not loaded');
        return undefined;
    }
}
