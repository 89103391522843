import { useContext, useEffect, useState } from 'react'
import { FormikErrors, FormikHelpers } from 'formik';
import CompanyContext, { CompanyProduct } from '../../../../context/CompanyContext';
import { Person, ShareholderSetupData, Shareholding, ShareholdingPostModel } from '../../../../api/inni/data-contracts';
import { useInniAPI } from '../../../../hooks/useInniAPI';
import { Shareholdings } from '../../../../api/inni/Shareholdings';
import { Ownership } from '../../../../api/inni/Ownership';
import { ShareholdingsTable } from './ShareholdingsTable';
import { asYearMonthDay } from '../../../../utils/formatDate';
import InfoBanner from '../../../../elements/InfoBanner/InfoBanner';
import { Button } from '../../../../elements/Button/Button';
import { useNavigateToEntity } from '../../../../hooks/useNavigateToEntity';
import { Action, Entity } from '../../../../utils/EntityAction';

export const ShareholdingRecords = ({person, reloadPeople} : {person:Person|undefined, reloadPeople: () => void}) => {
    const companyContext = useContext(CompanyContext);
    const shareholdingsApi = useInniAPI(Shareholdings, [400]);
    const ownershipApi = useInniAPI(Ownership, [400]);
    const [shareholdings, setShareholdings] = useState<Shareholding[]|undefined>();
    const [shareholderSetupData, setShareholderSetupData] = useState<ShareholderSetupData|undefined>();
    const [loaded, setLoaded] = useState(false);
    const navigateToEntity = useNavigateToEntity();
    const v8Styling = companyContext.product === CompanyProduct.isCap
    
    useEffect(() => {
        if (!loaded && shareholdingsApi && ownershipApi) {
            shareholdingsApi.index(companyContext.cid).then(results => {
                if (results.data) {
                    setShareholdings(results.data)
                    setLoaded(true)
                }
            }).catch(error => console.error(error))
        }
    }, [loaded, shareholdingsApi, shareholdings, ownershipApi, companyContext.cid])

    useEffect(() => {
        if (shareholdingsApi) {
            shareholdingsApi.onboardingData(companyContext.cid).then(results => {
                if (results.data) {
                    setShareholderSetupData(results.data)
                }
            }).catch(error => console.error(error))
        }
    }, [shareholdingsApi, companyContext.cid])

    const onCreate = (values: ShareholdingPostModel, actions: FormikHelpers<ShareholdingPostModel>) : Promise<number>=> {
        return new Promise((resolve, reject) => {
            if (shareholdingsApi) {
                shareholdingsApi.create(companyContext.cid, values)
                .then(res => {
                    if(res.status !== 201)
                        console.warn("Unexpected response code " + res.status)
                    
                    if(shareholdings && res.status === 201) {
                        setShareholdings([
                            ...shareholdings.map(holding => {
                                const newDateTo = new Date(values.dateFrom)
                                newDateTo && newDateTo.setDate(newDateTo.getDate() - 1)
                                return {...holding, dateTo: holding.personId === person?.id && holding.shareClass === values.shareClass ? asYearMonthDay(newDateTo) : holding.dateTo }
                            }),
                            {
                                id: parseInt(res.data),
                                personId: person?.id || -1,
                                dateFrom: values.dateFrom,
                                dateTo: values.dateTo,
                                shareClass: values.shareClass,
                                capitalPerShare: values.capitalPerShare,
                                numberOfShares: values.numberOfShares
                            }
                        ])
                        reloadPeople();
                        resolve(parseInt(res.data))
                    } else {
                        console.warn(`Unexpected response code: ${res.status}`)
                        reject()
                    }
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } 
            else {
                reject();
            }
        })
    }

    const onUpdate = (values: ShareholdingPostModel, actions: FormikHelpers<ShareholdingPostModel>, id: number) : Promise<boolean>=> {
        return new Promise((resolve, reject) => {
            if (shareholdingsApi) {
                shareholdingsApi.update(companyContext.cid, id, values)
                .then(res => {
                    if(res.status !== 200)
                        console.warn("Unexpected response code " + res.status)
                    
                    if(shareholdings && res.status === 200) {
                        setShareholdings([
                            ...shareholdings.map(holding => {
                                return holding.id === id ? {...values, id: holding.id } : holding
                            })
                        ])
                        resolve(true)
                    } else {
                        console.warn(`Unexpected response code: ${res.status}`)
                        reject()
                    }
                })
                .catch(error => {
                    actions.setErrors(error.error);
                    reject();
                })
            } 
            else {
                reject();
            }
        })
    }

    const validateCreate =  (values: ShareholdingPostModel) : Promise<FormikErrors<ShareholdingPostModel>> => {
        return new Promise((resolve, reject) => {
            if (shareholdingsApi) {
                return shareholdingsApi.validateCreate(companyContext.cid, values)
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }

    const validateUpdate =  (id: number, values: ShareholdingPostModel) : Promise<FormikErrors<ShareholdingPostModel>> => {
        return new Promise((resolve, reject) => {
            if (shareholdingsApi) {
                return shareholdingsApi.validateUpdate(companyContext.cid, id, values)
                .then(() => resolve({}))
                .catch(error => resolve(error.error))
            } else {
                reject();
            }
        })
    }

    const onDelete = (id: number) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (shareholdingsApi) {
                shareholdingsApi.delete(companyContext.cid, id)
                .then(data => {
                    if(shareholdings) {
                        let newRows = [...shareholdings]
                        newRows.splice(shareholdings.findIndex(x => x.id === id), 1)
                        setShareholdings(newRows)
                    }
                    reloadPeople();
                    resolve();
                })
                .catch(error => {
                    reject();
                })
            } else {
                reject();
            }
        })
    }

    const getSharesRestartInfo = () =>{
        return(
            <>
                {shareholderSetupData?.canRestart ?
                        <>
                            If the allocation of shares has been recorded incorrectly you can resubmit this information. &nbsp;
                            <Button small variant='primary' onClick={() => navigateToEntity(Entity.Shareholdings, Action.CreateWizard)} className='ml-1'>
                                Resubmit
                            </Button>
                        </>
                    :
                        <>
                            <p>{shareholderSetupData?.cannotRestartReason}</p>
                        </>
                }
                
            </>
        )
    }

    return (
        <>
            {v8Styling && shareholderSetupData && person && shareholdings && shareholdings.filter(x => x.personId === person.id).length > 0 &&
                <InfoBanner 
                    body={getSharesRestartInfo()} 
                    type={'info'}
                />
            }
           
            {shareholdings && person && <ShareholdingsTable 
                onCreate={onCreate} 
                validateCreate={validateCreate} 
                onUpdate={onUpdate} 
                validateUpdate={validateUpdate} 
                onDelete={onDelete} 
                shareholdings={shareholdings.filter(x => x.personId === person.id)} 
                person={person} />
            }
        </>
    )
}