import * as msal from "@azure/msal-browser";
import { LogLevel } from "@azure/msal-browser";
import configHelper from "../../utils/configHelper";
import authConfig, { AuthDefinition } from "./authConfig";

function loggerCallback(level: LogLevel, message: string, containsPii: boolean) {
    console.log(message);
 }


interface AuthConfig {
    msalConfiguration: msal.Configuration,
    scopes: Array<string>,
    passwordResetAuthority?: string,
    isB2C: boolean
}

function buildConfig(authDefinition: AuthDefinition): AuthConfig {
    let passwordResetAuthority = undefined;

    let config = {
        auth: {
            clientId: authDefinition.clientId,
            navigateToLoginRequestUrl: false,
            redirectUri: window.location.protocol + "//" + window.location.host + process.env.PUBLIC_URL + '/login',
            // if hub or mobile don't need/want to logout on MVC
            postLogoutRedirectUri: configHelper.useMobile || configHelper.platform === 'hub' ? 
                `${window.location.protocol}//${window.location.host}/` : 
                `${window.location.protocol}//${window.location.host}/login/logout`
        }
    } as msal.Configuration
    
    if (authDefinition.isB2C) {
        const tenant =`${authDefinition.tenantName}.onmicrosoft.com`;
        const authorityBase =  `https://${authDefinition.tenantName}.b2clogin.com/tfp/${tenant}/`
        const authority = `${authorityBase}${authDefinition.signInPolicy}`;
        passwordResetAuthority = `${authorityBase}${authDefinition.passwordResetPolicy}`;
    
        config.auth.authority = authority;
        config.auth.knownAuthorities = [`${authDefinition.tenantName}.b2clogin.com`];
    } else {
        config.auth.authority = `https://login.microsoftonline.com/${authDefinition.tenantName}`;
    }

    if (authDefinition.enableLogging) {
        config.system = {
            loggerOptions: {
                loggerCallback: loggerCallback,
                logLevel: msal.LogLevel.Verbose
            }
        }
    }


    return {
        msalConfiguration: config,
        scopes: authDefinition.scopes,
        passwordResetAuthority: passwordResetAuthority,
        isB2C: authDefinition.isB2C
    };
}

// Change this to build config for different environment
export const msalConfig = buildConfig(authConfig)

export const msalInstance = new msal.PublicClientApplication(msalConfig.msalConfiguration);

