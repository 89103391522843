/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TenancyNote, TenancyNotePostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class TenancyNotes<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags TenancyNotes
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/tenancies/{tenancyId}/notes
   * @secure
   */
  index = (companyId: number, tenancyId: number, params: RequestParams = {}) =>
    this.request<TenancyNote[], any>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyId}/notes`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TenancyNotes
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/tenancies/{tenancyId}/notes
   * @secure
   */
  create = (companyId: number, tenancyId: number, postmodel: TenancyNotePostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyId}/notes`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TenancyNotes
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/tenancies/{tenancyId}/notes/Validate
   * @secure
   */
  validateCreate = (
    companyId: number,
    tenancyId: number,
    postmodel: TenancyNotePostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyId}/notes/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TenancyNotes
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/tenancies/{tenancyId}/notes/{id}/Validate
   * @secure
   */
  validateUpdate = (
    companyId: number,
    tenancyId: number,
    id: number,
    postmodel: TenancyNotePostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyId}/notes/${id}/Validate`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TenancyNotes
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/tenancies/{tenancyId}/notes/{id}
   * @secure
   */
  update = (
    companyId: number,
    tenancyId: number,
    id: number,
    postModel: TenancyNotePostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyId}/notes/${id}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags TenancyNotes
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/tenancies/{tenancyId}/notes/{id}
   * @secure
   */
  delete = (companyId: number, tenancyId: number, id: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/tenancies/${tenancyId}/notes/${id}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
}
