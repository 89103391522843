import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { Account, Asset, PropertyCreateModel, PropertyUpdatePostModel } from '../../../api/inni/data-contracts';
import { Text as EditText, Submit, SelectList, ListOption, Switch }  from '../../../elements/EditRow/EditRow'
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Assets } from '../../../api/inni/Assets';
import { useFetchEntityList } from '../../../hooks/entities/useFetchEntityList';
import { useHasPermission } from '../../../hooks/useHasPermission';
import { Action, Entity } from '../../../utils/EntityAction';
import CompanyContext, { CompanyProduct } from '../../../context/CompanyContext';

export interface PropertyEditFormProps {
    initialValues: PropertyCreateModel,
    readOnlyFields?: Record<string, string>,
    formSubmit: (values: PropertyCreateModel, actions: FormikHelpers<PropertyCreateModel>) => Promise<void>,
    formValidate: (values: PropertyCreateModel) => Promise<FormikErrors<PropertyCreateModel>>,
    onCancelClick?: () => void,
    isExisting?: boolean,
    accounts: Account[]
}

export const PropertyEditForm = ({
        initialValues,
        readOnlyFields,
        formSubmit,
        formValidate,
        onCancelClick,
        isExisting,
        accounts
    } : PropertyEditFormProps) => {

    const [assets, assetsLoaded] = useFetchEntityList<Asset, Assets>(Assets)
    const [assetsList, setAssetsList] = useState<ListOption[]>([]);

    // Load assets
    useEffect(() => {
        if (assetsLoaded && assets) {
            let out:Array<ListOption> = [];
            out.push({value: '', label: 'None'})
            assets.forEach((asset : Asset) => {
                out.push({
                    value: String(asset.id),
                    label: asset.description || String(asset.id)
                })
            })
            setAssetsList(out)
        }
    }, [assets, assetsLoaded])

    // These values come from swagger, can't convert a type to values at runtime. TODO is to get swagger to generate proper enums
    const tenureDropDown:Array<ListOption> = [
        {value: "RentToRent", label: "Rent-to-rent"},
        {value: "Owned", label: "Owned"},
        {value: "Freehold", label: "Freehold"},
        {value: "Leasehold", label: "Leasehold"}
    ]

    const tenureLifecycleStatusDropDown:Array<ListOption> = [
        {value: "UnderOffer", label: "Under offer"},
        {value: "Active", label: "Active"},
        {value: "Sold", label: "Sold"}
    ]

    const hasPermission = useHasPermission()
    const companyContext = useContext(CompanyContext)
    const isSaas = companyContext.product === CompanyProduct.isCap

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={formSubmit}
            validateOnChange={false}       
            validate={formValidate}
        >
            {({ isSubmitting }) => (
                <Form>
                    <EditText<PropertyCreateModel> name='name' placeholder='Required' />
                    <EditText<PropertyCreateModel> name='nickname' />
                    <EditText<PropertyCreateModel> name='addressLine1' placeholder='Required' />
                    <EditText<PropertyCreateModel> name='addressLine2' />
                    <EditText<PropertyCreateModel> name='postTown' placeholder='Required' />
                    <EditText<PropertyCreateModel> name='county' />
                    <EditText<PropertyCreateModel> name='postcode' placeholder='Required' />
                    <EditText<PropertyCreateModel> name='country' />
                    {/*Asset - TODO?*/}
                    {hasPermission(Entity.PropertyAsset, Action.All)[0] && !isSaas && <SelectList<PropertyCreateModel> name='assetId' options={assetsList} label="Related asset" />}
                    {hasPermission(Entity.PropertyTenure, Action.All)[0] && !isSaas && <SelectList<PropertyCreateModel> name='tenure' options={tenureDropDown} />}
                    {hasPermission(Entity.PropertyTenure, Action.All)[0] && !isSaas && <SelectList<PropertyCreateModel> name='tenureLifecycleStatus' options={tenureLifecycleStatusDropDown} />}

                    {isExisting && !isSaas && <Switch<PropertyUpdatePostModel> name="isInactive" label='Archive property' />}

                    <Submit disabled={isSubmitting} onCancelClick={onCancelClick} />
                </Form>       
            )}
            </Formik>  
    )
}