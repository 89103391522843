import { useContext } from "react";
import CompanyContext from "../context/CompanyContext";
import UserContext from "../context/UserContext";
import BrandContext from "../context/BrandContext";
import { appInsights } from "../utils/appInsights";


export function useLogEventToAppInsights(): (name: string, properties?: { [key: string]: string | number; }, measurements?: { [key: string]: number; }) => void {

    const company = useContext(CompanyContext);
    const brand = useContext(BrandContext);
    const user = useContext(UserContext);

    return (name: string, properties?: { [key: string]: string | number; }, measurements?: { [key: string]: number; }) => {

        // client ip, op name, session id are set by app insights

        appInsights.context.application.ver = window.version || "unknown";
        appInsights.context.user.authenticatedId = user.user?.email || "";
        appInsights.context.user.accountId = String(company.cid);

        const extraProperties = {
            userIsAdmin: user.user?.email.includes("@inniaccounts") || user.user?.email.includes("@provestor") || window.location.host.includes("admin"),
            brand: brand.brand.name,
            baseProductName: company.baseProductName,
            baseProductCategory: company.baseProductCategory
        }

        appInsights.trackEvent({
            name,
            properties: { ...properties, ...extraProperties },  
            measurements 
        });


    }
}