import React from 'react';
import FormikField from '../../../elements/FormikField/FormikField';
import { Formik, FormikProps, FormikValues } from 'formik';
import { Form, Row } from 'react-bootstrap';
import ModalDialogbox from '../Elements/Modal/Modal';
import { checkDateExists } from '../Elements/Dropdown/DropdownButton';
import { TimesheetItem } from '../../../api/inni/data-contracts';
import { formatDate } from '../../../utils/formatDate';

type ModalSelectorProps = {
	showModal: boolean;
	setShow: (show: boolean) => void;
	startDate: string;
	endDate: string;
	SubmitValues: (data1: string, date2: string) => void;
	timeSheetSummary: TimesheetItem[];
};

type TimeSelectorProps = {
	timeSheetSummary: TimesheetItem[];
	formikRef: any;
	startDate: string;
	endDate: string;
	SubmitValues: (data1: string, date2: string) => void;
};

function CustomTimeSelectorFields({ formikRef, SubmitValues, startDate, endDate, timeSheetSummary }: TimeSelectorProps) {
	const [error, setError] = React.useState(false);
	return (
		<>
			<Formik
				initialValues={{
					startDate: startDate,
					endDate: endDate,
				}}
				onSubmit={(values) => {
					SubmitValues(values.startDate, values.endDate);
				}}
				innerRef={formikRef}
				validate={(values) => {}}>
				{({ values, setFieldValue }) => (
					<Form className="mt-4">
						<Row>
							<FormikField
								label="Start date"
								type="date"
								min={startDate}
								max={endDate}
								name="startDate"
								onChange={() => setFieldValue('startDate', values.startDate)}
							/>
							<FormikField
								label="End date"
								type="date"
								min={startDate}
								max={formatDate(endDate).split('/').reverse().join('/')}
								name="endDate"
								onChange={() => {
									setFieldValue('endDate', values.endDate);
								}}
							/>
							<FormikField
								name="billableDays"
								label="Available hours"
								type="label"
								className="font-weight-bold"
								width="100px"
								value={
									timeSheetSummary
										?.filter((x) => checkDateExists(x.date, values.startDate, values.endDate))
										.reduce((prevValue: any, currValue: { hours: number }) => prevValue + currValue.hours, 0) + ' hours'
								}
								readonly></FormikField>
						</Row>
					</Form>
				)}
			</Formik>
			{error && (
				<small className="invalid-feedback ml-3" style={{ display: 'block' }}>
					End date must be greater than start date
				</small>
			)}
		</>
	);
}

function DateSelector({ showModal, setShow, SubmitValues, ...props }: ModalSelectorProps) {
	const formikRef = React.useRef<FormikProps<FormikValues>>();

	const handleSubmit = () => {
		if (formikRef.current) {
			formikRef.current.handleSubmit();
		}
	};
	return (
		<>
			<ModalDialogbox
				type="new"
				buttonSubmit="Add date"
				setShow={setShow}
				children={<CustomTimeSelectorFields SubmitValues={SubmitValues} formikRef={formikRef} {...props} />}
				title="Add billable time"
				show={showModal}
				onClickSubmit={handleSubmit}
			/>
		</>
	);
}

export default DateSelector;
