import { useContext } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../context/UserContext";

export const useHistoryWrapper = () => {
  const userContext = useContext(UserContext);
  const history = useHistory();

  const push = (url: string, pushState?: Object) => {
    if (userContext.clearErrorMessage) userContext.clearErrorMessage();
    if (pushState !== undefined)
      history.push(url, pushState)
    else 
      history.push(url);
  }

  const goBack = () => {
    if (userContext.clearErrorMessage) userContext.clearErrorMessage();
    history.goBack();
  }

  const go = (num: number) => {
    if (userContext.clearErrorMessage) userContext.clearErrorMessage();
    history.go(num);
  }

  return { push, goBack, go, history }
}