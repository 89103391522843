import { EntityRoute, RouteContext } from './routeTypes';
import { Entity } from '../../utils/EntityAction';
import QuickEntry from '../../features/QuickEntry/QuickEntry';
import QuickEntryMobile from '../../features/QuickEntry/QuickEntryMobile';
import QuickEntryWrapper from '../../features/QuickEntry/QuickEntryWrapper';
import configHelper from '../../utils/configHelper';

const quickEntry:Array<EntityRoute> = [
    {
        path: '/company/:cid/quickentry',
        exact: true,
        context: RouteContext.Company,
        entity: configHelper.useMobile ? Entity.QuickEntry : Entity.QuickEntryV7, // use new mobile QE on mobile
        component: QuickEntryWrapper
    }
]

export default quickEntry;

