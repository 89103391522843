import { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/esm/Alert';

type Props = {
	bannerProps: DismissibleBannerProps|undefined;
	className?: string;
};
export type DismissibleBannerProps = {
	body: string;
	showBanner: boolean;
	type: 'info' | 'warning' | 'danger' | 'success' | 'admin';
	thin?: boolean;
	useFullscreen?: boolean;
};

function DismissibleBanner({ bannerProps, className }: Props) {
	const [banner, setBanner] = useState<DismissibleBannerProps>();
	useEffect(() => {
		bannerProps ? setBanner(bannerProps) : setBanner({body: '', showBanner: false, type: 'info'});
		const timeId = setTimeout(() => {
			// After 6 seconds set the show value to false
			setBanner(undefined)
		}, 6000);

		return () => {
			clearTimeout(timeId);
		};
	}, [bannerProps]);

	if (banner?.showBanner) {
		const { body, type} = banner;

		return (
				<Alert
			    	style={{ 
						position: 'fixed', 
						bottom: 0, 
						width: banner.useFullscreen ? '90%' : 'calc(90% - 240px)', 
						left: banner.useFullscreen ? '5%' : 'calc(5% + 240px)', 
						zIndex: 1000 
					}}
					variant={type}
					onClose={() => setBanner({ ...banner, showBanner: false })}
					dismissible
					className={className ? className : ''}
				>
					{type === 'danger' && <p>{'Sorry, something went wrong. ' + body}</p>}
					{type !== 'danger' && <p>{body}</p>}
				</Alert>
		);
	}

	return null;
}

export default DismissibleBanner;
