import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
    faCheck,
    faPencil,
    faPlus,
    faTimes,
    faTrash,
    faArrowToBottom,
    faDownload,
    faPaperclip,
    faArrowRight,
    faSearch,
    faUndo,
    faReceipt,
    faChevronLeft,
    faCopy,
    faEnvelope,
    faFileInvoice,
    faFileExport,
    faCalendarEdit,
    faSync,
    faTrashUndo,
    faSplit,
    faMagnifyingGlass,
    faFile,
    faArchive
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useState, useRef, useEffect, ReactElement, useContext } from 'react';
import { useHasPermission } from '../../hooks/useHasPermission';
import { Entity, Action } from '../../utils/EntityAction';
import styles from './Button.module.css'
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CompanyContext from '../../context/CompanyContext';
import Icon from '../../elements/Icon/Icon';

export type ButtonVariant = 'change' | 'positive' | 'primary'
    | 'secondary' | 'success' | 'danger'
    | 'warning' | 'info' | 'dark'
    | 'light' | 'recordPayment' | 'paymentInactive';

export type ButtonType =
    'save' |
    'cancel' |
    'new' |
    'done' |
    'edit' |
    'delete' |
    'drilldown' |
    'import' |
    'download' |
    'upload' |
    'submit' | 
    'close' |
    'view' |
    'moveToInbox' |
    'viewReceipt' |
    'back' |
    'copy' |
    'email' |
    'file' |
    'fileInvoice' |
    'split' |
    'fileexport' |
    'calender' |
    'sync' |
    'restore' |
    'continue' |
    'archive'

interface ButtonTypeDefinition {
    label?: string, 
    variant: ButtonVariant,
    icon?: IconDefinition,
    outline?: boolean,
    labelV8?: string
}

const ButtonTypeDefinitions: {[key:string] : ButtonTypeDefinition} = {
    'save': {
        variant: 'change',
        icon: faCheck
    },
    'cancel': {
        variant: 'dark',
        icon: faTimes,
        outline: true
    },
    'new': {
        variant: 'change',
        icon: faPlus,
        label: 'New',
        labelV8: 'Add'
    },
    'done': {
        variant: 'positive',
        icon: faCheck
    },
    'edit': {
        variant: 'positive',
        icon: faPencil
    },
    'delete': {
        variant: 'danger',
        icon: faTrash
    },
    'archive': {
        variant: 'danger',
        icon: faArchive
    },
    'import': {
        variant: 'change',
        icon: faArrowToBottom
    },
    'download': {
        variant: 'primary',
        icon: faDownload,
        outline: true
    },
    'upload': {
        variant: 'positive',
        icon: faPaperclip
    },
    'submit': {
        variant: 'positive',
        icon: faArrowRight
    },
    'close': {
        variant: 'primary',
        icon: faTimes,
        outline: true
    },
    'viewReceipt': {
        variant: 'primary',
        icon: faReceipt,
        label: 'View receipt'
    },
    'view': {
        variant: 'primary',
        icon: faSearch
    },
    'moveToInbox': {
        variant: 'primary',
        icon: faUndo,
        label: 'Move to inbox'
    },
    'back': {
        variant: 'light',
        icon: faChevronLeft
    }, 
    'copy':{
        variant: 'positive',
        icon:faCopy
    },
    'email':{
        variant: 'positive',
        icon:faEnvelope
    }, 
    'file':{
        variant: 'positive',
        icon:faFile
    },
    'fileInvoice':{ 
        variant: 'positive',
        icon:faFileInvoice
    },
    'split':{ 
        variant: 'change',
        icon:faSplit
    },
    'fileexport':{
        variant: 'positive',
        icon:faFileExport
    }, 
    'calender':{
        variant: 'positive',
        icon:faCalendarEdit
    },
    'sync':{
        variant: 'positive',
        icon:faSync
    },
    'restore':{
        variant: 'positive',
        icon:faTrashUndo
    },
    'drilldown':{
        variant: 'positive',
        icon:faMagnifyingGlass
    },
    'continue':{
        variant: 'positive'
    }
}

const AllowedV8Icons = ['download', 'file'];


export interface ButtonProps {
    /** Used to set the color, icon and label */
    buttonType?: ButtonType,

    /** The colour. 'change' and 'positive' for continuity with V6  */
    variant?: ButtonVariant,
    outline?: boolean,

    label?: string,
    children?: React.ReactNode,

    entity?: Entity,
    action?: Action,
    extraParams?: { [key: string]: string|number; },
    /** If entity & action provided, it will hide the button if no permission. Set this to always show it. */
    skipPermissionCheck?: boolean,

    /** Render as a form submit button */
    submitButton? : boolean,

    onClick?: () => void,

    disabled?: boolean, 
    disabledMessage?: string,

    tooltipMessage?: string,

    className?: string,

    confirmMessage?: string,

    small?: boolean,
    tableBtn?: boolean, // smaller than smaller (less top/bottom padding)
    tableBtnMd?: boolean // smaller then smaller but a bit more then tableBtn
    rowWithErrorBtn?: boolean,
    thin?: boolean,

    admin?: boolean,

    mobile?: boolean,
    iconOnly?: boolean;
    pointerEventOff?: boolean;
    isNavigateInNewTab?: boolean;
    headerBtn?: boolean;
    jumbo?: boolean;
    marginTop?: boolean;
}

//Resembles link text - But for onClicks rather than an actual link to somewhere
export const TextButton = ({onClick, text} : {onClick: () => void, text: string}) => {
    return <button className={styles.textBtn} onClick={onClick}>{text}</button>
}


export const Button = ({
    buttonType, label, children,
    variant, className, outline,
    disabled, disabledMessage, skipPermissionCheck,
    tooltipMessage, thin, entity,
    action, extraParams, submitButton,
    onClick, confirmMessage, small,
    rowWithErrorBtn, tableBtn, admin,
    mobile, iconOnly, pointerEventOff,
    isNavigateInNewTab, tableBtnMd, headerBtn,
    jumbo, marginTop} : ButtonProps) => {

    const [showTooltip, setShowTooltip] = useState(false);
    const [showTooltipDelay, setShowTooltipDelay] = useState(false);

    const refTooltipDelay = useRef<number | null>(null);

    const checkPermission = useHasPermission();
    const navigateToEntity = useNavigateToEntity();

    const companyContext = useContext(CompanyContext);
    const v8Styling = companyContext.company?.useV8UI || false;

    // Destroy in-progress timeouts on unmount (tooltip)
    useEffect(() => {
        return () => {
            if (refTooltipDelay.current != null)
                clearTimeout(refTooltipDelay.current);
        }
    }, [])
    
    const getLabel = () => {
        if (label && !buttonType) return label; // if we have a btntype we still use the label but want to append the icon aswell
        if (children) return children; 
        if (buttonType) {
            const bt = ButtonTypeDefinitions[buttonType];
            const text = label
                ? label
                : (bt.label || (v8Styling && bt.labelV8))
                    ? v8Styling ? bt.labelV8 : bt.label
                    : _.upperFirst(buttonType); // if we provide 'label' prop we go to that PLUS the icon otherwise fallback to previous

            // Special exception for continue button which uses non-standard icon and comes after text
            // if future examples arise we need to support this format
            if (buttonType === 'continue')
                return <>{ text } <Icon name='arrowNext'/></>
            // Mobile generally doesn't have space for icons
            else if (bt.icon && !mobile && (!v8Styling || iconOnly || (v8Styling && AllowedV8Icons.includes(buttonType))) ) {
                return <><FontAwesomeIcon icon={bt.icon} className={iconOnly ? 'm-0' : ''}/>{iconOnly ? '' : text}</>
            } else {
                return text;
            }
        }  
        return '';
    }

    const getVariant = () => {
        if (variant) return styles[variant];
        if (buttonType) {
            const bt = ButtonTypeDefinitions[buttonType];
            return [styles[bt.variant], bt.outline ? styles.outline : null];
        }
        return null;
    }

    const hasPermission = () => {
        if (skipPermissionCheck) return true;
        if (!entity) return true;   
        const p = checkPermission(entity, action || Action.List);
        return p[0];
    }

    const isAdminPermission = () => {
        if (!entity) return false;   
        const p = checkPermission(entity, action || Action.List);
        return p[1];
    }

    const onButtonClick = (e: React.MouseEvent<HTMLElement>) => {
        if (!disabled) {
            if (confirmMessage) {
                if (!window.confirm(confirmMessage)) {
                    e.preventDefault();
                    return;
                }
            }
            if (onClick) {
                onClick();
                e.preventDefault();
                return;
            }
            if (entity) {
                navigateToEntity(entity, action, extraParams, isNavigateInNewTab);
                e.preventDefault();
            }
        } else {
            e.preventDefault();
        }
    }

    const TooltipTouchDelay = () => {
        if (disabled && disabledMessage && !showTooltipDelay) {
            setShowTooltip(true);
            setShowTooltipDelay(true);
            refTooltipDelay.current = window.setTimeout(() => {
                setShowTooltip(false);
                setShowTooltipDelay(false);
            }, 3000); 
        }
    }

    const handleDisabledTrigger = (show:boolean) => {
        if (disabled && disabledMessage && !showTooltipDelay)
            setShowTooltip(show);
    }

    const handleTooltipTrigger = (show:boolean) => {
        if (tooltipMessage && !showTooltipDelay)
            setShowTooltip(show && !disabled);
    }

    const ButtonElement:ReactElement = (
        <button
            data-disabled={disabled}
            onClick={onButtonClick}
            onTouchEnd={TooltipTouchDelay}
            type={submitButton ? 'submit' : 'button'}
            className={
                classNames(
                    className, 
                    styles.button, 
                    getVariant(),
                    (isAdminPermission() || admin)
                        ? (
                            (buttonType === 'delete' || variant === 'danger')
                                ? styles.adminOnlyDanger
                                : styles.adminOnly
                        )
                        : null,
                    {
                        [styles.outline]: outline,
                        [styles.disabled]: disabled,
                        [styles.small]: small,
                        [styles.thin]: thin,
                        [styles.mobile]: mobile,
                        [styles.tableBtn]: tableBtn,
                        [styles.tableBtnMd]: tableBtnMd,
                        [styles.pointerEventsNone]: pointerEventOff,
                        [styles.rowWithErrorBtn]: rowWithErrorBtn,
                        [styles.iconOnly]: iconOnly,
                        [styles.jumbo]: jumbo,
                        [styles.headerBtn]: headerBtn,
                        [styles.marginTop]: marginTop
                    }
                )
            }
        > 
            {getLabel()}
        </button> 
    )

    if (hasPermission()) {
        if (disabledMessage) {
            return (
                <OverlayTrigger
                    show={showTooltip}
                    onToggle={(show) => handleDisabledTrigger(show)}
                    overlay={<Tooltip id='delete-tip'>{disabledMessage}</Tooltip>}
                >
                    <span className={classNames({[styles.btnSpan]: !headerBtn, [styles.headerMessageBtn]: headerBtn}, 'd-inline-block')}>
                        { ButtonElement }
                    </span>
                </OverlayTrigger>
            )
        } else if (tooltipMessage) {
            return (
                <OverlayTrigger
                    show={showTooltip}
                    onToggle={(show) => handleTooltipTrigger(show)}
                    overlay={<Tooltip id='delete-tip'>{tooltipMessage}</Tooltip>}
                >
                    <span className={classNames({[styles.btnSpan]: !headerBtn, [styles.headerMessageBtn]: headerBtn}, 'd-inline-block')}>
                        { ButtonElement }
                    </span>
                </OverlayTrigger>
            )
        } else {
            return ButtonElement
        }
    } else {
        return null;
    }
}
