import { countryCodes, nationalityCodes } from '../commonLists';

export const countryCodesToListOptions = (showNullDefault: boolean = false) => {
    if (showNullDefault)
        return [
            {value: null, label: 'Please select...'},
            ...countryCodes.map((entry) => ({value: entry.code, label: entry.name}))
        ];

    return countryCodes.map((entry) => ({value: entry.code, label: entry.name}));
}


export const nationalityCodesToListOptions = (showNullDefault: boolean = false) => {
    if (showNullDefault)
        return [
            {value: null, label: 'Please select...'},
            ...nationalityCodes.map((entry) => ({value: entry.code, label: entry.name}))
        ];

    return nationalityCodes.map((entry) => ({value: entry.code, label: entry.name}));
}
