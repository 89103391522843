import { useContext, useEffect, useState } from "react";
import styles from "./BankSelection.module.css"
import BankAccountCard from "./Component/BankAccountCard/BankAccountCard";
import { SbBlokData, getStoryblokApi } from "@storyblok/react";
import SearchWithClear from "../../../elements/SearchWithClear/SearchWithClear";
import { LoadingNewBankCard } from "../../../elements/LoadingBankCard/LoadingBankCard";
import { LoadingPlaceholderSearchSelector } from "../../../elements/LoadingPlaceholder/LoadingPlaceholder";
import SimpleFullscreen from "../../../components/Fullscreen/SimpleFullscreen";
import BrandContext from "../../../context/BrandContext";
import BankSelectionWelcome from "./Component/BankSelectionWelcomePage";
import { useNavigateToEntity } from "../../../hooks/useNavigateToEntity";
import { Action, Entity } from "../../../utils/EntityAction";

export interface BankInfo extends SbBlokData {
    BankName:string,
    BankAccountType: string,
    Interest: string,
    MonthlyFee: string,
    Overdraft: string,
    InfoLink: string,
    BankLink:string
    Likes: ListItem[],
    Checks:ListItem[],
    IsRecommended:boolean,
    BankImage:image
}

export interface ListItem extends SbBlokData {
    item: string,
}

export interface image{
    alt:string;
    filename:string;
}


const BankSelectionPage=()=>{
    const [banksInformationData, setBanksInformationData]= useState<BankInfo[]|undefined>()
    const [filteredBanksInformationData, setFilteredBanksInformationData]= useState<BankInfo[]|undefined>()
    const [searchTerm, setSearchTerm]=useState<string|undefined>(undefined)
    const [loading, setLoading]=useState<boolean>(true);

    const navigateToEntity= useNavigateToEntity();

    const [isWelcomeMessgae, setIswelcomeMessage]= useState<boolean>(true)
    
    useEffect(()=>{
        setLoading(true);
        const storyblokApi = getStoryblokApi();
        storyblokApi.get('cdn/stories/bank-details/bank-selection', { version: 'draft'})
        .then(res => {
            const bankDetails: BankInfo[] = [];
            res.data.story.content.Body.forEach((data: BankInfo) => {
                bankDetails.push(data)
            });
            
            setBanksInformationData(bankDetails)
            setLoading(false);
        }).catch(err => {
            console.error(err)
        });
    },[])

    useEffect(()=>{
        if(banksInformationData){
            const filteredData = banksInformationData
                                    .filter(x=> !x.IsRecommended && 
                                                x.BankName.toLowerCase().includes(searchTerm?.toLowerCase()||''));
            setFilteredBanksInformationData(filteredData)
        }
    },[banksInformationData,searchTerm]);



    return(
        <SimpleFullscreen 
            isOpen={true}  
            onClose={()=>navigateToEntity(Entity.OnboardingSetup,Action.List)}             
        >

            {isWelcomeMessgae &&

                <BankSelectionWelcome onclick={()=>{setIswelcomeMessage(false)}}/>
            
            }

            {!isWelcomeMessgae &&
                <div className={styles.outerLayout}>
                    <div className={styles.innerLayout} >
                    <p className={styles.H2Title}>Choose a Bank</p> 
                    <p>We understand the importance of making the right banking choice for your property business. Please select the one that best fits your needs</p> 

                    <div>
                        <p className={styles.dividerTitle} style={{marginBottom:"28px"}}>Banks we recommend:</p>
                        <div className={styles.bankAccounts}>
                            {loading &&
                                <>
                                    {Array(2)
                                        .fill(0)
                                        .map((_, index) => (
                                                <LoadingNewBankCard key={index} isRecommended/>
                                    ))}
                                </>
                            }

                            {!loading && banksInformationData && banksInformationData.filter(x=>x.IsRecommended).map((data)=>{
                                    return(
                                        <>
                                        
                                            <BankAccountCard 
                                                    key={data.BankName}
                                                    image={data.BankImage?.filename} 
                                                    title={data.BankName} 
                                                    subtitle={data.BankAccountType} 
                                                    interest={data.Interest} 
                                                    monthlyFee={data.MonthlyFee} 
                                                    overdraft={data.Overdraft} 
                                                    thingsToCheckList={data.Checks.map(x => x.item)} 
                                                    thingsWeLikeList={data.Likes.map(x => x.item)}
                                                    isRecommended={data.IsRecommended}
                                                    link={data.BankLink}
                                            />
                                        </>
                                    )
                                })
                            }

                        </div>
                    </div>

                    <div className={styles.allBanks}>
                        <p className={styles.dividerTitle}>All banks we support:</p>
                        <div className={styles.searchBar}>
                            {!loading ? 
                                <SearchWithClear search={searchTerm ||''} setSearch={setSearchTerm } placeholder="Search banks"/> :
                                <LoadingPlaceholderSearchSelector/>
                            }
                        </div>
                                    
                    </div>
                        <div className={styles.bankAccounts}>

                            {loading &&
                                <>
                                    {Array(2)
                                        .fill(0)
                                        .map((_, index) => (
                                                <LoadingNewBankCard key={index+2}/>
                                    ))}
                                </>
                            }

                            {!loading && filteredBanksInformationData && filteredBanksInformationData.length > 0 &&
                                
                                filteredBanksInformationData.map((data)=>{
                                    return(
                                        <BankAccountCard 
                                            key={data.BankName}
                                            image={data.BankImage?.filename} 
                                            title={data.BankName} 
                                            subtitle={data.BankAccountType} 
                                            interest={data.Interest} 
                                            monthlyFee={data.MonthlyFee} 
                                            overdraft={data.Overdraft} 
                                            thingsToCheckList={data.Checks.map(x => x.item)} 
                                            thingsWeLikeList={data.Likes.map(x => x.item)}
                                            isRecommended={data.IsRecommended}
                                            link={data.BankLink}
                                        />
                                    )
                                })
                            }
                           
                            {!loading && filteredBanksInformationData && filteredBanksInformationData.length === 0 &&
                                <div className={styles.noResult}>
                                    <h3 className="text-muted">No results</h3>  
                                </div>
                            }

                        </div>
                    </div>  
                </div>
            }
        </SimpleFullscreen>
        
    );
}

export default BankSelectionPage

