/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BankTransactionPostModel,
  BankTransactionViewModel,
  ExpenseAsSuggestion,
  VatCodeListOption,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Expenses<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Expenses
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/expenses
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<BankTransactionViewModel[], any>({
      path: `/api/v2/companies/${companyId}/expenses`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expenses
   * @name Post
   * @request POST:/api/v2/companies/{companyId}/expenses
   * @secure
   */
  post = (companyId: number, expense: BankTransactionPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/expenses`,
      method: "POST",
      body: expense,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expenses
   * @name New
   * @summary (Mobile only)Returns default values for a new entity (So prepopulate a field with this data) - GET /mileage/new
   * @request GET:/api/v2/companies/{companyId}/expenses/new
   * @secure
   */
  new = (companyId: number, params: RequestParams = {}) =>
    this.request<BankTransactionViewModel, any>({
      path: `/api/v2/companies/${companyId}/expenses/new`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expenses
   * @name NewSuggestions
   * @request GET:/api/v2/companies/{companyId}/expenses/newsuggestions
   * @secure
   */
  newSuggestions = (companyId: number, query?: { employeeId?: number }, params: RequestParams = {}) =>
    this.request<ExpenseAsSuggestion[], any>({
      path: `/api/v2/companies/${companyId}/expenses/newsuggestions`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expenses
   * @name Get
   * @request GET:/api/v2/companies/{companyId}/expenses/{id}
   * @secure
   */
  get = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<BankTransactionViewModel, any>({
      path: `/api/v2/companies/${companyId}/expenses/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expenses
   * @name Put
   * @request PUT:/api/v2/companies/{companyId}/expenses/{id}
   * @secure
   */
  put = (companyId: number, id: number, expense: BankTransactionPostModel, params: RequestParams = {}) =>
    this.request<number, any>({
      path: `/api/v2/companies/${companyId}/expenses/${id}`,
      method: "PUT",
      body: expense,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Expenses
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/expenses/{id}
   * @secure
   */
  delete = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/expenses/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Expenses
   * @name VatRates
   * @request GET:/api/v2/companies/{companyId}/expenses/vatrates
   * @secure
   */
  vatRates = (companyId: number, params: RequestParams = {}) =>
    this.request<VatCodeListOption[], any>({
      path: `/api/v2/companies/${companyId}/expenses/vatrates`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
