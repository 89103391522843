import { BrandWrapper } from '../../../../../components/BrandWrapper/BrandWrapper';
import SimpleFullscreen from '../../../../../components/Fullscreen/SimpleFullscreen';
import styles from '../shareholdings.module.scss'


interface createShareholdingsProps{
    hide: () => void;
} 

const CreateComplexShareholdings = ({hide}: createShareholdingsProps) => {

    return (
        <SimpleFullscreen
            isOpen={true}
            onClose={hide}
            title={"Shareholder information needed"}
        >
            <BrandWrapper>
                <div className={styles.subtitle}>
                    <p>We try to pull your shareholder data from public sources, but we've not been able to do that for your company, please email details of your shareholder structure to support@provestor.co.uk</p>
                </div> 
            </BrandWrapper> 
        </SimpleFullscreen>
  )
}

export default CreateComplexShareholdings