import React from 'react';
import { formatDate } from '../../../utils/formatDate';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import _ from 'lodash';
import {  Tenancy } from '../../../api/inni/data-contracts';
import { Action, Entity } from '../../../utils/EntityAction';
import { formatCurrency } from '../../../utils/formatNumbers';
import { LettableUnitListItem } from '../../../hooks/lists/useLettableUnitsList';
import { TenancyName } from '../TenancyUtilities';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';

export interface TenancyListTableProps {
    loaded: boolean, 
    tenancies?: Tenancy[],
    lettableUnits?: LettableUnitListItem[],
    hideProperty?: boolean
    v8Styling?: boolean
    appendURL?: string

}

export const TenancyListTable = ({loaded, tenancies, lettableUnits, hideProperty, v8Styling, appendURL}:TenancyListTableProps) => {
    return (
        <DataGrid.Table>
            <thead>
                <tr>
                    <th style={{width:'20%'}}>Tenant</th>
                    {!hideProperty &&
                        <th style={{width:'20%'}}>Property</th>
                    }
                    <th style={{width:'20%', textAlign:'right'}}>Start date</th>
                    <th style={{width:'20%', textAlign:'right'}}>End date</th>
                    <th style={{width:'20%', textAlign:'right'}}>Outstanding balance</th>
                </tr>
            </thead>
            <tbody>
                {!loaded && 
                    <>
                        <DataGrid.LoadingRow cols={hideProperty ? 4 : 5}  />
                        <DataGrid.LoadingRow cols={hideProperty ? 4 : 5}  />
                        <DataGrid.LoadingRow cols={hideProperty ? 4 : 5}  />
                        <DataGrid.LoadingRow cols={hideProperty ? 4 : 5}  />
                    </>
                }
                {loaded && 
                    <>
                        {tenancies && tenancies.length > 0
                            ? (
                                _.sortBy(tenancies, 'startDate' ).map(tenancy => 
                                    <DataGrid.EntityNavigateRow
                                        key={tenancy.id}
                                        entity={Entity.Tenancy}
                                        action={Action.Read}
                                        extraParams={{'id': tenancy.id}}
                                    >
                                        <td>{TenancyName(tenancy)}</td>
                                        {!hideProperty &&
                                            <td>{lettableUnits?.find(unit => unit.id === tenancy.propertyLettableUnitId)?.fullName}</td>
                                        }
                                        <td style={{textAlign:'right'}}>{formatDate(tenancy.startDate)}</td>
                                        <td style={{textAlign:'right'}}>{formatDate(tenancy.endDate)}</td>
                                        <td style={{textAlign:'right'}}>{formatCurrency(tenancy.outstandingGBP)}</td>
                                    </DataGrid.EntityNavigateRow>
                                )
                            )
                            : (
                                <tr>
                                    <td colSpan={5} className="p-0">
                                        <NoContentSlate type={DatagridType.PropertyTenancy} termsKey="emptyTerms" appendURL={appendURL} /> 
                                    </td>
                                </tr>
                            )
                        }
                    </>
                }
                

            </tbody>
        </DataGrid.Table>
    )
}