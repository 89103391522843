import { FormikErrors, FormikHelpers } from 'formik';
import { useContext, useState } from 'react';
import { DebtorsPostModel, SetupWizardForm } from '../../../api/inni/data-contracts';
import { SetupWizard } from '../../../api/inni/SetupWizard';
import CompanyContext from '../../../context/CompanyContext';
import { Button } from '../../../elements/Button/Button';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import { FormikRowInput, FormikRowLogic } from '../../../elements/FormikTableEditor/FormikTableEditor';
import { NoContentSlate } from '../../../elements/Slates/NoContentSlate';
import { TableIcon } from '../../../elements/TableIcon/TableIcon';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';
import { useInniAPI } from '../../../hooks/useInniAPI';
import { useModalDialog } from '../../../hooks/useModalDialog';
import { formatDate } from '../../../utils/formatDate';
import { formatCurrency } from '../../../utils/formatNumbers';

const Debtors = ({formState, setFormState} : {formState : SetupWizardForm, setFormState : (i : SetupWizardForm) => void}) => {

    const swAPI = useInniAPI(SetupWizard, [400])
    const companyContext = useContext(CompanyContext)

    const [creating, setCreating] = useState(false)
    const [editingRow, setEditingRow] = useState<number | undefined>(undefined)

    const [showModalDialog, modalDialog] = useModalDialog();

    const validateCreate = (values: DebtorsPostModel) : Promise<FormikErrors<DebtorsPostModel>> => {
        return new Promise((resolve, reject) => {
            if (swAPI) {
                swAPI.validateCreateDebtor(companyContext.cid, values)
                .then(res => {                    
                    resolve({})
                })
                .catch(err => resolve(err.error))
            }
        })
    }
    const validateUpdate = (id: number, values: DebtorsPostModel) : Promise<FormikErrors<DebtorsPostModel>> => {
        return new Promise((resolve, reject) => {
            if (swAPI) {
                swAPI.validateUpdateDebtor(companyContext.cid, { id: id }, values)
                .then(res => resolve({}))
                .catch(err => resolve(err.error))
            }
        })
    }
    const createCall = (values: DebtorsPostModel, actions: FormikHelpers<DebtorsPostModel>) : Promise<number> => {
        return new Promise((resolve, reject) => {
            if (swAPI) {
                swAPI.createDebtor(companyContext.cid, values)
                .then(res => {
                    let tempFs = formState
                    if (!tempFs.generalDebtors) tempFs.generalDebtors = []
                    tempFs.generalDebtors?.push({...values, id : parseInt(res.data), accountId: 0, readOnly: false})
                    setFormState(tempFs)
                    setCreating(false)
                    resolve(parseInt(res.data))
                })
            }
        })
    }
    const editCall = (values: DebtorsPostModel, actions: FormikHelpers<DebtorsPostModel>) : Promise<boolean> => {
        return new Promise((resolve, reject) => {
            let id = formState.generalDebtors ? formState.generalDebtors[editingRow || 0].id : 0
            if (swAPI) {
                swAPI.updateDebtor(companyContext.cid, { id: id }, values)
                .then(res => {
                    if (res.status === 200) {
                        let tempFs = formState
                        let i = tempFs.generalDebtors?.findIndex(x => x.id === id)
                        if (i !== undefined && i !== -1 && tempFs.generalDebtors) {
                            tempFs.generalDebtors[i] = {...tempFs.generalDebtors[i], ...values}
                        }
                        setFormState({...tempFs})
                    }
                    setEditingRow(undefined)
                    resolve(true)
                })
            }
        })
    }

    const showDeleteDialog = (debtorId: number) => {
        showModalDialog(
            'Delete unpaid payment?',
            'Are you sure you want to delete this unpaid payment?',
            [
                <Button variant="danger" label="Yes" onClick={() => deleteDebtor(debtorId)} />,
                <Button variant="secondary" label="No" onClick={() => {}} />,
            ],
            false
        );
    }

    const deleteDebtor = (debtorId : number) => {
        if (swAPI) {
            swAPI.deleteDebtor(companyContext.cid, { id: debtorId })
            .then(res => {
                if (res.status === 200) {
                    let tempFs = formState
                    if (tempFs.generalDebtors) {
                        let i = tempFs.generalDebtors.findIndex(x => x.id === debtorId)
                        if (i !== -1) {
                            if (tempFs.generalDebtors.length === 1) {
                                tempFs.generalDebtors = []
                            } else {
                                tempFs.generalDebtors.splice(i, 1)
                            }
                        }
                    }
                    setFormState({...tempFs})
                }
            })
        }
    }

    //Setup formik instance to be attached to inputs
    const formik = FormikRowLogic<DebtorsPostModel>({
        validateUpdate: validateUpdate, 
        validateCreate: validateCreate, 
        onCreate: createCall, 
        onUpdate: editCall, 
        addingNewRow: creating,
        id: creating || !editingRow ? undefined : formState.generalDebtors ? formState.generalDebtors[editingRow || 0].id : undefined,
        editRowValues: creating || editingRow === undefined ? 
            { date: '', amount: 0, amountVat: 0, includeOnVatReturn: false } as DebtorsPostModel : 
            formState.generalDebtors ? 
            {...formState.generalDebtors[editingRow || 0], 
                date: formState.generalDebtors[editingRow || 0].date || '', 
                includeOnVatReturn: (formState.generalDebtors[editingRow || 0].amountVat || 0) > 0, 
                amountVat: formState.generalDebtors[editingRow || 0].amountVat || 0} : undefined})

    return (<>
        <DataGrid.Table noHover>
            <thead>
                <tr>
                    <th>Date</th>
                    <th>Ref No</th>
                    <th>Description</th>
                    <th>Gross</th>
                    <th>VAT</th>
                    <th>Include on VAT Return</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {formState.generalDebtors?.length === 0 && 
                <tr>
                    <td colSpan={6}>
                        <NoContentSlate termsKey="emptyTerms" whiteBg type={DatagridType.Transactions} />
                    </td>
                </tr>}
                {formState.generalDebtors?.map((x,i) => {
                    return (<>
                        {editingRow !== i ?
                        <tr>
                            <td>{formatDate(x.date)}</td>
                            <td>{x.refNo}</td>
                            <td>{x.description}</td>
                            <td>{formatCurrency(x.amount)}</td>
                            <td>{formatCurrency(x.amountVat)}</td>
                            <td>{x.includeOnVatReturn && <TableIcon icon="check" />}</td>
                            <td style={{textAlign: 'right'}}>
                                <Button tableBtn onClick={() => {setCreating(false); setEditingRow(i)}} buttonType="edit" />
                                <Button tableBtn onClick={() => showDeleteDialog(x.id)} buttonType="delete" />
                            </td>
                        </tr> :
                        <>
                            <tr>
                                <td>
                                    <label>Date</label>
                                    <FormikRowInput<DebtorsPostModel> formik={formik} property="date" type="date"  />
                                </td>
                                <td colSpan={2}>
                                    <label>Ref no</label>
                                    <FormikRowInput<DebtorsPostModel> formik={formik} property="refNo" type="text"  />
                                </td>
                                <td colSpan={3}>
                                    <label>Description</label>
                                    <FormikRowInput<DebtorsPostModel> formik={formik} property="description" type="text"  />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Amount</label>
                                    <FormikRowInput<DebtorsPostModel> formik={formik} property="amount" type="number" prefix="£" />
                                </td>
                                <td>
                                    <label>VAT Amount</label>
                                    <FormikRowInput<DebtorsPostModel> formik={formik} property="amountVat" type="number" prefix="£" />
                                </td>
                                <td>
                                    <label>Include on VAT return</label>
                                    <FormikRowInput<DebtorsPostModel> formik={formik} property="includeOnVatReturn" type="check" />
                                </td>
                                <td colSpan={1}></td>
                                <td colSpan={3}>
                                    
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={7} style={{textAlign: 'right'}}>
                                    <Button onClick={formik.submitForm} buttonType="save" />
                                    <Button onClick={() => setEditingRow(undefined)} buttonType="cancel" />
                                </td>
                            </tr>
                        </>}
                    </>)
                })}
                {creating && <>
                <tr>
                    <td>
                        <label>Date</label>
                        <FormikRowInput<DebtorsPostModel> formik={formik} property="date" type="date"  />
                    </td>
                    <td colSpan={2}>
                        <label>Ref no</label>
                        <FormikRowInput<DebtorsPostModel> formik={formik} property="refNo" type="text"  />
                    </td>
                    <td colSpan={3}>
                        <label>Description</label>
                        <FormikRowInput<DebtorsPostModel> formik={formik} property="description" type="text"  />
                    </td>
                    <td></td>
                </tr>
                <tr>
                    <td>
                        <label>Amount</label>
                        <FormikRowInput<DebtorsPostModel> formik={formik} property="amount" type="number" prefix="£" />
                    </td>
                    <td>
                        <label>VAT Amount</label>
                        <FormikRowInput<DebtorsPostModel> formik={formik} property="amountVat" type="number" prefix="£" />
                    </td>
                    <td>
                        <label>Include on VAT return</label>
                        <FormikRowInput<DebtorsPostModel> formik={formik} property="includeOnVatReturn" type="check" />
                    </td>
                    <td colSpan={4}></td>
                </tr></>}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={6}>
                        {!creating && <Button onClick={() => {setEditingRow(undefined); setCreating(true)}} variant="change">New Payment</Button>}
                        {creating && <>
                            <Button onClick={formik.submitForm} buttonType="save" />
                            <Button onClick={() => setCreating(false)} buttonType="cancel" />
                        </>}
                    </td>
                </tr>
            </tfoot>
            { modalDialog }
        </DataGrid.Table>
    </>)
}

export default Debtors