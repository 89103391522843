/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ArthurEntityPostModel, ExternalPropertyConnection } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ExternalPropertyServices<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ExternalPropertyServices
   * @name Index
   * @summary Gets connected services and whether they are authorised
   * @request GET:/api/v2/companies/{companyId}/externalpropertyservices
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<ExternalPropertyConnection[], any>({
      path: `/api/v2/companies/${companyId}/externalpropertyservices`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalPropertyServices
   * @name HasEntityId
   * @request GET:/api/v2/companies/{companyId}/externalpropertyservices/HasEntityId
   * @secure
   */
  hasEntityId = (companyId: number, params: RequestParams = {}) =>
    this.request<boolean, any>({
      path: `/api/v2/companies/${companyId}/externalpropertyservices/HasEntityId`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalPropertyServices
   * @name Delete
   * @summary Deletes access token and refresh token for a given service
   * @request DELETE:/api/v2/companies/{companyId}/externalpropertyservices/{service}
   * @secure
   */
  delete = (companyId: number, service: "MTD" | "Arthur", params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/externalpropertyservices/${service}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags ExternalPropertyServices
   * @name SetArthurEntityId
   * @request POST:/api/v2/companies/{companyId}/externalpropertyservices/setarthurentityid
   * @secure
   */
  setArthurEntityId = (companyId: number, arthurEntity: ArthurEntityPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/externalpropertyservices/setarthurentityid`,
      method: "POST",
      body: arthurEntity,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
