type Props = {
	status: "PendingResponse" | "Approved" | "Rejected" | "WaitingReview";
	name: string;
};
const StatusItem = ({ status, name }: Props) => {
    const statuses = {
        Approved: <p className="mb-1"><span className="mr-3">✔️</span> Passed!</p>,
        WaitingReview: <p className="mb-1"><span className="mr-3">✖️</span> We're checking manually</p>,
        Rejected: <p className="mb-1"><span className="mr-3">❌</span> Rejected</p>,
		PendingResponse: <p className="mb-1"><span className="mr-3">⌛</span> Waiting verification</p>,
    }
	return (
		<tr>
			<td>{name}</td>
			<td >{statuses[status]}</td>
		</tr>
	);
};

export default StatusItem;
