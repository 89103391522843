export const setPropToStoryblokText = (
	text: string,
	props: { [key: string]: string }
) => {
	let editedText = text;
	for (const key in props) {
		if (Object.prototype.hasOwnProperty.call(props, key)) {
			editedText = editedText.replace(
				new RegExp(`{${key}}|{{${key}}}`, 'gi'),
				props[key]
			);
		}
	}
    return editedText;
};
