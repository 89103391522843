/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AtedDeclaration } from "./data-contracts";
import { HttpClient, RequestParams } from "./http-client";

export class Ated<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Ated
   * @name ForValuation
   * @request GET:/api/v2/companies/{companyId}/ated/forvaluation
   * @secure
   */
  forValuation = (companyId: number, query: { valuationId: number }, params: RequestParams = {}) =>
    this.request<AtedDeclaration, any>({
      path: `/api/v2/companies/${companyId}/ated/forvaluation`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ated
   * @name DeclarationMadeForValuation
   * @request POST:/api/v2/companies/{companyId}/ated/forvaluation
   * @secure
   */
  declarationMadeForValuation = (companyId: number, query: { valuationId: number }, params: RequestParams = {}) =>
    this.request<AtedDeclaration, any>({
      path: `/api/v2/companies/${companyId}/ated/forvaluation`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ated
   * @name ForProperty
   * @request GET:/api/v2/companies/{companyId}/ated/forproperty
   * @secure
   */
  forProperty = (companyId: number, query: { propertyId: number }, params: RequestParams = {}) =>
    this.request<AtedDeclaration, any>({
      path: `/api/v2/companies/${companyId}/ated/forproperty`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Ated
   * @name DeclarationMadeForProperty
   * @request POST:/api/v2/companies/{companyId}/ated/forproperty
   * @secure
   */
  declarationMadeForProperty = (companyId: number, query: { propertyId: number }, params: RequestParams = {}) =>
    this.request<AtedDeclaration, any>({
      path: `/api/v2/companies/${companyId}/ated/forproperty`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
}
