import React from 'react';
import { formatDate } from '../../../utils/formatDate';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import _ from 'lodash';
import { Property } from '../../../api/inni/data-contracts';
import { Badge } from 'react-bootstrap';
import { formatCurrency0dp } from '../../../utils/formatNumbers';
import { Action, Entity } from '../../../utils/EntityAction';
import { formatTenure } from '../../../utils/formatters/formatProperty';
import { LettableUnitsBadges } from '../Elements/LettableUnitsBadges';


export interface PropertyListTableProps {
    loaded: boolean, 
    properties: Array<Property>
}


export const PropertyListTable = ({loaded, properties}:PropertyListTableProps) => {

    const propertyName = (property: Property) => {
        const name = property.nickname ? property.nickname : property.name;
        if (property.saleDate || property.isInactive) {
            return <del>{name}</del>
        } else {
            return <>{name}</>
        }
    }

    const propertyValue = (property: Property) => {
        if (property.saleDate && property.salePrice) {
            return <>{`${property.saleDate ? 'Sold for ' : ''} ${formatCurrency0dp(property.salePrice)}`}</>
        }  else if (property.currentValue) {
            return <>{formatCurrency0dp(property.currentValue)}</>
        } else {
            return <></>
        }
    }


    const tenure = (property: Property) => {
        if (property.saleDate) {
            return <Badge pill variant='warning'>Sold</Badge>
        }
        else if (property.isInactive) {
            return <Badge pill variant='warning'>Archived</Badge>
        }
        else {
            return <>{formatTenure(property.tenure)}</>
        }
    }

    return (
        <DataGrid.Table>
            <thead>
                <tr>
                    <th style={{width:'20%'}}>Name</th>
                    <th style={{width:'20%', textAlign:'right'}}>Tenure</th>
                    <th style={{width:'20%', textAlign:'right'}}>Purchase date</th>
                    <th style={{width:'20%', textAlign:'right'}}>Current value</th>
                    <th style={{width:'20%', textAlign:'right'}}>Lettable units</th>
                </tr>
            </thead>
            <tbody>
                {!loaded && 
                    <>
                        <DataGrid.LoadingRow cols={5}  />
                        <DataGrid.LoadingRow cols={5}  />
                        <DataGrid.LoadingRow cols={5}  />
                        <DataGrid.LoadingRow cols={5}  />
                    </>
                }
                {loaded && 
                    <>
                        {_.sortBy(properties, 'purchaseDate' ).map(property => 
                            <DataGrid.EntityNavigateRow key={property.id} entity={Entity.Property} action={Action.Read} extraParams={{'id': property.id}} >
                                <td>{propertyName(property)}</td>
                                <td style={{textAlign:'right'}}>{tenure(property)}</td>
                                <td style={{textAlign:'right'}}>{formatDate(property.purchaseDate)}</td>
                                <td style={{textAlign:'right'}}>{propertyValue(property)}</td>
                                <td style={{textAlign:'right'}}>
                                    <h4 className="d-inline"><LettableUnitsBadges units={property.lettableUnits} /></h4>
                                </td>
                            </DataGrid.EntityNavigateRow>
                        )}
                    </>
                }
                

            </tbody>
        </DataGrid.Table>
    )
}