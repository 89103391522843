import React from 'react';
import { Modal as ReactModal } from 'react-bootstrap';
import { Button } from '../../elements/Button/Button';
import { BrandWrapper } from '../BrandWrapper/BrandWrapper';
import styles from './Modal.module.scss';
import useIsMobile from '../../hooks/useIsMobile';

export interface ReceiptModalProps {
    showModal: boolean,
    hideModal: () => void,
    children?: React.ReactNode,
    title?: string,
    size?: "sm" | "lg" | "xl",
    buttons?: React.ReactNode | undefined,
    showCloseBtn?: boolean | undefined,
    hideCrossClose?: boolean,
    titleBg?: string,
    titleColor?: string,
    restoreFocus?: boolean,
    footerChildren?: React.ReactNode
}

export const Modal = ({showModal, hideModal, children, title, size, buttons, showCloseBtn, titleBg, titleColor, restoreFocus, footerChildren, hideCrossClose}:ReceiptModalProps) => {
    const isMobile = useIsMobile();

    return (
        <ReactModal size={size || "lg"} aria-labelledby="inni-modal-title" centered show={showModal} onHide={hideModal} restoreFocus={restoreFocus !== undefined ? restoreFocus : true} data-cy="modal">
            {/* Add brandwrapper to inject custom css vars */}
            <BrandWrapper>
                {title &&
                    <ReactModal.Header closeButton={!hideCrossClose} style={{background: titleBg, color: titleColor}}>
                        <ReactModal.Title id="inni-modal-title">
                            {title}
                        </ReactModal.Title>
                    </ReactModal.Header>
                }
                
                <ReactModal.Body className={styles.modalBody}>
                    {children}
                </ReactModal.Body>
                {(showCloseBtn || buttons) && (
                    <ReactModal.Footer>
                        {footerChildren && <div className="d-flex w-100">
                            {footerChildren}
                        </div>}
                        <div className="d-flex w-100">
                            {/* Ideally we would assign isMobile to buttons recursively however requires
                            refactoring of usage first since not all inputs are reliable button arrays - DD */}
                            {buttons}
                            { showCloseBtn && <Button variant="primary" outline onClick={hideModal} mobile={isMobile}>Close</Button> }
                        </div>
                    </ReactModal.Footer>
                )}
            </BrandWrapper>
        </ReactModal>        
    )
}