import { AllocationItemPostModel } from "../../api/inni/data-contracts"

export interface BankingFields {
    date?: boolean, 
    payee?: boolean, 
    description?: boolean, 
    amount?: boolean, 
    vatRate?: boolean, 
    matches?: boolean, 
    ref?: boolean, 
    category?: boolean, 
    contract?: boolean, 
    billable?: boolean,
    transferredTo?: boolean
}

const defaultValues:BankingFields = {
    date: true,
    description: true,
    amount: true,
    matches: true,
    payee: true,
    ref: true
}

//Need the string version for the switch case to work
export enum ProcessorType {
    assetPurchase = "assetPurchase",
    assetSale = "assetSale",
    recurringPayment = "recurringPayment",
    recurringTransfer = "recurringTransfer",
    expense = "expense",
    refund = "refund",
    payslip = "payslip",
    PAYEReturn = "PAYEReturn",
    VATReturn = "VATReturn",
    transfer = "transfer",
    otherIncome = "otherIncome",
    salesInvoice = "salesInvoice",
    creditNote = "creditNote",
    creditCardPayment = "creditCardPayment",
    dividend = "dividend",
    generalJournal = "generalJournal",
    generalCreditors = "generalCreditors",
    generalDebtors = "generalDebtors",
    openingBalance = "openingBalance",
    corpTax = "corpTax",
    P11D = "P11D",
    pensionProvider = "pensionProvider",
    mileage = "mileage",
    lettingReceipt = "lettingReceipt",
    lettingPayment = "lettingPayment",
}

export const GetBankingFields = (type:ProcessorType) => {
    let fields:BankingFields = {}
    //NOTE: Transfer doesn't go through Setup func in BPDisplay.vb
    switch(type) {
        case ProcessorType.otherIncome:
            fields = {vatRate: true, category: true}
            break;
        case ProcessorType.transfer:
        case ProcessorType.recurringTransfer:
            fields = {transferredTo: true, payee: false, ref: false}
            break;
        case ProcessorType.assetPurchase:
            fields = {vatRate: true}
            break;
        case ProcessorType.assetSale:
            fields = {vatRate: true}
            break;
        case ProcessorType.creditCardPayment:
            fields = {category: true}
            break;
        case ProcessorType.recurringPayment:
        case ProcessorType.expense:
        case ProcessorType.refund:
            fields = {vatRate: true, category: true, contract: true, billable: true}
            break;
        case ProcessorType.lettingReceipt:
        case ProcessorType.lettingPayment:
            fields = {category:true}
            break;
    }

    return {...defaultValues, ...fields} as BankingFields
}

export interface BPFlowStages {
    showAmount: boolean
    showContractId: boolean
    showIncreasesPropertyValue: boolean
    showIsAsset: boolean
    showAssetSale: boolean
    showIsReplacementItem: boolean
    showBillToContract: boolean
    showApplicablePeriodStart: boolean
    showNewTxnDescription: boolean
    showPayee: boolean
    showVATCode: boolean
    showCompletionStatementType: boolean
    showCompletionStatementDate: boolean
}

export const GetNewBPFlow: (stages: BPFlowStages) => (keyof AllocationItemPostModel)[] = (stages: BPFlowStages) => {
    let flow: (keyof AllocationItemPostModel)[] = []
    if(stages.showAmount) flow.push("amount")
    if(stages.showContractId) flow.push("contractId")
    if(stages.showIncreasesPropertyValue && stages.showContractId) flow.push("increasesPropertyValue") //If we don't show contract (property), no need to ask this as we don't know which one
    if(stages.showIsReplacementItem) flow.push("isReplacementItem")
    if(stages.showBillToContract && stages.showBillToContract) flow.push("billToContract") //If we don't show contract (property), no need to ask this as we don't know which one
    if(stages.showApplicablePeriodStart) flow.push("applicablePeriodStart")
    if(stages.showNewTxnDescription) flow.push("newTxnDescription")
    if(stages.showPayee) flow.push("newTxnPayee")
    if(stages.showIsAsset) flow.push("isAsset")
    if(stages.showAssetSale) flow.push("assetId")
    if(stages.showVATCode) flow.push("vatCode")
    if(stages.showCompletionStatementType) flow.push("completionStatementType")
    if(stages.showCompletionStatementDate) flow.push("completionStatementDate")
    return flow
}

export const getPaymentTypeShort = (paymentTypeLong:ProcessorType) => {
    switch(paymentTypeLong) {
        case ProcessorType.assetPurchase : return "AP"
        case ProcessorType.assetSale : return "AS"
        case ProcessorType.recurringPayment : return "RP"
        case ProcessorType.recurringTransfer : return "RT"
        case ProcessorType.expense : return "EX"
        case ProcessorType.refund : return "RX"
        case ProcessorType.payslip : return "PS"
        case ProcessorType.PAYEReturn : return "PR"
        case ProcessorType.VATReturn : return "VR"
        case ProcessorType.transfer : return "TR"
        case ProcessorType.otherIncome : return "OI"
        case ProcessorType.salesInvoice : return "SI"
        case ProcessorType.creditNote : return "CN"
        case ProcessorType.creditCardPayment : return "CC"
        case ProcessorType.dividend : return "DP"
        case ProcessorType.generalJournal : return "GJ"
        case ProcessorType.generalCreditors : return "GC"
        case ProcessorType.generalDebtors : return "GD"
        case ProcessorType.openingBalance : return "OB"
        case ProcessorType.corpTax : return "CT"
        case ProcessorType.P11D : return "11"
        case ProcessorType.pensionProvider : return "PP"
        case ProcessorType.mileage : return "MI"
        case ProcessorType.lettingReceipt : return "LR"
        case ProcessorType.lettingPayment : return "LP"
        default: return ""
    }
}

export const getPaymentTypeLong = (paymentType:string) => {
    switch(paymentType) {
        case "AP" : return ProcessorType.assetPurchase
        case "AS" : return ProcessorType.assetSale
        case "RP" : return ProcessorType.recurringPayment
        case "RT" : return ProcessorType.recurringTransfer
        case "EX" : return ProcessorType.expense
        case "RX" : return ProcessorType.refund
        case "PS" : return ProcessorType.payslip
        case "PR" : return ProcessorType.PAYEReturn
        case "VR" : return ProcessorType.VATReturn
        case "TR" : return ProcessorType.transfer
        case "OI" : return ProcessorType.otherIncome
        case "SI" : return ProcessorType.salesInvoice
        case "CN" : return ProcessorType.creditNote
        case "CC" : return ProcessorType.creditCardPayment
        case "DP" : return ProcessorType.dividend
        case "GJ" : return ProcessorType.generalJournal
        case "GC" : return ProcessorType.generalCreditors
        case "GD" : return ProcessorType.generalDebtors
        case "OB" : return ProcessorType.openingBalance
        case "CT" : return ProcessorType.corpTax
        case "11" : return ProcessorType.P11D
        case "PP" : return ProcessorType.pensionProvider
        case "MI" : return ProcessorType.mileage
        case "LR" : return ProcessorType.lettingReceipt
        case "LP" : return ProcessorType.lettingPayment
        default: return ""
    }
}

export const getCategoryFallbackTextForType = (paymentType:string) => {
    switch(paymentType) {        
        case "AP" : return "an asset purchase"
        case "AS" : return "an asset sale"
        case "RP" : return "a recurring payment"
        case "RT" : return "a recurring transfer"
        case "EX" : return "an expense"
        case "RX" : return "a refund"
        case "PS" : return "a payslip"
        case "PR" : return "a PAYE return"
        case "VR" : return "a VAT return"
        case "TR" : return "a bank transfer"
        case "OI" : return "other income"
        case "SI" : return "a sales invoice"
        case "CN" : return "a credit note"
        case "CC" : return "a credit card payment"
        case "DP" : return "a dividend"
        case "GJ" : return "a general journal"
        case "GC" : return "general creditors"
        case "GD" : return "general debtors"
        case "OB" : return "your opening balance"
        case "CT" : return "corporation tax"
        case "11" : return "a P11D"
        case "PP" : return "a pension provider"
        case "MI" : return "mileage"
        case "LR" : return "a letting receipt"
        case "LP" : return "a letting payment"
        case "TO" :
        case "TI" :
            return "a bank transfer"
        default: {
            console.warn("Unexpected payment type", paymentType)
            return ""
        }
    }
}