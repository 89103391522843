import { faTag } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { IncorporationFile } from '../../../../api/inni/data-contracts'
import { IncorporationForm } from '../../../../api/inni/IncorporationForm'
import { Modal } from '../../../../components/Modal/Modal'
import Badge from '../../../../elements/Badge/Badge'
import { Button } from '../../../../elements/Button/Button'
import FileIcon from '../../../../elements/FileIcon/FileIcon'
import ZoomImage from '../../../../elements/ZoomImage/ZoomImage'
import { useInniAPI } from '../../../../hooks/useInniAPI'
import { DefaultLayout } from '../../../../layouts/Desktop/DefaultLayout'
import { Entity } from '../../../../utils/EntityAction'
import styles from '../../NoCompanies.module.css'
import { placeholderImage } from '../../../../utils/formatters/formatProperty'
import { LoadingPlaceholder } from '../../../../elements/LoadingPlaceholder/LoadingPlaceholder'
import { NoContentSlate } from '../../../../elements/Slates/NoContentSlate'
import { DatagridType } from '../../../../hooks/terms/useDatagridTerms'
import useIsMobile from '../../../../hooks/useIsMobile'

const DocumentLoading = () => {
    return (
        <Col xl={3} lg={4} md={6} xs={12}>
            <Card className="shadow" style={{marginBottom: '20px'}}>
                <Card.Header className={styles.docCardHeader}>
                    <img src={placeholderImage} alt="Placeholder" />
                </Card.Header>
                <Card.Body>
                    <p><LoadingPlaceholder width="50%" /></p>
                    {/* We probably don't need this if we aren't putting all 'hub' documents together */}
                    <div className={styles.docTags}>
                        <Badge color="#eee"><FontAwesomeIcon icon={faTag} /> <LoadingPlaceholder width="75px" /></Badge>
                        <Badge color="#eee"><FontAwesomeIcon icon={faTag} /> <LoadingPlaceholder width="75px" /></Badge>
                    </div>
                    <div className={styles.docCardButtons}>
                        <Button disabled buttonType="view"/>
                        <Button disabled className={styles.cardBtn} buttonType="download" />
                    </div>
                </Card.Body>
            </Card>
        </Col>
    )
}

const IncorporationDocuments = () => {

    const incorpAPI = useInniAPI(IncorporationForm)

    const isMobile = useIsMobile()

    const [documents, setDocuments] = useState<IncorporationFile[] | undefined>(undefined)
    const [documentsLoaded, setDocumentsLoaded] = useState(false)

    const [docShowing, setDocShowing] = useState<IncorporationFile | undefined>(undefined)
    const [modalShowing, setModalShowing] = useState(false)

    useEffect(() => {
        if (incorpAPI && !documentsLoaded) {
            incorpAPI.getFiles(0)
            .then(res => {
                if (res.status === 200) {
                    setDocuments(res.data)
                    setDocumentsLoaded(true)
                }
            })
        }
    }, [documentsLoaded, incorpAPI])

    const getImageOrFallback = (externalUrl : string, name : string) => {
        if (externalUrl.includes('.pdf')) {
            return <div><FileIcon iconType="pdf" /></div>
        } else if (externalUrl.includes('.xls') || externalUrl?.includes('.csv')) {
            return <div><FileIcon iconType="xls" /></div>
        } else if (externalUrl.includes('.txt')) {
            return <div><FileIcon iconType="file" /></div>
        } else {
            return <img src={externalUrl} alt={name} />
        }
    }

    // Basically isImage... returns true if has a file extension in the list below
    const shouldShowView = (url : string) => (
        url.includes('.png') 
        || url.includes('.jpg') 
        || url.includes('.jpeg') 
        || url.includes('.bmp') 
        || url.includes('.gif')
    )

    return (
        <DefaultLayout useFullWidth={isMobile} entity={Entity.IncorporationDocuments} title="Incorporation Documents">
            { documentsLoaded && documents && documents.length === 0 && <NoContentSlate whiteBg type={DatagridType.IncorporationDocuments} termsKey="emptyTerms" /> }
            <Container>
                <Row>
                    { !documentsLoaded && <>
                        <DocumentLoading />
                        <DocumentLoading />
                        <DocumentLoading />
                    </>}
                    {/* If docs loaded and there is one then we show them */}
                    {documents && documents.length !== 0 && documentsLoaded && 
                        documents.map((x,i) => {
                            return (
                            <Col key={i} xl={3} lg={4} md={6} xs={12}>
                                <Card className="shadow" style={{marginBottom: '20px'}}>
                                    <Card.Header onClick={() => {if (shouldShowView(x.externalUrl || '')) {setModalShowing(true); setDocShowing(x)}}} className={styles.docCardHeader}
                                        style={{cursor: shouldShowView(x.externalUrl || '') ? 'pointer' : ''}}>
                                        { getImageOrFallback(x.externalUrl || '', x.name || '') }
                                    </Card.Header>
                                    <Card.Body>
                                        <p>{x.name}</p>
                                        {/* We probably don't need this if we aren't putting all 'hub' documents together */}
                                        <div className={styles.docTags}>
                                            <Badge color="var(--account-color-6)"><FontAwesomeIcon icon={faTag} /> Incorporation document</Badge>
                                        </div>
                                        <div className={styles.docCardButtons}>
                                            {shouldShowView(x.externalUrl || '') && <Button buttonType="view" onClick={() => {setModalShowing(true); setDocShowing(x)}} />}
                                            <a href={x.downloadUrl}><Button className={styles.cardBtn} buttonType="download" /></a>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>)
                        })
                    }
                </Row>
            </Container>

            <Modal title="View Document" showModal={modalShowing && docShowing !== undefined} hideModal={() => setModalShowing(false)}
                buttons={[<Button buttonType="close" key="closeBtn" onClick={() => setModalShowing(false)} />, <a key="downloadBtn" href={docShowing?.downloadUrl}><Button buttonType="download" /></a>]}
            >
                {docShowing && !docShowing.externalUrl?.includes('.pdf') && !docShowing.externalUrl?.includes('.xls') && !docShowing.externalUrl?.includes('.csv') 
                && <div className={styles.viewImage}><ZoomImage maxHeight='500px' src={docShowing.externalUrl || ''} alt={docShowing.name || ''} /></div>}
                {/* A thumbnail is generated, but not instantly, so show preview unavailable - do we need the placeholder? */}
                {docShowing && (docShowing.externalUrl?.includes('.pdf') || docShowing.externalUrl?.includes('.xls') || docShowing.externalUrl?.includes('.csv')) && 
                <div className={styles.noPreview}>
                    <img src={placeholderImage} alt="Placeholder" />
                    <>Sorry, there is currently no preview available, please download the file instead</>
                </div>} 
            </Modal>
            
        </DefaultLayout>
    )
}

export default IncorporationDocuments