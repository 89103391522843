import { faCloudUploadAlt, faSpinner } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback } from 'react'
import { Card } from 'react-bootstrap'
import { FileRejection, useDropzone } from 'react-dropzone'
import styles from './FileUploadModal.module.css'
import { Button } from '../../elements/Button/Button';


export interface FileUploadModalProps {
    onCancelClick?: () => void,
    onFilesDropped?: (files:File[], fileRejections:FileRejection[]) => void,
    maxFiles?: number,
    uploading?: boolean,
    accept?: 'images'|'documents'|'pdfimg' | 'csv',
    maxSizeMB?: number,
    showDropZoneText?: boolean,
    altTooltipText?: React.ReactNode,
    extraButtons?: React.ReactNode
}

/**
 * This doesn't actually do any uploading... it simply shows a modal 
 * drop zone / uploader for the user and excutes a callback when a valid
 * file is dropped. The calling/parent component is responsible for uploading
 * the selected file.
 */
export const FileUploadModal = ({onCancelClick, maxFiles, onFilesDropped, uploading, accept, extraButtons,
                                    maxSizeMB, showDropZoneText, altTooltipText}:FileUploadModalProps) => {


    const buildAccept = () => {
        if (accept === 'images')
            return ['image/*'];
        if (accept === 'pdfimg')
            return ['image/*','.pdf'];
        if (accept === 'documents')
            return ['image/*','.pdf','.doc','.docx'];
        if (accept === 'csv') 
            return ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
        return;
    }

    const acceptMessage = () => {
        if (accept === 'images')
            return 'Image files only.';
        if (accept === 'documents')
            return 'Image files, PDFs or Word Documents only.'
        if (accept === 'csv')
            return 'CSV files only.'
        return;
    }

    const onDrop = useCallback((acceptedFiles:File[], fileRejections:FileRejection[]) => {
        if (onFilesDropped) onFilesDropped(acceptedFiles, fileRejections);
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone(
        {   onDrop, 
            multiple: maxFiles !== 1, 
            maxFiles: maxFiles, 
            accept: buildAccept(),
            maxSize: maxSizeMB ? (maxSizeMB*1024*1024) : undefined
        })
      
    const cancelClick = () => {
        if (onCancelClick) onCancelClick();
    }

    return (
        <div data-cy="fileUploadModal" className={styles.shim}>
            <div className={styles.outer}>
            <Card>
                <Card.Header>Upload file</Card.Header>
                <Card.Body>
                    <div {...getRootProps({className: styles.dropzone})}>
                        <input {...getInputProps()} />
                        <div className={styles.uploadIcon}>
                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                        </div>
                        <div className={styles.meta}>
                            {acceptMessage()}&nbsp;
                            {maxSizeMB && 
                                <>{maxSizeMB}MB max</>
                            }
                        </div>
                        {!uploading &&
                            <div>
                                Drop your file here < br/>
                                Or click to browse
                            </div>
                        }
                        {uploading &&
                            <div><FontAwesomeIcon icon={faSpinner} pulse /></div>
                        }
                    </div>
                    <div style={{marginBottom: '20px'}}>
                        {showDropZoneText &&
                            <p style={{textAlign: 'center'}}>
                                <i>
                                    {altTooltipText || "Tip: you can drag and drop your attachments directly onto the table row"}
                                </i>
                            </p>
                        } 
                    </div>
                    <div className="text-center">
                        {extraButtons}
                        <Button buttonType="close" onClick={cancelClick}>Cancel</Button>
                    </div>
                </Card.Body>
            </Card>
            </div>
        </div>
    )
}