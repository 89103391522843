import { useFormik } from 'formik';
import { useContext, useState } from 'react';
import { Onboarding } from '../api/inni/Onboarding';
import CompanyContext from '../context/CompanyContext';
import { useInniAPI } from './useInniAPI';
export interface IFinanceUploadTable {
	name: string;
	file: File | null;
	id: number;
	fileName: string;
	type?: 'Sale' | 'Purchase';
}

export interface ICSFile {
	fileToUpload: null | File;
	isNew: boolean;
	isUpdated: boolean;
	mortgageAccountId: number;
	lenderName?: string;
	mortgageDocumentId: number;
	fileName?: string;
}

interface ICSFull extends ICSFile {
	name: string;
	id: number;
}
export interface ICSUploadTable {
	files: ICSFile[];
	id: number;
	name: string;
	showNewBtn: boolean;
}
const validateUploadForm = (values: IFinanceUploadTable[]) => {
	const errors: { [key: string]: string } = {};
	values.forEach((v, i) => {
		if (!v.fileName) {
			errors[`[${i}].file`] = 'This field is required';
		}
	});
	return errors;
};

const validateUploadCSForm = (values: ICSUploadTable[]) => {
	const errors: { [key: string]: string } = {};
	values.forEach((v, i) => {
		if (!v.files.every((v) => v.fileName)) {
			errors[`[${i}].fileToUpload`] = 'This field is required';
		}
	});
	return errors;
};
export const useUploadDocumentsTable = () => {
	const { company } = useContext(CompanyContext);
	const onboardingApi = useInniAPI(Onboarding);
	const uploadTableFinanceFormik = useFormik({
		initialValues: [] as IFinanceUploadTable[],
		onSubmit: (values) => {},
		validate: validateUploadForm,
		validateOnMount: false,
	});
	const uploadMortgageDocumentsFormik = useFormik({
		initialValues: [] as ICSUploadTable[],
		onSubmit: (values) => {},
		validate: validateUploadCSForm,
		validateOnMount: false,
	});
	const [notifyAccountantsMessage, setNotifyAccountantsMessage] = useState('');

	const generateNotifyAccountMessage = (
		competitionStatements: IFinanceUploadTable[],
		mortgageDocuments: ICSFull[]
	) => {
		const message = `${
			notifyAccountantsMessage ? 'Message:\n ' + notifyAccountantsMessage + '\n ' : 'No message included. \n '
		}`;
		const countOfFiles = `${[...competitionStatements, ...mortgageDocuments].length} files uploaded\n `;
		const competitionStatementsMessage = competitionStatements.reduce(
			(acc, t) => acc + `Completion statement for ${t.name}\n `,
			''
		);
		const mortgageDocumentsMessage = mortgageDocuments.reduce(
			(acc, t) => acc + `Mortgage agreement/statement for ${t.name}\n `,
			''
		);
		return message + countOfFiles + competitionStatementsMessage + mortgageDocumentsMessage;
	};

	const validateSubmitPropertyRecords = () => {
		const csValidationPromise = uploadTableFinanceFormik.validateForm().then((values) => {
			uploadTableFinanceFormik.setErrors(values);
			return !Object.keys(values).length;
		});
		const mdValidationPromise = uploadMortgageDocumentsFormik.validateForm().then((values) => {
			uploadMortgageDocumentsFormik.setErrors(values);
			return !Object.keys(values).length;
		});

		return [csValidationPromise, mdValidationPromise];
	};
	const makePromiseUpload = (id: number, fileToUpload: File | null, isMortgage: boolean = false) => {
		return new Promise((resolve, reject) => {
			if (onboardingApi && company?.id && fileToUpload) {
				!isMortgage &&
					onboardingApi
						.uploadCompletionStatement(company.id, id, { fileToUpload })
						.then(resolve)
						.catch(reject);
				isMortgage &&
					onboardingApi.uploadMortgageStatement(company.id, id, { fileToUpload }).then(resolve).catch(reject);
			}
		});
	};

	const makePromiseReplaceStatement = (id: number, fileToUpload: File | null) => {
		return new Promise((resolve, reject) => {
			if (onboardingApi && company?.id && fileToUpload) {
				onboardingApi
					.editingUploadMortgageStatement(company.id, id, { fileToUpload })
					.then(resolve)
					.catch(reject);
			}
		});
	};

	const filterMortgagesWithFiles = () =>
		uploadMortgageDocumentsFormik.values
			.filter((v) => v.files.length)
			.map((v) => ({ ...v, files: v.files.filter((f) => f.fileToUpload) }));

	const mergeAllMortgages = (): ICSFull[] =>
		filterMortgagesWithFiles()
			.map(({ id, files, name }) => files.map((f) => ({ ...f, id, name })))
			.reduce((acc, files) => [...acc, ...files], []);

	const submitPropertyRecords = (onSuccess?: (canComplete: boolean) => void) => {
		if (onboardingApi && company?.id) {
			const competitionStatements = uploadTableFinanceFormik.values.filter((item) => item.file);
			const mergedMortgages = mergeAllMortgages();
			const csPromises = competitionStatements.map(({ id, file }) => makePromiseUpload(id, file));
			const mdPromises = mergedMortgages.map(({ id, fileToUpload, isUpdated, mortgageAccountId }) =>
				isUpdated
					? makePromiseReplaceStatement(mortgageAccountId, fileToUpload)
					: makePromiseUpload(id, fileToUpload, true)
			);
			const validations = validateSubmitPropertyRecords();

			Promise.all(validations)
				.then((validStatuses) => validStatuses.every((isValid) => isValid))
				.then((isValid) => {
					Promise.all([...csPromises, ...mdPromises])
						.then(() => {
							const message = generateNotifyAccountMessage(competitionStatements, mergedMortgages);
							return onboardingApi.notifyOfPropertyRecordsUpdate(company.id, { updateText: message });
						})
						.then(() => onSuccess && onSuccess(isValid));
				});
		}
	};

	return {
		uploadTableFinanceFormik,
		uploadMortgageDocumentsFormik,
		submitPropertyRecords,
		setNotifyAccountantsMessage,
		notifyAccountantsMessage,
	};
};
