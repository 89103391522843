import React from "react"
import { PieChart, PieChartValue } from "../../../elements/DashboardCards/PieChart"
import { SimpleKpi } from "../../../elements/DashboardCards/SimpleKpi"
import { formatCurrencyAbbreviated } from "../../../utils/formatNumbers"


export const ValueGrossKpi = ({loading, value} : {loading?: boolean, value?: number}) => {
    return (
        <SimpleKpi loading={loading} label='Gross value' value={formatCurrencyAbbreviated(value)} height={4} />
    )
}

export const ValueEquityKpi = ({loading, value} : {loading?: boolean, value?: number}) => {
    return (
        <SimpleKpi loading={loading} label='Equity' value={formatCurrencyAbbreviated(value)} helpText='Net value, after mortgage debt' height={4} />
    )
}

export const ValueGrossEquityPieChart = ({loading, gross, equity} : {loading?: boolean, gross?: number, equity?: number}) => {

    const chartData = () : Array<PieChartValue> => {
        return [
            {
              id: "equity",
              label: "Equity",
              value: equity || 0,
              colorName: 'success'
            },
            {
              id: "debt",
              label: "Mortgage / debt",
              value: (gross || 0) - (equity || 0),
              colorName: 'warning'
            }
          ]
    }

    return (
        <PieChart 
            loading={loading}
            label='Value' 
            calculateTotal 
            showLegend
            valueFormat={formatCurrencyAbbreviated}  
            data={chartData()}
            /> 
    )
    
}