import { useContext } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';
import { SbBlokData } from "@storyblok/react";
import InfoBanner from '../../InfoBanner/InfoBanner';
import { Button } from '../../../elements/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { PageMessages } from '../../../api/inni/PageMessages';
import { useInniAPI } from '../../../hooks/useInniAPI';
import CompanyContext, { CompanyProduct } from '../../../context/CompanyContext';

export interface iInfoBox extends SbBlokData {
    key: string,
    value: iInfoBoxVariant[],
    variants: {[key: string]: iInfoBoxVariant},
    type: 'info' | 'warning' | 'danger' | 'positive' | 'admin',
    hide: boolean
}

export interface iInfoBoxVariant extends SbBlokData {
    variant: string,
    title: string,
    body: string,
}

interface InfoBannerProps {
	blok: iInfoBox,
  variant?: string,
  setMessageRead?: (sbKey: string) => void,
  messageType?: 'onboarding'
};

const InfoBox = ({ blok, variant='default', setMessageRead, messageType='onboarding' }: InfoBannerProps) => {
  const pageMessages = useInniAPI(PageMessages);
  const companyContext = useContext(CompanyContext)

  const hideMessage = () => {
    if (pageMessages && setMessageRead && companyContext.product === CompanyProduct.isCap) {
      pageMessages.markAsRead(companyContext.cid, {storyBlokId: `info-boxes/${messageType}/` + blok.key}).then(res => {
        if (res.status === 200)
          setMessageRead(`info-boxes/${messageType}/` + blok.key);
      }).catch(error => {
        console.error(error);
      })
    }
  }

  return ( 
    <InfoBanner
      key={`infobanner_${blok._uid}`}
      type={render(blok.type)}
      title={render(blok.variants[variant].title)}
      body={
        <>
          {render(blok.variants[variant].body)}
          { blok.hide && (
            <Button small variant="primary" onClick={hideMessage}>
              <FontAwesomeIcon icon={faEyeSlash} />
              Hide
            </Button>
          )}
        </>
      }
		/>
  )
};
 
export default InfoBox;
