import { Form, Formik} from 'formik';
import { ListOption, SelectList, Submit, Text } from '../../../elements/EditRow/EditRow';
import { CompanyOnlineSystemSettingsPostModel} from '../../../api/inni/data-contracts';
import useCompareChange from '../../../hooks/useCompareChange';
import { CompanySettingsProps } from '../CompanySettingsDataTypes';

function OnlineSystemsTab<T extends CompanyOnlineSystemSettingsPostModel>({
    settings,
    handleSubmit,
    handleValidate
}: CompanySettingsProps<T>) {

  const vatLoginTypes: ListOption[] = [
    {value:'',label:'NA'},
    {value:'Own ',label:'Own login'},
    {value:'Inni',label:'iA Agent Login'}
  ];

  const formikRef = useCompareChange<T>();
  

  return (
    <Formik
    initialValues={settings as T}
    validateOnChange={false}
    enableReinitialize
    onSubmit={handleSubmit}
    validate={handleValidate}
    innerRef={formikRef}
    validateOnBlur
>
    {({ isSubmitting,dirty }) => (
        <Form>
          <Text<CompanyOnlineSystemSettingsPostModel> name="chSecurityCode" label="Companies House security code"/>
          <Text<CompanyOnlineSystemSettingsPostModel> name="chAuthCode" label="Companies House auth code"/>
          <Text<CompanyOnlineSystemSettingsPostModel> name="govGWCode" label="Government Gateway code"/>
          <Text<CompanyOnlineSystemSettingsPostModel> name="govGWPassword" label="Government Gateway password"/>
          <Text<CompanyOnlineSystemSettingsPostModel> name="vatUsername" label="VAT username"/>
          <Text<CompanyOnlineSystemSettingsPostModel> name="vatPassword" label="VAT password"/>
          {<SelectList<CompanyOnlineSystemSettingsPostModel> name="vatLoginType" label="VAT login type" options={vatLoginTypes}/>}
          <Submit disabled={isSubmitting || !dirty} onCancelClick={() => {}} />
        </Form>
    )}
</Formik>
  )
}


export default OnlineSystemsTab;
