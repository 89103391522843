import * as Tabs from '../../../elements/VerticalTabs/VerticalTabs';
import { PersonTabLoading } from './TabExtras';
import { Tabs as HorizontalTabs, TabLoading as HorizontalTabLoading } from '../../../elements/HorizontalTabs/HorizontalTabs';
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder';
import { Button } from '../../../elements/Button/Button';
import styles from "../People.module.scss"
import PersonCard from './PersonCard/PersonCard';

export const PeopleLoading = () => (
    <Tabs.Wrapper>
        <Tabs.Tabs onChangeTab={() => {}}>
            <PersonTabLoading />
            <PersonTabLoading />
            <PersonTabLoading />
        </Tabs.Tabs>

        <Tabs.Content>
            <Button variant="primary"><LoadingPlaceholder dark width="100px" /></Button>
            <HorizontalTabs>
                {/* We don't know exactly how many of these we are going to have range from 2 to infinite (we get one for each employment record)! */}
                <HorizontalTabLoading />
                <HorizontalTabLoading />
                <HorizontalTabLoading />
            </HorizontalTabs>
        </Tabs.Content>
    </Tabs.Wrapper>
)


export const PeopleLoadingCAPCompany = () => (
    <>
        <div className={styles.PeopleCards}>
                        
            <PersonCard 
                selected={true}
                personName={''}
                isLoading={true} 
            />
            <PersonCard 
                selected={false} 
                personName={''} 
                isLoading={true}                              
            />

        </div>
        <div style={{margin:"40px"}}>
            <Button variant="primary"><LoadingPlaceholder dark width="100px" /></Button>
            <HorizontalTabs>
                {/* We don't know exactly how many of these we are going to have range from 2 to infinite (we get one for each employment record)! */}
                <HorizontalTabLoading />
                <HorizontalTabLoading />
                <HorizontalTabLoading />
            </HorizontalTabs>
        </div>
    </>

)