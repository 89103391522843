export interface ImageIconOverlayProps {
  size: number;
  icon: string;
  dark?: boolean;
}

/**
 * Overlays an icon image over the image directly below it in top right corner (could be extended)
 * @param size - size in pixels
 * @param icon - icon file name relative 'to public/images'
 * @param dark - displays darker transparent background instead of light
 * @returns <img>
 */
const ImageIconOverlay = ({ size, icon, dark=false }: ImageIconOverlayProps) => {
  const sizePx: string = size + 'px';
  const bg: string = dark ? 'rgba(0, 0, 0, 0.045)' : 'rgba(255, 255, 255, 0.8)';

  return (
    <img
      className="d-flex align-items-center justify-content-center m-1 p-1"
      src={`./images/${icon}`}
      style={{
        position: 'absolute',
        right: 0,
        width: sizePx,
        height: sizePx,
        background: bg,
        borderRadius: '5px',
        zIndex: 1,
      }}
    />
  );
};

export default ImageIconOverlay;
