/**
 * @prettier
 */
import React, { useContext, useEffect } from 'react';
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';
import { Entity } from '../../utils/EntityAction';
import { Helmet } from 'react-helmet';
import styles from './DefaultLayout.module.scss';
import stylesV8 from './DefaultLayout_V75.module.scss';
import BrandContext from '../../context/BrandContext';
import { LoadingShim } from '../../elements/LoadingShim/LoadingShim';

type EmptyLayoutProps = {
  children: React.ReactNode;
  title?: string;
  entity?: Entity;
  loading?: boolean;
};

export const EmptyLayout = ({ children, title, entity, loading }: EmptyLayoutProps) => {
  const brand = useContext(BrandContext).brand;
  const companyContext = useContext(CompanyContext);
  const v8Styling = companyContext.company?.useV8UI;
  const styling = v8Styling ? stylesV8 : styles;

  useEffect(() => {
    if (companyContext.setActiveEntity) companyContext.setActiveEntity(entity);
  }, [entity, companyContext]);

  return (
    <>
      {title && (
        <Helmet>
          <title>{title ? `${title} - ${brand.name}` : brand.name}</title>
        </Helmet>
      )}
      <div id={styling.emptyLayout}>
        {loading && <LoadingShim />}
        {children}
      </div>
    </>
  );
};
