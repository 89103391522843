import { faUserTie, faCoins, faUsers } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Container } from 'react-bootstrap'
import styles from '../People.module.scss'
import * as Tabs from '../../../elements/VerticalTabs/VerticalTabs'
import { LoadingPlaceholder } from '../../../elements/LoadingPlaceholder/LoadingPlaceholder'

export const PersonTabLoading = () => (
    <Tabs.Tab keyName={"loading"}>
        <LoadingPlaceholder />
        <div className={styles.tabIconContainer}>
            {/* Hide or disable??? */}
            <FontAwesomeIcon icon={faUserTie} />
            <FontAwesomeIcon icon={faCoins} />
            <FontAwesomeIcon icon={faUsers} />
        </div>
    </Tabs.Tab>
)
