import PillTabs from "../../components/PillTabs/PillTabs";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout";
import { Tab } from "react-bootstrap";
import { Entity } from "../../utils/EntityAction";
import { Suspense, lazy, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CompanyContext from '../../context/CompanyContext';
import { useHistoryWrapper } from "../../hooks/useHistoryWrapper";
import styles from './CompanyInformation.module.scss';

const CompanyInfomation = lazy(() => import("./CompanySettings"));
const AssetPage = lazy(() => import("../Assets/AssetPage"));
const BillingPage = lazy(() => import("../Billing/BillingPage"));
const People = lazy(() => import("../People/People"));

interface TabItem {
    eventKey: string;
    title: string;
    adminOnly: boolean;
}

export enum CompanyInformationMenuEnum{
    COMPANY_INFORMATION = 'info',
    ASSET = 'assets',
    PEOPLE = 'people',
    BILLING = 'billing',
}

type  props={
    active:CompanyInformationMenuEnum;
    setExpandedView: (show: boolean) => void;
    showExpandedView: boolean;
}

interface RouteParams {
    activePage: string
}

export default function CompanyInformationMenu(){
    const activePage: string = useParams<RouteParams>().activePage
    const [activeTab, setActiveTab] = useState<CompanyInformationMenuEnum>(getTabName(activePage));
    const history = useHistoryWrapper();
    const companyContext = useContext(CompanyContext);
    const [showExpandedView, setShowExpandedView] = useState(false);

    const useV8Styling = companyContext.company?.useV8UI;

    useEffect(() =>{
        const tabName = getTabName(activePage);
        setActiveTab(tabName);
    }, [activePage])
    
    function GetMenu() {
        let tabs: Array<TabItem>= [];
        if(companyContext && companyContext.navMenu && companyContext.navMenu.navItems){
            companyContext.navMenu.navItems.find(x => x.name === "Company info")?.v8TopNavTabs?.forEach((item) => {
                tabs.push({ eventKey: getTabName(item.name?.replaceAll(" ", "").toLowerCase() || ""), 
                            title: item.name || "", 
                            adminOnly: item.isAdminOnly 
                        });
            }); 
        }
        return tabs;
    }

    const handleTabSelect = (value: string | null ) => {
        let selectedTab = getTabName(value || "info" );
        history.push(`/company/${companyContext.cid}/companyinfo/${selectedTab}`);
    };

    function getTabName(value: string): CompanyInformationMenuEnum {
        if( Object.values(CompanyInformationMenuEnum).includes(value as CompanyInformationMenuEnum))
            return (value as CompanyInformationMenuEnum)
        else
            return CompanyInformationMenuEnum.COMPANY_INFORMATION          
    }

    return(
        <DefaultLayout
            title={showExpandedView ? undefined : 'Company info'}
            entity={Entity.CompanySettingsV7}
            useFullWidth={!useV8Styling}
        >
            { !showExpandedView && (
                <PillTabs defaultActiveKey={activeTab} onSelect={handleTabSelect} className='m-0'>
                    { GetMenu().map((tab) =>
                        <Tab 
                            key={tab.eventKey} 
                            eventKey={tab.eventKey} 
                            title={tab.adminOnly ? 
                                        <span className={styles.adminOnly} onClick={(e) => e.preventDefault()}>{tab.title}</span> 
                                    : 
                                        tab.title
                                    }
                        />
                    )}
                </PillTabs> 
            )}
            <MenuList active={activeTab} setExpandedView={setShowExpandedView} showExpandedView={showExpandedView}/>
        </DefaultLayout>
    );
}


export function MenuList({active, setExpandedView, showExpandedView}:props){

    return (
        <>
         {active===CompanyInformationMenuEnum.COMPANY_INFORMATION &&
                <Suspense fallback={null}>
                    <CompanyInfomation setExpandedView={setExpandedView} showExpandedView={showExpandedView}/>    
                </Suspense>
            }
            {active===CompanyInformationMenuEnum.ASSET &&
                <Suspense fallback={null}>
                    <AssetPage setExpandedView={setExpandedView} showExpandedView={showExpandedView}/>
                </Suspense>
            }
             {active===CompanyInformationMenuEnum.PEOPLE &&
                <Suspense fallback={null}>
                    <People/> 
                </Suspense>
            }
            {active===CompanyInformationMenuEnum.BILLING &&
                <Suspense fallback={null}>
                    <BillingPage/>
                </Suspense>
            }
        </>
    );
}