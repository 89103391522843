/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Consultation, ConsultationPostModel, ConsultationsSummary } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class CompanyConsultations<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags CompanyConsultations
   * @name Summary
   * @summary For the "get help page we need to know a "consulation status" - this is NOT admin code - consultations sits awkwardly...
   * @request GET:/api/v2/companies/{companyId}/consultations/Summary
   * @secure
   */
  summary = (companyId: number, params: RequestParams = {}) =>
    this.request<ConsultationsSummary, any>({
      path: `/api/v2/companies/${companyId}/consultations/Summary`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanyConsultations
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/consultations
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Consultation[], any>({
      path: `/api/v2/companies/${companyId}/consultations`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanyConsultations
   * @name Create
   * @request POST:/api/v2/companies/{companyId}/consultations
   * @secure
   */
  create = (companyId: number, postModel: ConsultationPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/consultations`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanyConsultations
   * @name Get
   * @request GET:/api/v2/companies/{companyId}/consultations/{consultationId}
   * @secure
   */
  get = (companyId: number, consultationId: number, params: RequestParams = {}) =>
    this.request<Consultation, any>({
      path: `/api/v2/companies/${companyId}/consultations/${consultationId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanyConsultations
   * @name Update
   * @request PUT:/api/v2/companies/{companyId}/consultations/{consultationId}
   * @secure
   */
  update = (companyId: number, consultationId: number, postModel: ConsultationPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/consultations/${consultationId}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanyConsultations
   * @name Delete
   * @request DELETE:/api/v2/companies/{companyId}/consultations/{consultationId}
   * @secure
   */
  delete = (companyId: number, consultationId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/consultations/${consultationId}`,
      method: "DELETE",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanyConsultations
   * @name ValidateUpdate
   * @request PUT:/api/v2/companies/{companyId}/consultations/{ConsultationId}/Validate
   * @secure
   */
  validateUpdate = (
    companyId: number,
    consultationId: number,
    postmodel: ConsultationPostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/consultations/${consultationId}/Validate`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags CompanyConsultations
   * @name ValidateCreate
   * @request POST:/api/v2/companies/{companyId}/consultations/Validate
   * @secure
   */
  validateCreate = (companyId: number, postmodel: ConsultationPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/consultations/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
