import React, { useContext, useEffect, useState } from 'react';
import { Companies } from '../../api/inni/Companies';
import { CompanySummary } from '../../api/inni/data-contracts';
import CompanyContext from '../../context/CompanyContext';
import UserContext from '../../context/UserContext';
import { useInniAPI } from '../../hooks/useInniAPI';
import styles from './DesktopWrapper.module.css';
import { TopBar } from '../Components/TopBar';
import { useHasPermission } from '../../hooks/useHasPermission';
import { Entity, Action } from '../../utils/EntityAction';
import { SideBarV8, SideBar } from '../Components/SideBar';

type DesktopWrapperProps = {
    children?: React.ReactNode
}


export const DesktopWrapper = ({ children }: DesktopWrapperProps) => {
    const userContext = useContext(UserContext);
    const companyContext = useContext(CompanyContext);

    // If user has admin 'search companies' permission don't
    // download all companies in favour of searching
    const hasPermission = useHasPermission();
    const hasSearchCompanies = hasPermission(Entity.Company, Action.Search)[1];
    const showCustomErrorMessage = hasPermission(Entity.ErrorMessage, Action.All)[0];

    const companiesApi = useInniAPI(Companies);
    const [companies, setCompanies] = useState<Array<CompanySummary>>([]);
    const [companiesLoaded, setCompaniesLoaded] = useState(false);

    const [sideBarCollapsed, setSideBarCollapsed] = useState(false);


    useEffect(() => {
        if (!hasSearchCompanies && companiesApi && !companiesLoaded) {
            setCompaniesLoaded(true);
            companiesApi.index().then(response => {
                setCompanies(response.data);
            })
        }

    }, [companiesApi, companiesLoaded, hasSearchCompanies])

    const toggleSidebar = () => {
        setSideBarCollapsed(!sideBarCollapsed)
    }

    const clearErrorMessage = (e?: React.MouseEvent<HTMLElement>, preventDefault: boolean = false) => {
        if (userContext.clearErrorMessage) userContext.clearErrorMessage();
        if (e && preventDefault)
            e.preventDefault();
    }

    return (
        <div id={styles.desktopWrapper}>
            {/* Error bar */}
            {userContext.errorMessage &&
                <div id={styles.alertBar}>
                    {showCustomErrorMessage && userContext.customErrorMessage ? 
                            <div className={styles.error}>
                                <strong>{userContext.customErrorMessage}</strong>&nbsp;<a href="javascript:void(0);" onClick={clearErrorMessage}>[hide this message]</a>
                            </div>
                        :
                            <div className={styles.error}>
                                <strong>We're sorry, something went wrong.</strong>&nbsp;{userContext.errorMessage}&nbsp;<a href="javascript:void(0);" onClick={clearErrorMessage}>[hide this message]</a>
                            </div>
                    }
                </div>
            }

            {/* Top bar */}
            {!companyContext.company?.useV8UI &&
                <div id={styles.topBar} className={userContext.errorMessage ? styles.hasError : undefined}>
                    <TopBar
                        user={userContext.user}
                        company={companyContext.company}
                        companies={companies}
                        contextLevel={companyContext.cid === -1 ? 'contact' : 'company'}
                        hasSearchCompanies={hasSearchCompanies}
                        logout={userContext.logout}
                        toggleSidebar={toggleSidebar}
                        useMobileLayout={false}
                    />
                </div>
            }

            {/* Side bar */}
            { (companyContext.navMenu && companyContext.navMenu.navItems) && (
                <div id={companyContext.navMenu.useV8Menu ? styles.contentWrapperV8 : styles.contentWrapper}>
                    <div id={companyContext.navMenu.useV8Menu ? styles.sideBarV8 : styles.sideBar}>
                        { companyContext.navMenu.menuStyle === 'V8'
                            ? (
                                <SideBarV8
                                    collapsed={sideBarCollapsed}
                                    onClickCb={clearErrorMessage}
                                    logout={userContext.logout}
                                    useMobileLayout={false}
                                    user={userContext.user}
                                    company={companyContext.company}
                                    hasSearchCompanies={hasSearchCompanies}
                                    companies={companies}
                                    contextLevel={companyContext.cid === -1 ? 'contact' : 'company'}
                                    sideBarEntries={companyContext.navMenu.navItems}
                                />
                            )
                            : (
                                <SideBar
                                    collapsed={sideBarCollapsed}
                                    onClickCb={clearErrorMessage}
                                    useMobileLayout={false}
                                    sideBarEntries={companyContext.navMenu.navItems}
                                />
                            )
                        }
                    </div>
                    <div id={companyContext.company?.useV8UI ? styles.contentV8 : styles.content }>
                        {children}
                    </div>
                </div>
            )}
        </div>
    )
}