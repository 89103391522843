import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope } from '@fortawesome/pro-regular-svg-icons'
import { SimpleTerm, useSimpleTerms } from '../../hooks/terms/useSimpleTerms'
import { Card } from 'react-bootstrap';

const Details = () => {
  const simpleTerms = useSimpleTerms()

  return (
    <>
      <p className="mb-2">If you have questions or need assistance, we're here to help.</p>
      <div className="d-inline-block mr-4">
          <FontAwesomeIcon icon={faPhoneAlt} className="mr-2" />
          <span>{simpleTerms(SimpleTerm.AppContactPhone)}</span>
      </div>
      <div className="d-inline-block">
          <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
          <span><a href={`mailto:${simpleTerms(SimpleTerm.AppContactEmail)}`}>{simpleTerms(SimpleTerm.AppContactEmail)}</a></span>
      </div>
    </>
  )

}

const ContactDetails = ({card}: {card?:boolean}) => {
    return (
        <div className="w-100 text-center p-2">
            { card 
              ? (
                <Card className="d-inline-block py-3 px-4 shadow-sm">
                  <Details />
                </Card>
              )
              : <Details />
            }
        </div>
    )
}

export default ContactDetails
