import { differenceInYears, format, isValid } from 'date-fns';

// "You Probably Don't Need Moment.js Anymore"
// https://dockyard.com/blog/2020/02/14/you-probably-don-t-need-moment-js-anymore
export function formatDate(date: string | undefined):string {
    if (date) {
        return new Date(date).toLocaleDateString(
            'en-gb'
          );    
    } else return '';
}

export function formatDateTime(date: string | undefined):string {
    if (date) {
        const fullStr = new Date(date).toLocaleString('en-gb');    
        return fullStr.substring(0, fullStr.lastIndexOf(':'));
    } else return '';
}

const basicDateCheck = /^(((\d{2})(-|\/)\d{2}(-|\/)([1-9])(\d{3}))|(([1-9])(\d{3})(-|\/)\d{2}(-|\/)(\d{2})))$/ // 00/00/1000 or 1000/00/00 (Dashes also work) (NO 0 AT START OF YEARS)

//Note: asHtml5Date can fail on dates which aren't formatted as yyyy-mm-dd on some browsers
export const asHtml5Date = (date:string|undefined) => {
    if (date && basicDateCheck.test(date.split("T")[0]) && isValid(new Date(date))) {
        return asYearMonthDay(new Date(date))
    }
    return date;
}

export const dateIsValid = (date:string|undefined) => {
    return date !== undefined && basicDateCheck.test(date.split("T")[0]) && isValid(new Date(date))
}

export function formatNaturalLanguageDate(date: string | undefined):string {
    if(date){
        const convertedDate= formatDate(date)
        const [day, month, year] = convertedDate.split('/').map(Number)
        return `${day}${nth(day)} ${getMonthFromNum(month-1)} ${year}`
    }else return ''
}

export const nth = (d:number) => {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
}

export const getTimeDifference = (dt1: Date, dt2: Date, type: 'years') => {
    const timeDiff = dt1.getTime() - dt2.getTime();
    switch (type) {
        case 'years':
            return Math.floor(timeDiff / (1000 * 3600 * 24 * 365.25));
        default:
            return null;
    }
}

//Starts at 0
export const getShortMonthFromNum = (num:number) => {
    switch(num) {
        case 0: return "Jan"
        case 1: return "Feb"
        case 2: return "Mar"
        case 3: return "Apr"
        case 4: return "May"
        case 5: return "Jun"
        case 6: return "Jul"
        case 7: return "Aug"
        case 8: return "Sep"
        case 9: return "Oct"
        case 10: return "Nov"
        case 11: return "Dec"
    }
}

//Starts at 0
export const getMonthFromNum = (num:number) => {
    switch(num) {
        case 0: return "January"
        case 1: return "February"
        case 2: return "March"
        case 3: return "April"
        case 4: return "May"
        case 5: return "June"
        case 6: return "July"
        case 7: return "August"
        case 8: return "September"
        case 9: return "October"
        case 10: return "November"
        case 11: return "December"
    }
}

const adjustDateForTimezone = (date:Date) => {
    //Timezone offset to avoid 00:00 on the 5th locally becoming 23:00 on the 4th on the server
    //ISOStrings are ALWAYS UTC, but we send a local time (BST) so we cheat by subtracting the offset so that UTC "Time" is the same as teh local "time"
    //Doing this with datepart etc would require fewer comments but more code :)
    const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    return new Date(date.getTime() + tzoffset)
}

//Drop the time component and return in the format yyyy-MM-dd
//Uses local time (Does not convert to UTC)
//Though the source date can still be wrong due to timezones and all javascript date objects being UTC, so we need to account for that
export const asYearMonthDay = (date:Date) => {
    //If we're at 0 hours don't adjust the timezone, we probably adjusted it already!
    return format(date.getHours() === 0 ? date : adjustDateForTimezone(date), "yyyy-MM-dd")
}

export const isSameOrBefore = (date1: Date, date2: Date) => differenceInYears(date1, date2) <= 0;