import { faCalendarAlt, faTag } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { Document } from '../../../../api/inni/data-contracts';
import { Button } from '../../../../elements/Button/Button';
import FileIcon, { IconType } from '../../../../elements/FileIcon/FileIcon';
import { formatDate } from '../../../../utils/formatDate';
import { formatFileSize } from '../../../../utils/formatters/formatSize';
import { DocumentActions } from '../../DocumentsPage';
import { trimString } from '../../helperfunctions/CountStringsinArray';
import styles from './FileDetailsCard.module.css';
import { memo, useCallback, useContext } from 'react';
import CompanyContext, { CompanyProduct } from '../../../../context/CompanyContext';

type FilesLayOutProps = {
	documents: Document[];
	onClick: (id: number, action: DocumentActions) => void;
	isAdmin: boolean;
	isAdminDecorated: boolean;
};

type FileDetailsCardProps = {
	document: Document;
	onClick: (id: number, action: DocumentActions) => void;
	isAdmin: boolean;
	isAdminDecorated: boolean;
}
function FilesLayOut({ documents, onClick, isAdmin, isAdminDecorated }: FilesLayOutProps) {

	const FileDetailsCardMemo = memo(FileDetailsCard, (prevProps, nextProps) => {
		return prevProps.document.id === nextProps.document.id;
		});

	return (
		<>
			<div className={styles.filesLayout}>
				{documents.map((document) => (
						<FileDetailsCardMemo
							key={document.id}
							document={document}
							onClick={onClick}
							isAdmin={isAdmin}
							isAdminDecorated={isAdminDecorated}
						/>
				))}
			</div>
		</>
	);
}

function FileDetailsCard({document,onClick,isAdmin,isAdminDecorated }: FileDetailsCardProps) {
	const companyContext = useContext(CompanyContext);
	const v75Styling = (companyContext.company?.useV8UI)

	const handleDoubleClick = useCallback(() =>{
		if(document.canEditPermissions && !document.isDeleted){
			onClick(document.id, DocumentActions.Edit)
		}
	},[document,onClick]);

	const handleGoogledriveClick = useCallback(() => {
		onClick(document.id, DocumentActions.GoogleDrive);
	}, [document, onClick]);

	const handleDropboxClick = useCallback(() => {
		onClick(document.id, DocumentActions.DropBox);
	}, [document, onClick]);

	const handleCopyLinkClick = useCallback(() => {
		onClick(document.id, DocumentActions.Copy);
	}, [document, onClick]);

	const handleDownloadClick = useCallback(() => {
		onClick(document.id, DocumentActions.Download);
	}, [document, onClick]);

	const handleDeleteClick = useCallback(() => {
		onClick(document.id, DocumentActions.Delete);
	}, [document, onClick]);

	const handleRestoreClick = useCallback(() => {
		onClick(document.id, DocumentActions.Restore);
	}, [document, onClick]);

	const handleEditClick = useCallback(() => {
		onClick(document.id, DocumentActions.Edit);
	}, [document, onClick]);

	return (
		<div
			className={
				classNames(
					styles.fileDetailsCard,
					document.permissionClientRead && isAdmin ? styles.clientAccessCard : '',
					{[styles.fileDetailsCardV75]: v75Styling}
				)}
			onDoubleClick={handleDoubleClick}>
			<div className={styles.fileDetailsBody}>
				<div className="d-flex h-100">
					<div className={styles.iconWrapper}>
						<div className="p-3">
							<FileIcon iconType={(document.documentExtension as IconType) || 'file'} />
							<div className={styles.iconWrapperText}>
								{formatDate(document.dateUpdated)}
								<br></br>
								{formatFileSize(document.documentSize)}
							</div>
						</div>
					</div>
					<div className={styles.detailsWrapper}>
						<div className={styles.fileName}>{trimString(document.documentName)}</div>
						<div className={styles.fileType}>
							<>
								{(document.taxYearName || document.financialYearName) && (
									<>
										<FontAwesomeIcon icon={faCalendarAlt} />
										<span className="text-muted">&nbsp;{document.taxYearName || document.financialYearName}</span>
									</>
								)}
							</>
							<div className="d-flex">
								<FontAwesomeIcon icon={faTag} className="mt-1" size="1x" /> &nbsp;
								{document.tags && (document.tags.length === 0 || document.tags === null) ? (
									<span className="font-italic"> No labels </span>
								) : <div className={classNames(styles.labelsText,'text-muted')}>
									{
										document.tags
											?.filter((x) => (!isAdmin ? x !== 'Client Visible' : true)).join(',')
									}
								</div>}
							</div>
						</div>
						<div className={classNames(styles.fileSource, document.permissionClientRead ? styles.clientAccess : styles.adminAccess)}>
							{isAdmin && <span>{document.permissionClientRead ? 'Client can access' : 'Private'}</span>}
						</div>
						<div className={styles.downloadButtonGroup}>
							<Button
								small
								iconOnly
								variant="secondary"
								buttonType="download"
								tooltipMessage="Download file"
								onClick={handleDownloadClick}></Button>
							<Button
								small
								iconOnly
								variant="secondary"
								tooltipMessage="Dowload to google drive"
								onClick={handleGoogledriveClick}>
								{<CreateSVGIcon icon="googleDrive" />}
							</Button>
							<Button
								small
								iconOnly
								variant="secondary"
								tooltipMessage="Dowload to dropbox"
								onClick={handleDropboxClick}>
								{<CreateSVGIcon icon="dropBox" />}
							</Button>
							<Button
								small
								iconOnly
								buttonType="copy"
								variant="secondary"
								tooltipMessage="Copy the file"
								onClick={handleCopyLinkClick}></Button>
							{document.canEditPermissions && (
								<div className={classNames(styles.actionButtonGroup, 'mt-2')}>
									{!document.isDeleted && document.canEdit && (
										<Button
											onClick={handleEditClick}
											small
											buttonType="edit"
											tooltipMessage={document.canEdit ? 'Edit file' : ''}
											disabled={!document.canEdit || !document.canEditPermissions}
											disabledMessage={!document.canEdit ? 'File cannot be edited' : ''}
											iconOnly
											admin={isAdminDecorated}></Button>
									)}
									{document.isDeleted && (
										<Button
											onClick={handleRestoreClick}
											small
											buttonType="restore"
											tooltipMessage="Restore the document"
											iconOnly
											admin={isAdminDecorated}></Button>
									)}
									{(document.canDelete && !document.isDeleted)&& (
										<Button
											onClick={handleDeleteClick}
											small
											buttonType="delete"
											tooltipMessage={document.canDelete ? 'Delete File' : ''}
											disabledMessage={!document.canDelete ? 'File cannot be deleted' : ''}
											disabled={!document.canDelete}
											iconOnly
											admin={isAdminDecorated}></Button>
									)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

const CreateSVGIcon = ({ icon }: { icon: 'googleDrive' | 'dropBox' }) => {
	// Not available in our version of font awesome
	switch (icon) {
		case 'googleDrive':
			return (
				<svg fill='#fff' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height={12} width={12}>
					<path d="M339 314.9L175.4 32h161.2l163.6 282.9H339zm-137.5 23.6L120.9 480h310.5L512 338.5H201.5zM154.1 67.4L0 338.5 80.6 480 237 208.8 154.1 67.4z" />
				</svg>
			);
		case 'dropBox':
			return (
				<svg fill='#fff' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 528 512" height={12} width={12}>
					<path
						d="M264.4 116.3l-132 84.3 132 84.3-132 84.3L0 284.1l132.3-84.3L0 116.3 132.3 32l132.1 84.3zM131.6 395.7l132-84.3 132 84.3-132
                     84.3-132-84.3zm132.8-111.6l132-84.3-132-83.6L395.7 32 528 116.3l-132.3 84.3L528 284.8l-132.3 84.3-131.3-85z"
					/>
				</svg>
			);
		default:
			return null;
	}
};

export default FilesLayOut;
