import { useContext, useState, useEffect } from "react";
import CompanyContext, { CompanyProduct } from "../../context/CompanyContext";
import { useInniAPI } from "../../hooks/useInniAPI";
import { formatCurrency } from "../../utils/formatNumbers";
import { useAssertPermission } from "../../hooks/useAssertPermission";
import { Action, Entity } from "../../utils/EntityAction";
import { formatDate } from "../../utils/formatDate";
import {CorporationTax} from '../../api/inni/CorporationTax'
import { AccountingPeriod, CorporationTaxDetails, Market, ReportItem } from "../../api/inni/data-contracts";
import classNames from "classnames";
import React from "react";
import styles from './CorporationTax.module.scss'
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout";
import { BrandWrapper } from "../../components/BrandWrapper/BrandWrapper";
import { AccountingPeriods } from "../../api/inni/AccountingPeriods";
import FinancialYearSelector from "./Components/FinancialYearSelector";
import LoadingCT from "./Components/LoadingCT";
import EmptySlateCT from "./Components/EmptySlateCT";
import InfoBanner from "../../elements/InfoBanner/InfoBanner";
import { Markets } from "../../api/inni/Markets";
import {useHasPermission} from "../../hooks/useHasPermission";

const CorporationTaxReport = () => {
	useAssertPermission(Entity.CorporationTaxV7, Action.List);
	const CorporationTaxAPI = useInniAPI(CorporationTax);
	const accountingPeriodAPI = useInniAPI(AccountingPeriods);
	const companyContext = useContext(CompanyContext);
	const [isLoaded, setIsLoaded] = useState(false);
	const [periodID, setPeriodID] = useState<number|undefined>();
	const [data, setData] = useState<CorporationTaxDetails|undefined>(undefined)
	const [fyLoaded, setFYLoaded] = useState(false);
	const [accountingPeriods, setAccountingPeriods] = useState<AccountingPeriod[]|undefined>(undefined);

	const hasPermission = useHasPermission();
	const haveAccountManager= hasPermission(Entity.AccountManagerSupport, Action.All)[0];
	
	const v8Styling = companyContext.company?.useV8UI || false;
	const [market, setMarkets] = useState<Market>();
	const marketAPI = useInniAPI(Markets);

	useEffect(() => {
		if (CorporationTaxAPI && periodID) {
			setIsLoaded(false)
			CorporationTaxAPI.getDetails(companyContext.cid, {accountingPeriodId: periodID })
			.then((res) => {
					setData(res.data);
					setIsLoaded(true);
			})
		}
	}, [CorporationTaxAPI, companyContext.cid, periodID]);

	useEffect(() => {
        if (accountingPeriodAPI) {
            accountingPeriodAPI?.index(companyContext.cid)
            .then(response => {
                let sorted = response.data.sort((a,b) => a.dateFrom > b.dateFrom ? -1 : 1)
                if (sorted && sorted[0]) {
                    setAccountingPeriods(sorted);  
                    setPeriodID(sorted[0].id); 
                    setFYLoaded(true);                           
                }
            })
        }
    }, [accountingPeriodAPI, companyContext.cid])

	useEffect(() => {
		if (marketAPI) {
		  marketAPI?.index(companyContext.cid).then((response) => {
			setMarkets(response.data);
		  });
		}
	  }, [companyContext.cid, marketAPI]);

	const switchAccountingPeriod = (val: number | undefined) => {
		setPeriodID(val);
	};

	const recursiveBreakdown = (reportItem: ReportItem, level: number, key: string) => {
        return <div style={{borderBottom: '1px solid lightgrey'}} key={key}>
			{level === 0 &&
				<h1 className={styles.reportTitle}>
					{reportItem.name === "Assessable Profit" ? "Profit" : "Corporation tax"}
				</h1>

			}
            <div className={classNames(styles.reportItemParent)}  key={`${key}-${reportItem.id}`}>
				{React.createElement(`h${level===0 ? 3 : 2}`,{ className: classNames(styles[`level-${level}`], styles.flexGrow), key: key}, 
					<>
						{reportItem.name}
						<span className={styles.floatRight}>{formatCurrency(reportItem.amountWithChildrenDisplay)}</span>
					</>
				)}
            </div>

			{ reportItem.children && 
				reportItem.children.map((item, index) => (
					(item.amountWithChildrenDisplay !==0 || level <1) &&
						<React.Fragment key={`${key}-${item.id}`}>
							{recursiveBreakdown(item, level + 1, `${key}-${item.id}`)}
						</React.Fragment>
				))
			}
        </div>
    }

	
	return (
		<DefaultLayout
			entity={Entity.CorporationTaxV7}
			title="Corporation tax"
			subLayout={v8Styling}
		>
			<BrandWrapper>

				<div className={styles.selectFYYear}>
					<FinancialYearSelector
						options={accountingPeriods?.map((item)=>({value:item.id.toString(), label:"FY to end "+ formatDate(item.dateTo)}))}
						setFilter={switchAccountingPeriod}
						placeHolder="Select period ...."  
						isLoaded={fyLoaded}
					/>
				</div>
				
				{ isLoaded && data?.report && data.report.length > 0 && 
					<>	
						{new Date(data.dateTo) > new Date("2023-01-04") && (
							data.numAdditionalCompanies === 0 ? (
								<InfoBanner type="info" 
									title={"Any additional companies?"}
									body= {
										<div>
											The corporation tax calculation assumes that your company has no {market?.isProperty ? "associated companies" : <a target="_blank" rel="noreferrer" href="https://www.inniaccounts.co.uk/knowledge-hub/article/corporation-tax-changes/">associated companies</a>}. If this is incorrect then the corporation tax forecast may be understated.
											{ haveAccountManager &&  
												<p>
													Please get in touch with your account manager if you are involved in any other companies that are under the same control as {companyContext.company?.name}.
												</p>
											}
										</div>
									}
								/>
							) : (
								<InfoBanner type="info" 
									title={`${data.numAdditionalCompanies} additional ${(data.numAdditionalCompanies || 0) > 1 ? "companies" : "company"}`}
									body= {
										<div>
											You have told us that you have {data.numAdditionalCompanies} associated {(data.numAdditionalCompanies ||0) > 1 ? "companies" : "company"} - this affects the corporation tax calculation. { haveAccountManager && <span>Please contact your account manager if you believe this is incorrect.</span>} {!market?.isProperty && <span>For more details please <a target="_blank" rel="noreferrer" href="https://www.inniaccounts.co.uk/knowledge-hub/article/corporation-tax-changes/">click here</a>.</span>}
										</div>
									}
								/>
							)
						)}

						<InfoBanner type="info"
							body= {
								<div>
									This is your corporation tax forecast for the financial year {formatDate(data.dateFrom)} to {formatDate(data.dateTo)}. {' '}
									{data.isFirstPayment ?
											<>
												As this is your first year of trading, most of your corporation tax will be due on {formatDate(data.paymentDate)}.
											</>
										:
											<>
												Payment will be due on {formatDate(data.paymentDate)}.
											</>
									}
								</div>
							}
							thin
						/>
						
						{
							data.report.map((report) => (
								<div className="mb-5" key={`container-${report.id}`}>
									{ recursiveBreakdown(report, 0, `${report.id}`) }
								</div>
							))
						}
					</>
				}

				{ !isLoaded &&
					<div>
						{Array(2)
							.fill(0)
							.map((_, index) => (
								<LoadingCT key={index}/>
						))}
					</div>
				}

				{ isLoaded && !accountingPeriods && !data?.report &&
					<EmptySlateCT/>
				}

			</BrandWrapper>
		</DefaultLayout>
	);
};

export default CorporationTaxReport;
