import React, {useEffect, useState} from 'react'
import UserContext, { User } from '../../context/UserContext';



type UserWrapperProps = {
    children?: React.ReactNode,
    getAccessToken: () => Promise<[string, Date | null] | null>,
    logout: () => Promise<void>,
    firstName: string,
    lastName: string,
    fullName: string,
    email: string,
    oid: string
    hasAMSupport?: boolean
}

export const UserWrapper = ({firstName, lastName, fullName, email, oid, getAccessToken, logout, children, hasAMSupport} : UserWrapperProps) => {
 
    const [user, setUser] = useState<User | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string|undefined>(undefined);
    const [customErrorMessage, setCustomErrorMessage] = useState<string|undefined>(undefined);

    useEffect(() => {
        setUser({
            contactId: 0,  // id 0 is understood by backend as logged in user
            firstName: firstName,
            lastName: lastName,
            fullName: fullName,
            email: email,
            oid: oid 
        })
    }, [firstName, lastName, fullName, email, oid]);

    const error404 = 'We could not find that item.' + hasAMSupport ? ' Please contact your account manager. (Error 404)' : ' (Error 404)'
    const error423 = 'Your account has been locked.' + hasAMSupport ? ' Please contact your account manager. (Error 423)' : ' (Error 423)'
    
    const defaultError = (errorStatus:number) => {
        if(hasAMSupport) {
            return `Please try again and if it continues, please contact your account manager. (Error ${errorStatus})`
        }
        return `Please try again later (Error ${errorStatus}).`
    }

    const displayHttpResponseError = (error: Response) => {
        const convertedError = error as any;
        
        if (convertedError.hasOwnProperty('error')) {
            const customMessageFull = `${convertedError["error"]["message"] || ''} ${convertedError["error"]["exceptionMessage"] || ''}`
            
            const customMessage = customMessageFull.trim() !== '' ? customMessageFull : convertedError["error"]

            if (customMessage)
                setCustomErrorMessage(`${customMessage.toString()} (Error ${error.status})`)
        }

        switch (error.status) {
            case 400:
                // User correctable error
                setErrorMessage('There was an error saving your data. Please check your entry and try again. (Error 400)')
                break;
            case 401:
                setErrorMessage('You are not logged in. Please refresh this page and try again. (Error 401)')
                break;
            case 404:
                setErrorMessage(error404)
                break;
            case 423:
                setErrorMessage(error423)
                break;
            default:
                // Some other error, including 500
                setErrorMessage(defaultError(error.status))
        }
       
        console.error(error)
    }

    const clearErrorMessage = () => {
        setErrorMessage(undefined)
        setCustomErrorMessage(undefined)
    }

    if (user) {
        return (
            <UserContext.Provider value={{
                user: user,
                getAccessToken: getAccessToken,
                logout: logout,
                displayHttpResponseError: displayHttpResponseError,
                errorMessage: errorMessage,
                customErrorMessage: customErrorMessage,
                clearErrorMessage: clearErrorMessage
            }}>
                {children}
            </UserContext.Provider>
        )
    } else {
        return null;
    }



}