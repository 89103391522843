import { useContext, useEffect, useState } from "react";
import { Assets } from "../../api/inni/Assets";
import { Asset, AssetChangetoExpense, AssetPostModel, Market } from "../../api/inni/data-contracts";
import CompanyContext, { CompanyProduct } from "../../context/CompanyContext";
import * as DataGrid from "../../elements/DataGrid/DataGrid";
import { useInniAPI } from "../../hooks/useInniAPI";
import { DefaultLayout } from "../../layouts/Desktop/DefaultLayout";
import { Action, Entity } from "../../utils/EntityAction";
import { Markets } from "../../api/inni/Markets";
import { AssetCreateModal } from "./CreateAsset";
import { Button } from "../../elements/Button/Button";
import { useHasPermission } from "../../hooks/useHasPermission";
import { Badge } from "react-bootstrap";
import { formatDate } from "../../utils/formatDate";
import { formatCurrency } from "../../utils/formatNumbers";
import { FormikHelpers } from "formik";
import { DatagridType } from "../../hooks/terms/useDatagridTerms";
import { NoContentSlate } from "../../elements/Slates/NoContentSlate";
import { AssetAsDetailed } from "../../api/inni/data-contracts";
import { AssetReadModal } from "./ReadAsset";
import InfoBanner from "../../elements/InfoBanner/InfoBanner";
import Toolbar from "../../layouts/Desktop/Toolbar";
import BlankStateCard from "../../components/BlankStateCard/BlankStateCard";
import { SbBlokData, getStoryblokApi } from "@storyblok/react";
import ViewAsset from "./components/ViewAssetV8";
import { useNavigateToEntity } from "../../hooks/useNavigateToEntity";
import EditAsset from "./components/EditAssetV8";
import { BrandWrapper } from "../../components/BrandWrapper/BrandWrapper";

interface AssetProps{
  showExpandedView?: boolean;
  setExpandedView?: (show: boolean) => void
}

function AssetPage({showExpandedView, setExpandedView}: AssetProps) {
  const hasPermission = useHasPermission();
  const assetAPI = useInniAPI(Assets, [400]);
  const marketAPI = useInniAPI(Markets);
  const [assetLoaded, setAssetLoaded] = useState<boolean>(false);
  const [marketLoaded, setMarketLoaded] = useState<boolean>(false);
  const [assets, setassets] = useState<Asset[]>();
  const [market, setMarkets] = useState<Market>();
  const companyContext = useContext(CompanyContext);
  const companyId = companyContext.cid;
  const [showModal, setShowModal] = useState(false);
  const [showAssetModal, setShowAssetModal] = useState(false);
  const [initialValues, setInitialValues] = useState<AssetAsDetailed>();
  const v8Styling = companyContext.company?.useV8UI || false;
  const [blankSlateInfo, setBlankSlateInfo]= useState<SbBlokData>();
  const [showView, setShowView] = useState(false)
  const [showEdit, setShowEdit] = useState(false)


  useEffect(() => {
    if (!assetLoaded && assetAPI) {
      assetAPI?.index(companyId).then((response) => {
        setassets(response.data);
        setAssetLoaded(true);
      });
    }
  }, [assetAPI, companyId, assetLoaded, setAssetLoaded]);

  useEffect(() => {
    if (marketAPI && !marketLoaded) {
      marketAPI?.index(companyId).then((response) => {
        setMarkets(response.data);
        setMarketLoaded(true);
      });
    }
  }, [companyId, marketAPI, marketLoaded]);

  const showNewModal = () => {
    assetAPI
      ?.new(companyContext.cid)
      .then((response: { data: AssetAsDetailed }) => {
        response.data && setInitialValues(response.data);
        setShowModal(true);
      });
  };
  
  const showAsset = (id: number) => {
    assetAPI
      ?.getAsset(companyContext.cid, id)
      .then((response: { data: AssetAsDetailed }) => {
        response.data && setInitialValues(response.data);
        if(v8Styling){
          setShowView(true) 
          if(setExpandedView){
            setExpandedView(true)
          }
        } 
        else 
          setShowAssetModal(true);
      });
  };

  const AssetStatus = ({ disposed }: { disposed: boolean }) => {
    let status = disposed ? "DISPOSED" : "ACTIVE";
    let statusVariant = disposed ? "secondary" : "success";
    return (
      <Badge style={{ borderRadius: "2px" }} variant={statusVariant}>
        {status}
      </Badge>
    );
  };

  const createAsset = (
    values: AssetPostModel,
    actions: FormikHelpers<AssetPostModel>
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (assetAPI) {
        assetAPI
          .create(companyContext.cid, values)
          .then((response) => {
            setShowModal(false);
            setAssetLoaded(false);
            resolve();
          })
          .catch((error) => {
            actions.setErrors(error.error);
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const updateAsset = (
    values: AssetPostModel,
    actions: FormikHelpers<AssetPostModel>
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (assetAPI && initialValues?.id && values) {
        assetAPI
          .update(companyContext.cid, initialValues.id, values)
          .then((response) => {
            if(v8Styling)
              showViewAsset(true);
            else 
              setShowModal(false);
            setAssetLoaded(false);
            resolve();
          })
          .catch((error) => {
            actions.setErrors(error.error);
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const editAsset = (id: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (assetAPI) {
        assetAPI
          .getAsset(companyContext.cid, id)
          .then((response: { data: AssetAsDetailed }) => {
            response.data && setInitialValues(response.data);
            setShowAssetModal(false);
            setShowModal(true);
          })
          .catch((error) => {});
      } else {
        reject();
      }
    });
  };

  const deleteAsset = (id: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (assetAPI && id) {
        assetAPI
          .delete(companyContext.cid, id)
          .then((response) => {
            if(v8Styling)
              showAssetList(); 
            else 
              setShowAssetModal(false);
            setAssetLoaded(false);
            resolve();
          })
          .catch((error) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  const changeAssetPayementToExpense = (values:AssetChangetoExpense): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (assetAPI &&initialValues?.id &&values) {
        assetAPI
          .updateAssetToExpense(companyId, initialValues?.id,values)
          .then((response) => {
            if(v8Styling)
              showAssetList(); 
            else 
              setShowAssetModal(false);
            setAssetLoaded(false);
            resolve();
          })
          .catch((error) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };
  
  const disposeAssetAtZero = (id: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (assetAPI && id) {
        assetAPI
          .disposeAssetAtZero(companyContext.cid, id)
          .then((response) => {
            if(v8Styling)
              showAssetList(); 
            else 
              setShowAssetModal(false);
            setAssetLoaded(false);
            resolve();
          })
          .catch((error) => {
            reject();
          });
      } else {
        reject();
      }
    });
  };

  useEffect(()=>{
    const storyblokApi = getStoryblokApi();
    storyblokApi.get('cdn/stories/blank-slate/asset', { version: 'published'})
    .then(res => {
        setBlankSlateInfo(res.data.story.content)
    }).catch(err => {
        console.error(err)
    });
  },[])

  const hasAdminPermission = hasPermission(Entity.AssetAdmin, Action.All)[0]
  const isAdminDecorated = hasPermission(Entity.AssetAdmin, Action.All)[1]
  const navigateToEntity = useNavigateToEntity();

  const createNewAsset =()=>{
    v8Styling ?
        navigateToEntity(Entity.AssetV7, Action.Create)
      :
        showNewModal();
  }

  const showEditAsset = () =>{
    setShowEdit(true)
    setShowView(false)
  }

  const showAssetList = () =>{
    setShowEdit(false)
    setShowView(false)
    if(setExpandedView){
      setExpandedView(false);
    }
  }

  const showViewAsset = (updateAssetDetails?: boolean ) =>{
    setShowEdit(false)
    setShowView(true)
    if(initialValues && updateAssetDetails){
      showAsset(initialValues?.id)
    }
    if(setExpandedView){
      setExpandedView(true)
    }
  }

  return (
    <DefaultLayout
      entity={Entity.AssetV7}
      title={showExpandedView? undefined :"Assets"}
      greyBackground
      subLayout={v8Styling}
    >
      {!showExpandedView && hasAdminPermission && assetLoaded && (!v8Styling || (v8Styling && assets && assets?.length > 0)) && 
        <Toolbar>
            <Button
              admin={isAdminDecorated}
              buttonType="new"
              entity={Entity.AssetV7}
              action={Action.Create}
              onClick={createNewAsset}
              headerBtn={v8Styling}
            />
        </Toolbar>
      }

      <BrandWrapper>
        {!showView && !showEdit && (
          <>
            {/* {!(v8Styling && assets?.length === 0) && assetLoaded && hasBookkeeping22 && (
              <InfoBanner
                title="Use with care"
                body={
                  <>
                    <div>This feature should only be used by your accountant at year-end to address issues with your accounts.</div>
                    <div>Assets will normally be created for you if the purchase has been categorised correctly.</div>
                    <div>Creating an asset here won't necessarily resolve the underlying problem. If you notice a missing asset, please review how the purchase was recorded in your bookkeeping.</div>
                  </>
                }
                type="info"
                noTopMargin
              />
            )} */}

            {v8Styling && assetLoaded && assets?.length===0 &&
              <BlankStateCard 
                  content={blankSlateInfo} 
                  onButtonClick={createNewAsset}
                  buttonEntity={Entity.AssetV7}
                  buttonAction={Action.Create}
                />
            }

            { (!(assetLoaded && assets?.length===0) || !v8Styling ) &&
                <DataGrid.Table>
                  <thead>
                    <tr>
                      <th>Purchased</th>
                      {(hasAdminPermission ||
                        market?.isProperty) && <th className="assetAdminonly">Category</th>}
                      <th>Description</th>
                      <th>Original value</th>
                      <th>Current value</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                {!assetLoaded && (
                      <>
                        <DataGrid.LoadingRow cols={(hasAdminPermission || market?.isProperty)?6:5} />
                        <DataGrid.LoadingRow cols={(hasAdminPermission || market?.isProperty)?6:5} />
                        <DataGrid.LoadingRow cols={(hasAdminPermission || market?.isProperty)?6:5} />              
                      </>
                    )
                  }

                  {assetLoaded&&assets && assets.length > 0 && (
                    assets?.map((assets) => (
                      <tr onClick={() => showAsset(assets.id)} key={assets.id}>
                        <td>{formatDate(assets.datePurchased)}</td>
                        {(hasAdminPermission ||
                          market?.isProperty) && <td>{assets.assetCategoryName}</td>}
                        <td>{assets.description}</td>
                        <td>{formatCurrency(assets.originalValue)}</td>
                        <td>{formatCurrency(assets.currentValue)}</td>
                        <td>
                          <AssetStatus disposed={assets.isDisposed}></AssetStatus>
                        </td>
                      </tr>
                    ))) 
                  }

                  {assetLoaded&&assets && assets.length === 0 && (
                      <tr>
                        <td colSpan={6} style={{ padding: 0 }}>
                          <NoContentSlate
                            type={DatagridType.Assets}
                            termsKey="emptyTerms"
                          />
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </DataGrid.Table>
            }

            {initialValues && showModal && (
              <AssetCreateModal
                hide={() => setShowModal(false)}
                create={createAsset}
                data={initialValues}
                update={updateAsset}
                isProperty={market?.isProperty}
              />
            )}

            {initialValues && showAssetModal && (
              <AssetReadModal
                hide={() => setShowAssetModal(false)}
                data={initialValues}
                edit={editAsset}
                deleteAsset={deleteAsset}
                changeToExpense={changeAssetPayementToExpense}
                isProperty={market?.isProperty}
                disposeAssetAtZero={disposeAssetAtZero}
              />
            )}
          </>
        )}

        {showView  && !showEdit &&
          <ViewAsset 
            data={initialValues}
            isProperty={market?.isProperty} 
            back={showAssetList}
            edit={showEditAsset } 
            deleteAsset={deleteAsset} 
            changeToExpense={changeAssetPayementToExpense} 
            disposeAssetAtZero={disposeAssetAtZero}   
          />
        }

        {showEdit && !showView &&
          <EditAsset 
            data={initialValues}
            isProperty={market?.isProperty} 
            onCancel={showViewAsset}
            update={updateAsset}  
            back={showAssetList}
          />
        }
      </BrandWrapper>
    </DefaultLayout>
  );
}

export default AssetPage;
