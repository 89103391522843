import { EntityRoute, RouteContext } from "./routeTypes";
import { Entity } from "../../utils/EntityAction";
import ConsultationsListPage from "../../features/Consultations/ConsultationsListPage";

const consultations:Array<EntityRoute> = [
    {
        path: '/company/:cid/consultations',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Consultation,
        component: ConsultationsListPage
    }
]

export default consultations;

