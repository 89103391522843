import classNames from 'classnames';
import _ from 'lodash';
import React, { useContext } from 'react';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import { Action, Entity } from '../../utils/EntityAction';
import { LoadingPlaceholder } from '../LoadingPlaceholder/LoadingPlaceholder';
import styles from './DataGrid.module.css'
import CompanyContext, { CompanyProduct } from '../../context/CompanyContext';

const Table = (
    {children, noHover, formEdit, useMatchStyling}:
    {children: React.ReactNode, noHover? : boolean, formEdit?: boolean, useMatchStyling?: boolean}) => {

    const companyContext = useContext(CompanyContext);
    const v8Styling = companyContext.company?.useV8UI || false;

    return (
        <div className="table-responsive-lg">
            <table
                data-cy="dataGridTable"
                className={
                    classNames(
                        styles.dataGrid,
                        {
                            [styles.hover]: !noHover,
                            'table': true,
                            [styles.formEdit]: formEdit,
                            [styles.matchRow]: useMatchStyling,
                            [styles.v8]: v8Styling
                        }
                    )
                }
            >
                {children}
            </table>
        </div>
    )
       
}

const LoadingRow = ({cols} : {cols:number}) => {
    return (
        <tr>
            {_.times(cols, n => <td key={n}><LoadingPlaceholder /></td>)}
        </tr>
    )
}

const EntityNavigateRow = (
    {children, entity, action, extraParams, className}:
    {children: React.ReactNode, entity : Entity, action? : Action, extraParams? : { [key: string]: string|number; }, className?: string}) => {

    const navigateToEntity = useNavigateToEntity();

    const onRowClick = () => {
        navigateToEntity(entity, action, extraParams);
    }

    return (
        <tr data-cy="dataGridTableRow" onClick={onRowClick} className={className}>
            {children}
        </tr>
    )
}

export {Table, LoadingRow, EntityNavigateRow}