import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import { DividendListPage } from "../../features/Dividends/DividendListPage";
import { DividendReadPage } from "../../features/Dividends/DividendReadPage";
import { DividendNewPage } from "../../features/Dividends/DividendNewPage";

const expenseImport:Array<EntityRoute> = [
    {
        path: '/company/:cid/dividends/',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.DividendV7,
        action: Action.List,
        component: DividendListPage
    },
    {
        path: '/company/:cid/dividends/details/:lineId',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.DividendV7,
        action: Action.Read,
        component: DividendReadPage
    },
    {
        path: '/company/:cid/dividends/new',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.DividendV7,
        action: Action.Create,
        component: DividendNewPage
    }
]

export default expenseImport;