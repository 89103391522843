import { useContext } from "react";
import UserContext from "../context/UserContext";


/**
 * Purely a convenience wrapper around use context 
 */
export const useDisplayHttpResponseError = () :  (error: Response) => void => {
    const userContext = useContext(UserContext);
    return userContext.displayHttpResponseError;     
}