/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CalculationResults,
  Datagrid,
  Dividend,
  DividendDetailed,
  DividendEligibilityModel,
  DividendEmail,
  DividendEmailPostModel,
  DividendPostModel,
  DividendTimeLine,
  NewDividendViewData,
  PersonWithDividendData,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Dividends<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Dividends
   * @name Index
   * @request GET:/api/v2/companies/{companyId}/dividends
   * @secure
   */
  index = (companyId: number, params: RequestParams = {}) =>
    this.request<Dividend[], any>({
      path: `/api/v2/companies/${companyId}/dividends`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name DividendEligibility
   * @request GET:/api/v2/companies/{companyId}/dividends/DividendEligibility
   * @secure
   */
  dividendEligibility = (companyId: number, params: RequestParams = {}) =>
    this.request<DividendEligibilityModel, any>({
      path: `/api/v2/companies/${companyId}/dividends/DividendEligibility`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name Filter
   * @request GET:/api/v2/companies/{companyId}/dividends/Filter
   * @secure
   */
  filter = (companyId: number, query?: { yearCode?: string }, params: RequestParams = {}) =>
    this.request<Datagrid, any>({
      path: `/api/v2/companies/${companyId}/dividends/Filter`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name GetOne
   * @request GET:/api/v2/companies/{companyId}/dividends/{id}
   * @secure
   */
  getOne = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<DividendDetailed, any>({
      path: `/api/v2/companies/${companyId}/dividends/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name DeleteDividend
   * @request DELETE:/api/v2/companies/{companyId}/dividends/{id}
   * @secure
   */
  deleteDividend = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/dividends/${id}`,
      method: "DELETE",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name GetOneByLineId
   * @request GET:/api/v2/companies/{companyId}/dividends/GetOneByLine/{lineId}
   * @secure
   */
  getOneByLineId = (companyId: number, lineId: number, params: RequestParams = {}) =>
    this.request<DividendDetailed, any>({
      path: `/api/v2/companies/${companyId}/dividends/GetOneByLine/${lineId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name GetEmailVoucher
   * @request GET:/api/v2/companies/{companyId}/dividends/EmailFields/{lineId}
   * @secure
   */
  getEmailVoucher = (companyId: number, lineId: number, params: RequestParams = {}) =>
    this.request<DividendEmail, any>({
      path: `/api/v2/companies/${companyId}/dividends/EmailFields/${lineId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name EmailPdf
   * @request POST:/api/v2/companies/{companyId}/dividends/Email/{lineId}
   * @secure
   */
  emailPdf = (companyId: number, lineId: number, dividendEmail: DividendEmailPostModel, params: RequestParams = {}) =>
    this.request<DividendEmailPostModel, any>({
      path: `/api/v2/companies/${companyId}/dividends/Email/${lineId}`,
      method: "POST",
      body: dividendEmail,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    }); /**
 * No description
 * 
 * @tags Dividends
 * @name CalculateDividend
 * @summary POST because we post a lot of data,
The calculate might fail because you want too much, we don't return bad request as it's not quite true, but there is an Errors dictionary as to why the divi isn't valid.
We return supporting data in any case
 * @request POST:/api/v2/companies/{companyid}/dividends/Calculate
 * @secure
 */
  calculateDividend = (companyid: number, newDividend: DividendPostModel, params: RequestParams = {}) =>
    this.request<CalculationResults, any>({
      path: `/api/v2/companies/${companyid}/dividends/Calculate`,
      method: "POST",
      body: newDividend,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name RepayDla
   * @summary On teh end of the create flow...
   * @request GET:/api/v2/companies/{companyId}/dividends/ReplayDLA/{lineId}
   * @secure
   */
  repayDla = (companyId: number, lineId: number, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/companies/${companyId}/dividends/ReplayDLA/${lineId}`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name GetTimeLine
   * @request GET:/api/v2/companies/{companyId}/dividends/TimeLine/{lineId}
   * @secure
   */
  getTimeLine = (companyId: number, lineId: number, params: RequestParams = {}) =>
    this.request<DividendTimeLine[], any>({
      path: `/api/v2/companies/${companyId}/dividends/TimeLine/${lineId}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name ViewMinutes
   * @request GET:/api/v2/companies/{companyId}/dividends/ViewMinutes/{id}
   * @secure
   */
  viewMinutes = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/dividends/ViewMinutes/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name ViewVoucher
   * @request GET:/api/v2/companies/{companyId}/dividends/ViewVoucher/{id}
   * @secure
   */
  viewVoucher = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/dividends/ViewVoucher/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name ViewMigrationVouchers
   * @request GET:/api/v2/companies/{companyId}/dividends/ViewMigrationVouchers/{id}
   * @secure
   */
  viewMigrationVouchers = (companyId: number, id: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/dividends/ViewMigrationVouchers/${id}`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name AllVouchersAndMinutesZipped
   * @request GET:/api/v2/companies/{companyId}/dividends/AllVouchersAndMinutesZipped
   * @secure
   */
  allVouchersAndMinutesZipped = (companyId: number, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/companies/${companyId}/dividends/AllVouchersAndMinutesZipped`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name CreateDividendData
   * @summary Returns data for creating new dividend, it's a superset of the data from Calculate() - which just returns teh stuff that's likely changed.
   * @request GET:/api/v2/companies/{companyId}/dividends/Create
   * @secure
   */
  createDividendData = (companyId: number, params: RequestParams = {}) =>
    this.request<NewDividendViewData, any>({
      path: `/api/v2/companies/${companyId}/dividends/Create`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name CreateDividend
   * @summary Create a dividend
   * @request POST:/api/v2/companies/{companyId}/dividends/Create
   * @secure
   */
  createDividend = (companyId: number, pm: DividendPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/companies/${companyId}/dividends/Create`,
      method: "POST",
      body: pm,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags Dividends
   * @name PeopleWithDividendData
   * @request GET:/api/v2/companies/{companyId}/dividends/PeopleWithDividendData
   * @secure
   */
  peopleWithDividendData = (companyId: number, params: RequestParams = {}) =>
    this.request<PersonWithDividendData[], any>({
      path: `/api/v2/companies/${companyId}/dividends/PeopleWithDividendData`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
}
