import { ReactElement, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvoiceAsDetailed, InvoicePostModel } from '../../api/inni/data-contracts';
import { Invoices } from '../../api/inni/Invoices';
import { useAssertPermission } from '../../hooks/useAssertPermission';
import { useInniAPI } from '../../hooks/useInniAPI';
import { DefaultLayout } from '../../layouts/Desktop/DefaultLayout';
import { Entity, Action } from '../../utils/EntityAction';
import InvoiceForm from './Components/InvoiceFormWrapper';
import { FormikErrors, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { useNavigateToEntity } from '../../hooks/useNavigateToEntity';
import { Button } from '../../elements/Button/Button';
import Toolbar from '../../layouts/Desktop/Toolbar';
import Loadingtemplate from './Components/Loadingtemplate';
import { getDocumentName } from './HelperFunctions/ShowField';
import { useHasPermission } from '../../hooks/useHasPermission';
interface RouteParams {
	id: string;
	cid: string;
}
export interface InvoiceReactSelect {
	value?: string | number;
	label?: string | number;
}
interface RouteParams {
	cid: string;
	id: string;
	copy: string;
	year: string;
}

const EditInvoice = (): ReactElement => {
	useAssertPermission(Entity.InvoiceV7, Action.Create);
	const hasPermission = useHasPermission();
	const [enableForeignInvoices, setEnableForeignInvoices] = useState(false);
	const formikRef = useRef<FormikProps<FormikValues>>();
	const navigateToEntity = useNavigateToEntity();
	const invoiceId = parseInt(useParams<RouteParams>().id);
	const companyId = parseInt(useParams<RouteParams>().cid);
	const year = useParams<RouteParams>().year;
	const invoiceAPI = useInniAPI(Invoices, [400]);
	const invoiceAPIwithout400 = useInniAPI(Invoices, []);
	const [initialValues, setInitialValues] = useState<InvoiceAsDetailed | undefined>(undefined);
	const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);
	useEffect(() => {
		if (invoiceAPI) {
			invoiceAPI.new(companyId, { invoiceId: invoiceId }).then((response) => {
				setInitialValues(response.data);
				setInitialValuesLoaded(true);
			});
		}
	}, [companyId, invoiceAPI, invoiceId]);

	useEffect(() => {
		setEnableForeignInvoices(hasPermission(Entity.ForeignInvoicing, Action.All)[0]);
	}, []);

	const handleCancel = () => {
		navigateToEntity(Entity.InvoiceV7, Action.Read, { id: invoiceId });
	};

	const validateEditInvoice = (values: InvoicePostModel): Promise<FormikErrors<InvoicePostModel>> => {
		return new Promise((resolve, reject) => {
			if (invoiceAPI && companyId) {
				return invoiceAPI
					.validateUpdate(companyId, invoiceId, values)
					.then(() => resolve({}))
					.catch((error) => resolve(error.error));
			} else {
				reject();
			}
		});
	};

	const UpdateInvoice = (values: InvoicePostModel, actions: FormikHelpers<InvoicePostModel>): Promise<void> => {
		return new Promise((resolve, reject) => {
			if (invoiceAPIwithout400 && companyId) {
				return invoiceAPIwithout400
					.update(companyId, invoiceId, values)
					.then((data) => {
						navigateToEntity(Entity.InvoiceV7, Action.Read, { id: +data.data, year: year });
						resolve();
					})
					.catch((error) => {
						actions.setErrors(error.error);
					});
			} else {
				reject();
			}
		});
	};

	const handleSubmit = () => {
		if (formikRef.current) {
			formikRef.current.handleSubmit();
		}
	};

	return (
		<>
			{!initialValuesLoaded && <Loadingtemplate editing></Loadingtemplate>}
			{initialValuesLoaded && enableForeignInvoices !== undefined && (
				<DefaultLayout
					title={initialValues && 'Edit ' + (initialValues && getDocumentName(initialValues.documentType)?.toLowerCase())}
					loading={!initialValuesLoaded}
					useFullWidth
					>
					<Toolbar>
						<Button buttonType="save" onClick={handleSubmit} disabled={formikRef.current?.isSubmitting}></Button>
						<Button buttonType="cancel" onClick={handleCancel}></Button>
					</Toolbar>
					{initialValuesLoaded && initialValues && (
						<InvoiceForm
							enableForeignInvoices={enableForeignInvoices}
							formikref={formikRef}
							companyId={companyId}
							initialValues={initialValues}
							createInvoice={UpdateInvoice}
							validateInvoice={validateEditInvoice}></InvoiceForm>
					)}
				</DefaultLayout>
			)}
		</>
	);
};
export default EditInvoice;
