/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { ContactOnboarding, ContactOnboardingPostModel } from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class ContactOnboardings<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags ContactOnboardings
   * @name Index
   * @summary Get contact id based on the current user context
   * @request GET:/api/v2/contacts/{contactId}/ContactOnboardings
   * @secure
   */
  index = (contactId: number, params: RequestParams = {}) =>
    this.request<ContactOnboarding[], any>({
      path: `/api/v2/contacts/${contactId}/ContactOnboardings`,
      method: "GET",
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactOnboardings
   * @name Create
   * @request POST:/api/v2/contacts/{contactId}/ContactOnboardings
   * @secure
   */
  create = (contactId: number, postModel: ContactOnboardingPostModel, params: RequestParams = {}) =>
    this.request<string, any>({
      path: `/api/v2/contacts/${contactId}/ContactOnboardings`,
      method: "POST",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactOnboardings
   * @name ValidateUpdate
   * @request PUT:/api/v2/contacts/{contactId}/ContactOnboardings/Validate
   * @secure
   */
  validateUpdate = (contactId: number, postmodel: ContactOnboardingPostModel, params: RequestParams = {}) =>
    this.request<object, any>({
      path: `/api/v2/contacts/${contactId}/ContactOnboardings/Validate`,
      method: "PUT",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactOnboardings
   * @name ValidateCreate
   * @request POST:/api/v2/contacts/{contactId}/ContactOnboardings/Validate
   * @secure
   */
  validateCreate = (contactId: number, postmodel: ContactOnboardingPostModel, params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/api/v2/contacts/${contactId}/ContactOnboardings/Validate`,
      method: "POST",
      body: postmodel,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags ContactOnboardings
   * @name Update
   * @request PUT:/api/v2/contacts/{contactId}/ContactOnboardings/{contactOnboardingId}
   * @secure
   */
  update = (
    contactId: number,
    contactOnboardingId: number,
    postModel: ContactOnboardingPostModel,
    params: RequestParams = {},
  ) =>
    this.request<object, any>({
      path: `/api/v2/contacts/${contactId}/ContactOnboardings/${contactOnboardingId}`,
      method: "PUT",
      body: postModel,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
