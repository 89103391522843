import InfoBanner from "../../../elements/InfoBanner/InfoBanner"

export const CompanySetupNotComplete = (v8Styling: boolean) => {
    return (
        <>
            { v8Styling
                ? (
                    <div className="centerFlexContent">
                        <div className="p-5">
                            <h5>Your company setup is not complete yet</h5>
                            <p>Until the company balance sheet is complete, we can't give you an accurate view of your company's financial position.</p>
                        </div>
                    </div>
                )
                : (
                    <div style={{padding: '0px 10px'}}>
                        <InfoBanner
                            type="warning"
                            title="Your company setup is not complete yet"
                            body="Until the company balance sheet is complete, we can't give you an accurate view of your company's financial position"
                        />
                    </div>
                )
            }
        </>
    )
}

export const NoData = (v8Styling: boolean) => {
    return (
        <>
            { v8Styling
                ? (
                    <div className="centerFlexContent">
                        <div className="p-5">
                            <h5>No information to display here yet</h5>
                            <p>Check back later once you've categorised some bookkeeping transactions</p>
                        </div>
                    </div>
                )
                : (
                    <div style={{padding: '0px 10px'}}>
                        <InfoBanner
                            type="info"
                            title="No information to display here yet"
                            body="Check back later once you've categorised some bookkeeping transactions"
                        />
                    </div>
                )
            }
        </>
    )
}
