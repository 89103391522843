import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import RecurringPayments from "../../features/RecurringPayments/RecurringPayments";

const recurringPayments:Array<EntityRoute> = [
    {
        path: '/company/:cid/recurringpayments',
        exact: true,
        context: RouteContext.Company,
        action: Action.List,
        entity: Entity.RegularPayment,
        component: RecurringPayments
    },
]

export default recurringPayments;