import styles from './EditRow.module.css';
import React, { ReactNode, useEffect, useMemo, useRef } from 'react';
import { Form, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { Field, FieldProps, useField } from 'formik';
import _ from 'lodash';
import classNames from 'classnames';
import Select, { components, GroupTypeBase, MenuProps } from 'react-select';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../Button/Button';
import { asHtml5Date } from '../../utils/formatDate';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { BrandWrapper } from '../../components/BrandWrapper/BrandWrapper';
 
/* V6 UI:
CSS
https://innidev.visualstudio.com/inni/_git/inni?path=%2FWebsite%2FassetsV6%2Fsrc%2Fstylesheets%2F_forms.scss&version=GBmaster&_a=contents
Control source (EEEK!)
https://innidev.visualstudio.com/inni/_git/inni?path=%2FWebControls%2FInni%2FEditorExtensions.vb&_a=contents&version=GBmaster

*/

export const RowWrapper = ({name, label, type, labelTop=false, children, labelClassName = '', hideLabel, labelTextMuted, useFullLabelWidth } : 
    {name: string, label?: string, type?: string, labelTop?: boolean, children: React.ReactNode, labelClassName?: string, hideLabel?: boolean, labelTextMuted?: boolean, useFullLabelWidth?: boolean}) => {

    const generateLabel = () => {
        if (label) return label;
        let l = _.lowerCase(name);
        l = _.upperFirst(l);
        return l;
    }

    return (
        <div className={classNames(styles.editRow, styles['input-' + type])}>
            {!hideLabel && <label className={classNames(labelClassName, labelTextMuted && 'text-muted', useFullLabelWidth && 'w-100')}>{generateLabel()}</label>}
            <div data-cy={name} className={labelTop ? styles.valueWrapperFullWidth : styles.valueWrapper}>
                {children}
            </div>
        </div>
    )
}

export interface EditCommonProps<T> {
    name: Extract<keyof T, string>,
    label?: string,
    help?: string | JSX.Element
    labelTop?: boolean,
    popoverHelp?: JSX.Element,
    // TODO implement remaining disabled functionality
    disabled?: boolean
    labelTextMuted?: boolean
    useFullLabelWidth?: boolean
}


export interface TextProps {
    type?: 'text' | 'email' | 'password' | 'number' | 'textarea',
    small?: boolean,
    suffix?: string,
    maxLength?: number
    min?: number,
    labelClassName?: string
    autoFocus?: boolean;
    hideLabel?: boolean;
    placeholder?: string;
    onlyIntegers?: boolean;
    onlyPositiveNumbers?: boolean;
}


//value={field.value || ''} is on some inputs, this is because we need to avoid undefined values which don't agree with formik validation
//https://github.com/formium/formik/issues/321

export const LabelOnly = ({label, labelTop=false, children}: {label: string, labelTop?: boolean, children: ReactNode}) => {
    return (
        <RowWrapper name={`label_${label}`} label={label} labelTop={labelTop}>
            { children }
        </RowWrapper>
    )
}

const Text = <T, >({labelClassName, name, label, type, help, small, suffix, labelTop, maxLength, popoverHelp, min, disabled, autoFocus, hideLabel, placeholder, labelTextMuted, onlyIntegers, onlyPositiveNumbers}: EditCommonProps<T> & TextProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const popover = (
        <Popover id="popover-basic" title={name}>
            <Popover.Content>{popoverHelp}</Popover.Content>
        </Popover>
    );
    useEffect(() => {
        autoFocus && inputRef && inputRef.current && inputRef.current.focus()
    }, [autoFocus]);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) =>{
		if(onlyIntegers){
			if (e.key === '.' || e.key === ',' ) {
				e.preventDefault();
			}
		}

		if(onlyPositiveNumbers){
			if (e.key === '-' ) {
				e.preventDefault();
			}
		}
		
	}

    return (
        <RowWrapper labelClassName={labelClassName} name={name} label={label} type={type} labelTop={labelTop} hideLabel={hideLabel} labelTextMuted={labelTextMuted}>
            <Field name={name}>
                {({field, form, meta}: FieldProps) => (
                <>
                    <InputGroup className={classNames(styles.inputGroup, {[styles.small]: small})}>
                        {type === 'textarea' &&                    
                            <Form.Control autoFocus={autoFocus} readOnly={disabled} as={'textarea'} rows={5} {...field} value={field.value || ''} isInvalid={meta.touched && meta.error !== undefined} />
                        }
                        {type === 'number' &&                    
                            <Form.Control ref={inputRef} readOnly={disabled} type="number" {...field} value={field.value || 0} isInvalid={meta.touched && meta.error !== undefined} maxLength={maxLength} min={min} onKeyDown={handleKeyDown}/>
                        }
                        {(type !== 'textarea' && type !== 'number') &&                    
                            <Form.Control placeholder={placeholder} ref={inputRef} readOnly={disabled} type={type ? type : 'text'} {...field} value={field.value || field.value === 0 || ''} isInvalid={meta.touched && meta.error !== undefined} maxLength={maxLength} />
                        }
                        {suffix &&
                            <InputGroup.Append>
                                <InputGroup.Text>{suffix}</InputGroup.Text>
                            </InputGroup.Append>                    
                        }
                        {popoverHelp &&
                        <div className={styles.popoverHelpIcon}>
                            <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
                                <FontAwesomeIcon size="lg" className="fad" icon={faInfoCircle} style={{ cursor: 'pointer' } as React.CSSProperties} />
                            </OverlayTrigger>
                        </div>}
                    </InputGroup>
                    {help && <Form.Text className={`text-muted ${labelTop && styles.labelTopHlpMsg}`}>{help}</Form.Text> }
                    {meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}                    
                </>
                )}
            </Field>
        </RowWrapper>

    )

}

const DateSelector = <T, >({name, label, help, readOnlyWithMessage, labelTop, disabled}: EditCommonProps<T> & {readOnlyWithMessage?: string}) => {

    
    return (
        <RowWrapper name={name} label={label} type={'date'} labelTop={labelTop}>
            <Field name={name}>
                {({field, form, meta}: FieldProps) => (
                <>
                    <InputGroup className={styles.inputGroup}>
                        <Form.Control readOnly={readOnlyWithMessage !== undefined || disabled} type={'date'} {...({...field, value: asHtml5Date(field.value)})} isInvalid={meta.touched && meta.error !== undefined} />
                    </InputGroup>
                    {help && <Form.Text className={`text-muted ${labelTop && styles.labelTopHlpMsg}`} >{help}</Form.Text> }
                    {readOnlyWithMessage && <Form.Text className={`text-muted ${labelTop && styles.labelTopHlpMsg}`} >{readOnlyWithMessage}</Form.Text> }
                    {meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}                    
                </>
                )}
            </Field>
        </RowWrapper>

    )

}



export const currencyIntervals: {[key: string]: string} = {
    'D': 'Day',
    'W': 'Week',
    'M': 'Month',
    'Q': 'Quarter',
    'A': 'Year'
}


export interface CurrencyProps<T> {
    intervalFieldName?: Extract<keyof T, string>,
    intervals?: ('D'|'W'|'M'|'Q'|'A')[],
    intervalLabel?: string,
    readOnlyWithMessage?: string,
    flipToPositive?: boolean,
    min?: number,
    step? : string,
    inputSingleRow?: boolean,
    currencySymbol?: string,
    onlyIntegers?: boolean;
    onlyPositiveNumbers?: boolean;
}


const Currency = <T,>({name, label, help, intervalFieldName, intervals, intervalLabel, readOnlyWithMessage, flipToPositive, labelTop, min, step, labelTextMuted, inputSingleRow, currencySymbol, onlyIntegers, onlyPositiveNumbers } : EditCommonProps<T> & CurrencyProps<T>) => {

    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) =>{
		if(onlyIntegers){
			if (e.key === '.' || e.key === ',' ) {
				e.preventDefault();
			}
		}

		if(onlyPositiveNumbers){
			if (e.key === '-' ) {
				e.preventDefault();
			}
		}
		
	}

    return (
        <RowWrapper name={name} label={label} type={'currency'} labelTop={labelTop} labelTextMuted={labelTextMuted}>
            <div className={inputSingleRow ? classNames(styles.currencyValue, styles.inputSingleRow) :styles.currencyValue}>
                <Field name={name}>
                    {({field, form, meta}: FieldProps) => (
                        <>
                            <InputGroup className={styles.inputGroup}>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>{currencySymbol? currencySymbol : '£'}</InputGroup.Text>
                                </InputGroup.Prepend>                    
                                <Form.Control step={step} readOnly={readOnlyWithMessage !== undefined} type='number' {...field} min={( min || min === 0) ? min : undefined} value={flipToPositive ? (field.value && Math.abs(field.value) || '') : field.value} isInvalid={meta.touched && meta.error !== undefined}  onKeyDown={handleKeyDown}/>
                            </InputGroup>
                            {help && <Form.Text className={`text-muted ${labelTop && styles.labelTopHlpMsg}`}>{help}</Form.Text> }
                            {readOnlyWithMessage && <Form.Text className={`text-muted ${labelTop && styles.labelTopHlpMsg}`} >{readOnlyWithMessage}</Form.Text> }
                            {meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}                    
                        </>
                    )}
                </Field>
            </div>
            {intervalLabel && 
                <div className={inputSingleRow ? classNames(styles.intervalLabel, styles.singleRowIntervalLabel) :styles.intervalLabel}>{intervalLabel}</div>
            }
            {intervals &&
                <div className={inputSingleRow ? classNames(styles.currencyValue, styles.inputSingleRow) :styles.currencyValue}>
                    <Field name={intervalFieldName}>
                        {({field, form, meta}: FieldProps) => (
                        <>
                        
                            <Form.Control as='select' {...field} isInvalid={meta.touched && meta.error !== undefined}>
                                {intervals.map(interval => 
                                    <option key={interval} value={interval}>{currencyIntervals[interval]}</option>    
                                )}
                            </Form.Control> 
                            {help && <Form.Text className={`text-muted ${labelTop && styles.labelTopHlpMsg}`} >{help}</Form.Text> }
                            {meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}                    
                        </>
                        )}
                    </Field>
                </div>
            }
        </RowWrapper>

    )
}


export interface ListOption {
    value: string | null,
    label: string,
    icon?: IconDefinition,
    iconColor?: string,
    options?: {value: string, label: string}[]
}

export interface GroupedListOption {
    label: string,
    icon?: IconDefinition,
    iconColor?: string,
    options?: {value: string|number, label: string}[]
}

export interface SelectListProps {
    options: ListOption[],
    readOnlyWithMessage?: string,    // TODO move this to EditCommonProps and implement elsewhere
    readOnly?: boolean, // TODO move this to EditCommonProps and implement elsewhere
    allowSearch?: boolean
    addNew?: () => void;
    hideLabel?: boolean;
}

export interface GroupedSelectListProps {
    options: GroupedListOption[],
    readOnlyWithMessage?: string,    // TODO move this to EditCommonProps and implement elsewhere
    readOnly?: boolean // TODO move this to EditCommonProps and implement elsewhere
}

type CustomMenuProps = React.ComponentType<MenuProps<any, false, GroupTypeBase<any>>>;
const SelectList = <T,>({name, label, options, help, readOnlyWithMessage, readOnly, labelTop, allowSearch, addNew, hideLabel}: EditCommonProps<T> & SelectListProps) => {

    const [field, meta, helpers] = useField({name: name}); 
    const { setValue, setTouched, setError } = helpers;
    

    const onChange = (selectedOption: {value: string; label: any;} | null) => {
        setError(undefined);        
        setValue(selectedOption ? selectedOption.value : undefined, true);
        setTouched(true, false);
    }

    const formattedOptions = useMemo(() => {
        let out:Array<{value: string | null, label: any}> = [];
        
        options?.forEach(option => {
            let label:ReactNode = undefined;
            if (option.icon) {
                label = <><FontAwesomeIcon fixedWidth  icon={option.icon} style={{color: option.iconColor}} /> {option.label}</>
            } else {
                label = option.label
            }

            out.push({value:option.value, label})
        })

        return out;

    }, [options])

    const CustomMenu: CustomMenuProps = ({children, ...props}) =>
        <components.Menu
            {...props}
        >
            <BrandWrapper>
                {children}
                <div className="p-2">
                    <Button label="Add new" buttonType="new" variant="primary" className="btn-block" onClick={addNew} />
                </div>
            </BrandWrapper>
        </components.Menu>

    return (
        <RowWrapper name={name} label={label} type='selectList' labelTop={labelTop} hideLabel={hideLabel}>
            <Field name={name}>
                {({field, form, meta}: FieldProps) => (
                <>
                    <Select 
                        className={styles.selectList + ` ${labelTop && styles.LblTopAlignLeft}`}
                        classNamePrefix='selectList'
                        options={formattedOptions}      
                        onChange={onChange}
                        isSearchable={allowSearch}
                        components={addNew ? { Menu: CustomMenu } : undefined}
                        isInvalid={meta.touched && meta.error !== undefined}
                        value={(formattedOptions ? formattedOptions.find(formattedOptions => formattedOptions.value == field.value) : '') as any}
                        isDisabled={readOnly || readOnlyWithMessage !== undefined}
                        menuPlacement="auto" // go upwards when needed - mainly happens on mobile, down is still default
                        menuPortalTarget={document.body} //https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown, https://github.com/JedWatson/react-select/issues/1537
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                    {help && <Form.Text className={`text-muted ${labelTop && styles.labelTopHlpMsg}`} >{help}</Form.Text> }
                    {readOnlyWithMessage && <Form.Text className={`text-muted ${labelTop && styles.labelTopHlpMsg}`}>{readOnlyWithMessage}</Form.Text> }
                    {meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error.includes('Must be between') ? 'Required' : meta.error}</Form.Control.Feedback>}                    
                </>
                )}
            </Field>
        </RowWrapper>

    )

}

const GroupedSelectList = <T,>({name, label, options, help, readOnlyWithMessage, readOnly, labelTop}: EditCommonProps<T> & GroupedSelectListProps) => {

    const [field, meta, helpers] = useField({name: name}); 
    const { setValue, setTouched, setError } = helpers;
    

    const onChange = (selectedOption: { value: any; }) => {
        setError(undefined);        
        setValue(selectedOption ? selectedOption.value : undefined, true);
        setTouched(true, false);
    }

    const formattedOptions = useMemo(() => {
        let out:Array<{label: string, options: [{value: string|number, label: any}]}> = [];
        
        options?.forEach(option => {
            let arr:[{value: string|number, label: any}] = [{value: '', label: ''}];

            option.options?.forEach(o => {

                let label:ReactNode = undefined
                
                if (option.icon) {
                    label = <><FontAwesomeIcon fixedWidth  icon={option.icon} style={{color: option.iconColor}} /> {option.label}</>
                } else {
                    label = option.label
                }
                arr.push({value: o.value, label: label})
                                
            })
            

            out.push({label: option.label, options: arr})
        })

        return out;

    }, [options])

    return (
        <RowWrapper name={name} label={label} type='selectList' labelTop={labelTop}>
            <Field name={name}>
                {({field, form, meta}: FieldProps) => (
                <>
                    <Select 
                        className={styles.selectList}
                        classNamePrefix='selectList'
                        options={options}      
                        onChange={onChange}
                        isSearchable={true}
                        isInvalid={meta.touched && meta.error !== undefined}
                        value={(options ? options.map(i => i.options?.find(options => options.value === field.value)) : '') as any}
                        isDisabled={readOnly || readOnlyWithMessage !== undefined}
                        menuPortalTarget={document.body} //https://stackoverflow.com/questions/55830799/how-to-change-zindex-in-react-select-drowpdown, https://github.com/JedWatson/react-select/issues/1537
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                    {help && <Form.Text className="text-muted">{help}</Form.Text> }
                    {readOnlyWithMessage && <Form.Text className="text-muted">{readOnlyWithMessage}</Form.Text> }
                    {meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error.includes('Must be between') ? 'Required' : meta.error}</Form.Control.Feedback>}                    
                </>
                )}
            </Field>
        </RowWrapper>

    )

}


const Switch = <T,>({name, label, help, labelTop, disabled, labelTextMuted, useFullLabelWidth}: EditCommonProps<T>) => {


    return (
        <RowWrapper name={name} label={label} type='switch' labelTop={labelTop} labelTextMuted={labelTextMuted} useFullLabelWidth={useFullLabelWidth}>
            <Field name={name}>
                {({field, form, meta}: FieldProps) => (
                <>
                    <Form.Check disabled={disabled} className={`${labelTop && styles.switchLblTop} ${labelTop && 'pt-0'}`} type='switch' id={'switch-' + name} {...field} checked={field.value} />
                    {help && <Form.Text className={`text-muted ${labelTop && styles.labelTopHlpMsg}`}>{help}</Form.Text> }
                    {meta.touched && meta.error && <Form.Control.Feedback type="invalid">{meta.error}</Form.Control.Feedback>}                    
                </>
                )}
            </Field>
        </RowWrapper>

    )
}

export interface SubmitProps {
    onCancelClick?: () => void,
    disabled? : boolean,
    submitDisabled? : boolean,
    showCancel?: boolean,
    alignLeft?: boolean
    marginTop?: boolean
}

const Submit = ({onCancelClick, disabled, submitDisabled, showCancel = true, alignLeft, marginTop = false} : SubmitProps) => {

    return (
        <div className={styles.editRow}>
        {!alignLeft && <label>&nbsp;</label>}
        <div className={`${styles.valueWrapper} ${styles.buttonAlign}`}>
            <Button submitButton buttonType='save' disabled={disabled || submitDisabled} className='mr-2' marginTop={marginTop} />
            {showCancel && <Button buttonType='cancel' onClick={onCancelClick} disabled={disabled} marginTop={marginTop} />}
        </div>
    </div>
    )
}


const SubHeading = ({children} : {children:ReactNode}) => {
    return (
        <h2 className={styles.subheading}>{children}</h2>
    )
}

export {Text, Currency, SelectList, Switch, Submit, SubHeading, DateSelector, GroupedSelectList}