import Overlay from '../components/Overlay/Overlay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobilePhone } from '@fortawesome/pro-regular-svg-icons';

const MobileBlocker = () => {
    return (
        <Overlay isOpen={true}>
            <div className='centerFlexContentBoth' style={{flexDirection: 'column', padding: '30px'}}>
                    <h1><FontAwesomeIcon icon={faMobilePhone} size={'xl'} /></h1>
                    <h1 className="mt-3 mb-5">Sorry!</h1>
                    <div>
                        <h4 className="mb-4">This feature isn't available on mobile just yet.</h4>
                        <h4 className="mb-4">Please login on a desktop device.</h4>
                        <h4>Thank you.</h4>
                    </div>
            </div>
        </Overlay>
    )
}

export default MobileBlocker;