import { Formik, FormikHelpers } from "formik"
import { Col, Form, Row } from "react-bootstrap"
import { FactFindingForm, FactFindingFormPostModel } from "../../api/inni/data-contracts";
import { Button } from "../../elements/Button/Button"
import useIsMobile from '../../hooks/useIsMobile';

export const FactFinding = ({onSubmit, formData}: {onSubmit: (values: FactFindingFormPostModel, actions: FormikHelpers<FactFindingForm>) => Promise<boolean>, formData: FactFindingForm}) => {
    const isMobile = useIsMobile();

    const validation = (values:FactFindingForm) => {
        var errors = {} as any
        if(!values.isDraft) {
            if(values.propertiesOwned === null || values.propertiesOwned?.toString() === "") errors.propertiesOwned = 'Required'
            if(values.howOwned === null || values.howOwned === "") errors.howOwned = 'Required'
            if(values.longTermGoal === null || values.longTermGoal === "") errors.longTermGoal = 'Required'
            if(values.customerNotes === null || values.customerNotes === "") errors.customerNotes = 'Required'
        }
        return errors
    }
    
    return (
        <Formik onSubmit={onSubmit} initialValues={formData} validate={validation}>
            {( {values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <Form>
                    <Row>
                        <Form.Group as={Col} md>
                            <Form.Label style={{fontWeight: 'bold'}}>Forename</Form.Label>
                            <p>{values.firstName}</p>
                        </Form.Group>
                        <Form.Group as={Col} md>
                            <Form.Label style={{fontWeight: 'bold'}}>Surname</Form.Label>
                            <p>{values.lastName}</p>
                        </Form.Group>
                    </Row>

                    <Form.Group className="mb-3">
                        <Form.Label style={{fontWeight: 'bold'}}>Your email</Form.Label>
                        <p>{values.email}</p>
                    </Form.Group>

                    <Form.Group className="mb-3" style={{marginTop:'2rem', maxWidth: (isMobile ? '100%' : '300px')}}>
                        <Form.Label style={{fontWeight: 'bold'}}>Your phone number</Form.Label>
                        <Form.Control name="phoneNumber"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.phoneNumber || ""}
                            style={{padding: '0.64rem'}}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" style={{marginTop:'2rem', maxWidth: (isMobile ? '100%' : '300px'), fontWeight: 'bold'}}>
                        <Form.Label>How many properties do you currently own?</Form.Label>
                        <Form.Control type="number" name="propertiesOwned"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.propertiesOwned}
                            min={0}
                            style={{padding: '0.64rem'}}
                        />
                        {errors.propertiesOwned && touched.propertiesOwned && <div className="invalid-feedback" style={{display:'block', fontWeight:'normal'}}>{errors.propertiesOwned}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3" style={{fontWeight: 'bold'}}>
                        <Form.Label>How are these properties legally owned?</Form.Label>
                        <Form.Control as="textarea" rows={3} name="howOwned"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.howOwned || ""}
                            style={{padding: '0.64rem'}}
                        />
                        <Form.Text className="text-muted">For example, personally owned, owned via a limited company, joint ownership with a spouse, etc</Form.Text>
                        {errors.howOwned && touched.howOwned && <div className="invalid-feedback" style={{display:'block', fontWeight:'normal'}}>{errors.howOwned}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{fontWeight: 'bold'}}>What are your property buying/selling plans for the next 4-5 years?</Form.Label>
                        <Form.Control as="textarea" rows={3} name="longTermGoal"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.longTermGoal || ""}
                            style={{padding: '0.64rem'}}
                        />
                        {errors.longTermGoal && touched.longTermGoal && <div className="invalid-feedback" style={{display:'block', fontWeight:'normal'}}>{errors.longTermGoal}</div>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label style={{fontWeight: 'bold'}}>Please outline the most immediate area of tax/planning you have and would like to discuss with an accountant on this call</Form.Label>
                        <Form.Control as="textarea" rows={3} name="customerNotes"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.customerNotes || ""}
                            style={{padding: '0.64rem'}}
                        />
                        {errors.customerNotes && touched.customerNotes && <div className="invalid-feedback" style={{display:'block', fontWeight:'normal'}}>{errors.customerNotes}</div>}
                    </Form.Group>
                    <div className="d-flex">
                        {formData.isDraft && <Button variant="change" outline disabled={isSubmitting} mobile={isMobile} onClick={() => {setFieldValue('isDraft', true); handleSubmit()}}>Save as draft</Button>}
                        {values.ragStatus !== "G" && <Button variant="change" disabled={isSubmitting} mobile={isMobile} onClick={() => {setFieldValue('isDraft', false); handleSubmit()}}>Submit</Button>}
                    </div>
                </Form>
            )}
        </Formik>
    )
}