import { useContext, useEffect, useState } from "react";
import { ApiConfig, HttpClient, HttpResponse } from "../../api/inni/http-client";
import CompanyContext from "../../context/CompanyContext";
import { useInniAPI } from "../useInniAPI";

interface HasIndex<R>  extends HttpClient  {
    index: (cid: number) => Promise<HttpResponse<R[], any>>
}
 
export function useFetchEntityList<R, T extends HasIndex<R>>(apiClass: { new (p1: ApiConfig): T  }, hideErrorCodes?: number[]): [Array<R>, boolean] {

    const companyContext = useContext(CompanyContext);
    const api = useInniAPI(apiClass, hideErrorCodes);
    const [entities, setEntites] = useState<Array<R>>([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (api && !loaded) {
            api.index(companyContext.cid).then(
                response => {
                    setEntites(response.data);
                    setLoaded(true);
                }
            )
        }        

    }, [api, companyContext.cid, loaded])

    return [entities, loaded]
}
