import { OverlayTrigger, Tooltip, Image } from 'react-bootstrap';
import {
  faCheck,
  faExclamationTriangle,
  faFileAlt,
  faImage,
  faPaperclip,
  faSpinner,
  faTrash,
  faLock,
  faTimes,
  faExclamationCircle,
  faExternalLink,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './TableIcon.module.css'
import { MouseEventHandler } from 'react';
import classNames from 'classnames';
import upload from '../../layouts/Components/icons/v8/upload.svg'


type attachmentType = 'image' | 'file' | 'upload' |
  'note' | 'removeAttachment' | 'spinner' |
  'check' | 'warning' | 'warningSubtle' | 'lock' |
  'cross' | 'exclamation' | 'externallink' |
  'removeLine';

interface tableIconProps {
  icon: attachmentType
  onClick?: MouseEventHandler,
  note?: string,
  noRightMargin?: boolean
}

/**
 * Designed to be used within table cells.
 * Be aware it should not be allowed to make table cells bigger, remove vertical padding on table cells to ensure this.
 * Where the attachment will open a media modal use icon 'image', any other attachment type use 'file'
 * @param icon - image, file or upload
 * @param onClick - callback function
 */
export const TableIcon = ({icon, onClick, note, noRightMargin}: tableIconProps) => {
  const tooltipUpload = (props:any) => (
      <Tooltip {...props}>
        Upload an attachment
      </Tooltip>
  );

  const tooltipOpen = (props:any) => (
      <Tooltip {...props}> 
        View attachment
      </Tooltip>
  );

  const tooltipNote = (props:any) => {
      return( note ?
        <Tooltip {...props}>
          {note}
        </Tooltip> : <></>
      )
  };

  const tooltipExternalLink = (props:any) => {
    return(
      <Tooltip {...props}>
        Go to details
      </Tooltip>
    )
  };

  const tooltipRemoveAttachment = (props:any) => {
    return(
      <Tooltip {...props}>
        Remove attachment
      </Tooltip>
    )
  };

  const tooltipRemoveLine = (props:any) => {
    return(
      <Tooltip {...props}>
        Remove line
      </Tooltip>
    )
  };

  switch (icon) {
    case 'image':
      return (
        <OverlayTrigger placement="top" overlay={tooltipOpen}>
          <FontAwesomeIcon
            icon={faImage}
            className={classNames(styles.defaultIcon, styles.imageIcon, { [styles.noRightMargin]: noRightMargin })}
            onClick={onClick}
          />
        </OverlayTrigger>
      );
    case 'file':
      return (
        <OverlayTrigger placement="top" overlay={tooltipOpen}>
          <FontAwesomeIcon icon={faPaperclip} className={styles.defaultIcon} onClick={onClick} />
        </OverlayTrigger>
      );
    case 'upload':
      return (
        <OverlayTrigger placement="top" overlay={tooltipUpload}>
          <Image alt="Upload" src={upload} className={`${styles.defaultIcon} ${styles.greyedIcon}`} onClick={onClick} />
        </OverlayTrigger>
      );
    case 'note':
        return (
          <OverlayTrigger placement="top" overlay={tooltipNote}>
            <FontAwesomeIcon icon={faFileAlt} className={`${styles.defaultIcon}`} onClick={onClick} />
          </OverlayTrigger>
        );
    case 'removeAttachment':
      return (
        <OverlayTrigger placement="top" overlay={tooltipRemoveAttachment}>
          <FontAwesomeIcon icon={faTrash} className={`${styles.defaultIcon}`} onClick={onClick} />
        </OverlayTrigger>
      );
    case 'removeLine':
      return (
        <OverlayTrigger placement="top" overlay={tooltipRemoveLine}>
          <FontAwesomeIcon icon={faTrash} className={`${styles.defaultIcon}`} onClick={onClick} />
        </OverlayTrigger>
      );
    case 'spinner':
      return (
        <FontAwesomeIcon icon={faSpinner} spin className={`${styles.defaultIcon}`} onClick={onClick} />
      );
    case 'check':
      return (
        <FontAwesomeIcon icon={faCheck} className={`${styles.defaultIcon} ${styles.checkIcon}`} onClick={onClick} />
      );
    case 'warning':
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className={classNames(styles.defaultIcon, styles.warningIcon, {[styles.noRightMargin]: noRightMargin})}
          onClick={onClick}
        />
      );
    case 'warningSubtle':
      return (
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className={classNames(styles.defaultIcon, styles.warningIconSubtle, {[styles.noRightMargin]: noRightMargin})}
          onClick={onClick} 
        />
      );
    case 'lock':
      return (
        <OverlayTrigger placement="top" overlay={tooltipNote}>
          <FontAwesomeIcon icon={faLock} className={`${styles.defaultIcon} ${styles.greyedIcon}`} onClick={onClick} />
        </OverlayTrigger>
      );
    case 'cross':
      return (
        <FontAwesomeIcon icon={faTimes} className={`${styles.defaultIcon} ${styles.crossIcon}`} onClick={onClick} />
      );
    case 'exclamation':
      return (
        <FontAwesomeIcon icon={faExclamationCircle} className={`${styles.defaultIcon} ${styles.exclamationIcon}`} onClick={onClick} />
      );
    case 'externallink':
      return (
        <OverlayTrigger placement="top" overlay={tooltipExternalLink}>
          <FontAwesomeIcon
            icon={faExternalLink}
            className={classNames(styles.defaultIcon, {[styles.noRightMargin]: noRightMargin})}
            onClick={onClick}
          />
        </OverlayTrigger>
      );
  }
}

/**
 * Where an attachment will open a media modal use icon 'image', any other attachment type use 'file'
 * @param icon - image, file or upload
 * @param onClick - callback function
 */
export const TableIconCell = ({icon, onClick}: tableIconProps) => (
  <td className="pt-0 pb-0">
    <TableIcon icon={icon} onClick={onClick} />
  </td>
)
