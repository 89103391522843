import { useState } from 'react'
import { Card } from 'react-bootstrap'
import { ViewSwitcher } from '../../../elements/ViewSwitcher/ViewSwitcher'
import PortfolioCalendar from '../../PortfolioDashboard/PortfolioCalendar'
import Reminders from './Reminders'
import styles from '../Dashboard.module.scss'

const LeftHandCard = () => {
    const [view, setView] = useState<string>('list')

    return (<>
        {/* Display as none (rather than not rendering) to avoid reloading reminders data */}
        <div className="mb-4" style={{display: view === 'list' ? '' : 'none'}}>
            <Reminders view={view} setView={setView} />
        </div>
        {/* TEMP - DO NOT RENDER CAL */}
        {/* <div style={{display: view === 'cal' ? '' : 'none'}}>
            <Card className={styles.reminderCard} style={{height: '600px', width: '100%', marginBottom: '20px'}}>
                <div style={{display: 'flex', justifyContent:'flex-end', padding: '20px', paddingBottom: '0px'}}>
                    <div style={{marginTop: '-2px'}}><ViewSwitcher small calander list listText="Reminders" viewChanged={setView} currentView={view}  /></div>
                </div>
                <hr />
                <PortfolioCalendar />
            </Card>
        </div> */}
    </>)
}

export default LeftHandCard