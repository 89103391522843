import { useEffect } from 'react';
import styles from './Overlay.module.css';

interface OverlayProps {
    isOpen: boolean
    children: React.ReactNode
}

const Overlay = ({isOpen, children} : OverlayProps) => {
    useEffect(() => {
        if (isOpen) {
          document.body.classList.add("overlay-open")
        } else {
          document.body.classList.remove("overlay-open")
        }
    }, [isOpen])

    return isOpen ? (
        <div className={styles.overlay} data-cy="overlay">
            {children}
        </div>
    ) : null
}

export default Overlay