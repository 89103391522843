import React from 'react';
import styles from './BlankStateCard.module.scss';
import { Card } from 'react-bootstrap';
import { LoadingPlaceholder } from '../../elements/LoadingPlaceholder/LoadingPlaceholder';

const BlankStateCardPlaceholder = () => {
	return (
		<>
			<div className={styles.loading} />

			<div className={styles.cardBodyContent}>
				<div>
					<Card.Title>
						<LoadingPlaceholder width="40%" height='2rem'/>
					</Card.Title>
					<Card.Text className="m-0">
						<LoadingPlaceholder/>
						<LoadingPlaceholder/>
						<LoadingPlaceholder/>
					</Card.Text>
				</div>
				<div className='mt-3'>
					<div className={styles.loadingButton}/>
				</div>
			</div>						
		</>
	);
};

export default BlankStateCardPlaceholder;
