import { useContext, useEffect, useState } from 'react';
import { IdVerificationModel } from '../../../api/inni/data-contracts';
import { Onboarding } from '../../../api/inni/Onboarding';
import CompanyContext from '../../../context/CompanyContext';
import { useInniAPI } from '../../../hooks/useInniAPI';
import StatusItem from './StatusItem';
import * as DataGrid from '../../../elements/DataGrid/DataGrid';
import _ from 'lodash';
import { NoContentSlate } from '../../Slates/NoContentSlate';
import { DatagridType } from '../../../hooks/terms/useDatagridTerms';

const StatusTable = () => {
	const onboardingApi = useInniAPI(Onboarding);
	const { company } = useContext(CompanyContext);
	const [verificationStatuses, setVerificationStatuses] = useState<IdVerificationModel[]>([]);
	const [loader, setLoader] = useState(false);
	useEffect(() => {
		if (onboardingApi && company?.id) {
			setLoader(true);
			onboardingApi
				.getStatus(company?.id)
				.then(({ data }) => setVerificationStatuses(data))
				.catch((err) => console.error(err))
				.finally(() => setLoader(false));
		}
	}, [onboardingApi, company?.id]);

	return (
		<DataGrid.Table>
			<thead>
				<tr>
					<th>Member</th>
					<th>Status</th>
				</tr>
			</thead>
			<tbody>
				{loader && (
					<>
						<DataGrid.LoadingRow cols={2} />
						<DataGrid.LoadingRow cols={2} />
					</>
				)}
				{!loader && (
					<>
						{verificationStatuses.length > 0 ? (
							verificationStatuses.map(({ contactId, firstName, lastName, status }) => (
								<StatusItem
									key={contactId}
									name={`${firstName} ${lastName}`}
									status={status || 'PendingResponse'}
								/>
							))
						) : (
							<tr>
								<td colSpan={5} style={{ padding: 0 }}>
									<NoContentSlate type={DatagridType.Tenancy} termsKey="emptyTerms" />
								</td>
							</tr>
						)}
					</>
				)}
			</tbody>
		</DataGrid.Table>
	);
};

export default StatusTable;
