import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";

const external:Array<EntityRoute> = [
    // {
    //     entity: Entity.CompanyDashboard,
    //     context: RouteContext.Company,
    //     externalUrl: '/Company/:cid'
    // },
    // {
    //     entity: Entity.PropertyDashboard,
    //     context: RouteContext.Company,
    //     externalUrl: '/Company/:cid'
    // },
    {
        entity: Entity.QuickEntry,
        context: RouteContext.Company,
        externalUrl: '/client/quickentry/default.aspx?cid=:cid'
    },
    {
        entity: Entity.Invoice,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Invoicing'
    },
    {
        entity: Entity.Invoice,
        action: Action.Read,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/invoicing/view/:id'
    },
    {
        entity: Entity.Contract,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Contracts'
    },
    {
        entity: Entity.Property,
        action: Action.Edit,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Property/Edit?propertyId=:propertyId',
    },
    { 
        entity: Entity.Arthur,
        action: Action.LinkToExternal,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Property/ConnectToArthur',
    },
    {
        entity: Entity.BrandingTheme,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/invoiceBranding'
    },
    {
        entity: Entity.PaymentProvider,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/PaymentProviders'
    },
    {
        entity: Entity.Ir35ActionPlan,
        context: RouteContext.Company,
        externalUrl: 'https://ir35.inniaccounts.co.uk/'
    },

    {
        entity: Entity.PersonalTaxPlanner,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/TaxPlanning'
    },
    {
        entity: Entity.Payslip,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Payslip'
    },
    {
        entity: Entity.Payslip,
        action: Action.Read,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Payslip/details/:psid'
    },
    {
        entity: Entity.Dividend,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Dividends'
    },

    {
        entity: Entity.OpenBankingDashboard,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/OpenBankingDashboard'
    },
    {
        entity: Entity.Bookkeeping,
        context: RouteContext.Company,
        externalUrl: '/client/banking/default.aspx?cid=:cid&aid=:aid'
    },
    {
      entity: Entity.BookkeepingV6Admin,
        context: RouteContext.Company,
        externalUrl: '/client/banking/default.aspx?cid=:cid&aid=:aid'
    },
    {
        entity: Entity.BankTransaction,
        action: Action.AllPaymentsAdmin,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/BankPaymentSearch'
    },
    {
        entity: Entity.VatReturn,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/VatReturns'
    },
    {
        entity: Entity.PayePayment,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/PAYEPayment'
    },
    {
        entity: Entity.CorporationTax,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/CorporationTax/Details'
    },
    

    // Replace external route with new react component
    {
        entity: Entity.Report,
        context: RouteContext.Company,
        externalUrl: '/client/reporting/default.aspx?cid=:cid'
    },
    {
        entity: Entity.Asset,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Asset'
    },
    // {
    //     entity: Entity.RegularPayment,
    //     context: RouteContext.Company,
    //     externalUrl: '/client/recurringpayments/default.aspx?cid=:cid'
    // },



    {
        entity: Entity.Document,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Files'
    },
    // {
    //     entity: Entity.Receipt,
    //     context: RouteContext.Company,
    //     externalUrl: '/Company/:cid/MyReceipts/inbox'
    // },


    {
        entity: Entity.Company,
        action: Action.List,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Settings'
    },
    {
        entity: Entity.Person,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/manageemployees'
    },
    {
        entity: Entity.WorkplacePension,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/AutoEnrolment'
    },
    {
        entity: Entity.AuditTrail,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/AuditTrail'
    },
    {
        entity: Entity.Subscription,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/billing'
    },
    // {
    //     entity: Entity.Account,
    //     context: RouteContext.Company,
    //     action: Action.Edit,
    //     externalUrl: '/client/accounts/editaccounts.aspx?cid=:cid'
    // },


    // {
    //     entity: Entity.UserProfile,
    //     context: RouteContext.Company,
    //     externalUrl: '/Company/:cid/UserProfile/profile'
    // },
    // {
    //     entity: Entity.Adjustment,
    //     context: RouteContext.Company,
    //     externalUrl: '/Company/:cid/Adjustments'
    // },
    {
        entity: Entity.VatSetting,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/VatSettings'
    },


    {
        entity: Entity.VatObligation,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/Obligations'
    },
    {
        entity: Entity.Company,
        action: Action.Edit,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/settings/edit'
    },
    {
        entity: Entity.ExpenseImport,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/ExpensesImport?accountId=:accountId'
    },

    {
        entity: Entity.SetupWizardOLD,
        context: RouteContext.Company,
        externalUrl: '/client/setupwizard/setuppayroll.aspx?cid=:cid'
    },
    {
        entity: Entity.SetupStatementImporting,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/SetupStatementImport/Step1?accountid=:aid'
    },
    {
        entity: Entity.UploadStatement,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/banking/uploadstatement?accountid=:aid'
    },
    {
        entity: Entity.OpenBanking,
        context: RouteContext.Company,
        externalUrl: '/Company/:cid/setupstatementimport/capstep1'
    },

]

export default external;

