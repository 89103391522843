import { EntityRoute, RouteContext } from "./routeTypes";
import { Action, Entity } from "../../utils/EntityAction";
import { TenancyListPage } from "../../features/Tenancies/TenancyListPage";
import { TenancyReadPage } from "../../features/Tenancies/TenancyReadPage";
import { TenancyNewPage } from "../../features/Tenancies/TenancyNewPage";

const dashboard:Array<EntityRoute> = [
    {
        path: '/company/:cid/tenancy',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Tenancy,
        component: TenancyListPage
    },
    {
        path: '/company/:cid/tenancy/new/:propertyId?/:lettableUnitId?',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Tenancy,
        action: Action.Create,
        component: TenancyNewPage
    },
    {
        path: '/company/:cid/tenancy/:id',
        exact: true,
        context: RouteContext.Company,
        entity: Entity.Tenancy,
        action: Action.Read,
        component: TenancyReadPage
    }
    
]

export default dashboard;

