import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addDays, format, isValid, setDate } from 'date-fns'
import { addMonths } from 'date-fns/esm'
import { Form, Formik, FormikErrors } from 'formik'
import { useContext } from 'react'
import { SetupWizardForm, VatReturnSetupPostModel } from '../../../api/inni/data-contracts'
import { SetupWizard } from '../../../api/inni/SetupWizard'
import CompanyContext from '../../../context/CompanyContext'
import { Button } from '../../../elements/Button/Button'
import { Currency } from '../../../elements/DetailRow/DetailRow'
import { Currency as CurrencyEdit, ListOption, SelectList, Switch } from '../../../elements/EditRow/EditRow'
import { useInniAPI } from '../../../hooks/useInniAPI'
import { useNavigateToEntity } from '../../../hooks/useNavigateToEntity'
import { Action, Entity } from '../../../utils/EntityAction'
import { formatDate } from '../../../utils/formatDate'
import styles from '../SetupWizard.module.css'
import { WarningText } from './WarningText'

const VATReturns = ({setNextPage, formState, setPrevPage} : {setNextPage : (fs?: SetupWizardForm) => void, formState : SetupWizardForm, setPrevPage : () => void}) => {

    const swAPI = useInniAPI(SetupWizard, [400])
    const companyContext = useContext(CompanyContext)
    const navToEntity = useNavigateToEntity()

    const saveVatReturn = (values : VatReturnSetupPostModel) => {
        if (swAPI) {
            swAPI.updateVatReturn(companyContext.cid, values)
            .then(res => {
                if (res.status === 200) {
                    setNextPage({...formState, ...values})
                }
            })
        }
    }

    const validateVatReturn = (values : VatReturnSetupPostModel) : Promise<FormikErrors<VatReturnSetupPostModel>> => {
        return new Promise<FormikErrors<VatReturnSetupPostModel>>((resolve, reject) => {
            if (swAPI) {
                swAPI?.validateUpdateVatReturn(companyContext.cid, values).then(x => {
                    resolve({})
                }).catch(err => resolve(err.error))
            } else {
                reject()
            }            
        })
    }

    // populate last vat return date based on migration date
    // if mig date is 15th may last VAT return is end of april, march or feb
    const generateLastVatReturnDD = () => {
        let lastVatSl : ListOption[] = []
        if (formState.setupMigrationDate && formState.setupMigrationDate.length > 0) {
            let d = new Date(formState.setupMigrationDate)
            if (isValid(d)) {
                let vd = setDate(d, 1)
                // for loop or map better??
                let arr = [...Array(3)]
                arr.map((x,i) => {
                    let ddd = addDays(addMonths(vd, -i), -1)
                    lastVatSl.push({ value: ddd.toISOString().split('.')[0] || '', label: "To end " + format(ddd, "MMM yy") })
                })
            }
        }
        return lastVatSl
    }

    return (
        <div>

            <Formik
                initialValues={formState as VatReturnSetupPostModel}
                validateOnChange={false}
                enableReinitialize
                onSubmit={saveVatReturn}
                validate={validateVatReturn}
            >
                {({ isSubmitting, values }) => (
                    <Form>
                        <p style={{cursor: 'pointer'}} onClick={setPrevPage}><FontAwesomeIcon icon={faArrowLeft} /> Back</p>
                        <h1>VAT returns</h1>
                        <hr />
                        {formState.setupVATAdjPP ? 
                        <>
                            <p>The VAT Returns for this company have already been confirmed, please reset the wizard on the confirmation page to make changes.</p>
                            <div className={styles.formButtons}>
                                <Button onClick={setNextPage} variant="change">Continue</Button>
                            </div>
                        </> : 
                        formState.showVatReturns && !formState.vatRegistered ? 
                        <>
                            <p>VAT Returns is not available for this company please set the company as VAT Registered in company details to enable VAT</p>
                            <div className={styles.formButtons}>
                                <Button onClick={setNextPage} variant="change">Continue</Button>
                            </div>
                        </> : 
                        <>
                            <p><b>Has your company previously submitted a VAT return?</b></p>
                            <Switch<VatReturnSetupPostModel> name="submittedReturn" />
                            
                            {values.submittedReturn && <>
                                <h5>Your lastest VAT return</h5>
                                <SelectList<VatReturnSetupPostModel> name="setupStartVAT" options={generateLastVatReturnDD()} />
                                <Switch<VatReturnSetupPostModel> name="isUnpaidVatReturn" label={`Does the payment for this return appear on your bank statement prior to ${formatDate(formState.setupMigrationDate)}?`} />
                                <CurrencyEdit<VatReturnSetupPostModel> label="What is the outstanding balance (leave blank if unknown)?" name="unpaidVATReturnAmount" />
                                <span className='text-danger'>Enter 0 here if the VAT return was fully paid, leave blank if you don't know, enter the REMAINING amount if partly paid</span>
                            </>}
                            <hr />
                            <h5>Items not included on your VAT return</h5>
                            <p>Have you created any transactions (such as invoices or expense claims) prior to {formatDate(formState.setupMigrationDate)} which haven't yet been included on a VAT return?</p>
                            <p>If yes, ENTER A <b>MIGRATION</b> VAT ADJUSTMENT. The net VAT due will appear here (and in the liability account). 
                             <a href="#" onClick={() => navToEntity(Entity.SetupWizard, Action.Read, { 'page': 'balancesheet' })}>Go to balance sheet</a></p>
                            <Currency entity={formState} name="vatNotOnAReturn" ensureRender
                                label="VAT amount not on a return" />
                            <div className={styles.formButtons}>
                                <Button submitButton variant="change">Continue</Button>
                            </div>
                            <WarningText />
                        </>}
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default VATReturns
